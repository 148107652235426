import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Col,
  Row,
  sx,
  InputLabel,
  LoadingButton,
} from '@applift/factor';
import { InfoCircle, Add, CurrencyDollar, ArrowRightLong, EqualTo, Show } from '@applift/icons';
import clsx from 'clsx';
import { BUDGET_TYPE_ID } from 'constants/apps';

import styles from './index.module.scss';

interface TotalBudgetCompletionData {
  updatedTotalBudget: number;
  selectedCondition: 'set' | 'add';
}

interface SetTotalBudgetProps {
  name?: string;
  inlineEdit?: boolean;
  totalBudget: number;
  ordersCount: number;
  closeDialog: () => void;
  onCompletion: (data: TotalBudgetCompletionData) => void;
  isBudgetBeingSet?: boolean;
  budgetTypeId?: number;
}

export const SetTotalBudget = (props: SetTotalBudgetProps) => {
  const {
    name,
    totalBudget,
    ordersCount,
    closeDialog,
    onCompletion,
    inlineEdit,
    isBudgetBeingSet,
    budgetTypeId,
  } = props;
  const [updatedTotalBudget, updateBudget] = React.useState(
    inlineEdit || totalBudget === 0 ? '' : totalBudget?.toString(),
  );
  const [selectedCondition, updateCondition] = React.useState<'set' | 'add'>('set');
  const isFieldUpdatedRef = React.useRef(false);

  const getHelperText = () => {
    if (!Number(updatedTotalBudget)) {
      return selectedCondition === 'add' && ordersCount === 1 ? (
        <>
          The total budget for the {name} will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'neutral-600', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {totalBudget}
          </Typography>
          .
        </>
      ) : (
        'The total budget will be updated as per the newly added amount.'
      );
    }
    if (selectedCondition === 'set') {
      return ordersCount === 1 ? (
        <>
          The total budget for the {name} will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'neutral-600', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {updatedTotalBudget}
          </Typography>
          .
        </>
      ) : (
        <>
          The total budget for the {ordersCount} insertion orders will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'neutral-600', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {updatedTotalBudget}
          </Typography>
          .
        </>
      );
    }
    if (selectedCondition === 'add') {
      return ordersCount !== 1 ? (
        <>
          The total budget for the {ordersCount} insertion orders will be increased by
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'neutral-600', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {updatedTotalBudget}
          </Typography>
          .
        </>
      ) : (
        <>
          The total budget for the {name} will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'neutral-600', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? totalBudget.toFixed(2) : totalBudget} +
          </Typography>
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'success-500', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'success-500', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'success-500', mr: 4 }}>
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? Number(updatedTotalBudget).toFixed(2)
              : Number(updatedTotalBudget)}
          </Typography>
          =
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Show
              style={{ verticalAlign: 'text-top' }}
              fontSize={14}
              sx={{ textColor: 'neutral-600', ml: 4 }}
            />
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? (totalBudget + Number(updatedTotalBudget)).toFixed(2)
              : totalBudget + Number(updatedTotalBudget)}
          </Typography>
          .
        </>
      );
    }
    return null;
  };

  const setInputValueWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexPositiveFloat = /^(?:\d+(?:\.\d+)?|)$/;
    const regexPositiveInt = /^[1-9]\d*$/;

    const regexForValidation =
      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? regexPositiveFloat : regexPositiveInt;

    const isValid =
      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? regexForValidation.test(e.target.value) ||
          (Boolean(e.target.value.split('.').length - 1 < 2) &&
            e.target.value[e.target.value.length - 1] === '.')
        : regexForValidation.test(e.target.value) || e.target.value === '';

    if (!isValid) {
      return;
    }

    isFieldUpdatedRef.current = true;
    updateBudget(e.target.value);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle onClose={closeDialog}>Set Total Budget</DialogTitle>
      <DialogContent dividers sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography sx={{ mb: 16 }}>
          {inlineEdit ? 'Set' : 'Enter'} the
          <Typography weight="demi" sx={{ ml: 4 }}>
            Total Budget
          </Typography>{' '}
          for the
          {ordersCount === 1 && <Typography sx={{ ml: 4 }}>insertion order</Typography>}
          <Typography weight="demi" sx={{ mx: 4 }}>
            {ordersCount === 1 ? name : ordersCount}
          </Typography>
          {ordersCount > 1 && 'insertion orders'}
        </Typography>

        {!inlineEdit && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Row sx={{ mb: 16 }}>
              <Col>
                <TextField
                  value={updatedTotalBudget}
                  onChange={setInputValueWrapper}
                  placeholder="Enter total budget"
                  variant="outlinedDash"
                  label="Total Budget"
                  InputProps={{
                    startAdornment:
                      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                        <CurrencyDollar sx={{ textColor: 'neutral-400' }} />
                      ) : (
                        <Show sx={{ textColor: 'neutral-400' }} />
                      ),
                  }}
                  sx={{ mb: 2 }}
                  fullWidth
                />
              </Col>
              <Col>
                <InputLabel sx={{ visibility: 'hidden' }}>Dummy Label</InputLabel>
                <ToggleButtonGroup
                  color="primary"
                  variant="outlined"
                  value={selectedCondition}
                  exclusive
                  onChange={(_, val) => {
                    isFieldUpdatedRef.current = true;
                    updateCondition(val);
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="set">
                    <EqualTo />
                    Set
                  </ToggleButton>
                  <ToggleButton value="add">
                    <Add />
                    Add
                  </ToggleButton>
                </ToggleButtonGroup>
                <Tooltip
                  title="Set exact budget or add more to the existing budget across selected campaigns"
                  placement="top"
                  arrow
                >
                  <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400', ml: 12 }} />
                </Tooltip>
              </Col>
            </Row>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 8 }}>
              <InfoCircle fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
              <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
                {getHelperText()}
              </Typography>
            </Box>
          </Box>
        )}
        {inlineEdit && (
          <Box sx={{ display: 'flex', gap: 12 }}>
            <TextField
              value={`$${totalBudget}`}
              disabled
              label="Current Budget"
              variant="outlined"
              InputProps={{
                classes: {
                  root: clsx(
                    sx({
                      borderRadius: 'all',
                      bgColor: 'neutral-75',
                    }),
                    styles.textField,
                  ),
                },
              }}
            />
            <ArrowRightLong fontSize={24} sx={{ textColor: 'neutral-400' }} />
            <TextField
              value={updatedTotalBudget}
              onChange={(e: any) => {
                updateBudget(e.target.value);
              }}
              type="number"
              label="New Total Budget"
              variant="outlined"
              InputProps={{
                startAdornment: '$',
                classes: {
                  input: sx({ borderColor: 'neutral-0' }),
                  root: clsx(
                    sx({
                      textWeight: 'demi',
                      textColor: 'neutral-1000',
                      borderRadius: 'all',
                      bgColor: 'neutral-75',
                    }),
                    styles.textField,
                  ),
                },
                inputProps: {
                  className: sx({ pl: 0 }),
                },
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={
            !(Number(updatedTotalBudget) > 0) ||
            !isFieldUpdatedRef.current ||
            (selectedCondition === 'set' && Number(updatedTotalBudget) === totalBudget)
          }
          loading={isBudgetBeingSet}
          onClick={() =>
            onCompletion({
              updatedTotalBudget: Number(updatedTotalBudget),
              selectedCondition,
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
