import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';

import { PmpCreationApiRef, PmpCreationForm, PmpFormState } from '@applift/inventory';
import { getOwId } from 'iqm-framework';
import { useAddPmpDeal } from 'hooks/useInventory';
import { AddPmpDealRequest, PmpDeal } from 'models/Inventory';

export interface AddPmpDealDialogProps {
  open: boolean;
  closeDialog: () => void;
  setSelectedPmpDealsData: React.Dispatch<React.SetStateAction<PmpDeal[]>>;
}

export const AddPmpDealDialog = (props: AddPmpDealDialogProps) => {
  const { open, closeDialog, setSelectedPmpDealsData } = props;

  const [data, setData] = React.useState<AddPmpDealRequest>({} as AddPmpDealRequest);
  const apiRef = React.useRef<PmpCreationApiRef>(null);

  const disable = React.useMemo(() => {
    if (!(data.dealId && data.dealName && data.exchangeId)) {
      return true;
    }
    return apiRef.current?.hasError();
  }, [data]);
  const [isLoading, setLoading] = useState(false);

  const onChange = React.useCallback((newData: PmpFormState) => {
    setData({
      dealId: newData.dealId,
      cpm: newData.cpm,
      creativeTypes: newData.creativeTypes.map((type) => type.id),
      dealName: newData.dealName,
      active: Boolean(newData.dealStatus),
      description: newData.description,
      exchangeId: newData.exchange?.[0]?.value,
    });
  }, []);

  const addPmpDeal = useAddPmpDeal({
    onSuccess: (res) => {
      const { id } = res.data ?? {};
      enqueueSnackbar({ message: 'PMP Deal created successfully.', variant: 'success' });
      setSelectedPmpDealsData((prev) => {
        const currentEpoch = new Date().getTime();
        return [
          ...prev,
          { ...data, id, exchanges: [data.exchangeId], created: currentEpoch },
        ] as PmpDeal[];
      });
      setSelectedPmpDealsData((prev) => {
        return prev;
      });
      setLoading(false);
      closeDialog();
    },
    onError: (e) => {
      e?.errorObjects?.forEach(({ field, error }) => {
        if (field === 'dealId + exchangeId') {
          apiRef.current?.setError({
            dealId:
              'Deal ID already exists for selected exchange. Use a different Deal ID or Exchange',
          });
        }
      });
      enqueueSnackbar({ message: 'Something went wrong. Please try again', variant: 'error' });
      setLoading(false);
    },
  });

  const handleAddPmpDeal = () => {
    setLoading(true);
    const trimedData = {
      ...data,
      dealId: data.dealId.trim(),
      dealName: data.dealName.trim(),
      description: data.description.trim(),
    };
    addPmpDeal.mutate(trimedData);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>Add Deal</DialogTitle>
      <DialogContent dividers sx={{ py: 32, overflow: 'hidden' }}>
        <PmpCreationForm
          disabledStates={{ dealStatus: true }}
          onChange={onChange}
          loggedInOwId={getOwId()!}
          organizationType="ADVERTISER"
          ref={apiRef}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" sx={{ borderRadius: 2 }} onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          sx={{ borderRadius: 2 }}
          onClick={handleAddPmpDeal}
          disabled={disable}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
