import { AudienceWarning } from 'models/Audience';
import { createAction } from '../../utils/actions';
import { audienceConstants } from './constants';
import { Option } from '../../models/Option';

type Data = {
  key: string;
  value: Option<number | string>[];
};

export type SetAudienceField = {
  setAudienceField: (data: Data) => void;
};

export type SelectAudienceField = {
  selectAudienceField: (data: Data) => void;
};

export type ResetState = {
  resetState: () => void;
};

export type SetAudienceWarning = {
  setAudienceWarning: (warning: AudienceWarning) => void;
};

export type SetPreBidSegmentIds = {
  setAudienceWarning: (ids: number[]) => void;
};

export const audienceActions = {
  setAudienceField(data: Data) {
    return createAction<Data>(audienceConstants.SET_AUDIENCE_FIELD, data);
  },

  selectAudienceField(data: Data) {
    return createAction<Data>(audienceConstants.SELECT_AUDIENCE_FIELD, data);
  },

  setAudienceWarning(warning: AudienceWarning) {
    return createAction<AudienceWarning>(audienceConstants.SET_AUDIENCE_WARNING, warning);
  },

  setPreBidSegmentIds(ids: number[]) {
    return createAction<number[]>(audienceConstants.SET_PREBID_SEGMENT_IDS, ids);
  },

  resetState() {
    return createAction(audienceConstants.RESET_AUDIENCE_STORE);
  },
};
