import * as React from 'react';
import { GridRenderEditCellParams } from '@applift/datagrid';
import { Popover, enqueueSnackbar } from '@applift/factor';
import { EpochDatePicker, TimezoneProvider } from 'iqm-framework';
import moment, { Moment } from 'moment';

import { Timezone } from 'models/Timezone';
import { useTimezone } from 'hooks/useTimezone';

import styles from './styles.module.scss';

export const EditDateCell = (props: GridRenderEditCellParams) => {
  const { apiRef, row, column } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [datePickerPopupEl, setDatePickerPopupEl] = React.useState<HTMLElement | null>(null);
  const [datePickerInputEl, setDatePickerInputEl] = React.useState<HTMLElement | null>(null);
  const [updateClicked, setUpdateClicked] = React.useState<boolean>(false);
  const rowId = React.useMemo(() => row.id, [row]);
  const field = React.useMemo(() => column.id, [column]);
  const timezoneContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const element = apiRef.getCellElement(rowId, field);
    setAnchorEl(element);
  }, [apiRef, rowId, field]);

  const timezoneOptions = useTimezone();
  const selectedTimezone = timezoneOptions.data?.find(
    (option) => option.id === props.row.original.ioTimezone,
  ) as Timezone;

  const timeZone = {
    label: selectedTimezone?.label,
    id: selectedTimezone?.id,
    value: selectedTimezone?.name,
  };

  const updateDate = (date: number) => {
    setUpdateClicked(true);
    const { ioStartTime, ioEndTime } = row.original || {};

    if (
      field === 'ioEndTime' &&
      ioStartTime &&
      moment(ioStartTime).add(30, 'minute').isAfter(moment(date))
    ) {
      enqueueSnackbar(
        'The end date must be 30 minutes after the start time, please correct it and try again.',
        {
          variant: 'error',
        },
      );
    } else if (
      field === 'ioStartTime' &&
      ioEndTime &&
      moment(date).add(30, 'minute').isAfter(moment(ioEndTime))
    ) {
      enqueueSnackbar(
        'The start date must be 30 minutes before the end time, please correct it and try again.',
        {
          variant: 'error',
        },
      );
    } else {
      props.apiRef.setEditCellValue({
        rowId: props.row.id,
        field: props.cell.column.id,
        value: date,
        debounceMs: 100,
      });
    }

    apiRef.stopCellEditMode({ rowId, field });
  };

  const datePopoverRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        setDatePickerPopupEl(
          timezoneContainerRef?.current?.querySelector(
            '.date-range-picker__wrapper-inner',
          ) as HTMLElement,
        );
        setDatePickerInputEl(
          timezoneContainerRef?.current?.querySelector('.datePickerIO') as HTMLElement,
        );
      }, 0);
    }
  }, [anchorEl]);

  datePopoverRef.current = datePickerPopupEl as HTMLElement;

  // @ts-ignore
  const resizeObserver = React.useMemo(
    () =>
      // @ts-ignore
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const cellEl = apiRef.getCellElement(rowId, field) as HTMLDivElement;
        setTimeout(() => {
          // @ts-ignore
          datePickerInputEl.style.width = `${cellEl?.getBoundingClientRect()?.width}px`;
          // @ts-ignore
          datePickerInputEl.style.height = `${cellEl?.getBoundingClientRect()?.height}px`;
        }, 0);
        setTimeout(() => {
          const elHeight = entries?.[0].target?.getBoundingClientRect().height;
          const spaceBelow = (window.innerHeight -
            (cellEl?.getBoundingClientRect()?.bottom as number)) as number;
          const enoughSpace = elHeight < spaceBelow;
          if (!enoughSpace) {
            // @ts-ignore
            datePickerPopupEl.style.top = `-${elHeight + 2}px`;
          }
        }, 150);
      }),
    [datePickerInputEl, apiRef, datePickerPopupEl, field, rowId],
  );

  React.useEffect(() => {
    if (datePickerPopupEl) {
      resizeObserver.observe(datePickerPopupEl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerPopupEl, resizeObserver]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
        apiRef.stopCellEditMode({ rowId, field, ignoreModifications: true });
      }}
      PaperProps={{ sx: { overflow: 'visible' } }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div id="inline-set-end-date" ref={timezoneContainerRef}>
        <TimezoneProvider timezone={timeZone}>
          <EpochDatePicker
            dateRangePickerClassName={styles.dateRangePicker}
            className="datePickerIO"
            dateFormat="MM/DD/YYYY hh:mm A"
            datePickerProps={{
              numberOfCalendars: 1,
            }}
            placeholder="Select End Date"
            preselected={[
              {
                key: 'today',
                title: 'Today',
                date: (
                  tz: {},
                  convert: (date: Moment) => Moment,
                  getCurrentDay: (tz: {}) => Moment,
                ) => convert(getCurrentDay(tz)?.add(0, 'minutes')),
              },
            ]}
            withTimePicker
            singleDateMode
            singleDate={(props.renderValue() as number) ? props.renderValue() : undefined}
            calendarMinimumDate={moment().valueOf()}
            onDateChanged={updateDate}
            insidePreselectors
            pickerIsOpen
            onClickOutside={() => {
              if (!updateClicked) {
                apiRef.stopCellEditMode({ rowId, field, ignoreModifications: true });
              }
            }}
          />
        </TimezoneProvider>
      </div>
    </Popover>
  );
};
