import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@applift/factor';
import { RowSelectionState, SortingState } from '@applift/datagrid';
import { connect } from 'react-redux';
import { applicationActions } from 'store/app/actions';
import { AppState } from 'models/Store';
import { SelectInventoryField, SetInventoryField, inventoryActions } from 'store/inventory/actions';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { PmpDeal } from 'models/Inventory';
import { usePmpDealsList } from 'hooks/useInventory';

import styles from './styles.module.scss';
import { PmpDealTable } from '../PmpDealTable/PmpDealTable';
import { Filters } from '../PmpDealTable/Filters';

export interface SelectInventoryGroupDialogProps extends SetInventoryField, SelectInventoryField {
  open: boolean;
  closeDialog: () => void;
  selectedPmpDealsData: PmpDeal[];
  setSelectedPmpDealsData: React.Dispatch<React.SetStateAction<PmpDeal[]>>;
}

export const SelectPmpDealDialogComponent = (props: SelectInventoryGroupDialogProps) => {
  const { open, closeDialog, selectedPmpDealsData, setSelectedPmpDealsData } = props;

  const initialSelection =
    selectedPmpDealsData?.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: true }),
      {} as RowSelectionState,
    ) ?? {};

  const [search, setSearch] = React.useState('');
  const [exchanges, setExchanges] = React.useState<RowSelectionState>({});
  const [rowSelection, setRowSelection] = React.useState(initialSelection);
  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'created', desc: true }]);

  const exchangesStr = Object.keys(exchanges).join(',');
  const { data, isLoading, fetchNextPage } = usePmpDealsList({
    searchKeywords: search,
    exchanges: exchangesStr,
    active: true, // Only active deals can be attached to the campaign
    sortBy: sorting.map((state) => (state.desc ? `-${state.id}` : `+${state.id}`)).join(','),
  });
  const filteredRecords = data?.pages[0]?.data?.filteredRecords;

  const pmpDealsList =
    React.useMemo(
      () =>
        data?.pages.reduce(
          (prev, curr) => [...(prev ?? []), ...(curr.data?.pmpDealData ?? [])],
          [] as PmpDeal[],
        ),
      [data],
    ) ?? [];

  const [tempSelection, setTempSelection] = React.useState(selectedPmpDealsData);

  React.useEffect(() => {
    setTempSelection((prev) => {
      const preSelectedIds = prev?.map((grp) => grp.id);
      const preSelectedGroups = prev.filter((grp) => grp.id! in rowSelection);
      const newSelected = Object.keys(rowSelection)
        .filter((rowId) => preSelectedIds.includes(Number(rowId)) === false)
        .map((rowId) => ({
          ...pmpDealsList.filter((grp) => grp.id === Number(rowId))[0],
        }));
      return [...preSelectedGroups, ...newSelected].sort((a, b) => a.id! - b.id!);
    });
    // eslint-disable-next-line
  }, [rowSelection]);

  const handleSelect = () => {
    setSelectedPmpDealsData?.(tempSelection);
    closeDialog();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xl" classes={{ paper: styles.dialogPaper }}>
      <DialogTitle>Select Deals</DialogTitle>
      <DialogContent dividers sx={{ py: 32, overflow: 'hidden' }}>
        <Paper
          sx={{ height: 100, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
          variant="elevation"
          elevation={2}
        >
          <Filters
            search={search}
            setSearch={(val) => {
              setSearch(val);
            }}
            exchanges={exchanges}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setExchanges={(val) => {
              setExchanges(val);
            }}
            filteredRecords={filteredRecords}
          />
          <PmpDealTable
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            data={pmpDealsList}
            loading={isLoading}
            fetchNextPage={fetchNextPage}
            filteredRecords={filteredRecords ?? 0}
            search={search}
            sorting={sorting}
            setSorting={setSorting}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" sx={{ borderRadius: 2 }} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          sx={{ borderRadius: 2 }}
          onClick={handleSelect}
          disabled={Object.keys(rowSelection).length === 0}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  auth: state.auth,
  submitted: state.app.submitted,
  editableCampaign: state.app.editableCampaign,
  errorCreating: state.app.errorCreating,
  inventoryState: state.inventory,
  selectedInventoryGroups: state.inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups],
  selectedDealGroups: state.inventory.sidebarCampaignInfo[CampaignInfoField.dealGroups],
  selectedPublishers: state.publishers.selectedPublishers,
  publishers: state.publishers.publishers,
});

const mapAction = {
  resetError: applicationActions.resetError,
  setInventoryField: inventoryActions.setInventoryField,
  selectInventoryField: inventoryActions.selectInventoryField,
};

export const SelectPmpDealDialog = connect(mapState, mapAction)(SelectPmpDealDialogComponent);
