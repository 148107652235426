import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@applift/factor';
import React from 'react';

interface ErrorThemeDialogProps {
  closeDialog: () => void;
  dialogMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  title: string;
  onConfirmation: () => void;
  primaryBtnText: string;
  dialogContent: string | JSX.Element;
}

export const ErrorThemeDialog = (props: ErrorThemeDialogProps) => {
  const { closeDialog, dialogMaxWidth, title, onConfirmation, primaryBtnText, dialogContent } =
    props;

  return (
    <Dialog open onClose={closeDialog} maxWidth={dialogMaxWidth} fullWidth>
      <DialogTitle id={`${title}_dialog`} onClose={closeDialog}>
        <Typography weight="demi"> {title} </Typography>
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={onConfirmation}>
          {primaryBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
