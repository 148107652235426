import { AxiosResponse } from 'axios';
import { CampaignGroup } from '../models/CampaignGroup';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';

interface CampaignGroupParams {
  includeChild: 0 | 1;
}

interface CreateGroupParams {
  name: string;
}

interface DeleteGroupParams {
  ids: number;
}

export const List = async (): Promise<CampaignGroup[]> => {
  try {
    const response: AxiosResponse<GetResponse<CampaignGroup[]>> = await getInstance().get(
      '/v2/cmp/campaigngroup/list',
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting campaign group list', e);
    return [];
  }
};

export const CampaignList = async (params: CampaignGroupParams): Promise<CampaignGroup[]> => {
  try {
    const response: AxiosResponse<GetResponse<CampaignGroup[]>> = await getInstance().get(
      '/v2/cmp/campaigngroup/campaign/list',
      {
        params,
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting campaign group list', e);
    return [];
  }
};

export const CreateGroup = async (data: CreateGroupParams): Promise<CampaignGroup> => {
  try {
    const response: AxiosResponse<GetResponse<CampaignGroup>> = await getInstance().post(
      '/v2/cmp/campaigngroup',
      {
        ...data,
        parentId: '0',
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while create campaign group', e);
    throw e;
  }
};

export const DeleteGroup = async (params: DeleteGroupParams): Promise<GetResponse> => {
  try {
    const response: AxiosResponse<GetResponse> = await getInstance().delete(
      '/v2/cmp/campaigngroup/remove',
      { params },
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while delete campaign group', e);
    throw e;
  }
};
