import { Moment } from 'moment';

export function nearestMinutes(interval: number, someMoment: Moment) {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestPastMinutes(interval: number, someMoment: Moment) {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(interval: number, someMoment: Moment) {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}
