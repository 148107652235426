import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { StoreFilter } from '../models/ZustandStore';
import { createCampaignInfoStore } from './pgCampaign/CampaignInfo';
import { createLocationBlockStore } from './pgCampaign/LocationBlock';
import { createCreativesBlockStore } from './pgCampaign/CreativesBlock';
import { createInventoryBlockStore } from './pgCampaign/Inventory';
import { createPgCampaignPageStore } from './pgCampaign/PgCampaignPage';
import { createConversionBlockStore } from './pgCampaign/ConversionBlock';
import { createPoliticalAdvertiserBlock } from './pgCampaign/CampaignPoliticalAdvertiserBlock';
import { createErrorStore } from './pgCampaign/ErrorsStore';

export const useStore = create<StoreFilter>()(
  devtools((...a) => ({
    ...createCampaignInfoStore(...a),
    ...createLocationBlockStore(...a),
    ...createCreativesBlockStore(...a),
    ...createInventoryBlockStore(...a),
    ...createPgCampaignPageStore(...a),
    ...createConversionBlockStore(...a),
    ...createPoliticalAdvertiserBlock(...a),
    ...createErrorStore(...a),
  })),
);
