import { put, select } from 'redux-saga/effects';

import { API } from 'api';
import { AppState } from 'models/Store';
import { Dictionary } from 'models/Dictionary';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Logger } from 'utils/logger';
import { TV_TRAFFIC_IDS } from 'constants/technology';
import { technologyActions } from '../technology/actions';

export function* fetchTrafficTypes(): Generator<unknown, void, any> {
  const state: AppState = yield select();
  const { auth } = state;

  if (auth.userData) {
    try {
      const list: Dictionary[] = yield API.List.TrafficTypes();
      const types = list.map((t) => ({
        label: t.name,
        value: t.id,
      }));

      const nonTvTypes = types.filter((t) => !TV_TRAFFIC_IDS.includes(t.value));
      yield put(
        technologyActions.setTechnologyField({
          key: 'traffic',
          value: nonTvTypes,
        }),
      );

      const tvTypes = types.filter((t) => TV_TRAFFIC_IDS.includes(t.value));
      yield put(
        technologyActions.setTechnologyField({
          key: 'tvTraffic',
          value: tvTypes,
        }),
      );

      if (!window.location.hash?.includes('edit')) {
        yield put(
          technologyActions.selectTechnologyField({
            key: CampaignInfoField.traffic,
            value: nonTvTypes,
          }),
        );
      }
    } catch (e) {
      Logger.log('[fetchTrafficTypes Error]', e);
    }
  }
}
