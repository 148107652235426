import * as React from 'react';
import { TypoTooltip } from '@applift/factor';
import { displayTime } from 'utils/format';

interface PercentCellType {
  value: number;
  isFooterCell?: boolean;
}

export const HourCell = ({ value, isFooterCell = false }: PercentCellType) => {
  return value != null ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {displayTime(value)}
    </TypoTooltip>
  ) : (
    <>—</>
  );
};
