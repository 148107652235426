import React from 'react';
import { Box, TypoTooltip, Typography, TypographyProps } from '@applift/factor';
import { useInventoryGroupsTypes } from 'hooks/useInventory';
import { groupTypesIconMapping } from '../groupTypeIconMapping';

export interface InventoryGroupTypeCellProps {
  groupId: number;
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const InventoryGroupTypeCell = ({
  groupId,
  typographyProps,
}: InventoryGroupTypeCellProps) => {
  const inventoryGroupesTypes = useInventoryGroupsTypes();

  const Icon = groupTypesIconMapping[groupId as keyof typeof groupTypesIconMapping];
  const groupName = inventoryGroupesTypes.filter((group) => group.id === groupId)[0]?.name;

  return (
    <TypoTooltip title={groupName}>
      <Box sx={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <Icon color="primary" fontSize={24} />
        {/* eslint-disable-next-line */}
        <Typography {...typographyProps}>{groupName}</Typography>
      </Box>
    </TypoTooltip>
  );
};
