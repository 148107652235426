import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';

import { getDimensions } from 'api/Dimension';
import { WithResponse } from '../models/Response';

export const getDimensionsKey = {
  keys: (args: { scope: string }) => [args],
};

export const useDimensions = () => {
  const data = useQuery(getDimensionsKey.keys({ scope: 'getDimensions' }), getDimensions, {
    onError: (e: WithResponse) => {
      enqueueSnackbar(
        e?.errorObjects ? (e.errorObjects[0]?.error as string) : 'Something went wrong!',
        {
          variant: 'error',
        },
      );
    },
    cacheTime: Infinity,
  });
  return data;
};
