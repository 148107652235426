/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { TextField, Tooltip, Icon, Tumbler, ButtonsGroup, CollapsibleBlock } from 'factor';
import { connect } from 'react-redux';
import { Box, Typography } from '@applift/factor';
import { BidAlt } from '@applift/icons';

import { blockStyles } from 'components/Block';
import { ServerErrorMessage } from 'components/ServerErrorMessage';
import { AppState } from 'models/Store';
import { Option } from 'models/Option';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { NumberInput } from 'components/NumberInput/index';
import { bidActions, ChangeBidField, ResetImpressionsField } from 'store/bid/actions';
import { BidStore } from 'store/bid/reducer';
import { applicationActions, ResetError } from 'store/app/actions';
import { checkBudgetCapping } from 'store/app/helpers';
import { ErrorCreatingResponse } from 'models/Response';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { checkForFewerValidation } from 'utils/validationRules';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { formatConversionsType, formatToBoolean } from 'utils/helpers';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { numberFormat } from 'utils/format';

import styles from './index.module.scss';

type Props = ChangeBidField &
  ResetImpressionsField &
  ResetError & {
    deviceTypes: Option<number>[];
    bidStore: BidStore;
    errorCreating: ErrorCreatingResponse | null;
    submitted: boolean;
    editableCampaign: ExistingCampaignData | null;
    isCollapseOpen?: boolean;
    onToggle?: (isOpened: boolean) => void;
    collapseMode?: CollapseMode;
    budgetTypeId: number;
  };

type State = {
  isAppUrlFocused: boolean;
};

const conversionsTypes = [
  { title: 'Install', value: true },
  { title: 'Non-install', value: false },
];

class BidStrategyBlockComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAppUrlFocused: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { editableCampaign } = this.props;
    let budgetCapping = false;
    if (editableCampaign && editableCampaign !== prevProps.editableCampaign) {
      if (editableCampaign.maxDayImpressions) {
        budgetCapping = true;
        this.handleChangeStoreData('impressionsMaxDaily')(
          editableCampaign.maxDayImpressions.toString(),
        );
      }
      if (editableCampaign.maxDayClicks) {
        budgetCapping = true;
        this.handleChangeStoreData('clicksMaxDaily')(editableCampaign.maxDayClicks.toString());
      }
      if (editableCampaign.maxDayConversions) {
        budgetCapping = true;
        this.handleChangeStoreData('conversionsMaxDaily')(
          editableCampaign.maxDayConversions.toString(),
        );
      }
      if (editableCampaign.totalImpressions) {
        budgetCapping = true;
        this.handleChangeStoreData('impressionsMaxTotal')(
          editableCampaign.totalImpressions.toString(),
        );
      }
      if (editableCampaign.totalClicks) {
        budgetCapping = true;
        this.handleChangeStoreData('clicksMaxTotal')(editableCampaign.totalClicks.toString());
      }
      if (editableCampaign.totalConversions) {
        budgetCapping = true;
        this.handleChangeStoreData('conversionsMaxTotal')(
          editableCampaign.totalConversions.toString(),
        );
      }
      if (editableCampaign.impressionCapping) {
        this.handleChangeStoreData('impressionsCapping')(true);
        this.handleChangeStoreData('impressionsCappingValue')(editableCampaign.impressionCapping);
      }
      if (!editableCampaign.bidPacing) {
        this.handleChangeStoreData('bidPacing')(false);
      }
    }
    if (budgetCapping) {
      this.handleChangeStoreData('budgetCapping')(true, true);
    }
  }

  handleChangeStoreData =
    (key: string) =>
    (value: number | boolean | string, stopResetting: boolean = false) => {
      const { changeBidField, errorCreating, resetError, resetImpressionsField, budgetTypeId } =
        this.props;
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[key]) {
        resetError();
      }
      changeBidField({
        key,
        value,
      });
      if (!stopResetting) {
        if (key === 'budgetCapping' && budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
          resetImpressionsField();
        }
      }
    };

  handleCheckBudgetCapping = () => {
    const { bidStore: bid } = this.props;
    return checkBudgetCapping(
      bid.budgetCapping,
      {
        impressionsMaxTotal: bid.impressionsMaxTotal,
        clicksMaxTotal: bid.clicksMaxTotal,
        conversionsMaxTotal: bid.conversionsMaxTotal,
      },
      {
        impressionsMaxDaily: bid.impressionsMaxDaily,
        clicksMaxDaily: bid.clicksMaxDaily,
        conversionsMaxDaily: bid.conversionsMaxDaily,
      },
    );
  };

  getHeaderObj = () => {
    const { bidStore } = this.props;
    return {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
          <BidAlt fontSize={24} sx={{ textColor: 'primary-600' }} />
          <Typography>Bid Strategy</Typography>
        </Box>
      ),
      summary: {
        'Bid Pacing': formatToBoolean(bidStore.bidPacing),
        'Frequency Capping': bidStore.impressionsCapping
          ? bidStore.impressionsCappingValue.toString()
          : '',
        'Conversions Optimization': bidStore.conversionsOptimization ? 'Selected' : '',
        'Target CPI/CPA': bidStore.conversionsOptimization ? bidStore.targetCpiCpa : '',
        'Conversions Type': bidStore.conversionsOptimization
          ? formatConversionsType(bidStore.conversionsType)
          : '',
        'App URL':
          bidStore.conversionsOptimization &&
          bidStore.conversionsType &&
          bidStore.appUrl.trim().length
            ? `https://${bidStore.appUrl.trim()}`
            : '',
        'Budget Capping': bidStore.budgetCapping ? 'Selected' : '',
        'Impressions Max Daily': bidStore.budgetCapping
          ? numberFormat(bidStore.impressionsMaxDaily)
          : '',
        'Impressions Max Total': bidStore.budgetCapping
          ? numberFormat(bidStore.impressionsMaxTotal)
          : '',
        'Clicks Max Daily': bidStore.budgetCapping ? bidStore.clicksMaxDaily : '',
        'Clicks Max Total': bidStore.budgetCapping ? bidStore.clicksMaxTotal : '',
        'Conversions Max Daily': bidStore.budgetCapping ? bidStore.conversionsMaxDaily : '',
        'Conversions Max Total': bidStore.budgetCapping ? bidStore.conversionsMaxTotal : '',
      },
    };
  };

  render() {
    const { bidStore, submitted, isCollapseOpen, onToggle, collapseMode, budgetTypeId } =
      this.props;
    const { isAppUrlFocused } = this.state;

    return (
      <CollapsibleBlock
        collapsible={collapseMode !== ALL_EXPANDED}
        isCollapseOpen={isCollapseOpen}
        onToggle={onToggle}
        header={this.getHeaderObj()}
        customStyle={{
          block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
          panelHeaderExpand: blockStyles.panelHeaderExpand,
          panelHeaderCollapse: blockStyles.panelHeaderCollapse,
        }}
      >
        <div data-qa="255" className={`row ${blockStyles.blockRow}`}>
          <div data-qa="256" className={`col-4 ${blockStyles.input} ${blockStyles.first}`}>
            <div data-qa="257" className="mr-2">
              <Tumbler
                name="budgetCapping"
                id="budgetCapping"
                disabled={budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED}
                on={bidStore.budgetCapping}
                onChange={() => {
                  this.handleChangeStoreData('budgetCapping')(!bidStore.budgetCapping);
                }}
              />
            </div>
            <label
              data-qa="258"
              className={`text-label ${blockStyles.inputLabel} ${
                bidStore.budgetCapping ? 'on' : ''
              } `}
            >
              <span data-qa="259">Budget Capping</span>
              <Tooltip label="Budget Capping" portal>
                <Icon className="p-1 ml-2" name="Question" />
              </Tooltip>
            </label>
          </div>
          <div data-qa="260" className={`col-4 ${blockStyles.input} ${blockStyles.first}`}>
            <div data-qa="261" className="mr-2">
              <Tumbler
                name="impressionsCapping"
                id="impressionsCapping"
                on={bidStore.impressionsCapping}
                onChange={() =>
                  this.handleChangeStoreData('impressionsCapping')(!bidStore.impressionsCapping)
                }
              />
            </div>
            <label
              data-qa="262"
              className={`text-label ${blockStyles.inputLabel} ${
                bidStore.impressionsCapping ? 'on' : ''
              } `}
            >
              <span data-qa="263">Frequency Capping</span>
              <Tooltip label="Frequency Capping" portal>
                <Icon className="p-1 ml-2" name="Question" />
              </Tooltip>
            </label>

            {bidStore.impressionsCapping && (
              <NumberInput
                className={blockStyles.inputNumberInput}
                value={bidStore.impressionsCappingValue}
                onChange={(v) => {
                  const value = Math.abs(+v);
                  if (
                    !Number.isNaN(value) &&
                    value >= 1 &&
                    /^[1-9]?[0-9]$/.test(value.toString())
                  ) {
                    return this.handleChangeStoreData('impressionsCappingValue')(value);
                  }
                  return this.handleChangeStoreData('impressionsCappingValue')(
                    +value.toString().substr(1, 3) > 1 ? value.toString().substr(1, 3) : 1,
                  );
                }}
              />
            )}
          </div>
          <div data-qa="264" className={`col-4 ${blockStyles.input} ${blockStyles.first}`}>
            <div data-qa="265" className="mr-2">
              <Tumbler
                name="bidPacing"
                id="bidPacing"
                on={bidStore.bidPacing}
                onChange={() => this.handleChangeStoreData('bidPacing')(!bidStore.bidPacing)}
              />
            </div>
            <label
              data-qa="266"
              className={`text-label ${blockStyles.inputLabel} ${bidStore.bidPacing ? 'on' : ''} `}
            >
              <span data-qa="267">Bid Pacing</span>
              <Tooltip
                auto={false}
                position="left"
                label="When enabled, the daily budget distributed equally throughout the day"
                portal
              >
                <Icon className="p-1 ml-2" name="Question" />
              </Tooltip>
            </label>
          </div>
        </div>

        {bidStore.conversionsOptimization && (
          <>
            <div data-qa="276" className={`row ${blockStyles.blockRow}`}>
              <div data-qa="277" className={`col-5 ${blockStyles.input} ${blockStyles.first}`}>
                <label data-qa="278" className={`text-label ${blockStyles.inputLabel}`}>
                  <span data-qa="279">Target CPI/CPA</span>
                  <Tooltip label="Target CPI/CPA">
                    <Icon className="p-1 ml-2" name="Question" />
                  </Tooltip>
                </label>
                <div data-qa="280" className={blockStyles.inputField}>
                  <TextField
                    value={bidStore.targetCpiCpa}
                    type="text"
                    onChange={(value: string) => {
                      this.handleChangeStoreData('targetCpiCpa')(value.replace(/[^0-9]*$/, ''));
                    }}
                  />
                  <ServerErrorMessage errorKey={errorFieldsMapper.targetCpiCpa} />
                </div>
              </div>
            </div>

            <div data-qa="281" className={`${blockStyles.blockRow}`}>
              <div data-qa="282" className={`${blockStyles.input} ${blockStyles.first}`}>
                <label data-qa="283" className={`text-label ${blockStyles.inputLabel}`}>
                  <span data-qa="284">Conversions Type</span>
                  <Tooltip label="Conversions Type">
                    <Icon className="p-1 ml-2" name="Question" />
                  </Tooltip>
                </label>
                <ButtonsGroup
                  size="sm"
                  items={conversionsTypes}
                  value={bidStore.conversionsType}
                  onChange={(value: any) => this.handleChangeStoreData('conversionsType')(value)}
                />
              </div>
            </div>

            {bidStore.conversionsType && (
              <div data-qa="285" className={`row ${blockStyles.blockRow}`}>
                <div data-qa="286" className={`col-5 ${blockStyles.input} ${blockStyles.first}`}>
                  <label data-qa="287" className={`text-label ${blockStyles.inputLabel}`}>
                    <span data-qa="288">App URL</span>
                    <Tooltip label="App URL">
                      <Icon className="p-1 ml-2" name="Question" />
                    </Tooltip>
                  </label>
                  <div data-qa="289" className={blockStyles.inputField}>
                    <TextField
                      value={bidStore.appUrl}
                      label={isAppUrlFocused || bidStore.appUrl.length ? '' : 'https://'}
                      placeholder="https://"
                      onFocus={() => {
                        this.setState({
                          isAppUrlFocused: true,
                        });
                      }}
                      onBlur={() => {
                        this.setState({
                          isAppUrlFocused: false,
                        });
                      }}
                      onChange={(value: string) => this.handleChangeStoreData('appUrl')(value)}
                    />
                    <ServerErrorMessage errorKey={errorFieldsMapper.appUrl} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {bidStore.budgetCapping && (
          <div
            data-qa="295"
            className={`${blockStyles.inputsList} row`}
            data-not-valid={!this.handleCheckBudgetCapping()}
          >
            <div
              data-qa="296"
              className={`align-items-start ${blockStyles.input} ${blockStyles.first} col-4`}
            >
              <div
                data-qa="297"
                className={`${blockStyles.inputLabel} ${blockStyles.inputsListTitles}`}
              >
                <label data-qa="298" className={`text-label ${blockStyles.inputsListRow}`}>
                  <span data-qa="299">Impressions</span>
                  <Tooltip label="Impressions">
                    <Icon className="p-1 ml-2" name="Question" />
                  </Tooltip>
                </label>
                <label data-qa="300" className={`text-label ${blockStyles.inputsListRow}`}>
                  <span data-qa="301">Clicks</span>
                  <Tooltip label="Clicks">
                    <Icon className="p-1 ml-2" name="Question" />
                  </Tooltip>
                </label>
                <label data-qa="302" className={`text-label ${blockStyles.inputsListRow}`}>
                  <span data-qa="303">Conversions</span>
                  <Tooltip label="Conversions">
                    <Icon className="p-1 ml-2" name="Question" />
                  </Tooltip>
                </label>
              </div>

              <div data-qa="304" className={`${blockStyles.inputsListTiles}`}>
                <div data-qa="305" className={`${blockStyles.inputsListTile}`}>
                  <h4 data-qa="306" className={blockStyles.inputsTitle}>
                    Maximum Daily
                  </h4>
                  <div
                    data-qa="307"
                    className={`${blockStyles.inputsListRow} ${styles.inputContainer}`}
                  >
                    {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                      <TextField
                        value={bidStore.impressionsMaxDaily}
                        type="number"
                        onChange={(value: string) =>
                          this.handleChangeStoreData('impressionsMaxDaily')(value)
                        }
                        validationRules={[
                          {
                            ...checkForFewerValidation(submitted, +bidStore.impressionsMaxTotal),
                            name: 'Impressions Max Daily',
                            comparedFieldName: 'Impressions Max Total',
                          },
                        ]}
                        validationKeys={[submitted, +bidStore.impressionsMaxTotal]}
                      />
                    ) : (
                      <Typography sx={{ ml: 12 }}>
                        {numberFormat(bidStore.impressionsMaxDaily)}
                      </Typography>
                    )}
                    <ServerErrorMessage errorKey={errorFieldsMapper.impressionsMaxDaily} />
                  </div>
                  <div
                    data-qa="308"
                    className={`${blockStyles.inputsListRow} ${styles.inputContainer}`}
                  >
                    <TextField
                      value={bidStore.clicksMaxDaily}
                      type="number"
                      onChange={(value: string) =>
                        this.handleChangeStoreData('clicksMaxDaily')(value)
                      }
                      validationRules={[
                        {
                          ...checkForFewerValidation(submitted, +bidStore.clicksMaxTotal),
                          name: 'Clicks Max Daily',
                          comparedFieldName: 'Clicks Max Total',
                        },
                      ]}
                      validationKeys={[submitted, +bidStore.clicksMaxTotal]}
                    />
                    <ServerErrorMessage errorKey={errorFieldsMapper.clicksMaxDaily} />
                  </div>
                  <div
                    data-qa="309"
                    className={`${blockStyles.inputsListRow} ${styles.inputContainer}`}
                  >
                    <TextField
                      value={bidStore.conversionsMaxDaily}
                      type="number"
                      onChange={(value: string) =>
                        this.handleChangeStoreData('conversionsMaxDaily')(value)
                      }
                      validationRules={[
                        {
                          ...checkForFewerValidation(submitted, +bidStore.conversionsMaxTotal),
                          name: 'Conversions Max Daily',
                          comparedFieldName: 'Conversions Max Total',
                        },
                      ]}
                      validationKeys={[submitted, +bidStore.conversionsMaxTotal]}
                    />
                    <ServerErrorMessage errorKey={errorFieldsMapper.conversionsMaxDaily} />
                  </div>
                </div>

                <div data-qa="310" className={`${blockStyles.inputsListTile}`}>
                  <h4 data-qa="311" className={blockStyles.inputsTitle}>
                    Maximum Total
                  </h4>
                  <div
                    data-qa="312"
                    className={`${blockStyles.inputsListRow} ${styles.inputContainer}`}
                  >
                    {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                      <TextField
                        value={bidStore.impressionsMaxTotal}
                        type="number"
                        onChange={(value: string) =>
                          this.handleChangeStoreData('impressionsMaxTotal')(value)
                        }
                      />
                    ) : (
                      <Typography sx={{ ml: 12 }}>
                        {numberFormat(bidStore.impressionsMaxTotal)}
                      </Typography>
                    )}
                    <ServerErrorMessage errorKey={errorFieldsMapper.impressionsMaxTotal} />
                  </div>
                  <div
                    data-qa="313"
                    className={`${blockStyles.inputsListRow} ${styles.inputContainer}`}
                  >
                    <TextField
                      value={bidStore.clicksMaxTotal}
                      type="number"
                      onChange={(value: string) =>
                        this.handleChangeStoreData('clicksMaxTotal')(value)
                      }
                    />
                    <ServerErrorMessage errorKey={errorFieldsMapper.clicksMaxTotal} />
                  </div>
                  <div
                    data-qa="314"
                    className={`${blockStyles.inputsListRow} ${styles.inputContainer}`}
                  >
                    <TextField
                      value={bidStore.conversionsMaxTotal}
                      type="number"
                      onChange={(value: string) =>
                        this.handleChangeStoreData('conversionsMaxTotal')(value)
                      }
                    />
                    <ServerErrorMessage errorKey={errorFieldsMapper.conversionsMaxTotal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CollapsibleBlock>
    );
  }
}

const mapState = (state: AppState) => ({
  bidStore: state.bid,
  deviceTypes: state.technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes],
  errorCreating: state.app.errorCreating,
  submitted: state.app.submitted,
  editableCampaign: state.app.editableCampaign,
  budgetTypeId: state.app.budgetTypeId,
});

const mapActions = {
  changeBidField: bidActions.changeBidField,
  resetError: applicationActions.resetError,
  resetImpressionsField: bidActions.resetImpressionsField,
};

export const BidStrategyBlock = connect(mapState, mapActions)(BidStrategyBlockComponent);
