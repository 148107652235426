import * as React from 'react';
import { TextField, BaseTextFieldProps, Box, IconButton } from '@applift/factor';
import { Close } from '@applift/icons';

import styles from './index.module.scss';

export interface BreadCrumbSelectPropTypes {
  value: BaseTextFieldProps<any>['value'];
  searchPlaceholder: BaseTextFieldProps<any>['placeholder'];
  onChange: BaseTextFieldProps<any>['onChange'];
  panelType: 'campaign' | 'io';
  children: BaseTextFieldProps<any>['children'];
  search: BaseTextFieldProps<any>['value'];
  width: number;
  renderValue: (val: any) => React.ReactNode;
  onSearch: (val: string) => void;
}

export const BreadCrumbSelect = (props: BreadCrumbSelectPropTypes) => {
  const { onChange, value, children, renderValue, search, onSearch, width, searchPlaceholder } =
    props;

  return (
    <TextField
      sx={{ width: 'auto', display: 'inline' }}
      SelectProps={{
        style: { width },
        MenuProps: {
          PaperProps: {
            style: { width: 200 },
            sx: { height: 'auto' },
          },
        },
        renderValue,
        renderContentTop: (
          <Box sx={{ width: 100, borderBottom: 1 }}>
            <TextField
              placeholder={searchPlaceholder}
              value={search}
              onChange={(e: any) => onSearch(e.target.value)}
              fullWidth
              classes={{
                root: styles.breadcrumbSelectSearchInput,
              }}
              InputProps={{
                endAdornment:
                  (search as string)?.length > 0 ? (
                    <IconButton
                      onClick={() => {
                        onSearch('');
                      }}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  ) : null,
              }}
            />
          </Box>
        ),
      }}
      select
      value={value}
      variant="outlined"
      onChange={(e: any) => onChange && onChange(e)}
    >
      {children}
    </TextField>
  );
};
