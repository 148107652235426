import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Layout,
  sx,
  useDebounceValue,
} from '@applift/factor';
import { ORIGIN_URL, getHeaders } from 'iqm-framework';
import { AppState } from 'models/Store';
import { User } from 'models/User';
import CreativesTableView from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/creativeTableView/CreativesTableView';
import CreativesGroupsList from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/creativesGroupsList/CreativesGroupsList';
import CreativesManagePanel from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/creativesManagePanel/CreativesManagePanel';
import { TCreativeStatus } from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/creativesManagePanel/creativeStatusesCount/CreativeStatusesCount';
import SelectionActionPanel from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/selectionActionPanel/SelectionActionPanel';
import CreativesCardList from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/creativesCardList/CreativesCardList';
import { Creative } from 'models/Creative';
import axios from 'axios';

import './styles.module.scss';

export interface SelectCreativeDialogPropTypes {
  authorizedUser: User;
  selectedCreativeType: number | null;
  selectedCreatives?: Creative[];
  setCreativeSelectDialogType?: (arg: 'select' | 'create' | '') => void;
  setSelectedCreatives: (arg: Creative[]) => void;
}

const SelectCreativeDialogComponent = (props: SelectCreativeDialogPropTypes) => {
  const {
    setCreativeSelectDialogType,
    authorizedUser,
    selectedCreativeType,
    setSelectedCreatives,
    selectedCreatives,
  } = props;

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [searchField, setSearchField] = React.useState<string>();
  const [status, setStatus] = React.useState<TCreativeStatus>({
    creativeCount: 74,
    displayName: 'All',
    id: 0,
    status: 'all',
  });
  const [isTableView, setIsTableView] = React.useState<boolean>(true);
  const [localSelectedCreatives, setLocalSelectedCreatives] = React.useState<any[]>([]);
  const [creatives, setCreatives] = React.useState<Creative[]>();
  const [filteredRecords, setFilteredRecords] = React.useState<number>();
  const [, setTotalRecords] = React.useState<number>();
  const [pageNumber, setPageNumber] = React.useState<number>();
  const [creativeGroupId, setCreativeGroupId] = React.useState<number>();
  const [url, setUrl] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const prevUrlRef = React.useRef<string>();

  const emptyListLabel = searchField
    ? 'No results found. Try changing search parameters.'
    : 'No Data Available';

  const getCreativeStatusParams = (status: any) => {
    return status && status.id ? status.id || '' : '1,2,3';
  };

  const debouncedSearch = useDebounceValue(searchField, 500);

  React.useEffect(() => {
    const queryParams: any = {
      debouncedSearch,
      status,
      creativeGroupId,
    };
    const creativeTypeId = selectedCreativeType;
    const domainURL = authorizedUser ? authorizedUser.domainURL : '';
    if (domainURL) {
      const q = queryParams.debouncedSearch || '';
      const id = creativeTypeId;
      let params = `searchField=${q}&creativeTypeIds=${id}`;
      if (queryParams.creativeGroupId) {
        params += `&creativeGroupId=${queryParams.creativeGroupId}`;
      }
      params += `&status=${getCreativeStatusParams(queryParams.status)}`;

      setUrl(`${ORIGIN_URL}/api/v2/crt/creative/list?${params}`);
    }
  }, [authorizedUser, creativeGroupId, debouncedSearch, selectedCreativeType, status]);

  const onChangeGroup = (newCreativeGroupId?: number) => {
    if (creativeGroupId !== newCreativeGroupId) {
      setPageNumber(1);
      setCreatives([]);
      setSelectedCreatives([]);
      setCreativeGroupId(newCreativeGroupId);
    }
  };

  const loadCreatives = React.useCallback(
    async (pageNumber: number) => {
      setPageNumber(1);
      setIsLoading(true);
      try {
        const { data } = await axios.get(`${url}&noOfEntries=50&pageNo=${pageNumber}`, {
          headers: getHeaders({ apiToken: authorizedUser.apiToken }, authorizedUser.userId),
        });

        setPageNumber(pageNumber);
        setCreatives(
          pageNumber !== 1
            ? [...(creatives as Creative[]), ...data.responseObject.data]
            : data.responseObject.data,
        );
        setFilteredRecords(data.responseObject.filteredRecords);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [authorizedUser.apiToken, authorizedUser.userId, creatives, url],
  );

  React.useEffect(() => {
    if (url !== prevUrlRef.current && !isTableView) {
      loadCreatives(1);
      prevUrlRef.current = url;
    }
  }, [isTableView, loadCreatives, url]);

  return (
    <Dialog
      open
      maxWidth="xxl"
      classes={{
        paper: sx({ height: 100 }),
      }}
      fullWidth
      onClose={() => setCreativeSelectDialogType && setCreativeSelectDialogType('')}
    >
      <DialogTitle>Select Creatives</DialogTitle>
      <DialogContent dividers sx={{ p: 0, pr: 8 }}>
        <Layout
          open={isSidebarOpen}
          sidebarVariant="permanent"
          // @ts-ignore
          toggleSidebar={() => setIsSidebarOpen((val) => !val)}
          hideResizerHandle
          invisibleResizerHandle
          sidebar={
            <CreativesGroupsList
              status={getCreativeStatusParams(status)}
              creatives={(creatives as Creative[]) || []}
              authorizedUser={authorizedUser}
              creativeTypeId={selectedCreativeType ?? 0}
              totalRecords={filteredRecords}
              onChangeGroup={onChangeGroup}
            />
          }
        >
          <Box sx={{ width: 100, display: 'flex', flexDirection: 'column', pl: 32, pr: 16 }}>
            <CreativesManagePanel
              status={status as TCreativeStatus}
              onChangeStatus={(val) => {
                setPageNumber(1);
                setLocalSelectedCreatives([]);
                setStatus(val);
              }}
              searchValue={searchField ?? ''}
              toggleView={() => {
                if (isTableView) {
                  loadCreatives(1);
                }
                setIsTableView((val) => !val);
              }}
              onSearch={(val) => {
                setPageNumber(1);
                setSearchField(val);
              }}
              isTableView={isTableView}
            />
            <SelectionActionPanel
              selectedCreatives={localSelectedCreatives}
              selectCreatives={setLocalSelectedCreatives}
            />
            {isTableView ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 100,
                  width: 100,
                  position: 'relative',
                }}
              >
                <CreativesTableView
                  url={authorizedUser && url ? url : ''}
                  creatives={creatives ?? []}
                  status={status}
                  creativeTypeId={selectedCreativeType as number}
                  onCreativesLoaded={(e) => {
                    setCreatives(e.creatives);
                    setFilteredRecords(e.filteredRecords);
                    setTotalRecords(e.totalRecords);
                    setPageNumber(e.pageNumber);
                  }}
                  onCreativesSelected={(val: Creative[], shouldClear?: any) => {
                    setLocalSelectedCreatives(val);
                  }}
                  emptyTableLabel={emptyListLabel}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 100,
                  width: 100,
                  position: 'relative',
                }}
              >
                <CreativesCardList
                  emptyListLabel={emptyListLabel}
                  pageNumber={pageNumber as number}
                  filteredRecords={filteredRecords}
                  loadCreatives={(page: number) => {
                    loadCreatives(page);
                  }}
                  creatives={creatives ?? []}
                  selectedCreatives={localSelectedCreatives}
                  isLoading={isLoading}
                  onCreativesSelected={(val) => {
                    setLocalSelectedCreatives(val);
                  }}
                />
              </Box>
            )}
          </Box>
        </Layout>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setCreativeSelectDialogType && setCreativeSelectDialogType('')}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            const allCreatives = [...(selectedCreatives ?? [])];
            localSelectedCreatives?.forEach((creative: Creative) => {
              if (!allCreatives?.find((oldCreative) => oldCreative.id === creative.id)) {
                allCreatives.push(creative);
              }
            });
            setSelectedCreatives(allCreatives);
            if (setCreativeSelectDialogType) {
              setCreativeSelectDialogType('');
            }
          }}
          disabled={!localSelectedCreatives?.length}
        >
          Select Creatives
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  authorizedUser: state.auth.userData,
});

export const SelectCreativeDialog = connect(mapState)(SelectCreativeDialogComponent);
