import { Box, Button, Col, DialogActions, Drawer, LoadingButton, Row } from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import * as React from 'react';

interface Props {
  anchorRef: React.MutableRefObject<any>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLoading: boolean;
  onClickStartNow: () => void;
  warningText?: string;
}

export const IOStartDateWarningDrawer = (props: Props) => {
  const { anchorRef, isOpen, setIsOpen, isLoading, onClickStartNow, warningText } = props;

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      onClose={() => setIsOpen(false)}
      variant="temporary"
      SlideProps={{
        appear: false,
      }}
      sx={{ position: 'absolute' }}
      PaperProps={{ sx: { position: 'absolute' } }}
      ModalProps={{
        container: anchorRef.current,
        slotProps: {
          backdrop: {
            sx: { position: 'absolute' },
          },
        },
      }}
    >
      <DialogActions>
        <Row>
          <Col xs={7}>
            <Box sx={{ mr: 8, display: 'flex' }}>
              <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400', mr: 8 }} />
              <Box>
                {warningText || (
                  <>
                    The start date for the IO has elapsed. Please change the start date or click on
                    Start IO Now to create the IO with the current date & time.
                  </>
                )}
              </Box>
            </Box>
          </Col>
          <Col xs={5} sx={{ display: 'flex', justifyContent: 'end', gap: 16 }}>
            <Button onClick={() => setIsOpen(false)} color="secondary" disabled={isLoading}>
              Change Start Date
            </Button>
            <LoadingButton onClick={onClickStartNow} loading={isLoading} color="primary">
              Start IO Now
            </LoadingButton>
          </Col>
        </Row>
      </DialogActions>
    </Drawer>
  );
};
