import * as React from 'react';
import moment from 'moment';
import { RowSelectionState } from '@applift/datagrid';
import { Col, MenuItem, TextField } from '@applift/factor';
import { Timezone } from 'components/Timezone';
import { TimezoneProvider, EpochDateRangePicker, OptionId } from 'iqm-framework';
import { CampaignSelect } from 'components/CampaignSelect';
import { CampaignCreativeCountType, CampaignStatusCountType } from 'models/Count';
import { WithResponse } from 'models/Response';
import { CampaignTypeResponseType } from 'models/CampaignList';
import { CAMPAIGN_STATUSES_ICONS, CREATIVES_TYPES_ICONS } from 'constants/iconMapper';
import { DateRange } from 'models/Date';
import { CampaignTypeSelect } from 'components/CampaignTypeSelect/CampaignTypeSelect';

import styles from './index.module.scss';

export interface CampaignListFilterPropTypes {
  ioId: number;
  statusFilter: CampaignStatusCountType['status_key'][];
  timezoneFilter?: OptionId<string>;
  selectedCampaign?: number[];
  campaignStatusCount?: CampaignStatusCountType[];
  campaignCreativeCount?: CampaignCreativeCountType[];
  campaignStatusCountLoading: boolean;
  campaignCreativeCountLoading: boolean;
  setTimezoneFilter: React.Dispatch<React.SetStateAction<OptionId<string> | undefined>>;
  creativeTypeFilter?: number[];
  setCreativeTypeFilter: React.Dispatch<React.SetStateAction<number[]>>;
  dateRange: DateRange | undefined;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  setStatusFilter: (arg: any) => void;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setSelectedCampaign: (arg: number[]) => void;
  selectedCampaignTypeId?: number[];
  setSelectedCampaignTypeId: (arg: number[] | undefined) => void;
  campaignTypes?: WithResponse<CampaignTypeResponseType>;
}

const preselected = [
  EpochDateRangePicker.PRESELECT_KEYS.TODAY,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_WEEK,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_MONTH,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_THREE_MONTHS,
];

const today = new Date();
const twoYearsAgo = new Date().setFullYear(today.getFullYear() - 2);

export const CampaignListFilters = (props: CampaignListFilterPropTypes) => {
  const {
    setStatusFilter,
    statusFilter,
    setTimezoneFilter,
    timezoneFilter,
    creativeTypeFilter,
    setCreativeTypeFilter,
    campaignStatusCountLoading,
    campaignStatusCount,
    campaignCreativeCountLoading,
    campaignCreativeCount,
    ioId,
    selectedCampaign,
    dateRange,
    setDateRange,
    setSelectedCampaign,
    setRowSelection,
    setSelectedCampaignTypeId,
    selectedCampaignTypeId,
    campaignTypes,
  } = props;

  const handleDateChange = ({ startDate, endDate }: { startDate: number; endDate: number }) => {
    const start = moment
      .tz(startDate, timezoneFilter?.value ?? '')
      .startOf('day')
      .valueOf();

    const end = moment
      .tz(endDate, timezoneFilter?.value ?? '')
      .endOf('day')
      .valueOf();

    setDateRange({ start: start as unknown as Date, end: end as unknown as Date });
  };

  return (
    <>
      <Col>
        <TextField
          variant="outlined"
          select
          SelectProps={{
            placeholder: 'Status',
            renderValue: (value) => {
              if (campaignStatusCountLoading) {
                return 'Loading...';
              }
              const currectSelection = campaignStatusCount?.find((val) => val.status_key === value);
              return `Status - ${currectSelection?.status_label} (${currectSelection?.status_count})`;
            },
          }}
          value={statusFilter[0]}
          sx={{ width: 100 }}
          onChange={(e) => {
            setStatusFilter([e.target.value]);
            setRowSelection({});
          }}
        >
          {campaignStatusCount
            ?.sort((a, b) => a.order - b.order)
            ?.map((val) => (
              // @ts-ignore
              <MenuItem startIcon={CAMPAIGN_STATUSES_ICONS[val.status_key]} value={val.status_key}>
                {val.status_label} ({val.status_count})
              </MenuItem>
            ))}
        </TextField>
      </Col>
      <Col>
        <TextField
          variant="outlined"
          select
          SelectProps={{
            multiple: true,
            placeholder: 'Creative Type',
            renderValue: (value: any) => {
              if (!value.length) {
                return undefined;
              }
              if (campaignCreativeCountLoading) {
                return 'Loading...';
              }
              const currectSelection = campaignCreativeCount?.filter((val) =>
                value.includes(val.creativeTypeId),
              );
              if (currectSelection?.length === campaignCreativeCount?.length) {
                return `Creative Type - All (${currectSelection?.reduce(
                  (arg, val) => arg + val.count,
                  0,
                )})`;
              }
              return `Creative Type - ${
                currectSelection?.map((val) => val.creativeType).join(', ') || ''
              }`;
            },
            MenuProps: {
              PaperProps: { sx: { height: 'auto' } },
            },
          }}
          value={creativeTypeFilter}
          sx={{ width: 100 }}
          onChange={(e) => {
            setCreativeTypeFilter(
              (e.target.value as unknown as Array<string>).map((val: string | number) => +val),
            );
            setRowSelection({});
          }}
        >
          {campaignCreativeCount?.map((val) => (
            <MenuItem
              // @ts-ignore
              startIcon={CREATIVES_TYPES_ICONS[val.creativeType]}
              value={val.creativeTypeId}
            >
              {val.creativeType} ({val.count})
            </MenuItem>
          ))}
        </TextField>
      </Col>
      <Col>
        <CampaignSelect
          ioId={ioId}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={(val) => {
            setSelectedCampaign(val);
            setRowSelection({});
          }}
          creativeTypeFilter={creativeTypeFilter}
          statusFilter={statusFilter}
          campaignStatusCount={campaignStatusCount}
        />
      </Col>
      <Col>
        <CampaignTypeSelect
          ioId={ioId}
          selectedCampaign={selectedCampaign}
          campaignTypes={campaignTypes}
          selectedCampaignTypeId={selectedCampaignTypeId}
          creativeTypeFilter={creativeTypeFilter}
          setSelectedCampaignTypeId={(val) => {
            setSelectedCampaignTypeId(val);
            setRowSelection({});
          }}
          statusFilter={statusFilter}
          campaignStatusCount={campaignStatusCount}
        />
      </Col>
      <Col>
        <Timezone
          variant="outlined"
          onChange={(val) => {
            setTimezoneFilter(val);
            setRowSelection({});
          }}
          value={timezoneFilter}
          key={timezoneFilter?.id}
        />
      </Col>
      <Col>
        {timezoneFilter && (
          <TimezoneProvider timezone={timezoneFilter}>
            <EpochDateRangePicker
              showLabelAlways={false}
              dateRange={{ startDate: dateRange?.start, endDate: dateRange?.end }}
              dateFormat="MM/DD/YYYY"
              onDateRangeChange={(val: { startDate: number; endDate: number }) => {
                handleDateChange(val);
                setRowSelection({});
              }}
              defaultDateRange={{ startDate: today, endDate: today }}
              datePickerProps={{
                numberOfCalendars: 2,
                singleDateRange: true,
                minimumDate: twoYearsAgo,
              }}
              preselected={preselected}
              insidePreselectors
              removeSafariSelectionIssue
              underline
              dateRangePickerClassName={styles.dateRangePicker}
              className="dateRangePickerIO"
            />
          </TimezoneProvider>
        )}
      </Col>
    </>
  );
};
