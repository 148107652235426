import React from 'react';
import { Icon, Chip } from 'factor';

import { PolygonExtended } from 'models/PolygonExtended';
import { Option } from 'models/Option';
import { GROUPED_TAGS_VALUE } from 'utils/figures';

import styles from '../index.module.scss';

const POLYGON = 'polygon';
const STYLE_INCLUDE = '_include';
// const STYLE_EXCLUDE = '_exclude';

interface RenderPolygonTags {
  polygons: PolygonExtended[];
  removeItemFromStore: (type: string) => (item: Option) => void;
  removeGroupFromStore: (type: string) => () => void;
  chipClassName?: string;
  readonly?: boolean;
}

export const PolygonTags = ({
  polygons,
  removeItemFromStore,
  removeGroupFromStore,
  chipClassName,
  readonly,
}: RenderPolygonTags) => {
  if (polygons.length > GROUPED_TAGS_VALUE) {
    return (
      <Chip
        item={{
          value: POLYGON,
          label: `${polygons.length} selected`,
        }}
        readonly={readonly}
        onRemove={removeGroupFromStore(POLYGON)}
        className={`${chipClassName || STYLE_INCLUDE} ${styles.chip}`}
        icon={<Icon name="Polygon" />}
      />
    );
  }
  return (
    <>
      {polygons.map((polData) => (
        <Chip
          key={polData.id}
          item={{
            value: polData.id,
            label: polData.address,
          }}
          readonly={readonly}
          onRemove={removeItemFromStore(POLYGON)}
          className={`${chipClassName || STYLE_INCLUDE} ${styles.chip}`}
          icon={<Icon name="Polygon" />}
        />
      ))}
    </>
  );
};
