import React from 'react';
import { ColumnDef } from '@applift/datagrid';
import { RawDataCell } from 'components/CellType';
import { InventoryGroupListResponse } from 'models/Inventory';
// @ts-ignore
import { InventoryGroupTypeCell } from '../../CellTypes/InventoryGroupTypeCell';
import { AllowedBlockedListCell } from '../../CellTypes/AllowedBlockedListCell';
import { FormattedNumberCell } from '../../CellTypes/FormattedNumberCell';
import { ActionCell } from '../../CellTypes/ActionCell';
import { CountCell } from '../../CellTypes/CountCell';

export const colDef: ColumnDef<InventoryGroupListResponse['inventoryGroupList'][0]>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorKey: 'name',
    cell: (props) => (
      <RawDataCell
        value={props.renderValue() as string}
        typographyProps={{
          sx: { textColor: props.row.original.isWhiteListed ? 'neutral-1000' : 'danger-500' },
        }}
      />
    ),
    enableSorting: false,
    size: 260,
  },
  {
    header: 'Type',
    id: 'groupTypeId',
    accessorKey: 'groupTypeId',
    cell: (props) => (
      <InventoryGroupTypeCell
        groupId={props.renderValue() as number}
        typographyProps={{
          sx: { textColor: props.row.original.isWhiteListed ? 'neutral-1000' : 'danger-500' },
        }}
      />
    ),
    enableSorting: false,
    size: 190,
  },
  {
    header: 'Count',
    id: 'inventories',
    accessorKey: 'count',
    cell: (props) => (
      <CountCell
        row={props.row.original as any}
        typographyProps={{
          sx: { textColor: props.row.original.isWhiteListed ? 'neutral-1000' : 'danger-500' },
        }}
      />
    ),
    enableSorting: false,
    size: 130,
  },
  {
    header: 'Impressions',
    id: 'impressions',
    accessorKey: 'impressions',
    cell: (props) => (
      <FormattedNumberCell
        value={props.renderValue() as number}
        typographyProps={{
          sx: { textColor: props.row.original.isWhiteListed ? 'neutral-1000' : 'danger-500' },
        }}
      />
    ),
    enableSorting: true,
    size: 130,
  },
  {
    header: 'Reach',
    id: 'reach',
    accessorKey: 'reach',
    cell: (props) => (
      <FormattedNumberCell
        value={props.renderValue() as number}
        typographyProps={{
          sx: { textColor: props.row.original.isWhiteListed ? 'neutral-1000' : 'danger-500' },
        }}
      />
    ),
    enableSorting: false,
    size: 130,
  },
  {
    header: 'Allowlist/Blocklist',
    accessorKey: 'isWhiteListed',
    id: 'allowListBlockList',
    cell: (props) => (
      <AllowedBlockedListCell
        isWhiteListed={props.renderValue() as boolean}
        onAction={props.onAction}
        // @ts-ignore
        row={props.row.original}
      />
    ),
    enableSorting: false,
    meta: {
      align: 'center',
    },
    size: 140,
  },
  {
    header: 'Actions',
    id: 'actions',
    cell: (props) => <ActionCell onAction={props.onAction} rowId={props.row.original.id} />,
    enableSorting: false,
    meta: {
      align: 'center',
    },
    size: 70,
  },
];
