import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Col,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Row,
  Typography,
} from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { filterLabels } from 'store/advancedTargeting/constants';
import { advancedTargetingActions } from 'store/advancedTargeting/actions';
import { AdvancedTargetingState } from 'store/advancedTargeting/reducer';
import { AppState } from 'models/Store';
import { connect, useDispatch } from 'react-redux';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { TextAreaWithCSVUpload } from './components/TextAreaWithCSVUpload';

export interface BlocklistDialogProps {
  open: boolean;
  closeDialog: () => any;
  editableCampaign: ExistingCampaignData | null;
  advancedTargeting: AdvancedTargetingState;
}

export const blockFields: (
  | 'blackListedPackageName'
  | 'blackListedAppId'
  | 'blackListedSiteDomain'
)[] = ['blackListedPackageName', 'blackListedAppId', 'blackListedSiteDomain'];

export const BlocklistDialogComponent = (props: BlocklistDialogProps) => {
  const { open, closeDialog, advancedTargeting } = props;

  const sideBarInfo = advancedTargeting.sidebarCampaignInfo;
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    blackListedPackageName: sideBarInfo.selectedBlackListedPackageName,
    blackListedAppId: sideBarInfo.selectedBlackListedAppId,
    blackListedSiteDomain: sideBarInfo.selectedBlackListedSiteDomain,
  });

  useEffect(() => {
    if (advancedTargeting.sidebarCampaignInfo) {
      blockFields.forEach((field) => {
        const val = advancedTargeting.sidebarCampaignInfo[CampaignInfoField[field]];
        if (val) {
          setValues((prev) => ({ ...prev, [field]: val }));
        }
      });
    }
  }, [advancedTargeting.sidebarCampaignInfo]);

  const handleAdd = () => {
    blockFields.forEach((field) => {
      dispatch(
        advancedTargetingActions.selectAdvancedTargetingField({
          key: CampaignInfoField[field],
          value: values[field],
        }),
      );
    });
    closeDialog();
  };

  const disable = React.useMemo(() => {
    const isError = Object.values(values).some(
      (val) => val.split(',').filter(Boolean).length > 500,
    );
    const hasChanges =
      values.blackListedPackageName !== sideBarInfo.selectedBlackListedPackageName ||
      values.blackListedAppId !== sideBarInfo.selectedBlackListedAppId ||
      values.blackListedSiteDomain !== sideBarInfo.selectedBlackListedSiteDomain;
    return isError || !hasChanges;
  }, [sideBarInfo, values]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle onClose={closeDialog}>Blocklist</DialogTitle>
      <DialogContent dividers sx={{ px: 24, py: 32 }}>
        <Row>
          <Col>
            <TextAreaWithCSVUpload
              label={filterLabels.blackListedPackageName}
              placeholder="Enter Android Package Names"
              tooltipMessage="List of the Android Package Names blocked for this campaign"
              value={values.blackListedPackageName}
              handleChange={(val) =>
                setValues((prev) => ({ ...prev, blackListedPackageName: val }))
              }
            />
          </Col>
          <Col>
            <TextAreaWithCSVUpload
              label={filterLabels.blackListedAppId}
              placeholder="Enter iOS apps IDs"
              tooltipMessage="List of the iOS Apps IDs blocked for this campaign"
              value={values.blackListedAppId}
              handleChange={(val) => setValues((prev) => ({ ...prev, blackListedAppId: val }))}
            />
          </Col>
          <Col>
            <TextAreaWithCSVUpload
              label={filterLabels.blackListedSiteDomain}
              placeholder="Enter Site Domains"
              tooltipMessage="List of the Site Domains blocked for this campaign"
              value={values.blackListedSiteDomain}
              handleChange={(val) => setValues((prev) => ({ ...prev, blackListedSiteDomain: val }))}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', width: 100, justifyContent: 'between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoCircle sx={{ textColor: 'neutral-400', mr: 8, fontSize: 24 }} />
            <Typography>
              The campaign app allows up to 500 records. Use Inventory app for more.
            </Typography>
          </Box>
          <Box>
            <Button color="secondary" onClick={closeDialog} sx={{ mr: 16 }}>
              Cancel
            </Button>
            <Button onClick={handleAdd} disabled={disable}>
              Add
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
  advancedTargeting: state.advancedTargeting,
});

export const BlocklistDialog = connect(mapState)(BlocklistDialogComponent);
