import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from '@applift/factor';
import * as React from 'react';

interface Props {
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const StartIONowDialog = (props: Props) => {
  const { isLoading, onClose, onConfirm } = props;

  return (
    <Dialog open>
      <DialogTitle>Start IO Now?</DialogTitle>
      <DialogContent>
        The start date for the IO has elapsed. Please change the start date or click on Start IO Now
        to update the IO with the current date & time.
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={isLoading} onClick={onClose}>
          Change Start Date
        </Button>
        <LoadingButton color="primary" loading={isLoading} onClick={onConfirm}>
          Start IO Now
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
