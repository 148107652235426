import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Table, Tumbler, Tooltip } from 'factor';
import upperFirst from 'lodash/upperFirst';

import { CurrencyFormat, numberFormat } from 'utils/format';
import { audienceIDMap, AudienceItem, audienceStatusIconMap } from 'models/Audience';
import { OptionId } from 'models/Option';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { SelectAudienceField, audienceActions } from 'store/audience/actions';
import { defaultAdvancePageState } from 'store/advance/reducer';
import { AUDIENCES_STATUS, SEGMENTED_AUDIENCE_TYPE_ID } from 'constants/audience';

import styles from './styles.module.scss';

interface Props extends SelectAudienceField {
  selectedAudiences: AudienceItem[];
  organizationTimezone?: null | number | OptionId;
}

function isMoreThanAYearOld(epoch: number) {
  const currentTime = new Date().getTime() / 1000;
  const oneYearInMs = 365 * 24 * 60 * 60;
  return currentTime - epoch >= oneYearInMs;
}

const AudiencesTableComponent = (props: Props) => {
  const { selectedAudiences, selectAudienceField, organizationTimezone } = props;

  const handleRemove = (item: AudienceItem) => {
    selectAudienceField({
      key: CampaignInfoField.audiences,
      value: selectedAudiences.filter((audience) => audience.value !== item.value),
    });
  };

  const onTumblerChange = (item: AudienceItem) => () => {
    const index = selectedAudiences.findIndex((aud) => aud.audienceId === item.audienceId);
    if (index !== -1) {
      const audience = selectedAudiences[index];
      if (audience.included) {
        delete audience.included;
        audience.excluded = true;
      } else {
        delete audience.excluded;
        audience.included = true;
      }
      const newAudiences = [
        ...selectedAudiences.slice(0, index),
        audience,
        ...selectedAudiences.slice(index + 1),
      ];
      selectAudienceField({
        key: CampaignInfoField.audiences,
        value: newAudiences,
      });
    }
  };

  const renderAllowlistBlocklistTooltip = () => (
    <Tooltip
      auto
      portal
      labelMaxWidth={260}
      label={
        <div className={styles.allowBlockTooltip}>
          <div className={styles.allowBlockTooltipInfo}>
            <div className={styles.allowBlockTooltipSubtitle}>
              <div className={`${styles.bubble} ${styles.bubbleAllowed}`} />
              Allowlist:
            </div>
            List of audiences targeted for this campaign
          </div>
          <div className={styles.allowBlockTooltipInfo}>
            <div className={styles.allowBlockTooltipSubtitle}>
              <div className={`${styles.bubble} ${styles.bubbleBlocked}`} />
              Blocklist:
            </div>
            List of audiences blocked for this campaign
          </div>
        </div>
      }
    >
      Allowlist/Blocklist
    </Tooltip>
  );

  const headerMapper = [
    ...(selectedAudiences.length > 1
      ? [
          {
            label: 'Relation',
            className: 'w-70-70',
          },
        ]
      : []),
    {
      label: 'ID',
      className: 'w-80-80',
    },
    {
      label: 'Audience Name',
      className: 'w-230-230',
    },
    {
      label: 'Created',
      className: 'w-100-100',
    },
    {
      label: 'Reach',
      className: 'w-100-100',
    },
    {
      label: 'Cost (CPM)',
      className: 'w-120-120',
    },
    {
      label: 'Status',
      className: 'w-100-200',
    },
    {
      label: renderAllowlistBlocklistTooltip(),
      className: 'w-100-200',
    },
    {
      label: 'Remove',
      className: 'w-75-75',
    },
  ];

  const bodyMapper = [
    ...(selectedAudiences.length > 1
      ? [
          {
            key: () => 'OR',
            className: `w-70-70 _left ${styles.relationValue}`,
            attachDataGroupAttribute: true,
          },
        ]
      : []),
    {
      key: (row: AudienceItem) => (
        <span className={row.excluded ? styles.audienceRowExcluded : ''}>{row.audienceId}</span>
      ),
      className: 'w-80-80 _left',
    },
    {
      key: (row: AudienceItem) => (
        <div className={styles.audienceRow}>
          <div className={styles.audienceRowContainer}>
            <span className={styles.audienceRowIcon}>
              {row.audienceTypeId ? <Icon name={audienceIDMap[row.audienceTypeId]} /> : null}
            </span>
            <Tooltip label={row.label} auto={false} portal>
              <div
                className={`${row.excluded ? styles.audienceRowExcluded : ''} ${
                  styles.audienceName
                }`}
              >
                {row.label}
              </div>
            </Tooltip>
          </div>
          {row.createdOn && isMoreThanAYearOld(row.createdOn) ? (
            <div className={styles.warningIcon}>
              <Tooltip
                label="This audience is older than 1 Year. It might not have the most latest reach data."
                className={styles.warningTooltip}
                labelMaxWidth={300}
                portal
              >
                <span className={styles.toolTipContent}>
                  <Icon name="WarningTriangle" className="_size-18" />
                </span>
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      className: `${styles.iconRow} w-230-230`,
    },
    {
      key: (row: AudienceItem) => {
        return (
          <span className={row.excluded ? styles.audienceRowExcluded : ''}>
            {row.createdOn ? (
              moment
                .unix(row.createdOn)
                .tz(
                  (organizationTimezone as OptionId)?.value ||
                    defaultAdvancePageState.sidebarCampaignInfo[CampaignInfoField.timezone].value,
                )
                .format('MM/DD/YYYY')
            ) : (
              <>&mdash;</>
            )}
          </span>
        );
      },
      className: 'w-100-100 _right',
    },
    {
      key: (row: AudienceItem) => {
        return (
          <span className={row.excluded ? styles.audienceRowExcluded : ''}>
            {row.uniques !== undefined ? numberFormat(row.uniques) : <>&mdash;</>}
          </span>
        );
      },
      className: 'w-100-100 _right',
    },
    {
      key: (row: AudienceItem) => (
        <span className={row.excluded ? styles.audienceRowExcluded : ''}>
          {row.dataCost &&
          row.status !== AUDIENCES_STATUS.PROCESSING &&
          row.status !== AUDIENCES_STATUS.PENDING ? (
            CurrencyFormat.format(row.dataCost)
          ) : (
            <>&mdash;</>
          )}
        </span>
      ),
      className: `${styles.dataCost} w-120-120`,
    },
    {
      key: (row: AudienceItem) => {
        const iconName = row.status ? audienceStatusIconMap[upperFirst(row.status)] : null;
        return (
          <div className={styles.audienceRow}>
            {iconName ? <Icon className={styles.audienceRowIcon} name={iconName} /> : null}
            <span
              className={`${styles.overflowEllipses} ${
                row.excluded ? styles.audienceRowExcluded : ''
              }`}
            >
              {upperFirst(row.statusDisplayName)}
            </span>
            {row.status?.toLowerCase() === AUDIENCES_STATUS.PROCESSING &&
            row.audienceTypeId === SEGMENTED_AUDIENCE_TYPE_ID ? (
              <Tooltip
                label="This audience will be ready in the next 24 hours"
                portal
                position="left"
                labelMaxWidth={200}
                className="mt-1"
              >
                <span className="ml-1">
                  <Icon name="Info" className="_size-16" />
                </span>
              </Tooltip>
            ) : null}
          </div>
        );
      },
      className: `${styles.iconRow} w-100-200`,
    },
    {
      key: (row: AudienceItem) => (
        <Tumbler
          on={!!row.included}
          onChange={onTumblerChange(row)}
          className={row.excluded ? styles.excludedTumbler : styles.includedTumbler}
        />
      ),
      className: `w-100-200 ${styles.tumblerRow}`,
    },
    {
      key: (row: AudienceItem) => (
        <button
          className={styles.audienceRowDelete}
          type="button"
          onClick={() => handleRemove(row)}
        >
          <Icon name="DeleteAlt" className={styles.audienceRowIcon} />
        </button>
      ),
      className: `w-75-75 ${styles.deleteIcon}`,
    },
  ];

  return (
    <Table
      key={selectedAudiences.length}
      header={headerMapper}
      body={bodyMapper}
      data={selectedAudiences}
      mergeSimilarCellsInColumn={selectedAudiences.length > 1}
      mergeCellProps={{
        addCellSpanningEntireRowAfterDataGroup: true,
        entireRowCellValues: [
          {
            value: 'AND',
            style: 'width: 55px; display: flex; justify-content: center; align-items: center;',
          },
        ],
      }}
      rowKeyExtractor={(row: AudienceItem) => row.audienceId}
      fixedheader
      offsetTop={0}
      enableContextMenu={false}
      className={styles.audiencesTable}
      tbodyRowHeight={32}
      theadRowHeight={32}
      windowFreeResizeEvent
      tableMaxHeight="18rem"
      fixedXScroller
      preventNavigationByScroll
      infiniteScroll
      freezeRows={0}
      skeletonRowsNumber={0}
      progressHeight={0}
    />
  );
};

const mapState = (state: AppState) => ({
  selectedAudiences: state.audience.sidebarCampaignInfo[CampaignInfoField.audiences],
  organizationTimezone: state.auth.organizationTimezoneInfo,
});

const mapAction = {
  selectAudienceField: audienceActions.selectAudienceField,
};

export const AudiencesTable = connect(mapState, mapAction)(AudiencesTableComponent);
