import * as List from './List';
import * as CampaignGroup from './CampaignGroup';
import * as Audience from './Audience';
import * as Inventory from './Inventory';
import * as Exchange from './Exchange';
import * as DealGroup from './DealGroup';
import * as Creatives from './Creatives';
import * as Campaign from './Campaign';
import * as Publishers from './Publishers';
import * as Advertiser from './Advertiser';
import * as CreativeGroup from './CreativeGroup';
import * as Location from './Location';
import * as Reach from './Reach';
import * as Estimator from './Estimator';
import * as Conversion from './Conversion';

export const API = {
  List,
  CampaignGroup,
  Audience,
  Inventory,
  Exchange,
  DealGroup,
  Creatives,
  Campaign,
  Publishers,
  Advertiser,
  CreativeGroup,
  Location,
  Reach,
  Estimator,
  Conversion,
};
