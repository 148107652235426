import * as React from 'react';
import moment from 'moment';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Col,
  Row,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';

import { OptionId } from 'models/Option';
import { getFutureTimestamp } from 'utils/date';
import { SingleDatePicker } from 'components/SingleDatePicker';
import { Timezone } from 'components/Timezone';
import { Timezone as TimezoneType } from 'models/Timezone';
import { CampaignListType } from 'models/CampaignList';
import { useTimezone } from 'hooks/useTimezone';
import { CAMPAIGN_STATUSES } from 'constants/campaignList';

import styles from './index.module.scss';

interface RunOrRestoreData {
  endDate?: number;
  campaignIds: string;
  status: 'draft' | 'running';
}

interface RunOrRestoreProps {
  selectedCampaigns: CampaignListType[];
  closeDialog: () => void;
  onCompletion: (data: RunOrRestoreData) => void;
  type: 'Run' | 'Restore';
  isLoading: boolean;
  organizationTimezoneId: number | undefined;
}

export const RunOrRestore = (props: RunOrRestoreProps) => {
  const { closeDialog, onCompletion, selectedCampaigns, type, isLoading, organizationTimezoneId } =
    props;

  const timezoneFetch = useTimezone();

  const [campaignEndDate, setCampaignEndDate] = React.useState<number | undefined>(undefined);
  const [selectedTimezone, updateTimeZone] = React.useState<OptionId<string> | undefined>(
    undefined,
  );

  const someExpiredCampaigns = selectedCampaigns.some(
    (item) => item.status === CAMPAIGN_STATUSES.EXPIRED,
  );
  const allPausedCampaigns = selectedCampaigns.every(
    (item) => item.status === CAMPAIGN_STATUSES.PAUSED,
  );

  React.useEffect(() => {
    if (timezoneFetch.data?.length && organizationTimezoneId) {
      const selectedTimezone = timezoneFetch.data?.find(
        (item) => item.name === selectedCampaigns[0]?.campaignTimezone,
      );
      if (
        selectedTimezone?.id &&
        selectedCampaigns.every(
          (item) => item.campaignTimezone === selectedCampaigns[0].campaignTimezone,
        )
      ) {
        updateTimeZone({
          id: selectedTimezone.id,
          label: selectedTimezone.label,
          value: selectedTimezone.name,
        });
        // prefill end date post 24 hours
      } else {
        const orgTimezone = timezoneFetch.data?.find(
          (item) => item.id === organizationTimezoneId,
        ) as TimezoneType;
        updateTimeZone({
          label: orgTimezone?.label || '',
          id: orgTimezone?.id || -1,
          value: orgTimezone?.name || '',
        });
      }
      if (type === 'Run' && someExpiredCampaigns) {
        setCampaignEndDate(
          moment
            .tz(selectedTimezone?.name || '')
            .add(24, 'hours')
            .valueOf(),
        );
      }
    }
  }, [timezoneFetch.data, selectedCampaigns, someExpiredCampaigns, type, organizationTimezoneId]);

  return (
    <Dialog fullWidth maxWidth="sm" open PaperProps={{ sx: { overflowY: 'visible' } }}>
      <DialogTitle onClose={closeDialog}>{type} Campaign</DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', overflowY: 'visible' }}
      >
        <Typography sx={{ mb: 16 }}>
          Enter the
          <Typography sx={{ textWeight: 'demi', mx: 4 }}>End Date</Typography>
          for the{' '}
          {selectedCampaigns.length === 1 && <Typography sx={{ mr: 2 }}>campaign</Typography>}
          <Typography sx={{ textWeight: 'demi', ml: 2, mr: 4 }}>
            {selectedCampaigns.length === 1
              ? `${selectedCampaigns[0].campaignName}.`
              : selectedCampaigns.length}
          </Typography>
          {selectedCampaigns.length > 1 && 'campaigns.'}
        </Typography>
        <Row>
          <Col xs={6}>
            <SingleDatePicker
              timezone={selectedTimezone}
              updateDate={(date: number) => {
                const futureEndDate = getFutureTimestamp(
                  date,
                  selectedTimezone?.value as string,
                  15,
                );
                // if selected time is less than 15 mins after current time, then set 15 mins+current time as value
                if (futureEndDate !== date && date) {
                  setCampaignEndDate(futureEndDate);
                  enqueueSnackbar(
                    'End time must be atleast 15 minutes in the future and was corrected',
                    {
                      variant: 'info',
                    },
                  );
                } else {
                  setCampaignEndDate(date);
                }
              }}
              date={campaignEndDate}
              label="End Date"
              placeholderText="Select End Date (Optional)"
              isClearable={type === 'Restore' || allPausedCampaigns}
              datePickerstyles={styles.datePicker}
            />
          </Col>
          <Col xs={6}>
            <Timezone
              label="Timezone"
              value={selectedTimezone}
              onChange={updateTimeZone}
              timezoneTextFieldProps={{ disabled: true }}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isLoading}
          onClick={() =>
            onCompletion({
              ...(campaignEndDate
                ? {
                    endDate: Math.round((campaignEndDate as number) / 1000),
                  }
                : {}),
              campaignIds: selectedCampaigns.map((campaign) => campaign.campaignId).toString(),
              status: type === 'Restore' ? 'draft' : 'running',
            })
          }
        >
          {type}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
