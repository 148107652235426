import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CollapsibleBlock } from 'factor';
import { Box, Typography, Button } from '@applift/factor';
import {
  Add,
  AppInventory,
  InventoryPmpDeals,
  InventoryGroups,
  ClipboardList,
} from '@applift/icons';

import { blockStyles } from 'components/Block';
import { ExcludedOption, Option } from 'models/Option';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { inventoryActions, SetInventoryField, SelectInventoryField } from 'store/inventory/actions';
import { InventoryState } from 'store/inventory/reducer';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { applicationActions } from 'store/app/actions';
import { useInventoryGroupsList, usePmpDealsList } from 'hooks/useInventory';
import { InventoryGroupListResponse, PmpDeal } from 'models/Inventory';
import { getPathNumber } from 'iqm-framework';
import { UINoteIcon } from 'components/UINoteIcon';
import { getTargetingTooltip } from 'utils/helpers';

import { ExchangesSelect } from './ExchangesSelect';
import { PublishersSelect } from './PublishersSelect';
import { AllowListButton, BlockListButton } from './AllowAndBlock';
import { ViewPmpDealTable, SelectPmpDealDialog } from './PmpDeals';
import { SelectInventoryGroupDialog, SelectedInventoryGroupTable } from './InventoryGroups';
import { AddPmpDealDialog } from './PmpDeals/PmpDealDialog/AddPmpDealDialog';

type Props = SetInventoryField &
  SelectInventoryField & {
    inventoryState: InventoryState;
    selectedInventoryGroupsFromStore: ExcludedOption[];
    selectedDealGroups: ExcludedOption[];
    selectedPublishers: Option[];
    publishers: Option[];
    isCollapseOpen?: boolean;
    onToggle?: (isOpened: boolean) => void;
    collapseMode?: CollapseMode;
  };

export const InventoryBlockComponent = (props: Props) => {
  const {
    collapseMode,
    isCollapseOpen,
    onToggle,
    selectedPublishers,
    selectedInventoryGroupsFromStore,
  } = props;

  const [inventoryType, setInventoryType] = React.useState<'inventoryGroup' | 'pmpDeals'>();
  const [pmpDealDialog, setPmpDealDialog] = React.useState(false);
  const [inventoryGrpDialog, setInventoryGrpDialog] = React.useState(false);
  const [addPmpDealDialog, setAddPmpDealDialog] = React.useState(false);

  const {
    app: { editableCampaign },
    advancedTargeting: {
      sidebarCampaignInfo: {
        selectedBlackListedAppId,
        selectedBlackListedPackageName,
        selectedBlackListedSiteDomain,
        selectedWhiteListedAppId,
        selectedWhiteListedPackageName,
        selectedWhiteListedSiteDomain,
        selectedExchanges,
      },
      exchanges,
    },
    publishers: { publishers },
  } = useSelector<AppState>((state) => state) as AppState;

  const whiteListedIds = editableCampaign?.whiteListedInventoryGroupIds;
  const blackListedIds = editableCampaign?.blackListedInventoryGroupIds;

  let initialIncludeExcludeMapping =
    whiteListedIds
      ?.split(',')
      .filter((i) => Boolean(i))
      .reduce((prev, curr) => {
        return { ...prev, [curr]: true };
      }, {}) ?? {};

  initialIncludeExcludeMapping =
    blackListedIds
      ?.split(',')
      .filter((i) => Boolean(i))
      .reduce((prev, curr) => {
        return { ...prev, [curr]: false };
      }, initialIncludeExcludeMapping) ?? initialIncludeExcludeMapping;

  const inventoryGrpIds = Object.keys(initialIncludeExcludeMapping).join(',');
  const { data, isLoading: isInventoryGrpLoading = false } = useInventoryGroupsList({
    ids: inventoryGrpIds,
  });
  const initialSelectedInventoryGroups = React.useMemo(() => {
    if (!inventoryGrpIds) {
      return [];
    }
    return (
      data?.pages?.reduce?.((prev, curr) => {
        return [
          ...prev,
          ...(curr.data?.inventoryGroupList?.map?.((grp) => {
            return {
              ...grp,
              isWhiteListed:
                initialIncludeExcludeMapping[grp.id as keyof typeof initialIncludeExcludeMapping],
            };
          }) ?? []),
        ];
      }, [] as InventoryGroupListResponse['inventoryGroupList']) ?? []
    );
    // eslint-disable-next-line
  }, [data, inventoryGrpIds]);

  const [selectedInventoryGroupsData, setSelectedInventoryGroupData] = React.useState(
    initialSelectedInventoryGroups,
  );

  useEffect(() => {
    if (initialIncludeExcludeMapping) {
      setSelectedInventoryGroupData(initialSelectedInventoryGroups);
    }
    // eslint-disable-next-line
  }, [initialSelectedInventoryGroups]);

  const dispatch = useDispatch();

  const pmpDealIds = editableCampaign?.pmpDealIds?.join(',') ?? '';
  const { data: pmpDealsData, isLoading: isPmpDealsLoading = false } = usePmpDealsList({
    ids: pmpDealIds,
  });
  const initialSelectedPmpDeals = React.useMemo(() => {
    if (!pmpDealIds) {
      return [];
    }
    return (
      pmpDealsData?.pages?.reduce?.((prev, curr) => {
        return [...prev, ...(curr.data?.pmpDealData ?? [])];
      }, [] as PmpDeal[]) ?? []
    );
    // eslint-disable-next-line
  }, [pmpDealsData, pmpDealIds]);

  const [selectedPmpDealsData, setSelectedPmpDealsData] = React.useState(initialSelectedPmpDeals);
  useEffect(() => {
    setSelectedPmpDealsData(initialSelectedPmpDeals);
    // eslint-disable-next-line
  }, [initialSelectedPmpDeals]);

  React.useEffect(() => {
    const selectedPmpDeals: Option<number>[] =
      selectedPmpDealsData?.map?.((deal) => {
        return {
          value: deal.id,
          label: deal?.id?.toString()!,
        };
      }) ?? [];
    dispatch(
      inventoryActions.selectInventoryField({
        key: CampaignInfoField.pmpDeals,
        value: selectedPmpDeals,
      }),
    );
  }, [selectedPmpDealsData, dispatch]);

  useEffect(() => {
    const selectedInventoryGroups: (Option<number> & {
      included?: boolean;
      excluded?: boolean;
    })[] =
      selectedInventoryGroupsData?.map?.((grp) => {
        return {
          value: Number(grp.id),
          label: grp?.id?.toString()!,
          ...(grp.isWhiteListed ? { included: true } : { excluded: true }),
        };
      }) ?? [];
    dispatch(
      inventoryActions.selectInventoryField({
        key: CampaignInfoField.inventoryGroups,
        value: selectedInventoryGroups,
      }),
    );
  }, [selectedInventoryGroupsData, dispatch]);

  const toSelectedStr = (val: any) => (val ? `${val} Selected` : '');

  const exchangesHeader = () => {
    const isAllSelected = exchanges?.every((exchange) => {
      return selectedExchanges?.some?.((obj) => String(obj?.value) === String(exchange?.value));
    });
    return isAllSelected ? toSelectedStr('All') : toSelectedStr(selectedExchanges.length);
  };

  const publisherCategoryHeader = () => {
    const isAllSelected = publishers.every((option) => {
      if (option.options) {
        return option.options?.every?.((option) => {
          return selectedPublishers.some((obj) => String(obj.value) === String(option.value));
        });
      }
      return selectedPublishers.some((obj) => String(obj.value) === String(option.value));
    });

    const groupIds = publishers.map((val) => val.value);
    const selectedPublishersCount = selectedPublishers.filter(
      (val) => !groupIds.includes(val.value),
    ).length;
    return isAllSelected ? toSelectedStr('All') : toSelectedStr(selectedPublishersCount);
  };

  const headerObject = {
    title: (
      <Box sx={{ display: 'flex', gapCol: 4, alignItems: 'center' }}>
        <ClipboardList fontSize={24} color="primary" />
        <Typography>Inventory</Typography>
      </Box>
    ),
    summary: {
      Exchanges: exchangesHeader(),
      'Publisher Category': publisherCategoryHeader(),
      'PMP Deals': toSelectedStr(selectedPmpDealsData.length),
      'Inventory Groups Allowlist': toSelectedStr(
        selectedInventoryGroupsFromStore.filter((i) => i.included).length,
      ),
      'Inventory Groups Blocklist': toSelectedStr(
        selectedInventoryGroupsFromStore.filter((i) => !i.included).length,
      ),
      'Android Package Name Allowlist': toSelectedStr(
        selectedWhiteListedPackageName.split(',').filter(Boolean).length,
      ),
      'Android Package Name Blocklist': toSelectedStr(
        selectedBlackListedPackageName.split(',').filter(Boolean).length,
      ),
      'iOS App IDs Allowlist': toSelectedStr(
        selectedWhiteListedAppId.split(',').filter(Boolean).length,
      ),
      'iOS App IDs Blocklist': toSelectedStr(
        selectedBlackListedAppId.split(',').filter(Boolean).length,
      ),
      'Site Domains Allowlist': toSelectedStr(
        selectedWhiteListedSiteDomain.split(',').filter(Boolean).length,
      ),
      'Site Domains Blocklist': toSelectedStr(
        selectedBlackListedSiteDomain.split(',').filter(Boolean).length,
      ),
    },
    keyIcon: {
      'Android Package Name Allowlist': (
        <UINoteIcon
          inline
          text={getTargetingTooltip('targeted', 'whiteListedPackageName')}
          marginLeft={0}
        />
      ),
      'Android Package Name Blocklist': (
        <UINoteIcon
          inline
          text={getTargetingTooltip('blocked', 'blackListedPackageName')}
          marginLeft={0}
        />
      ),
      'iOS App IDs Allowlist': (
        <UINoteIcon
          inline
          text={getTargetingTooltip('targeted', 'whiteListedAppId')}
          marginLeft={0}
        />
      ),
      'iOS App IDs Blocklist': (
        <UINoteIcon
          inline
          text={getTargetingTooltip('blocked', 'blackListedAppId')}
          marginLeft={0}
        />
      ),
      'Site Domains Allowlist': (
        <UINoteIcon
          inline
          text={getTargetingTooltip('targeted', 'whiteListedSiteDomain')}
          marginLeft={0}
        />
      ),
      'Site Domains Blocklist': (
        <UINoteIcon
          inline
          text={getTargetingTooltip('blocked', 'blackListedSiteDomain')}
          marginLeft={0}
        />
      ),
    },
  };

  return (
    <CollapsibleBlock
      collapsible={collapseMode !== ALL_EXPANDED}
      isCollapseOpen={isCollapseOpen}
      onToggle={onToggle}
      header={headerObject}
      customStyle={{
        block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
        panelHeaderExpand: blockStyles.panelHeaderExpand,
        panelHeaderCollapse: blockStyles.panelHeaderCollapse,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 75,
            gap: 24,
            px: 24,
            borderRight: 1,
            borderColor: 'neutral-200',
          }}
        >
          <Box>
            <Typography
              variant="bodyLarge"
              weight="demi"
              gutterBottom={false}
              sx={{ textColor: 'neutral-400' }}
            >
              What type of Inventory you want to add?
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 24 }}>
            <Button
              size="large"
              variant={inventoryType === 'inventoryGroup' ? 'outlined' : 'text'}
              startIcon={<InventoryGroups fontSize={32} color="primary" />}
              sx={{
                flexDirection: 'column',
                gap: 4,
                p: 16,
                bgColor: inventoryType === 'inventoryGroup' ? 'primary-50' : undefined,
                textColor: 'neutral-1000',
                textWeight: inventoryType === 'inventoryGroup' ? 'demi' : 'normal',
              }}
              onClick={() =>
                setInventoryType((prev) =>
                  prev !== 'inventoryGroup' ? 'inventoryGroup' : undefined,
                )
              }
            >
              Inventory Groups
            </Button>
            <Button
              variant={inventoryType === 'pmpDeals' ? 'outlined' : 'text'}
              size="large"
              startIcon={<InventoryPmpDeals fontSize={32} color="primary" />}
              sx={{
                flexDirection: 'column',
                gap: 4,
                p: 16,
                bgColor: inventoryType === 'pmpDeals' ? 'primary-50' : undefined,
                textColor: 'neutral-1000',
                textWeight: inventoryType === 'pmpDeals' ? 'demi' : 'normal',
              }}
              onClick={() =>
                setInventoryType((prev) => (prev !== 'pmpDeals' ? 'pmpDeals' : undefined))
              }
            >
              PMP Deals
            </Button>
          </Box>
          {inventoryType === 'inventoryGroup' && (
            <Box
              sx={{
                width: 100,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgColor: 'primary-50',
                borderRadius: 4,
                gap: 16,
                px: 32,
                py: 24,
              }}
            >
              <Typography
                variant="bodyMedium"
                sx={{ textColor: 'neutral-600', textAlign: 'center' }}
              >
                Select and add combination of Open Exchange, Contextual, and PMP inventory groups to
                organize your DSP inventory.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
                <Button
                  startIcon={<Add />}
                  onClick={() => setInventoryGrpDialog(true)}
                  sx={{ borderRadius: 2 }}
                >
                  Select Inventory Group
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<AppInventory />}
                  sx={{ borderRadius: 2 }}
                  onClick={() => {
                    window.open(`/inventory/u/${getPathNumber()}/#/groups?createGroup=true`);
                  }}
                >
                  Create Inventory Group
                </Button>
              </Box>
              {inventoryGrpDialog && (
                <SelectInventoryGroupDialog
                  open={inventoryGrpDialog}
                  {...{ selectedInventoryGroupsData, setSelectedInventoryGroupData }}
                  closeDialog={() => setInventoryGrpDialog(false)}
                />
              )}
            </Box>
          )}
          {inventoryType === 'pmpDeals' && (
            <Box
              sx={{
                width: 100,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgColor: 'primary-50',
                borderRadius: 4,
                gap: 16,
                px: 32,
                py: 24,
              }}
            >
              <Typography
                variant="bodyMedium"
                sx={{ textColor: 'neutral-600', textAlign: 'center' }}
              >
                Select and add Private Marketplace (PMP) Deals that provide exclusive, premium
                specifically tailored to your needs.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
                <Button
                  startIcon={<Add />}
                  sx={{ borderRadius: 2 }}
                  onClick={() => setPmpDealDialog(true)}
                >
                  Select Deals
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  sx={{ borderRadius: 2 }}
                  onClick={() => setAddPmpDealDialog(true)}
                >
                  Add Deal
                </Button>
              </Box>
              {pmpDealDialog && (
                <SelectPmpDealDialog
                  open={pmpDealDialog}
                  closeDialog={() => setPmpDealDialog(false)}
                  selectedPmpDealsData={selectedPmpDealsData}
                  setSelectedPmpDealsData={setSelectedPmpDealsData}
                />
              )}
              {addPmpDealDialog && (
                <AddPmpDealDialog
                  open={addPmpDealDialog}
                  closeDialog={() => setAddPmpDealDialog(false)}
                  setSelectedPmpDealsData={setSelectedPmpDealsData}
                />
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: 100, gap: 24 }}>
            {(selectedInventoryGroupsData.length > 0 || isInventoryGrpLoading) && (
              <SelectedInventoryGroupTable
                {...{
                  selectedInventoryGroupsData,
                  setSelectedInventoryGroupData,
                  isInventoryGrpLoading,
                }}
                isLoading={isInventoryGrpLoading}
              />
            )}
            {(selectedPmpDealsData.length > 0 || isPmpDealsLoading) && (
              <ViewPmpDealTable
                data={selectedPmpDealsData}
                setData={setSelectedPmpDealsData}
                loading={isPmpDealsLoading}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: 25,
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            padding: 24,
            alignItems: 'start',
          }}
        >
          <ExchangesSelect />
          <PublishersSelect />
          <AllowListButton />
          <BlockListButton />
        </Box>
      </Box>
    </CollapsibleBlock>
  );
};

const mapState = (state: AppState) => ({
  collapseMode: state.app.collapseData.collapseMode,
  auth: state.auth,
  submitted: state.app.submitted,
  editableCampaign: state.app.editableCampaign,
  errorCreating: state.app.errorCreating,
  inventoryState: state.inventory,
  selectedInventoryGroupsFromStore:
    state.inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups],
  selectedDealGroups: state.inventory.sidebarCampaignInfo[CampaignInfoField.dealGroups],
  selectedPublishers: state.publishers.selectedPublishers,
  publishers: state.publishers.publishers,
  advancedTargeting: state.advancedTargeting,
});

const mapAction = {
  resetError: applicationActions.resetError,
  setInventoryField: inventoryActions.setInventoryField,
  selectInventoryField: inventoryActions.selectInventoryField,
};

export const InventoryBlock = connect(mapState, mapAction)(InventoryBlockComponent);
