import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Icon, ProgressCircle } from 'factor';
import { uniqBy, has, capitalize } from 'lodash';
import { AppState } from 'models/Store';
import { Option } from 'models/Option';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { CircleExtended } from 'models/CircleExtended';
import { RectangleExtended } from 'models/RectangleExtended';
import { PolygonExtended, RadiusUnity } from 'models/PolygonExtended';
import {
  ICountryOption,
  IStateOption,
  RadiusUpdatedLocations,
  TLocationFileType,
} from 'models/Location';
import { filterFiguresByCountry, getBoundsFromPaths, getLatLng, TFigure } from 'utils/figures';
import {
  advanceActions,
  SetBlackList,
  SetCampaignSidebarInfo,
  SetWhiteList,
} from 'store/advance/actions';
import { ILocationFile } from 'store/location/reducer';
import {
  mapActions,
  RemoveCircleFromPreSaved,
  RemovePolygonFromPreSaved,
  RemoveRectangleFromPreSaved,
  UpdatePreSavedCircle,
  ClearPreSavedCircles,
  ClearPreSavedPolygons,
  ClearPreSavedRectangles,
  RemoveFileCircles,
  RemoveFilePolygons,
  UpdatePreSavedPolygon,
  UpdatePreSavedRectangle,
  setShapesRadiusLimitError,
  SetRadiusUpdatedLocation,
} from 'store/map/actions';
import { locationActions } from 'store/location/actions';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { API } from 'api';
import { metersToMiles } from 'utils/format';
import {
  blackListNameByTagName,
  shapeType,
  US_COUNTRY_ID,
  whiteListNameByTagName,
} from 'constants/location';
import { BlackLists, WhiteLists, Limits } from 'store/advance/reducer';
import { Accordion } from './Accordion';

import styles from './index.module.scss';
import { EditRadiusTextField } from './RadiusEditField';
import { ListItem } from './ListItem';

export type MapListedTagsNames =
  | 'dma'
  | 'districts'
  | 'senates'
  | 'houses'
  | 'cities'
  | 'counties'
  | 'zipcodes'
  | 'states';

export type ListedTagsNames = MapListedTagsNames | 'zipcodes' | 'files';

type Options = Option<number>[];

type LocationProps = {
  dma: Options;
  zipcodes: Options;
  districts: Options;
  senates: Options;
  houses: Options;
  states: Options;
  cities: Options;
  counties: Options;
};

const iconMapper: any = {
  addresses: 'Address',
  polygons: 'Polygon',
  locations: 'EventsLocation',
};

type Props = LocationProps &
  SetCampaignSidebarInfo &
  RemoveCircleFromPreSaved &
  RemovePolygonFromPreSaved &
  RemoveRectangleFromPreSaved &
  UpdatePreSavedCircle &
  UpdatePreSavedPolygon &
  UpdatePreSavedRectangle &
  ClearPreSavedCircles &
  ClearPreSavedPolygons &
  ClearPreSavedRectangles &
  RemoveFileCircles &
  RemoveFilePolygons &
  SetWhiteList &
  SetBlackList &
  SetRadiusUpdatedLocation &
  setShapesRadiusLimitError & {
    preSavedCircles: CircleExtended[];
    preSavedRectangles: RectangleExtended[];
    preSavedPolygons: PolygonExtended[];
    states: IStateOption[];
    country: ICountryOption | null;
    locationFiles: ILocationFile[];
    removeItemFromFile: (options: { fileId: number; itemId: number }) => void;
    removeFileData: (id: number) => void;
    dismissInvalidData: (id: number) => void;
    whiteLists: WhiteLists;
    blackLists: BlackLists;
    isReadOnly: boolean;
    containerRef?: HTMLElement;
    zipcodeLimitError?: string;
    shapesRadiusLimitErrorMsg?: string;
    limits?: Limits;
    radiusUpdatedLocations: RadiusUpdatedLocations;
    editableCampaign: ExistingCampaignData | null;
    selectedAreasIds: string[];
    filesBeingUploaded: TLocationFileType[];
    isOrBasedTargeting: boolean;
    hideToggle?: boolean;
  };

const TagsComponent = (props: Props) => {
  const {
    dma,
    districts,
    senates,
    houses,
    setCampaignSidebarInfo,
    preSavedCircles,
    preSavedPolygons,
    preSavedRectangles,
    removeCircleFromPreSaved,
    removePolygonFromPreSaved,
    removeRectangleFromPreSaved,
    updatePreSavedCircle,
    updatePreSavedPolygon,
    updatePreSavedRectangle,
    removeFileCircles,
    clearPreSavedCircles,
    clearPreSavedPolygons,
    clearPreSavedRectangles,
    states,
    cities,
    counties,
    country,
    locationFiles,
    removeItemFromFile,
    removeFileData,
    dismissInvalidData,
    removeFilePolygons,
    setWhiteList,
    setBlackList,
    whiteLists,
    blackLists,
    zipcodes,
    isReadOnly,
    containerRef,
    zipcodeLimitError,
    limits,
    setShapesRadiusLimitError,
    shapesRadiusLimitErrorMsg,
    setRadiusUpdatedLocations,
    radiusUpdatedLocations,
    editableCampaign,
    selectedAreasIds,
    filesBeingUploaded,
    isOrBasedTargeting,
    hideToggle,
  } = props;

  const getFilteredFigures = useCallback(
    <T extends TFigure>(figures: T[]) => {
      return filterFiguresByCountry(figures, country);
    },
    [country],
  );

  const removeHandler =
    (key: keyof LocationProps, isString: boolean = false) =>
    (item: Option<number>) => {
      const options = props[key];
      setCampaignSidebarInfo(
        CampaignInfoField[key],
        options.filter((o) => o.value !== item.value),
      );

      const newWhiteList = new Set(
        whiteLists[whiteListNameByTagName[key as ListedTagsNames]] || [],
      );
      const newBlackList = new Set(
        blackLists[blackListNameByTagName[key as ListedTagsNames]] || [],
      );
      if (isString) {
        newWhiteList.delete(item.value);
        newBlackList.delete(item.value);
      } else {
        newWhiteList.delete(Number(item.value));
        newBlackList.delete(Number(item.value));
      }
      setWhiteList(whiteListNameByTagName[key as ListedTagsNames], newWhiteList);
      setBlackList(blackListNameByTagName[key as ListedTagsNames], newBlackList);
    };

  const removePreselectedItemFromStore = useCallback(
    (type: string) => (item: Option) => {
      switch (type) {
        case shapeType.circle:
          const foundedCircles = preSavedCircles.find((i) => i.id === item.value);
          if (foundedCircles) {
            removeCircleFromPreSaved(foundedCircles);
          }
          break;
        case shapeType.polygon:
          const foundedPolygons = preSavedPolygons.find((i) => i.id === item.value);
          if (foundedPolygons) {
            removePolygonFromPreSaved(foundedPolygons);
          }
          break;
        case shapeType.rectangle:
          const foundedRectangles = preSavedRectangles.find((i) => i.id === item.value);
          if (foundedRectangles) {
            removeRectangleFromPreSaved(foundedRectangles);
          }
          break;
        default:
          break;
      }
    },
    [
      preSavedCircles,
      preSavedPolygons,
      preSavedRectangles,
      removeCircleFromPreSaved,
      removePolygonFromPreSaved,
      removeRectangleFromPreSaved,
    ],
  );

  const handleRemoveFileLocationItem = useCallback(
    (option: Option<string>, fileId: number) => {
      const foundLocaionFile = locationFiles.find((file) => file.fileId === fileId);
      removePreselectedItemFromStore(
        foundLocaionFile && foundLocaionFile.type === 'polygons'
          ? shapeType.polygon
          : shapeType.circle,
      )(option);
      const newWhiteList = new Set(
        whiteLists[whiteListNameByTagName['files' as ListedTagsNames]] || [],
      );
      const newBlackList = new Set(
        blackLists[blackListNameByTagName['files' as ListedTagsNames]] || [],
      );
      newWhiteList.delete(Number(option.value));
      newBlackList.delete(Number(option.value));
      setWhiteList(whiteListNameByTagName['files' as ListedTagsNames], newWhiteList);
      setBlackList(blackListNameByTagName['files' as ListedTagsNames], newBlackList);
      if (radiusUpdatedLocations && has(radiusUpdatedLocations, option.value.toString())) {
        const newUpdatedLocations = radiusUpdatedLocations;
        delete newUpdatedLocations[option.value.toString()];
        setRadiusUpdatedLocations(newUpdatedLocations);
      }
      if (
        foundLocaionFile &&
        foundLocaionFile.validLocations &&
        foundLocaionFile.validLocations.length > 1
      ) {
        removeItemFromFile({
          fileId,
          itemId: +option.value,
        });
      } else {
        removeFileData(fileId);
      }
    },
    [
      locationFiles,
      removeItemFromFile,
      removePreselectedItemFromStore,
      removeFileData,
      whiteLists,
      blackLists,
      radiusUpdatedLocations,
      setRadiusUpdatedLocations,
      setBlackList,
      setWhiteList,
    ],
  );

  const clearPreselectedItemFromStore = (type: string) => (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
    }

    switch (type) {
      case shapeType.circle:
        clearPreSavedCircles();
        break;
      case shapeType.polygon:
        clearPreSavedPolygons();
        break;
      case shapeType.rectangle:
        clearPreSavedRectangles();
        break;
      case 'cities':
        setCampaignSidebarInfo(CampaignInfoField.cities, []);
        setWhiteList(whiteListNameByTagName.cities, new Set([]));
        setBlackList(blackListNameByTagName.cities, new Set([]));
        break;
      case 'counties':
        setCampaignSidebarInfo(CampaignInfoField.counties, []);
        setWhiteList(whiteListNameByTagName.counties, new Set([]));
        setBlackList(blackListNameByTagName.counties, new Set([]));
        break;
      case 'dma':
        setCampaignSidebarInfo(CampaignInfoField.dma, []);
        setWhiteList(whiteListNameByTagName.dma, new Set([]));
        setBlackList(blackListNameByTagName.dma, new Set([]));
        break;
      case 'zipcodes':
        setCampaignSidebarInfo(CampaignInfoField.zipcodes, []);
        setWhiteList(whiteListNameByTagName.zipcodes, new Set([]));
        setBlackList(blackListNameByTagName.zipcodes, new Set([]));
        break;
      case 'districts':
        setCampaignSidebarInfo(CampaignInfoField.districts, []);
        setWhiteList(whiteListNameByTagName.districts, new Set([]));
        setBlackList(blackListNameByTagName.districts, new Set([]));
        break;
      case 'senates':
        setCampaignSidebarInfo(CampaignInfoField.senates, []);
        setWhiteList(whiteListNameByTagName.senates, new Set([]));
        setBlackList(blackListNameByTagName.senates, new Set([]));
        break;
      case 'houses':
        setCampaignSidebarInfo(CampaignInfoField.houses, []);
        setWhiteList(whiteListNameByTagName.houses, new Set([]));
        setBlackList(blackListNameByTagName.houses, new Set([]));
        break;
      case 'states':
        setCampaignSidebarInfo(CampaignInfoField.states, []);
        setWhiteList(whiteListNameByTagName.states, new Set([]));
        setBlackList(blackListNameByTagName.states, new Set([]));
        break;
      default:
        break;
    }
  };

  const checkIfDuplicated = useCallback(
    (isIncluded: boolean, stateAbb: string) => {
      if (!states.length) {
        return false;
      }
      const includedStateIds = new Set(whiteLists[whiteListNameByTagName.states] || []);
      return (
        isIncluded &&
        (states as IStateOption[])
          .filter((item) => includedStateIds.has(item.value))
          .map((state) => state?.abbreviation?.toUpperCase())
          .includes(stateAbb?.toUpperCase())
      );
    },
    [states, whiteLists],
  );

  const circlesWithoutFileInfo = useMemo(
    () => preSavedCircles.filter((circle) => !circle.fileId),
    [preSavedCircles],
  );

  const circles: Option<string>[] = useMemo(
    () =>
      getFilteredFigures(circlesWithoutFileInfo).map((circleData) => ({
        value: circleData.id,
        label: `${circleData.address || getLatLng(circleData)}, ${metersToMiles(
          +circleData.getRadius(),
        ).toFixed(2)}mi`,
        isInclude: circleData.isInclude,
        isDuplicateTargeting: checkIfDuplicated(circleData.isInclude, circleData.state),
        bounds: circleData.getBounds(),
        editableLabel: (
          <div className={styles.editableLabel}>
            <div className={styles.locationName}>
              {`${circleData.address || getLatLng(circleData)}`}
            </div>
            <div className={styles.radius}>
              <div>
                <EditRadiusTextField
                  circleData={circleData}
                  selectedAreasIds={selectedAreasIds}
                  updatePreSavedCircle={updatePreSavedCircle}
                />
              </div>
              <div>mi</div>
            </div>
          </div>
        ),
      })),
    [
      getFilteredFigures,
      circlesWithoutFileInfo,
      checkIfDuplicated,
      updatePreSavedCircle,
      selectedAreasIds,
    ],
  );

  const filesWithIgnorableErrors = useMemo(() => {
    if (editableCampaign?.locationDetails) {
      const ids = Object.values(editableCampaign.locationDetails)
        .filter((location) => location.fileName && location.invalidLocations?.length)
        .map((location) => +location.id);
      return new Set(ids);
    }
    return new Set<number>();
  }, [editableCampaign]);

  const locationsFromFiles = useMemo(
    () =>
      locationFiles.map((file) => ({
        name: file.filename,
        id: file.fileId,
        optionsWithBounds:
          file.validLocations?.map((data) => {
            let fallbackRadius = data.radius;
            let bounds = new google.maps.LatLngBounds();
            if (file.type === 'polygons') {
              const polygon = preSavedPolygons.find((one) => +(one.id ?? 0) === +data.locationId);
              if (polygon) {
                bounds = getBoundsFromPaths(polygon.getPaths());
              }
              if (data.radius === 0) {
                fallbackRadius = polygon?.getRadius(RadiusUnity.mile) ?? 0;
              }
            }
            if (file.type === 'addresses' || file.type === 'locations') {
              const preSavedCircle = preSavedCircles.find(
                (circle) => +(circle.id ?? 0) === +data.locationId,
              );
              if (preSavedCircle) {
                bounds = preSavedCircle.getBounds();
                fallbackRadius = +metersToMiles(preSavedCircle.getRadius()).toFixed(2);
              }
            }
            return {
              label: data.address
                ? `${data.address}, ${fallbackRadius}mi`
                : `Lat: ${data.latitude}, Long: ${data.longitude}, ${fallbackRadius}mi`,
              value: data.locationId.toString(),
              bounds,
              isDuplicateTargeting: checkIfDuplicated(
                data.isIncluded,
                data.stateShortName as string,
              ),
            };
          }) || [],
        invalidDataCount: filesWithIgnorableErrors.has(+file.fileId)
          ? undefined
          : file.invalidLocationsCount,
        type: file.type,
        iconName: {
          addresses: 'Address',
          locations: 'EventsLocation',
          polygons: 'Polygon',
        }[file.type],
        rowCount: file.rowCount,
      })),
    [locationFiles, preSavedPolygons, preSavedCircles, checkIfDuplicated, filesWithIgnorableErrors],
  );

  const polygons: Option<string>[] = useMemo(
    () =>
      getFilteredFigures(preSavedPolygons.filter((i) => !i.fileId)).map((polData) => ({
        value: polData.id,
        label: `${polData.address}, ${polData.getRadius(RadiusUnity.mile)}mi`,
        isInclude: polData.isInclude,
        bounds: getBoundsFromPaths(polData.getPaths()),
        isDuplicateTargeting: checkIfDuplicated(polData.isInclude, polData.state),
      })),
    [getFilteredFigures, preSavedPolygons, checkIfDuplicated],
  );

  const rectangles: Option<string>[] = useMemo(
    () =>
      getFilteredFigures(preSavedRectangles).map((rectData) => ({
        value: rectData.id,
        label: `${rectData.address}, ${rectData.getRadius(RadiusUnity.mile)}mi`,
        isInclude: rectData.isInclude,
        bounds: rectData.getBounds(),
        isDuplicateTargeting: checkIfDuplicated(rectData.isInclude, rectData.state),
      })),
    [getFilteredFigures, preSavedRectangles, checkIfDuplicated],
  );

  const handleDownloadInvalidData = useCallback((fileId: number, filename: string) => {
    API.Location.DownloadInvalidLocations({ fileId, filename });
  }, []);

  const handleDownloadValidData = useCallback(
    (fileId: number, filename: string, parentApp: string) => {
      API.Location.DownloadValidLocations({ fileId, filename, parentApp });
    },
    [],
  );

  const handleDismissInvalidData = useCallback(
    (fileId: number) => {
      API.Location.DismissInvalidLocations(fileId).then(() => dismissInvalidData(fileId));
    },
    [dismissInvalidData],
  );

  const getNewLists = (
    tagName: ListedTagsNames,
    isIncluded: boolean,
    id: number | string,
    isString: boolean = false,
  ) => {
    const newWhiteList = new Set<number | string>(
      whiteLists[whiteListNameByTagName[tagName]] || [],
    );
    const newBlackList = new Set<number | string>(
      blackLists[blackListNameByTagName[tagName]] || [],
    );
    let formatedId: number | string;
    if (isString) {
      formatedId = id;
    } else {
      formatedId = Number(id);
    }

    if (isIncluded) {
      newWhiteList.add(formatedId as any);
      newBlackList.delete(formatedId as any);
    } else {
      newWhiteList.delete(formatedId as any);
      newBlackList.add(formatedId as any);
    }
    return {
      whiteList: new Set(newWhiteList),
      blackList: new Set(newBlackList),
    };
  };

  const handleTumbler =
    (tagName: ListedTagsNames | 'circles' | 'polygons' | 'rectangles') =>
    (id: number | string, isInclude: boolean) => {
      switch (tagName) {
        case 'circles':
          const circle = preSavedCircles.find((i) => i.id === id);
          if (circle) {
            circle.setIsInclude(isInclude);
            updatePreSavedCircle(circle);
          }
          break;
        case 'polygons':
          const polygon = preSavedPolygons.find((i) => i.id === id);
          if (polygon) {
            polygon.setIsInclude(isInclude);
            updatePreSavedPolygon(polygon);
          }
          break;
        case 'rectangles':
          const rectangle = preSavedRectangles.find((i) => i.id === id);
          if (rectangle) {
            rectangle.setIsInclude(isInclude);
            updatePreSavedRectangle(rectangle);
          }
          break;
        case 'zipcodes': {
          const newLists = getNewLists(tagName, isInclude, id, true);
          setWhiteList(whiteListNameByTagName[tagName], newLists.whiteList);
          setBlackList(blackListNameByTagName[tagName], newLists.blackList);
          break;
        }
        default: {
          const newLists = getNewLists(tagName, isInclude, id);
          setWhiteList(whiteListNameByTagName[tagName], newLists.whiteList);
          setBlackList(blackListNameByTagName[tagName], newLists.blackList);
          break;
        }
      }
    };

  useEffect(() => {
    // @ts-ignore
    const ro = new window.ResizeObserver((entries) => {
      const firstEntry = entries[0];
      const child = firstEntry.target.querySelector('[data-location-section]');
      const maxHeight =
        firstEntry.target.getBoundingClientRect().bottom -
        (child?.getBoundingClientRect()?.top ?? firstEntry.target.getBoundingClientRect().bottom);
      if (maxHeight > 0) {
        child?.setAttribute(
          'style',
          editableCampaign?.parentApp === 'customers' || editableCampaign?.parentApp === 'bidModel'
            ? `max-height:${maxHeight}px;`
            : `max-height:${maxHeight}px;overflow:auto;margin-left:-16px;margin-right:-16px;padding-left:16px;padding-right:16px`,
        );
      }
    });
    if (containerRef) {
      ro.observe(containerRef);
    }
    return () => {
      ro.disconnect();
    };
  }, [containerRef, editableCampaign?.parentApp]);

  useEffect(() => {
    if (limits?.customAreas) {
      let total = 0;
      total = preSavedCircles.reduce<number>((prev, current) => {
        const radius = metersToMiles(+current.getRadius());
        return prev + radius;
      }, total);

      total = [...preSavedPolygons, ...preSavedRectangles].reduce<number>((prev, current) => {
        const radius = current.getRadius(RadiusUnity.mile);
        return prev + radius;
      }, total);

      if (total > limits.customAreas) {
        setShapesRadiusLimitError(
          `The total custom area can’t be greater than ${limits.customAreas} Sq. Miles. It includes Circles, Polygons, Rectangles, 
          Locations from files, and Addresses. Please adjust the locations or remove a few.`,
        );
      } else {
        setShapesRadiusLimitError('');
      }
    }
  }, [preSavedCircles, preSavedPolygons, preSavedRectangles, setShapesRadiusLimitError, limits]);

  const allItems = [
    ...dma,
    ...zipcodes,
    ...districts,
    ...senates,
    ...houses,
    ...circles,
    ...polygons,
    ...rectangles,
    ...locationsFromFiles,
    ...cities,
    ...counties,
    ...states,
  ];

  return (
    <div className={styles.tagsContainer}>
      <div className={styles.listHeader}>List of Targeted Locations</div>
      {allItems.length ? (
        <div className={styles.infoDiv}>
          <Icon name="Info" className="mr-1" />
          <div>{`The added locations in the list have the ${
            isOrBasedTargeting ? 'OR' : 'AND'
          } relationship among them`}</div>
        </div>
      ) : null}
      <div data-location-section="tags" className={styles.listContent}>
        {filesBeingUploaded.map((item) => (
          <div className={styles.loader}>
            <div className={styles.title}>
              <Icon name={iconMapper[item]} className="mr-2 ml-2" />
              {capitalize(item)}
            </div>
            <div className={styles.message}>
              <ProgressCircle className="mr-2" size={25} />
              <div>{`${capitalize(item)} file uploading`}</div>
            </div>
          </div>
        ))}
        {allItems.length ? (
          <ul className={styles.locationsList}>
            {states.length > 0 && (
              <Accordion
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterState"
                title="States"
                category="states"
                items={states}
                handleTumbler={handleTumbler('states')}
                handleRemove={removeHandler('states')}
                whiteList={new Set(whiteLists[whiteListNameByTagName.states])}
                handleRemoveSection={clearPreselectedItemFromStore('states')}
                isReadOnly={isReadOnly}
                hideToggle={hideToggle}
              />
            )}
            {dma.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterDma"
                title="DMA"
                category="dma"
                items={dma}
                handleRemove={removeHandler('dma')}
                handleRemoveSection={clearPreselectedItemFromStore('dma')}
                handleTumbler={handleTumbler('dma')}
                whiteList={new Set(whiteLists[whiteListNameByTagName.dma])}
                blackList={new Set(blackLists[blackListNameByTagName.dma])}
                isReadOnly={isReadOnly}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {counties.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterCounty"
                title="Counties"
                category="counties"
                items={counties}
                handleRemove={removeHandler('counties')}
                handleRemoveSection={clearPreselectedItemFromStore('counties')}
                handleTumbler={handleTumbler('counties')}
                whiteList={new Set(whiteLists.whiteListedCountyIds)}
                blackList={new Set(blackLists.blackListedCountyIds)}
                isReadOnly={isReadOnly}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {cities.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterCity"
                title="Cities"
                category="cities"
                items={cities}
                handleRemove={removeHandler('cities')}
                handleRemoveSection={clearPreselectedItemFromStore('cities')}
                handleTumbler={handleTumbler('cities')}
                whiteList={new Set(whiteLists.whiteListedCityIds)}
                blackList={new Set(blackLists.blackListedCityIds)}
                isReadOnly={isReadOnly}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {districts.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterCongress"
                title="Congressional Districts"
                category="districts"
                items={districts}
                handleRemove={removeHandler('districts')}
                handleRemoveSection={clearPreselectedItemFromStore('districts')}
                handleTumbler={handleTumbler('districts')}
                whiteList={new Set(whiteLists[whiteListNameByTagName.districts])}
                blackList={new Set(blackLists[blackListNameByTagName.districts])}
                isReadOnly={isReadOnly}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {senates.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterSenate"
                title="State Senates"
                category="senates"
                items={senates}
                handleRemove={removeHandler('senates')}
                handleRemoveSection={clearPreselectedItemFromStore('senates')}
                handleTumbler={handleTumbler('senates')}
                whiteList={new Set(whiteLists[whiteListNameByTagName.senates])}
                blackList={new Set(blackLists[blackListNameByTagName.senates])}
                isReadOnly={isReadOnly}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {houses.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterHouse"
                title="State Houses"
                category="houses"
                items={houses}
                handleRemove={removeHandler('houses')}
                handleRemoveSection={clearPreselectedItemFromStore('houses')}
                handleTumbler={handleTumbler('houses')}
                whiteList={new Set(whiteLists[whiteListNameByTagName.houses])}
                blackList={new Set(blackLists[blackListNameByTagName.houses])}
                isReadOnly={isReadOnly}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {zipcodes.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="FilterZip"
                category={country?.value === US_COUNTRY_ID ? 'zipcodes' : 'postalcodes'}
                title={`${country?.value === US_COUNTRY_ID ? 'Zip Codes' : 'Postal Codes'}`}
                items={uniqBy(zipcodes, 'value')}
                handleRemove={removeHandler('zipcodes', true)}
                handleRemoveSection={clearPreselectedItemFromStore('zipcodes')}
                handleTumbler={handleTumbler('zipcodes')}
                whiteList={new Set(whiteLists[whiteListNameByTagName.zipcodes])}
                blackList={new Set(blackLists[blackListNameByTagName.zipcodes])}
                isString
                isReadOnly={isReadOnly}
                errorMsg={zipcodeLimitError}
                showDuplicateTargetingError={isOrBasedTargeting}
                hideToggle={hideToggle}
              />
            )}
            {locationsFromFiles.map((data) =>
              (data.rowCount ?? data.optionsWithBounds.length) <= 1000 ? (
                <Accordion
                  selectedStates={states}
                  blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                  scrollBarWidth={editableCampaign?.scrollBarWidth}
                  key={data.id}
                  iconName={data.iconName}
                  category={data.type}
                  title={data.name}
                  items={data.optionsWithBounds}
                  invalidDataCount={data.invalidDataCount}
                  downloadInvalidData={() => handleDownloadInvalidData(data.id, data.name)}
                  handleRemove={(option) => handleRemoveFileLocationItem(option, data.id)}
                  showDuplicateTargetingError={isOrBasedTargeting}
                  isFile
                  handleRemoveSection={() => {
                    const newWhiteList = new Set(
                      whiteLists[whiteListNameByTagName['files' as ListedTagsNames]] || [],
                    );
                    const newBlackList = new Set(
                      blackLists[blackListNameByTagName['files' as ListedTagsNames]] || [],
                    );
                    data.optionsWithBounds.forEach((option) => {
                      newWhiteList.delete(Number(option.value));
                      newBlackList.delete(Number(option.value));
                      if (
                        radiusUpdatedLocations &&
                        has(radiusUpdatedLocations, option.value.toString())
                      ) {
                        const newUpdatedLocations = radiusUpdatedLocations;
                        delete newUpdatedLocations[option.value.toString()];
                        setRadiusUpdatedLocations(newUpdatedLocations);
                      }
                    });
                    setWhiteList(whiteListNameByTagName['files' as ListedTagsNames], newWhiteList);
                    setBlackList(blackListNameByTagName['files' as ListedTagsNames], newBlackList);
                    if (data.type === 'polygons') {
                      removeFilePolygons(data.id);
                    } else {
                      removeFileCircles(data.id);
                    }
                    removeFileData(data.id);
                  }}
                  dismissInvalidData={() => handleDismissInvalidData(data.id)}
                  handleTumbler={handleTumbler('files')}
                  whiteList={new Set(whiteLists[whiteListNameByTagName.files])}
                  blackList={new Set(blackLists[blackListNameByTagName.files])}
                  isReadOnly={isReadOnly}
                  errorMsg={shapesRadiusLimitErrorMsg}
                />
              ) : (
                <ListItem
                  errorMsg={shapesRadiusLimitErrorMsg}
                  iconName={data.iconName}
                  title={data.name}
                  items={data.optionsWithBounds}
                  invalidDataCount={data.invalidDataCount}
                  isReadOnly={isReadOnly}
                  parentApp={editableCampaign?.parentApp}
                  dismissInvalidData={() => handleDismissInvalidData(data.id)}
                  downloadInvalidData={() => handleDownloadInvalidData(data.id, data.name)}
                  rowCount={data.rowCount}
                  isFile
                  downloadValidData={() =>
                    handleDownloadValidData(data.id, data.name, editableCampaign?.parentApp!)
                  }
                  handleRemoveSection={() => {
                    const newWhiteList = new Set(
                      whiteLists[whiteListNameByTagName['files' as ListedTagsNames]] || [],
                    );
                    const newBlackList = new Set(
                      blackLists[blackListNameByTagName['files' as ListedTagsNames]] || [],
                    );
                    data.optionsWithBounds.forEach((option) => {
                      newWhiteList.delete(Number(option.value));
                      newBlackList.delete(Number(option.value));
                      if (
                        radiusUpdatedLocations &&
                        has(radiusUpdatedLocations, option.value.toString())
                      ) {
                        const newUpdatedLocations = radiusUpdatedLocations;
                        delete newUpdatedLocations[option.value.toString()];
                        setRadiusUpdatedLocations(newUpdatedLocations);
                      }
                    });
                    setWhiteList(whiteListNameByTagName['files' as ListedTagsNames], newWhiteList);
                    setBlackList(blackListNameByTagName['files' as ListedTagsNames], newBlackList);
                    if (data.type === 'polygons') {
                      removeFilePolygons(data.id);
                    } else {
                      removeFileCircles(data.id);
                    }
                    removeFileData(data.id);
                  }}
                />
              ),
            )}
            {circles.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="Radius"
                title="Custom Area Circles"
                category="circles"
                items={circles}
                handleRemove={removePreselectedItemFromStore(shapeType.circle)}
                handleRemoveSection={clearPreselectedItemFromStore(shapeType.circle)}
                handleTumbler={handleTumbler('circles')}
                isReadOnly={isReadOnly}
                errorMsg={shapesRadiusLimitErrorMsg}
                showDuplicateTargetingError={isOrBasedTargeting}
              />
            )}
            {polygons.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="Polygon"
                category="polygons"
                title="Polygons"
                items={polygons}
                handleRemove={removePreselectedItemFromStore(shapeType.polygon)}
                handleRemoveSection={clearPreselectedItemFromStore(shapeType.polygon)}
                handleTumbler={handleTumbler('polygons')}
                isReadOnly={isReadOnly}
                errorMsg={shapesRadiusLimitErrorMsg}
                showDuplicateTargetingError={isOrBasedTargeting}
              />
            )}
            {rectangles.length > 0 && (
              <Accordion
                selectedStates={states}
                blackListedStateIds={new Set(blackLists[blackListNameByTagName.states])}
                scrollBarWidth={editableCampaign?.scrollBarWidth}
                iconName="Square"
                title="Rectangles"
                category="rectangles"
                items={rectangles}
                handleRemove={removePreselectedItemFromStore(shapeType.rectangle)}
                handleRemoveSection={clearPreselectedItemFromStore(shapeType.rectangle)}
                handleTumbler={handleTumbler('rectangles')}
                isReadOnly={isReadOnly}
                errorMsg={shapesRadiusLimitErrorMsg}
                showDuplicateTargetingError={isOrBasedTargeting}
              />
            )}
          </ul>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <Icon name="CampaignsEmptyLocations" className={styles.emptyLocationsIcon} />
            <div className={styles.emptyTextHeader}>Add locations to your campaign</div>
            <div className={styles.emptyText}>
              Add the locations where you want your campaigns to be published. <br />
              You can customize the area and add custom data files for location targeting.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  states: state.advanced.sidebarCampaignInfo[CampaignInfoField.states],
  cities: state.advanced.sidebarCampaignInfo[CampaignInfoField.cities],
  counties: state.advanced.sidebarCampaignInfo[CampaignInfoField.counties],
  dma: state.advanced.sidebarCampaignInfo[CampaignInfoField.dma],
  zipcodes: state.advanced.sidebarCampaignInfo[CampaignInfoField.zipcodes],
  districts: state.advanced.sidebarCampaignInfo[CampaignInfoField.districts],
  senates: state.advanced.sidebarCampaignInfo[CampaignInfoField.senates],
  houses: state.advanced.sidebarCampaignInfo[CampaignInfoField.houses],
  zipcodeLimitError: state.advanced.zipcodeLimitError,
  preSavedCircles: state.map.preSavedCircles,
  preSavedPolygons: state.map.preSavedPolygons,
  preSavedRectangles: state.map.preSavedRectangles,
  locationFiles: state.location.locationFiles,
  whiteLists: state.advanced.whiteLists,
  blackLists: state.advanced.blackLists,
  isReadOnly: state.map.isMapReadOnly,
  limits: state.advanced.limits,
  shapesRadiusLimitErrorMsg: state.map.shapesRadiusLimitErrorMsg,
  radiusUpdatedLocations: state.map.radiusUpdatedLocations,
  editableCampaign: state.app.editableCampaign,
  selectedAreasIds: state.map.selectedAreasIds,
  filesBeingUploaded: state.location.filesBeingUploaded,
  isOrBasedTargeting: state.location.isLocationWithOrFilter,
});

const actions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  removeCircleFromPreSaved: mapActions.removeCircleFromPreSaved,
  removePolygonFromPreSaved: mapActions.removePolygonFromPreSaved,
  removeRectangleFromPreSaved: mapActions.removeRectangleFromPreSaved,
  clearPreSavedCircles: mapActions.clearPreSavedCircles,
  clearPreSavedPolygons: mapActions.clearPreSavedPolygons,
  clearPreSavedRectangles: mapActions.clearPreSavedRectangles,
  removeFileCircles: mapActions.removeFileCircles,
  removeFilePolygons: mapActions.removeFilePolygons,
  removeItemFromFile: locationActions.removeItemFromFile,
  removeFileData: locationActions.removeFileData,
  dismissInvalidData: locationActions.dismissInvalidData,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  updatePreSavedCircle: mapActions.updatePreSavedCircle,
  updatePreSavedPolygon: mapActions.updatePreSavedPolygon,
  updatePreSavedRectangle: mapActions.updatePreSavedRectangle,
  setShapesRadiusLimitError: mapActions.setShapesRadiusLimitError,
  setRadiusUpdatedLocations: mapActions.setRadiusUpdatedLocations,
};

export const Tags = connect(mapState, actions)(TagsComponent);
