import React from 'react';
import { connect } from 'react-redux';
import { DataRequestProps, TableBaseRoot } from 'iqm-framework';

import { User } from 'models/User';
import { AppState } from 'models/Store';
import { Creative } from 'models/Creative';
import styles from './CreativesTableView.module.scss';
import { Column } from './CreativesTableViewTypes';
// eslint-disable-next-line import/no-cycle
import CreativeTable from './creativeTable/CreativeTable';
// eslint-disable-next-line import/no-cycle
import { TCreativesPage } from '..';
import { TCreativeStatus } from '../creativesManagePanel/creativeStatusesCount/CreativeStatusesCount';

type TProps = {
  url: string;
  status?: TCreativeStatus;
  creatives: Creative[];
  authorizedUser: User;
  emptyTableLabel: string;
  creativeTypeId: number;
  onCreativesLoaded: (creativesPage: TCreativesPage) => void;
  onCreativesSelected: (selectedCreatives: Creative[]) => void;
};

type TState = {
  dataRequest: DataRequestProps;
  allColumns: Array<Column>;
  selectedColumns: Array<Column>;
};

export const columns = [
  { value: 'id', label: 'ID', alwaysEnabled: true },
  { value: 'creativeSource', label: 'Preview', alwaysEnabled: true },
  { value: 'name', label: 'Name' },
  { value: 'status', label: 'Status' },
  { value: 'clickUrl', label: 'Click Url' },
  { value: 'pixelUrl', label: 'Pixel Url' },
  { value: 'created', label: 'Created' },
  { value: 'size', label: 'Size' },
  { value: 'campaignCount', label: 'Part Of' },
];

class CreativesTableView extends React.Component<TProps, TState> {
  static getDerivedStateFromProps(nextProps: TProps, prevState: TState) {
    if (nextProps.url !== prevState.dataRequest.url) {
      return {
        dataRequest: {
          ...prevState.dataRequest,
          url: nextProps.url,
        },
      };
    }

    return null;
  }

  constructor(props: TProps) {
    super(props);

    const { url } = props;

    this.state = {
      allColumns: columns,
      selectedColumns: columns,

      dataRequest: {
        method: 'get',
        data: {},
        params: {},
        url,
      },
    };
  }

  updateSelectedColumns = (selectedColumns: Array<Column>) => {
    this.setState({
      selectedColumns,
    });
  };

  render() {
    const { allColumns, selectedColumns, dataRequest } = this.state;
    const {
      authorizedUser,
      creatives,
      url,
      onCreativesLoaded,
      onCreativesSelected,
      emptyTableLabel,
    } = this.props;

    if (!authorizedUser || !url) {
      return null;
    }

    return (
      <TableBaseRoot
        allColumns={allColumns}
        selectedColumns={selectedColumns}
        onColumnsChanged={this.updateSelectedColumns}
        dataRequest={dataRequest}
      >
        <div className={styles.container}>
          <div className={styles.table}>
            <CreativeTable
              creatives={creatives}
              onCreativesLoaded={onCreativesLoaded}
              onCreativesSelected={onCreativesSelected}
              emptyTableLabel={emptyTableLabel}
            />
          </div>
        </div>
      </TableBaseRoot>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  authorizedUser: state.auth.userData,
});

// const mapDispatchToProps = (dispatch: Dispatch) => ({
// });

export default connect(mapStateToProps)(CreativesTableView);
