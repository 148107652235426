import { Box, Link, Typography } from '@applift/factor';
import React from 'react';

interface PacingInfoTooltipProps {
  linkHref?: string;
  title: string;
}

export const PacingInfoTooltip = (props: PacingInfoTooltipProps) => {
  const { linkHref, title } = props;
  return (
    <Box>
      <Box sx={{ bgColor: 'neutral-75', paddingY: 12, paddingX: 16 }}>
        <Typography weight="demi">{title}</Typography>
      </Box>
      <Box sx={{ paddingY: 12, paddingX: 16 }}>
        <Typography component="p" variant="label" sx={{ mb: 8 }}>
          <Typography component="span" weight="demi">
            Number%
          </Typography>{' '}
          signifies the actual budget{' '}
          <Typography component="span" weight="demi">
            spent%
          </Typography>{' '}
          .
        </Typography>
        <Typography component="p" variant="label" sx={{ mb: 8 }}>
          Colors denote the pacing % with respect to the time and actual budget.
        </Typography>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                p: 8,
                borderRadius: 4,
                bgColor: 'purple-400',
                flexShrink: 0,
                mr: 8,
              }}
            />
            <Typography component="p" variant="label">
              {'Pacing is >120% of the expected spent'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 8 }}>
            <Box
              sx={{
                p: 8,
                borderRadius: 4,
                bgColor: 'success-400',
                flexShrink: 0,
                mr: 8,
              }}
            />
            <Typography component="p" variant="label">
              {'Pacing is >90% of the expected spent'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 8 }}>
            <Box
              sx={{
                p: 8,
                borderRadius: 4,
                bgColor: 'warning-400',
                flexShrink: 0,
                mr: 8,
              }}
            />
            <Typography component="p" variant="label">
              {'Pacing is >60% of the expected spent'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 8 }}>
            <Box
              sx={{
                p: 8,
                borderRadius: 4,
                bgColor: 'danger-400',
                flexShrink: 0,
                mr: 8,
              }}
            />
            <Typography component="p" variant="label">
              {'Pacing is <60% of the expected spent'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'start', mt: 8 }}>
            <Box
              sx={{
                p: 8,
                borderRadius: 4,
                bgColor: 'neutral-400',
                flexShrink: 0,
                mr: 8,
              }}
            />
            <Typography component="p" variant="label">
              End date is not provided. Hence, pacing cannot be calculated.
            </Typography>
          </Box>
        </Box>
        {Boolean(linkHref) && (
          <Box sx={{ mt: 12 }}>
            {/* @ts-ignore */}
            <Link target="_blank" href={linkHref}>
              Know more
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};
