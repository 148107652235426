import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  TextField,
  Typography,
} from '@applift/factor';
import { isValidUrl } from 'utils/isValidUrl';
import { urlRegEx } from 'utils/validationRules';

export interface EditClickUrlDialogPropTypes {
  creativeIds: number[];
  clickUrl: string | undefined;
  clickUrlUpdateLoading: boolean;
  updateClickUrl: (args: string) => void;
  setShowEditClickUrlDialog: (args: boolean) => void;
  setClickUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const EditClickUrlDialog = (props: EditClickUrlDialogPropTypes) => {
  const {
    setShowEditClickUrlDialog,
    updateClickUrl,
    clickUrlUpdateLoading,
    clickUrl,
    setClickUrl,
  } = props;

  const isClickUrlValid = () => {
    if (clickUrl) {
      return !isValidUrl(clickUrl) || !urlRegEx.test(clickUrl.trim());
    }
    return false;
  };

  React.useEffect(() => {
    setClickUrl('');
  }, [setClickUrl]);

  return (
    <Dialog open>
      <DialogTitle>Edit Click URL</DialogTitle>
      <DialogContent>
        <Typography>Enter the Click URL you want to apply to selected creatives</Typography>
        <TextField
          focused
          fullWidth
          sx={{ mt: 16 }}
          placeholder="Click URL"
          value={clickUrl}
          onChange={(e: any) => setClickUrl(e.target.value)}
          helperText={isClickUrlValid() ? 'Click URL must be a valid url address' : null}
          error={isClickUrlValid()}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowEditClickUrlDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={clickUrlUpdateLoading}
          disabled={!clickUrl || isClickUrlValid()}
          onClick={() => {
            if (clickUrl) {
              updateClickUrl(clickUrl);
            }
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
