import * as React from 'react';
import { Badge, Box, Typography } from '@applift/factor';
import { PgDealResponseType } from 'models/Inventory';
import styles from './styles.module.scss';

export interface DealStatusCellType {
  data: PgDealResponseType;
}

export const DealStatusCell = (props: DealStatusCellType) => {
  const { data } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Badge
        variant="dot"
        size="small"
        color={data.statusId === 1 ? 'success' : 'error'}
        classes={{ colorSuccess: styles.badgeActiveClass, colorError: styles.badgeErrorClass }}
      />
      <Typography sx={{ ml: 4 }}>{data.statusLabel}</Typography>
    </Box>
  );
};
