import * as React from 'react';
import { TextField, MenuItem, Box, Typography } from '@applift/factor';
import { CampaignAdvanced, CampaignPG } from '@applift/icons';
import { WithResponse } from 'models/Response';
import { CampaignTypeResponseType } from 'models/CampaignList';
import { useCampaignTypes } from 'hooks/useCampaignList';
import { CampaignStatusCountType } from 'models/Count';

export interface CampaignTypeIdSelectType {
  selectedCampaignTypeId?: number[];
  selectedCampaign?: number[];
  ioId: number;
  creativeTypeFilter?: number[];
  campaignStatusCount?: CampaignStatusCountType[];
  statusFilter: CampaignStatusCountType['status_key'][];
  setSelectedCampaignTypeId?: (arg: number[] | undefined) => void;
  campaignTypes?: WithResponse<CampaignTypeResponseType>;
}

export const CampaignTypeSelect = (props: CampaignTypeIdSelectType) => {
  const {
    setSelectedCampaignTypeId,
    selectedCampaignTypeId,
    selectedCampaign,
    ioId,
    creativeTypeFilter,
    statusFilter,
    campaignStatusCount,
  } = props;

  const statusFilterPayload = React.useMemo(
    () =>
      statusFilter[0] === 'all'
        ? campaignStatusCount
            ?.filter((val) => val.status_key !== 'all')
            .map((val) => val.status_key)
            .join(',')
        : statusFilter.join(','),
    [campaignStatusCount, statusFilter],
  );

  const { data: campaignTypes } = useCampaignTypes(
    {
      ioIds: [ioId as unknown as number],
      campaignIds: selectedCampaign,
      creativeTypeIds: creativeTypeFilter,
      statusList: statusFilterPayload?.split(','),
    },
    {
      enabled: !!ioId,
    },
  );

  return (
    <TextField
      variant="outlined"
      style={{ minWidth: 210 }}
      sx={{ width: 100 }}
      select
      value={selectedCampaignTypeId}
      onChange={(e) => {
        const selectedValues = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
        const filteredValues = selectedValues.filter(Boolean);
        if (setSelectedCampaignTypeId) {
          setSelectedCampaignTypeId(filteredValues);
        }
      }}
      SelectProps={{
        hideSelectNav: true,
        multiple: true,
        placeholder: 'Select Campaign Type',
        MenuProps: { PaperProps: { style: { height: 'fit-content' } } },
        renderValue: (val: any) => {
          if (!val.length) {
            return undefined;
          }
          const selectedCmpType =
            campaignTypes?.data?.campaignTypeDetails?.filter((inVal) => val.includes(inVal.id)) ??
            [];
          const isEveryValueSelected = campaignTypes?.data?.campaignTypeDetails.every((initVal) =>
            val.includes(initVal.id),
          );
          const totalCampaignCount = campaignTypes?.data?.campaignTypeDetails.reduce(
            (a, b) => b.count + a,
            0,
          );

          if (isEveryValueSelected) {
            return `All (${totalCampaignCount})`;
          }

          if (selectedCmpType[0]?.label && selectedCmpType[0]?.count) {
            return `${selectedCmpType[0]?.label} (${selectedCmpType[0]?.count})`;
          }
          return undefined;
        },
      }}
    >
      {campaignTypes?.data?.campaignTypeDetails?.map((val) => {
        return (
          <MenuItem key={val.id} value={val.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
              <Box sx={{ mr: 4, mt: 2 }}>
                {val.id === 2 ? (
                  <CampaignPG fontSize={20} color="primary" />
                ) : (
                  <CampaignAdvanced fontSize={20} color="primary" />
                )}
              </Box>
              <Typography>
                {val.label} ({val.count})
              </Typography>
            </Box>
          </MenuItem>
        );
      })}
    </TextField>
  );
};
