/* global google */
import { GeoRadiusDataTypes } from 'api/Campaign';
import { shapeType } from 'constants/location';
import uniqueId from 'lodash/uniqueId';

import { TAddressData } from 'utils/figures';

import { Circle, LatLng, LatLngLiteral, LatLngBounds, takeFormattedAddress } from './Google';

export class CircleExtended {
  private readonly original: google.maps.Circle;

  isInclude: boolean;

  filename: string | null = null;

  fileId: number | null = null;

  address: string = '';

  country: string = '';

  state: string = '';

  lat: number;

  lng: number;

  id: string = uniqueId('circle-');

  isSelected?: boolean;

  type: GeoRadiusDataTypes = shapeType.circle;

  constructor({
    circle,
    isInclude,
    addressData,
    filename,
    fileId,
    locationId,
  }: {
    circle: Circle;
    isInclude: boolean;
    addressData?: TAddressData;
    filename?: string;
    fileId?: number;
    locationId?: number;
  }) {
    this.original = circle;
    this.isInclude = isInclude;
    this.lat = circle.getCenter().lat();
    this.lng = circle.getCenter().lng();
    if (addressData) {
      this.address = addressData.address;
      this.country = addressData.country || '';
      this.state = addressData.state || '';
    }
    if (filename && fileId) {
      this.filename = filename;
      this.fileId = fileId;
    }
    if (locationId) {
      this.id = locationId.toString();
    }
  }

  setIsInclude(isInclude: boolean) {
    this.isInclude = isInclude;
  }

  setSelected(isSelected: boolean) {
    this.isSelected = isSelected;
  }

  getCenter(): LatLng {
    return this.original.getCenter();
  }

  getRadius(): number {
    return this.original.getRadius();
  }

  getBounds(): LatLngBounds {
    return this.original.getBounds();
  }

  setCenter(center: LatLng | LatLngLiteral): void {
    return this.original.setCenter(center);
  }

  setRadius(radius: number): void {
    return this.original.setRadius(radius);
  }

  setAddress(address: string) {
    this.address = address;
  }

  setCountry(country: string) {
    this.country = country;
  }

  setState(state: string) {
    this.state = state;
  }

  async update() {
    const { address, state, country } = await takeFormattedAddress(this.getCenter());
    this.address = address;
    this.state = state;
    this.country = country;
  }
}
