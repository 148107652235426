import { isExchangesDataValid } from 'store/app/helpers';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { advancedTargetingConstants } from './constants';
import { Option } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface DetailedExchangesType extends Option<number> {
  urlName: string;
}

export interface AdvancedTargetingState {
  exchanges: Option<number>[];
  detailedExchanges: DetailedExchangesType[];
  exchangesHasError: boolean;
  sidebarCampaignInfo: {
    [CampaignInfoField.exchanges]: Option<number>[];
    [CampaignInfoField.whiteListedDeviceId]: string;
    [CampaignInfoField.whiteListedIp]: string;
    [CampaignInfoField.whiteListedAppId]: string;
    [CampaignInfoField.whiteListedPackageName]: string;
    [CampaignInfoField.whiteListedSiteDomain]: string;
    [CampaignInfoField.blackListedDeviceId]: string;
    [CampaignInfoField.blackListedIp]: string;
    [CampaignInfoField.blackListedAppId]: string;
    [CampaignInfoField.blackListedPackageName]: string;
    [CampaignInfoField.blackListedSiteDomain]: string;
  };
}

const defaultAdvancedTargetingState: AdvancedTargetingState = {
  exchanges: [],
  detailedExchanges: [],
  exchangesHasError: false,
  sidebarCampaignInfo: {
    [CampaignInfoField.exchanges]: [],
    [CampaignInfoField.whiteListedDeviceId]: '',
    [CampaignInfoField.whiteListedIp]: '',
    [CampaignInfoField.whiteListedAppId]: '',
    [CampaignInfoField.whiteListedPackageName]: '',
    [CampaignInfoField.whiteListedSiteDomain]: '',
    [CampaignInfoField.blackListedDeviceId]: '',
    [CampaignInfoField.blackListedIp]: '',
    [CampaignInfoField.blackListedAppId]: '',
    [CampaignInfoField.blackListedPackageName]: '',
    [CampaignInfoField.blackListedSiteDomain]: '',
  },
};

const setStoreField = (
  state: AdvancedTargetingState,
  action: Action<{ key: string; value: Option<number>[] }>,
): AdvancedTargetingState => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setSidebarCampaignInfo = (
  state: AdvancedTargetingState,
  action: Action<{ key: string; value: string }>,
): AdvancedTargetingState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const selectExchanges = (
  state: AdvancedTargetingState,
  action: Action<Option<number>[]>,
): AdvancedTargetingState => {
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [CampaignInfoField.exchanges]: action.payload,
    },
  };
};

const resetAdvancedTargetingStore = (state: AdvancedTargetingState): AdvancedTargetingState => {
  return {
    ...state,
    exchanges: [],
    exchangesHasError: defaultAdvancedTargetingState.exchangesHasError,
    sidebarCampaignInfo: { ...defaultAdvancedTargetingState.sidebarCampaignInfo },
  };
};

const validateExchanges = (state: AdvancedTargetingState): AdvancedTargetingState => {
  return {
    ...state,
    exchangesHasError: !isExchangesDataValid(state.sidebarCampaignInfo.selectedExchanges),
  };
};

const reducer = reducerFromMap<AdvancedTargetingState>(defaultAdvancedTargetingState, {
  [advancedTargetingConstants.SET_EXCHANGES]: setStoreField,
  [advancedTargetingConstants.SELECT_ADVANCED_TARGETING_FIELD]: setSidebarCampaignInfo,
  [advancedTargetingConstants.SELECT_EXCHANGES]: selectExchanges,
  [advancedTargetingConstants.RESET_ADVANCED_TARGETING_STORE]: resetAdvancedTargetingStore,
  [advancedTargetingConstants.VALIDATE_EXCHANGES]: validateExchanges,
});

export const advancedTargeting = (
  state: AdvancedTargetingState = defaultAdvancedTargetingState,
  action: Action<any>,
) => reducer(state, action);
