import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { OptionId } from '../../models/Option';
import { authConstants } from './constants';
import { User } from '../../models/User';

export type AuthState = {
  authorized: boolean;
  userData: User;
  organizationTimezoneInfo: null | number | OptionId;
};

export const defaultAuthState: AuthState = {
  authorized: false,
  userData: {
    apiToken: '',
    owId: 0,
    userId: 0,
    domainURL: '',
    email: '',
    organizationName: '',
    firstName: '',
    lastName: '',
    userAvatar: '',
  },
  organizationTimezoneInfo: null,
};

function loginSuccess(state: AuthState, action: Action<User>): AuthState {
  const { payload } = action;
  return {
    ...state,
    userData: payload,
    authorized: true,
  };
}

function setOrganizationTimezoneInfo(state: AuthState, action: Action<null | number | OptionId>) {
  return {
    ...state,
    organizationTimezoneInfo: action.payload,
  };
}

const reducer = reducerFromMap<AuthState>(defaultAuthState, {
  [authConstants.LOGIN_SUCCESS]: loginSuccess,
  [authConstants.SET_ORGANIZATION_TIMEZONE_INFO]: setOrganizationTimezoneInfo,
});

export const auth = (state: AuthState = defaultAuthState, action: Action<any>) =>
  reducer(state, action);
