import * as React from 'react';
import { ModalCreativePreview } from 'iqm-framework';
import { Box } from '@applift/factor';
import { Audio, Show } from '@applift/icons';

import { CREATIVE_ID_FROM_NAME } from 'constants/creatives';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import { parseVASTDAAST } from 'utils/VastDaastParser';
import styles from './styles.module.scss';

export const CreativePreviewCell = (props: { data: any }) => {
  const { data } = props;
  const { creativeStatus } = data;

  const [clickedCreative, setClickedCreative] = React.useState<any>();
  const audioPlayerRef = React.useRef<HTMLAudioElement>(null);

  const [showCreativePreview, setShowCreativePreview] = React.useState<boolean>(false);

  const [creativeInfoFetchingEnabled, setEnableCreativeInfoFetch] = React.useState<boolean>(false);

  const [videoSource, setVideoSource] = React.useState<string>('');
  const [audioSource, setAudioSource] = React.useState<string>('');

  const creativeInfo = data;

  const handleOpenPreview = React.useCallback(async () => {
    if (data.id) {
      const creativeTypeId =
        CREATIVE_ID_FROM_NAME[
          capitalizeFirstLetter(
            data.creativeType ? data.creativeType.toLowerCase() : 'image',
          ) as keyof typeof CREATIVE_ID_FROM_NAME
        ];

      const isNativeVideo =
        creativeInfo?.creativeTypeId === CREATIVE_ID_FROM_NAME.Native &&
        creativeInfo?.nativeCreativeAttributes?.mediaType.toLowerCase() === 'video';

      setClickedCreative({
        ...creativeInfo,
        creativeThumbnailSource: data.creativeThumbnailSource || '',
        creativeCardSource: data.creativeCardSource || '',
        creativeTypeId,
        nativeCreativeAttributes: creativeInfo?.nativeCreativeAttributes,
      });

      if (
        (creativeTypeId === CREATIVE_ID_FROM_NAME.Video ||
          creativeTypeId === CREATIVE_ID_FROM_NAME.Audio ||
          isNativeVideo) &&
        creativeInfo
      ) {
        const parsed = JSON.parse(creativeInfo.creativeSource);
        const dastSource = parsed[Object.keys(parsed)[0]];
        const link = await parseVASTDAAST(dastSource);

        if (creativeTypeId === CREATIVE_ID_FROM_NAME.Video || isNativeVideo) {
          setVideoSource(link);
        } else {
          setAudioSource(link);
        }
      }
      setShowCreativePreview(true);
    }
  }, [creativeInfo, data]);

  React.useEffect(() => {
    if (creativeInfoFetchingEnabled) {
      handleOpenPreview();
    }
  }, [handleOpenPreview, creativeInfoFetchingEnabled]);

  const stopPlayingOtherAudio = () => {
    document
      .querySelectorAll('audio')
      .forEach(
        (playerEl) =>
          playerEl.getAttribute('src') !== audioPlayerRef.current?.getAttribute('src') &&
          playerEl.pause(),
      );
  };

  const playAudioHandler = React.useCallback(() => {
    stopPlayingOtherAudio();
    if (audioPlayerRef.current) {
      audioPlayerRef.current.play();
    }
  }, []);

  const renderContentCell = React.useCallback(
    (item: any) => {
      const { creativeThumbnailSource } = item;

      if (creativeThumbnailSource) {
        return (
          <>
            {showCreativePreview &&
            clickedCreative?.creativeTypeId === CREATIVE_ID_FROM_NAME.Audio ? (
              // eslint-disable-next-line
              <audio
                ref={audioPlayerRef}
                controls
                src={audioSource}
                className={styles.audioPlayer}
                autoPlay
                onPlay={playAudioHandler}
              />
            ) : (
              <Box
                className={`${styles.noDataWrapper} ${styles.clickable}`}
                onClick={() => {
                  if (!creativeInfo) {
                    setEnableCreativeInfoFetch(true);
                  } else {
                    handleOpenPreview();
                  }
                }}
              >
                <img
                  className={styles.maxHeight80}
                  style={{ cursor: 'pointer' }}
                  src={creativeThumbnailSource}
                  alt=""
                />
                <Box className={styles.imageOverlay}>
                  <Box>
                    <Show fontSize={20} sx={{ textColor: 'neutral-0' }} />
                    <Box>Preview</Box>
                  </Box>
                </Box>
              </Box>
            )}
            {showCreativePreview &&
            clickedCreative?.creativeTypeId !== CREATIVE_ID_FROM_NAME.Audio ? (
              <ModalCreativePreview
                creative={(() => {
                  return {
                    ...data,
                    ...(videoSource ? { creativeSource: videoSource } : {}),
                  };
                })()}
                onClose={() => setShowCreativePreview(false)}
              />
            ) : null}
          </>
        );
      }

      if (item.creativeType?.toLowerCase() === 'audio') {
        return (
          <Box>
            {showCreativePreview ? (
              // eslint-disable-next-line
              <audio
                ref={audioPlayerRef}
                controls
                src={audioSource}
                className={styles.audioPlayer}
                autoPlay
                onPlay={playAudioHandler}
              />
            ) : (
              <Box
                className={`${styles.noDataWrapper} ${styles.voiceIcon}`}
                onClick={() => {
                  if (!creativeInfo) {
                    setEnableCreativeInfoFetch(true);
                  } else {
                    handleOpenPreview();
                  }
                }}
              >
                <Audio fontSize={20} />
              </Box>
            )}
          </Box>
        );
      }

      if (showCreativePreview) {
        return (
          <ModalCreativePreview
            creative={{
              ...clickedCreative,
              creativeCardSource:
                clickedCreative?.creativeCardSource || clickedCreative?.creativeSource,
            }}
            //   @ts-ignore
            videoSource={videoSource}
            onClose={() => setShowCreativePreview(false)}
          />
        );
      }

      return (
        <Box
          className={`${styles.noDataWrapper} ${styles.clickable}`}
          onClick={() => {
            if (!creativeInfo) {
              setEnableCreativeInfoFetch(true);
            } else {
              handleOpenPreview();
            }
          }}
        >
          <Audio fontSize={20} />
        </Box>
      );
    },
    [
      audioSource,
      clickedCreative,
      creativeInfo,
      data,
      handleOpenPreview,
      playAudioHandler,
      showCreativePreview,
      videoSource,
    ],
  );

  if (data.total) {
    return null;
  }

  if (creativeStatus === 'Failed') {
    return <Box className={styles.noDataWrapper}>-</Box>;
  }

  return (
    <Box
      className={styles.maxHeight80}
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {renderContentCell(data)}
    </Box>
  );
};
