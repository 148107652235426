import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Col,
  Row,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import moment from 'moment';

import { SingleDatePicker } from 'components/SingleDatePicker';
import { Timezone } from 'components/Timezone';
import { CampaignListType } from 'models/CampaignList';
import { useTimezone } from 'hooks/useTimezone';
import { Timezone as TimezoneType } from 'models/Timezone';
import { EndDateRequestData } from 'api/Campaign';
import { IODetail } from 'models/IO';
import { IO_STATUSES } from 'constants/apps';
import { getFutureTimestamp } from 'utils/date';

interface SetSetCampaignDateAndTimezoneProps {
  closeDialog: () => void;
  isLoading?: boolean;
  selectedCampaigns: CampaignListType[];
  onCompletion: (data: EndDateRequestData) => void;
  organizationTimezoneId: number | undefined;
  ioDetail: IODetail;
}

export const SetCampaignDateAndTimezone = (props: SetSetCampaignDateAndTimezoneProps) => {
  const {
    closeDialog,
    isLoading,
    onCompletion,
    selectedCampaigns,
    organizationTimezoneId,
    ioDetail,
  } = props;

  const timezoneOptions = useTimezone().data;
  const isDifferentTimezonesPresent =
    selectedCampaigns.length > 1
      ? !selectedCampaigns
          .map((item) => item?.campaignTimezone)
          .every((element) => element === selectedCampaigns[0]?.campaignTimezone)
      : false;

  // date of campaigns will be in seconds and has to be converted to milliseconds
  const [selectedDate, updateSelectedDate] = React.useState(
    selectedCampaigns.length === 1 &&
      selectedCampaigns[0].endTime &&
      selectedCampaigns[0].endTime * 1000 > moment().valueOf()
      ? selectedCampaigns[0].endTime * 1000
      : null,
  );
  const firstSelectionTimeZone: TimezoneType | null =
    selectedCampaigns.length > 0
      ? (timezoneOptions?.find(
          (item) => item.label === selectedCampaigns[0]?.campaignTimezone,
        ) as TimezoneType)
      : null;
  const [updatedTimezone, updateSelectedTimezone] = React.useState(
    isDifferentTimezonesPresent
      ? undefined
      : {
          label: firstSelectionTimeZone?.label || '',
          id: firstSelectionTimeZone?.id || -1,
          value: firstSelectionTimeZone?.name || '',
        },
  );

  const getHelperText = () =>
    isDifferentTimezonesPresent ? (
      'Please be aware that selected campaigns have different timezones.'
    ) : (
      <>
        All the campaigns will stop serving once the Insertion Order’s
        <Typography weight="demi" sx={{ textColor: 'neutral-500', mx: 4 }}>
          End Date{' '}
          {moment(selectedCampaigns[0].ioEndTime ?? '')
            ?.tz(
              timezoneOptions?.find((item) => item.id === selectedCampaigns[0]?.ioTimezone)
                ?.name as string,
            )
            ?.format('MM/DD/YYYY hh:mm A')}
        </Typography>
        is reached.
      </>
    );

  const disableSetBtn = () => {
    if (!(selectedDate && updatedTimezone)) {
      return true;
    }
    return selectedCampaigns.length === 1
      ? (selectedCampaigns[0].endTime ?? 0) * 1000 === selectedDate &&
          selectedCampaigns[0].campaignTimezone === updatedTimezone.value
      : false;
  };

  React.useEffect(() => {
    if (!updatedTimezone && timezoneOptions?.length && isDifferentTimezonesPresent) {
      const orgTimezone = timezoneOptions?.find(
        (item) => item.id === organizationTimezoneId,
      ) as TimezoneType;
      updateSelectedTimezone({
        label: orgTimezone?.label || '',
        id: orgTimezone?.id || -1,
        value: orgTimezone?.name || '',
      });
    }
  }, [updatedTimezone, organizationTimezoneId, timezoneOptions, isDifferentTimezonesPresent]);

  return (
    <Dialog fullWidth maxWidth="sm" open PaperProps={{ sx: { overflowY: 'visible' } }}>
      <DialogTitle onClose={closeDialog}>Set End Date</DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', overflowY: 'visible' }}
      >
        <Typography sx={{ mb: 16 }}>
          Select the<Typography sx={{ textWeight: 'demi', ml: 4 }}>End Date</Typography> for the
          {selectedCampaigns.length === 1 && ' campaign'}
          <Typography sx={{ textWeight: 'demi', mx: 4 }}>
            {selectedCampaigns.length === 1
              ? `${selectedCampaigns[0].campaignName}.`
              : selectedCampaigns.length}
          </Typography>
          {selectedCampaigns.length > 1 && 'campaigns.'}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Row sx={{ mb: 16 }}>
            <Col>
              <SingleDatePicker
                timezone={updatedTimezone}
                updateDate={(date: number) => {
                  const futureEndDate = getFutureTimestamp(
                    date,
                    updatedTimezone?.value as string,
                    15,
                  );
                  // if selected time is less than 15 mins after current time, then set 15 mins+current time as value
                  if (futureEndDate !== date && date) {
                    updateSelectedDate(futureEndDate);
                    enqueueSnackbar(
                      'End time must be atleast 15 minutes in the future and was corrected',
                      {
                        variant: 'info',
                      },
                    );
                  } else {
                    updateSelectedDate(date);
                  }
                }}
                date={selectedDate as number}
                label="End Date"
                placeholderText="Select End Date"
              />
            </Col>
            <Col sx={{ mt: 2 }}>
              <Timezone
                label="Timezone"
                value={updatedTimezone}
                onChange={updateSelectedTimezone}
                timezoneTextFieldProps={{ disabled: true }}
              />
            </Col>
          </Row>
          {(isDifferentTimezonesPresent || selectedCampaigns[0].ioEndTime) &&
          ioDetail.ioStatusId !== IO_STATUSES.EXPIRED ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 8 }}>
              <InfoCircle fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
              <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
                {getHelperText()}
              </Typography>
            </Box>
          ) : null}
          {ioDetail.ioStatusId === IO_STATUSES.EXPIRED && ioDetail.ioEndTime ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 8 }}>
              <InfoCircle fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
              <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
                {`The IO End date (${moment(ioDetail.ioEndTime ?? '')
                  ?.tz(
                    timezoneOptions?.find((item) => item.id === selectedCampaigns[0]?.ioTimezone)
                      ?.name as string,
                  )
                  ?.format(
                    'MM/DD/YYYY hh:mm A',
                  )}) is expired. The campaign won’t start serving till the IO end date is updated.`}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={disableSetBtn()}
          loading={isLoading}
          onClick={() =>
            onCompletion({
              campaignIds: selectedCampaigns.map((cmp) => cmp.campaignId).toString(),
              // pass back timestamp in seconds to API
              endDate: Math.round((selectedDate as number) / 1000),
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
