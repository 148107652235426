export const AUDIENCES_STATUS = {
  PROCESSING: 'processing',
  PENDING: 'pending',
  APPROVED: 'approved',
  FAILED: 'failed',
  REJECTED: 'rejected',
  READY: 'ready',
};

export const DISPLAY_CREATIVE_IDS = [11, 13];

export const ALL_CATEGORY_CREATIVE_ID = [11, 13, 14];

export const PRE_BID_AUDIENCE_TYPE_ID = 8;

export const MATCHED_AUDIENCE_TYPE_ID = 1;
export const SEGMENTED_AUDIENCE_TYPE_ID = 2;
export const RETARGETED_AUDIENCE_TYPE_ID = 3;
export const GEOFARMED_AUDIENCE_TYPE_ID = 4;
export const CONTEXTUAL_AUDIENCE_TYPE_ID = 5;
export const LOOKALIKE_AUDIENCE_TYPE_ID = 6;
export const CAMPAIGN_AUDIENCE_TYPE_ID = 7;

export const CAMPAIGN_MAX_AUDIENCE_COUNT = 20;
