import { isPublisherDataValid } from 'store/app/helpers';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { publishersConstants } from './constants';
import { Option, NestedOption } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

interface SelectedPublishersType extends Option<number> {
  iab_id: string;
}

export interface PublishersState {
  publishers: NestedOption[];
  [CampaignInfoField.publishers]: SelectedPublishersType[];
  publisherStoreHasErrors: boolean;
}

const defaultPublishersState: PublishersState = {
  publishers: [],
  [CampaignInfoField.publishers]: [],
  publisherStoreHasErrors: false,
};

function setPublishers(state: PublishersState, action: Action<NestedOption[]>): PublishersState {
  return {
    ...state,
    publishers: action.payload,
  };
}

function selectPublishers(
  state: PublishersState,
  action: Action<SelectedPublishersType[]>,
): PublishersState {
  return {
    ...state,
    [CampaignInfoField.publishers]: action.payload,
  };
}

const validatePublisherStore = (state: PublishersState): PublishersState => {
  return {
    ...state,
    publisherStoreHasErrors: !isPublisherDataValid(state),
  };
};

const reducer = reducerFromMap<PublishersState>(defaultPublishersState, {
  [publishersConstants.SET_PUBLISHERS]: setPublishers,
  [publishersConstants.SELECT_PUBLISHERS]: selectPublishers,
  [publishersConstants.VALIDATE_PUBLISHER_STORE]: validatePublisherStore,
});

export const publishers = (state: PublishersState = defaultPublishersState, action: Action<any>) =>
  reducer(state, action);
