/* eslint-disable camelcase */
import Axios, { AxiosResponse, Canceler } from 'axios';
import get from 'lodash/get';
import { QueryFunctionContext } from '@tanstack/react-query';
import { LatLngLiteral, TLatLongNumbers } from 'models/Google';

import { RadiusUpdatedLocations } from 'models/Location';
import { ICreativeAdvancedTargeting } from 'store/creatives/reducer';
import { getCampaignData, getCustomerConfigQueryKey } from './QueryKeys';
import { Logger } from '../utils/logger';
import { getInstance, getInstanceWithTimestamps } from './Instance';
import {
  CampaignEstimatorMetaData,
  CreativesPlacementMapping,
  ExistingCampaignData,
} from '../models/ExistingCampaign';
import {
  GetResponse,
  GetStatusCodeResponse,
  CreateUpdateSuccessResponse,
  WithTimestamps,
  WithResponse,
} from '../models/Response';
import { Scheduling } from '../store/scheduling/reducer';

interface IResponse {
  campaign_id: number;
  end_date: number;
}

export type CreateCampaignResponse = WithTimestamps<
  GetStatusCodeResponse<CreateUpdateSuccessResponse>
>;

export type GeoRadiusDataTypes = 'circle' | 'custom' | 'square';
export type GeoRadiusData = {
  address: string;
  sid: string;
  unit: string;
  cid: string;
  latitude: number;
  longitude: number;
  radius: number;
  sdId?: number;
  polypath?: LatLngLiteral[];
  type?: GeoRadiusDataTypes;
  cdId?: number;
};

export type TSavedFigureParams = {
  radius: number;
  latitude: number;
  longitude: number;
  address: string;
  unit: 'mile' | 'km';
  sid: string;
  cid: string;
  polypath?: TLatLongNumbers[];
  type?: string;
};

export type CampaignCreateUpdateParams = {
  campaignName: string;
  isAgreementChecked?: boolean;
  ioId: number;
  countryId?: string;
  creativeType?: number;
  advertiserDomain?: string;
  budgetTotal?: number;
  maxBid?: number;
  isBidShading?: boolean;
  startTime?: number;
  endTime?: number | null;
  timezone?: number;
  creativeIds?: string;
  creativesPlacementMapping?: CreativesPlacementMapping;
  budgetDay?: number;
  budgetTypeId?: number;
  bidOptimization?: boolean;
  bidPacing?: boolean;
  impressionCapping?: number;
  maxDayClicks?: number;
  totalClicks?: number;
  maxDayImpressions?: number;
  totalImpressions?: number;
  maxDayConversions?: number;
  totalConversions?: number;
  conversionType?: 'install' | 'non-install';
  appURL?: string;
  carriers?: string;
  networkType?: string;
  deviceType?: string;
  trafficType?: string;
  isTvAd?: boolean;
  manufacturer?: string;
  os?: string;
  osVersion?: string;
  device?: string;
  totalBudgetPacing?: boolean;
  publisherAdCategory?: string;
  campaignGroupIds?: string;
  targetCPI?: number;
  politicalAdvertiserClientId?: number;
  stateIds?: string;
  ageRangeIds?: string;
  genderIds?: string;
  languageIds?: string;
  interestIds?: string;
  incomeRangeIds?: string;
  ethnicityIds?: string;
  whiteListedInventoryGroupIds?: string;
  blackListedInventoryGroupIds?: string;
  groupDealId?: string;
  scheduling?: Scheduling;
  whiteListedDeviceId?: string;
  whiteListedIp?: string;
  whiteListedPackageName?: string;
  whiteListedAppId?: string;
  whiteListedSiteDomain?: string;
  blackListedDeviceId?: string;
  blackListedIp?: string;
  blackListedPackageName?: string;
  blackListedAppId?: string;
  blackListedSiteDomain?: string;
  exchanges?: string;
  locationFileIds?: string;
  removedLocationId?: string;
  removedLocationIds?: string;
  conversionIds?: string;
  conversionTypeId?: number;
  // location
  whiteListedLocationIds?: string;
  whiteListedZipcodes?: string;
  whiteListedDmaIds?: string;
  whiteListedCongressionalDistrictIds?: string;
  whiteListedSenateDistrictIds?: string;
  whiteListedHouseDistrictIds?: string;
  whiteListedCityIds?: string;
  whiteListedCountyIds?: string;
  whiteListedGeoRadiusDetails?: GeoRadiusData[];
  blackListedLocationIds?: string;
  blackListedZipcodes?: string;
  blackListedDmaIds?: string;
  blackListedCongressionalDistrictIds?: string;
  blackListedSenateDistrictIds?: string;
  blackListedHouseDistrictIds?: string;
  blackListedCityIds?: string;
  blackListedCountyIds?: string;
  blackListedGeoRadiusDetails?: GeoRadiusData[];
  radiusUpdatedLocations?: RadiusUpdatedLocations;
  // audiences
  whiteListedAudienceIds?: string;
  blackListedAudienceIds?: string;

  creativeAdvanceTargeting?: ICreativeAdvancedTargeting;
  isAdvanceAudioVideoTargeted: boolean;

  campaignEstimatorMetaData?: CampaignEstimatorMetaData;

  prebidAudienceSegmentIdList?: number[];
};

export const CreateCampaign = async (req: CampaignCreateUpdateParams): Promise<IResponse> => {
  try {
    const response: AxiosResponse<IResponse> = await getInstance().post('/campaigns', req);

    return response.data;
  } catch (e) {
    Logger.log('Error while create new campaign', e);
    return e as any;
  }
};

export const CreateCampaignNew = async (
  req: CampaignCreateUpdateParams,
): Promise<CreateCampaignResponse> => {
  try {
    const response: AxiosResponse<CreateCampaignResponse> = await getInstanceWithTimestamps().post(
      '/v2/cmp/campaigns/add',
      req,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while create new campaign', e);
    return Promise.reject(e);
  }
};

export const SaveCampaignAsDraft = async (
  req: CampaignCreateUpdateParams,
): Promise<CreateCampaignResponse> => {
  try {
    const response: AxiosResponse<CreateCampaignResponse> = await getInstanceWithTimestamps().post(
      `/v2/cmp/campaigns/draft/add`,
      req,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while saving the campaign as draft', e);
    return Promise.reject(e);
  }
};

export const FetchCampaignData = async (id: number): Promise<ExistingCampaignData> => {
  try {
    const response: AxiosResponse<GetResponse<ExistingCampaignData>> = await getInstance().get(
      `/v2/cmp/campaign/${id}`,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const fetchCampaignData = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<typeof getCampaignData['keys']>
>): Promise<ExistingCampaignData> => {
  try {
    const { campaignId } = queryKey[0]!;
    const response: AxiosResponse<GetResponse<ExistingCampaignData>> = await getInstance().get(
      `/v2/cmp/campaign/${campaignId}`,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const EditCampaign = async (
  req: Partial<CampaignCreateUpdateParams>,
  id: number,
): Promise<CreateCampaignResponse> => {
  try {
    const response: AxiosResponse<CreateCampaignResponse> = await getInstanceWithTimestamps().patch(
      `/v2/cmp/campaign/${id}`,
      req,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while edit the campaign', e);
    return Promise.reject(e);
  }
};

export const EditCampaignDraft = async (
  req: CampaignCreateUpdateParams,
  id: number,
): Promise<CreateCampaignResponse> => {
  try {
    const response: AxiosResponse<CreateCampaignResponse> = await getInstanceWithTimestamps().put(
      `/v2/cmp/campaign/draft/${id}`,
      req,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while edit the campaign', e);
    return Promise.reject(e);
  }
};

export interface BudgetInfoParams {
  budgetTypeId: number;
  campaignId?: number;
  budgetDay?: number;
  budgetTotal?: number;
  endTime?: number;
  startTime: number;
  fixedDailyBudget?: boolean;
  timezone: number;
  isGraphInfoRequired?: boolean;
  maxBid?: number;
  totalImpressions?: number;
  spendingBudget?: number;
  campaignTypeId?: number;
}

export interface GraphObj {
  amount: number;
  date: string;
  type: 'projected' | 'spent' | 'shortage' | 'overflow';
}

export interface BudgetData {
  graphInfo?: GraphObj[];
  actualSpent: number;
  budgetTotal: number;
  campaignId?: number;
  budgetDay: number;
  endTime: number;
  fixedDailyBudget: false;
  startTime?: number;
  timezone?: number;
  actualImpressionSpent?: number;
  totalImpressions?: number;
  dailyImpressions?: number;
}

let budgetInfoCanceler: Canceler;
export const GetBudgetInfo = async (params: BudgetInfoParams): Promise<BudgetData> => {
  if (budgetInfoCanceler) {
    budgetInfoCanceler('Canceled by user');
  }

  try {
    const response: AxiosResponse<GetResponse<BudgetData>> = await getInstance().get(
      `v2/cmp/campaign/budgetInfo`,
      {
        params,
        cancelToken: new Axios.CancelToken((c) => {
          budgetInfoCanceler = c;
        }),
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while edit the campaign', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface GetEstimatedBudgetParams {
  campaignId: number;
  budgetTypeId: number;
  totalImpression: number;
  maxBid: number;
}

export interface EstimatedTotalBudgetData {
  campaignId: number;
  updatedTotalBudget: number;
}

export const GetEstimatedBudget = async (
  params: GetEstimatedBudgetParams,
): Promise<EstimatedTotalBudgetData | {}> => {
  try {
    const response: AxiosResponse<WithResponse<EstimatedTotalBudgetData>> = await getInstance().get(
      `/v3/cmp/campaign/updated-total-budget`,
      { params },
    );
    return response?.data?.data || {};
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface UpdateBudget {
  campaignIds: string;
  maxBid?: number;
  totalBudget?: number;
  dailyBudget?: number;
  totalBudgetUpdateType: 'change' | 'addition' | 'distribution';
  budgetTypeId?: 1 | 2;
  totalImpressions?: number;
  dailyImpressions?: number;
  campaignTypeId?: number;
}

export const updateCampaignBudget = async (requestData: UpdateBudget): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().put(
      '/v2/cmp/campaigns/update-budget',
      requestData,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while updating the budget', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export interface UpdateCampaignStatus {
  campaignIds: string;
  status: string;
  endDate?: number;
}

export const updateCampaignStatus = async (requestData: UpdateCampaignStatus): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().put(
      '/v2/cmp/campaigns/update-status',
      requestData,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while updating the status', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export interface DuplicateCampaignRequestData {
  endDate?: number;
  existingCampaignIds: string;
  ioId: number;
  startDate: number;
  timeZoneId: number;
  campaignTypeId?: number;
}

export const duplicateCampaign = async (
  requestData: DuplicateCampaignRequestData,
): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().post(
      '/v2/cmp/campaigns/duplicate',
      requestData,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while duplicating the campaign(s)', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export interface EndDateRequestData {
  endDate: number;
  campaignIds: string;
}

export const updateCampaignEndDate = async (requestData: EndDateRequestData): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().put(
      '/v2/cmp/campaigns/update-end-date',
      requestData,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while setting end date to the campaign(s)', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

interface AddOrUpdateCampaignPriority {
  priority: number;
  campaignIds: number[];
}

declare const UpdateAction: {
  readonly ADD: 'ADD';
  readonly UPDATE: 'UPDATE';
  readonly DELETE: 'DELETE';
};

interface BidModelDataInterface {
  priority?: number | null;
  bidMultiplier?: number;
  spendRatio?: number;
  spendRatioTypeId?: number;
  id?: number;
}
interface DimensionEntity {
  dimensionId?: number;
  entityId?: number;
  campaignId?: number;
}

export interface IBidModelRequest {
  action: keyof typeof UpdateAction;
  bidModelData?: BidModelDataInterface;
  dimensionEntityMappings?: DimensionEntity[];
  bidModelDataIds?: number[];
}

export interface PutCampaignPriorityParams {
  bidModelRequests?: IBidModelRequest[];
  addPriority?: AddOrUpdateCampaignPriority;
  updatePriority?: AddOrUpdateCampaignPriority;
  deletePriority?: {
    campaignIds: number[];
  };
  ioId: number;
}

export const putCampaignPriority = async (params: PutCampaignPriorityParams) => {
  const { ioId, ...apiBody } = params;
  try {
    const response: AxiosResponse<WithTimestamps<WithResponse<string>>> =
      await getInstanceWithTimestamps().put(`/v3/bm/io/${ioId}/bid-models`, {
        ...apiBody,
      });
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export interface CustomerConfigResponse {
  isBidShadingEnabled: boolean;
  isTestCustomer: boolean;
  pgCampaignFees: number;
  pgWorkspaceShare: number;
  isVldEnabled?: boolean;
  hadVldGenerated?: boolean;
}

export const getCustomerConfig = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCustomerConfigQueryKey['keys']>>) => {
  const payload = queryKey[0].customerConfigParams;
  try {
    const response: AxiosResponse<WithResponse<CustomerConfigResponse>> = await getInstance().get(
      `/v3/ua/customer/config/${payload.owId}`,
    );
    return response?.data?.data;
  } catch (e) {
    Logger.log(`Error while getting customer configuration`, e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface DuplicatePGCampaignPayloadType {
  existingCampaignIds: string;
  ioId: number;
  timeZoneId: number;
  startDate: number;
  endDate?: number;
  campaignTypeId: number;
  pgDealIds: number[];
}

export const duplicatePgCampaign = async (requestData: DuplicatePGCampaignPayloadType) => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().post(
      `/v2/cmp/campaigns/duplicate`,
      requestData,
    );
    return response?.data.responseObject;
  } catch (e) {
    Logger.log(`Error while getting customer configuration`, e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface CreatePGCampaignPayloadType {
  pgCampaignInfo: {
    campaignName: string;
    ioId?: number;
    timeZoneId?: number;
    spendingBudget?: number;
    maxBid?: number;
    startTime?: number;
    endTime?: number;
    budgetTypeId?: number;
    campaignTypeId?: number;
    advertiserDomain?: string;
    totalImpressions?: number;
  };
  creativeTargeting: {
    creativeTypeId?: number;
    creativeIds: number[];
  };
  inventoryTargeting: {
    pgDealIds: number[];
  };
  conversionTargeting?: {
    conversionTypeId?: number;
    conversionIds?: number[];
  };
  politicalAdvertiserClientId?: number;
  countryId: number;
}

export const createPgCampaign = async (requestData: CreatePGCampaignPayloadType) => {
  try {
    const response: AxiosResponse<WithResponse<any>> = await getInstance().post(
      `/v3/cmp/pg/campaigns/add`,
      requestData,
    );
    return response?.data;
  } catch (e) {
    // @ts-ignore
    Logger.log(`Error while creating pg campaign`, e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface EditPGCampaignPayloadType {
  pgCampaignInfo?: {
    campaignName?: string;
    ioId?: number;
    timeZoneId?: number;
    spendingBudget?: number;
    maxBid?: number;
    startTime?: number;
    endTime?: number;
    budgetTypeId?: number;
    campaignTypeId?: number;
    advertiserDomain?: string;
    totalImpressions?: number;
  };
  creativeTargeting?: {
    creativeTypeId?: number;
    creativeIds?: number[];
  };
  inventoryTargeting?: {
    pgDealIds?: number[];
  };
  conversionTargeting?: {
    conversionTypeId?: number;
    conversionIds?: number[];
  };
  politicalAdvertiserClientId?: number;
  countryId?: number;
  campaignId: number;
}

export const editPgCampaign = async (requestData: EditPGCampaignPayloadType) => {
  try {
    const response: AxiosResponse<WithResponse<any>> = await getInstance().patch(
      `/v3/cmp/pg/campaigns/${requestData.campaignId}`,
      { ...requestData, campaignId: undefined },
    );
    return response?.data;
  } catch (e) {
    Logger.log(`Error while getting customer configuration`, e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface CreatePGCampaignDraftPayloadType {
  pgCampaignInfo: {
    campaignName: string;
    ioId?: number;
    timeZoneId?: number;
    spendingBudget?: number;
    maxBid?: number;
    startTime?: number;
    endTime?: number;
    budgetTypeId?: number;
    campaignTypeId?: number;
    advertiserDomain?: string;
    totalImpressions?: number | null;
  };
  creativeTargeting: {
    creativeTypeId?: number;
    creativeIds?: number[];
  };
  inventoryTargeting: {
    pgDealIds?: number[];
  };
  conversionTargeting?: {
    conversionTypeId?: number;
    conversionIds?: number[];
  };
  politicalAdvertiserClientId?: number;
  countryId?: number;
  campaignId?: number;
}

export const createPgCampaignDraft = async (requestData: CreatePGCampaignDraftPayloadType) => {
  try {
    const response: AxiosResponse<WithResponse<any>> = await getInstance().post(
      `/v3/cmp/pg/campaigns/draft/add`,
      requestData,
    );
    return response?.data;
  } catch (e) {
    // @ts-ignore
    Logger.log(`Error while creating pg campaign`, e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const editDraftPgCampaign = async (requestData: CreatePGCampaignDraftPayloadType) => {
  try {
    const response: AxiosResponse<WithResponse<any>> = await getInstance().patch(
      `/v3/cmp/pg/campaigns/draft/${requestData.campaignId}`,
      requestData ? { ...requestData, campaignId: undefined } : undefined,
    );
    return response?.data;
  } catch (e) {
    Logger.log(`Error while getting customer configuration`, e);
    return Promise.reject(get(e, 'response.data', e));
  }
};
