import * as React from 'react';
import { Tooltip, Box, Typography, Badge } from '@applift/factor';

import { CampaignListType } from 'models/CampaignList';
import { getDisablePriorityEditTooltip } from 'utils/advancedModelling';
import { IODetail } from 'models/IO';
import { CAMPAIGN_STATUSES, CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { DEFAULT_ACTIVE_PRIORITY } from 'constants/advancedModelling';

import styles from './styles.module.scss';

interface Props {
  rowData: CampaignListType;
  ioDetail?: IODetail;
}

const currentlyServingTooltipText =
  'This campaign is currently delivering ads based on the priority settings.';
const servingInQueueTooltipText =
  'This campaign is in the queue and will start ad delivery once its priority settings become active.';

export const CampaignPriorityCell = (props: Props) => {
  const { rowData, ioDetail } = props;

  const renderStatus = () => {
    const currentTime = new Date(new Date().setMilliseconds(0)).getTime() / 1000;
    const campaignStartDate = rowData.startTime;
    if (
      rowData.status === CAMPAIGN_STATUSES.RUNNING &&
      ioDetail?.ioCurrentPriority &&
      rowData?.campaignPriority &&
      ioDetail?.ioCurrentPriority !== DEFAULT_ACTIVE_PRIORITY
    ) {
      if (
        currentTime > campaignStartDate &&
        rowData?.campaignPriority <= ioDetail.ioCurrentPriority
      ) {
        return (
          <Tooltip title={currentlyServingTooltipText} placement="top-start" arrow>
            <Badge
              color="success"
              size="small"
              variant="dot"
              classes={{
                badge: styles.badgeSuccessClass,
              }}
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip title={servingInQueueTooltipText} placement="top-start" arrow>
          <Badge
            color="secondary"
            size="small"
            variant="dot"
            classes={{
              badge: styles.badgeSecondaryClass,
            }}
          />
        </Tooltip>
      );
    }
    return null;
  };

  const renderTooltip = () => {
    if (rowData?.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg) {
      return 'Campaign priority is not applicable for PG Campaigns';
    }
    return getDisablePriorityEditTooltip(rowData, ioDetail);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between', width: 100 }}>
      {renderStatus()}
      <Tooltip
        sx={{ width: 100, textAlign: 'right' }}
        arrow
        title={renderTooltip()}
        placement="top-start"
      >
        <Typography>{rowData.campaignPriority || '-'}</Typography>
      </Tooltip>
    </Box>
  );
};
