import React from 'react';
import { Box, IconButton, Popover, Typography, Paper, Badge, Link, SvgIcon } from '@applift/factor';
import { Filter, Redo } from '@applift/icons';

interface FilterOpenerButtonProps {
  children: React.ReactNode;
  clearFilters?: () => void;
  badgeContent?: number;
}

export const FilterOpenerButton = (props: FilterOpenerButtonProps) => {
  const { children, clearFilters, badgeContent } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Badge overlap="circular" badgeContent={badgeContent} color="warning">
        <IconButton
          size="small"
          sx={{ p: 2, ...(open ? { borderColor: 'primary-400' } : {}) }}
          aria-controls="mainlist-filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          id="mainlist-filter-button"
        >
          <Filter fontSize={24} sx={{ textColor: 'neutral-500' }} />
        </IconButton>
      </Badge>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 2 }}
        id="mainlist-filter-menu"
      >
        <Paper
          variant="outlined"
          style={{ minWidth: 216, width: 'fit-content' }}
          sx={{ borderColor: 'primary-400', border: 1, paddingBottom: 8 }}
        >
          <>
            <Box
              style={{ minHeight: 48, height: 'fit-content' }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'between',
                px: 12,
                py: 8,
              }}
            >
              <Typography variant="bodySmall" weight="demi" sx={{ mb: 0 }}>
                Filter
              </Typography>
              {!!clearFilters && (
                <Link
                  Component="button"
                  color="primary"
                  underline="none"
                  onClick={clearFilters}
                  sx={{ mr: 8 }}
                  disabled={!badgeContent}
                >
                  <SvgIcon sx={{ mr: 2 }} fontSize={24}>
                    <Redo />
                  </SvgIcon>
                  Clear Filters
                </Link>
              )}
            </Box>
            {children}
          </>
        </Paper>
      </Popover>
    </Box>
  );
};
