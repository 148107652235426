import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { GlobeAlt } from '@applift/icons';
import { Box, Typography } from '@applift/factor';
import classNames from 'classnames/bind';
import { Button, Icon, CollapsibleBlock } from 'factor';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { OverlayType } from 'models/Google';
import { CityOption, DistrictOption, ICountryOption, IStateOption } from 'models/Location';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ErrorCreatingResponse } from 'models/Response';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { blockStyles } from 'components/Block';
import {
  advanceActions,
  SetBlackList,
  SetCampaignSidebarInfo,
  SetWhiteList,
  ResetWhiteList,
  ResetBlackList,
} from 'store/advance/actions';
import { SelectTechnologyField, technologyActions } from 'store/technology/actions';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { locationActions, ResetLocationState } from 'store/location/actions';
import { ResetError, applicationActions } from 'store/app/actions';
import {
  ClearPreSavedCircles,
  ClearPreSavedPolygons,
  ClearPreSavedRectangles,
  mapActions,
  SetAreaType,
  SetDrawerMode,
  SetOpenMapVisibility,
  SetSelectedLocationTab,
  SetToastMessage,
} from 'store/map/actions';
import { groupMultiselectedValues } from 'utils/helpers';
import {
  blackListNameByTagName,
  whiteListNameByTagName,
  blackListsKeys,
  whiteListsKeys,
  shapeType,
} from 'constants/location';
import { BlackLists, WhiteLists } from 'store/advance/reducer';
import { MapSearchBox } from 'pages/MainPage/CampaignPage/LocationBlock/Map/MapSearchBox';
import { ILocationFile } from 'store/location/reducer';
import { ListedTagsNames, Tags } from './Tags';
import { CountrySelectWrapper } from './CountrySelectWrapper';
import { LocationTabsContainer } from './LocationTabsContainer';
import { Map } from './Map';
import { EditableCampaignMarkers } from './EditableCampaignMarkers';

import styles from './LocationBlock.module.scss';

const cx = classNames.bind(styles);

interface Props
  extends SetCampaignSidebarInfo,
    ResetLocationState,
    ResetError,
    SetOpenMapVisibility,
    SelectTechnologyField,
    SetDrawerMode,
    SetAreaType,
    ClearPreSavedCircles,
    ClearPreSavedPolygons,
    ClearPreSavedRectangles,
    SetWhiteList,
    SetBlackList,
    SetToastMessage,
    SetSelectedLocationTab,
    ResetWhiteList,
    ResetBlackList {
  locationFiles: ILocationFile[];
  country: ICountryOption | null;
  states: IStateOption[];
  selectedStates: IStateOption[];
  errorCreating: ErrorCreatingResponse | null;
  isOpenMapVisible: boolean;
  clearLocationFiles: () => void;
  isCollapseOpen?: boolean;
  onToggle?: (isOpened: boolean) => void;
  collapseMode?: CollapseMode;
  whiteLists: WhiteLists;
  blackLists: BlackLists;
  selectedLocationTab: string;
  selectedDma: DistrictOption[];
  selectedSenates: DistrictOption[];
  selectedCities: CityOption[];
  selectedCounties: CityOption[];
  selectedHouses: DistrictOption[];
  selectedDistricts: DistrictOption[];
}

const LocationBlockComponent = (props: Props) => {
  const {
    country,
    states,
    setCampaignSidebarInfo,
    selectedStates,
    resetState,
    errorCreating,
    resetError,
    isOpenMapVisible,
    setOpenMapVisibility,
    clearLocationFiles,
    selectTechnologyField,
    isCollapseOpen,
    onToggle,
    collapseMode,
    clearPreSavedCircles,
    clearPreSavedPolygons,
    clearPreSavedRectangles,
    setWhiteList,
    setBlackList,
    whiteLists,
    blackLists,
    resetWhiteList,
    resetBlackList,
    setDrawerMode,
    setAreaType,
    setSelectedLocationTab,
    setToastMessage,
    selectedLocationTab,
    selectedDma,
    selectedSenates,
    selectedCities,
    selectedCounties,
    selectedHouses,
    selectedDistricts,
    locationFiles,
  } = props;

  const { google } = window;

  useEffect(() => {
    setDrawerMode(
      selectedLocationTab === 'customArea' ? (shapeType.circle as OverlayType) : undefined,
    );
    setAreaType(selectedLocationTab === 'customArea' ? 'Radius' : null);
  }, [selectedLocationTab, setAreaType, setDrawerMode]);

  const handleChangeCountry = (value: Option, isInitialLoad?: boolean) => {
    if (isInitialLoad && !country) {
      setCampaignSidebarInfo(CampaignInfoField.country, value);
    } else if (!country || value.value !== country.value) {
      setCampaignSidebarInfo(CampaignInfoField.country, value);
      setCampaignSidebarInfo(CampaignInfoField.states, []);
      setCampaignSidebarInfo(CampaignInfoField.dma, []);
      setCampaignSidebarInfo(CampaignInfoField.districts, []);
      setCampaignSidebarInfo(CampaignInfoField.senates, []);
      setCampaignSidebarInfo(CampaignInfoField.houses, []);
      setCampaignSidebarInfo(CampaignInfoField.zipcodes, []);
      setCampaignSidebarInfo(CampaignInfoField.cities, []);
      setCampaignSidebarInfo(CampaignInfoField.counties, []);
      clearPreSavedCircles(true);
      clearLocationFiles();
      clearPreSavedPolygons(true);
      clearPreSavedRectangles();
      resetWhiteList([...whiteListsKeys]);
      resetBlackList([...blackListsKeys]);
      selectTechnologyField({ key: CampaignInfoField.carriers, value: [] });
      resetState();
    }
  };

  const saveToStore = useCallback(
    (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => {
      setCampaignSidebarInfo(CampaignInfoField[field], value);
      if (Object.keys(whiteListNameByTagName).includes(field) && whiteLists) {
        const whiteListName = whiteListNameByTagName[field as ListedTagsNames];
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const whiteList = whiteLists[whiteListName] || [];
        const blackList = blackLists[blackListName] || [];

        if (typeof value === 'string' || typeof value === 'number') {
          setWhiteList(whiteListName, new Set([...whiteList, Number(value)]));
        } else {
          const newValues = value
            .filter((item) => !blackList.includes(Number(item.value)))
            .map(({ value }) => Number(value));
          setWhiteList(whiteListName, new Set(newValues));
        }
      }
      // We want to ensure to remove any id from blackList if it is not availalbe in the values.
      if (Object.keys(blackListNameByTagName).includes(field) && blackLists) {
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const blackList = blackLists[blackListName] || [];
        if (typeof value === 'string' || typeof value === 'number') {
          setBlackList(blackListName, new Set([...blackList, Number(value)]));
        } else {
          const newBlackList = blackList.filter(
            (item) => value.findIndex((one) => Number(one.value) === item) >= 0,
          );
          setBlackList(blackListName, new Set(newBlackList));
        }
      }
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
        resetError();
      }
    },
    [
      errorCreating,
      setCampaignSidebarInfo,
      resetError,
      setWhiteList,
      setBlackList,
      whiteLists,
      blackLists,
    ],
  );

  const saveToStoreString = useCallback(
    (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => {
      setCampaignSidebarInfo(CampaignInfoField[field], value);
      if (Object.keys(whiteListNameByTagName).includes(field) && whiteLists) {
        const whiteListName = whiteListNameByTagName[field as ListedTagsNames];
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const whiteList = whiteLists[whiteListName] || [];
        const blackList = blackLists[blackListName] || [];

        if (typeof value === 'string' || typeof value === 'number') {
          setWhiteList(whiteListName, new Set([...whiteList, String(value) as any]));
        } else {
          const newValues = value
            .filter((item) => !blackList.includes(String(item.value) as any))
            .map(({ value }) => String(value));
          setWhiteList(whiteListName, new Set(newValues) as any);
        }
      }
      // We want to ensure to remove any id from blackList if it is not availalbe in the values.
      if (Object.keys(blackListNameByTagName).includes(field) && blackLists) {
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const blackList = blackLists[blackListName] || [];
        if (typeof value === 'string' || typeof value === 'number') {
          setBlackList(blackListName, new Set([...blackList, String(value) as any]));
        } else {
          const newBlackList = blackList.filter(
            (item) => value.findIndex((one) => (String(one.value) as any) === item) >= 0,
          );
          setBlackList(blackListName, new Set(newBlackList));
        }
      }
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
        resetError();
      }
    },
    [
      errorCreating,
      setCampaignSidebarInfo,
      resetError,
      setWhiteList,
      setBlackList,
      whiteLists,
      blackLists,
    ],
  );

  useEffect(() => {
    document.body.style.overflow = isOpenMapVisible ? 'hidden' : '';
  }, [isOpenMapVisible]);

  const getHeaderObj = () => {
    return {
      title: (
        <Box sx={{ display: 'flex', gapCol: 4, alignItems: 'center' }}>
          <GlobeAlt fontSize={24} color="primary" />
          <Typography>Campaign Locations</Typography>
        </Box>
      ),
      summary: {
        Country: country?.label || '',
        States: groupMultiselectedValues(selectedStates, states.length),
        DMA: groupMultiselectedValues(selectedDma, states.length),
        Counties: groupMultiselectedValues(selectedCounties, states.length),
        Cities: groupMultiselectedValues(selectedCities, states.length),
        'Congressional Districts': groupMultiselectedValues(selectedDistricts, states.length),
        'State Senates': groupMultiselectedValues(selectedSenates, states.length),
        'State Houses': groupMultiselectedValues(selectedHouses, states.length),
      },
    };
  };

  useEffect(() => {
    const leftContainer = document.getElementById('locationLeftContainer') as HTMLDivElement;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries) => {
      // @ts-ignore
      entries.forEach((entry: ResizeObserverEntry) => {
        const rightContainer = document.getElementById('locationRightContainer') as HTMLDivElement;
        if (rightContainer) {
          rightContainer.style.height = `${entry.contentRect.height}px`;
        }
      });
    });
    resizeObserver.observe(leftContainer);
    return () => {
      resizeObserver.unobserve(leftContainer);
    };
  }, []);

  const getOpenMapUI = () => (
    <div className={styles.openMap}>
      <div className={styles.closeBtn} onClick={() => setOpenMapVisibility(false)}>
        <Icon name="Close" />
      </div>
      <div className={styles.tabAndMapWrapper}>
        <div className={`${styles.tabsWrapper} ${styles.openMapTabsWrapper}`}>
          <LocationTabsContainer
            styles={styles}
            saveToStore={saveToStore}
            saveToStoreString={saveToStoreString}
            country={country}
            isOpenMapsVisible
            selectedTab={selectedLocationTab}
            setSelectedTab={setSelectedLocationTab}
            setToastMessage={setToastMessage}
          />
        </div>
        <div className={styles.openMapGMapWrapper}>
          <Map />
        </div>
      </div>
      <div className={styles.selectedLocationsWrapper}>
        <EditableCampaignMarkers />
        <Tags />
      </div>
    </div>
  );
  const showInfoMessage = React.useMemo(
    () =>
      locationFiles.find(
        (locationFile) => locationFile.type === 'locations' && locationFile?.rowCount! > 1000,
      ),
    [locationFiles],
  );
  return (
    <div className={styles.locationsWrapper}>
      <CollapsibleBlock
        collapsible={collapseMode !== ALL_EXPANDED}
        isCollapseOpen={isCollapseOpen}
        onToggle={onToggle}
        header={getHeaderObj()}
        customStyle={{
          block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
          panelHeaderExpand: blockStyles.panelHeaderExpand,
          panelHeaderCollapse: blockStyles.panelHeaderCollapse,
        }}
      >
        <div className={styles.campaignsLocationContainer}>
          <div className={styles.topContainer}>
            <div className="row w-100">
              <div className={styles.item}>
                <CountrySelectWrapper onChange={handleChangeCountry} value={country} />
              </div>
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.leftContainer} id="locationLeftContainer">
              <div className={styles.tabsWrapper}>
                <LocationTabsContainer
                  styles={styles}
                  saveToStore={saveToStore}
                  saveToStoreString={saveToStoreString}
                  country={country}
                  selectedTab={selectedLocationTab}
                  setSelectedTab={setSelectedLocationTab}
                  setToastMessage={setToastMessage}
                />
              </div>
              {showInfoMessage && (
                <div className={styles.infoDiv}>
                  <Icon name="Info" className="mr-1" />
                  <div>Only the first 1000 locations are visible on the map.</div>
                </div>
              )}
              <div
                className={cx({
                  map_container: true,
                  map_container__visible: isOpenMapVisible,
                })}
              >
                {isOpenMapVisible ? getOpenMapUI() : <Map />}
                {google && !isOpenMapVisible ? (
                  <div className={styles.search_wrapper}>
                    <MapSearchBox hidden={selectedLocationTab === 'location'} />
                  </div>
                ) : null}

                {!isOpenMapVisible && (
                  <Button
                    className={`btn-square _cornflower-blue _map m-0 ${styles.btn} ${
                      selectedLocationTab !== 'location'
                        ? styles.btn_map
                        : styles.btn_map_locationTab
                    }`}
                    onClick={() => {
                      setOpenMapVisibility(true);
                    }}
                  >
                    <Icon
                      className={`btn-square__prefix ${styles.icon_map}`}
                      fill="#1d58d7"
                      name="EventsLocation"
                    />
                    Open Map
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.rightContainer} id="locationRightContainer">
              <EditableCampaignMarkers />
              <Tags />
            </div>
          </div>
        </div>
      </CollapsibleBlock>
    </div>
  );
};

const mapState = createSelector(
  (state: AppState) => state.advanced.sidebarCampaignInfo,
  (state: AppState) => state.advanced.states,
  (state: AppState) => state.auth,
  (state: AppState) => state.app,
  (state: AppState) => state.map,
  (state: AppState) => state.advanced.whiteLists,
  (state: AppState) => state.advanced.blackLists,
  (state: AppState) => state.location.locationFiles,
  (
    sidebarCampaignInfo,
    states,
    auth,
    appStore,
    mapStore,
    whiteLists,
    blackLists,
    locationFiles,
  ) => ({
    country: sidebarCampaignInfo[CampaignInfoField.country],
    selectedStates: sidebarCampaignInfo[CampaignInfoField.states],
    selectedDma: sidebarCampaignInfo[CampaignInfoField.dma],
    selectedSenates: sidebarCampaignInfo[CampaignInfoField.senates],
    selectedCities: sidebarCampaignInfo[CampaignInfoField.cities],
    selectedCounties: sidebarCampaignInfo[CampaignInfoField.counties],
    selectedHouses: sidebarCampaignInfo[CampaignInfoField.houses],
    selectedDistricts: sidebarCampaignInfo[CampaignInfoField.districts],
    states,
    auth,
    errorCreating: appStore.errorCreating,
    editableCampaign: appStore.editableCampaign,
    isOpenMapVisible: mapStore.isOpenMapVisible,
    whiteLists,
    blackLists,
    locationFiles,
    selectedLocationTab: mapStore.selectedLocationTab,
  }),
);

const actions = {
  setDeviceTypes: advanceActions.setDeviceTypes,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  resetState: locationActions.resetState,
  resetError: applicationActions.resetError,
  clearPreSavedCircles: mapActions.clearPreSavedCircles,
  clearPreSavedPolygons: mapActions.clearPreSavedPolygons,
  clearPreSavedRectangles: mapActions.clearPreSavedRectangles,
  setOpenMapVisibility: mapActions.setOpenMapVisibility,
  clearLocationFiles: locationActions.clearLocationFiles,
  setDrawerMode: mapActions.setDrawerMode,
  selectTechnologyField: technologyActions.selectTechnologyField,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  resetWhiteList: advanceActions.resetWhiteList,
  resetBlackList: advanceActions.resetBlackList,
  setAreaType: mapActions.setAreaType,
  setSelectedLocationTab: mapActions.setSelectedLocationTab,
  setToastMessage: mapActions.setToastMessage,
};

export const LocationBlock = connect(mapState, actions)(LocationBlockComponent);
