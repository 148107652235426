/* global google */
import React from 'react';
import { Marker } from '@react-google-maps/api';

import mapCloseMarkerImage from './img/map-close-marker.png';
import { LatLng, Point } from '../../../../../../models/Google';

interface Props {
  position: any;
  onClick: () => void;
  anchor?: Point;
  onMouseOver?: () => void;
  onMouseOut?: ({ latLng }: { latLng: LatLng }) => void;
}

export class CloseMarker extends React.PureComponent<Props> {
  render() {
    const { position, onClick, onMouseOver, onMouseOut, anchor } = this.props;

    return (
      <Marker
        position={position}
        zIndex={40}
        opacity={1}
        icon={{
          url: mapCloseMarkerImage,
          scaledSize: new google.maps.Size(24, 24),
          anchor,
        }}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    );
  }
}
