import { AxiosError, AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';

import { getInstance, getInstanceWithTimestamps } from 'api/Instance';
import { WithResponse, WithTimestamps } from 'models/Response';
import {
  IOCreateRequestData,
  IOCreateResponse,
  IODetail,
  IoBudgetImpressionInfoType,
  IoUpdateDetailsType,
  SetIOBudget,
  SetIOEndDate,
} from 'models/IO';
import { getIOBudgetImpInfoQueryKey, getIODetailsQueryKey } from './QueryKeys';

export const createIO = async (
  params: IOCreateRequestData,
): Promise<WithResponse<IOCreateResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<IOCreateResponse>> = await getInstance().post(
      '/v3/cmp/io/add',
      params,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getIOInfo = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getIODetailsQueryKey['keys']>>) => {
  const { ioId } = queryKey[0]!;
  try {
    const response: AxiosResponse<WithResponse<IODetail>> = await getInstance().get(
      `/v3/cmp/io/${ioId}`,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getIOBudgetImpInfo = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getIOBudgetImpInfoQueryKey['keys']>>) => {
  const { ioId } = queryKey[0]!;
  try {
    const response: AxiosResponse<WithResponse<IoBudgetImpressionInfoType>> =
      await getInstance().get(`/v3/cmp/io/campaign/budget-impression/info`, { params: { ioId } });
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const updateIOInfo = async (
  params: IoUpdateDetailsType,
): Promise<AxiosResponse<WithTimestamps<WithResponse<string>>>> => {
  const {
    ioEndTime,
    ioName,
    ioStartTime,
    ioTimeZoneId,
    ioTotalBudget,
    isAutoSumIoTotalBudget,
    ioTotalImpressions,
  } = params;
  try {
    const response: AxiosResponse<WithTimestamps<WithResponse<string>>> =
      await getInstanceWithTimestamps().patch(`/v3/cmp/io/${params.ioId}`, {
        ioEndTime,
        ioName: ioName?.trim(),
        ioStartTime,
        ioTimeZoneId,
        ioTotalBudget,
        isAutoSumIoTotalBudget,
        ioTotalImpressions,
      });
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setIOEndDate = async (params: SetIOEndDate) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().patch(
      '/v3/cmp/io/update-end-date',
      params,
    );

    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const setIOBudget = async (params: SetIOBudget) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().patch(
      '/v3/cmp/io/update-budget',
      params,
    );

    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export interface DeleteIOParams {
  ioIdsList: number[];
}

export const deleteIO = async (params: DeleteIOParams) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().post(
      '/v3/cmp/io/delete',
      params,
    );
    return response.data;
  } catch (err) {
    return Promise.reject((err as AxiosError).response || err);
  }
};

export interface DuplicateIOParams {
  ioExistingCampaignIds: number[];
  ioId: number;
  ioTimeZoneId: number;
  ioStartTime: number;
  ioEndTime?: number;
}

export interface DuplicateIOResponse {
  duplicatedIoId: number;
  duplicatedCampaignsIds: number[];
}

export const duplicateIO = async (params: DuplicateIOParams) => {
  try {
    const response: AxiosResponse<WithResponse<DuplicateIOResponse>> = await getInstance().post(
      '/v3/cmp/io/duplicate',
      params,
    );
    return response.data;
  } catch (err) {
    return Promise.reject((err as AxiosError).response || err);
  }
};
