import React from 'react';
import { connect } from 'react-redux';
import { LazySelect } from 'iqm-framework';
import isEqual from 'lodash/isEqual';
import { Box, Typography } from '@applift/factor';
import { UsersAlt } from '@applift/icons';
import { CollapsibleBlock } from 'factor';

import { blockStyles } from 'components/Block';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import {
  demographicActions,
  SetDemographicField,
  SelectDemographicField,
} from 'store/demographic/actions';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { AuthState } from 'store/auth2/reducer';
import { GetResponseWithData } from 'models/Response';
import { Dictionary } from 'models/Dictionary';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { DemographicState } from 'store/demographic/reducer';
import { groupMultiselectedValues } from 'utils/helpers';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';

import { SelectAgeGroupWrapper } from './SelectAgeGroupWrapper';
import { SelectGenderWrapper } from './SelectGenderWrapper';
import { SelectIncomeRangeWrapper } from './SelectIncomeRangeWrapper';
import { SelectEthnicityWrapper } from './SelectEthnicity';

type Props = SetDemographicField &
  SelectDemographicField & {
    auth: AuthState;
    demographicState: DemographicState;
    editableCampaign: ExistingCampaignData | null;
    isCollapseOpen?: boolean;
    onToggle?: (isOpened: boolean) => void;
    collapseMode?: CollapseMode;
  } & {
    [key: string]: Option<number>[];
  };

type State = {
  firstPageDataIsLoaded: boolean;
};

type Options = Option<number>[];

const ONE_PAGE_ITEMS_QTY = 100;

class DemographicsBlockComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firstPageDataIsLoaded: false,
    };
  }

  componentDidUpdate(prevProp: Props) {
    const { editableCampaign, demographicState } = this.props;
    const { firstPageDataIsLoaded } = this.state;
    const {
      age: ageOptions,
      gender: genderOptions,
      language: languageOptions,
      interest: interestOptions,
      incomeRange: incomeRangeOptions,
      nationality: nationalityOptions,
    } = demographicState;

    if (editableCampaign) {
      if (
        editableCampaign.ageRangeIds &&
        (editableCampaign !== prevProp.editableCampaign ||
          ageOptions.length !== prevProp.demographicState.age.length)
      ) {
        const ageIds = editableCampaign.ageRangeIds.split(',').map((i) => +i);
        this.handleChangeInStore(CampaignInfoField.age)(
          ageOptions.filter((o) => ageIds.includes(o.value)),
        );
      }

      if (
        editableCampaign.genderIds &&
        (editableCampaign !== prevProp.editableCampaign ||
          genderOptions.length !== prevProp.demographicState.gender.length)
      ) {
        const genderIds = editableCampaign.genderIds.split(',').map((i) => +i);
        this.handleChangeInStore(CampaignInfoField.gender)(
          genderOptions.filter((o) => genderIds.includes(o.value)),
        );
      }

      if (
        editableCampaign.languageIds &&
        !firstPageDataIsLoaded &&
        (editableCampaign !== prevProp.editableCampaign ||
          !isEqual(languageOptions, prevProp.demographicState.language))
      ) {
        const languageIds = editableCampaign.languageIds.split(',').map((i) => +i);
        this.handleChangeInStore(CampaignInfoField.language)(
          languageOptions.filter((o) => languageIds.includes(o.value)),
        );
      }

      if (
        editableCampaign.interestIds &&
        !firstPageDataIsLoaded &&
        (editableCampaign !== prevProp.editableCampaign ||
          !isEqual(interestOptions, prevProp.demographicState.interest))
      ) {
        const interestIds = editableCampaign.interestIds.split(',').map((i) => +i);
        this.handleChangeInStore(CampaignInfoField.interest)(
          interestOptions.filter((o) => interestIds.includes(o.value)),
        );
      }

      if (
        editableCampaign.incomeRangeIds &&
        (editableCampaign !== prevProp.editableCampaign ||
          incomeRangeOptions.length !== prevProp.demographicState.incomeRange.length)
      ) {
        const incomeRangeIds = editableCampaign.incomeRangeIds.split(',').map((i) => +i);
        this.handleChangeInStore(CampaignInfoField.incomeRange)(
          incomeRangeOptions.filter((o) => incomeRangeIds.includes(o.value)),
        );
      }

      if (
        editableCampaign.ethnicityIds &&
        !firstPageDataIsLoaded &&
        (editableCampaign !== prevProp.editableCampaign ||
          !isEqual(nationalityOptions, prevProp.demographicState.nationality))
      ) {
        const nationalityIds = editableCampaign.ethnicityIds.split(',').map((i) => +i);
        this.handleChangeInStore(CampaignInfoField.nationality)(
          nationalityOptions.filter((o) => nationalityIds.includes(o.value)),
        );
      }
    }
  }

  handleChangeInStore = (field: string) => (value: Options) => {
    const { selectDemographicField } = this.props;
    selectDemographicField({
      key: field,
      value,
    });
  };

  handleSetDataInStore = (field: string) => (value: Options) => {
    const { setDemographicField } = this.props;

    setDemographicField({
      key: field,
      value,
    });
  };

  setOptionsHandler = (
    response: GetResponseWithData<Dictionary[]>,
    storeKey: string,
    state?: any,
  ) => {
    const { data, filteredRecords, totalRecords } = response.responseObject;
    const transformedData = data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });

    if (state.searchField.trim().length || state.pageNo !== 1) {
      this.setState({
        firstPageDataIsLoaded: true,
      });
    }

    this.handleSetDataInStore(storeKey)(transformedData);

    return {
      filteredRecords,
      totalRecords,
      data: transformedData,
    };
  };

  getHeaderObj = () => {
    const { age, gender, language, interest, incomeRange, nationality, demographicState } =
      this.props;
    return {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
          <UsersAlt fontSize={24} sx={{ textColor: 'primary-600' }} />
          <Typography>Demographics</Typography>
        </Box>
      ),
      summary: {
        'Age Group': groupMultiselectedValues(age, demographicState.age.length),
        Gender: groupMultiselectedValues(gender, demographicState.gender.length),
        Language: groupMultiselectedValues(language, demographicState.language.length),
        Interest: groupMultiselectedValues(interest, demographicState.interest.length),
        'Income Range': groupMultiselectedValues(incomeRange, demographicState.incomeRange.length),
        Ethnicity: groupMultiselectedValues(nationality, demographicState.nationality.length),
      },
    };
  };

  virtualizationParams = () => {
    return this.props.editableCampaign
      ? {
          virtualizationProps: {
            height: 230,
            width: '100%',
            itemSize: 27,
            overscanCount: 10,
          },
        }
      : null;
  };

  render() {
    const {
      age,
      gender,
      language,
      interest,
      incomeRange,
      nationality,
      auth,
      editableCampaign,
      isCollapseOpen,
      onToggle,
      collapseMode,
    } = this.props;

    if (!auth.authorized) {
      return null;
    }

    return (
      <CollapsibleBlock
        isCollapseOpen={isCollapseOpen}
        onToggle={onToggle}
        collapsible={collapseMode !== ALL_EXPANDED}
        header={this.getHeaderObj()}
        customStyle={{
          block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
          panelHeaderExpand: blockStyles.panelHeaderExpand,
          panelHeaderCollapse: blockStyles.panelHeaderCollapse,
        }}
      >
        <div data-qa="177" className={`row ${blockStyles.blockRow}`}>
          <div data-qa="178" className="col-4">
            <SelectAgeGroupWrapper
              placeholder="Age Group"
              label="Age Group"
              value={age}
              dataPath="responseObject.data"
              onChange={this.handleChangeInStore(CampaignInfoField.age)}
              fetchedData={this.handleSetDataInStore('age')}
              tooltipParams={{
                label: 'Age Group',
              }}
            />
          </div>
          <div data-qa="179" className="col-4">
            <SelectGenderWrapper
              placeholder="Gender"
              label="Gender"
              value={gender}
              dataPath="responseObject.data"
              onChange={this.handleChangeInStore(CampaignInfoField.gender)}
              fetchedData={this.handleSetDataInStore('gender')}
              tooltipParams={{
                label: 'Gender',
              }}
            />
          </div>
          <div data-qa="180" className="col-4">
            <LazySelect
              selectSpecificProps={{
                placeholder: 'Language',
                label: 'Language',
                isSearchable: true,
              }}
              value={language}
              onChange={this.handleChangeInStore(CampaignInfoField.language)}
              apiPath="api/v2/master/segment/language"
              httpMethod="get"
              numberOfEntries={ONE_PAGE_ITEMS_QTY}
              totalRecordsPropName="totalRecords"
              transformPagingParameters={(state: any) => {
                return auth.userData
                  ? {
                      noOfEntries: state.noOfEntries,
                      pageNo: state.pageNo,
                      searchField: state.searchField,
                    }
                  : null;
              }}
              mapServerResponseData={(value, state) =>
                this.setOptionsHandler(value, 'language', state)
              }
            />
          </div>
        </div>
        <div data-qa="181" className={`row ${blockStyles.blockRow}`}>
          <div data-qa="182" className="col-4">
            <LazySelect
              selectSpecificProps={{
                placeholder: 'Interest',
                label: 'Interest',
                isSearchable: true,
                ...this.virtualizationParams(),
              }}
              value={interest}
              onChange={this.handleChangeInStore(CampaignInfoField.interest)}
              apiPath={`api/v2/master/segment/interest${
                editableCampaign ? `?campaignId=${editableCampaign.id}` : ''
              }`}
              httpMethod="get"
              numberOfEntries={ONE_PAGE_ITEMS_QTY}
              totalRecordsPropName="totalRecords"
              transformPagingParameters={(state: any) => {
                return auth.userData
                  ? {
                      noOfEntries: state.noOfEntries,
                      pageNo: editableCampaign ? -1 : state.pageNo,
                      searchField: state.searchField,
                    }
                  : null;
              }}
              mapServerResponseData={(value, state) =>
                this.setOptionsHandler(value, 'interest', state)
              }
            />
          </div>
          <div data-qa="183" className="col-4">
            <SelectIncomeRangeWrapper
              placeholder="Income Range"
              label="Income Range"
              value={incomeRange}
              dataPath="responseObject.data"
              onChange={this.handleChangeInStore(CampaignInfoField.incomeRange)}
              fetchedData={this.handleSetDataInStore('incomeRange')}
              tooltipParams={{
                label: 'Income Range',
              }}
            />
          </div>
          <div data-qa="184" className="col-4">
            <SelectEthnicityWrapper
              placeholder="Ethnicity"
              label="Ethnicity"
              value={nationality}
              dataPath="responseObject.data"
              onChange={this.handleChangeInStore(CampaignInfoField.nationality)}
              fetchedData={this.handleSetDataInStore('nationality')}
              tooltipParams={{
                label: 'Ethnicity',
              }}
            />
          </div>
        </div>
      </CollapsibleBlock>
    );
  }
}

const mapState = (state: AppState) => ({
  age: state.demographic.sidebarCampaignInfo[CampaignInfoField.age],
  gender: state.demographic.sidebarCampaignInfo[CampaignInfoField.gender],
  language: state.demographic.sidebarCampaignInfo[CampaignInfoField.language],
  interest: state.demographic.sidebarCampaignInfo[CampaignInfoField.interest],
  incomeRange: state.demographic.sidebarCampaignInfo[CampaignInfoField.incomeRange],
  nationality: state.demographic.sidebarCampaignInfo[CampaignInfoField.nationality],
  auth: state.auth,
  editableCampaign: state.app.editableCampaign,
  demographicState: state.demographic,
});

const mapAction = {
  setDemographicField: demographicActions.setDemographicField,
  selectDemographicField: demographicActions.selectDemographicField,
};

export const DemographicsBlock = connect(mapState, mapAction)(DemographicsBlockComponent);
