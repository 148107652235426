import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { AppState } from 'models/Store';
import {
  applicationActions,
  SetCollapseBlockIndex,
  SetCollapseMode,
  SetErrorCollapsiblesOpen,
} from 'store/app/actions';
import { ALL_COLLAPSED, ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { PRE_BID_AUDIENCE_TYPE_ID } from 'constants/audience';

interface Props extends SetCollapseMode, SetCollapseBlockIndex, SetErrorCollapsiblesOpen {
  children: Array<React.ReactElement<any> | null>;
  collapseMode: CollapseMode;
  openIndex: number;
  collapsibleHasError: Array<boolean>;
  forceErrorCollapsiblesOpen: boolean;
}

const CollapseControllerComponent = (props: Props) => {
  const {
    forceErrorCollapsiblesOpen,
    setErrorCollapsiblesOpen,
    collapsibleHasError,
    children,
    setCollapseMode,
    collapseMode,
    openIndex,
    setCollapseBlockIndex,
  } = props;
  const [collapsibleWithErrorHasClosed, setCollapsibleWithErrorHasClosed] = useState(
    collapsibleHasError.slice().fill(false),
  );

  const { search } = useLocation();

  const { audIds, audTypeIds, conversionIds, conversionType, preBidIds } =
    queryString.parse(search);

  useEffect(() => {
    if ((audIds || preBidIds) && audTypeIds) {
      setCollapseBlockIndex(audTypeIds.toString() === PRE_BID_AUDIENCE_TYPE_ID.toString() ? 2 : 1);
    }
    if (conversionIds && conversionType) {
      setCollapseBlockIndex(5);
    }
  }, [conversionType, conversionIds, audIds, audTypeIds, preBidIds, setCollapseBlockIndex]);

  useEffect(() => {
    if (forceErrorCollapsiblesOpen) {
      setCollapsibleWithErrorHasClosed(collapsibleHasError.slice().fill(false));

      if (collapsibleHasError.some((hasError) => hasError)) {
        setCollapseBlockIndex(-1);
      }
    }
  }, [collapsibleHasError, forceErrorCollapsiblesOpen, setCollapseBlockIndex]);

  const isCollapseOpen = useCallback(
    (currentIndex: number) => {
      if (collapseMode === ALL_EXPANDED) return true;
      if (collapseMode === ALL_COLLAPSED) return false;
      return (
        openIndex === currentIndex ||
        (collapsibleHasError[currentIndex] && !collapsibleWithErrorHasClosed[currentIndex])
      );
    },
    [openIndex, collapseMode, collapsibleWithErrorHasClosed, collapsibleHasError],
  );

  return (
    <>
      {children.map((child: React.ReactElement<any> | null, currentIndex: number) => {
        return (
          child &&
          React.cloneElement(child, {
            // eslint-disable-next-line react/no-array-index-key
            key: currentIndex,
            collapseMode,
            isCollapseOpen: isCollapseOpen(currentIndex),
            onToggle: (isOpened: boolean) => {
              if (collapsibleHasError[currentIndex]) {
                let newCollapsibleArray = collapsibleWithErrorHasClosed.slice(0, currentIndex);
                newCollapsibleArray.push(true);
                newCollapsibleArray = newCollapsibleArray.concat(
                  collapsibleWithErrorHasClosed.slice(currentIndex + 1),
                );
                setCollapsibleWithErrorHasClosed(newCollapsibleArray);
              } else {
                const newCollapsibleArray = collapsibleWithErrorHasClosed.slice().fill(true);
                setCollapsibleWithErrorHasClosed(newCollapsibleArray);
              }
              setCollapseBlockIndex(isOpened ? currentIndex : -1);
              setCollapseMode('');
              if (forceErrorCollapsiblesOpen) {
                setErrorCollapsiblesOpen(false);
              }
            },
          })
        );
      })}
    </>
  );
};

const mapState = (state: AppState) => ({
  collapseMode: state.app.collapseData.collapseMode,
  openIndex: state.app.collapseData.openIndex,
  forceErrorCollapsiblesOpen: state.app.forceErrorCollapsiblesOpen,
});

const mapAction = {
  setCollapseMode: applicationActions.setCollapseMode,
  setCollapseBlockIndex: applicationActions.setCollapseBlockIndex,
  setErrorCollapsiblesOpen: applicationActions.setErrorCollapsiblesOpen,
};

export const CollapseController = connect(mapState, mapAction)(CollapseControllerComponent);
