import { SvgIconProps } from '@applift/factor';
import {
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeVideo,
  CreativeNative,
} from '@applift/icons';

export type TCreativeTypeIds = 11 | 13 | 14 | 16 | 17 | 18 | 15;

export interface Creative {
  clickUrl: string | null;
  createdOn: number;
  created: string;
  creativeSource: string;
  creativeThumbnailSource: string;
  creativeType: string;
  creativeTypeId: TCreativeTypeIds;
  height: number;
  id: number;
  name: string;
  status: string;
  uuid: string;
  width: number;
  creativeCardSource: string | null;
  duration?: number;
  isTransformed?: number;
  campaignCount: number;
  pixelUrls?: string[];
  isHeader?: boolean;
  creativeScreenshotSource?: string;
  label?: string;
  value?: number;
  previewFlag: 0 | 1 | -1;
  nativeMediaType?: string;
}

export interface CreativesBasicInfoResponse {
  id: number;
  name: string;
  creativeTypeId: number;
  creativeType: string;
  status: string;
  createdAt: number;
  modifiedAt: number;
  owId: number;
  organizationName: string;
  organizationLogo: string;
  previewFlag: number;
  creativeScreenshotSource: string;
  creativeCardSource: string;
  creativeThumbnailSource: string;
  duration: number;
  pixelUrls: string[];
  clickUrl: string;
  height: number;
  width: number;
}

export const creativesIDMap: { [key: number]: string } = {
  11: 'CreativeImage',
  13: 'CreativeHtml',
  14: 'CreativeVideo',
  17: 'CreativeAudio',
  15: 'CreativeNative',
};

export const creativesIDMapV2: { [key: number]: string } = {
  11: 'Image',
  13: 'HTML',
  14: 'Video',
  15: 'Native',
  17: 'Audio',
};

export const creativesStatusMapper: { [key: string]: string } = {
  running: '2',
  pending: '1',
  rejected: '5',
  deleted: '4',
  paused: '3',
};

export const creativeTypeWithIconMap: {
  [key: string]: React.MemoExoticComponent<
    React.ForwardRefExoticComponent<
      Omit<SvgIconProps, 'children'> & React.RefAttributes<HTMLOrSVGElement>
    >
  >;
} = {
  Image: CreativeImage,
  HTML: CreativeHTML,
  Video: CreativeVideo,
  Audio: CreativeAudio,
  Native: CreativeNative,
};

export const IMAGE_CREATIVE_ID = 11;
export const HTML_CREATIVE_ID = 13;
export const VIDEO_CREATIVE_ID = 14;
export const NATIVE_CREATIVE_ID = 15;
export const TICKER_CREATIVE_ID = 16;
export const AUDIO_CREATIVE_ID = 17;
export const GOTV_CREATIVE_ID = 18;
