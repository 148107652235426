import React, { useEffect, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Select, Dialog, DialogContent, DialogButtons, Checkbox } from 'factor';

import { RectangleExtended } from 'models/RectangleExtended';
import { PolygonExtended } from 'models/PolygonExtended';
import { CircleExtended } from 'models/CircleExtended';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { ICountryOption, IStateOption } from 'models/Location';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Option } from 'models/Option';
import {
  advanceActions,
  SetCampaignSidebarInfo,
  SetStates,
  ResetCampaignSidebarInfo,
  ResetBlackList,
  ResetWhiteList,
} from 'store/advance/actions';
import {
  mapActions,
  ClearPreSavedRectangles,
  ClearPreSavedPolygons,
  ClearPreSavedCircles,
  RemoveRectangleFromPreSaved,
  RemovePolygonFromPreSaved,
  RemoveCircleFromPreSaved,
} from 'store/map/actions';
import { API } from 'api';
import { reverseFilterFiguresByStates } from 'utils/figures';
import {
  blackListNameByTagName,
  shapeType,
  US_COUNTRY_ID,
  whiteListNameByTagName,
} from 'constants/location';
import { CircleTags } from '../Tags/CircleTags';
import { PolygonTags } from '../Tags/PolygonTags';
import { RectangleTags } from '../Tags/RectangleTags';

import styles from './index.module.scss';

const STORAGE_NAME = 'removeFiguresOnStateDeselection';

type Props = SetCampaignSidebarInfo &
  RemoveCircleFromPreSaved &
  RemovePolygonFromPreSaved &
  RemoveRectangleFromPreSaved &
  ClearPreSavedCircles &
  ClearPreSavedPolygons &
  ClearPreSavedRectangles &
  ResetBlackList &
  ResetWhiteList &
  ResetCampaignSidebarInfo &
  SetStates & {
    selectedStates: IStateOption[];
    states: IStateOption[];
    country: ICountryOption | null;
    editableCampaign: ExistingCampaignData | null;
    preSavedCircles: CircleExtended[];
    preSavedPolygons: PolygonExtended[];
    preSavedRectangles: RectangleExtended[];
  };

const StateSelectWrapperComponent = (props: Props) => {
  const {
    selectedStates,
    states,
    country,
    setCampaignSidebarInfo,
    setStates,
    editableCampaign,
    preSavedCircles,
    preSavedRectangles,
    preSavedPolygons,
    removeCircleFromPreSaved,
    removePolygonFromPreSaved,
    removeRectangleFromPreSaved,
    clearPreSavedCircles,
    clearPreSavedPolygons,
    clearPreSavedRectangles,
    resetBlackList,
    resetWhiteList,
    resetCampaignSidebarInfo,
  } = props;

  const selectStatesHandler = useCallback(
    (value: Option<number>[]) => {
      if (value.length > 5) {
        resetCampaignSidebarInfo([
          CampaignInfoField.districts,
          CampaignInfoField.senates,
          CampaignInfoField.houses,
        ]);
        resetWhiteList([
          whiteListNameByTagName.districts,
          whiteListNameByTagName.senates,
          whiteListNameByTagName.houses,
        ]);
        resetBlackList([
          blackListNameByTagName.districts,
          blackListNameByTagName.senates,
          blackListNameByTagName.houses,
        ]);
      }
      setCampaignSidebarInfo(CampaignInfoField.states, value);
    },
    [setCampaignSidebarInfo, resetWhiteList, resetBlackList, resetCampaignSidebarInfo],
  );

  const initializedRef = useRef(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [tempStates, setTempStates] = useState<Option<number>[]>([]);
  const [remember, setRemember] = useState<boolean>(localStorage.getItem(STORAGE_NAME) === 'true');
  const [circlesToBeRemoved, setCirclesToBeRemoved] = useState<CircleExtended[]>([]);
  const [polygonsToBeRemoved, setPolygonsToBeRemoved] = useState<PolygonExtended[]>([]);
  const [rectanglesToBeRemoved, setRectanglesToBeRemoved] = useState<RectangleExtended[]>([]);
  const countryRef = useRef<ICountryOption | null>(country);

  useEffect(() => {
    const fetchStates = async () => {
      if (country) {
        const response = await API.List.States({
          parentSegmentIds: [country.value || US_COUNTRY_ID],
        });
        setStates(response);
      }
    };

    if (!states.length || countryRef.current?.value !== country?.value) {
      countryRef.current = country;
      fetchStates();
    }
  }, [country, setStates, selectStatesHandler, states.length]);

  const getConcatenatedIds = (
    blackListedStateIds: string | undefined,
    whiteListedStateIds: string | undefined,
  ) => {
    if (blackListedStateIds && whiteListedStateIds) {
      return `${blackListedStateIds},${whiteListedStateIds}`;
    }

    return blackListedStateIds || whiteListedStateIds;
  };

  useEffect(() => {
    if (
      !initializedRef.current &&
      editableCampaign &&
      (editableCampaign.blackListedStateIds || editableCampaign.whiteListedStateIds) &&
      states.length
    ) {
      if (selectedStates.length) {
        initializedRef.current = true;
        return;
      }
      const stateIds = getConcatenatedIds(
        editableCampaign.blackListedStateIds,
        editableCampaign.whiteListedStateIds,
      )?.split(',');
      const editableCampaignSelectedStates = states.filter((s) =>
        stateIds?.includes(s.value.toString()),
      );
      if (editableCampaignSelectedStates.length) {
        selectStatesHandler(editableCampaignSelectedStates);
        initializedRef.current = true;
      }
    }
  }, [editableCampaign, states, selectStatesHandler, selectedStates]);

  const removePreselectedItemFromStore = useCallback(
    (type: string) => (item: Option) => {
      switch (type) {
        case shapeType.circle: {
          const foundedCircles = preSavedCircles.find((i) => i.id === item.value);
          if (foundedCircles) {
            removeCircleFromPreSaved(foundedCircles);
          }
          break;
        }
        case shapeType.polygon: {
          const foundedPolygons = preSavedPolygons.find((i) => i.id === item.value);
          if (foundedPolygons) {
            removePolygonFromPreSaved(foundedPolygons);
          }
          break;
        }
        case shapeType.rectangle: {
          const foundedRectangles = preSavedRectangles.find((i) => i.id === item.value);
          if (foundedRectangles) {
            removeRectangleFromPreSaved(foundedRectangles);
          }
          break;
        }
        default:
          break;
      }
    },
    [
      preSavedCircles,
      preSavedPolygons,
      preSavedRectangles,
      removeCircleFromPreSaved,
      removePolygonFromPreSaved,
      removeRectangleFromPreSaved,
    ],
  );

  const removeFigures = useCallback(
    (circles: CircleExtended[], polygons: PolygonExtended[], rectangles: RectangleExtended[]) => {
      if (circles.length) {
        for (let i = 0; i < circles.length; i += 1) {
          const element = circles[i];
          removePreselectedItemFromStore(shapeType.circle)({ value: element.id, label: '' });
        }
      }

      if (polygons.length) {
        for (let i = 0; i < polygons.length; i += 1) {
          const element = polygons[i];
          removePreselectedItemFromStore(shapeType.polygon)({ value: element.id, label: '' });
        }
      }

      if (rectangles.length) {
        for (let i = 0; i < rectangles.length; i += 1) {
          const element = rectangles[i];
          removePreselectedItemFromStore(shapeType.rectangle)({ value: element.id, label: '' });
        }
      }
    },
    [removePreselectedItemFromStore],
  );

  useEffect(() => {
    const rectangles = reverseFilterFiguresByStates(preSavedRectangles, tempStates);
    const polygons = reverseFilterFiguresByStates(preSavedPolygons, tempStates);
    const circles = reverseFilterFiguresByStates(preSavedCircles, tempStates);
    setRectanglesToBeRemoved(rectangles);
    setCirclesToBeRemoved(circles);
    setPolygonsToBeRemoved(polygons);

    if (localStorage.getItem(STORAGE_NAME) === 'true' && tempStates.length) {
      selectStatesHandler(tempStates);
      setTempStates([]);
      removeFigures(circles, polygons, rectangles);
    } else if (rectangles.length || polygons.length || circles.length) {
      setDialogIsOpen(true);
    } else if (tempStates.length) {
      selectStatesHandler(tempStates);
      setTempStates([]);
    }
  }, [
    tempStates,
    preSavedCircles,
    preSavedPolygons,
    preSavedRectangles,
    country,
    selectStatesHandler,
    remember,
    removeFigures,
  ]);

  const clearPreselectedItemFromStore = (type: string) => () => {
    switch (type) {
      case shapeType.circle:
        clearPreSavedCircles();
        break;
      case shapeType.polygon:
        clearPreSavedPolygons();
        break;
      case shapeType.rectangle:
        clearPreSavedRectangles();
        break;
      default:
        break;
    }
  };

  const toggleRemember = (value: boolean) => {
    setRemember(value);
  };

  const renderDialog = () => {
    return country ? (
      <Dialog open={dialogIsOpen}>
        <DialogContent>
          <h5 data-qa="315" className="title-card">
            Change States
          </h5>
          <div data-qa="316" className="mt-4 mb-4">
            <p data-qa="317" className="mb-2">
              The following are out of the selected states and will not be saved. Are you sure?
            </p>
            <ul data-qa="318" className={styles.chip}>
              <CircleTags
                circles={circlesToBeRemoved}
                removeItemFromStore={removePreselectedItemFromStore}
                removeGroupFromStore={clearPreselectedItemFromStore}
                chipClassName="_black"
                readonly
              />
              <PolygonTags
                polygons={polygonsToBeRemoved}
                removeItemFromStore={removePreselectedItemFromStore}
                removeGroupFromStore={clearPreselectedItemFromStore}
                chipClassName="_black"
                readonly
              />
              <RectangleTags
                rectangles={rectanglesToBeRemoved}
                removeItemFromStore={removePreselectedItemFromStore}
                removeGroupFromStore={clearPreselectedItemFromStore}
                chipClassName="_black"
                readonly
              />
            </ul>
          </div>
          <div data-qa="319" className="d-flex justify-content-between">
            <Checkbox
              label="Don't ask me again"
              size="sm"
              checked={remember}
              onChange={toggleRemember}
            />
            <DialogButtons
              buttons={[
                {
                  title: 'Cancel',
                  handler: () => {
                    setDialogIsOpen(false);
                    setTempStates([]);
                  },
                },
                {
                  title: 'Change',
                  handler: () => {
                    setDialogIsOpen(false);
                    removeFigures(circlesToBeRemoved, polygonsToBeRemoved, rectanglesToBeRemoved);
                    selectStatesHandler(tempStates);
                    setTempStates([]);
                    if (remember) {
                      localStorage.setItem(STORAGE_NAME, 'true');
                    }
                  },
                  className: 'btn-square _persimmon _filled',
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    ) : null;
  };

  const hasFigures = (): boolean => {
    return !!(preSavedCircles.length || preSavedPolygons.length || preSavedRectangles.length);
  };

  return (
    <>
      <Select
        placeholder="Select States"
        label="States"
        showControlLabel
        options={states}
        value={selectedStates}
        isSearchable
        isMulti
        allSelectable
        isClearable
        withConfirmation
        onChange={(value: Option<number>[]) => {
          if (!hasFigures()) {
            selectStatesHandler(value);
          } else if (value.length) {
            setTempStates(value);
          } else {
            selectStatesHandler([]);
          }
        }}
        tooltipParams={{
          label: 'Campaign will be served only in selected States',
          position: 'bottom-left',
          auto: false,
        }}
      />
      {renderDialog()}
    </>
  );
};

const mapState = (state: AppState) => ({
  selectedStates: state.advanced.sidebarCampaignInfo[CampaignInfoField.states],
  states: state.advanced.states,
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  editableCampaign: state.app.editableCampaign,
  preSavedCircles: state.map.preSavedCircles,
  preSavedPolygons: state.map.preSavedPolygons,
  preSavedRectangles: state.map.preSavedRectangles,
});

const mapAction = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  setStates: advanceActions.setStates,
  removeCircleFromPreSaved: mapActions.removeCircleFromPreSaved,
  removePolygonFromPreSaved: mapActions.removePolygonFromPreSaved,
  removeRectangleFromPreSaved: mapActions.removeRectangleFromPreSaved,
  clearPreSavedCircles: mapActions.clearPreSavedCircles,
  clearPreSavedPolygons: mapActions.clearPreSavedPolygons,
  clearPreSavedRectangles: mapActions.clearPreSavedRectangles,
  resetCampaignSidebarInfo: advanceActions.resetCampaignSidebarInfo,
  resetBlackList: advanceActions.resetBlackList,
  resetWhiteList: advanceActions.resetWhiteList,
};

export const StateSelectWrapper = connect(mapState, mapAction)(StateSelectWrapperComponent);
