export enum CampaignInfoField {
  id = 'id',
  ioId = 'ioId',
  name = 'campaignName',
  group = 'campaignGroups',
  totalBudget = 'totalBudget',
  dailyBudget = 'dailyBudget',
  maxBid = 'maxBid',
  baseBid = 'baseBid',
  timezone = 'timezone',
  start = 'startDate',
  end = 'endDate',
  country = 'country',
  domain = 'domain',
  publishers = 'selectedPublishers',
  deviceTypes = 'selectedDeviceTypes',
  os = 'selectedOperetingSystems',
  carriers = 'selectedCarriers',
  network = 'selectedNetworkTypes',
  traffic = 'selectedTrafficTypes',
  manufacturers = 'selectedManufacturers',
  states = 'selectedStates',
  cities = 'selectedCities',
  counties = 'selectedCounties',
  age = 'selectedAge',
  gender = 'selectedGender',
  language = 'selectedLanguage',
  interest = 'selectedInterest',
  incomeRange = 'selectedIncomeRange',
  nationality = 'selectedNationality',
  zipcodes = 'selectedZipCodes',
  geoRadiuses = 'selectedGeoRadiuses',
  dma = 'selectedDma',
  districts = 'selectedCongressionalDistricts',
  senates = 'selectedSenates',
  houses = 'selectedHouses',
  inventoryGroups = 'selectedInventoryGroups',
  pmpDeals = 'selectedPmpDeals',
  dealGroups = 'selectedDealGroups',
  scheduling = 'selectedScheduling',
  exchanges = 'selectedExchanges',
  whiteListedDeviceId = 'selectedWhiteListedDeviceId',
  whiteListedIp = 'selectedWhiteListedIp',
  whiteListedAppId = 'selectedWhiteListedAppId',
  whiteListedPackageName = 'selectedWhiteListedPackageName',
  whiteListedSiteDomain = 'selectedWhiteListedSiteDomain',
  blackListedDeviceId = 'selectedBlackListedDeviceId',
  blackListedIp = 'selectedBlackListedIp',
  blackListedAppId = 'selectedBlackListedAppId',
  blackListedPackageName = 'selectedBlackListedPackageName',
  blackListedSiteDomain = 'selectedBlackListedSiteDomain',
  audienceTypes = 'selectedAudienceTypes',
  audiences = 'selectedAudiences',
  conversions = 'conversionIds',
  phone = 'phone',
  message = 'message',
  isBidShading = 'isBidShading',
}

export const statusIconMap: { [key: string]: string } = {
  draft: 'StatusDraftColor',
  pending: 'StatusPendingColor',
  paused: 'StatusPausedColor',
  running: 'StatusRunningColor',
  expired: 'StatusExpiredColor',
  deleted: 'StatusDeletedColor',
  rejected: 'StatusRejectedColor',
  failed: 'StatusFailedColor',
};
