import * as React from 'react';
import {
  Paper,
  Box,
  Row,
  Typography,
  Col,
  TextField,
  Checkbox,
  FormLabel,
  Alert,
  Tooltip,
  IconButton,
  Button,
  enqueueSnackbar,
} from '@applift/factor';
import {
  Close,
  SemanticInformation,
  IoImpression,
  StatusReady,
  IoCurrency,
  StatusExpired,
  StatusDeleted,
} from '@applift/icons';

import { SingleDatePicker } from 'components/SingleDatePicker';
import { Timezone } from 'components/Timezone';
import { IODetail, IoUpdateDetailsType } from 'models/IO';
import { compareTwoTimestampsAndIncrease } from 'utils/date';
import { useTimezone } from 'hooks/useTimezone';
import { Timezone as TimeZoneType } from 'models/Timezone';
import { OptionId } from 'models/Option';
import { BUDGET_TYPE_ID, IO_STATUSES } from 'constants/apps';
import { useIOBudgetImpInfo } from 'hooks/useIO';
import { DEFAULT_TIME_ZONE, IO_DISTRIBUTION_METHOD, IO_STATUS_ID } from 'constants/insertionOrder';
import { CurrencyFormat, numberFormat } from 'utils/format';
import { DataDogLogger } from 'services/DataDog';
import { START_END_TIME_DIFF } from 'constants/messages';
import { AmountField } from '../../../CampaignPage/CampaignInfoBlock/AmountField';
import { ConfirmCampaignPriorityRemovalDialog } from './components/ConfirmCampaignPriorityRemovalDialog';

import styles from './styles.module.scss';

export interface IODetailsWidgetPropTypes {
  ioDetail: IODetail | undefined;
  setIoDetails: React.Dispatch<React.SetStateAction<IoUpdateDetailsType | undefined>>;
  isIoStartDateEditable: boolean;
}

export const IODetailsWidget = (props: IODetailsWidgetPropTypes) => {
  const { ioDetail, setIoDetails, isIoStartDateEditable } = props;
  const [ioName, setIoName] = React.useState('');
  const [insertionOrderBudget, setInsertionOrderBudget] = React.useState('');
  const [isBudgetSameAsCampaigns, setIsBudgetSameAsCampaigns] = React.useState(false);
  const [endDate, setEndDate] = React.useState<number | null>(null);
  const [startDate, setStartDate] = React.useState<number>(0);
  const [ioTimeZone, setIOTimezone] = React.useState<OptionId<string> | undefined>(undefined);
  const [showBudgetAlert, setShowBudgetAlert] = React.useState<boolean>(true);
  const [valueToUpdateComponent, setValueToRerender] = React.useState<boolean>(true);
  const [isConfirmingPriorityRemoval, setIsConfirmingPriorityRemoval] = React.useState(false);

  const iONameUpdatedRef = React.useRef(false);

  const timezoneFetch = useTimezone();

  const { data: ioBudgetImpInfo } = useIOBudgetImpInfo(String(ioDetail?.ioId)!, {
    enabled: !!ioDetail?.ioId,
  });

  const {
    totalSpentBudget,
    totalBudget,
    totalAllocatedBudget,
    totalImpressions,
    totalAllocatedImpression,
    totalSpentImpression,
  } = React.useMemo(
    () =>
      ioBudgetImpInfo?.data ?? {
        totalSpentBudget: 0,
        totalBudget: 0,
        totalAllocatedBudget: 0,
        totalImpressions: 0,
        totalAllocatedImpression: 0,
        totalSpentImpression: 0,
      },
    [ioBudgetImpInfo?.data],
  );

  React.useEffect(() => {
    if (ioDetail) {
      setIoName(ioDetail.ioName);
      setIsBudgetSameAsCampaigns(ioDetail.isAutoSumIoTotalBudget);
      if (ioDetail.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
        setInsertionOrderBudget(ioDetail.ioTotalBudget.toString());
      }
      if (ioDetail.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
        setInsertionOrderBudget(
          ioDetail?.ioTotalImpressions ? ioDetail?.ioTotalImpressions.toString() : '0',
        );
      }
      if (ioDetail.ioEndTime) {
        setEndDate(ioDetail.ioEndTime);
      }

      setStartDate(ioDetail.ioStartTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ioDetail]);

  React.useEffect(() => {
    if (ioDetail && timezoneFetch.isSuccess) {
      const selectedTimezone = timezoneFetch.data?.find(
        (item) => item.id === ioDetail?.ioTimeZoneId,
      ) as TimeZoneType;
      if (selectedTimezone?.id) {
        setIOTimezone({
          id: selectedTimezone.id,
          label: selectedTimezone.label,
          value: selectedTimezone.name,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneFetch.isSuccess, ioDetail]);

  const handleChangeAndStore = (value: string | boolean) => {
    setInsertionOrderBudget(value as string);
  };

  const getBudgetDiff = React.useMemo(() => {
    if (
      Number(insertionOrderBudget) &&
      ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        totalAllocatedBudget > Number(insertionOrderBudget)) ||
        (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          totalAllocatedImpression > Number(insertionOrderBudget))) &&
      ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        Number(insertionOrderBudget) >= totalSpentBudget) ||
        (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          Number(insertionOrderBudget) >= totalSpentImpression))
    ) {
      const diff =
        ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? totalAllocatedBudget - Number(insertionOrderBudget)
          : totalAllocatedImpression - Number(insertionOrderBudget);
      const budgetToRender =
        ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? CurrencyFormat.format(diff)
          : numberFormat(diff);

      return (
        <Typography component="p" lineHeight="multi-line">
          The Insertion Order Budget is
          <Tooltip title={budgetToRender.length > 10 ? budgetToRender : ''}>
            <Typography weight="demi" sx={{ mx: 4, whiteSpace: 'nowrap' }} lineHeight="multi-line">
              {ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
                <Typography sx={{ marginRight: 2 }}>Imps</Typography>
              )}
              {budgetToRender.length <= 10
                ? budgetToRender
                : String(budgetToRender.slice(0, 10).concat('...'))}
            </Typography>
          </Tooltip>
          less than the total campaign budget. Campaigns will be paused once IO total budget is
          reached.
        </Typography>
      );
    }
    if (
      Number(insertionOrderBudget) &&
      ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        totalAllocatedBudget < Number(insertionOrderBudget)) ||
        (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          totalAllocatedImpression < Number(insertionOrderBudget))) &&
      ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        Number(insertionOrderBudget) >= totalSpentBudget) ||
        (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          Number(insertionOrderBudget) >= totalSpentImpression))
    ) {
      const diff =
        ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? Number(insertionOrderBudget) - totalAllocatedBudget
          : Number(insertionOrderBudget) - totalAllocatedImpression;
      const budgetToRender =
        ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? CurrencyFormat.format(diff)
          : numberFormat(diff);
      return (
        <Typography component="p" lineHeight="multi-line">
          The Insertion Order Budget is
          <Tooltip title={budgetToRender.length > 10 ? budgetToRender : ''}>
            <Typography weight="demi" sx={{ mx: 4, whiteSpace: 'nowrap' }} lineHeight="multi-line">
              {ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
                <Typography sx={{ marginRight: 2 }}>Imps</Typography>
              )}
              {budgetToRender.length <= 10
                ? budgetToRender
                : String(budgetToRender.slice(0, 10).concat('...'))}
            </Typography>
          </Tooltip>
          greater than the total campaign budget.
        </Typography>
      );
    }
    if (
      Number(insertionOrderBudget) === 0 &&
      ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED && totalAllocatedBudget > 0) ||
        (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          totalAllocatedImpression > 0))
    ) {
      return 'The campaigns won’t start serving until the Insertion Order Budget is set.';
    }
    return '';
  }, [
    insertionOrderBudget,
    totalAllocatedBudget,
    totalSpentBudget,
    totalSpentImpression,
    ioDetail?.ioBudgetTypeId,
    totalAllocatedImpression,
  ]);

  const amountFieldFooter = React.useMemo(() => {
    if (
      ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        totalSpentBudget > Number(insertionOrderBudget)) ||
        (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
          totalSpentImpression > Number(insertionOrderBudget))) &&
      !isBudgetSameAsCampaigns
    ) {
      return (
        <Typography
          variant="bodySmall"
          weight="demi"
          lineHeight="single-line"
          sx={{ textColor: 'danger-400', mr: 40, display: 'block' }}
        >
          The insertion order total budget can’t be less than the total spent of the campaigns
        </Typography>
      );
    }

    const getFormattedBudget = () => {
      if (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
        return Intl.NumberFormat('en-US').format(parseInt(insertionOrderBudget, 10));
      }

      return insertionOrderBudget.length <= 10
        ? CurrencyFormat.format(Number(insertionOrderBudget) || 0)
        : String(CurrencyFormat.format(Number(insertionOrderBudget)).slice(0, 10).concat('...'));
    };

    return (
      <Typography
        weight="demi"
        sx={{
          textColor: 'neutral-400',
          mt: 8,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography className={styles.innerHelpText} sx={{ mr: 4 }}>
          You will not spend more than
        </Typography>
        <Tooltip
          title={
            insertionOrderBudget.length <= 10
              ? ''
              : CurrencyFormat.format(Number(insertionOrderBudget))
          }
        >
          <Typography sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
            {ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
              <Typography className={styles.innerHelpText} sx={{ marginRight: 2 }}>
                Imps
              </Typography>
            )}
            {getFormattedBudget()}
          </Typography>
        </Tooltip>
        <Typography className={styles.innerHelpText}> in total</Typography>
      </Typography>
    );
  }, [
    insertionOrderBudget,
    isBudgetSameAsCampaigns,
    totalSpentBudget,
    totalSpentImpression,
    ioDetail?.ioBudgetTypeId,
  ]);

  const updateTimezone = (timezone: OptionId<string>) => {
    setIOTimezone(timezone);
  };

  const renderBudgetAlert = React.useCallback(() => {
    if (showBudgetAlert) {
      if (
        ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
          totalAllocatedBudget !== Number(insertionOrderBudget)) ||
          (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
            totalAllocatedImpression !== Number(insertionOrderBudget))) &&
        totalBudget !== 0 &&
        ((ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
          totalSpentBudget <= Number(insertionOrderBudget)) ||
          (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
            totalSpentImpression <= Number(insertionOrderBudget)))
      ) {
        return (
          <Alert
            sx={{ mt: 8 }}
            severity={Number(insertionOrderBudget) ? 'info' : 'error'}
            action={
              <IconButton
                onClick={() => {
                  setShowBudgetAlert(false);
                }}
              >
                <Close />
              </IconButton>
            }
          >
            {getBudgetDiff}
          </Alert>
        );
      }
    } else {
      return (
        <Box sx={{ mt: 8 }}>
          <Button
            variant="text"
            color={Math.abs(Number(insertionOrderBudget)) ? 'info' : 'error'}
            onClick={() => {
              setShowBudgetAlert(true);
            }}
            size="small"
            startIcon={<SemanticInformation />}
          >
            View Message
          </Button>
        </Box>
      );
    }

    return null;
  }, [
    getBudgetDiff,
    insertionOrderBudget,
    showBudgetAlert,
    totalAllocatedBudget,
    totalAllocatedImpression,
    ioDetail?.ioBudgetTypeId,
    totalSpentBudget,
    totalSpentImpression,
    totalBudget,
  ]);

  React.useEffect(() => {
    setIoDetails({
      isAutoSumIoTotalBudget: isBudgetSameAsCampaigns,
      ioEndTime: endDate as number,
      ioStartTime: startDate,
      ioName,
      ioId: ioDetail?.ioId,
      ioTimeZoneId: ioTimeZone?.id,
      ...(ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? { ioTotalBudget: Number(insertionOrderBudget) }
        : {}),
      ...(ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && !isBudgetSameAsCampaigns
        ? { ioTotalImpressions: Number(insertionOrderBudget) }
        : {}),
    });
  }, [
    endDate,
    insertionOrderBudget,
    ioDetail?.ioBudgetTypeId,
    ioDetail?.ioId,
    ioName,
    ioTimeZone?.id,
    isBudgetSameAsCampaigns,
    setIoDetails,
    startDate,
  ]);

  const getStatusInfo = () => {
    if (ioDetail?.ioStatusId === IO_STATUSES.ACTIVE) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StatusReady fontSize={16} color="info" />
          <Typography sx={{ ml: 4 }}>Active</Typography>
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {ioDetail?.ioStatusId === IO_STATUSES.EXPIRED ? (
          <StatusExpired fontSize={16} color="secondary" />
        ) : (
          <StatusDeleted fontSize={16} color="error" />
        )}
        <Typography sx={{ ml: 4 }}>
          {ioDetail?.ioStatusId === IO_STATUSES.EXPIRED ? 'Expired' : 'Deleted'}
        </Typography>
      </Box>
    );
  };

  const isDeleted = ioDetail?.ioStatusId === IO_STATUS_ID.DELETED;

  const isExpired = ioDetail?.ioStatusId === IO_STATUS_ID.EXPIRED;

  const getInsertionBudgetTooltip = () => {
    if (ioDetail?.ioStatusId === IO_STATUSES.DELETED) {
      return 'Total Budget can’t be edited for the Deleted IOs';
    }
    if (ioDetail?.ioStatusId === IO_STATUSES.EXPIRED) {
      return 'Total Budget can’t be edited for the Expired IOs';
    }
    if (isBudgetSameAsCampaigns) {
      return 'IO budget sets the total spending limit for all campaigns, including media cost, data cost and pre-bid cost';
    }
    return 'IO budget sets the total spending limit for all campaigns, including media cost, data cost and pre-bid cost';
  };

  const isInsertionBudgetDisable = React.useMemo(() => {
    return (
      isBudgetSameAsCampaigns ||
      ioDetail?.ioStatusId === IO_STATUSES.EXPIRED ||
      ioDetail?.ioStatusId === IO_STATUSES.DELETED
    );
  }, [isBudgetSameAsCampaigns, ioDetail?.ioStatusId]);

  const updateEndDate = (newEndDate: number) => {
    const endTimeWithThirtyMinDiff = compareTwoTimestampsAndIncrease(startDate, newEndDate, 30);

    if (newEndDate && endTimeWithThirtyMinDiff > newEndDate) {
      enqueueSnackbar(START_END_TIME_DIFF, {
        variant: 'info',
      });
    }

    if ((!newEndDate && ioDetail?.isIoPrioritise) || !ioDetail) {
      setIsConfirmingPriorityRemoval(true);
    } else {
      setEndDate(
        newEndDate && endTimeWithThirtyMinDiff > newEndDate ? endTimeWithThirtyMinDiff : newEndDate,
      );

      // forceUpdate as we need to re-render even setting same val
      setValueToRerender(!valueToUpdateComponent);
    }
  };

  const updateStartDate = (newStartDate: number) => {
    if (!endDate) {
      setStartDate(newStartDate);
    } else {
      const endTimeWithThirtyMinDiff = compareTwoTimestampsAndIncrease(newStartDate, endDate, 30);

      if (endTimeWithThirtyMinDiff > endDate) {
        enqueueSnackbar(START_END_TIME_DIFF, {
          variant: 'info',
        });
        setEndDate(endTimeWithThirtyMinDiff);
      }

      setStartDate(newStartDate);
    }

    setValueToRerender(!valueToUpdateComponent);
  };

  return (
    <>
      <Paper elevation={2} key={Number(valueToUpdateComponent)}>
        <Box sx={{ px: 16, pb: 12, pt: 24 }}>
          <Row sx={{ mb: 4 }}>
            <Col xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 16 }}>
                {ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                  <IoCurrency color="primary" fontSize={24} />
                ) : (
                  <IoImpression color="primary" fontSize={24} />
                )}
                <Typography variant="bodyLarge" weight="demi" sx={{ mb: 0, ml: 8 }}>
                  Insertion Order Info
                </Typography>
                <Box
                  style={{ width: '6px', height: '6px' }}
                  sx={{ borderRadius: 'circle', mx: 8, bgColor: 'neutral-400' }}
                />
                {getStatusInfo()}
              </Box>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row sx={{ gapRow: 24 }}>
                <Col md={6}>
                  <TextField
                    sx={{ width: 100 }}
                    label={
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}
                      >
                        <Typography variant="label">Insertion order name</Typography>
                        {!isDeleted && <Typography variant="label">{ioName.length}/255</Typography>}
                      </Box>
                    }
                    InputProps={{
                      disabled: isDeleted,
                      readOnly: isDeleted,
                    }}
                    variant={isDeleted ? 'outlined' : 'outlinedDash'}
                    placeholder="Insertion order name"
                    value={ioName}
                    onChange={(e: any) => {
                      iONameUpdatedRef.current = true;
                      setIoName(e.target.value?.trim() ? e.target.value : '');
                    }}
                    error={(!ioName.length || ioName.length > 255) && iONameUpdatedRef.current}
                    helperText={
                      !ioName.length && iONameUpdatedRef.current ? (
                        <Typography variant="label">Insertion order name can’t be empty</Typography>
                      ) : (
                        ' '
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Timezone
                    label="Timezone"
                    onChange={updateTimezone}
                    value={ioTimeZone || DEFAULT_TIME_ZONE}
                    timezoneTextFieldProps={{
                      disabled: isDeleted,
                      variant: isDeleted ? 'outlined' : 'outlinedDash',
                    }}
                  />
                </Col>
                <Col md={6}>
                  <SingleDatePicker
                    label="Start Date"
                    updateDate={updateStartDate}
                    date={startDate}
                    placeholderText="Select Start Date"
                    timezone={ioTimeZone}
                    isDisabled={!isIoStartDateEditable}
                    addedMinutes={0}
                  />
                </Col>
                <Col md={6}>
                  <SingleDatePicker
                    label="End Date"
                    updateDate={updateEndDate}
                    date={endDate as number}
                    placeholderText={isDeleted ? '—' : 'Select End Date (Optional)'}
                    errorMsg={
                      endDate && startDate >= endDate
                        ? 'End date must be after the start date'
                        : undefined
                    }
                    timezone={ioTimeZone}
                    isClearable
                    datePickerstyles={styles.datePicker}
                    warningMsg={
                      ioDetail?.ioStatusId === IO_STATUSES.EXPIRED
                        ? 'Update the end date to make the IO active'
                        : undefined
                    }
                    isDisabled={isDeleted}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                  <AmountField
                    fieldLabel="Insertion Order Budget"
                    isBlue={!isInsertionBudgetDisable}
                    value={insertionOrderBudget}
                    placeholder="0,000.00"
                    handleChange={handleChangeAndStore}
                    tooltipText={getInsertionBudgetTooltip()}
                    disabled={isInsertionBudgetDisable}
                    budgetTypeId={ioDetail?.ioBudgetTypeId}
                  />
                  <Box sx={{ pr: 24 }}>{amountFieldFooter}</Box>
                  <Box>
                    <Checkbox
                      disabled={isDeleted}
                      checked={isBudgetSameAsCampaigns}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
                            setInsertionOrderBudget(totalBudget.toString());
                          }
                          if (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
                            setInsertionOrderBudget(totalImpressions.toString());
                          }
                        } else {
                          if (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
                            setInsertionOrderBudget(ioDetail.ioTotalBudget.toString());
                          }
                          if (ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
                            setInsertionOrderBudget(
                              ioDetail?.ioTotalImpressions
                                ? ioDetail?.ioTotalImpressions.toString()
                                : '0',
                            );
                          }
                        }
                        setIsBudgetSameAsCampaigns(e.target.checked);
                      }}
                    />
                    <FormLabel disabled={!totalBudget || isDeleted || isExpired}>
                      Keep same as total campaign budget
                    </FormLabel>
                  </Box>
                </Col>
                <Col>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 2 }}>Budget Distribution Method:</Typography>{' '}
                    <Typography weight="demi">
                      {ioDetail
                        ? IO_DISTRIBUTION_METHOD[
                            ioDetail.ioBudgetDistributionMethodId as keyof typeof IO_DISTRIBUTION_METHOD
                          ]
                        : null}
                    </Typography>
                  </Box>
                  {/* Removed the below code for the release 2.2.0 */}
                  {/* {ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 8 }}>
                    <CurrencyDollar fontSize={16} color="secondary" />{' '}
                    <Typography sx={{ mr: 2 }}>Estimated IO Budget:</Typography>
                    <Typography weight="demi">{CurrencyFormat.format(totalBudget)}</Typography>
                  </Box>
                )} */}
                  {renderBudgetAlert()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Paper>
      {!!isConfirmingPriorityRemoval && (
        <ConfirmCampaignPriorityRemovalDialog
          onClose={() => {
            setIsConfirmingPriorityRemoval(false);
            setValueToRerender(!valueToUpdateComponent);
          }}
          onConfirm={() => {
            DataDogLogger.IODetailsPage.removeCampaignPrioritiesForIOEndDate();
            setIsConfirmingPriorityRemoval(false);
            setEndDate(null);
            setValueToRerender(!valueToUpdateComponent);
          }}
        />
      )}
    </>
  );
};
