import { createAction } from '../../utils/actions';
import { advancedTargetingConstants } from './constants';
import { Option } from '../../models/Option';
import { DetailedExchangesType } from './reducer';

export type ExchangeData = {
  key: string;
  value: Option<number>[];
};

export type DetailedExchangeData = {
  key: string;
  value: DetailedExchangesType;
};

export type Data = {
  key: string;
  value: string;
};

export type ResetState = {
  resetState: () => void;
};

export const advancedTargetingActions = {
  setExchanges(data: ExchangeData) {
    return createAction<ExchangeData>(advancedTargetingConstants.SET_EXCHANGES, data);
  },

  setDetailedExchanges(data: DetailedExchangeData) {
    return createAction<DetailedExchangeData>(advancedTargetingConstants.SET_EXCHANGES, data);
  },

  selectExchanges(data: Option<number>[]) {
    return createAction<Option<number>[]>(advancedTargetingConstants.SELECT_EXCHANGES, data);
  },

  selectAdvancedTargetingField(data: Data) {
    return createAction<Data>(advancedTargetingConstants.SELECT_ADVANCED_TARGETING_FIELD, data);
  },

  resetState() {
    return createAction(advancedTargetingConstants.RESET_ADVANCED_TARGETING_STORE);
  },

  validateExchanges() {
    return createAction(advancedTargetingConstants.VALIDATE_EXCHANGES);
  },
};
