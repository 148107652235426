import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Box, Row, Col, Typography, Button } from '@applift/factor';
import { Add, MoneyBags, InsightSearchPages, TargetAudio } from '@applift/icons';
import { CreateIODialog } from 'components/CreateIoDialog';
import { Document } from '@applift/illustrations';
import { ScreenLoader } from 'components/ScreenLoader';
import { useIoList } from 'hooks/useIoList';
import { DEFAULT_LIST_SORTING, DEFAULT_PAGE_SIZE } from 'constants/insertionOrder';
import { PATH } from 'constants/path';

import { FeatureSection } from './FeatureSection';

import styles from './styles.module.scss';

export const EmptyIoListPage = () => {
  const [openCreateIoDialog, setOpenCreateIoDialog] = React.useState(false);
  const history = useHistory();
  const {
    data,
    isLoading,
    isError,
    refetch: refetchIoList,
  } = useIoList('', DEFAULT_PAGE_SIZE, DEFAULT_LIST_SORTING, '', undefined, undefined, undefined, {
    onSuccess: (response) => {
      const totalCount = response.pages[0].data?.totalRecords;
      if (totalCount && totalCount > 0) {
        history.replace(PATH.IO_LIST);
      }
    },
  });

  const filteredRecords = React.useMemo(() => data?.pages[0]?.data?.totalRecords ?? 0, [data]);

  React.useEffect(() => {
    if (filteredRecords && filteredRecords > 0) {
      history.replace(PATH.IO_LIST);
    }
  }, [filteredRecords, history]);

  const emptyPageObject = {
    illustrations: <Document sx={{ width: 100, height: 'auto' }} />,
    title: 'Insertion Orders',
    subText:
      'An Insertion Order (IO) is a formal agreement that outlines the terms and conditions for running advertising campaigns. Add an IO to establish these terms and to ensure clarity and accountability in campaign management.',
    action: (
      <Button
        startIcon={<Add fontSize={20} />}
        variant="contained"
        color="primary"
        disableRipple
        onClick={() => {
          setOpenCreateIoDialog(true);
        }}
      >
        Create Insertion Order
      </Button>
    ),
  };
  const features = [
    {
      icon: TargetAudio,
      title: 'Strategic Campaign Planning',
      description:
        'Establish a well-defined strategy to guide your underlying advertising campaigns and optimize performance.',
    },
    {
      icon: MoneyBags,
      title: 'Budget Control',
      description: 'Gain control over campaign spending by setting predefined spending limits.',
    },
    {
      icon: InsightSearchPages,
      title: 'Comprehensive Reporting',
      description:
        'Access detailed reports and valuable insights at the IO level, providing a holistic view of all underlying campaigns.',
    },
  ];

  return !isLoading && !isError ? (
    <Container
      className={styles.container}
      sx={{
        px: { lg: 64, md: 40, sm: 32 },
      }}
    >
      <Row
        xs={{ gutterSize: 40 }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mb: 48,
        }}
      >
        <Col xs={12} sm={6} md={4}>
          <Box sx={{ textColor: 'primary-500' }}>{emptyPageObject.illustrations}</Box>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <Typography component="h1" variant="h5" weight="demi" sx={{ mb: 8 }}>
            {emptyPageObject.title}
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 16 }}
          >
            {emptyPageObject.subText}
          </Typography>
          {!!emptyPageObject.action && emptyPageObject.action}
        </Col>
      </Row>
      <Row xs={{ gutterSize: 40 }} sx={{ justifyContent: 'center' }}>
        {features.map((feature) => (
          <Col xs={features.length < 3 ? 5 : 4} key={feature.title}>
            <FeatureSection
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </Row>
      {openCreateIoDialog && (
        <CreateIODialog
          refetchIoList={refetchIoList}
          isOpen={openCreateIoDialog}
          setOpenDialog={setOpenCreateIoDialog}
          onClose={() => {
            setOpenCreateIoDialog(false);
            history.replace('/');
          }}
        />
      )}
    </Container>
  ) : (
    <Container className={styles.container}>
      <ScreenLoader text="Loading..." />
    </Container>
  );
};
