import { isBidDataValid } from 'store/app/helpers';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { bidConstants } from './constants';

export interface BidStore {
  conversionsOptimization: boolean;
  targetCpiCpa: string;
  conversionsType: boolean;
  bidOptimization: boolean;
  impressionsCapping: boolean;
  impressionsCappingValue: number;
  budgetCapping: boolean;
  impressionsMaxDaily: string;
  impressionsMaxTotal: string;
  clicksMaxDaily: string;
  clicksMaxTotal: string;
  conversionsMaxDaily: string;
  conversionsMaxTotal: string;
  bidPacing: boolean;
  appUrl: string;
  bidStoreHasErrors: boolean;
}

const defaultBidStore: BidStore = {
  bidOptimization: true,
  impressionsCapping: false,
  impressionsCappingValue: 1,
  conversionsOptimization: false,
  targetCpiCpa: '',
  conversionsType: true,
  budgetCapping: false,
  impressionsMaxDaily: '',
  impressionsMaxTotal: '',
  clicksMaxDaily: '',
  clicksMaxTotal: '',
  conversionsMaxDaily: '',
  conversionsMaxTotal: '',
  bidPacing: true,
  appUrl: '',
  bidStoreHasErrors: false,
};

const changeValue = (
  state: BidStore,
  action: Action<{ key: string; value: number | boolean }>,
): BidStore => {
  const { key, value } = action.payload;

  if (key === 'budgetCapping' && !value) {
    return {
      ...state,
      budgetCapping: false,
      clicksMaxDaily: '',
      clicksMaxTotal: '',
      conversionsMaxDaily: '',
      conversionsMaxTotal: '',
    };
  }

  return {
    ...state,
    [key]: value,
  };
};

const resetImpressionsField = (state: BidStore): BidStore => {
  return {
    ...state,
    impressionsMaxDaily: '',
    impressionsMaxTotal: '',
  };
};

const resetBidStore = (): BidStore => {
  return defaultBidStore;
};

const validateBidStore = (state: BidStore): BidStore => {
  return {
    ...state,
    bidStoreHasErrors: !isBidDataValid(state),
  };
};

const reducer = reducerFromMap<BidStore>(defaultBidStore, {
  [bidConstants.CHANGE_BID_FIELD]: changeValue,
  [bidConstants.RESET_BID_STORE]: resetBidStore,
  [bidConstants.VALIDATE_BID_STORE]: validateBidStore,
  [bidConstants.RESET_IMPRESSIONS_FIELD]: resetImpressionsField,
});

export const bid = (state: BidStore = defaultBidStore, action: Action<any>) =>
  reducer(state, action);
