import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LoadingButton,
  Typography,
} from '@applift/factor';
import { CampaignListType } from 'models/CampaignList';

interface DeleteActionProps {
  selectedCampaigns: CampaignListType[];
  onCompletion: (data: { campaignIds: string; status: 'deleted' }) => void;
  closeDialog: () => void;
  isLoading?: boolean;
}

export const DeleteAction = (props: DeleteActionProps) => {
  const { isLoading = false, selectedCampaigns, closeDialog, onCompletion } = props;

  const confirmationText = React.useMemo(() => {
    if (selectedCampaigns.length > 1) {
      return (
        <Typography component="span" variant="bodySmall">
          Delete the{' '}
          <Typography component="span" variant="bodySmall" weight="demi">
            {`${selectedCampaigns.length}`}
          </Typography>{' '}
          campaigns?
        </Typography>
      );
    }
    return (
      <>
        Are you sure you want to delete the campaign{' '}
        <Typography component="span" variant="bodySmall" weight="demi">
          {`${selectedCampaigns[0].campaignName}` || ''}
        </Typography>
        ?
      </>
    );
  }, [selectedCampaigns]);

  return (
    <Dialog
      open
      onClose={closeDialog}
      aria-labelledby="alert-dialog-delete-campaign-title"
      aria-describedby="alert-dialog-delete-campaign-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-delete-campaign-title">Delete Campaign?</DialogTitle>
      <DialogContent sx={{ pt: 16, pb: 8 }}>
        <Typography variant="bodySmall">{confirmationText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} disabled={isLoading} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={() =>
            onCompletion({
              campaignIds: selectedCampaigns.map((cmp) => cmp.campaignId).toString(),
              status: 'deleted',
            })
          }
          loading={isLoading}
          color="error"
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
