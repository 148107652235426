import React from 'react';
import { Box, Typography } from '@applift/factor';
import { ClipboardRemove } from '@applift/icons';

export const NoResultOverlay = (props: { search: string }) => {
  const { search } = props;
  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ClipboardRemove sx={{ textColor: 'neutral-500', fontSize: 40, mb: 16 }} />
      <Typography variant="bodyLarge" weight="demi">
        No results found
      </Typography>
      <Typography variant="bodyMedium">
        We can't find any items matching your search '{search}'
      </Typography>
    </Box>
  );
};
