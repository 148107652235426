/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import styles from './index.module.scss';
import { Props } from './types';

const AudioPlayer = memo((props: Props) => {
  const { className = '', getRef, source, onLoadedData, onError, onPlay = () => {} } = props;

  useEffect(() => {
    return () => {
      if (source) {
        URL.revokeObjectURL(source);
      }
    };
  }, []);

  return (
    <audio
      ref={getRef}
      controlsList="nodownload"
      className={`${styles.audio} ${className}`}
      controls
      src={source}
      onLoadedData={onLoadedData}
      onError={onError}
      onPlay={onPlay}
    />
  );
});

export default AudioPlayer;
