import React, { useState } from 'react';
import { Box, Chip, IconButton, SelectAdvanceComponents, TextField } from '@applift/factor';
import { Close, Search } from '@applift/icons';

import { RowSelectionState } from '@applift/datagrid';
import { useExchangesList } from 'hooks/useInventory';

export interface FilterProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  exchanges: RowSelectionState;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setExchanges: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  filteredRecords?: number;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export const Filters = (props: FilterProps) => {
  const {
    search,
    setSearch,
    exchanges,
    setExchanges,
    rowSelection,
    setRowSelection,
    filteredRecords,
  } = props;
  const { data, isLoading } = useExchangesList({ pageNo: -1, noOfEntries: -1 });
  const allOptions = React.useMemo(
    () =>
      data?.data?.data?.map?.((option: { name: any; id: { toString: () => any } }) => ({
        label: option.name,
        value: option.id.toString(),
      })) ?? [],
    [data],
  );

  const [exchangeSearch, setExchangeSearch] = useState('');
  const isFilterApplied = search.length > 0 || Object.values(exchanges).some(Boolean);

  const filteredOptions = React.useMemo(() => {
    return (
      allOptions?.filter?.((option: { label: string }) =>
        option.label.toLowerCase().includes(exchangeSearch.toLowerCase().trim()),
      ) ?? []
    );
  }, [allOptions, exchangeSearch]);

  const selectedRowsCount = Object.keys(rowSelection).filter((key) => rowSelection[key]).length;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'between', alignItems: 'center', p: 16 }}>
      <Box>
        {selectedRowsCount > 0 && isFilterApplied === false && (
          <Chip
            label={`${selectedRowsCount} out of ${filteredRecords ?? selectedRowsCount} selected`}
            onDelete={() => {
              setRowSelection({});
            }}
            color="secondary"
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexShrink: 0 }}>
        <TextField
          fullWidth
          variant="outlinedDash"
          placeholder="Search by Deal ID or Name"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setSearch(e.target.value)
          }
          InputProps={{
            startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
            endAdornment: search && (
              <IconButton
                onClick={() => {
                  setSearch('');
                }}
                sx={{ textColor: 'neutral-400' }}
              >
                <Close />
              </IconButton>
            ),
          }}
          sx={{ pr: 8 }}
          style={{ width: 260 }}
        />
        <TextField
          select="advanceSelect"
          fullWidth
          style={{ width: 200 }}
          SelectProps={{
            multiple: true,
            hideSelectNav: true,
            placeholder: 'Exchange',
            renderListItem: SelectAdvanceComponents.DefaultListOptionItemWrapper<LabelValuePair>({
              disableCheckbox: true,
              disableRowExpansion: true,
              selectionStyle: 'all',
            }),
            overlay: filteredOptions.length === 0 && !isLoading ? 'noResult' : undefined,
            data: filteredOptions,
            onInputChange: (_, value) => setExchangeSearch(value),
            renderValue: (val) => {
              const currentSelection = Array.isArray(val) ? val : [];
              const isAllSelected = allOptions.every((option: { value: string }) => {
                return currentSelection.some(({ value }) => option.value === value);
              });
              if (isAllSelected) {
                return 'All Selected';
                // eslint-disable-next-line
              } else if (currentSelection.length > 0) {
                return `${currentSelection.length} Selected`;
              }
              return null;
            },
            rowCount: 0,
          }}
          value={exchanges}
          onChange={(e) => setExchanges(e)}
        />
      </Box>
    </Box>
  );
};
