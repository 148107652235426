export const skeleton = {
  rows: 8,
  columns: 9,
  classes: [
    'w-80-80',
    'w-135-135',
    'w-250-650',
    'w-90-90',
    'w-150-300',
    'w-150-300',
    'w-135-135',
    'w-80-80',
    'w-80-80',
  ],
};

export const tableParams = {
  windowFreeResizeEvent: true,
  rowKeyExtractor: (d: { id: number }) => d.id,
  tbodyRowHeight: 80,
};
