import React from 'react';
import { CollapsibleBlock } from 'factor';
import { Box, Typography } from '@applift/factor';
import { AdjustmentsHorizontal } from '@applift/icons';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { formatAdvancedTargetingFields, getTargetingTooltip } from 'utils/helpers';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { AppState } from 'models/Store';
import { useDispatch, useSelector } from 'react-redux';
import { AdvancedTargetingOptionKeys } from 'store/advancedTargeting/constants';
import { UINoteIcon } from 'components/UINoteIcon';
import { advancedTargetingActions } from 'store/advancedTargeting/actions';
import { Allowlist } from './Allowlist/Allowlist';
import { Blocklist } from './Blocklist/Blocklist';

export interface AdvancedTargetingBlockProps {
  isCollapseOpen?: boolean;
  onToggle?: (isOpened: boolean) => void;
  collapseMode?: CollapseMode;
}

export const AdvancedTargetingBlock = (props: AdvancedTargetingBlockProps) => {
  const { isCollapseOpen, onToggle, collapseMode } = props;

  const {
    advancedTargeting,
    app: { editableCampaign },
  }: AppState = useSelector<AppState>((state) => state) as AppState;
  const dispatch = useDispatch();

  const getKeyIcon = (targeting: 'targeted' | 'blocked', facet: AdvancedTargetingOptionKeys) => (
    <UINoteIcon inline text={getTargetingTooltip(targeting, facet)} marginLeft={0} />
  );

  const {
    allowedDeviceIds,
    allowedIp,
    setAllowedDeviceIds,
    setAllowedIp,
    blockedDeviceIds,
    blockedIp,
    setBlockedDeviceIds,
    setBlockedIp,
  } = React.useMemo(() => {
    const sideBar = advancedTargeting.sidebarCampaignInfo;
    const setSideBar = (field: string) => {
      const { selectAdvancedTargetingField } = advancedTargetingActions;
      return (val: string) => {
        dispatch(selectAdvancedTargetingField({ key: field, value: val }));
      };
    };
    return {
      allowedDeviceIds: sideBar.selectedWhiteListedDeviceId,
      allowedIp: sideBar.selectedWhiteListedIp,
      setAllowedDeviceIds: setSideBar(CampaignInfoField.whiteListedDeviceId),
      setAllowedIp: setSideBar(CampaignInfoField.whiteListedIp),
      blockedDeviceIds: sideBar.selectedBlackListedDeviceId,
      blockedIp: sideBar.selectedBlackListedIp,
      setBlockedDeviceIds: setSideBar(CampaignInfoField.blackListedDeviceId),
      setBlockedIp: setSideBar(CampaignInfoField.blackListedIp),
    };
  }, [advancedTargeting.sidebarCampaignInfo, dispatch]);

  React.useEffect(() => {
    setAllowedDeviceIds(editableCampaign?.whiteListedDeviceId ?? '');
    setAllowedIp(editableCampaign?.whiteListedIp ?? '');
    setBlockedDeviceIds(editableCampaign?.blackListedDeviceId ?? '');
    setBlockedIp(editableCampaign?.blackListedIp ?? '');
    // eslint-disable-next-line
  }, [editableCampaign]);

  const headerObject = {
    title: (
      <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
        <AdjustmentsHorizontal fontSize={24} sx={{ textColor: 'primary-600' }} />
        <Typography>Advanced</Typography>
      </Box>
    ),
    summary: {
      'Device IDs Allowlist': formatAdvancedTargetingFields(
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedDeviceId],
      ),
      'Device IDs Blocklist': formatAdvancedTargetingFields(
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedDeviceId],
      ),
      'IPs Allowlist': formatAdvancedTargetingFields(
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedIp],
      ),
      'IPs Blocklist': formatAdvancedTargetingFields(
        advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedIp],
      ),
    },
    keyIcon: {
      'Device IDs Allowlist': getKeyIcon('targeted', 'whiteListedDeviceId'),
      'Device IDs Blocklist': getKeyIcon('blocked', 'blackListedDeviceId'),
      'IPs Allowlist': getKeyIcon('targeted', 'whiteListedIp'),
      'IPs Blocklist': getKeyIcon('blocked', 'blackListedIp'),
    },
  };

  return (
    <CollapsibleBlock
      isCollapseOpen={isCollapseOpen}
      onToggle={onToggle}
      collapsible={collapseMode !== ALL_EXPANDED}
      header={headerObject}
    >
      <Allowlist
        deviceIds={allowedDeviceIds}
        setDeviceIds={setAllowedDeviceIds}
        ip={allowedIp}
        setIp={setAllowedIp}
      />
      <Blocklist
        deviceIds={blockedDeviceIds}
        setDeviceIds={setBlockedDeviceIds}
        ip={blockedIp}
        setIp={setBlockedIp}
      />
    </CollapsibleBlock>
  );
};
