import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';

import {
  groupMultiselectedValues,
  formatScheduling,
  formatConversionsType,
  formatToBoolean,
  formatAdvancedTargetingFields,
  getTargetingTooltip,
} from 'utils/helpers';

import { UINoteIcon } from 'components/UINoteIcon';
import { AppState } from '../../../../models/Store';
import { CurrencyFormat, numberFormat } from '../../../../utils/format';
import { CampaignInfoField } from '../../../../models/CampaignInfoFields';
import { OptionId } from '../../../../models/Option';
import { AdvertiserInfoForm } from '../../../../store/advertiser/reducer';

import styles from './Index.module.scss';

const campaignInfoNameMapper = {
  [CampaignInfoField.name]: 'Name',
  [CampaignInfoField.id]: 'Campaign ID',
  [CampaignInfoField.group]: 'Group',
  [CampaignInfoField.totalBudget]: 'Budget',
  [CampaignInfoField.dailyBudget]: 'Daily Budget',
  [CampaignInfoField.maxBid]: 'Max Bid Price',
  [CampaignInfoField.isBidShading]: 'Bid Shading',
  [CampaignInfoField.timezone]: 'Timezone',
  [CampaignInfoField.start]: 'Start Date',
  [CampaignInfoField.end]: 'End Date',
  creatives: 'Creatives',
  [CampaignInfoField.country]: 'Country',
  [CampaignInfoField.states]: 'States',
  [CampaignInfoField.dma]: 'DMA',
  [CampaignInfoField.districts]: 'Districts',
  [CampaignInfoField.senates]: 'Senates',
  [CampaignInfoField.houses]: 'Houses',
  [CampaignInfoField.counties]: 'Counties',
  [CampaignInfoField.cities]: 'Cities',
  exchanges: 'Exchanges',
  [CampaignInfoField.publishers]: 'Publisher Category',
  includedInventoryGroups: 'Inventory Groups Allowlist',
  excludedInventoryGroups: 'Inventory Groups Blocklist',
  pmpDeals: 'PMP Deals',
  packageNameWL: 'Android Package Names Allowlist',
  packageNameBL: 'Android Package Names Blocklist',
  appIdWL: 'iOS Apps IDs Allowlist',
  appIdBL: 'iOS Apps IDs Blocklist',
  siteDomainWL: 'Site Domains Allowlist',
  siteDomainBL: 'Site Domains Blocklist',
  [CampaignInfoField.age]: 'Age',
  [CampaignInfoField.gender]: 'Gender',
  [CampaignInfoField.language]: 'Language',
  [CampaignInfoField.interest]: 'Interest',
  [CampaignInfoField.incomeRange]: 'Income Range',
  [CampaignInfoField.nationality]: 'Ethnicity',
  [CampaignInfoField.deviceTypes]: 'Device Type',
  [CampaignInfoField.manufacturers]: 'Manufacturers',
  [CampaignInfoField.os]: 'Operating System',
  [CampaignInfoField.carriers]: 'Carrier',
  [CampaignInfoField.network]: 'Network Type',
  [CampaignInfoField.traffic]: 'Channel',
  [CampaignInfoField.dealGroups]: 'Deal Groups',
  [CampaignInfoField.scheduling]: 'Scheduling',
  bidOptimization: 'Bid Optimization',
  bidPacing: 'Bid Pacing',
  impressionsCappingValue: 'Frequency Capping',
  conversionsOptimization: 'Conversions Optimization',
  targetCpiCpa: 'Target CPI/CPA',
  conversionsType: 'Conversions Type',
  appUrl: 'App URL',
  budgetCapping: 'Budget Capping',
  impressionsMaxDaily: 'Impressions Max Daily',
  impressionsMaxTotal: 'Impressions Max Total',
  clicksMaxDaily: 'Clicks Max Daily',
  clicksMaxTotal: 'Clicks Max Total',
  conversionsMaxDaily: 'Conversions Max Daily',
  conversionsMaxTotal: 'Conversions Max Total',
  advertiserUrl: 'Advertiser Webpage URL',
  deviceIdWL: 'Device IDs Allowlist',
  deviceIdBL: 'Device IDs Blocklist',
  ipWL: 'IPs Allowlist',
  ipBL: 'IPs Blocklist',
  includedAudiences: 'Audience Allowlist',
  excludedAudiences: 'Audience Blocklist',
  [CampaignInfoField.conversions]: 'Conversions',
};

const campaignInfoTooltipMapper: { [key: string]: string } = {
  includedAudiences: getTargetingTooltip('targeted', 'audiences'),
  excludedAudiences: getTargetingTooltip('blocked', 'audiences'),
  includedInventoryGroups: getTargetingTooltip('targeted', 'inventories'),
  excludedInventoryGroups: getTargetingTooltip('blocked', 'inventories'),
  deviceIdWL: getTargetingTooltip('targeted', 'whiteListedDeviceId'),
  deviceIdBL: getTargetingTooltip('blocked', 'blackListedDeviceId'),
  ipWL: getTargetingTooltip('targeted', 'whiteListedIp'),
  ipBL: getTargetingTooltip('blocked', 'blackListedIp'),
  packageNameWL: getTargetingTooltip('targeted', 'whiteListedPackageName'),
  packageNameBL: getTargetingTooltip('blocked', 'blackListedPackageName'),
  appIdWL: getTargetingTooltip('targeted', 'whiteListedAppId'),
  appIdBL: getTargetingTooltip('blocked', 'blackListedAppId'),
  siteDomainWL: getTargetingTooltip('targeted', 'whiteListedSiteDomain'),
  siteDomainBL: getTargetingTooltip('blocked', 'blackListedSiteDomain'),
};

const adveriserInfoNameMapper: AdvertiserInfoForm = {
  name: 'Name',
  address: 'Physical Address',
  phone: 'Phone Number',
  chief: 'Name of Chief',
  url: 'Website',
  links: 'Links',
  other: 'Other Information',
};

const DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

class SidebarCampaignInfoComponent extends React.PureComponent<any> {
  keys = Object.keys(campaignInfoNameMapper);

  advertiserKeys = Object.keys(adveriserInfoNameMapper);

  renderInfoItem = (key: string) => {
    const { props } = this;
    const value = props[key];

    if (!value) {
      return null;
    }

    const title = (campaignInfoNameMapper as any)[key];
    const tooltip = campaignInfoTooltipMapper[key] ? (
      <UINoteIcon
        text={campaignInfoTooltipMapper[key]}
        marginLeft={1}
        className={styles.titleTooltip}
        inline
      />
    ) : null;
    const listItemContent = (
      <>
        {title}
        {tooltip}: <span data-qa="140">{value}</span>
      </>
    );

    return (
      <li data-qa="139" key={key}>
        {listItemContent}
      </li>
    );
  };

  renderAdvertiserInfoItem = (key: string) => {
    const { advertiserForm } = this.props;
    const value = advertiserForm[key];
    if (!value) {
      return null;
    }

    const title = (adveriserInfoNameMapper as any)[key];

    return (
      <li data-qa="141" key={key}>
        {title}: <span data-qa="142">{value}</span>
      </li>
    );
  };

  hideAdvertiserBlock = (): boolean => {
    const { advertiserForm } = this.props;
    if (!advertiserForm.isPoliticalAdvertising) {
      return true;
    }
    return !this.advertiserKeys.filter((key) => advertiserForm[key] && advertiserForm[key].length)
      .length;
  };

  render() {
    return (
      <div data-qa="143" className={styles.info}>
        <ul data-qa="144" className={styles.infoList}>
          {this.keys.map((key: any) => {
            return this.renderInfoItem(key);
          })}
        </ul>
        {!this.hideAdvertiserBlock() ? (
          <>
            <h3 data-qa="145" className={`mt-4 mb-3 ${styles.infoTitle}`}>
              Advertiser Info
            </h3>
            <ul data-qa="146" className={styles.infoList}>
              {this.advertiserKeys.map((key: any) => this.renderAdvertiserInfoItem(key))}
            </ul>
          </>
        ) : null}
      </div>
    );
  }
}

const formatToCurrency = (value: string): string => {
  if (!value) {
    return '';
  }

  return CurrencyFormat.format(+value);
};

const getEndDate = (tz: OptionId<any> | undefined, value: number | null) => {
  if (!value) {
    return null;
  }

  return tz ? moment(value).tz(tz.value).format(DATE_FORMAT) : moment(value).format(DATE_FORMAT);
};

const mapState = (state: AppState) => {
  const o = state.advanced.sidebarCampaignInfo;
  const tz = o[CampaignInfoField.timezone];
  const country = o[CampaignInfoField.country];
  const domain = o[CampaignInfoField.domain];
  const { states } = state.advanced;
  const selectedStates = o[CampaignInfoField.states];
  const selectedPublishers = state.publishers[CampaignInfoField.publishers];
  const { publishers } = state.publishers;
  const {
    technology,
    bid,
    demographic,
    inventory,
    scheduling,
    advancedTargeting,
    audience,
    conversion,
  } = state;
  const { isBidShadingApplicable } = state.advertiser;

  const toSelectedStr = (val: any) => (val ? `${val} Selected` : '');

  const exchangesSidebar = () => {
    const { selectedExchanges } = state.advancedTargeting.sidebarCampaignInfo;
    const isAllSelected = state.advancedTargeting.exchanges?.every((exchange) => {
      return selectedExchanges?.some?.((obj) => String(obj?.value) === String(exchange?.value));
    });
    return isAllSelected ? toSelectedStr('All') : toSelectedStr(selectedExchanges.length);
  };

  const publisherCategorySidebar = () => {
    const isAllSelected = state.publishers.publishers.every((option) => {
      if (option.options) {
        return option.options?.every?.((option) => {
          return state.publishers.selectedPublishers.some(
            (obj) => String(obj.value) === String(option.value),
          );
        });
      }
      return selectedPublishers.some((obj) => String(obj.value) === String(option.value));
    });
    const groupIds = publishers.map((val) => val.value);
    const selectedPublishersCount = selectedPublishers.filter(
      (val) => !groupIds.includes(val.value),
    ).length;
    return isAllSelected ? toSelectedStr('All') : toSelectedStr(selectedPublishersCount);
  };

  return {
    [CampaignInfoField.id]: o[CampaignInfoField.id],
    [CampaignInfoField.group]: o[CampaignInfoField.group].map((op) => op.label).join(', '),
    [CampaignInfoField.totalBudget]: formatToCurrency(o[CampaignInfoField.totalBudget] || ''),
    [CampaignInfoField.dailyBudget]: formatToCurrency(
      !state.advanced.budgetPacing && o[CampaignInfoField.dailyBudget]
        ? o[CampaignInfoField.dailyBudget]
        : '',
    ),
    [CampaignInfoField.maxBid]: formatToCurrency(o[CampaignInfoField.maxBid] || ''),
    ...(isBidShadingApplicable
      ? {
          [CampaignInfoField.isBidShading]: formatToBoolean(o[CampaignInfoField.isBidShading]),
        }
      : {}),
    [CampaignInfoField.timezone]: tz ? tz.label : '',
    creatives: state.creatives.selectedCreatives.length
      ? `${state.creatives.selectedCreatives.length} selected`
      : '',
    [CampaignInfoField.start]: tz
      ? moment(o[CampaignInfoField.start]).tz(tz.value).format(DATE_FORMAT)
      : moment(o[CampaignInfoField.start]).format(DATE_FORMAT),
    [CampaignInfoField.end]: getEndDate(tz, o[CampaignInfoField.end]),
    [CampaignInfoField.country]: country ? country.label : '',
    [CampaignInfoField.states]: groupMultiselectedValues(selectedStates, states.length),
    [CampaignInfoField.dma]: o[CampaignInfoField.dma].length
      ? `${o[CampaignInfoField.dma].length} selected`
      : '',
    [CampaignInfoField.districts]: o[CampaignInfoField.districts].length
      ? `${o[CampaignInfoField.districts].length} selected`
      : '',
    [CampaignInfoField.senates]: o[CampaignInfoField.senates].length
      ? `${o[CampaignInfoField.senates].length} selected`
      : '',
    [CampaignInfoField.houses]: o[CampaignInfoField.houses].length
      ? `${o[CampaignInfoField.houses].length} selected`
      : '',
    [CampaignInfoField.counties]: o[CampaignInfoField.counties].length
      ? `${o[CampaignInfoField.counties].length} selected`
      : '',
    [CampaignInfoField.cities]: o[CampaignInfoField.cities].length
      ? `${o[CampaignInfoField.cities].length} selected`
      : '',
    [CampaignInfoField.domain]: domain ? `https://${domain}` : '',
    exchanges: exchangesSidebar(),
    [CampaignInfoField.publishers]: publisherCategorySidebar(),
    includedInventoryGroups: toSelectedStr(
      inventory.sidebarCampaignInfo.selectedInventoryGroups.filter((i) => i.included).length,
    ),
    excludedInventoryGroups: toSelectedStr(
      inventory.sidebarCampaignInfo.selectedInventoryGroups.filter((i) => !i.included).length,
    ),
    pmpDeals: toSelectedStr(state.inventory.sidebarCampaignInfo.selectedPmpDeals.length),
    packageNameWL: toSelectedStr(
      state.advancedTargeting.sidebarCampaignInfo.selectedWhiteListedPackageName
        .split(',')
        .filter(Boolean).length,
    ),
    packageNameBL: toSelectedStr(
      state.advancedTargeting.sidebarCampaignInfo.selectedBlackListedPackageName
        .split(',')
        .filter(Boolean).length,
    ),
    appIdWL: toSelectedStr(
      state.advancedTargeting.sidebarCampaignInfo.selectedWhiteListedAppId
        .split(',')
        .filter(Boolean).length,
    ),
    appIdBL: toSelectedStr(
      state.advancedTargeting.sidebarCampaignInfo.selectedBlackListedAppId
        .split(',')
        .filter(Boolean).length,
    ),
    siteDomainWL: toSelectedStr(
      state.advancedTargeting.sidebarCampaignInfo.selectedWhiteListedSiteDomain
        .split(',')
        .filter(Boolean).length,
    ),
    siteDomainBL: toSelectedStr(
      state.advancedTargeting.sidebarCampaignInfo.selectedBlackListedSiteDomain
        .split(',')
        .filter(Boolean).length,
    ),
    [CampaignInfoField.deviceTypes]: groupMultiselectedValues(
      technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes],
      technology.deviceTypes.length,
    ),
    [CampaignInfoField.manufacturers]: groupMultiselectedValues(
      technology.sidebarCampaignInfo[CampaignInfoField.manufacturers],
      technology.manufacturers.length,
    ),
    [CampaignInfoField.os]: groupMultiselectedValues(
      technology.sidebarCampaignInfo[CampaignInfoField.os],
      technology.os.length,
    ),
    [CampaignInfoField.carriers]: groupMultiselectedValues(
      technology.sidebarCampaignInfo[CampaignInfoField.carriers],
      technology.carriers.length,
    ),
    [CampaignInfoField.network]: groupMultiselectedValues(
      technology.sidebarCampaignInfo[CampaignInfoField.network],
      technology.network.length,
    ),
    [CampaignInfoField.traffic]: groupMultiselectedValues(
      technology.sidebarCampaignInfo[CampaignInfoField.traffic],
      technology.traffic.length,
    ),
    [CampaignInfoField.dealGroups]: groupMultiselectedValues(
      inventory.sidebarCampaignInfo[CampaignInfoField.dealGroups],
      inventory.dealGroups.length,
    ),
    advertiserForm: state.advertiser,
    bidOptimization: formatToBoolean(bid.bidOptimization),
    bidPacing: formatToBoolean(bid.bidPacing),
    impressionsCapping: bid.impressionsCapping ? 'Selected' : '',
    impressionsCappingValue: bid.impressionsCapping ? bid.impressionsCappingValue : '',
    conversionsOptimization: bid.conversionsOptimization ? 'Selected' : '',
    targetCpiCpa: bid.conversionsOptimization ? bid.targetCpiCpa : '',
    conversionsType: bid.conversionsOptimization ? formatConversionsType(bid.conversionsType) : '',
    appUrl:
      bid.conversionsOptimization && bid.conversionsType && bid.appUrl.trim().length
        ? `https://${bid.appUrl.trim()}`
        : '',
    budgetCapping: bid.budgetCapping ? 'Selected' : '',
    impressionsMaxDaily: bid.budgetCapping ? numberFormat(bid.impressionsMaxDaily) : '',
    impressionsMaxTotal: bid.budgetCapping ? numberFormat(bid.impressionsMaxTotal) : '',
    clicksMaxDaily: bid.budgetCapping ? bid.clicksMaxDaily : '',
    clicksMaxTotal: bid.budgetCapping ? bid.clicksMaxTotal : '',
    conversionsMaxDaily: bid.budgetCapping ? bid.conversionsMaxDaily : '',
    conversionsMaxTotal: bid.budgetCapping ? bid.conversionsMaxTotal : '',
    [CampaignInfoField.age]: groupMultiselectedValues(
      demographic.sidebarCampaignInfo[CampaignInfoField.age],
      demographic.age.length,
    ),
    [CampaignInfoField.gender]: groupMultiselectedValues(
      demographic.sidebarCampaignInfo[CampaignInfoField.gender],
      demographic.gender.length,
    ),
    [CampaignInfoField.language]: groupMultiselectedValues(
      demographic.sidebarCampaignInfo[CampaignInfoField.language],
      demographic.language.length,
    ),
    [CampaignInfoField.interest]: groupMultiselectedValues(
      demographic.sidebarCampaignInfo[CampaignInfoField.interest],
      demographic.interest.length,
    ),
    [CampaignInfoField.incomeRange]: groupMultiselectedValues(
      demographic.sidebarCampaignInfo[CampaignInfoField.incomeRange],
      demographic.incomeRange.length,
    ),
    [CampaignInfoField.nationality]: groupMultiselectedValues(
      demographic.sidebarCampaignInfo[CampaignInfoField.nationality],
      demographic.nationality.length,
    ),
    [CampaignInfoField.scheduling]: formatScheduling(
      scheduling.sidebarCampaignInfo[CampaignInfoField.scheduling],
    ),
    advertiserUrl: state.creatives.selectedCreatives.length ? state.creatives.advertiserUrl : '',
    deviceIdWL: formatAdvancedTargetingFields(
      advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedDeviceId],
    ),
    deviceIdBL: formatAdvancedTargetingFields(
      advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedDeviceId],
    ),
    ipWL: formatAdvancedTargetingFields(
      advancedTargeting.sidebarCampaignInfo[CampaignInfoField.whiteListedIp],
    ),
    ipBL: formatAdvancedTargetingFields(
      advancedTargeting.sidebarCampaignInfo[CampaignInfoField.blackListedIp],
    ),
    includedAudiences: groupMultiselectedValues(
      audience.sidebarCampaignInfo[CampaignInfoField.audiences].filter((i) => i.included),
      audience.audiences.length,
    ),
    excludedAudiences: groupMultiselectedValues(
      audience.sidebarCampaignInfo[CampaignInfoField.audiences].filter((i) => i.excluded),
      audience.audiences.length,
    ),
    [CampaignInfoField.conversions]: conversion.sidebarCampaignInfo[CampaignInfoField.conversions]
      .length
      ? `${conversion.sidebarCampaignInfo[CampaignInfoField.conversions].length} Selected`
      : '',
  };
};

export const SidebarCampaignInfo = connect(mapState)(SidebarCampaignInfoComponent);
