export const advertiserConstants = {
  SET_FIELD: 'SET_ADVERTISER_FIELD',
  RESET: 'RESET_ADVERTISER',
  SET_POLITICAL_ADV_STATUS: 'SET_POLITICAL_ADV_STATUS',
  SET_POLITICAL_ADVERTISER: 'SET_POLITICAL_ADVERTISER',
  SET_POLITICAL_ADVERTISER_LIST: 'SET_POLITICAL_ADVERTISER_LIST',
  GET_ADVERTISER_DATA: 'GET_ADVERTISER_DATA',
  START_VALIDATE_FORM: 'START_VALIDATE_FORM',
  SET_POLITICAL_ADVERTISER_VALIDATION_STATUS: 'SET_POLITICAL_ADVERTISER_VALIDATION_STATUS',
  START_ADVERTISER_CREATING: 'START_ADVERTISER_CREATING',
  CHANGE_CREATING_MODE: 'CHANGE_CREATING_MODE',
  SET_BID_SHADING_APPLICABILITY: 'SET_BID_SHADING_APPLICABILITY',
};
