import { AxiosError, AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import get from 'lodash/get';
import { UseInventoryGroupsListParams, UsePmpDealsListParams } from 'hooks/useInventory';

import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import {
  AddPmpDealRequest,
  InventoryGroup,
  InventoryGroupListResponse,
  InventoryGroupType,
  PgDealsListResponseType,
  PmpDealListResponse,
} from '../models/Inventory';
import { GetResponse, WithResponse } from '../models/Response';
import { getPgDealsListKey } from './QueryKeys';

export const Groups = async (): Promise<InventoryGroup[]> => {
  try {
    const response: AxiosResponse<GetResponse<InventoryGroup[]>> = await getInstance().get(
      '/v2/inv/group/list?includeStatistics=false&noOfEntries=0&provideAccountLevelExcludedGroup=false',
    );
    return get(response, 'data.responseObject', []);
  } catch (e) {
    Logger.log('Error while getting inventory group list', e);
    return [];
  }
};

export const getPGDealsList = async ({
  queryKey,
  meta,
  pageParam = 1,
}: QueryFunctionContext<ReturnType<typeof getPgDealsListKey['keys']>>) => {
  const { search, ids, noOfEntries, statusIds, exchangeIds } = queryKey[0]!;

  try {
    const response: AxiosResponse<WithResponse<PgDealsListResponseType>> = await getInstance().get(
      `/v3/inv/pg/deals/list`,
      {
        params: {
          searchField: search,
          noOfEntries,
          pageNo: pageParam,
          ids: ids?.join(','),
          statusIds: statusIds?.join(),
          exchangeIds: exchangeIds?.join(','),
          sortBy: '-id',
        },
      },
    );

    if (
      meta?.exchangeListObject &&
      meta?.pgDealsStatusObject &&
      meta?.pgDealsPaymentTypeObject &&
      response?.data?.data?.pgDealData?.length
    ) {
      response.data.data.pgDealData = response.data.data?.pgDealData?.map((val) => {
        return {
          ...val,
          // @ts-ignore
          ...meta?.exchangeListObject[val.exchangeId],
          // @ts-ignore
          ...meta?.pgDealsStatusObject[val.statusId],
          // @ts-ignore
          ...meta?.pgDealsPaymentTypeObject[val.paymentTypeId],
        };
      });
    }
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getInventoryGroupList = async ({
  queryKey,
  pageParam = 1,
}: QueryFunctionContext<[UseInventoryGroupsListParams & { scope: string }]>) => {
  const {
    searchField = '',
    groupType,
    pageSize,
    ids,
    sortBy,
    includeStatistics,
    provideAccountLevelExcludedGroup,
    groupFilterId,
    owId,
    excludeEmptyGroups,
  } = queryKey[0];

  try {
    const res: AxiosResponse<WithResponse<InventoryGroupListResponse>> = await getInstance().get(
      '/v3/inv/groups/list',
      {
        params: {
          searchField,
          groupTypeIds: groupType,
          pageNo: pageParam,
          noOfEntries: pageSize,
          sortBy,
          includeStatistics,
          provideAccountLevelExcludedGroup,
          groupFilterId,
          owId,
          ids,
          ...(typeof excludeEmptyGroups === 'boolean' ? { excludeEmptyGroups } : {}),
        },
      },
    );
    return res.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getInventoryGroupsTypes = async () => {
  try {
    const res: AxiosResponse<WithResponse<InventoryGroupType>> = await getInstance().get(
      '/v3/inv/inventory-group-types',
    );
    return res.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getPmpDealList = async ({
  queryKey,
  pageParam = 1,
}: QueryFunctionContext<[UsePmpDealsListParams & { scope: string }]>) => {
  const {
    searchKeywords = '',
    exchanges,
    creativeTypes,
    groupId,
    ids,
    sortBy,
    noOfEntries,
    active,
  } = queryKey[0];

  try {
    const res: AxiosResponse<WithResponse<PmpDealListResponse>> = await getInstance().post(
      '/v3/inv/pmp/deals/list',
      {
        ...(searchKeywords ? { searchKeywords: searchKeywords.split(',') } : {}),
        ...(ids?.length ? { ids: ids.split(',').map((id) => parseInt(id, 10)) } : {}),
        ...(creativeTypes?.length
          ? { creativeTypes: creativeTypes.split(',').map((id) => parseInt(id, 10)) }
          : {}),
        ...(exchanges?.length
          ? { exchanges: exchanges.split(',').map((id) => parseInt(id, 10)) }
          : {}),
        pageNo: pageParam,
        groupId,
        sortBy,
        noOfEntries,
        active,
      },
    );
    return res.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const addPmpDeal = async (requestData: AddPmpDealRequest): Promise<any> => {
  try {
    const response: AxiosResponse<WithResponse<AddPmpDealRequest>> = await getInstance().post(
      'v3/inv/pmp/deals/add',
      requestData,
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while creating pg deals', e);
    return Promise.reject((e as AxiosError).response?.data);
  }
};
