import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@applift/factor';
import { RowSelectionState, SortingState } from '@applift/datagrid';
import { connect } from 'react-redux';
import { applicationActions } from 'store/app/actions';
import { AppState } from 'models/Store';
import { SelectInventoryField, SetInventoryField, inventoryActions } from 'store/inventory/actions';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { InventoryGroupListResponse } from 'models/Inventory';
import { useInventoryGroupsList } from 'hooks/useInventory';

import { Filters } from '../InventoryTable/Filters';
import { InventoryGroupTable } from '../InventoryTable/InventoryTable';
import styles from './styles.module.scss';

export interface SelectInventoryGroupDialogProps extends SetInventoryField, SelectInventoryField {
  open: boolean;
  closeDialog: () => void;
  selectedInventoryGroupsData: InventoryGroupListResponse['inventoryGroupList'];
  setSelectedInventoryGroupData: React.Dispatch<
    React.SetStateAction<InventoryGroupListResponse['inventoryGroupList']>
  >;
}

export const SelectInventoryGroupDialogComponent = (props: SelectInventoryGroupDialogProps) => {
  const {
    open,
    closeDialog,
    selectInventoryField,
    selectedInventoryGroupsData,
    setSelectedInventoryGroupData,
  } = props;

  const initialSelection =
    selectedInventoryGroupsData?.reduce((prev, curr) => {
      if (curr.id && curr.isWhiteListed !== undefined) {
        return { ...prev, [curr.id]: true };
      }
      return prev;
    }, {} as RowSelectionState) ?? {};

  const [rowSelection, setRowSelection] = React.useState(initialSelection);

  const [searchField, setSearch] = React.useState('');
  const [groupType, setGroupType] = React.useState<number[]>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const { data, isLoading, fetchNextPage } = useInventoryGroupsList({
    searchField,
    groupType: groupType.join(', '),
    sortBy: sorting.map((val) => (val.desc ? `-${val.id}` : `+${val.id}`)).join(','),
    excludeEmptyGroups: true,
  });
  const inventoryGroupList = React.useMemo(() => {
    const list =
      data?.pages.reduce(
        (prev, curr) => [...(prev ?? []), ...(curr.data?.inventoryGroupList ?? [])],
        [] as InventoryGroupListResponse['inventoryGroupList'],
      ) ?? [];

    selectInventoryField({
      key: 'inventoryGroups',
      value: list.map((group) => ({
        label: group.id?.toString()!,
        value: group.id!,
      })),
    });
    return list;
  }, [data, selectInventoryField]);

  const includeExcludeState = selectedInventoryGroupsData.reduce((prev, curr) => {
    return curr.id ? { ...prev, [curr.id]: curr.isWhiteListed } : prev;
  }, {} as Record<string, boolean>);

  const [tempSelection, setTempSelection] = React.useState(selectedInventoryGroupsData);

  React.useEffect(() => {
    setTempSelection((prev) => {
      const preSelectedIds = prev?.map((grp) => grp.id);
      const preSelectedGroups = prev.filter((grp) => grp.id! in rowSelection);
      const newSelected = Object.keys(rowSelection)
        .filter((rowId) => preSelectedIds.includes(Number(rowId)) === false)
        .map((rowId) => ({
          ...inventoryGroupList.filter((grp) => grp.id === Number(rowId))[0],
          isWhiteListed: true && (includeExcludeState[rowId] ?? true),
        }));
      return [...preSelectedGroups, ...newSelected].sort((a, b) => a.id! - b.id!);
    });
    // eslint-disable-next-line
  }, [rowSelection]);

  const handleSelect = () => {
    setSelectedInventoryGroupData(tempSelection);
    closeDialog();
  };

  const filteredRecords = data?.pages[0]?.data?.filteredRecords;

  return (
    <Dialog open={open} fullWidth maxWidth="lg" classes={{ paper: styles.dialogPaper }}>
      <DialogTitle>Select Inventory Group</DialogTitle>
      <DialogContent dividers sx={{ py: 32, overflow: 'hidden' }}>
        <Paper
          sx={{ height: 100, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
          variant="elevation"
          elevation={2}
        >
          <Filters
            searchField={searchField}
            setSearch={(val) => {
              setSearch(val);
            }}
            groupType={groupType}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setGroupType={(val) => {
              setGroupType(val);
            }}
            filteredRecords={filteredRecords}
          />
          <InventoryGroupTable
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            data={inventoryGroupList}
            loading={isLoading}
            fetchNextPage={fetchNextPage}
            filteredRecords={filteredRecords ?? 0}
            sorting={sorting}
            search={searchField}
            setSorting={setSorting}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" sx={{ borderRadius: 2 }} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          sx={{ borderRadius: 2 }}
          disabled={Object.keys(rowSelection).length === 0}
          onClick={handleSelect}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  auth: state.auth,
  submitted: state.app.submitted,
  editableCampaign: state.app.editableCampaign,
  errorCreating: state.app.errorCreating,
  inventoryState: state.inventory,
  selectedInventoryGroups: state.inventory.sidebarCampaignInfo[CampaignInfoField.inventoryGroups],
  selectedDealGroups: state.inventory.sidebarCampaignInfo[CampaignInfoField.dealGroups],
  selectedPublishers: state.publishers.selectedPublishers,
  publishers: state.publishers.publishers,
});

const mapAction = {
  resetError: applicationActions.resetError,
  setInventoryField: inventoryActions.setInventoryField,
  selectInventoryField: inventoryActions.selectInventoryField,
};

export const SelectInventoryGroupDialog = connect(
  mapState,
  mapAction,
)(SelectInventoryGroupDialogComponent);
