export const locationConstants = {
  CHANGE_SELECTED_BUTTONS_STATE: 'CHANGE_SELECTED_BUTTONS_STATE',
  RESET_LOCATION_STORE: 'RESET_LOCATION_STORE',
  SET_LOCATION_ERROR: 'SET_LOCATION_ERROR',
  RESET_LOCATION_ERROR: 'RESET_LOCATION_ERROR',
  REMOVE_ACTION_BUTTON: 'REMOVE_ACTION_BUTTON',
  ADD_FILE_LOCATION: 'SET_FILE_LOCATION',
  REMOVE_FILE_LOCATION: 'REMOVE_FILE_LOCATION',
  REMOVE_FILE_LOCATION_ITEM: 'REMOVE_FILE_LOCATION_ITEM',
  CLEAR_LOCATION_FILES: 'CLEAR_LOCATION_FILES',
  DISMISS_INVALID_DATA: 'DISMISS_INVALID_DATA',
  FILE_UPLOAD_STATUS: 'FILE_UPLOAD_STATUS',
  SET_LOCATION_IS_OR_RELATIONSHIP: 'SET_LOCATION_IS_OR_RELATIONSHIP',
};
