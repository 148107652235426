import { AxiosResponse } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { Option } from '../models/Option';
import { GetResponse } from '../models/Response';

export type TCreativeGroupWithCreatives = {
  name: string;
  id: number;
  creatives: Option[];
};

type Params = {
  status?: string;
  creativeTypeId?: number;
};

export const FetchCreativeGroups = async (
  params: Params,
): Promise<TCreativeGroupWithCreatives[]> => {
  try {
    const response: AxiosResponse<GetResponse<TCreativeGroupWithCreatives[]>> =
      await getInstance().get('/v2/crt/group/creative/list', {
        params: {
          ...params,
        },
      });

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return [];
  }
};

type TFetchCreativeGroupsMapParams = {
  creativeIds: string;
};

export const FetchCreativeGroupsMap = async (
  params: TFetchCreativeGroupsMapParams,
): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().get(
      'v2/crt/group/creative',
      {
        params: {
          creativeIds: params.creativeIds,
        },
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return [];
  }
};
