import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Select, Icon, Tooltip } from 'factor';

import { Option, ExcludedOption } from 'models/Option';
import { API } from 'api';
import { AppState } from 'models/Store';
import {
  audienceActions,
  SetAudienceField,
  SelectAudienceField,
  SetAudienceWarning,
} from 'store/audience/actions';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { applicationActions, ResetError } from 'store/app/actions';
import { audienceIDMap, AudienceItem, AudienceWarning } from 'models/Audience';
import { UINoteIcon } from 'components/UINoteIcon';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { getTargetingTooltip } from 'utils/helpers';
import { CAMPAIGN_MAX_AUDIENCE_COUNT } from 'constants/audience';

import styles from '../styles.module.scss';

type Props = SetAudienceField &
  SelectAudienceField &
  SetAudienceWarning &
  ResetError & {
    audiences: Option<string>[];
    selectedAudiences: AudienceItem[];
    selectedAudienceTypes: Option<number>[];
    className?: string;
    isSelectionUpdatedFromURL: React.MutableRefObject<boolean>;
    editableCampaign: ExistingCampaignData | null;
  };

const SelectAudienceWrapperComponent = (props: Props) => {
  const {
    audiences,
    selectedAudiences,
    selectedAudienceTypes,
    setAudienceField,
    selectAudienceField,
    setAudienceWarning,
    isSelectionUpdatedFromURL,
    className = '',
    editableCampaign,
  } = props;

  const [selectedAudienceTypeIds, setSelectedAudienceTypeIds] = useState<string>('');

  const { search } = useLocation();

  const { audIds, audTypeIds } = queryString.parse(search);

  const selectAudience = useCallback(
    (value: Option<string>[]) => {
      selectAudienceField({
        key: CampaignInfoField.audiences,
        value,
      });
    },
    [selectAudienceField],
  );

  useEffect(() => {
    if (editableCampaign?.status === 'running' && selectedAudiences) {
      setAudienceWarning(AudienceWarning.NONE);
      if (!selectedAudiences.length) {
        return;
      }

      if (
        selectedAudiences.some((aud) => aud.status !== 'approved') &&
        selectedAudiences.some((aud) => aud.status === 'approved')
      ) {
        setAudienceWarning(AudienceWarning.SOME_UNAPPROVED);
      } else if (selectedAudiences.every((aud) => aud.status !== 'approved')) {
        setAudienceWarning(AudienceWarning.ALL_UNAPPROVED);
      }
    }
  }, [selectedAudiences, editableCampaign, setAudienceWarning]);

  useEffect(() => {
    if (audIds && audTypeIds && audiences.length && !isSelectionUpdatedFromURL.current) {
      const audIdsArray = (audIds as string).split(',');
      const selectedData = audiences.reduce((acc: ExcludedOption<string>[], aud) => {
        if (audIdsArray.includes(aud.value.toString())) {
          acc.push({ ...aud, included: true });
        }
        return acc;
      }, []);
      selectAudience(selectedData);
      isSelectionUpdatedFromURL.current = true;
    }
  }, [audTypeIds, audiences, audIds, selectAudience, isSelectionUpdatedFromURL]);

  useEffect(() => {
    setSelectedAudienceTypeIds(
      selectedAudienceTypes.map((type: Option<number>) => type.value).join(),
    );
  }, [selectedAudienceTypes]);

  useEffect(() => {
    if (!selectedAudienceTypeIds.length) {
      return;
    }

    const fetchAudiences = async () => {
      const list = await API.Audience.List(selectedAudienceTypeIds, '');
      const types = list.map((t) => ({
        label: t.audienceName,
        value: t.id,
        audienceTypeId: t.audienceTypeId,
        audienceId: t.id,
        status: t.status,
        createdOn: t.createdOn,
        uniques: t.uniques,
        dataCost: t.dataCost,
        statusDisplayName: t.statusDisplayName,
        reactLabel: (
          <div className={styles.option}>
            <div className={styles.optionImage}>
              <Icon name={audienceIDMap[t.audienceTypeId]} />
            </div>
            <span className={styles.optionTitle}>{t.audienceName}</span>
          </div>
        ),
        dataGroup: t.isVoterAudience ? '1' : '2',
      }));
      setAudienceField({
        key: 'audiences',
        value: types,
      });
    };

    if (!audiences.length || selectedAudienceTypeIds) {
      fetchAudiences();
    }
  }, [setAudienceField, selectedAudienceTypeIds, audiences.length]);

  const getTabIcon = (type: 'include' | 'exclude') => {
    return (
      <UINoteIcon
        text={getTargetingTooltip(type === 'include' ? 'targeted' : 'blocked', 'audiences')}
        marginLeft={1}
      />
    );
  };

  const maxAudiencesReached = selectedAudiences.length >= CAMPAIGN_MAX_AUDIENCE_COUNT;

  return selectedAudienceTypeIds.length > 0 ? (
    <Select
      placeholder="Select Audiences"
      label="Select Audiences"
      isMulti
      isSearchable
      isClearable
      showControlLabel
      searchByValue
      withTabs
      getTabIcon={getTabIcon}
      options={
        maxAudiencesReached
          ? audiences.map((item) => {
              if (selectedAudiences.some((j) => j.value === item.value)) {
                return item;
              }
              return {
                ...item,
                reactLabel: (
                  <Tooltip
                    label={`Maximum limit of ${CAMPAIGN_MAX_AUDIENCE_COUNT} audiences per campaign is reached`}
                    portal
                  >
                    {item.reactLabel || item.label}
                  </Tooltip>
                ),
                className: styles.disabled,
                isReadOnly: true,
              };
            })
          : audiences
      }
      value={selectedAudiences}
      onChange={selectAudience}
      className={`${className} ${styles.selectAudiencesDropdown}`}
      tooltipParams={{ label: 'Audience' }}
    />
  ) : null;
};

const mapState = (state: AppState) => ({
  isAuthorized: state.auth.authorized,
  audiences: state.audience.audiences,
  selectedAudiences: state.audience.sidebarCampaignInfo[CampaignInfoField.audiences],
  selectedAudienceTypes: state.audience.sidebarCampaignInfo[CampaignInfoField.audienceTypes],
  editableCampaign: state.app.editableCampaign,
});

const mapAction = {
  setAudienceField: audienceActions.setAudienceField,
  selectAudienceField: audienceActions.selectAudienceField,
  setAudienceWarning: audienceActions.setAudienceWarning,
  resetError: applicationActions.resetError,
};

export const SelectAudiencesWrapper = connect(mapState, mapAction)(SelectAudienceWrapperComponent);
