import * as React from 'react';
import { useTimezone } from 'hooks/useTimezone';
import {
  TextField,
  MenuItem,
  Box,
  IconButton,
  BaseTextFieldProps,
  Typography,
  TypoTooltip,
} from '@applift/factor';
import { ClipboardRemove, Close, Globe } from '@applift/icons';

import { OptionId } from 'models/Option';

import styles from './index.module.scss';

export interface TimezoneType {
  variant?: 'outlined';
  label?: string;
  onChange?: (timezone: OptionId<string>) => void;
  value?: OptionId<string>;
  timezoneTextFieldProps?: BaseTextFieldProps<any> & React.RefAttributes<HTMLDivElement>;
}

export const Timezone = (props: TimezoneType) => {
  const { variant, label, onChange, value, timezoneTextFieldProps } = props;
  const { data: timezoneData } = useTimezone();
  const [timezoneSearch, setTimezoneSearch] = React.useState('');

  const filteredTimezones = React.useMemo(() => {
    if (!timezoneSearch) {
      return timezoneData;
    }
    const timezones = timezoneData?.filter((val) =>
      val.name.toLowerCase().includes(timezoneSearch.toLowerCase()),
    );
    return timezones;
  }, [timezoneData, timezoneSearch]);

  const NoResultOverlay = () => {
    return (
      <MenuItem style={{ pointerEvents: 'none' }}>
        <Box
          sx={{
            width: 100,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            p: 32,
          }}
        >
          <ClipboardRemove sx={{ textColor: 'neutral-400' }} fontSize={32} />
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            No Results Found
          </Typography>
          {timezoneSearch ? (
            <Typography>
              We can’t find any items
              <br /> matching your search.
            </Typography>
          ) : null}
        </Box>
      </MenuItem>
    );
  };

  const currentTimezoneId = React.useMemo(() => value?.id, [value?.id]);

  return (
    // @ts-ignore
    <TextField
      variant={variant ?? 'outlinedDash'}
      select
      value={currentTimezoneId ?? 0}
      onChange={(e) => {
        onChange?.({
          label: timezoneData?.find((item) => item.id === Number(e.target.value))?.label as string,
          value: timezoneData?.find((item) => item.id === Number(e.target.value))?.name as string,
          id: Number(e.target.value),
        });
      }}
      SelectProps={{
        placeholder: 'Select Timezone',
        MenuProps: {
          PaperProps: { sx: { height: 'auto' }, style: { maxWidth: 200 } },
        },
        onClose: () => setTimezoneSearch(''),
        renderContentTop: (
          <Box sx={{ width: 100, borderBottom: 1 }}>
            <TextField
              placeholder="Search"
              value={timezoneSearch}
              onChange={(e: any) => setTimezoneSearch(e.target.value)}
              fullWidth
              classes={{
                root: styles.timezoneSearchInput,
              }}
              InputProps={{
                endAdornment:
                  timezoneSearch?.length > 0 ? (
                    <IconButton
                      onClick={() => {
                        setTimezoneSearch('');
                      }}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  ) : null,
              }}
            />
          </Box>
        ),
      }}
      sx={{ width: 100 }}
      label={label ? label || 'Timezone' : ''}
      InputProps={{
        startAdornment: <Globe sx={{ textColor: 'neutral-400' }} />,
        ...timezoneTextFieldProps?.InputProps,
      }}
      // eslint-disable-next-line
      {...timezoneTextFieldProps}
    >
      {filteredTimezones?.map((val) => (
        <MenuItem value={val.id.toString()}>
          <TypoTooltip>
            <Typography>{val?.label}</Typography>
          </TypoTooltip>
        </MenuItem>
      ))}
      {!filteredTimezones?.length && timezoneSearch ? <NoResultOverlay /> : null}
    </TextField>
  );
};
