import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { enqueueSnackbar } from '@applift/factor';
import clsx from 'clsx';

import {
  useCreateDraftPgCampaign,
  useCreatePgCampaign,
  useEditDraftPgCampaign,
  useEditPgCampaign,
} from 'hooks/useCampaign';
import { StoreFilter } from 'models/ZustandStore';
import { useStore } from 'zustandStore';

import {
  useCreateDraftPgCampaignPayload,
  useCreatePgCampaignPayload,
  useEditDraftPgCampaignPayload,
  useEditPgCampaignPayload,
} from 'hooks/useCreatePgCampaignPayload';
import { useClearPgCampaignStore } from 'hooks/useClearPgCampaignStore';
import { ProgressContent } from './ProgressContent';
import { MainContent } from '../../../components/MainContent';

import styles from './DefaultPage.module.scss';

export const PGCampaignSavePage = () => {
  const history = useHistory();
  const location = useLocation();

  const saveType = React.useRef<'create' | 'edit' | 'draftCreate' | 'draftEdit' | ''>(
    location.search.split('=')[1] as 'create' | 'edit' | 'draftCreate' | 'draftEdit' | '',
  );

  const [
    country,
    campaignName,
    maxBidPrice,
    endDate,
    startDate,
    spendingBudget,
    timezone,
    advertiserDomain,
    selectedCreatives,
    creativeTypeId,
    pgDealIds,
    ioDetails,
    politicalAdvertiserClientId,
    selectedConversions,
    conversionType,
    campaignDetails,
    totalImpressions,
  ] = useStore((state: StoreFilter) => [
    state.locationBlock.country,
    state.campaignInfoBlock.campaignName,
    state.campaignInfoBlock.maxBidPrice,
    state.campaignInfoBlock.endDate,
    state.campaignInfoBlock.startDate,
    state.campaignInfoBlock.spendingBudget,
    state.campaignInfoBlock.timezone,
    state.campaignInfoBlock.advertiserDomain,
    state.creativesBlock.selectedCreatives,
    state.creativesBlock.creativeTypeId,
    state.inventoryBlock.pgDealIds,
    state.pgCampaignPage.ioDetails,
    state.politicalAdvertiser.politicalAdvertiserClientId,
    state.conversionBlock.selectedConversions,
    state.conversionBlock.conversionType,
    state.pgCampaignPage.campaignDetails,
    state.campaignInfoBlock.totalImpressions,
  ]);

  const createCampaignPayload = {
    campaignDetails: {
      country,
      campaignName,
      maxBidPrice,
      endDate,
      startDate,
      spendingBudget,
      timeZone: timezone,
      advertiserDomain,
      selectedCreatives,
      creativeTypeId,
      pgDealIds,
      politicalAdvertiserClientId,
      selectedConversions,
      conversionType,
      totalImpressions,
    },
    ioDetails,
  };

  const editCampaignPayload = {
    campaignDetails: {
      country,
      campaignName,
      maxBidPrice,
      endDate,
      startDate,
      spendingBudget,
      timeZone: timezone,
      advertiserDomain,
      selectedCreatives,
      creativeTypeId,
      pgDealIds,
      politicalAdvertiserClientId,
      selectedConversions,
      conversionType,
      totalImpressions,
    },
    ioDetails,
    savedCampaignDetails: campaignDetails,
  };

  const { resetStore } = useClearPgCampaignStore();

  const [loading, setLoading] = React.useState<boolean>(true);

  const { payload: createPgCampaignPayload } = useCreatePgCampaignPayload(createCampaignPayload);

  const { actualPayload: createDraftPgCampaignPayload } =
    useCreateDraftPgCampaignPayload(createCampaignPayload);

  const { payload: editDraftPgCampaignPayload } =
    useEditDraftPgCampaignPayload(editCampaignPayload);

  const { payload: editPgCampaignPayload } = useEditPgCampaignPayload(editCampaignPayload);

  const { mutate: createPgCampaignMutation } = useCreatePgCampaign({
    onError: (e: any) => {
      // @ts-ignore
      setLoading(false);
      if (e.statusCode === 500) {
        enqueueSnackbar(e?.responseObject?.errorMsg, { variant: 'error' });
      } else {
        enqueueSnackbar(e?.errorObjects?.[0]?.error, { variant: 'error' });
      }
      history.replace(`/campaign-create-pg?ioId=${ioDetails?.ioId}`);
    },
    onSuccess: () => {
      resetStore();
      history.replace(`/io-detail/${ioDetails?.ioId}`);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: editPgCampaignMutation } = useEditPgCampaign({
    onError: (e: any) => {
      // @ts-ignore
      setLoading(false);
      // @ts-ignore
      if (e.statusCode === 500) {
        enqueueSnackbar(e?.responseObject?.errorMsg, { variant: 'error' });
      } else {
        enqueueSnackbar(e?.errorObjects?.[0]?.error, { variant: 'error' });
      }
      history.replace(`/campaign-edit-pg/${campaignDetails?.id}`);
    },
    onSuccess: () => {
      resetStore();
      history.replace(`/io-detail/${ioDetails?.ioId}`);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: createDraftPgCampaignMutation } = useCreateDraftPgCampaign({
    onError: (e: any) => {
      // @ts-ignore
      setLoading(false);
      if (e.statusCode === 500) {
        enqueueSnackbar(e?.responseObject?.errorMsg, { variant: 'error' });
      } else {
        enqueueSnackbar(e?.errorObjects?.[0]?.error, { variant: 'error' });
      }
      history.replace(`/campaign-create-pg?ioId=${ioDetails?.ioId}`);
    },
    onSuccess: () => {
      resetStore();
      history.replace(`/io-detail/${ioDetails?.ioId}`);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: editDraftPgCampaignMutation } = useEditDraftPgCampaign({
    onError: (e: any) => {
      // @ts-ignore
      setLoading(false);
      if (e.statusCode === 500) {
        enqueueSnackbar(e?.responseObject?.errorMsg, { variant: 'error' });
      } else {
        enqueueSnackbar(e?.errorObjects?.[0]?.error, { variant: 'error' });
      }
      history.replace(`/campaign-edit-pg/${campaignDetails?.id}`);
    },
    onSuccess: () => {
      resetStore();
      history.replace(`/io-detail/${ioDetails?.ioId}`);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  React.useEffect(() => {
    if (saveType.current === 'create') {
      createPgCampaignMutation(createPgCampaignPayload);
      saveType.current = '';
    }
    if (saveType.current === 'edit') {
      // @ts-ignore
      editPgCampaignMutation(editPgCampaignPayload);
      saveType.current = '';
    }
    if (saveType.current === 'draftCreate') {
      // @ts-ignore
      createDraftPgCampaignMutation(createDraftPgCampaignPayload);
      saveType.current = '';
    }
    if (saveType.current === 'draftEdit') {
      // @ts-ignore
      editDraftPgCampaignMutation(editDraftPgCampaignPayload);
      saveType.current = '';
    }
  }, [
    createDraftPgCampaignMutation,
    createDraftPgCampaignPayload,
    createPgCampaignMutation,
    createPgCampaignPayload,
    editDraftPgCampaignMutation,
    editDraftPgCampaignPayload,
    editPgCampaignMutation,
    editPgCampaignPayload,
    saveType,
  ]);

  React.useEffect(() => {
    if (ioDetails?.ioId) {
      if (!campaignName || !country) {
        history.replace(`/create-campaign-pg/ioId=${ioDetails?.ioId}`);
      }
    }
  }, [campaignName, country, history, ioDetails?.ioId]);

  return loading ? (
    <MainContent className={clsx(loading && '_loading', styles.container)}>
      <ProgressContent />
    </MainContent>
  ) : null;
};
