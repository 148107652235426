import { LatLngBoundsLiteral, LatLngLiteral, LatLngBounds, OverlayType } from 'models/Google';
import { LocationOption, RadiusUpdatedLocations } from 'models/Location';
import { Option } from 'models/Option';
import { shapeType } from 'constants/location';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { mapConstants } from './constants';
import { PolygonExtended } from '../../models/PolygonExtended';
import { CircleExtended } from '../../models/CircleExtended';
import { RectangleExtended } from '../../models/RectangleExtended';

export type LocationFilter = {
  country: string;
  states: string[];
};

export const USA_CENTER: LatLngLiteral = { lat: 41.021294, lng: -101.973788 };
const USA_ZOOM = 5;

export type MapBounds = null | LatLngBoundsLiteral | LatLngBounds;

export type TypeIds = 'cd' | 'sd' | 'hd' | 'dma' | 'ci' | 'co' | 'st' | 'zc';

type TFeaturesCacheItem = { [id: string]: any };

export interface MapState {
  isMapInstalled: boolean;
  isOpenMapVisible: boolean;
  isMapReadOnly: boolean;
  preSavedPolygons: PolygonExtended[];
  saved: PolygonExtended[];
  preSavedCircles: CircleExtended[];
  preSavedRectangles: RectangleExtended[];
  drawerMode: OverlayType | undefined;
  areaType: string | null;
  isInclude: boolean;
  displayRadiusType: 'default' | 'selected';
  selectedAreasIds: string[];
  selectedCounty: Option | null;
  selectedState: LocationOption | null;
  selectedStateHouse: LocationOption | null;
  center: LatLngLiteral;
  mapBounds: MapBounds;
  zoom: number;
  houseStateDifference: LocationOption[];
  toastMessage: string;
  sidebarIsOpen: boolean;
  shapesRadiusLimitErrorMsg?: string;
  radiusUpdatedLocations: RadiusUpdatedLocations;
  geoJSONData: { [id in TypeIds]: TFeaturesCacheItem };
  activeFeaturesIds: { [id: string]: string[] };
  selectedLocationTab: string;
}

export const defaultMapState: MapState = {
  isMapInstalled: false,
  isOpenMapVisible: false,
  isMapReadOnly: false,
  preSavedPolygons: [],
  saved: [],
  preSavedCircles: [],
  preSavedRectangles: [],
  drawerMode: shapeType.circle as OverlayType,
  areaType: 'Radius',
  isInclude: true,
  displayRadiusType: 'default',
  selectedAreasIds: [],
  selectedCounty: null,
  selectedState: null,
  selectedStateHouse: null,
  center: USA_CENTER,
  mapBounds: null,
  zoom: USA_ZOOM,
  houseStateDifference: [],
  toastMessage: '',
  sidebarIsOpen: true,
  shapesRadiusLimitErrorMsg: '',
  radiusUpdatedLocations: null,
  geoJSONData: { cd: {}, sd: {}, hd: {}, dma: {}, st: {}, ci: {}, co: {}, zc: {} },
  activeFeaturesIds: { cd: [], sd: [], hd: [], dma: [], st: [], ci: [], co: [], zc: [] },
  selectedLocationTab: 'location',
};

function initialMap(state: MapState) {
  return {
    ...state,
    isMapInstalled: true,
  };
}

function setOpenMapVisibility(state: MapState, action: Action<boolean>): MapState {
  return {
    ...state,
    isOpenMapVisible: action.payload,
  };
}

function setMapReadOnly(state: MapState, action: Action<boolean>): MapState {
  return {
    ...state,
    isMapReadOnly: action.payload,
  };
}

function pushPreSavedPolygon(state: MapState, action: Action<PolygonExtended>): MapState {
  return {
    ...state,
    preSavedPolygons: [...state.preSavedPolygons, action.payload],
  };
}

function updatePreSavedPolygon(state: MapState, action: Action<PolygonExtended>): MapState {
  return {
    ...state,
    preSavedPolygons: state.preSavedPolygons.map((polygon) =>
      polygon.id === action.payload.id ? action.payload : polygon,
    ),
  };
}

function removePolygonFromPreSaved(state: MapState, action: Action<PolygonExtended>): MapState {
  return {
    ...state,
    preSavedPolygons: state.preSavedPolygons.filter((p) => p.id !== action.payload.id),
  };
}

function clearPreSavedPolygons(state: MapState, action: Action<boolean>): MapState {
  return {
    ...state,
    preSavedPolygons: action.payload ? [] : state.preSavedPolygons.filter((c) => c.fileId),
  };
}

function removeSelectedPolygonsFromPreSaved(state: MapState): MapState {
  return {
    ...state,
    preSavedPolygons: state.preSavedPolygons.filter(
      (p) => state.selectedAreasIds.indexOf(p.id) === -1,
    ),
  };
}

function addPolygonsToSaved(state: MapState): MapState {
  return {
    ...state,
    saved: [...state.saved, ...state.preSavedPolygons],
    preSavedPolygons: [],
  };
}

function removePolygonFromSaved(state: MapState, action: Action<PolygonExtended>): MapState {
  return {
    ...state,
    saved: state.saved.filter((p) => p.id !== action.payload.id),
  };
}

function removeFilePolygons(state: MapState, action: Action<number>): MapState {
  return {
    ...state,
    preSavedPolygons: state.preSavedPolygons.filter((c) => c.fileId !== action.payload),
  };
}

function pushPreSavedCircle(
  state: MapState,
  action: Action<CircleExtended | CircleExtended[]>,
): MapState {
  return {
    ...state,
    preSavedCircles: [
      ...state.preSavedCircles,
      ...(Array.isArray(action.payload) ? action.payload : [action.payload]),
    ],
  };
}

function updatePreSavedCircle(state: MapState, action: Action<CircleExtended>) {
  return {
    ...state,
    preSavedCircles: state.preSavedCircles.map((circle) =>
      circle.id === action.payload.id ? action.payload : circle,
    ),
  };
}

function removeCircleFromPreSaved(state: MapState, action: Action<CircleExtended>): MapState {
  return {
    ...state,
    preSavedCircles: state.preSavedCircles.filter((c) => c.id !== action.payload.id),
  };
}

function removeFileCircles(state: MapState, action: Action<number>): MapState {
  return {
    ...state,
    preSavedCircles: state.preSavedCircles.filter((c) => c.fileId !== action.payload),
  };
}

function clearPreSavedCircles(state: MapState, action: Action<boolean>): MapState {
  return {
    ...state,
    preSavedCircles: action.payload ? [] : state.preSavedCircles.filter((c) => c.fileId),
  };
}

function removeSelectedCirclesFromPreSaved(state: MapState): MapState {
  return {
    ...state,
    preSavedCircles: state.preSavedCircles.filter(
      (p) => state.selectedAreasIds.indexOf(p.id) === -1,
    ),
  };
}

function pushPreSavedRectangle(state: MapState, action: Action<RectangleExtended>): MapState {
  return {
    ...state,
    preSavedRectangles: [...state.preSavedRectangles, action.payload],
  };
}

function updatePreSavedRectangle(state: MapState, action: Action<RectangleExtended>): MapState {
  return {
    ...state,
    preSavedRectangles: state.preSavedRectangles.map((rectangle) =>
      rectangle.id === action.payload.id ? action.payload : rectangle,
    ),
  };
}

function removeRectangleFromPreSaved(state: MapState, action: Action<RectangleExtended>): MapState {
  return {
    ...state,
    preSavedRectangles: state.preSavedRectangles.filter((r) => r.id !== action.payload.id),
  };
}

function removeSelectedRectanglesFromPreSaved(state: MapState): MapState {
  return {
    ...state,
    preSavedRectangles: state.preSavedRectangles.filter(
      (p) => state.selectedAreasIds.indexOf(p.id) === -1,
    ),
  };
}

function clearPreSavedRectangles(state: MapState): MapState {
  return {
    ...state,
    preSavedRectangles: [],
  };
}

function setDrawerMode(state: MapState, action?: Action<OverlayType | undefined>): MapState {
  return {
    ...state,
    drawerMode: action?.payload || undefined,
  };
}

function setAreaType(state: MapState, action: Action<string | null>): MapState {
  return {
    ...state,
    areaType: action.payload,
  };
}

function setAreaInclude(state: MapState, action: Action<boolean>): MapState {
  return {
    ...state,
    isInclude: action.payload,
  };
}

function pushSelectedAreaId(state: MapState, action: Action<string | string[]>): MapState {
  return {
    ...state,
    selectedAreasIds: [
      ...state.selectedAreasIds,
      ...(Array.isArray(action.payload) ? action.payload : [action.payload]),
    ],
  };
}

function removeSelectedAreaId(state: MapState, action: Action<string>): MapState {
  return {
    ...state,
    selectedAreasIds: state.selectedAreasIds.filter((id) => id !== action.payload),
  };
}

function emptySelectedAreasIds(state: MapState): MapState {
  return {
    ...state,
    selectedAreasIds: [],
  };
}

function countyChanged(state: MapState, action: Action<Option | null>): MapState {
  return {
    ...state,
    selectedCounty: action.payload,
  };
}

function stateChanged(state: MapState, action: Action<LocationOption>): MapState {
  return {
    ...state,
    selectedState: action.payload,
  };
}

function stateHouseChanged(state: MapState, action: Action<LocationOption>): MapState {
  return {
    ...state,
    selectedStateHouse: action.payload,
  };
}

function setMapCenter(state: MapState, action: Action<LatLngLiteral>): MapState {
  return {
    ...state,
    center: action.payload,
  };
}

function setMapBounds(state: MapState, action: Action<MapBounds>): MapState {
  return {
    ...state,
    mapBounds: action.payload,
  };
}

function setMapDefaultCenter(state: MapState): MapState {
  return {
    ...state,
    center: USA_CENTER,
    zoom: USA_ZOOM,
  };
}

function setHouseStateDifference(state: MapState, action: Action<LocationOption[]>): MapState {
  return {
    ...state,
    houseStateDifference: action.payload,
  };
}

function resetStore(state: MapState): MapState {
  return {
    ...state,
    preSavedCircles: [],
    preSavedRectangles: [],
    preSavedPolygons: [],
    radiusUpdatedLocations: null,
    activeFeaturesIds: { cd: [], sd: [], hd: [], dma: [], st: [], ci: [], co: [], zc: [] },
    selectedLocationTab: 'location',
  };
}

function setToastMessage(state: MapState, action: Action<string>): MapState {
  return {
    ...state,
    toastMessage: action.payload,
  };
}

function updateMapStore(state: MapState): MapState {
  return {
    ...state,
    preSavedPolygons: [...state.preSavedPolygons],
  };
}

function setSidebarState(state: MapState, action: Action<boolean>): MapState {
  return {
    ...state,
    sidebarIsOpen: action.payload,
  };
}

function setShapesRadiusLimitError(state: MapState, action: Action<string>): MapState {
  return {
    ...state,
    shapesRadiusLimitErrorMsg: action.payload,
  };
}

function addGeoJSONData(
  state: MapState,
  action: Action<{ key: TypeIds; label: string; value: any }>,
): MapState {
  const { geoJSONData } = state;
  const { key, label, value } = action.payload;
  geoJSONData[key][label] = value;
  return {
    ...state,
    geoJSONData,
  };
}

function setActiveGeoJsonIds(
  state: MapState,
  action: Action<{ key: TypeIds; selectedLabels: string[] }>,
): MapState {
  const { activeFeaturesIds } = state;
  const { key, selectedLabels } = action.payload;
  activeFeaturesIds[key] = selectedLabels;
  return {
    ...state,
    activeFeaturesIds,
  };
}

function setRadiusUpdatedLocations(
  state: MapState,
  action: Action<RadiusUpdatedLocations>,
): MapState {
  return {
    ...state,
    radiusUpdatedLocations: action.payload,
  };
}

function setSelectedLocationTab(state: MapState, action: Action<string>): MapState {
  return {
    ...state,
    selectedLocationTab: action.payload,
  };
}

const reducer = reducerFromMap<MapState>(defaultMapState, {
  [mapConstants.INITIAL_MAP]: initialMap,
  [mapConstants.PUSH__PRESAVED_POLYGON]: pushPreSavedPolygon,
  [mapConstants.REMOVE__POLYGON_FROM_PRESAVED]: removePolygonFromPreSaved,
  [mapConstants.CLEAR__PRESAVED_POLYGONS]: clearPreSavedPolygons,
  [mapConstants.CLEAR__PRESAVED_RECTANGLES]: clearPreSavedRectangles,
  [mapConstants.ADD__POLYGONS_TO_SAVED]: addPolygonsToSaved,
  [mapConstants.REMOVE__POLYGON_FROM_SAVED]: removePolygonFromSaved,
  [mapConstants.REMOVE_SELECTED_POLYGONS_FROM_PRESAVED]: removeSelectedPolygonsFromPreSaved,
  [mapConstants.PUSH__PRESAVED_CIRCLE]: pushPreSavedCircle,
  [mapConstants.UPDATE__PRESAVED_CIRCLE]: updatePreSavedCircle,
  [mapConstants.REMOVE__CIRCLE_FROM_PRESAVED]: removeCircleFromPreSaved,
  [mapConstants.CLEAR__PRESAVED_CIRCLES]: clearPreSavedCircles,
  [mapConstants.REMOVE_SELECTED_CIRCLES_FROM_PRESAVED]: removeSelectedCirclesFromPreSaved,
  [mapConstants.PUSH__PRESAVED_RECTANGLE]: pushPreSavedRectangle,
  [mapConstants.UPDATE__PRESAVED_RECTANGLE]: updatePreSavedRectangle,
  [mapConstants.REMOVE__RECTANGLE_FROM_PRESAVED]: removeRectangleFromPreSaved,
  [mapConstants.REMOVE_SELECTED_RECTANGLES_FROM_PRESAVED]: removeSelectedRectanglesFromPreSaved,
  [mapConstants.SET__DRAWER__MODE]: setDrawerMode,
  [mapConstants.SET__AREA__TYPE]: setAreaType,
  [mapConstants.SET__AREA__INCLUDE]: setAreaInclude,
  [mapConstants.PUSH_SELECTED_AREA_ID]: pushSelectedAreaId,
  [mapConstants.REMOVE_SELECTED_AREA_ID]: removeSelectedAreaId,
  [mapConstants.EMPTY_SELECTED_AREAS_IDS]: emptySelectedAreasIds,
  [mapConstants.CHANGED__LOCATION_COUNTY]: countyChanged,
  [mapConstants.CHANGED__LOCATION_STATE_SENATE]: stateChanged,
  [mapConstants.CHANGED__LOCATION_STATE_HOUSE]: stateHouseChanged,
  [mapConstants.SET__MAP_CENTER]: setMapCenter,
  [mapConstants.SET__MAP_BOUNDS]: setMapBounds,
  [mapConstants.SET__MAP_DEFAULT_CENTER]: setMapDefaultCenter,
  [mapConstants.SET__HOUSE_STATE_DIFFERENCE]: setHouseStateDifference,
  [mapConstants.RESET_MAP_STORE]: resetStore,
  [mapConstants.SET_TOAST_MESSAGE]: setToastMessage,
  [mapConstants.SET_MAP_VISIBILITY]: setOpenMapVisibility,
  [mapConstants.UPDATE_MAP_STORE]: updateMapStore,
  [mapConstants.SET_SIDEBAR_STATE]: setSidebarState,
  [mapConstants.REMOVE_FILE_PRESAVED_CIRCLES]: removeFileCircles,
  [mapConstants.REMOVE_FILE_PRESAVED_POLYGONS]: removeFilePolygons,
  [mapConstants.UPDATE__PRESAVED_POLYGON]: updatePreSavedPolygon,
  [mapConstants.SET_MAP_READONLY]: setMapReadOnly,
  [mapConstants.SET_SHAPE_RADIUS_LIMIT_ERROR]: setShapesRadiusLimitError,
  [mapConstants.SET_RADIUS_UPDATED_LOCATIONS]: setRadiusUpdatedLocations,
  [mapConstants.ADD_GEOJSON_FEATURES_CACHE]: addGeoJSONData,
  [mapConstants.SET_SELECTED_LOCATIONS_IDS]: setActiveGeoJsonIds,
  [mapConstants.SET_SELECTED_LOCATION_TAB]: setSelectedLocationTab,
});

export const map = (state: MapState = defaultMapState, action: Action<any>) =>
  reducer(state, action);
