import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  sx,
  LoadingButton,
} from '@applift/factor';
import { ArrowRightLong } from '@applift/icons';
import clsx from 'clsx';
import { BUDGET_TYPE_ID } from 'constants/apps';

interface TotalBudgetCompletionData {
  updatedTotalBudget: number;
}

interface InlineSetTotalBudgetProps {
  name?: string;
  currTotalBudget: number;
  newTotalBudget?: number;
  closeDialog: () => void;
  onCompletion: (data: TotalBudgetCompletionData) => void;
  isBudgetBeingSet?: boolean;
  budgetTypeId?: number;
}

export const InlineSetTotalBudget = (props: InlineSetTotalBudgetProps) => {
  const {
    name,
    currTotalBudget,
    closeDialog,
    onCompletion,
    isBudgetBeingSet,
    newTotalBudget,
    budgetTypeId,
  } = props;

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle onClose={closeDialog}>Set Total Budget</DialogTitle>
      <DialogContent dividers sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography sx={{ mb: 16 }}>
          Set the
          <Typography weight="demi" sx={{ ml: 4 }}>
            Total Budget
          </Typography>{' '}
          for the
          <Typography sx={{ ml: 4 }}>insertion order</Typography>
          <Typography weight="demi" sx={{ mx: 4 }}>
            {name}
          </Typography>
        </Typography>
        <Box sx={{ display: 'flex', gap: 12 }}>
          <TextField
            value={`${currTotalBudget}`}
            label="Current Budget"
            variant="outlined"
            InputProps={{
              readOnly: true,
              startAdornment:
                budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                  <Typography>$</Typography>
                ) : (
                  <Typography>Imps</Typography>
                ),
            }}
          />
          <ArrowRightLong
            fontSize={24}
            sx={{ textColor: 'neutral-400', alignSelf: 'end', mb: 8 }}
          />
          <TextField
            value={parseFloat(`${newTotalBudget}`)}
            type="number"
            label="New Total Budget"
            variant="outlined"
            InputProps={{
              readOnly: true,
              startAdornment:
                budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                  <Typography>$</Typography>
                ) : (
                  <Typography>Imps</Typography>
                ),
              classes: {
                root: clsx(
                  sx({
                    textWeight: 'demi',
                  }),
                ),
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isBudgetBeingSet}
          onClick={() =>
            onCompletion({
              updatedTotalBudget: Number(newTotalBudget),
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
