import * as React from 'react';
import {
  DialogTitle,
  DialogContent,
  TypoTooltip,
  Typography,
  Box,
  CircularProgress,
  Container,
} from '@applift/factor';

import { BUDGET_TYPE_ID } from 'constants/apps';
import { IoCurrency, IoImpression } from '@applift/icons';
import { IoListType } from 'models/IoList';
import { useIOBudgetImpInfo, useIOInfo } from 'hooks/useIO';
import { DialogCampaignListWrapper } from './DialogCampaignListWrapper';

interface CampaignDialogProps {
  onClose: (params: any) => void;
  data: { rowData: IoListType; actionMeta: { dateRange: { startDate: number; endDate: number } } };
}

export const CampaignDialog = (props: CampaignDialogProps) => {
  const { onClose, data } = props;

  const budgetTypeId = data?.rowData?.budgetTypeId;

  const { data: ioBudgetInfo } = useIOBudgetImpInfo(String(data?.rowData.ioId), {
    enabled: Boolean(String(data?.rowData.ioId)),
  });

  const { data: ioDetail } = useIOInfo(String(data?.rowData.ioId), {
    enabled: Boolean(String(data?.rowData.ioId)),
  });

  return (
    <>
      <DialogTitle
        onClose={onClose}
        noWrap
        sx={{ display: 'flex', width: 100, alignItems: 'center', overflow: 'hidden' }}
      >
        {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED && (
          <IoCurrency color="primary" fontSize={24} sx={{ marginRight: 8, flexShrink: 0 }} />
        )}
        {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
          <IoImpression color="primary" fontSize={24} sx={{ marginRight: 8, flexShrink: 0 }} />
        )}
        <Box sx={{ width: 100, display: 'flex', alignItems: 'end', overflow: 'hidden' }}>
          <TypoTooltip arrow placement="bottom" sx={{ overflow: 'hidden' }}>
            {data?.rowData.ioName}
          </TypoTooltip>
          {Boolean(data?.rowData?.campaignsCount) && (
            <Typography
              component="span"
              variant="bodyMedium"
              sx={{ ml: 4, mb: 0, flexShrink: 0 }}
            >{`(${data?.rowData?.campaignsCount} Campaign${
              data?.rowData?.campaignsCount! > 1 ? 's' : ''
            })`}</Typography>
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {ioDetail?.data && ioBudgetInfo?.data ? (
          <DialogCampaignListWrapper
            ioDetail={ioDetail?.data}
            ioBudgetInfo={ioBudgetInfo?.data}
            ioId={String(data?.rowData.ioId)}
            dateRange={data?.actionMeta.dateRange}
          />
        ) : (
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 100,
              height: 100,
            }}
          >
            <CircularProgress size={46} thickness={3} />
          </Container>
        )}
      </DialogContent>
    </>
  );
};
