import { AudienceItem, AudienceWarning } from 'models/Audience';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { audienceConstants } from './constants';
import { Option } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface AudienceState {
  audienceTypes: Option<number>[];
  audiences: Option<string>[];
  preBidSegmentIds: number[];
  audienceWarning: AudienceWarning;
  sidebarCampaignInfo: {
    [CampaignInfoField.audiences]: AudienceItem[];
    [CampaignInfoField.audienceTypes]: Option<number>[];
  };
}

const defaultAudienceState: AudienceState = {
  audienceTypes: [],
  audiences: [],
  audienceWarning: AudienceWarning.NONE,
  preBidSegmentIds: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.audiences]: [],
    [CampaignInfoField.audienceTypes]: [],
  },
};

const setStoreField = (
  state: AudienceState,
  action: Action<{ key: string; value: Option<number | string>[] }>,
): AudienceState => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setSidebarCampaignInfo = (
  state: AudienceState,
  action: Action<{ key: string; value: Option<number | string>[] }>,
): AudienceState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetAudienceStore = (state: AudienceState): AudienceState => {
  return {
    ...state,
    audienceWarning: AudienceWarning.NONE,
    preBidSegmentIds: [],
    sidebarCampaignInfo: { ...defaultAudienceState.sidebarCampaignInfo },
  };
};

const setAudienceWarning = (
  state: AudienceState,
  action: Action<AudienceWarning>,
): AudienceState => {
  return {
    ...state,
    audienceWarning: action.payload,
  };
};

const setPreBidSegmentIds = (state: AudienceState, action: Action<number[]>): AudienceState => {
  return {
    ...state,
    preBidSegmentIds: action.payload,
  };
};

const reducer = reducerFromMap<AudienceState>(defaultAudienceState, {
  [audienceConstants.SET_AUDIENCE_FIELD]: setStoreField,
  [audienceConstants.SELECT_AUDIENCE_FIELD]: setSidebarCampaignInfo,
  [audienceConstants.RESET_AUDIENCE_STORE]: resetAudienceStore,
  [audienceConstants.SET_AUDIENCE_WARNING]: setAudienceWarning,
  [audienceConstants.SET_PREBID_SEGMENT_IDS]: setPreBidSegmentIds,
});

export const audience = (state: AudienceState = defaultAudienceState, action: Action<any>) =>
  reducer(state, action);
