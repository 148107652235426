import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Row,
  Alert,
  Typography,
  DialogActions,
  Button,
  Col,
  Box,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';
import { TimezoneProvider } from 'iqm-framework';
import moment from 'moment';
import { Timezone } from 'models/Timezone';
import { useTimezone } from 'hooks/useTimezone';
import { Timezone as TimezonePicker } from 'components/Timezone';
import { OptionId } from 'models/Option';
import { WithResponse } from 'models/Response';
import { DEFAULT_TIME_ZONE, IO_STATUS_ID } from 'constants/insertionOrder';
import { IO_BUDGET_TYPE_ICON } from 'constants/iconMapper';
import { SingleDatePicker } from 'components/SingleDatePicker';
import { CampaignSelect } from 'components/CampaignSelect';
import { IOStartDateWarningDrawer } from 'components/IOStartDateWarningDrawer';
import { LoadingStatus } from 'constants/loading';
import { useDuplicateIO } from 'hooks/useIO';
import { DuplicateIOParams, DuplicateIOResponse } from 'api/IO';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  getSuccessToast?: (newIoId: number) => React.ReactNode;
  onDuplicateSuccess?: () => void;
  insertionOrder: {
    ioId: number;
    ioName: string;
    ioTimezone: number;
    ioBudgetTypeId: number;
    ioStatusId: number;
    ioStartTime: number;
  };
}

const STATUS_FILTER = ['running', 'pending', 'paused', 'expired', 'rejected', 'deleted', 'draft'];

const TIME_DURATION_ERROR = (
  <Typography weight="normal">
    Start time and end time should have at least 30 minutes difference and end time was corrected.
  </Typography>
);

export const DuplicateInsertionOrderDialog = (props: Props) => {
  const { onClose, getSuccessToast, onDuplicateSuccess, insertionOrder = {} as any } = props;
  const {
    ioId,
    ioName,
    ioBudgetTypeId,
    ioTimezone: initialIoTimezone,
    ioStatusId,
    ioStartTime: savedIoStartTime,
  } = insertionOrder;

  const [ioStartTime, _setIoStartTime] = useState<number | null>(
    typeof savedIoStartTime === 'number' &&
      moment(savedIoStartTime).isAfter(moment().add(5, 'minutes'))
      ? savedIoStartTime
      : moment().add(5, 'minutes').valueOf(),
  );
  const [ioEndTime, _setIoEndTime] = useState<number | undefined | null>();
  const [ioTimezone, setIoTimezone] = useState<OptionId<string> | null>(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState<number[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [isDateWarningOpen, setIsDateWarningOpen] = useState(false);
  const isTimezoneInitialized = useRef(false);
  const startTimeDebug = useRef<number | null>(0);
  const endTimeDebug = useRef<number | undefined | null>();
  const dialogActionsRef = useRef<any>(null);

  const timezoneOptions = useTimezone();

  useEffect(() => {
    if (!isTimezoneInitialized.current) {
      const selectedTimezone = timezoneOptions.data?.find(
        (option) => option.id === initialIoTimezone,
      ) as Timezone;

      if (selectedTimezone) {
        setIoTimezone({
          label: selectedTimezone?.label,
          id: selectedTimezone?.id,
          value: selectedTimezone?.name,
        });
        isTimezoneInitialized.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneOptions]);

  useEffect(() => {
    if (!ioStartTime) {
      _setIoStartTime(startTimeDebug.current);
    }
  }, [ioStartTime]);

  const setIoStartTime = (time: number) => {
    _setIoStartTime(null);
    startTimeDebug.current = time;
  };

  useEffect(() => {
    if (ioEndTime === null) {
      _setIoEndTime(endTimeDebug.current);
    }
  }, [ioEndTime]);

  const setIoEndTime = (time: number) => {
    _setIoEndTime(null);
    endTimeDebug.current = time;
  };

  const handleSetStartTime = (startTime: number) => {
    const isBeforeEndTime =
      !ioEndTime || moment(startTime).add(30, 'm').isBefore(moment(ioEndTime));

    if (!isBeforeEndTime) {
      setIoStartTime(startTime);
      setIoEndTime(moment(startTime).add(30, 'm').valueOf());
      enqueueSnackbar(TIME_DURATION_ERROR, { variant: 'info' });
    } else {
      setIoStartTime(startTime);
    }
  };

  const handleSetEndTime = (endTime: number) => {
    const isAfterStartTime = moment(ioStartTime).add(30, 'm').isBefore(moment(endTime));
    if (!isAfterStartTime) {
      enqueueSnackbar(TIME_DURATION_ERROR, { variant: 'info' });
      setIoEndTime(moment(ioStartTime).add(30, 'm').valueOf());
    } else {
      setIoEndTime(endTime);
    }
  };

  const showMutationToast = useCallback(
    (variant: 'error' | 'success') => {
      enqueueSnackbar(
        <>
          <Typography weight="demi">{ioName}</Typography>
          <Typography weight="normal">
            {variant === 'error' ? ' IO could not be duplicated.' : ' IO duplicated successfully.'}
          </Typography>
        </>,
        { variant },
      );
    },
    [ioName],
  );

  const onSuccess = useCallback(
    (res: WithResponse<DuplicateIOResponse>) => {
      if (onDuplicateSuccess) {
        onDuplicateSuccess();
      }

      if (getSuccessToast && res.data?.duplicatedIoId) {
        enqueueSnackbar(getSuccessToast(res.data.duplicatedIoId), { variant: 'success' });
      } else {
        showMutationToast('success');
      }

      setLoading(LoadingStatus.PENDING);
      setIsDateWarningOpen(false);
      onClose();
    },
    [showMutationToast, getSuccessToast, onClose, onDuplicateSuccess],
  );

  const onError = useCallback(() => {
    showMutationToast('error');
    setLoading(LoadingStatus.PENDING);
    onClose();
  }, [showMutationToast, onClose]);

  const duplicateIO = useDuplicateIO(onSuccess, onError);

  const handleConfirm = () => {
    if (!ioTimezone) {
      enqueueSnackbar('No timezone entered.', { variant: 'info' });
      return;
    }
    if (ioStartTime && ioStartTime < moment().valueOf()) {
      setIsDateWarningOpen(true);
      return;
    }

    setLoading(LoadingStatus.LOADING);
    const params = {
      ioStartTime,
      ioEndTime,
      ioTimeZoneId: ioTimezone?.id,
      ioId,
      ioExistingCampaignIds: selectedCampaigns,
    };
    duplicateIO.mutate(params as DuplicateIOParams);
  };

  const IOBudgetIcon = IO_BUDGET_TYPE_ICON[ioBudgetTypeId];

  const onClickStartNow = () => {
    if (!ioTimezone?.id) {
      enqueueSnackbar('No timezone entered.', { variant: 'info' });
      return;
    }

    let endTime = ioEndTime;
    const startTime = moment().seconds(0).milliseconds(0).valueOf();
    const isBeforeEndTime =
      !ioEndTime || moment(startTime).add(30, 'm').isBefore(moment(ioEndTime));

    if (!isBeforeEndTime) {
      endTime = moment(startTime).add(30, 'm').valueOf();
      enqueueSnackbar(TIME_DURATION_ERROR, { variant: 'info' });
    }

    setLoading(LoadingStatus.LOADING);

    const params = {
      ioStartTime: startTime,
      ioEndTime: endTime || undefined,
      ioTimeZoneId: ioTimezone.id,
      ioId,
      ioExistingCampaignIds: selectedCampaigns,
    };

    duplicateIO.mutate(params);
  };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { overflowY: 'visible' } }}
        maxWidth="xl"
        fullWidth
        open
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>Duplicate Insertion Order</DialogTitle>
        <DialogContent dividers sx={{ overflowY: 'visible' }}>
          <Row sx={{ mb: 24 }}>
            <Typography>
              Duplicate the&nbsp;
              {!!IOBudgetIcon && (
                <IOBudgetIcon
                  fontSize={24}
                  style={{ top: 6 }}
                  sx={{ position: 'relative', textColor: 'primary-600' }}
                />
              )}
              &nbsp;<Typography weight="demi">{ioName}</Typography>&nbsp;insertion order
            </Typography>
          </Row>
          <Row sx={{ width: 100, mb: 24 }}>
            <TimezoneProvider timezone={ioTimezone || DEFAULT_TIME_ZONE}>
              <Col>
                <SingleDatePicker
                  label="IO Start Date"
                  date={ioStartTime || undefined}
                  updateDate={handleSetStartTime}
                  placeholderText="Enter Start Date"
                  timezone={ioTimezone || undefined}
                  addedMinutes={0}
                />
              </Col>
              <Col className={styles.datePicker}>
                <SingleDatePicker
                  label="IO End Date"
                  date={ioEndTime || undefined}
                  updateDate={handleSetEndTime}
                  placeholderText={`Enter End Date${
                    ioStatusId === IO_STATUS_ID.EXPIRED ? '' : ' (Optional)'
                  }`}
                  timezone={ioTimezone || undefined}
                />
              </Col>
              <Col>
                <TimezonePicker
                  label="IO Timezone"
                  onChange={setIoTimezone}
                  value={ioTimezone || DEFAULT_TIME_ZONE}
                />
              </Col>
              <Col>
                <CampaignSelect
                  selectedCampaign={selectedCampaigns}
                  setSelectedCampaign={setSelectedCampaigns}
                  ioId={ioId}
                  statusFilter={STATUS_FILTER}
                  variant="outlinedDash"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
                      <Typography variant="label">Campaigns</Typography>
                    </Box>
                  }
                  selectAllOnMount
                />
              </Col>
            </TimezoneProvider>
          </Row>
          <Row>
            <Alert severity="info">
              All the campaigns that are selected will be added as draft campaigns.
            </Alert>
          </Row>
        </DialogContent>
        <DialogActions ref={dialogActionsRef}>
          <Button
            disabled={loading === LoadingStatus.LOADING}
            onClick={onClose}
            variant="contained"
            color="secondary"
            size="medium"
          >
            Cancel
          </Button>
          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={
              !ioStartTime || !ioTimezone || (ioStatusId === IO_STATUS_ID.EXPIRED && !ioEndTime)
            }
            loading={loading === LoadingStatus.LOADING}
          >
            Duplicate
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <IOStartDateWarningDrawer
        isOpen={isDateWarningOpen}
        setIsOpen={setIsDateWarningOpen}
        isLoading={loading === LoadingStatus.LOADING}
        warningText="The start date for the duplicated IO has elapsed. Please change the start date or click on Start IO Now to duplicate the IO with the current date & time."
        anchorRef={dialogActionsRef}
        onClickStartNow={onClickStartNow}
      />
    </>
  );
};
