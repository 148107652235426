import * as React from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@applift/factor';
import { CREATIVE_ID_FROM_NAME } from 'constants/creatives';
import { Add, CreativeImage, Upload } from '@applift/icons';
import { useStore } from 'zustandStore';
import { BlockWiseErrorType, ErrorStateForComponentType, StoreFilter } from 'models/ZustandStore';

export interface CreateSelectCreativeBlockPropTypes {
  selectedCreativeTypeId: number | null;
  setCreativeTypeId: (arg: number | null) => void;
  setCreativeSelectDialogType?: (arg: 'select' | 'create') => void;
  setIsExistingCreative?: (arg: boolean) => void;
}
export const CreateSelectCreativeBlock = (props: CreateSelectCreativeBlockPropTypes) => {
  const {
    selectedCreativeTypeId,
    setCreativeSelectDialogType,
    setCreativeTypeId,
    setIsExistingCreative,
  } = props;

  const [creativesBlockError, resetError, selectedCreatives] = useStore((state: StoreFilter) => [
    state.errorFields.creativesBlock,
    state.errorFields.resetError,
    state.creativesBlock.selectedCreatives,
  ]);

  const nativeCreativeCreateRef = React.useRef<HTMLButtonElement | null | undefined>();
  const [showNativeCreativeCreateMenu, setShowNativeCreativeCreateMenu] = React.useState(false);

  const errorState: ErrorStateForComponentType = React.useMemo(
    () =>
      creativesBlockError
        ? Object.fromEntries(
            creativesBlockError?.map((val: BlockWiseErrorType) => [val.field, val]),
          )
        : {},
    [creativesBlockError],
  ) as ErrorStateForComponentType;

  const selectedCreativeMeta = [
    {
      name: 'image',
      label: 'Image',
      id: CREATIVE_ID_FROM_NAME.Image,
      note: 'Image creatives capture attention with visually appealing graphics, delivering an engaging and eye-catching brand experience.',
    },
    {
      name: 'html',
      label: 'HTML',
      id: CREATIVE_ID_FROM_NAME.HTML,
      note: 'HTML creatives offer dynamic and interactive content, elevating user engagement and interaction with your brand.',
    },
    {
      name: 'video',
      label: 'Video',
      id: CREATIVE_ID_FROM_NAME.Video,
      note: 'Video creatives bring your story to life, delivering a compelling visual narrative to captivate your audience.',
    },
    {
      name: 'audio',
      label: 'Audio',
      id: CREATIVE_ID_FROM_NAME.Audio,
      note: 'Audio creatives immerse users in a rich auditory experience, conveying your brand message through sound.',
    },
    {
      name: 'native',
      label: 'Native',
      id: CREATIVE_ID_FROM_NAME.Native,
      note: 'Native Creatives seamlessly blend into the surrounding content, providing a non-disruptive and cohesive user experience.',
    },
  ];

  const createSelectCreativeHandler = (arg: 'create' | 'select') => {
    if (setCreativeSelectDialogType) setCreativeSelectDialogType(arg);
  };

  React.useEffect(() => {
    if (errorState?.selectedCreatives?.field) {
      setCreativeTypeId(CREATIVE_ID_FROM_NAME.Image);
    }
  }, [errorState?.selectedCreatives?.field, setCreativeTypeId]);

  React.useEffect(() => {
    if (selectedCreatives.length && errorState?.selectedCreatives) {
      resetError({
        blockName: 'creativesBlock',
        field: 'selectedCreatives',
      });
    }
  }, [errorState?.selectedCreatives, resetError, selectedCreatives.length]);

  return selectedCreativeTypeId ? (
    <Box sx={{ bgColor: 'primary-50', mx: 'auto', px: 32, py: 24 }} style={{ width: '60%' }}>
      {selectedCreativeMeta.map((val) =>
        val.id === selectedCreativeTypeId ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="bodyMedium">{val.note}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  mt: 16,
                  gapCol: 16,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button onClick={() => createSelectCreativeHandler('select')} startIcon={<Add />}>
                  Select Creatives
                </Button>
                {selectedCreativeTypeId === CREATIVE_ID_FROM_NAME.Native ? (
                  <Box>
                    <Button
                      ref={nativeCreativeCreateRef as any}
                      onClick={() => setShowNativeCreativeCreateMenu(true)}
                      variant="outlined"
                      startIcon={<Add />}
                      sx={{ bgColor: 'neutral-0' }}
                    >
                      Add New Creatives
                    </Button>
                    <Menu
                      anchorEl={nativeCreativeCreateRef.current}
                      open={showNativeCreativeCreateMenu}
                      onClose={() => setShowNativeCreativeCreateMenu(false)}
                      PaperProps={{
                        sx: { borderColor: 'primary-600' },
                        variant: 'outlined',
                      }}
                    >
                      <MenuItem
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setIsExistingCreative?.(false);
                          createSelectCreativeHandler('create');
                          setShowNativeCreativeCreateMenu(false);
                        }}
                      >
                        <Upload color="primary" sx={{ mr: 8 }} />
                        <Typography variant="bodySmall" component="p" gutterBottom={false}>
                          By uploading Images or Videos
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setIsExistingCreative?.(true);
                          createSelectCreativeHandler('create');
                          setShowNativeCreativeCreateMenu(false);
                        }}
                      >
                        <CreativeImage color="primary" sx={{ mr: 8 }} />
                        <Typography>Using existing Images or Videos</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                ) : (
                  <Button
                    onClick={() => createSelectCreativeHandler('create')}
                    variant="outlined"
                    startIcon={<Add />}
                    sx={{ bgColor: 'neutral-0' }}
                  >
                    New Creatives
                  </Button>
                )}
              </Box>
              {errorState?.selectedCreatives?.field ? (
                <Typography
                  variant="label"
                  component="span"
                  sx={{ textColor: 'danger-400', textAlign: 'left' }}
                >
                  {errorState?.selectedCreatives?.errorText}
                </Typography>
              ) : null}
            </Box>
          </Box>
        ) : null,
      )}
    </Box>
  ) : null;
};
