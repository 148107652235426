import axios, { AxiosInstance } from 'axios';
import { getHeaders, AuthService } from 'iqm-framework';
import { attachInstance } from '@applift/conversion';
import { attachInstance as attachBidModelInstance } from '@applift/bid-model';
import { attachInstance as attachPlatformInstance } from '@applift/platform';
import { attachInstance as attachInventoryInstance } from '@applift/inventory';
import { Logger } from 'utils/logger';

import { ORIGIN_URL } from '../config';

let iqmInstance: AxiosInstance;
let iqmInstanceWithTimestamps: AxiosInstance;

export interface InstanceConfig {
  baseURL: string;
  token: string;
  owId: number;
}

export const onLogOut = () => {
  Logger.log('[LOGOUT]');
  AuthService.unauthorize();
  window.location.href = '/#/login';
};

export function createIqmInstance(config: InstanceConfig) {
  const instance = axios.create({
    ...config,
    baseURL: `${ORIGIN_URL}/api`,
    headers: getHeaders({ apiToken: config.token }, config.owId),
  });

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: response,
          },
        };
      }
      if (response.response?.status === 401) {
        onLogOut();
        // localStorageService.removeBaseInfo();
        // window.location.reload();
      }
      return Promise.reject(response);
    },
  );

  iqmInstance = instance;
  attachInstance(instance);
  attachBidModelInstance(instance);
  // @ts-ignore
  attachPlatformInstance(instance);
  attachInventoryInstance(instance);

  const instanceWithTimestamps = axios.create({
    ...config,
    baseURL: `${ORIGIN_URL}/api`,
    headers: getHeaders({ apiToken: config.token }, config.owId),
  });

  instanceWithTimestamps.interceptors.request.use((request: any) => {
    request.meta = { requestTimestamp: Date.now() };
    return request;
  });

  instanceWithTimestamps.interceptors.response.use(
    (response: any) => {
      return {
        ...response,
        data: {
          ...response.data,
          responseTimestamp: Date.now(),
          requestTimestamp: response.config.meta.requestTimestamp,
        },
      };
    },
    (response: any) => {
      const responseWithTimestamps = {
        ...response,
        responseTimestamp: Date.now(),
        requestTimestamp: response.config.meta.requestTimestamp,
        data: {
          ...response.data,
          responseTimestamp: Date.now(),
          requestTimestamp: response.config.meta.requestTimestamp,
        },
      };

      if (axios.isCancel(response)) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: responseWithTimestamps,
          },
        };
      }
      if (response.response?.status === 401) {
        onLogOut();
      }
      return Promise.reject(responseWithTimestamps);
    },
  );

  iqmInstanceWithTimestamps = instanceWithTimestamps;
}

export function getInstance(): AxiosInstance {
  return iqmInstance || axios;
}

export function getInstanceWithTimestamps(): AxiosInstance {
  return iqmInstanceWithTimestamps || axios;
}
