import * as React from 'react';
import { TypoTooltip } from '@applift/factor';
import { useTimezone } from 'hooks/useTimezone';

interface DateCellProps {
  /**
   * date should be in unix millisecond format.
   */
  epoch: number;
}

export const DateCell = ({ epoch }: DateCellProps) => {
  const displayValue = new Date(epoch).toLocaleDateString('en', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return epoch ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {displayValue}
    </TypoTooltip>
  ) : (
    <>—</>
  );
};

export const TimezoneCell = ({ timezoneName }: { timezoneName: string }) => {
  const { data: timezoneDate } = useTimezone();

  const timezoneLabel = timezoneDate?.filter((timezone) => timezone.name === timezoneName)[0]
    ?.label;

  return timezoneLabel ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {timezoneLabel}
    </TypoTooltip>
  ) : (
    <>—</>
  );
};
