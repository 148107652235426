import React from 'react';
import { Icon } from 'factor';

import { AUDIO_CREATIVE_ID, VIDEO_CREATIVE_ID, Creative } from 'models/Creative';
import { FAILED } from '../../SelectCreativesPopup/constants';
import { AudioCell } from './audioCell';
import { VideoCell } from './videoCell';
import { ImageCell } from './imageCell';

import styles from '../../SelectCreativesPopup/creativeTableView/creativeTable/CreativeTable.module.scss';

export default (onClick?: (creative: Creative) => void) => ({
  className: `w-135-135 ${styles.preview}`,
  key: (creative: Creative) => {
    const {
      creativeTypeId,
      // creativeSource,
      status,
    } = creative;
    if (status === FAILED) {
      return (
        <div className={styles.noDataWrapper}>
          <Icon name="NoImage" />
        </div>
      );
    }
    switch (creativeTypeId) {
      case VIDEO_CREATIVE_ID:
        return <VideoCell creative={creative} onClick={onClick} />;
      case AUDIO_CREATIVE_ID:
        return <AudioCell creative={creative} />;
      default:
        return (
          <div
            onClick={onClick ? () => onClick(creative) : undefined}
            className={styles.maxHeight80}
          >
            <ImageCell creative={creative} />
          </div>
        );
    }
  },
});
