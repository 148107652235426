import {
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  AccordionDetails,
  Paper,
  sx,
} from '@applift/factor';
import React from 'react';

export interface BlockWrapperTypes {
  icon?: JSX.Element | string;
  title: JSX.Element | string;
  content?: JSX.Element | string;
  defaultExpanded?: boolean;
  expanded?: boolean;
  children?: JSX.Element;
  blockSummary?: JSX.Element;
  blockCta?: JSX.Element;
  onChange?: () => void;
  id?: string;
  showExpandIcon?: boolean;
}

export const BlockWrapper = (props: BlockWrapperTypes) => {
  const {
    content,
    title,
    icon,
    defaultExpanded = false,
    expanded = undefined,
    children,
    onChange,
    blockSummary,
    id,
    showExpandIcon = true,
    blockCta,
  } = props;
  return (
    <Paper elevation={3} sx={{ mb: 16 }} id={id}>
      <Accordion defaultExpanded={defaultExpanded} expanded={expanded} onChange={onChange}>
        <AccordionSummary
          /* eslint-disable-next-line */
          {...(!showExpandIcon
            ? { expandIcon: '' }
            : {
                classes: {
                  content: sx({
                    width: 100,
                    display: 'flex',
                    alignItems: 'center',
                  }),
                },
              })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 32 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {icon}
              <Typography variant="bodyMedium" component="p" weight="demi" sx={{ ml: 8, mb: 0 }}>
                {title}
              </Typography>
            </Box>
            {!expanded ? <Box>{blockSummary}</Box> : null}
          </Box>
          <Box
            sx={{
              width: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              ml: 'auto',
              mr: 8,
            }}
          >
            {blockCta}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{content || children}</AccordionDetails>
      </Accordion>
    </Paper>
  );
};
