import * as React from 'react';
import { Icon, ButtonCircle } from 'factor';
import { Tooltip, IconButton, TypoTooltip } from '@applift/factor';
import { pluralize } from 'utils/pluralize';
import { Option } from 'models/Option';
import { Download } from '@applift/icons';

import styles from './index.module.scss';

type PropsType = {
  iconName: string;
  title: string;
  items: Option<number | string>[];
  handleRemoveSection: () => void;
  invalidDataCount?: number;
  downloadInvalidData?: () => void;
  dismissInvalidData?: () => void;
  downloadValidData?: () => void;
  rowCount?: number;
  isReadOnly?: boolean;
  errorMsg?: string;
  parentApp?: string | null;
  isFile?: boolean;
};

export const ListItem = (Props: PropsType) => {
  const {
    errorMsg,
    iconName,
    title,
    items,
    invalidDataCount,
    isReadOnly,
    handleRemoveSection,
    dismissInvalidData,
    downloadInvalidData,
    downloadValidData,
    rowCount,
    parentApp,
    isFile,
  } = Props;

  const locationCount = rowCount ?? items.length;
  const [showError, setShowError] = React.useState(false);

  return (
    <>
      <div className={styles.accordionHeader}>
        {/* eslint-disable-next-line   no-extra-boolean-cast */}
        {Boolean(errorMsg) ? (
          <Tooltip placement="top" title={errorMsg} arrow className="custom-tooltip">
            <div>
              <Icon name="ErrorTriangle" className="mr-2" />
            </div>
          </Tooltip>
        ) : (
          <Icon name={iconName} className="mr-2" />
        )}
        <TypoTooltip
          title={isFile ? title : ''}
          placement="top"
          arrow
          aria-multiline
          sx={{ display: 'block' }}
          className="title"
        >
          {title}
        </TypoTooltip>
        <div>
          &nbsp;(
          {locationCount < 1000
            ? locationCount
            : /* @ts-ignore */
              `${parseFloat(locationCount / 1000).toFixed(2)}k`}
          )
        </div>
        {!!invalidDataCount && (
          <Tooltip title={`${!showError ? 'Click to view message' : ''}`} arrow placement="top">
            <ButtonCircle
              iconName="Info"
              onClick={() => setShowError((prev) => !prev)}
              className={`${styles.erroIcon} ml-2 info-icon`}
            />
          </Tooltip>
        )}
        {(parentApp === 'bidModel' || parentApp === 'customers') && downloadValidData && (
          <Tooltip title="Download file" arrow placement="top" className="ml-auto">
            <IconButton>
              <Download fontSize={16} onClick={downloadValidData} />
            </IconButton>
          </Tooltip>
        )}
        {!isReadOnly ? (
          <Tooltip title="Remove locations" arrow placement="top">
            <div className="ml-auto">
              <ButtonCircle
                iconName="Delete"
                onClick={handleRemoveSection}
                className={styles.removeAccordion}
              />
            </div>
          </Tooltip>
        ) : null}
        <Tooltip
          title="Only the first 1000 locations are visibe on the map"
          arrow
          placement="top-end"
        >
          <div className={styles.infoIcon}>
            <Icon name="Info" />
          </div>
        </Tooltip>
      </div>
      {showError && !!invalidDataCount && !!dismissInvalidData && downloadInvalidData && (
        <div className={styles.warning}>
          <span className={styles.text}>
            {invalidDataCount} {pluralize('Row', invalidDataCount)} failed. Please fix failed{' '}
            {pluralize('row', invalidDataCount)} and re-upload.
          </span>
          <div onClick={downloadInvalidData} className={styles.link}>
            <Icon name="Download" className="mr-2" />
            <span>Download failed {pluralize('row', invalidDataCount)}</span>
          </div>
          <ButtonCircle
            iconName="Close"
            className={styles.removeButton}
            onClick={() => setShowError(false)}
          />
        </div>
      )}
    </>
  );
};
