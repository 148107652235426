import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { inventoryConstants } from './constants';
import { Option, ExcludedOption } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface InventoryState {
  inventoryGroups: Option<number>[];
  dealGroups: Option<number>[];
  sidebarCampaignInfo: {
    [CampaignInfoField.inventoryGroups]: ExcludedOption<number>[];
    [CampaignInfoField.dealGroups]: Option<number>[];
    [CampaignInfoField.pmpDeals]: Option<number>[];
  };
}

const defaultInventoryState: InventoryState = {
  inventoryGroups: [],
  dealGroups: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.inventoryGroups]: [],
    [CampaignInfoField.dealGroups]: [],
    [CampaignInfoField.pmpDeals]: [],
  },
};

const setStoreField = (
  state: InventoryState,
  action: Action<{ key: string; value: Option<number>[] }>,
): InventoryState => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setSidebarCampaignInfo = (
  state: InventoryState,
  action: Action<{ key: string; value: Option<number>[] }>,
): InventoryState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetInventoryStore = (state: InventoryState): InventoryState => {
  return {
    ...state,
    sidebarCampaignInfo: { ...defaultInventoryState.sidebarCampaignInfo },
  };
};

const reducer = reducerFromMap<InventoryState>(defaultInventoryState, {
  [inventoryConstants.SET_INVENTORY_FIELD]: setStoreField,
  [inventoryConstants.SELECT_INVENTORY_FIELD]: setSidebarCampaignInfo,
  [inventoryConstants.RESET_INVENTORY_STORE]: resetInventoryStore,
});

export const inventory = (state: InventoryState = defaultInventoryState, action: Action<any>) =>
  reducer(state, action);
