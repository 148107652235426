import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';

import { getModelledDimensionsCountKey } from '../api/QueryKeys';
import { WithResponse } from '../models/Response';
import { getModelledDimensionsCount } from '../api/Count';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useModelledDimensionsCount = (
  countParam: {
    campaignId: number;
    dimensionId?: number;
  },
  options?: {
    enabled?: boolean;
  },
) => {
  const data = useQuery(
    getModelledDimensionsCountKey.keys(
      'getModelledDimensionsCount',
      countParam.campaignId,
      countParam.dimensionId,
    ),
    getModelledDimensionsCount,
    {
      onError,
      enabled: options?.enabled,
      cacheTime: 0,
    },
  );
  return data;
};
