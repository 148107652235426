type KMLCoordinate = {
  lat: number;
  lon: number;
  alt?: number;
};

interface KMLGeometry {
  type: string;
  coordinates: KMLCoordinate[] | KMLCoordinate[][] | KMLCoordinate[][][] | number[];
}

interface KMLFeature {
  type: string;
  geometry: KMLGeometry | null;
  properties: { [key: string]: any };
}

interface KMLRoot {
  features: KMLFeature[];
}

const parseCoordinates = (coords: string[]): KMLCoordinate[] => {
  const result: KMLCoordinate[] = [];
  coords.forEach((coord) => {
    const [lon, lat, alt] = coord.split(',').map((c) => parseFloat(c));
    result.push({ lon, lat, alt });
  });
  return result;
};

export const kmlToGeoJSON = (kml: any): any => {
  const parser = new DOMParser();
  const kmlDoc = parser.parseFromString(kml, 'application/xml');
  const kmlRoot: KMLRoot = { features: [] };

  const placemarks = kmlDoc.querySelectorAll('Placemark');
  for (let i = 0; i < placemarks.length; i += 1) {
    const placemark = placemarks[i];
    const feature: KMLFeature = {
      type: 'Feature',
      geometry: null,
      properties: {},
    };
    const name = placemark.querySelector('name');
    if (name) {
      feature.properties.name = name.textContent;
    }
    const description = placemark.querySelector('description');
    if (description) {
      feature.properties.description = description.textContent;
    }
    const point = placemark.querySelector('Point');
    const lineString = placemark.querySelector('LineString');
    const polygon = placemark.querySelector('Polygon');
    if (point) {
      const coordinates = point.querySelector('coordinates')?.textContent?.trim().split(',');
      const [lng, lat, alt] = coordinates?.map((coord) => parseFloat(coord)) as number[];
      feature.geometry = {
        type: 'Point',
        coordinates: [lng, lat, alt],
      };
    } else if (lineString) {
      const coordinates = lineString
        .querySelector('coordinates')
        ?.textContent?.trim()
        .split(' ') as string[];
      feature.geometry = {
        type: 'LineString',
        coordinates: parseCoordinates(coordinates),
      };
    } else if (polygon) {
      const outerRing = polygon
        .querySelector('outerBoundaryIs coordinates')
        ?.textContent?.trim()
        .split(' ') as string[];
      const innerRings = polygon.querySelectorAll('innerBoundaryIs coordinates');
      const coordinates = [parseCoordinates(outerRing)];
      innerRings.forEach((innerRing) => {
        const innerRingCoords = innerRing.textContent?.trim().split(' ');
        coordinates.push(parseCoordinates(innerRingCoords as string[]));
      });
      feature.geometry = {
        type: 'Polygon',
        coordinates,
      };
    }
    kmlRoot.features.push(feature);
  }
  return kmlRoot;
};

export const getFileExtension = (file: File) => file.name.substring(file.name.lastIndexOf('.') + 1);
