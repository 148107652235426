import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Box, Paper } from '@applift/factor';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { ScreenLoader } from 'components/ScreenLoader';
import { BreadCrumbActions } from 'components/BreadCrumbActions';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { useTimezone } from 'hooks/useTimezone';
import { useCreativeList } from 'hooks/useCreatives';
import { ConversionType } from 'models/Conversion';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import {
  applicationActions,
  SetBudgetTypeId,
  ResetStoreForCampaignSwitch,
  ResetAppStoreFull,
  SetModellingDimensionToShow,
} from 'store/app/actions';
import { API } from 'api';
import { useIOInfo } from 'hooks/useIO';
import { useClearPgCampaignStore } from 'hooks/useClearPgCampaignStore';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { AppState } from '../../../models/Store';
import { EditPGCampaign } from './EditPGCampaignPage';

import styles from './styles.module.scss';

type EditCampaignWrapperComponentProps = SetBudgetTypeId & SetModellingDimensionToShow & {};

const EditCampaignWrapperComponent = (props: EditCampaignWrapperComponentProps) => {
  const params = useParams<{ id: string }>();

  const [
    initCampaignInfoBlock,
    initCampaignCreativesSlice,
    initCampaignLocationSlice,
    initCampaignInventorySlice,
    initCampaignPoliticalAdvertiserBlock,
    initConversionBlock,
    setIsEditMode,
    setCampaignDetails,
    ioDetails,
    setIoDetails,
  ] = useStore((state: StoreFilter) => [
    state.campaignInfoBlock.initCampaignInfoBlock,
    state.creativesBlock.initCampaignCreativesSlice,
    state.locationBlock.initCampaignLocationSlice,
    state.inventoryBlock.initCampaignInventorySlice,
    state.politicalAdvertiser.initCampaignPoliticalAdvertiserBlock,
    state.conversionBlock.initConversionBlock,
    state.pgCampaignPage.setIsEditMode,
    state.pgCampaignPage.setCampaignDetails,
    state.pgCampaignPage.ioDetails,
    state.pgCampaignPage.setIoDetails,
  ]);

  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(null);
  const [editableCampaign, setEditableCampaign] = React.useState<ExistingCampaignData | null>(null);
  const { id: campaignID } = params;
  let campaignId: number = -1;
  if (campaignID) {
    campaignId = Number(campaignID);
  }

  const { data: creativeOverallData } = useCreativeList(
    {
      creativeIds: editableCampaign?.creativeIds,
    },
    { enabled: Boolean(editableCampaign?.creativeIds) },
  );

  const { data: timezones } = useTimezone();

  const transpiledTimezone = React.useMemo(
    () =>
      timezones?.map((val) => ({
        label: val.name,
        value: val.name,
        id: val.id,
      })),
    [timezones],
  );

  const { resetStore } = useClearPgCampaignStore();

  const isStoreReset = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (resetStore && !isStoreReset.current) {
      resetStore();
      isStoreReset.current = true;
    }
  }, [resetStore]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (campaignId >= 0) {
        try {
          setIsLoading(true);
          const editableCampaign = await API.Campaign.FetchCampaignData(campaignId);
          if (editableCampaign?.campaignTypeId !== CAMPAIGN_TYPE_BY_NAME.pg) {
            history.replace(`/io-detail/${editableCampaign.ioId}`);
          }
          if (['deleted', 'expired'].includes(editableCampaign?.status as string)) {
            history.replace(`/io-detail/${editableCampaign.ioId}`);
          }
          if (editableCampaign) {
            setIsEditMode(true);
            setEditableCampaign(editableCampaign);
          }

          setIsLoading(false);
        } catch (e) {
          setError(e);
          setIsLoading(false);
        }
      } else {
        setError('Invalid Campaign ID');
        setIsLoading(false);
      }
    };
    fetchData();
  }, [campaignId, history, setIsEditMode]);

  React.useEffect(() => {
    if (editableCampaign) {
      initCampaignInfoBlock({
        campaignName: editableCampaign.campaignName,
        timezone: transpiledTimezone?.filter((val) => val.id === editableCampaign.timezone)[0],
        spendingBudget: editableCampaign.spendingBudget,
        totalImpressions: (editableCampaign.totalImpressions as number) ?? null,
        maxBidPrice: editableCampaign.maxBid,
        startDate: editableCampaign.startTime,
        endDate: (editableCampaign.endTime as number) ?? null,
        advertiserDomain: editableCampaign.advertiserDomain,
        pgFeesBudget: editableCampaign?.pgFeesBudget || null,
        pgFeesPercentage: editableCampaign?.pgFeesPercentage || null,
        totalBudget: editableCampaign?.budgetTotal || null,
      });
    }
  }, [editableCampaign, initCampaignInfoBlock, transpiledTimezone]);

  React.useEffect(() => {
    if (editableCampaign) {
      initCampaignCreativesSlice({
        selectedCreatives: creativeOverallData?.responseObject?.data ?? [],
        creativeTypeId: editableCampaign.creativeType,
      });
    }
  }, [creativeOverallData?.responseObject?.data, editableCampaign, initCampaignCreativesSlice]);

  React.useEffect(() => {
    if (editableCampaign) {
      initCampaignLocationSlice({
        country: Number(editableCampaign.countryId),
      });
    }
  }, [editableCampaign, initCampaignLocationSlice]);

  React.useEffect(() => {
    if (editableCampaign) {
      initCampaignInventorySlice({
        pgDealIds: editableCampaign.pgDealIds ?? [],
      });
    }
  }, [editableCampaign, initCampaignInventorySlice]);

  React.useEffect(() => {
    if (editableCampaign && editableCampaign.id) {
      setCampaignDetails(editableCampaign);
    }
  }, [editableCampaign, setCampaignDetails]);

  React.useEffect(() => {
    if (editableCampaign && editableCampaign.id) {
      initConversionBlock({
        conversionType: editableCampaign?.conversionTypeId as ConversionType,
        selectedConversions: editableCampaign.conversionIds?.split(',').map(Number) as number[],
      });
    }
  }, [editableCampaign, initConversionBlock]);

  React.useEffect(() => {
    if (editableCampaign) {
      initCampaignPoliticalAdvertiserBlock({
        politicalAdvertiserClientId: editableCampaign?.politicalAdvertiserClientId ?? null,
        isPoliticalCampaign: Boolean(editableCampaign?.politicalAdvertiserClientId) ?? false,
      });
    }
  }, [editableCampaign, initCampaignPoliticalAdvertiserBlock]);

  const { data: ioDetailResponse, isLoading: ioDetailsLoading } = useIOInfo(
    editableCampaign?.ioId as unknown as string,
    {
      enabled: !!editableCampaign?.ioId,
    },
  );

  React.useEffect(() => {
    if (!ioDetails || ioDetails.ioId !== ioDetailResponse?.data?.ioId) {
      // @ts-ignore
      setIoDetails(ioDetailResponse?.data);
    }
  }, [ioDetailResponse?.data, ioDetails, setIoDetails]);

  React.useEffect(() => {
    if (!ioDetails) {
      // @ts-ignore
      setIoDetails(ioDetailResponse?.data);
    }
  }, [ioDetailResponse?.data, ioDetails, setIoDetails]);

  /* eslint-disable */
  return !isLoading && !ioDetailsLoading && !error ? (
    <EditPGCampaign editableCampaignProp={editableCampaign} />
  ) : error ? (
    <Container className={styles.LoaderContainer}>{error}</Container>
  ) : (
    <Container className={styles.LoaderContainer}>
      <ScreenLoader text="Loading..." />
    </Container>
  );
};

const mapCampaignTypeAction = {
  setBudgetTypeId: applicationActions.setBudgetTypeId,
  setModellingDimensionToShow: applicationActions.setModellingDimensionToShow,
};

const EditCampaignWrapper = connect(null, mapCampaignTypeAction)(EditCampaignWrapperComponent);

// Here we only clear the redux store, so we can initialize campaign with a fresh store
export interface ClearCampaignStoreWrapperComponentProps
  extends ResetStoreForCampaignSwitch,
    ResetAppStoreFull {
  campaignDataResetting?: AppState['app']['campaignDataResetting'];
}

const ClearCampaignStoreWrapperComponent = (props: ClearCampaignStoreWrapperComponentProps) => {
  const { resetStoreForCampaignSwitch, campaignDataResetting, resetAppStoreFull } = props;
  const { id: campaignId } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();

  const invokedRef = React.useRef(false);
  const prevCampaignIdRef = React.useRef(campaignId);

  React.useEffect(() => {
    // @ts-ignore
    if ((location?.state?.hasError ?? false) === true) {
      invokedRef.current = true;
      setIsLoading(false);
      return;
    }
    if (campaignId !== prevCampaignIdRef.current) {
      invokedRef.current = false;
      prevCampaignIdRef.current = campaignId;
    }
    if (campaignDataResetting === false && invokedRef.current === false) {
      setIsLoading(true);
      invokedRef.current = true;
      resetAppStoreFull();
      resetStoreForCampaignSwitch();
    } else if (campaignDataResetting === false) {
      setIsLoading(false);
    }
  }, [campaignDataResetting, resetStoreForCampaignSwitch, setIsLoading, campaignId]);

  return (
    <Box className={styles.container}>
      <Paper
        elevation={2}
        className={styles.breadcrumbBar}
        sx={{ px: 16, display: 'flex', alignItems: 'center', justifyContent: 'between' }}
      >
        <BreadCrumbActions panelType="campaign" />
      </Paper>
      {isLoading ? <ScreenLoader text="Loading..." /> : <EditCampaignWrapper />}
    </Box>
  );
};

const mapWrapperState = (state: AppState) => ({
  campaignDataResetting: state.app.campaignDataResetting,
});

const mapWrapperActions = {
  resetStoreForCampaignSwitch: applicationActions.resetStoreForCampaignSwitch,
  resetAppStoreFull: applicationActions.resetAppStoreFull,
};

export const EditPGCampaignPage = connect(
  mapWrapperState,
  mapWrapperActions,
)(ClearCampaignStoreWrapperComponent);
