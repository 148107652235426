import { AxiosError, AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';

import { WithResponse } from 'models/Response';
import {
  CampaignCreativeCountType,
  CampaignStatusCountType,
  ModelledDimensionsCount,
} from 'models/Count';
import { getInstance } from './Instance';
import {
  getCampaignByCreativeCountKey,
  getCampaignByStatusCountKey,
  getModelledDimensionsCountKey,
} from './QueryKeys';

export const getCampaignByStatusCount = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCampaignByStatusCountKey['keys']>>): Promise<
  WithResponse<CampaignStatusCountType[]>
> => {
  try {
    const { ioId, creativeTypeIds } = queryKey[0];
    const response: AxiosResponse<WithResponse<CampaignStatusCountType[]>> =
      await getInstance().post('/v3/cmp/campaigns/count', {
        owIds: 'all',
        creativeTypeIds,
        ioIds: ioId,
      });
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const getCampaignByCreativeCount = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCampaignByCreativeCountKey['keys']>>): Promise<
  WithResponse<CampaignCreativeCountType[]>
> => {
  try {
    const { ioIds, status } = queryKey[0];
    const response: AxiosResponse<WithResponse<CampaignCreativeCountType[]>> =
      await getInstance().post('/v3/cmp/creative-types/count', {
        ioIds,
        status,
      });
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const getModelledDimensionsCount = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getModelledDimensionsCountKey['keys']>>) => {
  try {
    const response: AxiosResponse<WithResponse<ModelledDimensionsCount>> = await getInstance().get(
      `/v3/bm/campaigns/${queryKey[0]?.campaignId}/bid-models/count`,
      {
        params: {
          dimensionId: queryKey[0]?.dimensionId,
        },
      },
    );
    return response.data.data;
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response || e);
  }
};
