import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { AppState } from 'models/Store';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { CircleExtended } from 'models/CircleExtended';
import { PolygonExtended } from 'models/PolygonExtended';
import { milesToMeters } from 'utils/format';
import {
  mapActions,
  PushPreSavedCircle,
  PushPreSavedPolygon,
  PushPreSavedRectangle,
} from 'store/map/actions';
import { applicationActions, SetMapLoadingStatus } from 'store/app/actions';
import { RectangleExtended } from 'models/RectangleExtended';
import { locationActions, ClearLocationFiles } from 'store/location/actions';
import { ILocationFile } from 'store/location/reducer';
import { ErrorCreatingResponse } from 'models/Response';
import { GeoLocation } from 'api/Location';
import { advanceActions, SetBlackList, SetWhiteList } from 'store/advance/actions';
import { shapeType } from 'constants/location';

type Props = PushPreSavedCircle &
  PushPreSavedPolygon &
  PushPreSavedRectangle &
  ClearLocationFiles &
  SetWhiteList &
  SetBlackList &
  SetMapLoadingStatus & {
    editableCampaign: ExistingCampaignData | null;
    errorCreating: ErrorCreatingResponse | null;
    addLocationFile: (file: ILocationFile) => void;
    isMapInstalled: boolean;
  };

const EditableCampaignMarkersComponent = (props: Props) => {
  const {
    editableCampaign,
    errorCreating,
    isMapInstalled,
    pushPreSavedCircle,
    pushPreSavedPolygon,
    pushPreSavedRectangle,
    addLocationFile,
    clearLocationFiles,
    setWhiteList,
    setBlackList,
    setMapLoadingStatus,
  } = props;
  const { google } = window;

  const prevCampaignValue = useRef(editableCampaign);
  const alreadySet = useRef(false);
  const [geoRadiusDetailsLoaded, setGeoRadiusDetailsLoaded] = useState(false);
  const [locationDetailsLoaded, setLocationDetailsLoaded] = useState(false);

  useEffect(() => {
    if (geoRadiusDetailsLoaded && locationDetailsLoaded) {
      setMapLoadingStatus(false);
    }
  }, [geoRadiusDetailsLoaded, locationDetailsLoaded, setMapLoadingStatus]);

  useEffect(() => {
    if (
      editableCampaign &&
      (!editableCampaign.whiteListedGeoRadiusDetails ||
        !editableCampaign.blackListedGeoRadiusDetails) &&
      (!editableCampaign.locationDetails || isEmpty(editableCampaign.locationDetails))
    ) {
      setMapLoadingStatus(false);
    }
  }, [editableCampaign, setMapLoadingStatus]);

  useEffect(() => {
    if (
      editableCampaign &&
      !isEqual(editableCampaign, prevCampaignValue.current) &&
      (editableCampaign.whiteListedGeoRadiusDetails ||
        editableCampaign.blackListedGeoRadiusDetails ||
        editableCampaign.locationDetails) &&
      !alreadySet.current &&
      isMapInstalled
    ) {
      const { blackListedGeoRadiusDetails = [], whiteListedGeoRadiusDetails = [] } =
        editableCampaign;
      const editableGeoRadiusDetails = [
        ...blackListedGeoRadiusDetails,
        ...whiteListedGeoRadiusDetails,
      ];
      const promises: Promise<void | undefined>[] = [];

      if (editableGeoRadiusDetails) {
        editableGeoRadiusDetails.forEach((data) => {
          const isInclude = whiteListedGeoRadiusDetails.some((item) => isEqual(item, data));
          if (data.type === shapeType.circle) {
            const circle = new CircleExtended({
              circle: new google.maps.Circle({
                center: {
                  lat: data.latitude,
                  lng: data.longitude,
                },
                radius: milesToMeters(data.radius),
              }),
              isInclude,
              addressData: {
                address: data.address,
                state: data.sid,
                country: data.cid,
              },
            });
            pushPreSavedCircle(circle);
            promises.push(Promise.resolve(undefined));
          } else if (data.polypath?.length && data.type === shapeType.polygon) {
            const polygon = new PolygonExtended({
              polygon: new google.maps.Polygon({
                paths: data.polypath,
              }),
              isInclude,
              addressData: {
                address: data.address,
                state: data.sid,
                country: data.cid,
              },
            });
            promises.push(polygon.update().finally(() => pushPreSavedPolygon(polygon)));
          } else if (data.polypath?.length && data.type === shapeType.rectangle) {
            const rectangle = new RectangleExtended({
              rectangle: new google.maps.Rectangle({
                bounds: new google.maps.LatLngBounds(data.polypath[2], data.polypath[0]),
              }),
              isInclude,
              addressData: {
                address: data.address,
                state: data.sid,
                country: data.cid,
              },
            });
            promises.push(rectangle.update().finally(() => pushPreSavedRectangle(rectangle)));
          }
        });
        Promise.allSettled(promises).finally(() => {
          setGeoRadiusDetailsLoaded(true);
        });
        alreadySet.current = true;
      } else {
        setGeoRadiusDetailsLoaded(true);
      }
    }
  }, [
    editableCampaign,
    google,
    isMapInstalled,
    pushPreSavedCircle,
    pushPreSavedPolygon,
    pushPreSavedRectangle,
  ]);

  const addCircle = useCallback(
    (data, locationData) => {
      if (google) {
        const circle = new CircleExtended({
          circle: new google.maps.Circle({
            center: {
              lat: locationData.latitude,
              lng: locationData.longitude,
            },
            radius: milesToMeters(locationData.radius),
          }),
          isInclude: locationData.isIncluded,
          addressData: {
            address: locationData.address,
            state: locationData.sid,
            country: locationData.cid,
          },
          fileId: data.id,
          filename: data.fileName,
          locationId: locationData.locationId,
        });
        pushPreSavedCircle(circle);
      }
    },
    [pushPreSavedCircle, google],
  );

  const addPolygon = useCallback(
    (data, locationData: GeoLocation) => {
      if (google && locationData.polypath) {
        const polygon = new PolygonExtended({
          polygon: new google.maps.Polygon({
            paths: locationData.polypath.map((i) => ({
              lat: i.x,
              lng: i.y,
            })),
          }),
          isInclude: locationData.isIncluded,
          fileId: data.id,
          locationId: locationData.locationId,
          addressData: {
            address: locationData.address || '',
            state: locationData.sid,
            country: locationData.cid,
          },
        });
        pushPreSavedPolygon(polygon);
      }
    },
    [google, pushPreSavedPolygon],
  );

  useEffect(() => {
    if (editableCampaign && isMapInstalled && !errorCreating && !locationDetailsLoaded) {
      const { locationDetails } = editableCampaign;
      const fileTypeMapper: { [key: string]: 'addresses' | 'polygons' | 'locations' } = {
        address: 'addresses',
        kml: 'polygons',
        location: 'locations',
      };

      if (locationDetails) {
        clearLocationFiles();
        Object.values(locationDetails).forEach((data) => {
          addLocationFile({
            filename: data.fileName,
            type: fileTypeMapper[data.fileType] || 'locations',
            fileId: data.id,
            validLocations: data.validLocations,
            invalidLocationsCount: data.invalidLocations?.length,
            rowCount: data.rowCount,
          });

          const newWhiteList = new Set<number>();
          const newBlackList = new Set<number>();
          if (data.validLocations) {
            data.validLocations.forEach((locationData) => {
              if (data.fileType === 'kml') {
                addPolygon(data, locationData);
              } else {
                addCircle(data, locationData);
              }

              if (locationData.isIncluded) {
                newWhiteList.add(locationData.locationId);
              } else {
                newBlackList.add(locationData.locationId);
              }
            });
          }
          // Need to figure out why we had this call here - caused issue during slow internet since fillStore was called first to set
          // whiteList location and this call will clear those, plus this call is not combining whiteList locations and overwriting previous files whiteList location
          // Commented this code to fix CB-1624
          // setWhiteList('whiteListedLocationIds', newWhiteList);
          // setBlackList('blackListedLocationIds', newBlackList);
        });
      }
      setLocationDetailsLoaded(true);
    }
  }, [
    editableCampaign,
    errorCreating,
    isMapInstalled,
    addLocationFile,
    addCircle,
    addPolygon,
    clearLocationFiles,
    setWhiteList,
    setBlackList,
    locationDetailsLoaded,
  ]);

  return null;
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
  errorCreating: state.app.errorCreating,
  isMapInstalled: state.map.isMapInstalled,
});

const actions = {
  pushPreSavedCircle: mapActions.pushPreSavedCircle,
  pushPreSavedPolygon: mapActions.pushPreSavedPolygon,
  pushPreSavedRectangle: mapActions.pushPreSavedRectangle,
  addLocationFile: locationActions.addLocationFile,
  clearLocationFiles: locationActions.clearLocationFiles,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  setMapLoadingStatus: applicationActions.setMapLoadingStatus,
};

export const EditableCampaignMarkers = connect(mapState, actions)(EditableCampaignMarkersComponent);
