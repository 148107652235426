import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames/bind';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ExistingCampaignData, ParentAppErrors } from 'models/ExistingCampaign';
import {
  advanceActions,
  SetBlackList,
  SetCampaignSidebarInfo,
  SetWhiteList,
} from 'store/advance/actions';
import {
  getLocationAPIList,
  isLocationSelectionPresent,
} from 'pages/MainPage/CampaignPage/helpers';
import { isBoolean } from 'lodash';
import { mapActions, SetDrawerMode } from 'store/map/actions';
import { locationActions, SetOrRelationship } from 'store/location/actions';
import { Tags } from '../../MainPage/CampaignPage/LocationBlock/Tags';
import { Map } from '../../MainPage/CampaignPage/LocationBlock/Map';
import { EditableCampaignMarkers } from '../../MainPage/CampaignPage/LocationBlock/EditableCampaignMarkers';

import styles from './MapBlock.module.scss';

const cx = classNames.bind(styles);

interface Props
  extends SetDrawerMode,
    SetCampaignSidebarInfo,
    SetWhiteList,
    SetBlackList,
    SetOrRelationship {
  editableCampaign: ExistingCampaignData | null;
  isOpenMapVisible: boolean;
  variant?: string;
  parentAppErrors?: ParentAppErrors | null;
}

const MapViewBlockComponent = forwardRef<any, Props>((props, ref) => {
  const {
    setDrawerMode,
    setCampaignSidebarInfo,
    editableCampaign,
    isOpenMapVisible,
    setWhiteList,
    setBlackList,
    variant,
    parentAppErrors,
    setLocationOrRelationship,
  } = props;

  const [initialized, setInitialized] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = isOpenMapVisible ? 'hidden' : '';
  }, [isOpenMapVisible]);

  useEffect(() => {
    if (editableCampaign && !initialized) {
      if (editableCampaign.filterStore?.country) {
        setCampaignSidebarInfo(CampaignInfoField.country, editableCampaign.filterStore.country);
      }

      if (isBoolean(editableCampaign.isLocationWithOrFilter)) {
        setLocationOrRelationship(editableCampaign.isLocationWithOrFilter);
      }

      if (isLocationSelectionPresent(editableCampaign)) {
        try {
          const apiList = getLocationAPIList(
            editableCampaign,
            editableCampaign?.parentApp?.toLowerCase() as string,
          );
          Promise.allSettled(apiList.map((field) => field.api)).then((results) => {
            results.forEach((result, idx) => {
              if (result.status === 'fulfilled' && result.value.length) {
                setCampaignSidebarInfo(apiList[idx].field, result.value);
              }
            });
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
      setInitialized(true);
    }
  }, [
    editableCampaign,
    setDrawerMode,
    setInitialized,
    initialized,
    setCampaignSidebarInfo,
    setBlackList,
    setWhiteList,
    setLocationOrRelationship,
  ]);

  return variant === 'maps' ? (
    <div className={styles.locationsWrapperWithBadges}>
      <div
        className={cx({
          map_container: true,
          map_container__visible: isOpenMapVisible,
          map_container__visible__wide: isOpenMapVisible,
        })}
      >
        <Map />
      </div>
    </div>
  ) : (
    <div className={styles.locationsWrapperWithTags}>
      <div
        className={cx({
          map_container: true,
          map_container__visible: isOpenMapVisible,
          map_container__visible__wide: isOpenMapVisible,
        })}
      >
        <div className={styles.info}>
          <div className={styles.infoLabel}>Country:</div>
          <div className={styles.infoText}>
            {parentAppErrors?.countryError || editableCampaign?.filterStore?.country?.label}
          </div>
        </div>
        <Map />
      </div>
      <div
        className={
          editableCampaign?.parentApp === 'customers' || editableCampaign?.parentApp === 'bidModel'
            ? styles.customersAppRightContainer
            : styles.rightContainer
        }
        ref={containerRef}
      >
        <Tags containerRef={containerRef.current ?? undefined} />
        <EditableCampaignMarkers />
      </div>
    </div>
  );
});

const mapState = createSelector(
  (state: AppState) => state.app,
  (state: AppState) => state.map,
  (state: AppState) => ({ advanced: state.advanced, map: state.map, location: state.location }),
  (appStore, mapStore, locationBlockState) => ({
    locationBlockState,
    editableCampaign: appStore.editableCampaign,
    isOpenMapVisible: mapStore.isOpenMapVisible,
  }),
);

const actions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  setDrawerMode: mapActions.setDrawerMode,
  setLocationOrRelationship: locationActions.setLocationOrRelationship,
};

export const MapViewBlock = connect(mapState, actions, null, { forwardRef: true })(
  MapViewBlockComponent,
);
