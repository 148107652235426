import { enqueueSnackbar } from '@applift/factor';
import { useQuery } from '@tanstack/react-query';
import { getAllowedApplicationsListKey } from '../api/QueryKeys';
import { GetAllowedApplicationsList } from '../api/App';
import { WithResponse } from '../models/Response';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useAllowedApplicationsList = () => {
  const data = useQuery(
    getAllowedApplicationsListKey.keys('getCustomerConfig'),
    GetAllowedApplicationsList,
    {
      onError,
      cacheTime: Infinity,
    },
  );
  return data;
};
