import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import get from 'lodash/get';

import { ILocationFilter, ILocationTypeWithOptions } from 'models/Location';
import { GetResponse, GetResponseWithData, WithResponse } from 'models/Response';
import { Logger } from 'utils/logger';
import { getPathNumber } from 'iqm-framework';
import { getInstance, getInstanceWithTimestamps } from './Instance';

export interface GeoLocation {
  latitude: number;
  longitude: number;
  radius: number;
  address?: string;
  stateShortName?: string;
  countryShortName?: string;
  locationId: number;
  rejectionReason: string | null;
  placemarkName: string | null;
  isIncluded: boolean;
  polypath?: {
    x: number;
    y: number;
  }[];
  cid?: string;
  sid?: string;
}

export interface GeoLocationWithAddress extends GeoLocation {
  formattedAddress: string;
  circleCountry: string;
  circleState: string;
}

export interface ValidateBulkLocationsResponse {
  invalidLocationsCount?: number;
  validLocations?: GeoLocation[];
  fileId: number;
  rowCount?: number;
}

export interface ZipCodeIdsResponse {
  id: number;
  name: string;
}

export const validateBulkLocations = async (
  req: FormData,
): Promise<AxiosResponse<GetResponseWithData<ValidateBulkLocationsResponse>>> => {
  try {
    const response: AxiosResponse<GetResponseWithData<ValidateBulkLocationsResponse>> =
      await getInstanceWithTimestamps().post(`/v2/validate/bulkLocations`, req);

    return response;
  } catch (e) {
    const timestamps = get(e, 'data', {});
    Logger.log('Error while validating locations', e);

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ ...timestamps, ...get(e, 'response', e) });
  }
};

export const DownloadInvalidLocations = async (params: { fileId: number; filename: string }) => {
  try {
    const response = await getInstance().get(`v2/cmp/invalidLocations/${params.fileId}/download`);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      response.headers['content-disposition']?.split('=')[1] || params.filename,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response.data;
  } catch (e) {
    Logger.log('Error while download invalid locations', e);
    return Promise.reject(get(e, 'data.responseObject.errorMsg', e.message));
  }
};

export const DownloadValidLocations = async (params: {
  fileId: number;
  filename: string;
  parentApp: string;
}) => {
  try {
    const response = await getInstance().get(`v3/cmp/locations/${params.fileId}/download`, {
      headers:
        params.parentApp === 'customers'
          ? {
              'X-IAA-REFERER': `${window.location.origin}/customers/u/${getPathNumber()}`,
            }
          : {},
    });
    const url = response.data.data.locationFileUrl;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      response.headers['content-disposition']?.split('=')[1] || params.filename,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response.data;
  } catch (e) {
    Logger.log('Error while download invalid locations', e);
    return Promise.reject(get(e, 'data.responseObject.errorMsg', e.message));
  }
};

export const DismissInvalidLocations = async (fileId: number) => {
  try {
    const response = await getInstance().delete(`v2/cmp/invalidLocations/${fileId}/dismiss`);
    return response.data;
  } catch (e) {
    Logger.log('Error while dismiss invalid locations', e);
    return Promise.reject(get(e, 'data.responseObject.errorMsg', e.message));
  }
};

export const fetchLocationFilters = async (): Promise<WithResponse<ILocationFilter[]>> => {
  try {
    const response: AxiosResponse<WithResponse<ILocationFilter[]>> = await getInstance().get(
      `v3/master/segment/locationtype`,
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while fetching location filters', e);
    return Promise.reject(get(e, 'errorObjects[0].error', ''));
  }
};

export const fetchLocations = async (
  source: CancelTokenSource,
  searchField: string,
  locationTypeIds: string,
  countryIds: string,
): Promise<WithResponse<ILocationTypeWithOptions>> => {
  try {
    const response: AxiosResponse<WithResponse<ILocationTypeWithOptions>> = await getInstance().get(
      `v3/master/segment/locationtype-details`,
      {
        params: {
          ...(searchField ? { searchField } : {}),
          ...(locationTypeIds ? { locationTypeIds } : {}),
          ...(countryIds ? { countryIds } : {}),
        },
        cancelToken: source?.token,
      },
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while fetching locations', e);
    return Promise.reject(get(e, 'errorObjects[0].error', '') || e);
  }
};

export const fetchGeoJSON = async (url: string): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Response[]>> = await axios.get(url);
    return response.data;
  } catch (e) {
    Logger.log('Error while getting geoJSON', e);
    return null;
  }
};
