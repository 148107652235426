import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Skeleton,
  TypoTooltip,
  Typography,
  enqueueSnackbar,
  isObject,
  useBeforeunload,
} from '@applift/factor';
import {
  ArrowLeft,
  ChevronRight,
  ClipboardRemove,
  Duplicate,
  EllipsisVertical,
  IoCurrency,
  IoImpression,
} from '@applift/icons';

import { AppState } from 'models/Store';
import { useIoBasicList } from 'hooks/useIoList';
import { IoBasicDetailsListType } from 'models/IoList';
import { useBasicCampaignList } from 'hooks/useCampaignList';
import { BasicCampaignListType, CampaignListType } from 'models/CampaignList';
import { applicationActions } from 'store/app/actions';
import { CAMPAIGN_STATUSES, CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { useIOBudgetImpInfo, useUpdateIOInfo } from 'hooks/useIO';
import { IODetail, IoUpdateDetailsType } from 'models/IO';
import { compareTwoTimestampsAndIncrease } from 'utils/date';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { CAMPAIGN_STATUSES_ICONS, CREATIVES_TYPES_ICONS } from 'constants/iconMapper';
import { DEFAULT_LIST_SORTING, IO_STATUS_ID } from 'constants/insertionOrder';
import { DiscardUnsaveChangesDialog } from 'components/DiscardUnsaveChangesDialog';
import { DuplicateInsertionOrderDialog } from 'components/DuplicateInsertionOrderDialog';
import { START_END_TIME_DIFF } from 'constants/messages';
import { useStore } from 'zustandStore';

import { StoreFilter } from 'models/ZustandStore';
import { SetCampaignSidebarInfo, advanceActions } from 'store/advance/actions';
import { BreadCrumbSelect } from './BreadCrumbSelect';
import { ButtonMenu } from '../../pages/MainPage/IODetails/components/CampaignList/ButtonMenu/ButtonMenu';
import { SaveIoDialog } from '../../pages/MainPage/IODetails/components/CampaignList/SaveIODialog';
import { StartIONowDialog } from './components/StartIONowDialog';
import { CampaignPriorityShiftInfo, IOPriorityShiftInfo } from './components/PriorityShiftInfo';

import styles from './index.module.scss';

export interface BreadCrumbActionPropTypes extends SetCampaignSidebarInfo {
  panelType: 'campaign' | 'io';
  campaignName: AppState['advanced']['sidebarCampaignInfo']['campaignName'];
  isEditingMode: AppState['app']['isEditingMode'];
  ioId?: AppState['advanced']['sidebarCampaignInfo']['ioId'];
  ioDetails?: IoUpdateDetailsType;
  ioInfoLoading?: boolean;
  refetchIoDetails?: () => void;
  campaignId?: number;
  setIsIoDetailsDirty?: (arg: boolean) => void;
  openIoSaveDialog?: boolean;
  setOpenIoSaveDialog?: (arg: boolean) => void;
  serverIoDetail?: IODetail;
  isIoStartDateEditable?: boolean;
}

interface clickEvent {
  name: string;
  props?: { [key: string]: any };
  action: () => void;
}

export const NoResultOverlay = () => {
  return (
    <MenuItem
      style={{ pointerEvents: 'none' }}
      sx={{
        width: 100,
        height: 100,
      }}
    >
      <Box
        sx={{
          width: 100,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          p: 32,
        }}
      >
        <ClipboardRemove sx={{ textColor: 'neutral-400' }} fontSize={32} />
        <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
          No Results Found
        </Typography>
        <Typography>
          We can’t find any items
          <br /> matching your search.
        </Typography>
      </Box>
    </MenuItem>
  );
};

const BreadCrumbActionsComponent = (props: BreadCrumbActionPropTypes) => {
  const {
    panelType,
    campaignName,
    ioId,
    ioDetails,
    refetchIoDetails,
    ioInfoLoading,
    campaignId,
    isEditingMode,
    setIsIoDetailsDirty,
    openIoSaveDialog,
    setOpenIoSaveDialog,
    serverIoDetail,
    isIoStartDateEditable,
    setCampaignSidebarInfo,
  } = props;

  const [pgCampaignName] = useStore((state: StoreFilter) => [state.campaignInfoBlock.campaignName]);

  const { ioStatusId } = serverIoDetail || {};

  const history = useHistory();
  const location = useLocation();

  const [ioSearch, setIoSearch] = React.useState('');
  const [campaignSearch, setCampaignSearch] = React.useState('');
  const [currentIO, setCurrentIO] = React.useState<IoBasicDetailsListType>();
  const [currentCampaign, setCurrentCampaign] = React.useState<BasicCampaignListType>();
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState<boolean>(false);
  const [event, setEvent] = React.useState<clickEvent | undefined>(undefined);
  // eslint-disable-next-line
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showMoreOptions, setMoreOptionsVisibility] = React.useState(false);
  const [isIoActionsMenuOpen, setIsIoActionsMenuOpen] = React.useState(false);
  const [isDuplicateIoDialogOpen, setIsDuplicateIoDialogOpen] = React.useState(false);
  const [unlistedIoId, setUnlistedIoId] = React.useState<number | null>(null);
  const ioActionsMenuAnchor = React.useRef<HTMLButtonElement | null | undefined>(null);
  const [isStartNowDialogOpen, setIsStartNowDialogOpen] = React.useState(false);

  const debouncedIoSearch = useDebounceValue(ioSearch, 500);
  const debouncedCampaignSearch = useDebounceValue(campaignSearch, 500);

  // next release
  const IO_ACTIONS = [
    {
      label: 'Duplicate',
      icon: <Duplicate sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {},
      disabled: false,
    },
  ];

  const {
    data: ioListData,
    isLoading: ioListLoading,
    refetch: ioListRefetch,
  } = useIoBasicList(debouncedIoSearch, [], DEFAULT_LIST_SORTING, undefined, undefined, {
    enabled:
      !!ioId &&
      (!!ioDetails?.ioName || isEditingMode || (!ioDetails?.ioName && panelType === 'campaign')),
  });

  const { data: ioBudgetImpInfo } = useIOBudgetImpInfo(String(ioId), {
    enabled: !!ioId,
  });

  const { totalSpentBudget, totalSpentImpression } = React.useMemo(
    () => ioBudgetImpInfo?.data ?? { totalSpentBudget: 0, totalSpentImpression: 0 },
    [ioBudgetImpInfo?.data],
  );

  const { data: campaignListData, isLoading: campaignListLoading } = useBasicCampaignList(
    debouncedCampaignSearch,
    [+ioId!],
    currentCampaign?.campaignId ? [+currentCampaign.campaignId] : undefined,
    [],
    // passing CAMPAIGN_TYPE_BY_NAME.advanced right now so that only advance type Campaign are listed
    [CAMPAIGN_TYPE_BY_NAME.advanced, CAMPAIGN_TYPE_BY_NAME.pg],
    [
      CAMPAIGN_STATUSES.DRAFT,
      CAMPAIGN_STATUSES.RUNNING,
      CAMPAIGN_STATUSES.PAUSED,
      CAMPAIGN_STATUSES.PENDING,
      CAMPAIGN_STATUSES.REJECTED,
    ],
    {
      enabled: isEditingMode,
    },
  );

  const [preiousIoDetails, setPreviousIoDetails] = React.useState<IoUpdateDetailsType>();

  const onUpdateSuccess = (_info: string, variables: IoUpdateDetailsType) => {
    setIsStartNowDialogOpen(false);
    if (refetchIoDetails) {
      refetchIoDetails();
    }
    ioListRefetch();

    const prevIODetails = ioDetails;
    if (prevIODetails) {
      if (typeof variables.ioEndTime === 'number') {
        prevIODetails.ioEndTime = variables.ioEndTime || undefined;
      }
      if (typeof variables.ioStartTime === 'number') {
        prevIODetails.ioStartTime = variables.ioStartTime;
      }
    }

    setPreviousIoDetails(prevIODetails);
    enqueueSnackbar(
      <Typography>
        <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
          {ioDetails?.ioName}
        </Typography>
        saved successfully.
      </Typography>,
      {
        variant: 'success',
      },
    );
  };

  const onUpdateSuccessDialog = (data: string) => {
    if (refetchIoDetails) {
      refetchIoDetails();
    }
    setPreviousIoDetails(ioDetails);
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>{data}</Typography>,
      {
        variant: 'success',
      },
    );
    ioListRefetch();
  };
  const onUpdateCompleteDialog = () => {
    history.push(`/campaign-create?ioId=${ioId}`);
    if (setOpenIoSaveDialog) {
      setOpenIoSaveDialog(false);
    }
  };

  const updateIoInfo = useUpdateIOInfo(onUpdateSuccess);
  const updateIoInfoDialog = useUpdateIOInfo(onUpdateSuccessDialog, onUpdateCompleteDialog);

  const flatDataIoList = React.useMemo(() => {
    return (
      ioListData?.pages
        ?.map((page) => {
          return page.data?.ioBasicDetailsList ?? [];
        })
        .flat(1) || []
    );
  }, [ioListData?.pages]);

  const flatDataCampaignList = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map((page) => {
          return page.data?.data ?? [];
        })
        .flat(1) || []
    );
  }, [campaignListData?.pages]);

  const isNewCampaign = React.useMemo(
    () => location.pathname.includes('/campaign-create'),
    [location.pathname],
  );

  const currentIoName = React.useMemo(() => {
    if (panelType === 'campaign' && ioId) {
      return flatDataIoList?.filter((val) => {
        return ioId && val?.ioId === +ioId;
      })[0]?.ioName;
    }
    return '';
  }, [flatDataIoList, ioId, panelType]);

  const isButtonDisabled = React.useMemo(() => {
    if (ioInfoLoading) {
      return true;
    }
    if (preiousIoDetails) {
      const {
        ioStartTime,
        ioEndTime,
        ioTotalBudget,
        ioTimeZoneId,
        ioBudgetDistributionMethodId,
        ioName,
        isAutoSumIoTotalBudget,
        ioTotalImpressions,
      } = preiousIoDetails;

      return (
        ioStartTime === ioDetails?.ioStartTime &&
        (ioEndTime ?? 0) === (ioDetails?.ioEndTime ?? 0) &&
        ioName.trim() === ioDetails?.ioName &&
        (ioDetails?.isAutoSumIoTotalBudget || ioTotalBudget === ioDetails?.ioTotalBudget) &&
        (ioDetails?.isAutoSumIoTotalBudget ||
          ioTotalImpressions === ioDetails?.ioTotalImpressions) &&
        ioTimeZoneId === ioDetails?.ioTimeZoneId &&
        !!ioBudgetDistributionMethodId === !!ioDetails?.ioBudgetDistributionMethodId &&
        !!isAutoSumIoTotalBudget === !!ioDetails?.isAutoSumIoTotalBudget
      );
    }
    return false;
  }, [
    ioDetails?.ioBudgetDistributionMethodId,
    ioDetails?.ioEndTime,
    ioDetails?.ioName,
    ioDetails?.ioStartTime,
    ioDetails?.ioTimeZoneId,
    ioDetails?.ioTotalBudget,
    ioDetails?.ioTotalImpressions,
    ioDetails?.isAutoSumIoTotalBudget,
    ioInfoLoading,
    preiousIoDetails,
  ]);

  useBeforeunload(() => !isButtonDisabled, 'Changes that you made may not be saved.');

  React.useEffect(() => {
    if (setIsIoDetailsDirty) {
      setIsIoDetailsDirty(!isButtonDisabled);
    }
  }, [isButtonDisabled, setIsIoDetailsDirty]);

  const changedIoDetailsKeys: IoUpdateDetailsType | {} = React.useMemo(() => {
    if (ioDetails && preiousIoDetails && isObject(ioDetails) && isObject(preiousIoDetails)) {
      const res = Object.keys(ioDetails)
        .filter(
          (val) =>
            ioDetails[val as keyof IoUpdateDetailsType] !==
            preiousIoDetails[val as keyof IoUpdateDetailsType],
        )
        .reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item as string]: ioDetails[item as keyof IoUpdateDetailsType],
            }),
          { ioId },
        );

      if (
        (res as IoUpdateDetailsType).isAutoSumIoTotalBudget ||
        ioDetails?.isAutoSumIoTotalBudget
      ) {
        delete (res as IoUpdateDetailsType).ioTotalBudget;
      }

      if (
        preiousIoDetails?.ioName &&
        preiousIoDetails.ioName.trim() === ioDetails.ioName.trim() &&
        preiousIoDetails.ioName.trim() !== ioDetails.ioName
      ) {
        (res as Partial<IoUpdateDetailsType>).ioName = ioDetails.ioName.trim();
      }

      return res;
    }

    return {};
  }, [ioDetails, ioId, preiousIoDetails]);

  const gotoListPage = React.useCallback(() => {
    history.push('/io-list');
  }, [history]);

  const setPrevIoDetailsFromDataList = React.useCallback((data) => {
    setPreviousIoDetails({
      ioEndTime: data.ioEndTime,
      ioId: data.ioId,
      ioName: data.ioName,
      ioStartTime: data.ioStartTime,
      ioTimeZoneId: data.ioTimeZoneId,
      ...(data?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? { ioTotalBudget: Number(data.ioTotalBudget) }
        : {}),
      ...(data?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
        ? { ioTotalImpressions: Number(data.ioTotalImpressions) }
        : {}),
      isAutoSumIoTotalBudget: data.isBudgetAutoSum,
    });
  }, []);

  const changeIo = React.useCallback(
    (ioId) => {
      history.replace(`/io-detail/${ioId}`);
      // @ts-ignore
      const data = flatDataIoList.filter((item) => item?.ioId === +ioId)[0];
      setCurrentIO(flatDataIoList.filter((item) => item?.ioId === +ioId)[0]);

      if (data) {
        setPrevIoDetailsFromDataList(data);
      } else if (!unlistedIoId) {
        setUnlistedIoId(ioId);
        ioListRefetch();
      }
    },
    [history, flatDataIoList, unlistedIoId, ioListRefetch, setPrevIoDetailsFromDataList],
  );

  React.useEffect(() => {
    if (unlistedIoId && flatDataIoList) {
      const data = flatDataIoList.filter((item) => item?.ioId === +unlistedIoId)[0];
      if (data) {
        setCurrentIO(data);
        setPrevIoDetailsFromDataList(data);
        setUnlistedIoId(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatDataIoList, unlistedIoId]);

  const onBackButtonClick = React.useCallback(() => {
    if (panelType === 'io') {
      if (isButtonDisabled) {
        gotoListPage();
      } else {
        setIsDiscardDialogOpen(true);
        setEvent({ name: 'gotoList', action: gotoListPage });
      }
    }
    if (panelType === 'campaign') {
      history.push(`/io-detail/${ioId}`);
    }
  }, [history, ioId, panelType, isButtonDisabled, gotoListPage]);

  React.useEffect(() => {
    if (flatDataIoList.length && ioId) {
      const currentIoObj = flatDataIoList.filter((item) => item?.ioId === +ioId)[0];
      if (!currentIoObj && ioDetails?.ioName) {
        ioListRefetch();
      }
      setCurrentIO(currentIoObj);
    }
  }, [currentIO, flatDataIoList, ioDetails?.ioName, ioId, ioListRefetch, location.pathname]);

  React.useEffect(() => {
    if (flatDataCampaignList.length && ioId) {
      setCurrentCampaign(flatDataCampaignList.filter((item) => item?.campaignId === campaignId)[0]);
    }
  }, [campaignId, currentCampaign, flatDataCampaignList, ioId]);

  React.useEffect(() => {
    if (
      preiousIoDetails === undefined &&
      isObject(ioDetails) &&
      ioDetails.ioName &&
      ioDetails.ioTimeZoneId &&
      ioInfoLoading === false
    ) {
      setPreviousIoDetails(ioDetails);
    }
  }, [ioDetails, ioInfoLoading, preiousIoDetails]);

  React.useEffect(() => {
    if (!campaignName && pgCampaignName) {
      setCampaignSidebarInfo('campaignName', pgCampaignName);
    }
  }, [campaignName, pgCampaignName, setCampaignSidebarInfo]);

  const handleIoActionsMenuSelection = (option: 'duplicate') => {
    if (option === 'duplicate') {
      setIsDuplicateIoDialogOpen(true);
    }
  };

  const duplicateableInsertionOrder = React.useMemo(
    () =>
      ioDetails && typeof ioStatusId === 'number'
        ? {
            ...ioDetails,
            ioTimezone: ioDetails.ioTimeZoneId as number,
            ioStatusId,
            ioId: ioDetails.ioId as number,
            ioBudgetTypeId: ioDetails.ioBudgetTypeId as number,
          }
        : null,
    [ioDetails, ioStatusId],
  );

  const onChangeIoSelection = (selectedIoId: number | string) => {
    if (ioSearch) {
      setIoSearch('');
    }
    if (isButtonDisabled) {
      changeIo(+selectedIoId);
    } else {
      setIsDiscardDialogOpen(true);
      setEvent({
        name: 'changeIo',
        action: () => {
          changeIo(+selectedIoId);
        },
        props: { ioId: +selectedIoId },
      });
    }
  };

  const getDuplicateSuccessToast = (newIoId: number) => (
    <>
      <Typography weight="normal" component="div">
        Insertion Order duplicated successfully
      </Typography>
      <Button
        size="small"
        variant="text"
        color="primary"
        sx={{ px: 0, mt: 8 }}
        onClick={() => {
          onChangeIoSelection(newIoId);
        }}
      >
        View duplicated Insertion Order
      </Button>
    </>
  );

  const handleUpdateIO = (ioStartTime?: number) => {
    if (ioDetails) {
      const updatePayload = changedIoDetailsKeys as IoUpdateDetailsType;

      if (ioStartTime) {
        updatePayload.ioStartTime = ioStartTime;
      }

      if ('ioEndTime' in updatePayload) {
        if (
          updatePayload.ioEndTime &&
          moment(updatePayload.ioEndTime).isSameOrBefore(moment().add(10, 'second'))
        ) {
          enqueueSnackbar('End date must be greater than today', {
            variant: 'error',
          });
          return;
        }
        if (!updatePayload.ioEndTime) {
          // pass endTime as 0 when end date's removed
          updatePayload.ioEndTime = 0;
        } else if (ioStartTime) {
          const endTimeWithThirtyMinDiff = compareTwoTimestampsAndIncrease(
            ioStartTime,
            updatePayload.ioEndTime,
            30,
          );

          if (endTimeWithThirtyMinDiff > updatePayload.ioEndTime) {
            enqueueSnackbar(START_END_TIME_DIFF, {
              variant: 'info',
            });
          }
          updatePayload.ioEndTime = endTimeWithThirtyMinDiff;
        }
      }

      updateIoInfo.mutate({ ...updatePayload });
    }
  };

  const onClickUpdateChanges = () => {
    if (
      isIoStartDateEditable &&
      serverIoDetail?.ioStartTime &&
      moment().valueOf() > serverIoDetail.ioStartTime
    ) {
      enqueueSnackbar(
        'The IO operation has already started. You cannot modify the start date of an active IO.',
        {
          variant: 'error',
        },
      );
      return;
    }

    if (ioDetails && moment().valueOf() > ioDetails?.ioStartTime && isIoStartDateEditable) {
      setIsStartNowDialogOpen(true);
    } else {
      handleUpdateIO();
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
        <IconButton onClick={onBackButtonClick}>
          <ArrowLeft sx={{ textColor: 'neutral-400' }} />
        </IconButton>
        <Breadcrumbs separator={<ChevronRight fontSize={16} />}>
          <Link
            underline="hover"
            Component="button"
            sx={{ textColor: 'neutral-1000' }}
            onClick={() => {
              if (isButtonDisabled) {
                gotoListPage();
              }
              if (!isButtonDisabled && panelType === 'io') {
                setIsDiscardDialogOpen(true);
                setEvent({ name: 'gotoList', action: gotoListPage });
              } else {
                gotoListPage();
              }
            }}
          >
            Insertion Orders
          </Link>
          {panelType === 'campaign' ? (
            <Box className={styles.breadcrumbText}>
              {!ioListLoading ? (
                <Link
                  underline="hover"
                  Component="button"
                  sx={{ textColor: 'neutral-1000', overflow: 'hidden', width: 100 }}
                  onClick={() => {
                    history.push(`/io-detail/${ioId}`);
                  }}
                >
                  <TypoTooltip sx={{ width: 100 }}>{currentIoName}</TypoTooltip>
                </Link>
              ) : (
                <Skeleton />
              )}
            </Box>
          ) : null}
          {panelType === 'io' ? (
            <BreadCrumbSelect
              searchPlaceholder="Search by ID, Name"
              width={200}
              value={currentIO ? currentIO.ioId : null}
              panelType={panelType}
              search={ioSearch}
              onSearch={setIoSearch}
              renderValue={(val) => {
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    {currentIO?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                      <IoCurrency fontSize={20} color="primary" />
                    ) : (
                      <IoImpression fontSize={20} color="primary" />
                    )}
                    <TypoTooltip>{currentIO?.ioName}</TypoTooltip>
                  </Box>
                );
              }}
              onChange={(e: any) => onChangeIoSelection(e.target.value)}
            >
              {flatDataIoList.map((val) => (
                <MenuItem value={val?.ioId}>
                  <Box sx={{ width: 100 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, width: 100 }}>
                      {val?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                        <IoCurrency fontSize={20} color="primary" />
                      ) : (
                        <IoImpression fontSize={20} color="primary" />
                      )}
                      <TypoTooltip sx={{ textWeight: 'demi', display: 'block' }}>
                        {val?.ioName}
                      </TypoTooltip>
                    </Box>
                    <TypoTooltip>ID {val?.ioId}</TypoTooltip>
                  </Box>
                </MenuItem>
              ))}
              {!flatDataIoList.length && !ioListLoading ? <NoResultOverlay /> : null}
            </BreadCrumbSelect>
          ) : null}
          {panelType === 'campaign' && !isNewCampaign ? (
            <BreadCrumbSelect
              searchPlaceholder="Search by ID, Name, Status"
              width={320}
              value={currentCampaign ? currentCampaign.campaignId : null}
              panelType={panelType}
              search={campaignSearch}
              onSearch={setCampaignSearch}
              renderValue={(val: CampaignListType) => {
                return <TypoTooltip>{currentCampaign?.name}</TypoTooltip>;
              }}
              onChange={(e: any) => {
                if (campaignSearch) {
                  setCampaignSearch('');
                }
                const selectedCampaignId = e.target.value;
                const selectedCampaignDetails = flatDataCampaignList.filter(
                  (val) => val?.campaignId === selectedCampaignId,
                )[0];
                if (selectedCampaignDetails?.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.advanced) {
                  history.replace(`/campaign-edit/${selectedCampaignId}`);
                } else if (selectedCampaignDetails?.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg) {
                  history.replace(`/campaign-edit-pg/${selectedCampaignId}`);
                }
              }}
            >
              {flatDataCampaignList.map((val) => (
                <MenuItem
                  // @ts-ignore
                  startIcon={CREATIVES_TYPES_ICONS[val?.creativeType]}
                  value={val?.campaignId}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: 100 }}>
                    <TypoTooltip>
                      <Typography weight="demi">{val?.name}</Typography>
                    </TypoTooltip>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>ID {val?.campaignId}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', ml: 12, mt: 4 }}>
                        {/* @ts-ignore */}
                        {CAMPAIGN_STATUSES_ICONS[val?.status]}
                        <Typography>
                          {val?.status.charAt(0).toLocaleUpperCase()}
                          {val?.status?.slice(1)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
              {!flatDataCampaignList.length && !campaignListLoading ? <NoResultOverlay /> : null}
            </BreadCrumbSelect>
          ) : null}
          {isNewCampaign ? (
            <Box className={styles.breadcrumbText}>
              <TypoTooltip sx={{ display: 'block' }}>
                {campaignName || pgCampaignName || 'New Campaign'}
              </TypoTooltip>
            </Box>
          ) : (
            ''
          )}
        </Breadcrumbs>
      </Box>
      {panelType === 'campaign' && !isNewCampaign && campaignId ? (
        <Box sx={{ marginLeft: 'auto' }}>
          <CampaignPriorityShiftInfo />
        </Box>
      ) : null}
      {panelType === 'io' && !!serverIoDetail && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          {/* next release */}
          {/* <IconButton onClick={() => console.log('IO Delete func')} disableRipple>
            <Delete color="error" />
          </IconButton> */}
          <IOPriorityShiftInfo ioDetails={serverIoDetail} />
          {ioStatusId !== IO_STATUS_ID.DELETED && !ioInfoLoading && (
            <>
              <Button
                color="primary"
                variant="text"
                onClick={
                  !isButtonDisabled
                    ? () => {
                        setIsDiscardDialogOpen(true);
                        setEvent({ name: 'gotoList', action: gotoListPage });
                      }
                    : gotoListPage
                }
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={onClickUpdateChanges}
                disabled={
                  isButtonDisabled ||
                  updateIoInfo.isLoading ||
                  (ioDetails?.ioName !== undefined &&
                    (ioDetails.ioName.trim().length < 1 || ioDetails.ioName.length > 255)) ||
                  !!(ioDetails?.ioTotalBudget && totalSpentBudget > ioDetails?.ioTotalBudget) ||
                  !!(
                    ioDetails?.ioTotalImpressions &&
                    totalSpentImpression > ioDetails?.ioTotalImpressions
                  ) ||
                  (ioDetails?.ioEndTime
                    ? (ioDetails?.ioStartTime ?? 0) > ioDetails?.ioEndTime
                    : false) ||
                  ioDetails?.ioStartTime === ioDetails?.ioEndTime
                }
              >
                Update Changes
              </Button>
              <>
                <IconButton
                  size="small"
                  color="primary"
                  ref={ioActionsMenuAnchor as any}
                  onClick={() => setIsIoActionsMenuOpen(true)}
                  sx={{ ml: 8 }}
                >
                  <EllipsisVertical />{' '}
                </IconButton>
                <Menu
                  anchorEl={ioActionsMenuAnchor.current}
                  open={isIoActionsMenuOpen}
                  onClose={() => setIsIoActionsMenuOpen(false)}
                  sx={{ mt: 4 }}
                  PaperProps={{
                    sx: { borderColor: 'primary-600' },
                    variant: 'outlined',
                  }}
                >
                  <MenuItem
                    onClick={() => handleIoActionsMenuSelection('duplicate')}
                    sx={{ pr: 32 }}
                  >
                    <Duplicate sx={{ mr: 8, textColor: 'neutral-400' }} /> Duplicate
                  </MenuItem>
                </Menu>
              </>
            </>
          )}
          {/* next release */}
          {/* <IconButton
            onClick={(e) => {
              setMoreAnchorEl(e.currentTarget);
              setMoreOptionsVisibility(true);
            }}
            disableRipple
          >
            <EllipsisVertical />
          </IconButton> */}
          <ButtonMenu
            anchorEl={moreAnchorEl}
            menuItemSxProps={{ py: 0 }}
            buttons={IO_ACTIONS}
            onClose={() => {
              setMoreOptionsVisibility(false);
            }}
            open={showMoreOptions}
          />
        </Box>
      )}
      {isDiscardDialogOpen && (
        <DiscardUnsaveChangesDialog
          isOpen={isDiscardDialogOpen}
          setOpenDialog={setIsDiscardDialogOpen}
          onDiscard={() => {
            setIsDiscardDialogOpen(false);
            if (event && event?.name === 'gotoList') {
              event.action();
            }
            if (event && event?.name === 'changeIo') {
              event.action();
            }
          }}
        />
      )}
      <SaveIoDialog
        isOpen={Boolean(openIoSaveDialog && !isButtonDisabled)}
        // eslint-disable-next-line
        setOpenDialog={setOpenIoSaveDialog ? setOpenIoSaveDialog : () => {}}
        ioId={String(ioId)}
        loading={updateIoInfoDialog.isLoading}
        onSave={() => {
          if (ioDetails && !ioDetails.ioName.trim()) {
            enqueueSnackbar(
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                Insertion order name can’t be empty
              </Typography>,
              {
                variant: 'error',
              },
            );
          }
          if (ioDetails && ioDetails.ioName) {
            const updatePayload = changedIoDetailsKeys as IoUpdateDetailsType;
            if ('ioEndTime' in updatePayload && !updatePayload.ioEndTime) {
              // pass endTime as 0 when end date's removed
              updatePayload.ioEndTime = 0;
            }
            updateIoInfoDialog.mutate({ ...updatePayload });
          }
        }}
      />
      {isDuplicateIoDialogOpen && !!duplicateableInsertionOrder && (
        <DuplicateInsertionOrderDialog
          onClose={() => setIsDuplicateIoDialogOpen(false)}
          onDuplicateSuccess={() => {
            ioListRefetch();
            setIsIoActionsMenuOpen(false);
          }}
          getSuccessToast={getDuplicateSuccessToast}
          insertionOrder={duplicateableInsertionOrder}
        />
      )}
      {isStartNowDialogOpen && (
        <StartIONowDialog
          onConfirm={() => handleUpdateIO(moment().seconds(0).milliseconds(0).valueOf())}
          onClose={() => setIsStartNowDialogOpen(false)}
          isLoading={updateIoInfo.isLoading}
        />
      )}
    </>
  );
};

const mapState = (state: AppState) => ({
  campaignName: state.advanced.sidebarCampaignInfo.campaignName,
  isEditingMode: state.app.isEditingMode,
  ioId: state.app.savedIoId,
  campaignId: state.app.editableCampaign?.id,
});

const mapAction = {
  setClearingCampaignDataStatus: applicationActions.setClearingCampaignDataStatus,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
};

export const BreadCrumbActions = connect(mapState, mapAction)(BreadCrumbActionsComponent);
