import React from 'react';
import { TextFieldWithIcon } from 'factor';
import debounce from 'lodash/debounce';

import styles from './searchBar.module.scss';

type TSearchBarProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

type TSearchBarState = {
  value: string;
};

class SearchBar extends React.Component<TSearchBarProps, TSearchBarState> {
  debouncedOnChange: (value: string) => void;

  constructor(props: TSearchBarProps) {
    super(props);
    this.debouncedOnChange = debounce((value: string) => props.onChange(value), 400);
    this.state = {
      value: props.value,
    };
  }

  shouldComponentUpdate(newProps: TSearchBarProps, newState: TSearchBarState) {
    const { value } = this.state;
    return value !== newState.value;
  }

  handleSearch = (e: any) => {
    const { value } = e.target;
    this.setState({ value });
    this.debouncedOnChange(value);
  };

  render() {
    const { value } = this.state;

    return (
      <div className={styles.searchBar}>
        <TextFieldWithIcon
          placeholder={this.props.placeholder || 'Search'}
          iconName="Search"
          value={value}
          onChange={this.handleSearch}
        />
      </div>
    );
  }
}

export default SearchBar;
