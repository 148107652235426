import { ValidateBulkLocationsResponse } from 'api/Location';
import { TLocationFileType } from 'models/Location';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { locationConstants } from './constants';

export interface ILocationFile extends ValidateBulkLocationsResponse {
  filename: string;
  type: TLocationFileType;
  removedLocationIds?: number[];
}

export interface LocationState {
  locationBlockErrors: string[];
  locationFiles: ILocationFile[];
  filesBeingUploaded: TLocationFileType[];
  isLocationWithOrFilter: boolean;
}

const defaultLocationState: LocationState = {
  locationBlockErrors: [],
  locationFiles: [],
  filesBeingUploaded: [],
  isLocationWithOrFilter: true,
};

const resetState = () => {
  return {
    ...defaultLocationState,
  };
};

const setLocationError = (state: LocationState, action: Action<string>) => {
  const { locationBlockErrors } = state;
  if (locationBlockErrors.indexOf(action.payload) === -1) {
    locationBlockErrors.push(action.payload);
  }
  return {
    ...state,
    locationBlockErrors,
  };
};

const resetLocationError = (state: LocationState, action: Action<string>) => {
  const { locationBlockErrors } = state;
  locationBlockErrors.splice(state.locationBlockErrors.indexOf(action.payload), 1);
  return {
    ...state,
    locationBlockErrors,
  };
};

const addLocationFile = (state: LocationState, action: Action<ILocationFile>) => {
  return {
    ...state,
    locationFiles: [...state.locationFiles, action.payload],
  };
};

const clearLocaitionFiles = (state: LocationState) => {
  return {
    ...state,
    locationFiles: [],
  };
};

const removeItemFromFile = (
  state: LocationState,
  action: Action<{ fileId: number; itemId: number }>,
) => {
  return {
    ...state,
    locationFiles: state.locationFiles.map((file) =>
      file.fileId === action.payload.fileId
        ? {
            ...file,
            validLocations: file.validLocations?.filter(
              (item) => item.locationId !== action.payload.itemId,
            ),
            removedLocationIds: file.removedLocationIds
              ? [...file.removedLocationIds, action.payload.itemId]
              : [action.payload.itemId],
          }
        : file,
    ),
  };
};

const dismissInvalidData = (state: LocationState, action: Action<number>) => {
  return {
    ...state,
    locationFiles: state.locationFiles.map((file) =>
      file.fileId === action.payload
        ? {
            ...file,
            invalidLocationsCount: 0,
          }
        : file,
    ),
  };
};

const removeFileData = (state: LocationState, action: Action<number>) => {
  return {
    ...state,
    locationFiles: state.locationFiles.filter((file) => file.fileId !== action.payload),
  };
};

const setFileUpload = (state: LocationState, action: Action<TLocationFileType[]>) => {
  return {
    ...state,
    filesBeingUploaded: action.payload,
  };
};

const setOrRelationship = (state: LocationState, action: Action<boolean>) => {
  return {
    ...state,
    isLocationWithOrFilter: action.payload,
  };
};

const reducer = reducerFromMap<LocationState>(defaultLocationState, {
  [locationConstants.RESET_LOCATION_STORE]: resetState,
  [locationConstants.SET_LOCATION_ERROR]: setLocationError,
  [locationConstants.RESET_LOCATION_ERROR]: resetLocationError,
  [locationConstants.ADD_FILE_LOCATION]: addLocationFile,
  [locationConstants.CLEAR_LOCATION_FILES]: clearLocaitionFiles,
  [locationConstants.REMOVE_FILE_LOCATION_ITEM]: removeItemFromFile,
  [locationConstants.REMOVE_FILE_LOCATION]: removeFileData,
  [locationConstants.DISMISS_INVALID_DATA]: dismissInvalidData,
  [locationConstants.FILE_UPLOAD_STATUS]: setFileUpload,
  [locationConstants.SET_LOCATION_IS_OR_RELATIONSHIP]: setOrRelationship,
});

export const location = (state: LocationState = defaultLocationState, action: Action<any>) =>
  reducer(state, action);
