import React from 'react';
import {
  Box,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuList,
} from '@applift/factor';

export interface AccordionMenuListOption<T = number> {
  label: React.ReactNode;
  value: T;
  icon?: React.ReactNode;
}

interface AccordionMenuListProps {
  options: AccordionMenuListOption[];
  label: string;
  onSelect: (values: number[]) => void;
  selectedIds: number[];
  multi?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const AccordionMenuList = (props: AccordionMenuListProps) => {
  const { label, options = [], onSelect, selectedIds, multi, isOpen, setIsOpen } = props;

  const handleSelection = (opt: AccordionMenuListOption) => {
    if (selectedIds.find((s) => s === opt.value)) {
      return onSelect(selectedIds.filter((s) => s !== opt.value));
    }
    if (multi) {
      return onSelect([...selectedIds, opt.value]);
    }
    return onSelect([opt.value]);
  };

  return (
    <Accordion
      disableGutters
      expanded={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      sx={{ boxShadow: 0 }}
    >
      <AccordionSummary
        style={{ minHeight: 30, height: 'fit-content' }}
        sx={{ borderBottom: 1, borderBottomColor: 'neutral-100' }}
      >
        <Typography variant="label" weight="demi">
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <MenuList sx={{ paddingTop: 2 }}>
          {options.map((opt: AccordionMenuListOption) => (
            <MenuItem
              onClick={() => {
                handleSelection(opt);
              }}
              selected={!!selectedIds.find((s) => opt.value === s)}
              key={opt.value}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ mr: 8, display: 'flex', alignItems: 'center' }}>{opt.icon}</Box>
                <Typography>{opt.label}</Typography>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </AccordionDetails>
    </Accordion>
  );
};
