import React from 'react';
import { Box, Tooltip, Typography } from '@applift/factor';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { IoListType } from 'models/IoList';
import { numberFormat, formatNumberWithDecimal } from 'utils/format';
import { RawDataCell } from 'components/CellType';

interface Props {
  data?: IoListType;
  isFooter?: boolean;
}

export const IoTotalBudgetCell = (props: Props) => {
  const { data, isFooter } = props;

  if (!data || (isFooter && !data?.budgetTypeId)) {
    return <Typography weight="demi">—</Typography>;
  }

  let tooltip = '';
  if (!isFooter) {
    if (data.ioStatusId === IO_STATUS_ID.EXPIRED) {
      tooltip = `Total budget can't be edited for Expired IOs`;
    } else if (data.ioStatusId === IO_STATUS_ID.DELETED) {
      tooltip = `Total budget can't be edited for Deleted IOs`;
    } else if (data.isAutoSumIoTotalBudget) {
      tooltip = 'Total Budget can’t be edited as it is fixed to the total campaign budget';
    }
  }

  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        {data?.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED && (
          <Typography sx={{ marginRight: 2 }}>$</Typography>
        )}
        {data?.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
          <Typography sx={{ marginRight: 2 }}>Imps</Typography>
        )}
        <Box sx={{ marginLeft: 'auto' }}>
          {data?.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <RawDataCell
              value={`${data.ioTotalImpressions ? numberFormat(data.ioTotalImpressions) : 0}`}
            />
          ) : (
            <RawDataCell value={formatNumberWithDecimal(data?.ioTotalBudget ?? 0)} />
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};
