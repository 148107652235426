import moment from 'moment';
import { Timezone } from 'models/Timezone';

export const getPriorityShiftTimestampString = (tz: Timezone, timestamp: number) => {
  return `${moment(timestamp).tz(tz.name).format('MM/DD/YYYY hh:mm A')} ${
    moment.tz.zone(tz.name)?.abbr(timestamp) || tz.label
  }`;
};

export const getCurrentCampaignPriorityString = (priority: number) => {
  if (priority === 1) {
    return ' 1';
  }
  return `: 1 to ${priority}`;
};
