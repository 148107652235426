import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';
import { useHistory } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';

import {
  IOCreateResponse,
  IoBudgetImpressionInfoType,
  IoUpdateDetailsType,
  SetIOEndDate,
} from 'models/IO';
import { getIOBudgetImpInfoQueryKey, getIODetailsQueryKey } from 'api/QueryKeys';
import {
  createIO,
  getIOInfo,
  updateIOInfo,
  setIOEndDate,
  getIOBudgetImpInfo,
  setIOBudget,
  deleteIO,
  DeleteIOParams,
  duplicateIO,
  DuplicateIOParams,
  DuplicateIOResponse,
} from 'api/IO';
import { WithResponse, WithTimestamps } from 'models/Response';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { queryClient } from '../cache/query';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useCreateIO = (onCreateSuccess: (info: IOCreateResponse) => void) => {
  const mutationResult = useMutation(createIO, {
    mutationKey: ['createIO'],
    onSuccess: (res) => {
      if (res.data) {
        onCreateSuccess(res.data);
      }
    },
    onError,
  });

  return mutationResult;
};

export const useSetIOEndDate = (onEndDateSetSuccess: () => void) => {
  const mutationResult = useMutation(setIOEndDate, {
    mutationKey: ['setIOEndDate'],
    onSuccess: (res) => {
      if (res.data) {
        onEndDateSetSuccess();
      }
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
      });
    },
    onError,
  });

  return mutationResult;
};

export const useSetIOEndDateInline = (onEndDateSetSuccess: (req: SetIOEndDate) => void) => {
  const mutationResult = useMutation(setIOEndDate, {
    mutationKey: ['setIOEndDate'],
    onSuccess: (res, req) => {
      if (res.data) {
        onEndDateSetSuccess(req);
      }
    },
    onError,
  });

  return mutationResult;
};

interface UseSetIOStartDateInlineParams {
  onSuccess: (response: AxiosResponse<WithTimestamps<WithResponse<string>>>) => void;
}

export const useSetIOStartDateInline = ({ onSuccess }: UseSetIOStartDateInlineParams) => {
  const mutationResult = useMutation(updateIOInfo, {
    mutationKey: ['useSetIOStartDateInline'],
    onMutate: (variables: IoUpdateDetailsType) => {
      const oldValues: { [key: string]: any } = {};
      queryClient.setQueriesData(
        {
          predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
        },
        (pages: any) => {
          const newPages = pages?.pages?.map((page: any) => {
            const newData = {
              ...page.data,
              recordsList: page?.data?.recordsList?.map((row: any) => {
                if (row.ioId === variables.ioId) {
                  oldValues[row.ioId] = row.ioStartTime;
                  return { ...row, ioStartTime: variables.ioStartTime };
                }
                return row;
              }),
            };
            return {
              ...page,
              data: newData,
            };
          });
          return { ...pages, pages: newPages };
        },
      );

      return oldValues;
    },
    onSuccess: (res) => {
      if (res.data) {
        onSuccess(res);
      }
    },
    onError: (error, _variables, oldValues) => {
      if (oldValues) {
        queryClient.setQueriesData(
          {
            predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
          },
          (pages: any) => {
            const newPages = pages?.pages?.map((page: any) => {
              const newData = {
                ...page.data,
                recordsList: page?.data?.recordsList?.map((row: any) => {
                  if (oldValues[row.ioId] !== undefined) {
                    return { ...row, ioStartTime: oldValues[row.ioId] };
                  }
                  return row;
                }),
              };
              return {
                ...page,
                data: newData,
              };
            });
            return { ...pages, pages: newPages };
          },
        );
      }

      onError((error as any)?.response?.data || (error as any));
    },
  });

  return mutationResult;
};

export const useIOInfo = (
  id: string,
  options?: {
    enabled?: UseQueryOptions<WithResponse<IoBudgetImpressionInfoType>>['enabled'];
  },
) => {
  const history = useHistory();
  const data = useQuery(getIODetailsQueryKey.keys('getIOInfo', id), getIOInfo, {
    cacheTime: 0,
    onError: (e: WithResponse) => {
      history.push('/io-list');
      onError(e);
    },
    enabled: options?.enabled ?? true,
    structuralSharing: false,
  });
  return data;
};

export const useUpdateIOInfo = (
  onCreateSuccess: (info: string, variables: IoUpdateDetailsType) => void,
  onCreateComplete?: () => void,
) => {
  const mutationResult = useMutation(updateIOInfo, {
    mutationKey: ['updateIo'],
    onSuccess: async (res, variables) => {
      const detailsQueryKey = getIODetailsQueryKey.keys('getIOInfo', `${variables.ioId}`);

      if (variables.ioEndTime === 0 && variables.ioId) {
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey?.[0]?.scope === 'getCampaignList' &&
            query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
        });

        await Promise.all([
          queryClient.refetchQueries(
            {
              type: 'active',
              predicate: (query: any) =>
                query.queryKey?.[0]?.scope === 'getCampaignList' &&
                query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
            },
            { throwOnError: false },
          ),
          queryClient.refetchQueries({
            predicate: (query: any) => {
              return (
                query.queryKey?.[0]?.scope === detailsQueryKey[0].scope &&
                query.queryKey?.[0]?.ioId === detailsQueryKey[0].ioId
              );
            },
          }),
        ]);
      } else {
        queryClient.refetchQueries({
          predicate: (query: any) => {
            return (
              query.queryKey?.[0]?.scope === detailsQueryKey[0].scope &&
              query.queryKey?.[0]?.ioId === detailsQueryKey[0].ioId
            );
          },
        });
      }

      if (res.data?.data) {
        onCreateSuccess(res.data.data, variables);
      }
    },
    onError: (e: AxiosError<WithResponse<any>>) => {
      enqueueSnackbar(
        e?.response?.data?.errorObjects?.[0]
          ? (e?.response?.data?.errorObjects[0]?.error as string)
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        },
      );
    },
    onSettled: () => {
      if (onCreateComplete) {
        onCreateComplete();
      }
    },
  });

  return mutationResult;
};

export const useIOBudgetImpInfo = (
  id: string,
  options?: {
    enabled?: UseQueryOptions<WithResponse<IoBudgetImpressionInfoType>>['enabled'];
    onSuccess?: UseQueryOptions<WithResponse<IoBudgetImpressionInfoType>>['onSuccess'];
    onError?: UseQueryOptions<WithResponse<IoBudgetImpressionInfoType>>['onError'];
  },
) => {
  const history = useHistory();
  const data = useQuery(
    getIOBudgetImpInfoQueryKey.keys('getIoBudgetImpInfo', id),
    getIOBudgetImpInfo,
    {
      cacheTime: 0,
      onError: (e: WithResponse) => {
        history.push('/io-list');
        enqueueSnackbar(
          e.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong. Please try after sometime.',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
    },
  );
  return data;
};

export const useSetIOBudget = (onBudgetSetSuccess: () => void, onSettled?: () => void) => {
  const mutationResult = useMutation(setIOBudget, {
    mutationKey: ['setIOBudget'],
    onSuccess: (res, payload) => {
      if (res.data) {
        onBudgetSetSuccess();
      }
      queryClient.setQueriesData(
        {
          predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
        },
        (data: any) => {
          const updatedPages = data.pages.map((page: any) => ({
            ...page,
            data: {
              ...page.data,
              recordsList: page.data?.recordsList?.map((record: any) => {
                if (payload.ioIdsList.includes(record.ioId)) {
                  return {
                    ...record,
                    ...(payload.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                      ? {
                          ioTotalBudget:
                            payload.budgetUpdateType === 'set'
                              ? Number(parseFloat(`${payload.budget}`).toFixed(2))
                              : Number(
                                  parseFloat(payload.budget + record.ioTotalBudget).toFixed(2),
                                ),
                          ioTotalBudgetCombined:
                            payload.budgetUpdateType === 'set'
                              ? Number(parseFloat(`${payload.budget}`).toFixed(2))
                              : Number(
                                  parseFloat(payload.budget + record.ioTotalBudget).toFixed(2),
                                ),
                        }
                      : {}),
                    ...(payload.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
                      ? {
                          ioTotalImpressions:
                            payload.budgetUpdateType === 'set'
                              ? parseFloat(`${payload.ioTotalImpressions}`)
                              : parseFloat(payload.ioTotalImpressions + record.ioTotalImpressions),
                          ioTotalBudgetCombined:
                            payload.budgetUpdateType === 'set'
                              ? parseFloat(`${payload.ioTotalImpressions}`)
                              : parseFloat(payload.ioTotalImpressions + record.ioTotalImpressions),
                        }
                      : {}),
                  };
                }
                return record;
              }),
            },
          }));
          return { ...data, pages: updatedPages };
        },
      );
    },
    onError,
    onSettled: () => {
      if (onSettled) {
        onSettled();
      }
    },
  });

  return mutationResult;
};

export const useDeleteIO = (
  onSuccess: (res: WithResponse<string>) => void,
  errorCallback?: (res: WithResponse<string>, params: DeleteIOParams) => void,
) => {
  const mutationResult = useMutation(deleteIO, {
    mutationKey: ['deleteIO'],
    onSuccess: (res: WithResponse<string>) => {
      queryClient.invalidateQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
      });
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
      });
      if (res.data) {
        onSuccess(res);
      }
    },
    onError: (e: WithResponse, params: DeleteIOParams) => {
      if (errorCallback) {
        errorCallback(e as WithResponse<string>, params);
      } else {
        onError(e);
      }
    },
  });

  return mutationResult;
};

export const useDuplicateIO = (
  onSuccess: (res: WithResponse<DuplicateIOResponse>) => void,
  errorCallback?: (res: WithResponse<DuplicateIOResponse>, params: DuplicateIOParams) => void,
) => {
  const mutationResult = useMutation(duplicateIO, {
    mutationKey: ['duplicateIO'],
    onSuccess: (res: WithResponse<DuplicateIOResponse>) => {
      queryClient.invalidateQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
      });
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
      });
      if (res.data) {
        onSuccess(res);
      }
    },
    onError: (e: WithResponse, params: DuplicateIOParams) => {
      if (errorCallback) {
        errorCallback(e as WithResponse<DuplicateIOResponse>, params);
      } else {
        onError(e);
      }
    },
  });

  return mutationResult;
};
