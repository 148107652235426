export function isValidUrl(url: string | URL) {
  if (typeof url !== 'string') {
    return false;
  }
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}
