import { getInstance } from '@applift/platform';
import { AxiosResponse, AxiosError } from 'axios';

const UpdateAction = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  EXCLUDE_DIMENSION_ENTITY: 'EXCLUDE_DIMENSION_ENTITY',
} as const;

interface BidModelDataInterface {
  priority?: number | null;
  bidMultiplier?: number;
  spendRatio?: number;
  spendRatioTypeId?: number;
  id?: number;
}

interface DimensionEntity {
  dimensionId: number;
  entityId: number;
}

export interface IBidModelRequest {
  action: keyof typeof UpdateAction;
  bidModelData?: BidModelDataInterface;
  dimensionEntityMappings?: DimensionEntity[];
  bidModelDataIds?: number[];
  excludeDimensionEntityMappings?: {
    dimensionId: number;
    ids: string;
    isExcluded: number;
  }[];
}

export interface UpdateModellingRequest {
  baseBid?: number;
  bidModelRequests?: IBidModelRequest[];
}

export const updateModelling = async (req: {
  campaignId: number;
  params: UpdateModellingRequest;
}) => {
  try {
    const { campaignId, params } = req;
    const response: AxiosResponse<any> = await getInstance().put(
      `/v3/bm/campaigns/${campaignId}/bid-models`,
      params,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};
