import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, Typography } from '@applift/factor';
import { Clock } from '@applift/icons';
import { CollapsibleBlock } from 'factor';

import { AppState } from 'models/Store';
import { formatScheduling } from 'utils/helpers';
import { Scheduling } from 'store/scheduling/reducer';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { blockStyles } from 'components/Block';

import { WeekScheduler } from './WeekScheduler';

type Props = {
  isCollapseOpen?: boolean;
  onToggle?: (isOpened: boolean) => void;
  collapseMode?: CollapseMode;
};

export const SchedulingBlock = (props: Props) => {
  const { isCollapseOpen, onToggle, collapseMode } = props;

  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();

  const scheduling: Scheduling = useSelector(
    (state: AppState) => state.scheduling.sidebarCampaignInfo[CampaignInfoField.scheduling],
  );

  const editableCampaign = useSelector((state: AppState) => state.app.editableCampaign);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.indexOf('campaign-create') >= 0) {
      setIsMounted(true);
    }
  }, [location]);

  useEffect(() => {
    if (editableCampaign) {
      setIsMounted(true);
    }
  }, [editableCampaign]);

  const getHeaderObj = () => {
    return {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
          <Clock fontSize={24} sx={{ textColor: 'primary-600' }} />
          <Typography>Scheduling</Typography>
        </Box>
      ),
      summary: {
        Scheduling: formatScheduling(scheduling),
      },
    };
  };

  return (
    <CollapsibleBlock
      isCollapseOpen={isCollapseOpen}
      onToggle={onToggle}
      collapsible={isMounted && collapseMode !== ALL_EXPANDED}
      header={getHeaderObj()}
      customStyle={{
        block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
        panelHeaderExpand: blockStyles.panelHeaderExpand,
        panelHeaderCollapse: blockStyles.panelHeaderCollapse,
      }}
    >
      <WeekScheduler />
    </CollapsibleBlock>
  );
};
