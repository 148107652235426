import React from 'react';
import { connect } from 'react-redux';
import { TypoTooltip } from '@applift/factor';
import get from 'lodash/get';
import { Select } from 'factor';
import { Dictionary } from '../../models/Dictionary';
import { Option } from '../../models/Option';
import { AppState } from '../../models/Store';
import { TooltipParams } from '../../models/TooltipParams';

type ApiMethod = (params: any) => Promise<Dictionary[]>;

interface Props {
  label?: string;
  placeholder?: string;
  value: Option<number>[];
  authorized: boolean;
  dataPath?: string;
  onChange: (value: Option<number>[]) => void;
  fetchedData?: (data: Option<number>[]) => void;
  tooltipParams?: TooltipParams;
  isOpen?: boolean | undefined;
  ignoredCloseClickElement?: HTMLElement;
  className?: string;
}

interface State {
  data: Option<number>[];
  isOpen: boolean | undefined;
}

export const withDictionarySelect = (
  apiMethod: ApiMethod,
  params: any = {},
  withTabs: boolean = false,
  shouldAutoRefresh = false,
  getTabIcon?: (type: 'include' | 'exclude') => React.ReactNode,
) => {
  class DictionarySelect extends React.PureComponent<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        data: [],
        isOpen: props.isOpen,
      };
    }

    componentDidMount() {
      const { authorized } = this.props;
      if (authorized) {
        this.fetchData();
      }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
      const { authorized } = this.props;
      const { isOpen } = this.state;
      if (
        prevProps.authorized !== authorized ||
        (isOpen && shouldAutoRefresh && isOpen !== prevState.isOpen)
      ) {
        this.fetchData();
      }
    }

    renderMenuItem = (label: string) => {
      return <TypoTooltip title={label}>{label}</TypoTooltip>;
    };

    fetchData = async () => {
      const { fetchedData, dataPath } = this.props;
      let formattedData = [];

      const response = await apiMethod({
        ...params,
      });

      if (dataPath) {
        formattedData = get(response, dataPath, []).map((d: any) => ({
          label: d.name,
          value: d.id,
          reactLabel: this.renderMenuItem(d.name),
        }));
      } else {
        formattedData = (Array.isArray(response) ? response : []).map((d) => ({
          label: d.name,
          value: d.id,
          reactLabel: this.renderMenuItem(d.name),
        }));
      }

      this.setState({
        data: formattedData,
      });

      if (fetchedData) {
        fetchedData(formattedData);
      }
    };

    setOpened = (isOpen: boolean) => this.setState({ isOpen });

    render() {
      const { data, isOpen } = this.state;
      const {
        value,
        onChange,
        label,
        placeholder,
        tooltipParams,
        ignoredCloseClickElement,
        className = '',
      } = this.props;

      // if isOpen is controlled with select inner state only, then no outer toggle function is needed
      const toggleOpenProp =
        typeof isOpen === 'boolean' || shouldAutoRefresh ? this.setOpened : undefined;

      return (
        <Select
          label={label}
          placeholder={placeholder}
          options={data}
          value={value}
          onChange={onChange}
          isSearchable
          isMulti
          allSelectable
          className={className}
          tooltipParams={tooltipParams}
          isOpen={isOpen}
          toggleOpenProp={toggleOpenProp}
          ignoredCloseClickElement={ignoredCloseClickElement}
          withTabs={withTabs}
          getTabIcon={getTabIcon}
        />
      );
    }
  }

  const mapState = (state: AppState) => {
    return {
      authorized: state.auth.authorized,
    };
  };

  return connect(mapState)(DictionarySelect);
};
