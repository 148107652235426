import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'factor';
import { TimezoneProvider, EpochDatePicker } from 'iqm-framework';
import { Typography } from '@applift/factor';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { AlertTriangle } from '@applift/icons';

import { useIOInfo } from 'hooks/useIO';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { ErrorThemeDialog } from 'components/ErrorThemeDialog/ErrorThemeDialog';
import { useModelledDimensionsCount } from 'hooks/useCount';
import { OptionId } from '../../../../../models/Option';
import { AppState } from '../../../../../models/Store';
import {
  advanceActions,
  SetCampaignSidebarInfo,
  ChangeDefaultDateState,
  SetEndDateCleared,
} from '../../../../../store/advance/actions';
import { applicationActions, ResetError } from '../../../../../store/app/actions';
import { ErrorCreatingResponse } from '../../../../../models/Response';
import { errorFieldsMapper } from '../../../../../constants/errorFieldsMapper';
import { ServerErrorMessage } from '../../../../../components/ServerErrorMessage';
import { nearestFutureMinutes } from '../../../../../utils/roundTime';
import { ExistingCampaignData } from '../../../../../models/ExistingCampaign';

import styles from './styles.module.scss';

const isCampaignActive = (editableCampaign: ExistingCampaignData) =>
  ['paused', 'running'].includes(editableCampaign.status);

interface Props
  extends SetCampaignSidebarInfo,
    ResetError,
    ChangeDefaultDateState,
    SetEndDateCleared {
  startDate: number;
  endDate: number | null;
  timezone?: OptionId;
  errorCreating: ErrorCreatingResponse | null;
  budgetPacing: boolean;
  editableCampaign: ExistingCampaignData | null;
  isEditingMode: boolean;
  isDateDefault: boolean;
  submitted: boolean;
  ioId: number | null;
  budgetTypeId: number;
}

enum StartDateError {
  BEFORE_IO_START = 'BEFORE_IO_START',
  BEFORE_CURRENT_TIME = 'BEFORE_CURRENT_TIME',
}

const StartEndDateWithTimezoneWrapperComponent = (props: Props) => {
  const {
    startDate,
    endDate,
    editableCampaign,
    isEditingMode,
    changeDefaultDateState,
    timezone,
    setCampaignSidebarInfo,
    errorCreating,
    resetError,
    isDateDefault,
    setEndDateCleared,
    submitted,
    budgetPacing,
    ioId,
    budgetTypeId,
  } = props;

  const [startDateError, setStartDateError] = useState<StartDateError | null>(null);
  const [timezoneLoaded, setTimezoneLoaded] = useState(false);
  const [showEndDateRemovalConfirmation, setShowEndDateRemovalConfirmation] = useState(false);
  const errorDateMs = useRef<number | null>(null);

  const ioDetails = useIOInfo(`${ioId}`, { enabled: !!ioId });

  useEffect(() => {
    if (editableCampaign && editableCampaign?.timezone === timezone?.id) {
      setTimezoneLoaded(true);
    }
  }, [editableCampaign, timezone]);

  const { data: modelledCount } = useModelledDimensionsCount(
    {
      campaignId: editableCampaign?.id as number,
    },
    {
      enabled: Boolean(editableCampaign?.id),
    },
  );

  const calendarMinimumDate =
    ioDetails.data?.data?.ioStartTime && ioDetails.data?.data?.ioStartTime > moment().valueOf()
      ? ioDetails.data.data.ioStartTime
      : moment().valueOf();

  const isStartDateBeforeIOStart = (updatedDate?: Moment) => {
    return (
      updatedDate &&
      calendarMinimumDate === ioDetails?.data?.data?.ioStartTime &&
      updatedDate.valueOf() < ioDetails.data.data.ioStartTime
    );
  };

  const handleChangeAndSaveToStore = (field: string) => (value: any) => {
    setCampaignSidebarInfo(field, value);
    if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
      resetError();
    }
  };

  const handleChangeStartDate = (value: number) => {
    // Account for when EpochDatePicker calls onError, then onDateChanged...
    // onError is called before rerender, and onDateChanged is called after rerender, in componentDidUpdate
    if (!(startDateError && errorDateMs.current === value)) {
      errorDateMs.current = null;
      setStartDateError(null);
    }

    if (isDateDefault && isEditingMode && value !== startDate) {
      changeDefaultDateState(false);
    }
    handleChangeAndSaveToStore('startDate')(value);
  };

  const initializeDates = () => {
    const defaultStartDate = nearestFutureMinutes(10, moment().add(10, 'minutes')).valueOf();

    if (!ioDetails.data?.data) {
      handleChangeStartDate(defaultStartDate);
    } else {
      handleChangeStartDate(
        defaultStartDate > ioDetails.data.data.ioStartTime
          ? defaultStartDate
          : ioDetails.data.data.ioStartTime,
      );
    }
  };

  useEffect(() => {
    if (!startDate) {
      initializeDates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditingMode) {
      changeDefaultDateState(true);
    } else {
      initializeDates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingMode]);

  useEffect(() => {
    if (
      editableCampaign &&
      timezone?.id !== editableCampaign?.timezone &&
      !isCampaignActive(editableCampaign) &&
      timezoneLoaded
    ) {
      changeDefaultDateState(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableCampaign, timezone, timezoneLoaded]);

  const removeEndDate = () => {
    setShowEndDateRemovalConfirmation(false);
    handleChangeAndSaveToStore('endDate')(null);
    setEndDateCleared(true);
  };

  const handleChangeEndDate = (value: number | null) => {
    if (value === undefined) {
      return;
    }
    if (!value && modelledCount?.totalCount) {
      setShowEndDateRemovalConfirmation(true);
    } else {
      handleChangeAndSaveToStore('endDate')(value);
      setEndDateCleared(!value);
    }
  };

  const endDateDialogMessage = (
    <Typography>
      Are you sure you want to remove the <Typography weight="demi">Campaign end date</Typography>?{' '}
      This action will remove all the <Typography weight="demi">Advanced Modelling</Typography>{' '}
      applied to the creatives.
    </Typography>
  );

  const getStartDateError = (): StartDateError | null => {
    if (startDateError) {
      return startDateError;
    }

    if (timezone && (!isEditingMode || !isDateDefault)) {
      if (ioDetails.data?.data?.ioStartTime && startDate < ioDetails.data.data.ioStartTime) {
        return StartDateError.BEFORE_IO_START;
      }
      if (startDate < moment().valueOf()) {
        return StartDateError.BEFORE_CURRENT_TIME;
      }
    }

    return null;
  };

  const validateMinRunningPeriod = (): boolean => {
    if (endDate) {
      return moment(endDate).diff(startDate, 'minute') >= 15;
    }
    return true;
  };

  const endDateNotProvided = (): boolean => {
    if (submitted && budgetPacing) {
      return endDate == null;
    }
    return false;
  };

  const onStartDateError = (_errorMsg: string, errorInfo: any) => {
    if (isStartDateBeforeIOStart(errorInfo?.updatedDate)) {
      setStartDateError(StartDateError.BEFORE_IO_START);
    } else if (errorInfo.updatedDate < moment().valueOf()) {
      setStartDateError(StartDateError.BEFORE_CURRENT_TIME);
    }

    errorDateMs.current = errorInfo.epochDate;
  };

  const startDateClassName =
    errorCreating?.errorField === errorFieldsMapper.startDate || getStartDateError()
      ? '_error'
      : '';

  const endDateClassName =
    !validateMinRunningPeriod() ||
    errorCreating?.errorField === errorFieldsMapper.endDate ||
    endDateNotProvided()
      ? '_error'
      : '';

  const showModellingWarning =
    modelledCount?.totalCount &&
    (!endDate || moment(endDate ?? 0).diff(startDate ?? 0, 'days') < 3);

  let warningText = '';

  if (showModellingWarning) {
    warningText =
      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? `For optimal effectiveness of the campaign modelling, it is recommended to allocate a daily budget of $100, with a campaign duration of at least 3 days.`
        : `For optimal effectiveness of the campaign modelling, it is recommended to allocate a daily budget of 1000 impressions, with a campaign duration of at least 3 days.`;
  }

  return (
    <div data-qa="374" className={`col-8 d-flex ${styles.wrapper}`}>
      <TimezoneProvider timezone={timezone}>
        <div data-qa="376" className="col-6">
          {editableCampaign?.status && isCampaignActive(editableCampaign) && timezone ? (
            <div>
              <div className={styles.readonlyFieldLabel}>Start Date</div>
              <div>{moment(startDate).utc().tz(timezone.value).format('MM/DD/YYYY hh:mm A')}</div>
            </div>
          ) : (
            <EpochDatePicker
              dateRangePickerClassName={startDateClassName}
              className={startDateClassName}
              dateFormat="MM/DD/YYYY hh:mm A"
              datePickerProps={{
                numberOfCalendars: 1,
                insidePreselectors: true,
              }}
              addedMinutes={15}
              label="Start Date"
              withTimePicker
              singleDateMode
              singleDate={startDate}
              calendarMinimumDate={calendarMinimumDate}
              onDateChanged={handleChangeStartDate}
              insidePreselectors
              disabled={ioDetails.isLoading}
              onError={onStartDateError}
            />
          )}
          {getStartDateError() === StartDateError.BEFORE_CURRENT_TIME && (
            <p data-qa="377" className={styles.boldError} data-not-valid="true">
              Start Date can't be in the past
            </p>
          )}
          {getStartDateError() === StartDateError.BEFORE_IO_START && (
            <p data-qa="377" className={styles.boldError} data-not-valid="true">
              Campaign's start time can't be before the IO start time
            </p>
          )}
          <ServerErrorMessage errorKey={errorFieldsMapper.startDate} />
        </div>
        <div data-qa="378" className="col-6 d-flex flex-column">
          <div className="d-flex">
            <EpochDatePicker
              dateRangePickerClassName={endDateClassName}
              className={endDateClassName}
              dateFormat="MM/DD/YYYY hh:mm A"
              datePickerProps={{
                numberOfCalendars: 1,
              }}
              singleDatePlaceholder={budgetPacing ? '' : '(Optional)'}
              label="End Date"
              withTimePicker
              singleDateMode
              singleDate={endDate}
              calendarMinimumDate={calendarMinimumDate}
              onDateChanged={handleChangeEndDate}
              isClearable={!budgetPacing}
            />
            {warningText && (
              <Tooltip label={warningText} labelMaxWidth={300} position="top" portal>
                <AlertTriangle
                  color="warning"
                  className={styles.alertIcon}
                  fontSize={24}
                  sx={{ ml: 8, mt: 8 }}
                />
              </Tooltip>
            )}
            <ServerErrorMessage errorKey={errorFieldsMapper.endDate} />
          </div>
          <div>
            {!validateMinRunningPeriod() && (
              <p data-qa="379" className={styles.boldError} data-not-valid="true">
                Running period can't be less than 15 minutes
              </p>
            )}
            {endDateNotProvided() && (
              <p className={styles.boldError} data-not-valid="true">
                End Date must be filled
              </p>
            )}
          </div>
        </div>
      </TimezoneProvider>
      {showEndDateRemovalConfirmation && (
        <ErrorThemeDialog
          primaryBtnText="Remove"
          title="Remove End Date?"
          dialogMaxWidth="sm"
          closeDialog={() => setShowEndDateRemovalConfirmation(false)}
          onConfirmation={removeEndDate}
          dialogContent={endDateDialogMessage}
        />
      )}
    </div>
  );
};

const mapState = (state: AppState) => ({
  timezone: state.advanced.sidebarCampaignInfo.timezone,
  startDate: state.advanced.sidebarCampaignInfo.startDate,
  endDate: state.advanced.sidebarCampaignInfo.endDate,
  errorCreating: state.app.errorCreating,
  budgetPacing: state.advanced.budgetPacing,
  isDateDefault: state.advanced.isDateDefault,
  editableCampaign: state.app.editableCampaign,
  isEditingMode: state.app.isEditingMode,
  submitted: state.app.submitted,
  ioId: state.app.savedIoId,
  budgetTypeId: state.app.budgetTypeId,
});

const mapAction = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setEndDateCleared: advanceActions.setEndDateCleared,
  resetError: applicationActions.resetError,
  changeDefaultDateState: advanceActions.changeDefaultDateState,
};

export const StartEndDateWithTimezoneWrapper = connect(
  mapState,
  mapAction,
)(StartEndDateWithTimezoneWrapperComponent);
