import * as React from 'react';
import { ColumnDef } from '@applift/datagrid';
import moment from 'moment';

import { RawDataCell, ImageCell } from 'components/CellType';
import { IconTextCell } from 'components/CellType/IconTextCell';
import {
  AUDIO_CREATIVE_ID,
  CreativesBasicInfoResponse,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
} from 'models/Creative';

export const creativesColDef: ColumnDef<CreativesBasicInfoResponse>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'ID',
    meta: {
      description: 'Creative ID',
    },
    cell: (props) => <RawDataCell value={`${props.renderValue()}`} />,
    size: 80,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    size: 150,
    enableSorting: false,
    cell: (props) => {
      return (
        <ImageCell src={props.renderValue() as string} alt={props.row.original.name as string} />
      );
    },
  },
  {
    accessorKey: 'name',
    id: 'name',
    enableSorting: false,
    header: 'Creative Name',
    cell: (props) => (
      <IconTextCell
        iconName={props.row.original.creativeType}
        label={props.renderValue() as string}
        iconSize={20}
      />
    ),
    size: 300,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    id: 'status',
    enableSorting: false,
    meta: {
      description: 'Creative Status',
    },
    cell: (props) => (
      <IconTextCell
        iconName={props.renderValue() as string}
        label={props.renderValue() as string}
        iconSize={20}
      />
    ),
    size: 150,
  },
  {
    accessorKey: 'clickUrl',
    id: 'clickUrl',
    header: 'Click URL',
    enableSorting: false,
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
    size: 200,
  },
  {
    accessorKey: 'pixelUrls',
    id: 'pixelUrls',
    header: 'Pixel URL',
    enableSorting: false,
    cell: (props) => {
      const pixelURLs = props.row.original?.pixelUrls ?? [];
      return <RawDataCell value={pixelURLs.length ? pixelURLs.toString() : '—'} />;
    },
    size: 200,
  },
  {
    accessorKey: 'createdAt',
    id: 'createdAt',
    header: 'Created on',
    enableSorting: false,
    meta: {
      align: 'right',
    },
    cell: (props) => moment(props.renderValue() as number).format('MM/DD/YYYY'),
    size: 150,
  },
  {
    accessorKey: 'size',
    id: 'size',
    header: 'Size',
    enableSorting: false,
    meta: {
      align: 'right',
    },
    cell: (props) => {
      const { width, height, duration } = props.row.original;
      switch (props.row.original.creativeTypeId) {
        case IMAGE_CREATIVE_ID:
        case HTML_CREATIVE_ID: {
          return `${width}x${height}`;
        }
        case VIDEO_CREATIVE_ID:
        case AUDIO_CREATIVE_ID: {
          return `${duration}s`;
        }
        case NATIVE_CREATIVE_ID:
          if (Boolean(width) && Boolean(height)) {
            return `${width}x${height}`;
          }
          if (typeof duration === 'number') {
            return `${duration}s`;
          }
          return '—';
        default: {
          return null;
        }
      }
    },
    size: 100,
  },
];
