import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography, Button, Tooltip } from '@applift/factor';
import { Add } from '@applift/icons';
import { IO_STATUSES } from 'constants/apps';
import { useIOInfo } from 'hooks/useIO';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { CampaignTypeByName } from 'models/campaign';
import { CreateCampaignDialog } from '../../CreateCampaignDialog';

export interface NoRowsOverlayPropTypes {
  ioId?: number;
  isIoDetailsDirty: boolean;
  setOpenIoSaveDialog: (arg: boolean) => void;
}

export const NoRowsOverlay = (props: NoRowsOverlayPropTypes) => {
  const { isIoDetailsDirty, setOpenIoSaveDialog } = props;
  const { id: ioId } = useParams<{ id: string }>();
  const history = useHistory();
  const { data: ioDetail } = useIOInfo(ioId);
  const [showCampaignCreateDialog, setShowCampaignCreateDialog] = React.useState(false);

  const getCreateCampaignButtonTooltip = () => {
    if (ioDetail?.data?.ioStatusId === IO_STATUSES.EXPIRED) {
      return 'The newly created campaign won’t serve due to the expiration of the IO end date';
    }
    if (ioDetail?.data?.ioStatusId === IO_STATUSES.DELETED) {
      return `Campaign can't be created for a deleted IO. Please restore the IO to create a campaign.`;
    }
    return '';
  };

  return (
    <Box
      sx={{
        width: 50,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        m: 'auto',
        px: 64,
        textAlign: 'center',
      }}
    >
      <Typography variant="bodySmall">
        Seamlessly blend your brand's core values into a compelling campaign that engages and
        resonates with the target audience.
      </Typography>
      <Tooltip placement="top" title={getCreateCampaignButtonTooltip()} arrow>
        <Box>
          <Button
            onClick={() => {
              setShowCampaignCreateDialog(true);
            }}
            size="medium"
            variant="contained"
            startIcon={<Add />}
            sx={{ mt: 16 }}
            disabled={ioDetail?.data?.ioStatusId === IO_STATUS_ID.DELETED}
          >
            Create Campaign
          </Button>
        </Box>
      </Tooltip>
      {showCampaignCreateDialog && (
        <CreateCampaignDialog
          onClose={() => setShowCampaignCreateDialog(false)}
          onCreate={(campaignType: CampaignTypeByName) => {
            if (isIoDetailsDirty) {
              setOpenIoSaveDialog(true);
            } else if (campaignType === 'advanced') {
              history.push(`/campaign-create?ioId=${ioId}`);
            } else if (campaignType === 'pg') {
              history.push(`/campaign-create-pg?ioId=${ioId}`);
            }
          }}
        />
      )}
    </Box>
  );
};
