import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { Creative, CreativesBasicInfoResponse } from 'models/Creative';

import { Option } from 'models/Option';
import { GetResponse } from 'models/Response';
import { Logger } from 'utils/logger';
import { getInstance } from './Instance';
import { getCreativeListKey, getCreativesBasicInfoKey } from './QueryKeys';

type Response = {
  name: string;
  id: number;
  rtbCampaignTypeId?: number;
};

export const FetchCreativeTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<GetResponse<Response[]>> = await getInstance().get(
      '/v2/master/static/creativeTypes',
    );

    return response.data.responseObject.map((i) => ({
      label: i.name,
      value: i.id,
      rtbCampaignTypeId: i.rtbCampaignTypeId || -1,
    }));
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return [];
  }
};

export const FetchRtbTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<GetResponse<Response[]>> = await getInstance().get(
      '/v2/master/rtbTypes',
    );

    return response.data.responseObject.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return [];
  }
};

type CreativeStatusesCountParams = {
  creativeTypeIds?: string;
};

export const FetchCreativeStatusesCount = async (
  params?: CreativeStatusesCountParams,
): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Response[]>> = await getInstance().get(
      'v2/crt/creativeStatus/creativeCount',
      {
        params,
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creative statuses count', e);
    return [];
  }
};

export type CreativeListParams = {
  pageNo?: number;
  noOfEntries?: number;
  searchField?: string;
  creativeTypeIds?: string;
  status?: string;
  creativeIds?: string;
  sortBy?: string;
  sortType?: 'asc' | 'desc';
};

export const FetchCreativeList = async (params: CreativeListParams): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().get(
      'v2/crt/creative/list',
      {
        params: {
          pageNo: params.pageNo,
          noOfEntries: params.noOfEntries,
          searchField: params.searchField,
          creativeTypeIds: params.creativeTypeIds,
          status: params.status,
          creativeIds: params.creativeIds,
          sortBy: params.sortBy,
          sortType: params.sortType,
        },
      },
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting creative list', e);
    return [];
  }
};

export const fetchCreativeListQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCreativeListKey['keys']>>): Promise<any> => {
  const params = queryKey[0]!;
  try {
    const response: AxiosResponse<GetResponse<any>> = await getInstance().get(
      'v2/crt/creative/list',
      {
        params: {
          pageNo: params.pageNo,
          noOfEntries: params.noOfEntries,
          searchField: params.searchField,
          creativeTypeIds: params.creativeTypeIds,
          status: params.status,
          creativeIds: params.creativeIds,
          sortBy: params.sortBy,
          sortType: params.sortBy,
        },
      },
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting creative list', e);
    return [];
  }
};

export const GetMediaSource = async (id: number): Promise<string> => {
  try {
    const response: AxiosResponse<
      GetResponse<{
        creativeSource: string;
      }>
    > = await getInstance().get(`/v2/crt/creative/${id}`);

    return response.data.responseObject.creativeSource;
  } catch (e) {
    Logger.log('Error while getting media source', e);
    return '';
  }
};

export type TAdPlacement = Response;

export const GetAdPlacementList = async (): Promise<TAdPlacement[]> => {
  try {
    const response: AxiosResponse<TAdPlacement[]> = await getInstance().get(`/video_start_delay`);
    return response.data;
  } catch (e) {
    Logger.log('Error while getting media source', e);
    return [];
  }
};

export type BulkUpdateResponse = {
  status: boolean;
  updatedData?: number[];
  failedData?: {
    data?: number[];
    message: string;
  };
};

type BulkUpdateClickURLRequestData = {
  creativeIds: string;
  clickURL: string;
  campaignId?: number;
  confirmStatusChange?: boolean;
};

export const BulkUpdateClickURL = async (
  requestData: BulkUpdateClickURLRequestData,
): Promise<BulkUpdateResponse> => {
  try {
    const response: AxiosResponse<GetResponse<BulkUpdateResponse>> = await getInstance().put(
      '/v2/crt/edit/creatives/clickURL',
      requestData,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while updating the click URL', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

type BulkUpdatePixelURLRequestData = {
  creativeIds: string;
  pixelURL: string;
};

export const BulkUpdatePixelURL = async (
  requestData: BulkUpdatePixelURLRequestData,
): Promise<BulkUpdateResponse> => {
  try {
    const response: AxiosResponse<GetResponse<BulkUpdateResponse>> = await getInstance().put(
      '/v2/crt/edit/creatives/pixelURL',
      requestData,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while updating the pixel URL', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

type MakeDuplicateCreativeParams = {
  creativeIds: string;
  clickURL: string;
};

export type DuplicateCreativesResponse = {
  data: {
    failedCreativeIds: number[];
    newCreativeIds: number[];
    successfullyDuplicatedCreativeIds: number[];
  };
  message: string;
};

export const MakeDuplicateCreatives = async (
  requestData: MakeDuplicateCreativeParams,
): Promise<DuplicateCreativesResponse> => {
  try {
    const res: AxiosResponse<GetResponse<DuplicateCreativesResponse>> = await getInstance().post(
      '/v2/crt/creative/duplicate',
      requestData,
    );

    return res.data.responseObject;
  } catch (e) {
    Logger.log('Error while copying creatives', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const FetchCreativeInfo = async (creativeId: Creative['id']): Promise<Creative[]> => {
  try {
    const res: AxiosResponse<GetResponse<Creative[]>> = await getInstance().get(
      `v2/crt/creatives/details?creativeIds=${creativeId}`,
    );

    return res.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creative(s) details', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export interface CreativesBasicInfoPayload {
  pageNo?: number;
  noOfEntries?: number;
  sortBy?: string; // only id , name, createdAt, status, modified
  order?: 'asc' | 'desc';
  searchField?: string;
  creativeIds?: number[];
  owIds?: number[];
  status?: number[];
  responseFields?: string[]; // id, name, creativeTypeId, creativeType, status, createdAt, modifiedAt, owId, organizationName, organizationLogo, previewFlag, creativeScreenshotSource, creativeCardSource, creativeThumbnailSource
  creativeTypeIds?: number[]; // 11,13,14,16 only
  isAllOwIds?: boolean;
  campaignId?: number;
  ids?: number[];
}

export const fetchCreativesBasicInfoV3 = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCreativesBasicInfoKey['keys']>>): Promise<any> => {
  const payload = queryKey[0].creativesListParams;
  try {
    const response: AxiosResponse<GetResponse<CreativesBasicInfoResponse[]>> =
      await getInstance().post('v3/crt/basic/list', payload);

    return response.data;
  } catch (e) {
    Logger.log('Error while getting creatives info', e);
    return [];
  }
};
