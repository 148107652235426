import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { useCustomerConfigInfo } from 'hooks/useUser';
import { Tooltip } from 'factor';
import { Button } from '@applift/factor';
import { AppBidModel } from '@applift/icons';
import { CREATIVE_TAB } from '@applift/bid-model';

import {
  BUDGET_TYPE_ID,
  MIN_REQUIRED_DAILY_BUDGET_FOR_MODELLING,
  MIN_REQUIRED_DAILY_IMPRESSIONS_FOR_MODELLING,
  MIN_REQUIRED_CAMPAIGN_DURATION_FOR_MODELLING,
} from 'constants/apps';
import { AppState } from 'models/Store';
import {
  SetSaveDraftSubmitted,
  SetModellingDimensionToShow,
  SaveCampaignAsDraft,
  applicationActions,
  CreateCampaign,
  CreateCampaignWithAdvertiser,
  SetErrorCollapsiblesOpen,
  SetSubmitted,
} from 'store/app/actions';
import { Creative } from 'models/Creative';
import { collectRequest, getChangedParameters } from 'store/app/helpers';
import { useDimensions } from 'hooks/useDimension';
import { CampaignCreateUpdateParams } from 'api/Campaign';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { useModelledDimensionsCount } from 'hooks/useCount';
import { SetCampaignSidebarInfo, advanceActions } from 'store/advance/actions';
import { CAMPAIGN_STATUSES } from 'constants/campaignList';
import { ApplyBidModellingDialog } from 'components/ApplyBidModellingDialog';
import { bidActions, ValidateBidStore } from 'store/bid/actions';
import { creativesActions, ValidateCreativeStore } from 'store/creatives/actions';
import { publisherActions, ValidatePublisherStore } from 'store/publishers/actions';
import { technologyActions, ValidateTechnologyStore } from 'store/technology/actions';
import { PoliticalAdvertiser } from 'api/Advertiser';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { SetBidShadingApplicability, advertiserActions } from 'store/advertiser/actions';
import { RUM_ACTION_ATTRIBUTE } from 'services/DataDog/constants';
import { SaveCampaignAsDraftDialog } from '../SaveCampaignAsDraftDialog/SaveCampaignAsDraftDialog';
import { AdvancedCreativesModelling } from './AdvancedCreativesModellingDialog';

interface Props
  extends SetSaveDraftSubmitted,
    CreateCampaign,
    SetErrorCollapsiblesOpen,
    SaveCampaignAsDraft,
    CreateCampaignWithAdvertiser,
    SetSubmitted,
    SetSaveDraftSubmitted,
    ValidateCreativeStore,
    ValidateTechnologyStore,
    ValidatePublisherStore,
    ValidateBidStore,
    SetModellingDimensionToShow,
    SaveCampaignAsDraft,
    SetCampaignSidebarInfo,
    SetBidShadingApplicability {
  sidebarCampaignInfo: AppState['advanced']['sidebarCampaignInfo'];
  impressionsMaxDaily: string;
  modellingDimensionToShow: string;
  budgetTypeId: number;
  selectedCreatives: Creative[];
  campaignId?: number;
  owId: number;
  appState: AppState;
  editableCampaign: ExistingCampaignData | null;
  politicalAdvertiser: PoliticalAdvertiser | null;
  isPoliticalAdvertising: boolean;
}

const AdvancedCreativesModellingButtonComponent = (props: Props) => {
  const {
    sidebarCampaignInfo,
    impressionsMaxDaily,
    budgetTypeId,
    modellingDimensionToShow,
    setModellingDimensionToShow,
    setSaveDraftSubmitted,
    saveCampaignAsDraft,
    selectedCreatives,
    campaignId,
    setCampaignSidebarInfo,
    setBidShadingApplicability,
    owId,
    appState,
    editableCampaign,
    setSubmitted,
    validateCreativeStore,
    validatePublisherStore,
    validateTechnologyStore,
    validateBidStore,
    setErrorCollapsiblesOpen,
    isPoliticalAdvertising,
    politicalAdvertiser,
    createCampaign,
    createCampaignWithAdvertiser,
  } = props;

  const CustomerConfigResponse = useCustomerConfigInfo(owId as number, (res) =>
    setBidShadingApplicability(res.isBidShadingEnabled),
  );

  const isBidShadingEnabled = CustomerConfigResponse.data?.isBidShadingEnabled;

  const [showSaveCampaignAsDraftDialog, setShowSaveCampaignAsDraftDialog] = React.useState(false);
  const [showConfirmBidModellingDialog, setShowConfirmBidModellingDialog] = React.useState(false);

  const history = useHistory();

  const { data: dimensionData } = useDimensions();

  const dimensionId = React.useMemo(() => {
    if (dimensionData) {
      return dimensionData.keyIdPair.creative;
    }
    return undefined;
  }, [dimensionData]);

  const { data: modelledCount } = useModelledDimensionsCount(
    {
      campaignId: campaignId as number,
    },
    {
      enabled: Boolean(campaignId),
    },
  );

  const modelledCreativesCount = modelledCount?.dimensionWiseCount.find(
    (dimension) => dimension.dimensionId === dimensionId,
  )?.count;

  const renderAdvancedModellingDialog = () => (
    <AdvancedCreativesModelling
      closeDialog={() => setModellingDimensionToShow('')}
      defaultTab={modelledCreativesCount ? CREATIVE_TAB.MODELLED : CREATIVE_TAB.ALL}
    />
  );

  const modellingDependentKeys = [
    'startTime',
    'endTime',
    'creativeIds',
    'maxDayImpressions',
    'maxBid',
    'totalImpressions',
    'budgetDay',
    'budgetTotal',
  ];

  const hasAnyDependentKeys = (object: { [key: string]: any }) =>
    modellingDependentKeys.some((key: string) => key in object);

  const saveNonDraftCampaign = () => {
    setSubmitted();
    validateCreativeStore();
    validatePublisherStore();
    validateTechnologyStore();
    validateBidStore();
    setModellingDimensionToShow('creatives');

    setErrorCollapsiblesOpen(true);
    if (isPoliticalAdvertising && !politicalAdvertiser) {
      createCampaignWithAdvertiser(history);
    } else {
      createCampaign(history);
    }
  };

  const saveAsDraft = () => {
    if (sidebarCampaignInfo.campaignName) {
      setModellingDimensionToShow('creatives');
    }
    setShowSaveCampaignAsDraftDialog(false);
    setSaveDraftSubmitted();
    saveCampaignAsDraft(history);
  };

  const saveCampaignWrapper = () => {
    const isDraftCampaign = editableCampaign?.status === CAMPAIGN_STATUSES.DRAFT;
    const isCampaignEditFlow = Boolean(campaignId);
    if (isDraftCampaign || !isCampaignEditFlow) {
      saveAsDraft();
    } else {
      saveNonDraftCampaign();
    }
  };

  const renderSaveCampaignAsDraftDialog = () => (
    <SaveCampaignAsDraftDialog
      closeDialog={() => setShowSaveCampaignAsDraftDialog(false)}
      onConfirmation={saveCampaignWrapper}
    />
  );
  const renderShowConformBidModellingDialog = () => (
    <ApplyBidModellingDialog
      setShowApplyBidModellingDialog={() => {
        setShowConfirmBidModellingDialog(false);
      }}
      applyBidModelling={() => {
        setCampaignSidebarInfo(CampaignInfoField.isBidShading, false);
        let changedValues: any = {};
        let isModellingDependenciesUpdated = false;
        const isCampaignEditFlow = Boolean(campaignId);
        if (isCampaignEditFlow) {
          const req = collectRequest(appState, true);
          changedValues = getChangedParameters(
            editableCampaign as ExistingCampaignData,
            req as CampaignCreateUpdateParams,
          );
          isModellingDependenciesUpdated = hasAnyDependentKeys(changedValues);
        }

        if (isCampaignEditFlow && !isModellingDependenciesUpdated) {
          setModellingDimensionToShow('creatives');
        } else {
          setShowSaveCampaignAsDraftDialog(true);
        }
      }}
    />
  );

  const getAdvancedModellingErrorTooltip = () => {
    if (modelledCreativesCount) {
      return '';
    }

    const { dailyBudget, startDate, endDate } = sidebarCampaignInfo;

    const isBudgetCampaign = budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED;
    const isBudgetOrImpressionNotSatisfied = isBudgetCampaign
      ? Number(dailyBudget) < MIN_REQUIRED_DAILY_BUDGET_FOR_MODELLING
      : Number(impressionsMaxDaily) < MIN_REQUIRED_DAILY_IMPRESSIONS_FOR_MODELLING;

    if (
      !startDate ||
      !endDate ||
      isBudgetOrImpressionNotSatisfied ||
      moment(endDate).diff(startDate, 'days') < MIN_REQUIRED_CAMPAIGN_DURATION_FOR_MODELLING
    ) {
      return `Advanced modelling is only available for campaigns with a daily ${
        isBudgetCampaign
          ? `budget exceeding $${MIN_REQUIRED_DAILY_BUDGET_FOR_MODELLING}`
          : `impression exceeding ${MIN_REQUIRED_DAILY_IMPRESSIONS_FOR_MODELLING}`
      } and a duration of at least ${MIN_REQUIRED_CAMPAIGN_DURATION_FOR_MODELLING} days.`;
    }

    if (!selectedCreatives.length) {
      return 'Atleast 1 creative is required to apply advanced modelling.';
    }

    return '';
  };

  const btnTitle = modelledCreativesCount ? 'View Advanced Modelling' : 'Advanced Modelling';

  return (
    <>
      <Tooltip
        label={getAdvancedModellingErrorTooltip()}
        auto
        adjustPortalPosition
        portal
        labelMaxWidth={350}
        disable={!getAdvancedModellingErrorTooltip()}
      >
        <Button
          size="small"
          color={modelledCreativesCount ? 'success' : 'primary'}
          variant="outlined"
          sx={{ marginLeft: 8 }}
          startIcon={<AppBidModel />}
          disabled={Boolean(getAdvancedModellingErrorTooltip())}
          onClick={(e) => {
            e.stopPropagation();
            let changedValues: any = {};
            let isModellingDependenciesUpdated = false;
            const isCampaignEditFlow = Boolean(campaignId);
            if (isCampaignEditFlow) {
              const req = collectRequest(appState, true);
              changedValues = getChangedParameters(
                editableCampaign as ExistingCampaignData,
                req as CampaignCreateUpdateParams,
              );
              isModellingDependenciesUpdated = hasAnyDependentKeys(changedValues);
            }

            if (sidebarCampaignInfo.isBidShading && isBidShadingEnabled) {
              setShowConfirmBidModellingDialog(true);
            } else if (isCampaignEditFlow && !isModellingDependenciesUpdated) {
              setModellingDimensionToShow('creatives');
            } else {
              setShowSaveCampaignAsDraftDialog(true);
            }
          }}
          {...{ [RUM_ACTION_ATTRIBUTE]: btnTitle }}
        >
          {btnTitle}
        </Button>
      </Tooltip>
      {modellingDimensionToShow === 'creatives' &&
        !showSaveCampaignAsDraftDialog &&
        renderAdvancedModellingDialog()}
      {showSaveCampaignAsDraftDialog && renderSaveCampaignAsDraftDialog()}
      {showConfirmBidModellingDialog &&
        isBidShadingEnabled &&
        renderShowConformBidModellingDialog()}
    </>
  );
};

const mapState = (state: AppState) => ({
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  impressionsMaxDaily: state.bid.impressionsMaxDaily,
  modellingDimensionToShow: state.app.modellingDimensionToShow,
  budgetTypeId: state.app.budgetTypeId,
  selectedCreatives: state.creatives.selectedCreatives,
  campaignId: state.app.editableCampaign?.id,
  owId: state.auth.userData.owId,
  appState: state,
  editableCampaign: state.app.editableCampaign,
  politicalAdvertiser: state.advertiser.politicalAdvertiser,
  isPoliticalAdvertising: state.advertiser.isPoliticalAdvertising,
});

const mapAction = {
  setModellingDimensionToShow: applicationActions.setModellingDimensionToShow,
  saveCampaignAsDraft: applicationActions.saveCampaignAsDraft,
  setSaveDraftSubmitted: applicationActions.setSaveDraftSubmitted,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setBidShadingApplicability: advertiserActions.setBidShadingApplicability,
  createCampaign: applicationActions.createCampaign,
  setSubmitted: applicationActions.setSubmitted,
  createCampaignWithAdvertiser: applicationActions.createCampaignWithAdvertiser,
  setErrorCollapsiblesOpen: applicationActions.setErrorCollapsiblesOpen,
  validateCreativeStore: creativesActions.validateCreativeStore,
  validatePublisherStore: publisherActions.validatePublisherStore,
  validateTechnologyStore: technologyActions.validateTechnologyStore,
  validateBidStore: bidActions.validateBidStore,
};

export const AdvancedCreativesModellingButton = connect(
  mapState,
  mapAction,
)(AdvancedCreativesModellingButtonComponent);
