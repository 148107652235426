import React from 'react';
import { useDispatch } from 'react-redux';
import { Header, getOwId, EXTEND_SESSION_FAILED } from 'iqm-framework';
import get from 'lodash/get';

import { User } from 'models/User';
import { localStorageService } from 'services/localStorage';
import { applicationActions } from 'store/app/actions';
import { authActions } from 'store/auth2/actions';
import { toastActions } from 'store/toast/actions';
import styles from './NavigationWrapper.module.scss';

export const NavigationWrapper = () => {
  const dispatch = useDispatch();

  const onAuthorizeUser = (user: any) => {
    const organizationTimezone = get(user, 'organizationTimezone');
    if (organizationTimezone) {
      dispatch(authActions.setOrganizationTimezoneInfo(organizationTimezone));
    }
  };

  const onRefreshAuthError = (err: any) => {
    dispatch(toastActions.open(EXTEND_SESSION_FAILED));
  };

  const onRefreshAuth = () => {
    const owId = getOwId();
    const userData = localStorageService.getBaseInfo<User>();
    if (userData && owId) {
      dispatch(authActions.login({ ...userData, userId: +owId }));
    } else {
      dispatch(toastActions.open(EXTEND_SESSION_FAILED));
    }
  };

  return (
    <Header
      backButton={false}
      title="Campaigns"
      // @ts-ignore
      isBeta
      leftLogo="Campaign Builder"
      onLogout={() => dispatch(applicationActions.resetStore())}
      onAuthorizeUser={onAuthorizeUser}
      sessionTimeoutDialogProps={{
        onRefreshAuth,
        onRefreshAuthError,
      }}
    >
      <div data-qa="53" id="topNavButtons" className={styles.buttons} />
    </Header>
  );
};
