import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { enqueueSnackbar, Paper, Typography } from '@applift/factor';
import {
  SortingState,
  RowSelectionState,
  constructListFromColumnDef,
  VisibilityState,
  GridActionCellParams,
} from '@applift/datagrid';
import { OptionId } from 'iqm-framework';

import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_LIST_SORTING,
  DEFAULT_COL_VISIBILITY,
} from 'constants/insertionOrder';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { SetIODate } from 'pages/MainPage/IODetails/components/SetIODateDialog';
import { SetTotalBudget } from 'components/SetTotalBudgetDialog';
import { AppState } from 'models/Store';
import { Timezone } from 'models/Timezone';
import { useIoList, useIoListCols } from 'hooks/useIoList';
import { useTimezone } from 'hooks/useTimezone';
import { PATH } from 'constants/path';
import { useSetIOBudget, useSetIOEndDate } from 'hooks/useIO';
import { IoListType } from 'models/IoList';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { ActionPanel } from './ActionPanel';
import { TableActions } from './tableActions';
import { colDef as columnDefinition } from './colDef';
import { ListGrid } from './ListGrid';
import { ActionNameType } from './action';

interface ListGridWrapperProp {
  setHeaderCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  timezoneFilter?: OptionId<string> | undefined;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  dateRange: { startDate: Date; endDate: Date } | null;
}

const ListGridWrapperComponent = (props: ListGridWrapperProp) => {
  const { setHeaderCount, timezoneFilter, rowSelection, setRowSelection, dateRange } = props;
  const timezoneOptions = useTimezone().data;

  const [search, setSearch] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>(DEFAULT_LIST_SORTING);
  const [showEndDate, setShowEndDate] = React.useState(false);
  const [showTotalBudget, setShowTotalBudget] = React.useState(false);
  const [statusIds, setStatusIds] = React.useState<number[]>([]);
  const [budgetTypeIds, setBudgetTypeIds] = React.useState<number[]>([]);
  const defaultData = React.useState(() => constructListFromColumnDef(columnDefinition))[0];
  const [columnVisibility, setColumnVisibility] = React.useState(defaultData[1]);
  const defaultDeselectedColumns = React.useState(() =>
    Object.keys(defaultData[1]).reduce((prev, one) => {
      // eslint-disable-next-line
      prev[one] = false;
      return prev;
    }, {} as VisibilityState),
  )[0];
  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    IoListType
  > | null>(null);

  const closeAction = () => setAction(null);

  const debouncedSearch = useDebounceValue(search, 500);
  const selectedIds = Object.keys(rowSelection).map((id) => Number(id));
  const selectedStatuses = statusIds.sort().join(',');
  const selectedBudgetTypeIds = budgetTypeIds.sort().join(',');

  const { data: ioListCols } = useIoListCols();

  const history = useHistory();

  React.useEffect(() => {
    if (
      ioListCols &&
      ioListCols.config &&
      Object.keys(ioListCols.config).includes('columnVisibility')
    ) {
      const visib = ioListCols.config.columnVisibility;
      setColumnVisibility(visib);
    }
    if (
      ioListCols &&
      ioListCols.config &&
      !Object.keys(ioListCols.config).includes('columnVisibility')
    ) {
      setColumnVisibility(DEFAULT_COL_VISIBILITY);
    }
  }, [ioListCols]);

  const { data, fetchNextPage, isFetching } = useIoList(
    debouncedSearch,
    DEFAULT_PAGE_SIZE,
    sorting,
    selectedStatuses,
    dateRange,
    timezoneFilter?.id,
    selectedBudgetTypeIds,
  );

  React.useEffect(() => {
    setHeaderCount(data?.pages[0].data?.filteredRecords);
  }, [data, setHeaderCount]);

  const flatData = React.useMemo(() => {
    return (
      data?.pages
        ?.map((page) => {
          return page.data?.recordsList ?? [];
        })
        .flat(1) || []
    );
  }, [data]);

  const selectedItems = React.useMemo(
    () =>
      flatData.length ? flatData.filter((data) => selectedIds.includes(data?.ioId as number)) : [],
    [selectedIds, flatData],
  );

  const displaySuccessSnackbar = (type: string) =>
    enqueueSnackbar(
      <Typography>
        <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
          {type}
        </Typography>
        updated successfully for
        <Typography variant="span" component="span" weight="demi" sx={{ ml: 4 }}>
          {selectedItems.length === 1
            ? selectedItems[0]?.ioName
            : `${selectedItems.length} Insertion Orders`}
          .
        </Typography>
      </Typography>,
      {
        variant: 'success',
      },
    );

  const onSuccessfulEndDateSet = () => {
    displaySuccessSnackbar('End Date');
    setShowEndDate(false);
    setRowSelection({});
  };
  const onSuccessfulBudgetSet = () => {
    displaySuccessSnackbar('Total Budget');
    setShowTotalBudget(false);
    setRowSelection({});
  };

  const setEndDate = useSetIOEndDate(onSuccessfulEndDateSet);
  const setBudget = useSetIOBudget(onSuccessfulBudgetSet);

  const isDifferentTimezonesPresent =
    selectedItems.length > 1
      ? !selectedItems
          .map((item) => item?.ioTimezone as number)
          .every((element) => element === selectedItems[0]?.ioTimezone)
      : false;

  const firstSelectionTimeZone: Timezone | null =
    selectedItems.length > 0
      ? (timezoneOptions?.find((item) => item.id === selectedItems[0]?.ioTimezone) as Timezone)
      : null;

  const totalRecords = data?.pages[0]?.data?.totalRecords ?? 0;
  const filteredRecords = React.useMemo(() => data?.pages[0]?.data?.filteredRecords ?? 0, [data]);

  const totalObject = data?.pages[0].data?.recordsTotal;

  React.useEffect(() => {
    if (search && search === '' && filteredRecords === 0) {
      history.push(PATH.EMPTY_IO_LIST);
    }
  }, [search, filteredRecords, history]);

  const totalBudgetCTA = React.useMemo(() => {
    if (selectedItems[0]?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return selectedItems.length === 1 ? (selectedItems[0]?.ioTotalImpressions as number) : 0;
    }
    return selectedItems.length === 1 ? (selectedItems[0]?.ioTotalBudget as number) : 0;
  }, [selectedItems]);

  const onSortingChangeWrapper = (value: React.SetStateAction<SortingState>) => {
    setRowSelection({});
    setSorting(value);
  };

  const setSearchWrapper = (value: React.SetStateAction<string>) => {
    setRowSelection({});
    setSearch(value);
  };

  const actionMetaData = React.useMemo(() => {
    if (action?.actionName === 'VIEW_CAMPAIGN') {
      return { dateRange };
    }
    return {};
  }, [action, dateRange]);

  return (
    <>
      <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <ActionPanel
          search={search}
          setSearch={setSearchWrapper}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          totalCount={totalRecords}
          setShowEndDate={setShowEndDate}
          setShowTotalBudget={setShowTotalBudget}
          selectedItems={selectedItems as IoListType[]}
          isTableLoading={isFetching}
          setStatusIds={setStatusIds}
          statusIds={statusIds}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          defaultData={defaultData}
          dateRange={dateRange}
          timezoneFilter={timezoneFilter}
          sorting={sorting}
          defaultDeselectedColumns={defaultDeselectedColumns}
          setBudgetTypeIds={setBudgetTypeIds}
          budgetTypeIds={budgetTypeIds}
        />
        <ListGrid
          footerData={{
            ...totalObject,
            ioId: 'Total',
            ioStartTime: '-',
            ioEndTime: '-',
            ioStatusId: '-',
            campaignsCount: '-',
            ioPacingPercentage: '-',
            budgetTypeId: budgetTypeIds.length === 1 ? budgetTypeIds[0] : totalObject?.budgetTypeId,
          }}
          data={flatData}
          columnDef={columnDefinition}
          totalRecords={filteredRecords}
          sorting={sorting}
          status={selectedStatuses}
          budgetTypeIds={selectedBudgetTypeIds}
          onSortingChange={onSortingChangeWrapper}
          rowIdKey="ioId"
          pageSize={DEFAULT_PAGE_SIZE}
          search={search}
          loading={isFetching}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          defaultDeselectedColumns={defaultDeselectedColumns}
          onAction={setAction}
          onFetchRows={() => {
            // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
            setTimeout(fetchNextPage, 100);
          }}
        />
      </Paper>
      <TableActions
        onClose={closeAction}
        // @ts-ignore
        action={action?.actionName}
        rowData={{ rowData: action?.metaData, actionMeta: actionMetaData }}
      />
      {showTotalBudget && (
        <SetTotalBudget
          ordersCount={selectedItems.length}
          closeDialog={() => setShowTotalBudget(false)}
          totalBudget={totalBudgetCTA}
          onCompletion={(data) =>
            setBudget.mutate({
              ioIdsList: selectedIds,
              budgetUpdateType: data.selectedCondition,
              ioBudgetTypeId: selectedItems[0]?.ioBudgetTypeId,
              ...(selectedItems[0]?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                ? { budget: Number(data.updatedTotalBudget) }
                : { ioTotalImpressions: Number(data.updatedTotalBudget) }),
            })
          }
          name={selectedItems[0]?.ioName}
          isBudgetBeingSet={setBudget.isLoading}
          budgetTypeId={selectedItems[0]?.ioBudgetTypeId}
        />
      )}
      {showEndDate && (
        <SetIODate
          closeDialog={() => setShowEndDate(false)}
          selectedItems={selectedItems as IoListType[]}
          isDateBeingSet={setEndDate.isLoading}
          onCompletion={(data) =>
            setEndDate.mutate({
              ioEndTime: data?.date,
              ioIdsList: selectedItems.map((item) => item?.ioId as number),
              ioTimezoneId: data.timezone.id,
            })
          }
          isDifferentTimezonesPresent={isDifferentTimezonesPresent}
          dialogName="Set End Date"
          dateLabel="End Date"
          endDate={selectedItems.length > 1 ? 0 : selectedItems[0]?.ioEndTime ?? 0}
          timezone={
            isDifferentTimezonesPresent
              ? undefined
              : {
                  label: firstSelectionTimeZone?.label || '',
                  id: firstSelectionTimeZone?.id || -1,
                  value: firstSelectionTimeZone?.name || '',
                }
          }
        />
      )}
    </>
  );
};

const mapState = (state: AppState) => ({
  organizationTimezone: state.auth.organizationTimezoneInfo,
});

export const ListGridWrapper = connect(mapState, null)(ListGridWrapperComponent);
