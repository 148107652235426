import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  LoadingButton,
  Col,
  Row,
} from '@applift/factor';

import { CampaignListType } from 'models/CampaignList';
import { UpdateBudget } from 'api/Campaign';
import { BUDGET_TYPE_ID } from 'constants/apps';

interface DailyBudgetActionProps {
  inlineEdit?: boolean;
  isLoading?: boolean;
  closeDialog: () => void;
  selectedCampaigns: CampaignListType[];
  onCompletion: (data: UpdateBudget) => void;
}

export const DailyBudgetAction = (props: DailyBudgetActionProps) => {
  const { closeDialog, onCompletion, inlineEdit, isLoading = false, selectedCampaigns } = props;
  const getDefaultVaue = (selectedCampaigns: CampaignListType[]) => {
    if (selectedCampaigns[0].budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return inlineEdit ||
        selectedCampaigns[0].dailyImpression === 0 ||
        selectedCampaigns.length > 1
        ? ''
        : selectedCampaigns[0].dailyImpression?.toString();
    }
    return inlineEdit || selectedCampaigns[0].budgetDay === 0 || selectedCampaigns.length > 1
      ? ''
      : selectedCampaigns[0].budgetDay?.toString();
  };
  const [dailyBudget, setDailyBudget] = React.useState(getDefaultVaue(selectedCampaigns));

  const budgetTypeId = React.useMemo(() => selectedCampaigns[0]?.budgetTypeId, [selectedCampaigns]);

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle onClose={closeDialog}>Set Daily Budget</DialogTitle>
      <DialogContent dividers sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography sx={{ mb: 24 }}>
          {inlineEdit ? 'Set' : 'Enter'} the
          <Typography weight="demi" sx={{ ml: 4 }}>
            Daily Budget
          </Typography>{' '}
          for the
          <Typography weight="demi" sx={{ mx: 4 }}>
            {selectedCampaigns.length === 1
              ? selectedCampaigns[0].campaignName
              : selectedCampaigns.length}
          </Typography>
          {selectedCampaigns.length === 1 && <Typography variant="span">campaign</Typography>}
          {selectedCampaigns.length > 1 && 'campaigns.'}
        </Typography>

        {!inlineEdit && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Row>
              <Col>
                <TextField
                  value={dailyBudget}
                  onChange={(e: any) => {
                    const regexPositiveFloat = /^(?:\d+(?:\.\d+)?|)$/;
                    const regexPositiveInt = /^[1-9]\d*$/;

                    const regexForValidation =
                      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                        ? regexPositiveFloat
                        : regexPositiveInt;

                    const isValid =
                      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                        ? regexForValidation.test(e.target.value) ||
                          (Boolean(e.target.value.split('.').length - 1 < 2) &&
                            e.target.value[e.target.value.length - 1] === '.')
                        : regexForValidation.test(e.target.value) || e.target.value === '';

                    if (!isValid) {
                      return;
                    }
                    setDailyBudget(e.target.value);
                  }}
                  placeholder="Enter Daily budget"
                  variant="outlinedDash"
                  label="Daily Budget"
                  InputProps={{
                    startAdornment:
                      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                        <Typography>$</Typography>
                      ) : (
                        <Typography>Imps</Typography>
                      ),
                  }}
                  sx={{ mb: 2 }}
                />
              </Col>
            </Row>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isLoading}
          disabled={!(Number(dailyBudget) > 0) || isLoading}
          onClick={() =>
            onCompletion({
              ...(budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                ? { dailyBudget: Number(dailyBudget) }
                : { dailyImpressions: Number(dailyBudget) }),
              totalBudgetUpdateType: 'change',
              campaignIds: selectedCampaigns.map((cmp) => cmp.campaignId).toString(),
              budgetTypeId: selectedCampaigns[0].budgetTypeId,
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
