import * as React from 'react';
import { DataGrid, getSortedRowModel, useGridApiRef } from '@applift/datagrid';
import { connect } from 'react-redux';
import { AppState } from 'models/Store';
import { OptionId } from 'models/Option';
import { PgDealResponseType } from 'models/Inventory';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { Box } from '@applift/factor';
import { getPgDealsListColDef } from '../SelectDealsDialog/coldef';

export interface SelectedPgDealsPropTypes {
  timezone: OptionId;
  selectedPgDeals: number[];
  exchangesListResponseMultiPageData: {
    totalRecords: number | undefined;
    filteredRecords: number | undefined;
    pgDealData: (PgDealResponseType | null | undefined)[] | undefined;
  };
  isPgDetailsListLoading: boolean;
}

export const SelectedPgDealsComponent = (props: SelectedPgDealsPropTypes) => {
  const { timezone, selectedPgDeals, isPgDetailsListLoading } = props;
  const apiRef = useGridApiRef();

  const [tableHeight, setTableHeight] = React.useState<number>(0);

  const [pgDealIds, selectedPgDealsStore, setPgDealsIds, setSelectedPgDeals] = useStore(
    (state: StoreFilter) => [
      state.inventoryBlock.pgDealIds,
      state.inventoryBlock.selectedPgDeals,
      state.inventoryBlock.setPgDealsIds,
      state.inventoryBlock.setSelectedPgDeals,
    ],
  );

  const tableData = React.useMemo(() => {
    const selectedPgDealsObj = Object.fromEntries(selectedPgDeals?.map((val) => [val, true]));
    const pgDealData = selectedPgDealsStore;
    // @ts-ignore
    return pgDealData?.filter((val) => selectedPgDealsObj[val?.id]);
  }, [selectedPgDeals, selectedPgDealsStore]);

  const removePGDeal = (id: number) => {
    if (setPgDealsIds) {
      setPgDealsIds(pgDealIds.filter((innerVal: number) => innerVal !== id));
      setSelectedPgDeals(
        selectedPgDealsStore.filter((innerVal: PgDealResponseType) => innerVal.id !== id),
      );
    }
  };

  React.useEffect(() => {
    setTableHeight((pgDealIds?.length ?? 0) * 40 + 45);
  }, [pgDealIds]);

  return (
    <Box style={{ height: tableHeight, maxHeight: '420px' }} sx={{ width: 100 }}>
      <DataGrid
        rowHeight={40}
        headerHeight={40}
        apiRef={apiRef}
        getRowId={(row) => {
          return String(row.id);
        }}
        loading={isPgDetailsListLoading}
        state={{ columnPinning: { right: ['actions'] } }}
        getSortedRowModel={getSortedRowModel()}
        pageSize={pgDealIds.length}
        hideFooter
        hideHeader
        showColumnRightBorder
        showCellRightBorder
        disableRowSelectionOnClick
        data={tableData.length ? tableData : []}
        columns={getPgDealsListColDef(timezone, removePGDeal)}
      />
    </Box>
  );
};

const mapState = (state: AppState) => ({
  timezone: state.advanced.sidebarCampaignInfo.timezone,
});

export const SelectedPgDeals = connect(mapState)(SelectedPgDealsComponent);
