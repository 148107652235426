import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@applift/factor';
import { ConversionSelectionTable } from '@applift/conversion';
import { AppState } from 'models/Store';
import { ConversionType } from 'models/Conversion';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { getConversionFilter } from 'utils/conversion';
import styles from './styles.module.scss';

interface StateProps {
  owId: number;
  editableCampaign: ExistingCampaignData | null;
}

interface Props extends StateProps {
  setOpen: (isOpen: boolean) => void;
  conversionType: ConversionType;
  setConversions: (ids: number[]) => void;
  initialConversionSelection: number[];
  onError: (e: any) => void;
}

type RowSelectionState = { [key: string]: boolean };

const toRowSelection = (ids: number[]) => {
  return ids.reduce((acc, curr) => {
    acc[`${curr}`] = true;
    return acc;
  }, {} as RowSelectionState);
};

const SelectConversionsDialogComponent = (props: Props) => {
  const {
    setOpen,
    conversionType,
    setConversions,
    initialConversionSelection,
    onError,
    owId,
    editableCampaign,
  } = props;
  const [conversionSelection, setConversionSelection] = useState<RowSelectionState | null>(null);

  const initialConversions = useMemo(() => {
    if (editableCampaign?.conversionIds) {
      const savedIds = editableCampaign.conversionIds
        .split(',')
        .map((id) => Number.parseInt(id, 10));
      const excludeConversionIds = savedIds.filter((id) =>
        initialConversionSelection?.includes(id),
      );
      const editableInitialConversionsArr = initialConversionSelection.filter(
        (id) => !excludeConversionIds.includes(id),
      );
      const editableInitialSelection = toRowSelection(editableInitialConversionsArr);
      return {
        initialConversionSelection: editableInitialSelection,
        excludeConversionIds,
      };
    }

    return {
      initialConversionSelection: toRowSelection(initialConversionSelection),
      excludeConversionIds: [],
    };
  }, [editableCampaign, initialConversionSelection]);

  useEffect(() => {
    if (initialConversions) {
      setConversionSelection(initialConversions.initialConversionSelection);
    }
  }, [initialConversions]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSelectConversions = () => {
    const conversionIds = Object.entries(conversionSelection || {})
      .filter(([key, _value]) => !!conversionSelection?.[key])
      .map((entry) => Number.parseInt(entry[0], 10));

    if (initialConversions?.excludeConversionIds?.length) {
      if (conversionType !== ConversionType.POSTBACK) {
        setConversions(conversionIds.concat(initialConversions.excludeConversionIds));
      } else {
        setConversions(conversionIds);
      }
    } else {
      setConversions(conversionIds);
    }

    setOpen(false);
  };

  const isSelectEnabled = useMemo(() => {
    if (!conversionSelection || !initialConversions) {
      return false;
    }

    const conversionIds = Object.entries(conversionSelection || {})
      .filter(([key, _value]) => !!conversionSelection?.[key])
      .map((entry) => Number.parseInt(entry[0], 10));
    const initialTableSelection = Object.keys(initialConversions.initialConversionSelection).map(
      (entry) => Number.parseInt(entry, 10),
    );

    return (
      conversionIds.length !== initialTableSelection.length ||
      (conversionIds.length < initialTableSelection.length
        ? conversionIds.find((id) => !initialTableSelection.includes(id))
        : initialTableSelection.find((id) => !conversionIds.includes(id)))
    );
  }, [initialConversions, conversionSelection]);

  const infoText = useMemo(() => {
    switch (conversionType) {
      case ConversionType.PIXEL:
        return `Select the pixels you want to add to the campaign. Once the campaign is running, they can't be edited.`;
      case ConversionType.POSTBACK:
        return `Select a single postback to add to the campaign. Once the campaign is running, it can't be edited.`;
      default:
        return null;
    }
  }, [conversionType]);

  return (
    <Dialog
      open
      aria-labelledby="conversion-selection-table-dialog-title"
      aria-describedby="conversion-selection-table-dialog-content"
      maxWidth="lg"
    >
      <DialogTitle id="conversion-selection-table-dialog-title">
        <h2>{`Select ${conversionType === ConversionType.PIXEL ? 'Pixel' : 'Postback'}`}</h2>
      </DialogTitle>
      <DialogContent
        id="conversion-selection-table-dialog-content"
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '820px',
        }}
      >
        {!!conversionSelection &&
          !!conversionType &&
          !!initialConversions?.initialConversionSelection && (
            <>
              <div className={styles.dialogContent}>{infoText}</div>
              <ConversionSelectionTable
                conversionType={getConversionFilter(conversionType) || 'pixel'}
                initialSelection={initialConversions.initialConversionSelection}
                onSelectRows={setConversionSelection}
                onError={onError}
                tableHeight="350px"
                owId={owId}
                excludeConversionIds={initialConversions.excludeConversionIds}
              />
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSelectConversions}
          disabled={!isSelectEnabled}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  owId: state.auth.userData.owId,
  editableCampaign: state.app.editableCampaign,
});

export const SelectConversionsDialog = connect<StateProps, null, any, AppState>(
  mapState,
  null,
)(SelectConversionsDialogComponent);
