import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@applift/factor';
import { CreateOrEditPostbackForm } from '@applift/conversion';
import { API } from 'api';
import { WithResponse } from 'models/Response';
import { CreatePostbackParams } from 'models/Conversion';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { LoadingStatus } from 'constants/loading';
import { Open, toastActions } from 'store/toast/actions';

interface StateProps {}

interface DispatchProps {
  openToast: Open['open'];
}

interface Props extends StateProps, DispatchProps {
  onClose: () => void;
  onCreate?: (conversionId: number) => void;
}

export const CreatePostbackDialogComponent = (props: Props) => {
  const { onClose, openToast, onCreate } = props;
  const [isCreatingPostback, setIsCreatingPostback] = useState<LoadingStatus>(
    LoadingStatus.PENDING,
  );

  const onClickCreate = async (params: CreatePostbackParams) => {
    try {
      setIsCreatingPostback(LoadingStatus.LOADING);
      const response = await API.Conversion.CreatePostback(params);
      if (!response?.data) {
        throw response;
      }
      if (onCreate) {
        onCreate(response.data.id);
      }
      openToast(`Postback conversion created successfully.`);
      setIsCreatingPostback(LoadingStatus.SUCCESS);
      onClose();
    } catch (err) {
      openToast((err as WithResponse<any>)?.errorObjects?.[0]?.error || SOMETHING_WENT_WRONG);
      setIsCreatingPostback(LoadingStatus.ERROR);
    }
  };

  return (
    <Dialog maxWidth="lg" fullWidth open>
      <CreateOrEditPostbackForm
        type="create"
        onCompletion={onClickCreate}
        isCompletionInProgress={isCreatingPostback === LoadingStatus.LOADING}
        onClose={onClose}
      />
    </Dialog>
  );
};

const mapAction = {
  openToast: toastActions.open,
};

export const CreatePostbackConversionDialog = connect(
  null,
  mapAction,
)(CreatePostbackDialogComponent);
