import * as React from 'react';
import { Link, Tooltip, Typography } from '@applift/factor';
import { GridActionCellParams } from '@applift/datagrid';
import { ACTION } from '../action';

interface CampaignCellType {
  action?: (args: GridActionCellParams) => any;
  rowData: any;
  cellData: number;
  rumActionName?: string;
}

export const CampaignCell = ({ action, rowData, cellData, rumActionName }: CampaignCellType) => {
  return cellData > 0 ? (
    <Tooltip title="Click to view all the campaigns" placement="top" arrow>
      <Link
        Component="button"
        color="primary"
        onClick={() => action?.({ actionName: ACTION.VIEW_CAMPAIGN, metaData: rowData })}
        /* eslint-disable-next-line */
        {...(rumActionName ? { 'data-dd-action-name': rumActionName } : {})}
      >
        {cellData}
      </Link>
    </Tooltip>
  ) : (
    <Typography>{cellData}</Typography>
  );
};
