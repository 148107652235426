import React from 'react';
import { Icon, Chip } from 'factor';

import { RectangleExtended } from 'models/RectangleExtended';
import { Option } from 'models/Option';
import { GROUPED_TAGS_VALUE } from 'utils/figures';
import { shapeType } from 'constants/location';

import styles from '../index.module.scss';

const STYLE_INCLUDE = '_include';

interface RenderRectangleTags {
  rectangles: RectangleExtended[];
  removeItemFromStore: (type: string) => (item: Option) => void;
  removeGroupFromStore: (type: string) => () => void;
  chipClassName?: string;
  readonly?: boolean;
}

export const RectangleTags = ({
  rectangles,
  removeItemFromStore,
  removeGroupFromStore,
  chipClassName,
  readonly,
}: RenderRectangleTags) => {
  if (rectangles.length > GROUPED_TAGS_VALUE) {
    return (
      <Chip
        item={{
          value: shapeType.rectangle,
          label: `${rectangles.length} selected`,
        }}
        readonly={readonly}
        onRemove={removeGroupFromStore(shapeType.rectangle)}
        className={`${chipClassName || STYLE_INCLUDE} ${styles.chip}`}
        icon={<Icon name="Polygon" />}
      />
    );
  }

  return (
    <>
      {rectangles.map((polData) => (
        <Chip
          key={polData.id}
          item={{
            value: polData.id,
            label: polData.address,
          }}
          readonly={readonly}
          onRemove={removeItemFromStore(shapeType.rectangle)}
          className={`${chipClassName || STYLE_INCLUDE} ${styles.chip}`}
          icon={<Icon name="Polygon" />}
        />
      ))}
    </>
  );
};
