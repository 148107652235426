import { createAction } from '../../utils/actions';
import { inventoryConstants } from './constants';
import { Option } from '../../models/Option';

type Data = {
  key: string;
  value: Option<number>[];
};

export type SetInventoryField = {
  setInventoryField: (data: Data) => void;
};

export type SelectInventoryField = {
  selectInventoryField: (data: Data) => void;
};

export type ResetState = {
  resetState: () => void;
};

export const inventoryActions = {
  setInventoryField(data: Data) {
    return createAction<Data>(inventoryConstants.SET_INVENTORY_FIELD, data);
  },

  selectInventoryField(data: Data) {
    return createAction<Data>(inventoryConstants.SELECT_INVENTORY_FIELD, data);
  },

  resetState() {
    return createAction(inventoryConstants.RESET_INVENTORY_STORE);
  },
};
