import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageLocation = () => {
  const location = useLocation();

  const isEditPage = useCallback(() => {
    const { pathname } = location;
    return pathname.indexOf('campaign-edit') >= 0;
  }, [location]);

  return [isEditPage];
};
