import * as React from 'react';
import { Box, Button, Paper } from '@applift/factor';
import { useHistory } from 'react-router-dom';
import { BlockWiseErrorType, StoreFilter } from 'models/ZustandStore';
import { useStore } from 'zustandStore';
import { PG_CAMPAIGN_COMPONENTS } from 'constants/pgCampaign';
import { BUDGET_TYPE_ID } from 'constants/apps';

const scrollToSection = (elemId: string) => {
  setTimeout(() => {
    document
      .getElementById(elemId)
      ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }, 0);
};

export const CampaignCTA = () => {
  const [expandedBlocks, setExpandedBlocks] = useStore((state: StoreFilter) => [
    state.pgCampaignPage.expandedBlocks,
    state.pgCampaignPage.setExpandedBlocks,
  ]);
  const history = useHistory();

  const isAllSectionExpanded = () => expandedBlocks.length === 4;

  const expandAllBlocks = () => {
    setExpandedBlocks(['Conversion', 'Creatives', 'Inventory', 'Location']);
  };

  const collapseAllBlocks = () => {
    setExpandedBlocks([]);
  };

  const handleExpandCollapseWrapper = () => {
    if (isAllSectionExpanded()) {
      collapseAllBlocks();
    } else {
      expandAllBlocks();
    }
  };

  const [
    country,
    campaignName,
    maxBidPrice,
    startDate,
    endDate,
    spendingBudget,
    totalImpressions,
    timezone,
    selectedCreatives,
    creativeTypeId,
    pgDealIds,
    isEditMode,
    advertiserDomain,
    setBlockFieldsError,
    campaignInfoBlockError,
    creativesBlockError,
    inventoryBlockError,
    politicalAdvertiserBlock,
    campaignDetails,
    isPoliticalCampaign,
    politicalAdvertiserClientId,
    ioDetails,
  ] = useStore((state: StoreFilter) => [
    state.locationBlock.country,
    state.campaignInfoBlock.campaignName,
    state.campaignInfoBlock.maxBidPrice,
    state.campaignInfoBlock.startDate,
    state.campaignInfoBlock.endDate,
    state.campaignInfoBlock.spendingBudget,
    state.campaignInfoBlock.totalImpressions,
    state.campaignInfoBlock.timezone,
    state.creativesBlock.selectedCreatives,
    state.creativesBlock.creativeTypeId,
    state.inventoryBlock.pgDealIds,
    state.pgCampaignPage.isEditMode,
    state.campaignInfoBlock.advertiserDomain,
    state.errorFields.setBlockFieldsError,
    state.errorFields.campaignInfoBlock,
    state.errorFields.creativesBlock,
    state.errorFields.inventoryBlock,
    state.errorFields.politicalAdvertiserBlock,
    state.pgCampaignPage.campaignDetails,
    state.politicalAdvertiser.isPoliticalCampaign,
    state.politicalAdvertiser.politicalAdvertiserClientId,
    state.pgCampaignPage.ioDetails,
  ]);

  const isFieldErrorAlreadyPresent = React.useCallback(
    (field: any, errorBlock?: BlockWiseErrorType[]) => {
      return Boolean(errorBlock?.filter((val) => val.field === field).length);
    },
    [],
  );

  const emptyFieldValidation = (value: any) => {
    return !value;
  };

  const shouldSaveCampaign = React.useMemo(() => {
    return isEditMode && campaignDetails && !['draft', 'rejected'].includes(campaignDetails.status);
  }, [campaignDetails, isEditMode]);

  const isEndDateValid =
    campaignInfoBlockError &&
    campaignInfoBlockError?.filter((val: BlockWiseErrorType) => val.field === 'endDate').length ===
      0;

  const isStartDateValid =
    campaignInfoBlockError &&
    campaignInfoBlockError?.filter((val: BlockWiseErrorType) => val.field === 'startDate')
      .length === 0;

  const isAdvertiserURLValid =
    campaignInfoBlockError &&
    campaignInfoBlockError?.filter((val: BlockWiseErrorType) => val.field === 'advertiserDomainUrl')
      .length === 0 &&
    advertiserDomain !== '';

  const isMaxBidLessThanSpendingBudget = React.useMemo(
    () =>
      ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED && maxBidPrice && spendingBudget
        ? maxBidPrice <= spendingBudget
        : true,
    [ioDetails?.ioBudgetTypeId, maxBidPrice, spendingBudget],
  );

  const createPgCampaignWrapper = (actionType: 'create' | 'edit' | 'draftCreate' | 'draftEdit') => {
    const blocksToBeExpanded: typeof expandedBlocks | string[] = [];
    if (
      !campaignName ||
      !maxBidPrice ||
      !(spendingBudget || totalImpressions) ||
      !isEndDateValid ||
      !isStartDateValid ||
      !isMaxBidLessThanSpendingBudget
    ) {
      if (
        emptyFieldValidation(campaignName) &&
        !isFieldErrorAlreadyPresent('campaignName', campaignInfoBlockError)
      ) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: 'Campaign name must be filled',
          field: 'campaignName',
        });
      }
      if (
        emptyFieldValidation(spendingBudget || totalImpressions) &&
        !isFieldErrorAlreadyPresent('spendingBudget', campaignInfoBlockError)
      ) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: "Spending Budget can't be empty",
          field: 'spendingBudget',
        });
      }
      if (
        emptyFieldValidation(maxBidPrice) &&
        !isFieldErrorAlreadyPresent('maxbidPrice', campaignInfoBlockError)
      ) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: "Max Bid Price can't be empty",
          field: 'maxbidPrice',
        });
      }
      if (
        !isMaxBidLessThanSpendingBudget &&
        !isFieldErrorAlreadyPresent('maxbidPrice', campaignInfoBlockError)
      ) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: 'Max bid must be less or equal than total budget!',
          field: 'maxbidPrice',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }
    if (!selectedCreatives.length || !advertiserDomain || !isAdvertiserURLValid) {
      if (emptyFieldValidation(selectedCreatives.length) && !creativesBlockError?.length) {
        if (!isFieldErrorAlreadyPresent('selectedCreatives', creativesBlockError)) {
          setBlockFieldsError({
            blockName: 'creativesBlock',
            errorText: 'Please select atleast one creative',
            field: 'selectedCreatives',
          });
        }
      }
      if (
        selectedCreatives.length &&
        emptyFieldValidation(advertiserDomain) &&
        !creativesBlockError?.length
      ) {
        if (
          campaignInfoBlockError &&
          !isFieldErrorAlreadyPresent('advertiserDomainUrl', campaignInfoBlockError)
        ) {
          setBlockFieldsError({
            blockName: 'campaignInfoBlock',
            errorText: "Advertiser domain URL can't be empty",
            field: 'advertiserDomainUrl',
          });
        }
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.creativeBlock);
      blocksToBeExpanded.push('Creatives');
    }
    if (!country) {
      scrollToSection(PG_CAMPAIGN_COMPONENTS.locationBlock);
      blocksToBeExpanded.push('Location');
    }

    if (!pgDealIds.length) {
      if (inventoryBlockError && !isFieldErrorAlreadyPresent('pgDeals', inventoryBlockError)) {
        setBlockFieldsError({
          blockName: 'inventoryBlock',
          errorText: 'At least one PG deal is required to run a PG campaign.',
          field: 'pgDeals',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.inventoryBlock);
      blocksToBeExpanded.push('Inventory');
    }
    if (isPoliticalCampaign && !politicalAdvertiserClientId) {
      if (
        politicalAdvertiserBlock &&
        !isFieldErrorAlreadyPresent('politicalAdvertiser', politicalAdvertiserBlock)
      ) {
        setBlockFieldsError({
          blockName: 'politicalAdvertiserBlock',
          errorText: 'Please select atleast one advertiser',
          field: 'politicalAdvertiser',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.politicalAdvertiserBlock);
    }
    setExpandedBlocks(blocksToBeExpanded as typeof expandedBlocks);

    if (
      maxBidPrice &&
      startDate &&
      (spendingBudget || totalImpressions) &&
      timezone?.id &&
      creativeTypeId &&
      country &&
      campaignName &&
      selectedCreatives.length &&
      pgDealIds.length &&
      advertiserDomain &&
      ((isPoliticalCampaign && politicalAdvertiserClientId) || !isPoliticalCampaign) &&
      ((endDate && isEndDateValid) || !endDate) &&
      isStartDateValid &&
      isAdvertiserURLValid &&
      isMaxBidLessThanSpendingBudget
    ) {
      history.push(`/campaign-saving-pg?type=${actionType}`);
    }
  };

  const editPgCampaignWrapper = (actionType: 'create' | 'edit' | 'draftCreate' | 'draftEdit') => {
    const blocksToBeExpanded: typeof expandedBlocks | string[] = [];
    if (
      !campaignName ||
      !maxBidPrice ||
      !spendingBudget ||
      !totalImpressions ||
      !isEndDateValid ||
      !isStartDateValid ||
      !isAdvertiserURLValid ||
      !isMaxBidLessThanSpendingBudget
    ) {
      if (emptyFieldValidation(campaignName)) {
        if (
          campaignInfoBlockError &&
          !isFieldErrorAlreadyPresent('campaignName', campaignInfoBlockError)
        ) {
          setBlockFieldsError({
            blockName: 'campaignInfoBlock',
            errorText: 'Campaign name must be filled',
            field: 'campaignName',
          });
        }
      }
      if (emptyFieldValidation(spendingBudget) || emptyFieldValidation(totalImpressions)) {
        if (!isFieldErrorAlreadyPresent('spendingBudget', campaignInfoBlockError)) {
          setBlockFieldsError({
            blockName: 'campaignInfoBlock',
            errorText:
              campaignDetails?.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
                ? "Total impressions can't be empty"
                : "Spending Budget can't be empty",
            field: 'spendingBudget',
          });
        }
      }
      if (emptyFieldValidation(maxBidPrice)) {
        if (!isFieldErrorAlreadyPresent('maxbidPrice', campaignInfoBlockError)) {
          setBlockFieldsError({
            blockName: 'campaignInfoBlock',
            errorText: "Max Bid Price can't be empty",
            field: 'maxbidPrice',
          });
        }
      }
      if (
        !isMaxBidLessThanSpendingBudget &&
        !isFieldErrorAlreadyPresent('maxbidPrice', campaignInfoBlockError)
      ) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: 'Max bid must be less or equal than total budget!',
          field: 'maxbidPrice',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }

    if (!selectedCreatives.length || !advertiserDomain || !isAdvertiserURLValid) {
      if (emptyFieldValidation(selectedCreatives.length) && !creativesBlockError?.length) {
        if (!isFieldErrorAlreadyPresent('selectedCreatives', creativesBlockError)) {
          setBlockFieldsError({
            blockName: 'creativesBlock',
            errorText: 'Please select at least one creative',
            field: 'selectedCreatives',
          });
        }
      }
      if (
        selectedCreatives.length &&
        emptyFieldValidation(advertiserDomain) &&
        !creativesBlockError?.length
      ) {
        if (
          campaignInfoBlockError &&
          !isFieldErrorAlreadyPresent('advertiserDomainUrl', campaignInfoBlockError)
        ) {
          setBlockFieldsError({
            blockName: 'campaignInfoBlock',
            errorText: "Advertiser domain URL can't be empty",
            field: 'advertiserDomainUrl',
          });
        }
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.creativeBlock);
      blocksToBeExpanded.push('Creatives');
    }
    if (!country) {
      scrollToSection(PG_CAMPAIGN_COMPONENTS.locationBlock);
      blocksToBeExpanded.push('Location');
    }
    if (!pgDealIds.length) {
      if (inventoryBlockError && !isFieldErrorAlreadyPresent('pgDeals', inventoryBlockError)) {
        setBlockFieldsError({
          blockName: 'inventoryBlock',
          errorText: 'At least one PG deal is required to run a PG campaign.',
          field: 'pgDeals',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.inventoryBlock);
      blocksToBeExpanded.push('Inventory');
    }
    if (isPoliticalCampaign && !politicalAdvertiserClientId) {
      if (
        politicalAdvertiserBlock &&
        !isFieldErrorAlreadyPresent('politicalAdvertiser', politicalAdvertiserBlock)
      ) {
        setBlockFieldsError({
          blockName: 'politicalAdvertiserBlock',
          errorText: 'Please select atleast one advertiser',
          field: 'politicalAdvertiser',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.politicalAdvertiserBlock);
    }
    setExpandedBlocks(blocksToBeExpanded as typeof expandedBlocks);

    if (
      maxBidPrice &&
      startDate &&
      (campaignDetails?.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? spendingBudget
        : totalImpressions) &&
      timezone?.id &&
      creativeTypeId &&
      country &&
      campaignName &&
      selectedCreatives.length &&
      pgDealIds.length &&
      ((isPoliticalCampaign && politicalAdvertiserClientId) || !isPoliticalCampaign) &&
      ((endDate && isEndDateValid) || !endDate) &&
      isStartDateValid &&
      isAdvertiserURLValid &&
      isMaxBidLessThanSpendingBudget
    ) {
      history.push(`/campaign-saving-pg?type=${actionType}`);
    }
  };

  const onSaveOrRunClick = () => {
    if (isEditMode) {
      editPgCampaignWrapper('edit');
    } else {
      createPgCampaignWrapper('create');
    }
  };

  const createPgDraftCampaignWrapper = (
    actionType: 'create' | 'edit' | 'draftCreate' | 'draftEdit',
  ) => {
    if (!isEndDateValid || !isStartDateValid) {
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }
    const blocksToBeExpanded: typeof expandedBlocks | string[] = [];
    if (!isAdvertiserURLValid) {
      scrollToSection(PG_CAMPAIGN_COMPONENTS.creativeBlock);
      blocksToBeExpanded.push('Creatives');
    }
    if (!campaignName && isEndDateValid && isAdvertiserURLValid && isStartDateValid) {
      if (emptyFieldValidation(campaignName)) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: 'Campaign name must be filled',
          field: 'campaignName',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }

    if (
      !isMaxBidLessThanSpendingBudget &&
      !isFieldErrorAlreadyPresent('maxbidPrice', campaignInfoBlockError)
    ) {
      setBlockFieldsError({
        blockName: 'campaignInfoBlock',
        errorText: 'Max bid must be less or equal than total budget!',
        field: 'maxbidPrice',
      });
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }
    setExpandedBlocks(blocksToBeExpanded as typeof expandedBlocks);

    if (
      startDate &&
      timezone?.id &&
      campaignName &&
      ((endDate && isEndDateValid) || !endDate) &&
      isStartDateValid &&
      ((advertiserDomain && isAdvertiserURLValid) || !advertiserDomain) &&
      isMaxBidLessThanSpendingBudget
    ) {
      history.push(`/campaign-saving-pg?type=${actionType}`);
    }
  };

  const editPgDraftCampaignWrapper = (
    actionType: 'create' | 'edit' | 'draftCreate' | 'draftEdit',
  ) => {
    if (!isEndDateValid && !isStartDateValid) {
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }
    const blocksToBeExpanded: typeof expandedBlocks | string[] = [];
    if (!isAdvertiserURLValid) {
      setBlockFieldsError({
        blockName: 'politicalAdvertiserBlock',
        errorText: 'Please select atleast one advertiser',
        field: 'politicalAdvertiser',
      });
      scrollToSection(PG_CAMPAIGN_COMPONENTS.creativeBlock);
      blocksToBeExpanded.push('Creatives');
    }
    if (!campaignName) {
      if (emptyFieldValidation(campaignName)) {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText: 'Campaign name must be filled',
          field: 'campaignName',
        });
      }
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }
    if (
      !isMaxBidLessThanSpendingBudget &&
      !isFieldErrorAlreadyPresent('maxbidPrice', campaignInfoBlockError)
    ) {
      setBlockFieldsError({
        blockName: 'campaignInfoBlock',
        errorText: 'Max bid must be less or equal than total budget!',
        field: 'maxbidPrice',
      });
      scrollToSection(PG_CAMPAIGN_COMPONENTS.campaignInfoBlock);
    }

    setExpandedBlocks(blocksToBeExpanded as typeof expandedBlocks);

    if (
      startDate &&
      timezone?.id &&
      campaignName &&
      ((endDate && isEndDateValid) || !endDate) &&
      isStartDateValid &&
      ((advertiserDomain && isAdvertiserURLValid) || !advertiserDomain) &&
      isMaxBidLessThanSpendingBudget
    ) {
      history.push(`/campaign-saving-pg?type=${actionType}`);
    }
  };

  const saveAsDraftWrapper = () => {
    if (isEditMode) {
      editPgDraftCampaignWrapper('draftEdit');
    } else {
      createPgDraftCampaignWrapper('draftCreate');
    }
  };

  return (
    <Paper sx={{ bgColor: 'neutral-0' }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between', mx: 16, my: 12 }}
      >
        <Box>
          <Button color="secondary" onClick={handleExpandCollapseWrapper}>
            {isAllSectionExpanded() ? 'Collapse All Sections' : 'Expand All Sections'}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 16 }}>
          {shouldSaveCampaign ? (
            <Button onClick={onSaveOrRunClick}>Save</Button>
          ) : (
            <>
              <Button variant="outlined" onClick={saveAsDraftWrapper}>
                Save as Draft
              </Button>

              <Button onClick={onSaveOrRunClick}> Run</Button>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
