import { GeoRadiusDataTypes } from 'api/Campaign';
import { ListedTagsNames } from 'pages/MainPage/CampaignPage/LocationBlock/Tags';
import { BlackLists, WhiteLists } from 'store/advance/reducer';

export const whiteListsKeys: (keyof WhiteLists)[] = [
  'whiteListedDmaIds',
  'whiteListedZipcodes',
  'whiteListedCongressionalDistrictIds',
  'whiteListedSenateDistrictIds',
  'whiteListedHouseDistrictIds',
  'whiteListedLocationIds',
  'whiteListedCityIds',
  'whiteListedCountyIds',
  'whiteListedStateIds',
];

export const blackListsKeys: (keyof BlackLists)[] = [
  'blackListedDmaIds',
  'blackListedZipcodes',
  'blackListedCongressionalDistrictIds',
  'blackListedSenateDistrictIds',
  'blackListedHouseDistrictIds',
  'blackListedLocationIds',
  'blackListedCityIds',
  'blackListedCountyIds',
  'blackListedStateIds',
];

export const whiteListNameByTagName: { [key in ListedTagsNames]: keyof WhiteLists } = {
  dma: whiteListsKeys[0],
  zipcodes: whiteListsKeys[1],
  districts: whiteListsKeys[2],
  senates: whiteListsKeys[3],
  houses: whiteListsKeys[4],
  files: whiteListsKeys[5],
  cities: whiteListsKeys[6],
  counties: whiteListsKeys[7],
  states: whiteListsKeys[8],
};

export const blackListNameByTagName: { [key in ListedTagsNames]: keyof BlackLists } = {
  dma: blackListsKeys[0],
  zipcodes: blackListsKeys[1],
  districts: blackListsKeys[2],
  senates: blackListsKeys[3],
  houses: blackListsKeys[4],
  files: blackListsKeys[5],
  cities: blackListsKeys[6],
  counties: blackListsKeys[7],
  states: blackListsKeys[8],
};

export const shapeType: { [key in 'circle' | 'polygon' | 'rectangle']: GeoRadiusDataTypes } = {
  circle: 'circle',
  polygon: 'custom',
  rectangle: 'square',
};

export const US_COUNTRY_ID = 30100001;

export const locationTypeIds = {
  states: 302,
  counties: 303,
  cities: 304,
  districts: 306,
  senates: 307,
  houses: 308,
  dma: 309,
};
