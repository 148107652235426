import React from 'react';
import { TableComponent } from 'iqm-framework';

import { Creative } from 'models/Creative';
import { HeaderMapper, BodyMapper } from './creativeTableOptions/creativeTableMappers';
import { skeleton, tableParams } from './creativeTableOptions/creativeTableDefaultProps';
// eslint-disable-next-line import/no-cycle
import { TCreativesPage } from '../..';

import styles from '../../styles.module.scss';

// eslint-disable-next-line import/no-mutable-exports
export let TableComponentInstance: any;

export type TProps = {
  creatives: Creative[];
  onCreativesLoaded: (creativesPage: TCreativesPage) => void;
  onCreativesSelected: (selectedCreatives: Creative[]) => void;
  emptyTableLabel: string;
};

export type TState = {
  pageNo: number;
  sorting: object;
};

class CreativeTable extends React.Component<TProps, TState> {
  handleSetRef = (ref: any) => {
    TableComponentInstance = ref;
  };

  transformRequestParams = (query: any, state: any) => {
    const params: any = {
      pageNo: state.isAllItemsLoaded ? -1 : query.pgno,
      noOfEntries: query.no_of_entries,
    };

    this.setState({ pageNo: query.pgno });

    if (state.sorting.field && state.sorting.direction) {
      params.sortBy = state.sorting.field;
      params.sortType = state.sorting.direction;
    }

    return params;
  };

  onLoadNewPage = (response: any) => {
    const { onCreativesLoaded } = this.props;
    const { pageNo } = this.state;

    if (response && response.responseObject) {
      onCreativesLoaded({
        creatives: response.responseObject.data,
        filteredRecords: response.responseObject.filteredRecords,
        totalRecords: response.responseObject.totalRecords,
        pageNumber: pageNo,
      });
    }
  };

  render() {
    const { creatives, onCreativesSelected, emptyTableLabel } = this.props;

    return (
      <TableComponent
        entityName="creatives"
        offsetTop={-100}
        idField="id"
        dataPath="responseObject.data"
        countPath="responseObject.filteredRecords"
        bodyMapping={BodyMapper}
        data={creatives.length ? creatives : undefined}
        totalItems={creatives.length ? creatives.length : undefined}
        headerMapping={HeaderMapper}
        transformRequestParams={this.transformRequestParams}
        innerRef={this.handleSetRef}
        emptyTableLabel={emptyTableLabel}
        onDataChanged={this.onLoadNewPage}
        onDataLoaded={this.onLoadNewPage}
        skeleton={skeleton}
        checkbox
        checkboxInHeader
        onSelect={onCreativesSelected}
        className={styles.selectCreativesTable}
        tableParams={{
          ...tableParams,
          windowFreeResizeEvent: true,
          tableMaxHeight: 'calc(85vh - 240px)',
          preventNavigationByScroll: true,
        }}
      />
    );
  }
}

export default CreativeTable;
