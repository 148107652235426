import * as React from 'react';
import { TypoTooltip, Typography, TypographyProps } from '@applift/factor';

interface CurrencyCellType {
  value: number;
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const FormattedNumberCell = ({ value, typographyProps }: CurrencyCellType) => {
  return value ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {/* eslint-disable-next-line */}
      <Typography {...typographyProps}>
        {Intl.NumberFormat('en', { notation: 'compact' } as any).format(value)}
      </Typography>
    </TypoTooltip>
  ) : (
    // eslint-disable-next-line
    <Typography {...typographyProps}>—</Typography>
  );
};
