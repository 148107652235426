let currentlyPlaying: HTMLMediaElement;
export function pauseOthersAndPlayThis(target: HTMLMediaElement) {
  if (currentlyPlaying) {
    currentlyPlaying.pause();
    currentlyPlaying.setAttribute('pause', 'true');
  }
  currentlyPlaying = target;
  // eslint-disable-next-line no-param-reassign
  target.oncanplaythrough = () => {
    currentlyPlaying.setAttribute('pause', 'false');
    target.play();
  };
}
