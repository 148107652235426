import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { schedulingConstants } from './constants';
import { Option } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface Scheduling {
  [key: string]: Array<Array<string>>;
}

export interface SchedulingState {
  sidebarCampaignInfo: {
    [CampaignInfoField.scheduling]: Scheduling;
  };
}

const defaultSchedulingState: SchedulingState = {
  sidebarCampaignInfo: {
    [CampaignInfoField.scheduling]: {},
  },
};

const setSidebarCampaignInfo = (
  state: SchedulingState,
  action: Action<{ key: string; value: Option<number>[] }>,
): SchedulingState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetSchedulingStore = (state: SchedulingState): SchedulingState => {
  return {
    ...state,
    sidebarCampaignInfo: { ...defaultSchedulingState.sidebarCampaignInfo },
  };
};

const reducer = reducerFromMap<SchedulingState>(defaultSchedulingState, {
  [schedulingConstants.SELECT_SCHEDULING_FIELD]: setSidebarCampaignInfo,
  [schedulingConstants.RESET_SCHEDULING_STORE]: resetSchedulingStore,
});

export const scheduling = (state: SchedulingState = defaultSchedulingState, action: Action<any>) =>
  reducer(state, action);
