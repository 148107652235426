export const CurrencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const metersToMiles = (value: number) => {
  return value / 1609.34;
};

export const milesToMeters = (value: number) => {
  return value * 1609.34;
};

export const nFormatter = (numArg: number, digits: number) => {
  const num = numArg < 0 ? -numArg : numArg;
  const isNumArgNegative = numArg < 0;
  if (Math.abs(Number(num)) >= 1.0e9) {
    return isNumArgNegative
      ? `-${(Math.abs(Number(num)) / 1.0e9).toFixed(digits)}B`
      : `${(Math.abs(Number(num)) / 1.0e9).toFixed(digits)}B`;
  }
  if (Math.abs(Number(num)) >= 1.0e6) {
    return isNumArgNegative
      ? `-${(Math.abs(Number(num)) / 1.0e6).toFixed(digits)}M`
      : `${(Math.abs(Number(num)) / 1.0e6).toFixed(digits)}M`;
  }
  if (Math.abs(Number(num)) >= 1.0e3) {
    return isNumArgNegative
      ? `-${(Math.abs(Number(num)) / 1.0e3).toFixed(digits)}K`
      : `${(Math.abs(Number(num)) / 1.0e3).toFixed(digits)}K`;
  }
  return `${Math.abs(Number(num))}`;
};

export const formatCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};

export const timeFormat = (secs: string | number) => {
  const secNum = parseInt(secs as string, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};

export const displayTime = (data: number) => {
  return data !== null ? timeFormat(+data) : '—';
};

export const toInitCap = (value: string) =>
  !!value && `${value[0]?.toLocaleUpperCase()}${value.slice(1)}`;

export const numberFormat = (value: string | number) => {
  return typeof value === 'number' || value
    ? Intl.NumberFormat('en-US').format(parseInt(value as string, 10))
    : '';
};

export const formatNumberWithDecimal = (value: number | string) => {
  const numberValue = Number(value);
  return numberValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
