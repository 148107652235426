import { StateCreator } from 'zustand';
import { PgCampaignPageSlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  expandedBlocks: [],
  campaignDetails: null,
  ioDetails: null,
  isEditMode: false,
};

export const createPgCampaignPageStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  PgCampaignPageSlice
> = (set) => ({
  pgCampaignPage: {
    ...initialState,
    setExpandedBlocks: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          pgCampaignPage: {
            ...prev.pgCampaignPage,
            expandedBlocks: value,
          },
        }),
        false,
        'setExpandedBlocks',
      ),
    setIoDetails: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          pgCampaignPage: {
            ...prev.pgCampaignPage,
            ioDetails: value,
          },
        }),
        false,
        'setIoDetails',
      ),
    setCampaignDetails: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          pgCampaignPage: {
            ...prev.pgCampaignPage,
            campaignDetails: value,
          },
        }),
        false,
        'setCampaignDetails',
      ),
    setIsEditMode: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          pgCampaignPage: {
            ...prev.pgCampaignPage,
            isEditMode: value,
          },
        }),
        false,
        'setIsEditMode',
      ),
  },
});
