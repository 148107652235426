import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Col,
  Row,
  LoadingButton,
  Alert,
  enqueueSnackbar,
} from '@applift/factor';
import { InfoCircle, IoCurrency, IoImpression } from '@applift/icons';
import { maxBy } from 'lodash';
import moment from 'moment';

import { OptionId } from 'models/Option';
import { SingleDatePicker } from 'components/SingleDatePicker';
import { Timezone } from 'components/Timezone';
import { DEFAULT_ORGANIZATION_TIMEZONE } from 'constants/timezone';
import { IoListType } from 'models/IoList';
import { BUDGET_TYPE_ID, IO_STATUSES } from 'constants/apps';

interface IODialogData {
  date: number;
  timezone: OptionId<string>;
}

interface SetIODateProps {
  selectedItems: IoListType[];
  closeDialog: () => void;
  isDifferentTimezonesPresent: boolean;
  timezone: OptionId<string> | undefined;
  endDate: number;
  dialogName: string;
  dateLabel: string;
  onCompletion: (data: IODialogData) => void;
  isDateBeingSet?: boolean;
}

export const SetIODate = (props: SetIODateProps) => {
  const {
    selectedItems,
    closeDialog,
    isDifferentTimezonesPresent,
    timezone,
    endDate,
    dialogName,
    isDateBeingSet,
    dateLabel,
    onCompletion,
  } = props;
  const [selectedDate, updateDate] = React.useState(endDate || null);
  const [updatedTimezone, selectTimeZone] = React.useState(timezone || undefined);
  const [updateForRerender, setUpdateForRerender] = React.useState(0);
  const selectionCount = selectedItems.length;
  const selectionName = selectedItems[0].ioName;
  const isEntireSelectionExpired = selectedItems.every(
    (item) => item.ioStatusId === IO_STATUSES.EXPIRED,
  );

  const getHelperText = () => {
    return isDifferentTimezonesPresent
      ? `Please be aware of different timezones for selected IOs; Updating the end date may
impact the campaigns below. When the IO end date is reached, all campaigns will stop
spending.`
      : `Please note that updating the end date may impact the campaigns below. When the IO end date is reached, all campaigns will stop spending`;
  };

  const getNameWithIcon = () => (
    <Box Component="span">
      {selectedItems[0].ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
        <IoCurrency
          fontSize={20}
          color="primary"
          sx={{ mx: 4 }}
          style={{ verticalAlign: 'bottom' }}
        />
      ) : (
        <IoImpression
          fontSize={20}
          color="primary"
          sx={{ mx: 4 }}
          style={{ verticalAlign: 'bottom' }}
        />
      )}
      <Typography sx={{ textWeight: 'demi' }}>{selectionName}</Typography>
    </Box>
  );

  const maxIoStartDate = React.useMemo(
    () => maxBy(selectedItems, (io) => io.ioStartTime)?.ioStartTime || 0,
    [selectedItems],
  );

  const handleSelectEndDate = (date: number | null) => {
    const minimumEndDate = moment(maxIoStartDate).add(30, 'm');

    if (!date || !maxIoStartDate || minimumEndDate.clone().isSameOrBefore(moment(date))) {
      updateDate(date);
    } else {
      updateDate(minimumEndDate.valueOf());
      enqueueSnackbar(
        'The end date is automatically corrected to ensure it is 30 minutes after the start time',
        {
          variant: 'info',
        },
      );
    }
    setUpdateForRerender((r) => r + 1);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open PaperProps={{ sx: { overflowY: 'visible' } }}>
      <DialogTitle onClose={closeDialog}>{dialogName}</DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', overflowY: 'visible' }}
        key={`set-io-date-inline-${updateForRerender}`}
      >
        <Typography sx={{ mb: 16 }}>
          Enter the<Typography sx={{ textWeight: 'demi', mx: 4 }}>{dateLabel}</Typography> for the
          {selectionCount === 1 && ' insertion order'}
          <Typography sx={{ textWeight: 'demi', mx: 4 }}>
            {selectionCount === 1 ? getNameWithIcon() : selectionCount}
          </Typography>
          {selectionCount > 1 && 'insertion orders.'}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Row sx={{ mb: 16 }}>
            <Col xs={6}>
              <SingleDatePicker
                timezone={updatedTimezone?.label ? updatedTimezone : DEFAULT_ORGANIZATION_TIMEZONE}
                updateDate={handleSelectEndDate}
                date={selectedDate as number}
                label={dateLabel}
                placeholderText="Select End Date"
              />
            </Col>
            <Col sx={{ mt: 2 }} xs={6}>
              <Timezone label="Timezone" value={updatedTimezone} onChange={selectTimeZone} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {isEntireSelectionExpired ? (
                <Alert severity="info">
                  Once the IO becomes Active, the underlying campaign(s) will start serving.
                </Alert>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 8 }}>
                  <InfoCircle fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
                  <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
                    {getHelperText()}
                  </Typography>
                </Box>
              )}
            </Col>
          </Row>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isDateBeingSet}
          disabled={
            !updatedTimezone ||
            !selectedDate ||
            (endDate === selectedDate && timezone?.label === updatedTimezone?.label)
          }
          onClick={() =>
            onCompletion({
              timezone: updatedTimezone as OptionId<string>,
              date: selectedDate as number,
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
