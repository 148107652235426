import { logDataDogAction } from 'iqm-framework';
import { AxiosError, AxiosResponse } from 'axios';
import { WithResponse, WithTimestamps } from 'models/Response';
import {
  getActionNamespace,
  getApiErrorMsg,
  getErrorResponseLatency,
  getResponseLatency,
} from '../helpers';

const SCOPE = 'ioDetails';

interface TSetCampaignPriorityParams {
  type: 'inline' | 'bulk';
  count?: number;
  response?: AxiosResponse<WithTimestamps<WithResponse<string>>>;
  error?: AxiosError<WithTimestamps<WithResponse<string>>>;
}

const setCampaignPriority = (params: TSetCampaignPriorityParams) => {
  const { type, count, response, error } = params;

  let responseInfo = {};
  if (response) {
    responseInfo = {
      statusCode: response.status,
      success: true,
      latency: getResponseLatency(response),
    };
  }
  if (error) {
    responseInfo = {
      statusCode: error.response?.status,
      success: false,
      errorMsg: getApiErrorMsg(error.response, true),
      latency: getErrorResponseLatency(error),
    };
  }

  logDataDogAction('Set Campaign Priority', {
    action: `${getActionNamespace()}/${SCOPE}/setCampaignPriority`,
    ...responseInfo,
    info: {
      type,
      count: type === 'bulk' ? count : undefined,
    },
  });
};

const removeCampaignPrioritiesForIOEndDate = () => {
  logDataDogAction('Remove IO End Date and Campaign Priorities', {
    action: `${getActionNamespace()}/confirmIOEndDatePriorityRemoval`,
  });
};

export interface CreateIoParams {
  actionType: 'startNow' | 'default';
}

const createIO = ({ actionType }: CreateIoParams) => {
  logDataDogAction('Create Insertion Order', {
    action: `${getActionNamespace()}/createInsertionOrder`,
    actionType,
  });
};

const openCreateIODialog = () => {
  logDataDogAction('Open Create Insertion Order Dialog');
};

export const IODetailsPage = {
  viewCreativesByCampaign: () => {
    logDataDogAction('Open Creative List Dialog', {
      action: `${getActionNamespace()}/viewCreativesByCampaignDialog`,
    });
  },
  setCampaignPriority,
  removeCampaignPrioritiesForIOEndDate,
  createIO,
  openCreateIODialog,
};
