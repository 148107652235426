import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { IoCurrency, IoImpression } from '@applift/icons';

import { Box, ButtonBase, Tooltip, Typography } from '@applift/factor';
import { GridActionCellParams } from '@applift/datagrid';

import { IoListType } from 'models/IoList';
import { BUDGET_TYPE_ID } from 'constants/apps';

interface IoNameCellType {
  action?: (args: GridActionCellParams) => any;
  rowData?: IoListType;
}

export const IoNameCell = ({ action, rowData }: IoNameCellType) => {
  const history = useHistory();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 100 }}>
      <Tooltip title="Click to view Insertion order details" arrow placement="top">
        <ButtonBase
          centerRipple={false}
          disableRipple
          onClick={() => {
            history.push(`io-detail/${rowData?.ioId}`);
          }}
          sx={{ width: 100, display: 'flex', justifyContent: 'start' }}
        >
          {rowData?.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED && (
            <IoCurrency color="primary" fontSize={24} sx={{ marginRight: 8 }} />
          )}
          {rowData?.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
            <IoImpression color="primary" fontSize={24} sx={{ marginRight: 8 }} />
          )}
          <Typography color="primary" sx={{ textColor: 'primary-600' }} noWrap>
            {rowData?.ioName}
          </Typography>
        </ButtonBase>
      </Tooltip>
    </Box>
  );
};
