import * as React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  SelectAdvanceComponents,
  TextField,
  Typography,
  enqueueSnackbar,
  useDebounceValue,
} from '@applift/factor';
import { AppState } from 'models/Store';
import { AuthService, ORIGIN_URL, getPathNumber } from 'iqm-framework';
import { RowSelectionState } from '@applift/datagrid';
import { Add } from '@applift/icons';
import { BlockWiseErrorType, ErrorStateForComponentType, StoreFilter } from 'models/ZustandStore';
import { useStore } from 'zustandStore';
import { ORGANIZATION_APP_NAME } from 'constants/apps';
import { usePoliticalAdvertiserList } from 'hooks/useCampaign';
import { App } from 'models/App';
import { BlockWrapper } from '../BlockWrapper';

export interface CountryListResponseType {
  id?: number;
  value?: number;
  name?: string;
  shortName?: string;
  abbreviation?: string;
  label?: string;
}

export interface AdvertiserResponseType {
  advertiserName: string;
  website: string;
  chiefName: string;
  contactNumber: string;
  address: string;
  id: number;
  legalInfo: string;
  links: string[];
}

export interface PoliticalTargetingBlockType {
  owId: number;
}

export const PoliticalTargetingBlockComponent = (props: PoliticalTargetingBlockType) => {
  const { owId } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    Object.keys({}).reduce((prev, one) => {
      // eslint-disable-next-line no-param-reassign
      prev[one] = true;
      return prev;
    }, {} as RowSelectionState),
  );
  const [hasOrganizationApp, setHasOrganizationApp] = React.useState(false);
  const [politicalAdvSearch, setPoliticalAdvSearch] = React.useState<string>();

  const initialTotalAdvertisers = React.useRef<number>(0);

  const open = Boolean(anchorEl);

  const handleTextfieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const advListRef = React.useRef();

  const renderItem = SelectAdvanceComponents.DefaultListOptionItemWrapper<AdvertiserResponseType>({
    disableCheckbox: true,
    selectionStyle: 'none',
    arrowPosition: 'right',
  });

  const debouncePoliticalAdvertiserSearch = useDebounceValue(politicalAdvSearch, 500);

  const { data: politicalAdvertiserListResponse, isLoading: isPoliticalAdvertiserListLoading } =
    usePoliticalAdvertiserList({
      owId,
      search: debouncePoliticalAdvertiserSearch,
    });

  const politicalAdvertiserList = React.useMemo(
    () => politicalAdvertiserListResponse?.data?.data ?? [],
    [politicalAdvertiserListResponse?.data?.data],
  );

  const [
    isPoliticalCampaign,
    politicalAdvertiserClientId,
    setPoliticalAdvertiserClientId,
    setIsPoliticalCampaign,
    resetLocationBlockStore,
    isEditMode,
    politicalAdvertiserBlockError,
    resetError,
    resetCampaignPoliticalAdvertiserStore,
  ] = useStore((state: StoreFilter) => [
    state.politicalAdvertiser.isPoliticalCampaign,
    state.politicalAdvertiser.politicalAdvertiserClientId,
    state.politicalAdvertiser.setPoliticalAdvertiserClientId,
    state.politicalAdvertiser.setIsPoliticalCampaign,
    state.locationBlock.resetLocationBlockStore,
    state.pgCampaignPage.isEditMode,
    state.errorFields.politicalAdvertiserBlock,
    state.errorFields.resetError,
    state.politicalAdvertiser.resetCampaignPoliticalAdvertiserStore,
  ]);

  const handleCountrySelectWrapper = (updater: any) => {
    const selection = updater();
    setPoliticalAdvertiserClientId(
      politicalAdvertiserList?.filter(
        (val: { id: number }) => val?.id === +Object.keys(selection)[0],
      )[0]?.id || politicalAdvertiserClientId,
    );
    setRowSelection(selection);
    setPoliticalAdvSearch(undefined);
  };

  const errorState: ErrorStateForComponentType = React.useMemo(
    () =>
      politicalAdvertiserBlockError
        ? Object.fromEntries(
            politicalAdvertiserBlockError?.map((val: BlockWiseErrorType) => [val.field, val]),
          )
        : {},
    [politicalAdvertiserBlockError],
  ) as ErrorStateForComponentType;

  React.useEffect(() => {
    if (
      (politicalAdvertiserClientId || !isPoliticalCampaign) &&
      errorState?.politicalAdvertiser?.field
    ) {
      resetError({
        blockName: 'politicalAdvertiserBlock',
        field: 'politicalAdvertiser',
      });
    }
  }, [
    errorState?.politicalAdvertiser?.field,
    isPoliticalCampaign,
    politicalAdvertiserClientId,
    resetError,
  ]);

  React.useEffect(() => {
    if (
      politicalAdvertiserClientId &&
      Object.keys(rowSelection).length === 0 &&
      politicalAdvertiserClientId !== Number(Object.keys(rowSelection)[0])
    ) {
      setRowSelection({ [politicalAdvertiserClientId]: true });
    }
  }, [politicalAdvertiserClientId, rowSelection]);

  const createNewAdvertiser = () => {
    window.open(
      `${ORIGIN_URL}/organization/u/${getPathNumber()}#/profile/advertiser-profile-settings`,
      '_blank',
    );
  };

  React.useEffect(() => {
    if (initialTotalAdvertisers.current === 0) {
      initialTotalAdvertisers.current = politicalAdvertiserList.length;
    }
  }, [politicalAdvertiserList.length]);

  const overlaySelect = React.useMemo(() => {
    if (!isPoliticalAdvertiserListLoading) {
      if (!Array.isArray(politicalAdvertiserList)) {
        return 'error';
      }
      if ((politicalAdvertiserList?.length ?? 0) <= 0) {
        if (politicalAdvSearch) {
          return 'noResult';
        }
        return 'noRows';
      }
    }
    return undefined;
  }, [isPoliticalAdvertiserListLoading, politicalAdvSearch, politicalAdvertiserList]);

  React.useEffect(() => {
    if (!isEditMode) {
      resetLocationBlockStore();
    }
  }, [isEditMode, resetLocationBlockStore]);

  React.useEffect(() => {
    AuthService.getUserApps()
      .then((res: any[]) => {
        if (res?.some((app: App) => app.appName === ORGANIZATION_APP_NAME)) {
          setHasOrganizationApp(true);
        }
      })
      .catch((e: Error) => {
        enqueueSnackbar('Something went wrong. Please try again over a few minutes.', {
          variant: 'error',
        });
      });
  }, []);

  React.useEffect(() => {
    if (!isEditMode) {
      resetCampaignPoliticalAdvertiserStore();
    }
  }, [isEditMode, resetCampaignPoliticalAdvertiserStore]);

  React.useEffect(() => {
    if (politicalAdvertiserList.length && !advListRef.current) {
      advListRef.current = politicalAdvertiserList;
    }
  }, [politicalAdvertiserList]);

  return (
    <Box>
      {isPoliticalCampaign ? (
        <BlockWrapper
          title="Political Advertiser Details"
          expanded
          id="politicalAdvertiserBlock"
          showExpandIcon={false}
        >
          <Box sx={{ px: 8, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="bodyMedium">
              We'll use this information to disclose on the webpage linked to Political Ad Icon & Ad
              Marker over the ad(s) for this Campaign as required by{' '}
              <Link
                Component="a"
                href="http://digitaladvertisingalliance.org/sites/politic/files/DAA_files/PA_Icon_AdMarker_Creative_Guidelines_V1_September_2018.pdf"
                // @ts-ignore
                target="_blank"
              >
                Creative Guidelines by Digital Advertising Alliance
              </Link>{' '}
              for political advertising. You can find your info in{' '}
              <Link
                Component="a"
                href="https://www.fec.gov/data/browse-data/"
                // @ts-ignore
                target="_blank"
              >
                Federal Election Commission database.
              </Link>
            </Typography>

            <Box>
              {!hasOrganizationApp ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="bodySmall">
                    No advertiser profiles found. Please contact your organization admin to gain
                    access.
                  </Typography>
                  {errorState?.politicalAdvertiser?.errorText ? (
                    <FormLabel sx={{ textColor: 'danger-400' }}>
                      {errorState?.politicalAdvertiser?.errorText}
                    </FormLabel>
                  ) : null}
                </Box>
              ) : null}
            </Box>
            {hasOrganizationApp && (
              <Box>
                {initialTotalAdvertisers.current === 0 ? (
                  <Box>
                    <Typography variant="bodySmall">
                      There are no advertisers present currently.
                    </Typography>
                    {errorState?.politicalAdvertiser?.errorText ? (
                      <FormLabel sx={{ textColor: 'danger-400' }}>
                        {errorState?.politicalAdvertiser?.errorText}
                      </FormLabel>
                    ) : null}
                  </Box>
                ) : (
                  <Box sx={{ mt: 24, display: 'flex', flexDirection: 'column' }}>
                    <TextField
                      size="medium"
                      variant="outlinedDash"
                      label="Political Advertiser"
                      select="advanceSelect"
                      placeholder="Select Political Advertiser..."
                      onClick={(e) => handleTextfieldClick(e)}
                      onChange={handleCountrySelectWrapper}
                      value={Object.keys(rowSelection).length ? rowSelection : undefined}
                      style={{ width: '320px' }}
                      SelectProps={{
                        error: Boolean(errorState?.politicalAdvertiser?.field),
                        multiple: false,
                        rowCount: isPoliticalAdvertiserListLoading ? 10 : 0,
                        getRowId: (row) => String(row.id),
                        getOptionLabel: (data) => String(data.id),
                        data: politicalAdvertiserList ?? [],
                        placeholder: 'Select Political Advertiser...',
                        overlay: overlaySelect,
                        slotProps: {
                          InputBaseProps: {
                            onChange: (e) => setPoliticalAdvSearch(e.target.value),
                            value: politicalAdvSearch,
                            placeholder: 'Search',
                          },
                          PopperProps: {
                            anchorEl,
                            open,
                            anchorOrigin: {
                              horizontal: 'right',
                              vertical: 'bottom',
                            },
                            style: { height: '220px' },
                            transformOrigin: { vertical: 'top', horizontal: 'right' },
                          },
                        },
                        renderListItem: renderItem,
                        renderOption: ({ row }) => (
                          <Typography>{row.original?.advertiserName}</Typography>
                        ),
                        renderValue: (val) => {
                          // @ts-ignore
                          if (!Object.keys(val?.[0])?.length) {
                            const currentSelectedCountryObj =
                              // @ts-ignore
                              advListRef.current?.filter(
                                // @ts-ignore
                                (val) => rowSelection[val.id],
                              )[0];
                            return (
                              <Typography>
                                {currentSelectedCountryObj?.advertiserName as string}
                              </Typography>
                            );
                          }
                          // @ts-ignore
                          return <Typography>{val[0]?.advertiserName as string}</Typography>;
                        },
                      }}
                    />
                    {errorState?.politicalAdvertiser?.errorText ? (
                      <Typography variant="label" sx={{ textColor: 'danger-400' }}>
                        {errorState?.politicalAdvertiser?.errorText}
                      </Typography>
                    ) : null}
                  </Box>
                )}
              </Box>
            )}
            <Box sx={{ display: 'inline-block', mt: 32 }}>
              <Button
                onClick={createNewAdvertiser}
                id="politicalTargetingBlock"
                startIcon={<Add />}
                // @ts-ignore
                target="_blank"
              >
                Add New
              </Button>
            </Box>
          </Box>
        </BlockWrapper>
      ) : null}
      <Box>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  setIsPoliticalCampaign(!isPoliticalCampaign);
                  if (isPoliticalCampaign && politicalAdvertiserClientId && isEditMode) {
                    setPoliticalAdvertiserClientId(0);
                  }
                  setTimeout(
                    () =>
                      document
                        .getElementById('politicalTargetingBlock')
                        ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' }),
                    0,
                  );
                }}
                checked={Boolean(isPoliticalCampaign)}
                value="advanced"
              />
            }
            label="This is a political campaign"
          />
        </FormControl>
      </Box>
    </Box>
  );
};

const mapState = (state: AppState) => ({
  owId: state.auth.userData.owId,
});

export const PoliticalTargetingBlock = connect(mapState)(PoliticalTargetingBlockComponent);
