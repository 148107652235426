import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { creativeGroupsConstants } from './constants';
import { Option } from '../../models/Option';

export type TCreativeIdsByCreativeGroupId = {
  [key: string]: Array<number>;
};

export interface CreativeGroupsState {
  creativeGroups: Option<number>[];
}

const defaultCreativesState: CreativeGroupsState = {
  creativeGroups: [],
};

function setCreativeGroups(
  state: CreativeGroupsState,
  action: Action<CreativeGroupsState>,
): CreativeGroupsState {
  return {
    ...state,
    creativeGroups: action.payload.creativeGroups,
  };
}

const reducer = reducerFromMap<CreativeGroupsState>(defaultCreativesState, {
  [creativeGroupsConstants.SET_CREATIVE_GROUPS]: setCreativeGroups,
});

export const creativeGroups = (
  state: CreativeGroupsState = defaultCreativesState,
  action: Action<any>,
) => reducer(state, action);
