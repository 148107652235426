import * as React from 'react';
import { Icon } from 'factor';
import { Box } from '@applift/factor';
import { Creative } from 'models/Creative';
import { useStore } from 'zustandStore';
import { API } from 'api';
import get from 'lodash/get';
import Overlay from 'components/Overlay';
import { isLocalhost } from 'utils/helpers';
import { onLogOut } from 'api/Instance';
import { User } from 'models/User';
import { getPathNumber } from 'iqm-framework';
import { connect } from 'react-redux';
import { AppState } from 'models/Store';
import { StoreFilter } from 'models/ZustandStore';

import styles from './styles.module.scss';

export interface CreateCreativeBlockComponentPropTypes {
  user: User;
  selectedCreativeType: number | null;
  isExistingCreative: boolean;
  setCreativeSelectDialogType?: (arg: 'select' | 'create' | '') => void;
}
const CREATIVES_URL = '/creatives';

const CreateCreativeBlockComponent = (props: CreateCreativeBlockComponentPropTypes) => {
  const { user, setCreativeSelectDialogType, selectedCreativeType, isExistingCreative } = props;

  const [selectedCreatives, setSelectedCreatives] = useStore((state: StoreFilter) => [
    state.creativesBlock.selectedCreatives,
    state.creativesBlock.setSelectedCreatives,
  ]);

  const creativesLoading = React.useCallback(
    async (creativeIds: string) => {
      if (creativeIds) {
        const { responseObject } = await API.Creatives.FetchCreativeList({
          creativeIds,
          creativeTypeIds: '',
          noOfEntries: 99999,
          pageNo: 1,
          searchField: '',
        });
        const creatives: Creative[] = get(responseObject, 'data', []);
        if (creatives.length) {
          setSelectedCreatives([...selectedCreatives, ...creatives]);
        }
      }
    },
    [selectedCreatives, setSelectedCreatives],
  );

  const getCreativesUrl = (user: User, selectedCreativeType: number | null) => {
    const origin = isLocalhost()
      ? 'http://localhost:8000'
      : `${CREATIVES_URL}/u/${getPathNumber()}`;

    return `${origin}/#/?action=${
      isExistingCreative ? 'isExistingCreative' : 'newCreative'
    }&payload=${btoa(
      JSON.stringify({ type: selectedCreativeType, minimizedView: true }).replace(
        /[\u00A0-\u2666]/g,
        '',
      ),
    )}`;
  };

  const [loaded, setLoaded] = React.useState(false);
  const [creativeLoading, setCreativeLoading] = React.useState(false);
  const [allowMount, setAllowMount] = React.useState(true);
  const iframeRef = React.createRef<HTMLIFrameElement>();
  const iframeRefWithEventHandler = React.useRef<HTMLIFrameElement | null>(null);
  // eslint-disable-next-line
  const [createdCreativeIds, setCreatedCreativeIds] = React.useState<string>('');

  const closeDialog = React.useCallback(
    () => setCreativeSelectDialogType && setCreativeSelectDialogType(''),
    [setCreativeSelectDialogType],
  );

  const receiveMessage = React.useCallback(
    (event: any) => {
      const shouldHandleMessage =
        event.data && event.data.startsWith && event.data.startsWith('{"type":"CREATIVES');
      if (shouldHandleMessage) {
        const data: { type: string; creativeIds?: string } = JSON.parse(event.data);
        if (data.type === 'CREATIVES_CLOSE') {
          setAllowMount(false);
          closeDialog();
        } else if (data.creativeIds?.length) {
          setCreatedCreativeIds(data.creativeIds);
          creativesLoading(data.creativeIds);
          closeDialog();
        } else if (data.type === 'CREATIVES_LOADING') {
          setCreativeLoading(true);
        } else if (data.type === 'CREATIVES_LOADED') {
          setCreativeLoading(false);
          closeDialog();
        } else if (data.type === 'CREATIVES_CLOSE_FOR_AUTH') {
          setAllowMount(false);
          onLogOut();
        }
      }
    },
    [closeDialog, creativesLoading],
  );

  React.useEffect(() => {
    const handleHashChange = () => {
      if (!isLocalhost()) {
        const loginHash = /^\/?#\/login\/?$/;

        if (loginHash.test(iframeRef.current?.contentWindow?.location.hash || '')) {
          setAllowMount(false);
          onLogOut();
        }
      }
    };

    setTimeout(() => {
      const origin = window.location.origin || 'http://localhost:8000';
      if (iframeRef.current && iframeRef.current.contentWindow && loaded) {
        window.addEventListener('message', receiveMessage);
        if (!isLocalhost()) {
          iframeRefWithEventHandler.current = iframeRef.current;
          iframeRef.current.contentWindow.addEventListener('hashchange', handleHashChange);
        }
        iframeRef.current.contentWindow.postMessage('OPEN_FROM_CAMPAIGNS', origin);
      }
    }, 1000);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
      if (!isLocalhost() && iframeRefWithEventHandler.current) {
        iframeRefWithEventHandler.current?.contentWindow?.removeEventListener(
          'hashchange',
          handleHashChange,
        );
      }
      window.removeEventListener('message', receiveMessage);
    };
  }, [iframeRef, loaded, receiveMessage]);

  const handleOverlayClose = () => {
    setAllowMount(false);
    if (setCreativeSelectDialogType) {
      setCreativeSelectDialogType('');
    }
    closeDialog();
  };

  if (!allowMount) {
    return null;
  }

  return (
    <Box sx={{ width: 100 }}>
      <Overlay onClose={handleOverlayClose} title="Add Creatives To Campaign">
        <div className={styles.container}>
          <span className="d-none">{creativeLoading}</span>
          {!loaded && (
            <div className="align-items-center d-flex flex-column h-100 justify-content-center">
              <div className="generate-progress">
                <div className="generate-progress__image">
                  <Icon name="GenerateProgress" />
                </div>
                <div className="generate-progress__bar _animated">
                  <div className="generate-progress__bar-stripped" />
                </div>
                <div className="generate-progress__value">
                  Please wait
                  <span className="generate-progress__value-dotted" />
                </div>
              </div>
            </div>
          )}
          <iframe
            ref={iframeRef}
            src={getCreativesUrl(user, selectedCreativeType)}
            frameBorder="0"
            title="new campaign"
            onLoad={() => setLoaded(true)}
            style={{
              display: loaded ? 'revert' : 'none',
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              margin: 0,
              padding: 0,
              zIndex: 10,
            }}
          />
        </div>
      </Overlay>
    </Box>
  );
};

const mapActions = (state: AppState) => ({
  user: state.auth.userData,
});

export const CreateCreativeBlock = connect(mapActions)(CreateCreativeBlockComponent);
