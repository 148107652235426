import React from 'react';

import styles from './ProgressContent.module.scss';
import { ReactComponent as RocketImage } from '../../../../svg/rocket.svg';

export const ProgressContent = () => {
  return (
    <div data-qa="73" className={`generate-progress ${styles.container}`}>
      <div data-qa="74" className={styles.image}>
        <RocketImage />
      </div>
      <div data-qa="75" className={styles.wrapper}>
        <div data-qa="76" className={`generate-progress__value ${styles.value}`}>
          Saving the campaign. Please wait
          <span data-qa="77" className="generate-progress__value-dotted" />
        </div>
        <div data-qa="78" className="generate-progress__bar _animated">
          <div data-qa="79" className="generate-progress__bar-stripped" />
        </div>
      </div>
    </div>
  );
};
