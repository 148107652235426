import * as React from 'react';
import { FilterDollar } from '@applift/icons';
import { Box, Typography } from '@applift/factor';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { PG_CAMPAIGN_COMPONENTS } from 'constants/pgCampaign';
import { ConversionType } from 'models/Conversion';
import { BlockWrapper } from '../BlockWrapper';
import { ConversionBlockContents } from './ConversionBlockContents';

export const ConversionBlock = () => {
  const [
    selectedConversions,
    setSelectedConversions,
    conversionType,
    expandedBlocks,
    setExpandedBlocks,
  ] = useStore((state: StoreFilter) => [
    state.conversionBlock.selectedConversions,
    state.conversionBlock.setSelectedConversions,
    state.conversionBlock.conversionType,
    state.pgCampaignPage.expandedBlocks,
    state.pgCampaignPage.setExpandedBlocks,
  ]);

  const onBlockAccordionChange = () => {
    if (expandedBlocks.includes('Conversion')) {
      setExpandedBlocks(
        expandedBlocks.filter(
          (val: 'Creatives' | 'Location' | 'Inventory' | 'Conversion') => val !== 'Conversion',
        ),
      );
    } else {
      setExpandedBlocks(['Conversion']);
    }
  };

  return (
    <BlockWrapper
      id={PG_CAMPAIGN_COMPONENTS.conversionBlock}
      title="Conversion"
      icon={<FilterDollar fontSize={24} color="primary" />}
      expanded={!!expandedBlocks.includes('Conversion')}
      onChange={onBlockAccordionChange}
      blockSummary={
        selectedConversions?.length && conversionType ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 8 }}>
            <Typography variant="bodySmall" gutterBottom={false}>
              Conversions: <Typography weight="demi">{selectedConversions.length}</Typography>
            </Typography>
            <Typography variant="bodySmall" gutterBottom={false}>
              Conversion Type:{' '}
              <Typography weight="demi">
                {conversionType === ConversionType.POSTBACK ? 'Postback' : 'Pixel'}
              </Typography>
            </Typography>
          </Box>
        ) : undefined
      }
    >
      <ConversionBlockContents
        initTableConversionIds={selectedConversions}
        setInitTableConversionIds={setSelectedConversions}
      />
    </BlockWrapper>
  );
};
