import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { getPathNumber, AuthService } from 'iqm-framework';
import { Select, Button, ProgressCircle } from 'factor';

import { ORIGIN_URL } from 'config';
import { checkAdvertiser } from 'store/app/helpers';
import { AdvertiserState } from 'store/advertiser/reducer';
import { Block, blockStyles } from '../../../../../components/Block';
import { AppState } from '../../../../../models/Store';
import { App } from '../../../../../models/App';
import {
  advertiserActions,
  SetAdvertiserField,
  ChangePoliticalAdvertiser,
  GetAdvertiserData,
  ChangeCreatingMode,
} from '../../../../../store/advertiser/actions';
import { toastActions, Open } from '../../../../../store/toast/actions';
import { PoliticalAdvertiser } from '../../../../../api/Advertiser';
import { Option } from '../../../../../models/Option';
import { ExistingCampaignData } from '../../../../../models/ExistingCampaign';
import { ORGANIZATION_APP_NAME } from '../../../../../constants/apps';

import styles from './styles.module.scss';

type Props = SetAdvertiserField &
  ChangePoliticalAdvertiser &
  GetAdvertiserData &
  ChangeCreatingMode & {
    politicalAdvertiser: PoliticalAdvertiser | null;
    politicalAdvertiserList: PoliticalAdvertiser[];
    editableCampaign: ExistingCampaignData | null;
    openToast: Open['open'];
    advertiser: AdvertiserState;
    submitted: boolean;
  };

const AdvertiserDetailsComponent = (props: Props) => {
  const {
    politicalAdvertiser,
    politicalAdvertiserList,
    changePoliticalAdvertiser,
    editableCampaign,
    getAdvertiserData,
    openToast,
    advertiser,
    submitted,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasOrganizationApp, setHasOrganizationApp] = useState(false);
  const [isLoadingApps, setIsLoadingApps] = useState(false);

  useEffect(() => {
    if (editableCampaign) {
      const foundAdvertiser = politicalAdvertiserList.find(
        (i) => i.id === editableCampaign.politicalAdvertiserClientId,
      );
      if (foundAdvertiser) {
        changePoliticalAdvertiser(foundAdvertiser);
      }
    }
  }, [editableCampaign, politicalAdvertiserList, changePoliticalAdvertiser]);

  const findSelectedAdvertiserHandler = (): Option | null => {
    if (politicalAdvertiser) {
      const foundedPoliticalAdvertiser = politicalAdvertiserList.find(
        (i) => i.id === politicalAdvertiser.id,
      );
      return foundedPoliticalAdvertiser
        ? { label: foundedPoliticalAdvertiser.advertiserName, value: foundedPoliticalAdvertiser.id }
        : null;
    }
    return null;
  };

  const changeAdvertiserHandler = (data: Option) => {
    const foundedPoliticalAdvertiser = politicalAdvertiserList.find((i) => i.id === data.value);
    if (foundedPoliticalAdvertiser) {
      changePoliticalAdvertiser(foundedPoliticalAdvertiser);
    }
  };

  const createNewAdvertiser = () => {
    window.open(
      `${ORIGIN_URL}/organization/u/${getPathNumber()}#/profile/advertiser-profile-settings`,
      '_blank',
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getAdvertiserData();
    setIsLoading(false);
  }, [getAdvertiserData]);

  useEffect(() => {
    setIsLoadingApps(true);
    AuthService.getUserApps()
      .then((res: any[]) => {
        if (res?.some((app: App) => app.appName === ORGANIZATION_APP_NAME)) {
          setHasOrganizationApp(true);
        }
        setIsLoadingApps(false);
      })
      .catch((e: Error) => {
        openToast('Something went wrong. Please try again over a few minutes.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDataValid = useMemo(() => checkAdvertiser(advertiser), [advertiser]);
  const showAdvertiserSelect = politicalAdvertiserList.length && !isLoadingApps;
  const showAddNewButton = hasOrganizationApp && !isLoadingApps;

  const getErrorText = () => {
    const mb = showAddNewButton ? 'mb-1' : 'mb-4';
    const mt = !isDataValid && submitted && showAdvertiserSelect ? 'mt-2' : '';
    const text = showAdvertiserSelect
      ? 'Please select Political Advertiser'
      : 'Political Advertiser selection is required';
    return (
      <div
        className={`${styles.error} ${mt} ${
          !isDataValid && !showAdvertiserSelect && submitted ? mb : ''
        }`}
        data-not-valid={!isDataValid}
      >
        {submitted && !isDataValid ? text : ''}
      </div>
    );
  };

  const renderNoAdvList = () => {
    if (isLoading || isLoadingApps) {
      return <ProgressCircle size={40} />;
    }

    return (
      <div className={`${blockStyles.blockSubtitle} ${submitted && !isDataValid ? 'mb-1' : ''}`}>
        There are no advertisers present currently.
        {!hasOrganizationApp
          ? ' No advertiser profiles found. Please contact your organization admin to gain access.'
          : ''}
      </div>
    );
  };

  return (
    <Block>
      <div data-qa="334" className={blockStyles.blockHeader}>
        <div data-qa="335" className={blockStyles.blockTitle}>
          <h3 data-qa="336">Political Advertiser Details</h3>
        </div>
      </div>
      <p data-qa="337" className={blockStyles.blockSubtitle}>
        We&apos;ll use this information to disclose on the webpage linked to Political Ad Icon & Ad
        Marker over the ad(s) for this Campaign as required by{' '}
        <a
          data-qa="338"
          href="http://digitaladvertisingalliance.org/sites/politic/files/DAA_files/PA_Icon_AdMarker_Creative_Guidelines_V1_September_2018.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Creative Guidelines by Digital Advertising Alliance
        </a>{' '}
        for political advertising. You can find your info in{' '}
        <a
          data-qa="339"
          href="https://www.fec.gov/data/browse-data/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Federal Election Commission database.
        </a>
      </p>
      {showAdvertiserSelect ? (
        <div
          data-qa="340"
          className={`row align-items-center ${blockStyles.blockRow} ${
            submitted && !isDataValid ? styles.errorWrapper : ''
          }`}
        >
          <div data-qa="341" className="col-4 mb-3">
            <Select
              onChange={changeAdvertiserHandler}
              isSearchable
              label="Political Advertisers"
              placeholder="Select Political Advertiser..."
              options={politicalAdvertiserList.map((i) => ({
                label: i.advertiserName,
                value: i.id,
              }))}
              value={findSelectedAdvertiserHandler()}
              tooltipParams={{
                label: 'Political Advertisers',
                auto: false,
                position: 'right',
              }}
            />
            {getErrorText()}
          </div>
        </div>
      ) : (
        renderNoAdvList()
      )}

      {showAddNewButton && (
        <div
          data-qa="353"
          className={`mt-4 d-flex ${blockStyles.row} ${
            !showAdvertiserSelect && submitted && !isDataValid ? 'mb-2' : ''
          }`}
        >
          <Button className="btn-square _conflower-blue _filled _md" onClick={createNewAdvertiser}>
            <span data-qa="354" className="btn-square__prefix">
              +
            </span>
            Add New
          </Button>
        </div>
      )}
      {!showAdvertiserSelect && getErrorText()}
    </Block>
  );
};

const mapState = (state: AppState) => ({
  politicalAdvertiser: state.advertiser.politicalAdvertiser,
  politicalAdvertiserList: state.advertiser.politicalAdvertiserList,
  editableCampaign: state.app.editableCampaign,
  advertiser: state.advertiser,
  submitted: state.app.submitted,
});

const mapAction = {
  setAdvertiserField: advertiserActions.setAdvertiserField,
  changePoliticalAdvertiser: advertiserActions.changePoliticalAdvertiser,
  getAdvertiserData: advertiserActions.getAdvertiserData,
  changeCreatingMode: advertiserActions.changeCreatingMode,
  openToast: toastActions.open,
};

export const AdvertiserDetails = connect(mapState, mapAction)(AdvertiserDetailsComponent);
