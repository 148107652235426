import React from 'react';

import { DataGrid, GridActionCellParams, SortingState, getSortedRowModel } from '@applift/datagrid';
import { Box, Typography, sx } from '@applift/factor';
import { PmpDeal } from 'models/Inventory';
import { colDef } from './colDef';

type Props = {
  data: PmpDeal[];
  loading?: boolean;
  setData: React.Dispatch<React.SetStateAction<PmpDeal[]>>;
};

export const ViewPmpDealTable = (props: Props) => {
  const { data, setData, loading } = props;

  const actions = {
    removeRow: ({ rowId }: { rowId: number }) => {
      setData((prev) => prev?.filter((grp) => grp.id !== rowId));
    },
  };

  const onAction = (params: GridActionCellParams) => {
    actions[params.actionName as keyof typeof actions](params.metaData);
  };

  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'created', desc: true }]);

  return (
    <Box style={{ height: 240 }} sx={{ display: 'flex', flexDirection: 'column', width: 100 }}>
      <Typography weight="demi" variant="bodyMedium" sx={{ textColor: 'neutral-500' }}>
        PMP Deals {!loading ? `(${data.length ?? 0})` : ''}
      </Typography>
      <DataGrid
        data={data}
        columns={colDef}
        rowHeight={40}
        headerHeight={40}
        hideHeader
        hideFooter
        showCellRightBorder
        showColumnRightBorder
        disableRowSelectionOnClick
        loading={loading}
        onAction={onAction}
        state={{ columnPinning: { right: ['actions'] }, sorting }}
        onSortingChange={setSorting}
        getSortedRowModel={getSortedRowModel()}
        classes={{
          root: sx({
            borderRadius: 0,
            borderTop: 1,
            borderLeft: 1,
            borderRight: 1,
            borderBottom: 1,
            borderTopColor: 'neutral-300',
          }),
        }}
      />
    </Box>
  );
};
