import * as React from 'react';
import {
  Box,
  Col,
  LinearProgress,
  LinearProgressProps,
  ColorClassModifier,
  Row,
  Tooltip,
  Typography,
  sx,
} from '@applift/factor';
import moment from 'moment';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { IoStatusType } from 'models/IO';
import { CampaignStatusType } from 'models/CampaignList';
import { formatNumberWithDecimal } from 'utils/format';
import { colorMappingForPacing } from 'constants/tableCell';
import { useTimezone } from 'hooks/useTimezone';

interface PacingCellWithTimeType {
  budgetCompletionPercentage: number;
  actualSpent: number;
  expectedSpent: number;
  campaignDuration: number;
  remainingDuration: number;
  budgetTypeId: number;
  /**
   * should be in milliseconds
   */
  startDate: number;
  /**
   * should be in milliseconds
   */
  endDate?: number | null;
  totalBudget: number;
  status: IoStatusType | CampaignStatusType;
  pacingOff?: boolean;
  timezoneId?: number;
}

export const PacingCellWithTime = ({
  budgetCompletionPercentage,
  actualSpent,
  expectedSpent,
  campaignDuration,
  remainingDuration,
  budgetTypeId,
  startDate,
  endDate,
  totalBudget,
  status,
  pacingOff = false,
  timezoneId,
}: PacingCellWithTimeType) => {
  const currentDate = React.useMemo(() => moment().valueOf(), []);

  const isStartDateInFuture = React.useMemo(() => {
    return startDate > currentDate;
  }, [startDate, currentDate]);

  const expectedSpentPercentage = React.useMemo(() => {
    const expectedSpentValue = expectedSpent || 1;
    return (actualSpent / expectedSpentValue) * 100;
  }, [actualSpent, expectedSpent]);

  const turnOffPaicngIndication = React.useMemo(
    () => isStartDateInFuture || !endDate || pacingOff,
    [isStartDateInFuture, endDate, pacingOff],
  );

  const { data: timezoneDate } = useTimezone();

  const timezoneName = timezoneDate?.filter((timezone) => timezone.id === timezoneId)[0]?.name;

  const getColor: () => LinearProgressProps['color'] = React.useCallback(() => {
    if (turnOffPaicngIndication) {
      return 'secondary';
    }
    if (expectedSpentPercentage < 60) return 'error';
    if (expectedSpentPercentage && expectedSpentPercentage < 90) return 'warning';
    if (expectedSpentPercentage && expectedSpentPercentage <= 120) return 'success';
    return 'secondary';
  }, [expectedSpentPercentage, turnOffPaicngIndication]);

  const daySpent = React.useMemo(() => {
    return ((campaignDuration - remainingDuration) / campaignDuration) * 100;
  }, [campaignDuration, remainingDuration]);

  const spentColor: ColorClassModifier = React.useMemo(() => {
    if (expectedSpentPercentage > 120 && !turnOffPaicngIndication) {
      return 'purple-500';
    }
    return colorMappingForPacing[`${getColor()}`];
  }, [expectedSpentPercentage, getColor, turnOffPaicngIndication]);

  const compactNumber = (value: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      // @ts-ignore
      notation: 'compact',
    });

    return formatter.format(value);
  };

  const formatBudget = React.useCallback(
    (budget: number) => {
      return budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
        ? `Imps ${compactNumber(budget)}`
        : `$${compactNumber(budget)}`;
    },
    [budgetTypeId],
  );

  const timeSpentInDays = React.useMemo(() => {
    if (!endDate && !isStartDateInFuture) {
      // converting so hours of the day will not interfare in difference calculation
      const currtUTC = moment(currentDate)
        .tz(timezoneName || 'UTC')
        .startOf('D')
        .valueOf();
      const startUTC = moment(startDate)
        .tz(timezoneName || 'UTC')
        .startOf('D')
        .valueOf();
      const timeDiff = moment(currtUTC).diff(startUTC, 'days');
      return timeDiff;
    }
    return campaignDuration - remainingDuration;
  }, [
    campaignDuration,
    remainingDuration,
    currentDate,
    endDate,
    isStartDateInFuture,
    startDate,
    timezoneName,
  ]);

  const infoTooltipContent = () => {
    return (
      <Box sx={{ p: 12 }}>
        <Typography component="p" variant="label">
          <Typography component="span">{`Actual ${
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'budget spent' : 'impressions served'
          }: `}</Typography>
          <Typography component="span" weight="demi" sx={{ textColor: spentColor }}>
            {`${formatBudget(actualSpent)} / ${formatBudget(totalBudget)}`}
          </Typography>
        </Typography>
        <Typography component="p" variant="label">
          <Typography component="span">{`Actual ${
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'budget spent' : 'impressions served'
          } %: `}</Typography>
          <Typography component="span" weight="demi" sx={{ textColor: spentColor }}>
            {`${formatNumberWithDecimal(budgetCompletionPercentage)}%`}
          </Typography>
        </Typography>
        <Typography component="p" variant="label">
          <Typography component="span">{`Expected ${
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'budget spent' : 'impressions served'
          }: `}</Typography>
          <Typography component="span" weight="demi">
            {isStartDateInFuture || !endDate ? '-' : formatBudget(expectedSpent)}
          </Typography>
        </Typography>
        <Typography component="p" variant="label">
          <Typography component="span">{'Time spent: '}</Typography>
          <Typography component="span" weight="demi">
            {`${timeSpentInDays} days / ${campaignDuration ? `${campaignDuration} days` : '-'}`}
          </Typography>
        </Typography>
      </Box>
    );
  };

  const showTime = () => {
    if (status === 'expired') {
      return (
        <Typography variant="label" sx={{ textColor: 'neutral-400' }} noWrap>
          Expired
        </Typography>
      );
    }
    if (status === 'deleted') {
      return (
        <Typography variant="label" sx={{ textColor: 'neutral-400' }} noWrap>
          Deleted
        </Typography>
      );
    }
    if (!endDate) {
      return (
        <Typography variant="label" sx={{ textColor: 'neutral-400' }} noWrap>
          No end date
        </Typography>
      );
    }
    if (isStartDateInFuture) {
      return (
        <Typography variant="label" sx={{ textColor: 'neutral-400' }} noWrap>
          Not started
        </Typography>
      );
    }
    return (
      <Typography component="p" variant="label" sx={{ display: 'block' }}>
        {typeof remainingDuration === 'number' ? `${remainingDuration} days left` : ''}
      </Typography>
    );
  };

  return (
    <Tooltip classes={{ tooltip: sx({ p: 0 }) }} title={infoTooltipContent} placement="right" arrow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: 100,
        }}
      >
        <Row xs={{ gutterSize: 8, gutterDirection: 'x' }} sx={{ alignItems: 'center' }}>
          <Col xs={7}>
            <LinearProgress
              classes={
                expectedSpentPercentage > 120 && !turnOffPaicngIndication
                  ? { bar: sx({ bgColor: 'purple-400' }) }
                  : {}
              }
              variant="determinate"
              value={budgetCompletionPercentage <= 100 ? budgetCompletionPercentage : 100}
              sx={{ bgColor: 'neutral-200' }}
              thickness={6}
              color={expectedSpentPercentage >= 0 ? getColor() : undefined}
            />
          </Col>
          <Col xs={5}>
            <Typography component="p" variant="label" sx={{ textColor: spentColor }} noWrap>
              <Typography weight="demi">{`${formatNumberWithDecimal(
                budgetCompletionPercentage,
              )}% `}</Typography>
              <Typography>
                {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'spent' : 'served'}
              </Typography>
            </Typography>
          </Col>
        </Row>
        <Row xs={{ gutterSize: 8, gutterDirection: 'x' }} sx={{ alignItems: 'center' }}>
          <Col xs={7}>
            <LinearProgress
              variant="determinate"
              value={isStartDateInFuture || !endDate ? 0 : daySpent}
              color="secondary"
              sx={{ bgColor: 'neutral-200' }}
              thickness={6}
            />
          </Col>
          <Col xs={5}>{showTime()}</Col>
        </Row>
      </Box>
    </Tooltip>
  );
};
