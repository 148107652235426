/* global google */
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Rectangle, Marker } from '@react-google-maps/api';

import { RectangleExtended } from 'models/RectangleExtended';
import { AppState } from 'models/Store';
import {
  mapActions,
  PushSelectedAreaId,
  RemoveRectangleFromPreSaved,
  RemoveSelectedAreaId,
  SetToastMessage,
  UpdatePreSavedRectangle,
} from 'store/map/actions';
import { RadiusUnity } from 'models/PolygonExtended';
import { Open, toastActions } from 'store/toast/actions';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { Rectangle as TRectangle } from 'models/Google';
import { CloseMarker } from '../CloseMarker';
import { MapInfoBox } from '../MapInfoBox';

interface Props
  extends PushSelectedAreaId,
    RemoveSelectedAreaId,
    RemoveRectangleFromPreSaved,
    UpdatePreSavedRectangle,
    SetToastMessage {
  selectedAreasIds: string[];
  getRectangle: () => RectangleExtended;
  isMapReadOnly: boolean;
  openToast: Open['open'];
}

const MapRectangleComponent = ({
  selectedAreasIds,
  updatePreSavedRectangle,
  setToastMessage,
  removeRectangleFromPreSaved,
  removeSelectedAreaId,
  pushSelectedAreaId,
  openToast,
  getRectangle,
  isMapReadOnly,
}: Props) => {
  const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
  const [mapRectangle, setMapRectangle] = useState<TRectangle | null>(null);
  const rectangle = useMemo(() => getRectangle(), [getRectangle]);

  const toggleActivity = () => {
    if (selectedAreasIds.indexOf(rectangle.id) !== -1) {
      removeSelectedAreaId(rectangle.id);
    } else {
      pushSelectedAreaId(rectangle.id);
    }
  };

  const handleRemoveRectangle = () => {
    removeRectangleFromPreSaved(rectangle);
    removeSelectedAreaId(rectangle.id);
  };

  const onMouseOver = () => {
    setIsInfoBoxVisible(true);
  };

  const onMouseOut = () => {
    setIsInfoBoxVisible(false);
  };

  const onEdit = async () => {
    if (mapRectangle) {
      try {
        const newBounds = mapRectangle.getBounds();
        rectangle.setBounds(newBounds);
        updatePreSavedRectangle(rectangle);
      } catch (error) {
        if (window.self !== window.top) {
          setToastMessage(SOMETHING_WENT_WRONG);
        } else {
          openToast(SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  const isRectangleIncluded = (rectangle: RectangleExtended) => {
    return rectangle.isInclude;
  };

  const handleRectangleComponentLoad = (mapRectangle: TRectangle) => {
    setMapRectangle(mapRectangle);
  };

  const isActive = selectedAreasIds.indexOf(rectangle.id) !== -1;

  return (
    <>
      <Rectangle
        options={{
          fillColor: isRectangleIncluded(rectangle) ? 'green' : 'red',
          strokeColor: isActive ? '#1d58d7' : undefined,
          draggable: false,
          /* eslint-disable-next-line no-unneeded-ternary */
          editable: isMapReadOnly ? false : true,
          /* eslint-disable-next-line no-unneeded-ternary */
          clickable: isMapReadOnly ? false : true,
        }}
        bounds={rectangle.getBounds()}
        onClick={toggleActivity}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onLoad={handleRectangleComponentLoad}
        onBoundsChanged={onEdit}
      />
      <Marker position={rectangle.getCenter()} />
      {isActive && !isMapReadOnly && (
        <CloseMarker
          position={rectangle.getBounds().getNorthEast()}
          onClick={handleRemoveRectangle}
          anchor={new google.maps.Point(0, 24)}
        />
      )}
      {isInfoBoxVisible && (
        <MapInfoBox
          radius={rectangle.getRadius(RadiusUnity.mile)}
          position={rectangle.getBounds().getNorthEast()}
          pixelOffset={new google.maps.Size(0, 4)}
        />
      )}
    </>
  );
};

const mapState = (state: AppState) => ({
  selectedAreasIds: state.map.selectedAreasIds,
  isMapReadOnly: state.map.isMapReadOnly,
});

const actions = {
  removeRectangleFromPreSaved: mapActions.removeRectangleFromPreSaved,
  pushSelectedAreaId: mapActions.pushSelectedAreaId,
  removeSelectedAreaId: mapActions.removeSelectedAreaId,
  updatePreSavedRectangle: mapActions.updatePreSavedRectangle,
  setToastMessage: mapActions.setToastMessage,
  openToast: toastActions.open,
};

export const MapRectangle = connect(mapState, actions)(MapRectangleComponent);
