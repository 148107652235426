import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';
import { PrebidProviderData } from '@applift/platform';

import {
  getAudienceListKey,
  getPreBidDataByAudienceIdsKey,
  getPreBidDataBySegmentIdsKey,
} from 'api/QueryKeys';
import { WithResponse } from 'models/Response';
import {
  getAudiencesList,
  getPrebidSegmentDataFromIds,
  getPrebidSegmentDataFromAudienceIds,
} from 'api/Audience';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something we wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useAudiencesList = (audiencesListParams: {
  audienceTypeIds: string;
  searchField: string;
}) => {
  const data = useQuery(
    getAudienceListKey.keys('getAudienceList', audiencesListParams),
    getAudiencesList,
    {
      onError,
    },
  );
  return data;
};

export const usePrebidDataBySegmentIds = (
  prebidAudienceSegmentIdList: number[],
  options?: {
    enabled: boolean;
    meta: Record<string, Omit<PrebidProviderData, 'childSegments'>>;
  },
) => {
  const queryData = useQuery(
    getPreBidDataBySegmentIdsKey.keys('getPrebidSegmentDataFromIds', {
      prebidAudienceSegmentIdList,
    }),
    getPrebidSegmentDataFromIds,
    {
      onError,
      enabled: options?.enabled,
      meta: options?.meta,
    },
  );
  return queryData;
};

export const usePrebidDataByAudienceIds = (
  prebidAudienceIdList: number[],
  options?: {
    enabled: boolean;
    meta: Record<string, Omit<PrebidProviderData, 'childSegments'>>;
  },
) => {
  const queryData = useQuery(
    getPreBidDataByAudienceIdsKey.keys('getPrebidSegmentDataFromIds', {
      prebidAudienceIdList,
    }),
    getPrebidSegmentDataFromAudienceIds,
    {
      onError,
      enabled: options?.enabled,
      meta: options?.meta,
    },
  );
  return queryData;
};
