import { validateUrl, validatePhone, validateMultiUrl } from '../../utils/validationRules';

export function validateAdvertiserFormSaga(state: any) {
  const { advertiser } = state;
  if (!advertiser.isPoliticalAdvertising) {
    return true;
  }
  return !!(
    advertiser.name.trim().length &&
    advertiser.address.length &&
    advertiser.chief.length &&
    advertiser.url.length &&
    validateUrl(true).func(advertiser.url) &&
    advertiser.phone.length &&
    validatePhone(true).func(advertiser.phone) &&
    validateMultiUrl(true).func(advertiser.links) &&
    advertiser.other.length
  );
}
