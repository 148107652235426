import * as React from 'react';
import { TypoTooltip } from '@applift/factor';
import { formatCurrency } from 'utils/format';

interface CurrencyCellType {
  value: number;
  isFooterCell?: boolean;
  formatCurrencyValue?: boolean;
}

export const CurrencyCell = ({
  value,
  isFooterCell = false,
  formatCurrencyValue = true,
}: CurrencyCellType) => {
  return value != null ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {formatCurrencyValue ? formatCurrency(value) : value}
    </TypoTooltip>
  ) : (
    <>—</>
  );
};
