import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Typography,
} from '@applift/factor';
import { SemanticInformation } from '@applift/icons';
import { BUDGET_TYPE_ID } from 'constants/apps';
import React from 'react';
import { localStorageService } from 'services/localStorage';
import { formatNumberWithDecimal } from 'utils/format';

export interface SpendingBudgetWarningDialogInterface {
  budgetTypeId?: number;
  budget: number | null;
  onClose: () => void;
  onCancel: () => void;
}

export const SpendingBudgetWarningDialog = (props: SpendingBudgetWarningDialogInterface) => {
  const { budget, budgetTypeId, onClose, onCancel } = props;

  const setStorageWithDialogInfo = () => {
    localStorageService.saveLocal('spendingBudget', true);
  };
  const [dontAskMeAgain, setDontAskMeAgain] = React.useState<boolean>(false);

  const onAddClickWrapper = () => {
    if (dontAskMeAgain) {
      setStorageWithDialogInfo();
    }
    onClose();
  };

  return (
    <Dialog open>
      <DialogTitle>Add Spending Budget</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'start', gapCol: 4 }}>
          <SemanticInformation fontSize={24} color="info" />
          <Typography>
            Spending budget{' '}
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? `($${formatNumberWithDecimal(budget ?? 0)})`
              : `(Imps ${budget?.toLocaleString() ?? 0})`}{' '}
            can’t be updated after the campaign starts running.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: 100, display: 'flex', justifyContent: 'between', alignItems: 'center' }}>
          <Box>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    // @ts-ignore
                    onClick={(e) => setDontAskMeAgain(e.target?.checked)}
                    checked={dontAskMeAgain}
                  />
                }
                label="Don’t ask me again"
              />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gapCol: 8 }}>
            <Button
              onClick={() => {
                if (dontAskMeAgain) {
                  setStorageWithDialogInfo();
                }
                onCancel();
                onClose();
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={onAddClickWrapper}>Add</Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
