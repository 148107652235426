import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@applift/factor';
import { CreateOrEditPixelForm } from '@applift/conversion';
import { API } from 'api';
import { CreatePixelParams, PixelConversionInfo, PixelIntegrationData } from 'models/Conversion';
import { WithResponse } from 'models/Response';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { LoadingStatus } from 'constants/loading';
import { toastActions, Open } from 'store/toast/actions';

interface StateProps {}

interface DispatchProps {
  openToast: Open['open'];
}

interface Props extends StateProps, DispatchProps {
  onClose: () => void;
  onCreate?: (conversionId: number) => void;
}

export const CreatePixelDialogComponent = (props: Props) => {
  const { onClose, openToast, onCreate } = props;

  const [step, setStep] = useState(1);
  const [pixelConversionScript, setPixelConversionScript] = useState('');
  const [isCreatingPixel, setIsCreatingPixel] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [isEmailBeingScheduled, setIsEmailBeingScheduled] = useState(false);
  const createdPixel = useRef<PixelConversionInfo | null>(null);

  const onClickCreate = async (data: CreatePixelParams) => {
    try {
      const params = { ...data };
      if (!params.piggybackData?.url) {
        delete params.piggybackData;
      }
      if (!params.customFields?.length) {
        delete params.customFields;
      }
      if (!params.financialMetrics) {
        delete params.financialMetrics;
      }

      setIsCreatingPixel(LoadingStatus.LOADING);
      const response = await API.Conversion.CreatePixel(params);
      if (!response?.data) {
        throw response;
      }
      if (onCreate) {
        onCreate(response.data.id);
      }
      openToast(`Pixel conversion created successfully.`);
      setIsCreatingPixel(LoadingStatus.SUCCESS);
      createdPixel.current = response.data || null;
      setPixelConversionScript(response?.data?.pixelConversionScript || '');
      setStep(3);
    } catch (err) {
      openToast((err as WithResponse<any>)?.errorObjects?.[0]?.error || SOMETHING_WENT_WRONG);
      setIsCreatingPixel(LoadingStatus.ERROR);
    }
  };

  const onIntegrationCompletion = async (info: PixelIntegrationData) => {
    try {
      setIsEmailBeingScheduled(true);
      await API.Conversion.SendPixelEmail({ ...info, conversionId: `${createdPixel.current?.id}` });
      openToast('Pixel conversion e-mail sent successfully.');
      onClose();
    } catch (err) {
      openToast((err as WithResponse<string>)?.errorObjects?.[0]?.error || SOMETHING_WENT_WRONG);
    } finally {
      setIsEmailBeingScheduled(false);
    }
  };

  return (
    <Dialog maxWidth="lg" fullWidth open>
      <CreateOrEditPixelForm
        isCompletionInProgress={isCreatingPixel === LoadingStatus.LOADING}
        type="create"
        step={step}
        setStep={setStep}
        onCompletion={onClickCreate}
        pixelConversionScript={pixelConversionScript}
        onIntegrationCompletion={onIntegrationCompletion}
        isEmailBeingScheduled={isEmailBeingScheduled}
        onClose={onClose}
      />
    </Dialog>
  );
};

const mapAction = {
  openToast: toastActions.open,
};

export const CreatePixelConversionDialog = connect(null, mapAction)(CreatePixelDialogComponent);
