import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { bidActions, ChangeBidField } from 'store/bid/actions';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { Creative } from '../../../models/Creative';
import { CampaignInfoField } from '../../../models/CampaignInfoFields';
import { AudienceWarning } from '../../../models/Audience';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { User } from '../../../models/User';
import { AppState } from '../../../models/Store';
import { Option } from '../../../models/Option';
import { FetchCreativeList } from '../../../api/Creatives';
import {
  applicationActions,
  SetCollapseBlockIndex,
  SetCurrentIoId,
  SetBudgetTypeId,
} from '../../../store/app/actions';
import {
  getTechnologyBlockErrorCreating,
  getInventoryBlockErrorCreating,
} from '../../../store/app/helpers';
import { creativesActions } from '../../../store/creatives/actions';
import { advanceActions, SetCampaignSidebarInfo } from '../../../store/advance/actions';
import { AdvancePageState } from '../../../store/advance/reducer';
import { ErrorCreatingResponse } from '../../../models/Response';
import { US_COUNTRY_ID } from '../../../constants/location';
import { Campaign } from './Campaign';

type Props = SetCollapseBlockIndex &
  SetCampaignSidebarInfo &
  SetCurrentIoId &
  SetBudgetTypeId &
  ChangeBidField & {
    isAuth: boolean;
    editableCampaign: ExistingCampaignData | null;
    user: User;
    selectCreatives: (creatives: Creative[]) => void;
    selectCreativeType: (option: Option<number>) => void;
    sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
    creativeStoreHasErrors: boolean;
    technologyStoreHasErrors: boolean;
    publisherStoreHasErrors: boolean;
    bidStoreHasErrors: boolean;
    errorCreating: ErrorCreatingResponse | null;
    audienceWarning: AudienceWarning;
    budgetTypeId: number;
    exchangesHasError: boolean;
  };

const CreateCampaignComponent = (props: Props) => {
  const {
    isAuth,
    editableCampaign,
    user,
    selectCreatives,
    selectCreativeType,
    sidebarCampaignInfo,
    setCollapseBlockIndex,
    setCampaignSidebarInfo,
    creativeStoreHasErrors,
    technologyStoreHasErrors,
    publisherStoreHasErrors,
    bidStoreHasErrors,
    errorCreating,
    audienceWarning,
    setCurrentIoId,
    changeBidField,
    budgetTypeId,
    exchangesHasError,
  } = props;
  const history = useHistory();

  const selectedCountry = sidebarCampaignInfo[CampaignInfoField.country];

  useEffect(() => {
    const { search } = history.location;
    const queryParams = queryString.parse(search);
    const { campaignName } = queryParams;

    if (typeof campaignName === 'string') {
      setCampaignSidebarInfo('campaignName', campaignName?.trim());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    const { search, pathname } = history.location;
    const queryParams = queryString.parse(search);
    const { creativeIds } = queryParams;
    if (!user) {
      return;
    }
    if (pathname.indexOf('campaign-create') >= 0 && creativeIds) {
      if (typeof creativeIds === 'string') {
        FetchCreativeList({
          creativeIds,
          pageNo: 1,
          noOfEntries: creativeIds?.split(',')?.length ?? 10,
        }).then((res) => {
          const creativeList = res.responseObject.data;
          selectCreativeType({
            label: creativeList[0].creativeType,
            value: creativeList[0].creativeTypeId,
          });
          selectCreatives(creativeList);
          setCollapseBlockIndex(0);
        });
      }
    }
  }, [history, user, selectCreatives, selectCreativeType, setCollapseBlockIndex]);

  useEffect(() => {
    // in create campaign, select US as default country
    if (!selectedCountry) {
      setCampaignSidebarInfo(CampaignInfoField.country, {
        label: 'United States',
        value: US_COUNTRY_ID,
        shortName: 'US',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, setCampaignSidebarInfo]);

  useEffect(() => {
    const { search } = history.location;
    const queryParams = queryString.parse(search);
    const { ioId } = queryParams;
    if (ioId) {
      setCurrentIoId(+ioId);
    }
  }, [history.location, setCurrentIoId]);
  React.useEffect(() => {
    if (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      changeBidField({
        key: 'budgetCapping',
        value: true,
      });
    }
  }, [changeBidField, budgetTypeId]);

  const errorIndices: boolean[] = Array(11).fill(false);
  errorIndices[0] = creativeStoreHasErrors;
  errorIndices[4] =
    publisherStoreHasErrors || exchangesHasError || getInventoryBlockErrorCreating(errorCreating);
  errorIndices[8] = technologyStoreHasErrors || getTechnologyBlockErrorCreating(errorCreating);
  errorIndices[9] = bidStoreHasErrors;

  return (
    <Campaign
      errorIndices={errorIndices}
      isAuth={isAuth}
      sidebarCampaignInfo={sidebarCampaignInfo}
      editableCampaign={editableCampaign}
      audienceWarning={audienceWarning}
      mode="create"
    />
  );
};

const mapState = (state: AppState) => ({
  isAuth: state.auth.authorized,
  user: state.auth.userData,
  editableCampaign: state.app.editableCampaign,
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  creativeStoreHasErrors: state.creatives.creativeStoreHasErrors,
  technologyStoreHasErrors: state.technology.technologyStoreHasErrors,
  publisherStoreHasErrors: state.publishers.publisherStoreHasErrors,
  bidStoreHasErrors: state.bid.bidStoreHasErrors,
  errorCreating: state.app.errorCreating,
  audienceWarning: state.audience.audienceWarning,
  budgetTypeId: state.app.budgetTypeId,
  exchangesHasError: state.advancedTargeting.exchangesHasError,
});

const mapAction = {
  selectCreatives: creativesActions.selectCreatives,
  selectCreativeType: creativesActions.selectCreativeType,
  setCollapseBlockIndex: applicationActions.setCollapseBlockIndex,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setCurrentIoId: applicationActions.setCurrentIoId,
  setBudgetTypeId: applicationActions.setBudgetTypeId,
  changeBidField: bidActions.changeBidField,
};

export const CreateCampaign = connect(mapState, mapAction)(CreateCampaignComponent);
