export const applicationConstants = {
  TOGGLE__SIDEBAR_MENU: 'TOGGLE__SIDEBAR_MENU',
  TOGGLE_RIGHT_SIDEBAR_MENU: 'TOGGLE_RIGHT_SIDEBAR_MENU',
  SET_SUBMITTED_STATE: 'SET_SUBMITTED_STATE',
  SET_SAVE_DRAFT_SUBMITTED_STATE: 'SET_SAVE_DRAFT_SUBMITTED_STATE',
  CREATE_CAMPAIGN_WITH_ADVERTISER: 'CREATE_CAMPAIGN_WITH_ADVERTISER',
  START_CREATING: 'START_CREATING',
  START_SAVING_CAMPAIGN_AS_DRAFT: 'START_SAVING_CAMPAIGN_AS_DRAFT',
  SUCCESS_CREATING: 'SUCCESS_CREATING',
  SUCCESS_EDITING: 'SUCCESS_EDITING',
  SUCCESS_CREATING_DRAFT: 'SUCCESS_CREATING_DRAFT',
  SET_CURRENT_IO_ID: 'SET_CURRENT_IO_ID',
  FAIL_CREATING: 'FAIL_CREATING',
  FAIL_SAVING_CAMPAIGN_AS_DRAFT: 'FAIL_SAVING_CAMPAIGN_AS_DRAFT',
  RESET_ERROR: 'RESET_ERROR',
  RESET_APP_STORE: 'RESET_APP_STORE',
  RESET_APP_STORE_FULL: 'RESET_APP_STORE_FULL',
  START_RESET_STORE: 'START_RESET_STORE',
  START_RESET_STORE_CAMPAIGN_SWITCH: 'START_RESET_STORE_CAMPAIGN_SWITCH',
  CHANGE_EDITING_MODE: 'CHANGE_EDITING_MODE',
  SET_EDITABLE_CAMPAIGN: 'SET_EDITABLE_CAMPAIGN',
  FILL_APPLICATION_STORE: 'FILL_APPLICATION_STORE',
  SET_EDITABLE_CAMPAIGN_LOADING_STATUS: 'SET_EDITABLE_CAMPAIGN_LOADING_STATUS',
  SET_MAP_LOADING_STATUS: 'SET_MAP_LOADING_STATUS',
  SET_COLLAPSE_MODE: 'SET_COLLAPSE_MODE',
  SET_COLLAPSE_BLOCK_INDEX: 'SET_COLLAPSE_BLOCK_INDEX',
  SET_ERROR_COLLAPSIBLES_OPEN: 'SET_ERROR_COLLAPSIBLES_OPEN',
  RESET_CLEARING_CAMPAIGN_DATA: 'RESET_CLEARING_CAMPAIGN_DATA',
  SET_BUDGET_TYPE_ID: 'SET_BUDGET_TYPE_ID',
  SET_MODELLING_DIMENSION_TO_SHOW: 'SET_MODELLING_DIMENSION_TO_SHOW',
};

export const ALL_COLLAPSED = 'ALL_COLLAPSED';
export const ALL_EXPANDED = 'ALL_EXPANDED';
export type CollapseMode = typeof ALL_EXPANDED | typeof ALL_COLLAPSED | '';
