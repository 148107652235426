import { Option, OptionData } from '../../models/Option';
import { advanceConstants } from './constants';
import { createAction } from '../../utils/actions';
import { DeviceTypeModel } from '../../models/Device';
import { ExistingCampaignData } from '../../models/ExistingCampaign';
import { BlackLists, WhiteLists, Limits } from './reducer';

export interface SetDeviceTypes {
  setDeviceTypes: (value: OptionData<DeviceTypeModel>[]) => void;
}

export interface SetStates {
  setStates: (value: Option<number>[]) => void;
}

export interface SetCampaignSidebarInfo {
  setCampaignSidebarInfo: (key: string, value: any) => void;
}

export interface ToggleBudgetPacing {
  toggleBudgetPacing: () => void;
}

export interface FillAdvanceStore {
  fillAdvanceStore: (campaign: ExistingCampaignData) => void;
}

export interface ChangeDefaultDateState {
  changeDefaultDateState: (value: boolean) => void;
}

export interface SetEndDateCleared {
  setEndDateCleared: (val: boolean) => void;
}

export type SetWhiteList = {
  setWhiteList: (listName: keyof WhiteLists, ids: Set<number | string>) => void;
};

export type SetBlackList = {
  setBlackList: (listName: keyof BlackLists, ids: Set<number | string>) => void;
};

export type ResetCampaignSidebarInfo = {
  resetCampaignSidebarInfo: (listNames: string[]) => void;
};

export type ResetWhiteList = {
  resetWhiteList: (listNames: string[]) => void;
};

export type ResetBlackList = {
  resetBlackList: (listNames: string[]) => void;
};

export type SetLimits = {
  setLimits: (limits: Limits) => void;
};

export type SetZipcodeLimitError = {
  setZipcodeLimitError: (errorMsg: string) => void;
};

export type SetIsTimezoneLoaded = {
  setIsTimezoneLoaded: (isTimezoneLoaded: boolean) => void;
};

export const advanceActions = {
  setDeviceTypes: (value: OptionData<DeviceTypeModel>[]) => {
    return createAction<OptionData<DeviceTypeModel>[]>(advanceConstants.DEVICE_TYPE__SET, value);
  },
  setStates: (value: Option<number>[]) => {
    return createAction<Option<number>[]>(advanceConstants.SET_STATES, value);
  },
  setCampaignSidebarInfo: (key: string, value: string | Option[]) => {
    return createAction<any>(advanceConstants.SIDEBAR_CAMPAIGN_INFO__SET, {
      key,
      value,
    });
  },
  changeDefaultDateState: (value: boolean) => {
    return createAction<boolean>(advanceConstants.CHANGE_DEFAULT_DATE_STATE, value);
  },
  toggleBudgetPacing: () => {
    return createAction<void>(advanceConstants.TOGGLE_BUDGET_PACING);
  },
  fillAdvanceStore: (campaign: ExistingCampaignData) => {
    return createAction<ExistingCampaignData>(advanceConstants.FILL_ADVANCE_STORE, campaign);
  },
  setEndDateCleared: (val: boolean) => {
    return createAction<boolean>(advanceConstants.SET_END_DATE_CLEARED, val);
  },
  setWhiteList(listName: string, ids: Set<number | string>) {
    return createAction(advanceConstants.SET_WHITE_LIST, { listName, ids });
  },
  setBlackList(listName: string, ids: Set<number | string>) {
    return createAction(advanceConstants.SET_BLACK_LIST, { listName, ids });
  },
  resetCampaignSidebarInfo: (listNames: string[]) => {
    return createAction(advanceConstants.RESET_SIDEBAR_CAMPAIGN_INFO, listNames);
  },
  resetWhiteList: (listNames: string[]) => {
    return createAction(advanceConstants.RESET_WHITE_LIST, listNames);
  },
  resetBlackList: (listNames: string[]) => {
    return createAction(advanceConstants.RESET_BLACK_LIST, listNames);
  },
  setLimits: (limits: Limits) => {
    return createAction(advanceConstants.SET_LIMITS, limits);
  },
  setZipcodeLimitError: (errorMsg: string) => {
    return createAction(advanceConstants.SET_ZIPCODE_LIMIT_ERROR, errorMsg);
  },
  setIsTimezoneLoaded: (isTimezoneLoaded: boolean) => {
    return createAction(advanceConstants.SET_IS_TIMEZONE_LOADED, isTimezoneLoaded);
  },
};
