import React, { useEffect, useState } from 'react';
import { Typography, Box, Switch, Row, Col, Tooltip } from '@applift/factor';
import { QuestionCircle } from '@applift/icons';
import { TextAreaWithCSVUpload } from '../../InventoryBlock/AllowAndBlock/components/TextAreaWithCSVUpload';

export interface AllowlistProps {
  deviceIds: string;
  ip: string;
  setDeviceIds: (val: any) => any;
  setIp: (val: any) => any;
}

export const Allowlist = (props: AllowlistProps) => {
  const { deviceIds, ip, setDeviceIds, setIp } = props;
  const [isSwitchOn, setSwitch] = useState(false);
  useEffect(() => {
    if (deviceIds.trim() || ip.trim()) {
      setSwitch(true);
    }
  }, [deviceIds, ip]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked === false) {
      setDeviceIds('');
      setIp('');
    }
    setSwitch(e.target.checked);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', padding: 16 }}>
      <Box
        sx={{
          gap: 4,
          display: 'flex',
          textColor: 'neutral-400',
          textWeight: 'demi',
          alignItems: 'center',
        }}
      >
        <Typography>Allowlist</Typography>
        <Tooltip title="Allowlist" placement="top" arrow>
          <QuestionCircle fontSize={18} />
        </Tooltip>
        <Typography>:</Typography>
        <Switch color="primary" size="small" onChange={handleChange} checked={isSwitchOn} />
      </Box>
      {isSwitchOn && (
        <Box sx={{ pl: 64 }}>
          <Row sx={{ px: 24 }}>
            <Col xs={3}>
              <TextAreaWithCSVUpload
                label="Device IDs"
                handleChange={setDeviceIds}
                value={deviceIds}
                tooltipMessage="List of the Device IDs targeted for this campaign"
                maxRows={8}
                minRows={8}
                placeholder="Please enter Device IDs"
                textfieldProps={{ label: 'Device IDs', error: false, helperText: '' }}
              />
            </Col>
            <Col xs={3}>
              <TextAreaWithCSVUpload
                label="IP"
                handleChange={setIp}
                value={ip}
                maxRows={8}
                minRows={8}
                tooltipMessage="List of the IPs targeted for this campaign"
                placeholder="Please enter IP"
                textfieldProps={{ label: 'IP', error: false, helperText: '' }}
              />
            </Col>
          </Row>
        </Box>
      )}
    </Box>
  );
};
