import React, { useState, useEffect } from 'react';
import { Icon } from 'factor';
import { parseVASTDAAST } from 'utils/VastDaastParser';
import { Creative } from 'models/Creative';
import { ApiService } from 'iqm-framework';

import WithErrorPlaceholderImage from '../../../SelectCreativesPopup/withErrorPlacehoderImage/WithErrorPlaceholderImage';

import AudioPlayer from './audioPlayer';
import { pauseOthersAndPlayThis } from './audioPlayer/utils';

import styles from './index.module.scss';

export const AUDIO_IS_PROCESSING = 'Audio is processing...';
export const AUDIO_LOADING_IS_FAILED = 'Audio loading is failed';
// export const VIDEO_IS_PROCESSING = 'Video is processing...';
// export const VIDEO_LOADING_IS_FAILED = 'Video loading is failed';

type TProps = {
  creative: Creative;
};

export const AudioCell = (props: TProps) => {
  const { creative } = props;
  const { creativeThumbnailSource } = creative;
  const [audioSource, setAudioSource] = useState('');
  let audioElemRef: HTMLAudioElement;

  useEffect(() => {
    if (!creative) {
      return;
    }
    const loadMediaSource = async () => {
      const sourceResponse = await ApiService.GetCreativeMediaSource(creative.id);
      if (sourceResponse) {
        const parsed = JSON.parse(sourceResponse);
        const dastSource = parsed[Object.keys(parsed)[0]];
        const link = await parseVASTDAAST(dastSource);
        if (link) {
          setAudioSource(link);
        }
      }
    };
    if (creative.creativeTypeId === 17) {
      loadMediaSource();
    }
  }, [creative]);

  const toggleAudioPlay = () => {
    if (audioSource) {
      audioElemRef.src = audioSource;
      pauseOthersAndPlayThis(audioElemRef);
    }
  };

  const creativeId = String(creative.id);

  return (
    <div className={styles.noDataWrapper}>
      <label className={styles.playButton} htmlFor={creativeId} onClick={toggleAudioPlay}>
        {creativeThumbnailSource ? (
          <WithErrorPlaceholderImage
            src={creative.creativeThumbnailSource}
            className={styles.maxHeight80}
          />
        ) : (
          <span className={styles.playIconWrapper}>
            <Icon name="VoiceXS" className={styles.playIcon} />
          </span>
        )}
      </label>
      <input className={styles.radioInput} type="radio" name="playButton" id={creativeId} />
      <div className={styles.audioPlayer}>
        <AudioPlayer
          getRef={(ref: HTMLAudioElement) => {
            audioElemRef = ref;
          }}
        />
      </div>
    </div>
  );
};
