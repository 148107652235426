import { enqueueSnackbar } from '@applift/factor';
import {
  UseInfiniteQueryResult,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { createPgDeals, getPgDealStatusList, getPgDealsPaymentTypeList } from 'api/DealGroup';
import { getDetailedExchangesInfo } from 'api/Exchange';
import {
  addPmpDeal,
  getInventoryGroupList,
  getInventoryGroupsTypes,
  getPGDealsList,
  getPmpDealList,
} from 'api/Inventory';
import { getPgDealsListKey, getPgDealsStatusList } from 'api/QueryKeys';
import { AxiosError, AxiosResponse } from 'axios';
import { CreatePgDealResponse, PgDealPaymentType, PgDealStatusType } from 'models/DealGroup';
import { DetailedExchange } from 'models/Exchange';
import {
  AddPmpDealResponse,
  InventoryGroupListResponse,
  PmpDealListResponse,
} from 'models/Inventory';
import { WithResponse } from 'models/Response';

export interface UsePgDealsListArgs {
  search?: string;
  pageNo?: number;
  ids?: number[];
  statusIds?: number[];
  campaignId?: number;
  exchangeIds?: number[];
  meta?: {
    exchangeList: DetailedExchange[];
    pgDealsStatusList: PgDealStatusType[];
    pgDealPaymentTypeList: PgDealPaymentType[];
  };
  options?: { enabled?: boolean; onSuccess?: UseQueryOptions['onSuccess'] };
}

export interface UseExchangeListParams {
  searchField?: string;
  exchangedIds?: string;
  Ids?: string;
  sortBy?: string;
  pageNo?: number;
  noOfEntries?: number;
  options?: { onSuccess: (e: any) => void };
}

export const useExchangesList = (params?: UseExchangeListParams) => {
  const response = useQuery(
    [{ ...params, scope: 'getDetailedExchangesInfo' }],
    getDetailedExchangesInfo,
    { onSuccess: params?.options?.onSuccess },
  );
  return response;
};

export const usePgDealsStatusList = () => {
  const response = useQuery(getPgDealsStatusList.keys('getPgDealsStatusList'), getPgDealStatusList);
  return response;
};

export const usePgDealsPaymentTypeList = () => {
  const response = useQuery(
    getPgDealsStatusList.keys('getPgDealsPaymentTypeList'),
    getPgDealsPaymentTypeList,
  );
  return response;
};

export const usePGDealsList = ({
  search,
  ids,
  meta,
  statusIds,
  exchangeIds,
  options,
}: UsePgDealsListArgs) => {
  const exchangeList = meta?.exchangeList;
  const pgDealsStatusList = meta?.pgDealsStatusList;
  const pgDealPaymentTypeList = meta?.pgDealPaymentTypeList;
  const exchangeListObject = exchangeList?.reduce((acc, current) => {
    // @ts-ignore
    acc[current.id] = { exchangeName: current.name, exchangeUrlName: current.urlName };
    return acc;
  }, {});
  const pgDealsStatusObject = pgDealsStatusList?.reduce((acc, current) => {
    // @ts-ignore
    acc[current.id] = {
      statusName: current.name,
      statusLabel: current.label,
    };
    return acc;
  }, {});
  const pgDealsPaymentTypeObject = pgDealPaymentTypeList?.reduce((acc, current) => {
    // @ts-ignore
    acc[current.id] = {
      paymentTypeName: current.name,
      paymentTypeLabel: current.label,
    };
    return acc;
  }, {});

  const response = useInfiniteQuery(
    getPgDealsListKey.keys(
      'getPGDealsList',
      search ?? '',
      ids,
      30,
      statusIds,
      undefined,
      exchangeIds,
    ),
    getPGDealsList,
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalCount = allPages.reduce<number>((total, current) => {
          // eslint-disable-next-line
          total += current?.data?.pgDealData?.length ?? 0;
          return total;
        }, 0);
        if (totalCount < (lastPage?.data?.filteredRecords ?? -1)) {
          return allPages.length + 1;
        }
        return undefined;
      },
      meta: { exchangeListObject, pgDealsStatusObject, pgDealsPaymentTypeObject },
      enabled: options?.enabled,
    },
  );
  return response;
};

export const usePgDealInfo = ({
  search,
  ids,
  meta,
  statusIds,
  options,
  campaignId,
}: UsePgDealsListArgs) => {
  const exchangeList = meta?.exchangeList;
  const pgDealsStatusList = meta?.pgDealsStatusList;
  const pgDealPaymentTypeList = meta?.pgDealPaymentTypeList;
  const exchangeListObject = exchangeList?.reduce((acc, current) => {
    // @ts-ignore
    acc[current.id] = { exchangeName: current.name, exchangeUrlName: current.urlName };
    return acc;
  }, {});
  const pgDealsStatusObject = pgDealsStatusList?.reduce((acc, current) => {
    // @ts-ignore
    acc[current.id] = {
      statusName: current.name,
      statusLabel: current.label,
    };
    return acc;
  }, {});
  const pgDealsPaymentTypeObject = pgDealPaymentTypeList?.reduce((acc, current) => {
    // @ts-ignore
    acc[current.id] = {
      paymentTypeName: current.name,
      paymentTypeLabel: current.label,
    };
    return acc;
  }, {});

  const response = useInfiniteQuery(
    getPgDealsListKey.keys('getPGDealsList', search ?? '', ids, 30, statusIds, campaignId),
    getPGDealsList,
    {
      meta: { exchangeListObject, pgDealsStatusObject, pgDealsPaymentTypeObject },
      enabled: options?.enabled,
      onSuccess: options?.onSuccess,
      cacheTime: 0,
    },
  );
  return response;
};

export interface UseInventoryGroupsListParams {
  searchField?: string;
  groupType?: string;
  pageSize?: number;
  ids?: string;
  sortBy?: string;
  includeStatistics?: boolean;
  provideAccountLevelExcludedGroup?: boolean;
  groupFilterId?: boolean;
  owId?: number;
  excludeEmptyGroups?: boolean;
}

export const useInventoryGroupsList = (
  params: UseInventoryGroupsListParams = {},
): UseInfiniteQueryResult<
  WithResponse<InventoryGroupListResponse>,
  AxiosResponse<any> | undefined
> => {
  const queryData = useInfiniteQuery(
    [{ ...params, scope: 'InventoryGroupsList' }],
    getInventoryGroupList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.inventoryGroupList?.length) ?? 0,
          0,
        );
        if (
          lastPage?.data?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.data?.filteredRecords
        ) {
          return pages.length + 1;
        }
        return null;
      },
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
    },
  );

  return queryData as UseInfiniteQueryResult<
    WithResponse<InventoryGroupListResponse>,
    AxiosResponse<any>
  >;
};

export const useInventoryGroupsTypes = () => {
  const queryData = useQuery([{ scope: 'InventoryGroupsTypes' }], getInventoryGroupsTypes);
  return queryData?.data?.data?.inventoryGroupTypeList ?? [];
};

export interface UsePmpDealsListParams {
  searchKeywords?: string;
  exchanges?: string;
  ids?: string;
  groupId?: number;
  creativeTypes?: string;
  noOfEntries?: number;
  sortBy?: string;
  active?: boolean;
}
export const usePmpDealsList = (
  params: UsePmpDealsListParams = {},
): UseInfiniteQueryResult<WithResponse<PmpDealListResponse>, AxiosResponse<any> | undefined> => {
  const queryData = useInfiniteQuery(
    [{ ...params, scope: 'PmpDealListResponse' }],
    getPmpDealList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.pmpDealData?.length) ?? 0,
          0,
        );
        if (
          lastPage?.data?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.data?.filteredRecords
        ) {
          return pages.length + 1;
        }
        return null;
      },
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
    },
  );

  return queryData as UseInfiniteQueryResult<WithResponse<PmpDealListResponse>, AxiosResponse<any>>;
};

export const useCreatePgDeals = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (arg: WithResponse<CreatePgDealResponse>) => void;
  onError?: (arg: WithResponse<CreatePgDealResponse>) => void;
}) => {
  const mutationResult = useMutation(createPgDeals, {
    mutationKey: ['createPgDeals'],
    onSuccess,
    onError,
  });

  return mutationResult;
};

export const useAddPmpDeal = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (arg: WithResponse<AddPmpDealResponse>) => void;
  onError?: (arg: WithResponse<AddPmpDealResponse>) => void;
}) => {
  const mutationResult = useMutation(addPmpDeal, {
    mutationKey: ['addPmpDeal'],
    onSuccess,
    onError,
  });

  return mutationResult;
};
