import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  LoadingButton,
} from '@applift/factor';
import { RUM_ACTION_ATTRIBUTE } from 'services/DataDog/constants';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

export const ConfirmCampaignPriorityRemovalDialog = (props: Props) => {
  const { onClose, onConfirm, isLoading } = props;

  return (
    <Dialog open maxWidth="sm">
      <DialogTitle>Remove IO End Date?</DialogTitle>
      <DialogContent>
        <Typography>
          Removing the <Typography weight="demi">IO end date</Typography> will also{' '}
          <Typography weight="demi">remove the priorities</Typography> from all campaigns. Are you
          sure you want to remove?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          {...{ [RUM_ACTION_ATTRIBUTE]: 'Confirm Remove IO End Date' }}
          onClick={onConfirm}
          color="error"
          loading={isLoading}
        >
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
