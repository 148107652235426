import { ExcludedOption } from './Option';

/* eslint-disable camelcase */
export interface AudienceSegment {
  cpm: number;
  group_name: string;
  id: number;
}

export interface AudienceGroup {
  campaigns: string;
  click_capping: number;
  conversion_capping: number;
  custom_capping: number;
  description: null;
  from_time: number;
  id: number;
  impression_capping: number;
  name: string;
  to_time: number;
}

export interface AudienceType {
  id: number;
  label: string;
  name: string;
}

export interface AudienceItem extends ExcludedOption<string> {
  audienceTypeId?: number;
  audienceId?: number;
  status?: string;
  createdOn?: number;
  uniques?: number;
  dataCost?: number;
  statusDisplayName: string;
  dataGroup: string;
}

export interface AudienceItemResponse {
  audienceName: string;
  audienceType: string;
  audienceTypeId: number;
  id: number;
  status: string;
  uniques?: number;
  createdOn: number;
  dataCost?: number;
  statusDisplayName: string;
  isVoterAudience: boolean;
}

export interface ChildSegment {
  prebidAudienceSegmentId: number;
  prebidAudienceSegmentName: string;
  description: string;
  parentPathKey: null | string;
  cpm: number;
  creativeTypeIdList: number[];
  isLeaf: boolean;
}
export interface ProviderData {
  providerId: number;
  providerName: string;
  description: string;
  providerLogoUrl: null | string;
  childSegments: ChildSegment[];
}

export interface PrebidSegmentData {
  prebidAudienceSegmentId: number;
  prebidAudienceSegmentName: string;
  description: string;
  parentPathKey: string;
  cpm: number;
  creativeTypeIdList: number[];
  providerId: number;
  providerLogoUrl: string;
  providerName: string;
}

export interface PreBidSegmentAudienceInfo {
  id: number;
  name: string;
  createdOn: number;
  cpm: number;
  owId: number;
  createdByUowId: number;
  prebidSegmentData: PrebidSegmentData[];
}

export const audienceIDMap: { [key: number]: string } = {
  1: 'AudienceMatched',
  2: 'AudienceSegmented',
  3: 'AudienceRetargeted',
  4: 'AudienceGeo',
  5: 'AudienceContextual',
  6: 'AudienceLookalike',
  7: 'AudienceCampaign',
};

export const audienceStatusIconMap: { [key: string]: string } = {
  Processing: 'StatusProcessingColor',
  Pending: 'StatusPendingColor',
  Approved: 'StatusReadyColor',
};

export enum AudienceWarning {
  NONE = 'NONE',
  SOME_UNAPPROVED = 'SOME_UNAPPROVED',
  ALL_UNAPPROVED = 'ALL_UNAPPROVED',
}
