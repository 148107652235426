import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { auth } from './auth2/reducer';
import { app } from './app/reducer';
import { advanced } from './advance/reducer';
import { sms } from './sms/reducer';
import { map } from './map/reducer';
import { creatives } from './creatives/reducer';
import { creativeGroups } from './creativeGroups/reducer';
import { publishers } from './publishers/reducer';
import { advertiser } from './advertiser/reducer';
import { technology } from './technology/reducer';
import { bid } from './bid/reducer';
import { demographic } from './demographic/reducer';
import { location } from './location/reducer';
import { inventory } from './inventory/reducer';
import { conversion } from './conversion/reducer';
import { scheduling } from './scheduling/reducer';
import { advancedTargeting } from './advancedTargeting/reducer';
import { toast } from './toast/reducer';
import { audience } from './audience/reducer';
import { reach } from './reach/reducer';
import { estimator } from './estimator/reducer';
import {
  watchAdvertiser,
  watchApp,
  watchCreatives,
  watchPublishers,
  watchReachParameters,
  watchReach,
  watchMap,
  watchStoreForEstimation,
  watchExchanges,
  watchTrafficTypes,
} from './sagas';

const reducer = combineReducers({
  auth,
  app,
  advanced,
  sms,
  map,
  creatives,
  publishers,
  advertiser,
  technology,
  bid,
  demographic,
  location,
  inventory,
  scheduling,
  advancedTargeting,
  toast,
  audience,
  creativeGroups,
  conversion,
  reach,
  estimator,
});

const sagaMiddleware = createSagaMiddleware();

const actionSanitizer = (action: any) => {
  if (action.type === 'SET__AREA__TYPE') {
    return {
      ...action,
      payload: {
        ...action.payload,
        reactLabel: '<<LONG_BLOB>>',
      },
    };
  }
  return action;
};

const composeEnhancers =
  // @ts-ignore
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer,
    })) ||
  compose;

export const store = createStore<any, any, any, any>(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(watchAdvertiser);
sagaMiddleware.run(watchApp);
sagaMiddleware.run(watchCreatives);
sagaMiddleware.run(watchPublishers);
sagaMiddleware.run(watchReach);
sagaMiddleware.run(watchReachParameters);
sagaMiddleware.run(watchMap);
sagaMiddleware.run(watchExchanges);
sagaMiddleware.run(watchStoreForEstimation);
sagaMiddleware.run(watchTrafficTypes);
