import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';

import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { technologyActions, SelectTechnologyField } from 'store/technology/actions';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { applicationActions, ResetError } from 'store/app/actions';
import { ErrorCreatingResponse } from 'models/Response';
import { ExistingCampaignData } from 'models/ExistingCampaign';

type Props = SelectTechnologyField &
  ResetError & {
    errorCreating: ErrorCreatingResponse | null;
    trafficTypes: Option<number>[];
    tvTrafficTypes: Option<number>[];
    selectedTrafficTypes: Option<number>[];
    className?: string;
    editableCampaign: ExistingCampaignData | null;
    isTvAd: boolean;
  };

const TrafficTypeSelectWrapperComponent = (props: Props) => {
  const {
    trafficTypes,
    tvTrafficTypes,
    selectedTrafficTypes,
    selectTechnologyField,
    className = '',
    errorCreating,
    resetError,
    editableCampaign,
    isTvAd,
  } = props;

  const selectTrafficType = (types: Option<number>[]) => {
    selectTechnologyField({
      key: CampaignInfoField.traffic,
      value: types,
    });
    if (errorCreating && errorCreating.errorField === CampaignInfoField.traffic) {
      resetError();
    }
  };

  useEffect(() => {
    if (
      editableCampaign &&
      editableCampaign.trafficType &&
      tvTrafficTypes?.length &&
      trafficTypes?.length
    ) {
      const savedTrafficTypesIds = editableCampaign.trafficType.split(',').map((i) => +i);
      const types = editableCampaign.isTvAd ? tvTrafficTypes : trafficTypes;
      selectTechnologyField({
        key: CampaignInfoField.traffic,
        value: types.filter((i) => savedTrafficTypesIds.includes(i.value)),
      });
    }
  }, [editableCampaign, trafficTypes, tvTrafficTypes, selectTechnologyField]);

  return (
    <Select
      placeholder={isTvAd ? 'TV Traffic Type' : 'Traffic Type'}
      label={isTvAd ? 'TV Traffic Type' : 'Traffic Type'}
      isMulti
      showControlLabel
      options={isTvAd ? tvTrafficTypes : trafficTypes}
      value={selectedTrafficTypes}
      onChange={selectTrafficType}
      className={className}
      tooltipParams={{ label: 'Consumer Device Type' }}
    />
  );
};

const mapState = (state: AppState) => ({
  trafficTypes: state.technology.traffic,
  tvTrafficTypes: state.technology.tvTraffic,
  selectedTrafficTypes: state.technology.sidebarCampaignInfo[CampaignInfoField.traffic],
  errorCreating: state.app.errorCreating,
  editableCampaign: state.app.editableCampaign,
  isTvAd: state.technology.isTvAd,
});

const mapAction = {
  selectTechnologyField: technologyActions.selectTechnologyField,
  resetError: applicationActions.resetError,
};

export const TrafficTypeSelectWrapper = connect(
  mapState,
  mapAction,
)(TrafficTypeSelectWrapperComponent);
