import moment from 'moment';

import { ICountryOption } from 'models/Location';
import { Option, OptionId } from 'models/Option';
import { Action } from 'models/Action';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ExistingCampaignData, FilterStore } from 'models/ExistingCampaign';
import { reducerFromMap } from 'utils/actions';
import { nearestFutureMinutes } from 'utils/roundTime';
import { DEFAULT_ORGANIZATION_TIMEZONE } from 'constants/timezone';
import { smsConstants } from './constants';

export interface SMSPageState {
  isDateDefault: boolean;
  isTimezoneLoaded: boolean;
  isUsingV2API: boolean; // This is so we can selectivelty in our location code make changes for v2 API
  filterStore?: FilterStore | null; // This is the store to revive filter options
  sidebarCampaignInfo: {
    [CampaignInfoField.name]: string;
    [CampaignInfoField.id]: number | null;
    [CampaignInfoField.group]: Option[];
    [CampaignInfoField.totalBudget]: string;
    [CampaignInfoField.timezone]: OptionId;
    [CampaignInfoField.start]: number;
    [CampaignInfoField.country]: ICountryOption | null;
    [CampaignInfoField.phone]: Option<number>;
    [CampaignInfoField.message]: string | null;
  };
}

export const defaultSMSPageState: SMSPageState = {
  isDateDefault: true,
  isTimezoneLoaded: false,
  isUsingV2API: true,
  filterStore: null,
  sidebarCampaignInfo: {
    [CampaignInfoField.name]: '',
    [CampaignInfoField.id]: null,
    [CampaignInfoField.group]: [],
    [CampaignInfoField.totalBudget]: '',
    [CampaignInfoField.timezone]: DEFAULT_ORGANIZATION_TIMEZONE,
    [CampaignInfoField.start]: nearestFutureMinutes(10, moment().add(10, 'minutes')).valueOf(),
    [CampaignInfoField.country]: null,
    [CampaignInfoField.phone]: { value: 18004388683, label: '+1 (800) GET VOTE (default)' },
    [CampaignInfoField.message]: null,
  },
};

const setSidebarCampaignInfo = (
  state: SMSPageState,
  action: Action<{ key: string; value: any }>,
): SMSPageState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const changeDefaultDateState = (state: SMSPageState, action: Action<boolean>) => {
  return {
    ...state,
    isDateDefault: action.payload,
  };
};

const fillStore = (state: SMSPageState, action: Action<ExistingCampaignData>): SMSPageState => {
  const editableCampaignData = action.payload;
  const editableSidebarCampaignInfo = {
    [CampaignInfoField.id]: editableCampaignData.id,
    [CampaignInfoField.name]: editableCampaignData.campaignName,
    [CampaignInfoField.start]: editableCampaignData.startTime * 1000,
  };

  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      ...editableSidebarCampaignInfo,
    },
    isUsingV2API: Boolean(editableCampaignData.isUsingV2API),
    filterStore: editableCampaignData.filterStore,
  };
};

const reset = (): SMSPageState => {
  return {
    ...defaultSMSPageState,
  };
};

const resetSidebarCampaingInfo = (state: SMSPageState, action: Action<string[]>): SMSPageState => {
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      ...(action.payload?.reduce<any>((prev, current) => ({ ...prev, [current]: [] }), {}) ?? {}),
    },
  };
};

const setIsTimezoneLoaded = (state: SMSPageState, action: Action<boolean>): SMSPageState => {
  return {
    ...state,
    isTimezoneLoaded: action.payload,
  };
};

const reducer = reducerFromMap<SMSPageState>(defaultSMSPageState, {
  [smsConstants.SIDEBAR_CAMPAIGN_INFO__SET]: setSidebarCampaignInfo,
  [smsConstants.CHANGE_DEFAULT_DATE_STATE]: changeDefaultDateState,
  [smsConstants.FILL_SMS_STORE]: fillStore,
  [smsConstants.RESET_SMS_STORE]: reset,
  [smsConstants.RESET_SIDEBAR_CAMPAIGN_INFO]: resetSidebarCampaingInfo,
  [smsConstants.SET_IS_TIMEZONE_LOADED]: setIsTimezoneLoaded,
});

export const sms = (state: SMSPageState = defaultSMSPageState, actions: Action<any>) =>
  reducer(state, actions);
