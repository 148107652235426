import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';

import { downloadCampaignList } from 'api/CampaignList';

export const useDownloadCampaignList = (options?: {
  onSuccess?: (res: any, payload: any) => void;
  onError?: (props: any) => void;
  onSettled?: (props: any) => void;
}) => {
  const queryData = useMutation(downloadCampaignList, {
    mutationKey: ['downloadCampaignList'],
    onSuccess: options?.onSuccess,
    onError: (e) => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      });
      if (options?.onError) {
        options.onError(e);
      }
    },
    onSettled: options?.onSettled,
  });
  return queryData;
};
