import { logDataDogAction, datadogRum } from 'iqm-framework';
import { get } from 'lodash';

import { TUploadLocationFileParams } from 'models/DataDog';

export const Location = {
  uploadLocationFile: (params: TUploadLocationFileParams) => {
    const { file, success, locationType, validLocations, response, errorMsg } = params;
    const requestTimestamp =
      response?.data?.requestTimestamp || (response as any)?.requestTimestamp;
    const responseTimestamp =
      response?.data?.responseTimestamp || (response as any)?.responseTimestamp;
    const latency =
      requestTimestamp && responseTimestamp ? responseTimestamp - requestTimestamp : null;
    const actionNamespace =
      datadogRum.getRumGlobalContext?.()?.iframeParent ||
      datadogRum.getRumGlobalContext?.().service ||
      'campaigns';
    const actionName =
      actionNamespace === 'audiences' ? 'uploadGeofarmedAudienceFile' : 'uploadLocationFile';
    const actionText =
      actionNamespace === 'audiences' ? 'Upload Geofarmed Audience File' : 'Upload Location File';
    const fileRUMProperties = {
      info: {
        fileSizeBytes: file?.size,
        fileMIME: file?.type,
        fileExtension: file?.name?.includes('.') ? file.name.split('.').pop() : '',
        locationType,
        validLocations,
      },
      action: `${actionNamespace}/${actionName}`,
      statusCode: get(response, 'status'),
      latency,
      success,
      errorMsg: !success
        ? errorMsg ||
          get(
            response,
            'data.responseObject.errorMsg',
            `API Error: ${response?.status || 'No Status Code Found'}`,
          )
        : null,
    };
    logDataDogAction(`${actionText} ${success ? 'succeeded' : 'failed'}`, fileRUMProperties);
  },
};
