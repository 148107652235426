/* eslint-disable camelcase */
import Axios, { AxiosResponse, Canceler } from 'axios';
import get from 'lodash/get';

import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GeoRadiusData } from './Campaign';

export interface ReachResponse {
  success: boolean;
  data: {
    estimated_reach: number;
  };
}

export interface ReachParameters {
  ageRangeIds: string;
  genderIds: string;
  incomeRangeIds: string;
  ethnicityIds: string;
  stateIds: string;
  whiteListedZipcodes: string;
  whiteListedCityIds: string;
  whiteListedGeoRadiusDetails: GeoRadiusData[];
}

let reachCanceler: Canceler;
export const cancelGetReach = () => {
  if (reachCanceler) {
    reachCanceler('Canceled by user');
  }
};

export const getReach = async (params: ReachParameters): Promise<ReachResponse | null> => {
  if (reachCanceler) {
    reachCanceler('Canceled by user');
  }

  try {
    const response: AxiosResponse<ReachResponse> = await getInstance().post(
      `/v3/cmp/estimate-reach`,
      params,
      {
        cancelToken: new Axios.CancelToken((c) => {
          reachCanceler = c;
        }),
        timeout: 300000,
      },
    );

    if (response.data.success) {
      return response.data;
    }
    throw response;
  } catch (e) {
    if (Axios.isCancel(e?.response?.data)) {
      return null;
    }
    Logger.log('Error while getting estimated reach: ', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};
