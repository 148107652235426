import * as React from 'react';
import { Container, Paper } from '@applift/factor';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { SetCurrentIoId, applicationActions } from 'store/app/actions';
import { useIOBudgetImpInfo, useIOInfo } from 'hooks/useIO';
import { useCampaignListCols } from 'hooks/useCampaignList';
import { MainContent } from 'components/MainContent';
import { AppState } from 'models/Store';
import { OptionId } from 'models/Option';
import { ScreenLoader } from 'components/ScreenLoader';
import { BreadCrumbActions } from 'components/BreadCrumbActions';
import { IODetail, IoUpdateDetailsType } from 'models/IO';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { WithResponse } from 'models/Response';
import { CampaignListWrapper } from './components/CampaignList/CampaignListWrapper';
import { IODetailsWidget } from './components/IODetailsWidget';

import styles from './index.module.scss';

export interface IODetailsPropTypes extends SetCurrentIoId {
  organizationTimezone: number | OptionId<any> | null;
}

export const IODetailsComponent = (props: IODetailsPropTypes) => {
  const { setCurrentIoId, organizationTimezone } = props;
  const { id: ioId } = useParams<{ id: string }>();

  const {
    data: ioDetail,
    refetch: refetchIoDetails,
    isLoading: ioInfoLoading,
    isError: ioInfoError,
  } = useIOInfo(ioId);
  const { data: ioBudgetInfo } = useIOBudgetImpInfo(ioId, {
    enabled: Boolean(ioId),
  });
  const [ioDetails, setIoDetails] = React.useState<IoUpdateDetailsType>();
  const [isIoDetailsDirty, setIsIoDetailsDirty] = React.useState(false);
  const [openIoSaveDialog, setOpenIoSaveDialog] = React.useState(false);
  const [isIoStartDateEditable, setIsIoStartDateEditable] = React.useState(false);
  const { isLoading: isCampaignListColsLoading } = useCampaignListCols();
  const prevIODetail = React.useRef<WithResponse<IODetail> | null>(null);

  React.useEffect(() => {
    if (ioId) {
      setCurrentIoId(+ioId);
    }
  }, [ioId, setCurrentIoId]);

  React.useEffect(() => {
    if (
      ioDetail?.data &&
      (prevIODetail.current?.data?.ioId !== ioDetail.data.ioId ||
        prevIODetail.current?.data?.ioStartTime !== ioDetail.data.ioStartTime)
    ) {
      const dateEditMin = moment().valueOf();
      const isDeleted = ioDetail.data.ioStatusId === IO_STATUS_ID.DELETED;
      setIsIoStartDateEditable(!isDeleted && ioDetail.data.ioStartTime >= dateEditMin);
      prevIODetail.current = ioDetail;
    }
  }, [ioDetail]);

  return (
    <MainContent className={styles.container}>
      <Paper
        elevation={2}
        className={styles.breadcrumbBar}
        sx={{ px: 16, display: 'flex', alignItems: 'center', justifyContent: 'between' }}
      >
        <BreadCrumbActions
          refetchIoDetails={refetchIoDetails}
          ioDetails={ioDetails}
          ioInfoLoading={ioInfoLoading}
          panelType="io"
          setIsIoDetailsDirty={setIsIoDetailsDirty}
          openIoSaveDialog={openIoSaveDialog}
          setOpenIoSaveDialog={setOpenIoSaveDialog}
          serverIoDetail={ioDetail?.data}
          isIoStartDateEditable={isIoStartDateEditable}
        />
      </Paper>
      {!(ioInfoLoading || isCampaignListColsLoading) && !ioInfoError ? (
        <Container
          sx={{
            px: 32,
            pt: 16,
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            height: 100,
            maxHeight: 100,
            overflowY: 'auto',
          }}
        >
          <IODetailsWidget
            ioDetail={ioDetail?.data}
            setIoDetails={setIoDetails}
            isIoStartDateEditable={isIoStartDateEditable}
          />
          <CampaignListWrapper
            ioDetail={ioDetail?.data}
            isIoDetailsDirty={isIoDetailsDirty}
            organizationTimezone={organizationTimezone}
            openIoSaveDialog={openIoSaveDialog}
            setOpenIoSaveDialog={setOpenIoSaveDialog}
            ioBudgetInfo={ioBudgetInfo?.data}
          />
        </Container>
      ) : (
        <ScreenLoader text="Loading..." />
      )}
    </MainContent>
  );
};
const mapAction = {
  setCurrentIoId: applicationActions.setCurrentIoId,
};

const mapState = (state: AppState) => ({
  organizationTimezone: state.auth.organizationTimezoneInfo,
});

export const IODetails = connect(mapState, mapAction)(IODetailsComponent);
