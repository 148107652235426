import React from 'react';
import { Icon } from 'factor';
import { Tooltip } from '@applift/factor';
import { connect } from 'react-redux';
import { AppState } from 'models/Store';
import { ILocationFile } from 'store/location/reducer';
import { TLocationFileType } from 'models/Location';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  browseFileButtonTitle?: string;
  id?: string;
  onFileUploaded: (fileList: FileList) => any;
  accept?: string;
  currentSelection?: 'locations' | 'addresses' | 'polygons';
  locationFiles: ILocationFile[];
  filesBeingUploaded: TLocationFileType[];
  parentApp: string;
}

export const FileUploaderHelper = (props: Props) => {
  const {
    className = '',
    browseFileButtonTitle = 'Upload File',
    onFileUploaded,
    id,
    accept = '.csv',
    locationFiles,
    filesBeingUploaded,
    currentSelection,
    parentApp,
  } = props;

  const isButtonDisabled = React.useMemo(
    () =>
      currentSelection === 'locations' && parentApp !== 'audiences'
        ? locationFiles.filter((file) => file.type === currentSelection).length > 0 ||
          filesBeingUploaded.includes(currentSelection)
        : false,
    [filesBeingUploaded, currentSelection, locationFiles, parentApp],
  );

  const handleBrowseInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onFileUploaded(event.target.files);
    }
  };

  const label = (
    <label
      className={`btn-square _conflower-blue _filled ${styles.uploadButton} ${
        isButtonDisabled ? styles.disabledUploadButton : ''
      }`}
      htmlFor={id || browseFileButtonTitle}
    >
      <Icon name="Upload" className={styles.uploadIcon} />
      {browseFileButtonTitle}
    </label>
  );

  return (
    <Tooltip
      title={isButtonDisabled ? 'Only one file can be uploaded' : ''}
      arrow
      placement="bottom-start"
    >
      <div className={className}>
        <input
          className={styles.fileInput}
          type="file"
          id={id || browseFileButtonTitle}
          onChange={handleBrowseInput}
          value=""
          accept={accept}
        />
        {label}
      </div>
    </Tooltip>
  );
};

const mapState = (state: AppState) => ({
  locationFiles: state.location.locationFiles,
  filesBeingUploaded: state.location.filesBeingUploaded,
  parentApp: state.app.editableCampaign?.parentApp!,
});

export const FileUploader = connect(mapState)(FileUploaderHelper);
