export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_LIST_SORTING = [{ id: 'campaignId', desc: true }];

export const CAMPAIGN_STATUSES = {
  EXPIRED: 'expired',
  DELETED: 'deleted',
  RUNNING: 'running',
  DRAFT: 'draft',
  PENDING: 'pending',
  PAUSED: 'paused',
  REJECTED: 'rejected',
};

export const CAMPAIGN_TYPE_BY_NAME = {
  advanced: 1,
  pg: 2,
};

export const CAMPAIGN_TYPE_BY_ID = {
  1: 'Advanced',
  2: 'PG',
};
