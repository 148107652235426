import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@applift/factor';
import { CREATIVE_NAME_FROM_ID } from 'constants/creatives';

export interface ChangeCreativeDialogPropTypes {
  onClose: () => void;
  onChangeCreativeType: () => void;
  creativeTypeId?: number;
}
export const ChangeCreativeDialog = (props: ChangeCreativeDialogPropTypes) => {
  const { onClose, onChangeCreativeType, creativeTypeId } = props;
  return (
    <Dialog open>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        You are about to change the creative type of this campaign. This action will remove the
        current selection of the{' '}
        <Typography weight="demi">
          {/* @ts-ignore */}
          {CREATIVE_NAME_FROM_ID[(creativeTypeId as number) ?? 11]}
        </Typography>{' '}
        creatives for this campaign.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          No
        </Button>
        <Button
          onClick={() => {
            onChangeCreativeType();
            onClose();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
