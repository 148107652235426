import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ReachParameters } from 'api/Reach';
import { metersToMiles } from 'utils/format';

export function getReachParameters(state: AppState): ReachParameters {
  const { demographic, advanced, map } = state;

  let stateIds = '';
  if (advanced.sidebarCampaignInfo[CampaignInfoField.states]) {
    const selectedState = advanced.sidebarCampaignInfo[CampaignInfoField.states];
    stateIds = selectedState.map((i) => i.value).join(',');
  }

  const whiteListedLocationIds = advanced?.whiteLists?.whiteListedLocationIds || [];
  const circles = map.preSavedCircles
    .filter(
      (circle) =>
        circle.isInclude && (!circle.filename || whiteListedLocationIds.includes(+circle.id)),
    )
    .map((circle) => ({
      unit: 'mile',
      address: circle.address,
      latitude: circle.lat,
      radius: metersToMiles(circle.getRadius()),
      type: circle.type,
      longitude: circle.lng,
      sid: circle.state,
      cid: circle.country,
    }));

  const polygons = map.preSavedPolygons
    .filter(
      (polygon) =>
        polygon.isInclude &&
        ((!polygon.fileId && !polygon.filename) || whiteListedLocationIds.includes(+polygon.id)),
    )
    .map((polygon) => ({
      polypath: polygon.getPolyPath(),
      unit: 'mile',
      address: polygon.address,
      latitude: polygon.lat,
      type: polygon.type,
      radius: metersToMiles(polygon.getRadius()),
      longitude: polygon.lng,
      sid: polygon.state,
      cid: polygon.country,
    }));

  const rectangles = map.preSavedRectangles
    .filter((rectangle) => rectangle.isInclude)
    .map((rectangle) => ({
      polypath: rectangle.getPolyPath(),
      unit: 'mile',
      address: rectangle.address,
      type: rectangle.type,
      radius: metersToMiles(rectangle.getRadius()),
      sid: rectangle.state,
      cid: rectangle.country,
      longitude: rectangle.lng,
      latitude: rectangle.lat,
    }));

  const params = {
    ageRangeIds: demographic.sidebarCampaignInfo[CampaignInfoField.age]
      .map((i) => i.value)
      .join(','),
    genderIds: demographic.sidebarCampaignInfo[CampaignInfoField.gender]
      .map((i) => i.value)
      .join(','),
    incomeRangeIds: demographic.sidebarCampaignInfo[CampaignInfoField.incomeRange]
      .map((i) => i.value)
      .join(','),
    ethnicityIds: demographic.sidebarCampaignInfo[CampaignInfoField.nationality]
      .map((i) => i.value)
      .join(','),
    stateIds,
    whiteListedZipcodes: advanced.whiteLists?.whiteListedZipcodes?.join(',') || '',
    whiteListedCityIds: advanced.whiteLists?.whiteListedCityIds?.join(',') || '',
    whiteListedGeoRadiusDetails: [...circles, ...polygons, ...rectangles],
  };

  return params;
}
