import { AxiosError, AxiosResponse } from 'axios';

import { WithRecordDimensionResponse } from '../models/Response';
import { getInstance } from './Instance';

export interface DimensionsObject {
  name: string;
  id: number;
  label: string;
  order: number;
  modellingEnabled: boolean;
}

export interface Dimensions extends DimensionsObject {
  subdimensions?: DimensionsObject[];
}

const transformDimensionData = (data: Dimensions[]) => {
  const keyIdPair: { [key: string]: number } = data.reduce((acc, item) => {
    acc[item.name] = item.id;
    if (item.subdimensions) {
      item.subdimensions.forEach((subItem) => {
        acc[subItem.name] = subItem.id;
      });
    }
    return acc;
  }, {} as { [key: string]: number });
  return {
    dimensionData: data,
    keyIdPair,
  };
};

export const getDimensions = async () => {
  try {
    const response: AxiosResponse<WithRecordDimensionResponse<Dimensions[]>> =
      await getInstance().get('/v3/bm/static/dimensions');
    return transformDimensionData(response.data.data.dimensions);
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data?.error);
  }
};
