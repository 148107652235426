import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox } from 'factor';

import { applicationActions, SetCollapseMode } from 'store/app/actions';
import { ALL_COLLAPSED, ALL_EXPANDED } from 'store/app/constants';
import { AdvertiserDetails } from './AdvertiserDetails';
import { Buttons } from '../PageButtons';
import { scrollToElement } from '../../../../utils/scroll';
import { AppState } from '../../../../models/Store';
import { advertiserActions, SetAdvertisingStatus } from '../../../../store/advertiser/actions';
import { ExistingCampaignData } from '../../../../models/ExistingCampaign';

import styles from './TermsBlock.module.scss';

interface Props extends SetAdvertisingStatus, SetCollapseMode {
  isPoliticalAdvertising: boolean;
  editableCampaign: ExistingCampaignData | null;
}

const EXPAND_TEXT = 'Expand All Sections';
const COLLAPSE_TEXT = 'Collapse All Sections';

export const TermsBlockComponent = (props: Props) => {
  const { isPoliticalAdvertising, editableCampaign, setAdvertisingStatus, setCollapseMode } = props;

  const [expandCollapseText, setExpandCollapseText] = useState<string>(EXPAND_TEXT);
  const editableCampaignRef = useRef<ExistingCampaignData | null>(editableCampaign);

  useEffect(() => {
    if (
      editableCampaign &&
      editableCampaign !== editableCampaignRef.current &&
      editableCampaign.politicalAdvertiserClientId
    ) {
      editableCampaignRef.current = editableCampaign;
      setAdvertisingStatus(true);
    }
  }, [editableCampaign, setAdvertisingStatus]);

  const handleCheckboxChange = (value: boolean) => {
    setAdvertisingStatus(value);
    setTimeout(() => {
      scrollToElement('#politicalAd');
    }, 0);
  };

  const handleExpandCollapse = useCallback(() => {
    if (expandCollapseText === EXPAND_TEXT) {
      setCollapseMode(ALL_EXPANDED);
      setExpandCollapseText(COLLAPSE_TEXT);
    } else {
      setCollapseMode(ALL_COLLAPSED);
      setExpandCollapseText(EXPAND_TEXT);
    }
  }, [expandCollapseText, setCollapseMode]);

  return (
    <>
      {isPoliticalAdvertising && <AdvertiserDetails />}
      <div
        data-qa="94"
        className="pt-3 pb-3 pl-3 d-flex align-items-center justify-content-between"
      >
        <div data-qa="95" className={styles.checkboxWrapper}>
          <Checkbox
            name="politicalAd"
            checked={isPoliticalAdvertising}
            onChange={handleCheckboxChange}
          />
          <div
            data-qa="96"
            className={`${styles.checkboxText} ${isPoliticalAdvertising ? 'checked' : ''}`}
            onClick={() => handleCheckboxChange(!isPoliticalAdvertising)}
          >
            This is a political campaign
          </div>
        </div>
      </div>
      <div data-qa="97" className={styles.fixedBottomBar}>
        <span
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              handleExpandCollapse();
              e.stopPropagation();
            }
          }}
          onClick={handleExpandCollapse}
          className={`${styles.expandCollapseButton} ${
            expandCollapseText === EXPAND_TEXT ? '' : 'expand'
          }`}
        >
          {expandCollapseText}
        </span>
        <div className="d-flex">
          <Buttons />
        </div>
      </div>
    </>
  );
};

const mapState = (state: AppState) => ({
  isPoliticalAdvertising: state.advertiser.isPoliticalAdvertising,
  editableCampaign: state.app.editableCampaign,
});

const mapAction = {
  setAdvertisingStatus: advertiserActions.setAdvertisingStatus,
  setCollapseMode: applicationActions.setCollapseMode,
};

export const TermsBlock = connect(mapState, mapAction)(TermsBlockComponent);
