import { StateCreator } from 'zustand';
import { CampaignPoliticalAdvertiserSlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  politicalAdvertiserClientId: null,
  isPoliticalCampaign: false,
};

export const createPoliticalAdvertiserBlock: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CampaignPoliticalAdvertiserSlice
> = (set) => ({
  politicalAdvertiser: {
    ...initialState,
    setPoliticalAdvertiserClientId: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          politicalAdvertiser: {
            ...prev.politicalAdvertiser,
            politicalAdvertiserClientId: value,
          },
        }),
        false,
        'setPoliticalAdvertiserClientId',
      ),
    setIsPoliticalCampaign: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          politicalAdvertiser: {
            ...prev.politicalAdvertiser,
            isPoliticalCampaign: value,
          },
        }),
        false,
        'setIsPoliticalCampaign',
      ),
    resetCampaignPoliticalAdvertiserStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          politicalAdvertiser: {
            ...prev.politicalAdvertiser,
            ...initialState,
          },
        }),
        false,
        'resetLocationBlockStore',
      ),
    initCampaignPoliticalAdvertiserBlock: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          politicalAdvertiser: {
            ...prev.politicalAdvertiser,
            ...value,
          },
        }),
        false,
        'initCampaignPoliticalAdvertiserBlock',
      ),
  },
});
