/* eslint-disable react/static-property-placement */
/* global google */
import React, { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Geocode from 'react-geocode';

import { mapActions } from 'store/map/actions';
import { LatLngLiteral, Library, MapOptions } from '../../models/Google';

const KEY = 'AIzaSyBnqYSOOEmYEXMydHgfsF_6dys_6JH1958';

Geocode.setApiKey(KEY);

export const MapContext = createContext<google.maps.Map | null>(null);

interface Props {
  center?: LatLngLiteral;
  zoom?: number;
  id?: string;
  options?: MapOptions;
  libraries?: Library[];
  innerRef?: (map: google.maps.Map) => void;
  onBoundsChanged?: () => void;
  onClick: (e: google.maps.MouseEvent, map?: google.maps.Map | null) => void;
  setDrawerMode: any;
  drawerMode: any;
  prevDrawerMode: any;
  children?: React.ReactNode;
}

export const Map = (props: Props) => {
  const {
    center = { lat: 41.021294, lng: -101.973788 },
    zoom = 4,
    id = 'map',
    options = {},
    libraries = [],
    innerRef,
    drawerMode,
    setDrawerMode,
    prevDrawerMode,
    onBoundsChanged,
    children,
    onClick,
  } = props;

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const dispatch = useDispatch();

  const onLoad = (loadedMap: google.maps.Map) => {
    if (loadedMap) {
      if (innerRef) {
        innerRef(loadedMap);
      }
      setMap(loadedMap);
      dispatch(mapActions.initialMap());
    }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: KEY,
    libraries,
    language: 'en',
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, please refresh.</div>;
  }

  if (!isLoaded) {
    return <>Loading...</>;
  }

  return (
    <GoogleMap
      onLoad={onLoad}
      options={options}
      id={id}
      mapContainerStyle={{
        height: '100%',
        width: '100%',
      }}
      zoom={zoom}
      center={center}
      onBoundsChanged={onBoundsChanged}
      onClick={(e: any) => {
        const shouldSetPreviousMode =
          !drawerMode &&
          prevDrawerMode &&
          (prevDrawerMode === google.maps.drawing.OverlayType.POLYGON ||
            prevDrawerMode === google.maps.drawing.OverlayType.RECTANGLE);

        if (shouldSetPreviousMode) {
          setDrawerMode(prevDrawerMode);
        }

        onClick(e);
      }}
    >
      <MapContext.Provider value={map}>{map ? children : null}</MapContext.Provider>
    </GoogleMap>
  );
};
