import React from 'react';
import { Switch } from 'react-router';

import { DataDogRumRoute } from 'iqm-framework';
import { NavigationWrapper } from '../../components/NavigationWrapper';
import { routes } from './routes';

const MainPageComponent = () => {
  return (
    <>
      <NavigationWrapper />
      <Switch>
        {routes.map((route) => {
          return (
            <DataDogRumRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
              useHashForViewName
            />
          );
        })}
      </Switch>
    </>
  );
};

export const MainPage = MainPageComponent;
