import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@applift/factor';

export interface ApplyBidModellingDialogType {
  setShowApplyBidModellingDialog: () => void;
  applyBidModelling: () => void;
}

export const ApplyBidModellingDialog = (props: ApplyBidModellingDialogType) => {
  const { setShowApplyBidModellingDialog, applyBidModelling } = props;

  return (
    <Dialog open>
      <DialogTitle>Apply Advanced Modelling?</DialogTitle>
      <DialogContent>
        This action will turn OFF the bid shading once any modelling has been applied. Are you sure
        you want to apply advanced modelling?
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            setShowApplyBidModellingDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            applyBidModelling();
            setShowApplyBidModellingDialog();
          }}
        >
          Apply Advanced Modelling
        </Button>
      </DialogActions>
    </Dialog>
  );
};
