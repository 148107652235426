import * as React from 'react';
import { OptionId } from 'iqm-framework';
import { connect } from 'react-redux';
import { InfoCircle } from '@applift/icons';
import moment, { Moment } from 'moment';
import { Box, Col, Divider, Row, TextField, Typography } from '@applift/factor';

import { SingleDatePicker } from 'components/SingleDatePicker';

import { checkForFewerValidation, greaterZeroValidation } from 'utils/validationRules';
import { useStore } from 'zustandStore';
import { Timezone } from 'components/Timezone';
import { useTimezone } from 'hooks/useTimezone';
import { BlockWiseErrorType, ErrorStateForComponentType, StoreFilter } from 'models/ZustandStore';
import { AppState } from 'models/Store';
import { PG_CAMPAIGN_COMPONENTS } from 'constants/pgCampaign';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { useCustomerConfigInfo } from 'hooks/useUser';
import { isFifteenMinutesApart } from 'utils/date';
import { useCampaignBudgetInfo } from 'hooks/useCampaign';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { localStorageService } from 'services/localStorage';
import { formatNumberWithDecimal } from 'utils/format';
import { nearestFutureMinutes } from 'utils/roundTime';

import { BlockWrapper } from '../BlockWrapper';
import { AmountField } from './components/AmountField';
import { SpendingBudgetWarningDialog } from './components/SpendingBudgetWarningDialog';

export interface CampaignInfoBlockPropTypes {
  orgTimezone: OptionId<string>;
  ioId: number | null;
  owId?: number;
}

enum StartDateError {
  BEFORE_IO_START = 'BEFORE_IO_START',
  BEFORE_CURRENT_TIME = 'BEFORE_CURRENT_TIME',
}

const currentDate = new Date();
const afterFifteenMinute = new Date(currentDate);
afterFifteenMinute.setMinutes(currentDate.getMinutes() + 30);

const CampaignInfoBlockComponent = React.forwardRef<HTMLDivElement, CampaignInfoBlockPropTypes>(
  (props, ref) => {
    const { orgTimezone, owId } = props;
    const spendingBudgetStorageVal: { data: boolean } =
      localStorageService?.getLocal('spendingBudget');
    const [showAddSpendingBudgetWarning, setShowAddSpendingBudgetWarning] =
      React.useState<boolean>(false);
    const [budgetInfoLocal, setBudgetInfoLocal] = React.useState<number>();
    const errorStartDateTimestamp = React.useRef<number | null>();
    const [startDateError, setStartDateError] = React.useState<StartDateError | null>();

    const [
      campaignName,
      spendingBudget,
      maxBidPrice,
      startDate,
      endDate,
      timezone,
      setCampaignName,
      setSpendingBudget,
      setMaxBidPrice,
      setStartDate,
      setEndDate,
      setTimezone,
      ioDetails,
      isEditMode,
      campaignInfoBlockErrorFields,
      resetError,
      resetErrorStore,
      campaignDetails,
      setTotalImpressions,
      totalImpressions,
      setBlockFieldsError,
      pgFeesPercentage,
      pgFeesBudget,
      totalBudget,
      setTotalBudget,
      setPgFeesBudget,
    ] = useStore((state: StoreFilter) => [
      state.campaignInfoBlock.campaignName,
      state.campaignInfoBlock.spendingBudget,
      state.campaignInfoBlock.maxBidPrice,
      state.campaignInfoBlock.startDate,
      state.campaignInfoBlock.endDate,
      state.campaignInfoBlock.timezone,
      state.campaignInfoBlock.setCampaignName,
      state.campaignInfoBlock.setSpendingBudget,
      state.campaignInfoBlock.setMaxBidPrice,
      state.campaignInfoBlock.setStartDate,
      state.campaignInfoBlock.setEndDate,
      state.campaignInfoBlock.setTimezone,
      state.pgCampaignPage.ioDetails,
      state.pgCampaignPage.isEditMode,
      state.errorFields.campaignInfoBlock,
      state.errorFields.resetError,
      state.errorFields.resetErrorStore,
      state.pgCampaignPage.campaignDetails,
      state.campaignInfoBlock.setTotalImpressions,
      state.campaignInfoBlock.totalImpressions,
      state.errorFields.setBlockFieldsError,
      state.campaignInfoBlock.pgFeesPercentage,
      state.campaignInfoBlock.pgFeesBudget,
      state.campaignInfoBlock.totalBudget,
      state.campaignInfoBlock.setTotalBudget,
      state.campaignInfoBlock.setPgFeesBudget,
    ]);

    const calculatedTotalBudget = React.useCallback(() => {
      if (maxBidPrice && totalImpressions) {
        const bid = maxBidPrice;
        const totalI = totalImpressions;
        return (bid * totalI) / 1000;
      }
      return 0;
    }, [maxBidPrice, totalImpressions]);

    const { data: customerConfigData } = useCustomerConfigInfo(owId as number);
    const { mutate: getCampaignBudgetInfoMutation } = useCampaignBudgetInfo({
      onSuccess: (res: any) => {
        const { budgetTotal, spendingBudget, pgFeesBudget } = res;
        if (spendingBudget) {
          setSpendingBudget(spendingBudget);
        }
        if (budgetTotal) {
          setTotalBudget(budgetTotal);
        }
        if (pgFeesBudget) {
          setPgFeesBudget(pgFeesBudget);
        }
      },
    });

    const { data: timezones } = useTimezone();

    React.useEffect(() => {
      if (isEditMode) {
        getCampaignBudgetInfoMutation({
          budgetTypeId: ioDetails?.ioBudgetTypeId as number,
          campaignTypeId: CAMPAIGN_TYPE_BY_NAME.pg,
          startTime: startDate as number,
          timezone: timezone?.id as number,
          ...(ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
            ? { spendingBudget: spendingBudget as number }
            : { totalImpressions: totalImpressions as number }),
          maxBid: maxBidPrice as number,
          campaignId: campaignDetails?.id,
        });
      }
    }, [
      campaignDetails?.id,
      getCampaignBudgetInfoMutation,
      ioDetails?.ioBudgetTypeId,
      isEditMode,
      maxBidPrice,
      spendingBudget,
      startDate,
      timezone?.id,
      totalImpressions,
    ]);

    const currentPgCampaignFees = React.useMemo(
      () => (isEditMode ? pgFeesPercentage : customerConfigData?.pgCampaignFees),
      [customerConfigData?.pgCampaignFees, isEditMode, pgFeesPercentage],
    );

    React.useEffect(() => {
      if (!timezone) {
        const currentTimezone = timezones
          ?.filter((val: { id: number | undefined }) => val.id === ioDetails?.ioTimeZoneId)
          .map((val: { name: any }) => ({ ...val, value: val.name }));

        if (currentTimezone) {
          setTimezone(currentTimezone[0] as unknown as OptionId<string>);
        }
      }
    }, [ioDetails?.ioTimeZoneId, orgTimezone, setTimezone, timezone, timezones]);

    const calendarMinimumDate =
      ioDetails?.ioStartTime && ioDetails.ioStartTime > moment().valueOf()
        ? ioDetails.ioStartTime
        : moment().valueOf();

    const getCampaignStartDatePrefill = () => {
      const ioStartTime = ioDetails?.ioStartTime as number;
      const futureStartDate = nearestFutureMinutes(10, moment().add(10, 'minutes')).valueOf();

      if (moment(ioStartTime).isBefore(moment())) {
        return futureStartDate;
      }

      return Math.max(ioStartTime, futureStartDate);
    };

    React.useEffect(() => {
      if (!isEditMode) {
        setStartDate(getCampaignStartDatePrefill() / 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ioDetails?.ioStartTime, isEditMode, setStartDate]);

    const errorState: ErrorStateForComponentType = React.useMemo(
      () =>
        campaignInfoBlockErrorFields
          ? Object.fromEntries(
              campaignInfoBlockErrorFields?.map((val: BlockWiseErrorType) => [val.field, val]),
            )
          : {},
      [campaignInfoBlockErrorFields],
    ) as ErrorStateForComponentType;

    const pgCampaignFeeBudget = React.useMemo(() => {
      if (isEditMode) {
        return pgFeesBudget;
      }
      if (spendingBudget && ioDetails?.ioBudgetTypeId === 1) {
        return (spendingBudget * (currentPgCampaignFees ?? 0)) / 100;
      }
      if (calculatedTotalBudget()) {
        return (calculatedTotalBudget() * (currentPgCampaignFees ?? 0)) / 100;
      }
      return 0;
    }, [
      calculatedTotalBudget,
      currentPgCampaignFees,
      ioDetails?.ioBudgetTypeId,
      isEditMode,
      pgFeesBudget,
      spendingBudget,
    ]);

    const pgCampaignTotalBudget = React.useMemo(() => {
      if (isEditMode) {
        return totalBudget;
      }
      if (spendingBudget && ioDetails?.ioBudgetTypeId === 1) {
        return spendingBudget + (spendingBudget * (currentPgCampaignFees ?? 0)) / 100;
      }
      if (calculatedTotalBudget() && ioDetails?.ioBudgetTypeId === 2) {
        return (
          calculatedTotalBudget() + (calculatedTotalBudget() * (currentPgCampaignFees ?? 0)) / 100
        );
      }
      return 0;
    }, [
      calculatedTotalBudget,
      currentPgCampaignFees,
      ioDetails?.ioBudgetTypeId,
      isEditMode,
      spendingBudget,
      totalBudget,
    ]);

    React.useEffect(() => {
      if (startDate && startDateError && errorState?.startDate?.field !== 'startDate') {
        setBlockFieldsError({
          blockName: 'campaignInfoBlock',
          errorText:
            startDateError === StartDateError.BEFORE_CURRENT_TIME
              ? "Start date can't be in past"
              : "Campaign's start time can't be before the IO start time",
          field: 'startDate',
        });
      } else if (!startDateError && errorState?.startDate?.field === 'startDate') {
        resetError({
          blockName: 'campaignInfoBlock',
          field: 'startDate',
        });
      }
    }, [
      errorState?.startDate?.field,
      resetError,
      setBlockFieldsError,
      startDate,
      timezone,
      startDateError,
    ]);

    React.useEffect(() => {
      if (startDate) {
        if (endDate && !isFifteenMinutesApart(startDate * 1000, endDate * 1000)) {
          setBlockFieldsError({
            blockName: 'campaignInfoBlock',
            errorText: "Running period can't be less than 15 minutes",
            field: 'endDate',
          });
        } else if (errorState?.endDate?.field === 'endDate' || !endDate) {
          resetError({ blockName: 'campaignInfoBlock', field: 'endDate' });
        }
      }
    }, [endDate, errorState?.endDate?.field, resetError, setBlockFieldsError, startDate]);

    React.useEffect(() => {
      if (campaignName && errorState?.campaignName) {
        resetError({
          blockName: 'campaignInfoBlock',
          field: 'campaignName',
        });
      }
    }, [campaignName, errorState?.campaignName, resetError]);

    React.useEffect(() => {
      if (spendingBudget && errorState?.spendingBudget) {
        resetError({
          blockName: 'campaignInfoBlock',
          field: 'campaignName',
        });
      }
    }, [errorState?.spendingBudget, resetError, spendingBudget]);

    React.useEffect(() => {
      if (maxBidPrice && errorState?.maxbidPrice) {
        resetError({
          blockName: 'campaignInfoBlock',
          field: 'campaignName',
        });
      }
    }, [errorState?.maxbidPrice, maxBidPrice, resetError]);

    React.useEffect(() => {
      if (maxBidPrice) {
        if (
          errorState?.endDate?.field === 'endDate' &&
          errorState?.maxbidPrice?.errorText?.includes('zero')
        ) {
          resetError({ blockName: 'campaignInfoBlock', field: 'maxbidPrice' });
        }
      }
    }, [errorState?.endDate?.field, errorState?.maxbidPrice?.errorText, maxBidPrice, resetError]);

    React.useEffect(() => {
      resetErrorStore();
    }, [resetErrorStore]);

    return (
      <div ref={ref}>
        <BlockWrapper
          title="Campaign Info"
          icon={<InfoCircle fontSize={24} color="primary" />}
          defaultExpanded
          expanded
          showExpandIcon={false}
          id={PG_CAMPAIGN_COMPONENTS.campaignInfoBlock}
        >
          <Box>
            <Row>
              <Col xs={4}>
                <TextField
                  variant="outlinedDash"
                  fullWidth
                  onBlur={() => setCampaignName(campaignName.trim())}
                  onChange={(e: any) => setCampaignName(e.target.value)}
                  value={campaignName}
                  label="Campaign Name"
                  placeholder="Campaign Name"
                  error={Boolean(errorState.campaignName)}
                  helperText={errorState?.campaignName?.errorText}
                />
              </Col>
              <Col xs={4}>
                <Timezone
                  value={timezone}
                  onChange={(val) => {
                    setTimezone(val);
                  }}
                  label="Timezone"
                />
              </Col>
            </Row>
            <Row sx={{ mt: 24 }}>
              <Col xs={4}>
                <AmountField
                  budgetTypeId={ioDetails?.ioBudgetTypeId}
                  disabled={
                    isEditMode && ['running', 'paused'].includes(campaignDetails?.status as string)
                  }
                  isBlue
                  fieldLabel="Spending Budget"
                  handleChange={(val: string) => {
                    const currentBudget =
                      ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                        ? Number(spendingBudget ?? 0)
                        : Number(totalImpressions ?? 0);
                    if (
                      (!spendingBudgetStorageVal || !spendingBudgetStorageVal?.data) &&
                      currentBudget !== Number(val)
                    ) {
                      setBudgetInfoLocal(
                        ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                          ? Number(spendingBudget ?? 0)
                          : Number(totalImpressions ?? 0),
                      );
                      setShowAddSpendingBudgetWarning(true);
                    }
                    if (ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
                      return setTotalImpressions(Number(val));
                    }
                    return setSpendingBudget(Number(val ?? 0));
                  }}
                  dynamicHeight
                  placeholder={
                    ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? '000.00' : '00,000'
                  }
                  value={
                    ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                      ? String(spendingBudget ?? 0)
                      : String(totalImpressions ?? 0)
                  }
                  tooltipText="Total Budget spent on the inventory"
                  validationRules={[
                    {
                      ...greaterZeroValidation(Boolean(errorState?.spendingBudget?.field)),
                      name:
                        campaignDetails?.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                          ? 'Spending Budget'
                          : 'Total Impressions',
                    },
                  ]}
                  validationKeys={[
                    String(spendingBudget),
                    Boolean(errorState?.spendingBudget?.field),
                  ]}
                  footer={
                    ioDetails?.ioBudgetTypeId === 1 ? (
                      <Box sx={{ py: 12 }}>
                        <Divider variant="fullWidth" sx={{ mr: 16 }} style={{ opacity: '10%' }} />
                        <Box
                          sx={{
                            textColor: 'neutral-75',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'between',
                            pr: 24,
                            mt: 8,
                          }}
                        >
                          <Typography variant="label">
                            PG Campaign Fees ({currentPgCampaignFees ?? 0}%):{' '}
                          </Typography>
                          <Typography>
                            ${formatNumberWithDecimal(pgCampaignFeeBudget ?? 0) || 0}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            textColor: 'neutral-75',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'between',
                            pr: 24,
                            textWeight: 'demi',
                            mt: 4,
                          }}
                        >
                          <Typography variant="label">Total Budget: </Typography>
                          <Typography>
                            ${formatNumberWithDecimal(pgCampaignTotalBudget ?? 0)}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ py: 12 }}>
                        <Divider variant="fullWidth" sx={{ mr: 16 }} style={{ opacity: '10%' }} />
                        <Box
                          sx={{
                            textColor: 'neutral-75',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'between',
                            pr: 24,
                            mt: 8,
                          }}
                        >
                          <Typography variant="label">Estimated Media Spent: </Typography>
                          <Typography>
                            $
                            {isEditMode
                              ? formatNumberWithDecimal(spendingBudget ?? 0)
                              : formatNumberWithDecimal(calculatedTotalBudget())}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            textColor: 'neutral-75',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'between',
                            pr: 24,
                            mt: 4,
                          }}
                        >
                          <Typography variant="label">
                            PG Campaign Fees ({currentPgCampaignFees ?? 0}%):{' '}
                          </Typography>
                          <Typography>
                            ${formatNumberWithDecimal(pgCampaignFeeBudget || 0)}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            textColor: 'neutral-75',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'between',
                            pr: 24,
                            textWeight: 'demi',
                            mt: 4,
                          }}
                        >
                          <Typography variant="label">Estimated Total Media Spent: </Typography>
                          <Typography>
                            ${formatNumberWithDecimal(pgCampaignTotalBudget ?? 0)}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  }
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    mt: 4,
                    textColor: 'neutral-500',
                    width: 75,
                  }}
                >
                  <InfoCircle fontSize={16} sx={{ textColor: 'neutral-400', mt: 2 }} />
                  <Typography sx={{ ml: 4 }} gutterBottom={false} component="span" variant="label">
                    Spending budget can’t be updated after the campaign starts running.
                  </Typography>
                </Box>
              </Col>
              <Col xs={4}>
                <AmountField
                  fieldLabel="Max Bid Price"
                  handleChange={(val: string) => {
                    setMaxBidPrice(Number(val ?? 0));
                  }}
                  max={1000}
                  placeholder="000.00"
                  value={String(maxBidPrice ?? 0)}
                  tooltipText="This is a Fixed Price which is used for bidding in Programmatic Guaranteed (PG) campaigns"
                  validationRules={[
                    {
                      ...greaterZeroValidation(Boolean(errorState?.maxbidPrice?.field)),
                      name: 'Max Bid Price',
                    },
                    {
                      ...checkForFewerValidation(
                        Boolean(errorState?.maxbidPrice?.field),
                        spendingBudget || 0,
                      ),
                      comparedFieldName: 'Spending Budget',
                      name: 'Max Bid Price',
                    },
                  ]}
                  validationKeys={[
                    String(spendingBudget),
                    String(maxBidPrice),
                    Boolean(errorState?.maxbidPrice?.field),
                  ]}
                />
              </Col>
            </Row>
            <Row sx={{ mt: 24 }}>
              <Col xs={4}>
                <Box style={{ width: '65%' }}>
                  <SingleDatePicker
                    onError={(_e: string, timestamps: any) => {
                      const { epochDate } = timestamps || {};
                      errorStartDateTimestamp.current = epochDate;
                      if (epochDate) {
                        if (ioDetails?.ioStartTime && epochDate <= ioDetails?.ioStartTime) {
                          setStartDateError(StartDateError.BEFORE_IO_START);
                        }
                        if (epochDate <= moment().valueOf()) {
                          setStartDateError(StartDateError.BEFORE_CURRENT_TIME);
                        }
                      }
                    }}
                    updateDate={(date) => {
                      // onError fires before updateDate; must check if the selected date has changed
                      // before clearing the start date error
                      if (errorStartDateTimestamp.current !== date) {
                        setStartDateError(null);
                      }

                      setStartDate(date / 1000);
                    }}
                    timezone={timezone}
                    date={(startDate as number) * 1000}
                    calendarMinimumDate={calendarMinimumDate}
                    label="Start Date"
                    isDisabled={['running', 'paused'].includes(campaignDetails?.status as string)}
                    placeholderText="Select Start Date"
                    preselected={
                      calendarMinimumDate !== ioDetails?.ioStartTime
                        ? [
                            {
                              key: 'today',
                              title: 'Today',
                              date: (
                                tz: {},
                                convert: (date: Moment) => Moment,
                                getCurrentDay: (tz: {}) => Moment,
                              ) => convert(getCurrentDay(tz)?.add(15, 'minutes')),
                            },
                          ]
                        : []
                    }
                    errorMsg={
                      errorState?.startDate?.field === 'startDate'
                        ? errorState?.startDate?.errorText
                        : undefined
                    }
                  />
                </Box>
              </Col>
              <Col xs={4}>
                <Box style={{ width: '65%' }}>
                  <SingleDatePicker
                    timezone={timezone}
                    updateDate={(date) => {
                      setEndDate(date / 1000);
                    }}
                    calendarMinimumDate={moment(calendarMinimumDate).add(15, 'minutes')}
                    date={(endDate as number) ? (endDate as number) * 1000 : undefined}
                    label="End Date (Optional)"
                    placeholderText="Select End Date"
                    isClearable
                    errorMsg={
                      errorState?.endDate?.field === 'endDate'
                        ? errorState?.endDate?.errorText
                        : undefined
                    }
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...(calendarMinimumDate === ioDetails?.ioStartTime ? { preselected: [] } : {})}
                  />
                </Box>
              </Col>
            </Row>
          </Box>
        </BlockWrapper>
        {showAddSpendingBudgetWarning ? (
          <SpendingBudgetWarningDialog
            onCancel={() => {
              if (ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
                setTotalImpressions(Number(budgetInfoLocal));
              } else {
                setSpendingBudget(Number(budgetInfoLocal ?? 0));
              }
            }}
            budgetTypeId={ioDetails?.ioBudgetTypeId}
            onClose={() => setShowAddSpendingBudgetWarning(false)}
            budget={
              ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                ? spendingBudget
                : totalImpressions
            }
          />
        ) : null}
      </div>
    );
  },
);

const mapState = (state: AppState) => ({
  orgTimezone: state.advanced.sidebarCampaignInfo[CampaignInfoField.timezone],
  ioId: state.app.savedIoId,
  owId: state.auth.userData.owId,
});

export const CampaignInfoBlock = connect(mapState)(CampaignInfoBlockComponent);
