import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'factor';
import { Option } from 'models/Option';
import { API } from 'api';
import { AppState } from 'models/Store';
import {
  technologyActions,
  SetTechnologyField,
  SelectTechnologyField,
} from 'store/technology/actions';
import { Logger } from 'utils/logger';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { applicationActions, ResetError } from 'store/app/actions';
import { ErrorCreatingResponse } from 'models/Response';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { usePageLocation } from 'utils/hooks/usePageLocation';
import { DeviceTypesMapper } from 'models/Device';
import styles from './style.module.scss';

const CTV_ID = 14;
const CONNECTED_TV_DEVICE_ID = 13;

type Props = SetTechnologyField &
  SelectTechnologyField &
  ResetError & {
    errorCreating: ErrorCreatingResponse | null;
    isAuthorized: boolean;
    deviceTypes: Option<number>[];
    selectedDeviceTypes: Option<number>[];
    editableCampaign: ExistingCampaignData | null;
    selectedTraffic: Option<number>[];
  };

const DeviceTypeSelectWrapperComponent = (props: Props) => {
  const {
    isAuthorized,
    deviceTypes,
    selectedDeviceTypes,
    setTechnologyField,
    selectTechnologyField,
    errorCreating,
    resetError,
    editableCampaign,
    selectedTraffic,
  } = props;

  const [isEditPage] = usePageLocation();

  const isInitialized = useRef(false);
  const onlyAllowConnectedTv = selectedTraffic.length === 1 && selectedTraffic[0].value === CTV_ID;

  const sortedDeviceTypes = useMemo(() => {
    const devices = [deviceTypes[0], deviceTypes[3], deviceTypes[1], deviceTypes[2]];
    /* eslint-disable no-nested-ternary */
    return deviceTypes.length && !onlyAllowConnectedTv
      ? devices
      : deviceTypes.length && onlyAllowConnectedTv
      ? devices.map((one, index) => (index > 0 ? { ...one, isDisabled: true } : one))
      : [];
  }, [deviceTypes, onlyAllowConnectedTv]);

  useEffect(() => {
    const fetchDeviceTypes = async () => {
      try {
        const list = await API.List.DeviceTypes();
        isInitialized.current = true;
        const types = list.map((t) => ({
          label: t.name,
          value: t.id,
        }));
        setTechnologyField({
          key: 'deviceTypes',
          value: types,
        });
        if (!isEditPage()) {
          selectTechnologyField({
            key: CampaignInfoField.deviceTypes,
            value: types,
          });
        }
      } catch (e) {
        Logger.log('[fetchDeviceTypes Error]', e);
      }
    };
    if (!isInitialized.current) {
      fetchDeviceTypes();
    }
  }, [
    isAuthorized,
    setTechnologyField,
    selectTechnologyField,
    isEditPage,
    editableCampaign,
    isInitialized,
  ]);

  useEffect(() => {
    selectTechnologyField({
      key: CampaignInfoField.deviceTypes,
      value: onlyAllowConnectedTv
        ? deviceTypes.filter((i) => i.value === CONNECTED_TV_DEVICE_ID)
        : deviceTypes,
    });
  }, [deviceTypes, selectTechnologyField, onlyAllowConnectedTv]);

  useEffect(() => {
    if (editableCampaign && editableCampaign.deviceType && isInitialized.current) {
      const savedDeviceTypesIds = editableCampaign.deviceType.split(',').map((i) => +i);
      selectTechnologyField({
        key: CampaignInfoField.deviceTypes,
        value: deviceTypes.filter((i) => savedDeviceTypesIds.includes(i.value)),
      });
    }
  }, [editableCampaign, deviceTypes, selectTechnologyField, isInitialized]);

  const handleClick =
    (isSelectedType: Option<number> | undefined, deviceType: Option<number>) => () => {
      const items = isSelectedType
        ? selectedDeviceTypes.filter((type: Option<number>) => type.value !== deviceType.value)
        : [...selectedDeviceTypes, deviceType];

      selectTechnologyField({
        key: CampaignInfoField.deviceTypes,
        value: items,
      });

      if (errorCreating && errorCreating.errorField === CampaignInfoField.deviceTypes) {
        resetError();
      }
    };

  const result = sortedDeviceTypes.map((deviceType: Option<number>) => {
    const isSelectedType = selectedDeviceTypes.find(
      (type: Option<number>) => type.value === deviceType.value,
    );
    return (
      <div
        key={deviceType.value}
        tabIndex={0}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            handleClick(isSelectedType, deviceType)();
            e.stopPropagation();
          }
        }}
        onClick={handleClick(isSelectedType, deviceType)}
        className={`${styles.deviceTypeBox} ${
          deviceType.isDisabled ? styles.deviceTypeBoxDisabled : ''
        } ${isSelectedType ? styles.deviceTypeBoxSelected : ''}`}
      >
        <Icon name={DeviceTypesMapper[deviceType.value].icon ?? ''} />
        {deviceType.label === 'Connected TV' ? 'TV' : deviceType.label}
      </div>
    );
  });

  return (
    <>
      <div className={styles.deviceWrapper}>
        <div className={styles.deviceWrapperTopText}>What type of device you want to add?</div>
        <div className={styles.deviceTypeWrapper}>{result}</div>
      </div>
      <hr className={styles.deviceWrapperDivider} />
    </>
  );
};

const mapState = (state: AppState) => ({
  isAuthorized: state.auth.authorized,
  deviceTypes: state.technology.deviceTypes,
  selectedDeviceTypes: state.technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes],
  errorCreating: state.app.errorCreating,
  editableCampaign: state.app.editableCampaign,
  selectedTraffic: state.technology.sidebarCampaignInfo[CampaignInfoField.traffic],
});

const mapAction = {
  setTechnologyField: technologyActions.setTechnologyField,
  selectTechnologyField: technologyActions.selectTechnologyField,
  resetError: applicationActions.resetError,
};

export const DeviceTypeSelectWrapper = connect(
  mapState,
  mapAction,
)(DeviceTypeSelectWrapperComponent);
