import { logDataDogAction } from 'iqm-framework';
import { getActionNamespace } from '../helpers';

export const IOListPage = {
  viewCampaignsByIO: () => {
    logDataDogAction('Open Campaign List Dialog', {
      action: `${getActionNamespace()}/viewCampaignsByIODialog`,
    });
  },
};
