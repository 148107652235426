import { useHistory } from 'react-router-dom';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { WithResponse } from '@applift/conversion/lib/models/Response';
import { enqueueSnackbar } from '@applift/factor';
import { getCampaignByCreativeCount } from 'api/Count';
import { getCampaignByCreativeCountKey } from 'api/QueryKeys';
import { CampaignCreativeCountType } from 'models/Count';

export const useCampaignCreativeCount = (
  ioId: string,
  status?: string,
  options?: {
    onSuccess?: UseQueryOptions<CampaignCreativeCountType>['onSuccess'];
    onError?: UseQueryOptions<CampaignCreativeCountType>['onError'];
    enabled?: UseQueryOptions<CampaignCreativeCountType>['enabled'];
  },
) => {
  const history = useHistory();
  const ioIds = ioId.split(',').map((val) => +val);
  const data = useQuery(
    // @ts-ignore
    getCampaignByCreativeCountKey.keys('getCampaignByCreativeCount', ioIds, status),
    getCampaignByCreativeCount,
    {
      cacheTime: 0,
      onError: (e: WithResponse) => {
        history.push('/io-list');
        enqueueSnackbar(
          e.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong. Please try after sometime.',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
    },
  );
  return data;
};
