import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';

import { FetchUserDetail } from 'api/App';
import { CustomerConfigResponse, getCustomerConfig } from 'api/Campaign';
import { getCustomerConfigQueryKey } from 'api/QueryKeys';
import { WithResponse } from '../models/Response';

export const getDimensionAndMetricsKey = {
  keys: (args: { scope: string }) => [args],
};

export const useUser = () => {
  const data = useQuery(
    getDimensionAndMetricsKey.keys({ scope: 'getUserDetail' }),
    FetchUserDetail,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e?.errorObjects ? (e.errorObjects[0]?.error as string) : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
      keepPreviousData: true,
      cacheTime: 5000,
    },
  );
  return data;
};

export const getBidShadingQueryKey = {
  keys: (args: { scope: string; owId: number }) => [args],
};

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useCustomerConfigInfo = (
  owId: number,
  onCompletion?: (res: CustomerConfigResponse) => void,
) => {
  const data = useQuery(
    getCustomerConfigQueryKey.keys('getCustomerConfig', { owId }),
    getCustomerConfig,
    {
      onError,
      onSuccess: onCompletion,
    },
  );
  return data;
};
