import moment from 'moment';

export const SECONDS_PER_DAY = 24 * 60 * 60;

// if passedTimestamp is less than currentTime plus increaseByMinutes, then it returns currentTime plus increaseByMinutes
export const getFutureTimestamp = (
  timeStamp: number,
  timezone: string,
  increaseByMinutes: number,
) => {
  const currentTimeIncreased = moment.tz(timezone).add(increaseByMinutes, 'minutes').valueOf();
  return timeStamp >= currentTimeIncreased ? timeStamp : currentTimeIncreased;
};

// checks if passed values have atleast minimalTimeDiffInMinutes between two timestamps or it increases endTimeStamp by minimalTimeDiffInMinutes
export const compareTwoTimestampsAndIncrease = (
  startTimeStamp: number,
  endTimeStamp: number,
  minimalTimeDiffInMinutes: number,
) => {
  if (startTimeStamp >= endTimeStamp) {
    return moment(startTimeStamp).add(minimalTimeDiffInMinutes, 'minutes').valueOf();
  }
  const actualTimeDiff = moment(endTimeStamp).diff(moment(startTimeStamp));
  return actualTimeDiff > minimalTimeDiffInMinutes * 60000
    ? endTimeStamp
    : moment(startTimeStamp).add(minimalTimeDiffInMinutes, 'minutes').valueOf();
};

export const getDurationInDays = (startTimeMs: number, endTimeMs: number) => {
  return moment(endTimeMs).diff(moment(startTimeMs), 'days', true);
};

export const isFifteenMinutesApart = (date1: number | null, date2: number | null) => {
  if (date1 && date2) {
    if (date1 - date2 > 0) {
      return false;
    }
    const difference = Math.abs(date2 - date1);
    const minutes = difference / (1000 * 60);
    return minutes >= 15;
  }
  return true;
};
