import React from 'react';
import { Tooltip, ButtonCircle } from 'factor';
import SearchBar from './searchBar/SearchBar';

import CreativeStatusesCount, {
  TCreativeStatus,
} from './creativeStatusesCount/CreativeStatusesCount';

import styles from './CreativesManagePanel.module.scss';

type TProps = {
  onChangeStatus: (creativeStatus: TCreativeStatus) => void;
  status: TCreativeStatus;
  searchValue: string;
  isTableView?: boolean;
  toggleView: () => void;
  onSearch: (searchValue: string) => void;
};

const CreativesManagePanel = (props: TProps) => {
  const { toggleView, searchValue, status, isTableView, onSearch, onChangeStatus } = props;

  return (
    <header className={styles.actionPanelRow}>
      <div className={`${styles.item} mr-3`}>
        <SearchBar value={searchValue} onChange={onSearch} />
      </div>
      <div className={`${styles.item} mr-3`}>
        <CreativeStatusesCount status={status} onChangeStatus={onChangeStatus} />
      </div>
      <div className={styles.globalAction}>
        <Tooltip
          label={`Show the creatives as ${isTableView ? 'cards' : 'table'}`}
          auto={false}
          position="left"
        >
          <ButtonCircle
            outline
            iconName={isTableView ? 'Table' : 'List'}
            className="_size-18"
            onClick={toggleView}
          />
        </Tooltip>
      </div>
    </header>
  );
};

export default CreativesManagePanel;
