/* eslint-disable import/no-cycle */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import keyBy from 'lodash/keyBy';
import { Button, Layout } from 'factor';
import { Option, getHeaders } from 'iqm-framework';

import { ORIGIN_URL } from 'config';
import { Creative } from 'models/Creative';
import { User } from 'models/User';
import { AppState } from 'models/Store';
import { creativesActions } from 'store/creatives/actions';
import CreativesTableView from './creativeTableView/CreativesTableView';
import CreativesManagePanel from './creativesManagePanel/CreativesManagePanel';
import CreativesGroupsList from './creativesGroupsList/CreativesGroupsList';
import { TCreativeStatus } from './creativesManagePanel/creativeStatusesCount/CreativeStatusesCount';
import SelectionActionPanel from './selectionActionPanel/SelectionActionPanel';
import { TableComponentInstance } from './creativeTableView/creativeTable/CreativeTable';
import CreativesCardList from './creativesCardList/CreativesCardList';

import styles from './styles.module.scss';

interface Props {
  authorizedUser: User;
  selectedCreativeType: Option<number>;
  selectedCreatives: Creative[];
  prevSelectedCreatives: Creative[];
  selectCreatives: (creatives: Creative[]) => void;
  handleClose: () => void;
  collapseIndex: number;
}

interface State {
  isTableView: boolean;
  isLoading: boolean;

  hasOpenedSidebar: boolean;

  selectedCreatives: Creative[];
  prevSelectedCreatives: Creative[];

  url: string;

  creativeGroupId?: number;
  searchField: string;
  status: TCreativeStatus;

  creatives: Creative[];
  allStatuses?: TCreativeStatus[];

  filteredRecords?: number;
  totalRecords?: number;
  pageNumber: number;
}

export type TCreativesPage = {
  creatives: Creative[];
  filteredRecords: number;
  totalRecords: number;
  pageNumber: number;
};

class SelectCreativesPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      url: props.authorizedUser ? this.makeRequestUrl({}) : '',
      prevSelectedCreatives: props.selectedCreatives || [],
      selectedCreatives: [],
      creatives: [],
      searchField: '',
      isTableView: true,
      isLoading: false,
      hasOpenedSidebar: true,
      pageNumber: 1,
      status: {
        creativeCount: 74,
        displayName: 'All',
        id: 0,
        status: 'all',
      },
    };
  }

  componentDidMount() {
    if (this.props.collapseIndex === 0) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(oldProps: Props, oldState: State) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!oldProps.authorizedUser && this.props.authorizedUser) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        url: this.makeRequestUrl({}),
      });
    }

    const { selectedCreatives, creatives } = this.state;
    if (oldState.creatives !== creatives && creatives.length) {
      setTimeout(() => {
        if (selectedCreatives.length) {
          const selectedMap = keyBy(selectedCreatives, 'id');
          const selected = creatives.filter((d: any) => selectedMap[d.id]);
          if (selected.length) {
            TableComponentInstance.setState({
              selected,
            });
          }
        }
      }, 50);
    }

    const { url, isTableView } = this.state;
    if (oldState.url !== url && !isTableView) {
      this.loadCreatives(1);
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  toggleView = () => {
    this.setState((state: State) => ({
      isTableView: !state.isTableView,
    }));
  };

  onCreativesLoaded = (creativesPage: TCreativesPage) => {
    const { creatives } = this.state;

    this.setState({
      creatives:
        creativesPage.pageNumber !== 1
          ? [...creatives, ...creativesPage.creatives]
          : creativesPage.creatives,
      filteredRecords: creativesPage.filteredRecords,
      // eslint-disable-next-line react/no-unused-state
      totalRecords: creativesPage.totalRecords,
      pageNumber: creativesPage.pageNumber,
    });
  };

  onChangeGroup = (newCreativeGroupId?: number) => {
    const { creativeGroupId } = this.state;

    if (creativeGroupId !== newCreativeGroupId) {
      this.setState({
        creativeGroupId: newCreativeGroupId,
        pageNumber: 1,
        creatives: [],
        selectedCreatives: [],
        url: this.makeRequestUrl({ creativeGroupId: newCreativeGroupId }),
      });
    }
  };

  onSearch = (searchField: string) => {
    this.setState({
      searchField,
      pageNumber: 1,
      url: this.makeRequestUrl({ searchField }),
    });
  };

  onChangeStatus = (status: TCreativeStatus) => {
    this.setState({
      status,
      pageNumber: 1,
      selectedCreatives: [],
      url: this.makeRequestUrl({ status }),
    });
  };

  onCreativesSelected = (selectedCreatives: Creative[], shouldClear?: boolean) => {
    if (shouldClear) {
      this.setState({
        selectedCreatives: [],
      });
    } else {
      this.setState({
        selectedCreatives,
      });
    }
  };

  getCreativeStatusParams = (status: any) => {
    return status && status.id ? status.id || '' : '1,2,3';
  };

  makeRequestUrl = (additionalProps: any) => {
    const { searchField, status, creativeGroupId } = this.state || {};

    const queryParams: any = {
      searchField,
      status,
      creativeGroupId,
      ...additionalProps,
    };

    const { authorizedUser, selectedCreativeType } = this.props;
    const creativeTypeId = selectedCreativeType && selectedCreativeType.value;

    const domainURL = authorizedUser ? authorizedUser.domainURL : '';
    if (!domainURL) {
      return '';
    }

    const q = queryParams.searchField || '';
    const id = creativeTypeId;

    let params = `searchField=${q}&creativeTypeIds=${id}`;

    if (queryParams.creativeGroupId) {
      params += `&creativeGroupId=${queryParams.creativeGroupId}`;
    }

    params += `&status=${this.getCreativeStatusParams(queryParams.status)}`;

    return `${ORIGIN_URL}/api/v2/crt/creative/list?${params}`;
  };

  loadCreatives = async (pageNumber: number) => {
    this.setState({ isLoading: true, pageNumber: 1 });

    try {
      const { url, creatives } = this.state;
      const { authorizedUser } = this.props;

      const { data } = await axios.get(`${url}&noOfEntries=50&pageNo=${pageNumber}`, {
        headers: getHeaders({ apiToken: authorizedUser.apiToken }, authorizedUser.userId),
      });

      this.setState({
        pageNumber,
        creatives:
          pageNumber !== 1 ? [...creatives, ...data.responseObject.data] : data.responseObject.data,
        filteredRecords: data.responseObject.filteredRecords,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  onSubmit = () => {
    const { selectedCreatives, prevSelectedCreatives } = this.state;
    const { selectCreatives, handleClose } = this.props;

    const allCreatives = [...prevSelectedCreatives];

    selectedCreatives.forEach((creative: Creative) => {
      if (!allCreatives.find((oldCreative) => oldCreative.id === creative.id)) {
        allCreatives.push(creative);
      }
    });

    selectCreatives(allCreatives);
    handleClose();
  };

  toggleSidebar = () => {
    const { hasOpenedSidebar } = this.state;
    this.setState({
      hasOpenedSidebar: !hasOpenedSidebar,
    });
  };

  render() {
    const { selectedCreativeType, authorizedUser, handleClose } = this.props;
    const {
      isTableView,
      isLoading,
      creatives,
      status,
      selectedCreatives,
      filteredRecords,
      searchField,
      url,
      hasOpenedSidebar,
      pageNumber,
    } = this.state;

    if (!authorizedUser) {
      return null;
    }

    if (!selectedCreativeType) {
      handleClose();
    }
    const creativeTypeId = selectedCreativeType && selectedCreativeType.value;

    const mainClass = `${selectedCreatives.length ? styles.hasSelectedCreatives : ''}`;
    const className = `${styles.selectCreativesContainer} ${mainClass}`;
    const emptyListLabel = searchField
      ? 'No results found. Try changing search parameters.'
      : 'No Data Available';

    return (
      <div className={styles.overlay}>
        <div className={className}>
          <div className={styles.moveBtnToTheTopLeftCorner}>
            <div className={styles.selectCreativesHeaderCloseBtn}>
              <button
                onClick={handleClose}
                type="button"
                className={`btn-close ${styles.btnClose}`}
              />
            </div>
          </div>
          <div className={styles.selectCreativesBody}>
            <div className={styles.selectCreativesHeader}>
              <h2>Select Creatives</h2>
            </div>
            <Layout
              opened={hasOpenedSidebar}
              toggleSidebar={this.toggleSidebar}
              className={styles.selectCreativesLayout}
              sidebarClassName={styles.selectCreativesSidebar}
              sidebar={
                <CreativesGroupsList
                  status={this.getCreativeStatusParams(status)}
                  creativeTypeId={selectedCreativeType && selectedCreativeType.value}
                  authorizedUser={authorizedUser}
                  creatives={creatives}
                  totalRecords={filteredRecords}
                  onChangeGroup={this.onChangeGroup}
                />
              }
            >
              <div className={styles.creativesList}>
                <CreativesManagePanel
                  status={status}
                  searchValue={searchField}
                  toggleView={this.toggleView}
                  onSearch={this.onSearch}
                  isTableView={isTableView}
                  onChangeStatus={this.onChangeStatus}
                />
                <SelectionActionPanel
                  selectedCreatives={selectedCreatives}
                  selectCreatives={this.onCreativesSelected}
                />
                {isTableView ? (
                  <CreativesTableView
                    url={url}
                    creatives={creatives}
                    status={status}
                    emptyTableLabel={emptyListLabel}
                    creativeTypeId={creativeTypeId}
                    onCreativesLoaded={this.onCreativesLoaded}
                    onCreativesSelected={this.onCreativesSelected}
                  />
                ) : (
                  <CreativesCardList
                    emptyListLabel={emptyListLabel}
                    pageNumber={pageNumber}
                    filteredRecords={filteredRecords}
                    loadCreatives={this.loadCreatives}
                    creatives={creatives}
                    selectedCreatives={selectedCreatives}
                    isLoading={isLoading}
                    onCreativesSelected={this.onCreativesSelected}
                  />
                )}
              </div>
            </Layout>
          </div>
          <div className={styles.selectCreativesFooter}>
            <div className={styles.buttonsWrapper}>
              <Button onClick={handleClose} className={`btn-square _cornflower-blue _map _md m-0 `}>
                Cancel
              </Button>
              <Button
                disabled={!selectedCreatives.length}
                className={`btn-square _filled _cornflower-blue _map _md m-0 `}
                onClick={this.onSubmit}
              >
                Select Creatives
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  authorizedUser: state.auth.userData,
  selectedCreativeType: state.creatives.selectedCreativeType,
  selectedCreatives: state.creatives.selectedCreatives,
  collapseIndex: state.app.collapseData.openIndex,
});

const mapAction = {
  selectCreatives: creativesActions.selectCreatives,
};

export default connect(mapStateToProps, mapAction)(withRouter<any, any>(SelectCreativesPopup));
