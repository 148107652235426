export const creativesConstants = {
  SET_CREATIVES: 'SET_CREATIVES',
  SET_CREATIVES_RTB: 'SET_CREATIVES_RTB',
  SELECT_CREATIVES: 'SELECT_CREATIVES',
  SET_CREATIVES_PLACEMENT_MAPPING: 'SET_CREATIVES_PLACEMENT_MAPPING',
  SET_CREATIVE_TYPES: 'SET_CREATIVE_TYPES',
  SELECT_CREATIVE_TYPE: 'SELECT_CREATIVE_TYPE',
  RESET_CREATIVE_STORE: 'RESET_CREATIVE_STORE',
  CHANGE_ADVERTISER_URL: 'CHANGE_ADVERTISER_URL',
  FETCH_CREATIVES: 'FETCH_CREATIVES',
  VALIDATE_CREATIVE_STORE: 'VALIDATE_CREATIVE_STORE',
  SET_ADVANCED_VIDEO_TARGETING: 'SET_ADVANCED_VIDEO_TARGETING',
  SET_ADVANCED_VIDEO_TARGETING_OPTIONS: 'SET_ADVANCED_VIDEO_TARGETING_OPTIONS',
};
