export const emptyValidation = {
  func: (value: string) => {
    return !!(value && value.trim());
  },
  error: (name: string = 'Field', rule: { [key: string]: any }) => {
    return rule?.errorMessage || `${rule?.name || name} must be filled`;
  },
};

export const emptyAfterSubmitValidation = (isConfirm: boolean) => {
  return {
    func: (value: string) => {
      return !isConfirm || !!(value && value.trim());
    },
    error: (name: string = 'Field', rule: { [key: string]: any }) => {
      return rule?.errorMessage || `${rule?.name || name} must be filled`;
    },
  };
};

export const greaterZeroValidation = (isConfirm: boolean) => {
  return {
    func: (value: number) => {
      return !isConfirm || value > 0;
    },
    error: (name: string = 'Field', rule: { [key: string]: any }) => {
      return rule?.errorMessage || `${rule?.name || name} must be greater than zero`;
    },
  };
};

export const greaterNumberValidation = (isConfirm: boolean, comparedValue: number) => {
  return {
    func: (value: number) => {
      return !isConfirm || value >= comparedValue;
    },
    error: (name: string = 'Field', rule: { [key: string]: any }) => {
      return rule?.errorMessage || `${rule?.name || name} can't be less than ${comparedValue}`;
    },
  };
};

export const checkForFewerValidation = (isConfirm: boolean, comparedValue: number) => {
  return {
    func: (value: number) => {
      return !isConfirm || !comparedValue || value <= comparedValue;
    },
    error: (name: string = 'Field', rule: { [key: string]: any }) => {
      return (
        rule?.errorMessage ||
        `${rule?.name || name} must be less or equal than ${rule && rule.comparedFieldName}!`
      );
    },
  };
};

export const checkForMoreOrEqualsValidation = (isConfirm: boolean, comparedValue: number) => {
  return {
    func: (value: number) => {
      return !isConfirm || value >= comparedValue;
    },
    error: (name: string = 'Field', rule: { [key: string]: any }) => {
      return (
        rule?.errorMessage ||
        `${rule?.name || name} must be more than or equal to ${rule && rule.comparedFieldName}`
      );
    },
  };
};

export const urlRegEx =
  /^(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const validateUrl = (isConfirm: boolean) => {
  return {
    func: (url: string) => !url || !isConfirm || urlRegEx.test(url),
    error: (name: string = 'Field', rule: any) => {
      return `${rule?.name || name} must be a valid url address`;
    },
  };
};

export const validateMultiUrl = (isConfirm: boolean) => {
  return {
    func: (url: string) =>
      !url || !isConfirm || url.split(',').every((i) => urlRegEx.test(i.trim())),
    error: (name: string = 'Field', rule: any) => {
      return `${rule?.name || name} must be a valid url address`;
    },
  };
};

export const validateOnlyLetters = (isConfirm: boolean) => {
  return {
    func: (value: string) => !value || !isConfirm || /^[a-zA-Z ]+$/.test(value),
    error: (name: string = 'Field', rule: any) => {
      return `${rule?.name || name} should not contain numbers and special characters`;
    },
  };
};

const phoneRegEx = /[\\+0-9().-]+$/;

export const validatePhone = (isConfirm: boolean) => {
  return {
    func: (value: string) => {
      return !value || !isConfirm || phoneRegEx.test(value);
    },
    error: (name: string = 'Field', rule: any) => {
      return `${rule?.name || name} should not contain letters and special characters`;
    },
  };
};

export const checkAudioFileURL = (value: string): Promise<string | undefined> => {
  const audio = new Audio();
  return new Promise((resolve, reject) => {
    audio.onerror = () => {
      audio.onerror = null;
      reject();
    };
    audio.onloadeddata = () => {
      audio.onloadeddata = null;
      resolve(value);
    };
    audio.src = value;
  });
};

export const compareNumberStrings = (str1: string, str2: string) => {
  // Split the input strings into arrays of numbers
  const arr1 = str1?.split(',').map(Number);
  const arr2 = str2?.split(',').map(Number);

  // Sort the arrays to ensure order doesn't affect the comparison
  arr1?.sort((a, b) => a - b);
  arr2?.sort((a, b) => a - b);

  // Compare the sorted arrays
  const isEqual = JSON.stringify(arr1) === JSON.stringify(arr2);
  return isEqual;
};
