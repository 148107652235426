import React from 'react';
import { ColumnDef } from '@applift/datagrid';
import { RawDataCell } from 'components/CellType';
import { InventoryGroupListResponse } from 'models/Inventory';
import { InventoryGroupTypeCell, FormattedNumberCell, CountCell } from '../../CellTypes';

export const colDef: ColumnDef<InventoryGroupListResponse['inventoryGroupList'][0]>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorKey: 'name',
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: false,
    size: 260,
  },
  {
    header: 'Type',
    id: 'groupTypeId',
    accessorKey: 'groupTypeId',
    cell: (props) => <InventoryGroupTypeCell groupId={props.renderValue() as number} />,
    enableSorting: false,
    size: 220,
  },
  {
    header: 'Count',
    id: 'inventories',
    cell: (props) => <CountCell row={props.row.original} />,
    enableSorting: false,
    size: 140,
  },
  {
    header: 'Impressions',
    id: 'impressions',
    accessorKey: 'impressions',
    cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
    enableSorting: true,
    size: 120,
  },
  {
    header: 'Reach',
    id: 'reach',
    accessorKey: 'reach',
    cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
    enableSorting: false,
    size: 120,
  },
];
