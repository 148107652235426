import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';
import { AxiosResponse } from 'axios';

import { WithResponse, WithTimestamps } from 'models/Response';
import {
  GetBudgetInfo,
  PutCampaignPriorityParams,
  createPgCampaign,
  createPgCampaignDraft,
  duplicateCampaign,
  duplicatePgCampaign,
  editDraftPgCampaign,
  editPgCampaign,
  fetchCampaignData,
  putCampaignPriority,
  updateCampaignBudget,
  updateCampaignEndDate,
  updateCampaignStatus,
} from 'api/Campaign';
import { getCampaignData, getIODetailsQueryKey, getPoliticalAdvertiserList } from 'api/QueryKeys';
import { fetchAdvertiserListQuery } from 'api/Advertiser';
import { queryClient } from '../cache/query';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e.errorObjects
      ? (e.errorObjects?.[0]?.error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useSetCampaignBudget = (onBudgetSetSuccess: (response: any) => void, ioId: string) => {
  const mutationResult = useMutation(updateCampaignBudget, {
    mutationKey: ['setCampaignBudget'],
    onSettled: () => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getIOInfo' ||
          query.queryKey?.[0]?.scope === 'getIoBudgetImpInfo',
      });
    },
    onSuccess: (res, payload) => {
      onBudgetSetSuccess(res);
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getCampaignList',
      });
    },
    onError,
  });

  return mutationResult;
};

export const useSetCampaignStatus = (onStatusSetSuccess: (response: any) => void) => {
  const mutationResult = useMutation(updateCampaignStatus, {
    mutationKey: ['setCampaignStatus'],
    onSettled: () => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getIOInfo' ||
          query.queryKey?.[0]?.scope === 'getIoBudgetImpInfo',
      });
    },
    onSuccess: (res) => {
      onStatusSetSuccess(res);
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getCampaignList',
      });
    },
    onError,
  });

  return mutationResult;
};

export const useDuplicateCampaign = (onDuplicateSuccess: (response: any) => void, ioId: string) => {
  const mutationResult = useMutation(duplicateCampaign, {
    mutationKey: ['duplicateCampaign'],
    onSettled: () => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getIOInfo' ||
          query.queryKey?.[0]?.scope === 'getIoBudgetImpInfo',
      });
    },
    onSuccess: (res) => {
      onDuplicateSuccess(res);
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getCampaignList',
      });
    },
    onError: (res: any) => {
      enqueueSnackbar(
        res?.errorMsg && typeof res.errorMsg === 'string'
          ? res.errorMsg
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        },
      );
    },
  });
  return mutationResult;
};

export const useDuplicatePGCampaign = (
  onDuplicateSuccess: (response: any) => void,
  ioId: string,
) => {
  const mutationResult = useMutation(duplicatePgCampaign, {
    mutationKey: ['duplicatePGCampaign'],
    onSettled: () => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getIOInfo' ||
          query.queryKey?.[0]?.scope === 'getIoBudgetImpInfo',
      });
    },
    onSuccess: (res) => {
      onDuplicateSuccess(res);
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getCampaignList',
      });
    },
    onError: (res: any) => {
      enqueueSnackbar(
        res?.errorMsg && typeof res.errorMsg === 'string'
          ? res.errorMsg
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        },
      );
    },
  });
  return mutationResult;
};

export const useUpdateCampaignEndDate = (onEndDateCompletion: (res: any) => void) => {
  const mutationResult = useMutation(updateCampaignEndDate, {
    mutationKey: ['updateCampaignEndDate'],
    onSuccess: (res) => {
      onEndDateCompletion(res);
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getCampaignList',
      });
    },
    onError: (res: any) => {
      if (res?.modified_data?.length) {
        onEndDateCompletion(res);
      }
      if (res?.reason?.length) {
        onEndDateCompletion(res);
      }
      enqueueSnackbar(
        res?.errorMsg && typeof res.errorMsg === 'string'
          ? res.errorMsg
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        },
      );
    },
  });
  return mutationResult;
};

interface UseMutateCampaignPriorityParams {
  editType: 'single' | 'bulk';
  onError?: (res: any) => void;
  onSuccess?: (res: AxiosResponse<WithTimestamps<WithResponse<string>>>) => void;
}

const updateCacheBeforePriorityMutation = (
  queryClient: QueryClient,
  variables: PutCampaignPriorityParams,
) => {
  const nextPriorityValues: { [key: string]: number | null } = {};
  const prevPriorityValues: { [key: string]: number | null } = {};

  const { addPriority, updatePriority, deletePriority } = variables;
  if (addPriority?.priority) {
    addPriority.campaignIds.forEach((cmpId) => {
      Object.assign(nextPriorityValues, { [cmpId]: addPriority.priority });
    });
  }
  if (updatePriority?.priority) {
    updatePriority.campaignIds.forEach((cmpId) => {
      Object.assign(nextPriorityValues, { [cmpId]: updatePriority.priority });
    });
  }
  if (deletePriority) {
    deletePriority.campaignIds.forEach((cmpId) => {
      Object.assign(nextPriorityValues, { [cmpId]: null });
    });
  }

  queryClient.setQueriesData(
    {
      type: 'active',
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getCampaignList' &&
        query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
    },
    (pages: any) => {
      const newPages = pages?.pages?.map((page: any) => {
        const newData = {
          ...page.data,
          recordsList: page?.data?.recordsList?.map((row: any) => {
            // @ts-ignore-next-line
            if (Object.hasOwn(nextPriorityValues, row.campaignId)) {
              Object.assign(prevPriorityValues, { [row.campaignId]: row.campaignPriority });
              return { ...row, campaignPriority: nextPriorityValues[row.campaignId] };
            }
            return row;
          }),
        };

        return {
          ...page,
          data: newData,
        };
      });
      return { ...pages, pages: newPages };
    },
  );
  return prevPriorityValues;
};

const revertCacheOnPriorityError = (
  variables: PutCampaignPriorityParams,
  prevPriorityValues: { [key: string]: number | null },
) => {
  queryClient.setQueriesData(
    {
      type: 'active',
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getCampaignList' &&
        query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
    },
    (pages: any) => {
      const newPages = pages?.pages?.map((page: any) => {
        const newData = {
          ...page.data,
          recordsList: page?.data?.recordsList?.map((row: any) => {
            return {
              ...row,
              // @ts-ignore-next-line
              ...(Object.hasOwn(prevPriorityValues, row.campaignId)
                ? { campaignPriority: prevPriorityValues[row.campaignId] }
                : {}),
            };
          }),
        };
        return {
          ...page,
          data: newData,
        };
      });
      return { ...pages, pages: newPages };
    },
  );
};

export const usePutCampaignPriority = ({
  editType,
  onError,
  onSuccess,
}: UseMutateCampaignPriorityParams) => {
  const mutationResult = useMutation(putCampaignPriority, {
    mutationKey: ['putCampaignPriority'],
    onMutate: (variables) => {
      if (editType === 'single') {
        return updateCacheBeforePriorityMutation(queryClient, variables);
      }
      return null;
    },
    onError: (res: any, variables, prevPriorityValues) => {
      if (onError) {
        onError(res);
      }
      if (editType === 'single') {
        revertCacheOnPriorityError(
          variables,
          prevPriorityValues as { [key: string]: number | null },
        );
      }
    },
    onSuccess: (data, variables) => {
      if (onSuccess) {
        onSuccess(data);
      }

      queryClient.refetchQueries({
        queryKey: getIODetailsQueryKey.keys('getIOInfo', `${variables.ioId}`),
      });

      if (editType === 'single') {
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey?.[0]?.scope === 'getCampaignList' &&
            query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
        });
      } else {
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey?.[0]?.scope === 'getCampaignList' &&
            query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
        });
        return queryClient.refetchQueries({
          type: 'active',
          predicate: (query: any) =>
            query.queryKey?.[0]?.scope === 'getCampaignList' &&
            query.queryKey?.[0]?.ioIds?.includes(variables.ioId),
        });
      }

      return null;
    },
  });

  return mutationResult;
};

export const useCampaignData = (
  { campaignId }: { campaignId: number },
  options?: { enabled?: boolean },
) => {
  const response = useQuery(
    getCampaignData.keys('getCampaignData', campaignId),
    fetchCampaignData,
    { enabled: options?.enabled },
  );
  return response;
};

export const useCreatePgCampaign = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess?: () => void;
  onError?: (e: any) => void;
  onSettled?: (e: any) => void;
}) => {
  const mutationResult = useMutation(createPgCampaign, {
    mutationKey: ['createPgCampaign'],
    onSuccess,
    onError,
    onSettled,
  });
  return mutationResult;
};

export const usePoliticalAdvertiserList = ({
  owId,
  search,
  options,
}: {
  owId: number;
  search?: string;
  options?: { onSuccess: (res: any) => void };
}) => {
  const response = useQuery(
    getPoliticalAdvertiserList.keys('getAdvertiserListQuery', owId, search),
    fetchAdvertiserListQuery,
    { enabled: Boolean(owId), onSuccess: options?.onSuccess },
  );
  return response;
};

export const useEditPgCampaign = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess?: () => void;
  onError?: (e: any) => void;
  onSettled?: (e: any) => void;
}) => {
  const mutationResult = useMutation(editPgCampaign, {
    mutationKey: ['editPgCampaign'],
    onSuccess,
    onError,
    onSettled,
  });
  return mutationResult;
};

export const useCreateDraftPgCampaign = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess?: () => void;
  onError?: (e: any) => void;
  onSettled?: (e: any) => void;
}) => {
  const mutationResult = useMutation(createPgCampaignDraft, {
    mutationKey: ['createDraftPgCampaign'],
    onSuccess,
    onError,
    onSettled,
  });
  return mutationResult;
};

export const useEditDraftPgCampaign = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess?: () => void;
  onError?: (e: any) => void;
  onSettled?: (e: any) => void;
}) => {
  const mutationResult = useMutation(editDraftPgCampaign, {
    mutationKey: ['editDraftPgCampaign'],
    onSuccess,
    onError,
    onSettled,
  });
  return mutationResult;
};

export const useCampaignBudgetInfo = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess?: (e: any) => void;
  onError?: (e: any) => void;
  onSettled?: (e: any) => void;
}) => {
  const mutationResult = useMutation(GetBudgetInfo, {
    mutationKey: ['campaignBudgetInfo'],
    onSuccess,
    onError,
    onSettled,
  });
  return mutationResult;
};
