import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ConversionType, Conversion } from 'models/Conversion';
import { conversionConstants } from './constants';

export interface ConversionState {
  conversionType: ConversionType | null;
  conversionTableSelection: Conversion[];
  sidebarCampaignInfo: {
    [CampaignInfoField.conversions]: number[];
  };
}

const defaultConversionState: ConversionState = {
  conversionType: null,
  conversionTableSelection: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.conversions]: [],
  },
};

const setSidebarCampaignInfo = (
  state: ConversionState,
  action: Action<{ key: string; value: number[] }>,
): ConversionState => {
  const { key, value } = action.payload;

  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetConversionStore = (state: ConversionState): ConversionState => {
  return {
    ...state,
    conversionTableSelection: [],
    conversionType: null,
    sidebarCampaignInfo: { ...defaultConversionState.sidebarCampaignInfo },
  };
};

const setConversionType = (
  state: ConversionState,
  action: Action<ConversionType | null>,
): ConversionState => {
  return {
    ...state,
    conversionType: action.payload,
  };
};

const setConversionTableSelection = (
  state: ConversionState,
  action: Action<Conversion[]>,
): ConversionState => {
  return {
    ...state,
    conversionTableSelection: action.payload,
  };
};

const reducer = reducerFromMap<ConversionState>(defaultConversionState, {
  [conversionConstants.SELECT_CONVERSION_FIELD]: setSidebarCampaignInfo,
  [conversionConstants.RESET_CONVERSION_STORE]: resetConversionStore,
  [conversionConstants.SET_CONVERSION_TYPE]: setConversionType,
  [conversionConstants.SET_CONVERSION_TABLE_SELECTION]: setConversionTableSelection,
});

export const conversion = (state: ConversionState = defaultConversionState, action: Action<any>) =>
  reducer(state, action);
