import * as React from 'react';
import { Row, Col, TextField, IconButton, Box, MenuItem } from '@applift/factor';
import { Search, Close } from '@applift/icons';
import { RowSelectionState } from '@applift/datagrid';

import { CampaignCreativeCountType, CampaignStatusCountType } from 'models/Count';
import { CAMPAIGN_STATUSES_ICONS, CREATIVES_TYPES_ICONS } from 'constants/iconMapper';

interface DialogCampaignListFilterProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  statusFilter: CampaignStatusCountType['status_key'][];
  setStatusFilter: (arg: any) => void;
  creativeTypeFilter?: number[];
  setCreativeTypeFilter: React.Dispatch<React.SetStateAction<number[]>>;
  campaignStatusCountLoading: boolean;
  campaignStatusCount?: CampaignStatusCountType[];
  campaignCreativeCountLoading: boolean;
  campaignCreativeCount?: CampaignCreativeCountType[];
  showCountOnFiler?: boolean;
}

export const DialogCampaignListFilter = (props: DialogCampaignListFilterProps) => {
  const {
    campaignStatusCountLoading,
    campaignStatusCount,
    statusFilter,
    setStatusFilter,
    creativeTypeFilter,
    setCreativeTypeFilter,
    campaignCreativeCountLoading,
    campaignCreativeCount,
    search,
    setSearch,
    setRowSelection,
    showCountOnFiler = false,
  } = props;

  return (
    <Row xs={{ gutterSize: 8, gutterDirection: 'x' }} sx={{ px: 24, alignItems: 'center' }}>
      <Col xs={3}>
        <TextField
          variant="outlined"
          select
          SelectProps={{
            placeholder: 'Status',
            renderValue: (value) => {
              if (campaignStatusCountLoading) {
                return 'Loading...';
              }
              const currectSelection = campaignStatusCount?.find((val) => val.status_key === value);
              return showCountOnFiler
                ? `Status - ${currectSelection?.status_label} (${currectSelection?.status_count})`
                : `Status - ${currectSelection?.status_label}`;
            },
          }}
          value={statusFilter[0]}
          sx={{ width: 100 }}
          onChange={(e) => {
            setStatusFilter([e.target.value]);
            setRowSelection({});
          }}
        >
          {campaignStatusCount
            ?.sort((a, b) => a.order - b.order)
            ?.map((val) => (
              // @ts-ignore
              <MenuItem startIcon={CAMPAIGN_STATUSES_ICONS[val.status_key]} value={val.status_key}>
                {showCountOnFiler
                  ? `${val.status_label} (${val.status_count})`
                  : `${val.status_label}`}
              </MenuItem>
            ))}
        </TextField>
      </Col>
      <Col xs={3}>
        <TextField
          variant="outlined"
          select
          SelectProps={{
            multiple: true,
            placeholder: 'Creative Type',
            renderValue: (value: any) => {
              if (!value.length) {
                return undefined;
              }
              if (campaignCreativeCountLoading) {
                return 'Loading...';
              }
              const currectSelection = campaignCreativeCount?.filter((val) =>
                value.includes(val.creativeTypeId),
              );
              if (currectSelection?.length === campaignCreativeCount?.length) {
                return showCountOnFiler
                  ? `Creative Type - All (${currectSelection?.reduce(
                      (arg, val) => arg + val.count,
                      0,
                    )})`
                  : 'Creative Type - All';
              }
              return `Creative Type - ${
                currectSelection?.map((val) => val.creativeType).join(', ') || ''
              }`;
            },
            MenuProps: {
              PaperProps: { sx: { height: 'auto' } },
            },
          }}
          value={creativeTypeFilter}
          sx={{ width: 100 }}
          onChange={(e) => {
            setCreativeTypeFilter(
              (e.target.value as unknown as Array<string>).map((val: string | number) => +val),
            );
            setRowSelection({});
          }}
        >
          {campaignCreativeCount?.map((val) => (
            <MenuItem
              // @ts-ignore
              startIcon={CREATIVES_TYPES_ICONS[val.creativeType]}
              value={val.creativeTypeId}
            >
              {showCountOnFiler ? `${val.creativeType} (${val.count})` : `${val.creativeType}`}
            </MenuItem>
          ))}
        </TextField>
      </Col>
      <Col
        xs={4}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          ml: 'auto',
        }}
      >
        <Box
          sx={{
            ml: 'auto',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            py: 8,
          }}
        >
          <TextField
            placeholder="Search by ID, Name, Status"
            InputProps={{
              startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
              endAdornment:
                search?.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                ) : null,
            }}
            style={{ width: '230px' }}
            variant="outlinedDash"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
              setRowSelection({});
            }}
          />
        </Box>
      </Col>
    </Row>
  );
};
