import * as React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  SelectAdvanceComponents,
  TextField,
  Tooltip,
  Typography,
} from '@applift/factor';
import { DataGrid, getSortedRowModel, useGridApiRef } from '@applift/datagrid';
import { ClipboardClose, Close, Search } from '@applift/icons';
import { AppState } from 'models/Store';
import { NoData } from '@applift/illustrations';
import { OptionId } from 'models/Option';
import { DetailedExchange } from 'models/Exchange';
import { useStore } from 'zustandStore';
import { PgDealResponseType, PgDealsListResponseType } from 'models/Inventory';
import { StoreFilter } from 'models/ZustandStore';
import { WithResponse } from '@applift/platform';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { getPgDealsListColDef } from './coldef';

export interface SelectInventoryGroupDialogPropTypes {
  timezone: OptionId;
  pgDealsSearch?: string;
  exchangeIds: number[];
  exchangeSearch?: string;
  exchangesListResponseMultiPageData: {
    totalRecords: number | undefined;
    filteredRecords: number | undefined;
    pgDealData: (PgDealResponseType | null | undefined)[] | undefined;
  };
  isExchangeListLoading: boolean;
  onClose: () => void;
  setPgDealsSearch: (args: string) => void;
  setExchangeIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  isPgDetailsListLoading: boolean;
  exchangeList?: DetailedExchange[];
  setExchangeSearch?: (args: string | undefined) => void;
  fetchNextPgDealsListPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<WithResponse<PgDealsListResponseType>, unknown>>;
}

export const SelectPreAddedDealsDialogComponent = (props: SelectInventoryGroupDialogPropTypes) => {
  const {
    onClose,
    timezone,
    exchangesListResponseMultiPageData,
    isPgDetailsListLoading,
    setExchangeIds,
    pgDealsSearch,
    setPgDealsSearch,
    exchangeList,
    fetchNextPgDealsListPage,
    exchangeIds,
    exchangeSearch,
    setExchangeSearch,
    isExchangeListLoading,
  } = props;
  const apiRef = useGridApiRef();
  const exchangeSelectApiRef = SelectAdvanceComponents.useSelectAdvanceApiRef<DetailedExchange>();

  const [rowSelectionExchangesSelect, setRowSelectionExchangesSelect] = React.useState({});
  const [rowSelectionDealsGrid, setRowSelectionDealsGrid] = React.useState({});
  const [sorting, setSorting] = React.useState([{ id: 'created', desc: true }]);
  const isSelectedDealsSetOnce = React.useRef(false);

  const [totalRecords, setTotalRecords] = React.useState<number>();

  const [pgDealIds, setPgDealsIds, setSelectedPgDeals] = useStore((state: StoreFilter) => [
    state.inventoryBlock.pgDealIds,
    state.inventoryBlock.setPgDealsIds,
    state.inventoryBlock.setSelectedPgDeals,
  ]);

  const renderItem = SelectAdvanceComponents.DefaultListOptionItemWrapper<DetailedExchange>({
    disableCheckbox: true,
    selectionStyle: 'none',
    arrowPosition: 'right',
  });

  const isSelectButtonDisabled = React.useMemo(
    () =>
      !Object.keys(rowSelectionDealsGrid).length || Object.keys(rowSelectionDealsGrid).length > 25,
    [rowSelectionDealsGrid],
  );

  const overlaySelect = React.useMemo(() => {
    if (!isExchangeListLoading) {
      if (!Array.isArray(exchangeList)) {
        return 'error';
      }
      if ((exchangeList?.length ?? 0) <= 0) {
        if (exchangeSearch) {
          return 'noResult';
        }
        return 'noRows';
      }
    }
    return undefined;
  }, [exchangeList, exchangeSearch, isExchangeListLoading]);

  const overlay = React.useMemo(() => {
    if (!isPgDetailsListLoading) {
      if (!Array.isArray(exchangesListResponseMultiPageData.pgDealData)) {
        return 'error';
      }
      if ((exchangesListResponseMultiPageData.pgDealData?.length ?? 0) <= 0) {
        if (pgDealsSearch) {
          return 'noResult';
        }
        return 'noRows';
      }
    }
    return undefined;
  }, [exchangesListResponseMultiPageData.pgDealData, isPgDetailsListLoading, pgDealsSearch]);

  const onSelectDealsWrapper = () => {
    const selectedPgDealsIdsInArray = Object.keys(rowSelectionDealsGrid).map(Number);
    setPgDealsIds(selectedPgDealsIdsInArray as PgDealResponseType['id'][]);
    // @ts-ignore
    setSelectedPgDeals([
      ...(exchangesListResponseMultiPageData.pgDealData?.filter(
        // @ts-ignore
        (val) => rowSelectionDealsGrid[val?.id],
      ) ?? []),
    ]);
    onClose();
  };

  const setSelectedPgDealsWrapper = (selectedRows: { [key: number]: boolean }) => {
    let action;
    if (
      // @ts-ignore
      Object.keys(selectedRows).filter((val) => rowSelectionExchangesSelect[val] === undefined)
        .length
    ) {
      action = 'add';
    } else {
      action = 'remove';
    }

    if (action === 'add') {
      const allSelectedItemsInArray = Object.keys(selectedRows);

      const selectedItemsInObject = exchangeIds
        ? Object.fromEntries(exchangeIds?.map((val) => [val, val]))
        : {};

      const itemsToAdd = allSelectedItemsInArray.filter((val) => !selectedItemsInObject[+val]);

      setExchangeIds((val: any) => [
        ...(val?.length ? val : []),
        ...(exchangeList ?? [])
          .filter((val) => itemsToAdd.includes(String(val.id)))
          .map((val) => val.id),
      ]);
    } else if (action === 'remove') {
      const itemToRemove = exchangeIds?.filter((val) => !selectedRows[val])[0];
      // @ts-ignore
      setExchangeIds((val: any[]) => val.filter((innerVal) => innerVal !== itemToRemove));
    }
  };

  const onSelectExchangesWrapper = (updater: any) => {
    const selectedRows =
      typeof updater === 'function' ? updater(rowSelectionExchangesSelect) : updater;

    setSelectedPgDealsWrapper(selectedRows);
    return setRowSelectionExchangesSelect(updater);
  };

  React.useEffect(() => {
    if (exchangesListResponseMultiPageData.filteredRecords) {
      setTotalRecords(exchangesListResponseMultiPageData.filteredRecords);
    }
  }, [exchangesListResponseMultiPageData.filteredRecords]);

  React.useEffect(() => {
    if (
      !Object.keys(rowSelectionDealsGrid).length &&
      pgDealIds.length &&
      isSelectedDealsSetOnce.current === false
    ) {
      const x = Object.fromEntries(pgDealIds.map((val: number) => [val, true]));
      setRowSelectionDealsGrid(x);
      isSelectedDealsSetOnce.current = true;
    }
  }, [pgDealIds, rowSelectionDealsGrid]);

  const NoRowsOverlay = () => {
    return (
      <MenuItem
        style={{ pointerEvents: 'none' }}
        sx={{
          width: 100,
          height: 100,
          p: 32,
        }}
      >
        <Box
          sx={{
            width: 100,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            height: 100,
          }}
        >
          <NoData
            color="primary"
            sx={{ mb: 2, height: 100 }}
            style={{ width: '30%', aspectRatio: '1/1' }}
          />
          <Typography>No PG deals found. Add deals to get started.</Typography>
        </Box>
      </MenuItem>
    );
  };
  const NoResultsOverlay = () => {
    return (
      <MenuItem
        style={{ pointerEvents: 'none' }}
        sx={{
          width: 100,
          height: 100,
          p: 32,
        }}
      >
        <Box
          sx={{
            width: 100,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            height: 100,
          }}
        >
          <ClipboardClose color="secondary" fontSize={36} sx={{ mb: 8 }} />
          <Typography>
            No PG deals found, We can't find any items matching your filters criteria.
          </Typography>
        </Box>
      </MenuItem>
    );
  };

  React.useEffect(() => {
    return () => {
      setPgDealsSearch('');
    };
  }, [setPgDealsSearch]);

  React.useEffect(() => {
    return () => {
      if (setExchangeSearch) {
        setExchangeSearch('');
        setExchangeIds(undefined);
      }
    };
  }, [setExchangeIds, setExchangeSearch]);

  return (
    <Dialog open maxWidth="xl" fullWidth PaperProps={{ sx: { height: 100 } }}>
      <DialogTitle>Select Deals</DialogTitle>
      <DialogContent dividers sx={{ height: 100 }}>
        <Paper sx={{ height: 100, pt: 16, display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              mb: 16,
              mx: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'between',
            }}
          >
            <Box>
              {Object.keys(rowSelectionDealsGrid).length ? (
                <Chip
                  size="small"
                  color="secondary"
                  label={`${
                    Object.keys(rowSelectionDealsGrid).length
                  } out of ${totalRecords} selected`}
                  onDelete={() => {
                    setPgDealsIds([]);
                    setRowSelectionDealsGrid({});
                  }}
                />
              ) : null}
            </Box>
            <Box sx={{ width: 50, display: 'flex', gapCol: 8, justifyContent: 'end' }}>
              <TextField
                style={{ width: '45%' }}
                placeholder="Search by Deal ID or Name"
                variant="outlinedDash"
                value={pgDealsSearch}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPgDealsSearch(e.target.value)
                }
                InputProps={{
                  startAdornment: <Search fontSize={16} sx={{ textColor: 'neutral-400' }} />,
                  endAdornment:
                    pgDealsSearch && pgDealsSearch?.length > 0 ? (
                      <IconButton onClick={() => setPgDealsSearch('')} size="small">
                        <Close fontSize={16} />
                      </IconButton>
                    ) : null,
                }}
              />
              <TextField
                style={{ width: '25%' }}
                select="advanceSelect"
                placeholder="Exchanges"
                value={rowSelectionExchangesSelect}
                onChange={onSelectExchangesWrapper}
                SelectProps={{
                  slotProps: {
                    PaperProps: {
                      style: {
                        maxWidth: 200,
                      },
                    },
                    InputBaseProps: {
                      onChange: (e) => setExchangeSearch?.(e.target.value),
                      value: exchangeSearch,
                      placeholder: 'Search by Deal ID or Name',
                    },
                  },
                  apiRef: exchangeSelectApiRef,
                  getRowId: (row) => String(row.id),
                  overlay: overlaySelect,
                  onClearAll: () => setExchangeIds([]),
                  getOptionLabel: (data) => String(data.id),
                  multiple: true,
                  pageSize: isExchangeListLoading ? 10 : 0,
                  renderListItem: renderItem,
                  rowCount: isExchangeListLoading ? 10 : 0,
                  data: exchangeList ?? [],
                  renderOption: (row) => <Typography>{row.row.original.name}</Typography>,
                  renderValue: (row) => {
                    // @ts-ignore
                    return <Typography>{row?.length} Selected</Typography>;
                  },
                }}
              />
            </Box>
          </Box>
          <DataGrid
            checkboxSelection
            apiRef={apiRef}
            getRowId={(row) => {
              return String(row.id);
            }}
            hideFooter
            hideHeader
            loading={isPgDetailsListLoading}
            onFetchRows={() => {
              fetchNextPgDealsListPage();
            }}
            getSortedRowModel={getSortedRowModel()}
            pageSize={exchangesListResponseMultiPageData.filteredRecords ?? 20}
            rowCount={exchangesListResponseMultiPageData.filteredRecords}
            overlay={overlay}
            showColumnRightBorder
            components={{ NoRowsOverlay, NoResultsOverlay }}
            showCellRightBorder
            disableRowSelectionOnClick
            onSortingChange={setSorting}
            state={{
              rowSelection: rowSelectionDealsGrid,
              columnVisibility: { actions: false },
              sorting,
            }}
            onRowSelectionChange={(updater) => {
              setRowSelectionDealsGrid(updater);
            }}
            data={exchangesListResponseMultiPageData.pgDealData ?? []}
            columns={getPgDealsListColDef(timezone)}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        {Object.keys(rowSelectionDealsGrid).length > 25 ? (
          <Tooltip title="Maximum limit of 25 deals per campaign is reached" arrow>
            <Box sx={{ ml: 16 }}>
              <Button disabled={isSelectButtonDisabled} onClick={onSelectDealsWrapper}>
                Select
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Button disabled={isSelectButtonDisabled} onClick={onSelectDealsWrapper}>
            Select
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  timezone: state.advanced.sidebarCampaignInfo.timezone,
});

export const SelectPreAddedDealsDialog = connect(mapState)(SelectPreAddedDealsDialogComponent);
