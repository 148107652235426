import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from '../../models/Route';
import { EmptyIoListPage } from './EmptyIoListPage';
import { CreateCampaignPage, EditCampaignPage } from './CampaignPage';
import { CreatePGCampaignPage, EditPGCampaignPage } from './PGCampaignPage';
import { CampaignSavePage } from './CampaignSavingPage';
import { IODetails } from './IODetails';
import { IoListPage } from './IoListPage';
import { PGCampaignSavePage } from './PGCampaignSavingPage';

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: EmptyIoListPage,
  },
  {
    path: '/io-list',
    exact: true,
    component: IoListPage,
  },
  {
    path: '/io-detail',
    exact: true,
    component: () => <Redirect to="/io-list" />,
  },
  {
    path: '/io-detail/:id',
    exact: true,
    component: IODetails,
  },
  {
    path: '/campaign-create',
    component: CreateCampaignPage,
  },
  {
    path: '/campaign-create-pg',
    component: CreatePGCampaignPage,
  },
  {
    path: '/campaign-edit/:id',
    component: EditCampaignPage,
    exact: true,
  },
  {
    path: '/campaign-edit-pg/:id',
    component: EditPGCampaignPage,
    exact: true,
  },
  {
    path: '/campaign-saving',
    component: CampaignSavePage,
  },
  {
    path: '/campaign-saving-pg',
    component: PGCampaignSavePage,
  },
];
