import { GeoRadiusData } from 'api/Campaign';
import { ICreativeAdvancedTargeting } from 'store/creatives/reducer';
import { Scheduling } from 'store/scheduling/reducer';
import { RadiusUpdatedLocations } from './Location';
// eslint-disable-next-line import/no-cycle
import { CreativesPlacementMapping } from './ExistingCampaign';

export interface EstimatorRequiredKeysType {
  targetableAudience: {
    [key: string]: boolean;
  };
  bidLandscapeEstimation: {
    [key: string]: boolean;
  };
  reachImpression: {
    [key: string]: boolean;
  };
}

export interface TargableAudienceResponseType {
  targetingType: 'Specific' | 'Moderate' | 'Broad';
  estimatedReachMin: number;
  estimatedReachMax: number;
  estimatedReachAvg: number;
}

export interface BidLandscapeInfoType {
  axis: {
    x: string;
    y: string;
  };
  bidGuidance: {
    [key: string]: number;
  }[];
}

export const getEstimatorRequiredQueryKey = {
  keys: (scope: string) => [{ scope } as const],
};

export const getEsimatorInfoQueryKey = {
  keys: (payload: { [key: string]: any }) => [{ payload } as const],
};

export type CampaignEstimatorParams = {
  campaignName: string;
  isAgreementChecked?: boolean;
  countryIds?: string;
  creativeTypes?: string;
  advertiserDomain?: string;
  totalBudget?: number;
  maxBid?: number;
  startTime?: number;
  endTime?: number | null;
  timezoneId?: number;
  creativeIds?: string;
  creativesPlacementMapping?: CreativesPlacementMapping;
  dailyBudget?: number;
  bidOptimization?: boolean;
  bidPacing?: boolean;
  impressionCapping?: number;
  maxDayClicks?: number;
  totalClicks?: number;
  maxDayImpressions?: number;
  totalImpressions?: number;
  maxDayConversions?: number;
  totalConversions?: number;
  conversionType?: 'install' | 'non-install';
  appURL?: string;
  carriers?: string;
  networkType?: string;
  deviceTypeNames?: string;
  trafficTypeNames?: string;
  isTvAd?: boolean;
  manufacturer?: string;
  os?: string;
  osVersion?: string;
  device?: string;
  totalBudgetPacing?: boolean;
  campaignPublisherCategoryTargetingCodes?: string;
  campaignGroupIds?: string;
  targetCPI?: number;
  politicalAdvertiserClientId?: number;
  stateIds?: string;
  stateCodes?: string;
  ageRangeIds?: string;
  genderIds?: string;
  languageIds?: string;
  interestIds?: string;
  incomeRangeIds?: string;
  ethnicityIds?: string;
  whitelistInventoryGroupIds?: string;
  blacklistInventoryGroupIds?: string;
  groupDealId?: string;
  scheduling?: Scheduling;
  whitelistDeviceIds?: string;
  whiteListedIp?: string;
  whitelistAndroidPackages?: string;
  whitelistAppIds?: string;
  whitelistSiteDomains?: string;
  blacklistDeviceIds?: string;
  blackListedIp?: string;
  blacklistAndroidPackages?: string;
  blacklistAppIds?: string;
  blacklistSiteDomains?: string;
  exchangeURLs?: string;
  locationFileIds?: string;
  removedLocationId?: string;
  removedLocationIds?: string;
  conversionIds?: string;
  // location
  whiteListedLocationIds?: string;
  zipCodesIncluded?: string;
  dmaCodesIncluded?: string;
  congressionalDistrictIds?: string;
  senateDistrictIdsIncluded?: string;
  houseDistrictIds?: string;
  cityIdsIncluded?: string;
  countyIdsIncluded?: string;
  whitelistLocationJson?: GeoRadiusData[];
  blackListedLocationIds?: string;
  zipCodesExcluded?: string;
  dmaCodesExcluded?: string;
  congressionalDistrictIdsExcluded?: string;
  senateDistrictIdsExcluded?: string;
  houseDistrictIdsExcluded?: string;
  cityIdsExcluded?: string;
  countyIdsExcluded?: string;
  blacklistLocationJson?: GeoRadiusData[];
  radiusUpdatedLocations?: RadiusUpdatedLocations;
  congressionalDistrictCodes: string;
  senateDistrictCodes: string;
  houseDistrictCodes: string;
  // audiences
  includedAudiencesV2?: { id?: number; type: number }[];
  excludedAudiencesV2?: { id?: number; type: number }[];

  creativeAdvanceTargeting?: ICreativeAdvancedTargeting;
  isAdvanceAudioVideoTargeted: boolean;
};
