import * as React from 'react';
import moment, { Moment } from 'moment';
import { Box, TextColorProps, Typography } from '@applift/factor';
import { EpochDatePicker, TimezoneProvider } from 'iqm-framework';

import { OptionId } from 'models/Option';

import styles from './styles.module.scss';

type SingleDatePickerProps = {
  timezone: OptionId<string> | undefined;
  label?: string | JSX.Element;
  date: number | undefined;
  updateDate: (date: any) => void;
  placeholderText: string;
  isDisabled?: boolean;
  datePickerstyles?: string;
  errorMsg?: string;
  warningMsg?: string;
} & {
  [key: string]: any;
};

export const SingleDatePicker = (props: SingleDatePickerProps) => {
  const {
    timezone,
    label,
    date,
    updateDate,
    placeholderText,
    isDisabled,
    datePickerstyles,
    errorMsg,
    warningMsg,
    addedMinutes,
  } = props;

  const getTextColor = (): TextColorProps => {
    if (warningMsg) {
      return { textColor: 'warning-400' };
    }
    return { textColor: !errorMsg ? 'neutral-600' : 'danger-400' };
  };

  return (
    <Box style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}>
      <TimezoneProvider timezone={timezone} key={timezone?.id}>
        {label && (
          <Typography variant="label" sx={{ ...getTextColor(), pl: 8 }}>
            {label}
          </Typography>
        )}
        <EpochDatePicker
          dateRangePickerClassName={`${styles.dateRangePicker} ${datePickerstyles} ${
            errorMsg && '_error'
          } ${warningMsg && '_warning'}`}
          className={`datePickerIO ${errorMsg && '_error'} ${isDisabled && 'disabled'} ${
            warningMsg && '_warning'
          }`}
          dateFormat="MM/DD/YYYY hh:mm A"
          datePickerProps={{
            numberOfCalendars: 1,
          }}
          placeholder={placeholderText}
          preselected={[
            {
              key: 'today',
              title: 'Today',
              date: (
                tz: {},
                convert: (date: Moment) => Moment,
                getCurrentDay: (tz: {}) => Moment,
              ) => convert(getCurrentDay(tz)?.add(addedMinutes ?? 30, 'minutes')),
            },
          ]}
          withTimePicker
          singleDateMode
          singleDate={date}
          calendarMinimumDate={moment().valueOf()}
          onDateChanged={(updatedDate: number) => {
            if (timezone) {
              updateDate(typeof updatedDate === 'number' ? updatedDate : null);
            }
          }}
          insidePreselectors
          removeSafariSelectionIssue
          // eslint-disable-next-line
          {...props}
        />
      </TimezoneProvider>
      {errorMsg && (
        <Box Component="p" className={styles.boldError}>
          {errorMsg}
        </Box>
      )}
      {warningMsg && (
        <Typography sx={{ textColor: 'warning-400' }} variant="label">
          {warningMsg}
        </Typography>
      )}
    </Box>
  );
};
