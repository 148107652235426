import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, ButtonBase, Tooltip, Typography } from '@applift/factor';
import { SemanticWarning } from '@applift/icons';

import { CampaignListType } from 'models/CampaignList';
import { CAMPAIGN_STATUSES, CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { getIcon } from 'registry';
import { AUDIENCE_ALL_UNAPPROVED, AUDIENCE_SOME_UNAPPROVED } from 'constants/messages';
import { BUDGET_TYPE_ID, IO_STATUSES } from 'constants/apps';

interface CampaignNameCellType {
  rowData?: CampaignListType;
}

export const CampaignNameCell = ({ rowData }: CampaignNameCellType) => {
  const history = useHistory();
  const audienceWarning = rowData?.audienceWarning;
  const ioDetail = rowData?.ioDetail;
  const ioBudgetInfo = rowData?.ioBudgetInfo;
  const isCampaignExpiredOrDeleted = [
    CAMPAIGN_STATUSES.DELETED,
    CAMPAIGN_STATUSES.EXPIRED,
  ].includes(rowData?.status?.toLowerCase() ?? '');

  const isPGCampaign = rowData?.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg;

  const redirectEditRoute = isPGCampaign
    ? `/campaign-edit-pg/${rowData?.campaignId}`
    : `/campaign-edit/${rowData?.campaignId}`;

  let isIOBudgetExhausted = false;
  if (ioBudgetInfo) {
    isIOBudgetExhausted =
      ioDetail?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? ioDetail?.ioTotalBudget <= ioBudgetInfo.totalSpentBudget
        : (ioDetail?.ioTotalImpressions as number) <= ioBudgetInfo.totalSpentImpression;
  }

  let warningMessage = '';
  if (audienceWarning) {
    warningMessage =
      audienceWarning !== 'SOME_UNAPPROVED' ? AUDIENCE_ALL_UNAPPROVED : AUDIENCE_SOME_UNAPPROVED;
  }
  const showHaltedWarning =
    (ioDetail?.ioStatusId === IO_STATUSES.EXPIRED || isIOBudgetExhausted) &&
    rowData?.status === CAMPAIGN_STATUSES.RUNNING;
  if (showHaltedWarning) {
    warningMessage = `The campaign's ad serving has been halted due to either the IO expiration or exhaustion of the IO budget.`;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
        justifyContent: 'between',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <Tooltip
          title="Click to view Campaign details"
          arrow
          disableHoverListener={isCampaignExpiredOrDeleted}
          placement="top"
        >
          <ButtonBase
            centerRipple={false}
            disableRipple
            disabled={isCampaignExpiredOrDeleted}
            onClick={() => history.push(redirectEditRoute)}
            sx={{ width: 100, display: 'flex', justifyContent: 'start', alignItems: 'center' }}
          >
            {rowData?.status !== 'draft' ? (
              <Box sx={{ mr: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* @ts-ignore */}
                {getIcon(rowData?.creativeType, { fontSize: 24 }, null)}
              </Box>
            ) : null}
            <Typography noWrap sx={!isCampaignExpiredOrDeleted ? { textColor: 'primary-600' } : {}}>
              {rowData?.campaignName}
            </Typography>
          </ButtonBase>
        </Tooltip>
      </Box>
      {warningMessage && (
        <Box sx={{ mt: 4, flexShrink: 0 }}>
          <Tooltip title={warningMessage} arrow placement="top">
            <SemanticWarning
              fontSize={24}
              color={
                warningMessage === AUDIENCE_SOME_UNAPPROVED || showHaltedWarning
                  ? 'warning'
                  : 'error'
              }
            />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};
