import React from 'react';
import { Switch, sx } from '@applift/factor';
import { GridActionCellParams } from '@applift/datagrid';
import { InventoryGroupListResponse } from 'models/Inventory';

interface AllowedBlockedListCellProps {
  isWhiteListed: boolean;
  row?: InventoryGroupListResponse['inventoryGroupList'][0];
  onAction?: (params: GridActionCellParams<string, any>) => void;
}

export const AllowedBlockedListCell = ({
  isWhiteListed = true,
  onAction,
  row,
}: AllowedBlockedListCellProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAction?.({
      actionName: 'updateRow',
      metaData: { rowId: row?.id, payload: { isWhiteListed: e.target.checked } },
    });
  };

  return (
    <Switch
      checked={isWhiteListed}
      size="small"
      color="success"
      onChange={handleChange}
      classes={{
        thumb: sx({ bgColor: isWhiteListed ? 'success-500' : 'danger-500' }),
        track: sx({ bgColor: isWhiteListed ? 'success-200' : 'danger-200' }),
      }}
    />
  );
};
