import { createAction } from '../../utils/actions';
import { advertiserConstants } from './constants';
import { PoliticalAdvertiser } from '../../api/Advertiser';

export interface SetAdvertiserField {
  setAdvertiserField: (data: { key: string; value: string | boolean }) => void;
}

export interface SetAdvertisingStatus {
  setAdvertisingStatus: (value: boolean) => void;
}

export interface GetAdvertiserData {
  getAdvertiserData: (id?: number) => void;
}

export interface ChangeCreatingMode {
  changeCreatingMode: (value: boolean) => void;
}

export interface ResetAdvertiserState {
  resetAdvertiserState: () => void;
}

export interface ChangePoliticalAdvertiser {
  changePoliticalAdvertiser: (data: PoliticalAdvertiser) => void;
}
export interface SetBidShadingApplicability {
  setBidShadingApplicability: (isApplicable: boolean) => void;
}

export const advertiserActions = {
  setAdvertiserField(data: { key: string; value: string | boolean }) {
    return createAction<any>(advertiserConstants.SET_FIELD, data);
  },

  setAdvertisingStatus(value: boolean) {
    return createAction<boolean>(advertiserConstants.SET_POLITICAL_ADV_STATUS, value);
  },

  getAdvertiserData(id?: number) {
    return createAction<number>(advertiserConstants.GET_ADVERTISER_DATA, id);
  },

  selectPoliticalAdvertiser(data: PoliticalAdvertiser[], id?: number) {
    return createAction<PoliticalAdvertiser>(
      advertiserConstants.SET_POLITICAL_ADVERTISER,
      id ? data.find((i) => i.id === id) || data[0] : data[0],
    );
  },

  setPoliticalAdvertiserList(data: PoliticalAdvertiser[]) {
    return createAction<PoliticalAdvertiser[]>(
      advertiserConstants.SET_POLITICAL_ADVERTISER_LIST,
      data,
    );
  },

  changePoliticalAdvertiser(data: PoliticalAdvertiser) {
    return createAction<PoliticalAdvertiser>(advertiserConstants.SET_POLITICAL_ADVERTISER, data);
  },

  changeCreatingMode(value: boolean) {
    return createAction<boolean>(advertiserConstants.CHANGE_CREATING_MODE, value);
  },

  resetAdvertiserState() {
    return createAction(advertiserConstants.RESET);
  },

  setValidationStatus(isValid: boolean) {
    return createAction<boolean>(
      advertiserConstants.SET_POLITICAL_ADVERTISER_VALIDATION_STATUS,
      isValid,
    );
  },

  setBidShadingApplicability(isBidShadingApplicable: boolean) {
    return createAction(advertiserConstants.SET_BID_SHADING_APPLICABILITY, isBidShadingApplicable);
  },
};
