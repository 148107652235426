import { useQuery } from '@tanstack/react-query';

import { API } from 'api';
import { getEstimatorRequiredQueryKey } from 'models/Estimator';

export function useEstimatorReqKeys() {
  const response = useQuery(
    getEstimatorRequiredQueryKey.keys('EstimatroRequiredKeys'),
    API.Estimator.getEstimatorRequiredKeys,
  );
  return response;
}
