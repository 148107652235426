import { metersToMiles } from 'utils/format';
import { PolygonExtended, RadiusUnity } from 'models/PolygonExtended';
import { CityOption, DistrictOption, IStateOption, StateRegionOption } from 'models/Location';
import { RectangleExtended } from 'models/RectangleExtended';
import { CircleExtended } from 'models/CircleExtended';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { GeoRadiusData } from 'api/Campaign';
import {
  LocationDetails as ExistingCampaignLocationDetails,
  CreateLocationParams,
  LocationBlockState,
  ParentAppErrors,
} from 'models/ExistingCampaign';
import { ILocationFile } from 'store/location/reducer';

export function collectRequest(state: LocationBlockState): CreateLocationParams {
  const { location, advanced, map } = state;

  const { sidebarCampaignInfo, whiteLists, blackLists, zipcodeLimitError } = advanced;
  const { shapesRadiusLimitErrorMsg } = map;
  const { locationFiles } = location;

  const excludedStates = (
    selectedStates: IStateOption[],
    selectedCities: CityOption[],
    selectedCounties: StateRegionOption[],
    selectedCongressionalDistricts: DistrictOption[],
    selectedHouses: DistrictOption[],
    selectedSenates: DistrictOption[],
    circles: CircleExtended[],
    polygons: PolygonExtended[],
    rectangles: RectangleExtended[],
  ): Number[] | null => {
    const excludedStates: Number[] = [];
    for (let itr = 0; itr < selectedStates.length; itr += 1) {
      const currentState = selectedStates[itr];
      if (selectedCities.some((one) => Number(one.stateId) === Number(currentState.v2Id))) {
        /* eslint-disable no-continue */
        continue;
      } else if (
        selectedCounties.some((one) => Number(one.stateId) === Number(currentState.v2Id))
      ) {
        continue;
      } else if (
        selectedCongressionalDistricts.some(
          (one) => Number(one.stateId) === Number(currentState.v2Id),
        )
      ) {
        continue;
      } else if (selectedHouses.some((one) => Number(one.stateId) === Number(currentState.v2Id))) {
        continue;
      } else if (selectedSenates.some((one) => Number(one.stateId) === Number(currentState.v2Id))) {
        continue;
      } else if (circles.some((one) => one.state === currentState.abbreviation)) {
        continue;
      } else if (polygons.some((one) => one.state === currentState.abbreviation)) {
        continue;
      } else if (rectangles.some((one) => one.state === currentState.abbreviation)) {
        continue;
      } else {
        excludedStates.push(currentState.value);
      }
    }

    return excludedStates;
  };

  const getListedGeoRadiusDetails = (
    isWhite: boolean,
    circles: CircleExtended[],
    polygons: PolygonExtended[],
    rectangles: RectangleExtended[],
  ): GeoRadiusData[] => {
    const formatedCircles = circles
      .filter((circle) => !circle.filename && circle.isInclude === isWhite)
      .map((circle) => ({
        unit: 'mile',
        address: circle.address,
        latitude: circle.lat,
        radius: metersToMiles(circle.getRadius()),
        type: circle.type,
        longitude: circle.lng,
        sid: circle.state,
        cid: circle.country,
      }));

    const formatedPolygons = polygons
      .filter((polygon) => !polygon.fileId && !polygon.filename && polygon.isInclude === isWhite)
      .map((polygon) => ({
        polypath: polygon.getPolyPath(),
        unit: 'mile',
        address: polygon.address,
        latitude: polygon.lat,
        type: polygon.type,
        radius: polygon.getRadius(RadiusUnity.mile),
        longitude: polygon.lng,
        sid: polygon.state,
        cid: polygon.country,
      }));

    const formatedRectangles = rectangles
      .filter((rectangle) => rectangle.isInclude === isWhite)
      .map((rectangle) => ({
        polypath: rectangle.getPolyPath(),
        unit: 'mile',
        address: rectangle.address,
        type: rectangle.type,
        radius: rectangle.getRadius(RadiusUnity.mile),
        sid: rectangle.state,
        cid: rectangle.country,
        longitude: rectangle.lng,
        latitude: rectangle.lat,
      }));

    return [...formatedCircles, ...formatedPolygons, ...formatedRectangles];
  };

  const getFileLocationDetails = (
    details: ILocationFile[],
    whiteListedLocationIds: number[] | undefined,
  ): ExistingCampaignLocationDetails =>
    details.reduce<ExistingCampaignLocationDetails>((accum, file) => {
      const newValidLocations = file.validLocations?.map((location) => ({
        latitude: location.latitude,
        longitude: location.longitude,
        radius: location.radius,
        address: location.address,
        locationId: location.locationId,
        isIncluded:
          whiteListedLocationIds && whiteListedLocationIds.indexOf(location.locationId) >= 0,
        polypath: location.polypath,
        cid: location.countryShortName,
        sid: location.stateShortName,
        placemarkName: location.placemarkName,
        cdId: 0,
        sdId: 0,
        hdId: 0,
      }));
      const result = {
        fileName: file.filename,
        fileType: file.type,
        validLocations: newValidLocations,
        id: file.fileId,
      };
      const newAccum = { ...accum, [file.fileId]: result };
      return newAccum;
    }, {});

  let request: CreateLocationParams = {
    countryId: sidebarCampaignInfo.country?.value,
    included: {
      counties: whiteLists.whiteListedCountyIds,
      cities: whiteLists.whiteListedCityIds,
      stateSenates: whiteLists.whiteListedSenateDistrictIds,
      stateHouses: whiteLists.whiteListedHouseDistrictIds,
      congressionalDistricts: whiteLists.whiteListedCongressionalDistrictIds,
      zipCodes: whiteLists.whiteListedZipcodes,
      dmaIds: whiteLists.whiteListedDmaIds,
      customAreas: getListedGeoRadiusDetails(
        true,
        map.preSavedCircles,
        map.preSavedPolygons,
        map.preSavedRectangles,
      ),
      locationIds: whiteLists.whiteListedLocationIds,
    },
    excluded: {
      counties: blackLists.blackListedCountyIds,
      cities: blackLists.blackListedCityIds,
      stateSenates: blackLists.blackListedSenateDistrictIds,
      stateHouses: blackLists.blackListedHouseDistrictIds,
      congressionalDistricts: blackLists.blackListedCongressionalDistrictIds,
      zipCodes: blackLists.blackListedZipcodes,
      dmaIds: blackLists.blackListedDmaIds,
      customAreas: getListedGeoRadiusDetails(
        false,
        map.preSavedCircles,
        map.preSavedPolygons,
        map.preSavedRectangles,
      ),
      locationIds: blackLists.blackListedLocationIds,
    },
    filterStore: {
      country: sidebarCampaignInfo.country,
      states: sidebarCampaignInfo.selectedStates,
      cities: sidebarCampaignInfo.selectedCities,
      counties: sidebarCampaignInfo.selectedCounties,
      districts: sidebarCampaignInfo.selectedCongressionalDistricts,
      senates: sidebarCampaignInfo.selectedSenates,
      houses: sidebarCampaignInfo.selectedHouses,
      dma: sidebarCampaignInfo.selectedDma,
      locationDetails: getFileLocationDetails(locationFiles, whiteLists.whiteListedLocationIds),
    },
    hasError: Boolean(zipcodeLimitError) || Boolean(shapesRadiusLimitErrorMsg),
    unUsedStates: excludedStates(
      sidebarCampaignInfo.selectedStates,
      sidebarCampaignInfo.selectedCities,
      sidebarCampaignInfo.selectedCounties,
      sidebarCampaignInfo.selectedCongressionalDistricts,
      sidebarCampaignInfo.selectedHouses,
      sidebarCampaignInfo.selectedSenates,
      map.preSavedCircles,
      map.preSavedPolygons,
      map.preSavedRectangles,
    ),
  };

  if (sidebarCampaignInfo[CampaignInfoField.states]) {
    const selectedState = sidebarCampaignInfo[CampaignInfoField.states];
    request = {
      ...request,
      included: {
        ...request.included,
        states: selectedState.map((i) => i.value),
      },
    };
  }

  if (locationFiles.length) {
    request = {
      ...request,
      locationFileIds: locationFiles.map((file) => file.fileId),
    };

    const updatedLocationIds = locationFiles.reduce((acc: number[], file) => {
      if (file.removedLocationIds) {
        return [...acc, ...file.removedLocationIds];
      }
      return acc;
    }, []);

    if (updatedLocationIds.length) {
      request = {
        ...request,
        removedLocationIds: updatedLocationIds,
      };
    }
  }

  if (
    [...(request.included?.cities ?? []), ...(request.excluded?.cities ?? [])].length === 0 &&
    [...(request.included?.counties ?? []), ...(request.excluded?.counties ?? [])].length === 0 &&
    [...(request.included?.stateHouses ?? []), ...(request.excluded?.stateHouses ?? [])].length ===
      0 &&
    [
      ...(request.included?.congressionalDistricts ?? []),
      ...(request.excluded?.congressionalDistricts ?? []),
    ].length === 0 &&
    [...(request.included?.stateSenates ?? []), ...(request.excluded?.stateSenates ?? [])]
      .length === 0 &&
    [...(request.included?.customAreas ?? []), ...(request.excluded?.customAreas ?? [])].length ===
      0 &&
    [...(request.included?.locationIds ?? []), ...(request.excluded?.locationIds ?? [])].length ===
      0 &&
    [...(request.included.zipCodes ?? []), ...(request.excluded?.zipCodes ?? [])].length === 0
  ) {
    if ((request.included.states?.length ?? 0) > 0) {
      request = { ...request, isOnlyStateSelected: true, isEmpty: false };
    } else {
      request = { ...request, isOnlyStateSelected: false, isEmpty: true };
    }
  } else {
    request = { ...request, isOnlyStateSelected: false, isEmpty: false };
  }
  return request;
}
export function plannerCollectRequest(state: LocationBlockState): CreateLocationParams {
  const { location, advanced, map } = state;

  const { sidebarCampaignInfo, whiteLists, blackLists, zipcodeLimitError } = advanced;
  const { shapesRadiusLimitErrorMsg } = map;
  const { locationFiles } = location;

  const excludedStates = (
    selectedStates: IStateOption[],
    selectedCities: CityOption[],
    selectedCounties: StateRegionOption[],
    selectedCongressionalDistricts: DistrictOption[],
    selectedHouses: DistrictOption[],
    selectedSenates: DistrictOption[],
    circles: CircleExtended[],
    polygons: PolygonExtended[],
    rectangles: RectangleExtended[],
  ): Number[] | null => {
    const excludedStates: Number[] = [];
    for (let itr = 0; itr < selectedStates.length; itr += 1) {
      const currentState = selectedStates[itr];
      if (selectedCities.some((one) => Number(one.stateId) === Number(currentState.v2Id))) {
        /* eslint-disable no-continue */
        continue;
      } else if (
        selectedCounties.some((one) => Number(one.stateId) === Number(currentState.v2Id))
      ) {
        continue;
      } else if (
        selectedCongressionalDistricts.some(
          (one) => Number(one.stateId) === Number(currentState.v2Id),
        )
      ) {
        continue;
      } else if (selectedHouses.some((one) => Number(one.stateId) === Number(currentState.v2Id))) {
        continue;
      } else if (selectedSenates.some((one) => Number(one.stateId) === Number(currentState.v2Id))) {
        continue;
      } else if (circles.some((one) => one.state === currentState.abbreviation)) {
        continue;
      } else if (polygons.some((one) => one.state === currentState.abbreviation)) {
        continue;
      } else if (rectangles.some((one) => one.state === currentState.abbreviation)) {
        continue;
      } else {
        excludedStates.push(currentState.value);
      }
    }

    return excludedStates;
  };

  const getListedGeoRadiusDetails = (
    isWhite: boolean,
    circles: CircleExtended[],
    polygons: PolygonExtended[],
    rectangles: RectangleExtended[],
  ): GeoRadiusData[] => {
    const formatedCircles = circles
      .filter((circle) => !circle.filename && circle.isInclude === isWhite)
      .map((circle) => ({
        unit: 'mile',
        address: circle.address,
        latitude: circle.lat,
        radius: metersToMiles(circle.getRadius()),
        type: circle.type,
        longitude: circle.lng,
        sid: circle.state,
        cid: circle.country,
      }));

    const formatedPolygons = polygons
      .filter((polygon) => !polygon.fileId && !polygon.filename && polygon.isInclude === isWhite)
      .map((polygon) => ({
        polypath: polygon.getPolyPath(),
        unit: 'mile',
        address: polygon.address,
        latitude: polygon.lat,
        type: polygon.type,
        radius: polygon.getRadius(RadiusUnity.mile),
        longitude: polygon.lng,
        sid: polygon.state,
        cid: polygon.country,
      }));

    const formatedRectangles = rectangles
      .filter((rectangle) => rectangle.isInclude === isWhite)
      .map((rectangle) => ({
        polypath: rectangle.getPolyPath(),
        unit: 'mile',
        address: rectangle.address,
        type: rectangle.type,
        radius: rectangle.getRadius(RadiusUnity.mile),
        sid: rectangle.state,
        cid: rectangle.country,
        longitude: rectangle.lng,
        latitude: rectangle.lat,
      }));

    return [...formatedCircles, ...formatedPolygons, ...formatedRectangles];
  };

  const getFileLocationDetails = (
    details: ILocationFile[],
    whiteListedLocationIds: number[] | undefined,
  ): ExistingCampaignLocationDetails =>
    details.reduce<ExistingCampaignLocationDetails>((accum, file) => {
      const newValidLocations = file.validLocations?.map((location) => ({
        latitude: location.latitude,
        longitude: location.longitude,
        radius: location.radius,
        address: location.address,
        locationId: location.locationId,
        isIncluded:
          whiteListedLocationIds && whiteListedLocationIds.indexOf(location.locationId) >= 0,
        polypath: location.polypath,
        cid: location.countryShortName,
        sid: location.stateShortName,
        placemarkName: location.placemarkName,
        cdId: 0,
        sdId: 0,
        hdId: 0,
      }));
      const result = {
        fileName: file.filename,
        fileType: file.type,
        validLocations: newValidLocations,
        id: file.fileId,
      };
      const newAccum = { ...accum, [file.fileId]: result };
      return newAccum;
    }, {});

  let request: CreateLocationParams = {
    countryId: sidebarCampaignInfo.country?.value,
    included: {
      counties: whiteLists.whiteListedCountyIds,
      cities: whiteLists.whiteListedCityIds,
      stateSenates: whiteLists.whiteListedSenateDistrictIds,
      stateHouses: whiteLists.whiteListedHouseDistrictIds,
      congressionalDistricts: whiteLists.whiteListedCongressionalDistrictIds,
      zipCodes: whiteLists.whiteListedZipcodes,
      dmaIds: whiteLists.whiteListedDmaIds,
      states: whiteLists.whiteListedStateIds,
      customAreas: getListedGeoRadiusDetails(
        true,
        map.preSavedCircles,
        map.preSavedPolygons,
        map.preSavedRectangles,
      ),
      locationIds: whiteLists.whiteListedLocationIds,
    },
    excluded: {
      counties: blackLists.blackListedCountyIds,
      cities: blackLists.blackListedCityIds,
      stateSenates: blackLists.blackListedSenateDistrictIds,
      stateHouses: blackLists.blackListedHouseDistrictIds,
      congressionalDistricts: blackLists.blackListedCongressionalDistrictIds,
      zipCodes: blackLists.blackListedZipcodes,
      dmaIds: blackLists.blackListedDmaIds,
      states: blackLists.blackListedStateIds,
      customAreas: getListedGeoRadiusDetails(
        false,
        map.preSavedCircles,
        map.preSavedPolygons,
        map.preSavedRectangles,
      ),
      locationIds: blackLists.blackListedLocationIds,
    },
    filterStore: {
      country: sidebarCampaignInfo.country,
      states: sidebarCampaignInfo.selectedStates,
      cities: sidebarCampaignInfo.selectedCities,
      counties: sidebarCampaignInfo.selectedCounties,
      districts: sidebarCampaignInfo.selectedCongressionalDistricts,
      senates: sidebarCampaignInfo.selectedSenates,
      houses: sidebarCampaignInfo.selectedHouses,
      dma: sidebarCampaignInfo.selectedDma,
      locationDetails: getFileLocationDetails(locationFiles, whiteLists.whiteListedLocationIds),
    },
    hasError: Boolean(zipcodeLimitError) || Boolean(shapesRadiusLimitErrorMsg),
    unUsedStates: excludedStates(
      sidebarCampaignInfo.selectedStates,
      sidebarCampaignInfo.selectedCities,
      sidebarCampaignInfo.selectedCounties,
      sidebarCampaignInfo.selectedCongressionalDistricts,
      sidebarCampaignInfo.selectedHouses,
      sidebarCampaignInfo.selectedSenates,
      map.preSavedCircles,
      map.preSavedPolygons,
      map.preSavedRectangles,
    ),
  };

  // if (sidebarCampaignInfo[CampaignInfoField.states]) {
  //   const selectedState = sidebarCampaignInfo[CampaignInfoField.states];
  //   request = {
  //     ...request,
  //     included: {
  //       ...request.included,
  //       states: selectedState.map((i) => i.value),
  //     },
  //   };
  // }

  if (locationFiles.length) {
    request = {
      ...request,
      locationFileIds: locationFiles.map((file) => file.fileId),
    };

    const updatedLocationIds = locationFiles.reduce((acc: number[], file) => {
      if (file.removedLocationIds) {
        return [...acc, ...file.removedLocationIds];
      }
      return acc;
    }, []);

    if (updatedLocationIds.length) {
      request = {
        ...request,
        removedLocationIds: updatedLocationIds,
      };
    }
  }

  if (
    [...(request.included?.cities ?? []), ...(request.excluded?.cities ?? [])].length === 0 &&
    [...(request.included?.counties ?? []), ...(request.excluded?.counties ?? [])].length === 0 &&
    [...(request.included?.stateHouses ?? []), ...(request.excluded?.stateHouses ?? [])].length ===
      0 &&
    [
      ...(request.included?.congressionalDistricts ?? []),
      ...(request.excluded?.congressionalDistricts ?? []),
    ].length === 0 &&
    [...(request.included?.stateSenates ?? []), ...(request.excluded?.stateSenates ?? [])]
      .length === 0 &&
    [...(request.included?.customAreas ?? []), ...(request.excluded?.customAreas ?? [])].length ===
      0 &&
    [...(request.included?.locationIds ?? []), ...(request.excluded?.locationIds ?? [])].length ===
      0 &&
    [...(request.included.zipCodes ?? []), ...(request.excluded?.zipCodes ?? [])].length === 0
  ) {
    if ((request.included.states?.length ?? 0) > 0) {
      request = { ...request, isOnlyStateSelected: true, isEmpty: false };
    } else {
      request = { ...request, isOnlyStateSelected: false, isEmpty: true };
    }
  } else {
    request = { ...request, isOnlyStateSelected: false, isEmpty: false };
  }
  return request;
}

export function transformDataAsPerCampaign(data: CreateLocationParams): any {
  let newData: any = {};
  newData = {
    isUsingV2API: true,
    filterStore: data.filterStore,
    whiteListedCountyIds: data?.included?.counties?.join?.(',') ?? null,
    whiteListedCityIds: data?.included?.cities?.join?.(',') ?? null,
    whiteListedSenateDistrictIds: data?.included?.stateSenates?.join?.(',') ?? null,
    whiteListedHouseDistrictIds: data?.included?.stateHouses?.join?.(',') ?? null,
    whiteListedCongressionalDistrictIds:
      data?.included?.congressionalDistricts?.join?.(',') ?? null,
    whiteListedZipcodes: data?.included?.zipCodes?.join?.(',') ?? null,
    whiteListedGeoRadiusDetails: data?.included?.customAreas ?? [],
    whiteListedLocationIds: data?.included?.locationIds?.join?.(',') ?? null,
    blackListedCountyIds: data?.excluded?.counties?.join?.(',') ?? null,
    whiteListedDmaIds: data?.included?.dmaIds?.join?.(',') ?? null,
    blackListedDmaIds: data?.excluded?.dmaIds?.join?.(',') ?? null,
    blackListedCityIds: data?.excluded?.cities?.join?.(',') ?? null,
    blackListedSenateDistrictIds: data?.excluded?.stateSenates?.join?.(',') ?? null,
    blackListedHouseDistrictIds: data?.excluded?.stateHouses?.join?.(',') ?? null,
    blackListedCongressionalDistrictIds:
      data?.excluded?.congressionalDistricts?.join?.(',') ?? null,
    blackListedZipcodes: data?.excluded?.zipCodes?.join?.(',') ?? null,
    blackListedGeoRadiusDetails: data?.excluded?.customAreas ?? [],
    blackListedLocationIds: data?.excluded?.locationIds?.join?.(',') ?? null,
    whiteListedStateIds: data?.included?.states?.join?.(',') ?? null,
    blackListedStateIds: data?.excluded?.states?.join?.(',') ?? null,
    selectedStates: data?.included?.states?.join?.(',') ?? null,
    countryId: data.countryId ?? null,
    locationDetails: data.filterStore?.locationDetails,
    parentApp: data.parentApp ?? null,
    scrollBarWidth: data.scrollBarWidth ?? null,
    isLocationWithOrFilter: data.isLocationWithOrFilter ?? null,
  };
  const filteredData = Object.keys(newData).reduce((accum, current) => {
    if (
      (current === 'blackListedGeoRadiusDetails' || current === 'whiteListedGeoRadiusDetails') &&
      newData[current].length
    ) {
      return { ...accum, [current]: newData[current] };
      /* eslint-disable no-else-return */
    } else if (newData[current] !== null) {
      return { ...accum, [current]: newData[current] };
    }
    return accum;
  }, {});
  return filteredData;
}

export const getParentAppErrors = (params: CreateLocationParams): ParentAppErrors => {
  return {
    countryError: params?.parentAppErrors?.countryError || null,
  };
};
