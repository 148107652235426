import { StateCreator } from 'zustand';
import { US_COUNTRY_ID } from 'constants/location';
import { CampaignLocationSlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  country: US_COUNTRY_ID,
};

export const createLocationBlockStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CampaignLocationSlice
> = (set) => ({
  locationBlock: {
    ...initialState,
    setCountry: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          locationBlock: {
            ...prev.locationBlock,
            country: value,
          },
        }),
        false,
        'setCountryName',
      ),
    resetLocationBlockStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          locationBlock: {
            ...prev.locationBlock,
            ...initialState,
          },
        }),
        false,
        'resetLocationBlockStore',
      ),
    initCampaignLocationSlice: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          locationBlock: {
            ...prev.locationBlock,
            ...value,
          },
        }),
        false,
        'initLocationBlockStore',
      ),
  },
});
