import React from 'react';
import { StatusReady, StatusExpired, StatusDeleted } from '@applift/icons';
import { IO_STATUS_ID } from 'constants/insertionOrder';

export interface TStatusFilterMapper {
  label: string;
  key: string;
  icon: React.ReactNode;
  value: number;
}

export const StatusFilterMapper: TStatusFilterMapper[] = [
  {
    label: 'Active',
    key: 'active',
    icon: <StatusReady color="info" sx={{ fontSize: 24 }} />,
    value: IO_STATUS_ID.ACTIVE,
  },
  {
    label: 'Expired',
    key: 'expired',
    icon: <StatusExpired color="secondary" sx={{ fontSize: 24 }} />,
    value: IO_STATUS_ID.EXPIRED,
  },
  {
    label: 'Deleted',
    key: 'deleted',
    icon: <StatusDeleted color="error" sx={{ fontSize: 24 }} />,
    value: IO_STATUS_ID.DELETED,
  },
];
