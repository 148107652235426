import {
  CreatePGCampaignDraftPayloadType,
  CreatePGCampaignPayloadType,
  EditPGCampaignPayloadType,
} from 'api/Campaign';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { ConversionType } from 'models/Conversion';
import { Creative } from 'models/Creative';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { IoBasicDetailsListType } from 'models/IoList';
import { OptionId } from 'models/Option';

export interface CampaignDetailsForPayloadType {
  country: number;
  campaignName: string;
  timeZone: OptionId<string> | undefined;
  maxBidPrice: number | null;
  endDate: number | null;
  startDate: number | null;
  spendingBudget: number | null;
  advertiserDomain: string | null;
  selectedCreatives: Creative[];
  creativeTypeId: number | null;
  pgDealIds: number[];
  politicalAdvertiserClientId: number | null;
  selectedConversions: number[];
  conversionType: ConversionType;
  totalImpressions?: number | null;
}

const haveSameElements = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return new Set(arr1).size === new Set([...arr1, ...arr2]).size;
};

const handleUndefinedNestedObject = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (
        typeof value === 'object' &&
        value !== null &&
        // @ts-ignore
        Object.keys(value).every((k) => value[k] === undefined)
      ) {
        return [key, undefined];
      }
      return [key, value];
    }),
  );

export interface CreatePgCampaignHookType {
  campaignDetails: CampaignDetailsForPayloadType;
  ioDetails: IoBasicDetailsListType | null | undefined;
}

export const useCreatePgCampaignPayload = ({
  campaignDetails,
  ioDetails,
}: CreatePgCampaignHookType) => {
  const payload: CreatePGCampaignPayloadType = {
    pgCampaignInfo: {
      campaignName: campaignDetails.campaignName,
      ioId: ioDetails?.ioId,
      ...(campaignDetails.timeZone ? { timeZoneId: campaignDetails.timeZone.id } : {}),
      ...(campaignDetails.spendingBudget &&
      ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? { spendingBudget: campaignDetails.spendingBudget }
        : {}),
      ...(ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
      campaignDetails.totalImpressions
        ? { totalImpressions: campaignDetails.totalImpressions }
        : {}),
      maxBid: campaignDetails.maxBidPrice ?? 0,
      ...(campaignDetails.endDate ? { endTime: campaignDetails.endDate } : {}),
      ...(campaignDetails.startDate ? { startTime: campaignDetails.startDate } : {}),
      campaignTypeId: CAMPAIGN_TYPE_BY_NAME.pg,
      budgetTypeId: ioDetails?.ioBudgetTypeId,
      ...(campaignDetails.advertiserDomain
        ? { advertiserDomain: campaignDetails.advertiserDomain }
        : {}),
    },
    creativeTargeting: {
      ...(campaignDetails.creativeTypeId ? { creativeTypeId: campaignDetails.creativeTypeId } : {}),
      creativeIds: campaignDetails.selectedCreatives.map((val: Creative) => val.id),
    },
    inventoryTargeting: {
      pgDealIds: campaignDetails.pgDealIds,
    },
    countryId: campaignDetails.country,
    ...(campaignDetails.politicalAdvertiserClientId
      ? { politicalAdvertiserClientId: campaignDetails.politicalAdvertiserClientId }
      : {}),

    conversionTargeting: {
      conversionIds:
        campaignDetails.selectedConversions.length && campaignDetails.selectedConversions.length
          ? campaignDetails.selectedConversions
          : undefined,
      conversionTypeId:
        campaignDetails.selectedConversions.length && campaignDetails.selectedConversions.length
          ? campaignDetails.conversionType
          : undefined,
    },
  };

  return { payload };
};

export interface EditPgCampaignHookType {
  campaignDetails: CampaignDetailsForPayloadType;
  ioDetails: IoBasicDetailsListType | null | undefined;
  savedCampaignDetails: ExistingCampaignData | null;
}

const isEntityChanged = (a: any, b: any) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return !haveSameElements(a, b) ? a : undefined;
  }
  return a !== b ? a : undefined;
};

const conversionPayload = (newVal: number[], oldVal: number[] | undefined) => {
  if (newVal.length > 0 && isEntityChanged(newVal, oldVal)) {
    return newVal;
  }
  if (isEntityChanged(newVal, oldVal) && newVal.length === 0 && oldVal?.length) {
    return [];
  }
  return undefined;
};

const conversionTypeIdPayload = (
  newCampaignDetails: CampaignDetailsForPayloadType,
  oldCampaignDetails: ExistingCampaignData,
) => {
  if (newCampaignDetails.selectedConversions.length) {
    return isEntityChanged(newCampaignDetails?.conversionType, oldCampaignDetails.conversionTypeId);
  }
  if (
    oldCampaignDetails.conversionIds?.split(',').length &&
    !newCampaignDetails.selectedConversions.length
  ) {
    return 0;
  }
  if (!newCampaignDetails.selectedConversions.length) {
    return undefined;
  }
  return isEntityChanged(newCampaignDetails?.conversionType, oldCampaignDetails.conversionTypeId);
};

const politicalAdvertiserPayload = (newVal: number | null, oldVal: number | undefined) => {
  if (newVal || newVal === 0) {
    return isEntityChanged(newVal, oldVal) || isEntityChanged(newVal, oldVal) === 0
      ? newVal
      : undefined;
  }
  return undefined;
};

export const useEditPgCampaignPayload = ({
  campaignDetails,
  savedCampaignDetails,
}: EditPgCampaignHookType) => {
  if (savedCampaignDetails?.id) {
    let payload: EditPGCampaignPayloadType = {
      campaignId: savedCampaignDetails?.id,
      pgCampaignInfo: {
        campaignName: isEntityChanged(
          campaignDetails.campaignName,
          savedCampaignDetails.campaignName,
        ),

        ...(campaignDetails.timeZone
          ? {
              timeZoneId: isEntityChanged(
                campaignDetails.timeZone.id,
                savedCampaignDetails.timezone,
              ),
            }
          : {}),
        ...(campaignDetails.spendingBudget &&
        savedCampaignDetails.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? {
              spendingBudget: isEntityChanged(
                campaignDetails.spendingBudget,
                savedCampaignDetails.spendingBudget,
              ),
            }
          : {}),
        ...(campaignDetails.totalImpressions &&
        savedCampaignDetails.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
          ? {
              totalImpressions: isEntityChanged(
                campaignDetails.totalImpressions,
                savedCampaignDetails.totalImpressions,
              ),
            }
          : {}),
        maxBid: isEntityChanged(campaignDetails.maxBidPrice, savedCampaignDetails.maxBid),
        ...(campaignDetails.endDate || campaignDetails.endDate === 0
          ? { endTime: isEntityChanged(campaignDetails.endDate, savedCampaignDetails.endTime) }
          : {}),
        ...(campaignDetails.startDate
          ? {
              startTime: isEntityChanged(campaignDetails.startDate, savedCampaignDetails.startTime),
            }
          : {}),
        ...(campaignDetails.advertiserDomain
          ? {
              advertiserDomain: isEntityChanged(
                campaignDetails.advertiserDomain,
                savedCampaignDetails.advertiserDomain,
              ),
            }
          : {}),
      },
      creativeTargeting: {
        creativeIds: campaignDetails.selectedCreatives
          ? isEntityChanged(
              campaignDetails.selectedCreatives.map((val: Creative) => val.id),
              savedCampaignDetails.creativeIds?.split(',').map(Number),
            )
          : undefined,
        ...(isEntityChanged(campaignDetails.creativeTypeId, savedCampaignDetails.creativeType)
          ? { creativeTypeId: campaignDetails.creativeTypeId ?? undefined }
          : {}),
      },
      inventoryTargeting: {
        pgDealIds: isEntityChanged(campaignDetails.pgDealIds, savedCampaignDetails.pgDealIds),
      },
      countryId: isEntityChanged(campaignDetails.country, Number(savedCampaignDetails.countryId)),

      politicalAdvertiserClientId: politicalAdvertiserPayload(
        campaignDetails.politicalAdvertiserClientId,
        savedCampaignDetails.politicalAdvertiserClientId,
      ),

      conversionTargeting: {
        conversionIds: conversionPayload(
          campaignDetails?.selectedConversions,
          savedCampaignDetails.conversionIds?.split(',').map(Number),
        ),
        conversionTypeId: conversionTypeIdPayload(campaignDetails, savedCampaignDetails),
      },
    };

    payload = handleUndefinedNestedObject(payload) as unknown as EditPGCampaignPayloadType;
    return { payload };
  }
  return {};
};

export const useCreateDraftPgCampaignPayload = ({
  campaignDetails,
  ioDetails,
}: CreatePgCampaignHookType) => {
  const payload: CreatePGCampaignDraftPayloadType = {
    pgCampaignInfo: {
      campaignName: campaignDetails.campaignName,
      ioId: ioDetails?.ioId,
      ...(campaignDetails.timeZone ? { timeZoneId: campaignDetails.timeZone.id } : {}),
      ...(campaignDetails.spendingBudget &&
      ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? { spendingBudget: campaignDetails.spendingBudget }
        : {}),
      ...(ioDetails?.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
      campaignDetails.totalImpressions
        ? { totalImpressions: campaignDetails.totalImpressions }
        : {}),
      maxBid: campaignDetails.maxBidPrice ?? undefined,
      ...(campaignDetails.endDate ? { endTime: campaignDetails.endDate } : {}),
      ...(campaignDetails.startDate ? { startTime: campaignDetails.startDate } : {}),
      campaignTypeId: CAMPAIGN_TYPE_BY_NAME.pg,
      budgetTypeId: ioDetails?.ioBudgetTypeId,
      ...(campaignDetails.advertiserDomain
        ? { advertiserDomain: campaignDetails.advertiserDomain }
        : {}),
    },
    creativeTargeting: {
      ...(campaignDetails.creativeTypeId ? { creativeTypeId: campaignDetails.creativeTypeId } : {}),
      creativeIds: campaignDetails.selectedCreatives.length
        ? campaignDetails?.selectedCreatives?.map((val: Creative) => val.id)
        : undefined,
    },
    inventoryTargeting: {
      pgDealIds: campaignDetails?.pgDealIds?.length ? campaignDetails.pgDealIds : undefined,
    },
    countryId: campaignDetails.country ?? undefined,
    ...(campaignDetails.politicalAdvertiserClientId
      ? { politicalAdvertiserClientId: campaignDetails.politicalAdvertiserClientId }
      : {}),

    conversionTargeting: {
      conversionIds: campaignDetails.selectedConversions.length
        ? campaignDetails.selectedConversions
        : undefined,
      conversionTypeId: campaignDetails.selectedConversions.length
        ? campaignDetails.conversionType
        : undefined || undefined,
    },
  };
  const actualPayload = handleUndefinedNestedObject(payload);
  return { actualPayload };
};

const creativePayload = (newVal: number[], oldVal: number[] | undefined) => {
  if (newVal.length > 0 && isEntityChanged(newVal, oldVal)) {
    return newVal;
  }
  if (isEntityChanged(newVal, oldVal) && newVal.length === 0 && oldVal?.length) {
    return [];
  }
  return undefined;
};

const inventoryPayload = (newVal: number[], oldVal: number[] | undefined) => {
  if (newVal.length > 0 && isEntityChanged(newVal, oldVal)) {
    return newVal;
  }
  if (isEntityChanged(newVal, oldVal) && newVal.length === 0 && oldVal?.length) {
    return [];
  }
  return undefined;
};

export const useEditDraftPgCampaignPayload = ({
  campaignDetails,
  ioDetails,
  savedCampaignDetails,
}: EditPgCampaignHookType) => {
  if (savedCampaignDetails?.id) {
    let payload: EditPGCampaignPayloadType = {
      campaignId: savedCampaignDetails?.id,
      pgCampaignInfo: {
        campaignName: isEntityChanged(
          campaignDetails.campaignName,
          savedCampaignDetails.campaignName,
        ),
        ...(campaignDetails.timeZone
          ? {
              timeZoneId: isEntityChanged(
                campaignDetails.timeZone.id,
                savedCampaignDetails.timezone,
              ),
            }
          : {}),
        ...((campaignDetails.spendingBudget || campaignDetails.spendingBudget === 0) &&
        savedCampaignDetails.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? {
              spendingBudget: isEntityChanged(
                campaignDetails.spendingBudget,
                savedCampaignDetails.spendingBudget,
              ),
            }
          : {}),
        ...((campaignDetails.totalImpressions || campaignDetails.totalImpressions === 0) &&
        savedCampaignDetails.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
          ? {
              totalImpressions: isEntityChanged(
                campaignDetails.totalImpressions,
                savedCampaignDetails.totalImpressions,
              ),
            }
          : {}),
        maxBid:
          campaignDetails.maxBidPrice || campaignDetails.maxBidPrice === 0
            ? isEntityChanged(campaignDetails.maxBidPrice, savedCampaignDetails.maxBid)
            : undefined,
        ...(campaignDetails.endDate || campaignDetails.endDate === 0
          ? { endTime: isEntityChanged(campaignDetails.endDate, savedCampaignDetails.endTime) }
          : {}),
        ...(campaignDetails.startDate
          ? {
              startTime: isEntityChanged(campaignDetails.startDate, savedCampaignDetails.startTime),
            }
          : {}),
        ...(campaignDetails.advertiserDomain
          ? {
              advertiserDomain: isEntityChanged(
                campaignDetails.advertiserDomain,
                savedCampaignDetails.advertiserDomain,
              ),
            }
          : {}),
      },
      creativeTargeting: {
        creativeIds: creativePayload(
          campaignDetails.selectedCreatives.map((val: Creative) => val.id),
          savedCampaignDetails.creativeIds?.split(',').map(Number),
        ),
        ...(isEntityChanged(campaignDetails.creativeTypeId, savedCampaignDetails.creativeType)
          ? { creativeTypeId: campaignDetails.creativeTypeId ?? undefined }
          : {}),
      },
      inventoryTargeting: {
        pgDealIds: inventoryPayload(campaignDetails.pgDealIds, savedCampaignDetails.pgDealIds),
      },
      countryId: campaignDetails.country
        ? isEntityChanged(campaignDetails.country, Number(savedCampaignDetails.countryId))
        : undefined,

      politicalAdvertiserClientId: politicalAdvertiserPayload(
        campaignDetails.politicalAdvertiserClientId,
        savedCampaignDetails.politicalAdvertiserClientId,
      ),

      conversionTargeting: {
        conversionIds: conversionPayload(
          campaignDetails.selectedConversions,
          savedCampaignDetails.conversionIds?.split(',').map(Number),
        ),
        conversionTypeId: conversionTypeIdPayload(campaignDetails, savedCampaignDetails),
      },
    };

    payload = handleUndefinedNestedObject(payload) as unknown as EditPGCampaignPayloadType;
    return { payload };
  }
  return {};
};
