import { Action } from 'models/Action';
import { Option } from 'models/Option';
import { Creative } from 'models/Creative';
import { CreativesPlacementMapping } from 'models/ExistingCampaign';
import { reducerFromMap } from 'utils/actions';
import { isCreativeDataValid } from 'store/app/helpers';
import { creativesConstants } from './constants';

export interface ICreativeAdvancedTargeting {
  placementType: number[] | undefined;
  rollPosition: number[] | undefined;
  playerSize: number[] | undefined;
  skippability: number[] | undefined;
  playbackMethod: number[] | undefined;
}

export interface CreativesState {
  creativeTypes: Option<number>[];
  creativeRtbTypes: Option<number>[];
  selectedCreativeType: Option<number> | null;
  creatives: Creative[];
  selectedCreatives: Creative[];
  advertiserUrl: string;
  creativesPlacementMapping: CreativesPlacementMapping;
  creativeStoreHasErrors: boolean;
  isAdvanceAudioVideoTargeted?: boolean;
  creativeAdvanceTargeting?: ICreativeAdvancedTargeting | undefined | null;
}

const defaultCreativesState: CreativesState = {
  creativeTypes: [],
  creativeRtbTypes: [],
  selectedCreativeType: null,
  creatives: [],
  selectedCreatives: [],
  advertiserUrl: '',
  creativesPlacementMapping: {},
  creativeStoreHasErrors: false,
  isAdvanceAudioVideoTargeted: false,
  creativeAdvanceTargeting: null,
};

function setCreativeTypes(state: CreativesState, action: Action<Option<number>[]>): CreativesState {
  return {
    ...state,
    creativeTypes: action.payload,
  };
}

function setCreativeRtbTypes(
  state: CreativesState,
  action: Action<Option<number>[]>,
): CreativesState {
  return {
    ...state,
    creativeRtbTypes: action.payload,
  };
}

function selectCreativeType(
  state: CreativesState,
  action: Action<Option<number> | null>,
): CreativesState {
  return {
    ...state,
    selectedCreativeType: action.payload,
  };
}

function setCreatives(state: CreativesState, action: Action<Creative[]>): CreativesState {
  return {
    ...state,
    creatives: action.payload,
  };
}

function selectCreatives(state: CreativesState, action: Action<Creative[]>): CreativesState {
  return {
    ...state,
    selectedCreatives: action.payload,
  };
}

function setCreativesPlacementMapping(
  state: CreativesState,
  action: Action<CreativesPlacementMapping>,
): CreativesState {
  return {
    ...state,
    creativesPlacementMapping: action.payload,
  };
}

function setUrl(state: CreativesState, action: Action<string>): CreativesState {
  return {
    ...state,
    advertiserUrl: action.payload,
  };
}

function reset(): CreativesState {
  return {
    ...defaultCreativesState,
  };
}

function validateCreativeStore(state: CreativesState): CreativesState {
  return {
    ...state,
    creativeStoreHasErrors: !isCreativeDataValid(state),
  };
}

function setAdvancedVideoTargeting(state: CreativesState, action: Action<boolean>): CreativesState {
  return {
    ...state,
    isAdvanceAudioVideoTargeted: action.payload,
  };
}

function setAdvancedVideoTargetingOptions(
  state: CreativesState,
  action: Action<ICreativeAdvancedTargeting>,
): CreativesState {
  return {
    ...state,
    creativeAdvanceTargeting: action.payload,
  };
}

const reducer = reducerFromMap<CreativesState>(defaultCreativesState, {
  [creativesConstants.SET_CREATIVE_TYPES]: setCreativeTypes,
  [creativesConstants.SET_CREATIVES_RTB]: setCreativeRtbTypes,
  [creativesConstants.SELECT_CREATIVE_TYPE]: selectCreativeType,
  [creativesConstants.SET_CREATIVES]: setCreatives,
  [creativesConstants.SET_CREATIVES_PLACEMENT_MAPPING]: setCreativesPlacementMapping,
  [creativesConstants.SELECT_CREATIVES]: selectCreatives,
  [creativesConstants.CHANGE_ADVERTISER_URL]: setUrl,
  [creativesConstants.RESET_CREATIVE_STORE]: reset,
  [creativesConstants.VALIDATE_CREATIVE_STORE]: validateCreativeStore,
  [creativesConstants.SET_ADVANCED_VIDEO_TARGETING]: setAdvancedVideoTargeting,
  [creativesConstants.SET_ADVANCED_VIDEO_TARGETING_OPTIONS]: setAdvancedVideoTargetingOptions,
});

export const creatives = (state: CreativesState = defaultCreativesState, action: Action<any>) =>
  reducer(state, action);
