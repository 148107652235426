import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../models/Store';
import { ErrorCreatingResponse } from '../../models/Response';

type Props = {
  error: ErrorCreatingResponse | null;
  errorKey: string;
};

const ServerErrorMessageComponent = (props: Props) => {
  const { error, errorKey } = props;

  return error && errorKey === error.errorField ? (
    <p data-qa="37" className="text-field__help-text _error" data-not-valid="true">
      {error.errorMsg}
    </p>
  ) : null;
};

const mapState = (state: AppState) => ({
  error: state.app.errorCreating,
});

export const ServerErrorMessage = connect(mapState)(ServerErrorMessageComponent);
