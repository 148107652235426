import { put, select } from 'redux-saga/effects';

import { advancedTargetingActions } from 'store/advancedTargeting/actions';
import { API } from '../../api';
import { AppState } from '../../models/Store';

export function* fetchDetailedExchanges(): Generator<unknown, void, any> {
  const state: AppState = yield select();
  const { auth } = state;
  if (auth.userData) {
    const response = yield API.Exchange.getDetailedExchangesInfo();
    const data = { key: 'detailedExchanges', value: response.data.data };
    yield put(advancedTargetingActions.setDetailedExchanges(data));
  }
}
