import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { bidActions, ChangeBidField } from 'store/bid/actions';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { ConversionTypeByName } from 'models/Conversion';
import { StoreFilter } from 'models/ZustandStore';
import { useStore } from 'zustandStore';
import { CampaignInfoField } from '../../../models/CampaignInfoFields';
import { AudienceWarning } from '../../../models/Audience';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { User } from '../../../models/User';
import { AppState } from '../../../models/Store';
import { FetchCreativeList } from '../../../api/Creatives';
import { applicationActions, SetCurrentIoId, SetBudgetTypeId } from '../../../store/app/actions';
import {
  getTechnologyBlockErrorCreating,
  getInventoryBlockErrorCreating,
} from '../../../store/app/helpers';
import { creativesActions } from '../../../store/creatives/actions';
import { advanceActions, SetCampaignSidebarInfo } from '../../../store/advance/actions';
import { AdvancePageState } from '../../../store/advance/reducer';
import { ErrorCreatingResponse } from '../../../models/Response';
import { US_COUNTRY_ID } from '../../../constants/location';
import { Campaign } from './Campaign';

type Props = SetCampaignSidebarInfo &
  SetCurrentIoId &
  SetBudgetTypeId &
  ChangeBidField & {
    isAuth: boolean;
    editableCampaign: ExistingCampaignData | null;
    user: User;
    sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
    creativeStoreHasErrors: boolean;
    technologyStoreHasErrors: boolean;
    publisherStoreHasErrors: boolean;
    bidStoreHasErrors: boolean;
    errorCreating: ErrorCreatingResponse | null;
    audienceWarning: AudienceWarning;
    budgetTypeId: number;
  };

const CreatePGCampaignComponent = (props: Props) => {
  const {
    isAuth,
    editableCampaign,
    user,
    sidebarCampaignInfo,
    setCampaignSidebarInfo,
    creativeStoreHasErrors,
    technologyStoreHasErrors,
    publisherStoreHasErrors,
    bidStoreHasErrors,
    errorCreating,
    audienceWarning,
    setCurrentIoId,
    changeBidField,
    budgetTypeId,
  } = props;

  const [
    setCampaignName,
    setSelectedCreatives,
    setCreativeTypeId,
    initConversionBlock,
    expandedBlocks,
    setExpandedBlocks,
  ] = useStore((store: StoreFilter) => [
    store.campaignInfoBlock.setCampaignName,
    store.creativesBlock.setSelectedCreatives,
    store.creativesBlock.setCreativeTypeId,
    store.conversionBlock.initConversionBlock,
    store.pgCampaignPage.expandedBlocks,
    store.pgCampaignPage.setExpandedBlocks,
  ]);

  const history = useHistory();

  const selectedCountry = sidebarCampaignInfo[CampaignInfoField.country];

  useEffect(() => {
    const { search } = history.location;
    const queryParams = queryString.parse(search);
    const { campaignName } = queryParams;

    if (typeof campaignName === 'string') {
      setCampaignName(campaignName?.trim() || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const { search, pathname } = history.location;
  const queryParams = queryString.parse(search);
  const { creativeIds, conversionIds, conversionType } = queryParams;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (pathname.includes('campaign-create-pg') && creativeIds && typeof creativeIds === 'string') {
      FetchCreativeList({
        creativeIds,
        pageNo: 1,
        noOfEntries: creativeIds?.split(',')?.length ?? 10,
      }).then((res) => {
        const creativeList = res.responseObject.data;
        setCreativeTypeId(creativeList[0].creativeTypeId);
        setSelectedCreatives(creativeList);

        if (!expandedBlocks.includes('Creatives')) {
          setExpandedBlocks([...expandedBlocks, 'Creatives']);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeIds, user]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    if (
      pathname.includes('campaign-create-pg') &&
      conversionIds &&
      typeof conversionIds === 'string' &&
      conversionType
    ) {
      const conversionIdArr = (conversionIds as string)
        .split(',')
        .map((id: string) => parseInt(id, 10));

      const ct = ConversionTypeByName[(conversionType as string)?.toLowerCase()];

      if (ct) {
        initConversionBlock({
          selectedConversions: conversionIdArr,
          conversionType: ct,
        });

        if (!expandedBlocks.includes('Conversion')) {
          setExpandedBlocks([...expandedBlocks, 'Conversion']);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionIds, conversionType]);

  useEffect(() => {
    // in create campaign, select US as default country
    if (!selectedCountry) {
      setCampaignSidebarInfo(CampaignInfoField.country, {
        label: 'United States',
        value: US_COUNTRY_ID,
        shortName: 'US',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, setCampaignSidebarInfo]);

  useEffect(() => {
    const { search } = history.location;
    const queryParams = queryString.parse(search);
    const { ioId } = queryParams;
    if (ioId) {
      setCurrentIoId(+ioId);
    }
  }, [history.location, setCurrentIoId]);
  React.useEffect(() => {
    if (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      changeBidField({
        key: 'budgetCapping',
        value: true,
      });
    }
  }, [changeBidField, budgetTypeId]);

  const errorIndices: boolean[] = Array(11).fill(false);
  errorIndices[0] = creativeStoreHasErrors;
  errorIndices[4] = publisherStoreHasErrors || getInventoryBlockErrorCreating(errorCreating);
  errorIndices[8] = technologyStoreHasErrors || getTechnologyBlockErrorCreating(errorCreating);
  errorIndices[9] = bidStoreHasErrors;

  return (
    <Campaign
      errorIndices={errorIndices}
      isAuth={isAuth}
      editableCampaign={editableCampaign}
      audienceWarning={audienceWarning}
      mode="create"
    />
  );
};

const mapState = (state: AppState) => ({
  isAuth: state.auth.authorized,
  user: state.auth.userData,
  editableCampaign: state.app.editableCampaign,
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  creativeStoreHasErrors: state.creatives.creativeStoreHasErrors,
  technologyStoreHasErrors: state.technology.technologyStoreHasErrors,
  publisherStoreHasErrors: state.publishers.publisherStoreHasErrors,
  bidStoreHasErrors: state.bid.bidStoreHasErrors,
  errorCreating: state.app.errorCreating,
  audienceWarning: state.audience.audienceWarning,
  budgetTypeId: state.app.budgetTypeId,
});

const mapAction = {
  selectCreatives: creativesActions.selectCreatives,
  selectCreativeType: creativesActions.selectCreativeType,
  setCollapseBlockIndex: applicationActions.setCollapseBlockIndex,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setCurrentIoId: applicationActions.setCurrentIoId,
  setBudgetTypeId: applicationActions.setBudgetTypeId,
  changeBidField: bidActions.changeBidField,
};

export const CreatePGCampaign = connect(mapState, mapAction)(CreatePGCampaignComponent);
