import React, { useState, useCallback } from 'react';
import { Tabs } from 'factor';

import { Option } from 'models/Option';
import { ICountryOption, TLocationFileType } from 'models/Location';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { US_COUNTRY_ID } from 'constants/location';
import { SetToastMessage } from 'store/map/actions';
import { DrawingModeSwitcher } from '../DrawingModeSwitcher';
import { UploadCustomAreaWrapper } from '../UploadCustomAreaWrapper';
import { UploadZipCodeWrapper } from '../UploadZipCodeWrapper';
import { LocationWrapper } from '../LocationWrapper';

interface Styles {
  [key: string]: string;
}

interface Props extends SetToastMessage {
  country: ICountryOption | null;
  saveToStore: (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => void;
  saveToStoreString: (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => void;
  styles: Styles;
  isOpenMapsVisible?: boolean;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const tabItems = (isUSA = true) => [
  {
    title: 'Location',
    value: 'location',
  },
  {
    title: 'Custom Area',
    value: 'customArea',
  },
  {
    title: `${isUSA ? 'Zip' : 'Postal'} Codes`,
    value: 'zipCodes',
  },
];

export const LocationTabsContainer = (props: Props) => {
  const {
    country,
    saveToStore,
    saveToStoreString,
    styles,
    setToastMessage,
    isOpenMapsVisible,
    selectedTab,
    setSelectedTab,
  } = props;

  const [extensionError, setExtensionError] = useState<string | JSX.Element>('');
  const [addressesSelection, setAddressesSelection] = useState<TLocationFileType>('locations');

  const isSelectedCountryUSA = useCallback(() => {
    return !!(country && country.value === US_COUNTRY_ID);
  }, [country]);

  const handleTabChange = useCallback(
    (item: { value: string }) => {
      setSelectedTab(item.value);
      if (selectedTab !== item.value) {
        setExtensionError('');
      }
    },
    [selectedTab, setSelectedTab],
  );

  return (
    <>
      <Tabs
        items={tabItems(isSelectedCountryUSA())}
        value={selectedTab}
        onChange={handleTabChange}
      />
      <div
        className={`${styles.tabContent} ${selectedTab === 'location' ? styles.show : 'hidden'}`}
      >
        <div className={styles.locationWrapper}>
          <LocationWrapper
            saveLocations={saveToStore}
            country={country}
            isOpenMapsVisible={isOpenMapsVisible}
            setToastMessage={setToastMessage}
            selectedTab={selectedTab}
          />
        </div>
      </div>
      <div
        className={`${styles.tabContent} ${selectedTab === 'customArea' ? styles.show : 'hidden'}`}
      >
        <div className={styles.tabLeftPortion}>
          <div className={styles.headerText}>Draw Custom Area</div>
          <DrawingModeSwitcher />
        </div>
        <div className={styles.tabRightPortion}>
          <UploadCustomAreaWrapper
            styles={styles}
            addressesSelection={addressesSelection}
            setAddressesSelection={setAddressesSelection}
            extensionError={extensionError}
            setExtensionError={setExtensionError}
          />
        </div>
      </div>
      <div
        className={`${styles.tabContent} ${selectedTab === 'zipCodes' ? styles.show : 'hidden'}`}
      >
        <UploadZipCodeWrapper
          styles={styles}
          extensionError={extensionError}
          setExtensionError={setExtensionError}
          isSelectedCountryUSA={isSelectedCountryUSA}
          changed={saveToStoreString('zipcodes')}
        />
      </div>
    </>
  );
};
