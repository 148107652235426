import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';

import { getCreativeListKey, getCreativesBasicInfoKey, getCreativesTypes } from 'api/QueryKeys';
import { WithResponse } from 'models/Response';
import {
  BulkUpdateClickURL,
  BulkUpdatePixelURL,
  CreativeListParams,
  CreativesBasicInfoPayload,
  fetchCreativeListQuery,
  FetchCreativeTypes,
  MakeDuplicateCreatives,
  fetchCreativesBasicInfoV3,
} from 'api/Creatives';

const onError = (e: WithResponse) =>
  enqueueSnackbar(
    e?.errorObjects?.[0]?.error
      ? (e.errorObjects[0].error as string)
      : 'Something went wrong. Please try after sometime.',
    {
      variant: 'error',
    },
  );

export const useCreativeList = (
  creativesListParams: CreativeListParams,
  queryParams?: { enabled?: boolean; onSuccess?: (arg: any) => void },
) => {
  const data = useQuery(
    // @ts-ignore
    getCreativeListKey.keys('fetchCreativeList', creativesListParams),
    fetchCreativeListQuery,
    {
      onSuccess: queryParams?.onSuccess,
      enabled: queryParams?.enabled,
      onError,
      staleTime: 0,
    },
  );

  return data;
};

export const useCreativesInfo = (
  creativesListParams: CreativesBasicInfoPayload,
  queryParams?: { enabled?: boolean; onSuccess?: (arg: any) => void },
) => {
  const data = useQuery(
    getCreativesBasicInfoKey.keys('fetchCreativesBasicInfoV3', creativesListParams),
    fetchCreativesBasicInfoV3,
    {
      onSuccess: queryParams?.onSuccess,
      enabled: queryParams?.enabled,
      onError,
      cacheTime: 0,
    },
  );
  return data;
};

export const useCreativeTypes = () => {
  const data = useQuery(getCreativesTypes.keys('getCreativesTypes'), FetchCreativeTypes, {
    onError,
  });
  return data;
};

export const useUpdateClickUrl = ({
  onSuccess,
  onError,
}: {
  onSuccess: (response: any) => void;
  onError: (response: any) => void;
}) => {
  const mutationResult = useMutation(BulkUpdateClickURL, {
    mutationKey: ['bulkUpdateClickURL'],
    onSuccess,
    onError,
  });

  return mutationResult;
};

export const useUpdatePixelUrl = ({
  onSuccess,
  onError,
}: {
  onSuccess: (response: any) => void;
  onError: (response: any) => void;
}) => {
  const mutationResult = useMutation(BulkUpdatePixelURL, {
    mutationKey: ['bulkUpdatePixelURL'],
    onSuccess,
    onError,
  });
  return mutationResult;
};

export const useDuplicateCreatives = ({ onSuccess }: { onSuccess: (response: any) => void }) => {
  const mutationResult = useMutation(MakeDuplicateCreatives, {
    mutationKey: ['MakeDuplicateCreatives'],
    onSuccess,
    onError,
  });
  return mutationResult;
};
