import React from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { Select, Dialog, DialogContent, DialogButtons } from 'factor';
import { RectangleExtended } from 'models/RectangleExtended';
import { PolygonExtended } from 'models/PolygonExtended';
import { CircleExtended } from 'models/CircleExtended';
import { ICountryOption } from 'models/Location';
import { ErrorCreatingResponse } from 'models/Response';
import { mapActions, SetToastMessage } from 'store/map/actions';
import { Open, toastActions } from 'store/toast/actions';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { API } from '../../../../../api';
import { Option } from '../../../../../models/Option';
import { AppState } from '../../../../../models/Store';
import { ExistingCampaignData } from '../../../../../models/ExistingCampaign';

interface Props extends SetToastMessage {
  value: Option | null;
  authorized: boolean;
  errorCreating: ErrorCreatingResponse | null;
  onChange: (value: ICountryOption, isInitialLoad?: boolean) => void;
  editableCampaign: ExistingCampaignData | null;
  preSavedCircles: CircleExtended[];
  preSavedPolygons: PolygonExtended[];
  preSavedRectangles: RectangleExtended[];
  openToast: Open['open'];
}

type State = {
  countries: ICountryOption[];
  dialogIsOpen: boolean;
  tempSelectedCountry: Option | null;
};

class CountrySelectWrapperComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      countries: [],
      dialogIsOpen: false,
      tempSelectedCountry: null,
    };
  }

  componentDidMount() {
    const { authorized } = this.props;
    if (authorized) {
      this.fetchCountries();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { authorized, editableCampaign, errorCreating, onChange } = this.props;
    const { countries } = this.state;
    if (prevProps.authorized !== authorized) {
      this.fetchCountries();
    }
    if (
      editableCampaign &&
      countries.length &&
      (prevProps.editableCampaign !== editableCampaign ||
        countries.length !== prevState.countries.length) &&
      !errorCreating
    ) {
      const editableCampaignCountry = countries.find(
        (c) => c.value === +editableCampaign.countryId,
      );
      if (editableCampaignCountry) {
        onChange(editableCampaignCountry, true);
      }
    }
  }

  fetchCountries = async () => {
    const response: ICountryOption[] = await API.List.getCountryOptions({ sortBy: '+id' });
    if (response.length) {
      const countries = response.map((country) => {
        return {
          label: country.label,
          value: country.value,
          shortName: country.shortName,
        };
      });

      this.setState({
        countries,
      });
    } else if (window.self !== window.top) {
      this.props.setToastMessage(SOMETHING_WENT_WRONG);
    } else {
      this.props.openToast(SOMETHING_WENT_WRONG);
    }
  };

  renderDialog = () => {
    const { onChange, value } = this.props;
    const { dialogIsOpen, tempSelectedCountry } = this.state;
    return tempSelectedCountry && value ? (
      <Dialog open={dialogIsOpen}>
        <DialogContent>
          <h5 data-qa="326" className="title-card">
            Change Country
          </h5>
          <p data-qa="327" className="mt-4 mb-4">
            Selected map areas can be changed. Are you sure?
          </p>
          <div data-qa="328" className="d-flex justify-content-between">
            <DialogButtons
              buttons={[
                {
                  title: 'Cancel',
                  handler: () => {
                    this.setState({ dialogIsOpen: false });
                    onChange(value);
                  },
                },
                {
                  title: 'Change',
                  handler: () => {
                    this.setState({ dialogIsOpen: false });
                    onChange(tempSelectedCountry);
                  },
                  className: 'btn-square _conflower-blue _filled',
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    ) : null;
  };

  hasFigures(): boolean {
    const { preSavedCircles, preSavedPolygons, preSavedRectangles } = this.props;
    return !!(preSavedCircles.length || preSavedPolygons.length || preSavedRectangles.length);
  }

  render() {
    const { countries } = this.state;

    const { value, onChange } = this.props;

    return (
      <>
        <Select
          placeholder="Select Country"
          label="Country"
          showControlLabel
          options={countries}
          value={value}
          isSearchable
          withConfirmation
          onChange={(selectedCountry: ICountryOption) => {
            if (selectedCountry.value !== value?.value && this.hasFigures()) {
              this.setState({
                tempSelectedCountry: selectedCountry,
                dialogIsOpen: true,
              });
            } else {
              onChange(selectedCountry);
            }
          }}
          tooltipParams={{
            label: 'Campaign will be served only in selected country',
          }}
        />
        {createPortal(this.renderDialog(), document.body)}
      </>
    );
  }
}

const mapState = (state: AppState) => ({
  authorized: state.auth.authorized,
  editableCampaign: state.app.editableCampaign,
  errorCreating: state.app.errorCreating,
  preSavedCircles: state.map.preSavedCircles,
  preSavedPolygons: state.map.preSavedPolygons,
  preSavedRectangles: state.map.preSavedRectangles,
});

const actions = {
  setToastMessage: mapActions.setToastMessage,
  openToast: toastActions.open,
};

export const CountrySelectWrapper = connect(mapState, actions)(CountrySelectWrapperComponent);
