import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { InfoCircle } from '@applift/icons';
import { TextField, CollapsibleBlock } from 'factor';
import { TimezoneProvider, TimezonePicker } from 'iqm-framework';
import isEqual from 'lodash/isEqual';
import { Box, Col, Typography } from '@applift/factor';

import { ExistingCampaignData } from 'models/ExistingCampaign';
import { blockStyles } from 'components/Block';
import { OptionId } from 'models/Option';
import { AppState } from 'models/Store';
import { API } from 'api';
import { BudgetData } from 'api/Campaign';
import { authActions, SetOrganizationTimezoneInfo } from 'store/auth2/actions';
import {
  advanceActions,
  SetCampaignSidebarInfo,
  SetDeviceTypes,
  SetIsTimezoneLoaded,
} from 'store/advance/actions';
import { Logger } from 'utils/logger';
import { useCustomerConfigInfo } from 'hooks/useUser';
import { emptyAfterSubmitValidation } from 'utils/validationRules';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { applicationActions, ResetError } from 'store/app/actions';
import { useIOInfo } from 'hooks/useIO';
import { advertiserActions, SetBidShadingApplicability } from 'store/advertiser/actions';
import { bidActions, ChangeBidField } from 'store/bid/actions';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { ErrorCreatingResponse } from 'models/Response';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { DEFAULT_ORGANIZATION_TIMEZONE } from 'constants/timezone';
import { ServerErrorMessage } from 'components/ServerErrorMessage';
import { StartEndDateWithTimezoneWrapper } from './StartEndDateWithTimezoneWrapper';
import { BudgetFieldsWrapper } from './BudgetFieldsWrapper';
import { EstimatedCampaignBudget } from './EstimatedCampaignField';

import styles from './styles.module.scss';

interface Props
  extends SetDeviceTypes,
    SetCampaignSidebarInfo,
    ResetError,
    SetOrganizationTimezoneInfo,
    SetIsTimezoneLoaded,
    SetBidShadingApplicability,
    ChangeBidField {
  timezone?: OptionId<number>;
  isTimezoneLoaded: boolean;
  submitted: boolean;
  saveDraftSubmitted: boolean;
  campaignName: string;
  errorCreating: ErrorCreatingResponse | null;
  editableCampaign: ExistingCampaignData | null;
  dailyBudget: string;
  totalBudget: string;
  startDate: number;
  endDate: number | null;
  budgetPacing: boolean;
  campaignId: number | null;
  isEndDateCleared: boolean;
  ioId?: AppState['advanced']['sidebarCampaignInfo']['ioId'];
  budgetTypeId: number;
  impressionsMaxDaily: string;
  impressionsMaxTotal: string;
  maxBid: string;
  isEditingMode: boolean;
  owId?: number;
}

const CampaignInfoBlockComponent = (props: Props) => {
  const {
    timezone,
    submitted,
    saveDraftSubmitted,
    campaignName: nameValue = '',
    errorCreating,
    resetError,
    setCampaignSidebarInfo,
    editableCampaign,
    dailyBudget,
    totalBudget,
    startDate,
    endDate,
    budgetPacing,
    campaignId,
    isEndDateCleared,
    // organizationTimezoneInfo,
    setOrganizationTimezoneInfo,
    setIsTimezoneLoaded,
    isTimezoneLoaded,
    budgetTypeId,
    impressionsMaxDaily,
    impressionsMaxTotal,
    maxBid,
    changeBidField,
    isEditingMode,
    owId,
    ioId,
    setBidShadingApplicability,
  } = props;

  const history = useHistory();

  const { data: ioDetail } = useIOInfo(ioId as unknown as string, { enabled: !!ioId });

  const [campaignName, setCampaignName] = useState<string>(nameValue);
  const [timezoneOptions, setTimezoneOptions] = useState<OptionId[]>([]);
  const [spentBudget, setSpentBudget] = useState<number | undefined>(undefined);
  const [budgetData, setBudgetData] = useState<BudgetData>();
  const [hasTimezoneInteraction, setHasTimezoneInteraction] = useState(false);
  const availableBudgetFields = useRef<{ [key: string]: number }>({});
  const isBudgetDataLoaded = useRef(false);
  let inputNameRef: HTMLInputElement | null = null;

  const CustomerConfigResponse = useCustomerConfigInfo(owId as number, (res) =>
    setBidShadingApplicability(res.isBidShadingEnabled),
  );

  const isBidShadingApplicable = CustomerConfigResponse.data?.isBidShadingEnabled;

  // Here we want to delay rendering of the component otherwise date component onChange handler is called even without date changed
  const [showTimeComponent, setShowTimeComponent] = React.useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowTimeComponent(true);
    });
  }, []);

  const handleChangeAndSaveToStore = useCallback(
    (field: string) => (value: any) => {
      if (field === 'campaignName') {
        setCampaignName(value);
      }
      setCampaignSidebarInfo(field, value);
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
        resetError();
      }
    },
    [errorCreating, resetError, setCampaignSidebarInfo],
  );

  const handleGetBudgetData = useCallback(async () => {
    try {
      const res: BudgetData = await API.Campaign.GetBudgetInfo({
        isGraphInfoRequired: false,
        budgetTypeId: availableBudgetFields.current.budgetTypeId,
        startTime: availableBudgetFields.current.startTime,
        timezone: availableBudgetFields.current.timezoneId,
        fixedDailyBudget: availableBudgetFields.current.fixedDailyBudget === 1,
        ...(availableBudgetFields.current.dailyBudget
          ? { budgetDay: Number(availableBudgetFields.current.dailyBudget) }
          : {}),
        ...(availableBudgetFields.current.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        availableBudgetFields.current.totalBudget
          ? { budgetTotal: Number(availableBudgetFields.current.totalBudget) }
          : {}),
        ...(availableBudgetFields.current.endDate
          ? { endTime: availableBudgetFields.current.endDate }
          : {}),
        ...(availableBudgetFields.current.campaignId
          ? { campaignId: availableBudgetFields.current.campaignId }
          : {}),
        ...(availableBudgetFields.current.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
        availableBudgetFields.current.totalImpressions
          ? { totalImpressions: availableBudgetFields.current.totalImpressions }
          : {}),
        ...(availableBudgetFields.current.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
        availableBudgetFields.current.fixedDailyBudget === 1 &&
        availableBudgetFields.current.dailyImpressions
          ? { dailyImpressions: availableBudgetFields.current.dailyImpressions }
          : {}),
        ...(availableBudgetFields.current.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
        typeof availableBudgetFields.current.maxBid === 'number' &&
        !Number.isNaN(availableBudgetFields.current.maxBid)
          ? { maxBid: availableBudgetFields.current.maxBid }
          : {}),
      });
      setBudgetData(res);
      if (
        availableBudgetFields.current.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
        res.actualImpressionSpent
      ) {
        setSpentBudget(res.actualImpressionSpent);
      }
      if (
        availableBudgetFields.current.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
        res.actualSpent
      ) {
        setSpentBudget(res.actualSpent);
      }
      isBudgetDataLoaded.current = true;
    } catch (e) {
      Logger.log('Error getting budgetInfo data');
    }
  }, []);

  const onTimezoneChange = useCallback(
    (tz: OptionId<number>) => {
      handleChangeAndSaveToStore('timezone')(tz);
      if (!isTimezoneLoaded) {
        setIsTimezoneLoaded(true);
      }
      setHasTimezoneInteraction(true);
    },
    [handleChangeAndSaveToStore, isTimezoneLoaded, setIsTimezoneLoaded],
  );

  useEffect(() => {
    if (
      !isTimezoneLoaded &&
      timezoneOptions?.length &&
      ioDetail?.data?.ioTimeZoneId &&
      !(typeof ioDetail?.data?.ioTimeZoneId === 'number')
    ) {
      setIsTimezoneLoaded(true);
    }
  }, [
    ioDetail?.data?.ioTimeZoneId,
    isTimezoneLoaded,
    setIsTimezoneLoaded,
    timezoneOptions?.length,
  ]);

  useEffect(() => {
    if (editableCampaign && editableCampaign.timezone) {
      const selectedTz = timezoneOptions.find((tz) => tz.id === editableCampaign.timezone);
      if (selectedTz) {
        handleChangeAndSaveToStore(CampaignInfoField.timezone)(selectedTz);
      }
    }
    if (editableCampaign && timezoneOptions?.length) {
      setIsTimezoneLoaded(true);
    }
  }, [editableCampaign, timezoneOptions, handleChangeAndSaveToStore, setIsTimezoneLoaded]);

  useEffect(() => {
    const { pathname } = history.location;

    if (
      pathname.indexOf('campaign-create') >= 0 &&
      typeof ioDetail?.data?.ioTimeZoneId === 'number' &&
      timezoneOptions?.length
    ) {
      const defaultTz = timezoneOptions.find(
        (tz) => tz.id === +(ioDetail?.data?.ioTimeZoneId || -1),
      );
      if (defaultTz) {
        if (!hasTimezoneInteraction) {
          handleChangeAndSaveToStore(CampaignInfoField.timezone)(defaultTz);
        }

        setOrganizationTimezoneInfo(defaultTz);
      } else {
        if (!hasTimezoneInteraction) {
          handleChangeAndSaveToStore(CampaignInfoField.timezone)(DEFAULT_ORGANIZATION_TIMEZONE);
        }

        setOrganizationTimezoneInfo(null);
      }
      setIsTimezoneLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneOptions, ioDetail?.data?.ioTimeZoneId, setOrganizationTimezoneInfo]);

  useEffect(() => {
    setCampaignName(nameValue);
  }, [nameValue]);

  useEffect(() => {
    if (!isBudgetDataLoaded.current || !budgetData) {
      return;
    }

    if (
      !availableBudgetFields.current.dailyBudget &&
      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
    ) {
      setCampaignSidebarInfo('dailyBudget', budgetData.budgetDay);
    }

    if (!availableBudgetFields.current.totalBudget) {
      setCampaignSidebarInfo('totalBudget', budgetData.budgetTotal);
    }

    if (!availableBudgetFields.current.endDate && !isEndDateCleared) {
      setCampaignSidebarInfo('endDate', budgetData.endTime * 1000);
    }
    if (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      if (availableBudgetFields.current.totalBudget) {
        setCampaignSidebarInfo('totalBudget', budgetData.budgetTotal);
      }
      if (!availableBudgetFields.current.dailyImpressions && budgetData.dailyImpressions) {
        changeBidField({
          key: 'impressionsMaxDaily',
          value: Math.floor(budgetData.dailyImpressions),
        });
      }
    }
  }, [budgetData, setCampaignSidebarInfo, isEndDateCleared, budgetTypeId, changeBidField, maxBid]);

  const isBudgetCampaign = React.useMemo(
    () => budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED,
    [budgetTypeId],
  );
  const isImpressionCampaign = React.useMemo(
    () => budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED,
    [budgetTypeId],
  );

  const fieldRequired = React.useCallback(() => {
    if (campaignId) {
      return 7;
    }
    return 6;
  }, [campaignId]);

  useEffect(() => {
    const newAvailableBudgetFields: { [key: string]: number } = {
      startTime: Math.round(startDate / 1000),
      fixedDailyBudget: Number(!budgetPacing),
    };
    if (dailyBudget && dailyBudget !== '0.00' && !budgetPacing && isBudgetCampaign) {
      newAvailableBudgetFields.dailyBudget = +dailyBudget;
    }
    if (totalBudget && totalBudget !== '0.00' && isBudgetCampaign) {
      newAvailableBudgetFields.totalBudget = +totalBudget;
    }
    if (endDate) {
      newAvailableBudgetFields.endDate = Math.round(endDate / 1000);
    }
    if (campaignId) {
      newAvailableBudgetFields.campaignId = campaignId;
    }

    if (timezone) {
      newAvailableBudgetFields.timezoneId = timezone.id;
    }

    if (budgetTypeId) {
      newAvailableBudgetFields.budgetTypeId = budgetTypeId;
    }

    if (isImpressionCampaign) {
      if (impressionsMaxDaily && !budgetPacing) {
        newAvailableBudgetFields.dailyImpressions = parseInt(impressionsMaxDaily, 10);
      }
      if (impressionsMaxTotal) {
        newAvailableBudgetFields.totalImpressions = parseInt(impressionsMaxTotal, 10);
      }
      if (maxBid && typeof parseFloat(maxBid) === 'number') {
        newAvailableBudgetFields.maxBid = parseFloat(maxBid);
      }
    }

    const shouldGetNewBudgetData =
      !isEqual(newAvailableBudgetFields, availableBudgetFields.current) &&
      (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
        ? Object.keys(newAvailableBudgetFields).filter((key) => key !== 'maxBid').length >=
          fieldRequired()
        : Object.keys(newAvailableBudgetFields).length >= fieldRequired());

    if (shouldGetNewBudgetData) {
      availableBudgetFields.current = newAvailableBudgetFields;
      handleGetBudgetData();
    }
  }, [
    dailyBudget,
    totalBudget,
    startDate,
    endDate,
    budgetPacing,
    timezone,
    handleGetBudgetData,
    budgetTypeId,
    campaignId,
    impressionsMaxTotal,
    impressionsMaxDaily,
    maxBid,
    isBudgetCampaign,
    isImpressionCampaign,
    fieldRequired,
  ]);

  const calculatedTotalBudget = React.useMemo(() => {
    if (
      (maxBid.length || typeof maxBid === 'number') &&
      (typeof impressionsMaxTotal === 'number' || impressionsMaxTotal.length)
    ) {
      const bid = parseFloat(maxBid);
      const totalI = parseFloat(impressionsMaxTotal);
      return `${(bid * totalI) / 1000}`;
    }
    return '';
  }, [maxBid, impressionsMaxTotal]);

  const calculatedDailyBudget = React.useMemo(() => {
    if (maxBid.length && (typeof impressionsMaxDaily === 'number' || impressionsMaxDaily.length)) {
      const bid = parseFloat(maxBid);
      const dailyI = parseFloat(impressionsMaxDaily);
      return `${(bid * dailyI) / 1000}`;
    }
    return '';
  }, [maxBid, impressionsMaxDaily]);

  React.useEffect(() => {
    if (
      budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
      calculatedTotalBudget.length &&
      !isEditingMode
    ) {
      setCampaignSidebarInfo('totalBudget', calculatedTotalBudget);
    }
    if (
      budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
      calculatedDailyBudget.length &&
      (!isEditingMode || !dailyBudget.length)
    ) {
      setCampaignSidebarInfo('dailyBudget', calculatedDailyBudget);
    }
  }, [
    budgetTypeId,
    calculatedTotalBudget,
    setCampaignSidebarInfo,
    isEditingMode,
    calculatedDailyBudget,
    dailyBudget,
  ]);

  return (
    <CollapsibleBlock
      collapsible={false}
      header={{
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
            <InfoCircle fontSize={24} color="primary" />
            <Typography>Campaign Info</Typography>
          </Box>
        ),
      }}
    >
      <TimezoneProvider timezone={isTimezoneLoaded ? timezone : { label: '', value: -1 }}>
        <div data-qa="245" className={`row mb-4 ${blockStyles.blockRow}`}>
          <div
            data-qa="246"
            className={`col-4 ${styles.nameField}`}
            data-not-valid={!campaignName.trim().length}
          >
            <TextField
              label="Campaign Name"
              value={campaignName}
              onChange={handleChangeAndSaveToStore('campaignName')}
              validationRules={[
                {
                  ...emptyAfterSubmitValidation(saveDraftSubmitted || submitted),
                  name: 'Campaign name',
                },
              ]}
              validateOnInit
              validationKeys={[submitted, saveDraftSubmitted]}
              inputRef={(el: HTMLInputElement) => {
                inputNameRef = el;
                inputNameRef.maxLength = 1024;
              }}
            />
            <ServerErrorMessage errorKey={errorFieldsMapper.campaignName} />
          </div>
          {/* <div className="col-4">
            <CampaignGroupSelectWrapper onChange={handleChangeAndSaveToStore('campaignGroups')} />
            <ServerErrorMessage errorKey={errorFieldsMapper.campaignGroups} />
          </div> */}
          <div data-qa="375" className="col-4">
            <TimezonePicker
              onLoaded={setTimezoneOptions}
              onTimezoneChange={onTimezoneChange}
              tooltipParams={{
                label:
                  'For scheduling sake campaign days will start at 12 am and end at 12 am in the selected timezone',
              }}
              placeholder={isTimezoneLoaded ? undefined : 'Loading'}
              clearSearchOnToggleOpen
            />
            <ServerErrorMessage errorKey={errorFieldsMapper.timezone} />
          </div>
        </div>
        <BudgetFieldsWrapper
          spentBudget={spentBudget}
          isBidShadingApplicable={isBidShadingApplicable as boolean}
        />
        <div className="row">
          {showTimeComponent && <StartEndDateWithTimezoneWrapper />}
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED && (
            <Col md={4}>
              <EstimatedCampaignBudget />
            </Col>
          )}
        </div>
      </TimezoneProvider>
    </CollapsibleBlock>
  );
};

const mapState = (state: AppState) => ({
  timezone: state.advanced.sidebarCampaignInfo[CampaignInfoField.timezone],
  isTimezoneLoaded: state.advanced.isTimezoneLoaded,
  campaignName: state.advanced.sidebarCampaignInfo[CampaignInfoField.name],
  submitted: state.app.submitted,
  saveDraftSubmitted: state.app.saveDraftSubmitted,
  errorCreating: state.app.errorCreating,
  editableCampaign: state.app.editableCampaign,
  dailyBudget: state.advanced.sidebarCampaignInfo[CampaignInfoField.dailyBudget],
  totalBudget: state.advanced.sidebarCampaignInfo[CampaignInfoField.totalBudget],
  startDate: state.advanced.sidebarCampaignInfo.startDate,
  endDate: state.advanced.sidebarCampaignInfo.endDate,
  budgetPacing: state.advanced.budgetPacing,
  campaignId: state.app.editableCampaign?.id || null,
  isEndDateCleared: state.advanced.isEndDateCleared,
  organizationTimezoneInfo: state.auth.organizationTimezoneInfo,
  budgetTypeId: state.app.budgetTypeId,
  impressionsMaxDaily: state.bid.impressionsMaxDaily,
  impressionsMaxTotal: state.bid.impressionsMaxTotal,
  maxBid: state.advanced.sidebarCampaignInfo.maxBid,
  isEditingMode: state.app.isEditingMode,
  owId: state.auth.userData.owId,
  ioId: state.app.savedIoId,
});

const mapActions = {
  setDeviceTypes: advanceActions.setDeviceTypes,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  resetError: applicationActions.resetError,
  setOrganizationTimezoneInfo: authActions.setOrganizationTimezoneInfo,
  setIsTimezoneLoaded: advanceActions.setIsTimezoneLoaded,
  changeBidField: bidActions.changeBidField,
  setBidShadingApplicability: advertiserActions.setBidShadingApplicability,
};

export const CampaignInfoBlock = connect(mapState, mapActions)(CampaignInfoBlockComponent);
