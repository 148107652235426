import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Tooltip } from 'factor';
import { Add } from '@applift/icons';
import { refetchSelectConversionsTable } from '@applift/conversion';

import { AppState } from 'models/Store';
import { ConversionType } from 'models/Conversion';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { conversionActions, SelectConversionField } from 'store/conversion/actions';
import { toastActions, Open } from 'store/toast/actions';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { SelectConversionsDialog } from './SelectConversionsDialog';
import { CreatePixelConversionDialog } from './CreatePixelConversionDialog';
import { CreatePostbackConversionDialog } from './CreatePostbackConversionDialog';
import { ChangeConversionTypeDialog } from './ChangeConversionTypeDialog';

import styles from './styles.module.scss';

interface DispatchProps extends SelectConversionField {
  openToast: Open['open'];
}

export type StateProps = {};

interface OwnProps {
  showTitle: boolean;
  disableChangeConversionType?: boolean;
  disableChangeConversionSelection?: boolean;
  className?: string;
  onSelectDialogConversions?: (ids: number[]) => void;
  onCreateConversion?: (conversionId: number) => void;
  setInitTableConversionIds: React.Dispatch<React.SetStateAction<number[]>>;
}

interface Props extends OwnProps, DispatchProps {}

export const ConversionSelectionCardComponent = (props: Props) => {
  const {
    showTitle,
    disableChangeConversionType,
    disableChangeConversionSelection,
    className,
    openToast,
    onSelectDialogConversions,
    onCreateConversion,
    setInitTableConversionIds,
    selectConversionField,
  } = props;

  const [isSelectConversionsDialogOpen, setIsSelectConversionsDialogOpen] = useState(false);
  const [isCreatePostbackDialogOpen, setIsCreatePostbackDialogOpen] = useState(false);
  const [isCreatePixelDialogOpen, setIsCreatePixelDialogOpen] = useState(false);
  const [isChangeConversionTypeDialogOpen, setIsChangeConversionTypeDialogOpen] = useState(false);

  const [conversionType, selectedConversions, setConversionType, setSelectedConversions] = useStore(
    (state: StoreFilter) => [
      state.conversionBlock.conversionType,
      state.conversionBlock.selectedConversions,
      state.conversionBlock.setConversionType,
      state.conversionBlock.setSelectedConversions,
    ],
  );

  const onTableApiError = useCallback(
    (_e: any) => {
      openToast(SOMETHING_WENT_WRONG);
    },
    [openToast],
  );

  const conversionTypeText = useMemo(
    () => (conversionType === ConversionType.POSTBACK ? 'Postback' : 'Pixel'),
    [conversionType],
  );

  const onClickChangeConversionType = useCallback(() => {
    setIsChangeConversionTypeDialogOpen(true);
  }, []);

  const title = useMemo(() => {
    if (showTitle) {
      const iconName =
        conversionType === ConversionType.POSTBACK ? 'EventsPostBack' : 'EventsPixel';
      return (
        <div
          className={`${styles.titleWrapper} ${
            disableChangeConversionType ? 'justify-content-start' : ''
          }`}
        >
          <div className={styles.title}>
            <Icon name={iconName} className="mr-2" />
            {conversionTypeText}
          </div>
          {!disableChangeConversionType && (
            <span
              role="button"
              onClick={onClickChangeConversionType}
              className={styles.changeConversionTypeButton}
            >
              Change Conversion Type
            </span>
          )}
        </div>
      );
    }
    return null;
  }, [
    conversionType,
    showTitle,
    conversionTypeText,
    onClickChangeConversionType,
    disableChangeConversionType,
  ]);

  const infoText = useMemo(() => {
    switch (conversionType) {
      case ConversionType.PIXEL:
        return 'Conversion tracking using a tracking pixel';
      case ConversionType.POSTBACK:
        return 'Conversion tracking using Media Measurement Partners (MMP)';
      default:
        return null;
    }
  }, [conversionType]);

  const openDialog = useCallback(
    (conversionType) => () => {
      refetchSelectConversionsTable();
      if (conversionType === ConversionType.PIXEL) {
        setIsCreatePixelDialogOpen(true);
      } else if (conversionType === ConversionType.POSTBACK) {
        setIsCreatePostbackDialogOpen(true);
      }
    },
    [],
  );

  const handleChangeInStore = (field: string) => (value: number[]) =>
    selectConversionField({
      key: field,
      value,
    });

  const handleChangeConversionType = () => {
    setSelectedConversions([]);
    setConversionType(ConversionType.NONE);
    handleChangeInStore(CampaignInfoField.conversions)([]);
    setInitTableConversionIds([]);
  };

  const buttons = useMemo(() => {
    if (disableChangeConversionSelection) {
      return null;
    }

    const disableButtons =
      conversionType === ConversionType.POSTBACK && selectedConversions?.length;

    return (
      <div className={styles.buttons}>
        <Tooltip label="Only one postback can be added to a campaign" disable={!disableButtons}>
          <Button
            onClick={() => setIsSelectConversionsDialogOpen(true)}
            size="md"
            className="btn-square _filled _cornflower-blue m-0 mr-3"
            disabled={disableButtons}
          >
            <Add fontSize={20} sx={{ marginRight: 8 }} />
            Select {conversionTypeText}
          </Button>
        </Tooltip>
        <Tooltip label="Only one postback can be added to a campaign" disable={!disableButtons}>
          <Button
            onClick={openDialog(conversionType)}
            size="md"
            className="btn-square _cornflower-blue m-0"
            disabled={disableButtons}
          >
            <Add fontSize={20} sx={{ marginRight: 8 }} />
            Create New {conversionTypeText}
          </Button>
        </Tooltip>
      </div>
    );
  }, [
    disableChangeConversionSelection,
    conversionType,
    conversionTypeText,
    openDialog,
    selectedConversions,
  ]);

  return (
    <>
      <div
        className={`${styles.wrapper} ${
          conversionType === ConversionType.POSTBACK ? styles.postbackWrapper : styles.pixelWrapper
        } ${className || ''}`}
      >
        {showTitle ? title : null}
        {!!infoText && <div className={styles.infoText}>{infoText}</div>}
        {buttons}
      </div>
      {isSelectConversionsDialogOpen && !!conversionType && (
        <SelectConversionsDialog
          initialConversionSelection={selectedConversions}
          setOpen={setIsSelectConversionsDialogOpen}
          conversionType={conversionType}
          setConversions={onSelectDialogConversions}
          onError={onTableApiError}
        />
      )}
      {isCreatePixelDialogOpen && (
        <CreatePixelConversionDialog
          onCreate={onCreateConversion}
          onClose={() => setIsCreatePixelDialogOpen(false)}
        />
      )}
      {isCreatePostbackDialogOpen && (
        <CreatePostbackConversionDialog
          onCreate={onCreateConversion}
          onClose={() => setIsCreatePostbackDialogOpen(false)}
        />
      )}
      {isChangeConversionTypeDialogOpen && (
        <ChangeConversionTypeDialog
          onClose={() => setIsChangeConversionTypeDialogOpen(false)}
          onConfirm={handleChangeConversionType}
        />
      )}
    </>
  );
};

const mapAction = {
  openToast: toastActions.open,
  selectConversionField: conversionActions.selectConversionField,
};

export const ConversionSelectionCard = connect<StateProps, DispatchProps, any, AppState>(
  null,
  mapAction,
)(ConversionSelectionCardComponent);
