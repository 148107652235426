import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  TextField,
  LoadingButton,
} from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import { MAX_PRIORITY, MIN_PRIORITY } from 'constants/advancedModelling';
import { REGEX_POSITIVE_INT } from 'constants/regex';
import { RUM_ACTION_ATTRIBUTE } from 'services/DataDog/constants';

interface Props {
  closeDialog: () => void;
  onCompletion: (priority: number) => void;
  isLoading: boolean;
  initialPriority?: number | null;
}

export const SetCampaignPriorityDialog = (props: Props) => {
  const { closeDialog, onCompletion, isLoading, initialPriority } = props;
  const [priority, setPriority] = React.useState<string>(`${initialPriority || ''}`);

  const handleConfirm = () => {
    if (priority) {
      onCompletion(Number.parseInt(priority, 10));
    }
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value || REGEX_POSITIVE_INT.test(value)) {
      setPriority(value);
    }
  };

  const textFieldError =
    priority &&
    (Number.parseInt(priority, 10) < MIN_PRIORITY || Number.parseInt(priority, 10) > MAX_PRIORITY)
      ? 'Must be in the range of 1-10'
      : '';

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeDialog}>Set Priority</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 24 }}>
          <Typography component="div">
            Enter the <Typography weight="demi">Priority</Typography> for the selected campaigns
          </Typography>
        </Box>
        <TextField
          label="Priority (1-10)"
          value={priority || ''}
          onChange={handleTextFieldChange}
          error={!!textFieldError}
          helperText={textFieldError}
          placeholder="Enter priority"
          variant="outlinedDash"
          sx={{ mb: 24 }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoCircle fontSize={24} sx={{ mr: 8, textColor: 'neutral-400' }} />
          <Typography component="div">
            This priority will be applied to all the selected campaign(s)
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleConfirm}
          disabled={
            !!textFieldError ||
            !priority ||
            (!!initialPriority && `${initialPriority}` === priority)
          }
          loading={isLoading}
          {...{ [RUM_ACTION_ATTRIBUTE]: 'Confirm Set Bulk Campaign Priority' }}
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
