import React from 'react';
import { User } from 'models/User';
import { AppState } from 'models/Store';
import { CreativeGroupsState } from 'store/creativeGroups/reducer';
import { creativeGroupsActions } from 'store/creativeGroups/actions';
import { connect } from 'react-redux';
import { API } from 'api';
import { Creative } from 'models/Creative';
import { TCreativeGroupWithCreatives } from 'api/CreativeGroup';

import styles from './CreativesGroupsList.module.scss';

type TCreativeGroup = {
  value: number;
  label: string;
};

type TProps = {
  authorizedUser: User;
  creativeGroups: CreativeGroupsState;
  fetchCreativeGroups: () => void;
  creatives: Creative[];
  status?: string;
  creativeTypeId?: number;
  totalRecords?: number;
  onChangeGroup: (creativeGroupId?: number) => void;
};

type TState = {
  allCreativesGroups: TCreativeGroup;
  groups: TCreativeGroup[];
  activeItemId?: number;
};

class CreativesGroupsList extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      groups: [],
      activeItemId: 0,
      allCreativesGroups: {
        value: 0,
        label: 'All Creatives',
      },
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.creatives.length) {
      this.loadGroups();
    }
  }

  componentDidUpdate(oldProps: TProps) {
    const { groups } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    if (!oldProps.totalRecords && this.props.totalRecords && !groups.length) {
      this.loadGroups();
    }
  }

  loadGroups = async () => {
    const { authorizedUser, creatives, totalRecords, status, creativeTypeId } = this.props;
    if (authorizedUser && creatives) {
      const creativeGroups = await API.CreativeGroup.FetchCreativeGroups({
        creativeTypeId,
        status,
      });

      const allCreativesGroups: TCreativeGroup = {
        value: 0,
        label: `All Creatives (${totalRecords})`,
      };

      this.setState({
        allCreativesGroups,
        groups: creativeGroups.map((group: TCreativeGroupWithCreatives) => {
          return {
            label: `${group.id === -1 ? 'Ungrouped Creatives' : group.name} (${
              group.creatives.length
            })`,
            value: group.id,
          };
        }, []),
      });
    }
  };

  renderItem = (item: TCreativeGroup) => {
    const { activeItemId } = this.state;
    const { onChangeGroup } = this.props;

    const isActive = activeItemId !== undefined && item.value === activeItemId;
    const className = `${styles.groupListItem} ${isActive ? styles.activeGroupListItem : ''}`;
    const onClick = () => {
      onChangeGroup(item.value !== 0 ? item.value : undefined);
      this.setState({
        activeItemId: item.value,
      });
    };

    return (
      <div
        key={item.value}
        onClick={item.value !== activeItemId ? onClick : undefined}
        className={className}
      >
        {item.label}
      </div>
    );
  };

  render() {
    const { groups, allCreativesGroups } = this.state;

    return (
      <div className={styles.creativeGroupsList}>
        {this.renderItem(allCreativesGroups)}
        {groups.map(this.renderItem)}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  creativeGroups: state.creativeGroups,
});

const mapDispatchToProps = {
  fetchCreativeGroups: creativeGroupsActions.fetchCreativeGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreativesGroupsList);
