import React from 'react';
import { Box, Button, ButtonProps } from '@applift/factor';
import { Upload } from '@applift/icons';

interface UploadFileProps {
  onUpload: (files: FileList) => void;
  /** File types that can be uploaded. Eg: ["csv", "xlsx"] */
  fileTypes: string[];
  uploadBtnVariant: 'text' | 'contained' | 'outlined' | undefined;
  uploadBtnText: string;
  buttonProps?: ButtonProps & React.RefAttributes<HTMLButtonElement>;
}

export const UploadFile = (props: UploadFileProps) => {
  const {
    onUpload,
    fileTypes,
    uploadBtnText,
    uploadBtnVariant,
    buttonProps: { sx, ...buttonProps } = {},
  } = props;

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const [value, setValue] = React.useState('');

  const handleFileInputClick = () => fileInputRef.current?.click?.();

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onUpload(e.target.files);
      setValue('');
    }
  };

  return (
    <Box>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept={fileTypes.map((type) => `.${type}`).join(', ')}
        onChange={handleFileInputChange}
        value={value}
      />
      <Button
        startIcon={<Upload />}
        variant={uploadBtnVariant}
        onClick={handleFileInputClick}
        sx={{ borderRadius: 2, ...sx }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...buttonProps}
      >
        {uploadBtnText}
      </Button>
    </Box>
  );
};
