import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Row,
  Col,
  TextField,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  Chip,
  Alert,
  DialogActions,
  Button,
  LoadingButton,
  enqueueSnackbar,
  RadioGroup,
  usePreviousProps,
} from '@applift/factor';
import { Hourglass, IoCurrency, IoImpression } from '@applift/icons';
import { TimezoneProvider } from 'iqm-framework';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';

import { AppState } from 'models/Store';
import { OptionId } from 'models/Option';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { useCreateIO } from 'hooks/useIO';
import { useTimezone } from 'hooks/useTimezone';
import { IOCreateResponse } from 'models/IO';
import { SingleDatePicker } from 'components/SingleDatePicker/SingleDatePicker';
import { Timezone } from 'components/Timezone';
import { IOStartDateWarningDrawer } from 'components/IOStartDateWarningDrawer';
import { DataDogLogger } from 'services/DataDog';
import { CreateIoParams } from 'services/DataDog/IODetailsPage';

import styles from './index.module.scss';

export interface CreateIODialogType {
  isOpen: boolean;
  refetchIoList: () => void;
  setOpenDialog: (arg: boolean) => void;
  onClose: () => void;
  organizationTimezoneInfo: null | number | OptionId;
}

const BUDGET_DISTRIBUTION_ID_MAP = {
  manual: '1',
  automatic: '2',
};

const CreateIODialogComponent = (props: CreateIODialogType) => {
  const { isOpen, setOpenDialog, refetchIoList, onClose, organizationTimezoneInfo } = props;
  const history = useHistory();
  const [ioName, setIoName] = React.useState('');
  const [ioStartTime, setIOStartTime] = React.useState<number | undefined>(undefined);
  const [ioBudgetDistributionMethodId, setIoBudgetDistributionMethodId] = React.useState<
    'manual' | 'automatic'
  >('manual');
  const [budgetType, setBudgetType] = React.useState<'dollar' | 'impression'>('dollar');
  const [timezone, setTimezone] = React.useState<OptionId<string>>();
  const [isDateWarningOpen, setIsDateWarningOpen] = React.useState(false);
  const prevIoName: Partial<{ ioName: string }> = usePreviousProps({ ioName });
  const dialogActionsRef = React.useRef<any>(null);
  const { data: timezoneData } = useTimezone();

  const onCreateSuccess = (ioCreateInfo: IOCreateResponse) => {
    setOpenDialog(false);
    enqueueSnackbar(
      <Typography>
        Insertion Order
        <Typography variant="span" component="span" weight="demi" sx={{ mx: 4 }}>
          {ioCreateInfo.ioName}
        </Typography>
        created successfully.
      </Typography>,
      {
        variant: 'success',
      },
    );
    refetchIoList();
    history.push(`/io-detail/${ioCreateInfo.ioId}`);
  };

  const createIO = useCreateIO(onCreateSuccess);

  const isIoNameInvalid = Boolean(
    ioName.length > 255 || (prevIoName.ioName?.length && !ioName.length),
  );

  const fieldHelperText = React.useMemo(() => {
    if (!ioName.length && prevIoName.ioName?.length) {
      return 'Insertion order name can’t be empty';
    }
    if (ioName.trim().length > 255) {
      return 'Character limit exceeded';
    }
    return '';
  }, [ioName, prevIoName.ioName]);

  React.useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen, setOpenDialog]);

  const handleClose = () => {
    setIsDateWarningOpen(false);
    onClose();
  };

  const handleCreateIO = (startTime: number, source: CreateIoParams['actionType']) => {
    DataDogLogger.IODetailsPage.createIO({ actionType: source });

    createIO.mutate({
      ioName: ioName?.trim(),
      ioStartTime: startTime,
      ioTimeZoneId: timezone?.id,
      ioBudgetDistributionMethodId: BUDGET_DISTRIBUTION_ID_MAP[ioBudgetDistributionMethodId],
      ioBudgetTypeId:
        budgetType === 'impression'
          ? BUDGET_TYPE_ID.IMPRESSIONS_BASED
          : BUDGET_TYPE_ID.DOLLAR_BASED,
    });
  };

  React.useEffect(() => {
    const defaultTimezone = timezoneData
      ?.filter((val) => val.id === organizationTimezoneInfo)
      .map((val) => ({ ...val, value: val.name }))[0];
    // @ts-ignore
    delete defaultTimezone?.name;
    if (!timezone) {
      setTimezone(defaultTimezone);
    }
  }, [organizationTimezoneInfo, timezone, timezoneData]);

  return (
    <>
      <Dialog open={isOpen} maxWidth="lg" fullWidth PaperProps={{ sx: { overflowY: 'visible' } }}>
        <DialogTitle onClose={handleClose}>Create Insertion Order</DialogTitle>
        <DialogContent dividers sx={{ py: 32, overflowY: 'visible' }}>
          <Row>
            <Col xs={4}>
              <TextField
                sx={{ width: 100 }}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
                    <Typography variant="label">Insertion Order Name</Typography>
                    <Typography variant="label">{ioName.length}/255</Typography>
                  </Box>
                }
                variant="outlinedDash"
                placeholder="Insertion order name"
                value={ioName}
                onChange={(e: any) => {
                  setIoName(e.target.value?.trim() ? e.target.value : '');
                }}
                error={isIoNameInvalid}
                helperText={
                  <Typography
                    variant="label"
                    sx={{ ...(isOpen ? { display: 'block' } : { display: 'none' }) }}
                  >
                    {fieldHelperText}
                  </Typography>
                }
              />
            </Col>
            <TimezoneProvider timezone={timezone}>
              <Col xs={4} className={styles.dateWrapper}>
                <SingleDatePicker
                  timezone={timezone}
                  updateDate={(date) => {
                    setIOStartTime(date);
                  }}
                  date={ioStartTime}
                  label="Start Date"
                  placeholderText="Select Start Date"
                  datePickerstyles={styles.startTimePicker}
                  preselected={[
                    {
                      key: 'today',
                      title: 'Today',
                      date: (
                        tz: {},
                        convert: (date: Moment) => Moment,
                        getCurrentDay: (tz: {}) => Moment,
                      ) => convert(getCurrentDay(tz)),
                    },
                  ]}
                />
              </Col>
              <Col xs={4}>
                <Timezone
                  label="Timezone"
                  onChange={setTimezone}
                  value={timezone}
                  timezoneTextFieldProps={{
                    variant: 'outlinedDash',
                  }}
                />
              </Col>
            </TimezoneProvider>
          </Row>
          <Row sx={{ mt: 32, mb: 16 }}>
            <Col xs={12}>
              <FormControl>
                <RadioGroup value={budgetType}>
                  <Typography variant="label">Budget Type</Typography>
                  <FormControlLabel
                    control={<Radio onClick={() => setBudgetType('dollar')} value="dollar" />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IoCurrency fontSize={20} sx={{ mr: 4 }} color="primary" />
                        Budget -
                        <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
                          Aims to allocate a specific monetary amount for campaigns.
                        </Typography>
                      </Box>
                    }
                  />
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      control={
                        <Radio value="impression" onClick={() => setBudgetType('impression')} />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IoImpression fontSize={20} sx={{ mr: 4 }} color="primary" />
                          Impression -
                          <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
                            Aims to deliver a predefined number of ad impressions across campaigns.
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
          <Row sx={{ mt: 32, mb: 16 }}>
            <Col xs={12}>
              <FormControl>
                <RadioGroup value={ioBudgetDistributionMethodId}>
                  <Typography variant="label">Budget distribution method</Typography>
                  <FormControlLabel
                    control={
                      <Radio
                        onClick={() => setIoBudgetDistributionMethodId('manual')}
                        value="manual"
                      />
                    }
                    label={
                      <Box>
                        Manual -{' '}
                        <Typography sx={{ textColor: 'neutral-500' }}>
                          The IO budget should be manually allocated to the campaigns underneath.
                        </Typography>
                      </Box>
                    }
                  />
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      disabled
                      control={
                        <Radio
                          value="automatic"
                          onClick={() => setIoBudgetDistributionMethodId('automatic')}
                        />
                      }
                      label={
                        <Typography sx={{ textColor: 'neutral-300' }}>
                          Auto - Our AI system automatically distributes the budget among the
                          underlying campaigns based on reach & targeting.
                        </Typography>
                      }
                    />
                    <Chip
                      variant="filled"
                      sx={{ ml: 4 }}
                      size="small"
                      color="secondary"
                      icon={<Hourglass />}
                      label={<Typography variant="label">Coming Soon</Typography>}
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Alert severity="info">
              Once the budget type & distribution method are chosen, they cannot be altered.
            </Alert>
          </Row>
        </DialogContent>
        <DialogActions ref={dialogActionsRef}>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <LoadingButton
            disabled={!ioName || isIoNameInvalid || !ioStartTime}
            onClick={() => {
              if (ioStartTime && ioStartTime < moment().valueOf()) {
                setIsDateWarningOpen(true);
              } else {
                handleCreateIO(ioStartTime as number, 'default');
              }
            }}
            loading={createIO.isLoading}
            color="primary"
            variant="contained"
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <IOStartDateWarningDrawer
        isLoading={createIO.isLoading}
        isOpen={isDateWarningOpen}
        setIsOpen={setIsDateWarningOpen}
        anchorRef={dialogActionsRef}
        onClickStartNow={() => {
          handleCreateIO(moment().seconds(0).milliseconds(0).valueOf(), 'startNow');
        }}
      />
    </>
  );
};

const mapState = (state: AppState) => ({
  organizationTimezoneInfo: state.auth.organizationTimezoneInfo,
});

export const CreateIODialog = connect(mapState)(CreateIODialogComponent);
