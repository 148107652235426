import * as React from 'react';
import { TypoTooltip, SxProps } from '@applift/factor';
import moment from 'moment';
import { useTimezone } from 'hooks/useTimezone';

interface DateCellType {
  /**
   * date should be in unix millisecond format.
   */
  date: number;
  timezoneId?: number;
  isTimezoneNameProvided?: boolean;
  sx?: SxProps;
  from?: boolean;
}

export const DateCell = ({
  date,
  sx,
  timezoneId,
  isTimezoneNameProvided = false,
  from,
}: DateCellType) => {
  const { data: timezoneDate } = useTimezone();

  const timezoneName = isTimezoneNameProvided
    ? (timezoneId as unknown as string)
    : timezoneDate?.filter((timezone) => timezone.id === timezoneId)[0]?.name;

  return date != null && date !== 0 ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        sx,
      }}
    >
      {timezoneName?.length
        ? moment(date)
            .tz(String(timezoneName) || 'UTC')
            .format('MM/DD/yyyy hh:mm A')
        : ''}
    </TypoTooltip>
  ) : (
    <>—</>
  );
};

export const TimezoneCell = ({ timezoneName }: { timezoneName: string }) => {
  const { data: timezoneDate } = useTimezone();

  const timezoneLabel = timezoneDate?.filter((timezone) => timezone.name === timezoneName)[0]
    ?.label;

  return timezoneLabel ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {timezoneLabel}
    </TypoTooltip>
  ) : (
    <>—</>
  );
};
