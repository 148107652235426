import { StoreFilter } from 'models/ZustandStore';
import { useStore } from 'zustandStore';

export const useClearPgCampaignStore = () => {
  const [
    setSpendingBudget,
    setMaxBidPrice,
    setStartDate,
    setEndDate,
    setTimezone,
    setAdvertiserDomain,
    setTotalBudget,
    setTotalImpressions,
    setPgFeesBudget,
    setPgFeesPercent,
    resetCreativeBlockStore,
    resetLocationBlockStore,
    resetInventoryBlockStore,
    resetConversionBlockStore,
    resetCampaignPoliticalAdvertiserStore,
  ] = useStore((state: StoreFilter) => [
    state.campaignInfoBlock.setSpendingBudget,
    state.campaignInfoBlock.setMaxBidPrice,
    state.campaignInfoBlock.setStartDate,
    state.campaignInfoBlock.setEndDate,
    state.campaignInfoBlock.setTimezone,
    state.campaignInfoBlock.setAdvertiserDomain,
    state.campaignInfoBlock.setTotalBudget,
    state.campaignInfoBlock.setTotalImpressions,
    state.campaignInfoBlock.setPgFeesBudget,
    state.campaignInfoBlock.setPgFeesPercent,
    state.creativesBlock.resetCreativeBlockStore,
    state.locationBlock.resetLocationBlockStore,
    state.inventoryBlock.resetInventoryBlockStore,
    state.conversionBlock.resetConversionBlockStore,
    state.politicalAdvertiser.resetCampaignPoliticalAdvertiserStore,
  ]);

  const resetStore = () => {
    setSpendingBudget(null);
    setMaxBidPrice(null);
    setStartDate(null);
    setEndDate(null);
    setTimezone(undefined);
    setAdvertiserDomain(null);
    setTotalBudget(null);
    setTotalImpressions(null);
    setPgFeesBudget(null);
    setPgFeesPercent(null);
    resetCreativeBlockStore();
    resetLocationBlockStore();
    resetInventoryBlockStore();
    resetConversionBlockStore();
    resetCampaignPoliticalAdvertiserStore();
  };

  return { resetStore };
};
