import { ORIGIN_URL } from 'config';
import { User } from '../../models/User';
import { OptionId } from '../../models/Option';
import { createAction } from '../../utils/actions';
import { createIqmInstance } from '../../api/Instance';
import { authConstants } from './constants';

export const authActions = {
  login(user: User) {
    createIqmInstance({
      baseURL: ORIGIN_URL,
      token: user.apiToken,
      owId: user.userId,
    });
    return createAction<User>(authConstants.LOGIN_SUCCESS, user);
  },
  setOrganizationTimezoneInfo(timezone: null | number | OptionId) {
    return createAction<null | number | OptionId>(
      authConstants.SET_ORGANIZATION_TIMEZONE_INFO,
      timezone,
    );
  },
};

export type Login = {
  login: (user: User) => void;
};

export type SetOrganizationTimezoneInfo = {
  setOrganizationTimezoneInfo: (timezone: null | number | OptionId) => void;
};
