export const BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
};

export const IO_STATUSES = {
  ACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
};

export const MIN_REQUIRED_DAILY_BUDGET_FOR_MODELLING = 100;

export const MIN_REQUIRED_DAILY_IMPRESSIONS_FOR_MODELLING = 1000;

export const MIN_REQUIRED_CAMPAIGN_DURATION_FOR_MODELLING = 3;

export const ORGANIZATION_APP_NAME = 'Organization';
export const REPORT_APP_NAME = 'Reports';
export const BID_MODEL_APP_NAME = 'Bid Model';
export const INSIGHTS_APP_NAME = 'Insights';
