import React from 'react';
import { InfoBox } from '@react-google-maps/api';

import styles from './styles.module.scss';

import { LatLng, Size } from '../../../../../../models/Google';

// import { ReactComponent as ManIcon } from './svg/info-box-man.svg';
import { ReactComponent as RadiusIcon } from './svg/info-box-radius.svg';

interface Props {
  position: LatLng;
  radius: number;
  pixelOffset?: Size;
}

export class MapInfoBox extends React.PureComponent<Props> {
  render() {
    const { position, radius, pixelOffset } = this.props;

    return (
      <InfoBox
        options={{
          closeBoxURL: '',
          disableAutoPan: true,
          pixelOffset,
        }}
        position={position}
      >
        <ul data-qa="412" className={styles.infoBox}>
          {/* <li data-qa="413" className={styles.infoBoxItem}>
            <i data-qa="414" className={styles.infoBoxIcon}>
              <ManIcon />
            </i>
            12,210
          </li> */}
          <li data-qa="415" className={styles.infoBoxItem}>
            <i data-qa="416" className={styles.infoBoxIcon}>
              <RadiusIcon />
            </i>
            {radius}mi
          </li>
        </ul>
      </InfoBox>
    );
  }
}
