import { createAction } from '../../utils/actions';
import { creativeGroupsConstants } from './constants';
import { Option } from '../../models/Option';
import { CreativeGroupsState } from './reducer';

export interface CreativeGroupsActions {
  fetchCreativeGroups: () => void;
  setCreativeGroups: (payload: CreativeGroupsState) => void;
}

export const creativeGroupsActions = {
  fetchCreativeGroups() {
    return createAction(creativeGroupsConstants.FETCH_CREATIVE_GROUPS);
  },

  setCreatives(creativeGroups: Option<number>[]) {
    return createAction<CreativeGroupsState>(creativeGroupsConstants.SET_CREATIVE_GROUPS, {
      creativeGroups,
    });
  },
};
