import axios, { AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import {
  BidLandscapeInfoType,
  EstimatorRequiredKeysType,
  TargableAudienceResponseType,
  getEsimatorInfoQueryKey,
} from 'models/Estimator';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import { WithResponse } from '../models/Response';

const ESTIMATOR_TIMEOUT = 30000;

export const getEstimatorRequiredKeys = async (): Promise<
  EstimatorRequiredKeysType | undefined
> => {
  try {
    const response: AxiosResponse<WithResponse<EstimatorRequiredKeysType>> =
      await getInstance().get('/v3/cmp/static/campaign/estimator/dimension/list');
    return response.data.data;
  } catch (e) {
    Logger.log('Error while getting the estimator key list', e);
    return Promise.reject(e);
  }
};

export const getTargatableAudienceInfo = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<typeof getEsimatorInfoQueryKey['keys']>>): Promise<
  WithResponse<TargableAudienceResponseType>
> => {
  const { payload } = queryKey[0]!;
  const { CancelToken } = axios;
  const source = CancelToken.source();
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled');
  });
  try {
    const response: AxiosResponse<WithResponse<TargableAudienceResponseType>> =
      await getInstance().post('/v3/cmp/campaign/estimation/reach', payload, {
        cancelToken: source.token,
        timeout: ESTIMATOR_TIMEOUT,
        timeoutErrorMessage: 'request timeout',
      });
    return response.data;
  } catch (e) {
    Logger.log('Error while getting targatable info', e);
    return Promise.reject(e);
  }
};

export const getBidLandscapeInfo = async ({
  queryKey,
  signal,
}: QueryFunctionContext<ReturnType<typeof getEsimatorInfoQueryKey['keys']>>): Promise<
  WithResponse<BidLandscapeInfoType>
> => {
  const { payload } = queryKey[0]!;
  const { CancelToken } = axios;
  const source = CancelToken.source();
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled');
  });
  try {
    const response: AxiosResponse<WithResponse<BidLandscapeInfoType>> = await getInstance().post(
      '/v3/cmp/campaign/estimation/bid-landscape',
      payload,
      {
        cancelToken: source.token,
        timeout: ESTIMATOR_TIMEOUT,
        timeoutErrorMessage: 'request timeout',
      },
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting bid landscape info', e);
    return Promise.reject(e);
  }
};
