import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { ErrorCreatingResponse, CreateUpdateSuccessResponse } from '../../models/Response';
import { applicationConstants, CollapseMode } from './constants';
import { ExistingCampaignData } from '../../models/ExistingCampaign';

export interface ApplicationState {
  sidebarOpened: boolean;
  isRightSidebarOpen: boolean;
  submitted: boolean;
  saveDraftSubmitted: boolean;
  loading: boolean;
  errorCreating: ErrorCreatingResponse | null;
  campaignCreated: boolean;
  campaignDrafted: boolean;
  campaignEdited: boolean;
  isEditingMode: boolean;
  campaignDataResetting: boolean;
  editableCampaign: ExistingCampaignData | null;
  editableCampaignIsLoading: boolean;
  mapIsLoading: boolean;
  savedCampaignId: number | null;
  savedIoId: number | null;
  savedCampaignStatus: string;
  collapseData: {
    collapseMode: CollapseMode;
    openIndex: number;
  };
  forceErrorCollapsiblesOpen: boolean;
  budgetTypeId: number;
  modellingDimensionToShow: string;
}

const defaultApplicationState: ApplicationState = {
  sidebarOpened: true,
  isRightSidebarOpen: true,
  submitted: false,
  saveDraftSubmitted: false,
  loading: false,
  errorCreating: null,
  campaignCreated: false,
  campaignDrafted: false,
  campaignEdited: false,
  isEditingMode: false,
  campaignDataResetting: false,
  editableCampaign: null,
  editableCampaignIsLoading: false,
  mapIsLoading: false,
  savedCampaignId: null,
  savedIoId: null,
  savedCampaignStatus: '',
  collapseData: {
    collapseMode: '',
    openIndex: -1,
  },
  forceErrorCollapsiblesOpen: false,
  budgetTypeId: 1,
  modellingDimensionToShow: '',
};

function toggleSidebar(state: ApplicationState): ApplicationState {
  return {
    ...state,
    sidebarOpened: !state.sidebarOpened,
  };
}

function toggleRightSidebar(state: ApplicationState): ApplicationState {
  return {
    ...state,
    isRightSidebarOpen: !state.isRightSidebarOpen,
  };
}

function setSubmitted(state: ApplicationState): ApplicationState {
  return {
    ...state,
    saveDraftSubmitted: false,
    submitted: true,
    errorCreating: null,
  };
}

function setSaveDraftSubmitted(state: ApplicationState): ApplicationState {
  return {
    ...state,
    saveDraftSubmitted: true,
    submitted: false,
    errorCreating: null,
  };
}

function changeCreatingMode(state: ApplicationState, action: Action<boolean>): ApplicationState {
  return {
    ...state,
    isEditingMode: action.payload,
  };
}

function toggleClearingCampaignData(
  state: ApplicationState,
  action: Action<boolean>,
): ApplicationState {
  return {
    ...state,
    campaignDataResetting: action.payload,
  };
}

function setBudgetTypeId(state: ApplicationState, action: Action<number>): ApplicationState {
  return {
    ...state,
    budgetTypeId: action.payload,
  };
}

function setEditableCampaign(
  state: ApplicationState,
  action: Action<ExistingCampaignData>,
): ApplicationState {
  return {
    ...state,
    editableCampaign: action.payload,
  };
}

function startCreating(state: ApplicationState): ApplicationState {
  return {
    ...state,
    loading: true,
    errorCreating: null,
    campaignCreated: false,
    campaignDrafted: false,
    campaignEdited: false,
    savedCampaignId: null,
    savedCampaignStatus: '',
  };
}

function startSavingCampaignAsDraft(state: ApplicationState): ApplicationState {
  return {
    ...state,
    loading: true,
    errorCreating: null,
    campaignCreated: false,
    campaignDrafted: false,
    campaignEdited: false,
  };
}

function setCurrentIoId(state: ApplicationState, action: Action<number>): ApplicationState {
  return {
    ...state,
    savedIoId: action.payload,
  };
}

function successCreating(
  state: ApplicationState,
  action: Action<CreateUpdateSuccessResponse>,
): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    campaignCreated: true,
    savedCampaignId: action.payload.data,
    savedCampaignStatus: action.payload.status,
  };
}

function successEditing(
  state: ApplicationState,
  action: Action<CreateUpdateSuccessResponse>,
): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    saveDraftSubmitted: false,
    campaignEdited: true,
    savedCampaignId: action.payload.data,
    savedCampaignStatus: action.payload.status,
  };
}

function successCreatingDraft(
  state: ApplicationState,
  action: Action<CreateUpdateSuccessResponse>,
): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    saveDraftSubmitted: false,
    campaignDrafted: true,
    savedCampaignId: action.payload.data,
    savedCampaignStatus: action.payload.status,
  };
}

function failCreating(state: ApplicationState, action: Action<any>): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    errorCreating: action.payload || true,
  };
}

function failSavingCampaignAsDraft(state: ApplicationState, action: Action<any>): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    saveDraftSubmitted: false,
    errorCreating: action.payload || true,
  };
}

function setEditableCampaignLoadingStatus(
  state: ApplicationState,
  action: Action<boolean>,
): ApplicationState {
  return {
    ...state,
    editableCampaignIsLoading: action.payload,
  };
}

function setMapLoadingStatus(state: ApplicationState, action: Action<boolean>): ApplicationState {
  return {
    ...state,
    mapIsLoading: action.payload,
  };
}

function setCollapseMode(state: ApplicationState, action: Action<CollapseMode>): ApplicationState {
  return {
    ...state,
    collapseData: {
      collapseMode: action.payload,
      openIndex: state.collapseData.openIndex,
    },
  };
}

function setCollapseBlockIndex(state: ApplicationState, action: Action<number>): ApplicationState {
  return {
    ...state,
    collapseData: {
      collapseMode: state.collapseData.collapseMode,
      openIndex: action.payload,
    },
  };
}

function resetError(state: ApplicationState): ApplicationState {
  return {
    ...state,
    errorCreating: null,
  };
}

function reset(state: ApplicationState): ApplicationState {
  const result = {
    ...defaultApplicationState,
    campaignCreated: state.campaignCreated,
    campaignDrafted: state.campaignDrafted,
    campaignEdited: state.campaignEdited,
    savedCampaignId: state.savedCampaignId,
    savedCampaignStatus: state.savedCampaignStatus,
    isEditingMode: state.isEditingMode,
    savedIoId: state.savedIoId,
  };
  return result;
}

function resetFull(): ApplicationState {
  const result = {
    ...defaultApplicationState,
  };
  return result;
}

function setErrorCollapsiblesOpen(
  state: ApplicationState,
  action: Action<boolean>,
): ApplicationState {
  return {
    ...state,
    forceErrorCollapsiblesOpen: action.payload,
  };
}
function setModellingDimensionToShow(
  state: ApplicationState,
  action: Action<string>,
): ApplicationState {
  return {
    ...state,
    modellingDimensionToShow: action.payload,
  };
}

const reducer = reducerFromMap<ApplicationState>(defaultApplicationState, {
  [applicationConstants.TOGGLE__SIDEBAR_MENU]: toggleSidebar,
  [applicationConstants.TOGGLE_RIGHT_SIDEBAR_MENU]: toggleRightSidebar,
  [applicationConstants.SET_SUBMITTED_STATE]: setSubmitted,
  [applicationConstants.SET_SAVE_DRAFT_SUBMITTED_STATE]: setSaveDraftSubmitted,
  [applicationConstants.CHANGE_EDITING_MODE]: changeCreatingMode,
  [applicationConstants.SET_BUDGET_TYPE_ID]: setBudgetTypeId,
  [applicationConstants.SET_EDITABLE_CAMPAIGN]: setEditableCampaign,
  [applicationConstants.RESET_CLEARING_CAMPAIGN_DATA]: toggleClearingCampaignData,
  [applicationConstants.START_CREATING]: startCreating,
  [applicationConstants.START_SAVING_CAMPAIGN_AS_DRAFT]: startSavingCampaignAsDraft,
  [applicationConstants.SUCCESS_CREATING]: successCreating,
  [applicationConstants.SUCCESS_EDITING]: successEditing,
  [applicationConstants.SUCCESS_CREATING_DRAFT]: successCreatingDraft,
  [applicationConstants.SET_CURRENT_IO_ID]: setCurrentIoId,
  [applicationConstants.FAIL_CREATING]: failCreating,
  [applicationConstants.FAIL_SAVING_CAMPAIGN_AS_DRAFT]: failSavingCampaignAsDraft,
  [applicationConstants.SET_EDITABLE_CAMPAIGN_LOADING_STATUS]: setEditableCampaignLoadingStatus,
  [applicationConstants.SET_MAP_LOADING_STATUS]: setMapLoadingStatus,
  [applicationConstants.SET_COLLAPSE_MODE]: setCollapseMode,
  [applicationConstants.SET_COLLAPSE_BLOCK_INDEX]: setCollapseBlockIndex,
  [applicationConstants.RESET_ERROR]: resetError,
  [applicationConstants.RESET_APP_STORE]: reset,
  [applicationConstants.RESET_APP_STORE_FULL]: resetFull,
  [applicationConstants.SET_ERROR_COLLAPSIBLES_OPEN]: setErrorCollapsiblesOpen,
  [applicationConstants.SET_MODELLING_DIMENSION_TO_SHOW]: setModellingDimensionToShow,
});

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) =>
  reducer(state, action);
