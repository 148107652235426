import React from 'react';
import {
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeNative,
  CreativeVideo,
} from '@applift/icons';
import { CREATIVE_ID_FROM_NAME } from 'constants/creatives';

export const getCreativeTypeIcon = (creativeTypeId: number) => {
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Image) {
    return <CreativeImage fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Video) {
    return <CreativeVideo fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Audio) {
    return <CreativeAudio fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.HTML) {
    return <CreativeHTML fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  if (creativeTypeId === CREATIVE_ID_FROM_NAME.Native) {
    return <CreativeNative fontSize={24} sx={{ textColor: 'primary-600' }} />;
  }
  return null;
};
