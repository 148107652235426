import get from 'lodash/get';
import { datadogRum } from 'iqm-framework';
import { AxiosError, AxiosResponse } from 'axios';

import { AppState } from 'models/Store';
import { DataDogCampaignAction } from 'models/DataDog';
import { CreateCampaignResponse, CampaignCreateUpdateParams } from 'api/Campaign';
import { extractPartialRequestForLogs, getChangedParameters } from 'store/app/helpers';
import { WithTimestamps } from 'models/Response';

export const getActionNamespace = () => {
  return (
    datadogRum.getRumGlobalContext?.()?.iframeParent ||
    datadogRum.getRumGlobalContext?.().service ||
    'campaigns'
  );
};

export const getResponseLatency = (response?: AxiosResponse<WithTimestamps<any>>) =>
  response?.data?.requestTimestamp && response?.data?.responseTimestamp
    ? response.data.responseTimestamp - response.data.requestTimestamp
    : null;

export const getErrorResponseLatency = (
  error: AxiosError<any> & { requestTimestamp?: number; responseTimestamp?: number },
) =>
  error.requestTimestamp && error.responseTimestamp
    ? error.responseTimestamp - error.requestTimestamp
    : null;

export const getApiErrorMsg = (response?: AxiosResponse<any>, withDefaultError?: boolean) => {
  return (
    get(response, 'data.errorObjects[0].error') ||
    get(response, 'data.responseObject.errorMsg') ||
    (withDefaultError ? `API Error: ${response?.status || 'No Status Code Found'}` : null)
  );
};

export const toDataDogCampaign = (
  state: AppState,
  response?: CreateCampaignResponse,
): DataDogCampaignAction => {
  const latency =
    response && response.requestTimestamp && response.responseTimestamp
      ? response.responseTimestamp - response.requestTimestamp
      : null;

  const partial = extractPartialRequestForLogs(state);
  let id = get(response || {}, 'responseObject.data');
  if (id && typeof +id !== 'number') {
    id = undefined;
  }

  return {
    id,
    name: partial.campaignName,
    timezone: get(state, 'advanced.sidebarCampaignInfo.timezone.label') || partial.timezone,
    creative_type: get(state, 'creatives.selectedCreativeType.label') || partial.creativeType,
    latency,
    start_date: partial.startTime,
    end_date: partial.endTime,
    total_budget: partial.budgetTotal,
    max_bid: partial.maxBid,
    daily_budget: partial.budgetDay,
    isBidShadingApplicable: state.advertiser.isBidShadingApplicable,
    isBidShading: partial.isBidShading,
  };
};

export const toDataDogCampaignEdit = (
  state: AppState,
  useChangedParametersOnly: boolean,
  response?: CreateCampaignResponse,
) => {
  const latency =
    response && response.requestTimestamp && response.responseTimestamp
      ? response.responseTimestamp - response.requestTimestamp
      : null;

  let partial;
  if (useChangedParametersOnly && state.app?.editableCampaign) {
    const editRequestDebug = extractPartialRequestForLogs(state);
    partial = getChangedParameters(
      state.app.editableCampaign,
      editRequestDebug as CampaignCreateUpdateParams,
    );
    partial.isBidShading = editRequestDebug.isBidShading;
  } else {
    partial = extractPartialRequestForLogs(state);
  }

  const timezone = partial.timezone
    ? get(state, 'advanced.sidebarCampaignInfo.timezone.label') || partial.timezone
    : undefined;
  const creativeType = partial.creativeType
    ? get(state, 'creatives.selectedCreativeType.label') || partial.creativeType
    : undefined;

  return {
    id: state.app?.editableCampaign?.id || get(response || {}, 'responseObject.data'),
    name: partial.campaignName,
    timezone,
    creative_type: creativeType,
    start_date: partial.startTime,
    end_date: partial.endTime,
    total_budget: partial.budgetTotal,
    max_bid: partial.maxBid,
    daily_budget: partial.budgetDay,
    latency,
    isBidShadingApplicable: state.advertiser.isBidShadingApplicable,
    isBidShading: partial.isBidShading,
  };
};
