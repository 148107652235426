import React from 'react';
import { connect } from 'react-redux';
import { TextField, ButtonCircle, ProgressCircle, Tooltip, Icon } from 'factor';
import { copyText } from 'iqm-framework';

import { Open, toastActions } from 'store/toast/actions';
import { FileUploader } from 'components/FileUploader';

import styles from './TextAreaBlock.module.scss';

interface Props {
  value: string;
  label: string;
  placeholder: string;
  onChanged: (value: string) => void;
  onFileUploaded?: ((data: any) => void) | null;
  onBtnClick?: ((...arg: any[]) => void) | null;
  id?: string;
  textAreaValidationErrors?: string[];
  openToast: Open['open'];
  isLoading?: boolean;
  uiNoteTooltip?: string;
}

const TextAreaBlockComponent = (props: Props) => {
  const {
    value,
    onChanged,
    label,
    placeholder,
    onFileUploaded,
    onBtnClick,
    id,
    textAreaValidationErrors,
    openToast,
    isLoading,
    uiNoteTooltip,
  } = props;

  const validationRules = [];
  const errorMsgs = textAreaValidationErrors?.filter((error) => error);
  if (errorMsgs?.length) {
    validationRules.push({
      func: () => false,
      error: () =>
        errorMsgs.map((errorMsg, i) => (
          <React.Fragment key={errorMsg}>
            {errorMsg}
            {i + 1 < errorMsgs.length ? <br data-qa="321" /> : ''}
          </React.Fragment>
        )),
    });
  }

  const handleClear = () => {
    onChanged('');
  };

  const handleCopy = () => {
    try {
      copyText(value);
      openToast('Copied!');
    } catch (e) {
      openToast('Error copying text.');
    }
  };

  return (
    <div
      data-qa="322"
      className={`w-100 ${styles.wrapper}`}
      data-not-valid={
        !!(textAreaValidationErrors?.length && textAreaValidationErrors.filter(Boolean).length)
      }
    >
      {isLoading && <ProgressCircle size={20} borderWidth={2} className={styles.loading} />}
      <TextField
        className={styles.textInput}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChanged}
        validationRules={validationRules}
        validationKeys={textAreaValidationErrors || []}
        isTextarea
      />
      <div data-qa="323" className="d-flex align-items-center justify-content-between mt-1">
        <div className="d-flex">
          {onFileUploaded && (
            <FileUploader onFileUploaded={onFileUploaded} browseFileButtonTitle="CSV" id={id} />
          )}
          {onBtnClick && (
            <span
              className={value?.trim().length ? styles.addBtn : styles.addBtnDisabled}
              onClick={() => onBtnClick(value)}
            >
              Add
            </span>
          )}
        </div>
        <div className={styles.actionButtons}>
          {uiNoteTooltip ? (
            <Tooltip
              auto
              portal
              labelMaxWidth={200}
              label={uiNoteTooltip}
              className={`mr-2 ${styles.infoTooltip}`}
            >
              <Icon name="CampaignsInfo" className={styles.campaignsInfoIcon} />
            </Tooltip>
          ) : null}
          {!!value?.trim().length && <ButtonCircle onClick={handleCopy} iconName="Copy" />}
          {!!value?.trim().length && (
            <ButtonCircle onClick={handleClear} className="ml-1" iconName="Refresh" />
          )}
        </div>
      </div>
    </div>
  );
};

const mapAction = {
  openToast: toastActions.open,
};

export const TextAreaBlock = connect(null, mapAction)(TextAreaBlockComponent);
