import { logDataDogAction } from 'iqm-framework';

import { DataDogCampaignAction } from 'models/DataDog';
import { getActionNamespace } from '../helpers';

export const App = {
  clickOnNewCampaign: () => {
    logDataDogAction('click on New Campaign');
  },
  creationOfCampaignSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('creation of Campaign Succeeded', campaign);
  },
  creationOfCampaignFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('creation of Campaign Failed', campaign);
  },
  editOfCampaignSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('edit of Campaign Succeeded', campaign);
  },
  editOfCampaignFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('edit of Campaign Failed', campaign);
  },
  setMaxBidPrice: () => {
    logDataDogAction('Set Max Bid Price', {
      action: `${getActionNamespace()}/setMaxBidPrice`,
    });
  },
};
