import * as React from 'react';
import {
  Box,
  Button,
  Col,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LoadingButton,
  Radio,
  RadioGroup,
  Row,
  Typography,
} from '@applift/factor';
import { Icon } from 'factor';
import { Creative } from 'models/Creative';
import WithErrorPlaceholderImage from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/withErrorPlacehoderImage/WithErrorPlaceholderImage';

export interface EditClickUrlDialogPropTypes {
  creativeIds?: number[];
  clickUrlUpdateLoading?: boolean;
  duplicateCreativesLoading?: boolean;
  clickUrl: string | undefined;
  failedCreatives?: Creative[];
  updateClickUrl?: (args: string, confirmStatusChange: boolean) => void;
  setShowConfirmClickUrlDialog: (args: boolean) => void;
  duplicateCreatives?: (clickUrl: string, creativesIds: number[]) => void;
}

export const ConfirmClickUrlChange = (props: EditClickUrlDialogPropTypes) => {
  const {
    setShowConfirmClickUrlDialog,
    clickUrlUpdateLoading,
    failedCreatives,
    updateClickUrl,
    clickUrl,
    duplicateCreatives,
    creativeIds,
    duplicateCreativesLoading,
  } = props;

  const [updateOrDuplicateCreative, setUpdateOrDuplicateCreative] = React.useState<
    'update' | 'duplicate'
  >('update');

  const renderContentCell = (creative: Creative) => {
    const { creativeThumbnailSource } = creative;
    if (creativeThumbnailSource) {
      return <WithErrorPlaceholderImage src={creative.creativeThumbnailSource} />;
    }
    return (
      <div>
        <Icon name="NoImage" />
      </div>
    );
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>Edit Click URL</DialogTitle>
      <DialogContent>
        <Typography>
          The following creatives are being used in other campaigns. If you update, they will go for
          re-approval and the effect will be applied in all the campaigns where they are used.
        </Typography>
        <Box sx={{ p: 8, border: 1, borderRadius: 4, mt: 16, mb: 24 }}>
          {failedCreatives?.map((crt) => (
            <Row key={crt.id} sx={{ mt: 16 }}>
              <Col xs={1}>
                <Typography variant="label" sx={{ display: 'block', textColor: 'neutral-500' }}>
                  ID
                </Typography>
                <Typography>{crt.id}</Typography>
              </Col>
              <Col xs={3}>{renderContentCell(crt)}</Col>
              <Col xs="auto">
                <Typography variant="label" sx={{ display: 'block', textColor: 'neutral-500' }}>
                  Name
                </Typography>
                <Typography>{crt.name}</Typography>
              </Col>
            </Row>
          ))}
        </Box>
        <FormControl>
          <RadioGroup value={updateOrDuplicateCreative} sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
              <FormControlLabel
                control={
                  <Radio
                    onClick={() => {
                      setUpdateOrDuplicateCreative('update');
                    }}
                    value="update"
                  />
                }
                sx={{ alignItems: 'start' }}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      flexDirection: 'column',
                      justifyContent: 'start',
                    }}
                  >
                    <Typography weight="demi">
                      Update creatives and apply the change to campaign
                    </Typography>
                    <Typography sx={{ textColor: 'neutral-500' }} variant="label">
                      Apply the change to selected creatives. The change gets reflected in their
                      associated campaigns including the running campaigns
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                control={
                  <Radio
                    value="duplicate"
                    onClick={() => {
                      setUpdateOrDuplicateCreative('duplicate');
                    }}
                  />
                }
                sx={{ alignItems: 'start' }}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      flexDirection: 'column',
                      justifyContent: 'start',
                    }}
                  >
                    <Typography weight="demi">Create a copy and update</Typography>
                    <Typography sx={{ textColor: 'neutral-500' }} variant="label">
                      Create a duplicate copy of the selected creatives and apply the change. All
                      associated campaigns remain untouched. It won't affect the running campaigns
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowConfirmClickUrlDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={clickUrlUpdateLoading || duplicateCreativesLoading}
          onClick={() => {
            if (updateOrDuplicateCreative === 'update' && updateClickUrl && clickUrl) {
              updateClickUrl(clickUrl, true);
            } else if (
              updateOrDuplicateCreative === 'duplicate' &&
              duplicateCreatives &&
              clickUrl &&
              creativeIds
            ) {
              duplicateCreatives(clickUrl, failedCreatives?.map((val) => val.id ?? []) || []);
            }
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
