import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../models/Store';
import { MapPolygon } from '../MapPolygon';
import { PolygonExtended } from '../../../../../../models/PolygonExtended';

interface Props {
  preSavedPolygons: PolygonExtended[];
}
class PreSavedPolygonsComponent extends React.PureComponent<Props> {
  render() {
    const { preSavedPolygons } = this.props;
    return preSavedPolygons.map((polygon) => {
      return <MapPolygon key={polygon.id} getPolygon={() => polygon} />;
    });
  }
}

const mapState = (state: AppState) => {
  return {
    preSavedPolygons: state.map.preSavedPolygons,
  };
};

export const PreSavedPolygons = connect(mapState)(PreSavedPolygonsComponent);
