import { AxiosError, AxiosResponse } from 'axios';
import { getInstance } from 'api/Instance';
import { WithResponse } from 'models/Response';
import {
  CreatePixelParams,
  CreatePostbackParams,
  PixelConversionInfo,
  PixelIntegrationData,
  CreatedPostbackInfo,
} from 'models/Conversion';

export const CreatePostback = async (
  params: CreatePostbackParams,
): Promise<WithResponse<CreatedPostbackInfo>> => {
  try {
    const response: AxiosResponse<WithResponse<CreatedPostbackInfo>> = await getInstance().post(
      `/v3/conversion/postback/add`,
      params,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const CreatePixel = async (
  params: CreatePixelParams,
): Promise<WithResponse<PixelConversionInfo>> => {
  try {
    const response: AxiosResponse<WithResponse<PixelConversionInfo>> = await getInstance().post(
      '/v3/conversion/pixel/add',
      params,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const SendPixelEmail = async (integrationData: PixelIntegrationData) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().post(
      '/v3/conversion/pixel/send-email',
      integrationData,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};
