export const SELECTION_COUNTRY_STATE = 'Selected location is out of your country selection';
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again';
export const AUDIENCE_SOME_UNAPPROVED =
  'Some of the attached audiences are not ready yet. The ads will be served to the ready audiences only.';
export const AUDIENCE_ALL_UNAPPROVED =
  'None of the attached audiences are ready yet. The ad serving will start once any of them gets ready.';
export const AUDIENCE_SAVE_DIALOG_WARNING =
  'None of the attached audiences are ready in this campaign. The ad serving will start once any of them gets ready.';
export const START_END_TIME_DIFF =
  'Start time and end time should have at least 30 minutes difference and end time was corrected.';
