import moment from 'moment';

import { CityOption, DistrictOption, ICountryOption, IStateOption } from 'models/Location';
import { Option, OptionId } from 'models/Option';
import { Action } from 'models/Action';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ExistingCampaignData, LocationDetails, FilterStore } from 'models/ExistingCampaign';
import { reducerFromMap } from 'utils/actions';
import { nearestFutureMinutes } from 'utils/roundTime';
import { stringToNumberArray, stringToArray } from 'utils/transformers';
import { blackListsKeys, whiteListsKeys } from 'constants/location';
import { DEFAULT_ORGANIZATION_TIMEZONE } from 'constants/timezone';
import { advanceConstants } from './constants';

export interface WhiteLists {
  whiteListedLocationIds?: number[];
  whiteListedZipcodes?: number[];
  whiteListedDmaIds?: number[];
  whiteListedCongressionalDistrictIds?: number[];
  whiteListedSenateDistrictIds?: number[];
  whiteListedHouseDistrictIds?: number[];
  whiteListedCityIds?: number[];
  whiteListedCountyIds?: number[];
  whiteListedStateIds?: number[];
}
export interface BlackLists {
  blackListedLocationIds?: number[];
  blackListedZipcodes?: number[];
  blackListedDmaIds?: number[];
  blackListedCongressionalDistrictIds?: number[];
  blackListedSenateDistrictIds?: number[];
  blackListedHouseDistrictIds?: number[];
  blackListedCityIds?: number[];
  blackListedCountyIds?: number[];
  blackListedStateIds?: number[];
}

export interface Limits {
  states?: number;
  city?: number;
  county?: number;
  customAreas?: number; // this is square miles
  zipCodes?: number;
  congressionalDistrict?: number;
  senateState?: number;
  senateHouses?: number;
}

export interface AdvancePageState {
  budgetPacing: boolean;
  states: IStateOption[];
  isDateDefault: boolean;
  isEndDateCleared: boolean;
  isTimezoneLoaded: boolean;
  isUsingV2API: boolean; // This is so we can selectivelty in our location code make changes for v2 API
  filterStore?: FilterStore | null; // This is the store to revive filter options
  sidebarCampaignInfo: {
    [CampaignInfoField.name]: string;
    [CampaignInfoField.id]: number | null;
    [CampaignInfoField.ioId]: number | null;
    [CampaignInfoField.group]: Option[];
    [CampaignInfoField.totalBudget]: string;
    [CampaignInfoField.dailyBudget]: string;
    [CampaignInfoField.maxBid]: string;
    [CampaignInfoField.baseBid]: string;
    [CampaignInfoField.timezone]: OptionId;
    [CampaignInfoField.start]: number;
    [CampaignInfoField.end]: number | null;
    [CampaignInfoField.country]: ICountryOption | null;
    [CampaignInfoField.states]: IStateOption[];
    [CampaignInfoField.cities]: CityOption[];
    [CampaignInfoField.counties]: CityOption[];
    [CampaignInfoField.domain]: string;
    [CampaignInfoField.zipcodes]: Option[];
    [CampaignInfoField.geoRadiuses]: string;
    [CampaignInfoField.dma]: DistrictOption[];
    [CampaignInfoField.districts]: DistrictOption[];
    [CampaignInfoField.senates]: DistrictOption[];
    [CampaignInfoField.houses]: DistrictOption[];
    [CampaignInfoField.isBidShading]: boolean;
  };
  blackLists: BlackLists;
  whiteLists: WhiteLists;
  limits?: Limits;
  zipcodeLimitError?: string;
}

export const defaultAdvancePageState: AdvancePageState = {
  budgetPacing: true,
  states: [],
  isDateDefault: true,
  isEndDateCleared: true,
  isTimezoneLoaded: false,
  isUsingV2API: false,
  filterStore: null,
  sidebarCampaignInfo: {
    [CampaignInfoField.name]: '',
    [CampaignInfoField.id]: null,
    [CampaignInfoField.ioId]: null,
    [CampaignInfoField.group]: [],
    [CampaignInfoField.totalBudget]: '',
    [CampaignInfoField.dailyBudget]: '',
    [CampaignInfoField.maxBid]: '',
    [CampaignInfoField.baseBid]: '',
    [CampaignInfoField.timezone]: DEFAULT_ORGANIZATION_TIMEZONE,
    [CampaignInfoField.start]: nearestFutureMinutes(10, moment().add(10, 'minutes')).valueOf(),
    [CampaignInfoField.end]: null,
    [CampaignInfoField.country]: null,
    [CampaignInfoField.states]: [],
    [CampaignInfoField.cities]: [],
    [CampaignInfoField.counties]: [],
    [CampaignInfoField.domain]: '',
    [CampaignInfoField.zipcodes]: [],
    [CampaignInfoField.geoRadiuses]: '',
    [CampaignInfoField.dma]: [],
    [CampaignInfoField.districts]: [],
    [CampaignInfoField.senates]: [],
    [CampaignInfoField.houses]: [],
    [CampaignInfoField.isBidShading]: true,
  },
  blackLists: {},
  whiteLists: {},
  limits: {},
  zipcodeLimitError: '',
};

const toggleBudgetPacing = (state: AdvancePageState): AdvancePageState => {
  const newBudgetPacing = !state.budgetPacing;
  return {
    ...state,
    budgetPacing: newBudgetPacing,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      ...(newBudgetPacing ? { [CampaignInfoField.dailyBudget]: '' } : {}),
    },
  };
};

const setStates = (
  state: AdvancePageState,
  action: Action<Option<number>[]>,
): AdvancePageState => ({
  ...state,
  states: action.payload,
});

const setSidebarCampaignInfo = (
  state: AdvancePageState,
  action: Action<{ key: string; value: any }>,
): AdvancePageState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const changeDefaultDateState = (state: AdvancePageState, action: Action<boolean>) => {
  return {
    ...state,
    isDateDefault: action.payload,
  };
};

const fillStore = (
  state: AdvancePageState,
  action: Action<ExistingCampaignData>,
): AdvancePageState => {
  const editableCampaignData = action.payload;
  const editableSidebarCampaignInfo = {
    [CampaignInfoField.id]: editableCampaignData.id,
    [CampaignInfoField.name]: editableCampaignData.campaignName,
    [CampaignInfoField.start]: editableCampaignData.startTime * 1000,
    [CampaignInfoField.end]: editableCampaignData.endTime
      ? +editableCampaignData.endTime * 1000
      : state.sidebarCampaignInfo[CampaignInfoField.end],
  };

  const getFilesList = (locationDetails?: LocationDetails, isWhite?: boolean) =>
    locationDetails
      ? Object.values(locationDetails)
          .map((file) =>
            file.validLocations
              ?.filter((location) => (isWhite ? location.isIncluded : !location.isIncluded))
              .map((location) => location.locationId),
          )
          .flat()
      : [];

  const whiteLists: WhiteLists = whiteListsKeys.reduce(
    (list, key) => ({
      ...list,
      [key]:
      /* eslint-disable no-nested-ternary */
        key === 'whiteListedLocationIds'
          ? getFilesList(editableCampaignData.locationDetails, true)
          : key === 'whiteListedZipcodes'
          ? stringToArray(editableCampaignData[key])
          : stringToNumberArray(editableCampaignData[key]),
    }),
    {},
  );
  const blackLists: BlackLists = blackListsKeys.reduce(
    (list, key) => ({
      ...list,
      [key]:
      /* eslint-disable no-nested-ternary */
        key === 'blackListedLocationIds'
          ? getFilesList(editableCampaignData.locationDetails, false)
          : key === 'blackListedZipcodes'
          ? stringToArray(editableCampaignData[key])
          : stringToNumberArray(editableCampaignData[key]),
    }),
    {},
  );

  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      ...editableSidebarCampaignInfo,
      isBidShading: editableCampaignData.isBidShading,
      baseBid: editableCampaignData.baseBid ? editableCampaignData.baseBid.toString() : '',
    },
    whiteLists,
    blackLists,
    isUsingV2API: Boolean(editableCampaignData.isUsingV2API),
    filterStore: editableCampaignData.filterStore,
  };
};

const reset = (): AdvancePageState => {
  return {
    ...defaultAdvancePageState,
  };
};

const setEndDateCleared = (state: AdvancePageState, action: Action<boolean>): AdvancePageState => {
  return {
    ...state,
    isEndDateCleared: action.payload,
  };
};

const setWhiteList = (
  state: AdvancePageState,
  action: Action<{ listName: string; ids: Set<number> }>,
) => {
  return {
    ...state,
    whiteLists: {
      ...state.whiteLists,
      [action.payload.listName]: Array.from(action.payload.ids),
    },
  };
};

const setBlackList = (
  state: AdvancePageState,
  action: Action<{ listName: string; ids: Set<number> }>,
) => {
  return {
    ...state,
    blackLists: {
      ...state.blackLists,
      [action.payload.listName]: Array.from(action.payload.ids),
    },
  };
};

const resetSidebarCampaingInfo = (
  state: AdvancePageState,
  action: Action<string[]>,
): AdvancePageState => {
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      ...(action.payload?.reduce<any>((prev, current) => ({ ...prev, [current]: [] }), {}) ?? {}),
    },
  };
};

const resetWhiteList = (state: AdvancePageState, action: Action<string[]>): AdvancePageState => {
  return {
    ...state,
    whiteLists: {
      ...state.whiteLists,
      ...(action.payload?.reduce<any>((prev, current) => ({ ...prev, [current]: [] }), {}) ?? {}),
    },
  };
};

const resetBlackList = (state: AdvancePageState, action: Action<string[]>): AdvancePageState => {
  return {
    ...state,
    blackLists: {
      ...state.blackLists,
      ...(action.payload?.reduce<any>((prev, current) => ({ ...prev, [current]: [] }), {}) ?? {}),
    },
  };
};

const setLimits = (state: AdvancePageState, action: Action<Limits>): AdvancePageState => {
  return {
    ...state,
    limits: action.payload,
  };
};

const setZipcodeLimitError = (
  state: AdvancePageState,
  action: Action<string>,
): AdvancePageState => {
  return {
    ...state,
    zipcodeLimitError: action.payload,
  };
};

const setIsTimezoneLoaded = (
  state: AdvancePageState,
  action: Action<boolean>,
): AdvancePageState => {
  return {
    ...state,
    isTimezoneLoaded: action.payload,
  };
};

const reducer = reducerFromMap<AdvancePageState>(defaultAdvancePageState, {
  [advanceConstants.TOGGLE_BUDGET_PACING]: toggleBudgetPacing,
  [advanceConstants.SET_STATES]: setStates,
  [advanceConstants.SIDEBAR_CAMPAIGN_INFO__SET]: setSidebarCampaignInfo,
  [advanceConstants.CHANGE_DEFAULT_DATE_STATE]: changeDefaultDateState,
  [advanceConstants.FILL_ADVANCE_STORE]: fillStore,
  [advanceConstants.RESET_ADVANCE_STORE]: reset,
  [advanceConstants.SET_END_DATE_CLEARED]: setEndDateCleared,
  [advanceConstants.SET_WHITE_LIST]: setWhiteList,
  [advanceConstants.SET_BLACK_LIST]: setBlackList,
  [advanceConstants.RESET_BLACK_LIST]: resetBlackList,
  [advanceConstants.RESET_WHITE_LIST]: resetWhiteList,
  [advanceConstants.RESET_SIDEBAR_CAMPAIGN_INFO]: resetSidebarCampaingInfo,
  [advanceConstants.SET_LIMITS]: setLimits,
  [advanceConstants.SET_ZIPCODE_LIMIT_ERROR]: setZipcodeLimitError,
  [advanceConstants.SET_IS_TIMEZONE_LOADED]: setIsTimezoneLoaded,
});

export const advanced = (state: AdvancePageState = defaultAdvancePageState, actions: Action<any>) =>
  reducer(state, actions);
