import React, { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Row,
  Typography,
  DialogActions,
  Button,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';
import { IO_BUDGET_TYPE_ICON } from 'constants/iconMapper';
import { useDeleteIO } from 'hooks/useIO';
import { LoadingStatus } from 'constants/loading';

interface Props {
  selectedInsertionOrders: { ioId: number; ioName: string; ioBudgetTypeId: number }[];
  onClose: () => void;
  onDeleteSuccess?: () => void;
}

export const DeleteInsertionOrderDialog = (props: Props) => {
  const { onClose, selectedInsertionOrders, onDeleteSuccess } = props;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);

  const displaySnackbar = useCallback(
    (variant: 'error' | 'success') => {
      if (variant === 'error') {
        enqueueSnackbar(
          <Typography>
            <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
              {selectedInsertionOrders.length !== 1
                ? `${selectedInsertionOrders.length} Insertion Orders`
                : selectedInsertionOrders[0].ioName}
            </Typography>
            {selectedInsertionOrders.length !== 1 ? (
              <>could not be deleted.</>
            ) : (
              <>IO could not be deleted.</>
            )}
          </Typography>,
          {
            variant,
          },
        );
      } else {
        enqueueSnackbar(
          <Typography>
            <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
              {selectedInsertionOrders.length !== 1
                ? `${selectedInsertionOrders.length} Insertion Orders`
                : selectedInsertionOrders[0].ioName}
            </Typography>
            {selectedInsertionOrders.length !== 1 ? (
              <>deleted successfully.</>
            ) : (
              <>IO deleted successfully.</>
            )}
          </Typography>,
          {
            variant,
          },
        );
      }
    },
    [selectedInsertionOrders],
  );

  const onSuccess = useCallback(() => {
    displaySnackbar('success');
    setLoading(LoadingStatus.SUCCESS);
    onClose();
    if (onDeleteSuccess) {
      onDeleteSuccess();
    }
  }, [onClose, displaySnackbar, onDeleteSuccess]);

  const onError = useCallback(() => {
    displaySnackbar('error');
    setLoading(LoadingStatus.ERROR);
    onClose();
  }, [onClose, displaySnackbar]);

  const deleteIO = useDeleteIO(onSuccess, onError);

  const handleDelete = () => {
    setLoading(LoadingStatus.LOADING);
    deleteIO.mutate({
      ioIdsList: selectedInsertionOrders?.map((io) => io.ioId),
    });
  };

  const dialogMessage = useMemo(() => {
    if (selectedInsertionOrders.length > 1) {
      return (
        <Typography>
          Are you sure you want to delete the{' '}
          <Typography weight="demi">{selectedInsertionOrders.length}</Typography>&nbsp;Insertion
          orders? Once they are deleted, all the underlying campaigns will also be deleted.
        </Typography>
      );
    }

    const BudgetIcon = IO_BUDGET_TYPE_ICON[selectedInsertionOrders[0]?.ioBudgetTypeId];

    return (
      <Typography>
        Are you sure you want to delete the{' '}
        {!!BudgetIcon && (
          <>
            <BudgetIcon
              fontSize={24}
              style={{ top: 6 }}
              sx={{ position: 'relative', textColor: 'primary-600' }}
            />
            &nbsp;
          </>
        )}
        <Typography weight="demi">{selectedInsertionOrders[0].ioName}</Typography>&nbsp;Insertion
        order? Once it is deleted, all the underlying campaigns will also be deleted.
      </Typography>
    );
  }, [selectedInsertionOrders]);

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle>
        Delete{selectedInsertionOrders.length > 1 ? ` ${selectedInsertionOrders.length} ` : ' '}
        Insertion Order{selectedInsertionOrders.length > 1 ? 's' : ''}?
      </DialogTitle>
      <DialogContent sx={{ paddingY: 0 }}>
        <Row>{dialogMessage}</Row>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading === LoadingStatus.LOADING}
          onClick={onClose}
          variant="contained"
          color="secondary"
          size="medium"
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={loading === LoadingStatus.LOADING}
          size="medium"
          variant="contained"
          color="error"
          onClick={handleDelete}
          loading={loading === LoadingStatus.LOADING}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
