import * as React from 'react';
import { connect } from 'react-redux';
import { Box, Tooltip, Typography } from '@applift/factor';
import { InfoCircle } from '@applift/icons';

import { AppState } from 'models/Store';
import { useTimezone } from 'hooks/useTimezone';

import { getPriorityShiftTimestampString } from './utils';
import styles from './styles.module.scss';

interface Props {
  campaignNextPerformanceCheckTime?: number | null;
  campaignLastPriorityShiftTime?: number | null;
  timezoneId?: number;
}

export const CampaignPriorityShiftInfoComponent = (props: Props) => {
  const { campaignNextPerformanceCheckTime, campaignLastPriorityShiftTime, timezoneId } = props;

  const { data: timezones } = useTimezone();

  const campaignTimezone = React.useMemo(() => {
    if (timezoneId && timezones) {
      return timezones.find((tz) => tz.id === timezoneId) || null;
    }
    return null;
  }, [timezoneId, timezones]);

  if (!campaignTimezone || !campaignNextPerformanceCheckTime) {
    return null;
  }

  const tooltipTitle = campaignLastPriorityShiftTime ? (
    <Typography noWrap component="span">
      <Typography noWrap sx={{ display: 'inline' }}>
        Last priority shift was on{' '}
      </Typography>
      <Typography noWrap weight="demi" sx={{ display: 'inline' }}>
        {getPriorityShiftTimestampString(campaignTimezone, campaignLastPriorityShiftTime)}
      </Typography>
    </Typography>
  ) : null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 8 }}>
      {campaignLastPriorityShiftTime ? (
        <Tooltip
          arrow
          classes={{
            tooltip: styles.tooltipFitContent,
            popper: styles.tooltipFitContent,
          }}
          title={tooltipTitle}
        >
          <InfoCircle fontSize={20} sx={{ textColor: 'neutral-400', mr: 4 }} />
        </Tooltip>
      ) : null}
      <Typography sx={{ textColor: 'neutral-500' }}>
        Next performance check:{' '}
        <Typography weight="demi">
          {getPriorityShiftTimestampString(campaignTimezone, campaignNextPerformanceCheckTime)}
        </Typography>
      </Typography>
    </Box>
  );
};

const mapState = (state: AppState) => ({
  timezoneId: state.app.editableCampaign?.timezone,
  campaignNextPerformanceCheckTime: state.app.editableCampaign?.campaignNextPerformanceCheckTime,
  campaignLastPriorityShiftTime: state.app.editableCampaign?.campaignLastPriorityShiftTime,
});

export const CampaignPriorityShiftInfo = connect(mapState)(CampaignPriorityShiftInfoComponent);
