import * as React from 'react';
import { Typography, Tooltip, Box } from '@applift/factor';
import { InfoCircle } from '@applift/icons';

import { DEFAULT_ACTIVE_PRIORITY } from 'constants/advancedModelling';
import { pluralize } from 'utils/pluralize';
import { IODetail } from 'models/IO';
import { useTimezone } from 'hooks/useTimezone';

import { getCurrentCampaignPriorityString, getPriorityShiftTimestampString } from './utils';
import styles from './styles.module.scss';

interface Props {
  ioDetails: IODetail;
}

export const IOPriorityShiftInfo = (props: Props) => {
  const { ioDetails } = props;
  const { ioLastPriorityShift, ioCurrentPriority, ioNextPerformanceCheck, ioTimeZoneId } =
    ioDetails;

  const { data: timezoneList } = useTimezone();

  const ioTimezone = React.useMemo(
    () => (timezoneList && ioTimeZoneId ? timezoneList.find((tz) => tz.id === ioTimeZoneId) : null),
    [timezoneList, ioTimeZoneId],
  );

  if (!ioTimezone || !ioNextPerformanceCheck) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 8 }}>
      <Tooltip
        arrow
        classes={{
          tooltip: styles.tooltipFitContent,
        }}
        title={
          <Typography component="div" style={{ width: 'fitContent', maxWidth: 500 }}>
            Priority shifts impact solely the active campaigns with assigned priorities.
            {ioLastPriorityShift && (
              <>
                <br />
                <br />
                <Typography sx={{ whiteSpace: 'nowrap' }} component="div">
                  Last priority shift was on{' '}
                  <Typography weight="demi">
                    {getPriorityShiftTimestampString(ioTimezone, ioLastPriorityShift)}
                  </Typography>
                </Typography>
              </>
            )}
            {ioCurrentPriority && (
              <>
                <br />
                <br />
                <Typography sx={{ whiteSpace: 'nowrap' }} component="div">
                  {ioCurrentPriority === DEFAULT_ACTIVE_PRIORITY ? (
                    'All campaigns will be considered with or without priority.'
                  ) : (
                    <>
                      Active{' '}
                      {pluralize('', ioCurrentPriority, {
                        single: 'priority',
                        multiple: 'priorities',
                      })}
                      <Typography weight="demi">
                        {getCurrentCampaignPriorityString(ioCurrentPriority)}
                      </Typography>
                    </>
                  )}
                </Typography>
              </>
            )}
          </Typography>
        }
      >
        <InfoCircle fontSize={20} sx={{ textColor: 'neutral-400', mr: 4 }} />
      </Tooltip>
      <Typography sx={{ textColor: 'neutral-500' }}>
        Next performance check:{' '}
        <Typography weight="demi">
          {getPriorityShiftTimestampString(ioTimezone, ioNextPerformanceCheck)}
        </Typography>
      </Typography>
    </Box>
  );
};
