import React from 'react';
import { Icon, Chip } from 'factor';

import { Option } from 'models/Option';
import { CircleExtended } from 'models/CircleExtended';
import { metersToMiles } from 'utils/format';
import { getLatLng, GROUPED_TAGS_VALUE } from 'utils/figures';
import { shapeType } from 'constants/location';

import styles from '../index.module.scss';

const STYLE_INCLUDE = '_include';

interface RenderCircleTags {
  circles: CircleExtended[];
  removeItemFromStore: (type: string) => (item: Option) => void;
  removeGroupFromStore: (type: string) => () => void;
  chipClassName?: string;
  readonly?: boolean;
}

export const CircleTags = ({
  circles,
  removeItemFromStore,
  removeGroupFromStore,
  chipClassName,
  readonly,
}: RenderCircleTags) => {
  return circles.length <= GROUPED_TAGS_VALUE ? (
    <>
      {circles.map((circleData) => (
        <Chip
          key={circleData.id}
          item={{
            value: circleData.id,
            label: circleData.address || getLatLng(circleData),
          }}
          value={`${+metersToMiles(circleData.getRadius()).toFixed(2)}`}
          onRemove={removeItemFromStore(shapeType.circle)}
          readonly={readonly}
          className={`${chipClassName || STYLE_INCLUDE} ${styles.chip}`}
          icon={<Icon name="Radius" />}
        />
      ))}
    </>
  ) : (
    <Chip
      item={{
        value: shapeType.circle,
        label: `${circles.length} selected`,
      }}
      readonly={readonly}
      onRemove={removeGroupFromStore(shapeType.circle)}
      className={`${chipClassName || STYLE_INCLUDE} ${styles.chip}`}
      icon={<Icon name="Radius" />}
    />
  );
};
