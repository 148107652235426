import { Action } from 'models/Action';
import { reducerFromMap } from 'utils/actions';
import { CampaignEstimatorMetaData } from 'models/ExistingCampaign';
import { CampaignEstimatorParams } from 'models/Estimator';
import { estimatorConstants } from './constants';

export interface EstimatorState {
  bidLandscape?: CampaignEstimatorParams | {};
  targatableAudience?: CampaignEstimatorParams | {};
  reachAndImpressions?: CampaignEstimatorParams | {};
  estimatorKeys?: CampaignEstimatorParams;
  campaignEstimatorMetaData?: CampaignEstimatorMetaData;
  stale?: {
    bidLandscape?: boolean;
    targatableAudience?: boolean;
    reachAndImpressions?: boolean;
  };
}

export const defaultEstimatorState: EstimatorState = {
  bidLandscape: {},
  reachAndImpressions: {},
  targatableAudience: {},
  estimatorKeys: undefined,
  campaignEstimatorMetaData: undefined,
  stale: {
    bidLandscape: false,
    reachAndImpressions: false,
    targatableAudience: false,
  },
};

const setBidLandscapePayload = (state: EstimatorState, action: Action<any>): EstimatorState => ({
  ...state,
  bidLandscape: { ...action.payload },
});

const setTargatableAudiencePayload = (
  state: EstimatorState,
  action: Action<any>,
): EstimatorState => ({
  ...state,
  targatableAudience: { ...action.payload },
});

const setReactImpressionPayload = (state: EstimatorState, action: Action<any>): EstimatorState => ({
  ...state,
  reachAndImpressions: { ...action.payload },
});

const setEstimatorKeys = (state: EstimatorState, action: Action<any>): EstimatorState => ({
  ...state,
  estimatorKeys: { ...action.payload },
});

const setEstimatorMetaData = (state: EstimatorState, action: Action<any>): EstimatorState => {
  return {
    ...state,
    campaignEstimatorMetaData: {
      ...state?.campaignEstimatorMetaData,
      [action.payload.key]: { ...action.payload.val },
    },
  };
};

const setEstimatorStaleCondition = (state: EstimatorState, action: Action<any>): EstimatorState => {
  return {
    ...state,
    stale: {
      ...state?.stale,
      [action.payload.key]: action.payload.val,
    },
  };
};

const reducer = reducerFromMap<EstimatorState | {}>(defaultEstimatorState, {
  [estimatorConstants.SET_BID_LANDSCAPE_PAYLOAD]: setBidLandscapePayload,
  [estimatorConstants.SET_TARGATABLE_AUDIENCE_PAYLOAD]: setTargatableAudiencePayload,
  [estimatorConstants.SET_REACH_IMPRESSION_PAYLOAD]: setReactImpressionPayload,
  [estimatorConstants.SET_ESTIMATOR_KEYS]: setEstimatorKeys,
  [estimatorConstants.SET_ESTIMATOR_META_DATA]: setEstimatorMetaData,
  [estimatorConstants.SET_ESTIMATOR_STALE_CONDITION]: setEstimatorStaleCondition,
});

export const estimator = (
  state: EstimatorState | {} = defaultEstimatorState,
  actions: Action<any>,
) => reducer(state, actions);
