import { logDataDogAction } from 'iqm-framework';
import { getActionNamespace } from '../helpers';

export const Estimator = {
  changeEstimatorSlider: (success: boolean, slider: 'uniqueReach' | 'totalImpressions') => {
    const msg = `Change Estimator ${
      slider === 'uniqueReach' ? 'Unique Reach' : 'Total Impressions'
    } ${success ? 'Succeeded' : 'Failed'}`;
    const actionNamespace = getActionNamespace();
    logDataDogAction(msg, {
      success,
      action: `${actionNamespace}/${
        slider === 'uniqueReach' ? 'changeEstimatorUniqueReach' : 'changeEstimatorTotalImpressions'
      }`,
    });
  },
  timeout: (widgetType: string, widgetTitle: string) => {
    const actionNamespace = getActionNamespace();
    const msg = `Estimator Timeout: ${widgetTitle}`;
    logDataDogAction(msg, {
      action: `${actionNamespace}/estimatorWidgetTimeout/${widgetType}`,
    });
  },
  refresh: (widgetType: string, widgetTitle: string, errorType: string) => {
    const actionNamespace = getActionNamespace();
    const msg = `Estimator Refresh: ${widgetTitle}`;
    logDataDogAction(msg, {
      action: `${actionNamespace}/estimatorWidgetRefresh/${widgetType}`,
      errorType: errorType?.toLowerCase(),
    });
  },
};
