import Axios from 'axios';
import VastParser from 'vast-library/parser';
import { validationUtils } from 'iqm-framework';
import get from 'lodash/get';

import { createURLBlobXML } from './blob';

const TAG_MEDIA_FILE = 'MediaFile';

export const parseVASTDAAST = async (value: string) => {
  const parser = new DOMParser();
  const URLBlobXML = createURLBlobXML(value);
  const response = await Axios.get(URLBlobXML);
  const xmlDoc = parser.parseFromString(response.data, 'text/xml');
  return get(xmlDoc.getElementsByTagName(TAG_MEDIA_FILE)[0], 'childNodes[0].nodeValue', '');
};

export const isFlash = validationUtils.isValidFlash;

export const parseVASTDAASTSync = (value: string): string => {
  const vastParser = new VastParser();
  const URLBlobXML = createURLBlobXML(value);
  vastParser.parseSync(URLBlobXML);
  URL.revokeObjectURL(URLBlobXML);
  return vastParser.getContents([TAG_MEDIA_FILE])[0] || '';
};
