import React from 'react';
import { Layout as FactorLayout } from 'factor';
import { connect } from 'react-redux';
import { AppState } from '../../models/Store';
import { applicationActions } from '../../store/app/actions';

interface Props {
  className?: string;
  mainClassName?: string;
  isSidebarOpen: boolean;
  toggleSidebarMenu: () => void;
  sidebar: React.ReactNode;
  children: React.ReactNode;
}

class LayoutComponent extends React.PureComponent<Props> {
  render() {
    const { className, mainClassName, isSidebarOpen, toggleSidebarMenu, sidebar, children } =
      this.props;

    return (
      <FactorLayout
        className={className}
        mainClassName={mainClassName}
        opened={isSidebarOpen}
        toggleSidebar={toggleSidebarMenu}
        sidebar={sidebar}
      >
        {children}
      </FactorLayout>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    isSidebarOpen: state.app.sidebarOpened,
  };
};

const actions = {
  toggleSidebarMenu: applicationActions.toggleSidebarMenu,
};

export const Layout = connect(mapState, actions)(LayoutComponent);
