export const advancedTargetingConstants = {
  SET_EXCHANGES: 'SET_EXCHANGES',
  SELECT_ADVANCED_TARGETING_FIELD: 'SELECT_ADVANCED_TARGETING_FIELD',
  SELECT_EXCHANGES: 'SELECT_EXCHANGES',
  RESET_ADVANCED_TARGETING_STORE: 'RESET_ADVANCED_TARGETING_STORE',
  VALIDATE_EXCHANGES: 'VALIDATE_EXCHANGES',
};

export interface Filter {
  label: string;
  key: string;
  selectedValue?: string;
}

export type AdvancedTargetingOptionKeys =
  | 'whiteListedDeviceId'
  | 'whiteListedIp'
  | 'whiteListedPackageName'
  | 'whiteListedAppId'
  | 'whiteListedSiteDomain'
  | 'blackListedDeviceId'
  | 'blackListedIp'
  | 'blackListedPackageName'
  | 'blackListedAppId'
  | 'blackListedSiteDomain';

export type FilterLabelsType = {
  [key in AdvancedTargetingOptionKeys]: string;
};

export const filterLabels: FilterLabelsType = {
  whiteListedDeviceId: 'Device IDs',
  whiteListedIp: 'IP',
  whiteListedPackageName: 'Android Package Names',
  whiteListedAppId: 'iOS Apps IDs',
  whiteListedSiteDomain: 'Site Domains',
  blackListedDeviceId: 'Device IDs',
  blackListedIp: 'IP',
  blackListedPackageName: 'Android Package Names',
  blackListedAppId: 'iOS Apps IDs',
  blackListedSiteDomain: 'Site Domains',
};
