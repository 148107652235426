import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { CollapsibleBlock, Button, Tooltip } from 'factor';
import { getPathNumber } from 'iqm-framework';
import { Target } from '@applift/icons';

import { groupMultiselectedValues, getTargetingTooltip } from 'utils/helpers';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { AudienceItem, AudienceWarning } from 'models/Audience';
import { blockStyles } from 'components/Block';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { User } from 'models/User';
import { AudienceWarningMessage } from 'components/AudienceWarningMessage';
import { UINoteIcon } from 'components/UINoteIcon';
import { CAMPAIGN_MAX_AUDIENCE_COUNT } from 'constants/audience';
import styles from './styles.module.scss';

import { SelectAudiencesWrapper } from './SelectAudiencesWrapper';
import { SelectAudienceTypesWrapper } from './SelectAudienceTypesWrapper';
import { AudiencesTable } from './AudiencesTable';

interface Props {
  user: User;
  selectedAudiences: AudienceItem[];
  selectedAudienceTypes: Option<number>[];
  audiences: Option<string>[];
  audienceTypes: Option<number>[];
  isCollapseOpen?: boolean;
  collapseMode?: CollapseMode;
  onToggle?: (isOpened: boolean) => void;
  audienceWarning: AudienceWarning;
}

const AudiencesBlockComponent = (props: Props) => {
  const {
    selectedAudiences,
    selectedAudienceTypes,
    audiences,
    audienceTypes,
    isCollapseOpen,
    onToggle,
    collapseMode,
    user,
    audienceWarning,
  } = props;

  const isSelectionUpdatedFromURL = useRef(false);

  const showAudienceWarning = audienceWarning !== AudienceWarning.NONE;

  const getHeaderObj = () => {
    return {
      title: (
        <div className={`${!isCollapseOpen ? styles.blockCollapsed : ''}`}>
          <div
            className={
              showAudienceWarning && isCollapseOpen ? styles.headerTextAbsolute : styles.headerText
            }
          >
            <Target fontSize={24} sx={{ textColor: 'primary-600', mr: 4 }} />
            Audience
          </div>
          {showAudienceWarning && isCollapseOpen && (
            <AudienceWarningMessage
              type={audienceWarning}
              iconOnly={false}
              className={styles.expandedAudienceWarning}
            />
          )}
          {showAudienceWarning && !isCollapseOpen && (
            <AudienceWarningMessage
              type={audienceWarning}
              iconOnly
              tooltipPosition="left"
              iconClassName={styles.collapsedAudienceWarningIcon}
              className={styles.collapsedAudienceWarning}
            />
          )}
        </div>
      ),
      summary: {
        'Audience Types': groupMultiselectedValues(selectedAudienceTypes, audienceTypes.length),
        'Audience Allowlist': groupMultiselectedValues(
          selectedAudiences.filter((i) => i.included),
          audiences.length,
        ),
        'Audience Blocklist': groupMultiselectedValues(
          selectedAudiences.filter((i) => i.excluded),
          audiences.length,
        ),
      },
      keyIcon: {
        'Audience Allowlist': (
          <UINoteIcon inline text={getTargetingTooltip('targeted', 'audiences')} marginLeft={0} />
        ),
        'Audience Blocklist': (
          <UINoteIcon inline text={getTargetingTooltip('blocked', 'audiences')} marginLeft={0} />
        ),
      },
    };
  };

  const onOpenAudience = () => {
    if (!user) {
      return;
    }
    window.open(`/audiences/u/${getPathNumber()}/#/`);
  };

  const maxAudiencesReached = selectedAudiences.length >= CAMPAIGN_MAX_AUDIENCE_COUNT;

  return (
    <CollapsibleBlock
      isCollapseOpen={isCollapseOpen}
      onToggle={onToggle}
      collapsible={collapseMode !== ALL_EXPANDED}
      header={getHeaderObj()}
      customStyle={{
        block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
        panelHeaderExpand: `${blockStyles.panelHeaderExpand} ${
          isCollapseOpen && showAudienceWarning ? blockStyles.panelHeaderExpandWithMessage : ''
        }`,
        panelHeaderCollapse: blockStyles.panelHeaderCollapse,
        ...(isCollapseOpen && showAudienceWarning
          ? {
              blockTitleText: blockStyles.blockTitleText,
              actionsWrapper: blockStyles.actionsWrapper,
            }
          : {}),
        ...(!isCollapseOpen && showAudienceWarning
          ? { actionsWrapper: styles.collapsedActionsWrapper }
          : {}),
      }}
    >
      <div className={styles.selectAudienceTypes}>
        <div className={styles.selectAudienceTypesTopText}>
          What type of Audience you want to add?
        </div>
        <div className="d-flex">
          <SelectAudienceTypesWrapper />
        </div>
        {!!selectedAudienceTypes.length && (
          <>
            <div className={styles.audiencesActionButtons}>
              <div className={styles.helpText}>
                Select from the list of Audiences uploaded earlier or create a new audience
              </div>
              <div className={styles.audiencesActionButtonsRow}>
                <SelectAudiencesWrapper isSelectionUpdatedFromURL={isSelectionUpdatedFromURL} />
                <Tooltip
                  label={`Maximum limit of ${CAMPAIGN_MAX_AUDIENCE_COUNT} audiences per campaign is reached`}
                  disable={!maxAudiencesReached}
                  portal
                >
                  <Button
                    className={`btn-square _cornflower-blue _map m-0 _md `}
                    onClick={onOpenAudience}
                    disabled={maxAudiencesReached}
                  >
                    + Add New Audience
                  </Button>
                </Tooltip>
              </div>
            </div>
            {!!selectedAudiences.length && (
              <div className={styles.selectedAudiences}>
                <AudiencesTable />
              </div>
            )}
          </>
        )}
      </div>
    </CollapsibleBlock>
  );
};

const mapState = (state: AppState) => {
  return {
    user: state.auth.userData,
    audiences: state.audience.audiences,
    audienceTypes: state.audience.audienceTypes,
    selectedAudienceTypes: state.audience.sidebarCampaignInfo[CampaignInfoField.audienceTypes],
    selectedAudiences: state.audience.sidebarCampaignInfo[CampaignInfoField.audiences],
    audienceWarning: state.audience.audienceWarning,
  };
};

export const AudiencesBlock = connect(mapState)(AudiencesBlockComponent);
