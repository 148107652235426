import * as React from 'react';
import { connect } from 'react-redux';
import { ResponsiveLine } from '@nivo/line';
import { Box, Paper, Typography } from '@applift/factor';

import { nFormatter } from 'utils/format';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { BidLandscapeInfoType } from 'models/Estimator';

export interface Props {
  data?: BidLandscapeInfoType;
  maxBid: string;
}

const BidLandscapeComponent = (props: Props) => {
  const { data } = props;
  const containerRef = React.useRef(null);
  const plottingData = React.useMemo(() => {
    const coordinates = data?.bidGuidance?.map((val) => {
      return {
        x: val[data.axis.x],
        y: val[data.axis.y],
      };
    });
    const id = 'BidLandscape';
    return { id, data: coordinates };
  }, [data?.axis.x, data?.axis.y, data?.bidGuidance]);

  return plottingData?.data?.length ? (
    <Box ref={containerRef} sx={{ width: 100 }} style={{ aspectRatio: '1/1' }}>
      <ResponsiveLine
        data={[(plottingData as any) || []]}
        margin={{ bottom: 50, left: 50, top: 20, right: 20 }}
        curve="linear"
        xScale={{ type: 'linear' }}
        yScale={{
          type: 'linear',
          stacked: true,
          reverse: false,
        }}
        yFormat={(e) => {
          return nFormatter(e as number, 0);
        }}
        xFormat={(e) => {
          return `$${(e as number).toFixed(2)}`;
        }}
        enablePoints={false}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: '#666666',
                strokeWidth: 1,
              },
            },
            legend: {
              text: {
                fontSize: 10,
                fill: '#666666',
                outlineWidth: 0,
                outlineColor: 'transparent',
              },
            },
            ticks: {
              line: {
                stroke: '#666666',
                strokeWidth: 1,
              },
              text: {
                fontSize: 10,
                fill: '#666666',
                outlineWidth: 0,
                outlineColor: 'transparent',
              },
            },
          },
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: 'Max Bid Price',
          legendOffset: 40,
          legendPosition: 'middle',
          format: (e) => {
            return `$${nFormatter(e as number, 2)}`;
          },
          tickValues: 5,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 0,
          tickRotation: 0,
          legend: 'Impressions',
          legendOffset: -47,
          legendPosition: 'middle',
          format: (e) => {
            return nFormatter(e as number, 1);
          },
          tickValues: 5,
        }}
        useMesh
        colors={['hsl(221, 76%, 48%)']}
        motionConfig="molasses"
        gridXValues={5}
        gridYValues={6}
        tooltip={(e) => {
          return (
            <Paper
              elevation={2}
              sx={{
                bgColor: 'neutral-0',
                padding: 8,
                position: 'relative',
              }}
            >
              <>
                <Typography sx={{ display: 'block' }} variant="label">
                  Max Bid Price:{' '}
                </Typography>
                <Typography variant="label" sx={{ textWeight: 'demi' }}>
                  {e.point.data.xFormatted}
                </Typography>
              </>
              <br />
              <>
                <Typography sx={{ display: 'block', mt: 8 }} variant="label">
                  Impressions:{' '}
                </Typography>
                <Typography variant="label" sx={{ textWeight: 'demi' }}>
                  {e.point.data.yFormatted}
                </Typography>
              </>
            </Paper>
          );
        }}
      />
    </Box>
  ) : null;
};

const mapState = (state: AppState) => {
  return {
    bidLandscape: state.estimator.bidLandscape,
    maxBid: state.advanced.sidebarCampaignInfo[CampaignInfoField.maxBid],
  };
};

export const BidLandscape = connect(mapState)(BidLandscapeComponent);
