import { Route } from 'models/Route';
import { MainPage } from 'pages/MainPage';
import { AppPage } from 'pages/AppPage';

export const routes: Route[] = [
  {
    path: '/apps',
    exact: false,
    component: AppPage,
    private: true,
  },
  {
    path: '/',
    exact: false,
    component: MainPage,
    private: true,
  },
];
