import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { Application } from '../models/App';
import { WithResponse } from '../models/Response';

export const FetchUserDetail = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await getInstance().get(`/v3/ua/profile`);
    return response.data.data;
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const GetAllowedApplicationsList = async (): Promise<Application[]> => {
  try {
    const response: AxiosResponse<WithResponse<Application[]>> = await getInstance().get(
      `/v3/ua/user/allowed-applications`,
    );
    return response.data.data as Application[];
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface ModelledListPayload {
  searchField?: string;
  campaignId: number;
  endDate?: number;
  startDate?: number;
  order?: string;
  sortBy?: 'DESC' | 'ASC';
  timeZoneId: number;
  pageSize: number;
  isExcluded?: number;
  dimensionId: number;
}
