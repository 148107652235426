import * as React from 'react';
import moment from 'moment';
import { Row, Col, sx, enqueueSnackbar, Typography } from '@applift/factor';
import {
  SortingState,
  DataGrid,
  RowSelectionState,
  useGridApiRef,
  VisibilityState,
  GridActionCellParams,
} from '@applift/datagrid';
import { SearchData } from '@applift/illustrations';
import { ClipboardRemove } from '@applift/icons';
import { queryClient } from 'cache';
import { useSetIOBudget, useSetIOEndDateInline, useSetIOStartDateInline } from 'hooks/useIO';
import { InlineSetTotalBudget } from 'components/InlineTotalBudgetDialog';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { SetIOEndDate } from 'models/IO';
import { NoResultsOverlay } from './NoResultOverlay';

export interface ListGridProps {
  data: any[];
  columnDef: any[];
  totalRecords?: number;
  rowIdKey: string;
  sorting: SortingState;
  pageSize: number;
  search: string;
  status: string;
  budgetTypeIds: string;
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>;
  footerData: any;
  loading: boolean;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  onFetchRows?: () => void;
  columnVisibility: VisibilityState;
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>;
  defaultDeselectedColumns: VisibilityState;
  onAction: (params: GridActionCellParams<any, any>) => void;
}

interface InlineTotalBudgetUpdateObj {
  currBudget?: number;
  newBudget?: number;
  name: string;
  ioBudgetTypeId?: number;
}

export const ListGrid = (props: ListGridProps) => {
  const {
    data,
    columnDef,
    totalRecords,
    rowIdKey,
    pageSize,
    sorting,
    footerData,
    loading,
    rowSelection,
    setRowSelection,
    search,
    status,
    budgetTypeIds,
    onFetchRows,
    onSortingChange,
    columnVisibility,
    setColumnVisibility,
    defaultDeselectedColumns,
    onAction,
  } = props;

  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);
  const [inlineUpdateObj, setInlineUpdateObj] = React.useState<
    InlineTotalBudgetUpdateObj | undefined
  >(undefined);

  const [columnOrder] = React.useState<string[]>([
    '__check__',
    'ioId',
    'ioName',
    'ioStatusId',
    'campaignsCount',
    'ioTimeZoneName',
    'ioStartTime',
    'ioEndTime',
    'ioTotalBudget',
    'ioPacingPercentage',
    'spent',
    'mediaSpent',
    'dailyPacingPercentage',
    'impressions',
    'clicks',
    'reach',
    'frequency',
    'totalAttributedConversion',
  ]);

  const overlay = React.useMemo(() => {
    if (!data?.length && !loading && (status || search || budgetTypeIds)) {
      return 'noResult';
    }
    return undefined;
  }, [data?.length, loading, search, status, budgetTypeIds]);

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, resolve } = promiseArguments;
    resolve(newRow);
  };

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value;
      if (typeof _value === 'function') {
        value = _value();
      }
      setRowSelection({ ...value });
    },

    [setRowSelection],
  );
  const apiRef = useGridApiRef();
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', (params) => {
      setHeight(params.height);
    });
  }, [apiRef]);

  const displaySuccessSnackbar = (type: string) =>
    enqueueSnackbar(
      <Typography>
        <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
          {type}
        </Typography>
        updated successfully for
        <Typography variant="span" component="span" weight="demi" sx={{ mx: 4 }}>
          {inlineUpdateObj?.name}.
        </Typography>
      </Typography>,
      {
        variant: 'success',
      },
    );

  const onSuccessfulEndDateSet = (req: SetIOEndDate) => {
    displaySuccessSnackbar('End Date');
    const id = req.ioIdsList[0];
    const row = apiRef?.current?.tableInstance?.getRow(String(id)).original;
    if (row?.ioStatusId === IO_STATUS_ID.EXPIRED) {
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
      });
    }
  };

  const setEndDate = useSetIOEndDateInline(onSuccessfulEndDateSet);

  const setStartDate = useSetIOStartDateInline({
    onSuccess: () => {
      displaySuccessSnackbar('Start Date');
    },
  });

  const onSuccessfulBudgetSet = () => {
    displaySuccessSnackbar('Total Budget');
  };

  const onSettled = () => {
    setPromiseArguments(null);
  };

  const setBudget = useSetIOBudget(onSuccessfulBudgetSet, onSettled);

  return (
    <>
      <Row sx={{ flexGrow: 1 }}>
        <Col xs={12}>
          <DataGrid
            apiRef={apiRef}
            processRowUpdate={async (newRow, oldRow) => {
              const allKeys = Object.keys(newRow);
              const modifiedKeys = allKeys.filter((key) => newRow[key] !== oldRow[key]);
              if (modifiedKeys.includes('ioEndTime')) {
                setInlineUpdateObj({
                  name: oldRow.ioName,
                });
                try {
                  queryClient.setQueriesData(
                    {
                      predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
                    },
                    (pages: any) => {
                      const newPages = pages?.pages?.map((page: any) => {
                        const newData = {
                          ...page.data,
                          recordsList: page?.data?.recordsList?.map((list: any) => {
                            if (list.ioId === newRow.ioId) {
                              return newRow;
                            }
                            return list;
                          }),
                        };
                        return {
                          ...page,
                          data: newData,
                        };
                      });
                      return { ...pages, pages: newPages };
                    },
                  );
                  await setEndDate.mutateAsync({
                    ioEndTime: newRow.ioEndTime,
                    ioIdsList: [newRow.ioId],
                    ioTimezoneId: newRow.ioTimezone,
                  });
                } catch (e) {
                  // eslint-disable-next-line
                  console.error(e);
                  queryClient.setQueriesData(
                    {
                      predicate: (query: any) => query.queryKey?.[0]?.scope === 'getIoList',
                    },
                    (pages: any) => {
                      const newPages = pages?.pages?.map((page: any) => {
                        const newData = {
                          ...page.data,
                          recordsList: page?.data?.recordsList?.map((list: any) => {
                            if (list.ioId === newRow.ioId) {
                              return oldRow;
                            }
                            return list;
                          }),
                        };
                        return {
                          ...page,
                          data: newData,
                        };
                      });
                      return { ...pages, pages: newPages };
                    },
                  );
                }
              }
              if (modifiedKeys.includes('ioStartTime')) {
                if (moment(oldRow.ioStartTime).isSameOrBefore(moment())) {
                  enqueueSnackbar(
                    'The IO operation has already started. You cannot modify the start date of an active IO.',
                    {
                      variant: 'error',
                    },
                  );
                } else {
                  setInlineUpdateObj({
                    name: oldRow.ioName,
                  });
                  setStartDate.mutate({
                    ioId: newRow.ioId,
                    ioStartTime: newRow.ioStartTime,
                    ioName: oldRow.ioName,
                  });
                }
              }
              if (
                modifiedKeys.includes('ioTotalBudget') ||
                modifiedKeys.includes('ioTotalImpressions') ||
                modifiedKeys.includes('ioTotalBudgetCombined')
              ) {
                if (oldRow.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
                  setInlineUpdateObj({
                    currBudget: oldRow.ioTotalBudget,
                    newBudget: newRow.ioTotalBudget,
                    name: oldRow.ioName,
                    ioBudgetTypeId: oldRow.ioBudgetTypeId,
                  });
                }
                if (oldRow.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
                  setInlineUpdateObj({
                    currBudget: oldRow.ioTotalImpressions ?? 0,
                    newBudget: newRow.ioTotalImpressions,
                    name: oldRow.ioName,
                    ioBudgetTypeId: oldRow.ioBudgetTypeId,
                  });
                }
                const result: any = await new Promise((resolve, reject) => {
                  setPromiseArguments({ resolve, reject, newRow, oldRow });
                });
                if (result.ioTotalBudget === newRow.ioTotalBudget) {
                  if (result.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
                    setBudget.mutate({
                      budget: Number(result.ioTotalBudget),
                      ioIdsList: [result.ioId],
                      budgetUpdateType: 'set',
                      ioBudgetTypeId: result.ioBudgetTypeId,
                    });
                  }
                }
                if (result.ioTotalImpressions === newRow.ioTotalImpressions) {
                  if (result.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
                    setBudget.mutate({
                      ioTotalImpressions: Number(result.ioTotalImpressions),
                      ioIdsList: [result.ioId],
                      budgetUpdateType: 'set',
                      ioBudgetTypeId: result.ioBudgetTypeId,
                    });
                  }
                }
              }
            }}
            isCellEditable={({ rowId, field }) => {
              if (field === 'ioTotalBudget' || field === 'ioTotalBudgetCombined') {
                const row = apiRef.current.tableInstance.getRow(rowId).original;
                return (
                  !row.isAutoSumIoTotalBudget &&
                  row.ioStatusId !== IO_STATUS_ID.EXPIRED &&
                  row.ioStatusId !== IO_STATUS_ID.DELETED
                );
              }
              if (field === 'ioEndTime') {
                const row = apiRef.current.tableInstance.getRow(rowId).original;
                return row.ioStatusId !== IO_STATUS_ID.DELETED;
              }
              if (field === 'ioStartTime') {
                const row = apiRef.current.tableInstance.getRow(rowId).original;
                return (
                  row.ioStatusId !== IO_STATUS_ID.DELETED &&
                  moment(row.ioStartTime).isAfter(moment())
                );
              }
              return true;
            }}
            footerPlacement={data?.length ? 'top' : undefined}
            footerData={footerData}
            data={data}
            columns={columnDef}
            getRowId={(row: any) => {
              if (row && typeof row[rowIdKey] === 'number') {
                return `${row[rowIdKey]}`;
              }
              return row[rowIdKey];
            }}
            state={{
              sorting,
              rowSelection,
              columnOrder,
              columnVisibility: {
                ...defaultDeselectedColumns,
                ...columnVisibility,
              },
            }}
            pageSize={pageSize}
            hideHeader
            hideFooter
            rowHeight={46}
            checkboxSelection
            overscrollBehaviorX="contain"
            overlay={overlay}
            components={{
              NoResultsOverlay,
            }}
            componentsProps={{
              noResultsOverlay: {
                text: 'No results found',
                subText: 'We can’t find any items matching your search.',
                illustration:
                  height <= 250 ? (
                    <ClipboardRemove sx={{ textColor: 'neutral-400', mb: 8 }} fontSize={36} />
                  ) : (
                    <SearchData
                      sx={{
                        textColor: 'primary-500',
                        width: height <= 400 ? 50 : 100,
                        height: 'auto',
                        mb: height <= 400 ? 24 : 40,
                      }}
                    />
                  ),
              },
            }}
            showColumnRightBorder
            showCellRightBorder
            disableRowSelectionOnClick
            rowCount={totalRecords}
            classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
            loading={loading}
            onAction={onAction}
            onRowSelectionChange={setRowSelectionWrapper}
            onFetchRows={onFetchRows}
            onSortingChange={onSortingChange}
            onColumnVisibilityChange={setColumnVisibility}
            onCellClick={(params) => {
              if (params.cellMode === 'view' && apiRef.current) {
                apiRef.current.startCellEditMode({
                  rowId: params.rowId,
                  field: params.field,
                });
              }
            }}
          />
        </Col>
      </Row>
      {!!promiseArguments && inlineUpdateObj && typeof inlineUpdateObj?.currBudget === 'number' && (
        <InlineSetTotalBudget
          closeDialog={() => {
            handleNo();
          }}
          currTotalBudget={inlineUpdateObj?.currBudget}
          onCompletion={() => {
            handleYes();
          }}
          name={inlineUpdateObj.name}
          isBudgetBeingSet={setBudget.isLoading}
          newTotalBudget={inlineUpdateObj?.newBudget}
          budgetTypeId={inlineUpdateObj.ioBudgetTypeId}
        />
      )}
    </>
  );
};
