import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Col,
  Row,
  MenuItem,
  TextField,
  Alert,
  Divider,
  LoadingButton,
  TypoTooltip,
  CircularProgress,
  enqueueSnackbar,
  FormLabel,
} from '@applift/factor';
import { IoCurrency, IoImpression } from '@applift/icons';
import moment from 'moment';

import { OptionId } from 'models/Option';
import { SingleDatePicker } from 'components/SingleDatePicker';
import { useDuplicateCampaign } from 'hooks/useCampaign';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { compareTwoTimestampsAndIncrease, getFutureTimestamp } from 'utils/date';
import { useIoBasicList } from 'hooks/useIoList';
import { Timezone } from 'components/Timezone';
import { NoResultOverlay } from 'components/BreadCrumbActions/BreadCrumbActions';
import { Timezone as TimezoneType } from 'models/Timezone';
import { useTimezone } from 'hooks/useTimezone';
import { CampaignListType } from 'models/CampaignList';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { IODetail } from 'models/IO';
import { IO_STATUS_ID } from 'constants/insertionOrder';

import styles from './index.module.scss';

const CAMPAIGN_START_AFTER_IO =
  'Campaign start time must be after the IO start time and was corrected';
const CAMPAIGN_START_AFTER_15_MIN =
  'Start time must be atleast 15 minutes in the future and was corrected';

interface DuplicateCampaignProps {
  campaignDetails: CampaignListType[];
  parentIO: IODetail;
  closeDialog: () => void;
  organizationTimezoneId: number | undefined;
}

export const DuplicateCampaign = (props: DuplicateCampaignProps) => {
  const { closeDialog, campaignDetails, parentIO, organizationTimezoneId } = props;

  const doesAllCampaignHaveSameValue = React.useCallback(
    (key: keyof CampaignListType) =>
      campaignDetails.every((campaignDetail) => campaignDetails[0][key] === campaignDetail[key]),
    [campaignDetails],
  );

  const [ioSearchText, setIOSearchText] = React.useState('');
  const [campaignEndDate, setCampaignEndDate] = React.useState<number | undefined>(undefined);
  const [selectedIo, setSelectedIO] = React.useState<IODetail | undefined>(
    parentIO.ioStatusId === IO_STATUS_ID.DELETED ? undefined : parentIO,
  );
  const [campaignStartDate, setCampaignStartDate] = React.useState<number | undefined>(
    // date of campaigns will be in seconds and has to be converted to milliseconds
    doesAllCampaignHaveSameValue('startTime') &&
      (!selectedIo || campaignDetails[0].startTime * 1000 > selectedIo.ioStartTime)
      ? campaignDetails[0].startTime * 1000
      : undefined,
  );
  const [selectedTimezone, updateTimeZone] = React.useState<OptionId<string> | undefined>(
    undefined,
  );
  const [valueToUpdateComponent, setValueToRerender] = React.useState<number>(1);
  const debouncedIoSearch = useDebounceValue(ioSearchText, 500);
  const { data: ioListData, isLoading: ioListLoading } = useIoBasicList(
    debouncedIoSearch,
    [],
    undefined,
    [IO_STATUS_ID.ACTIVE, IO_STATUS_ID.EXPIRED],
    [parentIO.ioBudgetTypeId as number],
    { enabled: !!parentIO.ioBudgetTypeId },
  );
  const timezoneOptions = useTimezone().data;

  const onDuplicationSuccess = (res: any) => {
    if (res.status) {
      enqueueSnackbar(
        <Typography>
          <Typography variant="span" component="span" weight="demi" sx={{ mr: 4 }}>
            {res.duplicatedCampaignIds.length === 1
              ? campaignDetails[0].campaignName
              : `${res.duplicatedCampaignIds.length} campaigns`}
          </Typography>
          duplicated successfully.
        </Typography>,
        {
          variant: 'success',
        },
      );
    } else {
      enqueueSnackbar(
        <Typography>
          {res?.reason?.[0]?.errorMessage ||
            'Something went wrong. Please try again after some time.'}
        </Typography>,
        {
          variant: 'error',
        },
      );
    }
    closeDialog();
  };
  const duplicateMutation = useDuplicateCampaign(
    onDuplicationSuccess,
    selectedIo?.ioId.toString()!,
  );

  const flatDataIoList = React.useMemo(
    () =>
      ioListData?.pages
        ?.map((page) => {
          return page.data?.ioBasicDetailsList ?? [];
        })
        .flat(1) || [],
    [ioListData?.pages],
  );

  const selectedIOTimezone = timezoneOptions?.find((item) => item.id === selectedIo?.ioTimeZoneId);
  const isEndDateMandatory = campaignDetails.some((cmp) => cmp.budgetPacing);

  React.useEffect(() => {
    if (timezoneOptions?.length && !selectedTimezone) {
      const allCampaignsHaveSameTimezone = doesAllCampaignHaveSameValue('campaignTimezone');
      if (allCampaignsHaveSameTimezone) {
        const allCampaignsOriginalTimezone = timezoneOptions?.find(
          (item) => item.name === campaignDetails[0].campaignTimezone,
        ) as TimezoneType;
        updateTimeZone({
          ...allCampaignsOriginalTimezone,
          value: allCampaignsOriginalTimezone.name,
        });
      } else {
        const orgTimezone = timezoneOptions?.find(
          (item) => item.id === organizationTimezoneId,
        ) as TimezoneType;
        updateTimeZone({
          ...orgTimezone,
          value: orgTimezone.name,
        });
      }
      if (doesAllCampaignHaveSameValue('startTime')) {
        setCampaignStartDate(
          Math.max(
            getFutureTimestamp(
              campaignDetails[0].startTime * 1000,
              (selectedTimezone as any)?.value,
              15,
            ),
            selectedIo?.ioStartTime || -1,
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignDetails,
    doesAllCampaignHaveSameValue,
    selectedTimezone,
    timezoneOptions,
    organizationTimezoneId,
  ]);

  React.useEffect(() => {
    if (campaignStartDate && campaignEndDate) {
      const endTimeWithFifteenMinDiff = compareTwoTimestampsAndIncrease(
        campaignStartDate,
        campaignEndDate,
        15,
      );
      if (endTimeWithFifteenMinDiff > campaignEndDate) {
        setCampaignEndDate(endTimeWithFifteenMinDiff);
        enqueueSnackbar(
          'Start time and end time should have atleast 15 minutes difference and end time was corrected',
          {
            variant: 'info',
          },
        );
      }
    }
  }, [campaignEndDate, campaignStartDate]);

  React.useEffect(() => {
    if (
      selectedIo?.ioStartTime &&
      campaignStartDate &&
      campaignStartDate < selectedIo.ioStartTime
    ) {
      setCampaignStartDate(selectedIo.ioStartTime);
    }
    setValueToRerender((v) => v + 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIo]);

  const shouldDisableDuplicateBtn = () => {
    if (
      !campaignStartDate ||
      !selectedTimezone ||
      ioListLoading ||
      duplicateMutation.isLoading ||
      !selectedIo
    ) {
      return true;
    }
    if (isEndDateMandatory) {
      return campaignEndDate
        ? Math.floor(campaignStartDate / 60000) >= Math.floor((campaignEndDate as number) / 60000)
        : true;
    }
    return false;
  };

  const handleSelectIO = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newSelectedIO = flatDataIoList?.find(
      (item) => item?.ioId === Number(e.target.value),
    ) as any;

    setSelectedIO(newSelectedIO);

    if (
      newSelectedIO?.ioStartTime &&
      campaignStartDate &&
      campaignStartDate < newSelectedIO.ioStartTime
    ) {
      setCampaignStartDate(newSelectedIO.ioStartTime);
      enqueueSnackbar(CAMPAIGN_START_AFTER_IO, {
        variant: 'info',
      });
    }
  };

  const calendarMinimumDate =
    selectedIo && selectedIo.ioStartTime > moment().valueOf()
      ? selectedIo.ioStartTime
      : moment().valueOf();

  return (
    <Dialog fullWidth maxWidth="lg" open PaperProps={{ sx: { overflowY: 'visible' } }}>
      <DialogTitle onClose={closeDialog}>Duplicate Campaign</DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', overflowY: 'visible' }}
        key={`${selectedTimezone?.id} ${valueToUpdateComponent} ${campaignStartDate}`}
      >
        <Typography sx={{ mb: 16 }}>
          Duplicate the {campaignDetails.length === 1 && 'campaign'}
          <Typography sx={{ textWeight: 'demi', ml: 2 }}>
            {campaignDetails.length === 1
              ? campaignDetails[0].campaignName
              : campaignDetails.length}
          </Typography>{' '}
          {campaignDetails.length !== 1 && 'campaigns.'}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Row>
            <Col sx={{ mt: 4 }} xs={3}>
              <TextField
                required
                variant="outlinedDash"
                select
                value={selectedIo?.ioId}
                onChange={handleSelectIO}
                SelectProps={{
                  placeholder: 'Select Insertion Order',
                  onClose: () => setIOSearchText(''),
                  renderValue: () => {
                    return (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        {selectedIo?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                          <IoCurrency fontSize={20} color="primary" />
                        ) : (
                          <IoImpression fontSize={20} color="primary" />
                        )}
                        <TypoTooltip>{selectedIo?.ioName}</TypoTooltip>
                      </Box>
                    );
                  },
                  MenuProps: {
                    PaperProps: { style: { width: 200 }, sx: { height: 'auto' } },
                  },
                  renderContentTop: (
                    <Box sx={{ width: 100, borderBottom: 1 }}>
                      <TextField
                        placeholder="Search by ID, Name"
                        value={ioSearchText}
                        onChange={(e: any) => setIOSearchText(e.target.value)}
                        classes={{
                          root: styles.iOSearchInput,
                        }}
                      />
                    </Box>
                  ),
                }}
                sx={{ width: 100 }}
                label="Campaign Insertion Order"
              >
                {!ioListLoading &&
                  flatDataIoList.map((val) => (
                    <MenuItem value={val?.ioId}>
                      <Box sx={{ width: 100 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, width: 100 }}>
                          {val?.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                            <IoCurrency fontSize={20} color="primary" />
                          ) : (
                            <IoImpression fontSize={20} color="primary" />
                          )}
                          <TypoTooltip sx={{ textWeight: 'demi', display: 'block' }}>
                            {val?.ioName}
                          </TypoTooltip>
                        </Box>
                        <TypoTooltip>ID {val?.ioId}</TypoTooltip>
                      </Box>
                    </MenuItem>
                  ))}
                {!flatDataIoList.length && !ioListLoading ? <NoResultOverlay /> : null}
                {ioListLoading && (
                  <CircularProgress sx={{ my: 56, mx: 'auto', display: 'block' }} />
                )}
              </TextField>
            </Col>
            <Col xs={3}>
              <SingleDatePicker
                timezone={selectedTimezone}
                date={campaignStartDate}
                calendarMinimumDate={calendarMinimumDate}
                updateDate={(date) => {
                  const futureStartDate = getFutureTimestamp(
                    date,
                    // @ts-ignore
                    selectedTimezone?.value,
                    15,
                  );
                  if (futureStartDate !== date && date) {
                    setCampaignStartDate(futureStartDate);
                    enqueueSnackbar(CAMPAIGN_START_AFTER_15_MIN, {
                      variant: 'info',
                    });
                  } else {
                    setCampaignStartDate(date);
                  }
                  // forceUpdate as we need to re-render even setting same val
                  setValueToRerender((v) => v + 1);
                }}
                onError={(_msg: any, errorInfo: any) => {
                  if (
                    selectedIo &&
                    errorInfo?.updatedDate &&
                    errorInfo.updatedDate.valueOf() < selectedIo.ioStartTime
                  ) {
                    enqueueSnackbar(CAMPAIGN_START_AFTER_IO, {
                      variant: 'info',
                    });
                  }
                }}
                label={<FormLabel required>Campaign Start Date</FormLabel>}
                placeholderText="Select Start Date"
                errorMsg={
                  campaignEndDate &&
                  campaignStartDate &&
                  Math.floor(campaignStartDate / 60000) >= Math.floor(campaignEndDate / 60000)
                    ? 'Start time must be before end time'
                    : undefined
                }
              />
            </Col>
            <Col xs={3}>
              <SingleDatePicker
                timezone={selectedTimezone}
                calendarMinimumDate={calendarMinimumDate}
                updateDate={(date: number) => {
                  const futureEndDate = getFutureTimestamp(
                    date,
                    selectedTimezone?.value as string,
                    15,
                  );
                  // if selected time is less than 15 mins after current time, then set 15 mins+current time as value
                  if (futureEndDate !== date && date) {
                    setCampaignEndDate(futureEndDate);
                    enqueueSnackbar(CAMPAIGN_START_AFTER_15_MIN, {
                      variant: 'info',
                    });
                  } else {
                    setCampaignEndDate(date);
                  }
                  // forceUpdate as we need to re-render even setting same val
                  setValueToRerender((v) => v + 1);
                }}
                date={campaignEndDate}
                label="Campaign End Date"
                placeholderText={`Select End Date ${!isEndDateMandatory ? '(Optional)' : ''}`}
                datePickerstyles={styles.datePicker}
                isClearable={!isEndDateMandatory}
              />
            </Col>
            <Col sx={{ mt: 2 }} xs={3}>
              <Timezone
                label="Campaign Timezone"
                value={selectedTimezone}
                onChange={updateTimeZone}
                timezoneTextFieldProps={{ disabled: true }}
              />
            </Col>
          </Row>
          <Divider orientation="horizontal" sx={{ my: 16 }} />
          <Row>
            <Col
              className={styles.infoSection}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 12,
                mt: 8,
              }}
              xs={12}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component="p"
                  variant="p"
                  gutterBottom={false}
                  sx={{ mr: 4, textColor: 'neutral-500' }}
                >
                  IO Start Date:
                </Typography>
                <Typography component="p" variant="p" gutterBottom={false}>
                  {selectedIo?.ioStartTime
                    ? moment(selectedIo.ioStartTime)
                        .tz(selectedIOTimezone?.name ?? '')
                        .format('MM/DD/YYYY hh:mm A')
                    : '—'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component="p"
                  variant="p"
                  gutterBottom={false}
                  sx={{ mr: 4, textColor: 'neutral-500' }}
                >
                  IO End Date:
                </Typography>
                <Typography component="p" variant="p" gutterBottom={false}>
                  {selectedIo?.ioEndTime
                    ? moment(selectedIo.ioEndTime)
                        .tz(selectedIOTimezone?.name ?? '')
                        .format('MM/DD/YYYY hh:mm A')
                    : '—'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component="p"
                  variant="p"
                  gutterBottom={false}
                  sx={{ mr: 4, textColor: 'neutral-500' }}
                >
                  IO Timezone:
                </Typography>
                <Typography component="p" variant="p" gutterBottom={false}>
                  {selectedIOTimezone?.label || '-'}
                </Typography>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sx={{ mt: 24 }} xs={12}>
              <Alert severity="info" sx={{ borderColor: 'info-200', pt: 4, pb: 8 }}>
                <Typography
                  align="center"
                  variant="bodySmall"
                  sx={{
                    mb: 0,
                  }}
                >
                  The duplicated campaign(s) will be added as a draft campaign
                </Typography>
              </Alert>
            </Col>
          </Row>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={duplicateMutation.isLoading}
          disabled={shouldDisableDuplicateBtn()}
          onClick={() =>
            duplicateMutation.mutate({
              campaignTypeId: campaignDetails[0].campaignTypeId,
              startDate: Math.round((campaignStartDate as number) / 1000),
              ...(campaignEndDate
                ? { endDate: Math.round((campaignEndDate as number) / 1000) }
                : {}),
              ioId: selectedIo?.ioId!,
              timeZoneId: selectedTimezone?.id as number,
              existingCampaignIds: campaignDetails.map((cmp) => cmp.campaignId).toString(),
            })
          }
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
