import { GridApiCommunity } from '@applift/datagrid/dist/models/api/gridApiCommunity';

interface GetDataGridHeightParams {
  tableApi: GridApiCommunity | undefined;
  rowCount: number | undefined;
  maxVisibleRows: number;
  rowHeight: number;
  headerHeight: number;
}

export const getDataGridHeight = ({
  tableApi,
  rowCount,
  maxVisibleRows,
  rowHeight,
  headerHeight,
}: GetDataGridHeightParams) => {
  const scrollBarSize = tableApi?.getRootDimensions?.()?.scrollBarSize || 0;
  const rows = rowCount ? Math.min(rowCount, maxVisibleRows) : maxVisibleRows;
  return rowHeight * rows + headerHeight + scrollBarSize + 2;
};
