import * as React from 'react';
import { GridRenderEditCellParams } from '@applift/datagrid';
import { EditableCell } from 'components/CellType';
import { REGEX_POSITIVE_INT } from 'constants/regex';

export const EditCampaignPriorityCell = (props: GridRenderEditCellParams) => {
  const validate = (value: string) => !value || REGEX_POSITIVE_INT.test(value);

  return (
    <EditableCell
      validate={validate}
      shouldIgnoreModifications={(inputValue: any) => {
        if (inputValue === '' && props.row.original.campaignPriority !== null) {
          return false;
        }

        return (
          !REGEX_POSITIVE_INT.test(inputValue) ||
          Number.parseInt(inputValue, 10) ===
            Number.parseInt(props.row.original.campaignPriority, 10)
        );
      }}
      disableConfirmCheck={(value: string) =>
        props.row.original.campaignPriority === null && !value
      }
      /* eslint-disable-next-line */
      {...props}
    />
  );
};
