import { StateCreator } from 'zustand';
import { CampaignInfoSlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  campaignName: '',
  timezone: undefined,
  spendingBudget: null,
  maxBidPrice: null,
  startDate: null,
  endDate: null,
  advertiserDomain: null,
  totalImpressions: null,
  pgFeesPercentage: null,
  pgFeesBudget: null,
  totalBudget: null,
};

export const createCampaignInfoStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CampaignInfoSlice
> = (set) => ({
  campaignInfoBlock: {
    ...initialState,
    setCampaignName: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            campaignName: value,
          },
        }),
        false,
        'setCampaignName',
      ),
    setTimezone: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            timezone: value,
          },
        }),
        false,
        'setTimezone',
      ),
    setSpendingBudget: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            spendingBudget: value,
          },
        }),
        false,
        'setSpendingBudget',
      ),
    setTotalImpressions: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            totalImpressions: value,
          },
        }),
        false,
        'setTotalImpressions',
      ),
    setTotalBudget: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            totalBudget: value,
          },
        }),
        false,
        'setTotalBudget',
      ),
    setMaxBidPrice: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            maxBidPrice: value,
          },
        }),
        false,
        'setMaxBidPrice',
      ),
    setPgFeesBudget: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            pgFeesBudget: value,
          },
        }),
        false,
        'setPgFeesBudget',
      ),
    setPgFeesPercent: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            pgFeesPercentage: value,
          },
        }),
        false,
        'setPgFeesPercent',
      ),
    setStartDate: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            startDate: value,
          },
        }),
        false,
        'setStartDate',
      ),
    setEndDate: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            endDate: value,
          },
        }),
        false,
        'setEndDate',
      ),
    setAdvertiserDomain: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            advertiserDomain: value,
          },
        }),
        false,
        'setAdvertiserDomain',
      ),
    resetCampaignInfoStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            ...initialState,
          },
        }),
        false,
        'resetCampaignInfoStore',
      ),
    initCampaignInfoBlock: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          campaignInfoBlock: {
            ...prev.campaignInfoBlock,
            ...value,
          },
        }),
        false,
        'initCampaignInfoBlock',
      ),
  },
});
