import React from 'react';
import { GridActionCellParams } from '@applift/datagrid';
import { IconButton, TypographyProps } from '@applift/factor';
import { Delete } from '@applift/icons';

export interface ActionCellProps {
  onAction?: (params: GridActionCellParams<string, any>) => void;
  rowId?: number;
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const ActionCell = (props: ActionCellProps) => {
  const { onAction, rowId = -1 } = props;

  const handleRemoveSelection = () => {
    onAction?.({
      actionName: 'removeRow',
      metaData: { rowId },
    });
  };

  return (
    <IconButton
      onClick={handleRemoveSelection}
      sx={{ fontSize: 24, textColor: 'neutral-400' }}
      borderRadius="circle"
    >
      <Delete />
    </IconButton>
  );
};
