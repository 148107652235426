import * as React from 'react';
import { connect } from 'react-redux';
import { Box, Paper } from '@applift/factor';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  applicationActions,
  SetBudgetTypeId,
  ResetStoreForCampaignSwitch,
  ResetAppStoreFull,
} from 'store/app/actions';
import { BreadCrumbActions } from 'components/BreadCrumbActions';
import { ScreenLoader } from 'components/ScreenLoader';
import { useIOInfo } from 'hooks/useIO';
import { nearestFutureMinutes } from 'utils/roundTime';
import { advanceActions, SetCampaignSidebarInfo } from 'store/advance/actions';
import { AppState } from '../../../models/Store';
import { CreateCampaign } from './CreateCampaignPage';

import styles from './styles.module.scss';

type CreateCampaignWrapperComponentProps = SetBudgetTypeId & SetCampaignSidebarInfo & {};

const CreateCampaignWrapperComponent = (props: CreateCampaignWrapperComponentProps) => {
  const { setBudgetTypeId, setCampaignSidebarInfo } = props;
  const history = useHistory();
  const { search } = history.location;
  const queryParams = queryString.parse(search);
  const { ioId } = queryParams;
  const { data: ioDetail, isLoading } = useIOInfo(ioId as string);
  const prevIoStartTime = React.useRef<number | undefined>();

  React.useEffect(() => {
    if (isLoading === false) {
      if (ioDetail?.data?.ioBudgetTypeId) {
        setBudgetTypeId(ioDetail?.data?.ioBudgetTypeId);
      }

      if (ioDetail?.data?.ioStartTime && ioDetail?.data?.ioStartTime !== prevIoStartTime.current) {
        const defaultStartDate = nearestFutureMinutes(10, moment().add(10, 'minutes')).valueOf();
        setCampaignSidebarInfo(
          'startDate',
          defaultStartDate > ioDetail.data.ioStartTime
            ? defaultStartDate
            : ioDetail.data.ioStartTime,
        );

        prevIoStartTime.current = ioDetail.data.ioStartTime;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ioId, ioDetail, isLoading, setBudgetTypeId]);

  return !isLoading ? <CreateCampaign /> : <ScreenLoader text="Loading..." />;
};

const mapCreateCampaignWrapper = {
  setBudgetTypeId: applicationActions.setBudgetTypeId,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
};

const CreateCampaignWrapper = connect(
  null,
  mapCreateCampaignWrapper,
)(CreateCampaignWrapperComponent);

// Here we only clear the redux store, so we can initialize campaign with a fresh store
export interface ClearCampaignStoreWrapperProp
  extends ResetStoreForCampaignSwitch,
    ResetAppStoreFull {
  campaignDataResetting?: AppState['app']['campaignDataResetting'];
}

const ClearCampaignStoreWrapperComponent = (props: ClearCampaignStoreWrapperProp) => {
  const { resetStoreForCampaignSwitch, campaignDataResetting, resetAppStoreFull } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();

  const invokedRef = React.useRef(false);

  React.useEffect(() => {
    // @ts-ignore
    if ((location?.state?.hasError ?? false) === true) {
      invokedRef.current = true;
      setIsLoading(false);
      return;
    }
    if (campaignDataResetting === false && invokedRef.current === false) {
      setIsLoading(true);
      invokedRef.current = true;
      resetAppStoreFull();
      resetStoreForCampaignSwitch();
    } else if (campaignDataResetting === false) {
      setIsLoading(false);
    }
  }, [
    campaignDataResetting,
    resetStoreForCampaignSwitch,
    setIsLoading,
    location,
    resetAppStoreFull,
  ]);

  return (
    <Box className={styles.container}>
      <Paper
        elevation={2}
        className={styles.breadcrumbBar}
        sx={{ px: 16, display: 'flex', alignItems: 'center', justifyContent: 'between' }}
      >
        <BreadCrumbActions panelType="campaign" />
      </Paper>
      {isLoading ? <ScreenLoader text="Loading..." /> : <CreateCampaignWrapper />}
    </Box>
  );
};

const mapClearCampaignStoreWrapperState = (state: AppState) => ({
  campaignDataResetting: state.app.campaignDataResetting,
});

const mapClearCampaignStoreWrapperActions = {
  resetStoreForCampaignSwitch: applicationActions.resetStoreForCampaignSwitch,
  resetAppStoreFull: applicationActions.resetAppStoreFull,
};

export const CreateCampaignPage = connect(
  mapClearCampaignStoreWrapperState,
  mapClearCampaignStoreWrapperActions,
)(ClearCampaignStoreWrapperComponent);
