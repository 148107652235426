import * as React from 'react';
import { ColumnDef, GridActionCellParams } from '@applift/datagrid';
import { Tooltip, Box, sx, Typography } from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import moment from 'moment';

import { DataDogLogger } from 'services/DataDog';
import {
  CurrencyCell,
  FormattedNumberCell,
  RawDataCell,
  DateCell,
  PacingCellWithTime,
  TimezoneCell,
} from 'components/CellType';
import { PacingInfoTooltip } from 'components/PacingInfoTooltip';
import { IoListType } from 'models/IoList';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { IO_STATUS_ID, MappingStatusIdToStatus } from 'constants/insertionOrder';
import {
  IoNameCell,
  EditDateCell,
  EditTotalBudgetCell,
  IoStatusCell,
  CampaignCell,
} from './CellType';
import { IoTotalBudgetCell } from './CellType/IoTotalBudgetCell';

export const colDef: ColumnDef<IoListType>[] = [
  {
    accessorKey: 'ioId',
    id: 'ioId',
    header: 'ID',
    meta: {
      description: 'Insertion Order ID',
      excludeColumnFromColumnVisibility: true,
    },
    cell: (props) => <RawDataCell value={`${props.renderValue()}`} />,
    size: 70,
  },
  {
    accessorKey: 'ioName',
    id: 'ioName',
    header: 'Insertion Order Name',
    meta: {
      description: 'Insertion Order Name',
      excludeColumnFromColumnVisibility: true,
    },
    cell: (props) => <IoNameCell rowData={props.row?.original} />,
    size: 250,
  },
  {
    id: 'insertionOrderSpecifics',
    header: 'Insertion Order Specifics',
    columns: [
      {
        accessorKey: 'ioStatusId',
        header: 'Status',
        enableSorting: false,
        meta: {
          description: 'Insertion Order Status',
        },
        cell: (props) => <IoStatusCell rowData={props.row?.original} />,
        size: 150,
      },
      {
        accessorKey: 'campaignsCount',
        header: 'Campaigns',
        meta: {
          description: 'Number of campaign in IO',
          align: 'right',
        },
        cell: (props) => {
          return (
            <CampaignCell
              action={(params: GridActionCellParams<string, any>) => {
                DataDogLogger.IOListPage.viewCampaignsByIO();
                props?.onAction?.(params);
              }}
              rowData={props.row?.original}
              cellData={props.renderValue() as any}
              rumActionName="Open Campaign List Dialog"
            />
          );
        },
        size: 115,
      },
      {
        accessorKey: 'ioTimeZoneName',
        id: 'ioTimeZoneName',
        header: 'Time Zone',
        cell: (props) => <TimezoneCell timezoneName={`${props.renderValue()}`} />,
        size: 130,
        meta: {
          align: 'left',
          description: 'IO Time Zone',
        },
      },
      {
        accessorKey: 'ioStartTime',
        id: 'ioStartTime',
        header: 'Start Date',
        meta: {
          description: 'Insertion Order Start Date',
          editable: true,
          renderEditCell: EditDateCell,
        },
        cell: (props) => {
          let tooltip: string | null = null;
          if (moment(props.row.original.ioStartTime).isSameOrBefore(moment())) {
            tooltip = `The IO operation has already started. You cannot modify the start date of an active IO.`;
          }
          if (props.row?.original?.ioStatusId === IO_STATUS_ID.DELETED) {
            tooltip = `Start date can't be edited for Deleted IOs`;
          }

          return (
            <Tooltip title={tooltip} arrow placement="top" key={moment().valueOf()}>
              <Box sx={{ width: 100 }}>
                <DateCell
                  date={props.renderValue() as number}
                  timezoneId={props.row.original.ioTimezone}
                />
              </Box>
            </Tooltip>
          );
        },
        size: 200,
      },
      {
        accessorKey: 'ioEndTime',
        id: 'ioEndTime',
        header: 'End Date',
        meta: {
          description: 'Insertion Order End Date',
          editable: true,
          renderEditCell: EditDateCell,
        },
        cell: (props) => {
          const tooltip =
            props.row?.original?.ioStatusId === IO_STATUS_ID.DELETED
              ? `End date can't be edited for Deleted IOs`
              : 'Please note that updating the end date may impact the campaigns below. When  the IO end date is reached, all campaigns will stop spending.';
          return (
            <Tooltip title={tooltip} arrow placement="top">
              <Box sx={{ width: 100 }}>
                <DateCell
                  date={props.renderValue() as number}
                  timezoneId={props.row.original.ioTimezone}
                />
              </Box>
            </Tooltip>
          );
        },
        size: 200,
      },
    ],
  },
  {
    id: 'budget',
    header: 'Budget',
    columns: [
      {
        accessorKey: 'ioTotalBudgetCombined',
        id: 'ioTotalBudget',
        header: 'Total Budget',
        meta: {
          description:
            'The amount set as the total spending limit including media cost, data cost and pre-bid cost',
          align: 'right',
          editable: true,
          valueSetter: (params) => {
            const { budgetTypeId } = params.row;
            if (budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
              return { ...params.row, ...{ ioTotalBudget: params.value } };
            }
            return { ...params.row, ...{ ioTotalImpressions: params.value } };
          },
          preProcessEditCellProps: (params) => {
            const budgetTypeId = params?.ctx.row.original.ioBudgetTypeId;
            const value = parseFloat(params?.props?.value) ?? 0;
            const dollarSpent = parseFloat(params?.ctx?.row?.original?.spent) || 0;
            const impressionSpent = parseInt(params?.ctx?.row?.original?.impressions, 10) || 0;
            const spent =
              budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? dollarSpent : impressionSpent;
            let error = '';
            if (value < spent) {
              error =
                budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                  ? 'Total budget cannot be less than total spent of the IO.'
                  : 'Total budget cannot be less than spent impression of the IO.';
            }
            return {
              ...params.props,
              error,
            };
          },
          renderEditCell: (props) => {
            // eslint-disable-next-line
            return <EditTotalBudgetCell {...props} />;
          },
        },
        cell: (props) => <IoTotalBudgetCell data={props.row.original} />,
        footer: (props) => <IoTotalBudgetCell data={props.row?.original} isFooter />,
        size: 150,
      },
      {
        accessorKey: 'ioPacingPercentage',
        id: 'ioPacingPercentage',
        header: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            IO Pacing
            <Tooltip
              classes={{ tooltip: sx({ p: 0 }) }}
              title={() => {
                return (
                  <PacingInfoTooltip
                    title="IO Pacing"
                    linkHref="https://help.iqm.com/en/articles/8672915-a-guide-to-io-pacing-campaign-pacing-daily-pacing-in-iqm-s-platform"
                  />
                );
              }}
              placement="right"
              arrow
            >
              <InfoCircle fontSize={24} sx={{ ml: 8 }} />
            </Tooltip>
          </Box>
        ),
        meta: {
          align: 'right',
          headerTitle: 'IO Pacing',
        },
        cell: (props) => {
          const rowData = props.row?.original;
          const budgetTypeId = rowData.ioBudgetTypeId;

          const currentDate = moment().valueOf();
          const startDate = rowData.ioStartTime;

          const isStartDateInFuture = startDate > currentDate;

          const showPacingCell = () => {
            if (isStartDateInFuture) {
              return false;
            }
            const totalbudget =
              rowData?.budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
                ? rowData.ioTotalImpressions
                : rowData.ioTotalBudget;
            if (!totalbudget || !rowData.campaignsCount) {
              return false;
            }
            if (rowData.ioPacingPercentage === null) {
              return false;
            }
            return true;
          };

          return showPacingCell() ? (
            <PacingCellWithTime
              budgetCompletionPercentage={rowData.ioPacingPercentage ?? 0}
              actualSpent={rowData.ioActualSpent}
              expectedSpent={rowData.ioExpectedSpent}
              remainingDuration={rowData.ioRemainingDuration}
              campaignDuration={rowData.ioDuration}
              budgetTypeId={budgetTypeId}
              startDate={rowData.ioStartTime}
              endDate={rowData.ioEndTime}
              totalBudget={
                budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
                  ? rowData.ioTotalImpressions
                  : rowData.ioTotalBudget
              }
              status={MappingStatusIdToStatus[rowData.ioStatusId]}
              timezoneId={rowData.ioTimezone}
            />
          ) : (
            <Typography weight="demi">-</Typography>
          );
        },
        size: 300,
        minSize: 240,
      },
    ],
  },
  {
    id: 'spending',
    header: 'Spending',
    columns: [
      {
        accessorKey: 'spent',
        id: 'spent',
        header: 'Total Spent',
        meta: {
          description:
            'Total spent is the amount spent by the campaign(s) for the selected timeframe that includes the media cost, data cost and pre-bid cost',
          align: 'right',
        },
        cell: (props) => {
          return <CurrencyCell value={props.renderValue() as number} />;
        },
        footer: (props) => {
          // @ts-ignore
          return <CurrencyCell value={props.renderValue() as number} />;
        },
        size: 150,
      },
      {
        accessorKey: 'mediaSpent',
        id: 'mediaSpent',
        header: 'Media Cost',
        meta: {
          description: 'Media Cost',
          align: 'right',
        },
        cell: (props) => {
          return <CurrencyCell value={props.renderValue() as number} />;
        },
        footer: (props) => {
          // @ts-ignore
          return <CurrencyCell value={props.renderValue() as number} />;
        },
        size: 150,
      },
    ],
  },
  {
    id: 'performance',
    header: 'Performance',
    columns: [
      {
        accessorKey: 'impressions',
        id: 'impressions',
        header: 'Impressions',
        meta: {
          description: 'Number of times ad displayed on someone’s screen',
          align: 'right',
        },
        enableSorting: true,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 150,
      },
      {
        accessorKey: 'clicks',
        id: 'clicks',
        header: 'Clicks',
        meta: {
          description: 'Number of times someone clicks your ad',
          align: 'right',
        },
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 120,
      },
      {
        accessorKey: 'reach',
        id: 'reach',
        header: 'Reach',
        meta: {
          align: 'right',
        },
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 120,
      },
      {
        accessorKey: 'frequency',
        id: 'frequency',
        header: 'Frequency',
        meta: {
          align: 'right',
        },
        cell: (props) => <FormattedNumberCell value={props.renderValue()?.toFixed(2) as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()?.toFixed(2) as number} />
        ),
        size: 120,
      },
    ],
  },
  {
    id: 'conversion',
    header: 'Conversion',
    columns: [
      {
        accessorKey: 'totalAttributedConversion',
        id: 'totalAttributedConversion',
        header: 'Attributed Conversions',
        meta: {
          description:
            'Total number of conversions that are attributed to a specific campaign based on the attribution model used',
          align: 'right',
        },
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 220,
      },
    ],
  },
];
