import * as React from 'react';
import {
  StatusFailed,
  StatusProcessing,
  StatusRunning,
  StatusPaused,
  StatusPending,
  StatusReady,
  StatusRejected,
  StatusDeleted,
  StatusDraft,
  StatusExpired,
  StatusError,
  CreativeImage,
  CreativeHTML,
  CreativeAudio,
  CreativeVideo,
  CreativeGoTV,
  DeviceDesktop,
  DeviceMobile,
  DeviceTablet,
  DeviceTV,
  ChannelApp,
  ChannelWeb,
  ChannelCTV,
  ChannelOTT,
  ChannelUnknown,
  StatusApproved,
  AudienceMatched,
  CreativeNative,
  CampaignAdvanced,
  CampaignPG,
} from '@applift/icons';
import { SvgIconProps } from '@applift/factor';
import { toInitCap } from 'utils/format';

interface IconType {
  element: any;
  props?: Partial<SvgIconProps>;
}

// eslint-disable-next-line no-underscore-dangle
const _IconNames = {
  Processing: 'StatusFailed',
  Failed: 'StatusFailed',
  Active: 'StatusActive',
  Running: 'StatusRunning',
  Paused: 'StatusPaused',
  Suspended: 'StatusPaused',
  Pending: 'StatusPending',
  Ready: 'StatusReady',
  Rejected: 'StatusRejected',
  Deleted: 'StatusDeleted',
  Draft: 'StatusDraft',
  Expired: 'StatusExpired',
  Image: 'CreativeImage',
  HTML: 'CreativeHTML',
  Native: 'CreativeNative',
  Audio: 'CreativeAudio',
  Video: 'CreativeVideo',
  GOTV: 'CreativeGoTV',
  Error: 'StatusError',
  Desktop: 'DeviceDesktop',
  Mobile: 'DeviceMobile',
  Tablet: 'DeviceTablet',
  ConnectedTV: 'DeviceTV',
  Web: 'ChannelWeb',
  App: 'ChannelApp',
  OTT: 'ChannelOTT',
  CTV: 'ChannelCTV',
  Unknown: 'ChannelUnknown',
  AudienceMatched: 'AudienceMatched',
  CampaignAdvanced: 'CampaignAdvanced',
  CampaignPG: 'CampaignPG',
} as const;

export type IconNamesTypes = keyof typeof _IconNames;

export type IconRegistry = {
  [key in IconNamesTypes]: IconType;
};

export const IconNames: IconRegistry = {
  Active: {
    element: StatusApproved,
    props: { sx: { textColor: 'success-400' } },
  },
  Processing: {
    element: StatusProcessing,
    props: { sx: { textColor: 'warning-400' } },
  },
  Failed: {
    element: StatusFailed,
    props: { sx: { textColor: 'danger-400' } },
  },
  Running: {
    element: StatusRunning,
    props: { sx: { textColor: 'success-400' } },
  },
  Paused: {
    element: StatusPaused,
    props: { sx: { textColor: 'warning-400' } },
  },
  Suspended: {
    element: StatusPaused,
    props: { sx: { textColor: 'warning-400' } },
  },
  Pending: {
    element: StatusPending,
    props: { sx: { textColor: 'warning-400' } },
  },
  Ready: {
    element: StatusReady,
    props: { sx: { textColor: 'info-400' } },
  },
  Rejected: {
    element: StatusRejected,
    props: { sx: { textColor: 'danger-400' } },
  },
  Deleted: {
    element: StatusDeleted,
    props: { sx: { textColor: 'danger-400' } },
  },
  Draft: {
    element: StatusDraft,
    props: { sx: { textColor: 'info-400' } },
  },
  Expired: {
    element: StatusExpired,
    props: { sx: { textColor: 'neutral-600' } },
  },
  Image: {
    element: CreativeImage,
    props: { sx: { textColor: 'primary-600' } },
  },
  HTML: {
    element: CreativeHTML,
    props: { sx: { textColor: 'primary-600' } },
  },
  Native: {
    element: CreativeNative,
    props: { sx: { textColor: 'primary-600' } },
  },
  Audio: {
    element: CreativeAudio,
    props: { sx: { textColor: 'primary-600' } },
  },
  Video: {
    element: CreativeVideo,
    props: { sx: { textColor: 'primary-600' } },
  },
  GOTV: {
    element: CreativeGoTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  Desktop: {
    element: DeviceDesktop,
    props: { sx: { textColor: 'primary-600' } },
  },
  Mobile: {
    element: DeviceMobile,
    props: { sx: { textColor: 'primary-600' } },
  },
  Tablet: {
    element: DeviceTablet,
    props: { sx: { textColor: 'primary-600' } },
  },
  ConnectedTV: {
    element: DeviceTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  App: {
    element: ChannelApp,
    props: { sx: { textColor: 'primary-600' } },
  },
  Web: {
    element: ChannelWeb,
    props: { sx: { textColor: 'primary-600' } },
  },
  OTT: {
    element: ChannelOTT,
    props: { sx: { textColor: 'primary-600' } },
  },
  CTV: {
    element: ChannelCTV,
    props: { sx: { textColor: 'primary-600' } },
  },
  Unknown: {
    element: ChannelUnknown,
    props: { sx: { textColor: 'primary-600' } },
  },
  Error: {
    element: StatusError,
    props: { sx: { textColor: 'danger-400' } },
  },
  AudienceMatched: {
    element: AudienceMatched,
    props: { sx: { textColor: 'primary-600' } },
  },
  CampaignAdvanced: {
    element: CampaignAdvanced,
    props: { sx: { textColor: 'primary-600' } },
  },
  CampaignPG: {
    element: CampaignPG,
    props: { sx: { textColor: 'primary-600' } },
  },
};

export const getIcon = (
  iconName: IconNamesTypes,
  props?: Partial<SvgIconProps>,
  fallbackIcon?: React.ReactNode,
) => {
  const result =
    IconNames[
      iconName?.trim().replace(/\s\s+/g, '').split(' ').map(toInitCap).join('') as IconNamesTypes
    ];

  if (!result?.element && fallbackIcon !== undefined) {
    return fallbackIcon;
  }

  const Component = (result?.element || StatusError) as (props: any) => JSX.Element;
  const finalProps = result?.props || {};
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...finalProps} {...props} />;
};
