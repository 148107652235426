import * as React from 'react';
import { TypoTooltip, Typography, TypographyProps } from '@applift/factor';

interface RawDataCellProps {
  value: string;
  isFooterCell?: boolean;
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const RawDataCell = ({ value, isFooterCell, typographyProps }: RawDataCellProps) => {
  return value != null && value !== '' ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {/* eslint-disable-next-line */}
      <Typography {...typographyProps}>{value ?? '—'}</Typography>
    </TypoTooltip>
  ) : (
    // eslint-disable-next-line
    <Typography {...typographyProps}>—</Typography>
  );
};
