import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { DataDogRumRoute } from 'iqm-framework';

import { routes } from './routes';

export const AppPage = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      {routes.map((route) => {
        return (
          <DataDogRumRoute
            key={route.path}
            path={`${url}${route.path}`}
            exact={route.exact}
            component={route.component}
            useHashForViewName
          />
        );
      })}
    </Switch>
  );
};
