import { AxiosError, AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import { get } from 'lodash';
import { Logger } from 'utils/logger';
import { WithResponse } from 'models/Response';
import {
  BasicCampaignListApiData,
  CampaignListApiData,
  CampaignTypeResponseType,
  DownloadCampaignListPayloadType,
  DownloadCampaignListResponseType,
  SaveColListParam,
} from 'models/CampaignList';
import { CAMPAIGN_STATUSES } from 'constants/campaignList';
import { getAudienceWarning } from 'utils/audience';
import { getCampaignTypeQueryKey } from 'hooks/useCampaignList';
import { getInstance } from './Instance';
import { getCampaignListKey, getBasicCampaignListKey } from './QueryKeys';
import { getAudiencesStatusInfo } from './Audience';

export const getCampaignList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCampaignListKey['keys']>>): Promise<
  WithResponse<CampaignListApiData>
> => {
  const {
    searchField,
    sortBy,
    pageSize,
    ioIds,
    campaignStatus,
    creativeTypeIds,
    timezoneId,
    campaignIds,
    endDate,
    startDate,
    campaignTypeIds,
  } = queryKey[0] || {};
  try {
    const response: AxiosResponse<WithResponse<CampaignListApiData>> = await getInstance().post(
      '/v3/cmp/io/campaigns/list',
      {
        searchField,
        noOfEntries: pageSize,
        pageNo: pageParam,
        sortBy,
        ioIdsList: ioIds,
        creativeTypeIds,
        campaignStatus,
        timezoneId,
        campaignIds,
        endDate,
        startDate,
        campaignTypeIds,
      },
    );

    if (response.data.data?.recordsList) {
      const runningCampaignIds = response.data.data?.recordsList
        .filter((cmp) => cmp.status === CAMPAIGN_STATUSES.RUNNING)
        .map((filteredCmp) => filteredCmp.campaignId);
      if (runningCampaignIds.length) {
        const audienceDetail = await getAudiencesStatusInfo(runningCampaignIds.toString());
        const warning = getAudienceWarning(audienceDetail.data ?? {});
        if (Object.keys(warning ?? {}).length) {
          response.data.data.recordsList = response.data.data.recordsList.map((record) => ({
            ...record,
            ...(Object.keys(warning).includes(record.campaignId.toString())
              ? { audienceWarning: warning[record.campaignId.toString()] }
              : {}),
          }));
          return response.data;
        }
      }
    }
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const getBasicCampaignList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getBasicCampaignListKey['keys']>>): Promise<
  WithResponse<BasicCampaignListApiData>
> => {
  const {
    searchField,
    noOfEntries,
    ids,
    ioIdsList,
    pageNo,
    creativeTypeIds,
    status,
    campaignTypeIds,
  } = queryKey[0] || {};
  try {
    const response: AxiosResponse<WithResponse<BasicCampaignListApiData>> =
      await getInstance().post('/v3/cmp/basic/list', {
        searchField,
        noOfEntries,
        pageNo,
        ids,
        ioIdsList,
        creativeTypeIds,
        status,
        campaignTypeIds,
      });
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const getCampaignListCols = async (): Promise<SaveColListParam | undefined> => {
  try {
    const response: AxiosResponse<WithResponse<SaveColListParam>> = await getInstance().get(
      `/v3/cmp/io/campaign/config`,
    );

    return response.data.data;
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const saveCampaignListCols = async (
  params: SaveColListParam,
): Promise<WithResponse<SaveColListParam>> => {
  try {
    const response: AxiosResponse<WithResponse<SaveColListParam>> = await getInstance().post(
      '/v3/cmp/io/campaign/config/save',
      params,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const downloadCampaignList = async (
  params: DownloadCampaignListPayloadType,
): Promise<WithResponse<DownloadCampaignListResponseType>> => {
  try {
    const response: AxiosResponse<WithResponse<DownloadCampaignListResponseType>> =
      await getInstance().post('/v3/cmp/io/campaigns/list/download', params);
    return response.data;
  } catch (e) {
    Logger.log('Error while setting end date to the campaign(s)', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const getCampaignTypeList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCampaignTypeQueryKey['keys']>>): Promise<
  WithResponse<CampaignTypeResponseType>
> => {
  try {
    const { ioIds, campaignIds, creativeTypeIds, statusList } = queryKey[0]!;
    const response: AxiosResponse<WithResponse<CampaignTypeResponseType>> =
      await getInstance().post('v3/cmp/campaign-type/count', {
        countRequired: true,
        ioIds,
        campaignIds,
        creativeTypeIds,
        statusList,
      });
    return response.data;
  } catch (e) {
    Logger.log('Error while getting the campaigns type', e);
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};
