import * as React from 'react';
import { GridRenderEditCellParams } from '@applift/datagrid';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { EditableCell } from 'components/CellType';
import { REGEX_POSITIVE_FLOAT, REGEX_POSITIVE_INT } from 'constants/regex';

export const EditTotalBudgetCell = (props: GridRenderEditCellParams) => {
  const { value, apiRef, row } = props;
  const dataRow = apiRef.tableInstance.getRow(row.id).original;

  const validate = (value: string) => {
    const regexForValidation =
      dataRow.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? REGEX_POSITIVE_FLOAT
        : REGEX_POSITIVE_INT;

    const isValid =
      dataRow.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? regexForValidation.test(value) ||
          (Boolean(value.split('.').length - 1 < 2) && value[value.length - 1] === '.')
        : regexForValidation.test(value) || value === '';

    return isValid;
  };

  const shouldIgnoreModifications = (inputValue: any) =>
    dataRow.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
      ? dataRow.ioTotalImpressions === inputValue
      : false;

  return (
    <EditableCell
      shouldIgnoreModifications={shouldIgnoreModifications}
      validate={validate}
      initialValue={
        dataRow.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? value : dataRow.ioTotalImpressions
      }
      /* eslint-disable-next-line */
      {...props}
    />
  );
};
