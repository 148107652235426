import * as React from 'react';
import { Alert, IconButton, Link, Typography } from '@applift/factor';
import { Close } from '@applift/icons';

import { EstimatorState } from 'store/estimator/reducer';

export interface EstimatorAlertType {
  countryIssue: string;
  olderCampaign: string;
  defaultInfo: string;
}

interface Props extends EstimatorState {
  alertRef?: keyof EstimatorAlertType;
  onClose?: () => void;
}

const ESTIMATION_ERROR_STRING: EstimatorAlertType = {
  countryIssue: 'Currently, estimations are available only for the USA.',
  olderCampaign:
    'Estimations are not available for campaigns created before the launch of the estimation feature.',

  defaultInfo: 'Explore all available parameters for the estimator by clicking ',
};

export const KNOW_MORE_URL =
  'https://help.iqm.com/en/articles/7883438-estimate-reach-impression-and-audience-size-for-a-campaign';

export const WarningAlert = (props: Props) => {
  const { alertRef, estimatorKeys, onClose } = props;

  const [showAlert, setShowAlert] = React.useState(!!alertRef);

  React.useEffect(() => {
    setShowAlert(!!alertRef);
  }, [alertRef, estimatorKeys]);

  return showAlert && alertRef ? (
    <Alert
      action={
        <IconButton
          onClick={() => {
            if (onClose) {
              onClose();
            }
            setShowAlert(false);
          }}
        >
          <Close />
        </IconButton>
      }
      severity="info"
      sx={{ py: 12, mt: 16 }}
    >
      <Typography>{ESTIMATION_ERROR_STRING[alertRef]} </Typography>
      <Link
        Component="button"
        onClick={() => {
          window.open(KNOW_MORE_URL, '_blank');
        }}
        sx={{ lineHeight: 'multi-line' }}
      >
        {alertRef === 'defaultInfo' ? 'here.' : 'Know more'}
      </Link>
    </Alert>
  ) : null;
};
