import React from 'react';
import { Icon, Tooltip } from 'factor';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  text: string;
  marginLeft?: number;
  labelMaxWidth?: number;
  inline?: boolean;
}

export const UINoteIcon = (props: Props) => {
  const { text, className, marginLeft, labelMaxWidth = 200, inline } = props;
  const ml = marginLeft ? `ml-${marginLeft}` : '';

  return (
    <Tooltip
      portal
      auto
      label={text}
      labelMaxWidth={labelMaxWidth}
      className={`${inline ? styles.inline : ''} ${ml} ${styles.uiNoteIcon} ${className || ''}`}
    >
      <Icon name="CampaignsInfo" />
    </Tooltip>
  );
};
