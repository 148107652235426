import * as React from 'react';
import { Icon } from 'factor';
import { Box, Tooltip } from '@applift/factor';
import capitalize from 'lodash/capitalize';
import { getIcon } from 'registry';

import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { CampaignTypeById } from 'models/campaign';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { Sidebar } from '../../../components/Sidebar';
import { AudienceWarningMessage } from '../../../components/AudienceWarningMessage';
import { statusIconMap } from '../../../models/CampaignInfoFields';
import { CollapseController } from '../../../components/CollapseController';
import { Layout } from '../../../components/Layout';
import { MainContent } from '../../../components/MainContent';
import { CampaignInfoBlock } from './CampaignInfoBlock';
import { CreativeBlock } from './CreativeBlock';
import { LocationBlock } from './LocationBlock';
import { InventoryBlock } from './InventoryBlock';
import { ConversionBlock } from './ConversionBlock';
import { SidebarCampaignInfo } from './SidebarCampaignInfo';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { scrollToElement } from '../../../utils/scroll';
import { AudienceWarning } from '../../../models/Audience';
import styles from './styles.module.scss';
import { CampaignCTA } from './CampaignCTA';
import { PoliticalTargetingBlock } from './PoliticalTargeting';

interface CampaignProps {
  isAuth: boolean;
  errorIndices: boolean[];
  editableCampaign: ExistingCampaignData | null;
  audienceWarning: AudienceWarning;
  mode: 'create' | 'edit';
}

export const Campaign = (props: CampaignProps) => {
  const { isAuth, errorIndices, editableCampaign, audienceWarning, mode } = props;

  const [setExpandedBlocks, campaignName] = useStore((state: StoreFilter) => [
    state.pgCampaignPage.setExpandedBlocks,
    state.campaignInfoBlock.campaignName,
  ]);

  const getTitle = React.useCallback(() => {
    if (campaignName) {
      return campaignName;
    }
    if (editableCampaign?.campaignName?.trim()) {
      return editableCampaign?.campaignName?.trim();
    }
    return mode === 'create' ? 'New Campaign' : '';
  }, [campaignName, editableCampaign?.campaignName, mode]);

  React.useEffect(() => {
    setExpandedBlocks([]);
  }, [setExpandedBlocks]);

  const sidebar = (
    <Sidebar
      title={getTitle()}
      icon={
        editableCampaign && statusIconMap[editableCampaign.status] ? (
          <Tooltip title={capitalize(editableCampaign?.status)} placement="right">
            <Box>{getIcon(editableCampaign.status as any, { fontSize: 24 })}</Box>
          </Tooltip>
        ) : (
          <Icon name="Advanced" />
        )
      }
      rightIcon={
        audienceWarning === AudienceWarning.NONE ? null : (
          <AudienceWarningMessage type={audienceWarning} iconOnly />
        )
      }
      campaignType={CAMPAIGN_TYPE_BY_NAME.pg as CampaignTypeById}
    >
      <SidebarCampaignInfo />
    </Sidebar>
  );

  React.useEffect(() => {
    scrollToElement();
  }, []);

  return (
    <Layout sidebar={sidebar} className={styles.layout}>
      <MainContent className={styles.mainContent}>
        <CollapseController collapsibleHasError={errorIndices}>
          <Box sx={{ height: 100, overflow: 'auto', py: 12, px: 16 }}>
            {isAuth ? <CampaignInfoBlock /> : null}
            {/* if adding any children here do modify errorIndices */}
            <CreativeBlock />
            <LocationBlock />
            <InventoryBlock />
            <ConversionBlock />
            <PoliticalTargetingBlock />
          </Box>
          <CampaignCTA />
        </CollapseController>
      </MainContent>
    </Layout>
  );
};
