export const advanceConstants = {
  DEVICE_TYPE__SET: 'DEVICE_TYPE__SET',
  SIDEBAR_CAMPAIGN_INFO__SET: 'SIDEBAR_CAMPAIGN_INFO__SET',
  TOGGLE_BUDGET_PACING: 'TOGGLE_BUDGET_PACING',
  RESET_ADVANCE_STORE: 'RESET_ADVANCE_STORE',
  SET_STATES: 'SET_STATES',
  FILL_ADVANCE_STORE: 'FILL_ADVANCE_STORE',
  CHANGE_DEFAULT_DATE_STATE: 'CHANGE_DEFAULT_DATE_STATE',
  SET_END_DATE_CLEARED: 'SET_END_DATE_CLEARED',
  SET_WHITE_LIST: 'SET_WHITE_LIST',
  SET_BLACK_LIST: 'SET_BLACK_LIST',
  RESET_SIDEBAR_CAMPAIGN_INFO: 'RESET_SIDEBAR_CAMPAIGN_INFO',
  RESET_WHITE_LIST: 'RESET_WHITE_LIST',
  RESET_BLACK_LIST: 'RESET_BLACK_LIST',
  SET_LIMITS: 'SET_LIMITS',
  SET_ZIPCODE_LIMIT_ERROR: 'SET_ZIPCODE_LIMIT_ERROR',
  SET_IS_TIMEZONE_LOADED: 'SET_IS_TIMEZONE_LOADED',
};
