import { StateCreator } from 'zustand';
import { CampaignInventorySlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  pgDealIds: [],
  selectedPgDeals: [],
};

export const createInventoryBlockStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CampaignInventorySlice
> = (set) => ({
  inventoryBlock: {
    ...initialState,
    setPgDealsIds: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          inventoryBlock: {
            ...prev.inventoryBlock,
            pgDealIds: value,
          },
        }),
        false,
        'setPgDealsIds',
      ),
    setSelectedPgDeals: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          inventoryBlock: {
            ...prev.inventoryBlock,
            selectedPgDeals: value,
          },
        }),
        false,
        'setPgDealsIds',
      ),
    resetInventoryBlockStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          inventoryBlock: {
            ...prev.inventoryBlock,
            ...initialState,
          },
        }),
        false,
        'resetInventoryBlockStore',
      ),
    initCampaignInventorySlice: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          inventoryBlock: {
            ...prev.inventoryBlock,
            ...value,
          },
        }),
        false,
        'initCampaignLocationSlice',
      ),
  },
});
