import * as React from 'react';
import { Typography } from '@applift/factor';
import { ClipboardList } from '@applift/icons';
import { uniqBy } from 'lodash';
import { StoreFilter } from 'models/ZustandStore';
import { useStore } from 'zustandStore';
import { PG_CAMPAIGN_COMPONENTS } from 'constants/pgCampaign';
import {
  useExchangesList,
  usePgDealInfo,
  usePgDealsPaymentTypeList,
  usePgDealsStatusList,
} from 'hooks/useInventory';
import { BlockWrapper } from '../BlockWrapper';
import { AddCreatePgDeals } from './components/AddCreatePgDeals';

export const InventoryBlock = () => {
  const [pgDealIds, expandedBlocks, setExpandedBlocks, selectedPgDeals, setSelectedPgDeals] =
    useStore((state: StoreFilter) => [
      state.inventoryBlock.pgDealIds,
      state.pgCampaignPage.expandedBlocks,
      state.pgCampaignPage.setExpandedBlocks,
      state.inventoryBlock.selectedPgDeals,
      state.inventoryBlock.setSelectedPgDeals,
    ]);

  const { data: exchangesListResponse } = useExchangesList();
  const { data: dealsStatusListResponse } = usePgDealsStatusList();
  const { data: dealsPaymentTypeListResponse } = usePgDealsPaymentTypeList();

  usePgDealInfo({
    ids: pgDealIds,
    meta: {
      exchangeList: exchangesListResponse?.data?.data || [],
      pgDealsStatusList: dealsStatusListResponse?.data?.dealStatuses || [],
      pgDealPaymentTypeList: dealsPaymentTypeListResponse?.data?.pgPaymentTypes || [],
    },
    options: {
      enabled:
        Boolean(pgDealIds?.length) &&
        Boolean(exchangesListResponse?.data?.data?.length) &&
        Boolean(dealsStatusListResponse?.data?.dealStatuses?.length) &&
        Boolean(dealsPaymentTypeListResponse?.data.pgPaymentTypes.length) &&
        Boolean(pgDealIds.length),

      onSuccess: (val: any) => {
        // @ts-ignore
        const alreadySelectedDeals = val.pages[0].data.pgDealData;
        setSelectedPgDeals(
          uniqBy([...selectedPgDeals, ...alreadySelectedDeals], (deal) => deal.id),
        );
      },
    },
  });

  const onBlockAccordionChange = () => {
    if (expandedBlocks.includes('Inventory')) {
      setExpandedBlocks(
        expandedBlocks.filter(
          (val: 'Creatives' | 'Location' | 'Inventory' | 'Conversion') => val !== 'Inventory',
        ),
      );
    } else {
      setExpandedBlocks(['Inventory']);
    }
  };

  return (
    <BlockWrapper
      title="Inventory"
      id={PG_CAMPAIGN_COMPONENTS.inventoryBlock}
      icon={<ClipboardList fontSize={24} color="primary" />}
      expanded={!!expandedBlocks.includes('Inventory')}
      onChange={onBlockAccordionChange}
      blockSummary={
        pgDealIds.length ? (
          <Typography variant="bodySmall" gutterBottom={false}>
            Deals: <Typography weight="demi">{pgDealIds.length}</Typography>
          </Typography>
        ) : undefined
      }
    >
      <AddCreatePgDeals />
    </BlockWrapper>
  );
};
