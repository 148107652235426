import { CampaignEstimatorParams } from 'models/Estimator';
import { estimatorConstants } from './constants';
import { createAction } from '../../utils/actions';

export interface EstimatorDataActionType {
  setBidLandscapePayload: (value: CampaignEstimatorParams) => void;
  setTargatableAudiencePayload: (value: CampaignEstimatorParams) => void;
  setReachImpressionPayload: (value: CampaignEstimatorParams) => void;
  setEstimatorMetaData: (value: {
    key: 'reachMeta' | 'landScapeMeta' | 'sliderMeta';
    val: any;
  }) => void;
  fetchEstimatorKeys: () => void;
  setEstimatorStaleCondition: (value: {
    key: 'bidLandscape' | 'reachAndImpressions' | 'targatableAudience';
    val: any;
  }) => void;
}

export const estimatorActions = {
  fetchEstimatorKeys() {
    return createAction(estimatorConstants.FETCH_ESTIMATOR_KEYS);
  },
  setEstimatorKeys: (value: CampaignEstimatorParams) => {
    return createAction<CampaignEstimatorParams>(estimatorConstants.SET_ESTIMATOR_KEYS, value);
  },
  setBidLandscapePayload: (value: CampaignEstimatorParams) => {
    return createAction<CampaignEstimatorParams>(
      estimatorConstants.SET_BID_LANDSCAPE_PAYLOAD,
      value,
    );
  },
  setTargatableAudiencePayload: (value: CampaignEstimatorParams) => {
    return createAction<CampaignEstimatorParams>(
      estimatorConstants.SET_TARGATABLE_AUDIENCE_PAYLOAD,
      value,
    );
  },
  setReachImpressionPayload: (value: CampaignEstimatorParams) => {
    return createAction<CampaignEstimatorParams>(
      estimatorConstants.SET_REACH_IMPRESSION_PAYLOAD,
      value,
    );
  },
  setEstimatorMetaData: (value: {
    key: 'reachMeta' | 'landScapeMeta' | 'sliderMeta';
    val: any;
  }) => {
    return createAction<typeof value>(estimatorConstants.SET_ESTIMATOR_META_DATA, value);
  },
  setEstimatorStaleCondition: (value: {
    key: 'bidLandscape' | 'reachAndImpressions' | 'targatableAudience';
    val: boolean;
  }) => {
    return createAction<typeof value>(estimatorConstants.SET_ESTIMATOR_STALE_CONDITION, value);
  },
};
