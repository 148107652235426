/* global google */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { TextField, Snackbar } from 'factor';

import { CampaignInfoField } from 'models/CampaignInfoFields';
import { SELECTION_COUNTRY_STATE, SOMETHING_WENT_WRONG } from 'constants/messages';
import { LatLngLiteral } from 'models/Google';
import { milesToMeters } from 'utils/format';
import {
  mapActions,
  PushPreSavedCircle,
  SetMapCenter,
  SetMapBounds,
  SetToastMessage,
} from 'store/map/actions';
import { AppState } from 'models/Store';
import { CircleExtended } from 'models/CircleExtended';
import { ICountryOption } from 'models/Location';
import { Open, toastActions } from 'store/toast/actions';

import styles from './style.module.scss';

type Props = PushPreSavedCircle &
  SetMapCenter &
  SetMapBounds &
  SetToastMessage & {
    areaType: string | null;
    toastMessage: string;
    isOpenMapVisible: boolean;
    country: ICountryOption | null;
    hidden?: boolean;
    selectedLocationTab: string;
    openToast: Open['open'];
  };

const MapSearchBoxComponent = (props: Props) => {
  const {
    pushPreSavedCircle,
    setMapCenter,
    areaType,
    setMapBounds,
    toastMessage,
    setToastMessage,
    isOpenMapVisible,
    country,
    hidden,
    openToast,
    selectedLocationTab,
  } = props;

  const [address, setAddress] = useState('');
  const [snackbarIsVisible, setSnackbarIsVisible] = useState(false);

  useEffect(() => {
    setAddress('');
  }, [selectedLocationTab]);

  const inputRef = useCallback(
    (inputRefNode: HTMLInputElement) => {
      if (isOpenMapVisible && inputRefNode) {
        inputRefNode?.focus();
      }
    },
    [isOpenMapVisible],
  );

  const displayToast = (message: string) => {
    if (window.self !== window.top) {
      setToastMessage(message);
    } else {
      openToast(message);
    }
  };

  const changeMapCenterHandler = (latLng: LatLngLiteral) => {
    const { lat, lng } = latLng;
    setMapCenter({ lat, lng });
  };

  const checkOutOfBounds = (figure: CircleExtended): void => {
    if (country?.shortName !== figure.country) {
      setToastMessage(SELECTION_COUNTRY_STATE);
    }
  };

  const handleChange = (value: string) => {
    setAddress(value);
  };

  const handleSelect = async (address: string, placeId: string) => {
    try {
      const results = await (placeId ? geocodeByPlaceId(placeId) : geocodeByAddress(address));
      const latLng = await getLatLng(results[0]);
      if (areaType === 'Radius') {
        const circle = new google.maps.Circle({
          center: latLng,
          radius: milesToMeters(0.5),
        });
        const circleInstance = new CircleExtended({ circle, isInclude: true });
        await circleInstance.update();
        checkOutOfBounds(circleInstance);
        pushPreSavedCircle(circleInstance);
        setMapBounds(circle.getBounds());
      }
      changeMapCenterHandler(latLng);
    } catch (error) {
      if (error === 'ZERO_RESULTS') {
        displayToast('Location not found');
      } else {
        displayToast(SOMETHING_WENT_WRONG);
      }
    }
  };

  if (hidden) {
    return (
      <Snackbar
        open={!!toastMessage.length}
        message={toastMessage}
        onClose={() => setToastMessage('')}
      />
    );
  }

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        const newInputsProps = {
          ...getInputProps(),
          placeholder: 'Search Places...',
        };
        Reflect.deleteProperty(newInputsProps, 'onBlur');
        return (
          <div data-qa="395" className={styles.container}>
            <TextField
              inputRef={inputRef}
              value={address}
              className={styles.text_field}
              iconName="Search"
              inputAttributes={newInputsProps}
              onBlur={getInputProps().onBlur}
              onChange={(value: string) => setAddress(value)}
            />
            <div
              data-qa="396"
              className={`select js-select ${suggestions.length ? '_opened' : ''}`}
            >
              <div
                data-qa="397"
                className={`select__wrapper ${suggestions.length ? styles.select_opened : ''}`}
              >
                <div data-qa="398" className="select__list-wrapper">
                  <div data-qa="399" className="select__list">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'select__option _active'
                        : 'select__option';
                      return (
                        <div
                          data-qa="400"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <div data-qa="401" className="select__option-label">
                            {suggestion.description}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Snackbar
              open={snackbarIsVisible}
              message={SELECTION_COUNTRY_STATE}
              onClose={setSnackbarIsVisible}
              showCloseButton
            />
            {/* TODO: need refactor */}
            <Snackbar
              open={!!toastMessage.length}
              message={toastMessage}
              onClose={() => setToastMessage('')}
              showCloseButton
            />
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

const mapState = (state: AppState) => ({
  areaType: state.map.areaType,
  toastMessage: state.map.toastMessage,
  isOpenMapVisible: state.map.isOpenMapVisible,
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  selectedLocationTab: state.map.selectedLocationTab,
});

const actions = {
  pushPreSavedCircle: mapActions.pushPreSavedCircle,
  setMapCenter: mapActions.setMapCenter,
  setMapBounds: mapActions.setMapBounds,
  setToastMessage: mapActions.setToastMessage,
  openToast: toastActions.open,
};

export const MapSearchBox = connect(mapState, actions)(MapSearchBoxComponent);
