import { createAction } from '../../utils/actions';
import { demographicConstants } from './constants';
import { Option } from '../../models/Option';

type Data = {
  key: string;
  value: Option<number>[];
};

export type SetDemographicField = {
  setDemographicField: (data: Data) => void;
};

export type SelectDemographicField = {
  selectDemographicField: (data: Data) => void;
};

export type ResetState = {
  resetState: () => void;
};

export const demographicActions = {
  setDemographicField(data: Data) {
    return createAction<Data>(demographicConstants.SET_DEMOGRAPHIC_FIELD, data);
  },

  selectDemographicField(data: Data) {
    return createAction<Data>(demographicConstants.SELECT_DEMOGRAPHIC_FIELD, data);
  },

  resetState() {
    return createAction(demographicConstants.RESET_DEMOGRAPHIC_STORE);
  },
};
