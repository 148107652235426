import * as React from 'react';
import {
  TextField,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
  Container,
  TypoTooltip,
  IconButton,
  BaseTextFieldProps,
  MenuProps,
} from '@applift/factor';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { useBasicCampaignList } from 'hooks/useCampaignList';
import { CAMPAIGN_STATUSES_ICONS, CREATIVES_TYPES_ICONS } from 'constants/iconMapper';
import { ClipboardRemove, Close } from '@applift/icons';
import { CampaignStatusCountType } from 'models/Count';
import { creativesIDMapV2 } from 'models/Creative';

import styles from './index.module.scss';

export interface CampaignSelectPropTypes {
  ioId: number;
  selectedCampaign?: number[];
  creativeTypeFilter?: number[];
  statusFilter: CampaignStatusCountType['status_key'][];
  campaignStatusCount?: CampaignStatusCountType[];
  setSelectedCampaign: (arg: number[]) => void;
  variant?: BaseTextFieldProps<any>['variant'];
  label?: BaseTextFieldProps<any>['label'];
  selectAllOnMount?: boolean;
  MenuProps?: Partial<MenuProps>;
}

export const CampaignSelect = (props: CampaignSelectPropTypes) => {
  const {
    ioId,
    setSelectedCampaign,
    selectedCampaign,
    creativeTypeFilter,
    statusFilter,
    campaignStatusCount,
    variant,
    label,
    selectAllOnMount,
    MenuProps = {},
  } = props;

  const [campaignSearch, setCampaignSearch] = React.useState('');
  const hasSelectedAllOnMount = React.useRef(false);

  const debouncedSearch = useDebounceValue(campaignSearch, 500);

  const statusFilterPayload = React.useMemo(
    () =>
      statusFilter[0] === 'all'
        ? campaignStatusCount
            ?.filter((val) => val.status_key !== 'all')
            .map((val) => val.status_key)
        : statusFilter,
    [campaignStatusCount, statusFilter],
  );

  const { data: campaignListData, isLoading: campaignListDataLoading } = useBasicCampaignList(
    debouncedSearch,
    [+ioId],
    [],
    creativeTypeFilter,
    [],
    statusFilterPayload,
  );

  const flatData = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map((page) => {
          return page.data?.data ?? [];
        })
        .flat(1) || []
    );
  }, [campaignListData]);

  const noResultFound = React.useMemo(
    () => !campaignListData?.pages?.[0]?.data?.filteredRecords && !campaignListDataLoading,
    [campaignListData?.pages, campaignListDataLoading],
  );

  React.useEffect(() => {
    if (!hasSelectedAllOnMount.current && selectAllOnMount && flatData?.length) {
      hasSelectedAllOnMount.current = true;
      setSelectedCampaign(flatData.map((data) => data?.campaignId).filter((d) => !!d) as number[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatData]);

  const NoResultOverlay = () => {
    return (
      <MenuItem
        style={{ pointerEvents: 'none' }}
        sx={{
          width: 100,
          height: 100,
          p: 32,
        }}
      >
        <Box
          sx={{
            width: 100,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <ClipboardRemove sx={{ textColor: 'neutral-400' }} fontSize={32} />
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            No Results Found
          </Typography>
          {campaignSearch ? (
            <Typography>
              We can’t find any items
              <br /> matching your search.
            </Typography>
          ) : null}
        </Box>
      </MenuItem>
    );
  };

  return (
    <TextField
      variant={variant || 'outlined'}
      label={label}
      select
      size="medium"
      SelectProps={{
        multiple: true,
        placeholder: 'Select Campaigns',
        onClose: () => setCampaignSearch(''),
        renderValue: (val) => {
          if (campaignListDataLoading) {
            return 'Loading...';
          }
          // @ts-ignore
          return `Campaigns (${val.length})`;
        },

        renderContentTop: (
          <Box sx={{ width: 100, borderBottom: 1 }}>
            <TextField
              // change border within
              placeholder="Search"
              value={campaignSearch}
              onChange={(e: any) => setCampaignSearch(e.target.value)}
              fullWidth
              classes={{
                root: styles.campaignSearchInput,
              }}
              InputProps={{
                endAdornment:
                  campaignSearch?.length > 0 ? (
                    <IconButton
                      onClick={() => {
                        setCampaignSearch('');
                      }}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  ) : null,
              }}
            />
          </Box>
        ),
        MenuProps: {
          PaperProps: {
            style: { width: 500 },
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          ...MenuProps,
        },
      }}
      value={selectedCampaign as any}
      sx={{ width: 100 }}
      onChange={(e) => {
        const selectedValues = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
        const filteredValues = selectedValues.filter(Boolean);
        setSelectedCampaign(filteredValues as unknown as Array<number>);
      }}
    >
      {flatData.map((val) => (
        <MenuItem
          startIcon={
            <Typography sx={{ fontSize: 24 }}>
              {/* @ts-ignore */}
              {CREATIVES_TYPES_ICONS[creativesIDMapV2[val?.creativeTypeId as number]]}
            </Typography>
          }
          value={val?.campaignId}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', width: 100 }}
            style={{ minWidth: 0 }}
          >
            <TypoTooltip>
              <Typography weight="demi">{val?.name}</Typography>
            </TypoTooltip>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>ID {val?.campaignId}</Typography>
              <Box
                Component="div"
                sx={{ mx: 8 }}
                className={styles.separator}
                style={{ width: 3, height: 3, borderRadius: 50, backgroundColor: '' }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mr: 4 }}>
                  {/* @ts-ignore */}
                  {CAMPAIGN_STATUSES_ICONS[val.status]}
                </Box>
                <Typography>
                  {val?.status.charAt(0).toLocaleUpperCase()}
                  {val?.status?.slice(1)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </MenuItem>
      ))}
      {campaignListDataLoading ? (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: 'auto',
            py: 48,
          }}
        >
          <CircularProgress size={38} thickness={5} />{' '}
        </Container>
      ) : null}
      {noResultFound ? <NoResultOverlay /> : null}
    </TextField>
  );
};
