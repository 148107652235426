import React from 'react';
import { connect } from 'react-redux';
import { CollapsibleBlock } from 'factor';
import { Box, Typography } from '@applift/factor';
import { Devices } from '@applift/icons';

import { blockStyles } from 'components/Block';
import { ServerErrorMessage } from 'components/ServerErrorMessage';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import {
  technologyActions,
  SetTechnologyField,
  SelectTechnologyField,
  SetIsTvAd,
} from 'store/technology/actions';
import { applicationActions, ResetError } from 'store/app/actions';
import { ErrorCreatingResponse } from 'models/Response';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { groupMultiselectedValues } from 'utils/helpers';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { getTechnologyBlockErrorCreating } from 'store/app/helpers';

import { SelectNetworkTypesWrapper } from './SelectNetworkTypesWrapper';
import { TrafficTypeSelectWrapper } from './SelectTrafficTypesWrapper';
import { SelectManufacturersWrapper } from './SelectManufacturersWrapper';
import { SelectDeviceOsWrapper } from './SelectDeviceOsWrapper';
import { DeviceTypeSelectWrapper } from './DeviceTypeSelectWrapper';
import { SelectCarriersWrapper } from './SelectCarriersWrapper';

type Options = Option<number>[];

type Props = SetTechnologyField &
  SelectTechnologyField &
  ResetError &
  SetIsTvAd & {
    submitted: boolean;
    deviceTypes: Options;
    manufacturers: Options;
    os: Options;
    carriers: Options;
    network: Options;
    traffic: Options;
    selectedDeviceTypes: Options;
    selectedManufacturers: Options;
    selectedOs: Options;
    selectedCarriers: Options;
    selectedNetwork: Options;
    selectedTraffic: Options;
    errorCreating: ErrorCreatingResponse | null;
    editableCampaign: ExistingCampaignData | null;
    isCollapseOpen?: boolean;
    onToggle?: (isOpened: boolean) => void;
    collapseMode?: CollapseMode;
    technologyStoreHasErrors: boolean;
  };

interface State {
  carriers: Options;
  trafficTypes: Options;
  deviceOs: Options;
}

class TechnologyBlockComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      carriers: [],
      trafficTypes: [],
      deviceOs: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    const {
      editableCampaign,
      manufacturers,
      os,
      carriers,
      network,
      traffic,
      setIsTvAd,
      selectTechnologyField,
      selectedNetwork,
      selectedTraffic,
    } = this.props;

    if (editableCampaign) {
      if (prevProps.editableCampaign !== editableCampaign && editableCampaign.isTvAd) {
        setIsTvAd(editableCampaign.isTvAd);
        selectTechnologyField({
          key: CampaignInfoField.traffic,
          value: selectedTraffic,
        });
        selectTechnologyField({
          key: CampaignInfoField.network,
          value: selectedNetwork,
        });
      }

      if (
        prevProps.manufacturers.length !== manufacturers.length ||
        prevProps.editableCampaign !== editableCampaign
      ) {
        this.selectDataFromEditableCampaign(
          manufacturers,
          CampaignInfoField.manufacturers,
          editableCampaign.manufacturer,
        );
      }

      if (prevProps.os.length !== os.length || prevProps.editableCampaign !== editableCampaign) {
        this.selectDataFromEditableCampaign(os, CampaignInfoField.os, editableCampaign.os);
      }

      if (
        prevProps.carriers.length !== carriers.length ||
        prevProps.editableCampaign !== editableCampaign
      ) {
        this.selectDataFromEditableCampaign(
          carriers,
          CampaignInfoField.carriers,
          editableCampaign.carriers,
        );
      }

      if (
        prevProps.network.length !== network.length ||
        prevProps.editableCampaign !== editableCampaign
      ) {
        this.selectDataFromEditableCampaign(
          network,
          CampaignInfoField.network,
          editableCampaign.networkType,
        );
      }

      if (
        prevProps.traffic.length !== traffic.length ||
        prevProps.editableCampaign !== editableCampaign
      ) {
        this.selectDataFromEditableCampaign(
          traffic,
          CampaignInfoField.traffic,
          editableCampaign.trafficType,
        );
      }
    }
  }

  selectDataFromEditableCampaign = (options: Options, storeKey: string, ids?: string) => {
    if (ids) {
      const idsArray = ids.split(',').map((i) => +i);
      const selectedOptions = options.filter((o) => idsArray.includes(o.value));
      if (selectedOptions.length) {
        this.handleChangeDataInStore(storeKey)(selectedOptions);
      }
    }
  };

  handleChange = (field: string) => (value: Options) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  handleChangeDataInStore = (field: string) => (value: Options) => {
    const { selectTechnologyField } = this.props;
    this.handleResetError(field);
    selectTechnologyField({
      key: field,
      value,
    });
  };

  handleSetDataInStore = (field: string) => (value: Options) => {
    const { setTechnologyField } = this.props;

    setTechnologyField({
      key: field,
      value,
    });
  };

  handleResetError = (field: string) => {
    const { errorCreating, resetError } = this.props;
    if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
      resetError();
    }
  };

  getHeaderObj = () => {
    const {
      deviceTypes,
      manufacturers,
      os,
      carriers,
      network,
      traffic,
      selectedDeviceTypes,
      selectedManufacturers,
      selectedOs,
      selectedCarriers,
      selectedNetwork,
      selectedTraffic,
    } = this.props;

    return {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
          <Devices fontSize={24} sx={{ textColor: 'primary-600' }} />
          <Typography>Device</Typography>
        </Box>
      ),
      summary: {
        'Device Type': groupMultiselectedValues(selectedDeviceTypes, deviceTypes.length),
        Manufacturer: groupMultiselectedValues(selectedManufacturers, manufacturers.length),
        OS: groupMultiselectedValues(selectedOs, os.length),
        Carrier: groupMultiselectedValues(selectedCarriers, carriers.length),
        'Network Type': groupMultiselectedValues(selectedNetwork, network.length),
        Channel: groupMultiselectedValues(selectedTraffic, traffic.length),
      },
    };
  };

  render() {
    const {
      submitted,
      selectedDeviceTypes,
      selectedManufacturers,
      selectedOs,
      selectedCarriers,
      selectedNetwork,
      selectedTraffic,
      errorCreating,
      isCollapseOpen,
      onToggle,
      collapseMode,
      technologyStoreHasErrors,
    } = this.props;

    return (
      <CollapsibleBlock
        collapsible={collapseMode !== ALL_EXPANDED}
        isCollapseOpen={isCollapseOpen}
        onToggle={onToggle}
        header={this.getHeaderObj()}
        customStyle={{
          block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
          panelHeaderExpand: blockStyles.panelHeaderExpand,
          panelHeaderCollapse: blockStyles.panelHeaderCollapse,
        }}
      >
        <div
          data-qa="120"
          className={`row ${blockStyles.blockRow}`}
          data-not-valid={
            technologyStoreHasErrors || getTechnologyBlockErrorCreating(errorCreating)
          }
        >
          <div data-qa="121" className="col-12" style={{ marginBottom: '1rem' }}>
            <DeviceTypeSelectWrapper />
            {submitted && !selectedDeviceTypes.length ? (
              <p data-qa="122" className="text-field__help-text _error">
                At least one device type must be selected!
              </p>
            ) : null}
            <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.deviceTypes]} />
          </div>
          <div data-qa="123" className="col-4">
            <SelectManufacturersWrapper
              placeholder="Select Manufacturer"
              label="Manufacturer"
              value={selectedManufacturers}
              onChange={this.handleChangeDataInStore(CampaignInfoField.manufacturers)}
              fetchedData={this.handleSetDataInStore('manufacturers')}
              tooltipParams={{ label: 'Consumer device Manufacturer' }}
            />
            <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.manufacturers]} />
          </div>
          <div data-qa="124" className="col-4">
            <SelectDeviceOsWrapper
              placeholder="Select Operating System"
              label="Operating System"
              value={selectedOs}
              onChange={this.handleChangeDataInStore(CampaignInfoField.os)}
              fetchedData={this.handleSetDataInStore('os')}
              tooltipParams={{ label: 'Consumer Device Operating System' }}
            />
            <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.os]} />
          </div>
          <div data-qa="126" className="col-4">
            <SelectCarriersWrapper
              value={selectedCarriers}
              onChange={this.handleChangeDataInStore(CampaignInfoField.carriers)}
            />
            <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.carriers]} />
          </div>
        </div>
        <div data-qa="125" className={`row ${blockStyles.blockRow}`}>
          <div data-qa="127" className="col-4">
            <SelectNetworkTypesWrapper
              placeholder="Select Network Type"
              label="Network Type"
              value={selectedNetwork}
              onChange={this.handleChangeDataInStore(CampaignInfoField.network)}
              fetchedData={this.handleSetDataInStore('network')}
              tooltipParams={{ label: 'Consumer Device Network' }}
            />
            <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.network]} />
          </div>
          <div data-qa="128" className="col-4">
            <TrafficTypeSelectWrapper
              className={submitted && !selectedTraffic.length ? '_error' : ''}
            />
            {submitted && !selectedTraffic.length ? (
              <p className="text-field__help-text _error">
                At least one traffic type must be selected!
              </p>
            ) : null}
            <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.traffic]} />
          </div>
        </div>
      </CollapsibleBlock>
    );
  }
}

const mapState = (state: AppState) => ({
  submitted: state.app.submitted,
  deviceTypes: state.technology.deviceTypes,
  manufacturers: state.technology.manufacturers,
  os: state.technology.os,
  carriers: state.technology.carriers,
  network: state.technology.network,
  traffic: state.technology.traffic,
  selectedDeviceTypes: state.technology.sidebarCampaignInfo[CampaignInfoField.deviceTypes],
  selectedManufacturers: state.technology.sidebarCampaignInfo[CampaignInfoField.manufacturers],
  selectedOs: state.technology.sidebarCampaignInfo[CampaignInfoField.os],
  selectedCarriers: state.technology.sidebarCampaignInfo[CampaignInfoField.carriers],
  selectedNetwork: state.technology.sidebarCampaignInfo[CampaignInfoField.network],
  selectedTraffic: state.technology.sidebarCampaignInfo[CampaignInfoField.traffic],
  errorCreating: state.app.errorCreating,
  editableCampaign: state.app.editableCampaign,
  technologyStoreHasErrors: state.technology.technologyStoreHasErrors,
});

const mapAction = {
  setTechnologyField: technologyActions.setTechnologyField,
  selectTechnologyField: technologyActions.selectTechnologyField,
  setIsTvAd: technologyActions.setIsTvAd,
  resetError: applicationActions.resetError,
};

export const TechnologyBlock = connect(mapState, mapAction)(TechnologyBlockComponent);
