import { AxiosResponse } from 'axios';
import { WithResponse } from 'models/Response';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import {
  CreatePgDealResponse,
  CreatePgDealPayload,
  DealGroup,
  PgDealPaymentResponseType,
  PgDealStatusResponseType,
} from '../models/DealGroup';

export const getItems = async (): Promise<DealGroup[]> => {
  try {
    const response: AxiosResponse<DealGroup[]> = await getInstance().get('/deal_group_list');
    return response.data;
  } catch (e) {
    Logger.log('Error while getting deal group list', e);
    return [];
  }
};

export const getPgDealStatusList = async (): Promise<{
  data: PgDealStatusResponseType;
}> => {
  try {
    const response: AxiosResponse<{ data: PgDealStatusResponseType }> = await getInstance().get(
      'v3/inv/static/deal-status',
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting exchanges: ', e);
    // @ts-ignore
    return [];
  }
};

export const getPgDealsPaymentTypeList = async (): Promise<{
  data: PgDealPaymentResponseType;
}> => {
  try {
    const response: AxiosResponse<{ data: PgDealPaymentResponseType }> = await getInstance().get(
      'v3/fa/static/pg/payment-type',
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting payment types: ', e);
    // @ts-ignore
    return [];
  }
};

export const createPgDeals = async (requestData: CreatePgDealPayload): Promise<any> => {
  try {
    const response: AxiosResponse<WithResponse<CreatePgDealResponse>> = await getInstance().post(
      'v3/inv/pg/deals/add',
      requestData,
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while creating pg deals', e.response.data);
    return Promise.reject(e.response.data);
  }
};
