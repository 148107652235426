import { Scheduling } from 'store/scheduling/reducer';
import { Option, NestedOption } from 'models/Option';
import { AdvancedTargetingOptionKeys, filterLabels } from 'store/advancedTargeting/constants';
import { pluralize } from './pluralize';

export const convertListToHierarchySelectOptions = (
  list: any[],
  fieldName: string,
  cb: (item: any) => Option<any>,
) => {
  const response = list.reduce((acc, item) => {
    const option = cb(item);
    let options = [];
    if (item[fieldName] && item[fieldName].length) {
      options = convertListToHierarchySelectOptions(item[fieldName], fieldName, cb);
    }

    acc.push({
      ...option,
      options,
    });

    return acc;
  }, []);

  return response;
};

export const convertHierarchySelectOptionsToList = (value: NestedOption[]): Option<number>[] => {
  return value.reduce((acc: Option<number>[], group: NestedOption): Option<number>[] => {
    if (group.options) {
      acc.push(...group.options);
    }
    acc.push({
      label: group.label,
      value: group.value,
      uncountable: group.uncountable,
    });
    return acc;
  }, []);
};

export const selectOptionsFromString = (
  value: string,
  options: Option<number>[],
): Option<number>[] => {
  const valueArray = value.split(',').map((v) => +v);
  return options.filter((o) => valueArray.includes(o.value));
};

export const groupMultiselectedValues = (selected: Option[], allValues: number): string => {
  const MAX_SEPARATED_COUNTS = 5;

  if (!selected.length) {
    return '';
  }

  if (allValues && selected.length >= allValues) {
    return 'All Selected';
  }

  return selected.length > MAX_SEPARATED_COUNTS
    ? `${selected
        .filter((item, index) => index < MAX_SEPARATED_COUNTS && item.label)
        .map((i) => i.label)
        .join(', ')} and ${selected.length - MAX_SEPARATED_COUNTS} more`
    : selected.map((item) => item.label).join(', ');
};

export const formatScheduling = (scheduling: Scheduling): string => {
  const intervals = Object.entries(scheduling).reduce((acc, arr) => {
    return acc + arr[1].length;
  }, 0);

  if (intervals === 0) {
    return '';
  }

  return `${intervals} ${pluralize('interval', intervals)} selected`;
};

export const formatToBoolean = (value: boolean) => {
  return value ? 'ON' : 'OFF';
};

export const formatConversionsType = (value: boolean) => {
  return value ? 'Install' : 'Non-install';
};

export const formatAdvancedTargetingFields = (...arr: string[]) => {
  const count = arr
    .join()
    .split(',')
    .filter((str) => str.trim()).length;

  if (!count) {
    return '';
  }
  return `${count} ${pluralize('item', count)} selected`;
};

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  );

export const getWhiteList = (list: { locationId: number | string; isIncluded: boolean }[]) => {
  return list.filter(({ isIncluded }) => isIncluded).map(({ locationId }) => Number(locationId));
};

export const getBlackList = (list: { locationId: number | string; isIncluded: boolean }[]) => {
  return list.filter(({ isIncluded }) => !isIncluded).map(({ locationId }) => Number(locationId));
};

type TFacet = AdvancedTargetingOptionKeys | 'audiences' | 'inventories';

export const getTargetingTooltip = (type: 'targeted' | 'blocked', facet: TFacet) => {
  if (filterLabels[facet as AdvancedTargetingOptionKeys]) {
    const targeting =
      filterLabels[facet as AdvancedTargetingOptionKeys] === 'IP'
        ? 'IPs'
        : filterLabels[facet as AdvancedTargetingOptionKeys];
    return `List of ${targeting} ${type} for this campaign`;
  }
  return `List of ${facet} ${type} for this campaign`;
};
