import { createAction } from '../../utils/actions';
import { mapConstants } from './constants';
import { PolygonExtended } from '../../models/PolygonExtended';
import { CircleExtended } from '../../models/CircleExtended';
import { Option, OptionData } from '../../models/Option';
import { LocationOption, LocationState, RadiusUpdatedLocations } from '../../models/Location';
import { LatLngLiteral, OverlayType } from '../../models/Google';
import { MapBounds, TypeIds } from './reducer';
import { RectangleExtended } from '../../models/RectangleExtended';

export interface PushPreSavedPolygon {
  pushPreSavedPolygon: (polygon: PolygonExtended) => void;
}

export interface UpdatePreSavedPolygon {
  updatePreSavedPolygon: (polygon: PolygonExtended) => void;
}

export interface RemovePolygonFromPreSaved {
  removePolygonFromPreSaved: (polygon: PolygonExtended) => void;
}

export interface ClearPreSavedPolygons {
  clearPreSavedPolygons: (withFilePolygons?: boolean) => void;
}

export interface AddPolygonsToSaved {
  addPolygonsToSaved: () => void;
}

export interface RemovePolygonFromSaved {
  removePolygonFromSaved: (polygon: PolygonExtended) => void;
}

export interface RemoveSelectedPolygonsFromPreSaved {
  removeSelectedPolygonsFromPreSaved: () => void;
}

export interface PushPreSavedCircle {
  pushPreSavedCircle: (circle: CircleExtended | CircleExtended[]) => void;
}

export interface UpdatePreSavedCircle {
  updatePreSavedCircle: (circle: CircleExtended) => void;
}

export interface RemoveCircleFromPreSaved {
  removeCircleFromPreSaved: (circle: CircleExtended) => void;
}

export interface RemoveSelectedCirclesFromPreSaved {
  removeSelectedCirclesFromPreSaved: () => void;
}

export interface PushPreSavedRectangle {
  pushPreSavedRectangle: (rectangle: RectangleExtended) => void;
}

export interface UpdatePreSavedRectangle {
  updatePreSavedRectangle: (rectangle: RectangleExtended) => void;
}

export interface RemoveRectangleFromPreSaved {
  removeRectangleFromPreSaved: (rectangle: RectangleExtended) => void;
}

export interface ClearPreSavedRectangles {
  clearPreSavedRectangles: () => void;
}

export interface SetDrawerMode {
  setDrawerMode: (drawerMode?: OverlayType | undefined) => void;
}

export interface SetAreaType {
  setAreaType: (areaType: string | null) => void;
}

export interface SetAreaInclude {
  setAreaInclude: (isInclude: boolean) => void;
}

export interface PushSelectedAreaId {
  pushSelectedAreaId: (selectedAreaId: string | string[]) => void;
}

export interface RemoveSelectedAreaId {
  removeSelectedAreaId: (selectedAreaId: string) => void;
}

export interface SetSelectedLocationTab {
  setSelectedLocationTab: (locationTab: string) => void;
}

export interface EmptySelectedAreasIds {
  emptySelectedAreasIds: () => void;
}

export interface ChangeCounty {
  changeCounty: (county: Option | null) => void;
}

export interface ChangeState {
  changeState: (value: OptionData<LocationState, number>) => void;
}

export interface ChangeStateHouse {
  changeStateHouse: (value: OptionData<LocationState, number>) => void;
}

export interface SetMapCenter {
  setMapCenter: (value: LatLngLiteral) => void;
}

export interface SetMapBounds {
  setMapBounds: (value: MapBounds) => void;
}

export interface SetMapDefaultCenter {
  setMapDefaultCenter: () => void;
}

export interface SetHouseStateDifference {
  setHouseStateDifference: (value: LocationOption[]) => void;
}

export interface SetToastMessage {
  setToastMessage: (message: string) => void;
}

export interface ClearPreSavedCircles {
  clearPreSavedCircles: (withFileCircles?: boolean) => void;
}

export interface SetOpenMapVisibility {
  setOpenMapVisibility: (value: boolean) => void;
}

export interface SetDuplicateTargeting {
  setDuplicateTargeting: (value: boolean) => void;
}

export interface UpdateMapStore {
  updateMapStore: () => void;
}

export interface SetSidebarState {
  setSidebarState: (value: boolean) => void;
}

export interface RemoveFileCircles {
  removeFileCircles: (fileId: number) => void;
}

export interface RemoveFilePolygons {
  removeFilePolygons: (fileId: number) => void;
}

export interface SetMapReadOnly {
  setMapReadOnly: (value: boolean) => void;
}

export interface setShapesRadiusLimitError {
  setShapesRadiusLimitError: (value: string) => void;
}

export interface SetRadiusUpdatedLocation {
  setRadiusUpdatedLocations: (value: RadiusUpdatedLocations) => void;
}

export interface AddGeoJsonData {
  addGeoJSONData: (value: { key: TypeIds; label: string; value: any }) => void;
}

export interface SetActiveFeaturesIds {
  setActiveFeaturesIds: (value: { key: string; selectedLabels: string[] }) => void;
}

export const mapActions = {
  initialMap: () => {
    return createAction(mapConstants.INITIAL_MAP);
  },
  pushPreSavedPolygon: (polygon: PolygonExtended) => {
    return createAction<PolygonExtended>(mapConstants.PUSH__PRESAVED_POLYGON, polygon);
  },
  updatePreSavedPolygon: (polygon: PolygonExtended) =>
    createAction(mapConstants.UPDATE__PRESAVED_POLYGON, polygon),
  removePolygonFromPreSaved: (polygon: PolygonExtended) => {
    return createAction<PolygonExtended>(mapConstants.REMOVE__POLYGON_FROM_PRESAVED, polygon);
  },
  clearPreSavedPolygons: (withFileItems: boolean = false) => {
    return createAction<boolean>(mapConstants.CLEAR__PRESAVED_POLYGONS, withFileItems);
  },
  addPolygonsToSaved: () => {
    return createAction<void>(mapConstants.ADD__POLYGONS_TO_SAVED);
  },
  removePolygonFromSaved: (polygon: PolygonExtended) => {
    return createAction<PolygonExtended>(mapConstants.REMOVE__POLYGON_FROM_SAVED, polygon);
  },
  removeSelectedPolygonsFromPreSaved: () => {
    return createAction<void>(mapConstants.REMOVE_SELECTED_POLYGONS_FROM_PRESAVED);
  },
  pushPreSavedCircle: (circle: CircleExtended | CircleExtended[]) => {
    return createAction<CircleExtended | CircleExtended[]>(
      mapConstants.PUSH__PRESAVED_CIRCLE,
      circle,
    );
  },
  updatePreSavedCircle: (circle: CircleExtended) =>
    createAction(mapConstants.UPDATE__PRESAVED_CIRCLE, circle),
  removeCircleFromPreSaved: (circle: CircleExtended) => {
    return createAction<CircleExtended>(mapConstants.REMOVE__CIRCLE_FROM_PRESAVED, circle);
  },
  removeSelectedCirclesFromPreSaved: () => {
    return createAction<void>(mapConstants.REMOVE_SELECTED_CIRCLES_FROM_PRESAVED);
  },
  clearPreSavedCircles: (withFileCircles: boolean = false) => {
    return createAction<boolean>(mapConstants.CLEAR__PRESAVED_CIRCLES, withFileCircles);
  },
  pushPreSavedRectangle: (rectangle: RectangleExtended) => {
    return createAction<RectangleExtended>(mapConstants.PUSH__PRESAVED_RECTANGLE, rectangle);
  },
  updatePreSavedRectangle: (rectangle: RectangleExtended) => {
    return createAction<RectangleExtended>(mapConstants.UPDATE__PRESAVED_RECTANGLE, rectangle);
  },
  removeRectangleFromPreSaved: (rectangle: RectangleExtended) => {
    return createAction<RectangleExtended>(mapConstants.REMOVE__RECTANGLE_FROM_PRESAVED, rectangle);
  },
  removeSelectedRectanglesFromPreSaved: () => {
    return createAction<void>(mapConstants.REMOVE_SELECTED_RECTANGLES_FROM_PRESAVED);
  },
  clearPreSavedRectangles: () => {
    return createAction<void>(mapConstants.CLEAR__PRESAVED_RECTANGLES);
  },
  setDrawerMode: (drawerMode?: OverlayType | undefined) => {
    return createAction<OverlayType | undefined>(mapConstants.SET__DRAWER__MODE, drawerMode);
  },
  setAreaType: (areaType: string | null) => {
    return createAction<string | null>(mapConstants.SET__AREA__TYPE, areaType);
  },
  setAreaInclude: (isInclude: boolean) => {
    return createAction<boolean>(mapConstants.SET__AREA__INCLUDE, isInclude);
  },
  pushSelectedAreaId: (selectedAreaId: string | string[]) => {
    return createAction<string | string[]>(mapConstants.PUSH_SELECTED_AREA_ID, selectedAreaId);
  },
  removeSelectedAreaId: (selectedAreaId: string) => {
    return createAction<string>(mapConstants.REMOVE_SELECTED_AREA_ID, selectedAreaId);
  },
  emptySelectedAreasIds: () => {
    return createAction<void>(mapConstants.EMPTY_SELECTED_AREAS_IDS);
  },
  changeCounty: (value: Option | null) => {
    return createAction<Option | null>(mapConstants.CHANGED__LOCATION_COUNTY, value);
  },
  changeState: (value: LocationOption) => {
    return createAction<LocationOption>(mapConstants.CHANGED__LOCATION_STATE_SENATE, value);
  },
  changeStateHouse: (value: LocationOption) => {
    return createAction<LocationOption>(mapConstants.CHANGED__LOCATION_STATE_HOUSE, value);
  },
  setMapCenter: (value: LatLngLiteral) => {
    return createAction<LatLngLiteral>(mapConstants.SET__MAP_CENTER, value);
  },
  setMapBounds: (value: MapBounds) => {
    return createAction<MapBounds>(mapConstants.SET__MAP_BOUNDS, value);
  },
  setMapDefaultCenter: () => {
    return createAction<void>(mapConstants.SET__MAP_DEFAULT_CENTER);
  },
  setHouseStateDifference: (value: LocationOption[]) => {
    return createAction<LocationOption[]>(mapConstants.SET__HOUSE_STATE_DIFFERENCE, value);
  },
  setToastMessage: (message: string) => {
    return createAction(mapConstants.SET_TOAST_MESSAGE, message);
  },
  setOpenMapVisibility: (value: boolean) => {
    return createAction<boolean>(mapConstants.SET_MAP_VISIBILITY, value);
  },
  updateMapStore: () => {
    return createAction(mapConstants.UPDATE_MAP_STORE);
  },
  setSidebarState: (value: boolean) => {
    return createAction(mapConstants.SET_SIDEBAR_STATE, value);
  },
  removeFileCircles: (fileId: number) => {
    return createAction(mapConstants.REMOVE_FILE_PRESAVED_CIRCLES, fileId);
  },
  removeFilePolygons: (fileId: number) => {
    return createAction(mapConstants.REMOVE_FILE_PRESAVED_POLYGONS, fileId);
  },
  setMapReadOnly: (value: boolean) => {
    return createAction(mapConstants.SET_MAP_READONLY, value);
  },
  setShapesRadiusLimitError: (value: string) => {
    return createAction(mapConstants.SET_SHAPE_RADIUS_LIMIT_ERROR, value);
  },
  setRadiusUpdatedLocations: (value: RadiusUpdatedLocations) => {
    return createAction(mapConstants.SET_RADIUS_UPDATED_LOCATIONS, value);
  },
  setSelectedLocationIds: (value: { key: string; selectedLabels: string[] }) => {
    return createAction(mapConstants.SET_SELECTED_LOCATIONS_IDS, value);
  },
  addGeoJSONData: (value: { key: TypeIds; label: string; value: any }) => {
    return createAction(mapConstants.ADD_GEOJSON_FEATURES_CACHE, value);
  },
  setSelectedLocationTab: (value: string) => {
    return createAction(mapConstants.SET_SELECTED_LOCATION_TAB, value);
  },
};
