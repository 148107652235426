import { StateCreator } from 'zustand';
import { ConversionType } from 'models/Conversion';
import { CampaignConversionSlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  conversionType: ConversionType.NONE,
  selectedConversions: [],
  conversionTableSelection: [],
};

export const createConversionBlockStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CampaignConversionSlice
> = (set) => ({
  conversionBlock: {
    ...initialState,
    setSelectedConversions: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          conversionBlock: {
            ...prev.conversionBlock,
            selectedConversions: value,
          },
        }),
        false,
        'setSelectedConversions',
      ),
    setConversionTableSelection: (value) => {
      return set(
        (prev: StoreFilter) => ({
          ...prev,
          conversionBlock: {
            ...prev.conversionBlock,
            conversionTableSelection: value,
          },
        }),
        false,
        'setConversionTableSelection',
      );
    },
    setConversionType: (value) => {
      return set(
        (prev: StoreFilter) => ({
          ...prev,
          conversionBlock: {
            ...prev.conversionBlock,
            conversionType: value,
          },
        }),
        false,
        'setConversionType',
      );
    },
    resetConversionBlockStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          conversionBlock: {
            ...prev.conversionBlock,
            ...initialState,
          },
        }),
        false,
        'resetConversionBlockStore',
      ),
    initConversionBlock: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          conversionBlock: {
            ...prev.conversionBlock,
            selectedConversions: value.selectedConversions || [],
            conversionType: value.conversionType,
          },
        }),
        false,
        'initConversionBlock',
      ),
  },
});
