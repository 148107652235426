import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { ProgressContent } from './ProgressContent';
import { AppState } from '../../../models/Store';
import {
  applicationActions,
  ResetAppStoreFull,
  SetModellingDimensionToShow,
} from '../../../store/app/actions';
import { MainContent } from '../../../components/MainContent';

import styles from './DefaultPage.module.scss';

interface Props extends ResetAppStoreFull, SetModellingDimensionToShow {
  loading: boolean;
  error: any;
  campaignCreated: boolean;
  campaignDrafted: boolean;
  campaignEdited: boolean;
  savedIoId: AppState['app']['savedIoId'];
  modellingDimensionToShow: string;
  savedCampaignId: number | null;
}

const CampaignSavingPageComponent = (props: Props) => {
  const history = useHistory();

  const {
    loading,
    campaignCreated,
    campaignDrafted,
    campaignEdited,
    error,
    savedIoId,
    modellingDimensionToShow,
    savedCampaignId,
    setModellingDimensionToShow,
  } = props;

  useEffect(() => {
    if (error) {
      // @ts-ignore
      const previousPath = history?.location?.state?.previous ?? '';
      const urlParmasStr = previousPath.split('?')?.[1] ?? '';
      const urlParams = new URLSearchParams(urlParmasStr);
      if (modellingDimensionToShow) {
        setModellingDimensionToShow('');
      }
      if (!urlParams.has('ioId')) {
        history.goBack();
      }
      const ioId = urlParams.get('ioId');
      if (previousPath.indexOf('campaign-create')) {
        history.replace({
          pathname: '/campaign-create',
          search: `ioId=${ioId}`,
          state: { hasError: true },
        });
      } else if (previousPath.indexOf('campaign-edit')) {
        history.replace({
          pathname: '/campaign-edit',
          search: `ioId=${ioId}`,
          state: { hasError: true },
        });
      } else {
        history.goBack();
      }
    } else if (campaignCreated || campaignDrafted || campaignEdited) {
      // modellingDimensionToShow - this will indicate if campaign was saved during modelling and will prevent user from
      // navigating to io page and will make them stay at campaign page
      if (modellingDimensionToShow && savedCampaignId) {
        history.push({
          pathname: `/campaign-edit/${savedCampaignId}`,
          state: { modellingDimensionToShow },
        });
      } else {
        history.replace(`/io-detail/${+savedIoId!}`);
      }
    }
  }, [
    campaignCreated,
    campaignDrafted,
    campaignEdited,
    history,
    error,
    savedIoId,
    modellingDimensionToShow,
    savedCampaignId,
    setModellingDimensionToShow,
  ]);

  return loading ? (
    <MainContent className={clsx(loading && '_loading', styles.container)}>
      <ProgressContent />
    </MainContent>
  ) : null;
};

const mapState = (state: AppState) => ({
  loading: state.app.loading,
  error: state.app.errorCreating,
  campaignCreated: state.app.campaignCreated,
  campaignDrafted: state.app.campaignDrafted,
  campaignEdited: state.app.campaignEdited,
  savedCampaignId: state.app.savedCampaignId,
  savedIoId: state.app.savedIoId,
  modellingDimensionToShow: state.app.modellingDimensionToShow,
});

const mapAction = {
  resetAppStoreFull: applicationActions.resetAppStoreFull,
  setModellingDimensionToShow: applicationActions.setModellingDimensionToShow,
};

export const CampaignSavePage = connect(mapState, mapAction)(CampaignSavingPageComponent);
