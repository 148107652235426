import React, { useEffect, useState } from 'react';
import { ButtonCircle } from 'factor';

import styles from './Block.module.scss';

type Props = {
  children: React.ReactNode;
  header?: {
    title: string;
    collapsable: boolean;
    isExtended?: boolean;
    summary: {
      [key: string]: string;
    };
  };
};

export const Block = (props: Props) => {
  const { children, header } = props;
  const [isOpen, setIsOpen] = useState(!header?.collapsable);

  useEffect(() => {
    if (header && header.isExtended) {
      setIsOpen(header.isExtended);
    }
  }, [header]);

  const getSummary = () =>
    header &&
    Object.keys(header.summary).map((key) =>
      header.summary[key].length ? (
        <span className={styles.summaryContainer} key={key}>
          <span className={styles.summaryLabel}>{key}: </span>
          <span>{header.summary[key]}</span>
        </span>
      ) : null,
    );

  return (
    <div data-qa="47" className={`block ${styles.block}`}>
      {header && (
        <div data-qa="147" className={`${styles.blockHeader} ${isOpen ? '' : 'mb-0'}`}>
          <div data-qa="148" className={styles.blockTitle}>
            <h4 data-qa="149" className={styles.blockTitleText}>
              {header.title}
            </h4>
            {header && !isOpen && <div className={styles.summary}>{getSummary()}</div>}
          </div>
          {header.collapsable && (
            <div
              className={styles.arrow}
              onClick={() => {
                if (!header.isExtended) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <ButtonCircle iconName={isOpen ? 'Expand' : 'Right'} />
            </div>
          )}
        </div>
      )}
      <div hidden={!isOpen}>{children}</div>
    </div>
  );
};

export const blockStyles = styles;
