import { select, put } from 'redux-saga/effects';
import get from 'lodash/get';

import { AppState } from 'models/Store';
import { Action } from 'models/Action';
import { PoliticalAdvertiser, CreatingParams, CreatingResponse } from 'api/Advertiser';
import { API } from 'api';
import { WithResponse } from 'models/Response';
import { createAction } from 'utils/actions';
import { advertiserActions } from '../advertiser/actions';
import { applicationConstants } from '../app/constants';
import { validateAdvertiserFormSaga } from '../advertiser/helpers';

export function* fetchAdvertisersSaga(action: Action<number>): Generator<unknown, void, any> {
  const state: AppState = yield select();
  const { userData: user } = state.auth;

  if (user) {
    const params = yield {
      owId: user.userId,
    };
    const response: WithResponse<{
      data: PoliticalAdvertiser[];
    }> = yield API.Advertiser.fetchAdvertiserList(params);

    if (response.data?.data.length) {
      const { data } = response.data;
      yield put(advertiserActions.setPoliticalAdvertiserList(data));
      if (!state.app.editableCampaign) {
        yield put(advertiserActions.selectPoliticalAdvertiser(data, action.payload));
      }
    }
  }
}

export function* createAdvertiserSaga(): Generator<unknown, unknown, any> {
  try {
    const state: AppState = yield select();
    const { advertiser } = state;
    const formIsValid: boolean = yield validateAdvertiserFormSaga(state);
    if (formIsValid) {
      const request: CreatingParams = {
        name: advertiser.name,
        address: advertiser.address,
        phone: advertiser.phone,
        website: advertiser.url,
        chiefName: advertiser.chief,
        links: advertiser.links,
        legalInfo: advertiser.other,
      };
      const fetchListParams = yield {};
      const response: CreatingResponse = yield API.Advertiser.createPoliticalAdvertiser(request);
      const advertisers: PoliticalAdvertiser[] = yield API.Advertiser.fetchAdvertiserList(
        fetchListParams,
      );
      const savedAdvertiser = advertisers.find((i: PoliticalAdvertiser) => i.id === response.id);

      if (savedAdvertiser) {
        yield put(advertiserActions.changePoliticalAdvertiser(savedAdvertiser));
      }
      return savedAdvertiser;
    }
    return null;
  } catch (error) {
    const errorObj = get(error, 'responseObject', null);
    yield put(createAction<any>(applicationConstants.FAIL_CREATING, errorObj || error));
    return errorObj;
  }
}
