import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AlertTriangle, InfoCircle } from '@applift/icons';
import { Tooltip, Typography } from '@applift/factor';
import kebabCase from 'lodash/kebabCase';
import isEqual from 'lodash/isEqual';

import { BUDGET_TYPE_ID } from 'constants/apps';

import styles from './index.module.scss';

export interface ValidationRule {
  name?: string;
  comparedFieldName?: string;
  func?: (value: number) => boolean;
  error: (
    name: string | undefined,
    rule: {
      [key: string]: any;
    },
  ) => any;
}

interface Props {
  fieldLabel: string;
  isBlue?: boolean;
  value: string;
  handleChange: (newValue: string) => void;
  handleClickWhenDisabled?: () => void;
  tooltipText?: string;
  placeholder: string;
  max?: number;
  disabled?: boolean;
  validationRules?: ValidationRule[];
  validationKeys?: (string | boolean)[];
  budgetTypeId?: number;
  warningTooltipText?: string;
  footer?: JSX.Element;
  dynamicHeight?: boolean;
}

export const AmountField = (props: Props) => {
  const {
    fieldLabel,
    isBlue,
    handleChange,
    value,
    tooltipText,
    placeholder,
    max,
    disabled,
    handleClickWhenDisabled,
    validationRules,
    validationKeys,
    budgetTypeId = 1,
    warningTooltipText,
    footer,
    dynamicHeight,
  } = props;
  const kebabCaseLabel = kebabCase(fieldLabel);
  const [fieldValue, setFieldValue] = useState<string>(String(value));
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [validity, setValidity] = useState<boolean>(true);
  const [helpText, setHelpText] = useState<string>('');
  const [currentFontSize, setCurrentFontSize] = useState<number>(32);
  const amountFieldRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const existingValidationKeys = useRef<any[]>([]);

  useLayoutEffect(() => {
    setCurrentFontSize(
      fieldValue.length > 9 ? 112 / fieldValue.length + fieldValue.length * 1.25 : 32,
    );
  }, [fieldValue]);

  useEffect(() => {
    if (!inputFieldRef.current) {
      return;
    }
    if (isFocused) {
      inputFieldRef.current.focus();
    } else {
      inputFieldRef.current.blur();
    }
  }, [isFocused]);

  useEffect(() => {
    const newValue =
      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? String(Number(value).toFixed(2))
        : String(Number(value));
    setFieldValue(newValue);
  }, [value, budgetTypeId]);

  const onBlur = useCallback(() => {
    const newValue =
      budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? String(Number(fieldValue).toFixed(2))
        : String(Number(fieldValue));
    setFieldValue(newValue);
    handleChange(newValue);
    setIsFocused(false);
  }, [fieldValue, handleChange, budgetTypeId]);

  // const windowClickHandler = useCallback(
  //   ({ target }: MouseEvent) => {
  //     if (isFocused && (target as Element)?.closest('.amount-field') !== amountFieldRef.current) {
  //       onBlur();
  //     }
  //   },
  //   [isFocused, onBlur],
  // );

  // useEffect(() => {
  //   window.addEventListener('click', windowClickHandler);

  //   return () => {
  //     window.removeEventListener('click', windowClickHandler);
  //   };
  // }, [windowClickHandler]);

  const setValidationState = useCallback((isValid: boolean, rule?: ValidationRule) => {
    if (!amountFieldRef.current) {
      return;
    }

    setValidity(isValid);
    setHelpText(isValid || !rule ? '' : rule.error(undefined, rule));
  }, []);

  const handleValidation = useCallback(
    (vRules = validationRules || [], newValue) => {
      const rules = vRules.slice();

      let rule = rules.pop();

      while (rule) {
        const isValid = rule.func(+newValue);
        if (!isValid) {
          setValidationState(false, rule);
          return;
        }
        rule = rules.pop();
      }

      setValidationState(true);
    },
    [setValidationState, validationRules],
  );

  useEffect(() => {
    if (validationKeys && !isEqual(validationKeys, existingValidationKeys.current)) {
      existingValidationKeys.current = validationKeys;
      handleValidation(undefined, value);
    }
  }, [handleValidation, validationKeys, value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueToSave = `${e.currentTarget.value}`;

    if (
      (/^\d+(\.\d*){0,1}$/.test(valueToSave) || valueToSave === '') &&
      (!max || +valueToSave <= max)
    ) {
      setFieldValue(valueToSave);
      if (validationRules?.length) {
        handleValidation(validationRules, valueToSave);
      }
    }
  };

  const setFocus = () => {
    if (disabled && handleClickWhenDisabled) {
      handleClickWhenDisabled();
    } else {
      setIsFocused(true);
    }
  };

  const getDecimalCurrencyValue = (val: string) => {
    const amount = Number(val.substring(0, val.indexOf('.')));
    const amountInLocale = amount
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      .slice(1);
    return amountInLocale.substring(0, amountInLocale.length - 3);
  };

  const wrapperClassNames = useMemo(() => {
    const classNames = ['amount-field', styles.fieldWrapper];
    if (disabled) {
      classNames.push(styles.fieldWrapperDisabled);
    }

    if (isBlue) {
      classNames.push(styles.bgBlue);
    }

    if (isFocused) {
      classNames.push(styles.fieldWrapperFocused);
    }

    if (!validity) {
      classNames.push(styles.hasError);
    }

    return classNames.join(' ');
  }, [disabled, isBlue, isFocused, validity]);

  return (
    <div className="d-flex flex-column">
      <div
        onClick={setFocus}
        ref={amountFieldRef}
        style={{ ...(dynamicHeight ? { height: 'auto' } : {}) }}
        className={wrapperClassNames}
      >
        <div className="d-flex justify-content-between">
          <div>
            <label htmlFor={kebabCaseLabel} className={styles.label}>
              {fieldLabel}
              {tooltipText ? (
                <Tooltip title={tooltipText} placement="top" arrow>
                  <InfoCircle />
                </Tooltip>
              ) : null}
            </label>
          </div>
          {warningTooltipText && (
            <div>
              <Tooltip title={warningTooltipText} placement="top" arrow>
                <AlertTriangle
                  color="warning"
                  sx={{ m: 12 }}
                  className={styles.alertIcon}
                  fontSize={24}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center">
          {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
            <span className={styles.currency}>$</span>
          ) : (
            <span className={styles.currency}>Imps</span>
          )}
          {isFocused || !fieldValue || fieldValue === '0.00' || fieldValue === '0' ? (
            <input
              className={styles.input}
              id={kebabCaseLabel}
              name={kebabCaseLabel}
              value={fieldValue === '0.00' || fieldValue === '0' ? '' : fieldValue}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={setFocus}
              placeholder={placeholder}
              ref={inputFieldRef}
              disabled={disabled}
              title=""
              autoComplete="off"
              /* eslint-disable react/jsx-props-no-spreading */
              {...(budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
                ? { onKeyDown: (evt) => evt.key === '.' && evt.preventDefault() }
                : {})}
            />
          ) : (
            <div className={styles.readonlyValue}>
              {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                <>
                  <span style={{ fontSize: `${currentFontSize}px` }}>
                    {getDecimalCurrencyValue(fieldValue)}
                  </span>
                  <span style={{ fontSize: `${currentFontSize / 1.5}px` }}>
                    {fieldValue.substring(fieldValue.indexOf('.'))}
                  </span>
                </>
              ) : (
                <span style={{ fontSize: `${currentFontSize}px` }}>
                  {Intl.NumberFormat('en-US').format(parseInt(fieldValue, 10))}
                </span>
              )}
            </div>
          )}
        </div>
        {footer}
      </div>
      <Typography variant="label" sx={{ textColor: 'danger-400', mt: 4 }}>
        {helpText}
      </Typography>
    </div>
  );
};
