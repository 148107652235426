import * as React from 'react';
import { Dialog } from '@applift/factor';

import { ActionNameType, ActionMapper } from './action';

interface TableActionsProps {
  action: ActionNameType;
  rowData?: any;
  onClose: (props?: any) => any;
}

const EmptyComponent = () => {
  return null;
};

export const TableActions = (props: TableActionsProps) => {
  const { action, rowData, onClose } = props;

  const currentAction = ActionMapper[action];
  const Component = currentAction?.component || EmptyComponent;

  return (
    // eslint-disable-next-line
    <Dialog open={Boolean(currentAction)} {...currentAction?.dialogProps}>
      <Component onClose={onClose} data={rowData!} action={action} />
    </Dialog>
  );
};
