import React from 'react';
import { Icon } from 'factor';

type TProps = {
  src: string;
  className?: string;
};

type TState = {
  hasLoadingError: boolean;
};

export default class WithErrorPlaceholderImage extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      hasLoadingError: false,
    };
  }

  onError = () =>
    this.setState({
      hasLoadingError: true,
    });

  render() {
    const { className, src } = this.props;
    const { hasLoadingError } = this.state;

    if (hasLoadingError) {
      return <Icon name="NoImage" />;
    }

    return (
      <img alt="No preview available" className={className} src={src} onError={this.onError} />
    );
  }
}
