import { put } from 'redux-saga/effects';

import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Action } from 'models/Action';
import { findGeometryByLocationName } from 'models/Google';
import { Option } from 'models/Option';
import { USA_CENTER } from 'store/map/reducer';
import { Logger } from 'utils/logger';
import { US_COUNTRY_ID } from 'constants/location';
import { mapActions } from '../map/actions';

export function* centerMap(
  action: Action<{ key: string; value: Option }>,
): Generator<unknown, void, any> {
  const { value, key } = action.payload;
  if (value && key === CampaignInfoField.country) {
    if (value.value === US_COUNTRY_ID) {
      yield put(mapActions.setMapCenter(USA_CENTER));
    } else {
      try {
        const response = yield findGeometryByLocationName(value.label);
        const { location } = response;
        const coordinates = {
          lat: location.lat(),
          lng: location.lng(),
        };
        yield put(mapActions.setMapCenter(coordinates));
      } catch (err) {
        Logger.log('Error while centering map', err);
      }
    }
  }
}
