import * as React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LoadingButton,
} from '@applift/factor';

import { CampaignListType } from 'models/CampaignList';

interface PauseData {
  campaignIds: string;
  status: 'paused';
}

interface PauseProps {
  selectedCampaigns: CampaignListType[];
  closeDialog: () => void;
  onCompletion: (data: PauseData) => void;
  isLoading: boolean;
}

export const PauseCampaign = (props: PauseProps) => {
  const { closeDialog, onCompletion, selectedCampaigns, isLoading } = props;

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle onClose={closeDialog}>Pause Campaign</DialogTitle>
      <DialogContent
        dividers
        sx={{ flexDirection: 'column', display: 'flex', overflowY: 'visible' }}
      >
        <Typography sx={{ mb: 16 }}>
          Pause the{' '}
          {selectedCampaigns.length === 1 && <Typography sx={{ mr: 2 }}>campaign</Typography>}
          <Typography sx={{ textWeight: 'demi', ml: 2 }}>
            {selectedCampaigns.length === 1
              ? `${selectedCampaigns[0].campaignName}`
              : selectedCampaigns.length}
          </Typography>
          {selectedCampaigns.length > 1 ? ' campaigns?' : '?'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isLoading}
          onClick={() =>
            onCompletion({
              campaignIds: selectedCampaigns.map((item) => item.campaignId).toString(),
              status: 'paused',
            })
          }
        >
          Pause
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
