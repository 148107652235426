import React from 'react';
import { DataGrid, GridActionCellParams, getSortedRowModel } from '@applift/datagrid';
import { Box, Typography, sx } from '@applift/factor';
import { InventoryGroupListResponse } from 'models/Inventory';
import { colDef } from './colDef';

type Props = {
  selectedInventoryGroupsData: InventoryGroupListResponse['inventoryGroupList'];
  setSelectedInventoryGroupData: React.Dispatch<
    React.SetStateAction<InventoryGroupListResponse['inventoryGroupList']>
  >;
  isLoading?: boolean;
};

export const SelectedInventoryGroupTable = (props: Props) => {
  const { setSelectedInventoryGroupData, selectedInventoryGroupsData, isLoading } = props;

  const actions = {
    updateRow: (params: {
      rowId: number;
      payload: InventoryGroupListResponse['inventoryGroupList'][0];
    }) => {
      const { rowId, payload } = params;
      setSelectedInventoryGroupData(
        (prev) => prev?.map((grp) => (grp.id === rowId ? { ...grp, ...payload } : grp)) ?? [],
      );
    },
    removeRow: ({ rowId }: { rowId: number }) => {
      setSelectedInventoryGroupData((prev) => prev?.filter((grp) => grp.id !== rowId));
    },
  };

  const onAction = (params: GridActionCellParams) => {
    actions[params.actionName as keyof typeof actions](params.metaData);
  };

  return (
    <Box style={{ height: 240 }} sx={{ width: 100, display: 'flex', flexDirection: 'column' }}>
      <Typography weight="demi" variant="bodyMedium" sx={{ textColor: 'neutral-500' }}>
        Inventory Groups {!isLoading ? `(${selectedInventoryGroupsData.length ?? 0})` : ''}
      </Typography>
      <DataGrid
        data={selectedInventoryGroupsData ?? []}
        columns={colDef}
        rowHeight={40}
        headerHeight={40}
        hideHeader
        hideFooter
        showCellRightBorder
        showColumnRightBorder
        getRowId={(row) => `${row.id}`}
        onAction={onAction}
        state={{ columnPinning: { right: ['actions'] } }}
        loading={isLoading}
        getSortedRowModel={getSortedRowModel()}
        classes={{
          root: sx({
            borderRadius: 0,
            borderTop: 1,
            borderLeft: 1,
            borderRight: 1,
            borderBottom: 1,
            borderTopColor: 'neutral-300',
          }),
        }}
      />
    </Box>
  );
};
