import { AxiosError, AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import { WithResponse } from 'models/Response';
import { OptionId } from 'models/Option';
import { BasicIoListApiData, IoListApiData, DownloadIoList } from 'models/IoList';
import { SaveColListParam } from 'models/CampaignList';
import { get } from 'lodash';
import { Logger } from 'utils/logger';
import { getInstance } from './Instance';
import { getBasicIoListKey, getIoListKey } from './QueryKeys';

export const getIoList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getIoListKey['keys']>>): Promise<
  WithResponse<IoListApiData>
> => {
  const {
    searchField,
    sortBy,
    pageSize,
    ioStatusIds,
    startDate,
    endDate,
    timeZoneId,
    budgetTypeIds,
  } = queryKey[0] || {};
  try {
    const response: AxiosResponse<WithResponse<IoListApiData>> = await getInstance().get(
      '/v3/cmp/io/advanced/list',
      {
        params: {
          searchField,
          pageSize,
          pageNo: pageParam,
          sortBy,
          ioStatusIds,
          budgetTypeIds,
          ...(startDate ? { startDate } : {}),
          ...(endDate ? { endDate } : {}),
          ...(timeZoneId ? { timeZoneId } : {}),
        },
      },
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const getIoBasicList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getBasicIoListKey['keys']>>): Promise<
  WithResponse<BasicIoListApiData>
> => {
  const { searchField, ioIdList, pageNo, sortBy, ioStatusIdsList, ioBudgetTypeIdsList } =
    queryKey[0] || {};
  try {
    const response: AxiosResponse<WithResponse<BasicIoListApiData>> = await getInstance().post(
      '/v3/cmp/io/basic/list',
      {
        searchField,
        ioIdList,
        pageNo,
        sortBy,
        ioStatusIdsList,
        ioBudgetTypeIdsList,
      },
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const getIoListCols = async (): Promise<SaveColListParam | undefined> => {
  try {
    const response: AxiosResponse<WithResponse<SaveColListParam>> = await getInstance().get(
      `/v3/cmp/io/config`,
    );

    return response.data.data;
  } catch (e) {
    Logger.log('Error while fetch campaign data', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const saveIoListCols = async (
  params: SaveColListParam,
): Promise<WithResponse<SaveColListParam>> => {
  try {
    const response: AxiosResponse<WithResponse<SaveColListParam>> = await getInstance().post(
      '/v3/cmp/io/config/save',
      params,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response);
  }
};

export const downloadIoList = async (data: DownloadIoList) => {
  try {
    const response: AxiosResponse<WithResponse<{ url: string }>> = await getInstance().post(
      `/v3/cmp/io/advanced/list/download`,
      data,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

interface IOBudgetTypesResponse {
  budgetTypeData: OptionId[];
  totalRecords: number;
  filteredRecords: number;
}

export const getIOBudgetTypes = async () => {
  try {
    const response: AxiosResponse<WithResponse<IOBudgetTypesResponse>> = await getInstance().get(
      '/v3/cmp/static/campaign/budget-type',
    );
    if (response.data.success) {
      return response.data;
    }

    throw response;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};
