import * as React from 'react';
import {
  Col,
  IconButton,
  MenuItem,
  Paper,
  Row,
  sx,
  TextField,
  useDebounceValue,
} from '@applift/factor';
import { SortingState, DataGrid, useGridApiRef } from '@applift/datagrid';
import {
  Search,
  Close,
  StatusRunning,
  StatusPending,
  StatusPaused,
  StatusDeleted,
  StatusRejected,
  ClipboardRemove,
  StatusAll,
} from '@applift/icons';
import { SearchData } from '@applift/illustrations';

import { NoResultsOverlay } from 'pages/MainPage/IoListPage/ListGrid/NoResultOverlay';
import { useCreativesInfo } from 'hooks/useCreatives';
import { creativesStatusMapper, IMAGE_CREATIVE_ID } from 'models/Creative';
import { CampaignListType } from 'models/CampaignList';
import { creativesColDef } from './creativesColDef';

export const creativesStatusFilterMapper = [
  {
    label: 'Running',
    key: 'running',
    icon: <StatusRunning sx={{ mr: 8 }} color="success" />,
  },
  {
    label: 'Pending',
    key: 'pending',
    icon: <StatusPending sx={{ mr: 8 }} color="warning" />,
  },
  {
    label: 'Paused',
    key: 'paused',
    icon: <StatusPaused sx={{ mr: 8 }} color="warning" />,
  },
  {
    label: 'Deleted',
    key: 'deleted',
    icon: <StatusDeleted sx={{ mr: 8 }} color="error" />,
  },
  {
    label: 'Rejected',
    key: 'rejected',
    icon: <StatusRejected sx={{ mr: 8 }} color="error" />,
  },
  {
    label: 'All',
    key: 'running,pending,paused,deleted,rejected',
    icon: <StatusAll sx={{ mr: 8 }} color="info" />,
  },
];

interface CreativesListInterface {
  campaignData: CampaignListType;
}

export const CreativesList = (props: CreativesListInterface) => {
  const { campaignData } = props;

  const [search, setSearch] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'id', desc: true }]);
  const [status, setStatus] = React.useState({
    key: 'running,pending,paused,deleted,rejected',
    label: 'All',
  });
  const [height, setHeight] = React.useState(0);

  const apiRef = useGridApiRef();
  const debouncedSearch = useDebounceValue(search, 500);

  const { data: creativesResponseData, isFetching } = useCreativesInfo({
    campaignId: campaignData.campaignId,
    searchField: debouncedSearch,
    ...(status
      ? {
          status: status.key.split(',').map((stat) => Number(creativesStatusMapper[stat])),
        }
      : {}),
    order: sorting?.[0]?.desc ? 'desc' : 'asc',
    sortBy: 'id',
    noOfEntries: campaignData.creativesCount,
  });

  const [columnOrder] = React.useState<string[]>([
    'id',
    'creativeThumbnailSource',
    'name',
    'status',
    'clickUrl',
    'pixelUrls',
    'createdAt',
    'size',
  ]);

  const creativesInfo = React.useMemo(
    () => creativesResponseData?.data?.data ?? [],
    [creativesResponseData],
  );

  const rowIdKey = 'id';
  const overlay = React.useMemo(() => {
    if (!creativesInfo?.length && !isFetching) {
      return 'noResult';
    }
    return undefined;
  }, [creativesInfo, isFetching]);

  React.useEffect(() => {
    apiRef?.current?.subscribeEvent?.('viewportInnerSizeChange', (params) => {
      setHeight(params.height);
    });
  }, [apiRef]);

  const columnVisibility = React.useMemo(() => {
    return { pixelUrls: creativesInfo[0]?.creativeTypeId === IMAGE_CREATIVE_ID };
  }, [creativesInfo]);

  return (
    <Paper elevation={2} sx={{ height: 100, display: 'flex', flexDirection: 'column' }}>
      <Row sx={{ display: 'flex', justifyContent: 'between' }}>
        <Col xs={4} sx={{ my: 16, ml: 16 }}>
          <TextField
            variant="outlined"
            select
            SelectProps={{
              placeholder: 'Status',
              renderValue: () => `Status - ${status.label}`,
              MenuProps: {
                PaperProps: { sx: { height: 'auto' } },
              },
            }}
            value={status.key}
            sx={{ width: 75 }}
            onChange={(e) =>
              setStatus({
                key: e.target.value,
                label: creativesStatusFilterMapper.find(
                  (statusItem) => statusItem.key === e.target.value,
                )?.label as string,
              })
            }
          >
            {creativesStatusFilterMapper?.map((val) => (
              <MenuItem startIcon={val.icon} value={val.key}>
                {val.label}
              </MenuItem>
            ))}
          </TextField>
        </Col>
        <Col
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            my: 16,
          }}
        >
          <TextField
            type="text"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setSearch(e.target.value);
            }}
            placeholder="Search by ID, Name"
            variant="outlinedDash"
            InputProps={{
              startAdornment: <Search fontSize={16} sx={{ textColor: 'neutral-400' }} />,
              endAdornment:
                search?.length > 0 ? (
                  <IconButton onClick={() => setSearch('')} size="small">
                    <Close fontSize={16} />
                  </IconButton>
                ) : null,
            }}
          />
        </Col>
      </Row>
      <Row sx={{ flexGrow: 1 }}>
        <Col xs={12}>
          <DataGrid
            apiRef={apiRef}
            data={creativesInfo}
            columns={creativesColDef}
            loading={isFetching}
            rowHeight={100}
            state={{ sorting, columnOrder, columnVisibility }}
            getRowId={(row: any) => {
              if (row && typeof row[rowIdKey] === 'number') {
                return `${row[rowIdKey]}`;
              }
              return row[rowIdKey];
            }}
            hideFooter
            hideHeader
            density="compact"
            showColumnRightBorder
            showCellRightBorder
            overlay={overlay}
            overscrollBehaviorX="contain"
            components={{
              NoResultsOverlay,
            }}
            componentsProps={{
              noResultsOverlay: {
                text: 'No results found',
                subText: 'We can’t find any items matching your search.',
                illustration:
                  height <= 250 ? (
                    <ClipboardRemove sx={{ textColor: 'neutral-400', mb: 8 }} fontSize={36} />
                  ) : (
                    <SearchData
                      sx={{
                        textColor: 'primary-500',
                        width: height <= 400 ? 50 : 100,
                        height: 'auto',
                        mb: height <= 400 ? 24 : 40,
                      }}
                    />
                  ),
              },
            }}
            classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
            onSortingChange={setSorting}
            rowCount={creativesResponseData?.responseObject?.data?.length ?? 0}
          />
        </Col>
      </Row>
    </Paper>
  );
};
