import React, { useEffect, useRef } from 'react';
import Postmate from 'postmate';
import { Logger } from 'utils/logger';
import { initData } from 'mock/locationBlock';
import styles from './index.module.scss';

export const IframeTest = () => {
  const ref = useRef(null);
  const child = useRef<any>(null);

  useEffect(() => {
    const initHandShake = async () => {
      child.current = await new Postmate({
        container: ref.current,
        url: `http://${window.location.host}/#/apps/maps-with-tabs`,
        name: 'location-block',
        classListArray: [styles.iframeSize],
      });
    };
    initHandShake();
    return () => {
      child.current?.destroy?.();
    };
  }, []);

  const getData = async () => {
    const data = await child.current.get('getData');
    Logger.log(data);
  };

  const sendData = async () => {
    child.current.call('initStore', JSON.stringify(initData));
  };
  return (
    <>
      <div ref={ref} style={{ width: 1200, height: 800 }} />
      <button onClick={getData} type="button">
        Get Data
      </button>
      <button onClick={sendData} type="button">
        Init
      </button>
    </>
  );
};
