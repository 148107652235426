import { decodeHTMLEntities } from './decodeEncode';

export const createURLBlob = (value: string, typeOfBlob: string): string => {
  const blob = new Blob([value], { type: typeOfBlob });
  return URL.createObjectURL(blob);
};

export const createURLBlobXML = (value: string): string => {
  return createURLBlob(decodeHTMLEntities(value), 'text/xml');
};
