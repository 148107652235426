import * as React from 'react';
import { TypoTooltip, Typography, TypographyProps } from '@applift/factor';
import { InventoryGroupListResponse } from 'models/Inventory';

interface CurrencyCellType {
  row: InventoryGroupListResponse['inventoryGroupList'][0];
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const CountCell = ({ row = {}, typographyProps }: CurrencyCellType) => {
  const { count, groupTypeId } = row ?? {};

  const groupTypeMapping = {
    1: { label: 'Inventories' },
    2: { label: 'Deals' },
    3: { label: 'Contextual' },
  };

  const displayValue = groupTypeMapping[groupTypeId as keyof typeof groupTypeMapping] ?? {};

  return groupTypeMapping && count ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {/* eslint-disable-next-line */}
      <Typography {...typographyProps}>
        {Intl.NumberFormat('en', { notation: 'compact' } as any).format(count)} {displayValue.label}
      </Typography>
    </TypoTooltip>
  ) : (
    // eslint-disable-next-line
    <Typography {...typographyProps}>—</Typography>
  );
};
