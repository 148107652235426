import * as React from 'react';
import { TypoTooltip, Typography, TypographyProps } from '@applift/factor';
import { useExchangesList } from 'hooks/useInventory';

interface ExchangeCellProps {
  value: number[];
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const ExchangeCell = ({ value = [], typographyProps }: ExchangeCellProps) => {
  const { data } = useExchangesList();
  const exchanges = data?.data.data ?? [];
  const displayValue = value
    ?.map?.((val) => exchanges.filter((type: { id: number }) => type.id === val)?.[0]?.name ?? '')
    .filter(Boolean)
    .join(', ');
  return displayValue ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {/* eslint-disable-next-line */}
      <Typography {...typographyProps}>{displayValue}</Typography>
    </TypoTooltip>
  ) : (
    // eslint-disable-next-line
    <Typography {...typographyProps}>-</Typography>
  );
};
