import * as React from 'react';
import {
  IconButton,
  SelectAdvanceComponents,
  TextField,
  Typography,
  useDebounceValue,
} from '@applift/factor';
import { RowSelectionState } from '@applift/datagrid';
import { Close, GlobeAlt } from '@applift/icons';
import { StoreFilter } from 'models/ZustandStore';
import { useCountryList } from 'hooks/useCountryList';
import { useStore } from 'zustandStore';
import { PG_CAMPAIGN_COMPONENTS } from 'constants/pgCampaign';
import { BlockWrapper } from '../BlockWrapper';

export interface CountryListResponseType {
  id?: number;
  value?: number;
  name?: string;
  shortName?: string;
  abbreviation?: string;
  label?: string;
}

export const LocationBlock = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    Object.keys({}).reduce((prev, one) => {
      // eslint-disable-next-line no-param-reassign
      prev[one] = true;
      return prev;
    }, {} as RowSelectionState),
  );
  const [countrySearch, setCountrySearch] = React.useState<string>();
  const countryListRef = React.useRef();

  const open = Boolean(anchorEl);

  const countryListSearch = useDebounceValue(countrySearch, 500);

  const { data: countryListResponse, isLoading: isCountryListLoading } = useCountryList({
    searchField: countryListSearch,
    options: {
      onSuccess: (res) => {
        if (!countryListRef.current) {
          countryListRef.current = res.data;
        }
      },
    },
  });

  const countryList = React.useMemo(() => countryListResponse?.data, [countryListResponse?.data]);

  const filteredRecords = React.useMemo(
    () => countryListResponse?.filteredRecords,
    [countryListResponse?.filteredRecords],
  );

  const handleTextfieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderItem = SelectAdvanceComponents.DefaultListOptionItemWrapper<CountryListResponseType>({
    disableCheckbox: true,
    selectionStyle: 'none',
    arrowPosition: 'right',
  });

  const overlay = React.useMemo(() => {
    if (!isCountryListLoading) {
      if (!Array.isArray(countryList)) {
        return 'error';
      }
      if ((countryList?.length ?? 0) <= 0) {
        if (countrySearch) {
          return 'noResult';
        }
        return 'noRows';
      }
    }
    return undefined;
  }, [countryList, countrySearch, isCountryListLoading]);

  const [country, setCountry, expandedBlocks, setExpandedBlocks] = useStore(
    (state: StoreFilter) => [
      state.locationBlock.country,
      state.locationBlock.setCountry,

      state.pgCampaignPage.expandedBlocks,
      state.pgCampaignPage.setExpandedBlocks,
    ],
  );

  const handleCountrySelectWrapper = (updater: any) => {
    const selection = updater();
    setCountrySearch(undefined);
    setCountry(
      countryList?.filter((val: { value: string | number }) => {
        // @ts-ignore
        return selection[val.value];
      })[0]?.value ?? country,
    );
    setRowSelection(selection);
  };

  React.useEffect(() => {
    if (
      country &&
      Object.keys(rowSelection).length === 0 &&
      country !== Number(Object.keys(rowSelection)[0])
    ) {
      setRowSelection({ [country]: true });
    }
  }, [country, countryList, rowSelection]);

  const onBlockAccordionChange = () => {
    if (expandedBlocks.includes('Location')) {
      setExpandedBlocks(
        expandedBlocks.filter(
          (val: 'Creatives' | 'Location' | 'Inventory' | 'Conversion') => val !== 'Location',
        ),
      );
    } else {
      setExpandedBlocks(['Location']);
    }
  };

  React.useEffect(() => {
    if (countryList?.length && !countryListRef.current) {
      countryListRef.current = countryList;
    }
  }, [countryList]);

  return (
    <BlockWrapper
      id={PG_CAMPAIGN_COMPONENTS.locationBlock}
      title="Location"
      icon={<GlobeAlt fontSize={24} color="primary" />}
      expanded={!!expandedBlocks.includes('Location')}
      onChange={onBlockAccordionChange}
      blockSummary={
        country && countryList?.length ? (
          <Typography variant="bodySmall" gutterBottom={false}>
            Country:{' '}
            <Typography weight="demi">
              {countryList?.filter((val: { value: number }) => val.value === country)[0]?.label}
            </Typography>
          </Typography>
        ) : undefined
      }
    >
      <TextField
        size="medium"
        variant="outlinedDash"
        label="Country"
        select="advanceSelect"
        placeholder="Select Select Country"
        onClick={(e) => handleTextfieldClick(e)}
        onChange={handleCountrySelectWrapper}
        value={rowSelection}
        style={{ width: '320px' }}
        SelectProps={{
          getRowId: (row) => String(row.value),
          getOptionLabel: (data) => String(data.value),
          data: countryList ?? [],
          rowCount: filteredRecords ?? 20,
          slotProps: {
            InputBaseProps: {
              onChange: (e) => setCountrySearch(e.target.value),
              value: countrySearch,
              placeholder: 'Search',
              endAdornment: (
                <IconButton disabled={!countrySearch} onClick={() => setCountrySearch('')}>
                  <Close />
                </IconButton>
              ),
            },
            PopperProps: {
              anchorEl,
              open,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
              style: { height: '220px' },
              transformOrigin: { vertical: 'top', horizontal: 'right' },
            },
          },
          renderListItem: renderItem,
          placeholder: 'Select Country',
          overlay,
          renderOption: ({ row }) => <Typography>{row.original?.label}</Typography>,
          renderValue: (val) => {
            // @ts-ignore
            if (!Object.keys(val?.[0])?.length) {
              const currentSelectedCountryObj: CountryListResponseType =
                // @ts-ignore
                countryListRef.current?.filter(
                  // @ts-ignore
                  (val: CountryListResponseType) => rowSelection[val.value],
                )[0];
              return <Typography>{currentSelectedCountryObj?.label as string}</Typography>;
            }
            // @ts-ignore
            return <Typography>{val[0]?.label as string}</Typography>;
          },
        }}
      />
    </BlockWrapper>
  );
};
