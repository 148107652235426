import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@applift/factor';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

export const ChangeConversionTypeDialog = (props: Props) => {
  const { onClose, onConfirm } = props;

  const onClickChange = () => {
    onConfirm();
    onClose();
  };

  const onClickCancel = () => {
    onClose();
  };

  return (
    <Dialog open>
      <DialogTitle>Change Conversion Type?</DialogTitle>
      <DialogContent sx={{ paddingY: 0 }}>
        Are you sure you want to change the conversion type? This will remove the current
        conversions added to the campaign.
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onClickChange}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};
