/* global google */
import React from 'react';
import { connect } from 'react-redux';
import { Sidebar, ButtonsGroup, Button, Icon } from 'factor';
import { shapeType, whiteListNameByTagName, blackListNameByTagName } from 'constants/location';
import { BlackLists, WhiteLists } from 'store/advance/reducer';
import { SetWhiteList, SetBlackList } from 'store/advance/actions';
import { StateSelectWrapper } from '../../StateSelectWrapper';
import { Option, ExcludedOption } from '../../../../../../models/Option';
import { CampaignInfoField } from '../../../../../../models/CampaignInfoFields';
import { AppState } from '../../../../../../models/Store';
import { RadiusSwitcher } from '../RadiusSwitcher';
import { MapDrawing } from '../MapDrawing';
import { OverlayType } from '../../../../../../models/Google';
import {
  mapActions,
  RemoveCircleFromPreSaved,
  RemoveRectangleFromPreSaved,
  RemovePolygonFromPreSaved,
  SetSidebarState,
  SetAreaType,
} from '../../../../../../store/map/actions';
import { CircleExtended } from '../../../../../../models/CircleExtended';
import { RectangleExtended } from '../../../../../../models/RectangleExtended';
import { PolygonExtended } from '../../../../../../models/PolygonExtended';
import { User } from '../../../../../../models/User';
import { ErrorCreatingResponse } from '../../../../../../models/Response';
import { advanceActions, SetCampaignSidebarInfo } from '../../../../../../store/advance/actions';
import { applicationActions, ResetError } from '../../../../../../store/app/actions';
import { errorFieldsMapper } from '../../../../../../constants/errorFieldsMapper';
import { ICountryOption, IStateOption } from '../../../../../../models/Location';
import { ListedTagsNames, Tags } from '../../Tags';

import styles from './Index.module.scss';

// This component is currently not being used anywhere. Not deleting it as might be needed in future

export const AREA_TYPES = [
  { label: 'Circle', value: 'Radius', icon: <Icon name="Radius" /> },
  { label: 'Polygon', value: 'Polygon', icon: <Icon name="Polygon" /> },
  { label: 'Rectangle', value: 'Rectangle', icon: <Icon name="Square" /> },
];

interface Props
  extends RemoveCircleFromPreSaved,
    RemoveRectangleFromPreSaved,
    RemovePolygonFromPreSaved,
    SetCampaignSidebarInfo,
    SetSidebarState,
    ResetError,
    SetWhiteList,
    SetBlackList,
    SetAreaType {
  hidden: boolean;
  states: IStateOption[];
  preSavedCircles: CircleExtended[];
  preSavedRectangles: RectangleExtended[];
  preSavedPolygons: PolygonExtended[];
  setDrawerMode: (drawerMode?: OverlayType) => void;
  areaType: string | null;
  removeSelectedPolygonsFromPreSaved: () => void;
  removeSelectedRectanglesFromPreSaved: () => void;
  removeSelectedCirclesFromPreSaved: () => void;
  selectedAreasIds: string[];
  emptySelectedAreasIds: () => void;
  dma: ExcludedOption[];
  districts: Option[];
  houses: Option[];
  senates: Option[];
  user: User;
  errorCreating: ErrorCreatingResponse | null;
  isOpen: boolean;
  isMapVisible: boolean;
  selectedCountry: ICountryOption | null;
  whiteLists: WhiteLists;
  blackLists: BlackLists;
}

class MapFilterComponent extends React.PureComponent<Props> {
  ref: any = React.createRef();

  areaTypes = AREA_TYPES.map((type) => ({
    value: type.value,
    title: (
      <div data-qa="404" className={styles.buttonItemLabel}>
        <i data-qa="405" className={styles.buttonItemLabelIcon}>
          {type.icon}
        </i>
        <span data-qa="406" className={styles.labelTitle}>
          {type.label}
        </span>
      </div>
    ),
  }));

  componentDidMount() {
    const { areaType } = this.props;
    if (!areaType) {
      this.handleAreaChange(this.areaTypes[0].value);
    }
  }

  componentWillUnmount() {
    this.ref.removeEventListener('click', this.onSidebarClick);
  }

  onSidebarClick = (event: MouseEvent) => {
    if (event.ctrlKey || event.metaKey) {
      event.stopPropagation();
    }
  };

  // TODO: CB-1272
  saveToStore = (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => {
    const { setCampaignSidebarInfo, errorCreating, resetError } = this.props;
    setCampaignSidebarInfo(CampaignInfoField[field], value);
    if (Object.keys(whiteListNameByTagName).includes(field) && this.props.whiteLists) {
      const whiteListName = whiteListNameByTagName[field as ListedTagsNames];
      const blackListName = blackListNameByTagName[field as ListedTagsNames];
      const whiteList = this.props.whiteLists[whiteListName] || [];
      const blackList = this.props.blackLists[blackListName] || [];
      if (typeof value === 'string' || typeof value === 'number') {
        this.props.setWhiteList(whiteListName, new Set([...whiteList, Number(value)]));
      } else {
        const newValues = value
          .filter((item) => !blackList.includes(Number(item.value)))
          .map(({ value }) => Number(value));
        this.props.setWhiteList(whiteListName, new Set(newValues));
      }
    }
    if (Object.keys(blackListNameByTagName).includes(field) && this.props.blackLists) {
      const blackListName = blackListNameByTagName[field as ListedTagsNames];
      const blackList = this.props.blackLists[blackListName] || [];
      if (typeof value === 'string' || typeof value === 'number') {
        this.props.setBlackList(blackListName, new Set([...blackList, Number(value)]));
      } else {
        const newBlackList = blackList.filter(
          (item) => value.findIndex((one) => Number(one.value) === item) >= 0,
        );
        this.props.setBlackList(blackListName, new Set(newBlackList));
      }
    }
    if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
      resetError();
    }
  };

  handleAreaChange = (area: string) => {
    const { setDrawerMode, setAreaType } = this.props;
    setAreaType(area);

    if (area === 'Polygon') {
      setDrawerMode(google.maps.drawing.OverlayType.POLYGON);
    } else if (area === 'Rectangle') {
      setDrawerMode(google.maps.drawing.OverlayType.RECTANGLE);
    } else {
      setDrawerMode(google.maps.drawing.OverlayType.CIRCLE);
    }
  };

  toggleSidebar = () => {
    const { isOpen, setSidebarState } = this.props;
    setSidebarState(!isOpen);
  };

  clearSelectedAreas = () => {
    const {
      removeSelectedPolygonsFromPreSaved,
      removeSelectedRectanglesFromPreSaved,
      emptySelectedAreasIds,
      removeSelectedCirclesFromPreSaved,
    } = this.props;

    removeSelectedPolygonsFromPreSaved();
    removeSelectedRectanglesFromPreSaved();
    removeSelectedCirclesFromPreSaved();
    emptySelectedAreasIds();
  };

  removePreselectedItemFromStore = (type: string) => (item: Option) => {
    const {
      preSavedCircles,
      preSavedRectangles,
      preSavedPolygons,
      removeCircleFromPreSaved,
      removeRectangleFromPreSaved,
      removePolygonFromPreSaved,
    } = this.props;
    const foundedCircles = preSavedCircles.find((i) => i.id === item.value);
    const foundedRectangles = preSavedRectangles.find((i) => i.id === item.value);
    const foundedPolygons = preSavedPolygons.find((i) => i.id === item.value);
    switch (type) {
      case shapeType.circle:
        if (foundedCircles) {
          removeCircleFromPreSaved(foundedCircles);
        }
        break;
      case shapeType.polygon:
        if (foundedPolygons) {
          removePolygonFromPreSaved(foundedPolygons);
        }
        break;
      case shapeType.rectangle:
        if (foundedRectangles) {
          removeRectangleFromPreSaved(foundedRectangles);
        }
        break;
      default:
        break;
    }
  };

  getRef = (el: HTMLElement) => {
    this.ref = el;
  };

  render() {
    const { hidden, areaType, selectedAreasIds, isOpen, isMapVisible } = this.props;

    return (
      <Sidebar
        getRef={this.getRef}
        className={`${styles.container} ${hidden ? '_hidden' : ''} ${
          !isMapVisible ? styles.containerMini : ''
        } map-sidebar`}
        arrowClassName={styles.arrow}
        isOpen={isOpen}
        toggleSidebar={this.toggleSidebar}
      >
        {isMapVisible && <StateSelectWrapper />}
        <ButtonsGroup
          size="md"
          className={styles.buttonsGroup}
          items={this.areaTypes}
          value={areaType}
          onChange={this.handleAreaChange}
        />

        {!hidden && areaType === 'Radius' && <RadiusSwitcher className="mt-3 mb-2" />}
        {isMapVisible && (
          <div data-qa="411" className="mt-3">
            <Tags containerRef={this.ref} />
          </div>
        )}

        {!!selectedAreasIds.length && (
          <Button
            className={`btn-square _filled _gray ${styles.clear}`}
            onClick={this.clearSelectedAreas}
          >
            Clear Selected Areas
          </Button>
        )}

        <MapDrawing />
      </Sidebar>
    );
  }
}

const mapState = (state: AppState) => ({
  states: state.advanced.states,
  areaType: state.map.areaType,
  selectedAreasIds: state.map.selectedAreasIds,
  preSavedCircles: state.map.preSavedCircles,
  preSavedRectangles: state.map.preSavedRectangles,
  preSavedPolygons: state.map.preSavedPolygons,
  dma: state.advanced.sidebarCampaignInfo[CampaignInfoField.dma],
  districts: state.advanced.sidebarCampaignInfo[CampaignInfoField.districts],
  senates: state.advanced.sidebarCampaignInfo[CampaignInfoField.senates],
  houses: state.advanced.sidebarCampaignInfo[CampaignInfoField.houses],
  selectedCountry: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  user: state.auth.userData,
  errorCreating: state.app.errorCreating,
  isOpen: state.map.sidebarIsOpen,
  isMapVisible: state.map.isOpenMapVisible,
  whiteLists: state.advanced.whiteLists,
  blackLists: state.advanced.blackLists,
});

const actions = {
  setDrawerMode: mapActions.setDrawerMode,
  setAreaType: mapActions.setAreaType,
  removeSelectedPolygonsFromPreSaved: mapActions.removeSelectedPolygonsFromPreSaved,
  removeSelectedRectanglesFromPreSaved: mapActions.removeSelectedRectanglesFromPreSaved,
  removeSelectedCirclesFromPreSaved: mapActions.removeSelectedCirclesFromPreSaved,
  emptySelectedAreasIds: mapActions.emptySelectedAreasIds,
  removeCircleFromPreSaved: mapActions.removeCircleFromPreSaved,
  removeRectangleFromPreSaved: mapActions.removeRectangleFromPreSaved,
  removePolygonFromPreSaved: mapActions.removePolygonFromPreSaved,
  setSidebarState: mapActions.setSidebarState,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  resetError: applicationActions.resetError,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
};

export const MapFilter = connect(mapState, actions)(MapFilterComponent);
