import React, { createRef, useEffect, useState, useRef } from 'react';
import { Icon } from 'factor';
import Overlay from 'components/Overlay';
import { onLogOut } from 'api/Instance';
import { isLocalhost } from 'utils/helpers';
import styles from './styles.module.scss';

type TProps = {
  onClose: (creativeIds?: string) => void;
  getUrl: () => string;
};

const CreateCreativesPopup = (props: TProps) => {
  const { onClose, getUrl } = props;
  const [loaded, setLoaded] = useState(false);
  const [creativeLoading, setCreativeLoading] = useState(false);
  const [allowMount, setAllowMount] = useState(true);
  const iframeRef = createRef<HTMLIFrameElement>();
  const iframeRefWithEventHandler = useRef<HTMLIFrameElement | null>(null);

  const receiveMessage = (event: any) => {
    const shouldHandleMessage =
      event.data && event.data.startsWith && event.data.startsWith('{"type":"CREATIVES');
    if (shouldHandleMessage) {
      const data: { type: string; creativeIds?: string } = JSON.parse(event.data);
      if (data.type === 'CREATIVES_CLOSE') {
        setAllowMount(false);
        onClose();
      } else if (data.creativeIds?.length) {
        onClose(data.creativeIds);
      } else if (data.type === 'CREATIVES_LOADING') {
        setCreativeLoading(true);
      } else if (data.type === 'CREATIVES_LOADED') {
        setCreativeLoading(false);
      } else if (data.type === 'CREATIVES_CLOSE_FOR_AUTH') {
        setAllowMount(false);
        onLogOut();
      }
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      if (!isLocalhost()) {
        const loginHash = /^\/?#\/login\/?$/;

        if (loginHash.test(iframeRef.current?.contentWindow?.location.hash || '')) {
          setAllowMount(false);
          onLogOut();
        }
      }
    };

    setTimeout(() => {
      const origin = window.location.origin || 'http://localhost:8000';
      if (iframeRef.current && iframeRef.current.contentWindow && loaded) {
        window.addEventListener('message', receiveMessage);
        if (!isLocalhost()) {
          iframeRefWithEventHandler.current = iframeRef.current;
          iframeRef.current.contentWindow.addEventListener('hashchange', handleHashChange);
        }
        iframeRef.current.contentWindow.postMessage('OPEN_FROM_CAMPAIGNS', origin);
      }
    }, 1000);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
      if (!isLocalhost() && iframeRefWithEventHandler.current) {
        iframeRefWithEventHandler.current?.contentWindow?.removeEventListener(
          'hashchange',
          handleHashChange,
        );
      }
      window.removeEventListener('message', receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const handleOverlayClose = () => {
    setAllowMount(false);
    onClose();
  };

  if (!allowMount) {
    return null;
  }

  return (
    <Overlay onClose={handleOverlayClose} title="Add Creatives To Campaign">
      <div className={styles.container}>
        {/* We don't want circle loader of popup so removed the loading={creativeLoading} prop from <Overlay> component */}
        <span className="d-none">{creativeLoading}</span>
        {!loaded && (
          <div className="align-items-center d-flex flex-column h-100 justify-content-center">
            <div className="generate-progress">
              <div className="generate-progress__image">
                <Icon name="GenerateProgress" />
              </div>
              <div className="generate-progress__bar _animated">
                <div className="generate-progress__bar-stripped" />
              </div>
              <div className="generate-progress__value">
                Please wait
                <span className="generate-progress__value-dotted" />
              </div>
            </div>
          </div>
        )}
        <iframe
          ref={iframeRef}
          src={getUrl()}
          frameBorder="0"
          title="new campaign"
          onLoad={() => setLoaded(true)}
          style={{
            display: loaded ? 'revert' : 'none',
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            margin: 0,
            padding: 0,
            zIndex: 10,
          }}
        />
      </div>
    </Overlay>
  );
};

export default CreateCreativesPopup;
