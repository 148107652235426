import { IoStatusType } from 'models/IO';

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_LIST_SORTING = [{ id: 'ioId', desc: true }];

export const IO_DISTRIBUTION_METHOD = {
  1: 'Manual',
  2: 'Auto',
};

export const DEFAULT_TIME_ZONE = { label: 'US/Eastern', value: 'US/Eastern', id: 29 };

export const IO_BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
};

export const IO_STATUS_ID = {
  ACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
};

export const MappingStatusIdToStatus: { [key: number]: IoStatusType } = {
  1: 'active',
  2: 'expired',
  3: 'deleted',
};

export const DEFAULT_COL_VISIBILITY = {
  campaignsCount: true,
  ioStatusId: true,
  ioStartTime: true,
  ioEndTime: true,
  ioTotalBudget: true,
  ioPacingPercentage: true,
  spent: true,
  impressions: true,
  clicks: true,
  totalAttributedConversion: true,
};
