import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ConversionType, Conversion } from 'models/Conversion';
import { createAction } from '../../utils/actions';
import { conversionConstants } from './constants';

type Data = {
  key: string;
  value: number[];
};

export type SelectConversionField = {
  selectConversionField: (data: Data) => void;
};

export type ResetState = {
  resetState: () => void;
};

export type SetConversionType = {
  setConversionType: (ConversionType: ConversionType | null) => void;
};

export type SetSelectedConversions = {
  setSelectedConversions: (ids: number[]) => void;
};

export type SetConversionTableSelection = {
  setConversionTableSelection: (conversions: Conversion[]) => void;
};

export const conversionActions = {
  selectConversionField(data: Data) {
    return createAction<Data>(conversionConstants.SELECT_CONVERSION_FIELD, data);
  },

  setSelectedConversions(ids: number[]) {
    return createAction<Data>(conversionConstants.SELECT_CONVERSION_FIELD, {
      key: CampaignInfoField.conversions,
      value: ids,
    });
  },

  resetState() {
    return createAction(conversionConstants.RESET_CONVERSION_STORE);
  },

  setConversionType(conversionType: ConversionType | null) {
    return createAction(conversionConstants.SET_CONVERSION_TYPE, conversionType);
  },

  setConversionTableSelection(selection: Conversion[]) {
    return createAction(conversionConstants.SET_CONVERSION_TABLE_SELECTION, selection);
  },
};
