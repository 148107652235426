export const CREATIVE_ID_FROM_NAME = {
  Image: 11,
  Contextual: 12,
  HTML: 13,
  Video: 14,
  Native: 15,
  'Ticker Banner': 16,
  Audio: 17,
  GOTV: 18,
};

export const CREATIVE_NAME_FROM_ID = {
  11: 'Image',
  12: 'Contextual',
  13: 'HTML',
  14: 'Video',
  15: 'Native',
  16: 'Ticker Banner',
  17: 'Audio',
  18: 'GOTV',
};
