import { DialogProps } from '@applift/factor';
import { CampaignDialog } from './component';

export const ACTION = {
  VIEW_CAMPAIGN: 'VIEW_CAMPAIGN',
};

export type ActionNameType = keyof typeof ACTION;

type ExtractProps<TComponentOrTProps> = TComponentOrTProps extends React.ComponentType<infer TProps>
  ? TProps
  : TComponentOrTProps;

export interface ActionDetails {
  dialogProps: Partial<DialogProps>;
  component: (props: ExtractProps<any>) => JSX.Element;
}

export type Action = {
  [key in ActionNameType]: ActionDetails;
};

export const ActionMapper: Action = {
  VIEW_CAMPAIGN: {
    dialogProps: {
      maxWidth: 'xxl',
      fullWidth: true,
      PaperProps: { sx: { height: 100 } },
    },
    component: CampaignDialog,
  },
};
