import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonProps,
} from '@applift/factor';

export interface DiscardUnsaveChangesDialogPropTypes {
  isOpen: boolean;
  setOpenDialog: (arg: boolean) => void;
  onDiscard: ButtonProps['onClick'];
}

export const DiscardUnsaveChangesDialog = (props: DiscardUnsaveChangesDialogPropTypes) => {
  const { isOpen, setOpenDialog, onDiscard } = props;
  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogTitle id="conversion-selection-table-dialog-title">
        Discard Unsaved Changes
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        You have unsaved changes, are you sure you want to discard them?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={onDiscard}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};
