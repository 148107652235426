import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';

import { User } from 'models/User';
import { AppState } from 'models/Store';
import { ReactComponent as AllStatusIcon } from './statusIcons/all.svg';
import { ReactComponent as DeletedStatusIcon } from './statusIcons/deleted.svg';
import { ReactComponent as FailedStatusIcon } from './statusIcons/failed.svg';
import { ReactComponent as PausedStatusIcon } from './statusIcons/paused.svg';
import { ReactComponent as PendingStatusIcon } from './statusIcons/pending.svg';
import { ReactComponent as RejectedStatusIcon } from './statusIcons/rejected.svg';
import { ReactComponent as RunningStatusIcon } from './statusIcons/running.svg';
import { API } from '../../../../../../../api';

import styles from './CreativeStatusesCount.module.scss';

export type TCreativeStatus = {
  creativeCount: number;
  status: string;
  displayName: string;
  id: number | string;
  value?: string;
};

const statusIdToIconMap: Record<string | number, React.ReactNode> = {
  all: <AllStatusIcon />,
  0: <AllStatusIcon />,
  1: <PendingStatusIcon />,
  2: <RunningStatusIcon />,
  3: <PausedStatusIcon />,
  4: <DeletedStatusIcon />,
  5: <RejectedStatusIcon />,
  7: <FailedStatusIcon />,
};

type TProps = {
  authorizedUser: User;
  status: TCreativeStatus;
  onChangeStatus: (creativeStatus: TCreativeStatus) => void;
  creativeStatusFilter?: TCreativeStatus;
};

type TState = {
  allStatuses: TCreativeStatus[];
};

class CreativeStatusesCount extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      allStatuses: [],
    };
  }

  async componentDidMount() {
    this.loadStatuses();
  }

  componentDidUpdate(oldProps: TProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!oldProps.authorizedUser && this.props.authorizedUser) {
      this.loadStatuses();
    }
  }

  loadStatuses = async () => {
    const { authorizedUser } = this.props;
    if (!authorizedUser) {
      return;
    }

    const allStatuses: TCreativeStatus[] = await API.Creatives.FetchCreativeStatusesCount();

    const all = allStatuses.find((s: TCreativeStatus) => s.status === 'all');
    const availableStatuses = [];
    if (all) {
      availableStatuses.push(all);
    }

    allStatuses.forEach((s) => {
      // running, paused, pending, failed
      if (s.status !== 'all' && [1, 2, 3].find((id) => s.id === id)) {
        availableStatuses.push(s);
      }
    });

    this.setState({
      allStatuses: availableStatuses,
    });
  };

  render() {
    const { onChangeStatus, status } = this.props;
    const { allStatuses } = this.state;

    const statusOptions = allStatuses.map((creativeStatus: TCreativeStatus) => ({
      ...creativeStatus,
      label: `${creativeStatus.displayName}`,
      value: creativeStatus.id || 'all',
      reactLabel: (
        <div className={styles.creativeStatusBlock}>
          {statusIdToIconMap[creativeStatus.id || 'all']}
          <span>{creativeStatus.displayName}</span>
        </div>
      ),
    }));

    const selectedStatus = status && {
      id: status.id,
      label: `${status.displayName}`,
      value: status.id || 'all',
      reactLabel: (
        <div className={styles.creativeStatusBlock}>
          {statusIdToIconMap[status.id || 'all']}
          <span>{status.displayName}</span>
        </div>
      ),
    };

    return (
      <div className={styles.wrapper}>
        <Select
          className="select-creative-status"
          onChange={onChangeStatus}
          value={selectedStatus}
          options={statusOptions}
          placeholder="Status"
          label="Status"
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  authorizedUser: state.auth.userData,
});

export default connect(mapStateToProps)(CreativeStatusesCount);
