import React, { useEffect, useState } from 'react';
import { CollapsibleBlock, Icon, ButtonCircle, Tumbler, Chip, Tooltip } from 'factor';
import { useStore } from 'react-redux';
import { Tooltip as AppliftTootip, TypoTooltip } from '@applift/factor';

import { AppState } from 'models/Store';
import { TypeNames, TYPES_IDS } from 'pages/MainPage/CampaignPage/LocationBlock/Map/MapData';
import { LatLngBounds } from 'models/Google';
import { IStateOption } from 'models/Location';
import { mapActions } from 'store/map/actions';
import { Option } from 'models/Option';
import { getBoundsFromLatLng } from 'utils/figures';
import { pluralize } from 'utils/pluralize';
import { useScrollbarWidth } from 'utils/hooks/useScrollBarWidth';

import styles from './index.module.scss';

const singularForm: { [key: string]: string } = {
  senates: 'state senate',
  cities: 'city',
  counties: 'county',
  dma: 'DMA',
  houses: 'state house',
  districts: 'congressional district',
  circles: 'circle',
  polygons: 'polygon',
  rectangles: 'rectangle',
  locations: 'location',
  addresses: 'address',
  zipcodes: 'zip code',
  postalcodes: 'postal code',
};

export const Accordion = ({
  iconName,
  title,
  items,
  handleRemove,
  handleRemoveSection,
  invalidDataCount,
  downloadInvalidData,
  dismissInvalidData,
  handleTumbler,
  whiteList,
  blackList,
  isString = false,
  isReadOnly = false,
  errorMsg,
  category,
  scrollBarWidth,
  selectedStates,
  showDuplicateTargetingError,
  blackListedStateIds,
  isFile,
  hideToggle,
}: {
  iconName: string;
  title: string;
  items: Option<number | string>[];
  handleRemove: (item: Option) => void;
  handleRemoveSection: () => void;
  invalidDataCount?: number;
  downloadInvalidData?: () => void;
  dismissInvalidData?: () => void;
  handleTumbler?: (id: number | string, isIncluded: boolean) => void;
  whiteList?: Set<number>;
  blackList?: Set<number>;
  isString?: boolean;
  isReadOnly?: boolean;
  errorMsg?: string;
  category: string;
  scrollBarWidth?: string | null;
  selectedStates?: IStateOption[];
  showDuplicateTargetingError?: boolean;
  blackListedStateIds?: Set<number>;
  isFile?: boolean;
  hideToggle?: boolean;
}) => {
  const width = useScrollbarWidth();
  const paddingRightOfElement = scrollBarWidth || width || 20;

  const store = useStore();

  const [isCollapseOpen, setCollapseOpen] = useState(false);

  const selectedStateIds = selectedStates?.map((item) => item.value) || [];

  const highlightSelectedLocation = async (item: Option<number | string>) => {
    const { emptySelectedAreasIds, pushSelectedAreaId, setMapBounds } = mapActions;

    let bounds = new google.maps.LatLngBounds();
    if (['circles', 'polygons', 'rectangles', 'locations', 'addresses'].includes(category)) {
      store.dispatch(emptySelectedAreasIds());
      store.dispatch(pushSelectedAreaId(item.value.toString()));
      bounds = item.bounds as LatLngBounds;
    }
    if (Object.keys(TYPES_IDS).includes(category)) {
      const state: AppState = store.getState();
      if (state.map.geoJSONData[TYPES_IDS[category as TypeNames]][item.label]) {
        bounds = getBoundsFromLatLng(
          state.map.geoJSONData[TYPES_IDS[category as TypeNames]][item.label].features[0].geometry
            .coordinates,
        );
      }
    }
    if (bounds) {
      store.dispatch(setMapBounds(bounds));
    }
  };

  const renderListItem = (item: Option<number | string>) => {
    const { value, label, editableLabel } = item;
    let isIncluded: boolean | undefined = false;
    if (isString) {
      isIncluded = handleTumbler ? whiteList?.has(value as any) || item.isInclude : true;
    } else {
      isIncluded = handleTumbler ? whiteList?.has(Number(value)) || item.isInclude : true;
    }
    const showError =
      showDuplicateTargetingError &&
      isIncluded &&
      category !== 'states' &&
      (selectedStateIds.includes(item.parentId as number) || item.isDuplicateTargeting) &&
      !blackListedStateIds?.has(item.parentId ?? 0);
    return (
      <div
        key={`${label}-${value}`}
        className={`${styles.listItem} ${isIncluded ? styles.included : styles.excluded} ${
          showError ? styles.errorItem : ''
        }`}
        style={{ paddingRight: paddingRightOfElement ? `${paddingRightOfElement}px` : '8px' }}
      >
        {showError && (
          <Tooltip
            label={`This ${singularForm[category]} is already covered in state targeting`}
            auto={false}
            labelMaxWidth={287}
            position="left"
            className={styles.errorTooltip}
            portal
          >
            <Icon name="ErrorTriangle" />
          </Tooltip>
        )}
        <span
          className={styles.locationName}
          onClickCapture={() => highlightSelectedLocation(item)}
        >
          {editableLabel || label}
        </span>
        <div className={styles.listItemButtons}>
          {handleTumbler && !hideToggle ? (
            <Tumbler
              name={label}
              id={value}
              onOff={false}
              on={isIncluded}
              onChange={() => handleTumbler(value, !isIncluded)}
              className={styles.listItemButtonsToggle}
            />
          ) : null}
          <ButtonCircle
            iconName="Close"
            className={styles.listItemButtonsRemove}
            onClick={() => handleRemove(item)}
          />
        </div>
      </div>
    );
  };

  const renderListItemReadOnly = (item: Option<number | string>) => {
    const { value, label } = item;
    let isIncluded: boolean | undefined = false;
    if (isString) {
      isIncluded = handleTumbler ? whiteList?.has(value as any) || item.isInclude : true;
    } else {
      isIncluded = handleTumbler ? whiteList?.has(Number(value)) || item.isInclude : true;
    }
    const showError =
      showDuplicateTargetingError &&
      isIncluded &&
      category !== 'states' &&
      (selectedStateIds.includes(item.parentId as number) || item.isDuplicateTargeting) &&
      !blackListedStateIds?.has(item.parentId ?? 0);
    const includeString = isIncluded ? 'Allowed' : 'Blocked';
    return (
      <div
        key={`${label}-${value}`}
        className={`${styles.listItem} ${!isIncluded ? styles.excludedReadOnly : ''} ${
          showError ? styles.errorItem : ''
        }`}
        style={{ paddingRight: paddingRightOfElement ? `${paddingRightOfElement}px` : '8px' }}
      >
        {showError && (
          <Tooltip
            label={`This ${singularForm[category]} is already covered in state targeting`}
            auto={false}
            labelMaxWidth={287}
            position="left"
            className={styles.errorTooltip}
            portal
          >
            <Icon name="ErrorTriangle" />
          </Tooltip>
        )}
        <span className={styles.locationName} onClick={() => highlightSelectedLocation(item)}>
          {label}
        </span>
        <Chip
          key={item.value}
          item={{ label: includeString, value: includeString }}
          className={`${styles.chip} ${
            isIncluded ? styles.includedReadOnly : styles.excludedReadOnly
          }`}
          readonly
        />
      </div>
    );
  };

  useEffect(() => {
    const root = document.querySelector(':root');
    (root as any).style.setProperty('--scrollBarWidth', `${paddingRightOfElement}px`);
  }, [paddingRightOfElement]);
  const [showInvaludLocations, setShowInvalidLications] = useState(false);

  return (
    <CollapsibleBlock
      className={styles.accordion}
      header={{
        title: (
          <div className={styles.accordionHeader}>
            {/* eslint-disable-next-line   no-extra-boolean-cast */}
            {Boolean(errorMsg) ? (
              <Tooltip postion="top-right" label={errorMsg} portal className="custom-tooltip">
                <Icon name="ErrorTriangle" className="mr-2" />
              </Tooltip>
            ) : (
              <Icon name={iconName} className="mr-2" />
            )}
            <TypoTooltip
              placement="top"
              title={isFile ? title : ''}
              arrow
              className="title"
              sx={{ display: 'block' }}
            >
              {title}
            </TypoTooltip>
            <div>
              &nbsp;(
              {items.length < 1000
                ? items.length
                : /* @ts-ignore */
                  `${parseFloat(items.length / 1000).toFixed(2)}k`}
              )
            </div>
            {!!invalidDataCount && <Icon name="Info" className="ml-2 mr-2 info-icon" />}
            {!isReadOnly ? (
              <AppliftTootip title="Remove location" placement="top" arrow>
                <div className="ml-auto">
                  <ButtonCircle
                    iconName="Delete"
                    onClick={handleRemoveSection}
                    className={styles.removeAccordion}
                  />
                </div>
              </AppliftTootip>
            ) : null}
          </div>
        ),
      }}
      collapsible
      isCollapseOpen={isCollapseOpen}
      onToggle={(isOpen: boolean) => {
        setCollapseOpen(isOpen);
        setShowInvalidLications(isOpen);
      }}
    >
      {showInvaludLocations && !!invalidDataCount && !!dismissInvalidData && downloadInvalidData && (
        <div className={styles.warning}>
          <span className={styles.text}>
            {invalidDataCount} {pluralize('Row', invalidDataCount)} failed. Please fix failed{' '}
            {pluralize('row', invalidDataCount)} and re-upload.
          </span>
          <div onClick={downloadInvalidData} className={styles.link}>
            <Icon name="Download" className="mr-2" />
            <span>Download failed {pluralize('row', invalidDataCount)}</span>
          </div>
          <ButtonCircle
            iconName="Close"
            className={styles.removeButton}
            onClick={() => setShowInvalidLications(false)}
          />
        </div>
      )}
      <div className={styles.accordionContent}>
        {items.map((item) => (isReadOnly ? renderListItemReadOnly(item) : renderListItem(item)))}
      </div>
    </CollapsibleBlock>
  );
};
