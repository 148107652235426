/* global google */
import React from 'react';
import { connect } from 'react-redux';
import { ButtonsGroup } from 'factor';

import { AppState } from 'models/Store';
import { mapActions, SetDrawerMode, SetAreaType } from 'store/map/actions';
import { AREA_TYPES } from '../Map/MapFilter';
import { RadiusSwitcher } from '../Map/RadiusSwitcher';
import styles from '../Map/MapFilter/Index.module.scss';

type Props = SetDrawerMode &
  SetAreaType & {
    areaType: string | null;
  };

const DrawingModeSwitcherComponent = (props: Props) => {
  const { areaType, setDrawerMode, setAreaType } = props;

  const getAreaTypes = () =>
    AREA_TYPES.map((type) => ({
      value: type.value,
      title: (
        <div data-qa="404" className={styles.buttonItemLabel}>
          <i data-qa="405" className={styles.buttonItemLabelIcon}>
            {type.icon}
          </i>
          <span data-qa="406" className={styles.labelTitle}>
            {type.label}
          </span>
        </div>
      ),
    }));

  const handleAreaChange = (area: string) => {
    setAreaType(area);

    if (area === 'Polygon') {
      setDrawerMode(google.maps.drawing.OverlayType.POLYGON);
    } else if (area === 'Rectangle') {
      setDrawerMode(google.maps.drawing.OverlayType.RECTANGLE);
    } else {
      setDrawerMode(google.maps.drawing.OverlayType.CIRCLE);
    }
  };

  return (
    <div className="d-flex w-100 mb-3 justify-content-center flex-wrap">
      <ButtonsGroup
        size="md"
        className="mb-2"
        items={getAreaTypes()}
        value={areaType}
        onChange={handleAreaChange}
      />
      {areaType === 'Radius' && <RadiusSwitcher />}
    </div>
  );
};

const mapState = (state: AppState) => ({
  areaType: state.map.areaType,
});

const mapAction = {
  setDrawerMode: mapActions.setDrawerMode,
  setAreaType: mapActions.setAreaType,
};

export const DrawingModeSwitcher = connect(mapState, mapAction)(DrawingModeSwitcherComponent);
