import React, { Fragment } from 'react';
import { Icon, Tooltip } from 'factor';
import { Creative } from 'models/Creative';
import WithErrorPlaceholderImage from '../../withErrorPlacehoderImage/WithErrorPlaceholderImage';
import creativeTypeViewByCreativeTypeId, {
  AUDIO_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  FAILED,
} from '../../constants';

import styles from './CreativeCard.module.scss';

type TCreativeCardProps = {
  creative: Creative;
  selected: boolean;
  selectCreative: (creative: Creative, isSelected: boolean, selectedCreativeInstance: any) => void;
};

type TCreativeCardState = {
  isSelected: boolean;
};

export default class CreativeCard extends React.Component<TCreativeCardProps, TCreativeCardState> {
  renderImage = () => {
    const { creative } = this.props;
    const { creativeCardSource, status } = creative;

    if (status === FAILED || !creativeCardSource) {
      return <Icon name="NoImage" />;
    }

    return <WithErrorPlaceholderImage src={creativeCardSource} />;
  };

  selectCard = (event: any) => {
    if (event.target.className === 'text-link') {
      return;
    }

    const { creative, selectCreative, selected } = this.props;

    selectCreative(creative, !selected, this);
  };

  getClickUrlAsLabel = () => {
    const { creative } = this.props;

    if (!creative.clickUrl) {
      return '—';
    }

    return (
      <Tooltip label={creative.clickUrl} auto={false} labelMaxWidth={287}>
        <div className={styles.width140}>
          <a
            href={creative.clickUrl}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.textOverflow}
          >
            {creative.clickUrl}
          </a>
        </div>
      </Tooltip>
    );
  };

  getPixelUrlsAsLabel = () => {
    const { creative } = this.props;
    const { pixelUrls } = creative;

    if (!pixelUrls || !pixelUrls.length) {
      return '—';
    }

    const tooltipLabel = creative.pixelUrls ? creative.pixelUrls.join(',') : '';

    if (!tooltipLabel) {
      return '—';
    }

    const content = pixelUrls.map((link: string, index: number) => (
      <Fragment key={link}>
        {index > 0 ? ', ' : ''}
        <a href={link} className={styles.textOverflow} rel="noopener noreferrer" target="_blank">
          {link}
        </a>
      </Fragment>
    ));

    return (
      <Tooltip label={tooltipLabel} auto={false} labelMaxWidth={287}>
        <div className={styles.width140}>{content || '—'}</div>
      </Tooltip>
    );
  };

  renderSize = () => {
    const { creative } = this.props;
    const { creativeTypeId, duration, width, height } = creative;
    switch (creativeTypeId) {
      case VIDEO_CREATIVE_ID:
      case AUDIO_CREATIVE_ID:
        return `${duration}s`;
      case NATIVE_CREATIVE_ID:
        if (Boolean(width) && Boolean(height)) {
          return `${width}x${height}`;
        }
        if (typeof duration === 'number') {
          return `${duration}s`;
        }
        return '—';
      default:
        return `${width}x${height}`;
    }
  };

  getPixelUrlsAsString = () => {
    const { creative } = this.props;
    return creative.pixelUrls ? creative.pixelUrls.join(', ') : '';
  };

  render() {
    const { creative, selected } = this.props;
    const creativeView = creativeTypeViewByCreativeTypeId[creative.creativeTypeId];

    return (
      <div
        className={`${styles.container} _tile  ${selected ? styles.selected : ''}`}
        onClick={this.selectCard}
      >
        <div className={styles.info}>
          <div className={styles.image} onClick={(e) => e.stopPropagation()}>
            {this.renderImage()}
          </div>
          <div className={`${styles.infoRow}`}>
            <i className={styles.icon}>
              <Icon name={creativeView.iconName} />
            </i>
            <div className={styles.title}>
              <h4>
                <Tooltip label={creative.name} auto={false} position="top" labelMaxWidth={287}>
                  <div className={styles.textOverflow}>{creative.name}</div>
                </Tooltip>
              </h4>
            </div>
            <div className={styles.uploaded}>
              <span>Creative ID: </span>
              {creative.id}
            </div>
            <div className={styles.uploaded}>
              <span>Uploaded on: </span>
              {creative.created}
            </div>
            <div className={styles.size}>
              <span>Status: </span>
              {creative.status}
            </div>
            <div className={styles.size}>
              <span>Size: </span>
              {this.renderSize()}
            </div>
            <div className={`${styles.status} _${creative.status}`}>{creative.status}</div>
            <div className={styles.url}>
              <span className={styles.flexShrink}>Click URL: </span>
              {this.getClickUrlAsLabel()}
            </div>
            <div className={styles.url}>
              <span className={styles.flexShrink}>Pixel URL: </span>
              {this.getPixelUrlsAsLabel()}
            </div>
            <div className={styles.size}>
              <span>Part Of: </span>
              {creative.campaignCount}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
