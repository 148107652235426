import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Icon } from 'factor';

import { API } from 'api';
import { Logger } from 'utils/logger';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { AudienceType, audienceIDMap } from 'models/Audience';
import { audienceActions, SetAudienceField, SelectAudienceField } from 'store/audience/actions';

import styles from '../styles.module.scss';

interface Props extends SetAudienceField, SelectAudienceField {
  audienceTypes: Option<number>[];
  selectedAudienceTypes: Option<number>[];
  selectedAudiences: any;
}

const SelectAudienceTypesWrapperComponent = (props: Props) => {
  const {
    audienceTypes,
    selectedAudienceTypes,
    setAudienceField,
    selectAudienceField,
    selectedAudiences,
  } = props;
  const [list, setList] = useState<Option[]>(audienceTypes || []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await API.Audience.AudienceTypes();
        const filteredForAudienceMap = res.filter((item: AudienceType) => !!audienceIDMap[item.id]);
        const mappedList = filteredForAudienceMap.map((item: AudienceType) => ({
          label: item.name.replace(' Audience', ''),
          value: item.id,
          reactLabel: (
            <div className={styles.option}>
              <div className={styles.optionImage}>
                <Icon name={audienceIDMap[item.id] ?? ''} />
              </div>
              <span className={styles.optionTitle}>{item.name}</span>
            </div>
          ),
        }));
        setAudienceField({
          key: 'audienceTypes',
          value: mappedList,
        });
        setList(mappedList);
      } catch (e) {
        Logger.log('[Fetch AudienceTypes Error]', e);
      }
    };

    if (!audienceTypes.length) {
      getData();
    }
  }, [audienceTypes.length, setAudienceField]);

  const { search } = useLocation();

  const { audIds, audTypeIds } = queryString.parse(search);

  useEffect(() => {
    if (audIds && audTypeIds && list.length) {
      selectAudienceField({
        key: CampaignInfoField.audienceTypes,
        value: list.filter((item) => `${audTypeIds}`.split(',').includes(`${item.value}`)),
      });
    }
  }, [list, audIds, audTypeIds, selectAudienceField]);

  const handleClick =
    (isSelectedType: Option<number> | undefined, audienceType: Option<number>) => () => {
      const items = isSelectedType
        ? selectedAudienceTypes.filter((type: Option<number>) => type.value !== audienceType.value)
        : [...selectedAudienceTypes, audienceType];

      selectAudienceField({
        key: CampaignInfoField.audienceTypes,
        value: items,
      });

      if (isSelectedType) {
        selectAudienceField({
          key: CampaignInfoField.audiences,
          value: selectedAudiences.filter(
            (audience: any) => audience.audienceTypeId !== audienceType.value,
          ),
        });
      }
    };

  return (
    <>
      {list.map((audienceType: Option<number>) => {
        const isSelectedType = selectedAudienceTypes.find(
          (type: Option<number>) => type.value === audienceType.value,
        );
        return (
          <div
            key={audienceType.value}
            tabIndex={0}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                handleClick(isSelectedType, audienceType)();
                e.stopPropagation();
              }
            }}
            onClick={handleClick(isSelectedType, audienceType)}
            className={`${styles.audienceTypeBox} ${
              isSelectedType ? styles.audienceTypeBoxSelected : ''
            }`}
          >
            <Icon name={audienceIDMap[audienceType.value]} />
            {audienceType.label}
          </div>
        );
      })}
    </>
  );
};

const mapState = (state: AppState) => {
  return {
    audienceTypes: state.audience.audienceTypes,
    selectedAudienceTypes: state.audience.sidebarCampaignInfo[CampaignInfoField.audienceTypes],
    selectedAudiences: state.audience.sidebarCampaignInfo[CampaignInfoField.audiences],
  };
};

const mapAction = {
  setAudienceField: audienceActions.setAudienceField,
  selectAudienceField: audienceActions.selectAudienceField,
};

export const SelectAudienceTypesWrapper = connect(
  mapState,
  mapAction,
)(SelectAudienceTypesWrapperComponent);
