import { put, select, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { History } from 'history';

import { API } from 'api';
import { createAction } from 'utils/actions';
import { scrollToElement } from 'utils/scroll';
import { AppState } from 'models/Store';
import { Action } from 'models/Action';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { conversionConstants } from 'store/conversion/constants';
import { DataDogLogger } from 'services/DataDog';
import { toDataDogCampaign, toDataDogCampaignEdit } from 'services/DataDog/helpers';

import { applicationConstants } from '../app/constants';
import { creativesConstants } from '../creatives/constants';
import { advanceConstants } from '../advance/constants';
import { smsConstants } from '../sms/constants';
import { advertiserConstants } from '../advertiser/constants';
import { technologyConstants } from '../technology/constants';
import { bidConstants } from '../bid/constants';
import {
  checkSaveDraftValidation,
  checkValidation,
  collectRequest,
  getChangedParameters,
} from '../app/helpers';
import { applicationActions } from '../app/actions';
import { demographicConstants } from '../demographic/constants';
import { advanceActions } from '../advance/actions';
import { smsActions } from '../sms/actions';
import { mapConstants } from '../map/constants';
import { inventoryConstants } from '../inventory/constants';
import { schedulingConstants } from '../scheduling/constants';
import { toastConstants } from '../toast/constants';
import { advancedTargetingConstants } from '../advancedTargeting/constants';
import { audienceConstants } from '../audience/constants';
import { locationConstants } from '../location/constants';
import { reachConstants } from '../reach/constants';
import { createAdvertiserSaga } from './advertiser';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export function* resetStoreSaga() {
  yield put(createAction(applicationConstants.RESET_APP_STORE));
  yield put(createAction(creativesConstants.RESET_CREATIVE_STORE));
  yield put(createAction(advanceConstants.RESET_ADVANCE_STORE));
  yield put(createAction(smsConstants.RESET_SMS_STORE));
  yield put(createAction(advertiserConstants.RESET));
  yield put(createAction(technologyConstants.RESET_TECHNOLOGY_STORE));
  yield put(createAction(bidConstants.RESET_BID_STORE));
  yield put(createAction(demographicConstants.RESET_DEMOGRAPHIC_STORE));
  yield put(createAction(inventoryConstants.RESET_INVENTORY_STORE));
  yield put(createAction(schedulingConstants.RESET_SCHEDULING_STORE));
  yield put(createAction(advancedTargetingConstants.RESET_ADVANCED_TARGETING_STORE));
  yield put(createAction(audienceConstants.RESET_AUDIENCE_STORE));
  yield put(createAction(mapConstants.RESET_MAP_STORE));
  yield put(createAction(locationConstants.RESET_LOCATION_STORE));
  yield put(createAction(conversionConstants.RESET_CONVERSION_STORE));
  yield put(createAction(reachConstants.RESET_REACH_STORE));
}

export function* resetStoreSagaForCampaignSwitch() {
  yield put(createAction(applicationConstants.RESET_CLEARING_CAMPAIGN_DATA, true));
  yield call(delay, 1);
  yield put(createAction(applicationConstants.RESET_APP_STORE));
  yield put(createAction(creativesConstants.RESET_CREATIVE_STORE));
  yield put(createAction(advanceConstants.RESET_ADVANCE_STORE));
  yield put(createAction(smsConstants.RESET_SMS_STORE));
  yield put(createAction(advertiserConstants.RESET));
  yield put(createAction(technologyConstants.RESET_TECHNOLOGY_STORE));
  yield put(createAction(bidConstants.RESET_BID_STORE));
  yield put(createAction(demographicConstants.RESET_DEMOGRAPHIC_STORE));
  yield put(createAction(inventoryConstants.RESET_INVENTORY_STORE));
  yield put(createAction(schedulingConstants.RESET_SCHEDULING_STORE));
  yield put(createAction(advancedTargetingConstants.RESET_ADVANCED_TARGETING_STORE));
  yield put(createAction(audienceConstants.RESET_AUDIENCE_STORE));
  yield put(createAction(mapConstants.RESET_MAP_STORE));
  yield put(createAction(locationConstants.RESET_LOCATION_STORE));
  yield put(createAction(conversionConstants.RESET_CONVERSION_STORE));
  yield put(createAction(reachConstants.RESET_REACH_STORE));
  yield put(createAction(advanceConstants.RESET_SIDEBAR_CAMPAIGN_INFO));
  yield call(delay, 1);
  yield put(createAction(applicationConstants.RESET_CLEARING_CAMPAIGN_DATA, false));
}

export function* createCampaignSaga(action: Action<History>): Generator<unknown, void, any> {
  const state: AppState = yield select();

  if (!checkValidation(state)) {
    yield scrollToElement();
    if (state.app.editableCampaign) {
      DataDogLogger.App.editOfCampaignFailed(toDataDogCampaignEdit(state, true));
    } else {
      DataDogLogger.App.creationOfCampaignFailed(toDataDogCampaign(state));
    }
  } else {
    if (action.payload) {
      yield action.payload.push({
        pathname: '/campaign-saving',
        state: { previous: window.location.hash },
      });
    }
    const { sidebarCampaignInfo } = state.advanced;

    try {
      let logNonExceptionFailure = true;
      if (
        sidebarCampaignInfo.timezone &&
        sidebarCampaignInfo.country &&
        state.auth.userData &&
        state.creatives.selectedCreativeType
      ) {
        const request = collectRequest(state);
        let response;
        if (request) {
          logNonExceptionFailure = false;
          if (state.app.editableCampaign) {
            response = yield API.Campaign.EditCampaign(
              getChangedParameters(state.app.editableCampaign, request),
              state.app.editableCampaign.id,
            );
            DataDogLogger.App.editOfCampaignSucceeded(toDataDogCampaignEdit(state, true, response));
            yield put(createAction(applicationConstants.SUCCESS_EDITING, response.responseObject));
          } else {
            response = yield API.Campaign.CreateCampaignNew(request);
            DataDogLogger.App.creationOfCampaignSucceeded(toDataDogCampaign(state, response));
            yield put(createAction(applicationConstants.SUCCESS_CREATING, response.responseObject));
          }

          yield put(createAction(applicationConstants.RESET_APP_STORE));
          yield put(createAction(creativesConstants.RESET_CREATIVE_STORE));
          yield put(createAction(advanceConstants.RESET_ADVANCE_STORE));
          yield put(createAction(smsConstants.RESET_SMS_STORE));
          yield put(createAction(advertiserConstants.RESET));
          yield put(createAction(technologyConstants.RESET_TECHNOLOGY_STORE));
          yield put(createAction(bidConstants.RESET_BID_STORE));
          yield put(createAction(demographicConstants.RESET_DEMOGRAPHIC_STORE));
          yield put(createAction(inventoryConstants.RESET_INVENTORY_STORE));
          yield put(createAction(schedulingConstants.RESET_SCHEDULING_STORE));
          yield put(createAction(advancedTargetingConstants.RESET_ADVANCED_TARGETING_STORE));
          yield put(createAction(audienceConstants.RESET_AUDIENCE_STORE));
          yield put(createAction(mapConstants.RESET_MAP_STORE));
          yield put(createAction(locationConstants.RESET_LOCATION_STORE));
          yield put(createAction(conversionConstants.RESET_CONVERSION_STORE));
          yield put(createAction(reachConstants.RESET_REACH_STORE));
          yield put(createAction(applicationConstants.CHANGE_EDITING_MODE, false));
        }
      }
      if (logNonExceptionFailure) {
        if (!state.app.editableCampaign) {
          DataDogLogger.App.creationOfCampaignFailed(toDataDogCampaign(state));
        } else {
          DataDogLogger.App.editOfCampaignFailed(toDataDogCampaignEdit(state, true));
        }
      }
    } catch (e) {
      if (!state.app.editableCampaign) {
        // @ts-ignore
        DataDogLogger.App.creationOfCampaignFailed(toDataDogCampaign(state, e?.data));
      } else {
        // @ts-ignore
        DataDogLogger.App.editOfCampaignFailed(toDataDogCampaignEdit(state, true, e?.data));
      }

      const errorObj = get(e, 'response.data.responseObject', null);
      yield put(
        createAction<any>(
          toastConstants.TOAST_OPEN,
          errorObj?.errorMsg || 'Campaign could not be saved. Please try again.',
        ),
      );
      yield put(createAction<any>(applicationConstants.FAIL_CREATING, errorObj || e));
    }
  }
}

export function* saveCampaignAsDraftSaga(action: Action<History>): Generator<unknown, void, any> {
  const state: AppState = yield select();

  if (!checkSaveDraftValidation(state)) {
    yield scrollToElement();
    if (state.app.editableCampaign) {
      DataDogLogger.App.editOfCampaignFailed(toDataDogCampaignEdit(state, false));
    } else {
      DataDogLogger.App.creationOfCampaignFailed(toDataDogCampaign(state));
    }
  } else {
    if (action.payload) {
      yield action.payload.push({
        pathname: '/campaign-saving',
        state: { previous: window.location.hash },
      });
    }
    const { sidebarCampaignInfo } = state.advanced;

    try {
      let logNonExceptionFailure = true;
      if (sidebarCampaignInfo.timezone && sidebarCampaignInfo.country && state.auth.userData) {
        const request = collectRequest(state);

        if (request) {
          logNonExceptionFailure = false;
          let response;
          if (state.app.editableCampaign) {
            response = yield API.Campaign.EditCampaignDraft(request, state.app.editableCampaign.id);
            DataDogLogger.App.editOfCampaignSucceeded(
              toDataDogCampaignEdit(state, false, response),
            );
            yield put(createAction(applicationConstants.SUCCESS_EDITING, response.responseObject));
          } else {
            response = yield API.Campaign.SaveCampaignAsDraft(request);
            DataDogLogger.App.creationOfCampaignSucceeded(toDataDogCampaign(state, response));
            yield put(
              createAction(applicationConstants.SUCCESS_CREATING_DRAFT, response.responseObject),
            );
          }

          yield put(createAction(applicationConstants.RESET_APP_STORE));
          yield put(createAction(creativesConstants.RESET_CREATIVE_STORE));
          yield put(createAction(advanceConstants.RESET_ADVANCE_STORE));
          yield put(createAction(smsConstants.RESET_SMS_STORE));
          yield put(createAction(advertiserConstants.RESET));
          yield put(createAction(technologyConstants.RESET_TECHNOLOGY_STORE));
          yield put(createAction(bidConstants.RESET_BID_STORE));
          yield put(createAction(demographicConstants.RESET_DEMOGRAPHIC_STORE));
          yield put(createAction(inventoryConstants.RESET_INVENTORY_STORE));
          yield put(createAction(schedulingConstants.RESET_SCHEDULING_STORE));
          yield put(createAction(advancedTargetingConstants.RESET_ADVANCED_TARGETING_STORE));
          yield put(createAction(audienceConstants.RESET_AUDIENCE_STORE));
          yield put(createAction(mapConstants.RESET_MAP_STORE));
          yield put(createAction(locationConstants.RESET_LOCATION_STORE));
          yield put(createAction(conversionConstants.RESET_CONVERSION_STORE));
          yield put(createAction(reachConstants.RESET_REACH_STORE));
          yield put(createAction(applicationConstants.CHANGE_EDITING_MODE, false));
        }
      }
      if (logNonExceptionFailure) {
        if (!state.app.editableCampaign) {
          DataDogLogger.App.creationOfCampaignFailed(toDataDogCampaign(state));
        } else {
          DataDogLogger.App.editOfCampaignFailed(toDataDogCampaignEdit(state, false));
        }
      }
    } catch (e) {
      if (!state.app.editableCampaign) {
        // @ts-ignore
        DataDogLogger.App.creationOfCampaignFailed(toDataDogCampaign(state, e?.data));
      } else {
        // @ts-ignore
        DataDogLogger.App.editOfCampaignFailed(toDataDogCampaignEdit(state, false, e?.data));
      }

      const errorObj = get(e, 'response.data.responseObject', null);
      yield put(
        createAction<any>(
          toastConstants.TOAST_OPEN,
          errorObj?.errorMsg || 'Campaign could not be saved. Please try again.',
        ),
      );
      yield put(
        createAction<any>(applicationConstants.FAIL_SAVING_CAMPAIGN_AS_DRAFT, errorObj || e),
      );
    }
  }
}

export function* createCampaignWithAdvertiser(
  action?: Action<History>,
): Generator<unknown, void, any> {
  const state: AppState = yield select();

  if (!checkValidation(state)) {
    yield scrollToElement();
  } else {
    const response = yield call(createAdvertiserSaga);
    if (response?.id) {
      yield put(applicationActions.createCampaign(action?.payload));
    }
  }
}

export function* fillCampaignForm(action: Action<ExistingCampaignData>) {
  yield put(advanceActions.fillAdvanceStore(action.payload));
  yield put(smsActions.fillSMSStore(action.payload));
}
