import * as React from 'react';
import { ColumnDef } from '@applift/datagrid';
import { CurrencyCell, DateCell, RawDataCell } from 'components/CellType';
import { ActionCell } from './cells/actionCell';
import { DealStatusCell } from './cells/dealStatusCell';

export const getPgDealsListColDef = (
  removePgDeal: (id: number) => void,
  timezone: any,
): ColumnDef<any>[] => [
  {
    accessorKey: 'dealId',
    header: 'Deal ID',
    id: 'dealId',
    size: 160,
    enableSorting: false,
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    accessorKey: 'dealName',
    header: 'Deal Name',
    id: 'dealName',
    size: 160,
    enableSorting: false,
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    accessorKey: 'statusLabel',
    header: 'Deal Status',
    id: 'statusLabel',
    size: 120,
    enableSorting: false,
    cell: (props) => <DealStatusCell data={props.row.original} />,
  },
  {
    accessorKey: 'exchangeName',
    header: 'Exchange',
    id: 'exchangeName',
    size: 180,
    enableSorting: false,
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    accessorKey: 'paymentTypeLabel',
    header: 'Payment To',
    id: 'paymentTypeLabel',
    size: 120,
    enableSorting: false,
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    accessorKey: 'cpm',
    header: 'CPM',
    id: 'cpm',
    size: 100,
    enableSorting: false,
    cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
  },
  {
    accessorKey: 'created',
    header: 'Created',
    id: 'created',
    size: 190,
    enableSorting: false,
    cell: (props) => {
      return <DateCell date={props.renderValue() as number} timezoneId={timezone.id} />;
    },
  },
  {
    accessorKey: 'description',
    header: 'Description',
    id: 'description',
    size: 420,
    enableSorting: false,
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    minSize: 68,
    size: 68,
    enableSorting: false,
    meta: {
      align: 'center',
    },
    cell: (props) => <ActionCell removePgDeal={removePgDeal} data={props.row.original} />,
  },
];
