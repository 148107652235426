import { createAction } from '../../utils/actions';
import { bidConstants } from './constants';

type Data = {
  key: string;
  value: boolean | number | string;
};

export const bidActions = {
  changeBidField(data: Data) {
    return createAction<Data>(bidConstants.CHANGE_BID_FIELD, data);
  },

  resetState() {
    return createAction(bidConstants.RESET_BID_STORE);
  },

  resetImpressionsField() {
    return createAction(bidConstants.RESET_IMPRESSIONS_FIELD);
  },

  validateBidStore() {
    return createAction(bidConstants.VALIDATE_BID_STORE);
  },
};

export interface ChangeBidField {
  changeBidField: (data: Data) => void;
}

export interface ResetState {
  resetState: () => void;
}

export interface ResetImpressionsField {
  resetImpressionsField: () => void;
}

export interface ValidateBidStore {
  validateBidStore: () => void;
}
