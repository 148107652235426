import { createAction } from '../../utils/actions';
import { publishersConstants } from './constants';
import { Option } from '../../models/Option';

export interface PublisherActions {
  fetchPublishers: () => void;
  selectPublishers: (creativeType: Option<number>[]) => void;
}

export type ValidatePublisherStore = {
  validatePublisherStore: () => void;
};

export const publisherActions = {
  fetchPublishers() {
    return createAction(publishersConstants.FETCH_PUBLISHERS);
  },

  selectPublishers(publishers: Option<number>[]) {
    return createAction<Option<number>[]>(publishersConstants.SELECT_PUBLISHERS, publishers);
  },

  validatePublisherStore() {
    return createAction(publishersConstants.VALIDATE_PUBLISHER_STORE);
  },
};
