import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  TextField,
  Typography,
} from '@applift/factor';
import { isValidUrl } from 'utils/isValidUrl';
import { urlRegEx } from 'utils/validationRules';

export interface EditClickUrlDialogPropTypes {
  creativeIds: number[];
  pixelUrlUpdateLoading: boolean;
  updatePixelUrl: (args: string) => void;
  setShowEditPixelUrlDialog: (args: boolean) => void;
}

export const EditPixelUrlDialog = (props: EditClickUrlDialogPropTypes) => {
  const { setShowEditPixelUrlDialog, updatePixelUrl, pixelUrlUpdateLoading } = props;

  const [pixelUrl, setPixelUrl] = React.useState<string>();

  const isPixelUrlValid = () => {
    if (pixelUrl) {
      return !isValidUrl(pixelUrl) || !urlRegEx.test(pixelUrl.trim());
    }
    return false;
  };

  return (
    <Dialog open>
      <DialogTitle>Edit Pixel URL</DialogTitle>

      <DialogContent>
        <Typography>Enter the Pixel URL you want to apply to selected creatives</Typography>
        <TextField
          focused
          fullWidth
          sx={{ mt: 16 }}
          placeholder="Pixel URL"
          value={pixelUrl}
          onChange={(e: any) => setPixelUrl(e.target.value)}
          helperText={isPixelUrlValid() ? 'Pixel URL must be a valid url address' : null}
          error={isPixelUrlValid()}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setShowEditPixelUrlDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={pixelUrlUpdateLoading}
          onClick={() => {
            if (pixelUrl) {
              updatePixelUrl(pixelUrl);
            }
          }}
          disabled={!pixelUrl || isPixelUrlValid()}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
