import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from 'factor';
import get from 'lodash/get';
import { Creative } from '../../../models/Creative';
import { CampaignInfoField } from '../../../models/CampaignInfoFields';
import {
  AudienceType,
  AudienceItemResponse,
  AudienceWarning,
  audienceIDMap,
} from '../../../models/Audience';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { User } from '../../../models/User';
import { AppState } from '../../../models/Store';
import { Option } from '../../../models/Option';
import { API } from '../../../api';
import {
  applicationActions,
  ChangeEditingMode,
  SetEditableCampaign,
  FillAppStore,
  SetEditableCampaignLoadingStatus,
  SetMapLoadingStatus,
  SetCollapseBlockIndex,
  SetCurrentIoId,
  SetBudgetTypeId,
} from '../../../store/app/actions';
import {
  getTechnologyBlockErrorCreating,
  getInventoryBlockErrorCreating,
} from '../../../store/app/helpers';
import { audienceActions, SelectAudienceField } from '../../../store/audience/actions';
import { creativesActions } from '../../../store/creatives/actions';
import { advanceActions, SetCampaignSidebarInfo } from '../../../store/advance/actions';
import { AdvancePageState } from '../../../store/advance/reducer';
import { locationActions, SetOrRelationship } from '../../../store/location/actions';
import { ErrorCreatingResponse } from '../../../models/Response';
import { ICreativeAdvancedTargeting } from '../../../store/creatives/reducer';
import { getLocationAPIList, isLocationSelectionPresent } from './helpers';
import { Campaign } from './Campaign';

import styles from './styles.module.scss';

type Props = FillAppStore &
  ChangeEditingMode &
  SetEditableCampaign &
  SelectAudienceField &
  SetEditableCampaignLoadingStatus &
  SetMapLoadingStatus &
  SetCollapseBlockIndex &
  SetOrRelationship &
  SetCampaignSidebarInfo &
  SetCurrentIoId &
  SetBudgetTypeId & {
    isAuth: boolean;
    editableCampaign: ExistingCampaignData | null;
    user: User;
    selectCreatives: (creatives: Creative[]) => void;
    setIsAdvancedVideoTargeting: (arg: boolean) => void;
    setAdvancedVideoTargetingOptions: (arg: ICreativeAdvancedTargeting | undefined) => void;
    selectCreativeType: (option: Option<number>) => void;
    changeUrl: (url: string) => void;
    sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
    creativeStoreHasErrors: boolean;
    technologyStoreHasErrors: boolean;
    publisherStoreHasErrors: boolean;
    bidStoreHasErrors: boolean;
    errorCreating: ErrorCreatingResponse | null;
    audienceWarning: AudienceWarning;
    editableCampaignProp: ExistingCampaignData | null;
    setPrebidSegmentIds: (ids: number[]) => void;
    exchangesHasError: boolean;
  };

const EditCampaignComponent = (props: Props) => {
  const {
    isAuth,
    changeEditingMode,
    setEditableCampaign,
    setEditableCampaignLoadingStatus,
    setMapLoadingStatus,
    fillAppStore,
    editableCampaign,
    user,
    selectCreatives,
    selectCreativeType,
    changeUrl,
    sidebarCampaignInfo,
    selectAudienceField,
    setCollapseBlockIndex,
    setCampaignSidebarInfo,
    creativeStoreHasErrors,
    technologyStoreHasErrors,
    publisherStoreHasErrors,
    bidStoreHasErrors,
    errorCreating,
    audienceWarning,
    setAdvancedVideoTargetingOptions,
    setIsAdvancedVideoTargeting,
    setLocationOrRelationship,
    setCurrentIoId,
    setBudgetTypeId,
    editableCampaignProp,
    setPrebidSegmentIds,
    exchangesHasError,
  } = props;
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      return;
    }

    const initializeCampaign = async (res: ExistingCampaignData) => {
      if (res.budgetTypeId) {
        setBudgetTypeId(res.budgetTypeId);
      }
      if (res.prebidAudienceSegmentIdList) {
        setPrebidSegmentIds(res.prebidAudienceSegmentIdList);
      }
      setEditableCampaign(res);
      fillAppStore(res);
      setLocationOrRelationship(res.isLocationWithOrFilter);
      const { creativeIds } = res;

      if (creativeIds) {
        try {
          const { responseObject } = await API.Creatives.FetchCreativeList({
            creativeIds: res.creativeIds,
            creativeTypeIds: '',
            noOfEntries: 99999,
            pageNo: 1,
            searchField: '',
          });
          const creatives: Creative[] = get(responseObject, 'data', []);
          if (creatives.length) {
            selectCreatives(creatives);
          }
          setIsAdvancedVideoTargeting(res.isAdvanceAudioVideoTargeted);
          setAdvancedVideoTargetingOptions(res.creativeAdvanceTargeting);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }

      const audiencesAsString = (res.whiteListedAudienceIds?.split(',') || [])
        .concat(res.blackListedAudienceIds?.split(',') || [])
        .join(',');

      if (audiencesAsString && audiencesAsString.length) {
        try {
          const [types, list] = await Promise.all([
            API.Audience.AudienceTypes(),
            API.Audience.List('', audiencesAsString),
          ]);
          const selectedAudiences = list
            .filter((audience: AudienceItemResponse) =>
              Boolean(
                res.blackListedAudienceIds?.includes(String(audience.id)) ||
                  res.whiteListedAudienceIds?.includes(String(audience.id)),
              ),
            )
            .map((audience: AudienceItemResponse) => ({
              label: audience.audienceName,
              value: audience.id,
              audienceTypeId: audience.audienceTypeId,
              audienceId: audience.id,
              excluded: !!res.blackListedAudienceIds?.includes(String(audience.id)),
              included: !!res.whiteListedAudienceIds?.includes(String(audience.id)),
              status: audience.status,
              createdOn: audience.createdOn,
              uniques: audience.uniques,
              dataCost: audience.dataCost,
              statusDisplayName: audience.statusDisplayName,
              dataGroup: audience.isVoterAudience ? '1' : '2',
              reactLabel: (
                <div className={styles.option}>
                  <div className={styles.optionImage}>
                    <Icon name={audienceIDMap[audience.audienceTypeId]} />
                  </div>
                  <span className={styles.optionTitle}>{audience.audienceName}</span>
                </div>
              ),
            }));

          if (selectedAudiences.length) {
            selectAudienceField({
              key: CampaignInfoField.audiences,
              value: selectedAudiences,
            });
          }

          const selectedAudienceTypes = types
            .filter((type: AudienceType) =>
              selectedAudiences.find((v) => v.audienceTypeId === type.id),
            )
            .map((item: AudienceType) => ({
              value: item.id,
              label: item.name.replace(' Audience', ''),
            }));

          if (selectedAudienceTypes.length) {
            selectAudienceField({
              key: CampaignInfoField.audienceTypes,
              value: selectedAudienceTypes,
            });
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
      if (res.ioId) {
        setCurrentIoId(res.ioId);
      }
      if (isLocationSelectionPresent(res)) {
        try {
          const apiList = getLocationAPIList(res);
          Promise.allSettled(apiList.map((field) => field.api)).then((results) => {
            results.forEach(
              (result: PromiseSettledResult<Option<number>[] | Option<string>[]>, idx: number) => {
                if (result.status === 'fulfilled' && result.value.length) {
                  setCampaignSidebarInfo(apiList[idx].field, result.value);
                }
              },
            );
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }

      if (res.advertiserDomain) {
        changeUrl(res.advertiserDomain);
      }
    };

    changeEditingMode(true);
    setEditableCampaignLoadingStatus(true);
    setMapLoadingStatus(true);
    initializeCampaign({
      ...editableCampaignProp!,
      publisherAdCategory: editableCampaignProp?.publisherAdCategory || '',
    })
      .catch(() => {
        changeEditingMode(false);
      })
      .finally(() => {
        setEditableCampaignLoadingStatus(false);
      });
  }, [
    history,
    user,
    changeEditingMode,
    setEditableCampaign,
    fillAppStore,
    setEditableCampaignLoadingStatus,
    setMapLoadingStatus,
    selectCreatives,
    changeUrl,
    selectAudienceField,
    selectCreativeType,
    setCollapseBlockIndex,
    setCampaignSidebarInfo,
    setIsAdvancedVideoTargeting,
    setAdvancedVideoTargetingOptions,
    setLocationOrRelationship,
    setBudgetTypeId,
    setCurrentIoId,
    setPrebidSegmentIds,
    editableCampaignProp,
  ]);

  const errorIndices: boolean[] = Array(11).fill(false);
  errorIndices[0] = creativeStoreHasErrors;
  errorIndices[4] =
    publisherStoreHasErrors || exchangesHasError || getInventoryBlockErrorCreating(errorCreating);
  errorIndices[8] = technologyStoreHasErrors || getTechnologyBlockErrorCreating(errorCreating);
  errorIndices[9] = bidStoreHasErrors;

  return (
    <Campaign
      errorIndices={errorIndices}
      isAuth={isAuth}
      editableCampaign={editableCampaign}
      sidebarCampaignInfo={sidebarCampaignInfo}
      audienceWarning={audienceWarning}
      mode="edit"
    />
  );
};

const mapState = (state: AppState) => ({
  isAuth: state.auth.authorized,
  user: state.auth.userData,
  editableCampaign: state.app.editableCampaign,
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  creativeStoreHasErrors: state.creatives.creativeStoreHasErrors,
  technologyStoreHasErrors: state.technology.technologyStoreHasErrors,
  publisherStoreHasErrors: state.publishers.publisherStoreHasErrors,
  bidStoreHasErrors: state.bid.bidStoreHasErrors,
  errorCreating: state.app.errorCreating,
  audienceWarning: state.audience.audienceWarning,
  exchangesHasError: state.advancedTargeting.exchangesHasError,
});

const mapAction = {
  changeEditingMode: applicationActions.changeEditingMode,
  setEditableCampaign: applicationActions.setEditableCampaign,
  selectAudienceField: audienceActions.selectAudienceField,
  fillAppStore: applicationActions.fillAppStore,
  setEditableCampaignLoadingStatus: applicationActions.setEditableCampaignLoadingStatus,
  setMapLoadingStatus: applicationActions.setMapLoadingStatus,
  selectCreatives: creativesActions.selectCreatives,
  setIsAdvancedVideoTargeting: creativesActions.setIsAdvancedVideoTargeting,
  setAdvancedVideoTargetingOptions: creativesActions.setAdvancedVideoTargetingOptions,
  selectCreativeType: creativesActions.selectCreativeType,
  changeUrl: creativesActions.changeUrl,
  setCollapseBlockIndex: applicationActions.setCollapseBlockIndex,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setLocationOrRelationship: locationActions.setLocationOrRelationship,
  setCurrentIoId: applicationActions.setCurrentIoId,
  setBudgetTypeId: applicationActions.setBudgetTypeId,
  setPrebidSegmentIds: audienceActions.setPreBidSegmentIds,
};

export const EditCampaign = connect(mapState, mapAction)(EditCampaignComponent);
