import { CircleExtended } from 'models/CircleExtended';
import { MVCArray } from 'models/Google';
import { ICountryOption, IStateOption } from 'models/Location';

export type TFigure = {
  state: string;
  country: string;
};

export type TAddressData = {
  address: string;
  country?: string;
  state?: string;
};

export const GROUPED_TAGS_VALUE = 5;

export const filterFiguresByCountry = <T extends TFigure>(
  figures: T[],
  country: ICountryOption | null,
) =>
  figures.filter(
    (figure: T) => !figure.country || !country || figure.country === country.shortName,
  );

export const reverseFilterFiguresByStates = <T extends TFigure>(
  figures: T[],
  states: IStateOption[],
) => {
  if (states.length) {
    return figures.filter((figure: T) => {
      return (
        !figure.state ||
        !states.find((state: IStateOption) => {
          return state.abbreviation === figure.state;
        })
      );
    });
  }
  return [];
};

export const getLatLng = (data: CircleExtended) => {
  return `Lat: ${data.getCenter().lat().toFixed(2)}
        Lng: ${data.getCenter().lng().toFixed(2)}`;
};

export const getBoundsFromLatLng = (coordinates: any[]) => {
  const bounds = new google.maps.LatLngBounds();
  const flattenedCoordinates = coordinates.flat(Infinity);
  for (let index = 0; index < flattenedCoordinates.length; index += 2) {
    bounds.extend({ lat: flattenedCoordinates[index + 1], lng: flattenedCoordinates[index] });
  }
  return bounds;
};

export const getBoundsFromPaths = (paths: MVCArray<MVCArray<google.maps.LatLng>>) => {
  const bounds = new google.maps.LatLngBounds();
  paths.forEach((path) => {
    path.forEach((element) => bounds.extend(element));
  });
  return bounds;
};
