import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { QueryFunctionContext } from '@tanstack/react-query';
import { WithResponse } from 'models/Response';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import { getPoliticalAdvertiserList } from './QueryKeys';

export type PoliticalAdvertiser = {
  id: number;
  advertiserName: string;
  address: string;
  contactNumber: string;
  website: string;
  chiefName: string;
  legalInfo: string;
  isActive: number;
  uuid: string;
  links: string | null;
};

type Params = {
  owId: number;
};

export const fetchAdvertiserList = async (
  params: Params,
): Promise<WithResponse<{ data: PoliticalAdvertiser[] }>> => {
  try {
    const response: AxiosResponse<
      WithResponse<{
        data: PoliticalAdvertiser[];
      }>
    > = await getInstance().get(
      `/v3/ua/organization/advertisers/list?searchField=&pageNo=-1&limit=10&owId=${params.owId}`,
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting political advertiser list:', e);
    return e.response.data;
  }
};

export const fetchAdvertiserListQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getPoliticalAdvertiserList['keys']>>): Promise<any> => {
  try {
    const { owId, search } = queryKey[0]!;
    const response: AxiosResponse<
      WithResponse<{
        data: PoliticalAdvertiser[];
      }>
    > = await getInstance().get(
      `/v3/ua/organization/advertisers/list?searchField=${
        search ?? ''
      }&pageNo=-1&limit=10&owId=${owId}`,
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while getting political advertiser list:', e);
    return e.response.data;
  }
};

export type CreatingParams = {
  name: string;
  address: string;
  phone: string;
  website: string;
  chiefName: string;
  legalInfo: string;
  links: string;
};

export type CreatingResponse = {
  id: number;
  message: string;
  uuid: string;
};

export const createPoliticalAdvertiser = async (req: CreatingParams): Promise<CreatingResponse> => {
  try {
    const response: AxiosResponse<any> = await getInstance().post('/v2/adv/politicalclients', req);
    return get(response, 'data.responseObject', null);
  } catch (e) {
    Logger.log('Error while creating political advertiser:', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};
