import React from 'react';
import { SelectAdvanceComponents, TextField, Typography } from '@applift/factor';
import { createSelector } from 'reselect';
import { AppState } from 'models/Store';
import { PublisherActions, publisherActions } from 'store/publishers/actions';
import { connect } from 'react-redux';
import { NestedOption } from 'models/Option';
import { Option } from 'iqm-framework';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { RowSelectionState } from '@applift/datagrid';
import { useLocation } from 'react-router';

interface LabelValuePair {
  label: string;
  value: string;
  subRows?: undefined | LabelValuePair[];
}

type Props = PublisherActions & {
  publishers: NestedOption[];
  editableCampaign: ExistingCampaignData | null;
  submitted: boolean;
};

const transformNestedData = (options: NestedOption<any>[]) => {
  return options?.map?.((option) => {
    if (option.options) {
      const subRows: NestedOption<any>[] = transformNestedData(option.options);
      return { ...option, subRows, value: option.value.toString() };
    }
    return option;
  });
};

const reduceToFlatObject = (nestedList: LabelValuePair[]): RowSelectionState => {
  return (
    nestedList?.reduce?.((prev, curr) => {
      let res = {};
      if (curr.subRows) {
        res = { [curr.value]: true, ...reduceToFlatObject(curr.subRows) };
      } else res = { [curr.value]: true };
      return { ...prev, ...res };
    }, {}) ?? {}
  );
};

const PublisherSelectComponent = (props: Props) => {
  const { fetchPublishers, publishers, selectPublishers, editableCampaign, submitted } = props;

  // Fetching publishers data
  React.useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  const { pathname } = useLocation();
  const [search, setSearch] = React.useState('');
  const [publisherSelection, setPublishersSelection] = React.useState<RowSelectionState>({});

  // Fetched data of type NestedOption[], transforming it to ----> LabelValuePair[]
  const allOptions: LabelValuePair[] = React.useMemo(
    () => transformNestedData(publishers) ?? [],
    [publishers],
  );

  // Apply the search filter
  const filterOptions = React.useMemo(() => {
    return allOptions
      .map((option) => {
        if (option.label?.toLowerCase?.()?.includes?.(search?.toLowerCase?.()?.trim?.())) {
          return option;
        }
        const subrowIncludedInSearch =
          option.subRows?.filter((row) => {
            return row.label?.toLowerCase?.()?.includes?.(search?.toLowerCase?.()?.trim?.());
          }) ?? [];
        if (subrowIncludedInSearch.length) {
          return { ...option, subRows: subrowIncludedInSearch };
        }
        return null;
      })
      .filter(Boolean);
  }, [allOptions, search]);

  // Initialize selection for create mode
  React.useEffect(() => {
    if (publishers.length && pathname.includes('campaign-create')) {
      const initialData = reduceToFlatObject(allOptions);
      setPublishersSelection(initialData);
    }
  }, [allOptions, pathname, publishers.length]);

  // Initialize selection for edit mode
  React.useEffect(() => {
    if (editableCampaign && publishers.length) {
      const selectedPublishersFromCampaign = editableCampaign.publisherAdCategory;
      if (!selectedPublishersFromCampaign) {
        setPublishersSelection(reduceToFlatObject(allOptions));
      } else {
        const initialData =
          selectedPublishersFromCampaign
            ?.split(',')
            ?.filter?.(Boolean)
            ?.reduce((prev, curr) => ({ ...prev, [curr]: true }), {} as RowSelectionState) ?? {};

        setPublishersSelection(initialData);
      }
    }
  }, [editableCampaign, publishers, allOptions]);

  const selectedGroupIds = React.useCallback(
    (val: number[]) => {
      const resArr: {
        value: number;
      }[] = [];
      filterOptions.forEach((one) => {
        if (
          // @ts-ignore
          one?.subRows?.every((two) => val.includes(two.value))
        ) {
          resArr.push({ value: Number(one.value) });
        }
      });
      return resArr;
    },
    [filterOptions],
  );

  // Update the store on selection change
  React.useEffect(() => {
    const selectedPublishersArray = Object.keys(publisherSelection)
      .filter((key) => publisherSelection[key])
      .map((p) => ({
        value: Number(p),
      }));

    const selectedGroups = selectedGroupIds(selectedPublishersArray.map((val) => val.value));

    const groupIds = publishers.map((val) => val.value);

    const selectedPublishersToSet = selectedPublishersArray.filter(
      (val) => !groupIds.includes(val.value),
    );

    selectPublishers([...selectedPublishersToSet, ...selectedGroups] as Option<number>[]);
  }, [publisherSelection, publishers, selectPublishers, selectedGroupIds]);

  const hasError = submitted && Object.values(publisherSelection).filter(Boolean).length === 0;

  return (
    <TextField
      fullWidth
      label="Publisher Category"
      select="advanceSelect"
      error={hasError}
      data-not-valid={hasError ? 'true' : 'false'}
      helperText={hasError ? 'Atleast 1 Publisher category must be selected' : ''}
      SelectProps={{
        multiple: true,
        onInputChange: (e) => {
          // @ts-ignore
          const val = e.target.value;
          if (typeof val === 'string') {
            setSearch(val);
          }
        },
        defaultExpanded: true,
        data: filterOptions as LabelValuePair[],
        overlay: filterOptions.length === 0 ? 'noResult' : undefined,
        renderListItem: SelectAdvanceComponents.DefaultListOptionItemWrapper<LabelValuePair>({
          disableCheckbox: true,
          disableRowExpansion: true,
          selectionStyle: 'all',
        }),
        getSubRows: (row) => row.subRows,
        slotProps: {
          PaperProps: {
            style: {
              maxWidth: 303,
            },
          },
        },
        renderOption: ({ row }) =>
          row.subRows.length > 0 ? (
            <Typography component="h2" variant="bodySmall" weight="demi" gutterBottom={false}>
              {row.original.label}
            </Typography>
          ) : (
            <Typography component="span" variant="bodySmall" gutterBottom={false}>
              {row.original.label}
            </Typography>
          ),
        rowCount: 0,
        renderValue: (val) => {
          const currentSelection = Array.isArray(val) ? val : [];
          const isAllSelected = allOptions.every((option) => {
            if (option.subRows) {
              // Check the one level of nesting, assumes if all the subrows are selected the block is selected
              return option?.subRows?.every?.((option) => {
                return currentSelection.some((obj) => obj.value === option.value);
              });
            }
            return currentSelection.some((obj) => obj.value === option.value);
          });
          const selectedCount = currentSelection.filter((one) => !one?.subRows?.length).length;
          if (isAllSelected) {
            return 'All Selected';
            // eslint-disable-next-line
          } else if (selectedCount) {
            return `${selectedCount} Selected`;
          }
          return null;
        },
        placeholder: 'Select Publisher Category',
      }}
      value={publisherSelection}
      onChange={setPublishersSelection}
    />
  );
};

const mapState = createSelector(
  (state: AppState) => state.publishers,
  (state: AppState) => state.app,
  (publishers: any, app) => ({
    publishers: publishers.publishers,
    editableCampaign: app.editableCampaign,
    submitted: app.submitted,
  }),
);

const mapAction = {
  fetchPublishers: publisherActions.fetchPublishers,
  selectPublishers: publisherActions.selectPublishers,
};

export const PublishersSelect = connect(mapState, mapAction)(PublisherSelectComponent);
