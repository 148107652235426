"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUrl = void 0;
function fetchUrlSync(_a) {
  var url = _a.url,
    _b = _a.retries,
    retries = _b === void 0 ? 2 : _b;
  var attempts = 0;
  while (attempts++ < retries) {
    try {
      var request = new XMLHttpRequest();
      request.open("GET", url, false);
      request.send();
      if (request.status >= 200 && request.status < 400) {
        return request.responseText;
      }
    } catch (e) {}
  }
  throw new Error(url + " fetch failed after " + attempts + " attempts");
}
function fetchUrlAsync(_a) {
  var url = _a.url,
    onError = _a.onError,
    onSuccess = _a.onSuccess,
    _b = _a.retries,
    retries = _b === void 0 ? 2 : _b;
  if (retries === 0) {
    onError(url + " fetch failed after 3 attempts");
    return;
  }
  var request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.onerror = function() {
    fetchUrlAsync({
      onError: onError,
      onSuccess: onSuccess,
      retries: retries - 1,
      url: url
    });
  };
  request.onload = function(res) {
    onSuccess(res.responseText);
  };
  request.send();
}
function fetchUrl(_a) {
  var url = _a.url,
    _b = _a.loadCallback,
    loadCallback = _b === void 0 ? function() {} : _b,
    _c = _a.syncInBrowser,
    syncInBrowser = _c === void 0 ? false : _c;
  if (!url) {
    throw new Error("'url' is undefined");
  }
  if (syncInBrowser) {
    return fetchUrlSync({ url: url });
  } else {
    fetchUrlAsync({
      onError: function() {
        throw new Error(url + " fetch failed");
      },
      onSuccess: loadCallback,
      url: url
    });
  }
}
exports.fetchUrl = fetchUrl;
