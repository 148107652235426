import * as React from 'react';
import { TypoTooltip, Typography, TypographyProps } from '@applift/factor';
import { useCreativeTypes } from 'hooks/useCreatives';

interface CreativeTypeCellProp {
  value: number[];
  typographyProps?: Omit<TypographyProps, 'children'>;
}

export const CreativeTypeCell = ({ value = [], typographyProps }: CreativeTypeCellProp) => {
  const creativeTypes = useCreativeTypes();
  const displayValue = value
    ?.map?.((val) => creativeTypes.data?.filter((type) => type.value === val)?.[0]?.label ?? '')
    .filter(Boolean)
    .join(', ');
  return displayValue ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {/* eslint-disable-next-line */}
      <Typography {...typographyProps}>{displayValue}</Typography>
    </TypoTooltip>
  ) : (
    //  eslint-disable-next-line
    <Typography {...typographyProps}>—</Typography>
  );
};
