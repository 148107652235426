import * as React from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@applift/factor';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';

import styles from './index.module.scss';

type Props = {
  totalBudget: string;
  maxBid: string;
  submitted: boolean;
  saveDraftSubmitted: boolean;
};

const EstimatedCampaignBudgetComponent = (props: Props) => {
  const { totalBudget, maxBid, submitted, saveDraftSubmitted } = props;
  const isMaxBidGreater = React.useMemo(() => {
    const bid = parseFloat(maxBid);
    const budget = parseFloat(totalBudget);
    return bid > budget;
  }, [maxBid, totalBudget]);

  const totalBudgetIntegerDecimalArray = String(Number(totalBudget).toFixed(2)).split('.');

  return (
    <Box className={styles.estimatedBudget} sx={{ position: 'relative', pl: 16 }}>
      <Box
        className={isMaxBidGreater && (submitted || saveDraftSubmitted) ? styles.error : ''}
        sx={{ display: 'flex' }}
      >
        <Typography
          variant="label"
          sx={{
            mr: 4,
            textColor:
              isMaxBidGreater && (submitted || saveDraftSubmitted) ? 'inherit' : 'neutral-600',
          }}
        >
          Estimated Max Media Spent
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography>
          {totalBudget.length || typeof totalBudget === 'number' ? (
            <>
              <Typography variant="span" sx={{ textWeight: 'demi' }}>
                $
                {Intl.NumberFormat('en-US').format(parseInt(totalBudgetIntegerDecimalArray[0], 10))}
              </Typography>
              .<Typography variant="span">{totalBudgetIntegerDecimalArray[1]}</Typography>
            </>
          ) : (
            '$00.00'
          )}
        </Typography>
      </Box>
      {isMaxBidGreater && (submitted || saveDraftSubmitted) && (
        <Typography className={styles.error} variant="label">
          Estimated Campaign Budget can't be less than the max bid. Please adjust the target or
          daily impressions
        </Typography>
      )}
    </Box>
  );
};

const mapState = (state: AppState) => ({
  totalBudget: state.advanced.sidebarCampaignInfo[CampaignInfoField.totalBudget],
  maxBid: state.advanced.sidebarCampaignInfo.maxBid,
  submitted: state.app.submitted,
  saveDraftSubmitted: state.app.saveDraftSubmitted,
});

const mapActions = {};

export const EstimatedCampaignBudget = connect(
  mapState,
  mapActions,
)(EstimatedCampaignBudgetComponent);
