/* global google */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import { createSelector } from 'reselect';
import { fileUtils } from 'iqm-framework';
import { connect } from 'react-redux';
import { Icon, ButtonsGroup } from 'factor';
import { get } from 'lodash';
import { loadAsync } from 'jszip';

import { API } from 'api';
import { GeoLocation, ValidateBulkLocationsResponse } from 'api/Location';
import { BlackLists, WhiteLists } from 'store/advance/reducer';
import { ILocationFile } from 'store/location/reducer';
import {
  mapActions,
  PushPreSavedCircle,
  PushPreSavedPolygon,
  PushSelectedAreaId,
} from 'store/map/actions';
import { advanceActions, SetBlackList, SetWhiteList } from 'store/advance/actions';
import { locationActions } from 'store/location/actions';
import { AppState } from 'models/Store';
import { CircleExtended } from 'models/CircleExtended';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { PolygonExtended } from 'models/PolygonExtended';
import { TLocationFileType, IStateOption, ICountryOption } from 'models/Location';
import { milesToMeters } from 'utils/format';
import { getFileExtension, kmlToGeoJSON } from 'utils/bulkFileUpload';
import { getBlackList, getWhiteList } from 'utils/helpers';
import { DataDogLogger } from 'services/DataDog';
import { UploadWithText } from '../UploadWithText';
import { MaximumRecordsCount } from './Constants';

interface CircleProps {
  circle: google.maps.Circle;
  isInclude: boolean;
  addressData?: {
    address: any;
    country: any;
    state: any;
  };
  fileId?: number;
  filename?: string;
  locationId?: number;
}

interface FileData {
  id: number;
  name: string;
}

interface Styles {
  [key: string]: string;
}

interface Props
  extends SetWhiteList,
    SetBlackList,
    PushPreSavedCircle,
    PushPreSavedPolygon,
    PushSelectedAreaId {
  styles: Styles;
  extensionError: string | JSX.Element;
  setExtensionError: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  addressesSelection: TLocationFileType;
  setAddressesSelection: React.Dispatch<React.SetStateAction<TLocationFileType>>;
  whiteLists: WhiteLists;
  blackLists: BlackLists;
  addLocationFile: (data: ILocationFile) => void;
  setLocationError: (errorName: string) => void;
  setFilesUpload: (files: TLocationFileType[]) => void;
  filesBeingUploaded: TLocationFileType[];
  selectedStates: IStateOption[];
  country: ICountryOption | null;
  parentApp: string;
}

const exampleFilesMapper = {
  locations: 'bulk-location-example.csv',
  addresses: 'bulk-addresses-example.csv',
  polygons: 'bulk-polygon-example.kmz',
};

const fileUploadButtonGroupItems = [
  {
    title: (
      <>
        <Icon name="EventsLocation" className="mr-1" />
        Locations
      </>
    ),
    value: 'locations',
  },
  {
    title: (
      <>
        <Icon name="Address" className="mr-1" />
        Addresses
      </>
    ),
    value: 'addresses',
  },
  {
    title: (
      <>
        <Icon name="Polygon" className="mr-1" />
        Polygons
      </>
    ),
    value: 'polygons',
  },
];

export const UploadCustomAreaComponent = ({
  addressesSelection,
  setAddressesSelection,
  setExtensionError,
  extensionError,
  setBlackList,
  setWhiteList,
  whiteLists,
  blackLists,
  addLocationFile,
  setLocationError,
  pushPreSavedCircle,
  pushPreSavedPolygon,
  pushSelectedAreaId,
  selectedStates,
  setFilesUpload,
  filesBeingUploaded,
  country,
  styles,
  parentApp,
}: Props) => {
  const filesBeingUploadedRef = useRef(filesBeingUploaded);
  filesBeingUploadedRef.current = filesBeingUploaded;
  const invalidGeoRadiuses: string[][] = []; // lines from textarea which structure/type is not appropriate

  const prepareCircle = (
    loc: GeoLocation,
    index: number,
    fileData?: FileData,
    locationId?: number,
  ) => {
    const { google } = window;
    const circleProps: CircleProps = {
      circle: new google.maps.Circle({
        center: { lat: loc.latitude, lng: loc.longitude },
        radius: milesToMeters(loc.radius),
      }),
      isInclude: true,
    };

    if (loc.address) {
      circleProps.addressData = {
        address: loc.address,
        country: loc.countryShortName,
        state: loc.stateShortName,
      };
    }

    if (fileData) {
      circleProps.filename = fileData.name;
      circleProps.fileId = fileData.id;
    }

    if (locationId) {
      circleProps.locationId = locationId;
    }

    return new CircleExtended(circleProps);
  };
  const addPolygonsToStore = (res: ValidateBulkLocationsResponse, filename: string) => {
    const { google } = window;
    addLocationFile({
      filename,
      type: addressesSelection,
      ...res,
    });
    if (res.validLocations?.length) {
      res.validLocations.forEach((data) => {
        if (data.polypath?.length) {
          const polygon = new PolygonExtended({
            polygon: new google.maps.Polygon({
              paths: data.polypath.map((i) => ({
                lat: i.x,
                lng: i.y,
              })),
            }),
            isInclude: true,
            fileId: res.fileId,
            locationId: data.locationId,
            addressData: {
              address: data.address || '',
              state: data.stateShortName,
            },
            filename,
          });
          polygon.setEditable(false);
          polygon.update().finally(() => pushPreSavedPolygon(polygon));
        }
      });
    }
  };
  const addCirclesToStore = (res: ValidateBulkLocationsResponse, filename?: string) => {
    const failedByCountryOrStateGeoRadiuses: number[][] = [];
    if (filename) {
      const arr: CircleExtended[] =
        res.validLocations?.map((data, index) =>
          prepareCircle(data, index, { name: filename, id: res.fileId }, data.locationId),
        ) || [];
      addLocationFile({
        filename,
        type: addressesSelection,
        ...res,
      });
      pushPreSavedCircle(arr);
      pushSelectedAreaId(arr.map((c: CircleExtended) => c.id));
    }
    const failedByCountryOrStateGeoRadiusesLength = failedByCountryOrStateGeoRadiuses.length;
    const invalidGeoRadiusesLength = invalidGeoRadiuses.length;
    if (failedByCountryOrStateGeoRadiusesLength || invalidGeoRadiusesLength) {
      if (invalidGeoRadiusesLength) {
        setLocationError('invalidCircleError');
      }

      if (failedByCountryOrStateGeoRadiusesLength) {
        setLocationError('rejectedCirclesError');
      }
    }
    return true;
  };

  const updateFileUploadData = (fileType: TLocationFileType) => {
    setFilesUpload([
      ...filesBeingUploadedRef.current.filter(
        (
          (idx) => (type: TLocationFileType) =>
            type !== fileType || --idx
        )(1),
      ),
    ]);
  };

  const validateFileData = async (file: File, fileType: TLocationFileType) => {
    const formData = new FormData();
    formData.append('countryShortName', country?.shortName || '');
    formData.append('locationFile', file, file.name);
    if (addressesSelection === 'addresses') {
      formData.append('fileType', 'address');
    } else if (addressesSelection === 'polygons') {
      formData.append('fileType', 'kml');
    }
    try {
      const response = await API.Location.validateBulkLocations(formData);
      const res = response.data.responseObject.data;

      if (addressesSelection === 'polygons') {
        addPolygonsToStore(res, file.name);
      } else {
        addCirclesToStore(res, file.name);
      }
      if (res.validLocations) {
        const newWhiteListedLocationIds = new Set([
          ...(whiteLists.whiteListedLocationIds || []),
          ...(res.validLocations ? getWhiteList(res.validLocations) : []),
        ]);
        const newBlackListedLocationIds = new Set([
          ...(blackLists.blackListedLocationIds || []),
          ...(res.validLocations ? getBlackList(res.validLocations) : []),
        ]);
        setWhiteList('whiteListedLocationIds', newWhiteListedLocationIds);
        setBlackList('blackListedLocationIds', newBlackListedLocationIds);
      }
      DataDogLogger.Location.uploadLocationFile({
        file,
        success: true,
        locationType: addressesSelection,
        validLocations: res?.validLocations?.length,
        response,
      });
      setExtensionError('');
    } catch (error) {
      setExtensionError(get(error, 'data.responseObject.errorMsg', 'Something went wrong'));
      DataDogLogger.Location.uploadLocationFile({
        file,
        success: false,
        locationType: addressesSelection,
        errorMsg: typeof error === 'string' ? error : '',
        response: error,
      });
    }
    updateFileUploadData(fileType);
  };

  const validateCount = (file: File, fileType: TLocationFileType) => {
    fileUtils.getFileData(
      file,
      (data: string[][]) => {
        if (parentApp === 'audiences' || fileType !== 'locations') {
          if (data.length - 1 <= 1000) {
            validateFileData(file, fileType);
          } else {
            updateFileUploadData(fileType);
            setExtensionError(<span>Maximum 1000 records are allowed. Please try again</span>);
          }
        } else if (fileType === 'locations') {
          if (data.length - 1 <= 500_000) {
            validateFileData(file, fileType);
          } else {
            updateFileUploadData(fileType);
            setExtensionError(
              <span>Only one file with CSV format supported with maximum 500k locations.</span>,
            );
          }
        }
      },
      () => {
        setExtensionError('Unable to read file data');
        updateFileUploadData(fileType);
      },
      {
        csvConfig: {
          preview: 500_000 + 10,
        },
      },
    );
  };

  const fileUploadHandler = async (fileList: FileList) => {
    const file = fileList.item(0);
    if (file) {
      setFilesUpload([...filesBeingUploaded, addressesSelection]);
      if (addressesSelection === 'polygons') {
        try {
          let kmlText = '';
          const fileExtension = getFileExtension(file);
          if (fileExtension === 'kmz') {
            const fileZip = await loadAsync(file);
            // assuming there's only one file in the KMZ, which is the KML file
            const kmlFile = fileZip.files[Object.keys(fileZip.files)[0]];
            kmlText = await kmlFile.async('string');
          }
          if (fileExtension === 'kml') {
            kmlText = await file.text();
          }
          if (kmlToGeoJSON(kmlText).features.length <= 1000) {
            validateFileData(file, addressesSelection);
          } else {
            setExtensionError(<span>Maximum 1000 records are allowed. Please try again</span>);
            updateFileUploadData(addressesSelection);
          }
        } catch (err) {
          setExtensionError('Unable to read file data');
        }
      } else {
        validateCount(file, addressesSelection);
      }
    } else {
      DataDogLogger.Location.uploadLocationFile({
        file,
        success: false,
        locationType: addressesSelection,
        errorMsg: 'No file found',
      });
    }
  };

  const onButtonGroupChange = (fileType: TLocationFileType) => {
    setAddressesSelection(fileType);
    setExtensionError('');
  };

  return (
    <>
      <div className={styles.headerText}>Upload File</div>
      <ButtonsGroup
        items={fileUploadButtonGroupItems}
        className={styles.switch}
        size="sm"
        value={addressesSelection}
        onChange={(fileType: TLocationFileType) => onButtonGroupChange(fileType)}
      />
      <UploadWithText
        currentSelection={addressesSelection}
        className="mt-3"
        helpText={
          addressesSelection === 'addresses'
            ? 'Upload multiple addresses from a file. Addresses will be listed on the right and visible on the map.'
            : 'Upload multiple locations from a file. Locations will be listed on the right and visible on the map.'
        }
        downHelpText={
          addressesSelection === 'polygons'
            ? `Only KMZ or KML format supported with maximum ${MaximumRecordsCount[addressesSelection]} records`
            : `Only CSV format supported with maximum ${
                parentApp === 'audiences' ? 1000 : MaximumRecordsCount[addressesSelection]
              } records`
        }
        sampleFileURL={`${process.env.PUBLIC_URL}/example-files/${exampleFilesMapper[addressesSelection]}`}
        id={addressesSelection === 'addresses' ? 'addresses' : 'locations'}
        onFileUploaded={fileUploadHandler}
        errorMessage={extensionError}
        accept={addressesSelection === 'polygons' ? '.kml,.kmz' : '.csv'}
      />
    </>
  );
};

const mapState = createSelector(
  (state: AppState) => state.advanced.sidebarCampaignInfo,
  (state: AppState) => state.advanced.whiteLists,
  (state: AppState) => state.advanced.blackLists,
  (state: AppState) => state.location.filesBeingUploaded,
  (state: AppState) => state.app.editableCampaign?.parentApp!,
  (sidebarCampaignInfo, whiteLists, blackLists, filesBeingUploaded, parentApp) => ({
    country: sidebarCampaignInfo[CampaignInfoField.country],
    selectedStates: sidebarCampaignInfo[CampaignInfoField.states],
    whiteLists,
    blackLists,
    filesBeingUploaded,
    parentApp,
  }),
);

const actions = {
  pushPreSavedCircle: mapActions.pushPreSavedCircle,
  pushPreSavedPolygon: mapActions.pushPreSavedPolygon,
  pushSelectedAreaId: mapActions.pushSelectedAreaId,
  setLocationError: locationActions.setLocationError,
  addLocationFile: locationActions.addLocationFile,
  setFilesUpload: locationActions.setFileUpload,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
};

export const UploadCustomAreaWrapper = connect(mapState, actions)(UploadCustomAreaComponent);
