import { API } from 'api';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ExistingCampaignData, GeoIdOption } from '../../../models/ExistingCampaign';

export const convertGeoIdsToArray = (data: GeoIdOption[]): number[] => {
  const result: number[] = [];
  data.forEach((d) => {
    d.geo_id.split(',').forEach((g) => result.push(+g));
  });
  return result;
};

export const isLocationSelectionPresent = (data: ExistingCampaignData) =>
  data.blackListedStateIds ||
  data.whiteListedStateIds ||
  data.blackListedCityIds ||
  data.whiteListedCityIds ||
  data.blackListedDmaIds ||
  data.whiteListedDmaIds ||
  data.blackListedCountyIds ||
  data.whiteListedCountyIds ||
  data.blackListedHouseDistrictIds ||
  data.whiteListedHouseDistrictIds ||
  data.blackListedSenateDistrictIds ||
  data.whiteListedSenateDistrictIds ||
  data.blackListedCongressionalDistrictIds ||
  data.whiteListedCongressionalDistrictIds ||
  data.blackListedZipcodes ||
  data.whiteListedZipcodes;

const convertToNumArray = (ids: string) => ids.split(',').map((id) => Number(id));

const getConcatenatedIds = (blackList: string, whiteList: string) => {
  if (blackList && whiteList) {
    return blackList.concat(',').concat(whiteList);
  }
  return blackList || whiteList;
};

export const getLocationAPIList = (
  campaignData: ExistingCampaignData,
  parentApp?: string | undefined,
) => [
  ...(campaignData.whiteListedStateIds || campaignData.blackListedStateIds
    ? [
        {
          field: CampaignInfoField.states,
          api: API.List.States(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedStateIds || '',
                  campaignData.whiteListedStateIds || '',
                ),
              ),
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedCityIds || campaignData.blackListedCityIds
    ? [
        {
          field: CampaignInfoField.cities,
          api: API.List.Cities(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedCityIds || '',
                  campaignData.whiteListedCityIds || '',
                ),
              ),
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedCountyIds || campaignData.blackListedCountyIds
    ? [
        {
          field: CampaignInfoField.counties,
          api: API.List.Counties(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedCountyIds || '',
                  campaignData.whiteListedCountyIds || '',
                ),
              ),
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedDmaIds || campaignData.blackListedDmaIds
    ? [
        {
          field: CampaignInfoField.dma,
          api: API.List.getDMAData(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedDmaIds || '',
                  campaignData.whiteListedDmaIds || '',
                ),
              ),
              sortBy: '+id',
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedHouseDistrictIds || campaignData.blackListedHouseDistrictIds
    ? [
        {
          field: CampaignInfoField.houses,
          api: API.List.Houses(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedHouseDistrictIds || '',
                  campaignData.whiteListedHouseDistrictIds || '',
                ),
              ),
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedSenateDistrictIds || campaignData.blackListedSenateDistrictIds
    ? [
        {
          field: CampaignInfoField.senates,
          api: API.List.Senates(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedSenateDistrictIds || '',
                  campaignData.whiteListedSenateDistrictIds || '',
                ),
              ),
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedCongressionalDistrictIds ||
  campaignData.blackListedCongressionalDistrictIds
    ? [
        {
          field: CampaignInfoField.districts,
          api: API.List.congressionalDistricts(
            {
              segmentIds: convertToNumArray(
                getConcatenatedIds(
                  campaignData.blackListedCongressionalDistrictIds || '',
                  campaignData.whiteListedCongressionalDistrictIds || '',
                ),
              ),
            },
            parentApp,
          ),
        },
      ]
    : []),
  ...(campaignData.whiteListedZipcodes || campaignData.blackListedZipcodes
    ? [
        {
          field: CampaignInfoField.zipcodes,
          api: API.List.getZipCodeData(
            {
              zipCodes: getConcatenatedIds(
                campaignData.blackListedZipcodes || '',
                campaignData.whiteListedZipcodes || '',
              ).split(','),
              sortBy: '+id',
            },
            parentApp,
          ),
        },
      ]
    : []),
];
