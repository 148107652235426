export const stringToNumberArray = (string?: string) =>
  string ? string.split(',').map((el) => Number(el)) : [];

export const stringToArray = (string?: string) => (string ? string.split(',') : []);

export const stringsToArray = (...strings: string[]) =>
  strings
    .join()
    .split(',')
    .filter((v) => v);
