import * as React from 'react';
import { Icon } from 'factor';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
  iconName: string;
  label: string;
  isSelected?: boolean;
}

export const IconButton = (props: Props) => {
  const { onClick, iconName, label, isSelected } = props;
  return (
    <div
      tabIndex={0}
      role="button"
      className={`${styles.button} ${
        typeof isSelected === 'boolean' && isSelected ? styles.buttonSelected : ''
      }`}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          onClick();
          e.stopPropagation();
        }
      }}
      onClick={onClick}
    >
      <Icon name={iconName} />
      {label}
    </div>
  );
};
