import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Alert } from '@applift/factor';
import {
  SelectedConversionsDisplayTable,
  refetchSelectedConversionsTable,
} from '@applift/conversion';
import { getConversionFilter } from 'utils/conversion';
import { AppState } from 'models/Store';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { ConversionType, Conversion } from 'models/Conversion';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import {
  SetConversionTableSelection,
  SetSelectedConversions,
  conversionActions,
} from 'store/conversion/actions';
import { Open, toastActions } from 'store/toast/actions';
import { SOMETHING_WENT_WRONG } from 'constants/messages';
import { ConversionTypeSelection } from './ConversionTypeSelection';
import { ConversionSelectionCard } from './ConversionSelectionCard';
import styles from './styles.module.scss';

interface StateProps {
  isEditingMode: boolean;
  editableCampaign: ExistingCampaignData | null;
  conversionType: ConversionType | null;
  selectedConversions: number[];
  conversionTableSelection: Conversion[];
}

interface DispatchProps extends SetSelectedConversions, SetConversionTableSelection {
  openToast: Open['open'];
}

interface Props extends StateProps, DispatchProps {
  initTableConversionIds: number[];
  setInitTableConversionIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const ConversionBlockContentsComponent = (props: Props) => {
  const {
    isEditingMode,
    editableCampaign,
    selectedConversions,
    conversionType,
    setSelectedConversions,
    openToast,
    initTableConversionIds,
    setInitTableConversionIds,
    setConversionTableSelection,
    conversionTableSelection,
  } = props;
  const [useInitTableForHeight, setUseInitTableForHeight] = React.useState(true);
  const [tempInitTableConversionIds, setTempInitTableConversionIds] = React.useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datetimeOnMount, _setDatetimeOnMount] = React.useState(moment().unix());

  React.useEffect(() => {
    // Force react-query to reload table
    if (tempInitTableConversionIds.length) {
      setInitTableConversionIds(tempInitTableConversionIds);
      setTempInitTableConversionIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempInitTableConversionIds]);

  const onSelectedConversionsLoadError = React.useCallback(
    (_e: any) => {
      openToast(SOMETHING_WENT_WRONG);
    },
    [openToast],
  );

  const handleSelectDialogConversions = React.useCallback(
    (ids: number[]) => {
      setUseInitTableForHeight(true);
      setSelectedConversions(ids);

      // Force react-query to reload table
      setTempInitTableConversionIds(ids);
      setInitTableConversionIds([]);
      refetchSelectedConversionsTable();
    },
    [setInitTableConversionIds, setSelectedConversions],
  );

  const onCreateConversion = React.useCallback(
    (conversionId: number) => {
      setUseInitTableForHeight(true);
      if (conversionType === ConversionType.POSTBACK) {
        setSelectedConversions([conversionId]);
        setInitTableConversionIds([conversionId]);
      } else {
        setSelectedConversions([...selectedConversions, conversionId]);
        setInitTableConversionIds([...selectedConversions, conversionId]);
      }
    },
    [selectedConversions, conversionType, setInitTableConversionIds, setSelectedConversions],
  );

  const handleDisplayTableSelectionChange = React.useCallback(
    (conversions: Conversion[]) => {
      setUseInitTableForHeight(conversions.length === initTableConversionIds.length);
      setConversionTableSelection(conversions);
      setSelectedConversions(conversions.map((conversion: any) => conversion.id));
    },
    [initTableConversionIds, setConversionTableSelection, setSelectedConversions],
  );

  const showPixelAlert = React.useMemo(() => {
    if (conversionType !== ConversionType.PIXEL || !conversionTableSelection?.length) {
      return false;
    }
    return !!conversionTableSelection.find(
      (conversion) => conversion.status?.toLowerCase() === 'pending',
    );
  }, [conversionType, conversionTableSelection]);

  const isRunningCampaign =
    editableCampaign?.startTime && editableCampaign.startTime < datetimeOnMount;
  const showEmptyStateRunningCampaign = !selectedConversions.length && isRunningCampaign;
  const showTableRunningCampaign = selectedConversions.length && isRunningCampaign;
  const showConversionTypeSelection =
    !selectedConversions.length &&
    (!editableCampaign?.startTime || editableCampaign.startTime > datetimeOnMount);

  if (showEmptyStateRunningCampaign) {
    return (
      <Alert severity="info" sx={{ mb: 8 }}>
        Since the campaign has already started serving, new conversions cannot be attached to it.
      </Alert>
    );
  }

  const tableHeight = Math.min(
    40 + (useInitTableForHeight ? initTableConversionIds.length : selectedConversions.length) * 36,
    300,
  );

  if (showTableRunningCampaign) {
    return (
      <>
        <ConversionSelectionCard
          showTitle
          disableChangeConversionSelection
          disableChangeConversionType
          setInitTableConversionIds={setInitTableConversionIds}
          className={styles.buttonsMarginBottom}
        />
        <div className={styles.displayTableWrapper}>
          {!!initTableConversionIds?.length && (
            <SelectedConversionsDisplayTable
              showActionColumn={false}
              initialSelectedIds={initTableConversionIds}
              onSetConversionSelection={setConversionTableSelection}
              onLoadError={onSelectedConversionsLoadError}
              conversionType={getConversionFilter(conversionType)}
              disablePixelTest
              tableHeight={`${tableHeight}px`}
            />
          )}
        </div>
      </>
    );
  }

  if (showConversionTypeSelection) {
    return (
      <div className={styles.conversionTypeSelection}>
        <ConversionTypeSelection setInitTableConversionIds={setInitTableConversionIds} />
        {conversionType !== ConversionType.NONE && !!conversionType ? (
          <ConversionSelectionCard
            showTitle={false}
            setInitTableConversionIds={setInitTableConversionIds}
            disableChangeConversionSelection={editableCampaign ? !isEditingMode : false}
            disableChangeConversionType={editableCampaign ? !isEditingMode : false}
            className={styles.mt32}
            onSelectDialogConversions={handleSelectDialogConversions}
            onCreateConversion={onCreateConversion}
          />
        ) : null}
      </div>
    );
  }

  // Final case: new campaign or editable existing campaign, with a converisonType and 1+ conversions selected
  return (
    <>
      <ConversionSelectionCard
        converisonType={conversionType}
        selectedConversions={selectedConversions}
        showTitle
        setInitTableConversionIds={setInitTableConversionIds}
        disableChangeConversionType={false}
        disableChangeConversionSelection={false}
        onSelectDialogConversions={handleSelectDialogConversions}
        onCreateConversion={onCreateConversion}
      />
      {showPixelAlert && (
        <Alert severity="info" sx={{ mb: 16, marginX: 'auto' }} style={{ width: '900px' }}>
          The pixels with pending status will not record the campaign conversions until they are
          active.
        </Alert>
      )}
      <div className={styles.displayTableWrapper}>
        <SelectedConversionsDisplayTable
          showActionColumn={
            !editableCampaign?.startTime || editableCampaign.startTime > datetimeOnMount
          }
          initialSelectedIds={initTableConversionIds}
          onSetConversionSelection={handleDisplayTableSelectionChange}
          onLoadError={onSelectedConversionsLoadError}
          conversionType={getConversionFilter(conversionType)}
          disablePixelTest
          tableHeight={`${tableHeight}px`}
        />
      </div>
    </>
  );
};

const mapState = (state: AppState) => ({
  isEditingMode: state.app.isEditingMode,
  editableCampaign: state.app.editableCampaign,
  conversionType: state.conversion.conversionType,
  selectedConversions: state.conversion.sidebarCampaignInfo[CampaignInfoField.conversions],
  conversionTableSelection: state.conversion.conversionTableSelection,
});

const mapAction = {
  setSelectedConversions: conversionActions.setSelectedConversions,
  setConversionTableSelection: conversionActions.setConversionTableSelection,
  openToast: toastActions.open,
};

export const ConversionBlockContents = connect<StateProps, DispatchProps, any, AppState>(
  mapState,
  mapAction,
)(ConversionBlockContentsComponent);
