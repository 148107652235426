import React from 'react';
import { ProgressCircle } from 'factor';
import styles from './styles.module.scss';

type TProps = {
  title?: string;
  children: any;
  onClose: () => void;
  loading?: boolean;
};

const Overlay = (props: TProps) => {
  const { onClose, title, children, loading } = props;
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.moveBtnToTheTopLeftCorner}>
          <div className={styles.overlayHeaderCloseBtn}>
            <button onClick={onClose} type="button" className={`btn-close ${styles.btnClose}`} />
          </div>
        </div>
        <div className={styles.overlayBody}>
          <div className={styles.overlayHeader}>
            <h2>{title}</h2>
          </div>
          {children}
        </div>
        {loading && (
          <div className={styles.spinnerContainer}>
            <ProgressCircle size={40} borderWidth={4} className={styles.loading} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Overlay;
