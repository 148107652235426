import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@applift/factor';

export interface ApplyBidShadingDialogType {
  setShowApplyBidShadingDialog: (arg: boolean) => void;
  applyBidShading: () => void;
}

export const ApplyBidShadingDialog = (props: ApplyBidShadingDialogType) => {
  const { setShowApplyBidShadingDialog, applyBidShading } = props;

  return (
    <Dialog open>
      <DialogTitle>Apply Bid Shading?</DialogTitle>
      <DialogContent>
        This action will remove all the advanced modelling applied to the creatives. Are you sure
        you want to apply bid shading?
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setShowApplyBidShadingDialog(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            applyBidShading();
            setShowApplyBidShadingDialog(false);
          }}
        >
          Apply Bid Shading
        </Button>
      </DialogActions>
    </Dialog>
  );
};
