import { AxiosError, AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import {
  PrebidNormalizedSegmentData,
  PrebidProviderData,
  PrebidSearchSegmentsData,
} from '@applift/platform';

import { ALL_CATEGORY_CREATIVE_ID } from 'constants/audience';
import {
  getAudienceListKey,
  getPreBidDataByAudienceIdsKey,
  getPreBidDataBySegmentIdsKey,
} from './QueryKeys';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse, WithPreBidAudienceResponse, WithResponse } from '../models/Response';
import {
  AudienceGroup,
  AudienceSegment,
  AudienceType,
  AudienceItemResponse,
  PreBidSegmentAudienceInfo,
} from '../models/Audience';

export const Segments = async (): Promise<AudienceSegment[]> => {
  try {
    const response: AxiosResponse<AudienceSegment[]> = await getInstance().get(
      '/audience_segments_group_list',
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while getting audience segment list', e);
    return [];
  }
};

export const Groups = async (id: number = 0): Promise<AudienceGroup[]> => {
  try {
    const response: AxiosResponse<AudienceGroup[]> = await getInstance().get(
      `/audience_groups/${id}`,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while getting audience group list', e);
    return [];
  }
};

export const AudienceTypes = async (): Promise<AudienceType[]> => {
  try {
    const response: AxiosResponse<GetResponse<AudienceType[]>> = await getInstance().get(
      `/v2/adv/static/audienceTypes`,
    );
    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting audience types list', e);
    return [];
  }
};

export const List = async (
  audienceTypeIds: string,
  searchField: string,
): Promise<AudienceItemResponse[]> => {
  try {
    const response: AxiosResponse<GetResponse<AudienceItemResponse[]>> = await getInstance().get(
      `/v2/audience/list?audienceTypeIds=${audienceTypeIds}&searchField=${searchField}`,
    );
    return response.data.responseObject || [];
  } catch (e) {
    Logger.log('Error while getting audience types list', e);
    return [];
  }
};

export const getAudiencesStatusInfo = async (campaignIds: string) => {
  try {
    const response: any = await getInstance().get(
      `/v3/cmp/audience/target/list?campaignIds=${campaignIds}`,
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAudiencesList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getAudienceListKey['keys']>>): Promise<any> => {
  const payload = queryKey[0].audienceListParams;
  try {
    const result = await List(payload.audienceTypeIds, payload.searchField);
    return result;
  } catch (e) {
    return [];
  }
};
export const fetchPreBidAudienceInfo = async (audienceId: number) => {
  try {
    const response: AxiosResponse<WithResponse<PreBidSegmentAudienceInfo>> =
      await getInstance().get(`/v3/audience/prebid/${audienceId}`);
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data);
  }
};

export const getPrebidSegmentDataFromIds = async ({
  queryKey,
  meta,
}: QueryFunctionContext<ReturnType<typeof getPreBidDataBySegmentIdsKey['keys']>>) => {
  const { prebidAudienceSegmentIdList } = queryKey[0]!.segmentListParams;
  try {
    const response: AxiosResponse<WithPreBidAudienceResponse<PrebidSearchSegmentsData[]>> =
      await getInstance().post('/v3/audience/prebid/details/search/list', {
        prebidAudienceSegmentIdList,
      });
    const { prebidSegmentData, totalRecords, filteredRecords } = response.data.data;
    const providerDataObject = meta as Record<string, Omit<PrebidProviderData, 'childSegments'>>;
    return {
      totalRecords,
      filteredRecords,
      prebidSegmentData: prebidSegmentData.map((one) => {
        return {
          value: `${one.prebidAudienceSegmentId}`,
          preservedValue: `${one.prebidAudienceSegmentId}`,
          isProvider: false,
          label: one.prebidAudienceSegmentName,
          description: one.description,
          parentPathKey: one.parentPathKey,
          cpm: one.cpm,
          creativeTypeIdList: one.creativeTypeIdList ?? [],
          creativeTypeIdSerialized: (one.creativeTypeIdList ?? []).sort().join(','),
          isLeaf: true,
          subRows: [],
          providerId: one.providerId,
          providerName: providerDataObject?.[`${one.providerId}`].providerName,
          providerLogoUrl: providerDataObject?.[`${one.providerId}`].providerLogoUrl,
          isAllCreativeType:
            (one.creativeTypeIdList ?? []).sort().join(',') === ALL_CATEGORY_CREATIVE_ID.join(','),
        } as PrebidNormalizedSegmentData;
      }),
    };
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getPrebidSegmentDataFromAudienceIds = async ({
  queryKey,
  meta,
}: QueryFunctionContext<ReturnType<typeof getPreBidDataByAudienceIdsKey['keys']>>) => {
  const { prebidAudienceIdList } = queryKey[0]!.segmentListParams;
  try {
    const response: AxiosResponse<WithPreBidAudienceResponse<PrebidSearchSegmentsData[]>> =
      await getInstance().post('/v3/audience/prebid/details/search/list', {
        prebidAudienceIdList,
      });
    const { prebidSegmentData, totalRecords, filteredRecords } = response.data.data;
    const providerDataObject = meta as Record<string, Omit<PrebidProviderData, 'childSegments'>>;
    return {
      totalRecords,
      filteredRecords,
      prebidSegmentData: prebidSegmentData.map((one) => {
        return {
          value: `${one.prebidAudienceSegmentId}`,
          preservedValue: `${one.prebidAudienceSegmentId}`,
          isProvider: false,
          label: one.prebidAudienceSegmentName,
          description: one.description,
          parentPathKey: one.parentPathKey,
          cpm: one.cpm,
          creativeTypeIdList: one.creativeTypeIdList ?? [],
          creativeTypeIdSerialized: (one.creativeTypeIdList ?? []).sort().join(','),
          isLeaf: true,
          subRows: [],
          providerId: one.providerId,
          providerName: providerDataObject?.[`${one.providerId}`].providerName,
          providerLogoUrl: providerDataObject?.[`${one.providerId}`].providerLogoUrl,
          isAllCreativeType:
            (one.creativeTypeIdList ?? []).sort().join(',') === ALL_CATEGORY_CREATIVE_ID.join(','),
        } as PrebidNormalizedSegmentData;
      }),
    };
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};
