import * as React from 'react';
import { Badge, Box, TypoTooltip, sx } from '@applift/factor';

interface DealStatusCellProps {
  active: boolean;
}

export const DealStatusCell = (props: DealStatusCellProps) => {
  const { active } = props;

  return active === true || active === false ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Badge
          size="small"
          variant="dot"
          sx={{ mr: 4 }}
          classes={{
            badge: sx({ bgColor: active ? 'success-500' : 'danger-500' }),
          }}
        />
        {active ? 'Active' : 'Inactive'}
      </Box>
    </TypoTooltip>
  ) : (
    <>—</>
  );
};
