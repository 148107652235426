import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames/bind';
import { Tabs, Snackbar } from 'factor';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { LocationBlockState, ExistingCampaignData } from 'models/ExistingCampaign';
import { ICountryOption, IStateOption, TLocationFileType } from 'models/Location';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ErrorCreatingResponse } from 'models/Response';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
// import { ServerErrorMessage } from 'components/ServerErrorMessage';
import {
  advanceActions,
  SetBlackList,
  SetCampaignSidebarInfo,
  SetWhiteList,
} from 'store/advance/actions';
import { ResetError, applicationActions } from 'store/app/actions';
import { blackListNameByTagName, US_COUNTRY_ID, whiteListNameByTagName } from 'constants/location';
import { BlackLists, WhiteLists } from 'store/advance/reducer';
import { stringsToArray } from 'utils/transformers';
import { collectRequest } from 'utils/transformLocationData';
import { ListedTagsNames, Tags } from '../../MainPage/CampaignPage/LocationBlock/Tags';
import { CountrySelectV2Wrapper } from '../../MainPage/CampaignPage/LocationBlock/CountrySelectV2Wrapper';
import { Map } from '../../MainPage/CampaignPage/LocationBlock/Map';
import { EditableCampaignMarkers } from '../../MainPage/CampaignPage/LocationBlock/EditableCampaignMarkers';
import { DrawingModeSwitcher } from '../../MainPage/CampaignPage/LocationBlock/DrawingModeSwitcher';
import { UploadCustomAreaWrapper } from '../../MainPage/CampaignPage/LocationBlock/UploadCustomAreaWrapper';
import { UploadZipCodeWrapper } from '../../MainPage/CampaignPage/LocationBlock/UploadZipCodeWrapper';
import { MapSearchBox } from '../../MainPage/CampaignPage/LocationBlock/Map/MapSearchBox';

import styles from './LocationBlock.module.scss';

const cx = classNames.bind(styles);

interface Props extends SetCampaignSidebarInfo, ResetError, SetWhiteList, SetBlackList {
  country: ICountryOption | null;
  selectedStates: IStateOption[];
  errorCreating: ErrorCreatingResponse | null;
  editableCampaign: ExistingCampaignData | null;
  isOpenMapVisible: boolean;
  whiteLists: WhiteLists;
  blackLists: BlackLists;
  locationBlockState: LocationBlockState;
}

const tabItems = (isUSA = true, showPolitical = false) => {
  const returnArr = [
    {
      title: 'Custom Area',
      value: 'customArea',
    },
    {
      title: `${isUSA ? 'Zip' : 'Postal'} Codes`,
      value: 'zipCodes',
    },
  ];

  if (isUSA && showPolitical) {
    returnArr.push({ title: 'Political Targeting', value: 'politicalTargeting' });
  }

  return returnArr;
};

const LocationBlockComponent = forwardRef<any, Props>((props, ref) => {
  const {
    setCampaignSidebarInfo,
    country,
    selectedStates,
    errorCreating,
    editableCampaign,
    isOpenMapVisible,
    whiteLists,
    blackLists,
    resetError,
    setWhiteList,
    setBlackList,
    locationBlockState,
  } = props;

  const { google } = window;

  const [extensionError, setExtensionError] = useState<string | JSX.Element>('');
  const [selectedTab, setSelectedTab] = useState<string>('customArea');
  const [addressesSelection, setAddressesSelection] = useState<TLocationFileType>('locations');
  const isMounted = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const getLocationDataForSave = () => {
    const { isEmpty, isOnlyStateSelected, ...others } = collectRequest(locationBlockState);
    if (others.hasError) {
      setSnackbarMessage('Please fix the errors marked in red, before proceeding');
    } else if (isEmpty) {
      setSnackbarMessage('Please select a few custom Area or add Zip codes before proceeding');
      others.hasError = true;
    } else if (isOnlyStateSelected) {
      setSnackbarMessage('Please select atleast one additional filter along with state');
      others.hasError = true;
    } else {
      setSnackbarMessage('');
    }
    return others;
  };

  useImperativeHandle(ref, () => ({
    getLocationDataForSave,
  }));

  const saveToStoreString = useCallback(
    (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => {
      setCampaignSidebarInfo(CampaignInfoField[field], value);
      if (Object.keys(whiteListNameByTagName).includes(field) && whiteLists) {
        const whiteListName = whiteListNameByTagName[field as ListedTagsNames];
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const whiteList = whiteLists[whiteListName] || [];
        const blackList = blackLists[blackListName] || [];

        if (typeof value === 'string' || typeof value === 'number') {
          setWhiteList(whiteListName, new Set([...whiteList, String(value) as any]));
        } else {
          const newValues = value
            .filter((item) => !blackList.includes(String(item.value) as any))
            .map(({ value }) => String(value));
          setWhiteList(whiteListName, new Set(newValues) as any);
        }
      }
      // We want to ensure to remove any id from blackList if it is not availalbe in the values.
      if (Object.keys(blackListNameByTagName).includes(field) && blackLists) {
        const blackListName = blackListNameByTagName[field as ListedTagsNames];
        const blackList = blackLists[blackListName] || [];
        if (typeof value === 'string' || typeof value === 'number') {
          setBlackList(blackListName, new Set([...blackList, String(value) as any]));
        } else {
          const newBlackList = blackList.filter(
            (item) => value.findIndex((one) => (String(one.value) as any) === item) >= 0,
          );
          setBlackList(blackListName, new Set(newBlackList));
        }
      }
      if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
        resetError();
      }
    },
    [
      errorCreating,
      setCampaignSidebarInfo,
      resetError,
      setWhiteList,
      setBlackList,
      whiteLists,
      blackLists,
    ],
  );

  const isSelectedCountryUSA = useCallback(() => {
    return !!(country && country.value === US_COUNTRY_ID);
  }, [country]);

  const handleTabChange = useCallback(
    (item: { value: string }) => {
      setSelectedTab(item.value);
      if (selectedTab !== item.value) {
        setExtensionError('');
      }
    },
    [selectedTab],
  );

  useEffect(() => {
    if (!selectedStates.length && selectedTab === 'politicalTargeting') {
      handleTabChange({ value: 'customArea' });
    }
    if (selectedStates.length > 5 && selectedTab === 'politicalTargeting') {
      handleTabChange({ value: 'customArea' });
    }
  }, [selectedTab, selectedStates, handleTabChange]);

  useEffect(() => {
    if (editableCampaign && !isMounted.current) {
      const { whiteListedZipcodes = '', blackListedZipcodes = '' } = editableCampaign;

      if (whiteListedZipcodes.length || blackListedZipcodes.length) {
        const zipcodes: Option<string>[] = stringsToArray(
          whiteListedZipcodes,
          blackListedZipcodes,
        ).map((i) => ({
          value: i,
          label: i,
        }));
        saveToStoreString('zipcodes')(zipcodes);
      }

      isMounted.current = true;
    }
  }, [editableCampaign, saveToStoreString]);

  useEffect(() => {
    document.body.style.overflow = isOpenMapVisible ? 'hidden' : '';
  }, [isOpenMapVisible]);

  return (
    <div className={styles.locationsWrapper}>
      <div className={styles.campaignsLocationContainer}>
        <div className={styles.topContainer}>
          <div className="row w-100">
            <div className={styles.item}>
              <CountrySelectV2Wrapper />
            </div>
            {/* this will disable filters other than country */}
            {/* <div className={styles.item}>
              <StateSelectV2Wrapper />
              {!selectedStates.length && isSelectedCountryUSA() && (
                <p className={styles.infoText}>Political Targeting available if State selected</p>
              )}
              <ServerErrorMessage errorKey={errorFieldsMapper[CampaignInfoField.states]} />
            </div>

            <div
              className={`${styles.item} ${
                selectedStates.length && isSelectedCountryUSA() ? '' : 'hidden'
              } `}
            >
              <CitySelectWrapper changed={saveToStore('cities')} />
            </div>
            <div
              className={`${styles.item} ${
                selectedStates.length && isSelectedCountryUSA() ? '' : 'hidden'
              }`}
            >
              <CountySelectWrapper changed={saveToStore('counties')} />
            </div> */}
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.tabsWrapper}>
              <Tabs
                items={tabItems(
                  isSelectedCountryUSA(),
                  selectedStates.length > 0 && selectedStates.length < 6,
                )}
                value={selectedTab}
                onChange={handleTabChange}
              />
              <div
                className={`${styles.tabContent} ${selectedTab === 'customArea' ? '' : 'hidden'}`}
              >
                <div className={styles.tabLeftPortion}>
                  <div className={styles.headerText}>Draw Custom Area</div>
                  <DrawingModeSwitcher />
                </div>
                <div className={styles.tabRightPortion}>
                  <UploadCustomAreaWrapper
                    styles={styles}
                    addressesSelection={addressesSelection}
                    setAddressesSelection={setAddressesSelection}
                    extensionError={extensionError}
                    setExtensionError={setExtensionError}
                  />
                </div>
              </div>
              <div className={`${styles.tabContent} ${selectedTab === 'zipCodes' ? '' : 'hidden'}`}>
                <UploadZipCodeWrapper
                  styles={styles}
                  extensionError={extensionError}
                  setExtensionError={setExtensionError}
                  isSelectedCountryUSA={isSelectedCountryUSA}
                  changed={saveToStoreString('zipcodes')}
                />
              </div>
              <div
                className={`${styles.tabContent} ${
                  selectedTab === 'politicalTargeting' ? '' : 'hidden'
                }`}
              >
                {/* this will disable filters other than country */}
                {/* <div className="row w-100 mt-3">
                  <div className="col-6 px-4 pb-4">
                    <DistrictSelectV2Wrapper changed={saveToStore('districts')} />
                  </div>
                  <div className="col-6 px-4 pb-4">
                    <SenateSelectV2Wrapper changed={saveToStore('senates')} />
                  </div>
                  <div className="col-6 px-4 pb-4">
                    <HouseSelectV2Wrapper changed={saveToStore('houses')} />
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className={cx({
                map_container: true,
                map_container__visible: isOpenMapVisible,
                map_container__visible__wide: isOpenMapVisible,
              })}
            >
              <Map />

              {google && !isOpenMapVisible ? (
                <div className={styles.search_wrapper}>
                  <MapSearchBox />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.rightContainer} ref={containerRef}>
            <EditableCampaignMarkers />
            <Tags containerRef={containerRef.current ?? undefined} />
          </div>
        </div>
      </div>
      <Snackbar
        className="geo-restriction"
        open={Boolean(snackbarMessage)}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        showCloseButton
      />
    </div>
  );
});

const mapState = createSelector(
  (state: AppState) => state.advanced.sidebarCampaignInfo,
  (state: AppState) => state.app,
  (state: AppState) => state.map,
  (state: AppState) => state.advanced.whiteLists,
  (state: AppState) => state.advanced.blackLists,
  (state: AppState) => ({ advanced: state.advanced, map: state.map, location: state.location }),
  (sidebarCampaignInfo, appStore, mapStore, whiteLists, blackLists, locationBlockState) => ({
    country: sidebarCampaignInfo[CampaignInfoField.country],
    selectedStates: sidebarCampaignInfo[CampaignInfoField.states],
    dma: sidebarCampaignInfo[CampaignInfoField.dma],
    locationBlockState,
    errorCreating: appStore.errorCreating,
    editableCampaign: appStore.editableCampaign,
    isOpenMapVisible: mapStore.isOpenMapVisible,
    whiteLists,
    blackLists,
  }),
);

const actions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  resetError: applicationActions.resetError,
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
};

export const LocationBlock = connect(mapState, actions, null, { forwardRef: true })(
  LocationBlockComponent,
);
