import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Icon, Chip } from 'factor';
import { AppState } from 'models/Store';
import { Option } from 'models/Option';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { CircleExtended } from 'models/CircleExtended';
import { RectangleExtended } from 'models/RectangleExtended';
import { PolygonExtended, RadiusUnity } from 'models/PolygonExtended';
import { ICountryOption } from 'models/Location';
import { filterFiguresByCountry, getLatLng, TFigure } from 'utils/figures';
import { metersToMiles } from 'utils/format';

import styles from './index.module.scss';

type Options = Option<number>[];

type LocationProps = {
  dma: Options;
  zipcodes: Options;
  districts: Options;
  senates: Options;
  houses: Options;
  cities: Options;
  counties: Options;
};

type Props = LocationProps & {
  preSavedCircles: CircleExtended[];
  preSavedRectangles: RectangleExtended[];
  preSavedPolygons: PolygonExtended[];
  country: ICountryOption | null;
};

const BadgesListingComponent = (props: Props) => {
  const {
    dma,
    districts,
    senates,
    houses,
    preSavedCircles,
    preSavedPolygons,
    preSavedRectangles,
    cities,
    counties,
    country,
    // locationFiles,
    zipcodes,
  } = props;

  const getFilteredFigures = useCallback(
    <T extends TFigure>(figures: T[]) => {
      return filterFiguresByCountry(figures, country);
    },
    [country],
  );

  const circlesWithoutFileInfo = useMemo(
    () => preSavedCircles.filter((circle) => !circle.fileId),
    [preSavedCircles],
  );

  const circles: Option<string>[] = useMemo(
    () =>
      getFilteredFigures(circlesWithoutFileInfo).map((circleData) => ({
        value: circleData.id,
        label: `${circleData.address || getLatLng(circleData)}, ${metersToMiles(
          +circleData.getRadius(),
        ).toFixed(2)}mi`,
        isInclude: circleData.isInclude,
      })),
    [getFilteredFigures, circlesWithoutFileInfo],
  );

  const polygons: Option<string>[] = useMemo(
    () =>
      getFilteredFigures(preSavedPolygons.filter((i) => !i.fileId)).map((polData) => ({
        value: polData.id,
        label: `${polData.address}, ${polData.getRadius(RadiusUnity.mile)}mi`,
        isInclude: polData.isInclude,
      })),
    [getFilteredFigures, preSavedPolygons],
  );

  const rectangles: Option<string>[] = useMemo(
    () =>
      getFilteredFigures(preSavedRectangles).map((polData) => ({
        value: polData.id,
        label: `${polData.address}, ${polData.getRadius(RadiusUnity.mile)}mi`,
        isInclude: polData.isInclude,
      })),
    [getFilteredFigures, preSavedRectangles],
  );

  return (
    <>
      <header className={styles.badgeTitle}>Location Information</header>
      <div className={styles.badgeContainer}>
        {cities.length > 0 && (
          <Chip
            key="cities"
            item={{ label: `${cities.length} Cities` }}
            readonly
            icon={<Icon name="FilterCity" />}
            className={styles.chip}
          />
        )}
        {counties.length > 0 && (
          <Chip
            key="counties"
            item={{ label: `${counties.length} Counties` }}
            readonly
            icon={<Icon name="FilterCounty" />}
            className={styles.chip}
          />
        )}
        {zipcodes.length > 0 && (
          <Chip
            key="zipcodes"
            item={{ label: `${zipcodes.length} Zip Codes` }}
            readonly
            icon={<Icon name="FilterZip" />}
            className={styles.chip}
          />
        )}
        {dma.length > 0 && (
          <Chip
            key="dam"
            item={{ label: `${dma.length} DMA's` }}
            readonly
            icon={<Icon name="FilterDma" />}
            className={styles.chip}
          />
        )}
        {districts.length > 0 && (
          <Chip
            key="districts"
            item={{ label: `${districts.length} Congressional Districts` }}
            readonly
            icon={<Icon name="FilterCongress" />}
            className={styles.chip}
          />
        )}
        {senates.length > 0 && (
          <Chip
            key="senates"
            item={{ label: `${senates.length} State Senates` }}
            readonly
            icon={<Icon name="FilterSenate" />}
            className={styles.chip}
          />
        )}
        {houses.length > 0 && (
          <Chip
            key="houses"
            item={{ label: `${houses.length} State Houses` }}
            readonly
            icon={<Icon name="FilterHouse" />}
            className={styles.chip}
          />
        )}
        {circles.length > 0 && (
          <Chip
            key="circles"
            item={{ label: `${circles.length} Custom Area Circle` }}
            readonly
            icon={<Icon name="FilterRadius" />}
            className={styles.chip}
          />
        )}
        {polygons.length > 0 && (
          <Chip
            key="polygons"
            item={{ label: `${polygons.length} Polygon` }}
            readonly
            icon={<Icon name="FilterPolygon" />}
            className={styles.chip}
          />
        )}
        {rectangles.length > 0 && (
          <Chip
            key="rectangles"
            item={{ label: `${rectangles.length} Squares` }}
            readonly
            icon={<Icon name="Square" />}
            className={styles.chip}
          />
        )}
      </div>
    </>
  );
};

const mapState = (state: AppState) => ({
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  cities: state.advanced.sidebarCampaignInfo[CampaignInfoField.cities],
  counties: state.advanced.sidebarCampaignInfo[CampaignInfoField.counties],
  dma: state.advanced.sidebarCampaignInfo[CampaignInfoField.dma],
  zipcodes: state.advanced.sidebarCampaignInfo[CampaignInfoField.zipcodes],
  districts: state.advanced.sidebarCampaignInfo[CampaignInfoField.districts],
  senates: state.advanced.sidebarCampaignInfo[CampaignInfoField.senates],
  houses: state.advanced.sidebarCampaignInfo[CampaignInfoField.houses],
  preSavedCircles: state.map.preSavedCircles,
  preSavedPolygons: state.map.preSavedPolygons,
  preSavedRectangles: state.map.preSavedRectangles,
  locationFiles: state.location.locationFiles,
});

export const BadgesListing = connect(mapState)(BadgesListingComponent);
