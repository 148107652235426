import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { advertiserConstants } from './constants';
import { PoliticalAdvertiser } from '../../api/Advertiser';

export interface AdvertiserInfoForm {
  name: string;
  address: string;
  phone: string;
  url: string;
  chief: string;
  links: string;
  other: string;
}

export interface AdvertiserState extends AdvertiserInfoForm {
  isPoliticalAdvertising: boolean;
  politicalAdvertiser: PoliticalAdvertiser | null;
  politicalAdvertiserList: PoliticalAdvertiser[];
  isValid: boolean;
  creatingMode: boolean;
  isBidShadingApplicable: boolean;
}

const defaultAdvertiserState: AdvertiserState = {
  isPoliticalAdvertising: false,
  name: '',
  address: '',
  phone: '',
  url: '',
  chief: '',
  links: '',
  other: '',
  politicalAdvertiser: null,
  politicalAdvertiserList: [],
  isValid: false,
  creatingMode: false,
  isBidShadingApplicable: false,
};

const setAdvertiserInfo = (
  state: AdvertiserState,
  action: Action<{ key: string; value: any }>,
): AdvertiserState => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const changePoliticalAdvStatus = (state: AdvertiserState, action: Action<boolean>) => ({
  ...state,
  isPoliticalAdvertising: action.payload,
});

const setPoliticalAdvertiser = (state: AdvertiserState, action: Action<PoliticalAdvertiser>) => ({
  ...state,
  politicalAdvertiser: action.payload,
});

const setPoliticalAdvertiserList = (
  state: AdvertiserState,
  action: Action<PoliticalAdvertiser[]>,
) => ({
  ...state,
  politicalAdvertiserList: action.payload,
});

const setValidStatus = (state: AdvertiserState, action: Action<boolean>) => {
  return {
    ...state,
    isValid: action.payload,
  };
};

const changeCreatingMode = (state: AdvertiserState, action: Action<boolean>) => {
  return {
    ...state,
    creatingMode: action.payload,
  };
};

const reset = (state: AdvertiserState): AdvertiserState => {
  return {
    ...defaultAdvertiserState,
    politicalAdvertiser: state.politicalAdvertiser,
    politicalAdvertiserList: state.politicalAdvertiserList,
    isBidShadingApplicable: state.isBidShadingApplicable,
  };
};

const setBidShadingApplicability = (
  state: AdvertiserState,
  action: Action<boolean>,
): AdvertiserState => {
  return {
    ...state,
    isBidShadingApplicable: action.payload,
  };
};

const reducer = reducerFromMap<AdvertiserState>(defaultAdvertiserState, {
  [advertiserConstants.SET_FIELD]: setAdvertiserInfo,
  [advertiserConstants.SET_POLITICAL_ADV_STATUS]: changePoliticalAdvStatus,
  [advertiserConstants.SET_POLITICAL_ADVERTISER]: setPoliticalAdvertiser,
  [advertiserConstants.SET_POLITICAL_ADVERTISER_LIST]: setPoliticalAdvertiserList,
  [advertiserConstants.SET_POLITICAL_ADVERTISER_VALIDATION_STATUS]: setValidStatus,
  [advertiserConstants.CHANGE_CREATING_MODE]: changeCreatingMode,
  [advertiserConstants.RESET]: reset,
  [advertiserConstants.SET_BID_SHADING_APPLICABILITY]: setBidShadingApplicability,
});

export const advertiser = (state: AdvertiserState = defaultAdvertiserState, action: Action<any>) =>
  reducer(state, action);
