export type TCreativeTypeViewOptions = {
  iconName: string;
  title: string;
  routePath: string;
  tooltipLabel?: string;
};

export type TCreativeTypeIds = 11 | 13 | 14 | 16 | 17 | 18 | 15;
export const FAILED = 'failed';

export type TCreativeTypeViewByCreativeTypeId = {
  [key in TCreativeTypeIds]: TCreativeTypeViewOptions;
};

export const IMAGE_CREATIVE_ID = 11;
export const HTML_CREATIVE_ID = 13;
export const VIDEO_CREATIVE_ID = 14;
export const NATIVE_CREATIVE_ID = 15;
export const TICKER_CREATIVE_ID = 16;
export const AUDIO_CREATIVE_ID = 17;
export const GOTV_CREATIVE_ID = 18;
export const VIDEO = 'video';
export const AUDIO = 'audio';

const creativeTypeViewByCreativeTypeId: TCreativeTypeViewByCreativeTypeId = {
  [IMAGE_CREATIVE_ID]: {
    iconName: 'BannerXS',
    title: 'Image',
    routePath: '/image',
    tooltipLabel: 'Create image ads (including animated GIF)',
  },
  [HTML_CREATIVE_ID]: {
    iconName: 'HtmlXS',
    title: 'HTML',
    routePath: '/html',
    tooltipLabel: 'Create interactive HTML ad',
  },
  [NATIVE_CREATIVE_ID]: {
    iconName: 'CreativeNative',
    title: 'Native',
    routePath: '/native',
  },
  [VIDEO_CREATIVE_ID]: {
    iconName: 'VideoXS',
    title: 'Video',
    routePath: '/video',
  },
  [GOTV_CREATIVE_ID]: {
    iconName: 'gotvXS',
    title: 'GOTV',
    routePath: '/gotv',
  },
  [AUDIO_CREATIVE_ID]: {
    iconName: 'VoiceXS',
    title: 'Audio',
    routePath: '/audio',
  },
  [TICKER_CREATIVE_ID]: {
    iconName: 'scrollXS',
    title: 'Ticker',
    routePath: '/ticker',
  },
};

export default creativeTypeViewByCreativeTypeId;
