import * as React from 'react';
import { Icon } from 'factor';
import { Box, Tooltip } from '@applift/factor';
import capitalize from 'lodash/capitalize';
import { getIcon } from 'registry';

import { Sidebar } from '../../../components/Sidebar';
import { AudienceWarningMessage } from '../../../components/AudienceWarningMessage';
import { statusIconMap } from '../../../models/CampaignInfoFields';
import { CollapseController } from '../../../components/CollapseController';
import { Layout } from '../../../components/Layout';
import { MainContent } from '../../../components/MainContent';
import { CampaignInfoBlock } from './CampaignInfoBlock';
import { CreativeBlock } from './CreativeBlock';
import { TermsBlock } from './TermsBlock';
import { LocationBlock } from './LocationBlock';
import { TechnologyBlock } from './TechnologyBlock';
import { AudiencesBlock } from './AudiencesBlock';
import { BidStrategyBlock } from './BidStrategyBlock';
import { DemographicsBlock } from './DemographicsBlock';
import { InventoryBlock } from './InventoryBlock';
import { SchedulingBlock } from './SchedulingBlock';
import { AdvancedTargetingBlock } from './AdvancedTargetingBlock';
import { ConversionBlock } from './ConversionBlock';
import { SidebarCampaignInfo } from './SidebarCampaignInfo';
import { PreBidAudiencesBlock } from './PreBidAudienceBlock/index';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { scrollToElement } from '../../../utils/scroll';
import { AudienceWarning } from '../../../models/Audience';
import { AdvancePageState } from '../../../store/advance/reducer';

import styles from './styles.module.scss';

interface CampaignProps {
  isAuth: boolean;
  errorIndices: boolean[];
  editableCampaign: ExistingCampaignData | null;
  sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
  audienceWarning: AudienceWarning;
  mode: 'create' | 'edit';
}

export const Campaign = (props: CampaignProps) => {
  const { isAuth, errorIndices, editableCampaign, sidebarCampaignInfo, audienceWarning, mode } =
    props;

  const getTitle = React.useCallback(() => {
    if (sidebarCampaignInfo?.campaignName?.trim()) {
      return sidebarCampaignInfo?.campaignName?.trim();
    }
    if (editableCampaign?.campaignName?.trim()) {
      return editableCampaign?.campaignName?.trim();
    }
    return mode === 'create' ? 'New Campaign' : '';
  }, [editableCampaign, sidebarCampaignInfo.campaignName, mode]);

  const sidebar = (
    <Sidebar
      title={getTitle()}
      icon={
        editableCampaign && statusIconMap[editableCampaign.status] ? (
          <Tooltip title={capitalize(editableCampaign?.status)} placement="right">
            <Box>{getIcon(editableCampaign.status as any, { fontSize: 24 })}</Box>
          </Tooltip>
        ) : (
          <Icon name="Advanced" />
        )
      }
      rightIcon={
        audienceWarning === AudienceWarning.NONE ? null : (
          <AudienceWarningMessage type={audienceWarning} iconOnly />
        )
      }
    >
      <SidebarCampaignInfo />
    </Sidebar>
  );

  React.useEffect(() => {
    scrollToElement();
  }, []);

  return (
    <Layout sidebar={sidebar} className={styles.layout}>
      <MainContent className={styles.mainContent}>
        {isAuth ? <CampaignInfoBlock /> : null}
        <CollapseController collapsibleHasError={errorIndices}>
          {/* if adding any children here do modify errorIndices */}
          <CreativeBlock />
          <AudiencesBlock />
          <PreBidAudiencesBlock />
          <LocationBlock />
          <InventoryBlock />
          <ConversionBlock />
          <SchedulingBlock />
          <DemographicsBlock />
          {isAuth ? <TechnologyBlock /> : null}
          <BidStrategyBlock />
          <AdvancedTargetingBlock />
          <TermsBlock />
        </CollapseController>
      </MainContent>
    </Layout>
  );
};
