import React from 'react';
import { SelectAdvanceComponents, TextField } from '@applift/factor';
import { RowSelectionState } from '@applift/datagrid';
import { AppState } from 'models/Store';
import { connect } from 'react-redux';
import { Option } from 'iqm-framework';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { useLocation } from 'react-router';
import { useExchangesList } from 'hooks/useInventory';
import { ExchangeData, advancedTargetingActions } from 'store/advancedTargeting/actions';

interface LabelValuePair {
  label: string;
  value: string;
}

interface Props {
  editableCampaign: ExistingCampaignData | null;
  submitted: boolean;
  saveDraftSubmitted: boolean;
  setExchanges: (data: ExchangeData) => void;
  selectExchanges: (data: Option<number>[]) => void;
}

const ExchangesSelectComponent = (props: Props) => {
  const { setExchanges, editableCampaign, selectExchanges, submitted, saveDraftSubmitted } = props;

  const { pathname } = useLocation();

  const [search, setSearch] = React.useState('');
  const [exchangesSelection, setExchangesSelection] = React.useState<RowSelectionState>({});

  const { data, isLoading } = useExchangesList();

  const allOptions: { label: string; value: string }[] = React.useMemo(() => {
    const allOptions = data?.data?.data?.map((exchange: { name: any; id: any }) => {
      return {
        label: exchange.name,
        value: exchange.id,
      };
    });
    setExchanges({
      key: 'exchanges',
      value: allOptions ?? [],
    });
    return allOptions?.map((obj: { value: { toString: () => any } }) => ({
      ...obj,
      value: obj.value.toString(),
    }));
  }, [data, setExchanges]);

  // Initialize selection for create mode
  React.useEffect(() => {
    if (allOptions?.length && pathname.includes('campaign-create')) {
      const initialData = allOptions.reduce((prev: any, curr: { value: any }) => {
        return { ...prev, [curr.value]: true };
      }, {});
      setExchangesSelection(initialData);
    }
  }, [allOptions, pathname]);

  // Initialize selection for edit mode
  React.useEffect(() => {
    if (editableCampaign && allOptions?.length) {
      const selectedExchangesFromCampaign = editableCampaign.exchanges;
      let initialData: RowSelectionState = {};
      if (selectedExchangesFromCampaign === '') {
        initialData = allOptions.reduce((prev, curr) => {
          return { ...prev, [curr.value]: true };
        }, {} as RowSelectionState);
      } else {
        initialData =
          selectedExchangesFromCampaign
            ?.split(',')
            ?.filter?.(Boolean)
            ?.reduce((prev, curr) => ({ ...prev, [curr]: true }), {} as RowSelectionState) ?? {};
      }
      setExchangesSelection(initialData);
    }
  }, [editableCampaign, allOptions]);

  // Apply the search filter
  const filterOptions =
    React.useMemo(() => {
      return allOptions?.filter?.((option: { label: string }) =>
        option?.label?.toLocaleLowerCase?.()?.includes?.(search?.toLowerCase?.()?.trim?.()),
      );
    }, [allOptions, search]) ?? [];

  // Updating the store in selection change
  React.useEffect(() => {
    const transformSelection = Object.keys(exchangesSelection)
      .filter((key) => exchangesSelection[key])
      .map((key) => {
        return { label: key, value: Number(key) };
      });
    selectExchanges(transformSelection);
  }, [exchangesSelection, setExchanges, selectExchanges]);

  const hasError =
    (submitted || saveDraftSubmitted) &&
    Object.values(exchangesSelection).filter(Boolean).length === 0;

  return (
    <TextField
      fullWidth
      label="Exchanges"
      select="advanceSelect"
      error={hasError}
      data-not-valid={hasError ? 'true' : 'false'}
      helperText={hasError ? 'Atleast 1 Exchange must be selected' : ''}
      SelectProps={{
        multiple: true,
        onInputChange: (e) => {
          // @ts-ignore
          const val = e.target.value;
          if (typeof val === 'string') {
            setSearch(val);
          }
        },
        defaultExpanded: true,
        data: filterOptions,
        overlay: filterOptions.length === 0 && !isLoading ? 'noResult' : undefined,
        renderListItem: SelectAdvanceComponents.DefaultListOptionItemWrapper<LabelValuePair>({
          disableCheckbox: true,
          disableRowExpansion: true,
          selectionStyle: 'all',
        }),
        slotProps: {
          PaperProps: {
            style: {
              maxWidth: 303,
            },
          },
        },
        renderValue: (val) => {
          const currentSelection = Array.isArray(val) ? val : [];
          const allSelected = allOptions?.every((option: { value: string }) =>
            currentSelection?.some?.((obj) => obj.value === option.value),
          );
          const selectedCount = Array.isArray(currentSelection) && currentSelection.length;
          if (allSelected) {
            return 'All Selected';
            // eslint-disable-next-line
          } else if (selectedCount) return `${selectedCount} Selected`;
          return '';
        },
        rowCount: 0,
        placeholder: 'Select Exchange',
      }}
      value={exchangesSelection}
      onChange={setExchangesSelection}
    />
  );
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
  submitted: state.app.submitted,
  saveDraftSubmitted: state.app.saveDraftSubmitted,
});

const mapAction = {
  selectExchanges: advancedTargetingActions.selectExchanges,
  setExchanges: advancedTargetingActions.setExchanges,
};

export const ExchangesSelect = connect(mapState, mapAction)(ExchangesSelectComponent);
