import React, { useEffect, useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Postmate from 'postmate';
import {
  applicationActions,
  ChangeEditingMode,
  SetEditableCampaign,
  FillAppStore,
  SetEditableCampaignLoadingStatus,
  SetMapLoadingStatus,
} from 'store/app/actions';
import { SetMapReadOnly, SetDrawerMode, SetAreaType, mapActions } from 'store/map/actions';
import { AppState } from 'models/Store';
import { transformDataAsPerCampaign, getParentAppErrors } from 'utils/transformLocationData';
import { CreateLocationParams, ParentAppErrors } from 'models/ExistingCampaign';
import { MapViewBlock } from './mapBlock';

interface Props
  extends FillAppStore,
    ChangeEditingMode,
    SetEditableCampaign,
    SetEditableCampaignLoadingStatus,
    SetMapLoadingStatus,
    SetMapReadOnly,
    SetAreaType,
    SetDrawerMode {
  variant: string;
}

const MapComponent = ({
  changeEditingMode,
  setEditableCampaignLoadingStatus,
  setMapLoadingStatus,
  setEditableCampaign,
  fillAppStore,
  setMapReadOnly,
  setAreaType,
  setDrawerMode,
  variant = 'maps',
}: Props) => {
  const ref = useRef<any>();
  const parent = useRef<any>();
  const [parentAppErrors, setParentAppErrors] = useState<ParentAppErrors | null>(null);
  const initStore = useCallback(
    (initDataStr: any) => {
      const initData = JSON.parse(initDataStr);
      const dataAsCampaign = transformDataAsPerCampaign(initData as CreateLocationParams);
      const errorMessages = getParentAppErrors(initData as CreateLocationParams);
      changeEditingMode(true);
      setEditableCampaignLoadingStatus(true);
      setMapLoadingStatus(true);
      setDrawerMode(null as any);
      setAreaType(undefined as any);
      Promise.resolve(true)
        .then(() => {
          setParentAppErrors(errorMessages);
          setEditableCampaign(dataAsCampaign);
          fillAppStore(dataAsCampaign);
          setMapReadOnly(true);
        })
        .catch(() => {
          changeEditingMode(false);
        })
        .finally(() => {
          setEditableCampaignLoadingStatus(false);
        });
    },
    [
      setAreaType,
      setMapReadOnly,
      changeEditingMode,
      setMapLoadingStatus,
      fillAppStore,
      setEditableCampaignLoadingStatus,
      setEditableCampaign,
      setDrawerMode,
    ],
  );

  useEffect(() => {
    fillAppStore({ isUsingV2API: true } as any);
  }, [fillAppStore]);

  useEffect(() => {
    parent.current = new Postmate.Model({
      initStore,
    });
  }, [initStore]);

  return <MapViewBlock ref={ref} variant={variant} parentAppErrors={parentAppErrors} />;
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
});

const mapAction = {
  changeEditingMode: applicationActions.changeEditingMode,
  setEditableCampaign: applicationActions.setEditableCampaign,
  fillAppStore: applicationActions.fillAppStore,
  setMapReadOnly: mapActions.setMapReadOnly,
  setDrawerMode: mapActions.setDrawerMode,
  setAreaType: mapActions.setAreaType,
  setEditableCampaignLoadingStatus: applicationActions.setEditableCampaignLoadingStatus,
  setMapLoadingStatus: applicationActions.setMapLoadingStatus,
};

export const MapBox = connect(mapState, mapAction)(MapComponent);

export const Maps = () => <MapBox variant="maps" />;

export const MapWithTabs = () => <MapBox variant="mapsWithDetails" />;
