import React from 'react';

import styles from './NumberInput.module.scss';

interface Props {
  className?: string;
  value: number | string;
  onChange: (value: string | number) => void;
}

export class NumberInput extends React.PureComponent<Props> {
  handleChange = (value: string | number) => {
    const { onChange } = this.props;
    onChange(value);
  };

  handleChangeInput =
    () =>
    ({ target }: { target: HTMLInputElement }) => {
      this.handleChange(target.value);
    };

  handleButtonClick = (value: number) => () => {
    const { value: propsValue } = this.props;
    let newValue = +propsValue + value;
    if (newValue < 0) {
      newValue = 0;
    }
    this.handleChange(newValue);
  };

  render() {
    const { className = '', value } = this.props;

    return (
      <div data-qa="10" className={`${styles.container} ${className}`}>
        <div
          data-qa="11"
          aria-hidden
          className={`${styles.btn} ${styles.minus}`}
          onClick={this.handleButtonClick(-1)}
        />
        <input
          data-qa="12"
          type="number"
          className={styles.field}
          value={value}
          onChange={this.handleChangeInput()}
        />
        <div
          data-qa="13"
          aria-hidden
          className={`${styles.btn} ${styles.plus}`}
          onClick={this.handleButtonClick(1)}
        />
      </div>
    );
  }
}
