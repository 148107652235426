import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';
import { DEFAULT_PG_FORM_STATE, PgCreationApiRef, PgCreationForm } from '@applift/inventory';
import { CreatePgDealPayload } from 'models/DealGroup';
import { useCreatePgDeals } from 'hooks/useInventory';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { PaymentTypeObject } from '@applift/inventory/lib/models/Filters';

export interface CreatePgDealsDialogType {
  onClose: () => void;
  refetchPgDealsList: () => void;
}

export interface CreatePgDealPayloadState {
  dealId: string;
  dealName: string;
  exchange: {
    label: string;
    value: number;
  }[];
  dealStatus: 1;
  paymentTo: {
    name: string;
    id: number;
    label: string;
    order: number;
  }[];
  description: string;
}

const dealInfoPlatform = {
  name: 'platform',
  id: 2,
  label: 'Platform',
  order: 2,
};

export const CreatePgDealDialog = (props: CreatePgDealsDialogType) => {
  const { onClose, refetchPgDealsList } = props;

  const [dealInfo, setDealInfo] = React.useState<CreatePgDealPayloadState>();
  const addDealRef = React.useRef<React.Ref<PgCreationApiRef> | undefined>();

  const [pgDealIds, setPgDealsIds] = useStore((state: StoreFilter) => [
    state.inventoryBlock.pgDealIds,
    state.inventoryBlock.setPgDealsIds,
  ]);

  const createPgDealMutation = useCreatePgDeals({
    onError: (res) => {
      const errorMessage = res?.errorObjects?.[0]?.error as string;
      if (errorMessage.includes('already exists')) {
        // @ts-ignore
        addDealRef.current?.setError({
          dealId:
            'Deal ID already exists for selected exchange. Use a different Deal ID or Exchange',
        });
      } else if (res.errorObjects?.length) {
        enqueueSnackbar(res?.errorObjects[0]?.error, { variant: 'error' });
      }
    },
    onSuccess: (res) => {
      if (res.success) {
        enqueueSnackbar('PG Deal added successfully', { variant: 'success' });
      }
      setPgDealsIds(res.data?.id ? [...pgDealIds, res.data?.id] : []);
      refetchPgDealsList();
      onClose();
    },
  });

  const defaultValue = React.useMemo(() => {
    return {
      ...DEFAULT_PG_FORM_STATE,
      paymentTo: [dealInfoPlatform] as PaymentTypeObject[],
    };
  }, []);

  const disableAddPgDealButton = React.useMemo(() => {
    return (
      dealInfo?.dealId &&
      dealInfo?.dealName &&
      dealInfo?.dealStatus &&
      dealInfo?.exchange?.length &&
      dealInfo?.paymentTo?.length
    );
  }, [
    dealInfo?.dealId,
    dealInfo?.dealName,
    dealInfo?.dealStatus,
    dealInfo?.exchange?.length,
    dealInfo?.paymentTo?.length,
  ]);

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle>Add Deal</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mt: 8 }}>
          <PgCreationForm
            disabledStates={{ dealStatus: true }}
            // @ts-ignore
            ref={addDealRef}
            // @ts-ignore
            onChange={(val: CreatePgDealPayload) => setDealInfo(val)}
            initValue={defaultValue}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={createPgDealMutation.isLoading}
          disabled={!disableAddPgDealButton}
          onClick={() => {
            createPgDealMutation.mutate({
              dealId: dealInfo?.dealId,
              dealName: dealInfo?.dealName,
              description: dealInfo?.description,
              statusId: dealInfo?.dealStatus,
              exchangeId: dealInfo?.exchange.map((val) => val.value)[0],
              paymentTo: dealInfo?.paymentTo.map((val) => val.id)[0],
            });
          }}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
