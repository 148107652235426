import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogButtons, DialogContent, Icon } from 'factor';
import { connect } from 'react-redux';
import { StatusPending } from '@applift/icons';

import { AudienceWarning } from 'models/Audience';
import { ValidateCreativeStore, creativesActions } from 'store/creatives/actions';
import { ValidatePublisherStore, publisherActions } from 'store/publishers/actions';
import { ValidateTechnologyStore, technologyActions } from 'store/technology/actions';
import { ValidateBidStore, bidActions } from 'store/bid/actions';
import { AUDIENCE_SAVE_DIALOG_WARNING } from 'constants/messages';
import { Conversion } from 'models/Conversion';
import { AppState } from 'models/Store';
import { AdvancePageState, WhiteLists } from 'store/advance/reducer';
import { MapState } from 'store/map/reducer';
import { advancedTargetingActions } from 'store/advancedTargeting/actions';

import { getLocationWarningVisibility, getPixelConversionWarningVisibility } from './helpers';
import {
  applicationActions,
  CreateCampaign,
  SetSubmitted,
  SetSaveDraftSubmitted,
  CreateCampaignWithAdvertiser,
  SaveCampaignAsDraft,
  SetErrorCollapsiblesOpen,
} from '../../../../store/app/actions';
import { PoliticalAdvertiser } from '../../../../api/Advertiser';
import { ExistingCampaignData } from '../../../../models/ExistingCampaign';

import styles from './PageButtons.module.scss';

interface ButtonsProps
  extends CreateCampaign,
    SetErrorCollapsiblesOpen,
    SaveCampaignAsDraft,
    CreateCampaignWithAdvertiser,
    SetSubmitted,
    SetSaveDraftSubmitted,
    ValidateCreativeStore,
    ValidateTechnologyStore,
    ValidatePublisherStore,
    ValidateBidStore {
  isPoliticalAdvertising: boolean;
  whiteLists: WhiteLists;
  politicalAdvertiser: PoliticalAdvertiser | null;
  isEditingMode: boolean;
  editableCampaign: ExistingCampaignData | null;
  editableCampaignIsLoading: boolean;
  mapIsLoading: boolean;
  audienceWarning: AudienceWarning;
  sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
  mapData: MapState;
  isOrBasedLocationTargeting: boolean;
  selectedConversions: Conversion[];
  validateExchanges: () => void;
}

const ButtonsComponent = (props: ButtonsProps) => {
  const {
    isEditingMode,
    editableCampaign,
    editableCampaignIsLoading,
    mapIsLoading,
    setSaveDraftSubmitted,
    saveCampaignAsDraft,
    setSubmitted,
    validateCreativeStore,
    validatePublisherStore,
    validateTechnologyStore,
    validateBidStore,
    setErrorCollapsiblesOpen,
    isPoliticalAdvertising,
    politicalAdvertiser,
    createCampaign,
    createCampaignWithAdvertiser,
    audienceWarning,
    sidebarCampaignInfo,
    mapData,
    whiteLists,
    isOrBasedLocationTargeting,
    selectedConversions,
    validateExchanges,
  } = props;

  const [isAudienceWarningOpen, setAudienceWarningVisibility] = useState(false);
  const [isLocationWarningOpen, setLocationWarningVisibility] = useState(false);
  const [isPixelConversionWarningOpen, setPixelConversionWarningVisibility] = useState(false);

  const history = useHistory();

  const handleSaveDraft = () => {
    setSaveDraftSubmitted();
    saveCampaignAsDraft(history);
  };

  const handleSave = () => {
    setSubmitted();
    validateCreativeStore();
    validatePublisherStore();
    validateTechnologyStore();
    validateBidStore();
    validateExchanges();

    setErrorCollapsiblesOpen(true);
    if (isPoliticalAdvertising && !politicalAdvertiser) {
      createCampaignWithAdvertiser(history);
    } else {
      createCampaign(history);
    }
  };

  const onClickSave = () => {
    const showAudienceWarning =
      editableCampaign?.status === 'running' && audienceWarning === AudienceWarning.ALL_UNAPPROVED;
    const showLocationWarning =
      isOrBasedLocationTargeting &&
      getLocationWarningVisibility(sidebarCampaignInfo, mapData, whiteLists);
    const showPixelConversionWarning = getPixelConversionWarningVisibility(selectedConversions);
    if (!showAudienceWarning && !showLocationWarning && !showPixelConversionWarning) {
      handleSave();
    } else {
      if (showAudienceWarning) {
        setAudienceWarningVisibility(true);
      }
      if (showLocationWarning) {
        setLocationWarningVisibility(true);
      }
      if (showPixelConversionWarning) {
        setPixelConversionWarningVisibility(true);
      }
    }
  };

  const onCloseDialog = () => {
    setAudienceWarningVisibility(false);
    setLocationWarningVisibility(false);
    setPixelConversionWarningVisibility(false);
  };

  const handleSaveFromDialog = () => {
    onCloseDialog();
    handleSave();
  };

  const dialogButtons = [
    {
      handler: onCloseDialog,
      title: `${isLocationWarningOpen ? 'Go Back' : 'Cancel'}`,
      className: '_md',
    },
    {
      handler: handleSaveFromDialog,
      title: `${isEditingMode ? 'Save' : 'Run'} Anyway`,
      className: '_filled _conflower-blue _md',
    },
  ];

  const getWarningText = () => {
    const errorCount =
      (isAudienceWarningOpen ? 1 : 0) +
      (isLocationWarningOpen ? 1 : 0) +
      (isPixelConversionWarningOpen ? 1 : 0);
    const locationWarning =
      errorCount === 1 ? (
        <>
          The locations marked with{' '}
          <span>
            <Icon name="ErrorTriangle" />
          </span>{' '}
          in the targeted list are already covered in the state targeting. Are you sure you want to
          continue?
        </>
      ) : (
        <>
          The locations marked with{' '}
          <span>
            <Icon name="ErrorTriangle" />
          </span>{' '}
          in the targeted list are already covered in the state targeting.
        </>
      );
    const pixelConversionWarning =
      errorCount === 1 ? (
        <>
          The pixels with <span className={styles.bold}>pending status</span> will not record the
          campaign conversions until they are active. Are you sure you want to run the campaign?
        </>
      ) : (
        <>
          The pixels with
          <StatusPending
            fontSize={16}
            sx={{ textColor: 'warning-400', marginX: 4 }}
            className={styles.statusPending}
          />
          <span className={styles.bold}>pending</span> status will not record the campaign
          conversions until they are active.
        </>
      );
    if (errorCount) {
      const className = errorCount > 1 ? 'mb-3' : '';
      return (
        <div>
          {isAudienceWarningOpen ? (
            <div className={className}>{AUDIENCE_SAVE_DIALOG_WARNING}</div>
          ) : (
            ''
          )}
          {isPixelConversionWarningOpen ? (
            <div className={className}>{pixelConversionWarning}</div>
          ) : (
            ''
          )}
          {isLocationWarningOpen ? <div className={className}>{locationWarning}</div> : ''}
          {errorCount > 1 ? (
            <div className={styles.bold}>Are you sure you want to continue?</div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const dialog = (
    <Dialog
      open={isAudienceWarningOpen || isLocationWarningOpen || isPixelConversionWarningOpen}
      onClickOutside={onCloseDialog}
    >
      <DialogContent>
        <h3 className="title-card mb-3">{`${isEditingMode ? 'Save' : 'Run'} Campaign?`}</h3>
        <h5 className={`title-card-subtitle mb-3 ${styles.warningText}`}>{getWarningText()}</h5>
        <DialogButtons buttons={dialogButtons} />
      </DialogContent>
    </Dialog>
  );

  if (editableCampaignIsLoading || mapIsLoading) {
    return null;
  }

  return isEditingMode &&
    editableCampaign &&
    !['draft', 'rejected'].includes(editableCampaign.status) ? (
    <>
      <Button className="btn-square _md _filled _conflower-blue " onClick={onClickSave}>
        Save
      </Button>
      {ReactDOM.createPortal(dialog, document.body)}
    </>
  ) : (
    <>
      <Button className="btn-square _md _conflower-blue mr-3" onClick={handleSaveDraft}>
        Save as Draft
      </Button>
      <Button className="btn-square _md _filled _conflower-blue" onClick={onClickSave}>
        Run
      </Button>
      {ReactDOM.createPortal(dialog, document.body)}
    </>
  );
};

const mapState = (state: AppState) => ({
  politicalAdvertiser: state.advertiser.politicalAdvertiser,
  isPoliticalAdvertising: state.advertiser.isPoliticalAdvertising,
  isEditingMode: state.app.isEditingMode,
  editableCampaign: state.app.editableCampaign,
  editableCampaignIsLoading: state.app.editableCampaignIsLoading,
  mapIsLoading: state.app.mapIsLoading,
  audienceWarning: state.audience.audienceWarning,
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  whiteLists: state.advanced.whiteLists,
  mapData: state.map,
  isOrBasedLocationTargeting: state.location.isLocationWithOrFilter,
  selectedConversions: state.conversion.conversionTableSelection,
});

const mapAction = {
  createCampaign: applicationActions.createCampaign,
  saveCampaignAsDraft: applicationActions.saveCampaignAsDraft,
  setSubmitted: applicationActions.setSubmitted,
  setSaveDraftSubmitted: applicationActions.setSaveDraftSubmitted,
  createCampaignWithAdvertiser: applicationActions.createCampaignWithAdvertiser,
  setErrorCollapsiblesOpen: applicationActions.setErrorCollapsiblesOpen,
  validateCreativeStore: creativesActions.validateCreativeStore,
  validatePublisherStore: publisherActions.validatePublisherStore,
  validateTechnologyStore: technologyActions.validateTechnologyStore,
  validateBidStore: bidActions.validateBidStore,
  validateExchanges: advancedTargetingActions.validateExchanges,
};

export const Buttons = connect(mapState, mapAction)(ButtonsComponent);

export class PageHeaderButtons extends React.PureComponent {
  container: HTMLElement | null = null;

  constructor(props: any) {
    super(props);

    this.setElement();
  }

  componentDidMount(): void {
    if (!this.container) {
      this.setElement();
      this.forceUpdate();
    }
  }

  setElement = () => {
    this.container = window.document.getElementById('topNavButtons');
  };

  render() {
    if (!this.container) {
      return null;
    }
    return ReactDOM.createPortal(<Buttons />, this.container);
  }
}
