import React from 'react';
import { connect } from 'react-redux';
import { Switch, Icon } from 'factor';

import { CircleExtended } from 'models/CircleExtended';
import { NumberInput } from 'components/NumberInput';
import {
  mapActions,
  RemoveSelectedCirclesFromPreSaved,
  UpdatePreSavedCircle,
} from 'store/map/actions';
import { AppState } from 'models/Store';
import { metersToMiles, milesToMeters } from 'utils/format';

import styles from './styles.module.scss';

interface Props extends RemoveSelectedCirclesFromPreSaved, UpdatePreSavedCircle {
  className?: string;
  isInclude: boolean;
  selectedAreasIds: string[];
  preSavedCircles: CircleExtended[];
  setAreaInclude: (isInclude: boolean) => void;
  displayType: 'selected' | 'default';
}

const switchList = [
  <span data-qa="417" className={`${styles.switchItem} _include`}>
    <i data-qa="418" className={styles.switchIcon}>
      <Icon name="Location" />
    </i>
    Include
  </span>,
  <span data-qa="419" className={`${styles.switchItem} _exclude`}>
    <i data-qa="420" className={styles.switchIcon}>
      <Icon name="Location" />
    </i>
    Exclude
  </span>,
];

class RadiusSwitcherComponent extends React.PureComponent<Props> {
  handleSwitchChange = (isExclude: boolean) => {
    const { setAreaInclude } = this.props;
    setAreaInclude(!isExclude);
  };

  isCircleSelected = () =>
    this.props.preSavedCircles.some((circle) => this.props.selectedAreasIds.includes(circle.id));

  handleNumberInputChange = (radius: number | string) => {
    if (+radius > 0) {
      const radiusInMeters = milesToMeters(+radius);
      if (this.isCircleSelected()) {
        this.changeRadiusOfActiveCircles(radiusInMeters);
      }
    }
  };

  changeRadiusOfActiveCircles = (radius: number) => {
    const { selectedAreasIds, preSavedCircles, updatePreSavedCircle } = this.props;
    preSavedCircles.forEach((circle) => {
      if (selectedAreasIds.includes(circle.id)) {
        circle.setRadius(radius);
        updatePreSavedCircle(circle);
      }
    });
  };

  getRadiusOfActiveCircle = () => {
    const { selectedAreasIds, preSavedCircles } = this.props;
    let radiusValue = 0.5;
    preSavedCircles.forEach((circle) => {
      if (selectedAreasIds.includes(circle.id)) {
        radiusValue = metersToMiles(circle.getRadius());
      }
    });
    return radiusValue;
  };

  render() {
    const { className, isInclude } = this.props;
    return (
      <div data-qa="421" className={`${styles.container} ${className}`}>
        {false && (
          <Switch
            className={styles.switch}
            size="md"
            list={switchList}
            name="radius-switch"
            value={!isInclude}
            onChange={this.handleSwitchChange}
          />
        )}
        <span data-qa="422" className={styles.label}>
          Radius(Miles)
        </span>
        <NumberInput
          className={styles.input}
          value={
            this.isCircleSelected() ? this.getRadiusOfActiveCircle().toFixed(2) : (0.5).toFixed(2)
          }
          onChange={this.handleNumberInputChange}
        />
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  isInclude: state.map.isInclude,
  selectedAreasIds: state.map.selectedAreasIds,
  preSavedCircles: state.map.preSavedCircles,
  displayType: state.map.displayRadiusType,
});

const actions = {
  setAreaInclude: mapActions.setAreaInclude,
  pushPreSavedCircle: mapActions.pushPreSavedCircle,
  removeSelectedCirclesFromPreSaved: mapActions.removeSelectedCirclesFromPreSaved,
  updatePreSavedCircle: mapActions.updatePreSavedCircle,
};

export const RadiusSwitcher = connect(mapState, actions)(RadiusSwitcherComponent);
