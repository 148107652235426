import React from 'react';
import { connect } from 'react-redux';
import { DrawingManager } from '@react-google-maps/api';

import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Polygon, OverlayType, Rectangle, MIN_POLYGON_PATH } from 'models/Google';
import { PolygonExtended } from 'models/PolygonExtended';
import { RectangleExtended } from 'models/RectangleExtended';
import { AppState } from 'models/Store';
import { ICountryOption } from 'models/Location';
import { mapActions, PushPreSavedPolygon, SetToastMessage } from 'store/map/actions';
import { SELECTION_COUNTRY_STATE, SOMETHING_WENT_WRONG } from 'constants/messages';
import { shapeType } from 'constants/location';
import { Open, toastActions } from 'store/toast/actions';

interface Props extends PushPreSavedPolygon, SetToastMessage {
  drawerMode: OverlayType | undefined;
  isInclude: boolean;
  pushPreSavedRectangle: (r: RectangleExtended) => void;
  country: ICountryOption | null;
  setDrawerMode: any;
  openToast: Open['open'];
}

class MapDrawingComponent extends React.PureComponent<Props> {
  onFigureComplete = (figure: PolygonExtended | RectangleExtended): void => {
    const { country, setToastMessage, openToast } = this.props;
    if (country?.shortName !== figure.country) {
      if (window.self !== window.top) {
        setToastMessage(SELECTION_COUNTRY_STATE);
      } else {
        openToast(SELECTION_COUNTRY_STATE);
      }
    }
  };

  onRectangleComplete = async (rectangle: Rectangle) => {
    const { pushPreSavedRectangle, isInclude, setToastMessage, setDrawerMode, openToast } =
      this.props;
    const rectangleInstance = new RectangleExtended({ rectangle, isInclude });
    try {
      await rectangleInstance.update();
      this.onFigureComplete(rectangleInstance);
      pushPreSavedRectangle(rectangleInstance);
      setDrawerMode(null);
      rectangle.setMap(null);
    } catch (error) {
      if (window.self !== window.top) {
        setToastMessage(SOMETHING_WENT_WRONG);
      } else {
        openToast(SOMETHING_WENT_WRONG);
      }
    }
  };

  onPolygonComplete = async (polygon: Polygon) => {
    const { pushPreSavedPolygon, isInclude, setToastMessage, setDrawerMode, openToast } =
      this.props;
    const polygonInstance = new PolygonExtended({ polygon, isInclude });
    if (polygonInstance.getPolyPath().length < MIN_POLYGON_PATH) {
      polygon.setMap(null);
      return;
    }
    try {
      await polygonInstance.update();
      this.onFigureComplete(polygonInstance);
      pushPreSavedPolygon(polygonInstance);
      setDrawerMode(null);
      polygon.setMap(null);
    } catch (error) {
      if (window.self !== window.top) {
        setToastMessage(SOMETHING_WENT_WRONG);
      } else {
        openToast(SOMETHING_WENT_WRONG);
      }
    }
  };

  render() {
    const { drawerMode } = this.props;
    if (drawerMode === shapeType.circle) {
      return null;
    }
    const fillColor = '#28bc97';
    const fillOpacity = 0.5;

    return (
      <DrawingManager
        options={{
          drawingControl: false,
          polygonOptions: {
            editable: true,
            draggable: true,
            fillColor,
            fillOpacity,
          },
          rectangleOptions: {
            editable: true,
            draggable: true,
            fillColor,
            fillOpacity,
          },
        }}
        drawingMode={drawerMode}
        onPolygonComplete={this.onPolygonComplete}
        onRectangleComplete={this.onRectangleComplete}
      />
    );
  }
}

const mapState = (state: AppState) => ({
  isInclude: state.map.isInclude,
  drawerMode: state.map.drawerMode,
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
});

const actions = {
  pushPreSavedPolygon: mapActions.pushPreSavedPolygon,
  pushPreSavedRectangle: mapActions.pushPreSavedRectangle,
  setToastMessage: mapActions.setToastMessage,
  setDrawerMode: mapActions.setDrawerMode,
  openToast: toastActions.open,
};

export const MapDrawing = connect(mapState, actions)(MapDrawingComponent);
