import React from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { Snackbar } from 'factor';

import styles from './MainContent.module.scss';
import { AppState } from '../../models/Store';
import { toastActions } from '../../store/toast/actions';

interface Props {
  className?: string;
  isRightSidebarOpen: boolean;
  children: React.ReactNode;
  toastOpened: boolean;
  toastMessage: string | JSX.Element;
  closeToast: () => void;
}

const MainContentComponent = (props: Props) => {
  const {
    className = '',
    isRightSidebarOpen,
    children,
    toastMessage,
    toastOpened,
    closeToast,
  } = props;

  return (
    <>
      <section
        data-qa="4"
        className={`${styles.container} ${className} ${
          isRightSidebarOpen ? '_right-sidebar-opened' : ''
        }`}
      >
        {children}
      </section>
      {createPortal(
        <Snackbar
          open={toastOpened}
          onClose={closeToast}
          message={toastMessage}
          showCloseButton
          className={styles.snackbar}
        />,
        document.body,
      )}
    </>
  );
};

const mapState = (state: AppState) => {
  return {
    isRightSidebarOpen: state.app.isRightSidebarOpen,
    toastOpened: state.toast.isOpen,
    toastMessage: state.toast.message,
  };
};

const mapActions = {
  closeToast: toastActions.close,
};

export const MainContent = connect(mapState, mapActions)(MainContentComponent);
