import { CampaignInfoField } from '../models/CampaignInfoFields';

export const errorFieldsMapper: { [key: string]: string } = {
  campaignName: 'campaignName',
  selectedPublishers: 'publisherAdCategory',
  dailyBudget: 'budgetDay',
  maxBid: 'maxBid',
  carriers: 'carriers',
  totalBudget: 'budgetTotal',
  [CampaignInfoField.deviceTypes]: 'deviceType',
  [CampaignInfoField.manufacturers]: 'manufacturer',
  [CampaignInfoField.os]: 'os',
  [CampaignInfoField.carriers]: 'carriers',
  [CampaignInfoField.network]: 'networkType',
  [CampaignInfoField.traffic]: 'trafficType',
  [CampaignInfoField.states]: 'stateIds',
  [CampaignInfoField.dma]: 'dmaIds',
  [CampaignInfoField.districts]: 'congressionalDistrictIds',
  [CampaignInfoField.senates]: 'senateDistrictIds',
  [CampaignInfoField.houses]: 'houseDistrictIds',
  targetCpiCpa: 'targetCPI',
  appUrl: 'appURL',
  impressionsMaxDaily: 'maxDayImpressions',
  impressionsMaxTotal: 'totalImpressions',
  clicksMaxDaily: 'maxDayClicks',
  clicksMaxTotal: 'totalClicks',
  conversionsMaxDaily: 'maxDayConversions',
  conversionsMaxTotal: 'totalConversions',
  creativeType: 'creativeType',
  creativeIds: 'creativeIds',
  campaignGroups: 'campaignGroupIds',
  timezone: 'timezone',
  startDate: 'startTime',
  endDate: 'endTime',
  advertiserDomain: 'advertiserDomain',
  whiteListedDeviceId: 'whiteListedDeviceId',
  whiteListedIp: 'whiteListedIp',
  whiteListedPackageName: 'whiteListedPackageName',
  whiteListedAppId: 'whiteListedAppId',
  whiteListedSiteDomain: 'whiteListedSiteDomain',
  blackListedDeviceId: 'blackListedDeviceId',
  blackListedIp: 'blackListedIp',
  blackListedPackageName: 'blackListedPackageName',
  blackListedAppId: 'blackListedAppId',
  blackListedSiteDomain: 'blackListedSiteDomain',
  whiteListedInventoryGroupIds: 'whiteListedInventoryGroupIdList',
  blackListedInventoryGroupIds: 'blackListedInventoryGroupIds',
};

export const politicalAdvErrorFieldsMapper: { [key: string]: string } = {
  name: 'name',
  address: 'address',
  phone: 'phone',
  url: 'website',
  chief: 'chiefName',
  links: 'links',
  other: 'legalInfo',
};
