import React, { useState, useEffect, useRef, useCallback } from 'react';
import Axios from 'axios';
import { Popover } from 'react-tiny-popover';
import { Icon, Checkbox, ProgressCircle } from 'factor';

import { ICountryOption, ILocationFilter, ILocationTypeWithOptions } from 'models/Location';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { SetToastMessage } from 'store/map/actions';
import { US_COUNTRY_ID } from 'constants/location';
import { Option } from 'models/Option';
import { API } from 'api';
import SearchBar from 'pages/MainPage/CampaignPage/CreativeBlock/SelectCreativesPopup/creativesManagePanel/searchBar/SearchBar';
import { LocationDropdown } from './LocationDropdown';

import styles from './index.module.scss';

interface Props extends SetToastMessage {
  saveLocations: (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => void;
  country: ICountryOption | null;
  isOpenMapsVisible?: boolean;
  selectedTab: string;
  parent?: string;
}

const iconMapper: {
  [key: number]: string;
} = {
  302: 'FilterState',
  303: 'FilterCounty',
  304: 'FilterCity',
  306: 'FilterCongress',
  307: 'FilterSenate',
  308: 'FilterHouse',
  309: 'FilterDma',
};

export const LocationWrapper = (props: Props) => {
  const { saveLocations, country, isOpenMapsVisible, setToastMessage, selectedTab, parent } = props;
  const [searchValue, setSearchValue] = useState('');
  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<number[]>([]);
  const [filters, setFilters] = useState<ILocationFilter[]>([]);
  const [locationData, setLocationData] = useState<ILocationTypeWithOptions>({});
  const [dataFetch, setDataFetchInfo] = useState({ inProgress: false, isSuccess: true });
  const cancelTokenSource = useRef(Axios.CancelToken.source());
  const searchBarRef = useRef<HTMLDivElement>(null);

  const isSelectedCountryUSA = useCallback(() => {
    return !!(country?.value === US_COUNTRY_ID);
  }, [country]);

  useEffect(() => {
    if (selectedTab !== 'location') {
      setSearchValue('');
    }
  }, [selectedTab]);

  useEffect(() => {
    const fetchFilters = async () => {
      const response = await API.Location.fetchLocationFilters();
      if (response?.success && response.data) {
        setFilters(response.data);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      setLocationData({});
      cancelTokenSource.current?.cancel?.();
      cancelTokenSource.current = Axios.CancelToken.source();
      try {
        const response = await API.Location.fetchLocations(
          cancelTokenSource.current,
          searchValue.trimEnd().trimStart(),
          appliedFilters.join(','),
          country?.value.toString() as string,
        );
        if (response?.success) {
          setDataFetchInfo({ inProgress: false, isSuccess: true });
          setLocationData(response.data as ILocationTypeWithOptions);
        }
      } catch (e) {
        if (e?.response?.data && !Axios.isCancel(e.response.data)) {
          setDataFetchInfo({ inProgress: false, isSuccess: false });
        } else {
          setDataFetchInfo({ inProgress: searchValue.trim().length > 1, isSuccess: true });
        }
      }
    };
    if (searchValue.trim().length > 1) {
      setDataFetchInfo({ inProgress: true, isSuccess: true });
      fetchLocations();
    }
  }, [searchValue, appliedFilters, country, setToastMessage]);

  useEffect(() => {
    setPopoverVisibility(searchValue.trim().length > 1);
    if (searchValue.trim().length < 1) {
      setAppliedFilters([]);
    }
  }, [searchValue]);

  useEffect(() => {
    // logic to monitor width of search bar and set dropdown popover width accordingly
    const searchBarContainerDiv = document.getElementById(
      `searchBarContainer${isOpenMapsVisible ? '-openmap' : ''}`,
    ) as HTMLDivElement;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries) => {
      // @ts-ignore
      entries.forEach((entry: ResizeObserverEntry) => {
        const popoverElement = document.getElementsByClassName(
          'location-popover',
        )?.[0] as HTMLDivElement;
        if (popoverElement) {
          popoverElement.style.width = `${entry.contentRect.width}px`;
        }
      });
    });
    resizeObserver.observe(searchBarContainerDiv);
    return () => {
      resizeObserver.unobserve(searchBarContainerDiv);
    };
  }, [isOpenMapsVisible]);

  return (
    <>
      <div className={styles.locationInfo}>
        {`Search and add ${
          isSelectedCountryUSA()
            ? 'States, DMAs, Counties, Cities, Congressional Districts, State Senates and State Houses'
            : 'States and Cities'
        } to the list of your targeted locations`}
      </div>
      <div className={styles.locationSearch} style={{ zIndex: popoverVisibility ? '4' : '3' }}>
        <Popover
          isOpen={popoverVisibility}
          clickOutsideCapture
          ref={searchBarRef}
          key={selectedTab}
          onClickOutside={() => {
            setAppliedFilters([]);
            setPopoverVisibility(false);
          }}
          positions={['bottom']}
          align="start"
          reposition={false}
          containerClassName="location-popover"
          containerStyle={{
            height: parent === 'planner' ? '345px' : '411px',
            width: `${
              document
                .getElementById(`searchBarContainer${isOpenMapsVisible ? '-openmap' : ''}`)
                ?.getBoundingClientRect().width
            }px`,
            background: 'white',
            zIndex: isOpenMapsVisible ? '100' : '10', // higher zIndex to display over map
          }}
          content={
            <div className={parent === 'planner' ? styles.plannerOpener : styles.opener}>
              <div className={styles.locationList}>
                {dataFetch.inProgress && <ProgressCircle size={70} className={styles.loader} />}
                {Object.keys(locationData)?.length && !dataFetch.inProgress ? (
                  <LocationDropdown
                    locationData={locationData}
                    searchText={searchValue}
                    appliedFilters={appliedFilters}
                    saveLocations={saveLocations}
                    parent="planner"
                  />
                ) : null}
                {!dataFetch.inProgress && !Object.keys(locationData)?.length ? (
                  <div className={styles.noResult}>
                    <Icon name="EmptyClipboard" />{' '}
                    {!dataFetch.isSuccess && <div className={styles.mainTitle}>Oops</div>}
                    <div className={styles.message}>
                      {dataFetch.isSuccess ? 'No results found' : 'Something went wrong'}
                    </div>
                    <div className={styles.subMessage}>
                      {dataFetch.isSuccess ? (
                        <>
                          We can't find any items matching your search <div>"{searchValue}" .</div>
                        </>
                      ) : (
                        'Please try again'
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={styles.filterList}>
                <div className={styles.header}>
                  <div>Filter by type</div>
                  {appliedFilters.length ? (
                    <div className={styles.filterOptions}>
                      <div>{`(${appliedFilters.length.toString().padStart(2, '0')})`}</div>
                      <div className={styles.filterRefresh} onClick={() => setAppliedFilters([])}>
                        <Icon name="FilterReset" />
                      </div>
                    </div>
                  ) : null}
                </div>
                {filters.map((filter) =>
                  isSelectedCountryUSA() || [302, 304].includes(filter.id) ? (
                    <div className="d-flex mt-2 align-items-center" key={filter.id}>
                      <Checkbox
                        checked={appliedFilters.includes(filter.id)}
                        onChange={() => {
                          setAppliedFilters((previousFilters) =>
                            previousFilters.includes(filter.id)
                              ? [...previousFilters.filter((filterId) => filterId !== filter.id)]
                              : [...previousFilters, filter.id],
                          );
                        }}
                        className={styles.filterCheckbox}
                      />
                      <Icon className={styles.filterIcon} name={iconMapper[filter.id]} />
                      <div className={styles.filterLabel}>{filter.name}</div>
                    </div>
                  ) : null,
                )}
              </div>
            </div>
          }
        >
          <div
            id={`searchBarContainer${isOpenMapsVisible ? '-openmap' : ''}`}
            className={popoverVisibility ? styles.inputContainer : ''}
            onClick={() => {
              if (!popoverVisibility && searchValue.trim().length > 1) {
                setPopoverVisibility(true);
              }
            }}
          >
            <SearchBar
              value={searchValue}
              onChange={setSearchValue}
              placeholder="Search locations"
            />
          </div>
        </Popover>
      </div>
    </>
  );
};
