import React, { useState, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { Dialog, DialogContent, DialogButtons, Checkbox } from 'factor';
import { LazySelect } from 'iqm-framework';
import { advanceActions, SetCampaignSidebarInfo } from 'store/advance/actions';
import { locationActions, ResetLocationState } from 'store/location/actions';
import { USA_CENTER } from 'store/map/reducer';
import {
  ClearPreSavedCircles,
  ClearPreSavedPolygons,
  ClearPreSavedRectangles,
  mapActions,
  SetMapCenter,
} from 'store/map/actions';
import { RectangleExtended } from 'models/RectangleExtended';
import { PolygonExtended } from 'models/PolygonExtended';
import { CircleExtended } from 'models/CircleExtended';
import { ICountryOption } from 'models/Location';
import { ErrorCreatingResponse } from 'models/Response';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Option } from 'models/Option';
import { findGeometryByLocationName } from 'models/Google';
import { AppState } from '../../../../../models/Store';
import { ExistingCampaignData } from '../../../../../models/ExistingCampaign';

import styles from './index.module.scss';

// Need to asses impact of country_short_name not available.

interface Props
  extends SetCampaignSidebarInfo,
    SetMapCenter,
    ClearPreSavedCircles,
    ClearPreSavedPolygons,
    ClearPreSavedRectangles,
    ResetLocationState {
  errorCreating: ErrorCreatingResponse | null;
  editableCampaign: ExistingCampaignData | null;
  preSavedCircles: CircleExtended[];
  preSavedPolygons: PolygonExtended[];
  preSavedRectangles: RectangleExtended[];
  isMapInstalled: boolean;
  selectedCountry: ICountryOption | null;
  clearLocationFiles: () => void;
  zipCodes: Option[];
}

const CountrySelectV2WrapperComponent: FC<Props> = ({
  selectedCountry,
  preSavedCircles,
  preSavedPolygons,
  preSavedRectangles,
  editableCampaign,
  setCampaignSidebarInfo,
  errorCreating,
  isMapInstalled,
  setMapCenter,
  clearPreSavedCircles,
  clearPreSavedPolygons,
  clearPreSavedRectangles,
  clearLocationFiles,
  resetState,
  zipCodes,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [tempSelectedCountry, setTempSelectedCountry] = useState<ICountryOption | null>(null);
  const [isCountryInitialized, setIsCountryInitialized] = useState(false);
  const [rememberSelection, setRememberSelection] = useState(false);

  useEffect(() => {
    // in create campaign, select US as default country
    if (!selectedCountry && !editableCampaign) {
      setCampaignSidebarInfo(CampaignInfoField.country, {
        label: 'United States',
        value: 30100001,
        shortName: 'US',
      });
    }
  }, [selectedCountry, setCampaignSidebarInfo, editableCampaign]);

  const transformRequestParams = (state: any) => ({
    pageNo: state.pageNo,
    noOfEntries: state.noOfEntries,
    searchField: state.searchField,
  });

  const mapResponseData = (response: any) => {
    const data = response.data || response.responseObject.data;
    return {
      data: data.map((item: any) => ({
        label: item.name,
        value: item.id,
        shortName: item.abbreviation,
      })),
      totalRecords: response.totalRecords || response.responseObject.totalRecords,
      filteredRecords: response.filteredRecords || response.responseObject.filteredRecords,
    };
  };

  useEffect(() => {
    if (
      !isCountryInitialized &&
      editableCampaign &&
      !errorCreating &&
      isMapInstalled &&
      editableCampaign?.filterStore?.country
    ) {
      setCampaignSidebarInfo(CampaignInfoField.country, editableCampaign.filterStore.country);
      setIsCountryInitialized(true);
    }
  }, [
    editableCampaign,
    setCampaignSidebarInfo,
    setIsCountryInitialized,
    errorCreating,
    isMapInstalled,
    isCountryInitialized,
  ]);

  const handleChangeCountry = (value: ICountryOption) => {
    if (!selectedCountry || value?.value !== selectedCountry?.value) {
      setCampaignSidebarInfo(CampaignInfoField.country, value);
      setCampaignSidebarInfo(CampaignInfoField.states, []);
      setCampaignSidebarInfo(CampaignInfoField.dma, []);
      setCampaignSidebarInfo(CampaignInfoField.districts, []);
      setCampaignSidebarInfo(CampaignInfoField.senates, []);
      setCampaignSidebarInfo(CampaignInfoField.houses, []);
      setCampaignSidebarInfo(CampaignInfoField.zipcodes, []);
      setCampaignSidebarInfo(CampaignInfoField.cities, []);
      setCampaignSidebarInfo(CampaignInfoField.counties, []);
      clearPreSavedCircles(true);
      clearLocationFiles();
      clearPreSavedPolygons(true);
      clearPreSavedRectangles();
      resetState();
      if (value.value === 30100001) {
        setMapCenter(USA_CENTER);
      } else {
        findGeometryByLocationName(value.label).then((response) => {
          const { location } = response;
          const lat = location.lat();
          const lng = location.lng();
          setMapCenter({ lat, lng });
        });
      }
    }
  };

  const renderDialog = () => {
    return tempSelectedCountry && selectedCountry ? (
      <Dialog open={dialogIsOpen}>
        <DialogContent>
          <h5 data-qa="326" className="title-card">
            Change Country
          </h5>
          <p data-qa="327" className="mt-4 mb-4">
            Are you sure you want to change the country? This action will remove the selected areas.
          </p>
          <div className={styles.rememberCheckBox}>
            <Checkbox
              label="Don't ask me again"
              size="sm"
              checked={rememberSelection}
              onChange={() => setRememberSelection(true)}
            />
          </div>
          <div data-qa="328" className="d-flex justify-content-between dialog__buttons">
            <DialogButtons
              buttons={[
                {
                  title: 'Cancel',
                  handler: () => {
                    setDialogIsOpen(false);
                    handleChangeCountry(selectedCountry);
                  },
                },
                {
                  title: 'Change',
                  handler: () => {
                    setDialogIsOpen(false);
                    handleChangeCountry(tempSelectedCountry);
                  },
                  className: 'btn-square _persimmon _filled',
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    ) : null;
  };

  const hasFigures = (): boolean => {
    return !!(preSavedCircles.length || preSavedPolygons.length || preSavedRectangles.length);
  };

  const hasZipCodes = (): boolean => Boolean((zipCodes?.length ?? 0) > 0);

  return (
    <>
      <LazySelect
        key="Country"
        selectSpecificProps={{
          placeholder: 'Select Country',
          label: 'Country',
          allSelectable: false,
          isClearable: false,
          isSearchable: true,
          isMulti: false,
          tooltipParams: {
            label: 'Campaign will be served only in selected country',
            position: 'bottom-left',
            auto: false,
          },
          withConfirmation: true,
        }}
        onChange={(value: any) => {
          let innerValue = value;
          if (innerValue === null) {
            innerValue = {};
          }
          if (
            selectedCountry?.value !== innerValue?.value &&
            (hasFigures() || hasZipCodes()) &&
            !rememberSelection
          ) {
            setTempSelectedCountry(innerValue);
            setDialogIsOpen(true);
          } else {
            handleChangeCountry(innerValue);
          }
        }}
        apiPath="api/v2/master/segment/country"
        httpMethod="get"
        transformPagingParameters={transformRequestParams}
        mapServerResponseData={mapResponseData}
        /* @ts-ignore */
        value={selectedCountry}
        totalRecordsPropName="totalRecords"
        numberOfEntries={50}
      />
      {createPortal(renderDialog(), document.body)}
    </>
  );
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
  errorCreating: state.app.errorCreating,
  preSavedCircles: state.map.preSavedCircles,
  preSavedPolygons: state.map.preSavedPolygons,
  preSavedRectangles: state.map.preSavedRectangles,
  isMapInstalled: state.map.isMapInstalled,
  selectedCountry: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  zipCodes: state.advanced.sidebarCampaignInfo[CampaignInfoField.zipcodes],
});

const actions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setMapCenter: mapActions.setMapCenter,
  clearPreSavedCircles: mapActions.clearPreSavedCircles,
  clearPreSavedPolygons: mapActions.clearPreSavedPolygons,
  clearPreSavedRectangles: mapActions.clearPreSavedRectangles,
  clearLocationFiles: locationActions.clearLocationFiles,
  resetState: locationActions.resetState,
};

export const CountrySelectV2Wrapper = connect(mapState, actions)(CountrySelectV2WrapperComponent);
