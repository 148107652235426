export const initData = {
  countryId: 30100001,
  included: {
    counties: [30315156, 30315160, 30315172, 30315175],
    cities: [30429911],
    stateSenates: [30714548, 30714550, 30714551],
    stateHouses: [30802991, 30802993, 30802995],
    congressionalDistricts: [30613735, 30613736, 30613737],
    zipCodes: [
      'M1R 0E9',
      'M3C 0C1',
      'M4C 0A3',
      '80202',
      '06103',
      '19901',
      '32301',
      '30303',
      '96813',
      '02201',
      '03301',
      '08608',
      '87501',
      '43215',
      '02903',
      '84111',
      '05602',
      '25301',
      '20001',
      '96950',
      '00901',
      '00802',
      'CR 01',
      '99999-9999',
      '99999',
      'A9A 9A9',
      'A9A-9A9',
      'AAA 9AA',
      'AA99 9AA',
      'AA9 9AA',
      'A9999AAA',
      '9999',
      '99999-999',
      '999-99',
      '999 99',
      '999AA',
      '999 AA',
      'SE-999-99',
      '9999-A',
    ],
    customAreas: [
      {
        unit: 'mile',
        address: 'Drummond, WI 54832, USA',
        latitude: 46.291980734810814,
        radius: 16.224332313594758,
        type: 'circle',
        longitude: -91.39740677422938,
        sid: 'WI',
        cid: 'US',
      },
      {
        polypath: [
          { lat: 45.65827183976637, lng: -91.23535843438563 },
          { lat: 45.719668094892675, lng: -89.58740921563563 },
          { lat: 44.93181700378978, lng: -89.74121780938563 },
        ],
        unit: 'mile',
        address: 'Westboro, WI 54490, USA',
        latitude: 45.32574254934123,
        type: 'custom',
        radius: 0.046447612064573054,
        longitude: -90.41138382501063,
        sid: 'WI',
        cid: 'US',
      },
      {
        polypath: [
          { lat: 43.77710983321977, lng: -88.55194778985438 },
          { lat: 43.77710983321977, lng: -90.39765091485438 },
          { lat: 42.946438183284414, lng: -90.39765091485438 },
          { lat: 42.946438183284414, lng: -88.55194778985438 },
        ],
        unit: 'mile',
        address: 'Lodi, WI 53555, USA',
        type: 'square',
        radius: 0.054581381187319034,
        sid: 'WI',
        cid: 'US',
        longitude: -89.47479935235438,
        latitude: 43.36177400825209,
      },
    ],
    locationIds: [50616, 50617, 50618, 50619, 50620],
    states: [30200028, 30200046, 30200049, 30214697, 30214702],
  },
  excluded: {
    counties: [],
    cities: [30429904, 30429907],
    stateSenates: [],
    stateHouses: [],
    congressionalDistricts: [],
    zipCodes: [],
    customAreas: [],
    locationIds: [],
  },
  filterStore: {
    country: { label: 'United States', value: 30100001, shortName: 'US' },
    states: [
      {
        label: 'North Carolina',
        value: 30200028,
        abbreviation: 'NC',
        stateCode: 30200028,
        v2Id: 30200028,
      },
      {
        label: 'Virginia',
        value: 30200046,
        abbreviation: 'VA',
        stateCode: 30200046,
        v2Id: 30200046,
      },
      {
        label: 'Wisconsin',
        value: 30200049,
        abbreviation: 'WI',
        stateCode: 30200049,
        v2Id: 30200049,
      },
      {
        label: 'Northern Mariana Islands (see also separate entry under MP)',
        value: 30214697,
        abbreviation: 'MP',
        stateCode: 30214697,
        v2Id: 30214697,
      },
      {
        label: 'Puerto Rico (see also separate entry under PR)',
        value: 30214702,
        abbreviation: 'PR',
        stateCode: 30214702,
        v2Id: 30214702,
      },
    ],
    cities: [
      { label: 'Aberdeen, NC', value: 30429904, stateId: 30200028 },
      { label: 'Albemarle, NC', value: 30429907, stateId: 30200028 },
      { label: 'Alliance, NC', value: 30429911, stateId: 30200028 },
    ],
    counties: [
      { label: 'Alleghany, NC', value: 30315156, stateId: 30200028 },
      { label: 'Beaufort, NC', value: 30315160, stateId: 30200028 },
      { label: 'Chatham, NC', value: 30315172, stateId: 30200028 },
      { label: 'Clay, NC', value: 30315175, stateId: 30200028 },
    ],
    districts: [
      { label: 'North Carolina-1', value: 30613735, stateId: 30200028, id: '1' },
      { label: 'North Carolina-2', value: 30613736, stateId: 30200028, id: '2' },
      { label: 'North Carolina-3', value: 30613737, stateId: 30200028, id: '3' },
    ],
    senates: [
      { label: 'North Carolina-1', value: 30714548, stateId: 30200028, id: '1' },
      { label: 'North Carolina-3', value: 30714550, stateId: 30200028, id: '3' },
      { label: 'North Carolina-4', value: 30714551, stateId: 30200028, id: '4' },
    ],
    houses: [
      { label: 'North Carolina-3', value: 30802991, stateId: 30200028, id: '3' },
      { label: 'North Carolina-5', value: 30802993, stateId: 30200028, id: '5' },
      { label: 'North Carolina-7', value: 30802995, stateId: 30200028, id: '7' },
    ],
    locationDetails: {
      '1689': {
        fileName: 'bulk-addresses-example (1).csv',
        fileType: 'locations',
        validLocations: [
          {
            latitude: 35.7950389,
            longitude: -78.65665039999999,
            radius: 1,
            address: '820 Graham St ,Raleigh,NC,27605',
            locationId: 50616,
            isIncluded: true,
            polypath: null,
            placemarkName: null,
            cdId: 0,
            sdId: 0,
            hdId: 0,
          },
          {
            latitude: 35.8440009,
            longitude: -78.65798,
            radius: 1.2,
            address: '4604 Gramercy Ct,Raleigh,NC,27609',
            locationId: 50617,
            isIncluded: true,
            polypath: null,
            placemarkName: null,
            cdId: 0,
            sdId: 0,
            hdId: 0,
          },
          {
            latitude: 35.8074803,
            longitude: -78.6582902,
            radius: 1.5,
            address: '2612 Dover Rd,Raleigh,NC,27608',
            locationId: 50618,
            isIncluded: true,
            polypath: null,
            placemarkName: null,
            cdId: 0,
            sdId: 0,
            hdId: 0,
          },
          {
            latitude: 35.7803352,
            longitude: -78.53267629999999,
            radius: 1.34,
            address: '1002 Lyndhurst Falls Ln,Knightdale,NC,27545',
            locationId: 50619,
            isIncluded: true,
            polypath: null,
            placemarkName: null,
            cdId: 0,
            sdId: 0,
            hdId: 0,
          },
          {
            latitude: 35.7831443,
            longitude: -78.6388985,
            radius: 0.5,
            address: '16 West Jones Street,Raleigh,NC,27601',
            locationId: 50620,
            isIncluded: true,
            polypath: null,
            placemarkName: null,
            cdId: 0,
            sdId: 0,
            hdId: 0,
          },
        ],
        id: 1689,
      },
    },
  },
  locationFileIds: [1689],
};
