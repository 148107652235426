import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../models/Store';
import { MapRectangle } from '../MapRectangle';
import { RectangleExtended } from '../../../../../../models/RectangleExtended';

interface Props {
  preSavedRectangles: RectangleExtended[];
}
const PreSavedRectanglesComponent: React.FC<Props> = (props: Props) => {
  const { preSavedRectangles } = props;

  return (
    <>
      {preSavedRectangles.map((rectangle: RectangleExtended) => (
        <MapRectangle key={rectangle.id} getRectangle={() => rectangle} />
      ))}
    </>
  );
};

const mapState = (state: AppState) => ({
  preSavedRectangles: state.map.preSavedRectangles,
});

export const PreSavedRectangles = connect(mapState)(PreSavedRectanglesComponent);
