import React from 'react';

import { DataGrid, RowSelectionState, SortingState } from '@applift/datagrid';
import { sx } from '@applift/factor';
import { InventoryGroupListResponse } from 'models/Inventory';
import { colDef } from './colDef';
import { NoResultOverlay } from '../../NoResultOverlay';

type Props = {
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  data: InventoryGroupListResponse['inventoryGroupList'];
  loading?: boolean;
  fetchNextPage: (val: any) => any;
  filteredRecords: number;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  search?: string;
};

export const InventoryGroupTable = (props: Props) => {
  const {
    data,
    rowSelection,
    setRowSelection,
    loading,
    fetchNextPage,
    filteredRecords,
    sorting,
    setSorting,
    search = '',
  } = props;

  return (
    <DataGrid
      data={data}
      columns={colDef}
      rowHeight={40}
      headerHeight={40}
      hideHeader
      hideFooter
      showCellRightBorder
      showColumnRightBorder
      checkboxSelection
      rowSelection
      getRowId={(row) => `${row.id}`}
      state={{ rowSelection, sorting }}
      components={{
        NoResultsOverlay: () => <NoResultOverlay search={search} />,
      }}
      onRowSelectionChange={setRowSelection}
      enableRowSelection
      disableRowSelectionOnClick
      loading={loading}
      onSortingChange={setSorting}
      rowCount={filteredRecords}
      onFetchRows={fetchNextPage}
      overlay={data.length === 0 && !loading ? 'noResult' : undefined}
      classes={{
        root: sx({
          borderRadius: 0,
          borderTop: 1,
          borderLeft: 1,
          borderRight: 1,
          borderBottom: 1,
          borderTopColor: 'neutral-300',
        }),
      }}
    />
  );
};
