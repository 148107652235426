import { TLocationFileType } from 'models/Location';
import { createAction } from '../../utils/actions';
import { locationConstants } from './constants';
import { ILocationFile } from './reducer';

export type ResetLocationState = {
  resetState: () => void;
};

export type ClearLocationFiles = {
  clearLocationFiles: () => void;
};

export type SetOrRelationship = {
  setLocationOrRelationship: (isOrRelationship: boolean) => void;
};

export const locationActions = {
  resetState() {
    return createAction(locationConstants.RESET_LOCATION_STORE);
  },

  setLocationError(errorName: string) {
    return createAction(locationConstants.SET_LOCATION_ERROR, errorName);
  },

  resetLocationError(errorName: string) {
    return createAction(locationConstants.RESET_LOCATION_ERROR, errorName);
  },

  addLocationFile(data: ILocationFile) {
    return createAction(locationConstants.ADD_FILE_LOCATION, data);
  },

  clearLocationFiles() {
    return createAction(locationConstants.CLEAR_LOCATION_FILES);
  },

  removeItemFromFile(options: { fileId: number; itemId: number }) {
    return createAction(locationConstants.REMOVE_FILE_LOCATION_ITEM, options);
  },

  removeFileData(id: number) {
    return createAction(locationConstants.REMOVE_FILE_LOCATION, id);
  },

  dismissInvalidData(id: number) {
    return createAction(locationConstants.DISMISS_INVALID_DATA, id);
  },

  setFileUpload(files: TLocationFileType[]) {
    return createAction(locationConstants.FILE_UPLOAD_STATUS, files);
  },

  setLocationOrRelationship(isOrRelationship: boolean) {
    return createAction(locationConstants.SET_LOCATION_IS_OR_RELATIONSHIP, isOrRelationship);
  },
};
