/* eslint-disable no-prototype-builtins */
/* global google */
import React from 'react';
import { connect } from 'react-redux';

import { LatLngLiteral, OverlayType, LatLng, Library } from 'models/Google';
import { SELECTION_COUNTRY_STATE, SOMETHING_WENT_WRONG } from 'constants/messages';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Map as GMap } from 'components/Map';
import {
  AddPolygonsToSaved,
  ClearPreSavedPolygons,
  EmptySelectedAreasIds,
  PushSelectedAreaId,
  SetMapBounds,
  mapActions,
  SetToastMessage,
  PushPreSavedCircle,
  PushPreSavedRectangle,
  PushPreSavedPolygon,
  SetDrawerMode,
} from 'store/map/actions';
import { AppState } from 'models/Store';
import { MapBounds } from 'store/map/reducer';
import { CircleExtended } from 'models/CircleExtended';
import { ICountryOption } from 'models/Location';
import { shapeType } from 'constants/location';
import { Open, toastActions } from 'store/toast/actions';
import { milesToMeters } from 'utils/format';
import { PreSavedRectangles } from './PreSavedRectangles';
import { mapStyles } from './mapStyle';
import { MapData } from './MapData';
import { PreSavedPolygons } from './PreSavedPolygons';
import { PreSavedCircles } from './PreSavedCircles';
import { MapDrawing } from './MapDrawing';
import { MapSearchBox } from './MapSearchBox';

interface Props
  extends ClearPreSavedPolygons,
    AddPolygonsToSaved,
    EmptySelectedAreasIds,
    PushSelectedAreaId,
    SetMapBounds,
    PushPreSavedCircle,
    PushPreSavedRectangle,
    PushPreSavedPolygon,
    SetToastMessage,
    SetDrawerMode {
  center: LatLngLiteral;
  bounds: MapBounds;
  zoom: number;
  areaType: string | null;
  isInclude: boolean;
  drawerMode: OverlayType | undefined;
  country: ICountryOption | null;
  isMapFullScreen: boolean;
  openToast: Open['open'];
  selectedLocationTab: string;
}

interface State {
  map?: google.maps.Map;
  prevDrawerMode?: OverlayType;
  isCtrlPress: boolean;
}

const libraries: Library[] = ['drawing', 'places', 'geometry'];

class MapComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: undefined,
      prevDrawerMode: shapeType.circle as OverlayType,
      isCtrlPress: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentDidUpdate(_prevProps: Props, _prevState: State, snapshot?: any) {
    const { map: stateMap } = this.state;
    const { drawerMode } = this.props;

    if (stateMap && snapshot) {
      const map: google.maps.Map = stateMap as any;
      map.fitBounds(snapshot);
    }

    if (_prevProps.drawerMode !== drawerMode) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        prevDrawerMode: !drawerMode && _prevProps.drawerMode ? _prevProps.drawerMode : drawerMode,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  }

  getSnapshotBeforeUpdate(prevProps: Readonly<Props>): any | null {
    const { bounds } = this.props;
    if (bounds && bounds !== prevProps.bounds) {
      return bounds;
    }
    return null;
  }

  onKeyDown = (event: KeyboardEvent) => {
    const { setDrawerMode, drawerMode } = this.props;
    if (this.isCtrlPress(event)) {
      this.setState({
        isCtrlPress: true,
        prevDrawerMode: drawerMode,
      });
      setDrawerMode();
    } else {
      this.setState({
        isCtrlPress: false,
      });
    }
  };

  onKeyUp = () => {
    const { setDrawerMode } = this.props;
    const { prevDrawerMode, isCtrlPress } = this.state;
    if (isCtrlPress) {
      setDrawerMode(prevDrawerMode);
    }
  };

  handleClear = () => {
    const { clearPreSavedPolygons } = this.props;
    clearPreSavedPolygons();
  };

  handleAddToSaved = () => {
    const { addPolygonsToSaved } = this.props;
    addPolygonsToSaved();
  };

  onHandleInner = (map: google.maps.Map) => {
    this.setState({ map });
  };

  checkOutOfBounds = (figure: CircleExtended): void => {
    const { country, setToastMessage, openToast } = this.props;
    if (country?.shortName !== figure.country) {
      if (window.self !== window.top) {
        setToastMessage(SELECTION_COUNTRY_STATE);
      } else {
        openToast(SELECTION_COUNTRY_STATE);
      }
    }
  };

  onClick = async ({ latLng }: { latLng: LatLng }) => {
    const {
      areaType,
      isInclude,
      pushPreSavedCircle,
      setMapBounds,
      pushSelectedAreaId,
      emptySelectedAreasIds,
      setToastMessage,
      openToast,
    } = this.props;

    if (areaType === 'Radius') {
      const circle = new google.maps.Circle({
        center: latLng,
        radius: milesToMeters(0.5),
      });
      const circleInstance = new CircleExtended({ circle, isInclude });

      try {
        await circleInstance.update();
        emptySelectedAreasIds();
        this.checkOutOfBounds(circleInstance);
        pushPreSavedCircle(circleInstance);
        pushSelectedAreaId(circleInstance.id);
        setMapBounds(circle.getBounds());
      } catch (error) {
        if (error === 'ZERO_RESULTS') {
          return;
        }
        if (window.self !== window.top) {
          setToastMessage(SOMETHING_WENT_WRONG);
        } else {
          openToast(SOMETHING_WENT_WRONG);
        }
      }
    }
  };

  isCtrlPress = (event: KeyboardEvent) => {
    return event.ctrlKey || event.metaKey;
  };

  render() {
    const {
      center,
      zoom,
      areaType,
      drawerMode,
      setDrawerMode,
      selectedLocationTab,
      isMapFullScreen,
    } = this.props;
    const { prevDrawerMode } = this.state;

    return (
      <GMap
        drawerMode={drawerMode}
        setDrawerMode={setDrawerMode}
        prevDrawerMode={prevDrawerMode}
        options={{
          draggableCursor: areaType === 'Radius' ? 'crosshair' : undefined,
          mapTypeControl: false,
          streetViewControl: false,
          styles: mapStyles,
          fullscreenControl: false,
          gestureHandling: 'greedy',
          maxZoom: 20,
          minZoom: 3,
          zoomControlOptions: {
            position: 7, // google.maps.ControlPosition.RIGHT_TOP,
            style: 1, // google.maps.ZoomControlStyle.SMALL,
          },
        }}
        libraries={libraries}
        center={center}
        innerRef={this.onHandleInner}
        zoom={zoom}
        onClick={this.onClick}
      >
        <PreSavedPolygons />
        <PreSavedCircles />
        <PreSavedRectangles />
        <MapData />
        <MapDrawing />
        <MapSearchBox hidden={selectedLocationTab === 'location' || !isMapFullScreen} />
      </GMap>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    center: state.map.center,
    bounds: state.map.mapBounds,
    zoom: state.map.zoom,
    isMapFullScreen: state.map.isOpenMapVisible,
    areaType: state.map.areaType,
    isInclude: state.map.isInclude,
    drawerMode: state.map.drawerMode,
    country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
    selectedLocationTab: state.map.selectedLocationTab,
  };
};
const actions = {
  clearPreSavedPolygons: mapActions.clearPreSavedPolygons,
  addPolygonsToSaved: mapActions.addPolygonsToSaved,
  pushPreSavedCircle: mapActions.pushPreSavedCircle,
  pushPreSavedRectangle: mapActions.pushPreSavedRectangle,
  pushPreSavedPolygon: mapActions.pushPreSavedPolygon,
  setDrawerMode: mapActions.setDrawerMode,
  emptySelectedAreasIds: mapActions.emptySelectedAreasIds,
  pushSelectedAreaId: mapActions.pushSelectedAreaId,
  setMapBounds: mapActions.setMapBounds,
  setToastMessage: mapActions.setToastMessage,
  openToast: toastActions.open,
};

export const Map = connect(mapState, actions)(MapComponent);
