import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { getPathNumber, AdvancedVideoOptionsGroup } from 'iqm-framework';
import {
  TextField,
  Icon,
  CollapsibleBlock,
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Tumbler,
  ActionMenu,
} from 'factor';
import { Box, Typography } from '@applift/factor';
import { LayoutCard } from '@applift/icons';

import { isLocalhost } from 'utils/helpers';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { API } from 'api';
import { AppState } from 'models/Store';
import { creativesActions, CreativesActions, ChangeUrl } from 'store/creatives/actions';
import { getCreativeTypeIcon } from 'utils/creatives';
import { Option } from 'models/Option';
import { ErrorCreatingResponse } from 'models/Response';
import {
  AUDIO_CREATIVE_ID,
  Creative,
  HTML_CREATIVE_ID,
  IMAGE_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
  creativesIDMap,
} from 'models/Creative';
import {
  applicationActions,
  ResetError,
  SetCollapseBlockIndex,
  SetEditableCampaign,
} from 'store/app/actions';
import { technologyActions, SetIsTvAd } from 'store/technology/actions';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { ServerErrorMessage } from 'components/ServerErrorMessage';
import { blockStyles } from 'components/Block';
import { emptyAfterSubmitValidation, validateUrl } from 'utils/validationRules';
import { getDomainUrl } from 'utils/domainUrl';
import { AdvertiserState } from 'store/advertiser/reducer';
import { User } from 'models/User';
import { CreativesPlacementMapping, ExistingCampaignData } from 'models/ExistingCampaign';
import { ICreativeAdvancedTargeting } from 'store/creatives/reducer';
import { AdvancePageState } from 'store/advance/reducer';
import creativeTypeViewByCreativeTypeId, {
  TCreativeTypeIds,
} from './SelectCreativesPopup/constants';
import { AdvancedCreativesModellingButton } from './AdvancedCreativesModelling/index';
import { SelectedCreativesTable } from './SelectedCreativesTable';
import SelectCreativesPopup from './SelectCreativesPopup';
import CreateCreativesPopup from './CreateCreativesPoupup';

import styles from './styles.module.scss';

const CREATIVES_URL = '/creatives';

interface Props
  extends CreativesActions,
    ResetError,
    ChangeUrl,
    SetEditableCampaign,
    SetCollapseBlockIndex,
    SetIsTvAd {
  user: User;
  creativeTypes: Option<number>[];
  selectedCreativeType: Option<number> | null;
  creatives: Creative[];
  selectedCreatives: Creative[];
  submitted: boolean;
  isCollapseOpen?: boolean;
  onToggle?: (isOpened: boolean) => void;
  saveDraftSubmitted: boolean;
  errorCreating: ErrorCreatingResponse | null;
  advertiserUrl: string;
  advertiserForm: AdvertiserState;
  editableCampaign: ExistingCampaignData | null;
  collapseMode?: CollapseMode;
  creativesPlacementMapping: CreativesPlacementMapping;
  openIndex: number;
  history: RouteComponentProps['history'];
  match: RouteComponentProps['match'];
  location: RouteComponentProps['location'];
  isTvAd: boolean;
  isAdvanceAudioVideoTargeted: boolean | undefined;
  creativeAdvanceTargeting?: ICreativeAdvancedTargeting | null;
  sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
}

interface State {
  isChanged: boolean;
  showWarningDialog: boolean;
  showDisableAdvacedVideoDialog: boolean;
  activePath: string;
  showCreateNativeMenu: boolean;
  isExistingCreative: boolean;
}

interface RenderRoutesProps {
  activePath: string;
  openIndex: number;
  handleSelectCreativePopupClose: (e?: MouseEvent) => void;
  setCollapseBlockIndex: (param: number) => void;
  onCreateCreativesPopupClose: (creativeIds?: string) => Promise<void>;
  getCreativesUrl: (user: User, selectedCreativeType?: number) => string;
  selectedCreativeType: Option<number> | null;
  user: User;
}

// home
const RenderRoutes = (props: RenderRoutesProps) => {
  const {
    activePath,
    openIndex,
    handleSelectCreativePopupClose,
    setCollapseBlockIndex,
    onCreateCreativesPopupClose,
    getCreativesUrl,
    selectedCreativeType,
    user,
  } = props;

  let ComponentToRender: JSX.Element | null = null;

  if (activePath === 'select-creatives-popup') {
    if (openIndex !== 0) {
      setCollapseBlockIndex(0);
    }
    ComponentToRender = <SelectCreativesPopup handleClose={handleSelectCreativePopupClose} />;
  } else if (activePath === 'create-creatives-popup') {
    ComponentToRender = (
      <CreateCreativesPopup
        onClose={(creativeIds?: string) => {
          onCreateCreativesPopupClose(creativeIds);
        }}
        getUrl={() =>
          selectedCreativeType ? getCreativesUrl(user, selectedCreativeType.value) : ''
        }
      />
    );
  }
  if (ComponentToRender === null) {
    return null;
  }
  return createPortal(ComponentToRender, document.body);
};

class CreativeBlockComponent extends React.PureComponent<Props, State> {
  popup: Window | null | undefined;

  newCreativesInterval?: NodeJS.Timeout;

  hrefMap: { [key: string]: string };

  constructor(props: Props) {
    super(props);
    this.hrefMap = {};
    this.state = {
      isChanged: false,
      showWarningDialog: false,
      showDisableAdvacedVideoDialog: false,
      activePath: '',
      showCreateNativeMenu: false,
      isExistingCreative: false,
    };
  }

  componentDidMount() {
    const { selectedCreatives, creativeTypes, fetchCreativeTypes, user, advertiserUrl, changeUrl } =
      this.props;

    if (!creativeTypes.length && user.userId) {
      fetchCreativeTypes();
    }

    if (selectedCreatives.length && !advertiserUrl) {
      changeUrl(this.getWebsiteUrl());
    }

    window.onfocus = this.stopFaviconBlinking;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      user,
      fetchCreativeTypes,
      changeUrl,
      advertiserForm,
      selectedCreatives,
      creatives,
      creativeTypes,
      editableCampaign,
      submitted,
      selectedCreativeType,
      creativesPlacementMapping,
      setCreativesPlacementMapping,
      openIndex,
    } = this.props;

    const { isChanged } = this.state;

    if (user !== prevProps.user) {
      fetchCreativeTypes();
    }

    if (
      !editableCampaign &&
      (prevProps.advertiserForm.isPoliticalAdvertising !== advertiserForm.isPoliticalAdvertising ||
        prevProps.advertiserForm.url !== advertiserForm.url ||
        prevProps.selectedCreatives !== selectedCreatives)
    ) {
      changeUrl(this.getWebsiteUrl());
    }

    if (
      !isChanged &&
      editableCampaign &&
      creativeTypes.length &&
      (prevProps.creativeTypes.length !== creativeTypes.length ||
        editableCampaign !== prevProps.editableCampaign)
    ) {
      const selectedType = creativeTypes.find((c) => c.value === editableCampaign.creativeType);
      if (selectedType) {
        this.selectCreativeTypeHandler(selectedType);
      }
    }

    if (
      !isChanged &&
      editableCampaign &&
      editableCampaign.creativeIds &&
      creatives.length &&
      (prevProps.creatives.length !== creatives.length ||
        editableCampaign !== prevProps.editableCampaign)
    ) {
      const editableCampaignCreativeIds = editableCampaign.creativeIds.split(',').map((i) => +i);
      const editableCampaignSelectedCreatives = creatives
        .filter((c) => editableCampaignCreativeIds.includes(c.id))
        .map((c) => ({
          label: c.name,
          value: c.id,
        }));

      if (editableCampaignSelectedCreatives.length) {
        this.selectCreativeHandler(editableCampaignSelectedCreatives, true);
      }
    }

    if (
      isEmpty(creativesPlacementMapping) &&
      editableCampaign?.creativesPlacementMapping &&
      !isEqual(
        editableCampaign.creativesPlacementMapping,
        prevProps.editableCampaign?.creativesPlacementMapping,
      )
    ) {
      setCreativesPlacementMapping(editableCampaign.creativesPlacementMapping);
    }

    if (submitted && !selectedCreatives.length && openIndex !== 0) {
      if (!selectedCreativeType) {
        this.selectCreativeTypeHandler(creativeTypes[0]);
      }
    }
  }

  componentWillUnmount() {
    window.onfocus = null;
    this.stopFaviconBlinking();
    window.removeEventListener('message', this.receiveMessage);
  }

  stopFaviconBlinking = () => {
    if (this.newCreativesInterval) {
      clearInterval(this.newCreativesInterval);

      const links: any = document.head.querySelectorAll("link[rel*='icon']");
      links.forEach((el: Element, index: number) => {
        el.setAttribute('href', this.hrefMap[index]);
      });
    }
  };

  removeHandler = (item: Option<number>) => {
    const { selectedCreatives, selectCreatives, changeUrl } = this.props;
    const transformedCreatives = selectedCreatives.filter((i) => {
      if (i.isHeader) {
        return false;
      }

      return i.id !== item.value;
    });

    if (selectedCreatives.length === 1) {
      changeUrl('');
    }

    selectCreatives(transformedCreatives);
  };

  selectCreativeTypeHandler = (creativeType: Option<number>) => {
    const {
      selectedCreativeType,
      selectCreatives,
      selectCreativeType,
      setIsTvAd,
      errorCreating,
      resetError,
    } = this.props;
    if (errorCreating && errorCreating.errorField === errorFieldsMapper.creativeType) {
      resetError();
    }
    if (selectedCreativeType && creativeType && selectedCreativeType.value !== creativeType.value) {
      selectCreatives([]);
    }

    if (creativeType) {
      if (selectedCreativeType && creativeType.value !== selectedCreativeType.value) {
        setIsTvAd(false);
      }
      selectCreativeType(creativeType);
    }

    this.props.setIsAdvancedVideoTargeting(false);
  };

  selectCreativeHandler = (items: Option<number>[], isFromEditableCampaign?: boolean) => {
    const {
      creatives,
      selectCreatives,
      selectedCreatives,
      errorCreating,
      resetError,
      changeUrl,
      editableCampaign,
      setEditableCampaign,
    } = this.props;

    const selectedIds = items.map((i) => i.value);
    const creativesIds = creatives.map((c) => c.id);
    const lastSelected = selectedCreatives.filter(
      (i) => selectedIds.includes(i.id) && !creativesIds.includes(i.id),
    );
    const updatedCreatives = creatives.filter((i) => selectedIds.includes(i.id));
    if (errorCreating && errorCreating.errorField === errorFieldsMapper.creativeIds) {
      resetError();
    }
    if (isFromEditableCampaign) {
      this.setState({
        isChanged: true,
      });
    }

    const headers: any = items.filter((i: Option<number>) => i.isHeader);
    const uniqCreatives = uniqBy([...lastSelected, ...updatedCreatives], 'id');
    selectCreatives([...headers, ...uniqCreatives]);

    if (editableCampaign) {
      setEditableCampaign({
        ...editableCampaign,
        creativeIds: [...headers, ...uniqCreatives].map((i) => i.value).join(','),
      });
    }

    if (isFromEditableCampaign && editableCampaign) {
      changeUrl(editableCampaign.advertiserDomain);
    } else {
      changeUrl(this.getWebsiteUrl());
    }
  };

  getAdvertiserUrl = () => {
    const {
      user,
      advertiserForm: { url },
    } = this.props;
    return user && user.websiteUrl ? user.websiteUrl : getDomainUrl(url);
  };

  getWebsiteUrl = (): string => {
    const { selectedCreatives } = this.props;
    if (!selectedCreatives.length) {
      return '';
    }
    const domainsCollection: string[] = [];
    let hasBlankClickUrl = false;
    selectedCreatives.forEach((item) => {
      if (item.clickUrl && item.clickUrl.length) {
        const domain = getDomainUrl(item.clickUrl.trim());
        if (!domainsCollection.includes(domain)) {
          domainsCollection.push(domain);
        }
      } else {
        hasBlankClickUrl = true;
      }
    });

    return !hasBlankClickUrl && domainsCollection.length === 1 ? domainsCollection[0] : '';
  };

  receiveMessage = (event: any) => {
    if (event.data !== 'NEW_CREATIVES_CREATED') return;
    let showIcon = false;
    const links: any = document.head.querySelectorAll("link[rel*='icon']");
    this.hrefMap = reduce(
      links,
      (result: { [key: string]: string }, link: HTMLLinkElement, index: number) => {
        // eslint-disable-next-line no-param-reassign
        result[index] = link.href;
        return result;
      },
      {},
    );

    this.newCreativesInterval = setInterval(() => {
      showIcon = !showIcon;
      links.forEach((el: Element, index: number) => {
        el.setAttribute('href', showIcon ? '/transparent_favicon.ico' : this.hrefMap[index]);
      });
    }, 600);
  };

  getCreativesUrl = (user: User, selectedCreativeType?: number) => {
    const origin = isLocalhost()
      ? 'http://localhost:8000'
      : `${CREATIVES_URL}/u/${getPathNumber()}`;

    return `${origin}/#/?action=${
      this.state.isExistingCreative ? 'isExistingCreative' : 'newCreative'
    }&payload=${btoa(
      JSON.stringify({ type: selectedCreativeType, minimizedView: true }).replace(
        /[\u00A0-\u2666]/g,
        '',
      ),
    )}`;
  };

  onSelectCreativesPopupOpen = (e?: MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    const { setCollapseBlockIndex } = this.props;
    setCollapseBlockIndex(0);
    this.setState((prev) => ({ ...prev, activePath: 'select-creatives-popup' }));
  };

  onSelectCreativesPopupClose = (e?: MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState((prev) => ({ ...prev, activePath: '' }));
  };

  handleRemoveSelectedCreatives = (removalSelection: { [key: number]: boolean }) => {
    const { selectedCreatives, selectCreatives } = this.props;
    const newSelectedCreatives = selectedCreatives.filter(
      (crt: Creative) => !removalSelection[crt.id],
    );
    selectCreatives(newSelectedCreatives);
  };

  onCreateCreativesPopupOpen = () => {
    this.setState((prev) => ({
      ...prev,
      activePath: 'create-creatives-popup',
    }));
  };

  onCreateCreativesPopupClose = async (creativeIds?: string) => {
    const { selectCreatives, selectedCreatives } = this.props;
    this.setState((prev) => ({ ...prev, activePath: '' }));
    if (creativeIds) {
      const { responseObject } = await API.Creatives.FetchCreativeList({
        creativeIds,
        creativeTypeIds: '',
        noOfEntries: 99999,
        pageNo: 1,
        searchField: '',
      });
      const creatives: Creative[] = get(responseObject, 'data', []);
      if (creatives.length) {
        selectCreatives([...selectedCreatives, ...creatives]);
      }
    }
  };

  handleCreativesTypeChange = () => {
    const { selectCreatives, selectCreativeType, setIsTvAd } = this.props;
    selectCreatives([]);
    selectCreativeType(null);
    setIsTvAd(false);
    this.setState({ showWarningDialog: false });
  };

  handleIsTvAdChange = () => {
    const { setIsTvAd, isTvAd } = this.props;
    setIsTvAd(!isTvAd);
  };

  handleIsAdvancedTargetingChange = () => {
    const { setIsAdvancedVideoTargeting, isAdvanceAudioVideoTargeted } = this.props;
    setIsAdvancedVideoTargeting(true);
    this.props.setAdvancedVideoTargetingOptions(undefined);
    this.setState((currState) => ({
      ...currState,
      showDisableAdvacedVideoDialog: !isAdvanceAudioVideoTargeted === false,
    }));
  };

  handleCreativeTypeChangeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ showWarningDialog: true });
  };

  getAdvertiserURLField = (className = '') => {
    const { submitted, advertiserUrl, changeUrl } = this.props;

    return (
      <div
        data-qa="224"
        className={className}
        data-not-valid={
          advertiserUrl.trim().length === 0 || !validateUrl(submitted).func(advertiserUrl)
        }
      >
        <TextField
          className={styles.advertiserURLField}
          label="Advertiser Domain URL"
          value={advertiserUrl}
          onChange={changeUrl}
          validateOnInit
          validationRules={[
            {
              ...emptyAfterSubmitValidation(submitted),
              name: 'Advertiser Domain URL',
            },
            {
              ...validateUrl(submitted),
              name: 'Advertiser Domain URL',
            },
          ]}
          validationKeys={[submitted]}
        />
        <ServerErrorMessage errorKey={errorFieldsMapper.advertiserDomain} />
      </div>
    );
  };

  renderDialog = () => {
    const { selectedCreativeType } = this.props;
    const { showWarningDialog } = this.state;
    const creativeTypeName = selectedCreativeType
      ? creativeTypeViewByCreativeTypeId[selectedCreativeType.value as TCreativeTypeIds].title
      : '';
    return (
      <Dialog open={showWarningDialog} className={styles.dialogWrapper}>
        <DialogHeader>
          <h2>Are you sure?</h2>
        </DialogHeader>
        <DialogContent>
          <p className="mb-3">
            You are about to change the creative type of this campaign. This action will remove the
            current selection of the <b>{creativeTypeName}</b> creatives for this campaign.
          </p>
          <div className={styles.dialogButtons}>
            <DialogButtons
              buttons={[
                {
                  title: 'No',
                  handler: () => {
                    this.setState({ showWarningDialog: false });
                  },
                },
                {
                  title: 'Yes',
                  handler: this.handleCreativesTypeChange,
                  className: 'btn-square _conflower-blue _filled',
                  disabled: false,
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  renderDisableAdvancedTargetings = () => {
    return (
      <Dialog open={this.state.showDisableAdvacedVideoDialog} className={styles.dialogWrapper}>
        <DialogHeader>
          <h2>Disable Advanced Targeting?</h2>
        </DialogHeader>
        <DialogContent>
          <p className="mb-3">
            Are you sure you want to disable the advanced targeting options? This might affect the
            campaign’s serving.
          </p>
          <div className={styles.dialogButtons}>
            <DialogButtons
              buttons={[
                {
                  title: 'Cancel',
                  handler: () => {
                    this.setState({
                      showDisableAdvacedVideoDialog: false,
                    });
                    this.props.setIsAdvancedVideoTargeting(true);
                  },
                },
                {
                  title: 'Disable',
                  handler: () => {
                    this.setState({
                      showDisableAdvacedVideoDialog: false,
                    });
                    this.props.setIsAdvancedVideoTargeting(false);
                  },
                  className: 'btn-square _conflower-blue _filled',
                  disabled: false,
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  getHeaderObj = () => {
    const { selectedCreativeType, selectedCreatives, submitted, editableCampaign } = this.props;
    const hasNoCreativesSelectedError = submitted && !selectedCreatives.length;
    const creativeTypeName = selectedCreativeType
      ? creativeTypeViewByCreativeTypeId[selectedCreativeType.value as TCreativeTypeIds].title
      : '';

    const isSelectedTypeNative =
      selectedCreativeType && selectedCreativeType.value === NATIVE_CREATIVE_ID;

    return {
      title: (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}>
            {selectedCreativeType ? (
              getCreativeTypeIcon(selectedCreativeType.value)
            ) : (
              <LayoutCard fontSize={24} color="primary" />
            )}
            <Typography>
              {creativeTypeName} Creatives
              {selectedCreatives.length > 0 && !editableCampaign?.creativeType && (
                <span
                  className={styles.creativeChangeBtn}
                  role="button"
                  tabIndex={0}
                  onClick={this.handleCreativeTypeChangeClick}
                >
                  Change
                </span>
              )}
            </Typography>
          </Box>
          <div className={styles.headerActions}>
            {editableCampaign?.creativeType ? (
              <>
                <div className={`${hasNoCreativesSelectedError ? styles.noCreativesSelected : ''}`}>
                  <Button
                    onClick={this.onSelectCreativesPopupOpen}
                    className="btn-square _filled _cornflower-blue _map m-0 mr-2"
                  >
                    + Select Creatives
                  </Button>
                  {hasNoCreativesSelectedError ? (
                    <p className={`text-field__help-text _error ${styles.headerWarningMsg}`}>
                      Please attach at least one creative.
                    </p>
                  ) : null}
                </div>
                {isSelectedTypeNative !== null ? (
                  <div>
                    {!isSelectedTypeNative ? (
                      <Button
                        className={`btn-square _cornflower-blue _map m-0 `}
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation();
                          this.onCreateCreativesPopupOpen();
                        }}
                      >
                        + New Creative
                      </Button>
                    ) : (
                      <div className={styles.createNativeCtaWrapper}>
                        <Button
                          className={`btn-square _cornflower-blue _map m-0 `}
                          onClick={(e: MouseEvent) => {
                            e.stopPropagation();
                            this.showCreateNativeMenu();
                          }}
                        >
                          Add New Creatives <Icon name="DropDown" />
                        </Button>
                        {this.state.showCreateNativeMenu ? (
                          <div
                            className={styles.nativeActionsWrapper}
                            onBlur={() => this.setState({ showCreateNativeMenu: false })}
                          >
                            <ActionMenu
                              className={styles.actionMenu}
                              actions={[
                                {
                                  label: 'By uploading Images or Videos',
                                  onClick: (e: MouseEvent) => {
                                    this.setState((prev) => ({
                                      ...prev,
                                      isExistingCreative: false,
                                    }));
                                    this.onCreateCreativesPopupOpen();
                                  },
                                  iconName: 'Upload',
                                },
                                {
                                  label: 'Using existing Images or Videos',
                                  onClick: (e: MouseEvent) => {
                                    this.setState((prev) => ({
                                      ...prev,
                                      isExistingCreative: true,
                                    }));
                                    this.onCreateCreativesPopupOpen();
                                  },
                                  iconName: 'CreativeImage',
                                },
                              ]}
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            ) : null}
            <AdvancedCreativesModellingButton />
          </div>
        </>
      ),
      summary: {
        Creatives: selectedCreatives.length ? `${selectedCreatives.length} selected` : '',
        'Advanced Targeting': this.props.isAdvanceAudioVideoTargeted ? 'On' : '',
        'TV-Ad Buying': this.props.isTvAd ? 'On' : '',
      },
    };
  };

  showCreateNativeMenu = () => {
    this.setState((val) => ({ showCreateNativeMenu: !val.showCreateNativeMenu }));
  };

  render() {
    const {
      creativeTypes,
      selectedCreativeType,
      selectedCreatives,
      user,
      submitted,
      editableCampaign,
      onToggle,
      isCollapseOpen,
      collapseMode,
      setCreativesPlacementMapping,
      creativesPlacementMapping,
      isTvAd,
    } = this.props;

    if (!user) {
      return null;
    }
    const hasNoCreativesSelectedError = submitted && !selectedCreatives.length;
    const isSelectedTypeImage =
      selectedCreativeType && selectedCreativeType.value === IMAGE_CREATIVE_ID;
    const isSelectedTypeHtml =
      selectedCreativeType && selectedCreativeType.value === HTML_CREATIVE_ID;
    const isSelectedTypeVideo =
      selectedCreativeType && selectedCreativeType.value === VIDEO_CREATIVE_ID;
    const isSelectedTypeAudio =
      selectedCreativeType && selectedCreativeType.value === AUDIO_CREATIVE_ID;
    const isSelectedTypeNative =
      selectedCreativeType && selectedCreativeType.value === NATIVE_CREATIVE_ID;

    const sortOrder: { [key: string]: number } = {
      Image: 1,
      HTML: 2,
      Video: 3,
      Audio: 4,
      Native: 5,
    };

    return (
      <>
        <CollapsibleBlock
          onToggle={onToggle}
          isCollapseOpen={isCollapseOpen}
          collapsible={collapseMode !== ALL_EXPANDED}
          header={this.getHeaderObj()}
          customStyle={{
            block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''} ${
              styles.blockTitleWidth
            }`,
            panelHeaderExpand: blockStyles.panelHeaderExpand,
            panelHeaderCollapse: blockStyles.panelHeaderCollapse,
          }}
        >
          <div className={`${styles.selectCreativeTypes}`}>
            {!editableCampaign?.creativeType && !(selectedCreatives.length > 0) && (
              <div className="mb-3">
                <div className={styles.selectCreativeTypesTopText}>
                  What type of creatives you want to add?
                </div>
                <div className="d-flex">
                  {creativeTypes
                    .map((crt) => ({ ...crt, order: sortOrder[`${crt.label}`] }))
                    .sort((a, b) => a.order - b.order)
                    .map((crtType: Option<number>) => (
                      <div
                        key={crtType.value}
                        tabIndex={0}
                        className={`${styles.creativeTypeBox} ${
                          selectedCreativeType?.value === crtType.value
                            ? styles.creativeTypeBoxSelected
                            : ''
                        }`}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          if (e.keyCode === 13 || e.keyCode === 32) {
                            this.selectCreativeTypeHandler(crtType);
                            e.stopPropagation();
                          }
                        }}
                        onClick={() => this.selectCreativeTypeHandler(crtType)}
                      >
                        <Icon name={creativesIDMap[crtType.value]} />
                        {crtType.label}
                      </div>
                    ))}
                </div>
              </div>
            )}
            {selectedCreativeType && !editableCampaign?.creativeType && (
              <div
                data-not-valid={hasNoCreativesSelectedError}
                className={`${styles.creativesActionButtons} ${
                  (isSelectedTypeVideo || isSelectedTypeAudio) &&
                  !this.props.isAdvanceAudioVideoTargeted
                    ? styles.video
                    : ''
                } ${this.props.isAdvanceAudioVideoTargeted ? styles.advancedTargeting : ''}`}
              >
                <div className={styles.selectCreativeActions}>
                  <div className={styles.selectCreative}>
                    <div className={styles.helpText}>
                      {isSelectedTypeImage && (
                        <>
                          Image creatives capture attention with visually appealing graphics,
                          delivering an engaging and eye-catching brand experience.
                        </>
                      )}
                      {isSelectedTypeHtml && (
                        <>
                          HTML creatives offer dynamic and interactive content, elevating user
                          engagement and interaction with your brand.
                        </>
                      )}
                      {isSelectedTypeVideo && (
                        <>
                          Video creatives bring your story to life, delivering a compelling visual
                          narrative to captivate your audience.
                        </>
                      )}
                      {isSelectedTypeAudio && (
                        <>
                          Audio creatives immerse users in a rich auditory experience, conveying
                          your brand message through sound.
                        </>
                      )}
                      {isSelectedTypeNative && (
                        <>
                          Native Creatives seamlessly blend into the surrounding content, providing
                          a non-disruptive and cohesive user experience.
                        </>
                      )}
                    </div>
                    <div
                      className={`d-flex justify-content-center w-100 px-4 align-items-center ${styles.actionBtns}`}
                    >
                      <div
                        className={`${
                          hasNoCreativesSelectedError &&
                          (isSelectedTypeVideo || isSelectedTypeAudio)
                            ? styles.noCreativesSelectedMinWidth
                            : ''
                        }
                    ${
                      hasNoCreativesSelectedError && !isSelectedTypeVideo
                        ? styles.noCreativesSelected
                        : ''
                    }`}
                      >
                        <Button
                          onClick={this.onSelectCreativesPopupOpen}
                          className={`btn-square _filled _cornflower-blue _map m-0 _md `}
                        >
                          + Select Creatives
                        </Button>
                        {hasNoCreativesSelectedError ? (
                          <p className={`text-field__help-text _error ${styles.warningMsg}`}>
                            Please attach at least one creative.
                          </p>
                        ) : null}
                      </div>
                      <div>
                        {!isSelectedTypeNative ? (
                          <Button
                            className={`btn-square _cornflower-blue _map m-0 _md `}
                            onClick={(e: MouseEvent) => {
                              e.stopPropagation();
                              this.onCreateCreativesPopupOpen();
                            }}
                          >
                            + New Creative
                          </Button>
                        ) : (
                          <div className={styles.createNativeCtaWrapper}>
                            <Button
                              className={`btn-square _cornflower-blue _map m-0 _md `}
                              onClick={(e: MouseEvent) => {
                                e.stopPropagation();
                                this.showCreateNativeMenu();
                              }}
                            >
                              Add New Creatives <Icon name="DropDown" />
                            </Button>
                            {this.state.showCreateNativeMenu ? (
                              <div
                                className={styles.nativeActionsWrapper}
                                onBlur={() => this.setState({ showCreateNativeMenu: false })}
                              >
                                <ActionMenu
                                  className={styles.actionMenu}
                                  actions={[
                                    {
                                      label: 'By uploading Images or Videos',
                                      onClick: (e: MouseEvent) => {
                                        this.setState((prev) => ({
                                          ...prev,
                                          isExistingCreative: false,
                                        }));
                                        this.onCreateCreativesPopupOpen();
                                      },
                                      iconName: 'Upload',
                                    },
                                    {
                                      label: 'Using existing Images or Videos',
                                      onClick: (e: MouseEvent) => {
                                        this.setState((prev) => ({
                                          ...prev,
                                          isExistingCreative: true,
                                        }));
                                        this.onCreateCreativesPopupOpen();
                                      },
                                      iconName: 'CreativeImage',
                                    },
                                  ]}
                                />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {(isSelectedTypeVideo || isSelectedTypeAudio) && (
                    <>
                      <div className={styles.verticalLine} />
                      <div className={styles.tumblerActions}>
                        {isSelectedTypeVideo && (
                          <Tumbler
                            onOff={false}
                            title="TV-Ad Buying"
                            on={isTvAd}
                            disabled={this.props.isAdvanceAudioVideoTargeted}
                            onChange={this.handleIsTvAdChange}
                            className={`${styles.tumblerAligned}`}
                          />
                        )}
                        <Tumbler
                          onOff={false}
                          disabled={isTvAd}
                          title="Advanced Targeting"
                          on={this.props.isAdvanceAudioVideoTargeted}
                          onChange={this.handleIsAdvancedTargetingChange}
                          className={styles.tumblerAligned}
                        />
                      </div>
                    </>
                  )}
                </div>
                {(isSelectedTypeVideo || isSelectedTypeAudio) &&
                  this.props.isAdvanceAudioVideoTargeted && (
                    <>
                      <AdvancedVideoOptionsGroup
                        creativeAdvanceTargeting={this.props.creativeAdvanceTargeting}
                        setAdvancedVideoTargetingOptions={
                          this.props.setAdvancedVideoTargetingOptions
                        }
                        creativeTypeId={selectedCreativeType.value}
                      />
                      <div className={styles.infoTile}>
                        <span>
                          <Icon className={styles.infoIcon} name="Info" />
                        </span>
                        Not all exchanges provide information about{' '}
                        {isSelectedTypeVideo &&
                          'video placement type, roll positions, player size, skippability or playback method'}{' '}
                        {isSelectedTypeAudio && 'audio roll positions'}. Deselecting "Unknown" may
                        reduce your reach. If you're concerned about scalability, please always
                        select "Unknown".
                      </div>
                    </>
                  )}
              </div>
            )}
          </div>
          {(editableCampaign?.creativeType === 14 || editableCampaign?.creativeType === 17) && (
            <div
              className={`mx-auto my-5 py-4 ${styles.creativesActionButtons} ${
                isSelectedTypeVideo && !this.props.isAdvanceAudioVideoTargeted ? styles.video : ''
              } ${styles.advancedTargeting}`}
            >
              <div className="d-flex">
                {isSelectedTypeVideo && (
                  <Tumbler
                    className="ml-4"
                    onOff={false}
                    title="TV-Ad Buying"
                    on={isTvAd}
                    onChange={this.handleIsTvAdChange}
                    disabled={this.props.isAdvanceAudioVideoTargeted}
                  />
                )}
                <Tumbler
                  onOff={false}
                  disabled={isTvAd}
                  title="Advanced Targeting"
                  on={this.props.isAdvanceAudioVideoTargeted}
                  onChange={this.handleIsAdvancedTargetingChange}
                  className="ml-4"
                />
              </div>
              {this.props.isAdvanceAudioVideoTargeted ? (
                <>
                  <AdvancedVideoOptionsGroup
                    creativeAdvanceTargeting={this.props.creativeAdvanceTargeting}
                    setAdvancedVideoTargetingOptions={this.props.setAdvancedVideoTargetingOptions}
                    isEditMode
                    creativeTypeId={selectedCreativeType?.value}
                  />
                  {isSelectedTypeAudio && (
                    <div className={styles.infoTile}>
                      <span>
                        <Icon className={styles.infoIcon} name="Info" />
                      </span>
                      Not all exchanges provide information about{' '}
                      {isSelectedTypeAudio && 'audio roll positions'}
                      {isSelectedTypeVideo &&
                        'video placement type, roll positions, player size, skippability or playback method'}
                      . Deselecting "Unknown" may reduce your reach. If you're concerned about
                      scalability, please always select "Unknown".
                    </div>
                  )}
                </>
              ) : null}
            </div>
          )}
          {selectedCreatives.length > 0 && (
            <SelectedCreativesTable
              creatives={selectedCreatives}
              removeSelectedCreatives={this.handleRemoveSelectedCreatives}
              getAdvertiserURLField={this.getAdvertiserURLField}
              creativesPlacementMapping={creativesPlacementMapping}
              setCreativesPlacementMapping={setCreativesPlacementMapping}
              editableCampaign={editableCampaign}
              sidebarCampaignInfo={this.props.sidebarCampaignInfo}
            />
          )}
          <RenderRoutes
            activePath={this.state.activePath}
            openIndex={this.props.openIndex}
            setCollapseBlockIndex={this.props.setCollapseBlockIndex}
            handleSelectCreativePopupClose={this.onSelectCreativesPopupClose}
            onCreateCreativesPopupClose={this.onCreateCreativesPopupClose}
            getCreativesUrl={this.getCreativesUrl}
            selectedCreativeType={selectedCreativeType}
            user={user}
          />
        </CollapsibleBlock>
        {this.renderDialog()}
        {this.state.showDisableAdvacedVideoDialog && this.renderDisableAdvancedTargetings()}
      </>
    );
  }
}

const mapState = createSelector(
  (state: AppState) => state.creatives,
  (state: AppState) => state.auth.userData,
  (state: AppState) => state.app.submitted,
  (state: AppState) => state.app.saveDraftSubmitted,
  (state: AppState) => state.app.errorCreating,
  (state: AppState) => state.app.editableCampaign,
  (state: AppState) => state.advertiser,
  (state: AppState) => state.app.collapseData.openIndex,
  (state: AppState) => state.technology.isTvAd,
  (state: AppState) => state.advanced.sidebarCampaignInfo,
  (
    creatives,
    user,
    submitted,
    saveDraftSubmitted,
    errorCreating,
    editableCampaign,
    advertiserForm,
    openIndex,
    isTvAd,
    sidebarCampaignInfo,
  ) => ({
    creativeTypes: creatives.creativeTypes,
    selectedCreativeType: creatives.selectedCreativeType,
    creatives: creatives.creatives,
    selectedCreatives: creatives.selectedCreatives,
    advertiserUrl: creatives.advertiserUrl,
    creativesPlacementMapping: creatives.creativesPlacementMapping,
    isAdvanceAudioVideoTargeted: creatives.isAdvanceAudioVideoTargeted,
    creativeAdvanceTargeting: creatives.creativeAdvanceTargeting,
    user,
    submitted,
    saveDraftSubmitted,
    errorCreating,
    editableCampaign,
    advertiserForm,
    openIndex,
    isTvAd,
    sidebarCampaignInfo,
  }),
);

const mapAction = {
  ...creativesActions,
  changeUrl: creativesActions.changeUrl,
  setCreativesPlacementMapping: creativesActions.setCreativesPlacementMapping,
  setIsAdvancedVideoTargeting: creativesActions.setIsAdvancedVideoTargeting,
  setAdvancedVideoTargetingOptions: creativesActions.setAdvancedVideoTargetingOptions,
  resetError: applicationActions.resetError,
  setEditableCampaign: applicationActions.setEditableCampaign,
  setCollapseBlockIndex: applicationActions.setCollapseBlockIndex,
  setIsTvAd: technologyActions.setIsTvAd,
};

export const CreativeBlock = withRouter(connect(mapState, mapAction)(CreativeBlockComponent));
