import React from 'react';
import { Icon } from 'factor';
import InfiniteScroll from 'react-infinite-scroller';
import keyBy from 'lodash/keyBy';

import { Creative } from 'models/Creative';
import CreativeCard from './creativeCard/CreativeCard';

import styles from './CreativesCardList.module.scss';
import styles1 from '../styles.module.scss';

type TCreativesCardListProps = {
  isLoading: boolean;
  pageNumber: number;
  creatives: Array<Creative>;
  filteredRecords?: number;
  selectedCreatives: Creative[];
  onCreativesSelected: (selectedCreatives: Creative[]) => void;
  loadCreatives: (page: number) => void;
  emptyListLabel: string;
};

// eslint-disable-next-line import/no-mutable-exports
export let CreativeCardsComponentInstance: any;

class CreativesCardList extends React.Component<TCreativesCardListProps> {
  selectedCreativesMap: any;

  constructor(props: TCreativesCardListProps) {
    super(props);
    CreativeCardsComponentInstance = this;
  }

  componentDidUpdate(oldProps: TCreativesCardListProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (oldProps.selectedCreatives.length && !this.props.selectedCreatives.length) {
      this.clearSelected();
    }
  }

  clearSelected = () => {
    if (!this.selectedCreativesMap) {
      this.selectedCreativesMap = {};
    }

    Object.keys(this.selectedCreativesMap).forEach((creativeId: string) => {
      delete this.selectedCreativesMap[creativeId];
    });

    this.forceUpdate();
  };

  selectCreative = (creative: Creative, isSelected: boolean, selectedCreativeInstance: any) => {
    const { selectedCreatives, onCreativesSelected } = this.props;

    if (!this.selectedCreativesMap) {
      this.selectedCreativesMap = {};
    }

    if (!isSelected) {
      delete this.selectedCreativesMap[creative.id];
    } else {
      this.selectedCreativesMap[creative.id] = selectedCreativeInstance;
    }

    const updatedSelectedCreatives = isSelected
      ? [...selectedCreatives, creative]
      : selectedCreatives.filter((_creative: Creative) => _creative.id !== creative.id);

    onCreativesSelected(updatedSelectedCreatives);
  };

  renderCreativeCard = (creative: Creative) => {
    const isSelected = this.selectedCreativesMap && this.selectedCreativesMap[creative.id];

    return (
      <li className={`${styles.item}`} key={creative.id}>
        <CreativeCard
          selected={isSelected}
          creative={creative}
          selectCreative={this.selectCreative}
        />
      </li>
    );
  };

  render() {
    const {
      isLoading,
      creatives,
      selectedCreatives,
      filteredRecords = 0,
      loadCreatives,
      pageNumber,
      emptyListLabel,
    } = this.props;

    if (!isLoading && !(creatives && creatives.length)) {
      return (
        <div className={styles.noDataAvailable}>
          <Icon name="NoData" />
          <h3 className="no-data-placeholder__subtitle">{emptyListLabel}</h3>
        </div>
      );
    }

    if (!this.selectedCreativesMap && selectedCreatives.length) {
      this.selectedCreativesMap = keyBy(selectedCreatives, 'id');
    }

    const hasMore = creatives.length < filteredRecords && !isLoading;

    return (
      <div className={styles1.fixContentHeight}>
        <InfiniteScroll
          pageStart={pageNumber}
          loadMore={loadCreatives}
          useWindow={false}
          threshold={400}
          hasMore={hasMore}
          initialLoad={false}
        >
          <ul className={`${styles.list} _tile`}>{creatives.map(this.renderCreativeCard)}</ul>
          {isLoading && (
            <div className="loader" key={0}>
              Loading ...
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  }
}

export default CreativesCardList;
