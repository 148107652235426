import { WithResponse } from '@applift/conversion/lib/models/Response';
import { enqueueSnackbar } from '@applift/factor';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getCampaignByStatusCount } from 'api/Count';
import { getCampaignByStatusCountKey } from 'api/QueryKeys';
import { CampaignStatusCountType } from 'models/Count';
import { useHistory } from 'react-router-dom';

export const useCampaignStatusCount = (
  ioIds: string,
  creativeTypeIds?: string,
  options?: {
    onSuccess?: UseQueryOptions<WithResponse<CampaignStatusCountType>>['onSuccess'];
    onError?: UseQueryOptions<WithResponse<CampaignStatusCountType>>['onError'];
    enabled?: UseQueryOptions<WithResponse<CampaignStatusCountType>>['enabled'];
  },
) => {
  const history = useHistory();
  const data = useQuery(
    getCampaignByStatusCountKey.keys('getCampaignByStatusCount', ioIds, creativeTypeIds),
    getCampaignByStatusCount,
    {
      cacheTime: 0,
      onError: (e: WithResponse) => {
        history.push('/io-list');
        enqueueSnackbar(
          e.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong. Please try after sometime.',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
    },
  );
  return data;
};
