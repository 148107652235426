import { CreativesPlacementMapping } from 'models/ExistingCampaign';
import { Option } from 'models/Option';
import { Creative } from 'models/Creative';
import { createAction } from 'utils/actions';
import { creativesConstants } from './constants';
import { ICreativeAdvancedTargeting } from './reducer';

export interface CreativesActions {
  fetchCreativeTypes: () => void;
  selectCreativeType: (creativeType: Option<number> | null) => void;
  setCreatives: (creatives: Creative[]) => void;
  selectCreatives: (creatives: Creative[]) => void;
  setCreativesPlacementMapping: (mapping: CreativesPlacementMapping) => void;
  validateCreativeStore: () => void;
  setIsAdvancedVideoTargeting: (isAdvancedVideoTargeting: boolean) => void;
  setAdvancedVideoTargetingOptions: (
    creativeAdvanceTargeting: ICreativeAdvancedTargeting | undefined,
  ) => void;
}

export interface ChangeUrl {
  changeUrl: (url: string) => void;
}

export interface ValidateCreativeStore {
  validateCreativeStore: () => void;
}

export const creativesActions = {
  fetchCreativeTypes() {
    return createAction(creativesConstants.FETCH_CREATIVES);
  },

  selectCreativeType(creativeType: Option<number> | null) {
    return createAction<Option<number> | null>(
      creativesConstants.SELECT_CREATIVE_TYPE,
      creativeType,
    );
  },

  setCreatives(creatives: Creative[]) {
    return createAction<Creative[]>(creativesConstants.SET_CREATIVES, creatives);
  },

  selectCreatives(creatives: Creative[]) {
    return createAction<Creative[]>(creativesConstants.SELECT_CREATIVES, creatives);
  },

  setCreativesPlacementMapping(mapping: CreativesPlacementMapping) {
    return createAction<CreativesPlacementMapping>(
      creativesConstants.SET_CREATIVES_PLACEMENT_MAPPING,
      mapping,
    );
  },

  changeUrl(url: string) {
    return createAction<string>(creativesConstants.CHANGE_ADVERTISER_URL, url);
  },

  validateCreativeStore: () => {
    return createAction(creativesConstants.VALIDATE_CREATIVE_STORE);
  },

  setIsAdvancedVideoTargeting(isAdvancedVideoTargeting: boolean) {
    return createAction(creativesConstants.SET_ADVANCED_VIDEO_TARGETING, isAdvancedVideoTargeting);
  },

  setAdvancedVideoTargetingOptions(
    creativeAdvanceTargeting: ICreativeAdvancedTargeting | undefined,
  ) {
    return createAction(
      creativesConstants.SET_ADVANCED_VIDEO_TARGETING_OPTIONS,
      creativeAdvanceTargeting,
    );
  },
};
