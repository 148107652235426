import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { API } from 'api';
import { AppState } from 'models/Store';
import { TargableAudienceResponseType, getEsimatorInfoQueryKey } from 'models/Estimator';
import { WithResponse } from 'models/Response';

export function useTargatableAudience(options?: {
  enabled?: UseQueryOptions<WithResponse<TargableAudienceResponseType>>['enabled'];
  onSuccess?: UseQueryOptions<WithResponse<TargableAudienceResponseType>>['onSuccess'];
  onError?: UseQueryOptions<WithResponse<TargableAudienceResponseType>>['onError'];
}) {
  const payload = useSelector<AppState>((state) => state.estimator.targatableAudience) || {};

  const response = useQuery(
    getEsimatorInfoQueryKey.keys(payload as object),
    API.Estimator.getTargatableAudienceInfo,
    {
      enabled: !!Object.keys(payload as object).length && options?.enabled,
      onSuccess: options?.onSuccess,
      onError: options?.onError,
      keepPreviousData: true,
    },
  );

  return response;
}
