import * as React from 'react';
import { Box, Button, Col, Row, isDeepEqual } from '@applift/factor';
import { connect } from 'react-redux';

import { AppState } from 'models/Store';
import { EstimatorState } from 'store/estimator/reducer';
import { EstimatorDataActionType, estimatorActions } from 'store/estimator/actions';
import { useEstimatorReqKeys } from 'hooks/useEstimatorReqKeys';
import { CampaignInfoField } from 'models/CampaignInfoFields';

import { CampaignEstimatorParams } from 'models/Estimator';
import { EstimatorAlertType, WarningAlert } from './WarningAlert';
import { EstimatorWrapper } from './EstimatorWrapper';

interface Props extends EstimatorState {
  setTargatableAudiencePayload: EstimatorDataActionType['setTargatableAudiencePayload'];
  setBidLandscapePayload: EstimatorDataActionType['setBidLandscapePayload'];
  setReachImpressionPayload: EstimatorDataActionType['setReachImpressionPayload'];
  setEstimatorStaleCondition: EstimatorDataActionType['setEstimatorStaleCondition'];
  stale: EstimatorState['stale'];
  isEstimatorAvailable?: boolean;
  isEditingMode?: boolean;
  campaignId: number | null;
}

const EstimatorComponent = (props: Props) => {
  const {
    setTargatableAudiencePayload,
    setBidLandscapePayload,
    estimatorKeys,
    setReachImpressionPayload,
    isEstimatorAvailable,
    isEditingMode,
    campaignId,
    setEstimatorStaleCondition,
    stale,
  } = props;

  const [targetableAudienceLocalPayload, setTargetableAudienceLocalPayload] =
    React.useState<CampaignEstimatorParams>();
  const [bidLandscapeLocalPayload, setBidLandscapeLocalPayload] =
    React.useState<CampaignEstimatorParams>();
  const [reachImpressionLocalPayload, setReachImpressionLocalPayload] =
    React.useState<CampaignEstimatorParams>();

  const targetableAudiencePrevPayloadRef = React.useRef<CampaignEstimatorParams>();
  const bidlandscapePrevPayloadRef = React.useRef<CampaignEstimatorParams>();
  const reachImpressionPrevPayloadRef = React.useRef<CampaignEstimatorParams>();

  const [shouldUpdateReach, setShouldUpdateReach] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [defaultAlertShown, setDefaultAlertShown] = React.useState(false);

  const { data: requiredKeyData } = useEstimatorReqKeys();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alertRef, setAlertRef] = React.useState<keyof EstimatorAlertType>();

  React.useEffect(() => {
    if (isEstimatorAvailable === false) {
      return setAlertRef('olderCampaign');
    }
    if (estimatorKeys?.countryIds && estimatorKeys?.countryIds !== '30100001') {
      return setAlertRef('countryIssue');
    }
    return setAlertRef(undefined);
  }, [
    campaignId,
    defaultAlertShown,
    estimatorKeys,
    isEditingMode,
    isEstimatorAvailable,
    requiredKeyData?.bidLandscapeEstimation,
    requiredKeyData?.reachImpression,
    requiredKeyData?.targetableAudience,
  ]);

  const hideEstimator = React.useMemo(
    () =>
      isEstimatorAvailable === false ||
      (estimatorKeys?.countryIds && estimatorKeys?.countryIds !== '30100001'),
    [estimatorKeys?.countryIds, isEstimatorAvailable],
  );

  const getNestedKeyValuePairs = (obj: any, keysArray: string | string[]) => {
    const result = {};
    function traverse(obj: any, path: any) {
      if (typeof obj !== 'object' || obj === null) {
        return;
      }
      Object.keys(obj).forEach((key) => {
        const newPath = path ? `${path}.${key}` : key;
        if (keysArray.includes(newPath)) {
          // @ts-ignore
          result[newPath] = obj[key];
        }
        traverse(obj[key], newPath);
      });
    }
    traverse(obj, '');
    return Object.keys(result).length ? result : null;
  };

  React.useEffect(() => {
    if (requiredKeyData) {
      const targatableAudienceKeys = Object.keys(requiredKeyData?.targetableAudience || {}).filter(
        (key) => requiredKeyData?.targetableAudience[key] === true,
      );
      const bidLandscapeKeys = Object.keys(requiredKeyData?.bidLandscapeEstimation || {}).filter(
        (key) => requiredKeyData?.bidLandscapeEstimation[key] === true,
      );
      const reachImpressionKeys = Object.keys(requiredKeyData?.reachImpression || {}).filter(
        (key) => requiredKeyData?.reachImpression[key] === true,
      );

      if (targatableAudienceKeys.length) {
        const result = targatableAudienceKeys
          .map((val) => getNestedKeyValuePairs(estimatorKeys, val))
          .filter(Boolean)
          .reduce((result, currentObject) => {
            return { ...result, ...currentObject };
          }, {});
        if (!isDeepEqual(result, targetableAudiencePrevPayloadRef.current)) {
          setEstimatorStaleCondition({ key: 'targatableAudience', val: true });
        } else {
          setEstimatorStaleCondition({ key: 'targatableAudience', val: false });
        }
        if (!isDeepEqual(result, targetableAudienceLocalPayload)) {
          setTargetableAudienceLocalPayload({ ...result } as unknown as CampaignEstimatorParams);
        }
      }
      if (bidLandscapeKeys.length) {
        const result = bidLandscapeKeys
          .map((val) => getNestedKeyValuePairs(estimatorKeys, val))
          .filter(Boolean)
          .reduce((result, currentObject) => {
            return { ...result, ...currentObject };
          }, {});
        if (!isDeepEqual(result, bidlandscapePrevPayloadRef.current)) {
          setEstimatorStaleCondition({ key: 'bidLandscape', val: true });
        } else {
          setEstimatorStaleCondition({ key: 'bidLandscape', val: false });
        }
        if (!isDeepEqual(result, bidLandscapeLocalPayload)) {
          setBidLandscapeLocalPayload({ ...result } as unknown as CampaignEstimatorParams);
        }
      }
      if (reachImpressionKeys.length) {
        const result = reachImpressionKeys
          .map((val) => getNestedKeyValuePairs(estimatorKeys, val))
          .filter(Boolean)
          .reduce((result, currentObject) => {
            return { ...result, ...currentObject };
          }, {});
        if (!isDeepEqual(result, reachImpressionPrevPayloadRef.current)) {
          setEstimatorStaleCondition({ key: 'reachAndImpressions', val: true });
        } else {
          setEstimatorStaleCondition({ key: 'reachAndImpressions', val: false });
        }
        if (!isDeepEqual(result, reachImpressionKeys)) {
          setReachImpressionLocalPayload({ ...result } as unknown as CampaignEstimatorParams);
        }
      }
    }
  }, [
    bidLandscapeLocalPayload,
    estimatorKeys,
    requiredKeyData,
    setBidLandscapePayload,
    setEstimatorStaleCondition,
    setReachImpressionPayload,
    setTargatableAudiencePayload,
    targetableAudienceLocalPayload,
  ]);

  const runCampaignEstimations = React.useCallback(() => {
    setShouldUpdateReach(true);
    if (stale?.targatableAudience && targetableAudienceLocalPayload) {
      setTargatableAudiencePayload(targetableAudienceLocalPayload);
      targetableAudiencePrevPayloadRef.current = targetableAudienceLocalPayload;
      setEstimatorStaleCondition({ key: 'targatableAudience', val: false });
    }
    if (stale?.bidLandscape && bidLandscapeLocalPayload) {
      setBidLandscapePayload(bidLandscapeLocalPayload);
      bidlandscapePrevPayloadRef.current = bidLandscapeLocalPayload;
      setEstimatorStaleCondition({ key: 'bidLandscape', val: false });
    }
    if (stale?.reachAndImpressions && reachImpressionLocalPayload) {
      setReachImpressionLocalPayload(reachImpressionLocalPayload);
      reachImpressionPrevPayloadRef.current = reachImpressionLocalPayload;
      setEstimatorStaleCondition({ key: 'reachAndImpressions', val: false });
    }
  }, [
    bidLandscapeLocalPayload,
    reachImpressionLocalPayload,
    setBidLandscapePayload,
    setEstimatorStaleCondition,
    setTargatableAudiencePayload,
    stale?.bidLandscape,
    stale?.reachAndImpressions,
    stale?.targatableAudience,
    targetableAudienceLocalPayload,
  ]);

  React.useEffect(() => {
    setTargatableAudiencePayload({} as CampaignEstimatorParams);
    setBidLandscapePayload({} as CampaignEstimatorParams);
    setReachImpressionLocalPayload({} as CampaignEstimatorParams);
    setShouldUpdateReach(false);
  }, [
    setBidLandscapePayload,
    setEstimatorStaleCondition,
    setTargatableAudiencePayload,
    estimatorKeys?.countryIds,
  ]);

  return (
    <Box sx={{ ...(Object.keys(estimatorKeys || {}).length ? { px: 16 } : null) }}>
      <Row md={1}>
        <Col>
          <WarningAlert
            alertRef={alertRef}
            estimatorKeys={estimatorKeys}
            onClose={() => (alertRef === 'defaultInfo' ? setDefaultAlertShown(true) : null)}
          />
        </Col>
        {!hideEstimator ? (
          <>
            <Col sx={{ my: 16 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button variant="outlined" sx={{ width: 100 }} onClick={runCampaignEstimations}>
                  Run Campaign Estimations
                </Button>
              </Box>
            </Col>
            <EstimatorWrapper
              estimatorKeys={estimatorKeys}
              shouldUpdateReach={shouldUpdateReach}
              setShouldUpdateReach={setShouldUpdateReach}
            />
          </>
        ) : null}
      </Row>
    </Box>
  );
};

const mapState = (state: AppState) => {
  return {
    bidLandscape: state.estimator.bidLandscape,
    reachAndImpressions: state.estimator.reachAndImpressions,
    targatableAudience: state.estimator.targatableAudience,
    estimatorKeys: state.estimator.estimatorKeys,
    maxBid: state.advanced.sidebarCampaignInfo[CampaignInfoField.maxBid],
    isEstimatorAvailable: state.app.editableCampaign?.isEstimatorAvailable,
    isEditingMode: state.app.isEditingMode,
    stale: state.estimator.stale,
    campaignId: state.app.editableCampaign?.id || null,
  };
};

const mapActions = {
  fetchEstimatorKeys: estimatorActions.fetchEstimatorKeys,
  setTargatableAudiencePayload: estimatorActions.setTargatableAudiencePayload,
  setBidLandscapePayload: estimatorActions.setBidLandscapePayload,
  setReachImpressionPayload: estimatorActions.setReachImpressionPayload,
  setEstimatorStaleCondition: estimatorActions.setEstimatorStaleCondition,
};

export const Estimator = connect(mapState, mapActions)(EstimatorComponent);
