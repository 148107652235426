import * as React from 'react';
import { MenuItem, Box, Typography } from '@applift/factor';
import { ClipboardRemove } from '@applift/icons';
import { NoData } from '@applift/illustrations';
import { DataGrid, useGridApiRef } from '@applift/datagrid';
import { connect } from 'react-redux';
import { PgDealResponseType } from 'models/Inventory';
import { AppState } from 'models/Store';
import { getPgDealsListColDef } from './coldef';

export interface SelectedPgDealsListType {
  selectedPgDeals?: PgDealResponseType[];
  setSelectedPgDeals?: (val: any) => void;
  timezone: any;
  isLoading?: boolean;
}

const SelectedPgDealsListComponent = (props: SelectedPgDealsListType) => {
  const apiRef = useGridApiRef();

  const { selectedPgDeals, setSelectedPgDeals, timezone, isLoading } = props;

  const removePGDeal = (id: number) => {
    if (setSelectedPgDeals) {
      setSelectedPgDeals((val: any[]) =>
        val.filter((innerVal: { id: number }) => innerVal.id !== id),
      );
    }
  };

  const overlay = React.useMemo(() => {
    if (!selectedPgDeals?.length && !isLoading) {
      return 'noRows';
    }
    return undefined;
  }, [isLoading, selectedPgDeals]);

  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', (params) => {
      setHeight(params.height);
    });
  }, [apiRef]);

  const NoRowsOverlay = () => {
    return (
      <MenuItem
        style={{ pointerEvents: 'none' }}
        sx={{
          width: 100,
          height: 100,
          p: 32,
        }}
      >
        <Box
          sx={{
            width: 100,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          {height <= 150 ? (
            <ClipboardRemove sx={{ textColor: 'neutral-400', mb: 8 }} fontSize={36} />
          ) : (
            <NoData
              sx={{
                textColor: 'primary-500',
                height: 'auto',
                mb: height <= 400 ? 24 : 40,
              }}
              style={{ width: height <= 400 ? '10%' : '75%' }}
            />
          )}
          <Typography>No PG deals selected. Select deals to add in campaign.</Typography>
        </Box>
      </MenuItem>
    );
  };

  return (
    <DataGrid
      apiRef={apiRef}
      hideFooter
      hideHeader
      overlay={overlay}
      rowCount={isLoading ? 20 : undefined}
      showColumnRightBorder
      components={{ NoRowsOverlay }}
      showCellRightBorder
      disableRowSelectionOnClick
      data={selectedPgDeals ?? []}
      state={{ columnPinning: { right: ['actions'] } }}
      columns={getPgDealsListColDef(removePGDeal, timezone)}
      disableColumnReorder
    />
  );
};

const mapState = (state: AppState) => ({
  timezone: state.advanced.sidebarCampaignInfo.timezone,
});

export const SelectedPgDealsList = connect(mapState)(SelectedPgDealsListComponent);
