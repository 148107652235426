import React, { useEffect, useRef, useState } from 'react';
import { CircleExtended } from 'models/CircleExtended';
import { metersToMiles, milesToMeters } from 'utils/format';

import { UpdatePreSavedCircle } from 'store/map/actions';

interface EditRadius extends UpdatePreSavedCircle {
  circleData: CircleExtended;
  selectedAreasIds: string[];
}

export const EditRadiusTextField = ({
  circleData,
  selectedAreasIds,
  updatePreSavedCircle,
}: EditRadius) => {
  const [radiusValue, setRadiusValue] = useState(metersToMiles(circleData.getRadius()).toFixed(2));
  const [inEditMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef.current?.setAttribute(
      'style',
      `width: ${(inEditMode ? radiusValue.length : Number(radiusValue).toFixed(2).length) * 8.5}px`,
    );
  }, [radiusValue, inEditMode]);

  useEffect(() => {
    if (!inEditMode) {
      setRadiusValue((radValue) => Number(radValue).toFixed(2));
    }
  }, [inEditMode]);

  const modifyRadius = () => {
    inputRef.current?.blur();
    setEditMode(false);
    if (selectedAreasIds.includes(circleData.id) && +radiusValue > 0) {
      const radiusInMeters = +milesToMeters(+radiusValue).toFixed(2);
      circleData.setRadius(radiusInMeters);
      updatePreSavedCircle(circleData);
    } else {
      setRadiusValue(metersToMiles(circleData.getRadius()).toFixed(2));
    }
  };

  return (
    <input
      value={inEditMode ? radiusValue : Number(radiusValue).toFixed(2)}
      ref={inputRef as React.MutableRefObject<HTMLInputElement>}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          modifyRadius();
        }
      }}
      onFocus={() => setEditMode(true)}
      onBlur={modifyRadius}
      onChange={(e) => {
        if (/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
          setRadiusValue(e.target.value);
        }
      }}
    />
  );
};
