import React from 'react';
import { ColumnDef } from '@applift/datagrid';
import { CurrencyCell, RawDataCell } from 'components/CellType';
import { PmpDeal } from 'models/Inventory';
import { DateCell } from '../../CellTypes';
import { DealStatusCell } from '../../CellTypes/DealStatusCell';
import { CreativeTypeCell } from '../../CellTypes/CreativeTypesCell';
import { ExchangeCell } from '../../CellTypes/ExchangeCell';

export const colDef: ColumnDef<PmpDeal>[] = [
  {
    header: 'Deal ID',
    id: 'dealId',
    accessorKey: 'dealId',
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: false,
    size: 200,
  },
  {
    header: 'Deal Name',
    id: 'dealName',
    accessorKey: 'dealName',
    cell: (props) => <RawDataCell value={props.renderValue() as string} />,
    enableSorting: true,
    size: 200,
  },
  {
    header: 'Deal Status',
    id: 'active',
    accessorKey: 'active',
    cell: (props) => <DealStatusCell active={props.renderValue() as boolean} />,
    enableSorting: false,
  },
  {
    header: 'Exchange',
    id: 'exchanges',
    accessorKey: 'exchanges',
    cell: (props) => <ExchangeCell value={props?.renderValue() as any} />,
    enableSorting: false,
  },
  {
    header: 'Creative Types',
    id: 'creativeTypes',
    accessorKey: 'creativeTypes',
    cell: (props) => <CreativeTypeCell value={props.renderValue() as number[]} />,
    enableSorting: false,
  },
  {
    header: 'CPM',
    id: 'cpm',
    accessorKey: 'cpm',
    cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
    enableSorting: true,
  },
  {
    header: 'Created',
    id: 'created',
    accessorKey: 'created',
    cell: (props) => <DateCell epoch={props.renderValue() as number} />,
  },
  {
    header: 'Description',
    id: 'description',
    accessorKey: 'description',
    enableSorting: false,
    cell: (props) => <RawDataCell value={(props.renderValue() || null) as any} />,
  },
];
