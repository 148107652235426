import { AxiosResponse } from 'axios';
import { WithResponse } from 'models/Response';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseExchangeListParams } from 'hooks/useInventory';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import { DetailedExchange, Exchange } from '../models/Exchange';

export const getItems = async (): Promise<Exchange[]> => {
  try {
    const response: AxiosResponse<Exchange[]> = await getInstance().get('/exchanges');
    return response.data;
  } catch (e) {
    Logger.log('Error while getting exchanges: ', e);
    return [];
  }
};

export const getDetailedExchangesInfo = async (
  params?: QueryFunctionContext<UseExchangeListParams[]>,
): Promise<{
  data: WithResponse<DetailedExchange[]>;
}> => {
  const { queryKey } = params ?? { queryKey: [{}] as UseExchangeListParams[] };
  const [{ pageNo = -1, searchField, exchangedIds, Ids, sortBy, noOfEntries }] = queryKey;
  try {
    const response: AxiosResponse<{ data: WithResponse<DetailedExchange[]> }> =
      await getInstance().get('v3/master/exchanges', {
        params: {
          searchField,
          exchangedIds,
          Ids,
          sortBy,
          noOfEntries,
          pageNo,
        },
      });
    return response.data;
  } catch (e) {
    Logger.log('Error while getting exchanges: ', e);
    // @ts-ignore
    return [];
  }
};
