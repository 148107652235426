import * as React from 'react';
import { Box } from '@applift/factor';
import { useRanger } from 'react-ranger';

type Props = {
  values: number[];
  max: number;
  min: number;
  onChange: (arg: any) => void;
};

export const Slider = (props: Props) => {
  const { values, max, onChange } = props;

  const maxLength = Math.floor(max).toString().length;
  const factor = +maxLength >= 4 ? +`1${'0'.repeat(+maxLength - 2)}` : 1;

  const { getTrackProps, handles, segments } = useRanger({
    values: values.map((val) => val / factor),
    onChange: (val) => {
      const resArr = val.map((item) => item * factor);
      onChange(resArr);
    },
    min: 0,
    max: max / factor,
    stepSize: 1,
  });

  const segmentColorRenderer = (index: number) => {
    if (index === 0) {
      return 'primary-600';
    }
    if (index === 1) {
      return 'neutral-400';
    }
    if (index === 2) {
      return 'neutral-100';
    }
    return 'neutral-0';
  };

  const segmentBorderRadius = (index: number) => {
    if (index === 0) {
      return 'left';
    }

    if (index === 2) {
      return 'right';
    }
    return undefined;
  };

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getTrackProps({
        style: {
          height: '6px',
        },
      })}
    >
      {segments.map(({ getSegmentProps }, i) => {
        return (
          <Box
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getSegmentProps()}
            sx={{
              bgColor: segmentColorRenderer(i),
              height: 100,
              borderRadius: segmentBorderRadius(i),
            }}
          />
        );
      })}
      {handles.map(({ getHandleProps }, i) => (
        <Box
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getHandleProps({
            style: {
              display: i === 0 ? 'none' : 'block',
              width: '26px',
              height: '20px',
              borderRadius: '8%',
              background: '#FFFFFF',
              border: '1px solid #E7E7E7;',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.04)',
              padding: '2px',
              paddingTop: '5px',
            },
          })}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderBottomColor: 'primary-600',
              mt: 2,
              mb: 2,
              mx: 2,
            }}
          />
          <Box
            sx={{
              borderBottom: 1,
              borderBottomColor: 'primary-600',
              mt: 2,
              mb: 2,
              mx: 2,
            }}
          />
          <Box sx={{ borderBottom: 1, borderBottomColor: 'primary-600', mx: 2 }} />
        </Box>
      ))}
    </div>
  );
};
