import { History } from 'history';
import { applicationConstants, CollapseMode } from './constants';
import { createAction } from '../../utils/actions';
import { ExistingCampaignData } from '../../models/ExistingCampaign';

export interface ToggleSidebarMenu {
  toggleSidebarMenu: () => void;
}

export interface ToggleRightSidebarMenu {
  toggleRightSidebar: () => void;
}

export interface CreateCampaign {
  createCampaign: (history: History) => void;
}

export interface SaveCampaignAsDraft {
  saveCampaignAsDraft: (history: History) => void;
}

export interface CreateCampaignWithAdvertiser {
  createCampaignWithAdvertiser: (history: History) => void;
}

export interface SetSubmitted {
  setSubmitted: () => void;
}

export interface SetSaveDraftSubmitted {
  setSaveDraftSubmitted: () => void;
}

export interface ChangeEditingMode {
  changeEditingMode: (value: boolean) => void;
}

export interface SetCurrentIoId {
  setCurrentIoId: (value: number) => void;
}

export interface SetEditableCampaign {
  setEditableCampaign: (campaign: ExistingCampaignData | null) => void;
}

export interface SetBudgetTypeId {
  setBudgetTypeId: (budgetTypeId: number) => void;
}

export interface CheckValidation {
  checkValidation: () => boolean;
}

export interface FillAppStore {
  fillAppStore: (data: ExistingCampaignData) => void;
}

export interface SetEditableCampaignLoadingStatus {
  setEditableCampaignLoadingStatus: (status: boolean) => void;
}

export interface SetClearingCampaignDataStatus {
  setClearingCampaignDataStatus: (status: boolean) => void;
}

export interface SetMapLoadingStatus {
  setMapLoadingStatus: (status: boolean) => void;
}

export interface SetCollapseMode {
  setCollapseMode: (mode: CollapseMode) => void;
}

export interface SetCollapseBlockIndex {
  setCollapseBlockIndex: (index: number) => void;
}

export interface ResetStore {
  resetStore: () => void;
}

export interface ResetStoreForCampaignSwitch {
  resetStoreForCampaignSwitch: () => void;
}

export interface ResetError {
  resetError: () => void;
}

export interface SetErrorCollapsiblesOpen {
  setErrorCollapsiblesOpen: (isOpen: boolean) => void;
}

export interface ResetAppStoreFull {
  resetAppStoreFull: () => void;
}

export interface SetModellingDimensionToShow {
  setModellingDimensionToShow: (dimensionName: string) => void;
}

export const applicationActions = {
  toggleSidebarMenu() {
    return createAction<void>(applicationConstants.TOGGLE__SIDEBAR_MENU);
  },

  toggleRightSidebar() {
    return createAction<void>(applicationConstants.TOGGLE_RIGHT_SIDEBAR_MENU);
  },

  createCampaign(history?: History) {
    return createAction(applicationConstants.START_CREATING, history);
  },

  saveCampaignAsDraft(history?: History) {
    return createAction(applicationConstants.START_SAVING_CAMPAIGN_AS_DRAFT, history);
  },

  createCampaignWithAdvertiser(history: History) {
    return createAction(applicationConstants.CREATE_CAMPAIGN_WITH_ADVERTISER, history);
  },

  setSubmitted() {
    return createAction<void>(applicationConstants.SET_SUBMITTED_STATE);
  },

  setCurrentIoId(value: number) {
    return createAction<number>(applicationConstants.SET_CURRENT_IO_ID, value);
  },

  setSaveDraftSubmitted() {
    return createAction<void>(applicationConstants.SET_SAVE_DRAFT_SUBMITTED_STATE);
  },

  changeEditingMode(value: boolean) {
    return createAction<boolean>(applicationConstants.CHANGE_EDITING_MODE, value);
  },

  setEditableCampaign(campaign: ExistingCampaignData | null) {
    return createAction<ExistingCampaignData | null>(
      applicationConstants.SET_EDITABLE_CAMPAIGN,
      campaign || null,
    );
  },
  setBudgetTypeId(budgetTypeId: number) {
    return createAction<number>(applicationConstants.SET_BUDGET_TYPE_ID, budgetTypeId || 1);
  },

  fillAppStore(data: ExistingCampaignData) {
    return createAction<ExistingCampaignData>(applicationConstants.FILL_APPLICATION_STORE, data);
  },

  setEditableCampaignLoadingStatus(status: boolean) {
    return createAction<boolean>(applicationConstants.SET_EDITABLE_CAMPAIGN_LOADING_STATUS, status);
  },

  setClearingCampaignDataStatus(status: boolean) {
    return createAction<boolean>(applicationConstants.RESET_CLEARING_CAMPAIGN_DATA, status);
  },

  setMapLoadingStatus(status: boolean) {
    return createAction<boolean>(applicationConstants.SET_MAP_LOADING_STATUS, status);
  },

  setCollapseMode(mode: CollapseMode) {
    return createAction<CollapseMode>(applicationConstants.SET_COLLAPSE_MODE, mode);
  },

  setCollapseBlockIndex(index: number) {
    return createAction<number>(applicationConstants.SET_COLLAPSE_BLOCK_INDEX, index);
  },

  resetError() {
    return createAction<void>(applicationConstants.RESET_ERROR);
  },

  resetStore: () => {
    return createAction(applicationConstants.START_RESET_STORE);
  },

  resetStoreForCampaignSwitch: () => {
    return createAction(applicationConstants.START_RESET_STORE_CAMPAIGN_SWITCH);
  },

  resetAppStoreFull: () => {
    return createAction(applicationConstants.RESET_APP_STORE_FULL);
  },

  setErrorCollapsiblesOpen: (isOpen: boolean) => {
    return createAction<boolean>(applicationConstants.SET_ERROR_COLLAPSIBLES_OPEN, isOpen);
  },

  setModellingDimensionToShow(dimension: string) {
    return createAction<string>(applicationConstants.SET_MODELLING_DIMENSION_TO_SHOW, dimension);
  },
};
