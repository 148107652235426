import React from 'react';
import { Icon, Tooltip } from 'factor';

import { AudienceWarning } from 'models/Audience';
import { AUDIENCE_SOME_UNAPPROVED, AUDIENCE_ALL_UNAPPROVED } from 'constants/messages';

import styles from './AudienceWarningMessage.module.scss';

type Props = {
  type: AudienceWarning;
  iconOnly: boolean;
  className?: string;
  iconClassName?: string;
  tooltipPosition?: string;
};

export const AudienceWarningMessage = (props: Props) => {
  const { type, iconOnly, className, iconClassName, tooltipPosition } = props;

  if (type === AudienceWarning.NONE) {
    return null;
  }

  const text =
    type === AudienceWarning.SOME_UNAPPROVED ? AUDIENCE_SOME_UNAPPROVED : AUDIENCE_ALL_UNAPPROVED;

  return (
    <div
      className={`${className || ''} ${styles.warningWrapper} ${
        !iconOnly ? styles.expandedMessage : styles.iconOnly
      }`}
    >
      {!iconOnly ? (
        <>
          <Icon className={iconClassName || ''} name="WarningTriangle" />
          <span className={styles.warningText}>{text}</span>
        </>
      ) : (
        <Tooltip label={text} position={tooltipPosition} auto={!tooltipPosition} portal>
          <Icon className={iconClassName || ''} name="WarningTriangle" />
        </Tooltip>
      )}
    </div>
  );
};
