import { createAction } from '../../utils/actions';
import { technologyConstants } from './constants';
import { Option } from '../../models/Option';

type Data = {
  key: string;
  value: Option<number>[];
};

export type SetTechnologyField = {
  setTechnologyField: (data: Data) => void;
};

export type SelectTechnologyField = {
  selectTechnologyField: (data: Data) => void;
};

export type ResetState = {
  resetState: () => void;
};

export type SetIsTvAd = {
  setIsTvAd: (isTvAd: boolean) => void;
};

export type ValidateTechnologyStore = {
  validateTechnologyStore: () => void;
};

export const technologyActions = {
  setTechnologyField(data: Data) {
    return createAction<Data>(technologyConstants.SET_TECHNOLOGY_FIELD, data);
  },

  selectTechnologyField(data: Data) {
    return createAction<Data>(technologyConstants.SELECT_TECHNOLOGY_FIELD, data);
  },

  resetState() {
    return createAction(technologyConstants.RESET_TECHNOLOGY_STORE);
  },

  setIsTvAd(isTvAd: boolean) {
    return createAction(technologyConstants.SET_IS_TV_AD, isTvAd);
  },

  validateTechnologyStore() {
    return createAction(technologyConstants.VALIDATE_TECHNOLOGY_STORE);
  },
};
