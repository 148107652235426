import * as React from 'react';
import { Box, IconButton, Typography } from '@applift/factor';
import { Redo, AlertTriangle, Hourglass } from '@applift/icons';

type Props = {
  errorType: 'Timeout' | 'Server';
  refetch?: () => void;
  onRefetch?: (errorType: string) => void;
  widgetTitle: string;
};

export const WidgetError = (props: Props) => {
  const { refetch, errorType, onRefetch, widgetTitle } = props;

  const errorString =
    (errorType === 'Timeout' ? "It's taking more time than usual." : null) ||
    (errorType === 'Server'
      ? 'Oops, something went wrong. Please try again after some time.'
      : null);

  const handleRefetchClick = () => {
    if (onRefetch) {
      onRefetch(errorType);
    }
    if (refetch) {
      refetch();
    }
  };

  return (
    <Box sx={{ px: 16, py: 32, textAlign: 'center' }}>
      <Box>
        {errorType === 'Timeout' ? (
          <Hourglass fontSize={40} sx={{ textColor: 'neutral-300' }} />
        ) : (
          <AlertTriangle fontSize={40} sx={{ textColor: 'neutral-300' }} />
        )}
      </Box>
      <Typography>{errorString}</Typography>
      <Box sx={{ width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {errorType === 'Timeout' ? 'Please' : null}
        <IconButton
          sx={{
            textColor: 'primary-600',
            p: 0,
            display: errorType === 'Timeout' ? 'flex' : 'none',
            alignItems: 'center',
          }}
        >
          <Redo fontSize={16} />
          <Typography
            onClick={handleRefetchClick}
            sx={{ my: 'auto' }}
            variant="bodySmall"
            data-dd-action-name={`Refresh Estimator Widget (${widgetTitle})`}
          >
            {errorType === 'Timeout' ? 'Refresh' : null}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};
