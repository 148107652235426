import { union } from 'lodash';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { CityOption, DistrictOption } from 'models/Location';
import { Conversion } from 'models/Conversion';
import { AdvancePageState, WhiteLists } from 'store/advance/reducer';
import { MapState } from 'store/map/reducer';

export const getLocationWarningVisibility = (
  storeSideBarInfo: AdvancePageState['sidebarCampaignInfo'],
  mapStoreInfo: MapState,
  whiteLists: WhiteLists,
) => {
  const includedParentIds = storeSideBarInfo[CampaignInfoField.states]
    .filter((item) => whiteLists.whiteListedStateIds?.includes(item.value))
    .map((item) => item.value);

  const getParentIdsofIncludedItems = (
    items: DistrictOption[] | CityOption[],
    whiteListItems?: number[],
  ) =>
    items
      ? (items as DistrictOption[]).map((item) =>
          whiteListItems?.includes(item.value) ? item.parentId : null,
        )
      : [];

  const selectedIncludedStateAbbreviations = storeSideBarInfo[CampaignInfoField.states]
    .filter((item) => whiteLists.whiteListedStateIds?.includes(item.value))
    .map((includedStateId) => includedStateId.abbreviation?.toUpperCase());

  const { isstateOrPoliticalDuplicatesPresent } = (({
    selectedCities,
    selectedCounties,
    selectedDma,
    selectedCongressionalDistricts,
    selectedSenates,
    selectedHouses,
    selectedZipCodes,
  }) => ({
    isstateOrPoliticalDuplicatesPresent: union(
      getParentIdsofIncludedItems(selectedDma, whiteLists.whiteListedDmaIds),
      getParentIdsofIncludedItems(selectedSenates, whiteLists.whiteListedSenateDistrictIds),
      getParentIdsofIncludedItems(
        selectedCongressionalDistricts,
        whiteLists.whiteListedCongressionalDistrictIds,
      ),
      getParentIdsofIncludedItems(selectedCounties, whiteLists.whiteListedCountyIds),
      getParentIdsofIncludedItems(selectedCities, whiteLists.whiteListedCityIds),
      getParentIdsofIncludedItems(selectedHouses, whiteLists.whiteListedHouseDistrictIds),
      getParentIdsofIncludedItems(selectedZipCodes, whiteLists.whiteListedZipcodes),
    ).some((item) => includedParentIds.includes(item as number)),
  }))(storeSideBarInfo);

  const { isCustomAreaDuplicatePresent } = (({
    preSavedCircles,
    preSavedRectangles,
    preSavedPolygons,
  }) => ({
    isCustomAreaDuplicatePresent:
      !!preSavedCircles.filter(
        (circle) =>
          circle.isInclude &&
          selectedIncludedStateAbbreviations.includes(circle.state.toUpperCase()),
      ).length ||
      !!preSavedPolygons.filter(
        (polygon) =>
          polygon.isInclude &&
          selectedIncludedStateAbbreviations.includes(polygon.state.toUpperCase()),
      ).length ||
      !!preSavedRectangles.filter(
        (rectangle) =>
          rectangle.isInclude &&
          selectedIncludedStateAbbreviations.includes(rectangle.state.toUpperCase()),
      ).length,
  }))(mapStoreInfo);

  return isstateOrPoliticalDuplicatesPresent || isCustomAreaDuplicatePresent;
};

export const getPixelConversionWarningVisibility = (conversions: Conversion[]) => {
  if (conversions.length) {
    return !!conversions.find((conversion) => conversion.status?.toLowerCase() === 'pending');
  }
  return false;
};
