import * as React from 'react';
import {
  Add,
  CreativeAudio,
  CreativeHTML,
  CreativeImage,
  CreativeNative,
  CreativeVideo,
  InfoCircle,
  LayoutCard,
} from '@applift/icons';
import { useStore } from 'zustandStore';
import { Box, Button, Col, Link, Row, TextField, Typography, sx } from '@applift/factor';
import { CREATIVE_ID_FROM_NAME, CREATIVE_NAME_FROM_ID } from 'constants/creatives';
import { BlockWiseErrorType, ErrorStateForComponentType, StoreFilter } from 'models/ZustandStore';
import { PG_CAMPAIGN_COMPONENTS } from 'constants/pgCampaign';
import { Creative } from 'models/Creative';
import { useCreativeTypes } from 'hooks/useCreatives';
import { isValidUrl } from 'utils/isValidUrl';
import { urlRegEx } from 'utils/validationRules';
import { BlockWrapper } from '../BlockWrapper';
import { CreateSelectCreativeBlock } from './components/CreateSelectCreativeBlock';
import { SelectCreativeDialog } from './components/SelectCreativeDialog';
import { SelectedCreativeTable } from './components/SelectedCreativedTable';
import { CreateCreativeBlock } from './components/CreateCreativeDialog';
import { ChangeCreativeDialog } from './components/ChangeCreativeDialog';

const creativeOrder = {
  11: 1,
  13: 2,
  14: 3,
  17: 4,
  15: 5,
};

export const CreativeBlock = () => {
  const [creativeSelectDialogType, setCreativeSelectDialogType] = React.useState<
    'create' | 'select' | 'confirmClickUrlChange' | ''
  >('');

  const [
    selectedCreatives,
    setSelectedCreatives,
    creativeTypeId,
    setCreativeTypeId,
    expandedBlocks,
    setExpandedBlocks,
    isEditMode,
    advertiserDomain,
    setAdvertiserDomain,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    creativesBlockError,
    campaignInfoBlockError,
    resetError,
    setBlockFieldsError,
    campaignDetails,
  ] = useStore((state: StoreFilter) => [
    state.creativesBlock.selectedCreatives,
    state.creativesBlock.setSelectedCreatives,
    state.creativesBlock.creativeTypeId,
    state.creativesBlock.setCreativeTypeId,
    state.pgCampaignPage.expandedBlocks,
    state.pgCampaignPage.setExpandedBlocks,
    state.pgCampaignPage.isEditMode,
    state.campaignInfoBlock.advertiserDomain,
    state.campaignInfoBlock.setAdvertiserDomain,
    state.errorFields.creativesBlock,
    state.errorFields.campaignInfoBlock,
    state.errorFields.resetError,
    state.errorFields.setBlockFieldsError,
    state.pgCampaignPage.campaignDetails,
  ]);

  const [showChangeCreativeDialog, setShowChangeCreativeDialog] = React.useState(false);
  const [isExistingCreative, setIsExistingCreative] = React.useState<boolean>(false);

  const { data: creativeTypesResponse } = useCreativeTypes();

  const getCreativeTypeIcon = (creativeTypeId: number) => {
    if (creativeTypeId === CREATIVE_ID_FROM_NAME.Image) {
      return <CreativeImage fontSize={26} />;
    }
    if (creativeTypeId === CREATIVE_ID_FROM_NAME.Video) {
      return <CreativeVideo fontSize={26} />;
    }
    if (creativeTypeId === CREATIVE_ID_FROM_NAME.Audio) {
      return <CreativeAudio fontSize={26} />;
    }
    if (creativeTypeId === CREATIVE_ID_FROM_NAME.HTML) {
      return <CreativeHTML fontSize={26} />;
    }
    if (creativeTypeId === CREATIVE_ID_FROM_NAME.Native) {
      return <CreativeNative fontSize={26} />;
    }
    return null;
  };

  const creativeTypesMapper = React.useMemo(() => {
    // @ts-ignore
    return creativeTypesResponse?.map((val) => ({ ...val, icon: getCreativeTypeIcon(val?.value) }));
  }, [creativeTypesResponse]);

  const handleCreativeClickWrapper = ({ id }: { id: number }) => {
    setCreativeTypeId(id);
  };

  const errorStateAdvertiserDomain: ErrorStateForComponentType = React.useMemo(
    () =>
      campaignInfoBlockError
        ? Object.fromEntries(
            campaignInfoBlockError?.map((val: BlockWiseErrorType) => [val.field, val]),
          )
        : {},
    [campaignInfoBlockError],
  ) as ErrorStateForComponentType;

  const blockIcon = React.useMemo(
    () => creativeTypesMapper?.filter((val) => val.value === creativeTypeId)[0]?.icon,
    [creativeTypeId, creativeTypesMapper],
  );

  const errorState: ErrorStateForComponentType = React.useMemo(
    () =>
      creativesBlockError
        ? Object.fromEntries(
            creativesBlockError?.map((val: BlockWiseErrorType) => [val.field, val]),
          )
        : {},
    [creativesBlockError],
  ) as ErrorStateForComponentType;

  const accordionCTAs = () => {
    return isEditMode && campaignDetails?.creativeType ? (
      <Box
        sx={{ display: 'flex', alignItems: 'center', gapCol: 8, ml: 'auto', position: 'relative' }}
      >
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            if (!expandedBlocks.includes('Creatives')) {
              setExpandedBlocks([...expandedBlocks, 'Creatives']);
            }
            setCreativeSelectDialogType('select');
          }}
          startIcon={<Add />}
        >
          Select Creatives
        </Button>

        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            if (!expandedBlocks.includes('Creatives')) {
              setExpandedBlocks([...expandedBlocks, 'Creatives']);
            }
            setCreativeSelectDialogType('create');
          }}
          variant="outlined"
          startIcon={<Add />}
          sx={{ bgColor: 'neutral-0' }}
        >
          New Creatives
        </Button>
        {errorState?.selectedCreatives?.field ? (
          <Typography
            variant="label"
            component="span"
            style={{ top: '100%' }}
            sx={{ textColor: 'danger-400', textAlign: 'left', position: 'absolute' }}
          >
            {errorState?.selectedCreatives?.errorText}
          </Typography>
        ) : null}
      </Box>
    ) : undefined;
  };

  const blockTitle = creativeTypeId ? (
    <>
      <Box>
        <Typography>
          {CREATIVE_NAME_FROM_ID[creativeTypeId as keyof typeof CREATIVE_NAME_FROM_ID]} Creatives
        </Typography>
        {!isEditMode ? (
          <Link
            sx={{ ml: 8 }}
            underline="hover"
            onClick={(e) => {
              e.stopPropagation();
              setShowChangeCreativeDialog(true);
            }}
          >
            Change
          </Link>
        ) : null}
      </Box>
      {showChangeCreativeDialog ? (
        <ChangeCreativeDialog
          onClose={() => setShowChangeCreativeDialog(false)}
          creativeTypeId={creativeTypeId}
          onChangeCreativeType={() => {
            setSelectedCreatives([]);
            setCreativeTypeId(null);
          }}
        />
      ) : null}
    </>
  ) : (
    'Creatives'
  );

  const isSameClickUrl = React.useMemo(() => {
    return new Set(selectedCreatives.map((obj: Creative) => obj.clickUrl)).size === 1;
  }, [selectedCreatives]);

  const onBlockAccordionChange = () => {
    if (expandedBlocks.includes('Creatives')) {
      setExpandedBlocks(
        expandedBlocks.filter(
          (val: 'Creatives' | 'Location' | 'Inventory' | 'Conversion') => val !== 'Creatives',
        ),
      );
    } else {
      setExpandedBlocks(['Creatives']);
    }
  };

  const creativesMapperForBlock = creativeTypesMapper?.map((val: { value: string | number }) => ({
    ...val,
    // @ts-ignore
    order: creativeOrder[val.value],
  }));

  React.useEffect(() => {
    if (!isEditMode) {
      if (isSameClickUrl) {
        setAdvertiserDomain(selectedCreatives[0].clickUrl);
      } else {
        setAdvertiserDomain(null);
      }
    }
  }, [isEditMode, isSameClickUrl, selectedCreatives, setAdvertiserDomain]);

  React.useEffect(() => {
    if (
      advertiserDomain &&
      errorStateAdvertiserDomain?.advertiserDomainUrl?.field === 'advertiserDomainUrl' &&
      errorStateAdvertiserDomain?.advertiserDomainUrl?.errorText?.includes('empty')
    ) {
      resetError({
        blockName: 'campaignInfoBlock',
        field: 'advertiserDomainUrl',
      });
    }
  }, [
    advertiserDomain,
    errorStateAdvertiserDomain?.advertiserDomainUrl?.errorText,
    errorStateAdvertiserDomain?.advertiserDomainUrl?.field,
    resetError,
  ]);

  React.useEffect(() => {
    if (
      advertiserDomain &&
      (!urlRegEx.test(advertiserDomain.trim()) || !isValidUrl(advertiserDomain.trim())) &&
      errorStateAdvertiserDomain?.advertiserDomainUrl?.field !== 'advertiserDomainUrl'
    ) {
      setBlockFieldsError({
        blockName: 'campaignInfoBlock',
        field: 'advertiserDomainUrl',
        errorText: 'Please enter a valid URL',
      });
    } else if (
      advertiserDomain &&
      urlRegEx.test(advertiserDomain.trim()) &&
      isValidUrl(advertiserDomain.trim()) &&
      errorStateAdvertiserDomain?.advertiserDomainUrl?.field === 'advertiserDomainUrl'
    ) {
      resetError({
        blockName: 'campaignInfoBlock',
        field: 'advertiserDomainUrl',
      });
    }
  }, [
    advertiserDomain,
    errorStateAdvertiserDomain?.advertiserDomainUrl,
    resetError,
    setBlockFieldsError,
  ]);

  React.useEffect(() => {
    if (selectedCreatives.length && errorState?.selectedCreatives) {
      resetError({
        blockName: 'creativesBlock',
        field: 'selectedCreatives',
      });
    }
  }, [errorState?.selectedCreatives, resetError, selectedCreatives.length]);

  return (
    <BlockWrapper
      id={PG_CAMPAIGN_COMPONENTS.creativeBlock}
      title={blockTitle}
      icon={
        blockIcon ? (
          <Box sx={{ textColor: 'primary-600' }}>{blockIcon}</Box>
        ) : (
          <LayoutCard fontSize={24} color="primary" />
        )
      }
      expanded={!!expandedBlocks.includes('Creatives')}
      onChange={onBlockAccordionChange}
      blockSummary={
        selectedCreatives.length ? (
          <Typography variant="bodySmall" gutterBottom={false}>
            Creatives: <Typography weight="demi">{selectedCreatives.length} Selected</Typography>
          </Typography>
        ) : undefined
      }
      blockCta={accordionCTAs()}
    >
      <Box>
        {!isEditMode || !campaignDetails?.creativeType ? (
          <Box>
            {!selectedCreatives.length ? (
              <>
                <Row>
                  <Col xs={12}>
                    <Typography variant="bodyLarge" weight="demi" align="center">
                      What type of creatives you want to add?
                    </Typography>
                  </Col>
                </Row>
                <Row sx={{ marginTop: 24 }}>
                  <Col xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gapCol: 24,
                      }}
                    >
                      {creativesMapperForBlock
                        ?.sort((a: { order: number }, b: { order: number }) => a.order - b.order)
                        ?.map((val: any) => (
                          <Button
                            iconOrientation="vertical"
                            selected={val.value === creativeTypeId}
                            variant="text"
                            classes={{ selected: sx({ border: 1, borderColor: 'primary-600' }) }}
                            // @ts-ignore
                            startIcon={val.icon}
                            onClick={() => handleCreativeClickWrapper({ id: val.value })}
                          >
                            <Typography
                              variant="bodySmall"
                              sx={{ textColor: 'neutral-1000' }}
                              gutterBottom={false}
                            >
                              {val.label}
                            </Typography>
                          </Button>
                        ))}
                    </Box>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row sx={{ marginTop: 24 }}>
              <Col xs={12}>
                <CreateSelectCreativeBlock
                  selectedCreativeTypeId={creativeTypeId}
                  setCreativeSelectDialogType={setCreativeSelectDialogType}
                  setCreativeTypeId={setCreativeTypeId}
                  setIsExistingCreative={setIsExistingCreative}
                />
              </Col>
            </Row>
          </Box>
        ) : null}
        {selectedCreatives.length ? (
          <Row sx={{ mt: 16 }}>
            <Col xs={12}>
              <Box sx={{ bgColor: 'neutral-100', py: 4, px: 12 }}>
                <Typography variant="bodySmall" gutterBottom={false} weight="demi">
                  {selectedCreatives.length} creative(s) added to the campaign
                </Typography>
              </Box>
            </Col>
            <Col xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start', my: 16 }}>
                <Box style={{ width: '25%' }}>
                  <TextField
                    fullWidth
                    placeholder="Advertiser Domain URL"
                    value={advertiserDomain}
                    onChange={(e: any) => setAdvertiserDomain(e.target.value)}
                    error={Boolean(errorStateAdvertiserDomain?.advertiserDomainUrl)}
                    helperText={errorStateAdvertiserDomain?.advertiserDomainUrl?.errorText}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'start',
                    textColor: 'neutral-500',
                  }}
                >
                  <InfoCircle fontSize={20} sx={{ mx: 12 }} />
                  <Typography>
                    Advertiser Domain URL appears with the creative when published on the web/app
                    channels. As per the IAB guidelines, it is mandatory to add Advertiser Domain
                    URL with the creative.
                  </Typography>
                </Box>
              </Box>
            </Col>
            <Col xs={12}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <SelectedCreativeTable
                  selectedCreatives={selectedCreatives}
                  setSelectedCreatives={setSelectedCreatives}
                />
              </Box>
            </Col>
          </Row>
        ) : null}
        {creativeSelectDialogType === 'select' ? (
          <SelectCreativeDialog
            selectedCreatives={selectedCreatives}
            setSelectedCreatives={setSelectedCreatives}
            setCreativeSelectDialogType={setCreativeSelectDialogType}
            selectedCreativeType={creativeTypeId}
          />
        ) : null}
        {creativeSelectDialogType === 'create' ? (
          <CreateCreativeBlock
            selectedCreativeType={creativeTypeId}
            setCreativeSelectDialogType={setCreativeSelectDialogType}
            isExistingCreative={isExistingCreative}
          />
        ) : null}
      </Box>
    </BlockWrapper>
  );
};
