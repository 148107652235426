import * as React from 'react';
import { useIoBudgetTypesList } from 'hooks/useIoList';
import { IO_BUDGET_TYPE_ICON } from 'constants/iconMapper';
import { StatusFilterMapper } from '../../constants';
import { FilterOpenerButton } from './FilterOpenerButton';
import { AccordionMenuList } from './AccordionMenuList';

export interface StatusFilterProps {
  setStatusIds: React.Dispatch<React.SetStateAction<number[]>>;
  statusIds: number[];
  setBudgetTypeIds: React.Dispatch<React.SetStateAction<number[]>>;
  budgetTypeIds: number[];
}

export const Filters = (props: StatusFilterProps) => {
  const { setStatusIds, statusIds, setBudgetTypeIds, budgetTypeIds } = props;

  const [isStatusMenuOpen, setIsStatusMenuOpen] = React.useState(true);
  const [isBudgetTypeMenuOpen, setIsBudgetTypeMenuOpen] = React.useState(true);

  const ioBudgetTypeList = useIoBudgetTypesList();

  const ioBudgetTypeOptions = React.useMemo(
    () =>
      ioBudgetTypeList.data?.data?.budgetTypeData?.map((opt) => {
        const IconComponent = IO_BUDGET_TYPE_ICON[opt.id];
        return {
          label: opt.label,
          value: opt.id,
          icon: IconComponent ? <IconComponent color="primary" sx={{ fontSize: 24 }} /> : null,
        };
      }) || [],
    [ioBudgetTypeList.data],
  );

  const clearFilters = () => {
    setStatusIds([]);
    setBudgetTypeIds([]);
  };

  return (
    <FilterOpenerButton
      clearFilters={clearFilters}
      badgeContent={statusIds.length + budgetTypeIds.length}
    >
      <AccordionMenuList
        options={ioBudgetTypeOptions}
        label="Insertion Order Type"
        onSelect={setBudgetTypeIds}
        selectedIds={budgetTypeIds}
        setIsOpen={setIsBudgetTypeMenuOpen}
        isOpen={isBudgetTypeMenuOpen}
        multi
      />
      <AccordionMenuList
        options={StatusFilterMapper}
        label="Status"
        onSelect={setStatusIds}
        selectedIds={statusIds}
        setIsOpen={setIsStatusMenuOpen}
        isOpen={isStatusMenuOpen}
        multi
      />
    </FilterOpenerButton>
  );
};
