import React from 'react';
import { Creative } from 'models/Creative';
import { pluralize } from '../../../../../../utils/pluralize';

import styles from './SelectionActionPanel.module.scss';
// eslint-disable-next-line import/no-cycle
import { TableComponentInstance } from '../creativeTableView/creativeTable/CreativeTable';

type TProps = {
  selectedCreatives: Creative[];
  selectCreatives: (creatives: Creative[], shouldClear?: boolean) => void;
};

class SelectionActionPanel extends React.Component<TProps> {
  getSelectedCountTitle = () => {
    const { selectedCreatives } = this.props;

    return `${selectedCreatives.length} ${pluralize(
      'Creative',
      selectedCreatives.length,
    )} selected`;
  };

  handleClear = () => {
    const { selectCreatives } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    selectCreatives([], true);
    if (TableComponentInstance) {
      TableComponentInstance.setState({
        selected: [],
      });
    }
  };

  render() {
    const { selectedCreatives } = this.props;

    if (!selectedCreatives.length) {
      return null;
    }

    return (
      <div className={styles.row}>
        <div className={styles.selectedCreativesCount}>
          <button
            type="button"
            className={`btn-close ${styles.clearSelectedCreatives}`}
            onClick={this.handleClear}
          />
          <span className={styles.selectedCountTitle}>{this.getSelectedCountTitle()}</span>
        </div>
      </div>
    );
  }
}

export default SelectionActionPanel;
