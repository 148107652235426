import { Route } from '../../models/Route';

import { LocationBox } from './locationBlock';
import { IframeTest } from './iframeTest';
import { Maps, MapWithTabs } from './mapBlock';
import { LocationBox as PlannerBox } from './plannerLocationBlock';
import { Maps as PlannerMap } from './plannerMapBlock';

export const routes: Route[] = [
  // This is a mock route.
  {
    path: '/iframe',
    component: IframeTest,
    exact: true,
  },
  {
    path: '/location',
    component: LocationBox,
    exact: true,
  },
  {
    path: '/maps',
    component: Maps,
  },
  {
    path: '/maps-with-tabs',
    component: MapWithTabs,
  },
  {
    path: '/planner-location',
    component: PlannerBox,
    exact: true,
  },
  {
    path: '/planner-maps',
    component: PlannerMap,
    exact: true,
  },
];
