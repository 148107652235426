import * as React from 'react';
import { connect } from 'react-redux';
import { conversionActions, SetConversionType } from 'store/conversion/actions';
import { IconButton } from 'components/IconButton';
import { ConversionType } from 'models/Conversion';
import { AppState } from 'models/Store';
import styles from './styles.module.scss';

interface Props extends SetConversionType {
  conversionType: ConversionType | null;
  setInitTableConversionIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const ConversionTypeSelectionComponent = (props: Props) => {
  const { setConversionType, conversionType, setInitTableConversionIds } = props;
  const handleClick = (selected: ConversionType) => () => {
    if (!!selected && selected === conversionType) {
      setConversionType(ConversionType.NONE);
    } else {
      setConversionType(selected);
    }
    setInitTableConversionIds([]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>What type of conversion do you want to add?</div>
        <div className={styles.subtitle}>
          For mobile app based conversions use post-back conversion only
        </div>
      </div>
      <div className={`${styles.buttons} ${!conversionType ? styles.buttonsMarginBottom : ''}`}>
        <IconButton
          onClick={handleClick(ConversionType.PIXEL)}
          label="Pixel"
          iconName="EventsPixel"
          isSelected={conversionType === ConversionType.PIXEL}
        />
        <IconButton
          onClick={handleClick(ConversionType.POSTBACK)}
          label="Postback"
          iconName="EventsPostBack"
          isSelected={conversionType === ConversionType.POSTBACK}
        />
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  conversionType: state.conversion.conversionType,
});

const mapAction = {
  setConversionType: conversionActions.setConversionType,
};

export const ConversionTypeSelection = connect(
  mapState,
  mapAction,
)(ConversionTypeSelectionComponent);
