import { isTechnologyDataValid } from 'store/app/helpers';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { technologyConstants } from './constants';
import { Option } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface TechnologyStore {
  deviceTypes: Option<number>[];
  manufacturers: Option<number>[];
  os: Option<number>[];
  carriers: Option<number>[];
  network: Option<number>[];
  traffic: Option<number>[];
  tvTraffic: Option<number>[];
  sidebarCampaignInfo: {
    [CampaignInfoField.deviceTypes]: Option<number>[];
    [CampaignInfoField.manufacturers]: Option<number>[];
    [CampaignInfoField.os]: Option<number>[];
    [CampaignInfoField.carriers]: Option<number>[];
    [CampaignInfoField.network]: Option<number>[];
    [CampaignInfoField.traffic]: Option<number>[];
  };
  technologyStoreHasErrors: boolean;
  isTvAd: boolean;
}

const defaultTechnologyState: TechnologyStore = {
  deviceTypes: [],
  manufacturers: [],
  os: [],
  carriers: [],
  network: [],
  traffic: [],
  tvTraffic: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.deviceTypes]: [],
    [CampaignInfoField.manufacturers]: [],
    [CampaignInfoField.os]: [],
    [CampaignInfoField.carriers]: [],
    [CampaignInfoField.network]: [],
    [CampaignInfoField.traffic]: [],
  },
  technologyStoreHasErrors: false,
  isTvAd: false,
};

const setStoreField = (
  state: TechnologyStore,
  action: Action<{ key: string; value: Option<number>[] }>,
): TechnologyStore => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setSidebarCampaignInfo = (
  state: TechnologyStore,
  action: Action<{ key: string; value: Option<number>[] }>,
): TechnologyStore => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetTechnologyStore = (): TechnologyStore => {
  return defaultTechnologyState;
};

const setIsTvAd = (state: TechnologyStore, action: Action<boolean>): TechnologyStore => {
  return {
    ...state,
    isTvAd: action.payload,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [CampaignInfoField.deviceTypes]: state.deviceTypes.slice(),
      [CampaignInfoField.traffic]: action.payload ? state.tvTraffic.slice() : state.traffic.slice(),
    },
  };
};

const validateTechnologyStore = (state: TechnologyStore): TechnologyStore => {
  return {
    ...state,
    technologyStoreHasErrors: !isTechnologyDataValid(state),
  };
};

const reducer = reducerFromMap<TechnologyStore>(defaultTechnologyState, {
  [technologyConstants.SET_TECHNOLOGY_FIELD]: setStoreField,
  [technologyConstants.SELECT_TECHNOLOGY_FIELD]: setSidebarCampaignInfo,
  [technologyConstants.RESET_TECHNOLOGY_STORE]: resetTechnologyStore,
  [technologyConstants.SET_IS_TV_AD]: setIsTvAd,
  [technologyConstants.VALIDATE_TECHNOLOGY_STORE]: validateTechnologyStore,
});

export const technology = (state: TechnologyStore = defaultTechnologyState, action: Action<any>) =>
  reducer(state, action);
