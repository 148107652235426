/* eslint-disable react/destructuring-assignment */
/* global google */
import React from 'react';
import { connect } from 'react-redux';
import { Polygon, Marker } from '@react-google-maps/api';

import { PolygonExtended, RadiusUnity } from 'models/PolygonExtended';
import { AppState } from 'models/Store';
import {
  mapActions,
  PushSelectedAreaId,
  RemovePolygonFromPreSaved,
  RemovePolygonFromSaved,
  RemoveSelectedAreaId,
  SetDrawerMode,
  UpdateMapStore,
} from 'store/map/actions';

import { BlackLists, WhiteLists } from 'store/advance/reducer';
import { CloseMarker } from '../CloseMarker';
import { MapInfoBox } from '../MapInfoBox';

interface Props
  extends UpdateMapStore,
    PushSelectedAreaId,
    RemoveSelectedAreaId,
    RemovePolygonFromSaved,
    RemovePolygonFromPreSaved,
    SetDrawerMode {
  isSaved?: boolean;
  drawMode?: google.maps.drawing.OverlayType;
  getPolygon: () => PolygonExtended;
  draggable?: boolean;
  editable?: boolean;
  areaType: string | null;
  selectedAreasIds: string[];
  whiteLists: WhiteLists;
  blackLists: BlackLists;
  isMapReadOnly: boolean;
}

interface State {
  isInfoBoxVisible: boolean;
}

class MapPolygonComponent extends React.PureComponent<Props, State> {
  drawerMode?: google.maps.drawing.OverlayType | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      isInfoBoxVisible: false,
    };
  }

  toggleActivity = (polygon: PolygonExtended) => {
    const { selectedAreasIds, pushSelectedAreaId, removeSelectedAreaId } = this.props;

    if (selectedAreasIds.indexOf(polygon.id) !== -1) {
      removeSelectedAreaId(polygon.id);
    } else {
      pushSelectedAreaId(polygon.id);
    }
  };

  switchHandlerActivity = (handler: (arg?: any) => void) => () => {
    if (this.isAreaTypePolygon()) {
      handler();
    }
  };

  handleClick = (polygon: PolygonExtended) => {
    this.toggleActivity(polygon);
  };

  handleRemovePolygon = (polygon: PolygonExtended) => {
    const { isSaved, removePolygonFromPreSaved, removePolygonFromSaved, removeSelectedAreaId } =
      this.props;
    if (isSaved) {
      removePolygonFromSaved(polygon);
    } else {
      removePolygonFromPreSaved(polygon);
    }
    removeSelectedAreaId(polygon.id);
  };

  isAreaTypePolygon = () => {
    const { areaType } = this.props;
    return areaType === 'Polygon';
  };

  onMouseOver = () => {
    this.setState({
      isInfoBoxVisible: true,
    });
  };

  onMouseOut = () => {
    this.setState({
      isInfoBoxVisible: false,
    });
  };

  onEdit = (polygon: PolygonExtended) => {
    const { updateMapStore, selectedAreasIds, pushSelectedAreaId, removeSelectedAreaId } =
      this.props;

    if (selectedAreasIds.indexOf(polygon.id) !== -1) {
      removeSelectedAreaId(polygon.id);
    } else {
      pushSelectedAreaId(polygon.id);
    }
    polygon.update().then(() => updateMapStore());
  };

  isPolygonIncluded = (polygon: PolygonExtended) => {
    if (polygon.filename || polygon.fileId) {
      const { whiteListedLocationIds } = this.props.whiteLists;
      const { blackListedLocationIds } = this.props.blackLists;
      const { id } = polygon;
      const formatedId = Number(id);

      if (whiteListedLocationIds?.includes(formatedId)) {
        return true;
      }
      if (blackListedLocationIds?.includes(formatedId)) {
        return false;
      }
    }
    return polygon.isInclude;
  };

  render() {
    const { getPolygon, selectedAreasIds, isMapReadOnly } = this.props;
    const { isInfoBoxVisible } = this.state;
    const polygon = getPolygon();
    const paths = polygon.getPaths();
    const isActive = selectedAreasIds.indexOf(polygon.id) !== -1;

    return (
      <>
        <Polygon
          key={polygon.id}
          editable
          options={{
            fillColor: this.isPolygonIncluded(polygon) ? 'green' : 'red',
            strokeColor: isActive ? '#1d58d7' : undefined,
            draggable: false,
            editable: isMapReadOnly ? false : !polygon.fileId,
            clickable: isMapReadOnly ? false : !polygon.fileId,
          }}
          paths={paths}
          onClick={() => this.toggleActivity(polygon)}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onMouseUp={() => this.onEdit(polygon)}
        />
        <Marker position={polygon.getCenter()} />
        {isActive && !isMapReadOnly && (
          <CloseMarker
            position={polygon.getPath().getArray()[0]}
            onClick={() => this.handleRemovePolygon(polygon)}
            anchor={new google.maps.Point(0, 24)}
          />
        )}
        {isInfoBoxVisible && (
          <MapInfoBox
            radius={polygon.getRadius(RadiusUnity.mile)}
            position={polygon.getCenter()}
            pixelOffset={new google.maps.Size(4, 4)}
          />
        )}
      </>
    );
  }
}

const mapState = (state: AppState) => ({
  areaType: state.map.areaType,
  isMapReadOnly: state.map.isMapReadOnly,
  selectedAreasIds: state.map.selectedAreasIds,
  drawMode: state.map.drawerMode,
  whiteLists: state.advanced.whiteLists,
  blackLists: state.advanced.blackLists,
});

const actions = {
  removePolygonFromPreSaved: mapActions.removePolygonFromPreSaved,
  removePolygonFromSaved: mapActions.removePolygonFromSaved,
  pushSelectedAreaId: mapActions.pushSelectedAreaId,
  removeSelectedAreaId: mapActions.removeSelectedAreaId,
  updateMapStore: mapActions.updateMapStore,
  setDrawerMode: mapActions.setDrawerMode,
};

export const MapPolygon = connect(mapState, actions)(MapPolygonComponent);
