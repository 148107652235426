import * as React from 'react';
import { SvgIconProps } from '@applift/factor';
import {
  CreativeImage,
  CreativeHTML,
  CreativeVideo,
  CreativeAudio,
  CreativeGoTV,
  StatusAll,
  StatusDeleted,
  StatusDraft,
  StatusExpired,
  StatusPaused,
  StatusPending,
  StatusRejected,
  StatusRunning,
  IoCurrency,
  IoImpression,
  StatusReady,
  CreativeNative,
} from '@applift/icons';
import { IO_BUDGET_TYPE_ID, IO_STATUS_ID } from './insertionOrder';

export const CREATIVES_TYPES_ICONS = {
  Image: <CreativeImage color="primary" />,
  Native: <CreativeNative color="primary" />,
  HTML: <CreativeHTML color="primary" />,
  Video: <CreativeVideo color="primary" />,
  Audio: <CreativeAudio color="primary" />,
  GOTV: <CreativeGoTV color="primary" />,
};

export const CAMPAIGN_STATUSES_ICONS = {
  running: <StatusRunning color="success" />,
  pending: <StatusPending color="warning" />,
  paused: <StatusPaused color="warning" />,
  draft: <StatusDraft color="info" />,
  deleted: <StatusDeleted color="error" />,
  expired: <StatusExpired color="secondary" />,
  rejected: <StatusRejected color="error" />,
  all: <StatusAll color="info" />,
};

export const IO_BUDGET_TYPE_ICON = {
  [IO_BUDGET_TYPE_ID.DOLLAR_BASED]: IoCurrency,
  [IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED]: IoImpression,
};

export const IO_STATUS_ICON = {
  [IO_STATUS_ID.ACTIVE]: (props: Omit<SvgIconProps, 'children'> = {}) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StatusReady {...props} sx={{ textColor: 'info-400', ...(props.sx || {}) }} />
  ),
  [IO_STATUS_ID.DELETED]: (props: Omit<SvgIconProps, 'children'> = {}) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StatusDeleted {...props} sx={{ textColor: 'danger-400', ...(props.sx || {}) }} />
  ),
  [IO_STATUS_ID.EXPIRED]: (props: Omit<SvgIconProps, 'children'> = {}) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StatusExpired {...props} sx={{ textColor: 'neutral-500', ...(props.sx || {}) }} />
  ),
};
