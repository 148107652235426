import { AudienceItem } from '../models/Audience';

interface AudienceStatusItem {
  audienceId: number;
  audienceStatus: string;
}

export enum AudienceWarning {
  NONE = 'NONE',
  SOME_UNAPPROVED = 'SOME_UNAPPROVED',
  ALL_UNAPPROVED = 'ALL_UNAPPROVED',
}

export const getListedAudienceIds = (
  selectedAudiences: AudienceItem[],
  isWhite: boolean,
): string => {
  return selectedAudiences
    .filter(({ included, excluded }) => (isWhite ? included : excluded))
    .map(({ audienceId }) => audienceId)
    .join(',');
};

export const getListedAudienceEstimatorInfo = (
  selectedAudiences: AudienceItem[],
  isWhite: boolean,
) => {
  return selectedAudiences
    .filter(({ included, excluded }) => (isWhite ? included : excluded))
    .map(({ audienceId, audienceTypeId }) => ({ id: audienceId, type: audienceTypeId }));
};

const mapWarnings = (audiences: AudienceStatusItem[]) => {
  if (!audiences || !audiences.length) {
    return AudienceWarning.NONE;
  }
  if (
    audiences.some((aud) => aud.audienceStatus !== 'approved') &&
    audiences.some((aud) => aud.audienceStatus === 'approved')
  ) {
    return AudienceWarning.SOME_UNAPPROVED;
  }
  return audiences.every((aud) => aud.audienceStatus !== 'approved')
    ? AudienceWarning.ALL_UNAPPROVED
    : AudienceWarning.NONE;
};

export const getAudienceWarning = (audienceDetail: any) => {
  const audienceWarnings = audienceDetail;
  Object.entries(audienceDetail).forEach(([key]) => {
    const warning = mapWarnings([
      ...(audienceWarnings[key].included || []),
      ...(audienceWarnings[key].excluded || []),
    ]);
    if (warning === AudienceWarning.NONE) {
      delete audienceWarnings[key];
    } else {
      audienceWarnings[key] = warning;
    }
  });
  return audienceWarnings;
};
