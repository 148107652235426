import * as React from 'react';
import { Box, Button, DialogActions, Drawer, LoadingButton } from '@applift/factor';
import { InfoCircle } from '@applift/icons';

interface Props {
  anchorRef: React.MutableRefObject<any>;
  isOpen: boolean;
  onCancelClick: () => void;
  isLoading: boolean;
  onConfirmClick: () => void;
  warningText: string;
  cancelBtnText: string;
  confirmBtnText: string;
}

export const DialogWarningOverlay = (props: Props) => {
  const {
    anchorRef,
    isOpen,
    onCancelClick,
    isLoading,
    onConfirmClick,
    warningText,
    cancelBtnText,
    confirmBtnText,
  } = props;

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      onClose={onCancelClick}
      variant="temporary"
      SlideProps={{
        appear: false,
      }}
      sx={{ position: 'absolute', ml: 0 }}
      PaperProps={{ sx: { position: 'absolute' } }}
      ModalProps={{
        container: anchorRef.current,
        slotProps: {
          backdrop: {
            sx: { position: 'absolute' },
          },
        },
      }}
    >
      <DialogActions sx={{ justifyContent: 'between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400', mr: 8 }} />
          <Box>{warningText}</Box>
        </Box>
        <Box sx={{ gap: 16, display: 'flex', flexShrink: 0 }}>
          <Button onClick={onCancelClick} color="secondary" disabled={isLoading}>
            {cancelBtnText}
          </Button>
          <LoadingButton onClick={onConfirmClick} loading={isLoading} color="primary">
            {confirmBtnText}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Drawer>
  );
};
