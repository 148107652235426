import * as React from 'react';
import { Box, Col, Paper, Row } from '@applift/factor';
import { DEFAULT_LIST_SORTING, DEFAULT_PAGE_SIZE } from 'constants/campaignList';
import {
  RowSelectionState,
  SortingState,
  VisibilityState,
  constructListFromColumnDef,
} from '@applift/datagrid';
import { connect } from 'react-redux';
import { OptionId } from 'iqm-framework';

import { useCampaignList, useCampaignListCols } from 'hooks/useCampaignList';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { useTimezone } from 'hooks/useTimezone';
import { CampaignCreativeCountType, CampaignStatusCountType } from 'models/Count';
import { useCampaignStatusCount } from 'hooks/useCampaignStatusCount';
import { useCampaignCreativeCount } from 'hooks/useCampaignCreativeCount';
import { IODetail, IoBudgetImpressionInfoType } from 'models/IO';
import { CampaignList } from 'pages/MainPage/IODetails/components/CampaignList//CampaignList';
import { getCampaignsTableColDef } from 'pages/MainPage/IODetails/components/CampaignList/colDef';
import { AppState } from 'models/Store';
import { CampaignListType } from 'models/CampaignList';
import { CreativesCell } from 'components/CellType';
import { DialogCampaignListFilter } from './DialogCampaignListFilter';

export interface DialogCampaignListWrapperPropTypes {
  ioDetail: IODetail | undefined;
  organizationTimezone?: number | OptionId<any> | null;
  ioBudgetInfo: IoBudgetImpressionInfoType | undefined;
  ioId: string;
  dateRange: { startDate: number; endDate: number };
}

export const DialogCampaignListWrapperComponent = (props: DialogCampaignListWrapperPropTypes) => {
  const { ioDetail, organizationTimezone, ioBudgetInfo, ioId, dateRange } = props;
  const timezone = useTimezone();
  const timezoneOptions = timezone.data;

  const [search, setSearch] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>(DEFAULT_LIST_SORTING);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

  const [statusFilter, setStatusFilter] = React.useState<CampaignStatusCountType['status_key'][]>([
    'all',
  ]);
  const [creativeTypeFilter, setCreativeTypeFilter] = React.useState<
    CampaignCreativeCountType['creativeTypeId'][]
  >([]);
  const [timezoneFilter, setTimezoneFilter] = React.useState<OptionId<string> | undefined>(
    undefined,
  );

  const defaultData = React.useMemo(
    () => constructListFromColumnDef(getCampaignsTableColDef(ioDetail)),
    [ioDetail],
  );
  const [columnVisibility, setColumnVisibility] = React.useState(defaultData[1]);
  const defaultDeselectedColumns = React.useMemo(
    () =>
      Object.keys(defaultData[1]).reduce((prev, one) => {
        // eslint-disable-next-line
        prev[one] = false;
        return prev;
      }, {} as VisibilityState),
    [defaultData],
  );

  const { data: campaignListCols } = useCampaignListCols();

  React.useEffect(() => {
    if (
      campaignListCols &&
      campaignListCols.config &&
      Object.keys(campaignListCols.config).includes('columnVisibility')
    ) {
      const visib = campaignListCols.config.columnVisibility;
      setColumnVisibility(visib);
    }
    if (
      campaignListCols &&
      campaignListCols.config &&
      !Object.keys(campaignListCols.config).includes('columnVisibility')
    ) {
      setColumnVisibility(defaultData[1]);
    }
  }, [campaignListCols, defaultData]);

  React.useEffect(() => {
    if (timezoneOptions?.length && !timezoneFilter && organizationTimezone) {
      setTimezoneFilter(
        timezoneOptions
          ?.filter(
            (timezone) =>
              timezone.id ===
              (typeof organizationTimezone === 'number'
                ? organizationTimezone
                : organizationTimezone.id),
          )
          .map((orgTimezone) => ({
            label: orgTimezone.label,
            id: orgTimezone.id,
            value: orgTimezone.name,
          }))[0],
      );
    }
  }, [timezone, organizationTimezone, timezoneFilter, timezoneOptions]);

  const selectedStatus = React.useMemo(() => {
    if (statusFilter[0] === 'all') {
      return '';
    }
    if (statusFilter.length) {
      return statusFilter.join(',');
    }
    return '';
  }, [statusFilter]);

  const { data: campaignCreativeCount, isLoading: campaignCreativeCountLoading } =
    useCampaignCreativeCount(ioId, selectedStatus);

  const debouncedSearch = useDebounceValue(search, 500);

  const creativeTypeFilterPayload = React.useMemo(
    () => creativeTypeFilter?.filter(Boolean).join(','),
    [creativeTypeFilter],
  );

  const { data: campaignStatusCount, isLoading: campaignStatusCountLoading } =
    useCampaignStatusCount(ioId);

  const statusFilterPayload = React.useMemo(
    () =>
      statusFilter[0] === 'all'
        ? campaignStatusCount?.data
            ?.filter((val) => val.status_key !== 'all')
            .map((val) => val.status_key)
            .join(',')
        : statusFilter.join(','),
    [campaignStatusCount?.data, statusFilter],
  );
  const prevTotalRecordsRef = React.useRef<number>();

  const {
    data: campaignListData,
    isLoading: campaignListLoading,
    fetchNextPage,
  } = useCampaignList(
    debouncedSearch,
    DEFAULT_PAGE_SIZE,
    sorting,
    [+ioId],
    statusFilterPayload,
    timezoneFilter?.id,
    creativeTypeFilterPayload,
    undefined, // sending undefined just as placeholder for selected Campaign
    dateRange.startDate,
    dateRange.endDate,
    ioBudgetInfo,
    ioDetail,
    undefined, // sending undefined just as placeholder for selected Campaign
    {
      enabled: !!statusFilterPayload?.length,
      onSuccess: (data) => {
        prevTotalRecordsRef.current = data.pages[0]?.data?.totalRecords;
      },
    },
  );

  const setStatusFilterWrapper = React.useCallback((val) => {
    setCreativeTypeFilter([]);
    setStatusFilter(val);
  }, []);

  const setCreativeTypeFilterWrapper = React.useCallback((val) => {
    setCreativeTypeFilter(val);
  }, []);

  const filteredRecords = React.useMemo(
    () => campaignListData?.pages[0].data?.filteredRecords,
    [campaignListData?.pages],
  );

  const totalRecords = React.useMemo(
    () => campaignListData?.pages[0].data?.totalRecords,
    [campaignListData?.pages],
  );

  const footerData = campaignListData?.pages[0].data?.recordsTotal;

  const flatData = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map((page) => {
          return page.data?.recordsList ?? [];
        })
        .flat(1) || []
    );
  }, [campaignListData]);

  const modifiedColDef = React.useMemo(() => {
    return getCampaignsTableColDef(ioDetail).map((data) => {
      // @ts-ignore
      if (data.accessorKey === 'creativesCount') {
        return {
          ...data,
          cell: (props: any) => (
            <CreativesCell campaignData={props.row?.original as CampaignListType} cellDisabled />
          ),
        };
      }
      return data;
    });
  }, [ioDetail]);

  const overlay = React.useMemo(() => {
    if (!flatData.length && !campaignListLoading && !filteredRecords && totalRecords) {
      return 'noResult';
    }
    if (!flatData.length && !campaignListLoading) {
      return 'noResult';
    }
    return undefined;
  }, [flatData.length, filteredRecords, campaignListLoading, totalRecords]);

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
      }}
    >
      <Row>
        <Col>
          {ioDetail?.ioBudgetTypeId ? (
            <DialogCampaignListFilter
              search={search}
              setSearch={setSearch}
              setRowSelection={setRowSelection}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilterWrapper}
              creativeTypeFilter={creativeTypeFilter}
              setCreativeTypeFilter={setCreativeTypeFilterWrapper}
              campaignStatusCount={campaignStatusCount?.data}
              campaignCreativeCount={campaignCreativeCount?.data}
              campaignCreativeCountLoading={campaignCreativeCountLoading}
              campaignStatusCountLoading={campaignStatusCountLoading}
            />
          ) : null}
        </Col>
      </Row>
      <Row sx={{ flexGrow: 1 }}>
        <Col>
          <Box sx={{ height: 100 }}>
            <CampaignList
              data={flatData}
              columnDef={modifiedColDef}
              sorting={sorting}
              totalRecords={totalRecords}
              filteredRecords={filteredRecords}
              onSortingChange={setSorting}
              rowIdKey="campaignId"
              pageSize={DEFAULT_PAGE_SIZE}
              loading={campaignListLoading}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              footerData={footerData}
              isFiltered={!!search}
              onFetchRows={() => {
                // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                setTimeout(fetchNextPage, 100);
              }}
              isIoDetailsDirty={false}
              openIoSaveDialog={false}
              setOpenIoSaveDialog={(arg) => {}}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              defaultDeselectedColumns={defaultDeselectedColumns}
              checkboxSelection={false}
              overlay={overlay}
              ioDetail={ioDetail}
            />
          </Box>
        </Col>
      </Row>
    </Paper>
  );
};

const mapState = (state: AppState) => ({
  organizationTimezone: state.auth.organizationTimezoneInfo,
});

export const DialogCampaignListWrapper = connect(
  mapState,
  null,
)(DialogCampaignListWrapperComponent);
