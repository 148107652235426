import { Option } from '../../models/Option';
import { smsConstants } from './constants';
import { createAction } from '../../utils/actions';
import { ExistingCampaignData } from '../../models/ExistingCampaign';

export interface SetCampaignSidebarInfo {
  setCampaignSidebarInfo: (key: string, value: any) => void;
}

export interface FillSMSStore {
  fillAdvanceStore: (campaign: ExistingCampaignData) => void;
}

export interface ChangeDefaultDateState {
  changeDefaultDateState: (value: boolean) => void;
}

export type ResetCampaignSidebarInfo = {
  resetCampaignSidebarInfo: (listNames: string[]) => void;
};

export type SetIsTimezoneLoaded = {
  setIsTimezoneLoaded: (isTimezoneLoaded: boolean) => void;
};

export const smsActions = {
  setCampaignSidebarInfo: (key: string, value: string | Option[]) => {
    return createAction<any>(smsConstants.SIDEBAR_CAMPAIGN_INFO__SET, {
      key,
      value,
    });
  },
  changeDefaultDateState: (value: boolean) => {
    return createAction<boolean>(smsConstants.CHANGE_DEFAULT_DATE_STATE, value);
  },
  fillSMSStore: (campaign: ExistingCampaignData) => {
    return createAction<ExistingCampaignData>(smsConstants.FILL_SMS_STORE, campaign);
  },
  resetCampaignSidebarInfo: (listNames: string[]) => {
    return createAction(smsConstants.RESET_SIDEBAR_CAMPAIGN_INFO, listNames);
  },
  setIsTimezoneLoaded: (isTimezoneLoaded: boolean) => {
    return createAction(smsConstants.SET_IS_TIMEZONE_LOADED, isTimezoneLoaded);
  },
};
