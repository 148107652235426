import React from 'react';
import { Box, Col, ColorClassModifier, Row, Skeleton, Typography } from '@applift/factor';
import { TargableAudienceResponseType } from 'models/Estimator';

interface TargetableAudienceProps {
  data?: TargableAudienceResponseType;
  isLoading: boolean;
}

const AudienceReachDisplayer = ({
  targetingType = 'Broad',
}: {
  targetingType?: TargableAudienceResponseType['targetingType'];
}) => {
  const typeInfo = [
    {
      label: 'Specific',
      value: 'Specific',
      primaryColor: 'danger-400',
      secondaryColor: 'danger-50',
    },
    {
      label: 'Moderate',
      value: 'Moderate',
      primaryColor: 'warning-400',
      secondaryColor: 'warning-50',
    },
    {
      label: 'Broad',
      value: 'Broad',
      primaryColor: 'success-400',
      secondaryColor: 'success-50',
    },
  ];
  return (
    <>
      <Row md={3} sx={{ my: 8, borderRadius: 4, mx: 4, overflow: 'hidden' }}>
        {typeInfo.map((val) => (
          <Col sx={{ px: 0 }}>
            <Box
              sx={{
                bgColor:
                  targetingType === val.value
                    ? (val.primaryColor as ColorClassModifier)
                    : (val.secondaryColor as ColorClassModifier),
                pt: 4,
                pb: 2,
              }}
            />
          </Col>
        ))}
      </Row>
      <Row md={3} sx={{ borderRadius: 4, mx: 4, overflow: 'hidden' }}>
        {typeInfo.map((val) => (
          <Col sx={{ px: 0, textAlign: 'center' }}>
            <Typography
              component="p"
              variant="label"
              sx={
                targetingType === val.value ? { textWeight: 'demi' } : { textColor: 'neutral-400' }
              }
            >
              {val.label}
            </Typography>
          </Col>
        ))}
      </Row>
    </>
  );
};

export const TargetableAudience = (props: TargetableAudienceProps) => {
  const { data, isLoading } = props;

  return (
    <>
      <Typography>Your audience selection is {data?.targetingType}</Typography>
      {isLoading ? (
        <Skeleton sx={{ padding: 12 }} />
      ) : (
        <AudienceReachDisplayer targetingType={data?.targetingType} />
      )}
    </>
  );
};
