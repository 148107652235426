import { AppState } from 'models/Store';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Tooltip } from 'factor';
import { Typography, Switch, enqueueSnackbar } from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import { queryClient } from 'cache/query';
import { useMutation } from '@tanstack/react-query';

import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ErrorCreatingResponse } from 'models/Response';
import { useModelledDimensionsCount } from 'hooks/useCount';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { CreativePageApiRefType, useModelledCreativesList } from '@applift/bid-model';
import { useDimensions } from 'hooks/useDimension';
import { IBidModelRequest, updateModelling } from 'api/Modelling';
import {
  advanceActions,
  SetCampaignSidebarInfo,
  SetEndDateCleared,
  ToggleBudgetPacing,
} from 'store/advance/actions';
import { bidActions, ChangeBidField } from 'store/bid/actions';
import { applicationActions, ResetError } from 'store/app/actions';
import { errorFieldsMapper } from 'constants/errorFieldsMapper';
import { BUDGET_TYPE_ID } from 'constants/apps';
import {
  checkForFewerValidation,
  checkForMoreOrEqualsValidation,
  greaterZeroValidation,
} from 'utils/validationRules';
import { CurrencyFormat, numberFormat } from 'utils/format';
import { DataDogLogger } from 'services/DataDog';
import { AmountField, ValidationRule } from '../AmountField';
import { ApplyBidShadingDialog } from './ApplyBidShadingDialog';

import styles from './index.module.scss';

interface Props
  extends SetCampaignSidebarInfo,
    ResetError,
    ToggleBudgetPacing,
    SetEndDateCleared,
    ChangeBidField {
  spentBudget: number | undefined;
  budgetPacing: boolean;
  totalBudget: string;
  dailyBudget: string;
  maxBid: string;
  baseBid?: string | number;
  errorCreating: ErrorCreatingResponse | null;
  editableCampaign: ExistingCampaignData | null;
  submitted: boolean;
  saveDraftSubmitted: boolean;
  budgetTypeId: number;
  impressionsMaxDaily: string;
  impressionsMaxTotal: string;
  isBidShading: boolean;
  isBidShadingApplicable: boolean;
  timezoneId: number;
}

const BudgetFieldsWrapperComponent = (props: Props) => {
  const {
    spentBudget,
    setCampaignSidebarInfo,
    errorCreating,
    resetError,
    dailyBudget,
    totalBudget,
    maxBid,
    budgetPacing,
    toggleBudgetPacing,
    editableCampaign,
    submitted,
    setEndDateCleared,
    saveDraftSubmitted,
    budgetTypeId,
    changeBidField,
    impressionsMaxTotal,
    impressionsMaxDaily,
    isBidShading,
    isBidShadingApplicable,
    baseBid = 0,
    timezoneId,
  } = props;

  const happenedOnce = useRef<boolean>(false);
  const [fixedDailyCheckboxRed, setFixedDailyCheckboxRed] = useState<boolean>(false);
  const [showApplyBidShadingDialog, setShowApplyBidShadingDialog] = useState<boolean>(false);

  const { data: dimensionData } = useDimensions();

  const { data: modelledCount } = useModelledDimensionsCount(
    {
      campaignId: editableCampaign?.id as number,
    },
    {
      enabled: Boolean(editableCampaign?.id),
    },
  );

  const maxBidValidationRules: ValidationRule[] = [
    {
      ...checkForMoreOrEqualsValidation(+maxBid !== 0, +baseBid),
      name: 'Max Bid Price',
      comparedFieldName: `Base Bid Price ($${(+baseBid ?? 0)?.toFixed(2)})`,
    },
    {
      ...greaterZeroValidation(submitted || !!editableCampaign),
      name: 'Max Bid Price',
    },
  ];

  if (budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
    maxBidValidationRules.push({
      ...checkForFewerValidation(submitted || (saveDraftSubmitted && !!totalBudget), +totalBudget),
      name: 'Max bid',
      comparedFieldName: 'total budget',
    });
  }

  useEffect(() => {
    if (happenedOnce.current || !editableCampaign) {
      return;
    }

    if (editableCampaign) {
      happenedOnce.current = true;
    }

    if (editableCampaign.maxBid) {
      setCampaignSidebarInfo('maxBid', editableCampaign.maxBid);
    }

    if (editableCampaign.budgetDay) {
      setCampaignSidebarInfo('dailyBudget', editableCampaign.budgetDay);
    }

    if (editableCampaign.budgetTotal) {
      setCampaignSidebarInfo('totalBudget', editableCampaign.budgetTotal);
    }

    if (!editableCampaign.totalBudgetPacing) {
      toggleBudgetPacing();
    }
  }, [
    dailyBudget,
    editableCampaign,
    maxBid,
    setCampaignSidebarInfo,
    totalBudget,
    toggleBudgetPacing,
  ]);

  const handleChangeAndStore = (field: string) => (value: string | boolean) => {
    setCampaignSidebarInfo(field, value);
    if (field !== 'maxBid') {
      setEndDateCleared(false);
    }
    if (errorCreating && errorCreating.errorField === errorFieldsMapper[field]) {
      resetError();
    }
  };

  const handleImpressionChange = (key: string) => (value: number | boolean | string) => {
    setEndDateCleared(false);
    changeBidField({
      key,
      value: parseInt(value as string, 10),
    });
  };

  const highlightFixedDailyCheckbox = () => {
    setFixedDailyCheckboxRed(true);
  };

  const handleToggleBudgetPacing = () => {
    toggleBudgetPacing();
    setFixedDailyCheckboxRed(false);
  };

  const greaterThanSpent = (isConfirm: boolean, spent: number) => {
    return {
      func: (value: number) => {
        return !isConfirm || value >= spent;
      },
      error: (name: string = 'Field', rule: { [key: string]: any }) => {
        return rule?.errorMessage || `${rule?.name || name} can't be less than spent`;
      },
    };
  };

  const dimensionId = React.useMemo(() => {
    if (dimensionData) {
      return dimensionData.keyIdPair.creative;
    }
    return null;
  }, [dimensionData]);

  const campaignBudget =
    budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
      ? editableCampaign?.budgetTotal
      : editableCampaign?.totalImpressions;

  const { data: modelledCreativesData } = useModelledCreativesList(
    {
      skipReporting: true,
      dimensionId: dimensionId as number,
      campaignId: editableCampaign?.id as number,
      timeZoneId: timezoneId,
      pageNo: -1,
    },
    {
      enabled: Boolean(dimensionId) && Boolean(editableCampaign?.id),
      meta: { campaignBudget },
    },
  );

  const creativeRef = React.useRef<CreativePageApiRefType>(null);

  const updateMutation = useMutation(updateModelling, {
    mutationKey: ['updateCreativesModelling'],
    onSuccess: (res) => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          [
            'getModelledDimensionsCount',
            'getModelledCreativesList',
            'getAllCreativeDimensionList',
          ].includes(query.queryKey?.[0]?.scope),
      });
      enqueueSnackbar(res.data || 'Updated successfully', {
        variant: 'success',
      });
    },
    onError: (res) => {
      enqueueSnackbar((res as any)?.errorObjects[0]?.error || 'Something went wrong.', {
        variant: 'error',
      });
    },
  });

  const removeModelling = () => {
    if (modelledCreativesData?.data.length) {
      const deletedActions: IBidModelRequest[] = [];
      modelledCreativesData.data.forEach((item) =>
        deletedActions.push({ action: 'DELETE', bidModelDataIds: [item.bidModelDataId as number] }),
      );
      const payload = {
        bidModelRequests: deletedActions,
        baseBid: creativeRef.current?.getModelledCreativesPayload().baseBid,
      };
      updateMutation.mutate({
        campaignId: editableCampaign?.id as number,
        params: payload,
      });
    }
  };

  const renderApplyBidShadingDialog = () => {
    return (
      <ApplyBidShadingDialog
        setShowApplyBidShadingDialog={() => setShowApplyBidShadingDialog(false)}
        applyBidShading={() => {
          removeModelling();
          setCampaignSidebarInfo('baseBid', '0');
          setCampaignSidebarInfo(CampaignInfoField.isBidShading, true);
        }}
      />
    );
  };

  const getModellingWarningText = () => {
    const showWarning =
      modelledCount?.totalCount &&
      (budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
        ? Number(dailyBudget) < 100
        : Number(impressionsMaxDaily) < 1000);
    if (!showWarning) {
      return '';
    }
    return budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
      ? `For optimal effectiveness of the campaign modelling, it is recommended to allocate a daily budget of $100, with a campaign duration of at least 3 days.`
      : `For optimal effectiveness of the campaign modelling, it is recommended to allocate a daily budget of 1000 impressions, with a campaign duration of at least 3 days.`;
  };

  return (
    <div
      className="row mb-4"
      data-not-valid={
        totalBudget === '' ||
        maxBid === '' ||
        parseInt(totalBudget, 10) === 0 ||
        parseInt(maxBid, 10) === 0 ||
        (!budgetPacing && !dailyBudget) ||
        (!budgetPacing && !+dailyBudget) ||
        +totalBudget < +maxBid
      }
    >
      <div className="col-4">
        {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
          <AmountField
            fieldLabel="Total Budget"
            isBlue
            value={totalBudget}
            max={10000000000}
            placeholder="00,000.00"
            handleChange={handleChangeAndStore('totalBudget')}
            tooltipText="Total Budget sets the total spending limit for the
            campaign including media cost, data cost and 
            pre-bid cost"
            validationRules={[
              {
                ...greaterZeroValidation(submitted),
                name: 'Total budget',
              },
            ]}
            validationKeys={[submitted, maxBid, dailyBudget, totalBudget]}
          />
        ) : (
          <AmountField
            fieldLabel="Total Budget"
            isBlue
            budgetTypeId={budgetTypeId}
            value={impressionsMaxTotal}
            placeholder="00,000"
            handleChange={(value) => {
              handleImpressionChange('impressionsMaxTotal')(value);
            }}
            tooltipText="Total Budget sets the total spending limit for the
            campaign including media cost, data cost and 
            pre-bid cost"
            validationRules={[
              {
                ...greaterZeroValidation(submitted),
                name: 'Target impressions',
              },
              ...(spentBudget
                ? [
                    {
                      ...greaterThanSpent(true, spentBudget),
                      name: 'Target impressions',
                    },
                  ]
                : []),
            ]}
            validationKeys={[submitted, maxBid, dailyBudget, totalBudget, impressionsMaxTotal]}
          />
        )}
        {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
          <div className={styles.helpText}>
            <div className={styles.innerHelpText}>You will not spend more than</div>
            <div className={styles.innerHelpTextForImpression}>
              <Typography sx={{ mx: 2 }}>Imps</Typography>
              {typeof impressionsMaxTotal === 'number' || impressionsMaxTotal.length
                ? numberFormat(impressionsMaxTotal)
                : '0'}
            </div>
            <div className={styles.innerHelpText}>in total</div>
          </div>
        ) : null}
        {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
          <div className={styles.helpText}>{`You will not spend more than ${CurrencyFormat.format(
            +totalBudget,
          )} in total`}</div>
        ) : null}
      </div>
      <div className="col-4">
        {budgetTypeId === 1 ? (
          <AmountField
            fieldLabel="Daily Budget"
            disabled={budgetPacing}
            warningTooltipText={getModellingWarningText()}
            value={dailyBudget}
            placeholder="0,000.00"
            max={10000000000}
            handleChange={handleChangeAndStore('dailyBudget')}
            handleClickWhenDisabled={highlightFixedDailyCheckbox}
            tooltipText="Daily Budget sets the daily spending limit for the
            campaign including media cost, data cost and 
            pre-bid cost"
            validationKeys={[submitted, budgetPacing, dailyBudget, totalBudget, maxBid]}
            validationRules={[
              {
                ...greaterZeroValidation(!budgetPacing && submitted),
                name: 'Daily budget',
              },
              {
                ...checkForFewerValidation(!budgetPacing && submitted, +totalBudget),
                name: 'Daily budget',
                comparedFieldName: 'total budget',
              },
            ]}
          />
        ) : (
          <AmountField
            fieldLabel="Daily Budget"
            budgetTypeId={budgetTypeId}
            warningTooltipText={getModellingWarningText()}
            disabled={budgetPacing}
            value={impressionsMaxDaily}
            placeholder="0,000"
            handleChange={handleImpressionChange('impressionsMaxDaily')}
            handleClickWhenDisabled={highlightFixedDailyCheckbox}
            tooltipText="Daily Budget sets the daily spending limit for the
            campaign including media cost, data cost and 
            pre-bid cost"
            validationKeys={[submitted, budgetPacing, dailyBudget, totalBudget, maxBid]}
            validationRules={[
              {
                ...greaterZeroValidation(!budgetPacing && submitted),
                name: 'Daily Impression',
              },
              {
                ...checkForFewerValidation(!budgetPacing && submitted, +impressionsMaxTotal),
                name: 'Daily Impression',
                comparedFieldName: 'target impression',
              },
            ]}
          />
        )}
        <div className={styles.helpText}>
          {budgetTypeId === 1
            ? 'Daily Budget may vary based on the spending.'
            : 'Daily impressions may vary based on the spending'}
        </div>
        {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
          <div className="d-flex mt-2">
            <Checkbox
              checked={!budgetPacing}
              onChange={handleToggleBudgetPacing}
              className={fixedDailyCheckboxRed ? styles.redCheckbox : ''}
            />
            <label
              className={`${styles.fixedDailyBudgetLabel} ${
                fixedDailyCheckboxRed ? styles.fixedDailyBudgetLabelRed : ''
              }`}
              onClick={handleToggleBudgetPacing}
            >
              Keep daily budget fixed
            </label>
          </div>
        ) : (
          <div className="d-flex mt-2">
            <Checkbox
              checked={!budgetPacing}
              onChange={handleToggleBudgetPacing}
              className={fixedDailyCheckboxRed ? styles.redCheckbox : ''}
            />
            <label
              className={`${styles.fixedDailyBudgetLabel} ${
                fixedDailyCheckboxRed ? styles.fixedDailyBudgetLabelRed : ''
              }`}
              onClick={handleToggleBudgetPacing}
            >
              Keep daily impressions fixed
            </label>
          </div>
        )}
      </div>
      <div className="col-4">
        <AmountField
          fieldLabel="Max Bid Price"
          value={maxBid}
          placeholder="000.00"
          max={1000}
          handleChange={(value) => {
            DataDogLogger.App.setMaxBidPrice();
            handleChangeAndStore('maxBid')(value);
          }}
          tooltipText="The maximum bidding price limit acts as the upper limit for any adjusted bid price, if applicable."
          validationRules={maxBidValidationRules}
          validationKeys={[
            submitted,
            dailyBudget,
            totalBudget,
            budgetPacing,
            saveDraftSubmitted,
            Boolean(baseBid),
            isBidShading,
          ]}
        />
        {isBidShadingApplicable && (
          <div className="d-flex align-items-center">
            <Switch
              checked={isBidShading}
              onChange={(e) => {
                if (modelledCount?.totalCount && !isBidShading) {
                  setShowApplyBidShadingDialog(true);
                } else {
                  setCampaignSidebarInfo(CampaignInfoField.isBidShading, e.target.checked);
                  setCampaignSidebarInfo('baseBid', '0');
                }
              }}
            />
            <Typography sx={{ fontSize: 12, mr: 4 }}>Bid Shading</Typography>
            <Tooltip
              label="This option allows the platform to automatically optimize your campaign to achieve the best effective cost per mille (eCPM)."
              placement="top"
              className="d-flex"
            >
              <InfoCircle sx={{ textColor: 'neutral-400' }} fontSize={24} />
            </Tooltip>
          </div>
        )}
      </div>
      {showApplyBidShadingDialog && renderApplyBidShadingDialog()}
    </div>
  );
};

const mapState = (state: AppState) => ({
  errorCreating: state.app.errorCreating,
  editableCampaign: state.app.editableCampaign,
  budgetPacing: state.advanced.budgetPacing,
  submitted: state.app.submitted,
  totalBudget: state.advanced.sidebarCampaignInfo[CampaignInfoField.totalBudget],
  dailyBudget: state.advanced.sidebarCampaignInfo[CampaignInfoField.dailyBudget],
  maxBid: state.advanced.sidebarCampaignInfo[CampaignInfoField.maxBid],
  baseBid: state.advanced.sidebarCampaignInfo[CampaignInfoField.baseBid],
  saveDraftSubmitted: state.app.saveDraftSubmitted,
  budgetTypeId: state.app.budgetTypeId,
  impressionsMaxDaily: state.bid.impressionsMaxDaily,
  impressionsMaxTotal: state.bid.impressionsMaxTotal,
  campaignId: state.app.editableCampaign?.id || null,
  timezoneId: state.advanced.sidebarCampaignInfo[CampaignInfoField.timezone].id,
  isBidShading: state.advanced.sidebarCampaignInfo[CampaignInfoField.isBidShading],
});

const mapActions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
  setEndDateCleared: advanceActions.setEndDateCleared,
  toggleBudgetPacing: advanceActions.toggleBudgetPacing,
  resetError: applicationActions.resetError,
  changeBidField: bidActions.changeBidField,
};

export const BudgetFieldsWrapper = connect(mapState, mapActions)(BudgetFieldsWrapperComponent);
