import { CampaignListType } from 'models/CampaignList';
import { IODetail } from 'models/IO';
import { DISABLE_CAMPAIGN_PRIORITY_END_DATE } from 'constants/advancedModelling';

export const getDisablePriorityEditTooltip = (campaign: CampaignListType, io?: IODetail) => {
  const { status } = campaign;
  let disableEditTooltip = '';

  if (status?.toLowerCase() === 'expired' || status?.toLowerCase() === 'deleted') {
    disableEditTooltip = 'Priority cannot be added or edited for expired/deleted campaigns';
  }

  if (!io?.ioEndTime) {
    disableEditTooltip = DISABLE_CAMPAIGN_PRIORITY_END_DATE;
  }

  return disableEditTooltip;
};
