export type { CmpConversion as Conversion } from '@applift/conversion';

export enum ConversionType {
  POSTBACK = 2,
  PIXEL = 1,
  NONE = 0,
}

export const ConversionTypeByName: { [key: string]: ConversionType } = {
  pixel: ConversionType.PIXEL,
  postback: ConversionType.POSTBACK,
};

export interface PixelPiggyBack {
  id: number;
  name: string;
  displayName: string;
  order: number;
  active: boolean;
}

interface PiggybackData {
  url: string;
  type: number | null;
}

interface ConversionDuration {
  click: number;
  view: number;
}

interface RepeatConversion {
  count: number;
  frequency: number;
  unit: string;
}

interface ConversionSetting {
  conversionDuration: ConversionDuration;
  repeatConversion: RepeatConversion;
  crossModelling: boolean;
}

export interface CreatePixelParams {
  name: string;
  attributionId: string;
  customFields?: string[];
  financialMetrics?: string;
  piggybackData?: PiggybackData;
  conversionSetting: ConversionSetting;
}

export interface PixelConversionInfo {
  id: number;
  name: string;
  uuid: string;
  typeId: number;
  owId: number;
  createdByUowId: number;
  modifiedByUowId: number;
  attributionId: number;
  statusId: number;
  customFields: string[];
  financialMetrics: string;
  piggybackData: {
    url: string;
    type: number;
  };
  conversionScript: string;
  conversionSetting: {
    conversionDuration: {
      view: number;
      click: number;
    };
    repeatConversion: {
      count: number;
      frequency: number;
      unit: 'Day' | 'Hour' | 'Minute';
    };
    crossModelling: boolean;
  };
  attributedClickThroughConversions: number;
  attributedConversions: number;
  attributedViewThroughConversions: number;
  campaignCount: number;
  created: number;
  pixelConversionScript: string;
  status: string;
  totalConversions: number;
  type: 'Pixel';
}

export interface PixelIntegrationData {
  recipients: string;
  emailSubject: string;
  emailText: string;
  conversionId?: string;
}

export interface CreatePostbackParams {
  name: string;
  partnerTypeId: string;
  impressionUrl: string;
  clickUrl: string;
}

export interface CreatedPostbackInfo {
  id: number;
  name: string;
  owId: number;
  uowId: number;
  typeId: number;
  statusId: number;
  uuid: string;
  partnerId: number;
  impressionUrl: string;
  clickUrl: string;
}
