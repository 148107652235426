import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@applift/factor';
import { FilterDollar } from '@applift/icons';
import { CollapsibleBlock } from 'factor';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { blockStyles } from 'components/Block';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import {
  conversionActions,
  SelectConversionField,
  SetConversionType,
} from 'store/conversion/actions';
import { ConversionType } from 'models/Conversion';
import { getConversionFilter } from 'utils/conversion';
import { ConversionBlockContents } from './ConversionBlockContents';

interface Props extends SelectConversionField, SetConversionType {
  isCollapseOpen?: boolean;
  onToggle?: (isOpened: boolean) => void;
  collapseMode?: CollapseMode;
  selectedConversions: number[];
  editableCampaign: ExistingCampaignData | null;
  isEditingMode: boolean;
  conversionType: ConversionType | null;
}

const ConversionBlockComponent = (props: Props) => {
  const {
    collapseMode,
    isCollapseOpen,
    onToggle,
    selectConversionField,
    selectedConversions,
    editableCampaign,
    isEditingMode,
    conversionType,
    setConversionType,
  } = props;

  const [initTableConversionIds, setInitTableConversionIds] = useState<number[]>([]);
  const { search } = useLocation();
  const { conversionIds, conversionType: conversionTypeURLPARAM } = queryString.parse(search);

  // Case: User attempts to save campaign after opening from Conversions, and save API fails
  useEffect(() => {
    if (selectedConversions?.length) {
      setInitTableConversionIds(selectedConversions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversionIds && conversionTypeURLPARAM && !selectedConversions?.length) {
      const conversionIdArr = (conversionIds as string)
        .split(',')
        .map((id: string) => parseInt(id, 10));

      const ct = (conversionTypeURLPARAM as string)?.toLowerCase();
      if (ct === 'pixel') {
        setConversionType(ConversionType.PIXEL);
      } else if (ct === 'postback') {
        setConversionType(ConversionType.POSTBACK);
      } else {
        return;
      }

      setInitTableConversionIds(conversionIdArr);
      selectConversionField({
        key: CampaignInfoField.conversions,
        value: conversionIdArr,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionIds, conversionTypeURLPARAM, selectConversionField, setConversionType]);

  const handleChangeInStore = (field: string) => (value: number[]) => {
    selectConversionField({
      key: field,
      value,
    });
  };

  useEffect(() => {
    if (editableCampaign && !selectedConversions?.length) {
      if (editableCampaign?.conversionIds) {
        const ids = editableCampaign.conversionIds.split(',').map((id) => Number.parseInt(id, 10));
        handleChangeInStore(CampaignInfoField.conversions)(ids);
        setInitTableConversionIds(ids);
      }
      if (editableCampaign?.conversionTypeId) {
        setConversionType(editableCampaign.conversionTypeId);
      } else {
        setConversionType(ConversionType.NONE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableCampaign]);

  const getHeaderObj = () => {
    const ct = getConversionFilter(conversionType);
    return {
      title: (
        <Box sx={{ display: 'flex', gapCol: 4, alignItems: 'center' }}>
          <FilterDollar fontSize={24} color="primary" />
          <Typography>Conversion</Typography>
        </Box>
      ),
      summary: {
        ...(selectedConversions?.length
          ? { Conversions: `${selectedConversions.length} Selected` }
          : {}),
        ...(conversionType !== ConversionType.NONE && !!ct
          ? { 'Conversion Type': `${ct?.charAt(0).toUpperCase()}${ct?.slice(1)}` }
          : {}),
      },
    };
  };

  if (isEditingMode && !editableCampaign?.id) {
    return null;
  }

  return (
    <CollapsibleBlock
      collapsible={collapseMode !== ALL_EXPANDED}
      isCollapseOpen={isCollapseOpen}
      onToggle={onToggle}
      header={getHeaderObj()}
      customStyle={{
        block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
        panelHeaderExpand: blockStyles.panelHeaderExpand,
        panelHeaderCollapse: blockStyles.panelHeaderCollapse,
      }}
    >
      <ConversionBlockContents
        initTableConversionIds={initTableConversionIds}
        setInitTableConversionIds={setInitTableConversionIds}
      />
    </CollapsibleBlock>
  );
};

const mapState = (state: AppState) => ({
  isEditingMode: state.app.isEditingMode,
  editableCampaign: state.app.editableCampaign,
  selectedConversions: state.conversion.sidebarCampaignInfo[CampaignInfoField.conversions],
  conversionType: state.conversion.conversionType,
});

const mapAction = {
  selectConversionField: conversionActions.selectConversionField,
  setConversionType: conversionActions.setConversionType,
};

export const ConversionBlock = connect(mapState, mapAction)(ConversionBlockComponent);
