import React, { useEffect, useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Postmate from 'postmate';
import { datadogRum } from 'iqm-framework';
import {
  applicationActions,
  ChangeEditingMode,
  SetEditableCampaign,
  FillAppStore,
  SetEditableCampaignLoadingStatus,
  SetMapLoadingStatus,
} from 'store/app/actions';
import { advanceActions, SetLimits } from 'store/advance/actions';
import { transformDataAsPerCampaign } from 'utils/transformLocationData';
import { CreateLocationParams } from 'models/ExistingCampaign';
import { AppState } from 'models/Store';
import { LocationBlock } from './locationBlock';

type Props = FillAppStore &
  SetLimits &
  ChangeEditingMode &
  SetEditableCampaign &
  SetEditableCampaignLoadingStatus &
  SetMapLoadingStatus;

export const LocationBoxComponent = ({
  changeEditingMode,
  setEditableCampaignLoadingStatus,
  setMapLoadingStatus,
  setEditableCampaign,
  fillAppStore,
  setLimits,
}: Props) => {
  const ref = useRef<any>();
  const parent = useRef<any>();
  const [iframeParent, setIframeParent] = useState('');
  const initStore = useCallback(
    (initDataStr: any) => {
      const initData = JSON.parse(initDataStr);
      const dataAsCampaign = transformDataAsPerCampaign(initData as CreateLocationParams);
      changeEditingMode(true);
      setEditableCampaignLoadingStatus(true);
      setMapLoadingStatus(true);
      Promise.resolve(true)
        .then(() => {
          setEditableCampaign(dataAsCampaign);
          fillAppStore(dataAsCampaign);
        })
        .catch(() => {
          changeEditingMode(false);
        })
        .finally(() => {
          setEditableCampaignLoadingStatus(false);
        });
    },
    [
      changeEditingMode,
      setMapLoadingStatus,
      fillAppStore,
      setEditableCampaignLoadingStatus,
      setEditableCampaign,
    ],
  );

  useEffect(() => {
    setLimits({
      states: 5,
      county: 10,
      city: 10,
      senateHouses: 10,
      senateState: 10,
      congressionalDistrict: 10,
      zipCodes: 50,
      customAreas: 100,
    });
    fillAppStore({ isUsingV2API: true } as any);
  }, [fillAppStore, setLimits]);

  useEffect(() => {
    parent.current = new Postmate.Model({
      getData: () => ref?.current?.getLocationDataForSave(),
      initStore,
      setIframeParent,
    });
  }, [initStore]);

  useEffect(() => {
    datadogRum.addRumGlobalContext('iframeParent', iframeParent);
  }, [iframeParent]);

  return <LocationBlock ref={ref} />;
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
});

const mapAction = {
  changeEditingMode: applicationActions.changeEditingMode,
  setEditableCampaign: applicationActions.setEditableCampaign,
  fillAppStore: applicationActions.fillAppStore,
  setEditableCampaignLoadingStatus: applicationActions.setEditableCampaignLoadingStatus,
  setMapLoadingStatus: applicationActions.setMapLoadingStatus,
  setLimits: advanceActions.setLimits,
};

export const LocationBox = connect(mapState, mapAction)(LocationBoxComponent);
