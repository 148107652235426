import * as React from 'react';
import { Box, Typography, enqueueSnackbar, sx } from '@applift/factor';
import {
  DataGrid,
  RowSelectionState,
  SortingState,
  VisibilityState,
  useGridApiRef,
} from '@applift/datagrid';
import { SearchData } from '@applift/illustrations';
import { ClipboardRemove } from '@applift/icons';
import { AxiosError, AxiosResponse } from 'axios';

import { getDataGridHeight } from 'utils/dataGrid';
import { getDisablePriorityEditTooltip } from 'utils/advancedModelling';
import { usePutCampaignPriority } from 'hooks/useCampaign';
import { CampaignListType } from 'models/CampaignList';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { IODetail } from 'models/IO';
import { WithResponse, WithTimestamps } from 'models/Response';
import { DataDogLogger } from 'services/DataDog';
import { PutCampaignPriorityParams } from 'api/Campaign';
import { NoResultsOverlay } from '../../../IoListPage/ListGrid/NoResultOverlay';
import { NoRowsOverlay } from './Overlay/NoRowsOverlay';

const ROW_HEIGHT = 46;

export interface CampaignListPropType {
  data: any[];
  columnDef: any[];
  totalRecords?: number;
  filteredRecords?: number;
  rowIdKey: string;
  sorting: SortingState;
  pageSize: number;
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>;
  footerData?: any;
  loading: boolean;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  isFiltered: boolean;
  isIoDetailsDirty?: boolean;
  onFetchRows?: () => void;
  openIoSaveDialog?: boolean;
  setOpenIoSaveDialog?: (arg: boolean) => void;
  columnVisibility: VisibilityState;
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>;
  defaultDeselectedColumns: VisibilityState;
  checkboxSelection?: boolean;
  overlay?: 'error' | 'noRows' | 'noResult' | undefined;
  ioDetail?: IODetail;
  isBidShadingApplicable?: boolean;
}

export const CampaignList = (props: CampaignListPropType) => {
  const {
    rowIdKey,
    columnDef,
    data,
    loading,
    onSortingChange,
    pageSize,
    rowSelection,
    setRowSelection,
    sorting,
    footerData,
    onFetchRows,
    filteredRecords,
    isIoDetailsDirty,
    setOpenIoSaveDialog,
    columnVisibility,
    setColumnVisibility,
    defaultDeselectedColumns,
    checkboxSelection = true,
    overlay,
    ioDetail,
    isBidShadingApplicable,
  } = props;

  const [columnOrder] = React.useState<string[]>([
    '__check__',
    'campaignPriority',
    'campaignId',
    'campaignName',
    'campaignTypeId',
    'status',
    'creativesCount',
    'budgetDay',
    'budgetTotal',
    'maxBid',
    'pacingPercentage',
    'dailyPacingPercentage',
    'startTime',
    'endTime',
    'pacing',
    'spent',
    'impressions',
    'clicks',
    'reach',
    'frequency',
    'CTR',
    'winRate',
    'VCR',
    'dataCost',
    'prebidCost',
    'mediaSpent',
    'campaignTimezone',
    'eCPM',
    'organizationBidShadingSaving',
    'eCPC',
    'eCPCV',
    'startCount',
    'firstCount',
    'midCount',
    'thirdCount',
    'completeCount',
    'audioVideoViewed',
    'conversions',
    'conversionsRate',
    'totalAttributedConversion',
    'totalAttributedViewThroughConversion',
    'totalAttributedClickThroughConversion',
    'costPerAttributedConversion',
    'totalAttributedConversionRate',
  ]);

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value;
      if (typeof _value === 'function') {
        value = _value();
      }
      setRowSelection({ ...value });
    },
    [setRowSelection],
  );

  const apiRef = useGridApiRef();
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', (params) => {
      setHeight(params.height);
    });
  }, [apiRef]);

  const handlePriorityMutationResult = React.useCallback(
    (resultType: 'error' | 'success', originalRow: CampaignListType) => {
      const snackbarContents = (
        <Box>
          <Typography weight="demi">Priority </Typography>
          {resultType === 'error' ? ' could not be updated for ' : ' updated successfully for  '}
          <Typography weight="demi">{originalRow.campaignName || ' 1 campaign '}</Typography>
        </Box>
      );

      enqueueSnackbar(snackbarContents, { variant: resultType });
    },
    [],
  );

  const priorityMutationParams = React.useMemo(
    () => ({
      editType: 'single' as const,
    }),
    [],
  );

  const putCampaignPriorityMutation = usePutCampaignPriority(priorityMutationParams);

  const filteredColDef = isBidShadingApplicable
    ? columnDef
    : columnDef.map((item) =>
        item.id === 'budget'
          ? {
              ...item,
              columns: item.columns.filter(
                (item: { id: string }) => item.id !== 'organizationBidShadingSaving',
              ),
            }
          : item,
      );

  const handlePutCampaignPriority = async (
    putCampaignPriorityParams: PutCampaignPriorityParams,
    oldRow: any,
  ) => {
    try {
      const response: AxiosResponse<WithTimestamps<WithResponse<string>>> =
        await putCampaignPriorityMutation.mutateAsync(putCampaignPriorityParams);
      DataDogLogger.IODetailsPage.setCampaignPriority({
        response,
        type: 'inline',
      });
      handlePriorityMutationResult('success', oldRow);
    } catch (error) {
      DataDogLogger.IODetailsPage.setCampaignPriority({
        error: error as AxiosError<any>,
        type: 'inline',
      });
      handlePriorityMutationResult('error', oldRow);
    }
  };

  const minTableHeight =
    overlay === 'noRows'
      ? 230
      : getDataGridHeight({
          tableApi: apiRef.current,
          rowCount: data.length,
          maxVisibleRows: 10,
          rowHeight: ROW_HEIGHT,
          headerHeight: 108,
        });

  return (
    <Box sx={{ width: 100 }} style={{ minHeight: minTableHeight, height: minTableHeight }}>
      <DataGrid
        apiRef={apiRef}
        footerPlacement={data?.length ? 'top' : undefined}
        footerData={{ ...footerData, campaignId: '', startTime: '-', endTime: '-' }}
        data={data}
        checkboxSelection={checkboxSelection}
        columns={filteredColDef}
        loading={loading}
        pageSize={pageSize}
        state={{
          sorting,
          rowSelection,
          columnOrder,
          columnVisibility: {
            ...defaultDeselectedColumns,
            ...columnVisibility,
          },
          columnPinning: { left: ['__check__', 'campaignPriority', 'campaignId'] },
        }}
        onColumnVisibilityChange={setColumnVisibility}
        getRowId={(row: any) => {
          if (row && typeof row[rowIdKey] === 'number') {
            return `${row[rowIdKey]}`;
          }
          return row[rowIdKey];
        }}
        hideFooter
        hideHeader
        rowHeight={46}
        showColumnRightBorder
        showCellRightBorder
        overlay={overlay}
        overscrollBehaviorX="contain"
        components={{
          NoRowsOverlay,
          NoResultsOverlay,
        }}
        componentsProps={{
          noResultsOverlay: {
            text: 'No results found',
            subText: 'We can’t find any items matching your search.',
            illustration:
              height <= 250 ? (
                <ClipboardRemove sx={{ textColor: 'neutral-400', mb: 8 }} fontSize={36} />
              ) : (
                <SearchData
                  sx={{
                    textColor: 'primary-500',
                    width: height <= 400 ? 50 : 100,
                    height: 'auto',
                    mb: height <= 400 ? 24 : 40,
                  }}
                />
              ),
          },
          // @ts-ignore
          noRowsOverlay: { isIoDetailsDirty, setOpenIoSaveDialog },
        }}
        disableRowSelectionOnClick
        classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
        onSortingChange={onSortingChange}
        rowCount={filteredRecords}
        onRowSelectionChange={setRowSelectionWrapper}
        onFetchRows={onFetchRows}
        isCellEditable={({ rowId, field }) => {
          const row = apiRef.current.tableInstance.getRow(rowId).original;
          if (field === 'campaignPriority' && getDisablePriorityEditTooltip(row, ioDetail)) {
            return false;
          }
          if (row?.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg) {
            return false;
          }
          return true;
        }}
        processRowUpdate={async (newRow, oldRow) => {
          const allKeys = Object.keys(newRow);
          const modifiedKeys = allKeys.filter((key) => newRow[key] !== oldRow[key]);

          if (modifiedKeys.includes('campaignPriority')) {
            let params: PutCampaignPriorityParams;
            if (!oldRow.campaignPriority && newRow.campaignPriority) {
              params = {
                ioId: oldRow.ioId,
                bidModelRequests: [
                  {
                    action: 'ADD',
                    bidModelData: { priority: newRow.campaignPriority },
                    dimensionEntityMappings: [{ campaignId: oldRow.campaignId }],
                  },
                ],
              };
            } else if (oldRow.campaignPriority && !newRow.campaignPriority) {
              params = {
                ioId: oldRow.ioId,
                bidModelRequests: [
                  {
                    action: 'DELETE',
                    bidModelDataIds: [oldRow.bidModelDataId],
                  },
                ],
              };
            } else {
              params = {
                ioId: oldRow.ioId,
                bidModelRequests: [
                  {
                    action: 'UPDATE',
                    bidModelData: {
                      priority: newRow.campaignPriority,
                      id: oldRow.bidModelDataId,
                    },
                  },
                ],
              };
            }

            handlePutCampaignPriority(params, oldRow);
          }
        }}
        onCellClick={(params) => {
          if (params.cellMode === 'view' && apiRef.current) {
            apiRef.current.startCellEditMode({
              rowId: params.rowId,
              field: params.field,
            });
          }
        }}
      />
    </Box>
  );
};
