import * as React from 'react';
import { Alert, Box, Button, Tooltip, Typography, useDebounceValue } from '@applift/factor';
import { Add } from '@applift/icons';
import { uniqBy } from 'lodash';
import { useStore } from 'zustandStore';
import { BlockWiseErrorType, ErrorStateForComponentType, StoreFilter } from 'models/ZustandStore';
import {
  useExchangesList,
  usePgDealsStatusList,
  usePgDealsPaymentTypeList,
  usePGDealsList,
  usePgDealInfo,
} from 'hooks/useInventory';
import { PgDealResponseType } from 'models/Inventory';
import { SelectPreAddedDealsDialog } from '../SelectDealsDialog';
import { SelectedPgDeals } from '../SelectedPgDeals';
import { CreatePgDealDialog } from '../CreatePgDealDialog';

export const AddCreatePgDeals = () => {
  const [showSelectDealsDialog, setShowSelectDealsDialog] = React.useState<boolean>(false);
  const [showCreateDealsDialog, setShowCreateDealsDialog] = React.useState<boolean>(false);

  const [pgDealsSearch, setPgDealsSearch] = React.useState<string>();
  const [exchangeSearch, setExchangeSearch] = React.useState<string>();
  const [exchangeIds, setExchangeIds] = React.useState<number[]>();
  const [showAlertForNoDealsEditMode, setShowAlertForNoDealsEditMode] =
    React.useState<boolean>(true);
  const [showAlertForDifferentPaymentTo, setShowAlertForDifferentPaymentTo] =
    React.useState<boolean>(false);

  const [
    pgDealIds,
    selectedPgDeals,
    setSelectedPgDeals,
    isEditMode,
    inventoryBlockError,
    resetError,
    campaignDetails,
  ] = useStore((state: StoreFilter) => [
    state.inventoryBlock.pgDealIds,
    state.inventoryBlock.selectedPgDeals,
    state.inventoryBlock.setSelectedPgDeals,
    state.pgCampaignPage.isEditMode,
    state.errorFields.inventoryBlock,
    state.errorFields.resetError,
    state.pgCampaignPage.campaignDetails,
  ]);

  const errorState: ErrorStateForComponentType = React.useMemo(
    () =>
      inventoryBlockError
        ? Object.fromEntries(
            inventoryBlockError?.map((val: BlockWiseErrorType) => [val.field, val]),
          )
        : {},
    [inventoryBlockError],
  ) as ErrorStateForComponentType;

  const debouncePgDealsSearch = useDebounceValue(pgDealsSearch, 500);
  const debounceExchangeDealsSearch = useDebounceValue(exchangeSearch, 500);

  const allExchangesRef = React.useRef();

  const { data: exchangesListResponse, isLoading: isExchangeListLoading } = useExchangesList({
    searchField: debounceExchangeDealsSearch,
    options: {
      onSuccess: (res) => {
        // @ts-ignore
        if (!allExchangesRef.current?.length) {
          allExchangesRef.current = res?.data?.data;
        }
      },
    },
  });

  const { data: dealsStatusListResponse } = usePgDealsStatusList();
  const { data: dealsPaymentTypeListResponse } = usePgDealsPaymentTypeList();

  const activeStatusId = dealsStatusListResponse?.data?.dealStatuses?.filter(
    (val: any) => val.name === 'active',
  )[0];

  const {
    data: pgDealListResponse,
    isLoading: isPgDetailsListLoading,
    fetchNextPage: fetchNextPgDealsListPage,
    refetch: refetchPgDealsList,
  } = usePGDealsList({
    search: debouncePgDealsSearch,
    exchangeIds,
    // @ts-ignore
    statusIds: [activeStatusId?.id],
    options: {
      enabled:
        // @ts-ignore
        Boolean(allExchangesRef.current?.length) &&
        Boolean(dealsStatusListResponse?.data?.dealStatuses.length) &&
        Boolean(dealsPaymentTypeListResponse?.data.pgPaymentTypes.length),
    },
    meta: {
      exchangeList: allExchangesRef.current || [],
      pgDealsStatusList: dealsStatusListResponse?.data?.dealStatuses || [],
      pgDealPaymentTypeList: dealsPaymentTypeListResponse?.data?.pgPaymentTypes || [],
    },
  });

  const dealIdsToFetchInfo = React.useMemo(() => {
    const selectedPgDealsObj = Object.fromEntries(selectedPgDeals.map((val: any) => [val.id, val]));
    const res = pgDealIds.filter((val: any) => !selectedPgDealsObj[val]);
    return res;
  }, [pgDealIds, selectedPgDeals]);

  const exchangesListResponseMultiPageData = React.useMemo(() => {
    // @ts-ignore
    const responsePages = pgDealListResponse?.pages.map((val) => val.data?.pgDealData).flat(1);

    return {
      totalRecords: pgDealListResponse?.pages[0].data?.totalRecords,
      filteredRecords: pgDealListResponse?.pages[0].data?.filteredRecords,
      pgDealData: responsePages,
    };
  }, [pgDealListResponse]);

  usePgDealInfo({
    ids: dealIdsToFetchInfo,
    statusIds: [1],
    meta: {
      exchangeList: exchangesListResponse?.data?.data || [],
      pgDealsStatusList: dealsStatusListResponse?.data?.dealStatuses || [],
      pgDealPaymentTypeList: dealsPaymentTypeListResponse?.data?.pgPaymentTypes || [],
    },
    options: {
      enabled:
        Boolean(pgDealIds?.length) &&
        Boolean(exchangesListResponse?.data?.data?.length) &&
        Boolean(dealsStatusListResponse?.data.dealStatuses.length) &&
        Boolean(dealsPaymentTypeListResponse?.data.pgPaymentTypes.length) &&
        Boolean(dealIdsToFetchInfo.length),
      onSuccess: (val: any) => {
        // @ts-ignore
        const alreadySelectedDeals = val.pages[0].data.pgDealData;
        setSelectedPgDeals(
          uniqBy([...selectedPgDeals, ...alreadySelectedDeals], (deal) => deal.id),
        );
      },
    },
  });

  const isSamePaymentToSelected = React.useMemo(() => {
    return new Set(selectedPgDeals.map((val: PgDealResponseType) => val.paymentTypeId)).size === 1;
  }, [selectedPgDeals]);

  React.useEffect(() => {
    if (!isSamePaymentToSelected) {
      setShowAlertForDifferentPaymentTo(true);
    } else {
      setShowAlertForDifferentPaymentTo(false);
    }
  }, [isSamePaymentToSelected]);

  React.useEffect(() => {
    if (errorState?.pgDeals?.field) {
      setShowAlertForNoDealsEditMode(true);
    }
  }, [errorState]);

  React.useEffect(() => {
    if (selectedPgDeals.length && errorState?.pgDeals?.field) {
      resetError({
        blockName: 'inventoryBlock',
        field: 'pgDeals',
      });
    }
  }, [errorState?.pgDeals, resetError, selectedPgDeals.length]);

  return (
    <Box>
      <Box sx={{ width: 75, bgColor: 'primary-50', mx: 'auto', py: 24, px: 32, borderRadius: 4 }}>
        <Typography variant="bodyMedium" align="center">
          Programmatic Guaranteed (PG) offers premium ad space with certainty and control, ensuring
          guaranteed ad placement and impressions.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gapCol: 16,
            justifyContent: 'center',
            mt: 16,
          }}
        >
          {pgDealIds.length >= 25 ? (
            <Tooltip title="Maximum limit of 25 deals per campaign is reached">
              <Box>
                <Button
                  onClick={() => setShowSelectDealsDialog(true)}
                  disabled={pgDealIds.length >= 25}
                  startIcon={<Add />}
                >
                  Select Deals
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button
              onClick={() => setShowSelectDealsDialog(true)}
              disabled={pgDealIds.length >= 25}
              startIcon={<Add />}
            >
              Select Deals
            </Button>
          )}
          {pgDealIds.length >= 25 ? (
            <Tooltip title="Maximum limit of 25 deals per campaign is reached">
              <Box>
                <Button
                  variant="outlined"
                  sx={{ bgColor: 'neutral-0' }}
                  disabled={pgDealIds.length >= 25}
                  startIcon={<Add />}
                  onClick={() => setShowCreateDealsDialog(true)}
                >
                  Add Deal
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button
              variant="outlined"
              sx={{ bgColor: 'neutral-0' }}
              disabled={pgDealIds.length >= 25}
              startIcon={<Add />}
              onClick={() => setShowCreateDealsDialog(true)}
            >
              Add Deal
            </Button>
          )}
        </Box>
      </Box>
      {showAlertForDifferentPaymentTo && pgDealIds.length > 1 && selectedPgDeals.length > 1 ? (
        <Box sx={{ width: 75, mx: 'auto', mt: 16 }}>
          <Alert
            onClose={() => {
              setShowAlertForDifferentPaymentTo(false);
            }}
            severity="error"
          >
            PG Deals with only the same payment type can be selected in a single campaign.
          </Alert>
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', mt: 16 }}>
        {pgDealIds.length ? (
          <SelectedPgDeals
            exchangesListResponseMultiPageData={exchangesListResponseMultiPageData}
            selectedPgDeals={pgDealIds}
            isPgDetailsListLoading={isPgDetailsListLoading}
          />
        ) : null}
      </Box>
      {((pgDealIds.length === 0 && isEditMode && campaignDetails?.status !== 'draft') ||
        errorState?.pgDeals?.field) &&
      showAlertForNoDealsEditMode ? (
        <Box sx={{ width: 75, mx: 'auto' }}>
          <Alert
            onClose={() => {
              setShowAlertForNoDealsEditMode(false);
              resetError({
                blockName: 'inventoryBlock',
                field: 'pgDeals',
              });
            }}
            severity="error"
          >
            At least one PG deal is required to run a PG campaign.
          </Alert>
        </Box>
      ) : null}{' '}
      {showSelectDealsDialog ? (
        <SelectPreAddedDealsDialog
          exchangesListResponseMultiPageData={exchangesListResponseMultiPageData}
          onClose={() => setShowSelectDealsDialog(false)}
          setPgDealsSearch={setPgDealsSearch}
          setExchangeIds={setExchangeIds}
          isPgDetailsListLoading={isPgDetailsListLoading}
          pgDealsSearch={pgDealsSearch}
          exchangeList={exchangesListResponse?.data.data}
          fetchNextPgDealsListPage={fetchNextPgDealsListPage}
          exchangeIds={exchangeIds || []}
          exchangeSearch={exchangeSearch}
          setExchangeSearch={setExchangeSearch}
          isExchangeListLoading={isExchangeListLoading}
        />
      ) : null}
      {showCreateDealsDialog ? (
        <CreatePgDealDialog
          onClose={() => setShowCreateDealsDialog(false)}
          refetchPgDealsList={refetchPgDealsList}
        />
      ) : null}
    </Box>
  );
};
