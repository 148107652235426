import * as React from 'react';
import {
  TextField,
  Box,
  IconButton,
  MenuItem,
  Typography,
  TypoTooltip,
  CircularProgress,
  Container,
  enqueueSnackbar,
} from '@applift/factor';
import { AudiencePreBid, Close, Search } from '@applift/icons';
import { PrebidNormalizedSegmentData, SelectPrebidSegmentApiRefType } from '@applift/platform';

import { AudienceItemResponse } from 'models/Audience';
import { ALL_CATEGORY_CREATIVE_ID } from 'constants/audience';
import { NoResultOverlay } from 'components/BreadCrumbActions/BreadCrumbActions';
import { useAudiencesList } from 'hooks/useAudience';
import { fetchPreBidAudienceInfo } from 'api/Audience';

import styles from './index.module.scss';

interface SelectPreBidAudienceProps {
  apiRef: React.MutableRefObject<SelectPrebidSegmentApiRefType | undefined>;
  selectedSegmentsRef: React.MutableRefObject<PrebidNormalizedSegmentData[]>;
}

export const SelectPreBidAudiences = (props: SelectPreBidAudienceProps) => {
  const { apiRef, selectedSegmentsRef } = props;

  const [audienceSearch, setAudienceSearch] = React.useState('');
  const audienceListQuery = useAudiencesList({ audienceTypeIds: '8', searchField: audienceSearch });
  const audienceData = audienceListQuery.data as AudienceItemResponse[];
  const [loadingAudienceIds, setLoadingAudienceIds] = React.useState<number[]>([]);

  const addSegment = async (val: AudienceItemResponse) => {
    if (!loadingAudienceIds.includes(val.id)) {
      setLoadingAudienceIds((prev) => [...prev, val.id]);
      try {
        const audienceInfo = await fetchPreBidAudienceInfo(val.id);

        const selectedAudienceSegments = audienceInfo.data?.prebidSegmentData.map((one) => {
          return {
            value: `${one.prebidAudienceSegmentId}`,
            preservedValue: `${one.prebidAudienceSegmentId}`,
            isProvider: false,
            label: one.prebidAudienceSegmentName,
            description: one.description,
            parentPathKey: one.parentPathKey,
            cpm: one.cpm,
            creativeTypeIdList: one.creativeTypeIdList ?? [],
            creativeTypeIdSerialized: (one.creativeTypeIdList ?? []).sort().join(','),
            isLeaf: true,
            subRows: [],
            providerId: one.providerId,
            providerName: one.providerName,
            providerLogoUrl: one.providerLogoUrl,
            isAllCreativeType:
              (one.creativeTypeIdList ?? []).sort().join(',') ===
              ALL_CATEGORY_CREATIVE_ID.join(','),
          } as PrebidNormalizedSegmentData;
        });

        const newSelectionObject: Record<string, PrebidNormalizedSegmentData> = {};
        const newSelection = [
          ...selectedSegmentsRef.current,
          ...(selectedAudienceSegments as PrebidNormalizedSegmentData[]),
        ];
        newSelection.forEach((item) => {
          newSelectionObject[item.preservedValue] = item;
        });
        apiRef.current?.reinitializeRowSelection(newSelectionObject);

        enqueueSnackbar(
          <>
            Audience <Typography weight="demi">{val.audienceName} </Typography> is added
            succesfully.
          </>,
          {
            variant: 'success',
          },
        );
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
      setLoadingAudienceIds((prev) => prev.filter((id) => id !== val.id));
    }
  };

  return (
    <TextField
      variant="outlined"
      select
      size="medium"
      value={[]}
      SelectProps={{
        multiple: true,
        hideSelectNav: true,
        placeholder: 'Select Audiences',
        MenuProps: {
          PaperProps: { sx: { height: 'auto' }, style: { maxWidth: 200 } },
        },
        onClose: () => setAudienceSearch(''),
        renderContentTop: (
          <Box sx={{ borderBottom: 1 }}>
            <Typography sx={{ fontSize: 12, p: 8 }}>
              Click on audience to populate its segments in listing
            </Typography>
            <Box sx={{ width: 100 }}>
              <TextField
                placeholder="Search"
                sx={{ borderTop: 0 }}
                value={audienceSearch}
                onChange={(e: any) => setAudienceSearch(e.target.value)}
                fullWidth
                classes={{
                  root: styles.audienceSearchInput,
                }}
                InputProps={{
                  startAdornment: <Search fontSize={16} sx={{ textColor: 'neutral-400' }} />,
                  endAdornment:
                    audienceSearch?.length > 0 ? (
                      <IconButton
                        onClick={() => {
                          setAudienceSearch('');
                        }}
                        size="small"
                      >
                        <Close />
                      </IconButton>
                    ) : null,
                }}
              />
            </Box>
          </Box>
        ),
      }}
      sx={{
        width: 100,
        border: 1,
        borderRadius: 4,
        bgColor: 'neutral-0',
      }}
    >
      {audienceListQuery.isLoading ? (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: 'auto',
            py: 48,
          }}
        >
          <CircularProgress size={38} thickness={5} />
        </Container>
      ) : null}
      {audienceData?.map((val) => (
        <MenuItem
          key={val.id}
          value={val.id}
          sx={{ display: 'flex', alignItems: 'center', width: 100 }}
          onClick={() => addSegment(val)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AudiencePreBid fontSize={16} sx={{ textColor: 'primary-600', mr: 4 }} />
            <TypoTooltip>
              <Typography>{val.audienceName}</Typography>
            </TypoTooltip>
          </Box>
          {loadingAudienceIds.includes(val.id) && (
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ml: 'auto',
                my: 'auto',
                paddingRight: 0,
              }}
            >
              <CircularProgress size={16} thickness={5} sx={{ ml: 'auto' }} />
            </Container>
          )}
        </MenuItem>
      ))}
      {!audienceData?.length && audienceSearch ? <NoResultOverlay /> : null}
    </TextField>
  );
};
