import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Col,
  Row,
  InputLabel,
  LoadingButton,
} from '@applift/factor';
import { InfoCircle, Add, EqualTo, Division } from '@applift/icons';
import { CampaignListType } from 'models/CampaignList';
import { UpdateBudget } from 'api/Campaign';
import { BUDGET_TYPE_ID } from 'constants/apps';

interface SetCampaignTotalBudgetProps {
  selectedCampaigns: CampaignListType[];
  closeDialog: () => void;
  onCompletion: (data: UpdateBudget) => void;
  isLoading?: boolean;
}

export const SetCampaignTotalBudget = (props: SetCampaignTotalBudgetProps) => {
  const { closeDialog, onCompletion, isLoading, selectedCampaigns } = props;
  const getDefaultValue = (selectedCampaigns: CampaignListType[]) => {
    if (selectedCampaigns[0].budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return selectedCampaigns.length > 1 || selectedCampaigns[0]?.targetImpression === 0
        ? ''
        : selectedCampaigns[0]?.targetImpression?.toString();
    }
    return selectedCampaigns.length > 1 || selectedCampaigns[0]?.budgetTotal === 0
      ? ''
      : selectedCampaigns[0]?.budgetTotal?.toString();
  };
  const [updatedTotalBudget, updateBudget] = React.useState(getDefaultValue(selectedCampaigns));
  const [selectedCondition, updateCondition] = React.useState<
    'change' | 'addition' | 'distribution'
  >('change');
  const budgetTypeId = React.useMemo(() => selectedCampaigns[0]?.budgetTypeId, [selectedCampaigns]);

  const isFieldUpdatedRef = React.useRef(false);
  const selectionCount = selectedCampaigns.length;

  const getHelperText = () => {
    if (!Number(updatedTotalBudget)) {
      return selectedCondition === 'addition' && selectionCount === 1 ? (
        <>
          The total budget for the {selectedCampaigns[0].campaignName} will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {selectedCampaigns[0]?.budgetTotal}
          </Typography>
          .
        </>
      ) : (
        'The total budget will be updated as per the newly added amount.'
      );
    }
    if (selectedCondition === 'change' || selectedCondition === 'distribution') {
      const calculatedBudgetForSplit =
        budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
          ? (Number(updatedTotalBudget) / selectedCampaigns.length).toFixed(2)
          : Math.floor(Number(updatedTotalBudget) / selectedCampaigns.length);
      return selectionCount === 1 ? (
        <>
          The total budget for the {selectedCampaigns[0].campaignName} will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {updatedTotalBudget}
          </Typography>
          .
        </>
      ) : (
        <>
          The total budget for the {selectionCount} campaigns will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600', mr: 4 }}>
            {selectedCondition === 'change' ? updatedTotalBudget : calculatedBudgetForSplit}
          </Typography>
          each.
        </>
      );
    }
    if (selectedCondition === 'addition') {
      return selectionCount !== 1 ? (
        <>
          The total budget for the {selectionCount} campaigns will be increased by
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {updatedTotalBudget}
          </Typography>
          .
        </>
      ) : (
        <>
          The total budget for the {selectedCampaigns[0].campaignName} will be
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600', mr: 4 }}>
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? selectedCampaigns[0].budgetTotal.toFixed(2)
              : selectedCampaigns[0].targetImpression}{' '}
            +
          </Typography>
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'success-500', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'success-500', mr: 4 }}>
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? Number(updatedTotalBudget).toFixed(2)
              : Number(updatedTotalBudget)}
          </Typography>
          =
          {budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED ? (
            <Typography
              component="span"
              weight="demi"
              sx={{ textColor: 'neutral-600', ml: 4, mr: 2 }}
            >
              Imps
            </Typography>
          ) : (
            <Typography component="span" weight="demi" sx={{ textColor: 'neutral-600', ml: 4 }}>
              $
            </Typography>
          )}
          <Typography weight="demi" sx={{ textColor: 'neutral-600' }}>
            {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? (selectedCampaigns[0].budgetTotal + Number(updatedTotalBudget)).toFixed(2)
              : selectedCampaigns[0].targetImpression + Number(updatedTotalBudget)}
          </Typography>
          .
        </>
      );
    }
    return null;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle onClose={closeDialog}>Set Total Budget</DialogTitle>
      <DialogContent dividers sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography sx={{ mb: 16 }}>
          Enter the
          <Typography weight="demi" sx={{ ml: 4 }}>
            Total Budget
          </Typography>{' '}
          for the
          {selectionCount === 1 && <Typography sx={{ ml: 4 }}>campaign</Typography>}
          <Typography weight="demi" sx={{ mx: 4 }}>
            {selectionCount === 1 ? selectedCampaigns[0].campaignName : selectionCount}
          </Typography>
          {selectionCount > 1 && 'campaigns'}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Row sx={{ mb: 16 }}>
            <Col xs={6}>
              <TextField
                value={updatedTotalBudget}
                onChange={(e: any) => {
                  const regexPositiveFloat = /^(?:\d+(?:\.\d+)?|)$/;
                  const regexPositiveInt = /^[1-9]\d*$/;

                  const regexForValidation =
                    budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                      ? regexPositiveFloat
                      : regexPositiveInt;

                  const isValid =
                    budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                      ? regexForValidation.test(e.target.value) ||
                        (Boolean(e.target.value.split('.').length - 1 < 2) &&
                          e.target.value[e.target.value.length - 1] === '.')
                      : regexForValidation.test(e.target.value) || e.target.value === '';

                  if (!isValid) {
                    return;
                  }
                  isFieldUpdatedRef.current = true;
                  updateBudget(e.target.value);
                }}
                placeholder="Enter total budget"
                variant="outlinedDash"
                label="Total Budget"
                InputProps={{
                  startAdornment:
                    budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                      <Typography>$</Typography>
                    ) : (
                      <Typography>Imps</Typography>
                    ),
                }}
                sx={{ mb: 2 }}
                fullWidth
              />
            </Col>
            <Col xs={6} sx={{ pr: 0 }}>
              <InputLabel sx={{ visibility: 'hidden' }}>Dummy Label</InputLabel>
              <ToggleButtonGroup
                color="primary"
                variant="outlined"
                value={selectedCondition}
                exclusive
                onChange={(_, val) => {
                  isFieldUpdatedRef.current = true;
                  updateCondition(val);
                }}
                aria-label="Platform"
              >
                <ToggleButton value="change">
                  <EqualTo />
                  Set
                </ToggleButton>
                <ToggleButton value="addition">
                  <Add />
                  Add
                </ToggleButton>
                {selectedCampaigns.length > 1 && (
                  <ToggleButton value="distribution">
                    <Division />
                    Split
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
              <Tooltip
                title={
                  selectedCampaigns.length === 1
                    ? 'Set exact budget or add more to the existing budget across selected campaigns'
                    : 'Set exact budget, Add more to the existing or Split budget across selected campaigns'
                }
                placement="top"
                arrow
              >
                <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400', ml: 12 }} />
              </Tooltip>
            </Col>
          </Row>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 8 }}>
            <InfoCircle fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
              {getHelperText()}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={
            !(Number(updatedTotalBudget) > 0) ||
            !isFieldUpdatedRef.current ||
            (selectedCondition === 'change' &&
              Number(updatedTotalBudget) === selectedCampaigns[0].budgetTotal)
          }
          loading={isLoading}
          onClick={() =>
            onCompletion({
              ...(budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                ? { totalBudget: Number(updatedTotalBudget) }
                : { totalImpressions: Number(updatedTotalBudget) }),
              totalBudgetUpdateType: selectedCondition,
              campaignIds: selectedCampaigns.map((cmp) => cmp.campaignId).toString(),
              budgetTypeId: selectedCampaigns[0].budgetTypeId,
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
