import * as React from 'react';
import { ColumnDef } from '@applift/datagrid';
import { Creative } from 'models/Creative';
import { DateCell, FormattedNumberCell, RawDataCell } from 'components/CellType';
import { CreativePreviewCell } from './cellTypes/CreativePreviewCell';
import { CreativeStatusCell } from './cellTypes/CreativeStatusCell';
import { CreativeSizeCell } from './cells/CreativeSizeCell';

export const colDef: ColumnDef<Creative>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'ID',
    enableSorting: false,
    meta: {
      description: 'Unique system-generated number assigned to each Creative',
    },
    cell: (props) => <RawDataCell value={String(props.renderValue())} />,
    size: 100,
  },
  {
    accessorKey: 'creativeThumbnailSource',
    id: 'creativeThumbnailSource',
    header: 'Preview',
    enableSorting: false,
    meta: {
      description: 'Creative Preview Thumbnail',
    },
    cell: (props) => <CreativePreviewCell data={props.row.original} />,
    size: 150,
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Name',
    cell: (props) => <RawDataCell value={String(props.renderValue())} />,
    size: 200,
    sortingFn: (rowA, rowB) => {
      return rowA.original.name.toLowerCase() < rowB.original.name.toLowerCase() ? -1 : 1;
    },
  },
  {
    accessorKey: 'status',
    id: 'status',
    enableSorting: false,
    header: 'Status',
    cell: (props) => <CreativeStatusCell value={String(props.renderValue())} />,
    size: 120,
  },
  {
    accessorKey: 'clickUrl',
    id: 'clickUrl',
    enableSorting: false,
    header: 'Click URL',
    cell: (props) => <RawDataCell value={String(props.renderValue() ?? '—')} />,
    size: 220,
  },
  {
    accessorKey: 'pixelUrls',
    id: 'pixelUrls',
    header: 'Pixel URL',
    cell: (props) => {
      // @ts-ignore
      return <RawDataCell value={String(props.renderValue()?.join(',')) || '—'} />;
    },
    size: 220,
  },
  {
    accessorKey: 'createdOn',
    id: 'createdOn',
    header: 'Created',
    meta: {
      align: 'right',
    },
    cell: (props) => {
      return (
        <DateCell
          date={props.renderValue() as number}
          // @ts-ignore
          timezoneId={props.table.options?.meta?.orgTimezone?.id}
        />
      );
    },
    size: 220,
  },
  {
    accessorKey: 'size',
    id: 'size',
    header: 'Size',
    meta: {
      align: 'right',
    },
    enableSorting: false,
    cell: (props) => {
      return <CreativeSizeCell creative={props.row.original} />;
    },
    size: 120,
  },
  {
    accessorKey: 'campaignCount',
    id: 'campaignCount',
    header: 'Part Of',
    meta: {
      align: 'right',
    },
    enableSorting: false,
    cell: (props) => {
      return <FormattedNumberCell value={props.renderValue() as number} />;
    },
    size: 120,
  },
];
