import { useRef } from 'react';

export const useScrollbarWidth = () => {
  const alreadyCalculated = useRef(false);
  const widthRef = useRef(0);

  if (alreadyCalculated.current) return widthRef.current;

  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.className = 'scrollDiv';
  document.body.appendChild(scrollDiv);
  const width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  widthRef.current = width;
  document.body.removeChild(scrollDiv);

  alreadyCalculated.current = true;
  widthRef.current = width;

  return width;
};
