/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { getPathNumber } from 'iqm-framework';
import { QueryFunctionContext } from '@tanstack/react-query';

import { CountryListResponseType } from 'pages/MainPage/PGCampaignPage/LocationBlock';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import {
  GetResponse,
  GetResponseWithData,
  GetResponseWithDataV3,
  //  WithV3Response,
} from '../models/Response';
import { Timezone } from '../models/Timezone';
import { DeviceType } from '../models/Device';
import { Dictionary } from '../models/Dictionary';
import { Option } from '../models/Option';
import {
  convertDistrictCodeFromDTO,
  convertLocationStateFromDTO,
  DistrictCodeDTO,
  LocationState,
  LocationStateDTO,
  DistrictCode,
  DistrictOption,
  StateRegionOption,
  ICountryOption,
  IZipCodeInfo,
} from '../models/Location';
import { getCountryListQueryKey } from './QueryKeys';

let timezonesPromise: Promise<AxiosResponse<GetResponse<Timezone[]>>>;
let deviceTypesPromise: Promise<AxiosResponse<DeviceType[]>>;
let ageRangePromise: Promise<AxiosResponse<Dictionary[]>>;
let genderPromise: Promise<AxiosResponse<Dictionary[]>>;
let incomeRangePromise: Promise<AxiosResponse<Dictionary[]>>;
let ethnicityPromise: Promise<AxiosResponse<Dictionary[]>>;
let networkTypesPromise: Promise<AxiosResponse<Dictionary[]>>;
// let trafficTypesPromise: Promise<AxiosResponse<WithV3Response<Dictionary[]>>>;
let manufacturersPromise: Promise<AxiosResponse<Dictionary[]>>;
let deviceOsPromise: Promise<AxiosResponse<Dictionary[]>>;
let districtCodesPromise: Promise<AxiosResponse<DistrictCodeDTO[]>>;
let senateStatesPromise: Promise<AxiosResponse<LocationStateDTO[]>>;
let houseStatesPromise: Promise<AxiosResponse<LocationStateDTO[]>>;
let conversionsPromise: Promise<AxiosResponse<GetResponse<{ data: unknown[] }>>>;

interface LocationTypeRequestParams {
  campaignId?: number;
  pageNo?: number;
  noOfEntries?: number;
  sortBy?: string;
  searchField?: string;
  parentSegmentIds?: number[];
  segmentIds?: number[];
  ids?: number[];
  cacheBuster?: number;
}

interface ZipCodeRequestParams {
  pageNo?: number;
  noOfEntries?: number;
  sortBy?: string;
  searchField?: string;
  parentSegmentIds?: number[];
  segmentIds?: number[];
  ids?: number[];
  zipCodes: string[];
}

export interface LocationDetailsResponse {
  name: string;
  id: number;
  parentId: number;
  abbreviation: string;
  geojsonUrl: string;
}

export const getCountryOptions = async (
  params?: LocationTypeRequestParams,
): Promise<ICountryOption[]> => {
  try {
    const response: AxiosResponse<
      GetResponseWithDataV3<{ name: string; id: number; abbreviation: string }[]>
    > = await getInstance().post(`/v3/master/segment/country`, params || {});
    const countries = response.data?.data?.data || [];
    return countries
      .filter((item) => item.name !== 'Unknown')
      .map((country) => ({
        label: country.name,
        value: country.id,
        shortName: country.abbreviation,
      }));
  } catch (e) {
    Logger.log('Error while getting countries', e);
    return [];
  }
};

export const getCountryList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCountryListQueryKey['keys']>>): Promise<{
  data?: CountryListResponseType[];
  totalRecords?: number;
  filteredRecords?: number;
}> => {
  try {
    const { searchField } = queryKey[0]!;
    const response: AxiosResponse<
      GetResponseWithDataV3<{ name: string; id: number; abbreviation: string }[]>
    > = await getInstance().post(
      `/v3/master/segment/country`,
      { searchField, sortBy: '+id' } || {},
    );
    const countries = response.data?.data?.data || [];
    return {
      filteredRecords: response.data?.data?.filteredRecords,
      totalRecords: response.data?.data?.totalRecords,
      data: countries
        .filter((item) => item.name !== 'Unknown')
        .map((country) => ({
          label: country.name,
          value: country.id,
          shortName: country.abbreviation,
        })),
    };
  } catch (e) {
    Logger.log('Error while getting countries', e);
    return {};
  }
};

export const States = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<
      GetResponseWithDataV3<
        { name: string; id: number; abbreviation: string; geojsonUrl: string }[]
      >
    > = await getInstance().post(`/v3/master/segment/state`, params || {}, {
      ...(parentApp
        ? {
            headers: {
              'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
            },
          }
        : {}),
    });
    const data = response?.data?.data?.data || [];

    return data.map((state: any) => ({
      label: state.name,
      value: state.id,
      abbreviation: state.abbreviation,
      geojsonUrl: state.geojsonUrl,
      v2Id: state.id,
    }));
  } catch (e) {
    Logger.log('Error while getting states', e);
    return [];
  }
};

export const Cities = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<StateRegionOption[]> => {
  try {
    const response: AxiosResponse<
      GetResponseWithDataV3<{ name: string; id: number; parentId: number; geojsonUrl: string }[]>
    > = await getInstance().post(`/v3/master/segment/city`, params || {}, {
      ...(parentApp
        ? {
            headers: {
              'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
            },
          }
        : {}),
    });
    const cities = response.data?.data?.data || [];
    return cities.map((city) => ({
      label: city.name,
      value: city.id,
      stateId: city.parentId,
      geojsonUrl: city.geojsonUrl,
      parentId: city.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting cities', e);
    return [];
  }
};

export const Counties = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<StateRegionOption[]> => {
  try {
    const response: AxiosResponse<
      GetResponseWithDataV3<{ name: string; id: number; parentId: number; geojsonUrl: string }[]>
    > = await getInstance().post(`/v3/master/segment/county`, params || {}, {
      ...(parentApp
        ? {
            headers: {
              'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
            },
          }
        : {}),
    });
    const counties = response.data?.data?.data || [];
    return counties.map((county) => ({
      label: county.name,
      value: county.id,
      stateId: county.parentId,
      geojsonUrl: county.geojsonUrl,
      parentId: county.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const Houses = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<StateRegionOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithDataV3<LocationDetailsResponse[]>> =
      await getInstance().post(`/v3/master/segment/house-district`, params || {}, {
        ...(parentApp
          ? {
              headers: {
                'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
              },
            }
          : {}),
      });
    const houses = response.data?.data?.data || [];
    return houses.map((house) => ({
      label: house.name,
      value: house.id,
      stateId: house.parentId,
      geojsonUrl: house.geojsonUrl,
      parentId: house.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const Senates = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<StateRegionOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithDataV3<LocationDetailsResponse[]>> =
      await getInstance().post(`/v3/master/segment/senate-district`, params || {}, {
        ...(parentApp
          ? {
              headers: {
                'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
              },
            }
          : {}),
      });
    const senates = response.data?.data?.data || [];
    return senates.map((senate) => ({
      label: senate.name,
      value: senate.id,
      stateId: senate.parentId,
      geojsonUrl: senate.geojsonUrl,
      parentId: senate.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const congressionalDistricts = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<StateRegionOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithDataV3<LocationDetailsResponse[]>> =
      await getInstance().post(`/v3/master/segment/congressional-district`, params || {}, {
        ...(parentApp
          ? {
              headers: {
                'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
              },
            }
          : {}),
      });
    const congressionalDistricts = response.data?.data?.data || [];
    return congressionalDistricts.map((congressionalDistrict) => ({
      label: congressionalDistrict.name,
      value: congressionalDistrict.id,
      stateId: congressionalDistrict.parentId,
      geojsonUrl: congressionalDistrict.geojsonUrl,
      parentId: congressionalDistrict.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const getZipCodeData = async (
  params: ZipCodeRequestParams,
  parentApp?: string,
): Promise<Option<string>[]> => {
  try {
    const response: AxiosResponse<GetResponseWithDataV3<IZipCodeInfo[]>> = await getInstance().post(
      `/v3/master/segment/zip`,
      params || {},
      {
        ...(parentApp
          ? {
              headers: {
                'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
              },
            }
          : {}),
      },
    );
    return params.zipCodes?.map((item) => {
      const currentItem = response.data?.data?.data.find((zipData) => zipData.name === item);
      return {
        label: item,
        value: item,
        ...(currentItem?.geojsonUrl ? { geojsonUrl: currentItem.geojsonUrl } : {}),
        ...(currentItem?.parentId ? { parentId: currentItem.parentId } : {}),
      };
    });
  } catch (e) {
    Logger.log('Error while getting zipCodeInfo', e);
    return [];
  }
};

export const getDMAData = async (
  params: LocationTypeRequestParams,
  parentApp?: string,
): Promise<StateRegionOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithDataV3<LocationDetailsResponse[]>> =
      await getInstance().post(`/v3/master/segment/dma`, params || {}, {
        ...(parentApp
          ? {
              headers: {
                'x-iaa-referer': `${window.location.origin}/${parentApp}/u/${getPathNumber()}`,
              },
            }
          : {}),
      });
    const dmaData = response.data?.data?.data || [];
    return dmaData.map((dma) => ({
      label: dma.name,
      value: dma.id,
      stateId: dma.parentId,
      geojsonUrl: dma.geojsonUrl,
      parentId: dma.parentId,
      abbreviation: dma.abbreviation,
    }));
  } catch (e) {
    Logger.log('Error while getting zipCodeInfo', e);
    return [];
  }
};

export const Timezones = async (): Promise<Timezone[]> => {
  try {
    const response: AxiosResponse<GetResponse<Timezone[]>> = await (timezonesPromise ||
      (timezonesPromise = getInstance().get('/v2/adv/static/timezones')));

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting timezones', e);
    return [];
  }
};

export const DeviceTypes = async (): Promise<DeviceType[]> => {
  try {
    const response: AxiosResponse<DeviceType[]> = await (deviceTypesPromise ||
      (deviceTypesPromise = getInstance().get('/device_types')));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting device types', e);
    return [];
  }
};

export const AgeRange = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (ageRangePromise ||
      (ageRangePromise = getInstance().get('/v2/master/segment/age')));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting age range list', e);
    return [];
  }
};

export const Gender = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (genderPromise ||
      (genderPromise = getInstance().get('/v2/master/segment/gender')));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting gender list', e);
    return [];
  }
};

export const IncomeRange = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (incomeRangePromise ||
      (incomeRangePromise = getInstance().get('/v2/master/segment/income')));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting income range list', e);
    return [];
  }
};

export const Ethnicity = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (ethnicityPromise ||
      (ethnicityPromise = getInstance().get('/v2/master/segment/ethnicity-group')));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting ethnicity list', e);
    return [];
  }
};

export const Carriers = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<Dictionary[]>> = await getInstance().get(
      `/v2/master/segment/device-carrier`,
    );

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while getting carriers list', e);
    return [];
  }
};

export const NetworkTypes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (networkTypesPromise ||
      (networkTypesPromise = getInstance().get(`/network_types`)));
    return response.data;
  } catch (e) {
    Logger.log('Error while getting network types list', e);
    return [];
  }
};

export const TrafficTypes = async (): Promise<Dictionary[]> => {
  try {
    // const response: AxiosResponse<WithV3Response<Dictionary[]>> = await (trafficTypesPromise ||
    //   (trafficTypesPromise = getInstance().get(`/v3/master/traffic-types`)));

    // return response.data.data?.data || [];

    // Todo: Remove this and uncommend below code
    const staticData = [
      { id: 11, name: 'App', isTvAd: false },
      { id: 14, name: 'CTV', isTvAd: true },
      { id: 13, name: 'OTT', isTvAd: true },
      { id: 12, name: 'Web', isTvAd: false },
    ];
    return staticData;
  } catch (e) {
    Logger.log('Error while getting traffic types list', e);
    return [];
  }
};

export const Manufacturers = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (manufacturersPromise ||
      (manufacturersPromise = getInstance().get(`/manufacturers`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting manufacturers list', e);
    return [];
  }
};

export const DeviceOs = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (deviceOsPromise ||
      (deviceOsPromise = getInstance().get(`/device_os`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while getting device os list', e);
    return [];
  }
};

export const DistrictCodes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<DistrictCodeDTO[]> = await (districtCodesPromise ||
      (districtCodesPromise = getInstance().get(`/congressionalDistrictIds`)));

    return response.data.map((d) => convertDistrictCodeFromDTO(d));
  } catch (e) {
    Logger.log('Error while getting district codes list', e);
    return [];
  }
};

export const SenateStates = async (): Promise<LocationState[]> => {
  try {
    const response: AxiosResponse<LocationStateDTO[]> = await (senateStatesPromise ||
      (senateStatesPromise = getInstance().get(`/list/senate_states`)));

    return response.data.map((d) => convertLocationStateFromDTO(d));
  } catch (e) {
    Logger.log('Error while getting senate states list', e);
    return [];
  }
};

export const HouseStates = async (): Promise<LocationState[]> => {
  try {
    const response: AxiosResponse<LocationStateDTO[]> = await (houseStatesPromise ||
      (houseStatesPromise = getInstance().get(`/list/house_states`)));

    return response.data.map((d) => convertLocationStateFromDTO(d));
  } catch (e) {
    Logger.log('Error while getting house states list', e);
    return [];
  }
};

type LocationStatesWithDistrictList = {
  [id: number]: LocationState & {
    districtList: Array<DistrictCode>;
  };
};

export const Districts = async (stateIds: string, type: string): Promise<DistrictOption[]> => {
  try {
    const response: AxiosResponse<GetResponse<LocationStatesWithDistrictList>> =
      await getInstance().get(`/v2/master/district/list`, {
        params: {
          stateIds,
          type,
        },
      });

    return Object.values(response.data.responseObject).reduce(
      (acc: DistrictOption[], state: any) => {
        return acc.concat(
          state.districtList.map((i: DistrictCode) => {
            const abbreviation = i.districtId.slice(0, i.districtId.indexOf('-'));
            const id = i.districtId.slice(i.districtId.indexOf('-') + 1);

            return {
              value: i.id,
              label: i.districtName,
              abbreviation,
              // eslint-disable-next-line no-restricted-globals
              id: isNaN(+id) ? id : +id,
              stateId: state.id,
            };
          }),
        );
      },
      [],
    );
  } catch (e) {
    Logger.log('Error while getting districts for selected states', e);
    return [];
  }
};

export const Conversions = async (campaignId?: number): Promise<unknown[]> => {
  try {
    const response: AxiosResponse<GetResponse<{ data: unknown[] }>> = await (conversionsPromise ||
      (conversionsPromise = getInstance().get(
        `/v2/conversion/list?limit=50&campaignId=${campaignId}`,
      )));

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while getting conversions', e);
    return [];
  }
};
