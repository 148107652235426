import * as React from 'react';
import { Delete } from '@applift/icons';
import { Box, IconButton } from '@applift/factor';
import { PgDealResponseType } from 'models/Inventory';

export interface ActionCellTypes {
  data: PgDealResponseType;
  removePgDeal?: (id: number) => void;
}

export const ActionCell = (props: ActionCellTypes) => {
  const { removePgDeal, data } = props;

  if (!removePgDeal) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <IconButton onClick={() => removePgDeal(data.id)}>
        <Delete sx={{ textColor: 'neutral-400' }} fontSize={24} />
      </IconButton>
    </Box>
  );
};
