import * as React from 'react';
import {
  Box,
  Button,
  Col,
  Divider,
  Row,
  TextField,
  Tooltip,
  Typography,
  sx,
} from '@applift/factor';
import { ArrowRightLong } from '@applift/icons';
import { connect } from 'react-redux';

import { nFormatter } from 'utils/format';
import { scrollToElement } from 'utils/scroll';
import { AppState } from 'models/Store';
import { DataDogLogger } from 'services/DataDog';
import { ChangeBidField, bidActions } from 'store/bid/actions';
import { EstimatorDataActionType, estimatorActions } from 'store/estimator/actions';
import { CampaignEstimatorMetaData } from 'models/ExistingCampaign';
import { Slider } from '../Slider';

import styles from '../styles.module.scss';

interface Budget {
  key: string;
  labelPrimary: JSX.Element;
  labelSecondary: JSX.Element;
  primaryValue: number;
  secondaryValue: number;
}

interface Impressions {
  key: string;
  labelPrimary: JSX.Element;
  labelSecondary: JSX.Element;
  primaryValue: number;
  secondaryValue: number;
}

interface MaxBid {
  key: string;
  labelPrimary: JSX.Element;
  labelSecondary: JSX.Element;
  primaryValue: number;
  secondaryValue: number;
}
interface ProposedValues {
  budget: Budget;
  impressions: Impressions;
  maxBid: MaxBid;
}

export interface ReachProps extends ChangeBidField {
  maxBid: string;
  totalBudget?: string;
  targetableAudience?: number;
  startDate?: number;
  endDate?: number;
  reach?: number;
  campaignEstimatorMetaData?: CampaignEstimatorMetaData;
  setEstimatorMetaData: EstimatorDataActionType['setEstimatorMetaData'];
  totalImpressions?: string;
  setCampaignSidebarInfo: (key: string, value: string) => void;
  setShouldUpdateReach: (arg: boolean) => void;
}

const BIAS_COEFF = -13.19309294;
const SQRT_D_COEFF = 0.76293798;
const LOG_D_COEFF = 8.5490572;
const D_COEFF = -0.01724674;

const ceilToSignificance = (number: number, significance: number | undefined) => {
  if (significance === undefined) {
    return Math.ceil(number);
  }
  if (significance === 0) {
    return 0;
  }
  return Math.ceil(number / significance) * significance;
};

export const ReachComponent = (props: ReachProps) => {
  const {
    maxBid,
    totalBudget,
    targetableAudience,
    setCampaignSidebarInfo,
    endDate,
    startDate,
    setEstimatorMetaData,
    campaignEstimatorMetaData,
    reach,
    totalImpressions: totalImpressionsBudget,
    changeBidField,
    setShouldUpdateReach,
  } = props;

  const [showAdvancedView, setShowAdvancedView] = React.useState(false);
  const [prevReach, setPrevReach] = React.useState(0);
  const [prevImpression, setPrevImpression] = React.useState(0);
  const [prevProposed, setPrevProposed] = React.useState({
    totalBudget: 0,
    maxBid: 0,
    totalImpressionsBudget: 0,
  });
  const [localBudget, setLocalBudget] = React.useState({
    totalBudget,
    maxBid,
    totalImpressionsBudget,
  });
  const [prevBudget, setPrevBudget] = React.useState({
    totalBudget,
    maxBid,
    totalImpressionsBudget,
  });
  const [prevTargetableAudience, setPrevTargetableAudience] = React.useState(
    targetableAudience || 0,
  );
  const [isSliderUpdated, setIsSliderUpdated] = React.useState(false);
  const [frequencyAtSlider, setFrequencyAtSlider] = React.useState<number | undefined>(undefined);
  const [seedFrequency, setSeedFrequency] = React.useState<number | undefined>(undefined);
  const currBudgetRef = React.useRef({ totalBudget, maxBid });

  const isTargetableAudienceChanged = React.useMemo(
    () => prevTargetableAudience !== targetableAudience,
    [prevTargetableAudience, targetableAudience],
  );

  const isBudgetChanged = React.useMemo(
    () =>
      prevBudget.totalBudget !== localBudget.totalBudget ||
      prevBudget.maxBid !== localBudget.maxBid,
    [localBudget.maxBid, localBudget.totalBudget, prevBudget.maxBid, prevBudget.totalBudget],
  );

  const metaSliderReach = campaignEstimatorMetaData?.sliderMeta?.sliderReach;
  const metaSliderImpression = campaignEstimatorMetaData?.sliderMeta?.sliderImpression;

  const [sliderInfo, setSliderInfo] = React.useState({
    uniqueReach: {
      key: 'uniqueReach',
      label: 'Unique Reach',
      range: {
        min: 0,
        max: 0,
      },
      slider: [0],
      maxSliderValue: 0,
    },
    totalImpressions: {
      key: 'totalImpressions',
      label: 'Total Impressions',
      range: {
        min: 0,
        max: 0,
      },
      slider: [0],
      maxSliderValue: 0,
    },
  });

  const updateEstimatorMeta = React.useCallback(() => {
    const currentUniqueReach = sliderInfo.uniqueReach.slider;
    const currentTotalImpressions = sliderInfo.totalImpressions.slider;
    if (currentUniqueReach[0] === currentUniqueReach[1]) {
      setEstimatorMetaData({
        key: 'sliderMeta',
        val: {
          sliderReach: sliderInfo.uniqueReach.slider[1],
          sliderImpression: sliderInfo.totalImpressions.slider[1],
          seedFrequency,
        },
      });
    }
    if (currentTotalImpressions[0] === currentTotalImpressions[1]) {
      setEstimatorMetaData({
        key: 'reachMeta',
        val: {
          estimatedReach: reach,
        },
      });
    }
  }, [
    reach,
    seedFrequency,
    setEstimatorMetaData,
    sliderInfo.totalImpressions.slider,
    sliderInfo.uniqueReach.slider,
  ]);

  const [proposedValues, setProposedValues] = React.useState<ProposedValues>({
    budget: {
      key: 'budget',
      labelPrimary: (
        <Tooltip title="Current Budget" placement="top">
          <Typography noWrap sx={{ display: 'block' }} style={{ pointerEvents: 'all' }}>
            Current Budget
          </Typography>
        </Tooltip>
      ),
      labelSecondary: (
        <Tooltip title="Proposed Total Budget" placement="top">
          <Typography noWrap sx={{ display: 'block' }} style={{ pointerEvents: 'all' }}>
            Proposed Total Budget
          </Typography>
        </Tooltip>
      ),
      primaryValue: localBudget.totalBudget ? +localBudget.totalBudget : 0,
      secondaryValue: 0,
    },
    impressions: {
      key: 'impressions',
      labelPrimary: (
        <Tooltip title="Current Budget" placement="top">
          <Typography noWrap sx={{ display: 'block' }} style={{ pointerEvents: 'all' }}>
            Current Budget
          </Typography>
        </Tooltip>
      ),
      labelSecondary: (
        <Tooltip title="Proposed Total Budget" placement="top">
          <Typography noWrap sx={{ display: 'block' }} style={{ pointerEvents: 'all' }}>
            Proposed Total Budget
          </Typography>
        </Tooltip>
      ),
      primaryValue: localBudget.totalBudget ? +localBudget.totalBudget : 0,
      secondaryValue: 0,
    },
    maxBid: {
      key: 'maxBid',
      labelPrimary: (
        <Tooltip title="Current Max Bid Price" placement="top">
          <Typography noWrap sx={{ display: 'block' }} style={{ pointerEvents: 'all' }}>
            Current Max Bid Price
          </Typography>
        </Tooltip>
      ),
      labelSecondary: (
        <Tooltip title="Required Total Max Bid Price" placement="top">
          <Typography noWrap sx={{ display: 'block' }} style={{ pointerEvents: 'all' }}>
            Proposed Max Bid Price
          </Typography>
        </Tooltip>
      ),
      primaryValue: +localBudget.maxBid,
      secondaryValue: 0,
    },
  });

  const inputTotalImpressions = React.useMemo(
    () => Number(localBudget.totalImpressionsBudget),
    [localBudget.totalImpressionsBudget],
  );

  const maxUniquesAtTwoFreq = React.useMemo(
    () => inputTotalImpressions / 2,
    [inputTotalImpressions],
  );

  const inputFlightDays = React.useMemo(() => {
    if (startDate && endDate) {
      const diffInMilliseconds = Math.abs(endDate - startDate);
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay) + 1;
      return diffInDays;
    }
    return 0;
  }, [endDate, startDate]);

  const defaultFrequency = React.useMemo(() => {
    const basicCoeff = BIAS_COEFF + inputFlightDays * D_COEFF;
    const logCoeff = Math.log(inputFlightDays) * LOG_D_COEFF;
    const sqrtCoeff = Math.sqrt(inputFlightDays) * SQRT_D_COEFF;
    const res = Math.ceil(Math.min(25, Math.max(3, basicCoeff + logCoeff + sqrtCoeff)));
    return res;
  }, [inputFlightDays]);

  const maximumReach = React.useMemo(
    () => (targetableAudience ? Math.min(maxUniquesAtTwoFreq, targetableAudience) : 0),
    [maxUniquesAtTwoFreq, targetableAudience],
  );

  const maximumImpressions = React.useMemo(
    () => inputTotalImpressions * 5,
    [inputTotalImpressions],
  );

  const rangeReachMin = React.useMemo(
    () => (sliderInfo.uniqueReach.slider[1] * 0.95) / 1000000,
    [sliderInfo],
  );

  const rangeReachMax = React.useMemo(
    () => (sliderInfo.uniqueReach.slider[1] * 1.05) / 1000000,
    [sliderInfo],
  );

  const rangeImpressionMin = React.useMemo(
    () => (sliderInfo.totalImpressions.slider[1] * 0.95) / 1000000,
    [sliderInfo],
  );
  const rangeImpressionMax = React.useMemo(
    () => (sliderInfo.totalImpressions.slider[1] * 1.05) / 1000000,
    [sliderInfo],
  );

  React.useEffect(() => {
    if (
      isTargetableAudienceChanged &&
      targetableAudience &&
      sliderInfo.uniqueReach.slider[1] >= targetableAudience &&
      sliderInfo.uniqueReach.slider[1] !== maximumReach
    ) {
      setSliderInfo((i) => ({
        ...i,
        uniqueReach: {
          ...i.uniqueReach,
          slider: [i.uniqueReach.slider[0], maximumReach],
        },
      }));
    }
  }, [
    isTargetableAudienceChanged,
    maximumReach,
    sliderInfo.uniqueReach.slider,
    targetableAudience,
  ]);

  const reachSliderVal = React.useMemo(() => {
    if (!(isTargetableAudienceChanged || isBudgetChanged) && metaSliderReach && !isSliderUpdated) {
      return Math.min(metaSliderReach, maximumReach);
    }
    if (
      isTargetableAudienceChanged &&
      targetableAudience &&
      sliderInfo.uniqueReach.slider[0] >= targetableAudience
    ) {
      return maximumReach;
    }
    return Math.min(inputTotalImpressions / (seedFrequency || defaultFrequency), maximumReach);
  }, [
    defaultFrequency,
    seedFrequency,
    inputTotalImpressions,
    isBudgetChanged,
    isSliderUpdated,
    isTargetableAudienceChanged,
    maximumReach,
    metaSliderReach,
    sliderInfo.uniqueReach.slider,
    targetableAudience,
  ]);

  const impressionSliderVal = React.useMemo(
    () =>
      !(isTargetableAudienceChanged || isBudgetChanged) && metaSliderImpression && !isSliderUpdated
        ? metaSliderImpression
        : Math.min(inputTotalImpressions, maximumImpressions),
    [
      inputTotalImpressions,
      isBudgetChanged,
      isSliderUpdated,
      isTargetableAudienceChanged,
      maximumImpressions,
      metaSliderImpression,
    ],
  );

  const impressionScale = React.useMemo(
    () => sliderInfo.totalImpressions.slider[1] / impressionSliderVal,
    [impressionSliderVal, sliderInfo.totalImpressions.slider],
  );

  const uniquesScale = React.useMemo(
    () => sliderInfo.uniqueReach.slider[1] / reachSliderVal,
    [reachSliderVal, sliderInfo.uniqueReach.slider],
  );

  const maxBidScale = React.useMemo(
    () => (impressionScale * Math.sqrt(uniquesScale)) ** 0.25,
    [impressionScale, uniquesScale],
  );

  const proposedMaxBid = React.useMemo(
    () => +ceilToSignificance(+localBudget.maxBid * maxBidScale, 0.1).toFixed(2),
    [localBudget.maxBid, maxBidScale],
  );

  const proposedBudget = React.useMemo(
    () => ((proposedMaxBid * sliderInfo.totalImpressions.slider[1]) / 1000).toFixed(2),
    [proposedMaxBid, sliderInfo.totalImpressions.slider],
  );

  const proposedBudgetImpressions = React.useMemo(
    () => ceilToSignificance(Number(localBudget.totalImpressionsBudget) * maxBidScale, 1000),
    [localBudget.totalImpressionsBudget, maxBidScale],
  );

  // This is made available for debugging purpose for Q/A should be removed when stablized or when moving to production/preprod
  // eslint-disable-next-line
  console.log({
    ...sliderInfo,
    inputTotalImpressions,
    maxUniquesAtTwoFreq,
    inputFlightDays,
    defaultFrequency,
    maximumReach,
    maximumImpressions,
    impressionScale,
    uniquesScale,
    maxBidScale,
    proposedMaxBid,
    proposedBudget,
    frequencyAtSlider,
    seedFrequency,
    reachSliderVal,
  });

  const shouldPreserveValue = React.useMemo(() => {
    return (
      reachSliderVal > prevReach ||
      (prevProposed.maxBid !== +maxBid && +prevBudget.maxBid !== +maxBid) ||
      +prevBudget.totalImpressionsBudget! !== +totalImpressionsBudget! ||
      (totalImpressionsBudget &&
        maxBid &&
        (prevProposed.totalImpressionsBudget !== +totalImpressionsBudget ||
          prevProposed.maxBid !== +maxBid))
    );
  }, [
    reachSliderVal,
    prevReach,
    prevProposed.maxBid,
    prevProposed.totalImpressionsBudget,
    maxBid,
    prevBudget.maxBid,
    prevBudget.totalImpressionsBudget,
    totalImpressionsBudget,
  ]);

  React.useEffect(() => {
    if (shouldPreserveValue) {
      setLocalBudget({ maxBid, totalBudget, totalImpressionsBudget });
    }
  }, [maxBid, shouldPreserveValue, totalBudget, totalImpressionsBudget]);

  const setSliderInfoCB = React.useCallback(
    (arg: keyof typeof sliderInfo, value) => {
      if (arg === 'totalImpressions') {
        const shouldUpdateSliderValue = value[1] > sliderInfo.totalImpressions.slider[0];
        if (!shouldUpdateSliderValue) {
          DataDogLogger.Estimator.changeEstimatorSlider(false, 'totalImpressions');
          return;
        }

        DataDogLogger.Estimator.changeEstimatorSlider(true, 'totalImpressions');
        setShowAdvancedView(true);
        setSliderInfo((i) => ({
          ...i,
          totalImpressions: {
            ...i.totalImpressions,
            slider: shouldPreserveValue
              ? [impressionSliderVal, value[1]]
              : [prevImpression, value[1]],
          },
        }));
      }
      if (arg === 'uniqueReach') {
        const shouldUpdateSliderValue = value[1] > sliderInfo.uniqueReach.slider[0];
        if (!shouldUpdateSliderValue) {
          DataDogLogger.Estimator.changeEstimatorSlider(false, 'uniqueReach');
          return;
        }
        DataDogLogger.Estimator.changeEstimatorSlider(true, 'uniqueReach');
        setShowAdvancedView(true);
        setSliderInfo((i) => ({
          ...i,
          uniqueReach: {
            ...i.uniqueReach,
            slider: shouldPreserveValue ? [reachSliderVal, value[1]] : [prevReach, value[1]],
          },
        }));
      }
    },
    [
      impressionSliderVal,
      prevImpression,
      prevReach,
      reachSliderVal,
      shouldPreserveValue,
      sliderInfo.totalImpressions.slider,
      sliderInfo.uniqueReach.slider,
    ],
  );

  const setProposedValue = React.useCallback((arg: keyof ProposedValues, value) => {
    if (value.target) {
      const { value: secondaryValue } = value.target;
      const regexForValidation = /^(?:\d+(?:\.\d+)?|)$/;
      const isValid =
        regexForValidation.test(secondaryValue) ||
        secondaryValue[secondaryValue.length - 1] === '.';

      if (!isValid) {
        return;
      }

      if (arg === 'budget') {
        setProposedValues((i: ProposedValues) => ({
          ...i,
          budget: { ...i.budget, secondaryValue },
        }));
      }
      if (arg === 'maxBid') {
        if (secondaryValue > 1000) {
          return;
        }
        setProposedValues((i: ProposedValues) => ({
          ...i,
          maxBid: { ...i.maxBid, secondaryValue },
        }));
      }
      if (arg === 'impressions') {
        setProposedValues((i: ProposedValues) => ({
          ...i,
          impressions: { ...i.impressions, secondaryValue },
        }));
      }
    }
  }, []);

  const checkProposedValuesValidation = React.useCallback(() => {
    const proposedBudgetLocal = proposedValues.budget.secondaryValue;
    const proposedMaxBidLocal = proposedValues.maxBid.secondaryValue;
    const proposedImpressionsLocal = proposedValues.impressions.secondaryValue;
    if (localBudget.totalBudget && proposedBudgetLocal < +localBudget.totalBudget) {
      setProposedValues((i: ProposedValues) => ({
        ...i,
        budget: { ...i.budget, secondaryValue: +proposedBudget },
      }));
    }
    if (proposedMaxBidLocal < +localBudget.maxBid) {
      setProposedValues((i: ProposedValues) => ({
        ...i,
        maxBid: { ...i.maxBid, secondaryValue: +proposedMaxBid },
      }));
    }
    if (proposedImpressionsLocal < Number(localBudget.totalImpressionsBudget)) {
      setProposedValues((i: ProposedValues) => ({
        ...i,
        impressions: { ...i.impressions, secondaryValue: +proposedBudgetImpressions },
      }));
    }
  }, [
    localBudget.maxBid,
    localBudget.totalBudget,
    localBudget.totalImpressionsBudget,
    proposedBudget,
    proposedBudgetImpressions,
    proposedMaxBid,
    proposedValues.budget.secondaryValue,
    proposedValues.impressions.secondaryValue,
    proposedValues.maxBid.secondaryValue,
  ]);

  const resetWidget = React.useCallback(() => {
    const resetReach = prevReach || reachSliderVal;
    const resetImpression = prevImpression || impressionSliderVal;
    if (targetableAudience && sliderInfo.uniqueReach.slider[1] >= targetableAudience) {
      setSliderInfo((i) => ({
        uniqueReach: {
          ...i.uniqueReach,
          slider: [sliderInfo.uniqueReach.slider[0], sliderInfo.uniqueReach.slider[0]],
        },
        totalImpressions: {
          ...i.totalImpressions,
          slider: [resetImpression, resetImpression],
        },
      }));
    }
    setSliderInfo((i) => ({
      uniqueReach: {
        ...i.uniqueReach,
        slider: [resetReach, resetReach],
      },
      totalImpressions: {
        ...i.totalImpressions,
        slider: [resetImpression, resetImpression],
      },
    }));
    setShowAdvancedView(false);
  }, [
    impressionSliderVal,
    prevImpression,
    prevReach,
    reachSliderVal,
    sliderInfo.uniqueReach.slider,
    targetableAudience,
  ]);

  React.useEffect(() => {
    if (
      (localBudget.totalBudget &&
        proposedValues.budget.primaryValue !== +localBudget.totalBudget) ||
      proposedValues.maxBid.primaryValue !== +localBudget.maxBid
    ) {
      setShowAdvancedView(false);
    }
    if (shouldPreserveValue) {
      setProposedValues((i: ProposedValues) => ({
        budget: {
          ...i.budget,
          primaryValue: localBudget.totalBudget ? +localBudget.totalBudget : 0,
        },
        impressions: {
          ...i.impressions,
          primaryValue: Number(localBudget?.totalImpressionsBudget),
        },
        maxBid: { ...i.maxBid, primaryValue: +localBudget.maxBid },
      }));
    }
  }, [
    localBudget.maxBid,
    localBudget.totalBudget,
    localBudget.totalImpressionsBudget,
    proposedValues.budget.primaryValue,
    proposedValues.maxBid.primaryValue,
    shouldPreserveValue,
  ]);

  React.useEffect(() => {
    setSliderInfo((i) => ({
      ...i,
      uniqueReach: {
        ...i.uniqueReach,
        range: { min: rangeReachMin, max: rangeReachMax },
      },
    }));
  }, [rangeReachMax, rangeReachMin]);

  React.useEffect(() => {
    setSliderInfo((i) => ({
      ...i,
      totalImpressions: {
        ...i.totalImpressions,
        range: { min: rangeImpressionMin, max: rangeImpressionMax },
      },
    }));
  }, [rangeImpressionMax, rangeImpressionMin]);

  React.useEffect(() => {
    setSliderInfo((i) => ({
      ...i,
      uniqueReach: {
        ...i.uniqueReach,
        maxSliderValue: maximumReach,
      },
    }));
  }, [maximumReach]);

  React.useEffect(() => {
    setSliderInfo((i) => ({
      ...i,
      totalImpressions: {
        ...i.totalImpressions,
        maxSliderValue: maximumImpressions,
      },
    }));
  }, [maximumImpressions]);

  React.useEffect(() => {
    setSliderInfo((i) => ({
      ...i,
      uniqueReach: {
        ...i.uniqueReach,
        slider: shouldPreserveValue ? [reachSliderVal, reachSliderVal] : [prevReach, prevReach],
      },
    }));
  }, [prevReach, reachSliderVal, shouldPreserveValue, localBudget.maxBid]);

  React.useEffect(() => {
    setSliderInfo((i) => ({
      ...i,
      totalImpressions: {
        ...i.totalImpressions,
        slider: shouldPreserveValue
          ? [impressionSliderVal, impressionSliderVal]
          : [prevImpression, prevImpression],
      },
    }));
  }, [impressionSliderVal, prevImpression, shouldPreserveValue]);

  React.useEffect(() => {
    setProposedValues((i: ProposedValues) => ({
      ...i,
      budget: { ...i.budget, secondaryValue: +proposedBudget },
    }));
  }, [proposedBudget, setProposedValues]);

  React.useEffect(() => {
    setProposedValues((i: ProposedValues) => ({
      ...i,
      maxBid: { ...i.maxBid, secondaryValue: proposedMaxBid },
    }));
  }, [proposedMaxBid, setProposedValues]);

  React.useEffect(() => {
    setProposedValues((i: ProposedValues) => ({
      ...i,
      impressions: { ...i.impressions, secondaryValue: proposedBudgetImpressions },
    }));
  }, [proposedBudgetImpressions, proposedMaxBid, setProposedValues]);

  React.useEffect(() => {
    if (!prevTargetableAudience && targetableAudience) {
      setPrevTargetableAudience(targetableAudience);
    }
  }, [prevTargetableAudience, targetableAudience]);

  React.useEffect(() => {
    const currentReach = sliderInfo.uniqueReach.slider[1];
    const currentImpression = Number(proposedBudgetImpressions);
    setFrequencyAtSlider(
      currentImpression && currentReach ? currentImpression / currentReach : undefined,
    );
  }, [proposedBudgetImpressions, sliderInfo.uniqueReach.slider]);

  React.useEffect(() => {
    if (totalBudget || maxBid) {
      currBudgetRef.current = { totalBudget, maxBid };
    }
  }, [maxBid, totalBudget]);

  React.useEffect(() => {
    if (
      (isTargetableAudienceChanged && targetableAudience && targetableAudience < prevReach) ||
      sliderInfo.uniqueReach.slider[0] === sliderInfo.uniqueReach.slider[1] ||
      sliderInfo.totalImpressions.slider[0] === sliderInfo.totalImpressions.slider[1]
    ) {
      updateEstimatorMeta();
    }
  }, [
    isTargetableAudienceChanged,
    prevReach,
    sliderInfo.totalImpressions.slider,
    sliderInfo.uniqueReach.slider,
    targetableAudience,
    updateEstimatorMeta,
  ]);

  React.useEffect(() => {
    if (campaignEstimatorMetaData?.sliderMeta?.seedFrequency && !seedFrequency) {
      setSeedFrequency(campaignEstimatorMetaData?.sliderMeta?.seedFrequency);
    }
  }, [campaignEstimatorMetaData?.sliderMeta?.seedFrequency, seedFrequency]);

  return (
    <Box>
      <Row>
        {Object.values(sliderInfo).map((val) =>
          val.key !== 'totalImpressions' ? (
            <Col md={12} sx={{ mt: 8 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'between',
                }}
              >
                <Typography>{val.label}</Typography>
                <Typography sx={{ textWeight: 'demi' }}>
                  {nFormatter(+(val.range.min * 1000000).toFixed(0), 1)} to{' '}
                  {nFormatter(+(val.range.max * 1000000).toFixed(0), 1)}
                </Typography>
              </Box>
              <Box sx={{ my: 16 }}>
                <Slider
                  values={val.slider}
                  max={val.maxSliderValue}
                  min={
                    (val.key === 'uniqueReach' && reachSliderVal) ||
                    (val.key === 'totalImpressions' && impressionSliderVal) ||
                    0
                  }
                  onChange={(value: any) => {
                    setPrevReach(sliderInfo.uniqueReach.slider[0]);
                    setPrevImpression(sliderInfo.totalImpressions.slider[0]);
                    setSliderInfoCB(val.key as keyof typeof sliderInfo, value);
                  }}
                />
              </Box>
            </Col>
          ) : null,
        )}
      </Row>
      {showAdvancedView ? (
        <>
          <Divider sx={{ mt: 8, mb: 16 }} />
          <Box sx={{ mt: 8 }}>
            <Typography>
              Budget updates required to suit your required reach & impressions
            </Typography>
            <Row sx={{ mt: 16 }}>
              {Object.values(proposedValues).map((val) =>
                val.key !== 'budget' ? (
                  <Col sx={{ mb: 16 }} md={12}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'between',
                      }}
                    >
                      <TextField
                        type="text"
                        label={val.labelPrimary}
                        value={val.primaryValue}
                        variant="outlinedDash"
                        className={styles.textFieldPrimary}
                        InputProps={{
                          classes: {
                            root: sx({ borderRadius: 'all', bgColor: 'neutral-75', border: 0 }),
                          },
                          readOnly: true,
                          startAdornment: val.key === 'impressions' ? 'Imps' : '$',
                        }}
                      />
                      <ArrowRightLong fontSize={20} sx={{ mt: 16, mx: 2 }} color="primary" />
                      <TextField
                        type="text"
                        label={val.labelSecondary}
                        value={val.secondaryValue}
                        onBlur={checkProposedValuesValidation}
                        InputProps={{
                          classes: {
                            root: `${sx({ bgColor: 'primary-50', borderRadius: 'all' })} ${
                              styles.textFieldSecondaryBeta
                            }`,
                          },
                          className: styles.numberInput,
                          startAdornment: val.key === 'impressions' ? 'Imps' : '$',
                        }}
                        onChange={(e: any) =>
                          setProposedValue(val.key as keyof typeof proposedValues, e)
                        }
                      />
                    </Box>
                  </Col>
                ) : null,
              )}
            </Row>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', mt: 8 }}>
              <Button
                variant="text"
                onClick={resetWidget}
                {...{ 'data-dd-action-name': 'Reset Reach & Impressions' }}
              >
                Reset
              </Button>
              <Button
                onClick={() => {
                  scrollToElement();
                  setShouldUpdateReach(true);
                  setCampaignSidebarInfo('maxBid', String(proposedValues.maxBid.secondaryValue));
                  changeBidField({
                    key: 'impressionsMaxTotal',
                    value: proposedValues.impressions.secondaryValue,
                  });
                  if (targetableAudience) {
                    setPrevTargetableAudience(+targetableAudience);
                  }
                  setIsSliderUpdated(true);
                  setSeedFrequency(frequencyAtSlider);
                  setProposedValues((i) => ({
                    budget: { ...i.budget, primaryValue: proposedValues.budget.secondaryValue },
                    impressions: {
                      ...i.impressions,
                      primaryValue: proposedValues.impressions.secondaryValue,
                    },
                    maxBid: { ...i.maxBid, primaryValue: proposedValues.maxBid.secondaryValue },
                  }));
                  if (
                    proposedValues.maxBid.secondaryValue !== proposedMaxBid ||
                    proposedValues.budget.secondaryValue !== +proposedBudget
                  ) {
                    setPrevReach(0);
                    setPrevImpression(0);
                  } else {
                    setPrevReach(sliderInfo.uniqueReach.slider[1]);
                    setPrevImpression(sliderInfo.totalImpressions.slider[1]);
                  }
                  setPrevProposed({
                    maxBid: +proposedValues.maxBid.secondaryValue,
                    totalBudget: +proposedValues.budget.secondaryValue!,
                    totalImpressionsBudget: +proposedValues.impressions.secondaryValue,
                  });
                  setPrevBudget({
                    maxBid,
                    totalBudget,
                    totalImpressionsBudget,
                  });
                  if (
                    proposedValues.budget.primaryValue === +proposedValues.budget.secondaryValue &&
                    proposedValues.maxBid.primaryValue === +proposedValues.maxBid.secondaryValue
                  ) {
                    resetWidget();
                  } else {
                    setShowAdvancedView(false);
                  }
                }}
                variant="outlined"
                sx={{ ml: 8 }}
                disabled={
                  proposedValues.impressions.primaryValue >
                    proposedValues.impressions.secondaryValue ||
                  proposedValues.maxBid.primaryValue > proposedValues.maxBid.secondaryValue
                }
                {...{ 'data-dd-action-name': 'Update Reach & Impressions' }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
const mapState = (state: AppState) => ({
  campaignEstimatorMetaData: state.app?.editableCampaign?.campaignEstimatorMetaData,
});

const mapActions = {
  setEstimatorMetaData: estimatorActions.setEstimatorMetaData,
  changeBidField: bidActions.changeBidField,
};

export const Reach = connect(mapState, mapActions)(ReachComponent);
