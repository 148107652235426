import React from 'react';
import { BaseTextFieldProps, Box, TextField, Tooltip, Typography } from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import Papa, { ParseResult } from 'papaparse';
import { UploadFile } from 'utils/UploadFile';

export interface TextAreaWithCSVUploadProps {
  label: string;
  tooltipMessage: string;
  value: string;
  placeholder: string;
  handleChange: (val: any) => any;
  maxRows?: number;
  minRows?: number;
  textfieldProps?: Partial<BaseTextFieldProps<any>>;
}

export const TextAreaWithCSVUpload = (props: TextAreaWithCSVUploadProps) => {
  const {
    label,
    tooltipMessage,
    value,
    handleChange,
    placeholder,
    maxRows = 15,
    minRows = 15,
    textfieldProps,
  } = props;

  const setValue = (val: string) => {
    handleChange(val.replace(/\s*(?:,|\n)\s*|\n/g, ','));
  };

  const handleFileUpload = (files: FileList) => {
    const fileExtension = files[0].name.split('.').splice(-1)[0];
    if (fileExtension === 'csv')
      Papa.parse(files[0], {
        complete: (results: ParseResult<string[]>) => {
          const val = results.data.flat().join();
          setValue(val);
        },
      });
  };

  const displayValue = React.useMemo(() => {
    return value.replace(/\s*(?:,|\n)\s*|\n/g, '\n');
  }, [value]);

  const MAXIUM_ALLOWED_ITEAMS = 500;
  const isError = value.split(',').filter(Boolean).length > MAXIUM_ALLOWED_ITEAMS;

  return (
    <Box
      sx={{
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: 16,
        p: 8,
      }}
    >
      {/* @ts-ignore */}
      <TextField
        label={
          <Box sx={{ display: 'flex', justifyContent: 'between' }}>
            <Typography>{label}</Typography>
            <Typography sx={{ textColor: isError ? 'danger-500' : 'neutral-1000' }}>
              {value.split(',').filter(Boolean).length}/500
            </Typography>
          </Box>
        }
        placeholder={placeholder}
        multiline
        minRows={maxRows}
        maxRows={minRows}
        fullWidth
        value={displayValue}
        error={isError}
        helperText={isError ? 'Maximum 500 records are allowed from campaign app' : ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setValue(e.target.value)
        }
        // eslint-disable-next-line
        {...textfieldProps}
      />
      <Box sx={{ display: 'flex', justifyContent: 'between', alignItems: 'center', width: 100 }}>
        <UploadFile
          uploadBtnVariant="outlined"
          uploadBtnText="CSV"
          fileTypes={['csv']}
          onUpload={handleFileUpload}
        />
        <Tooltip title={tooltipMessage ?? 'dsafdsafa'} placement="top" arrow>
          <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400' }} />
        </Tooltip>
      </Box>
    </Box>
  );
};
