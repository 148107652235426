import React, { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import Papa, { ParseResult } from 'papaparse';

import { DataDogLogger } from 'services/DataDog';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { advanceActions, SetZipcodeLimitError } from 'store/advance/actions';
import { Limits } from 'store/advance/reducer';
import { getZipCodeData } from 'api/List';
import { UploadWithText } from '../UploadWithText';
import { TextAreaBlock } from '../TextAreaBlock';

interface Styles {
  [key: string]: string;
}

interface Props extends SetZipcodeLimitError {
  styles: Styles;
  extensionError: string | JSX.Element;
  setExtensionError: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  isSelectedCountryUSA: () => boolean;
  changed: (value: string | Option[]) => void;
  zipcodes: Option[];
  limits?: Limits;
}

const UploadZipCodeComponent = ({
  extensionError,
  setExtensionError,
  isSelectedCountryUSA,
  styles,
  changed,
  zipcodes,
  setZipcodeLimitError,
  limits,
}: Props) => {
  const [texAreaZipcodes, setTexAreaZipcodes] = useState('');
  const zipCodeFileLimits = limits?.zipCodes || 1000;

  const fileUploadCsvZipCodesHandler = (file: FileList, cb: (data: string[][]) => void) => {
    const fileExtension = file[0].name.split('.').splice(-1)[0];

    if (fileExtension === 'csv') {
      Papa.parse(file[0], {
        complete: (results: ParseResult<string[]>) => {
          if (results.data[0].length > 1) {
            DataDogLogger.Location.uploadLocationFile({
              locationType: 'zipcodes',
              success: false,
              errorMsg: 'Multi-column csv',
              file: file[0],
            });
            setExtensionError(
              'You should use a one-column table. Please check the Sample File above',
            );
            return;
          }
          const filteredRows: string[][] = results.data.reduce((result: any, row: any) => {
            let value = row[0];
            value = value.replace(/\t/g, '').replace(/[^A-Za-z0-9,\s-]/g, '');
            if (value) {
              result.push(row);
            }
            return result;
          }, []);
          if (filteredRows.length <= zipCodeFileLimits) {
            cb(filteredRows);
            DataDogLogger.Location.uploadLocationFile({
              locationType: 'zipcodes',
              success: true,
              file: file[0],
              validLocations: filteredRows?.length || 0,
            });
          } else {
            setExtensionError(`Maximum ${zipCodeFileLimits} records are allowed. Please try again`);
          }
        },
      });
      setExtensionError('');
    } else {
      DataDogLogger.Location.uploadLocationFile({
        locationType: 'zipcodes',
        success: false,
        errorMsg: 'Disallowed file extension',
        file: file[0],
      });
      setExtensionError('Only CSV files are available for upload');
    }
  };

  const setZipCodeData = async (zipCodeArray: string[]) => {
    if (zipCodeArray.length) {
      const data = await getZipCodeData({
        zipCodes: zipCodeArray,
        sortBy: '+id',
        pageNo: -1,
      });
      const zipCodeData = zipCodeArray.map((item) => {
        const currentItem = data.find((zipData) => zipData.label === item);
        return {
          label: item,
          value: item,
          ...(currentItem?.geojsonUrl ? { geojsonUrl: currentItem.geojsonUrl } : {}),
          ...(currentItem?.parentId ? { parentId: currentItem.parentId } : {}),
        };
      });
      changed([...zipCodeData, ...zipcodes]);
    }
  };

  const saveCsvZipCodesToStore = async (uploadedCsvZipcodes: string[][]) => {
    const previouslyAddedZips = zipcodes.map((item) => item.label);
    const newCsvZipcodes = uploadedCsvZipcodes
      .map((i) => ({ label: i[0], value: i[0] }))
      .filter((item) => !previouslyAddedZips.includes(item.label));
    const zipSet = new Set();
    const uniqueCodes = newCsvZipcodes.filter((one) => {
      if (zipSet.has(one.value)) {
        return false;
      }
      zipSet.add(one.value);
      return true;
    });
    setZipCodeData(uniqueCodes.map((item) => item.label));
  };

  useEffect(() => {
    if (limits?.zipCodes && zipcodes.length > limits?.zipCodes) {
      setZipcodeLimitError(
        `You can only add upto ${limits?.zipCodes} ${
          isSelectedCountryUSA() ? 'Zip' : 'Postal'
        } Codes. Please remove additional  ${isSelectedCountryUSA() ? 'Zip' : 'Postal'}
      Codes`,
      );
    } else {
      setZipcodeLimitError('');
    }
  }, [zipcodes, setZipcodeLimitError, isSelectedCountryUSA, limits]);

  const uploadZipCodes = (data: any) => {
    fileUploadCsvZipCodesHandler(data, saveCsvZipCodesToStore);
  };

  const addZipCodes = (str: string) => {
    if (str) {
      const strData = str
        .replace(/\n/g, ',')
        .replace(/\t/g, '')
        .replace(/[^A-Za-z0-9,\s-]/g, '');
      if (!strData.length) {
        return;
      }
      const typedZipCodes = strData
        .split(',')
        .filter((i) => i.trim().length > 0)
        .map((i) => [i.trim()]);
      if (typedZipCodes.length <= zipCodeFileLimits) {
        saveCsvZipCodesToStore(typedZipCodes);
        setTexAreaZipcodes('');
      } else {
        setExtensionError(`Maximum ${zipCodeFileLimits} records are allowed. Please try again`);
      }
    }
  };
  return (
    <>
      <div className={styles.tabLeftPortion}>
        <div className={styles.headerText}>{isSelectedCountryUSA() ? 'Zip' : 'Postal'} Codes</div>
        <TextAreaBlock
          value={texAreaZipcodes}
          onChanged={setTexAreaZipcodes}
          label=""
          placeholder={`Enter ${isSelectedCountryUSA() ? 'Zip' : 'Postal'} Codes`}
          id="zip"
          onBtnClick={addZipCodes}
        />
      </div>
      <div className={styles.tabRightPortion}>
        <div className={styles.headerText}>Upload CSV File</div>
        <UploadWithText
          helpText={`Upload multiple ${
            isSelectedCountryUSA() ? 'Zip' : 'Postal'
          } codes from a file. Uploaded ${
            isSelectedCountryUSA() ? 'Zip' : 'Postal'
          } codes will be listed on the right.`}
          sampleFileURL={`${process.env.PUBLIC_URL}/example-files/${'bulk-zipcode-example.csv'}`}
          id="zipCodes"
          onFileUploaded={uploadZipCodes}
          errorMessage={extensionError}
          downHelpText={`Only CSV format supported with maximum ${zipCodeFileLimits} records`}
        />
      </div>
    </>
  );
};

const mapState = createSelector(
  (state: AppState) => state.advanced.sidebarCampaignInfo,
  (state: AppState) => state.advanced.limits,
  (sidebarCampaignInfo, limits) => ({
    limits,
    zipcodes: sidebarCampaignInfo[CampaignInfoField.zipcodes],
  }),
);

const mapAction = {
  setZipcodeLimitError: advanceActions.setZipcodeLimitError,
};

export const UploadZipCodeWrapper = connect(mapState, mapAction)(UploadZipCodeComponent);
