import * as React from 'react';
import { Menu, sx, MenuItem, Box, Typography, SxProps, Tooltip } from '@applift/factor';

export interface ButtonMenuPropsType {
  buttons: any[];
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  menuItemSxProps?: SxProps;
}

export const ButtonMenu = (props: ButtonMenuPropsType) => {
  const { anchorEl, buttons, onClose, open, menuItemSxProps } = props;

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'ColumnMenuButton',
      }}
      sx={{ width: 100, marginTop: 8 }}
      classes={{
        list: sx({
          border: 1,
          borderColor: 'primary-600',
          borderRadius: 4,
        }),
      }}
    >
      {buttons.map((val) => (
        <Tooltip placement="bottom" arrow title={val.tooltipTitle}>
          <Box hidden={val.hidden}>
            <MenuItem
              key={val.label}
              onClick={val?.onClick}
              disabled={val?.disabled}
              sx={menuItemSxProps ?? undefined}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
                {val.icon}
                <Typography
                  component="p"
                  variant="p"
                  gutterBottom={false}
                  sx={{ width: 100, height: 100, mx: 8 }}
                >
                  {val.label}
                </Typography>
              </Box>
            </MenuItem>
          </Box>
        </Tooltip>
      ))}
    </Menu>
  );
};
