import React, { useRef } from 'react';
import { Box, Col, Link, Paper, Skeleton, Tooltip, Typography } from '@applift/factor';
import { InfoCircle, SemanticWarning } from '@applift/icons';
import { connect } from 'react-redux';

import { useTargatableAudience } from 'hooks/useTargatableAudience';
import { nFormatter } from 'utils/format';
import { useBidLandscape } from 'hooks/useBidLandscape';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { AppState } from 'models/Store';
import { EstimatorState } from 'store/estimator/reducer';

import { advanceActions } from 'store/advance/actions';
import { CampaignEstimatorParams } from 'models/Estimator';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { DataDogLogger } from 'services/DataDog';
import { TargetableAudience } from './TargetableAudience';
import { BidLandscape } from './BidLandscape';
import { WidgetError } from './WidgetError';
import { KNOW_MORE_URL } from './WarningAlert';
import BidLandscapeLoading from '../../../image/bid-landscape-loading.gif';
import { ReachImpressionWrapper } from './ReachImpressionWrapper';

export interface EstimatorWidgetsInfoType {
  key: 'targetableAudience' | 'reachAndImpressions' | 'bidLandscape';
  header: string;
  tooltipInfo?: { header: string; body: string; link: string };
  footer?: JSX.Element | null;
  component?: JSX.Element;
  hide?: boolean;
  stash?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isTimeout?: boolean;
  isStale?: boolean;
  staleMessage: string;
  refetch?: () => void;
  onRefetch?: (errorType: string) => void;
}

export interface Props extends EstimatorState {
  isEstimatorAvailable?: boolean;
  maxBid: string;
  totalBudget?: string;
  startDate?: number;
  endDate?: number | null;
  targatebleAudiencePayload?: CampaignEstimatorParams | {};
  bidLandscapePayload?: CampaignEstimatorParams | {};
  budgetTypeId?: number;
  totalImpressions?: string;
  stale: EstimatorState['stale'];
  shouldUpdateReach: boolean;
  setShouldUpdateReach: (arg: boolean) => void;
  setCampaignSidebarInfo: (key: string, value: string) => void;
}

const rangeCreator = (min: number | undefined, max: number | undefined) => {
  const minFormatedVal = nFormatter(min || 0, 2);
  const maxFormatedVal = nFormatter(max || 0, 2);
  if (minFormatedVal === maxFormatedVal) {
    return minFormatedVal;
  }
  return `${minFormatedVal} - ${maxFormatedVal}`;
};

interface KeyHeader {
  key: 'targetableAudience' | 'bidLandscape' | 'reachAndImpressions';
  header: string;
}

const TARGETABLE_AUDIENCE: KeyHeader = {
  key: 'targetableAudience',
  header: 'Targetable Audience ',
};

const BID_LANDSCAPE: KeyHeader = {
  key: 'bidLandscape',
  header: 'Bid Landscape ',
};

const isTimeoutError = (error: any) => {
  return (
    error &&
    error.code === 'ECONNABORTED' &&
    // @ts-ignore
    error.message?.includes?.('timeout')
  );
};

export const EstimatorWrapperComponent = (props: Props) => {
  const {
    maxBid,
    isEstimatorAvailable,
    estimatorKeys,
    endDate,
    startDate,
    totalBudget,
    setCampaignSidebarInfo,
    targatebleAudiencePayload,
    bidLandscapePayload,
    budgetTypeId,
    totalImpressions,
    stale,
    shouldUpdateReach,
    setShouldUpdateReach,
  } = props;

  const {
    data: targatableAudience,
    isError: isTargetableAudienceError,
    isFetching: isTargetableAudienceLoading,
    refetch: fetchTargetableAudience,
    error: targatableAudienceError,
  } = useTargatableAudience({
    enabled: isEstimatorAvailable !== false && estimatorKeys?.countryIds === '30100001',
  });

  const [reachImpressionProps, setReachImpressionProps] = React.useState<any>();
  const [prevProposedBudget, setPrevProposedBudget] = React.useState({
    maxBid,
    totalImpressions,
    totalBudget,
  });

  const prevReach = useRef<number>();

  const isPrevBudgetSameAsCurrent = React.useMemo(() => {
    if (prevProposedBudget.maxBid === '' || prevProposedBudget.totalBudget === '') {
      return true;
    }
    if (budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) {
      return (
        prevProposedBudget.maxBid === '' ||
        prevProposedBudget.totalBudget === '' ||
        Number(prevProposedBudget.maxBid) === Number(maxBid) ||
        Number(totalBudget) === Number(prevProposedBudget.totalBudget)
      );
    }
    if (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return (
        prevProposedBudget.maxBid === '' ||
        prevProposedBudget.totalImpressions === '' ||
        Number(prevProposedBudget.maxBid) === Number(maxBid) ||
        Number(totalImpressions) === Number(prevProposedBudget.totalImpressions)
      );
    }
    return true;
  }, [
    budgetTypeId,
    maxBid,
    prevProposedBudget.maxBid,
    prevProposedBudget.totalBudget,
    prevProposedBudget.totalImpressions,
    totalBudget,
    totalImpressions,
  ]);

  const REACH_AND_IMPRESSIONS: KeyHeader = React.useMemo(
    () => ({
      key: 'reachAndImpressions',
      header: budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'Reach & Impressions ' : 'Reach ',
    }),
    [budgetTypeId],
  );

  const {
    data: bidLandscape,
    isFetching: isBidLandscapeFetching,
    isError: isBidLandscapeError,
    refetch: fetchBidLandscape,
    error: bidLandScapeError,
  } = useBidLandscape({
    enabled: isEstimatorAvailable !== false && estimatorKeys?.countryIds === '30100001',
  });

  React.useEffect(() => {
    if (isTimeoutError(bidLandScapeError)) {
      DataDogLogger.Estimator.timeout(BID_LANDSCAPE.key, BID_LANDSCAPE.header);
    }
  }, [bidLandScapeError]);

  React.useEffect(() => {
    if (isTimeoutError(targatableAudienceError)) {
      DataDogLogger.Estimator.timeout(TARGETABLE_AUDIENCE.key, TARGETABLE_AUDIENCE.header);
    }
  }, [targatableAudienceError]);

  const timeoutError = React.useMemo(() => {
    const result = {
      bidlandscapeTimeout: false,
      targatableAudienceTimeout: false,
    };
    if (isTimeoutError(bidLandScapeError)) {
      result.bidlandscapeTimeout = true;
    }
    if (isTimeoutError(targatableAudienceError)) {
      result.targatableAudienceTimeout = true;
    }
    return result;
  }, [bidLandScapeError, targatableAudienceError]);

  const estimatorWidgetsInfo: EstimatorWidgetsInfoType[] = React.useMemo(
    () => [
      {
        ...TARGETABLE_AUDIENCE,
        tooltipInfo: {
          header: 'Targetable Audience',
          body: 'This widget displays estimated size based on your campaign targeting parameters.',
          link: 'https:iqm.com',
        },
        component: (
          <TargetableAudience
            data={targatableAudience?.data}
            isLoading={isTargetableAudienceLoading}
          />
        ),
        footer: Object.keys(targatebleAudiencePayload as object).length ? (
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography component="span" sx={{ mr: 4 }}>
              Targetable audience:{' '}
              {!isTargetableAudienceLoading ? (
                <Typography component="span" sx={{ textWeight: 'demi' }}>
                  {(targatableAudience?.data?.estimatedReachAvg as number) <= 10
                    ? 'Limited'
                    : rangeCreator(
                        targatableAudience?.data?.estimatedReachMin,
                        targatableAudience?.data?.estimatedReachMax,
                      )}
                </Typography>
              ) : null}
            </Typography>
            {isTargetableAudienceLoading ? <Skeleton sx={{ width: 25 }} /> : null}
          </Box>
        ) : null,
        hide: isEstimatorAvailable === false || estimatorKeys?.countryIds !== '30100001',
        stash: !Object.keys(targatebleAudiencePayload as object).length,
        isError: isTargetableAudienceError || targatableAudience?.success === false,
        refetch: fetchTargetableAudience,
        isLoading: isTargetableAudienceLoading,
        isTimeout:
          // @ts-ignore
          timeoutError.targatableAudienceTimeout || targatableAudienceError?.request.status === 408,
        onRefetch: (errorType: string) =>
          DataDogLogger.Estimator.refresh(
            TARGETABLE_AUDIENCE.key,
            TARGETABLE_AUDIENCE.header,
            errorType,
          ),
        isStale: stale?.targatableAudience,
        staleMessage: 'Click on "Run Campaign Estimations" to see the most recent estimations.',
      },
      {
        ...REACH_AND_IMPRESSIONS,
        tooltipInfo: {
          header: budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'Reach & Impressions' : 'Reach',
          body:
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
              ? 'This widget displays estimated reach and impressions based on your campaign targeting parameters.'
              : 'This widget displays estimated reach based on your campaign targeting parameters.',
          link: 'https:iqm.com',
        },
        component: (
          <ReachImpressionWrapper
            maxBid={reachImpressionProps?.maxBid}
            totalBudget={reachImpressionProps?.totalBudget}
            totalImpressions={reachImpressionProps?.totalImpressions}
            reach={reachImpressionProps?.reach}
            targetableAudience={reachImpressionProps?.targetableAudience}
            setCampaignSidebarInfo={setCampaignSidebarInfo}
            startDate={reachImpressionProps?.startDate}
            endDate={reachImpressionProps?.endDate}
            budgetTypeId={budgetTypeId}
            setShouldUpdateReach={setShouldUpdateReach}
            setPrevProposedBudget={setPrevProposedBudget}
          />
        ),
        stash:
          isEstimatorAvailable === false ||
          estimatorKeys?.countryIds !== '30100001' ||
          !Math.abs(reachImpressionProps?.maxBid) ||
          !Math.abs(reachImpressionProps?.totalBudget) ||
          !reachImpressionProps?.startDate ||
          !reachImpressionProps?.endDate ||
          !targatableAudience?.data?.estimatedReachAvg ||
          !Object.keys(targatebleAudiencePayload as object).length,
        hide:
          isEstimatorAvailable === false ||
          estimatorKeys?.countryIds !== '30100001' ||
          Number(targatableAudience?.data?.estimatedReachAvg) <= 10 ||
          isTargetableAudienceError,
        onRefetch: (errorType: string) =>
          DataDogLogger.Estimator.refresh(
            REACH_AND_IMPRESSIONS.key,
            REACH_AND_IMPRESSIONS.header,
            errorType,
          ),
        isStale:
          Number(maxBid) !== Number(reachImpressionProps?.maxBid) ||
          !isPrevBudgetSameAsCurrent ||
          (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
            Number(totalImpressions) !== Number(reachImpressionProps?.totalImpressions)) ||
          (budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED &&
            Number(totalBudget) !== Number(reachImpressionProps?.totalBudget)) ||
          startDate !== reachImpressionProps?.startDate ||
          endDate !== reachImpressionProps?.endDate ||
          !targatableAudience?.data?.estimatedReachAvg,
        staleMessage:
          'Kindly input the total budget, max bid, start date, and end date, then click on "Run Campaign Estimations" to view the latest estimations.',
      },
      {
        ...BID_LANDSCAPE,
        tooltipInfo: {
          header: 'Bid Landscape',
          body: 'Bid Landscape forecasts the number of impressions advertisers can expect to win at different bid prices',
          link: 'https:iqm.com',
        },
        component: isBidLandscapeFetching ? (
          <Box
            sx={{
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ aspectRatio: '1/1' }}
          >
            <img style={{ width: '100%' }} src={BidLandscapeLoading} alt="Loading" />
          </Box>
        ) : (
          <BidLandscape data={bidLandscape?.data} />
        ),
        hide: isEstimatorAvailable === false || estimatorKeys?.countryIds !== '30100001',
        stash: !Object.keys(bidLandscapePayload as object).length,
        isError: isBidLandscapeError || bidLandscape?.success === false,
        refetch: fetchBidLandscape,
        isLoading: isBidLandscapeFetching,
        // @ts-ignore
        isTimeout: timeoutError.bidlandscapeTimeout || bidLandScapeError?.request.status === 408,
        onRefetch: (errorType: string) =>
          DataDogLogger.Estimator.refresh(BID_LANDSCAPE.key, BID_LANDSCAPE.header, errorType),
        isStale: stale?.bidLandscape,
        staleMessage: 'Click on "Run Campaign Estimations" to see the most recent estimations.',
      },
    ],
    [
      targatableAudience?.data,
      targatableAudience?.success,
      isTargetableAudienceLoading,
      isEstimatorAvailable,
      estimatorKeys?.countryIds,
      targatebleAudiencePayload,
      isTargetableAudienceError,
      fetchTargetableAudience,
      timeoutError.targatableAudienceTimeout,
      timeoutError.bidlandscapeTimeout,
      targatableAudienceError,
      stale?.targatableAudience,
      stale?.bidLandscape,
      REACH_AND_IMPRESSIONS,
      budgetTypeId,
      reachImpressionProps,
      setCampaignSidebarInfo,
      setShouldUpdateReach,
      maxBid,
      totalBudget,
      startDate,
      endDate,
      isPrevBudgetSameAsCurrent,
      totalImpressions,
      isBidLandscapeFetching,
      bidLandscape?.data,
      bidLandscape?.success,
      bidLandscapePayload,
      isBidLandscapeError,
      fetchBidLandscape,
      bidLandScapeError,
    ],
  );

  React.useEffect(() => {
    if (shouldUpdateReach && maxBid && (totalBudget || totalImpressions) && endDate && startDate) {
      prevReach.current = targatableAudience?.data?.estimatedReachAvg;
      setReachImpressionProps({
        maxBid: maxBid ?? 0,
        totalBudget,
        totalImpressions,
        reach: targatableAudience?.data?.estimatedReachAvg,
        targetableAudience: targatableAudience?.data?.estimatedReachAvg,
        startDate,
        endDate: endDate as number,
        budgetTypeId,
      });
      setPrevProposedBudget({ totalBudget, totalImpressions, maxBid });
      setShouldUpdateReach(false);
    } else if (Object.keys(reachImpressionProps || {}).length && !prevReach.current) {
      setShouldUpdateReach(true);
    } else {
      setShouldUpdateReach(false);
    }
  }, [
    budgetTypeId,
    endDate,
    maxBid,
    reachImpressionProps,
    setCampaignSidebarInfo,
    setShouldUpdateReach,
    shouldUpdateReach,
    startDate,
    targatableAudience,
    targatableAudience?.data?.estimatedReachAvg,
    totalBudget,
    totalImpressions,
  ]);

  const renderWidget = React.useCallback((value: EstimatorWidgetsInfoType) => {
    if (value.isTimeout && !value.isLoading) {
      return (
        <WidgetError
          widgetTitle={value.header}
          errorType="Timeout"
          refetch={value.refetch}
          onRefetch={value.onRefetch}
        />
      );
    }
    if (value.isError && !value.isLoading) {
      return (
        <WidgetError
          widgetTitle={value.header}
          errorType="Server"
          refetch={value.refetch}
          onRefetch={value.onRefetch}
        />
      );
    }
    return value.component;
  }, []);

  return (
    <>
      {estimatorWidgetsInfo.map((value) => (
        <Col key={value.key} sx={{ mb: 12 }}>
          <Paper elevation={2}>
            {!value.hide ? (
              <>
                <Box
                  sx={{
                    p: 16,
                    ...(value.key === 'targetableAudience' && !value.hide ? { pb: 0 } : null),
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      mb: 12,
                    }}
                  >
                    <Typography
                      variant="bodySmall"
                      sx={{ textWeight: 'demi', mr: 2 }}
                      gutterBottom={false}
                    >
                      {value.header}
                    </Typography>
                    <Tooltip
                      title={
                        <Box>
                          <Typography sx={{ display: 'block', mb: 8, textWeight: 'demi' }}>
                            {value.tooltipInfo?.header}
                          </Typography>
                          <Typography sx={{ display: 'block', mb: 4 }}>
                            {value.tooltipInfo?.body}
                          </Typography>
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => window.open(KNOW_MORE_URL, '__blank')}
                          >
                            Know More
                          </Link>
                        </Box>
                      }
                      placement="top-end"
                      arrow
                      leaveDelay={100}
                    >
                      <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400' }} />
                    </Tooltip>
                    {value.isStale && !value.stash ? (
                      <Tooltip
                        title={
                          'The existing estimation data is outdated. Please click on "Run Campaign Estimations" to access the most recent estimations.'
                        }
                        placement="top-end"
                        arrow
                        leaveDelay={100}
                      >
                        <SemanticWarning color="warning" fontSize={24} />
                      </Tooltip>
                    ) : null}
                  </Box>
                  {value.stash ? (
                    <Box sx={{ ...(value.key === 'targetableAudience' ? { pb: 12 } : { pb: 0 }) }}>
                      <Typography
                        sx={{ textColor: 'neutral-600' }}
                        variant="label"
                        lineHeight="single-line"
                      >
                        {value.staleMessage}
                      </Typography>
                    </Box>
                  ) : (
                    renderWidget(value)
                  )}
                </Box>
                {value.footer && !value.isTimeout && !value.isError && !value.hide ? (
                  <Box sx={{ mt: 12, borderTop: 1, p: 16 }}>{value.footer}</Box>
                ) : null}
              </>
            ) : null}
          </Paper>
        </Col>
      ))}
    </>
  );
};

const mapState = (state: AppState) => {
  return {
    maxBid: state.advanced.sidebarCampaignInfo[CampaignInfoField.maxBid],
    totalBudget: state.advanced.sidebarCampaignInfo[CampaignInfoField.totalBudget],
    totalImpressions: state.bid.impressionsMaxTotal,
    startDate: state.advanced.sidebarCampaignInfo[CampaignInfoField.start],
    endDate: state.advanced.sidebarCampaignInfo[CampaignInfoField.end],
    isEstimatorAvailable: state.app.editableCampaign?.isEstimatorAvailable,
    targatebleAudiencePayload: state.estimator.targatableAudience,
    bidLandscapePayload: state.estimator.bidLandscape,
    budgetTypeId: state.app.budgetTypeId,
    stale: state.estimator.stale,
  };
};
const mapAction = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
};

export const EstimatorWrapper = connect(mapState, mapAction)(EstimatorWrapperComponent);
