import { createAction } from '../../utils/actions';
import { toastConstants } from './constants';
import { ToastState } from './reducer';

export const toastActions = {
  open(message: ToastState['message']) {
    return createAction(toastConstants.TOAST_OPEN, message);
  },
  close() {
    return createAction(toastConstants.TOAST_CLOSE);
  },
};

export interface Open {
  open: (message: string | JSX.Element) => void;
}
