import * as React from 'react';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { IconButton } from 'components/IconButton';
import { ConversionType } from 'models/Conversion';
import styles from './styles.module.scss';

interface Props {
  setInitTableConversionIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ConversionTypeSelection = (props: Props) => {
  const { setInitTableConversionIds } = props;

  const [conversionType, setConversionType] = useStore((state: StoreFilter) => [
    state.conversionBlock.conversionType,
    state.conversionBlock.setConversionType,
  ]);

  const handleClick = (selected: ConversionType) => () => {
    if (!!selected && selected === conversionType) {
      setConversionType(ConversionType.NONE);
    } else {
      setConversionType(selected);
    }
    setInitTableConversionIds([]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>What type of conversion do you want to add?</div>
        <div className={styles.subtitle}>
          For mobile app based conversions use post-back conversion only
        </div>
      </div>
      <div className={`${styles.buttons} ${!conversionType ? styles.buttonsMarginBottom : ''}`}>
        <IconButton
          onClick={handleClick(ConversionType.PIXEL)}
          label="Pixel"
          iconName="EventsPixel"
          isSelected={conversionType === ConversionType.PIXEL}
        />
        <IconButton
          onClick={handleClick(ConversionType.POSTBACK)}
          label="Postback"
          iconName="EventsPostBack"
          isSelected={conversionType === ConversionType.POSTBACK}
        />
      </div>
    </div>
  );
};
