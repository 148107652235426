import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { audienceConstants } from 'store/audience/constants';
import { inventoryConstants } from 'store/inventory/constants';
import { conversionConstants } from 'store/conversion/constants';
import { schedulingConstants } from 'store/scheduling/constants';
import { bidConstants } from 'store/bid/constants';
import { technologyConstants } from 'store/technology/constants';
import { advancedTargetingConstants } from 'store/advancedTargeting/constants';
import { estimatorActions } from 'store/estimator/actions';
import { AppState } from 'models/Store';
import { collectRequestEstimator } from 'store/app/helpers';
import { CampaignEstimatorParams } from 'models/Estimator';
import { locationConstants } from 'store/location/constants';
import { fetchAdvertisersSaga, createAdvertiserSaga } from './advertiser';
import {
  resetStoreSaga,
  createCampaignSaga,
  createCampaignWithAdvertiser,
  fillCampaignForm,
  saveCampaignAsDraftSaga,
  resetStoreSagaForCampaignSwitch,
} from './application';
import { fetchCreativesSaga } from './creatives';
import { fetchPublishers } from './publishers';
import { fetchReachSaga, reachParameterChangeSaga } from './reach';
import { centerMap } from './map';
import { advertiserConstants } from '../advertiser/constants';
import { applicationConstants } from '../app/constants';
import { demographicConstants } from '../demographic/constants';
import { advanceConstants } from '../advance/constants';
import { smsConstants } from '../sms/constants';
import { creativesConstants } from '../creatives/constants';
import { publishersConstants } from '../publishers/constants';
import { reachConstants } from '../reach/constants';
import { mapConstants } from '../map/constants';
import { authConstants } from '../auth2/constants';
import { fetchDetailedExchanges } from './exchanges';
import { fetchTrafficTypes } from './technology';

export function* watchApp() {
  yield takeLatest(applicationConstants.START_RESET_STORE, resetStoreSaga);
  yield takeLatest(
    applicationConstants.START_RESET_STORE_CAMPAIGN_SWITCH,
    resetStoreSagaForCampaignSwitch,
  );
  yield takeLatest(applicationConstants.START_CREATING, createCampaignSaga);
  yield takeLatest(applicationConstants.START_SAVING_CAMPAIGN_AS_DRAFT, saveCampaignAsDraftSaga);
  yield takeLatest(applicationConstants.FILL_APPLICATION_STORE, fillCampaignForm);
  yield takeEvery(
    applicationConstants.CREATE_CAMPAIGN_WITH_ADVERTISER,
    createCampaignWithAdvertiser,
  );
}

export function* watchAdvertiser() {
  yield takeEvery(advertiserConstants.GET_ADVERTISER_DATA, fetchAdvertisersSaga);
  yield takeEvery(advertiserConstants.START_ADVERTISER_CREATING, createAdvertiserSaga);
}

export function* watchCreatives() {
  yield takeEvery(creativesConstants.FETCH_CREATIVES, fetchCreativesSaga);
}

export function* watchPublishers() {
  yield takeEvery(publishersConstants.FETCH_PUBLISHERS, fetchPublishers);
}

export function* watchExchanges() {
  yield takeEvery(advancedTargetingConstants.SELECT_EXCHANGES, fetchDetailedExchanges);
}

export function* watchTrafficTypes() {
  yield takeLatest(
    [authConstants.LOGIN_SUCCESS, technologyConstants.RESET_TECHNOLOGY_STORE],
    fetchTrafficTypes,
  );
}

export function* watchReachParameters() {
  yield takeEvery(
    [
      smsConstants.SIDEBAR_CAMPAIGN_INFO__SET,
      advanceConstants.SIDEBAR_CAMPAIGN_INFO__SET,
      advanceConstants.SET_WHITE_LIST,
      demographicConstants.SELECT_DEMOGRAPHIC_FIELD,
      mapConstants.RESET_MAP_STORE,
      mapConstants.PUSH__PRESAVED_POLYGON,
      mapConstants.REMOVE__POLYGON_FROM_PRESAVED,
      mapConstants.CLEAR__PRESAVED_POLYGONS,
      mapConstants.CLEAR__PRESAVED_RECTANGLES,
      mapConstants.ADD__POLYGONS_TO_SAVED,
      mapConstants.REMOVE__POLYGON_FROM_SAVED,
      mapConstants.REMOVE_SELECTED_POLYGONS_FROM_PRESAVED,
      mapConstants.PUSH__PRESAVED_CIRCLE,
      mapConstants.UPDATE__PRESAVED_CIRCLE,
      mapConstants.REMOVE__CIRCLE_FROM_PRESAVED,
      mapConstants.CLEAR__PRESAVED_CIRCLES,
      mapConstants.REMOVE_SELECTED_CIRCLES_FROM_PRESAVED,
      mapConstants.PUSH__PRESAVED_RECTANGLE,
      mapConstants.UPDATE__PRESAVED_RECTANGLE,
      mapConstants.REMOVE__RECTANGLE_FROM_PRESAVED,
      mapConstants.REMOVE_SELECTED_RECTANGLES_FROM_PRESAVED,
      mapConstants.UPDATE__PRESAVED_POLYGON,
      mapConstants.UPDATE_MAP_STORE,
      mapConstants.REMOVE_FILE_PRESAVED_CIRCLES,
      mapConstants.REMOVE_FILE_PRESAVED_POLYGONS,
    ],
    reachParameterChangeSaga,
  );
}

export function* watchReach() {
  yield takeLatest(reachConstants.FETCH_ESTIMATED_REACH, fetchReachSaga);
}

export function* watchMap() {
  yield takeEvery(advanceConstants.SIDEBAR_CAMPAIGN_INFO__SET, centerMap);
}

export function* setStoreChanges() {
  const state: AppState = yield select();

  yield put(
    estimatorActions.setEstimatorKeys(collectRequestEstimator(state) as CampaignEstimatorParams),
  );
}

export function* watchStoreForEstimation() {
  yield takeLatest(
    [
      creativesConstants.SELECT_CREATIVES,
      audienceConstants.SELECT_AUDIENCE_FIELD,
      inventoryConstants.SELECT_INVENTORY_FIELD,
      conversionConstants.SELECT_CONVERSION_FIELD,
      schedulingConstants.SELECT_SCHEDULING_FIELD,
      demographicConstants.SELECT_DEMOGRAPHIC_FIELD,
      bidConstants.CHANGE_BID_FIELD,
      technologyConstants.SELECT_TECHNOLOGY_FIELD,
      advancedTargetingConstants.SELECT_ADVANCED_TARGETING_FIELD,
      advancedTargetingConstants.SELECT_EXCHANGES,
      advancedTargetingConstants.SET_EXCHANGES,
      advanceConstants.SIDEBAR_CAMPAIGN_INFO__SET,
      advertiserConstants.SET_POLITICAL_ADV_STATUS,
      advertiserConstants.SET_POLITICAL_ADVERTISER,
      publishersConstants.SELECT_PUBLISHERS,
      // Location actions
      mapConstants.RESET_MAP_STORE,
      mapConstants.PUSH__PRESAVED_POLYGON,
      mapConstants.REMOVE__POLYGON_FROM_PRESAVED,
      mapConstants.CLEAR__PRESAVED_POLYGONS,
      mapConstants.CLEAR__PRESAVED_RECTANGLES,
      mapConstants.ADD__POLYGONS_TO_SAVED,
      mapConstants.REMOVE__POLYGON_FROM_SAVED,
      mapConstants.REMOVE_SELECTED_POLYGONS_FROM_PRESAVED,
      mapConstants.PUSH__PRESAVED_CIRCLE,
      mapConstants.UPDATE__PRESAVED_CIRCLE,
      mapConstants.REMOVE__CIRCLE_FROM_PRESAVED,
      mapConstants.CLEAR__PRESAVED_CIRCLES,
      mapConstants.REMOVE_SELECTED_CIRCLES_FROM_PRESAVED,
      mapConstants.PUSH__PRESAVED_RECTANGLE,
      mapConstants.UPDATE__PRESAVED_RECTANGLE,
      mapConstants.REMOVE__RECTANGLE_FROM_PRESAVED,
      mapConstants.REMOVE_SELECTED_RECTANGLES_FROM_PRESAVED,
      mapConstants.UPDATE__PRESAVED_POLYGON,
      mapConstants.UPDATE_MAP_STORE,
      mapConstants.REMOVE_FILE_PRESAVED_CIRCLES,
      mapConstants.REMOVE_FILE_PRESAVED_POLYGONS,
      mapConstants.SET_SELECTED_LOCATIONS_IDS,
      locationConstants.CLEAR_LOCATION_FILES,
      locationConstants.REMOVE_FILE_LOCATION,
      locationConstants.REMOVE_FILE_LOCATION_ITEM,
      advanceConstants.SET_WHITE_LIST,
      advanceConstants.SET_BLACK_LIST,
    ],
    setStoreChanges,
  );
}
