import * as React from 'react';
import { Box, Typography } from '@applift/factor';
import {
  StatusDeleted,
  StatusPaused,
  StatusPending,
  StatusRejected,
  StatusRunning,
} from '@applift/icons';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export interface CreativeStatusCellType {
  value: string;
}

export const CreativeStatusCell = (props: CreativeStatusCellType) => {
  const { value } = props;

  const statusToIconMapper = {
    pending: <StatusPending color="warning" />,
    running: <StatusRunning color="success" />,
    paused: <StatusPaused color="warning" />,
    deleted: <StatusDeleted color="error" />,
    rejected: <StatusRejected color="error" />,
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gapCol: 4 }}>
      {/* @ts-ignore */}
      {statusToIconMapper[value]}
      <Typography>{capitalizeFirstLetter(value)}</Typography>
    </Box>
  );
};
