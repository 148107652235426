import { Action } from 'models/Action';
import { reducerFromMap } from 'utils/actions';
import { reachConstants } from './constants';
import { ReachParameters } from '../../api/Reach';

export interface ReachState {
  estimatedReach: number | null;
  reachParameters: null | ReachParameters;
  isFetchingReach: boolean;
  isShowingReach: boolean;
  hasFetchError: boolean;
}

const defaultReachState: ReachState = {
  estimatedReach: null,
  reachParameters: null,
  isFetchingReach: false,
  isShowingReach: false,
  hasFetchError: false,
};

function setEstimatedReach(state: ReachState, action: Action<number>): ReachState {
  return {
    ...state,
    estimatedReach: action.payload,
  };
}

function setReachParameters(state: ReachState, action: Action<ReachParameters>): ReachState {
  return {
    ...state,
    reachParameters: action.payload,
  };
}

function resetReachStore(state: ReachState, action: Action<void>): ReachState {
  return {
    ...defaultReachState,
  };
}

function setIsFetchingReach(state: ReachState, action: Action<boolean>): ReachState {
  return {
    ...state,
    isFetchingReach: action.payload,
  };
}

function setIsShowingReach(state: ReachState, action: Action<boolean>): ReachState {
  return {
    ...state,
    isShowingReach: action.payload,
  };
}

function setHasFetchError(state: ReachState, action: Action<boolean>): ReachState {
  return {
    ...state,
    hasFetchError: action.payload,
  };
}

const reducer = reducerFromMap<ReachState>(defaultReachState, {
  [reachConstants.SET_ESTIMATED_REACH]: setEstimatedReach,
  [reachConstants.SET_REACH_PARAMETERS]: setReachParameters,
  [reachConstants.RESET_REACH_STORE]: resetReachStore,
  [reachConstants.SET_IS_FETCHING_REACH]: setIsFetchingReach,
  [reachConstants.SET_IS_SHOWING_REACH]: setIsShowingReach,
  [reachConstants.SET_HAS_FETCH_ERROR]: setHasFetchError,
});

export const reach = (state: ReachState = defaultReachState, action: Action<any>) =>
  reducer(state, action);
