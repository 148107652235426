import * as React from 'react';
import {
  Box,
  Typography,
  TypoTooltip,
  Avatar,
  Chip,
  Col,
  Row,
  Tooltip,
  IconButton,
} from '@applift/factor';
import { CurrencyDollar, Delete, InfoCircle, SemanticWarning } from '@applift/icons';
import { PrebidNormalizedSegmentData, SelectPrebidSegmentApiRefType } from '@applift/platform';

import { Option } from 'models/Option';
import {
  AUDIO_CREATIVE_ID,
  Creative,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from 'models/Creative';
import { DISPLAY_CREATIVE_IDS } from 'constants/audience';

interface ViewPreBidSegmentsProps {
  selectedSegments: PrebidNormalizedSegmentData[];
  apiRef: React.MutableRefObject<SelectPrebidSegmentApiRefType | undefined>;
  selectedCreativeType: Option<number> | null;
  selectedCreatives: Creative[];
}

export const ViewPreBidSegments = (props: ViewPreBidSegmentsProps) => {
  const { selectedSegments, apiRef, selectedCreativeType, selectedCreatives } = props;

  const groupedData: { [key: string]: PrebidNormalizedSegmentData[] } = {};
  selectedSegments.forEach((obj) => {
    if (!groupedData[`${obj.parentPathKey}|${obj.providerId}`]) {
      groupedData[`${obj.parentPathKey}|${obj.providerId}`] = [];
    }
    groupedData[`${obj.parentPathKey}|${obj.providerId}`].push(obj);
  });
  const segmentsGroupedByParentPathKey = Object.values(groupedData);

  const getGroupWarning = (group: PrebidNormalizedSegmentData[]) => {
    if (!selectedCreativeType?.value) {
      return 'Kindly choose the appropriate creative type corresponding to these pre-bid segments.';
    }

    if (selectedCreativeType?.value === AUDIO_CREATIVE_ID) {
      return 'Currently, IAS does not offer pre-bid segments for audio.';
    }

    let campaignCreativeTypes;

    if (selectedCreativeType?.value === NATIVE_CREATIVE_ID) {
      if (
        selectedCreatives.length &&
        selectedCreatives.every((creative) => creative.nativeMediaType?.toLowerCase() === 'video')
      ) {
        campaignCreativeTypes = [VIDEO_CREATIVE_ID];
      } else if (
        selectedCreatives.length &&
        selectedCreatives.every((creative) => creative.nativeMediaType?.toLowerCase() === 'image')
      ) {
        campaignCreativeTypes = DISPLAY_CREATIVE_IDS;
      } else {
        return null;
      }
    } else {
      campaignCreativeTypes =
        selectedCreativeType?.value === VIDEO_CREATIVE_ID
          ? [VIDEO_CREATIVE_ID]
          : DISPLAY_CREATIVE_IDS;
    }

    if (group[0].creativeTypeIdList.sort().toString() !== campaignCreativeTypes.toString()) {
      return 'Please take note that the creative type chosen for this campaign differs from the pre-bid segments.';
    }
    return null;
  };

  return (
    <Row sx={{ overflowY: 'hidden', width: 75, mx: 'auto' }}>
      <Col
        xs={12}
        sx={{
          height: 'auto',
          overflowY: 'hidden',
          p: 16,
        }}
      >
        <Box
          style={{
            maxHeight: '500px',
          }}
          sx={{
            overflowY: 'auto',
            height: 'auto',
            borderRadius: 4,
            border: 1,
          }}
        >
          {segmentsGroupedByParentPathKey &&
            segmentsGroupedByParentPathKey?.map((group, groupIdx) => (
              <Box
                sx={{
                  borderBottom: groupIdx === segmentsGroupedByParentPathKey.length - 1 ? 0 : 1,
                  borderColor: 'neutral-200',
                  my: 8,
                }}
                key={group[0].parentPathKey}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, px: 16 }}>
                  <Box sx={{ px: 8, py: 0, pl: 4 }}>
                    <Avatar
                      size={32}
                      variant="square"
                      src={group[0].providerLogoUrl as string}
                      alt={group[0].providerName}
                      imgProps={{
                        style: { objectFit: 'cover' },
                      }}
                    />
                  </Box>
                  <Typography weight="bold" style={{ fontWeight: 600 }}>
                    {group[0].parentPathKey}
                  </Typography>
                  <Chip
                    label={
                      <Typography weight="normal">{`${Math.max(
                        ...group.map((segment: { cpm: number }) => segment.cpm ?? 0),
                      ).toFixed(2)} (CPM)`}</Typography>
                    }
                    color="secondary"
                    icon={<CurrencyDollar />}
                    size="small"
                  />
                  {getGroupWarning(group) && (
                    <Tooltip title={getGroupWarning(group)} placement="top" arrow>
                      <SemanticWarning color="warning" fontSize={24} />
                    </Tooltip>
                  )}
                  <Tooltip
                    title="This signifies the total cost for all the segments you've chosen within this specific category."
                    placement="top"
                    arrow
                  >
                    <InfoCircle fontSize={20} sx={{ textColor: 'neutral-400' }} />
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {group.map((item: PrebidNormalizedSegmentData) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'between',
                        width: 100,
                        mb: 2,
                        pl: 64,
                        pr: 12,
                      }}
                    >
                      <TypoTooltip>{item.label}</TypoTooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title="Removing the segments will impact only the current campaign and not the original audience that includes them."
                      >
                        <IconButton
                          sx={{ py: 0 }}
                          onClick={() => {
                            const filteredSegments = selectedSegments.filter(
                              (segment) => segment.value !== item.value,
                            );
                            const newSelectionObject: Record<string, PrebidNormalizedSegmentData> =
                              {};
                            filteredSegments.forEach((item) => {
                              newSelectionObject[item.preservedValue] = item;
                            });
                            apiRef.current?.reinitializeRowSelection(newSelectionObject);
                          }}
                        >
                          <Delete fontSize={24} sx={{ textColor: 'neutral-400' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
        </Box>
      </Col>
    </Row>
  );
};
