import * as React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Add, Bid, ExternalLink, Search } from '@applift/icons';
import {
  Alert,
  bindPopover,
  bindTrigger,
  Box,
  Button,
  Col,
  Link,
  Row,
  SvgIcon,
  Typography,
  usePopupState,
} from '@applift/factor';
import { CollapsibleBlock } from 'factor';
import {
  PrebidNormalizedSegmentData,
  PrebidProviderData,
  SelectPrebidSegment,
  SelectPrebidSegmentApiRefType,
  usePrebidProviderData,
} from '@applift/platform';

import { ALL_EXPANDED, CollapseMode } from 'store/app/constants';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { usePrebidDataByAudienceIds, usePrebidDataBySegmentIds } from 'hooks/useAudience';
import { blockStyles } from 'components/Block';
import { AppState } from 'models/Store';
import { AUDIO_CREATIVE_ID, Creative } from 'models/Creative';
import { Option } from 'models/Option';
import { audienceActions } from 'store/audience/actions';
import { SelectPreBidAudiences } from './components/index';
import { ViewPreBidSegments } from './components/ViewPreBidSegments.tsx/ViewPreBidSegments';

import styles from './index.module.scss';

interface Props {
  isCollapseOpen?: boolean;
  collapseMode?: CollapseMode;
  onToggle?: (isOpened: boolean) => void;
  selectedCreativeType: Option<number> | null;
  setPreBidSegmentIds?: (ids: number[]) => void;
  editableCampaign: ExistingCampaignData | null;
  selectedCreatives: Creative[];
}

const PreBidAudiencesBlockComponent = (props: Props) => {
  const {
    isCollapseOpen,
    onToggle,
    collapseMode,
    selectedCreativeType,
    setPreBidSegmentIds,
    editableCampaign,
    selectedCreatives,
  } = props;

  const [selectedSegments, setSelectedSegments] = React.useState<PrebidNormalizedSegmentData[]>([]);
  const { search } = useLocation();
  const { preBidIds } = queryString.parse(search);
  const isSelectionUpdatedFromURL = React.useRef(false);
  const selectedSegmentsRef = React.useRef<PrebidNormalizedSegmentData[]>([]);

  const apiRef = React.useRef<SelectPrebidSegmentApiRefType>();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'IASPopper',
  });

  const providerData = usePrebidProviderData();

  const providerDataObject = React.useMemo(() => {
    if (providerData.isSuccess) {
      return providerData.data.prebidSegmentData.reduce((prev, one) => {
        // eslint-disable-next-line
        prev[one.providerId] = {
          providerId: one.providerId,
          providerLogoUrl: one.providerLogoUrl,
          providerName: one.providerName,
          description: one.description,
        } as Omit<PrebidProviderData, 'childSegments'>;
        return prev;
      }, {} as Record<string, Omit<PrebidProviderData, 'childSegments'>>);
    }
    return {} as Record<string, Omit<PrebidProviderData, 'childSegments'>>;
  }, [providerData.isSuccess, providerData.data]);

  const preBidDataBySegmentIds = usePrebidDataBySegmentIds(
    (editableCampaign?.prebidAudienceSegmentIdList as number[]) ?? [],
    {
      enabled:
        Boolean(editableCampaign?.prebidAudienceSegmentIdList?.length) &&
        Object.keys(providerDataObject ?? {}).length > 0,
      meta: providerDataObject,
    },
  );

  React.useEffect(() => {
    if (preBidDataBySegmentIds.data?.prebidSegmentData?.length) {
      const newSelectionObject: Record<string, PrebidNormalizedSegmentData> = {};
      const newSelection = preBidDataBySegmentIds.data
        ?.prebidSegmentData as PrebidNormalizedSegmentData[];
      newSelection.forEach((item) => {
        newSelectionObject[item.preservedValue] = item;
      });
      apiRef.current?.reinitializeRowSelection(newSelectionObject);
    }
  }, [preBidDataBySegmentIds.data]);

  const preBidDataByAudienceIds = usePrebidDataByAudienceIds(
    (preBidIds as string)?.split(',').map((item) => Number(item)),
    {
      enabled: Boolean(preBidIds) && Object.keys(providerDataObject ?? {}).length > 0,
      meta: providerDataObject,
    },
  );

  React.useEffect(() => {
    if (
      preBidDataByAudienceIds.data?.prebidSegmentData?.length &&
      !isSelectionUpdatedFromURL.current
    ) {
      const newSelectionObject: Record<string, PrebidNormalizedSegmentData> = {};
      const newSelection = preBidDataByAudienceIds.data
        ?.prebidSegmentData as PrebidNormalizedSegmentData[];
      newSelection.forEach((item) => {
        newSelectionObject[item.preservedValue] = item;
      });
      apiRef.current?.reinitializeRowSelection(newSelectionObject);
      isSelectionUpdatedFromURL.current = true;
    }
  }, [preBidDataByAudienceIds.data, preBidDataByAudienceIds.isSuccess]);

  const getGroupedSegmentData = (data: any[]) => {
    const groupedData: { [key: string]: any[] } = {};
    data.forEach((obj) => {
      if (!groupedData[`${obj.parentPathKey}|${obj.providerId}`]) {
        groupedData[`${obj.parentPathKey}|${obj.providerId}`] = [];
      }
      groupedData[`${obj.parentPathKey}|${obj.providerId}`].push(obj);
    });
    return Object.values(groupedData);
  };

  const headerObj = {
    title: (
      <Box sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }} className={styles.header}>
        <Bid fontSize={24} sx={{ textColor: 'primary-600' }} />
        <Typography>Pre-bid Audience</Typography>
      </Box>
    ),
    ...(selectedSegments.length
      ? {
          summary: {
            Segments: getGroupedSegmentData(selectedSegments).map(
              (group, groupIdx) =>
                `${group[0].parentPathKey?.replaceAll('>', ' - ')} (${group.length})
                  ${groupIdx !== getGroupedSegmentData(selectedSegments).length - 1 ? ',' : ''} `,
            ),
          },
        }
      : {}),
  };

  const getAlertMessage = () => {
    if (!selectedCreativeType) {
      return 'Total cost for all the added pre-bid segments will be available once a creative type is selected.';
    }
    if (selectedCreativeType.value === AUDIO_CREATIVE_ID) {
      return 'Currently, IAS does not offer pre-bid segments for audio.';
    }
    const applicableSegments = selectedSegments.filter((segment: any) =>
      segment.creativeTypeIdList.includes(selectedCreativeType.value),
    );

    const segmentsGroupedByParentPathKey = getGroupedSegmentData(applicableSegments);
    let cpmTotal = 0;
    segmentsGroupedByParentPathKey.forEach((groupArray) => {
      cpmTotal += groupArray[0].cpm;
    });
    return (
      <>
        Total cost for all the added pre-bid segments will be
        <Typography weight="demi" sx={{ ml: 2 }}>
          ${cpmTotal.toFixed(2)} (CPM)
        </Typography>
      </>
    );
  };

  return (
    <CollapsibleBlock
      key="prebidAudienceBlock"
      isCollapseOpen={isCollapseOpen}
      onToggle={onToggle}
      collapsible={collapseMode !== ALL_EXPANDED}
      header={headerObj}
      customStyle={{
        block: `${blockStyles.block} ${!isCollapseOpen ? blockStyles.collapsed : ''}`,
        panelHeaderExpand: `${blockStyles.panelHeaderExpand} ${
          isCollapseOpen ? blockStyles.panelHeaderExpandWithMessage : ''
        }`,
        panelHeaderCollapse: blockStyles.panelHeaderCollapse,
        ...(isCollapseOpen
          ? {
              blockTitleText: blockStyles.blockTitleText,
              actionsWrapper: blockStyles.actionsWrapper,
            }
          : {}),
        ...(!isCollapseOpen ? { actionsWrapper: styles.collapsedActionsWrapper } : {}),
      }}
    >
      <>
        <Row>
          <Col xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                bgColor: 'primary-50',
                p: 32,
                width: 75,
                borderRadius: 8,
                margin: 'auto',
              }}
            >
              <Row sx={{ mb: 16 }}>
                <Col xs={8} className={styles.helpText}>
                  Select from the list of pre-bid audiences or add new audiences
                </Col>
                <Col xs={4}>
                  <Link
                    /* @ts-ignore */
                    target="_blank"
                    href="https://help.iqm.com/en/articles/8698741-specs-limitations-and-best-practices-of-ias-pre-bid-segments"
                    sx={{ mt: 4 }}
                  >
                    <SvgIcon fontSize={20} sx={{ mr: 8 }}>
                      <ExternalLink />
                    </SvgIcon>
                    <Typography weight="demi">Pre-bid Best Practices</Typography>
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col xs={8}>
                  <SelectPreBidAudiences
                    apiRef={apiRef}
                    selectedSegmentsRef={selectedSegmentsRef}
                  />
                </Col>
                <Col xs={4}>
                  {/* @ts-ignore */}
                  <Button
                    // eslint-disable-next-line
                    {...bindTrigger(popupState)}
                    variant="outlined"
                    startIcon={<Add />}
                    size="medium"
                    selected={popupState.isOpen}
                    sx={{ bgColor: 'neutral-0' }}
                  >
                    Add Pre-bid Audience
                  </Button>
                  {/* @ts-ignore */}
                  <SelectPrebidSegment
                    key="prebid-dropdown"
                    // eslint-disable-next-line
                    {...bindPopover(popupState)}
                    onChange={(data) => {
                      selectedSegmentsRef.current = Object.values(data);
                      setSelectedSegments(Object.values(data));
                      setPreBidSegmentIds?.(Object.values(data).map((item) => Number(item.value)));
                    }}
                    ref={apiRef}
                    size="medium"
                    slotProps={{
                      // @ts-ignore
                      PopperProps: {
                        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                        transformOrigin: { horizontal: 'right', vertical: 'top' },
                      },
                      InputBaseProps: {
                        startAdornment: <Search />,
                      },
                      PaperProps: {
                        style: {
                          maxWidth: '576px',
                          width: 'calc(100% - 64px)',
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
        {selectedSegments.length > 0 && (
          <Row sx={{ mt: 16, width: 75, mx: 'auto' }}>
            <Col xs={12} sx={{ px: 16 }}>
              <Alert severity="secondary">{getAlertMessage()}</Alert>
            </Col>
          </Row>
        )}
        {selectedSegments.length > 0 && (
          <Row>
            <Col xs={12}>
              <ViewPreBidSegments
                selectedSegments={selectedSegments}
                apiRef={apiRef}
                selectedCreativeType={selectedCreativeType}
                selectedCreatives={selectedCreatives}
              />
            </Col>
          </Row>
        )}
      </>
    </CollapsibleBlock>
  );
};

const mapState = (state: AppState) => {
  return {
    selectedCreativeType: state.creatives.selectedCreativeType,
    editableCampaign: state.app.editableCampaign,
    selectedCreatives: state.creatives.selectedCreatives,
  };
};

const mapAction = {
  setPreBidSegmentIds: audienceActions.setPreBidSegmentIds,
};

export const PreBidAudiencesBlock = connect(mapState, mapAction)(PreBidAudiencesBlockComponent);
