import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../models/Store';
import { MapCircle } from '../MapCircle';
import { CircleExtended } from '../../../../../../models/CircleExtended';

interface Props {
  preSavedCircles: CircleExtended[];
}
class PreSavedCirclesComponent extends React.PureComponent<Props> {
  render() {
    const { preSavedCircles } = this.props;
    return preSavedCircles.map((circle: CircleExtended) => {
      return <MapCircle key={circle.getRadius()} getCircle={() => circle} />;
    });
  }
}

const mapState = (state: AppState) => ({
  preSavedCircles: state.map.preSavedCircles,
});

export const PreSavedCircles = connect(mapState)(PreSavedCirclesComponent);
