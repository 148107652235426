import { App } from './App';
import { Location } from './Location';
import { Estimator } from './Estimator';
import { IOListPage } from './IOListPage';
import { IODetailsPage } from './IODetailsPage';

export const DataDogLogger = {
  App,
  Location,
  Estimator,
  IOListPage,
  IODetailsPage,
};
