import * as React from 'react';
import { Container, Row, Col, Typography, Button, Box, enqueueSnackbar } from '@applift/factor';
import { Add } from '@applift/icons';
import { EpochDateRangePicker, OptionId, TimezoneProvider, TimezonePicker } from 'iqm-framework';
import moment from 'moment';
import { connect } from 'react-redux';
import { RowSelectionState } from '@applift/datagrid';
import { useHistory, useLocation } from 'react-router-dom';

import { ScreenLoader } from 'components/ScreenLoader';
import { CreateIODialog } from 'components/CreateIoDialog';
import { useIoList, useIoListCols } from 'hooks/useIoList';
import { useTimezone } from 'hooks/useTimezone';
import { AppState } from 'models/Store';
import { DEFAULT_LIST_SORTING, DEFAULT_PAGE_SIZE } from 'constants/insertionOrder';
import { DataDogLogger } from 'services/DataDog';
import { ListGridWrapper } from './ListGrid';

import styles from './styles.module.scss';

interface ListComponentProps {
  organizationTimezone?: number | OptionId<any> | null;
}

const ListComponent = (props: ListComponentProps) => {
  const { organizationTimezone } = props;
  const {
    isLoading,
    isError,
    refetch: refetchIoList,
  } = useIoList('', DEFAULT_PAGE_SIZE, DEFAULT_LIST_SORTING);
  const [openCreateIoDialog, setOpenCreateIoDialog] = React.useState(false);
  const [headerCount, setHeaderCount] = React.useState<number | undefined>(undefined);
  const { isLoading: ioListColLoading } = useIoListCols();
  const [timezoneFilter, setTimezoneFilter] = React.useState<OptionId<string> | undefined>(
    undefined,
  );
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [defaultDateRangeWasUpdated, setDefaultDateRangeWasUpdated] =
    React.useState<boolean>(false);
  const [calendarMinimumDate, setCalendarMinimumDate] = React.useState<any>(null);
  const [calendarMaximumDate, setCalendarMaximumDate] = React.useState<any>(null);
  const timezone = useTimezone();
  const timezoneOptions = timezone.data;
  const isTimezoneFetching = useTimezone().isLoading;
  const [prevDateRange, setPrevDateRange] = React.useState<{
    startDate: Date;
    endDate: Date;
  } | null>();
  const [dateRange, setDateRange] = React.useState<
    { startDate: Date; endDate: Date } | { startDate: number; endDate: number } | null
  >(null);

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const createIoParam = searchParams.get('createIO');

  const preselected = [
    EpochDateRangePicker.PRESELECT_KEYS.TODAY,
    EpochDateRangePicker.PRESELECT_KEYS.YESTERDAY,
    EpochDateRangePicker.PRESELECT_KEYS.LAST_WEEK,
    EpochDateRangePicker.PRESELECT_KEYS.LAST_MONTH,
    EpochDateRangePicker.PRESELECT_KEYS.LAST_THREE_MONTHS,
  ];
  const today = new Date();
  const twoYearsAgo = new Date().setFullYear(today.getFullYear() - 2);

  const setInitDateRange = (dateRange: any) => {
    setDefaultDateRangeWasUpdated(true);
    setDateRange(dateRange);
  };

  React.useEffect(() => {
    if (createIoParam) {
      setOpenCreateIoDialog(true);
    } else {
      setOpenCreateIoDialog(false);
    }
  }, [createIoParam]);

  const getCalendarMinMax = (timezone: any | null) => {
    if (timezone) {
      const activeTimezoneCurrentDate = moment.tz(moment().utc(), 'UTC').tz(timezone.value);
      const activeTimezoneStartDate = activeTimezoneCurrentDate
        .clone()
        .add(-2, 'year')
        .startOf('day');
      const activeTimezoneEndDate = activeTimezoneCurrentDate.clone().endOf('day');

      const calendarMinimumDate = moment.tz(
        activeTimezoneStartDate.format('YYYY-MM-DD HH:mm:ss'),
        moment.tz.guess(),
      );
      const calendarMaximumDate = moment.tz(
        activeTimezoneEndDate.format('YYYY-MM-DD HH:mm:ss'),
        moment.tz.guess(),
      );

      return {
        calendarMinimumDate,
        calendarMaximumDate,
      };
    }

    return {
      calendarMinimumDate: null,
      calendarMaximumDate: null,
    };
  };

  React.useEffect(() => {
    if (timezoneOptions?.length && !timezoneFilter && organizationTimezone) {
      setTimezoneFilter(
        timezoneOptions
          ?.filter(
            (timezone) =>
              timezone.id ===
              (typeof organizationTimezone === 'number'
                ? organizationTimezone
                : organizationTimezone.id),
          )
          .map((orgTimezone) => ({
            label: orgTimezone.label,
            id: orgTimezone.id,
            value: orgTimezone.name,
          }))[0],
      );
    }
  }, [timezone, organizationTimezone, timezoneFilter, timezoneOptions]);

  React.useEffect(() => {
    const maxMinObj = getCalendarMinMax(timezoneFilter);
    setCalendarMaximumDate(maxMinObj.calendarMaximumDate);
    setCalendarMinimumDate(maxMinObj.calendarMinimumDate);
  }, [timezoneFilter]);

  const handleDateChange = ({ startDate, endDate }: { startDate: number; endDate: number }) => {
    const isInitialDateRange = !dateRange;
    const range = {
      startDate,
      endDate,
    };
    // @ts-ignore
    setPrevDateRange(dateRange);

    // eslint-disable-next-line
    isInitialDateRange ? setInitDateRange(range) : setDateRange(range);
  };

  React.useEffect(() => {
    if (
      dateRange &&
      prevDateRange &&
      moment(dateRange.endDate).diff(dateRange.startDate, 'days') > 365
    ) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Max 1 year of duration is supported',
      });
      setDateRange(prevDateRange);
    }
  }, [dateRange, prevDateRange, setDateRange]);

  const getDefaultDateRange = () => {
    return {
      getStartDate: (tz: string) => {
        const startDate = moment.tz(moment().utc(), 'UTC').tz(tz).startOf('day');
        return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
      },
      getEndDate: (tz: string) => {
        const startDate = moment.tz(moment().utc(), 'UTC').tz(tz).endOf('day');
        return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
      },
    };
  };

  const getDatesOnMinMaxUpdate = (
    startDate: number,
    endDate: number,
    minDate: Date,
    maxDate: Date,
  ) => {
    let newStart = startDate;
    let newEnd = endDate;
    if (endDate > maxDate.valueOf()) {
      newEnd = maxDate.valueOf();
      if (newStart > newEnd) {
        newStart = moment(newStart).add(-1, 'day').valueOf();
      }
    } else if (startDate < minDate.valueOf()) {
      newStart = minDate.valueOf();
      if (newStart > newEnd) {
        newEnd = moment(newEnd).add(1, 'day').valueOf();
      }
    }
    return { startDate: newStart, endDate: newEnd };
  };

  const dateRangeNew =
    defaultDateRangeWasUpdated && dateRange
      ? {
          startDate: dateRange.startDate.valueOf(),
          endDate: dateRange.endDate.valueOf(),
        }
      : null;

  return !(isLoading || ioListColLoading) && !isError && !isTimezoneFetching ? (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        px: 40,
      }}
    >
      <Row>
        <Col
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 24,
          }}
        >
          <Col xs={5}>
            <Typography component="h4" variant="bodyLarge" weight="demi" gutterBottom={false}>
              {`Insertion Orders ${typeof headerCount === 'number' ? `(${headerCount})` : ''}`}
            </Typography>
          </Col>
          {timezoneFilter && calendarMaximumDate && calendarMinimumDate && (
            <Box sx={{ ml: 'auto', display: 'flex' }}>
              <TimezoneProvider timezone={timezoneFilter}>
                <Box className={styles.timezonePicker} sx={{ mr: 8 }}>
                  <TimezonePicker
                    // @ts-ignore
                    noLabel
                    onTimezoneChange={(val) => {
                      setTimezoneFilter(val);
                      setRowSelection({});
                    }}
                  />
                </Box>
                <EpochDateRangePicker
                  dateRange={dateRangeNew}
                  dateFormat="MM/DD/YYYY"
                  onDateRangeChange={(val: { startDate: number; endDate: number }) => {
                    handleDateChange(val);
                    setRowSelection({});
                  }}
                  datePickerProps={{
                    numberOfCalendars: 2,
                    singleDateRange: true,
                    minimumDate: twoYearsAgo,
                  }}
                  preselected={preselected}
                  insidePreselectors
                  defaultDateRange={getDefaultDateRange()}
                  calendarMinimumDate={calendarMinimumDate}
                  calendarMaximumDate={calendarMaximumDate}
                  getDatesOnMinMaxUpdate={getDatesOnMinMaxUpdate}
                  removeSafariSelectionIssue
                  showLabelAlways={false}
                  underline
                  dateRangePickerClassName={styles.dateRangePicker}
                  className="dateRangePickerIO"
                />
              </TimezoneProvider>
            </Box>
          )}
          <Button
            color="primary"
            disableRipple
            startIcon={<Add />}
            sx={{ ml: 8 }}
            onClick={() => {
              DataDogLogger.IODetailsPage.openCreateIODialog();
              const newSearchParams = new URLSearchParams(location.search);
              newSearchParams.set('createIO', 'true');
              history.replace(`?${newSearchParams.toString()}`);
            }}
          >
            Create Insertion Order
          </Button>
        </Col>
      </Row>
      <ListGridWrapper
        setHeaderCount={setHeaderCount}
        timezoneFilter={timezoneFilter}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        // @ts-ignore
        dateRange={dateRange}
      />
      {openCreateIoDialog && (
        <CreateIODialog
          refetchIoList={refetchIoList}
          isOpen={openCreateIoDialog}
          setOpenDialog={setOpenCreateIoDialog}
          onClose={() => {
            setOpenCreateIoDialog(false);
            history.replace('/io-list');
          }}
        />
      )}
    </Container>
  ) : (
    <ScreenLoader text="Loading..." />
  );
};

const mapState = (state: AppState) => ({
  organizationTimezone: state.auth.organizationTimezoneInfo,
});

export const List = connect(mapState, null)(ListComponent);
