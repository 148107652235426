import { Box, Typography } from '@applift/factor';

import { useCountryList } from 'hooks/useCountryList';
import { OptionId } from 'models/Option';
import { StoreFilter } from 'models/ZustandStore';
import moment from 'moment';
import React from 'react';
import { CurrencyFormat } from 'utils/format';
import { useStore } from 'zustandStore';

export interface SidebarInfoType {
  label: string;
  value: string | JSX.Element;
  hide: boolean;
}

const DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

export const SidebarCampaignInfo = () => {
  const [
    spendingBudget,
    maxBidPrice,
    timezone,
    startDate,
    endDate,
    selectedCreatives,
    country,
    pgDealIds,
    selectedConversions,
  ] = useStore((state: StoreFilter) => [
    state.campaignInfoBlock.spendingBudget,
    state.campaignInfoBlock.maxBidPrice,
    state.campaignInfoBlock.timezone,
    state.campaignInfoBlock.startDate,
    state.campaignInfoBlock.endDate,
    state.creativesBlock.selectedCreatives,
    state.locationBlock.country,
    state.inventoryBlock.pgDealIds,
    state.conversionBlock.selectedConversions,
  ]);

  const getEndDate = (tz: OptionId<any> | undefined, value: number | null) => {
    if (!value) {
      return null;
    }
    return tz
      ? moment(value * 1000)
          .tz(tz.value)
          .format(DATE_FORMAT)
      : moment(value).format(DATE_FORMAT);
  };

  const [sidebarInfo, setSidebarInfo] = React.useState<SidebarInfoType[]>([]);

  const { data: countryListResponse } = useCountryList({});

  const countryList = React.useMemo(() => countryListResponse?.data, [countryListResponse?.data]);

  const currentCountry =
    countryList?.filter((val: { value: number }) => {
      return val.value === country;
    })[0]?.label ?? country;

  React.useEffect(() => {
    setSidebarInfo([
      {
        label: 'Spending Budget',
        value: String(CurrencyFormat.format(spendingBudget || 0.0)),
        hide: false,
      },
      {
        label: 'Max Bid Price',
        value: (
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gapCol: 4 }}>
            {String(CurrencyFormat.format(maxBidPrice || 0.0))}
          </Box>
        ),
        hide: false,
      },
      { label: 'Timezone', value: String(timezone?.label), hide: false },
      { label: 'Start Date', value: String(getEndDate(timezone, startDate)), hide: false },
      { label: 'End Date', value: String(getEndDate(timezone, endDate)), hide: !endDate },
      {
        label: 'Creatives',
        value: String(`${selectedCreatives?.length} Selected`),
        hide: !selectedCreatives?.length,
      },
      {
        label: 'Country',
        value: String(currentCountry),
        hide: !currentCountry,
      },
      {
        label: 'Deals',
        value: String(`${pgDealIds?.length} Selected`),
        hide: !pgDealIds?.length,
      },
      {
        label: 'Conversions',
        value: String(`${selectedConversions?.length} Selected`),
        hide: !selectedConversions?.length,
      },
    ]);
  }, [
    currentCountry,
    endDate,
    maxBidPrice,
    pgDealIds.length,
    selectedConversions?.length,
    selectedCreatives?.length,
    spendingBudget,
    startDate,
    timezone,
    timezone?.label,
  ]);

  return (
    <Box>
      {sidebarInfo.map((val) =>
        !val.hide ? (
          <Box sx={{ mb: 16 }}>
            <Typography weight="demi" sx={{ textColor: 'neutral-400' }} component="span">
              {val.label}:{' '}
            </Typography>
            <Typography weight="demi" component="span">
              {val.value}
            </Typography>
          </Box>
        ) : null,
      )}
    </Box>
  );
};
