import React from 'react';
import { Box, Button, Typography } from '@applift/factor';
import { Add, Check } from '@applift/icons';
import { AppState } from 'models/Store';
import { connect, useDispatch } from 'react-redux';
import { AdvancedTargetingState } from 'store/advancedTargeting/reducer';
import { advancedTargetingActions } from 'store/advancedTargeting/actions';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { AllowlistDialog, allowFields } from './AllowlistDialog';

export interface AllowListButtonProps {
  editableCampaign?: ExistingCampaignData | null;
  advancedTargeting: AdvancedTargetingState;
}
export const AllowListButtonComponent = (props: AllowListButtonProps) => {
  const { advancedTargeting, editableCampaign } = props;

  const dispatch = useDispatch();

  const allowedList = React.useMemo(() => {
    const sideBarInfo = advancedTargeting.sidebarCampaignInfo;
    return {
      androidPackages: sideBarInfo.selectedWhiteListedPackageName.split(',').filter(Boolean) ?? [],
      iosApps: sideBarInfo.selectedWhiteListedAppId.split(',').filter(Boolean) ?? [],
      siteDomains: sideBarInfo.selectedWhiteListedSiteDomain.split(',').filter(Boolean) ?? [],
    };
  }, [advancedTargeting.sidebarCampaignInfo]);

  const [isDialogOpen, setDialog] = React.useState(false);

  React.useEffect(() => {
    if (editableCampaign) {
      allowFields.forEach((field) => {
        const val = editableCampaign[field as keyof typeof editableCampaign] as string;
        if (val) {
          dispatch(
            advancedTargetingActions.selectAdvancedTargetingField({
              key: CampaignInfoField[field as keyof typeof CampaignInfoField],
              value: val,
            }),
          );
        }
      });
    }
  }, [editableCampaign, dispatch]);

  return (
    <Box>
      {/* @ts-ignore */}
      <Button
        variant="outlined"
        onClick={() => setDialog(true)}
        // @ts-ignore
        sx={{ borderRadius: 2 }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...(() => {
          if (
            allowedList.androidPackages.length +
              allowedList.iosApps.length +
              allowedList.siteDomains.length >
            0
          ) {
            return {
              color: 'success',
              startIcon: <Check />,
              children: 'View Allowlist',
              sx: { mb: 12, borderRadius: 2 },
            };
          }
          return { startIcon: <Add />, children: 'Allowlist' };
        })()}
      />
      {allowedList.androidPackages.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography>Android Package Names: </Typography>
          <Typography weight="demi" color="success" sx={{ textColor: 'success-500' }}>
            {allowedList.androidPackages.length}
          </Typography>
        </Box>
      )}
      {allowedList.iosApps.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography>iOS Apps IDs: </Typography>
          <Typography weight="demi" color="success" sx={{ textColor: 'success-500' }}>
            {allowedList.iosApps.length}
          </Typography>
        </Box>
      )}
      {allowedList.siteDomains.length > 0 && (
        <Box>
          <Typography>Site Domains: </Typography>
          <Typography weight="demi" color="success" sx={{ textColor: 'success-500' }}>
            {allowedList.siteDomains.length}
          </Typography>
        </Box>
      )}
      {isDialogOpen && <AllowlistDialog open={isDialogOpen} closeDialog={() => setDialog(false)} />}
    </Box>
  );
};

const mapState = (state: AppState) => ({
  advancedTargeting: state.advancedTargeting,
  editableCampaign: state.app.editableCampaign,
});

export const AllowListButton = connect(mapState)(AllowListButtonComponent);
