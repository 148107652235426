import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  LoadingButton,
} from '@applift/factor';

export interface SaveIoDialogType {
  ioId: string;
  isOpen: boolean;
  setOpenDialog: (arg: boolean) => void;
  onSave: () => void;
  loading: boolean;
}

export const SaveIoDialog = (props: SaveIoDialogType) => {
  const { isOpen, setOpenDialog, onSave, loading } = props;

  React.useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen, setOpenDialog]);

  return (
    <Dialog open={isOpen} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Save Insertion Order</DialogTitle>
      <DialogContent sx={{ pt: 0, pb: 0 }}>
        <Typography variant="bodySmall">
          Before creating your first campaign, please save your Insertion Order. Are you sure you
          want to save and proceed with creating campaigns?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            onSave();
          }}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
