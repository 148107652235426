import { put, select } from 'redux-saga/effects';

import { API } from '../../api';
import { createAction } from '../../utils/actions';
import { Option } from '../../models/Option';
import { creativesConstants } from '../creatives/constants';
import { AppState } from '../../models/Store';

export function* fetchCreativesSaga(): Generator<unknown, void, any> {
  const state: AppState = yield select();
  const { auth } = state;
  if (auth.userData) {
    const response = yield API.Creatives.FetchCreativeTypes();
    const responseRtb = yield API.Creatives.FetchRtbTypes();
    yield put(createAction<Option<number>[]>(creativesConstants.SET_CREATIVE_TYPES, response));
    yield put(createAction<Option<number>[]>(creativesConstants.SET_CREATIVES_RTB, responseRtb));
  }
}
