import * as React from 'react';
import { Box, Typography } from '@applift/factor';
import { CalendarSettings, InsightsDatabase, Omnichannel, DatabaseCheck } from '@applift/icons';

const componentMapping = {
  CustomizeReport: CalendarSettings,
  DataReport: InsightsDatabase,
  OmniChannel: Omnichannel,
  SupplyPath: DatabaseCheck,
} as const;
type ComponentKeys = keyof typeof componentMapping;
type ComponentValues = typeof componentMapping[ComponentKeys];

export interface FeatureSectionProps {
  icon: ComponentValues;
  title: string;
  description: string;
  className?: string;
}

export const FeatureSection = ({ icon, title, description, className }: FeatureSectionProps) => {
  const IconComponent = icon;
  return (
    <Box className={className}>
      <IconComponent fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      <Typography component="h5" variant="bodyMedium" weight="demi" sx={{ mb: 8 }}>
        {title}
      </Typography>
      <Typography variant="bodySmall" gutterBottom={false}>
        {description}
      </Typography>
    </Box>
  );
};
