import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'factor';
import { Creative } from 'models/Creative';

import { API } from 'api';
import WithErrorPlaceholderImage from '../../../SelectCreativesPopup/withErrorPlacehoderImage/WithErrorPlaceholderImage';

import styles from './index.module.scss';

export interface Props {
  creative: Creative;
  placeholderIcon?: string;
  className?: string;
}

export const ImageCell = (props: Props) => {
  const { creative, className: errorClassName, placeholderIcon: errorIcon } = props;
  const [previewUrl, setPreviewUrl] = useState(creative.creativeThumbnailSource || '');
  const { creativeThumbnailSource } = creative;

  const getCreativeInfo = useCallback(async () => {
    const res = await API.Creatives.FetchCreativeInfo(creative.id);
    return res[0];
  }, [creative.id]);

  const previewCountIterator = useRef(0);

  const loadCreativePreview = useCallback(() => {
    if (creative.previewFlag === 0) {
      // eslint-disable-next-line func-names
      (async function () {
        const { creativeCardSource: localPreviewUrl, previewFlag } = await getCreativeInfo().then(
          (data) => data,
        );
        if (previewCountIterator.current >= 3) {
          return;
        }
        if (previewFlag === 0) {
          setTimeout(() => {
            loadCreativePreview();
            previewCountIterator.current += 1;
          }, 3000);
        }
        if (localPreviewUrl) {
          setPreviewUrl(localPreviewUrl);
        }
      })();
    }
  }, [creative.previewFlag, getCreativeInfo]);

  useEffect(() => {
    loadCreativePreview();
  }, [loadCreativePreview]);

  if (creativeThumbnailSource) {
    return <WithErrorPlaceholderImage src={previewUrl} className={styles.maxHeight80} />;
  }
  return (
    <div className={styles.noDataWrapper}>
      <Icon name={errorIcon || 'NoImage'} className={errorClassName || ''} />
    </div>
  );
};
