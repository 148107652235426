/* global google */
import uniqueId from 'lodash/uniqueId';
import every from 'lodash/every';

import { TAddressData } from 'utils/figures';

// eslint-disable-next-line import/no-cycle
import { GeoRadiusDataTypes } from 'api/Campaign';
// eslint-disable-next-line import/no-cycle
import { shapeType } from 'constants/location';
// eslint-disable-next-line import/no-cycle
import {
  TLatLongNumbers,
  LatLng,
  LatLngLiteral,
  MVCArray,
  Polygon,
  takeFormattedAddress,
} from './Google';

export const KM_TO_MILE = 0.621371;

export enum RadiusUnity {
  mile = 'mile',
  km = 'km',
}

// TODO: Hardcoded for USA now
// TODO: Add correct errors to reject
const checkCountryByCoordinate = (coord: LatLngLiteral): Promise<boolean> => {
  const coder = new google.maps.Geocoder();
  return new Promise<boolean>((resolve, reject) => {
    coder.geocode(
      {
        location: coord,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const foundCountry = results.find((res) => res.types.includes('country'));
          if (foundCountry) {
            if (foundCountry.address_components.find((c) => c.short_name === 'US')) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        } else {
          reject();
        }
      },
    );
  });
};

export class PolygonExtended {
  private readonly original: google.maps.Polygon;

  get lat() {
    return this.getCenter().lat();
  }

  get lng() {
    return this.getCenter().lng();
  }

  id: string = uniqueId('polygon-');

  address: string = '';

  state: string = '';

  country: string = '';

  center: LatLng | null = null;

  radius: number = 0;

  fileId: number | null = null;

  isInclude: boolean;

  type: GeoRadiusDataTypes = shapeType.polygon;

  filename: string | null = null;

  constructor({
    polygon,
    isInclude,
    addressData,
    fileId,
    locationId,
    filename,
  }: {
    polygon: Polygon;
    isInclude: boolean;
    addressData?: TAddressData;
    fileId?: number;
    locationId?: number;
    filename?: string;
  }) {
    this.original = polygon;
    this.isInclude = isInclude;
    if (addressData) {
      this.address = addressData.address;
      this.country = addressData.country || '';
      this.state = addressData.state || '';
    }
    if (fileId) {
      this.fileId = fileId;
    }
    if (filename) {
      this.filename = filename;
    }
    if (locationId) {
      this.id = locationId.toString();
    }
  }

  setIsInclude(isInclude: boolean) {
    this.isInclude = isInclude;
  }

  getPolyPath(): TLatLongNumbers[] {
    const polypath: TLatLongNumbers[] = [];
    this.getPath().forEach((element: LatLng) => {
      polypath.push({
        lat: element.lat(),
        lng: element.lng(),
      });
    });
    return polypath;
  }

  getPaths(): MVCArray<MVCArray<LatLng>> {
    return this.original.getPaths();
  }

  setDraggable = (draggable: boolean) => {
    return this.original.setDraggable(draggable);
  };

  setEditable = (editable: boolean) => {
    return this.original.setEditable(editable);
  };

  getPath(): MVCArray<LatLng> {
    return this.original.getPath();
  }

  getCenter(): LatLng {
    if (this.center) {
      return this.center;
    }
    const bounds = new google.maps.LatLngBounds();
    const vertices = this.getPath();

    vertices.forEach((ver: any) => {
      bounds.extend(ver);
    });

    this.center = bounds.getCenter();
    return this.center;
  }

  getRadius(unity = RadiusUnity.km): number {
    const kmRadius =
      Math.abs(Math.sqrt(google.maps.geometry.spherical.computeArea(this.getPath()) / Math.PI)) /
      1000;

    if (unity === RadiusUnity.km) {
      return +kmRadius.toFixed(2);
    }

    return +(kmRadius * KM_TO_MILE).toFixed(2);
  }

  async update() {
    const { address, state, country } = await takeFormattedAddress(this.getCenter());
    this.address = address;
    this.state = state;
    this.country = country;
    this.center = null;
    this.radius = this.getRadius(RadiusUnity.mile);
  }

  async allCoordinatesInOneCountry(): Promise<boolean> {
    const response = await new Promise<boolean>((resolve, reject) => {
      const path = this.getPath();
      const promises: Promise<boolean>[] = [];

      path.forEach((elem) => {
        promises.push(checkCountryByCoordinate({ lng: elem.lng(), lat: elem.lat() }));
      });

      Promise.all(promises)
        .then((responses) => {
          resolve(every(responses));
        })
        .catch(() => {
          reject();
        });
    });
    return response;
  }
}
