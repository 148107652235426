import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Col,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Row,
  Typography,
} from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import { connect, useDispatch } from 'react-redux';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { advancedTargetingActions } from 'store/advancedTargeting/actions';
import { filterLabels } from 'store/advancedTargeting/constants';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { AdvancedTargetingState } from 'store/advancedTargeting/reducer';
import { TextAreaWithCSVUpload } from './components/TextAreaWithCSVUpload';

export interface AllowlistDialogProps {
  open: boolean;
  closeDialog: () => any;
  editableCampaign: ExistingCampaignData | null;
  advancedTargeting: AdvancedTargetingState;
}

export const allowFields: (
  | 'whiteListedPackageName'
  | 'whiteListedAppId'
  | 'whiteListedSiteDomain'
)[] = ['whiteListedPackageName', 'whiteListedAppId', 'whiteListedSiteDomain'];

export const AllowlistDialogComponent = (props: AllowlistDialogProps) => {
  const { open, closeDialog, advancedTargeting } = props;
  const sideBarInfo = advancedTargeting.sidebarCampaignInfo;

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    whiteListedPackageName: sideBarInfo.selectedWhiteListedPackageName,
    whiteListedAppId: sideBarInfo.selectedWhiteListedAppId,
    whiteListedSiteDomain: sideBarInfo.selectedWhiteListedSiteDomain,
  });

  useEffect(() => {
    if (advancedTargeting.sidebarCampaignInfo) {
      allowFields.forEach((field) => {
        const val = advancedTargeting.sidebarCampaignInfo[CampaignInfoField[field]];
        if (val) {
          setValues((prev) => ({ ...prev, [field]: val }));
        }
      });
    }
  }, [advancedTargeting.sidebarCampaignInfo]);

  const disable = React.useMemo(() => {
    const isError = Object.values(values).some(
      (val) => val.split(',').filter(Boolean).length > 500,
    );
    const hasChanges =
      values.whiteListedPackageName !== sideBarInfo.selectedWhiteListedPackageName ||
      values.whiteListedAppId !== sideBarInfo.selectedWhiteListedAppId ||
      values.whiteListedSiteDomain !== sideBarInfo.selectedWhiteListedSiteDomain;
    return isError || !hasChanges;
  }, [sideBarInfo, values]);

  const handleAdd = () => {
    allowFields.forEach((field) => {
      dispatch(
        advancedTargetingActions.selectAdvancedTargetingField({
          key: CampaignInfoField[field],
          value: values[field],
        }),
      );
    });
    closeDialog();
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle onClose={closeDialog}>Allowlist</DialogTitle>
      <DialogContent dividers sx={{ px: 24, py: 32 }}>
        <Row>
          <Col>
            <TextAreaWithCSVUpload
              label={filterLabels.whiteListedPackageName}
              tooltipMessage="List of the Android Package Names targeted for this campaign"
              value={values.whiteListedPackageName}
              placeholder="Enter Android Package Names"
              handleChange={(val) =>
                setValues((prev) => ({ ...prev, whiteListedPackageName: val }))
              }
            />
          </Col>
          <Col>
            <TextAreaWithCSVUpload
              label={filterLabels.whiteListedAppId}
              tooltipMessage="List of the iOS Apps IDs targeted for this campaign"
              value={values.whiteListedAppId}
              placeholder="Enter iOS apps IDs"
              handleChange={(val) => setValues((prev) => ({ ...prev, whiteListedAppId: val }))}
            />
          </Col>
          <Col>
            <TextAreaWithCSVUpload
              label={filterLabels.whiteListedSiteDomain}
              tooltipMessage="List of the Site Domains targeted for this campaign"
              value={values.whiteListedSiteDomain}
              placeholder="Enter Site Domains"
              handleChange={(val) => setValues((prev) => ({ ...prev, whiteListedSiteDomain: val }))}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', width: 100, justifyContent: 'between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoCircle sx={{ textColor: 'neutral-400', mr: 8, fontSize: 24 }} />
            <Typography>
              The campaign app allows up to 500 records. Use Inventory app for more.
            </Typography>
          </Box>
          <Box>
            <Button color="secondary" onClick={closeDialog} sx={{ mr: 16 }}>
              Cancel
            </Button>
            <Button onClick={handleAdd} disabled={disable}>
              Add
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  editableCampaign: state.app.editableCampaign,
  advancedTargeting: state.advancedTargeting,
});

export const AllowlistDialog = connect(mapState)(AllowlistDialogComponent);
