import * as React from 'react';
import {
  Badge,
  Box,
  SelectAdvanceComponents,
  TextField,
  TypoTooltip,
  Typography,
} from '@applift/factor';
import { PgDealResponseType } from 'models/Inventory';
import { RowSelectionState } from '@applift/datagrid';
import { MAXIMUM_DEALS_SELECTION } from 'constants/pgDeals';

import styles from './styles.module.scss';

export interface PgDealsSelectProps {
  pgDealsList?: {
    totalRecords?: number;
    filteredRecords?: number;
    pgDealData?: PgDealResponseType[] | undefined;
  };
  pgDealsSearch?: string;
  selectedPgDeals?: PgDealResponseType[];
  setSelectedPgDeals?: (val: any) => void;
  isPgDetailsListLoading: boolean;
  setPgDealsSearch: (val: string) => void;
  fetchNextPgDealsListPage: () => void;
}

export const PgDealsSelect = (props: PgDealsSelectProps) => {
  const apiRef = SelectAdvanceComponents.useSelectAdvanceApiRef<PgDealResponseType>();
  const {
    pgDealsList: pgDealListResponse,
    setPgDealsSearch,
    pgDealsSearch,
    isPgDetailsListLoading,
    fetchNextPgDealsListPage,
    selectedPgDeals,
    setSelectedPgDeals,
  } = props;

  const pgDealsList = React.useMemo(
    // @ts-ignore
    () => pgDealListResponse?.pgDealData || [],
    // @ts-ignore
    [pgDealListResponse?.pgDealData],
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const renderItem = SelectAdvanceComponents.DefaultListOptionItemWrapper<PgDealResponseType>({
    disableCheckbox: true,
    selectionStyle: 'none',
    arrowPosition: 'right',
  });

  const handleTextfieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    Object.keys({}).reduce((prev, one) => {
      // eslint-disable-next-line no-param-reassign
      prev[one] = true;
      return prev;
    }, {} as RowSelectionState),
  );

  const overlay = React.useMemo(() => {
    if (!isPgDetailsListLoading) {
      if (!Array.isArray(pgDealsList)) {
        return 'error';
      }
      if ((pgDealsList?.length ?? 0) <= 0) {
        if (pgDealsSearch) {
          return 'noResult';
        }
        return 'noRows';
      }
    }
    return undefined;
  }, [isPgDetailsListLoading, pgDealsList, pgDealsSearch]);

  const setSelectedPgDealsWrapper = (selectedRows: { [key: number]: boolean }) => {
    if (setSelectedPgDeals) {
      let action;
      if (Object.keys(selectedRows).filter((val) => rowSelection[val] === undefined).length) {
        action = 'add';
      } else {
        action = 'remove';
      }

      if (action === 'add') {
        const allSelectedItemsInArray = Object.keys(selectedRows);

        const selectedItemsInObject = selectedPgDeals
          ? Object.fromEntries(selectedPgDeals?.map((val) => [val.id, val]))
          : {};

        const itemsToAdd = allSelectedItemsInArray.filter((val) => !selectedItemsInObject[+val]);

        setSelectedPgDeals((val: any) => [
          ...(val?.length ? val : []),
          ...pgDealsList.filter((val: { id: any }) => itemsToAdd.includes(String(val.id))),
        ]);
      } else if (action === 'remove') {
        const itemToRemove = selectedPgDeals?.filter((val) => !selectedRows[val.id])[0];
        setSelectedPgDeals((val: any[]) =>
          val.filter((innerVal: { id: number | undefined }) => innerVal.id !== itemToRemove?.id),
        );
      }
    }
  };

  const rowSelectionWrapper = (updater: any) => {
    const selectedRows = typeof updater === 'function' ? updater(rowSelection) : updater;
    const updateKey = Object.keys(updater())[0];
    if (Object.keys(rowSelection).length >= MAXIMUM_DEALS_SELECTION && updateKey) {
      return null;
    }

    setSelectedPgDealsWrapper(selectedRows);

    return setRowSelection(updater);
  };

  React.useEffect(() => {
    if (selectedPgDeals?.length) {
      const localRowSelection = Object.fromEntries(selectedPgDeals.map((val) => [val.id, true]));
      setRowSelection(localRowSelection);
    } else if (selectedPgDeals?.length === 0) {
      setRowSelection({});
    }
  }, [selectedPgDeals]);

  return (
    <TextField
      size="medium"
      variant="outlinedDash"
      label="PG Deals"
      select="advanceSelect"
      placeholder="Select PG Deals"
      onClick={(e) => handleTextfieldClick(e)}
      style={{ width: 208 }}
      onChange={rowSelectionWrapper}
      value={rowSelection}
      SelectProps={{
        getRowId: (row) => String(row.id),
        slotProps: {
          InputBaseProps: {
            onChange: (e) => setPgDealsSearch(e.target.value),
            value: pgDealsSearch,
            placeholder: 'Search by Deal ID or Name',
          },
          PaperProps: {
            style: {
              maxWidth: 500,
            },
          },
          PopperProps: {
            anchorEl,
            open,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          },
        },
        data: pgDealsList || [],
        loading: isPgDetailsListLoading,
        multiple: true,
        apiRef,
        rowCount: (pgDealListResponse?.filteredRecords ?? 0) - 1,
        rowHeight: 10,
        pageSize: 5,
        renderListItem: renderItem,
        hideSelectNav: true,
        renderValue: (val) => {
          // @ts-ignore
          return `${val?.length} Selected`;
        },
        onFetchRows: () => {
          fetchNextPgDealsListPage();
        },
        getOptionDisabled: (option) => {
          const isOptionDisabled =
            !rowSelection[option.id] && Object.keys(rowSelection).length >= MAXIMUM_DEALS_SELECTION;
          return isOptionDisabled;
        },
        getOptionLabel: (data) => String(data.id),
        overlay,
        renderOption: ({ row }) => {
          return (
            <Box>
              <Box>
                <TypoTooltip
                  arrow
                  TypgraphyProps={{
                    component: 'h2',
                    variant: 'bodyMedium',
                    weight: 'demi',
                    sx: { textColor: 'neutral-1000' },
                    style: { width: '460px', marginBottom: 0 },
                  }}
                >
                  {row.original.dealId}
                </TypoTooltip>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Box
                  style={{ maxWidth: '150px' }}
                  sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}
                >
                  <Typography sx={{ textColor: 'neutral-400' }} weight="demi" variant="label">
                    Name
                  </Typography>
                  <TypoTooltip
                    arrow
                    sx={{ textColor: 'neutral-1000', display: 'inline' }}
                    TypgraphyProps={{
                      component: 'p',
                      variant: 'label',
                      sx: { textColor: 'neutral-1000' },
                    }}
                  >
                    {row.original.dealName}
                  </TypoTooltip>
                </Box>
                <Typography sx={{ textColor: 'neutral-400', mx: 4 }}>•</Typography>
                <Box
                  style={{ maxWidth: '64px' }}
                  sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}
                >
                  {row.original.statusId === 1 ? (
                    <Badge
                      variant="dot"
                      size="small"
                      classes={{
                        badge: styles.badgeActiveClass,
                      }}
                    />
                  ) : (
                    <Badge
                      variant="dot"
                      size="small"
                      classes={{
                        badge: styles.badgeInactiveClass,
                      }}
                    />
                  )}
                  <TypoTooltip
                    arrow
                    sx={{ textColor: 'neutral-1000' }}
                    TypgraphyProps={{
                      component: 'p',
                      variant: 'label',
                      sx: { textColor: 'neutral-1000' },
                    }}
                  >
                    {row.original.statusLabel}
                  </TypoTooltip>
                </Box>
                <Typography sx={{ textColor: 'neutral-400', mx: 4 }}>•</Typography>
                <Box
                  style={{ maxWidth: '150px' }}
                  sx={{ display: 'flex', alignItems: 'center', gapCol: 4 }}
                >
                  <Typography sx={{ textColor: 'neutral-400' }} weight="demi" variant="label">
                    Exchange
                  </Typography>
                  <TypoTooltip
                    arrow
                    sx={{ textColor: 'neutral-1000' }}
                    TypgraphyProps={{
                      component: 'p',
                      variant: 'label',
                      sx: { textColor: 'neutral-1000' },
                    }}
                  >
                    {row.original.exchangeName}
                  </TypoTooltip>
                </Box>
              </Box>
            </Box>
          );
        },
      }}
    />
  );
};
