import * as React from 'react';
import { Container } from '@applift/factor';
import { List } from './List';

import style from './styles.module.scss';

export const IoListPage = () => {
  return (
    <Container className={style.container}>
      <List />
    </Container>
  );
};
