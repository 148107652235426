import * as React from 'react';
import { useHistory } from 'react-router';
import { getPathNumber } from 'iqm-framework';
import moment from 'moment';
import {
  Row,
  Col,
  TextField,
  IconButton,
  Button,
  Chip,
  Box,
  enqueueSnackbar,
  Tooltip,
} from '@applift/factor';
import {
  Search,
  CaretDown,
  Edit,
  Delete,
  Duplicate,
  Wallet,
  CalendarDollar,
  Bid,
  Close,
  AppReports,
  AppBidModel,
  FileCsv,
  FileXlsx,
  AppInsights,
} from '@applift/icons';
import { ColumnVisibility, RowSelectionState, VisibilityState } from '@applift/datagrid';

import { useSaveColCampaign } from 'hooks/useCampaignList';
import { useAllowedApplicationsList } from 'hooks/useApp';
import { CampaignListFileType, CampaignListType } from 'models/CampaignList';
import { DateRange } from 'models/Date';
import { OptionId } from 'models/Option';
import { localStorageService } from 'services/localStorage';
import { getAccountType } from 'utils/getAccountType';
import { CAMPAIGN_STATUSES, CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { IODetail } from 'models/IO';
import { DISABLE_CAMPAIGN_PRIORITY_END_DATE } from 'constants/advancedModelling';
import { User } from 'models/User';
import { showCampaignInsights } from 'utils/showCampaignInsights';
import { BID_MODEL_APP_NAME, INSIGHTS_APP_NAME, REPORT_APP_NAME } from 'constants/apps';
import { ButtonMenu } from '../ButtonMenu/ButtonMenu';

interface ActionPanelProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  totalCount?: number;
  selectedItems: CampaignListType[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setDialogToShow: React.Dispatch<
    React.SetStateAction<
      | 'duplicate'
      | 'endDate'
      | 'budgetTotal'
      | 'budgetDaily'
      | 'maxBid'
      | 'delete'
      | 'run'
      | 'restore'
      | 'pause'
      | 'priority'
      | null
    >
  >;
  timezoneFilter: OptionId<string> | undefined;
  dateRange: DateRange | undefined;
  columnVisibility: VisibilityState;
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>;
  defaultData: [any[], VisibilityState];
  isCampaignListDownloading: boolean;
  downloadCampaignList: (arg: CampaignListFileType) => void;
  ioDetail?: IODetail;
  userData: User;
  isVldEnabled?: boolean;
  hadVldGenerated?: boolean;
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    totalCount,
    search,
    setSearch,
    setDialogToShow,
    setRowSelection,
    selectedItems,
    timezoneFilter,
    dateRange,
    columnVisibility,
    setColumnVisibility,
    defaultData,
    downloadCampaignList,
    isCampaignListDownloading,
    ioDetail,
    userData,
    isVldEnabled = false,
    hadVldGenerated = false,
  } = props;

  const history = useHistory();

  const [changeAnchorEl, setChangeAnchorEl] = React.useState<null | HTMLElement>(null);
  const [budgetAnchorEl, setBudgetAnchorEl] = React.useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showChangeMenu, setChangeMenu] = React.useState(false);
  const [showBudgetMenu, setBudgetMenu] = React.useState(false);
  const [showMoreMenu, setMoreMenu] = React.useState(false);

  const { data: allowedApplicationsList } = useAllowedApplicationsList();

  const onOptimizeClick = () => {
    if (dateRange && timezoneFilter) {
      const selectedCampaign = selectedItems[0];
      const tzId = timezoneFilter.id.toString();
      const startDate = moment(dateRange.start).valueOf();
      const endDate = moment(dateRange.end).valueOf();
      const params = {
        startDate: `${startDate}`,
        endDate: `${endDate}`,
        timezone: tzId,
      };
      const paramsString = new URLSearchParams(params).toString();
      const link = `/bidmodel/u/${getPathNumber()}/#/creative/${selectedCampaign.campaignId.toString()}?${paramsString}`;
      window.open(link, '_blank');
    }
  };

  const onReportClick = () => {
    if (dateRange && timezoneFilter) {
      const selectedCampaigns = selectedItems.map((item) => item.campaignId).join(',');
      const campaignsOwIds = Array.from(
        selectedItems.reduce((acc, item) => {
          if (item.owId) {
            acc.add(item.owId);
          }
          return acc;
        }, new Set<number>()),
      );

      const startDate = moment(dateRange.start).valueOf().toString();
      const endDate = moment(dateRange.end).valueOf().toString();
      const params = {
        source: 'campaignsApp',
        campaignIds: selectedCampaigns,
        startDate,
        endDate,
        timezone: timezoneFilter.id.toString(),
        timezoneValue: timezoneFilter.value,
        owIds: getAccountType() !== 'Advertiser' ? campaignsOwIds : undefined,
      };

      try {
        localStorageService.saveLocal('reportDraft', params);
        const link = `/reports/u/${getPathNumber()}/#/create-from-draft`;
        window.open(link, '_blank');
      } catch (err) {
        enqueueSnackbar('Failed to save report', { variant: 'error' });
      }
    }
  };

  const onViewBiddingInsightsClick = () => {
    const selectedCampaign = selectedItems[0];
    const link = `/insights/u/${getPathNumber()}/#/campaign-bidding-insights/${selectedCampaign.campaignId.toString()}`;
    window.open(link, '_blank');
  };

  const onViewVldInsightsClick = () => {
    const selectedCampaign = selectedItems[0];
    const link = `/insights/u/${getPathNumber()}/#/voter-level-data/create?campaignId=${
      selectedCampaign.campaignId
    }`;
    window.open(link, '_blank');
  };

  const chipLabel = React.useMemo(() => {
    return `${selectedItems.length} of ${totalCount} selected`;
  }, [selectedItems, totalCount]);

  const hideBudgetMenu = () => {
    setBudgetAnchorEl(null);
    setBudgetMenu(false);
  };

  const hideChangeMenu = () => {
    setChangeAnchorEl(null);
    setChangeMenu(false);
  };

  const atleastOneItemHasVal = React.useCallback(
    (key: keyof CampaignListType, valArr: any[]) =>
      selectedItems.some((item) => valArr.includes(item[key])),
    [selectedItems],
  );

  const hasOnlyMentionedValues = (key: keyof CampaignListType, valArr: any[]) =>
    selectedItems.every((item) => valArr.includes(item[key]));

  const isSingleSelect = selectedItems.length === 1;

  const isPgCampaignSelected = Boolean(
    selectedItems.filter((val) => CAMPAIGN_TYPE_BY_NAME.pg === val.campaignTypeId).length,
  );

  const isAdvancedCampaignSelected = Boolean(
    selectedItems.filter((val) => CAMPAIGN_TYPE_BY_NAME.advanced === val.campaignTypeId).length,
  );

  const isExpCampaignTooOld =
    selectedItems.length &&
    atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.EXPIRED]) &&
    Math.abs(
      moment.tz(moment().utc(), 'UTC').tz(moment.tz.guess()).unix() - selectedItems[0].endTime,
    ) /
      (60 * 60 * 24) >
      14;

  const BUDGET_CAMPAIGN_ACTIONS = [
    {
      label: 'Total Budget',
      icon: <Wallet fontSize={24} sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {
        hideBudgetMenu();
        setDialogToShow('budgetTotal');
      },
      disabled: isPgCampaignSelected,
    },
    {
      label: 'Daily Budget',
      icon: <CalendarDollar fontSize={24} sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {
        hideBudgetMenu();
        setDialogToShow('budgetDaily');
      },
      disabled: isPgCampaignSelected,
    },
    {
      label: 'Max Bid Price',
      icon: <Bid fontSize={24} sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {
        hideBudgetMenu();
        setDialogToShow('maxBid');
      },
    },
  ];

  const isEditActionDisable =
    !isSingleSelect ||
    atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.EXPIRED, CAMPAIGN_STATUSES.DELETED]);

  const isDeleteActionDisable = atleastOneItemHasVal('status', [
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.DELETED,
  ]);

  const CHANGE_CAMPAIGN_ACTIONS = [
    {
      label: <Box>Edit</Box>,
      icon: <Edit fontSize={24} sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {
        const isAdvancedCampaign =
          selectedItems[0].campaignTypeId === CAMPAIGN_TYPE_BY_NAME.advanced;
        history.push(
          `/${isAdvancedCampaign ? 'campaign-edit' : 'campaign-edit-pg'}/${
            selectedItems[0].campaignId
          }`,
        );
        hideChangeMenu();
      },
      disabled: isEditActionDisable,
    },
    {
      label: 'Duplicate',
      icon: <Duplicate fontSize={24} sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {
        hideChangeMenu();
        setDialogToShow('duplicate');
      },
      disabled: isPgCampaignSelected && selectedItems.length > 1,
    },
    {
      label: 'Delete',
      icon: <Delete fontSize={24} sx={{ textColor: 'neutral-400' }} />,
      onClick: () => {
        hideChangeMenu();
        setDialogToShow('delete');
      },
      disabled: isDeleteActionDisable,
    },
  ];

  const disableReportBtn =
    !hasOnlyMentionedValues('status', [
      CAMPAIGN_STATUSES.PAUSED,
      CAMPAIGN_STATUSES.EXPIRED,
      CAMPAIGN_STATUSES.RUNNING,
      CAMPAIGN_STATUSES.DELETED,
    ]) ||
    (isPgCampaignSelected && isAdvancedCampaignSelected);

  const disableOptimizeBtn =
    !hasOnlyMentionedValues('status', [
      CAMPAIGN_STATUSES.EXPIRED,
      CAMPAIGN_STATUSES.RUNNING,
      CAMPAIGN_STATUSES.DELETED,
      CAMPAIGN_STATUSES.PAUSED,
      CAMPAIGN_STATUSES.DRAFT,
      CAMPAIGN_STATUSES.PENDING,
    ]) ||
    !isSingleSelect ||
    isPgCampaignSelected;

  const disableBiddingInsightBtn =
    !hasOnlyMentionedValues('status', [
      CAMPAIGN_STATUSES.RUNNING,
      CAMPAIGN_STATUSES.PAUSED,
      CAMPAIGN_STATUSES.EXPIRED,
    ]) ||
    !isSingleSelect ||
    isPgCampaignSelected ||
    isExpCampaignTooOld;

  const disableVldInsightButton =
    !isVldEnabled || !isSingleSelect || !selectedItems?.[0]?.isEligibleForVLDInsightsReport;

  const showRunBtn = atleastOneItemHasVal('status', [
    CAMPAIGN_STATUSES.PAUSED,
    CAMPAIGN_STATUSES.EXPIRED,
  ]);
  const disableRunBtn = !hasOnlyMentionedValues('status', [
    CAMPAIGN_STATUSES.PAUSED,
    CAMPAIGN_STATUSES.EXPIRED,
  ]);

  const showPauseBtn = atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.RUNNING]);
  const disablePauseBtn = !hasOnlyMentionedValues('status', [CAMPAIGN_STATUSES.RUNNING]);

  const hideBudgetMenuBtn = hasOnlyMentionedValues('status', [
    CAMPAIGN_STATUSES.DELETED,
    CAMPAIGN_STATUSES.EXPIRED,
  ]);

  const disableChangeMenuBtn = isPgCampaignSelected && isAdvancedCampaignSelected;

  const disableBudgetMenuBtn =
    atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.DELETED, CAMPAIGN_STATUSES.EXPIRED]) ||
    (isPgCampaignSelected && isAdvancedCampaignSelected);

  const hideEndDateBtn = hasOnlyMentionedValues('status', [
    CAMPAIGN_STATUSES.DELETED,
    CAMPAIGN_STATUSES.EXPIRED,
  ]);

  const disableEndDateBtn =
    atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.DELETED, CAMPAIGN_STATUSES.EXPIRED]) ||
    (isPgCampaignSelected && isAdvancedCampaignSelected);

  const hideSetPriorityButton =
    hasOnlyMentionedValues('status', [CAMPAIGN_STATUSES.DELETED, CAMPAIGN_STATUSES.EXPIRED]) ||
    (isPgCampaignSelected && !isAdvancedCampaignSelected);

  const disableChangeButtonTooltip = React.useMemo(() => {
    if (isPgCampaignSelected && isAdvancedCampaignSelected) {
      return 'Actions are not applicable if PG and Advanced campaigns are selected together';
    }
    return false;
  }, [isAdvancedCampaignSelected, isPgCampaignSelected]);

  const disableSetBudgetButtonTooltip = React.useMemo(() => {
    if (isPgCampaignSelected && isAdvancedCampaignSelected) {
      return 'Actions are not applicable if PG and Advanced campaigns are selected together';
    }
    return false;
  }, [isAdvancedCampaignSelected, isPgCampaignSelected]);

  const disableSetEndDateButtonTooltip = React.useMemo(() => {
    if (isPgCampaignSelected && isAdvancedCampaignSelected) {
      return 'Actions are not applicable if PG and Advanced campaigns are selected together';
    }
    return false;
  }, [isAdvancedCampaignSelected, isPgCampaignSelected]);

  const disableReportButtonTooltip = React.useMemo(() => {
    if (isPgCampaignSelected && isAdvancedCampaignSelected) {
      return 'Actions are not applicable if PG and Advanced campaigns are selected together';
    }
    return false;
  }, [isAdvancedCampaignSelected, isPgCampaignSelected]);

  const disableOptimizeButtonTooltip = React.useMemo(() => {
    if (isPgCampaignSelected && isAdvancedCampaignSelected) {
      return 'Actions are not applicable if PG and Advanced campaigns are selected together';
    }
    if (isPgCampaignSelected && selectedItems.length === 1) {
      return "PG Campaigns can't be optimized";
    }
    return false;
  }, [isAdvancedCampaignSelected, isPgCampaignSelected, selectedItems.length]);

  const disableSetPriorityButtonTooltip = React.useMemo(() => {
    if (isPgCampaignSelected && isAdvancedCampaignSelected) {
      return 'Actions are not applicable if PG and Advanced campaigns are selected together';
    }
    if (!ioDetail?.ioEndTime) {
      return DISABLE_CAMPAIGN_PRIORITY_END_DATE;
    }

    const isExpiredSelected = atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.EXPIRED]);
    const isDeletedSelected = atleastOneItemHasVal('status', [CAMPAIGN_STATUSES.DELETED]);

    if (isExpiredSelected && isDeletedSelected) {
      return 'Priority cannot be added or edited for the campaigns with expired/deleted status';
    }

    if (isExpiredSelected || isDeletedSelected) {
      let statusText = 'expired/deleted';
      if (isExpiredSelected && !isDeletedSelected) {
        statusText = 'expired';
      }
      if (isDeletedSelected && !isExpiredSelected) {
        statusText = 'deleted';
      }
      return `Priority cannot be added or edited for the ${statusText} campaigns`;
    }

    return '';
  }, [atleastOneItemHasVal, ioDetail?.ioEndTime, isAdvancedCampaignSelected, isPgCampaignSelected]);

  const saveMutation = useSaveColCampaign();

  const isUserAllowedForBiddingInsights = showCampaignInsights(userData?.email);

  const setColumnVisibilityWrapper = (value: any) => {
    const col = value(columnVisibility);
    saveMutation.mutate({
      config: { columnVisibility: col },
    });
    setColumnVisibility(value);
  };

  const isInsightsAppAllowed =
    allowedApplicationsList &&
    allowedApplicationsList.find((app) => app.appName === INSIGHTS_APP_NAME);

  const isReportsAllowed = !!(
    allowedApplicationsList &&
    allowedApplicationsList.find((app) => app.appName === REPORT_APP_NAME)
  );
  const isBidModelAllowed = !!(
    allowedApplicationsList &&
    allowedApplicationsList.find((app) => app.appName === BID_MODEL_APP_NAME)
  );
  const isBiddingInsightsAllowed = !!(isInsightsAppAllowed && isUserAllowedForBiddingInsights);
  const isVldInsightsAllowed = !!(isInsightsAppAllowed && (isVldEnabled || hadVldGenerated));

  const hideMoreButton = !isBiddingInsightsAllowed && !isBidModelAllowed && !isReportsAllowed;

  const MORE_OPTIONS = [
    {
      label: 'Create Report',
      icon: <AppReports fontSize={24} />,
      onClick: onReportClick,
      disabled: disableReportBtn,
      tooltipTitle: disableReportButtonTooltip,
      hidden: !isReportsAllowed,
    },
    {
      label: 'Optimize Campaign',
      icon: <AppBidModel fontSize={24} />,
      onClick: onOptimizeClick,
      disabled: disableOptimizeBtn,
      tooltipTitle: disableOptimizeButtonTooltip,
      hidden: !isBidModelAllowed,
    },
    {
      label: 'View Bidding Insights',
      icon: <AppInsights fontSize={24} />,
      onClick: onViewBiddingInsightsClick,
      disabled: disableBiddingInsightBtn,
      hidden: !isBiddingInsightsAllowed,
    },
    {
      label: 'Generate VLD Insights',
      icon: <AppInsights fontSize={24} />,
      onClick: onViewVldInsightsClick,
      disabled: disableVldInsightButton,
      hidden: !isVldInsightsAllowed,
      tooltipTitle:
        hadVldGenerated && !isVldEnabled
          ? 'Voter Level Data generation is disabled for your account.'
          : undefined,
    },
  ];
  return (
    <Row xs={{ gutterSize: 0, gutterDirection: 'x' }} sx={{ px: 24, alignItems: 'center' }}>
      {selectedItems.length > 0 && (
        <Col xs={8}>
          <Chip
            size="small"
            label={chipLabel}
            color="secondary"
            onDelete={() => setRowSelection({})}
          />
          <Tooltip title={disableChangeButtonTooltip} arrow>
            <Box sx={{ display: 'inline' }}>
              {showPauseBtn && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  disabled={disablePauseBtn}
                  sx={{ marginLeft: 8 }}
                  onClick={() => setDialogToShow('pause')}
                >
                  Pause
                </Button>
              )}
            </Box>
          </Tooltip>
          <Tooltip title={disableChangeButtonTooltip} arrow>
            <Box sx={{ display: 'inline' }}>
              {showRunBtn && (
                <Button
                  size="small"
                  color="primary"
                  disabled={disableRunBtn}
                  variant="contained"
                  sx={{ marginLeft: 8 }}
                  onClick={() => setDialogToShow('run')}
                >
                  Run
                </Button>
              )}
            </Box>
          </Tooltip>
          <Tooltip title={disableChangeButtonTooltip} arrow>
            <Box sx={{ display: 'inline' }}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={{ marginLeft: 8 }}
                endIcon={<CaretDown />}
                onClick={(e) => {
                  setChangeAnchorEl(e.currentTarget);
                  setChangeMenu(true);
                }}
                disabled={disableChangeMenuBtn}
              >
                Change
              </Button>
              <ButtonMenu
                anchorEl={changeAnchorEl}
                buttons={CHANGE_CAMPAIGN_ACTIONS}
                onClose={() => {
                  setChangeMenu(false);
                }}
                open={showChangeMenu}
              />
            </Box>
          </Tooltip>
          {!hideBudgetMenuBtn && (
            <Tooltip title={disableSetBudgetButtonTooltip} arrow>
              <Box sx={{ display: 'inline' }}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  disabled={disableBudgetMenuBtn}
                  endIcon={<CaretDown />}
                  sx={{ marginLeft: 8 }}
                  onClick={(e) => {
                    setBudgetAnchorEl(e.currentTarget);
                    setBudgetMenu(true);
                  }}
                >
                  Set Budget
                </Button>
                <ButtonMenu
                  anchorEl={budgetAnchorEl}
                  buttons={BUDGET_CAMPAIGN_ACTIONS}
                  onClose={() => {
                    setBudgetMenu(false);
                  }}
                  open={showBudgetMenu}
                />
              </Box>
            </Tooltip>
          )}
          <Tooltip title={disableSetEndDateButtonTooltip} arrow>
            <Box sx={{ display: 'inline' }}>
              {!hideEndDateBtn && (
                <Button
                  size="small"
                  color="primary"
                  disabled={disableEndDateBtn}
                  variant="outlined"
                  sx={{ marginLeft: 8 }}
                  onClick={() => setDialogToShow('endDate')}
                >
                  Set End Date
                </Button>
              )}
            </Box>
          </Tooltip>
          {!hideSetPriorityButton && (
            <Tooltip title={disableSetPriorityButtonTooltip} arrow>
              <Box sx={{ display: 'inline-block' }}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  sx={{ ml: 8 }}
                  onClick={() => setDialogToShow('priority')}
                  disabled={!!disableSetPriorityButtonTooltip}
                >
                  Set Priority
                </Button>
              </Box>
            </Tooltip>
          )}

          {!hideMoreButton && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              sx={{ marginLeft: 8 }}
              endIcon={<CaretDown />}
              onClick={(e) => {
                setMoreAnchorEl(e.currentTarget);
                setMoreMenu(true);
              }}
            >
              More
            </Button>
          )}
          <ButtonMenu
            anchorEl={moreAnchorEl}
            buttons={MORE_OPTIONS}
            onClose={() => {
              setMoreMenu(false);
            }}
            open={showMoreMenu}
          />
        </Col>
      )}
      <Col
        xs={4}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          ml: 'auto',
        }}
      >
        <Box
          sx={{
            ml: 'auto',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            py: 8,
            px: 24,
          }}
        >
          <TextField
            placeholder="Search by ID, Name, Status"
            InputProps={{
              startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
              endAdornment:
                search?.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                ) : null,
            }}
            style={{ width: '230px' }}
            variant="outlinedDash"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
              setRowSelection({});
            }}
          />
          <IconButton
            sx={{ mx: 4, p: 0 }}
            onClick={() => downloadCampaignList('csv')}
            disabled={isCampaignListDownloading}
          >
            <Tooltip title="Download CSV" placement="top" arrow>
              <FileCsv fontSize={24} sx={{ textColor: 'neutral-400' }} />
            </Tooltip>
          </IconButton>
          <IconButton
            sx={{ mx: 4, p: 0 }}
            onClick={() => downloadCampaignList('xlsx')}
            disabled={isCampaignListDownloading}
          >
            <Tooltip title="Download XLSX" placement="top" arrow>
              <FileXlsx fontSize={24} sx={{ textColor: 'neutral-400' }} />
            </Tooltip>
          </IconButton>
          <ColumnVisibility
            data={defaultData[0]}
            onChange={setColumnVisibilityWrapper}
            value={columnVisibility}
            slotProps={{
              SelectAdvancePopoverProps: {
                slotProps: {
                  // @ts-ignore
                  PopperProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                  },
                },
              },
              IconButtonProps: { color: 'secondary' },
            }}
          />
        </Box>
      </Col>
    </Row>
  );
};
