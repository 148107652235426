import * as React from 'react';

import { Box } from '@applift/factor';
import { GridActionCellParams } from '@applift/datagrid';

import { IoListType } from 'models/IoList';
import { IconTextCell } from 'components/CellType/IconTextCell';

interface IoStatusCellType {
  action?: (args: GridActionCellParams) => any;
  rowData?: IoListType;
}

const StatusMapper = {
  1: {
    label: 'Active',
    key: 'active',
    iconName: 'Ready',
  },
  2: {
    label: 'Expired',
    key: 'expired',
    iconName: 'Expired',
  },
  3: {
    label: 'Deleted',
    key: 'deleted',
    iconName: 'Deleted',
  },
};

export const IoStatusCell = ({ action, rowData }: IoStatusCellType) => {
  const statusId = rowData?.ioStatusId;
  return statusId ? (
    <Box>
      <IconTextCell
        // @ts-ignore
        iconName={StatusMapper[statusId].iconName as string}
        // @ts-ignore
        label={StatusMapper[statusId].label as string}
        iconSize={20}
      />
    </Box>
  ) : null;
};
