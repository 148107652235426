import React from 'react';
import { Icon } from 'factor';

import { FileUploader } from 'components/FileUploader';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  helpText?: React.ReactNode;
  downHelpText?: React.ReactNode;
  sampleFileURL: string;
  id: string;
  onFileUploaded: (fileList: FileList) => void;
  errorMessage: string | JSX.Element;
  accept?: string;
  currentSelection?: 'locations' | 'addresses' | 'polygons';
}

export const UploadWithText = (props: Props) => {
  const {
    className,
    helpText,
    downHelpText,
    sampleFileURL,
    id,
    onFileUploaded,
    errorMessage,
    accept,
    currentSelection,
  } = props;

  return (
    <div className={`d-flex flex-column ${className}`}>
      {helpText && <div className={styles.helpText}>{helpText}</div>}
      <div className="d-flex">
        <FileUploader
          onFileUploaded={onFileUploaded}
          browseFileButtonTitle="Upload File"
          id={id}
          accept={accept}
          currentSelection={currentSelection}
        />
        <a tabIndex={0} href={sampleFileURL} download className={styles.downloadBtnContainer}>
          Download Sample File
        </a>
      </div>
      {downHelpText && !errorMessage && (
        <div className={styles.helperText}>
          <Icon name="Info" />
          <div className={`mb-0 ${styles.helpText}`}>{downHelpText}</div>
        </div>
      )}
      <div className={`mt-2 mb-0 ${styles.errorMessage}`}>{errorMessage}</div>
    </div>
  );
};
