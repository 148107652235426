import * as React from 'react';
import {
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  TypoTooltip,
  Typography,
} from '@applift/factor';

import { CreativesList } from 'components/CreativesTable/CreativesList';
import { CampaignListType } from 'models/CampaignList';
import { creativeTypeWithIconMap } from 'models/Creative';
import { FormattedNumberCell } from './FormattedNumberCell';

interface CreativesCellType {
  campaignData: CampaignListType;
  cellDisabled?: boolean;
  rumActionName?: string;
  rumLogClick?: () => void;
}

export const CreativesCell = ({
  campaignData,
  rumActionName,
  rumLogClick,
  cellDisabled = false,
}: CreativesCellType) => {
  const [showCreativesInfo, setShowCreativesInfo] = React.useState(false);

  const CreativeIcon = creativeTypeWithIconMap[campaignData?.creativeType] || null;

  const creativesInfoDialog = (
    <Dialog fullWidth maxWidth="xxl" open PaperProps={{ sx: { height: 100 } }}>
      <DialogTitle
        onClose={() => setShowCreativesInfo(false)}
        sx={{ display: 'flex', width: 100, alignItems: 'center', overflow: 'hidden' }}
      >
        {!!CreativeIcon && (
          <CreativeIcon color="primary" sx={{ marginRight: 8, flexShrink: 0 }} fontSize={24} />
        )}
        <Box sx={{ width: 100, display: 'flex', alignItems: 'end', overflow: 'hidden' }}>
          <TypoTooltip arrow placement="bottom" sx={{ overflow: 'hidden' }}>
            {campaignData?.campaignName}
          </TypoTooltip>
          <Typography variant="bodyMedium" sx={{ ml: 4, mb: 0, flexShrink: 0 }}>
            {`(${campaignData?.creativesCount} Creative${
              campaignData?.creativesCount > 1 ? 's' : ''
            })`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <CreativesList campaignData={campaignData} />
      </DialogContent>
    </Dialog>
  );

  const doesCampaignHaveCreative = campaignData.creativesCount > 0;

  const isCellDisabled = !doesCampaignHaveCreative || cellDisabled;

  return (
    <Box sx={{ height: 100, display: 'flex', alignItems: 'center' }}>
      <Tooltip
        title="Click to view all the creatives"
        arrow
        placement="top"
        disableHoverListener={isCellDisabled}
      >
        <ButtonBase
          centerRipple={false}
          disableRipple
          disabled={isCellDisabled}
          onClick={() => {
            if (rumLogClick) {
              rumLogClick();
            }
            setShowCreativesInfo(true);
          }}
          sx={{ ...(!isCellDisabled ? { textColor: 'primary-600' } : {}), mt: 8 }}
          /* eslint-disable-next-line */
          {...(rumActionName ? { 'data-dd-action-name': rumActionName } : {})}
        >
          <FormattedNumberCell value={campaignData.creativesCount || 0} />
        </ButtonBase>
      </Tooltip>
      {showCreativesInfo && creativesInfoDialog}
    </Box>
  );
};
