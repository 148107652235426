import React from 'react';
import { Box, Chip, IconButton, MenuItem, TextField } from '@applift/factor';
import { RowSelectionState } from '@applift/datagrid';
import { Close, Search } from '@applift/icons';

import { useInventoryGroupsTypes } from 'hooks/useInventory';
import { groupTypesIconMapping } from '../../groupTypeIconMapping';

export interface FilterProps {
  searchField: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  groupType: number[];
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setGroupType: React.Dispatch<React.SetStateAction<number[]>>;
  filteredRecords?: number;
}

export const Filters = (props: FilterProps) => {
  const {
    searchField,
    setSearch,
    groupType,
    setGroupType,
    filteredRecords,
    rowSelection,
    setRowSelection,
  } = props;
  const inventoryGroupesTypes = useInventoryGroupsTypes();
  const isFilterApplied = searchField.length > 0 || groupType.length > 0;

  const selectedRowsCount = Object.keys(rowSelection).filter((key) => rowSelection[key]).length;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'between', alignItems: 'center', p: 16 }}>
      <Box>
        {selectedRowsCount > 0 && isFilterApplied === false && (
          <Chip
            label={`${selectedRowsCount} out of ${filteredRecords ?? selectedRowsCount} selected`}
            onDelete={() => {
              setRowSelection({});
            }}
            color="secondary"
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexShrink: 0 }}>
        <TextField
          fullWidth
          variant="outlinedDash"
          placeholder="Search by Name"
          value={searchField}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setSearch(e.target.value)
          }
          InputProps={{
            startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
            endAdornment: searchField && (
              <IconButton
                onClick={() => {
                  setSearch('');
                }}
                sx={{ textColor: 'neutral-400' }}
              >
                <Close />
              </IconButton>
            ),
          }}
          sx={{ pr: 8 }}
          style={{ width: 200 }}
        />
        <TextField
          select
          fullWidth
          SelectProps={{
            multiple: true,
            placeholder: 'Group Type',
            hideSelectNav: true,
            MenuProps: {
              anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
              transformOrigin: { horizontal: 'right', vertical: 'top' },
              PaperProps: {
                style: { maxHeight: 110 },
              },
            },
          }}
          value={groupType}
          onChange={(e) => setGroupType(e.target?.value as unknown as number[])}
          style={{ width: 200 }}
        >
          {inventoryGroupesTypes.map((type) => {
            const StartIcon = groupTypesIconMapping[type.id as keyof typeof groupTypesIconMapping];
            return (
              <MenuItem value={type.id} key={type.id} startIcon={<StartIcon color="primary" />}>
                {type.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </Box>
  );
};
