import { StateCreator } from 'zustand';
import { CampaignCreativesSlice, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  selectedCreatives: [],
  creativeTypeId: null,
};

export const createCreativesBlockStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  CampaignCreativesSlice
> = (set) => ({
  creativesBlock: {
    ...initialState,
    setSelectedCreatives: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          creativesBlock: {
            ...prev.creativesBlock,
            selectedCreatives: value,
          },
        }),
        false,
        'setSelectedCreatives',
      ),
    setCreativeTypeId: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          creativesBlock: {
            ...prev.creativesBlock,
            creativeTypeId: value,
          },
        }),
        false,
        'setCreativeTypeId',
      ),
    resetCreativeBlockStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          creativesBlock: {
            ...prev.creativesBlock,
            ...initialState,
          },
        }),
        false,
        'resetCreativeBlockStore',
      ),
    initCampaignCreativesSlice: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          creativesBlock: {
            ...prev.creativesBlock,
            ...value,
          },
        }),
        false,
        'initCreativesBlockStore',
      ),
  },
});
