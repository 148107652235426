import React from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { getPathNumber } from 'iqm-framework';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Link,
  SvgIcon,
  useDebounceValue,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor';
import { SortingState } from '@applift/datagrid';
import { AppBidModel, ExternalLink } from '@applift/icons';
import {
  CreativePage,
  CreativePageApiRefType,
  CREATIVE_TAB,
  useCreativeDimensionList,
  useModelledCreativesList,
} from '@applift/bid-model';
import { CreativeModelledListResponse } from '@applift/bid-model/lib/models/Creative';
import { IUpdateRequest } from '@applift/bid-model/lib/api/creative';

import { useDimensions } from 'hooks/useDimension';
import { DialogWarningOverlay } from 'components/DialogWarningOverlay/DialogWarningOverlay';
import { queryClient } from 'cache';
import { AppState } from 'models/Store';
import { AdvancePageState } from 'store/advance/reducer';
import { ExistingCampaignData } from 'models/ExistingCampaign';
import { useModelledDimensionsCount } from 'hooks/useCount';
import { IBidModelRequest, updateModelling } from 'api/Modelling';
import { SetCampaignSidebarInfo, advanceActions } from 'store/advance/actions';
import { BUDGET_TYPE_ID } from 'constants/apps';

interface AdvancedCreativesModellingDialogProps extends SetCampaignSidebarInfo {
  closeDialog: () => void;
  sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
  editableCampaign: ExistingCampaignData | null;
  budgetTypeId: number;
  defaultTab: 'All' | 'Modelled';
}

const AdvancedCreativesModellingComponent = (props: AdvancedCreativesModellingDialogProps) => {
  const {
    closeDialog,
    sidebarCampaignInfo,
    editableCampaign,
    budgetTypeId,
    setCampaignSidebarInfo,
    defaultTab,
  } = props;

  const [visibleSection, setVisibleSection] = React.useState<'All' | 'Modelled'>(defaultTab);
  const [search, setSearch] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'impressions', desc: true }]);
  const [allowBlockFilterValue, setAllowBlockFilterValue] = React.useState<
    'all' | 'allowed' | 'blocked'
  >('all');
  const [totalRecordsCount, setTotalRecordsCount] = React.useState<number | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
  const [modelledDataInfo, setModelledDataInfo] = React.useState<{
    hasChange: boolean;
    hasError: boolean;
    modifiedModelledState?: Partial<CreativeModelledListResponse>;
  }>({
    hasChange: false,
    hasError: false,
    modifiedModelledState: {},
  });

  const creativeRef = React.useRef<CreativePageApiRefType>(null);
  const dialogActionsRef = React.useRef<any>(null);
  const redirectTargetRef = React.useRef<'allTab' | 'bidModelApp' | null>(null);

  const debouncedSearch = useDebounceValue(search, 500);

  const isExcluded = React.useMemo(() => {
    if (allowBlockFilterValue === 'allowed') {
      return 0;
    }
    if (allowBlockFilterValue === 'blocked') {
      return 1;
    }
    return undefined;
  }, [allowBlockFilterValue]);

  const { data: dimensionData } = useDimensions();

  const dimensionId = React.useMemo(() => {
    if (dimensionData) {
      return dimensionData.keyIdPair.creative;
    }
    return null;
  }, [dimensionData]);

  const onAllCreativesFetched = (data: any) => {
    if (!totalRecordsCount) {
      setTotalRecordsCount(data.totalRecords);
    }
  };

  const campaignBudget =
    budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
      ? editableCampaign?.budgetTotal
      : editableCampaign?.totalImpressions;

  const { data: allCreativesData, isFetching: isAllCreativesBeingFetched } =
    useCreativeDimensionList(
      {
        searchField: debouncedSearch,
        dimensionId: dimensionId as number,
        campaignId: String(editableCampaign?.id),
        timeZoneId: sidebarCampaignInfo.timezone.id,
        sorting,
        isExcluded,
        skipReporting: true,
      },
      {
        enabled: Boolean(dimensionId) && typeof campaignBudget === 'number',
        meta: { campaignBudget },
        onSuccess: onAllCreativesFetched,
      },
    );

  const { data: modelledCreativesData, isFetching: isModelTableLoading } = useModelledCreativesList(
    {
      skipReporting: true,
      dimensionId: dimensionId as number,
      campaignId: editableCampaign?.id as number,
      timeZoneId: sidebarCampaignInfo.timezone.id,
      pageNo: -1,
    },
    {
      enabled: Boolean(dimensionId) && typeof campaignBudget === 'number',
      meta: { campaignBudget },
    },
  );

  const { data: modelledCount } = useModelledDimensionsCount(
    {
      campaignId: editableCampaign?.id as number,
    },
    {
      enabled: Boolean(editableCampaign?.id),
    },
  );

  const updateMutation = useMutation(updateModelling, {
    mutationKey: ['updateCreativesModelling'],
    onSuccess: (res) => {
      queryClient.refetchQueries({
        predicate: (query: any) =>
          // scope names are from bid-model app
          [
            'getModelledDimensionsCount',
            'getModelledCreativesList',
            'getAllCreativeDimensionList',
          ].includes(query.queryKey?.[0]?.scope),
      });
      enqueueSnackbar(res.data || 'Updated successfully', {
        variant: 'success',
      });
      if (hasUnsavedChanges) {
        setHasUnsavedChanges(false);
        if (redirectTargetRef.current === 'allTab') {
          setVisibleSection('All');
        } else if (redirectTargetRef.current === 'bidModelApp') {
          window.open(
            `/bidmodel/u/${getPathNumber()}/#/creative/${editableCampaign?.id}`,
            '_blank',
          );
        }
        redirectTargetRef.current = null;
      } else {
        closeDialog();
      }
    },
    onError: (res) => {
      enqueueSnackbar((res as any)?.errorObjects[0]?.error || 'Something went wrong.', {
        variant: 'error',
      });
    },
  });

  const modelledCreativesCount = modelledCount?.dimensionWiseCount.find(
    (dimension) => dimension.dimensionId === dimensionId,
  )?.count;

  const onRemoveModellingClick = () => {
    if (modelledCreativesData?.data.length) {
      const deletedActions: IBidModelRequest[] = [];
      modelledCreativesData.data.forEach((item) =>
        deletedActions.push({ action: 'DELETE', bidModelDataIds: [item.bidModelDataId as number] }),
      );
      const payload = {
        bidModelRequests: deletedActions,
        baseBid: creativeRef.current?.getModelledCreativesPayload().baseBid,
      };
      updateMutation.mutate({
        campaignId: editableCampaign?.id as number,
        params: payload,
      });
    } else {
      closeDialog();
    }
  };

  const saveChanges = () => {
    updateMutation.mutate({
      campaignId: editableCampaign?.id as number,
      params: creativeRef.current?.getModelledCreativesPayload() as IUpdateRequest,
    });
  };

  const setVisibleSectionWrapper = (newSection: 'All' | 'Modelled') => {
    if (newSection === CREATIVE_TAB.ALL && modelledDataInfo.hasChange) {
      redirectTargetRef.current = 'allTab';
      setHasUnsavedChanges(true);
    } else {
      setVisibleSection(newSection);
    }
  };

  const getFooterLeftContent = () => {
    if (
      visibleSection === CREATIVE_TAB.MODELLED &&
      !hasUnsavedChanges &&
      creativeRef.current?.getCurrentData()?.data?.length
    ) {
      return (
        <LoadingButton color="error" onClick={onRemoveModellingClick} variant="outlined">
          Remove modelling
        </LoadingButton>
      );
    }

    return null;
  };

  React.useEffect(() => {
    if (modelledDataInfo) {
      const baseBid = modelledDataInfo.modifiedModelledState?.campaignBidDetails?.baseBid;
      setCampaignSidebarInfo('baseBid', String(baseBid ?? 0));
    }
  }, [modelledDataInfo, setCampaignSidebarInfo]);

  return (
    <>
      <Dialog open maxWidth="xxl" fullWidth PaperProps={{ sx: { height: 100 } }}>
        <DialogTitle
          onClose={closeDialog}
          sx={{ display: 'flex', justifyContent: 'between', width: 100 }}
        >
          <Box sx={{ display: 'flex' }}>
            <AppBidModel fontSize={24} sx={{ mr: 4 }} />
            <Typography weight="demi">Creatives - Advanced Modelling</Typography>
          </Box>
          <Box sx={{ mr: 12 }}>
            <Link
              /* @ts-ignore */
              target="_blank"
              onClick={(e) => {
                if (modelledDataInfo.hasChange && visibleSection === CREATIVE_TAB.MODELLED) {
                  e.preventDefault();
                  redirectTargetRef.current = 'bidModelApp';
                  setHasUnsavedChanges(true);
                }
              }}
              href={`/bidmodel/u/${getPathNumber()}/#/creative/${editableCampaign?.id}`}
            >
              <SvgIcon fontSize={16} sx={{ mr: 4 }}>
                <ExternalLink />
              </SvgIcon>
              <Typography component="h6" variant="bodySmall" weight="demi" sx={{ mb: 0 }}>
                View in Bid Model
              </Typography>
            </Link>
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ height: 100 }}>
          <CreativePage
            ref={creativeRef}
            key={editableCampaign?.id}
            app="campaign"
            onModellingChange={(hasChange, hasError) =>
              setModelledDataInfo({
                hasChange,
                hasError,
                modifiedModelledState: creativeRef.current?.getCurrentData(),
              })
            }
            campaignData={{
              campaignId: editableCampaign?.id as number,
              isBidShading: sidebarCampaignInfo?.isBidShading,
              startTime: sidebarCampaignInfo.startDate ?? 0,
              endTime: sidebarCampaignInfo.endDate ?? 0,
              budgetTypeId,
              campaignStatus: editableCampaign?.status as string,
              budgetDay: editableCampaign?.budgetDay as number,
            }}
            dimensionId={dimensionId as number}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSectionWrapper}
            allCreativesTableProps={{
              data: allCreativesData,
              isFetching: isAllCreativesBeingFetched,
              search,
              setSearch,
              sorting,
              setSorting,
              allowBlockFilterValue,
              setAllowBlockFilterValue,
              totalCount: totalRecordsCount as number,
              dateRange: null,
              timezone: sidebarCampaignInfo.timezone,
              columnsToDisplay: [
                'id',
                'creativeThumbnailSource',
                'name',
                'creativeStatus',
                'modelled',
                'Allowlist/Blocklist',
              ],
            }}
            modelCreativesTableProps={{
              data: modelledCreativesData,
              isFetching: isModelTableLoading,
              dateRange: null,
              totalCount: modelledCreativesCount,
              dimensionId: dimensionId as number,
              timezone: sidebarCampaignInfo.timezone,
              columnsToDisplay: [
                'id',
                'creativeThumbnailSource',
                'name',
                'creativeStatus',
                'priority',
                'spendRatio',
                'spendRatioTypeId',
                'bidMultiplier',
                'Allowlist/Blocklist',
              ],
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'between' }} ref={dialogActionsRef}>
          <Box>{getFooterLeftContent()}</Box>
          <Box sx={{ gap: 12, display: 'flex', flexShrink: 0 }}>
            <Button color="secondary" onClick={closeDialog}>
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              loading={updateMutation.isLoading}
              disabled={
                visibleSection === CREATIVE_TAB.ALL ||
                modelledDataInfo.hasError ||
                !modelledDataInfo.hasChange
              }
              onClick={saveChanges}
            >
              Save Modelling
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
      <DialogWarningOverlay
        anchorRef={dialogActionsRef}
        isOpen={hasUnsavedChanges}
        onCancelClick={closeDialog}
        isLoading={updateMutation.isLoading}
        onConfirmClick={saveChanges}
        warningText={`Your modelling values are not saved. Please save your changes before switching to the ${
          redirectTargetRef.current === 'allTab' ? `‘All Creatives’ tab` : 'Bid Model'
        }. Otherwise, Modelling changes will be discarded.`}
        cancelBtnText="Cancel"
        confirmBtnText="Save Modelling"
      />
    </>
  );
};

const mapState = (state: AppState) => ({
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  editableCampaign: state.app.editableCampaign,
  budgetTypeId: state.app.budgetTypeId,
});

const mapActions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
};

export const AdvancedCreativesModelling = connect(
  mapState,
  mapActions,
)(AdvancedCreativesModellingComponent);
