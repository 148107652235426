import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { getOwId, getPathNumber, DataDogRumRoute, AuthService } from 'iqm-framework';
import { routes } from 'routes';
import { AppState } from 'models/Store';
import { authActions, Login } from 'store/auth2/actions';
import { User } from 'models/User';
import { localStorageService } from 'services/localStorage';
import { CssBaseline, ThemeProvider, SnackbarProvider } from '@applift/factor';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '../cache';

type Props = Login & {
  authorized: boolean;
};

const logout = () => {
  if (window.location !== window.parent.location) {
    window.parent.postMessage('sessionExpired', '*');
    const iframeRef = window.parent.document.querySelector('iframe[name=location-block]');
    iframeRef?.parentNode?.removeChild(iframeRef);
  }
  AuthService.unauthorize();
  window.location.href = `/#/login`;
};

const AppComponent = (props: Props) => {
  const { authorized, login } = props;
  const userId = getOwId();

  useEffect(() => {
    const userData = localStorageService.getBaseInfo<User>();
    if (userId && userData) {
      if (userData.failureDetails?.status === 'SUSPENDED') {
        window.location.href = `/marketplace/u/${getPathNumber()}/#/suspended`;
        return;
      }

      login({ ...userData, userId: +userId });
    }
    if (!userData && !authorized) {
      logout();
    }

    window.onstorage = () => {
      const apiToken = localStorageService.getBaseInfo<User>()?.apiToken;
      if (!apiToken) {
        // eslint-disable-next-line no-console
        console.log('No apiToken in LocalStorage');
        logout();
      }
    };
  }, [authorized, login, userId]);

  return authorized ? (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider domRoot={document.body}>
        <CssBaseline />
        <ThemeProvider variant="blue" style={{ height: '100%' }}>
          <Router>
            <Switch>
              {routes.map((route) => {
                return (
                  <DataDogRumRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    useHashForViewName
                  />
                );
              })}
            </Switch>
          </Router>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} panelPosition="right" position="top-right" />
      </SnackbarProvider>
    </QueryClientProvider>
  ) : null;
};

const mapState = (state: AppState) => ({
  authorized: state.auth.authorized,
});

const mapActions = {
  login: authActions.login,
};

export const App = connect(mapState, mapActions)(AppComponent);
