import * as React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Col,
  Row,
  sx,
  LoadingButton,
} from '@applift/factor';
import { ArrowRightLong } from '@applift/icons';
import clsx from 'clsx';

import { CampaignListType } from 'models/CampaignList';
import { UpdateBudget } from 'api/Campaign';

import styles from './index.module.scss';

interface SetCampaignMaxBidProps {
  inlineEdit?: boolean;
  selectedCampaigns: CampaignListType[];
  closeDialog: () => void;
  onCompletion: (data: UpdateBudget) => void;
  isLoading?: boolean;
}

export const SetCampaignMaxBid = (props: SetCampaignMaxBidProps) => {
  const { closeDialog, onCompletion, inlineEdit, selectedCampaigns, isLoading } = props;
  const [updatedMaxBid, updateMaxBid] = React.useState(
    inlineEdit || selectedCampaigns[0].maxBid === 0 || selectedCampaigns.length > 1
      ? ''
      : selectedCampaigns[0].maxBid.toString(),
  );

  const setInputValueWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexPositiveFloat = /^(?:\d+(?:\.\d+)?|)$/;

    const regexForValidation = regexPositiveFloat;

    const isValid =
      regexForValidation.test(e.target.value) ||
      (Boolean(e.target.value.split('.').length - 1 < 2) &&
        e.target.value[e.target.value.length - 1] === '.');

    if (!isValid) {
      return;
    }

    updateMaxBid(e.target.value);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle onClose={closeDialog}>Set Max Bid Price</DialogTitle>
      <DialogContent dividers sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography sx={{ mb: 16 }}>
          {inlineEdit ? 'Set' : 'Enter'} the
          <Typography weight="demi" sx={{ ml: 4 }}>
            Max Bid Price
          </Typography>{' '}
          for the
          {selectedCampaigns.length === 1 && <Typography sx={{ ml: 4 }}>campaign</Typography>}
          <Typography weight="demi" sx={{ mx: 4 }}>
            {selectedCampaigns.length === 1
              ? selectedCampaigns[0].campaignName
              : selectedCampaigns.length}
          </Typography>
          {selectedCampaigns.length > 1 && 'campaigns.'}
        </Typography>

        {!inlineEdit && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Row sx={{ mb: 16 }}>
              <Col>
                <TextField
                  value={updatedMaxBid}
                  onChange={setInputValueWrapper}
                  placeholder="Enter Max Bid Price"
                  variant="outlinedDash"
                  label="Max Bid Price"
                  InputProps={{
                    startAdornment: <Typography>$</Typography>,
                  }}
                  sx={{ mb: 2 }}
                />
              </Col>
            </Row>
          </Box>
        )}

        {inlineEdit && (
          <Box sx={{ display: 'flex', gap: 12 }}>
            <TextField
              value={`$${selectedCampaigns[0].maxBid}`}
              disabled
              label="Current Max Bid Price"
              variant="outlined"
              InputProps={{
                classes: {
                  root: clsx(
                    sx({
                      borderRadius: 'all',
                      bgColor: 'neutral-75',
                    }),
                    styles.textField,
                  ),
                },
              }}
            />
            <ArrowRightLong fontSize={24} sx={{ textColor: 'neutral-400', mt: 24 }} />
            <TextField
              value={updatedMaxBid}
              onChange={(e: any) => {
                updateMaxBid(e.target.value);
              }}
              type="number"
              label="New Max Bid Price"
              variant="outlined"
              InputProps={{
                startAdornment: '$',
                classes: {
                  input: sx({ borderColor: 'neutral-0' }),
                  root: clsx(
                    sx({
                      textWeight: 'demi',
                      textColor: 'neutral-1000',
                      borderRadius: 'all',
                      bgColor: 'neutral-75',
                    }),
                    styles.textField,
                  ),
                },
                inputProps: {
                  className: sx({ pl: 0 }),
                },
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={isLoading}
          disabled={!(Number(updatedMaxBid) > 0)}
          onClick={() =>
            onCompletion({
              maxBid: Number(updatedMaxBid),
              totalBudgetUpdateType: 'change',
              campaignIds: selectedCampaigns.map((cmp) => cmp.campaignId).toString(),
              budgetTypeId: selectedCampaigns[0].budgetTypeId,
              campaignTypeId: selectedCampaigns[0].campaignTypeId,
            })
          }
        >
          Set
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
