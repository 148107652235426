import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@applift/factor';

interface SaveCampaignAsDraftDialogProps {
  closeDialog: () => void;
  onConfirmation: () => void;
}

export const SaveCampaignAsDraftDialog = (props: SaveCampaignAsDraftDialogProps) => {
  const { closeDialog, onConfirmation } = props;

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeDialog}>
        <Typography weight="demi">Save Campaign Changes</Typography>
      </DialogTitle>
      <DialogContent>
        The campaign has unsaved changes. Please save these changes before applying Advanced
        Modelling.
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={() => onConfirmation()}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
