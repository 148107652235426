import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'factor';
import { CampaignTypeById } from 'models/campaign';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { Estimator } from './Estimator/index';
import { applicationActions, ResetStore } from '../../store/app/actions';

import styles from './Sidebar.module.scss';

interface Props extends ResetStore {
  icon?: React.ReactNode;
  title?: string;
  rightIcon?: React.ReactNode | null;
  children: React.ReactNode;
  campaignType?: CampaignTypeById;
}

const SidebarComponent = (props: Props) => {
  const { children, icon, title = 'Select Campaign Type', rightIcon, campaignType } = props;

  const renderHeader = () => {
    return (
      <header data-qa="24" className={styles.header}>
        {title.length > 20 ? (
          <div data-qa="25" className={styles.headerWrapper}>
            {icon && (
              <span data-qa="26" className={styles.icon}>
                {icon}
              </span>
            )}
            <Tooltip
              className={styles.tooltip}
              label={title}
              auto={false}
              position="bottom"
              labelMaxWidth={300}
            >
              <h3 data-qa="27" className={styles.title}>
                {title}
              </h3>
            </Tooltip>
            {rightIcon ? <div className={styles.rightIcon}>{rightIcon}</div> : null}
          </div>
        ) : (
          <div data-qa="25" className={styles.headerWrapper}>
            {icon && (
              <span data-qa="26" className={styles.icon}>
                {icon}
              </span>
            )}
            <h3 data-qa="27" className={styles.title}>
              {title}
            </h3>
            {rightIcon ? <div className={styles.rightIcon}>{rightIcon}</div> : null}
          </div>
        )}
      </header>
    );
  };

  return (
    <div data-qa="29" className={`${styles.sidebar}`}>
      <div data-qa="30" className={styles.container}>
        {renderHeader()}
        {campaignType === CAMPAIGN_TYPE_BY_NAME.pg ? null : <Estimator />}
        <div data-qa="31" className={styles.main}>
          <div data-qa="32" className={styles.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapActions = {
  resetStore: applicationActions.resetStore,
};

export const Sidebar = connect(null, mapActions)(SidebarComponent);
