import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Checkbox, Button, Icon, Tooltip } from 'factor';
import { ModalCreativePreview } from 'iqm-framework';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useModelledCreativesList } from '@applift/bid-model';

import { useDimensions } from 'hooks/useDimension';
import { pluralize } from 'utils/pluralize';
import { Creative } from 'models/Creative';
import { CreativesPlacementMapping, ExistingCampaignData } from 'models/ExistingCampaign';
import { Option } from 'models/Option';
import { API } from 'api';
import { TAdPlacement } from 'api/Creatives';
import { AdvancePageState } from 'store/advance/reducer';
import { CreativesActions } from 'store/creatives/actions';
import { AppState } from 'models/Store';

import {
  BodyMapper,
  HeaderMapper,
} from '../SelectCreativesPopup/creativeTableView/creativeTable/creativeTableOptions/creativeTableMappers';
import { BulkURLUpdate } from './BulkURLUpdate';
import CreativePreviewCell from './creativePreviewCell/CreativePreviewCell';
import selectionStyles from '../SelectCreativesPopup/selectionActionPanel/SelectionActionPanel.module.scss';

import styles from './styles.module.scss';

interface Props {
  creatives: Creative[];
  creativesPlacementMapping: CreativesPlacementMapping | undefined;
  removeSelectedCreatives: (removalSelection: { [key: number]: boolean }) => void;
  getAdvertiserURLField: (className?: string) => React.ReactNode;
  setCreativesPlacementMapping: CreativesActions['setCreativesPlacementMapping'];
  editableCampaign: ExistingCampaignData | null;
  sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
}

interface AdPlacementData {
  [key: string]: Option<number>;
}

const toTableSelection = (ids: (number | string)[]) =>
  ids.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );

const ALLOWED_URL_EDIT_STATUSES = ['running', 'pending', 'paused', 'rejected'];

export const SelectedCreativesTable = (props: Props) => {
  const {
    creatives,
    removeSelectedCreatives,
    getAdvertiserURLField,
    creativesPlacementMapping,
    setCreativesPlacementMapping,
    editableCampaign,
    sidebarCampaignInfo,
  } = props;

  const [previewedCreative, previewCreative] = useState<Creative | null>(null);
  const [localCreatives, setLocalCreatives] = useState<Creative[]>([]);
  const [tableSelectedCreatives, setTableSelectedCreatives] = useState<{ [key: number]: boolean }>(
    {},
  );
  const [adPlacementData, setAdPlacementData] = useState<AdPlacementData>({});
  const [adPlacementList, setAdPlacementList] = useState<Option<number>[]>([]);

  const tableKey = useRef<string>(uniqueId());
  const tableRef = useRef<any>();
  const sidebarOpened = useSelector((state: AppState) => state.app.sidebarOpened);

  const { data: dimensionData } = useDimensions();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      tableRef.current?.syncWidths?.();
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [sidebarOpened]);

  const dimensionId = React.useMemo(() => {
    if (dimensionData) {
      return dimensionData.keyIdPair.creative;
    }
    return undefined;
  }, [dimensionData]);

  const { data: modelTableData } = useModelledCreativesList(
    {
      skipReporting: true,
      dimensionId: dimensionId as number,
      campaignId: editableCampaign?.id as number,
      timeZoneId: sidebarCampaignInfo.timezone.id,
      pageNo: -1,
    },
    {
      enabled: Boolean(dimensionId && editableCampaign?.id),
      meta: { campaignBudget: editableCampaign?.budgetTotal },
    },
  );

  useEffect(() => {
    tableKey.current = uniqueId();

    const getList = async () => {
      const res = await API.Creatives.GetAdPlacementList();
      const preparedList = res.map((obj: TAdPlacement) => ({
        label: obj.name,
        value: obj.id,
      }));

      preparedList.push({
        label: 'Any',
        value: 0,
      });
      setAdPlacementList(preparedList);
    };

    getList();
  }, []);

  useEffect(() => {
    const prevLocalCreatives = localCreatives;

    setLocalCreatives([...creatives]);
    setTableSelectedCreatives(
      toTableSelection(
        Object.keys(tableSelectedCreatives).filter(
          (id: string) =>
            tableSelectedCreatives[id as any] &&
            creatives.find((crt) => crt.id === Number.parseInt(id, 10)),
        ),
      ),
    );

    if (
      creatives.length !== prevLocalCreatives?.length ||
      !prevLocalCreatives.every((localCrt) => creatives.find((crt) => crt.id === localCrt.id))
    ) {
      tableKey.current = uniqueId();
    }

    if (creatives.length && adPlacementList.length) {
      const mapping = creativesPlacementMapping || {};
      const placementDataToUpdate: AdPlacementData = {};

      for (let i = 0; i < creatives.length; i += 1) {
        const crt = creatives[i];
        if (!mapping[String(crt.id)]) {
          placementDataToUpdate[String(crt.id)] = {
            label: 'Any',
            value: 0,
          };
        } else {
          const existing = adPlacementList.find((obj) => obj.value === mapping[String(crt.id)]);

          if (existing) {
            placementDataToUpdate[String(crt.id)] = existing;
          }
        }
      }

      setAdPlacementData(placementDataToUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlacementList, creatives, creativesPlacementMapping]);

  useEffect(() => {
    const converted = Object.entries(adPlacementData).reduce((acc, entry) => {
      acc[entry[0]] = entry[1].value;
      return acc;
    }, {} as CreativesPlacementMapping);

    if (
      (!isEmpty(converted) && !isEqual(converted, creativesPlacementMapping)) ||
      (isEmpty(converted) && !creatives.length)
    ) {
      setCreativesPlacementMapping(converted);
    }
  }, [adPlacementData, creatives.length, creativesPlacementMapping, setCreativesPlacementMapping]);

  const isAllSelected = useMemo(
    () => localCreatives.every((crt) => tableSelectedCreatives[crt.id]),
    [localCreatives, tableSelectedCreatives],
  );

  const toggleSelection = useCallback(
    (id: number) => () => {
      const creativeInFocus = localCreatives.find((crt) => crt.id === id);
      if (!creativeInFocus) {
        return;
      }

      setTableSelectedCreatives((selection) => ({ ...selection, [id]: !selection[id] }));
    },
    [localCreatives],
  );

  const toggleSelectionAll = useCallback(
    (newValue: boolean) => {
      if (newValue) {
        setTableSelectedCreatives(toTableSelection(localCreatives.map((crt) => crt.id)));
      } else {
        setTableSelectedCreatives({});
      }
    },
    [localCreatives],
  );

  const handleRemove = () => {
    setTableSelectedCreatives({});
    tableKey.current = uniqueId();
    removeSelectedCreatives(tableSelectedCreatives);
  };

  const headerMapper = useMemo(() => {
    const headerColumnList = [
      {
        label: (
          <Checkbox
            checked={isAllSelected}
            name="checkbox-id-select-all"
            onChange={toggleSelectionAll}
          />
        ),
        className: '_checkbox',
        draggable: false,
      },
      ...Object.values(HeaderMapper),
    ];

    return headerColumnList;
  }, [isAllSelected, toggleSelectionAll]);

  const bodyMapper = useMemo(() => {
    const columns = {
      ...BodyMapper,
      creativeSource: CreativePreviewCell((creative: Creative) => {
        previewCreative(creative);
      }),
    };

    const bodyColumnList = [
      {
        key: (data: Creative) => (
          <Checkbox
            checked={tableSelectedCreatives[data.id]}
            name={`checkbox-id-${data.id}`}
            onChange={toggleSelection(data.id)}
          />
        ),
        className: '_checkbox',
      },
      ...Object.values(columns),
    ];

    return bodyColumnList;
  }, [tableSelectedCreatives, toggleSelection]);

  const clearSelected = () => {
    toggleSelectionAll(false);
  };

  const tableSelectionCount = useMemo(
    () =>
      Object.keys(tableSelectedCreatives).filter((key) => tableSelectedCreatives[key as any])
        .length,
    [tableSelectedCreatives],
  );

  const allowBulkURLUpdate = useMemo(() => {
    const selectedCreatives = localCreatives?.filter((crt) => tableSelectedCreatives[crt.id]);
    return selectedCreatives?.every((crt) =>
      ALLOWED_URL_EDIT_STATUSES.includes(crt.status?.toLowerCase()),
    );
  }, [tableSelectedCreatives, localCreatives]);

  const modelledCreativeIds = modelTableData?.data.map((item) => item.id);

  const selectedCreativeIds = Object.keys(tableSelectedCreatives).filter(
    (key) => tableSelectedCreatives[key as any] === true,
  );

  const isModelledCreativeSelected = selectedCreativeIds.some((element) =>
    (modelledCreativeIds ?? []).includes(Number(element)),
  );

  const removeBtn = (
    <Button
      className="btn-square _persimmon"
      onClick={handleRemove}
      disabled={isModelledCreativeSelected}
    >
      Remove
    </Button>
  );

  return (
    <div>
      <div className={styles.headerText}>
        {localCreatives.length} {pluralize('Creative', localCreatives.length)} added to the campaign
      </div>
      {localCreatives.length > 0 && (
        <div className="d-flex">
          {getAdvertiserURLField()}
          <div className={styles.infoTextWrapper}>
            <Icon name="Info" />
            <div className={styles.infoText}>
              Advertiser Domain URL appears with the creative when published on the web/app
              channels. As per the IAB guidelines, it is mandatory to add Advertiser Domain URL with
              the creative.
            </div>
          </div>
        </div>
      )}
      <div className={styles.selectedCreativeActions}>
        {tableSelectionCount > 0 && (
          <>
            <div className={selectionStyles.selectedCreativesCount}>
              <button
                type="button"
                className={`btn-close ${selectionStyles.clearSelectedCreatives}`}
                onClick={clearSelected}
              />
              <span className={styles.selectedCountTitle}>
                {tableSelectionCount} {pluralize('Creative', tableSelectionCount)} selected
              </span>
            </div>
            {allowBulkURLUpdate && (
              <BulkURLUpdate
                creatives={localCreatives}
                tableSelectedCreatives={tableSelectedCreatives}
              />
            )}
            {isModelledCreativeSelected ? (
              <Tooltip
                label="Creatives cannot be removed from the campaign until Advance Modelling is applied. To remove creatives, Modelling on creatives needs to be removed."
                portal
                labelMaxWidth={370}
              >
                {removeBtn}
              </Tooltip>
            ) : (
              removeBtn
            )}
          </>
        )}
      </div>
      <Table
        key={tableKey.current}
        data={[...localCreatives]}
        header={headerMapper}
        body={bodyMapper}
        tbodyRowHeight={80}
        rowKeyExtractor={(item: Creative) => item.id}
        className={styles.attachedCreativesTable}
        tableMaxHeight="calc(85vh - 240px)"
        preventNavigationByScroll
        fixedXScroller
        infiniteScroll
        freezeRows={0}
        skeletonRowsNumber={0}
        progressHeight={0}
        fixedheader
        offsetTop={0}
        ref={tableRef}
      />
      {previewedCreative && (
        <ModalCreativePreview creative={previewedCreative} onClose={() => previewCreative(null)} />
      )}
    </div>
  );
};
