/* global google */
import React from 'react';
import { connect } from 'react-redux';
import { Circle, Marker } from '@react-google-maps/api';

import { LatLng, OverlayType, Circle as TCircle } from 'models/Google';
import { CircleExtended } from 'models/CircleExtended';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { ICountryOption, RadiusUpdatedLocations } from 'models/Location';
import {
  mapActions,
  EmptySelectedAreasIds,
  SetToastMessage,
  PushSelectedAreaId,
  RemoveSelectedAreaId,
  RemoveCircleFromPreSaved,
  SetDrawerMode,
  UpdatePreSavedCircle,
  SetRadiusUpdatedLocation,
} from 'store/map/actions';
import { metersToMiles } from 'utils/format';

import { BlackLists, WhiteLists } from 'store/advance/reducer';
import { CloseMarker } from '../CloseMarker';
import { MapInfoBox } from '../MapInfoBox';

interface Props
  extends EmptySelectedAreasIds,
    SetToastMessage,
    PushSelectedAreaId,
    RemoveSelectedAreaId,
    RemoveCircleFromPreSaved,
    SetDrawerMode,
    SetRadiusUpdatedLocation,
    UpdatePreSavedCircle {
  getCircle: () => CircleExtended;
  isSaved?: boolean;
  radiusUpdatedLocations: RadiusUpdatedLocations | null;
  draggable?: boolean;
  editable?: boolean;
  drawerMode: OverlayType | undefined;
  areaType: string | null;
  selectedAreasIds: string[];
  country: ICountryOption | null;
  whiteLists: WhiteLists;
  blackLists: BlackLists;
  isMapReadOnly: boolean;
}

interface State {
  center: LatLng;
  radius: number;
  isInfoBoxVisible: boolean;
}

class MapCircleComponent extends React.PureComponent<Props, State> {
  mapCircle: any;

  marker: any;

  isMultiselectKeyPressed = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      center: props.getCircle().getCenter(),
      radius: props.getCircle().getRadius(),
      isInfoBoxVisible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onkeyDownHandler);
    document.addEventListener('keyup', this.onkeyUpHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onkeyDownHandler);
    document.removeEventListener('keyup', this.onkeyUpHandler);
  }

  onkeyDownHandler = (e: KeyboardEvent) => {
    this.isMultiselectKeyPressed = e.metaKey || e.ctrlKey;
  };

  onkeyUpHandler = () => {
    this.isMultiselectKeyPressed = false;
  };

  toggleSingleActivity = (circle: CircleExtended) => {
    const { selectedAreasIds, emptySelectedAreasIds, pushSelectedAreaId } = this.props;
    const isActive = this.isActive(circle);
    emptySelectedAreasIds();
    if (!isActive || selectedAreasIds.length > 1) {
      pushSelectedAreaId(circle.id);
    }
  };

  toggleMultiActivity = (circle: CircleExtended) => {
    const { pushSelectedAreaId, removeSelectedAreaId } = this.props;

    if (this.isActive(circle)) {
      removeSelectedAreaId(circle.id);
    } else {
      pushSelectedAreaId(circle.id);
    }
  };

  handleClick = (circle: CircleExtended) => {
    if (this.isMultiselectKeyPressed) {
      this.toggleMultiActivity(circle);
    } else {
      this.toggleSingleActivity(circle);
    }
  };

  handleCircleRemove = (circle: CircleExtended) => {
    const { removeCircleFromPreSaved, removeSelectedAreaId } = this.props;

    removeCircleFromPreSaved(circle);
    removeSelectedAreaId(circle.id);
  };

  handleCircleComponentLoad = (mapCircle: TCircle) => {
    this.mapCircle = mapCircle;
  };

  handleRadiusChanged = (circle: CircleExtended) => {
    const {
      emptySelectedAreasIds,
      pushSelectedAreaId,
      updatePreSavedCircle,
      setRadiusUpdatedLocations,
      radiusUpdatedLocations,
    } = this.props;
    const isAcitve = this.isActive(circle);
    const { mapCircle } = this;
    if (mapCircle) {
      if (!isAcitve) {
        emptySelectedAreasIds();
        pushSelectedAreaId(circle.id);
      }
      const newRadius = mapCircle.getRadius();
      circle.setRadius(newRadius);
      this.setState({ radius: newRadius });
      if (circle.fileId) {
        let location = radiusUpdatedLocations;
        if (location) {
          location[circle.id] = +metersToMiles(newRadius).toFixed(3);
        } else {
          location = { [circle.id]: +metersToMiles(newRadius).toFixed(3) };
        }
        setRadiusUpdatedLocations(location);
      }
      updatePreSavedCircle(circle);
    }
  };

  changeRadiusOfActiveItems = (radius: number) => {
    const circle = this.props.getCircle();
    if (this.isActive(circle)) {
      this.setState({
        radius,
      });
    }
  };

  handleCenterChanged = (circle: CircleExtended) => {
    const { mapCircle } = this;
    if (mapCircle) {
      const newCenter = mapCircle.getCenter();
      circle.setCenter(newCenter);
      this.setState({ center: newCenter });
    }
  };

  handleMouseOver = () => this.setInfoBoxVisibility(true);

  handleMouseOut = () => this.setInfoBoxVisibility(false);

  setInfoBoxVisibility = (isVisible: boolean) => {
    this.setState({ isInfoBoxVisible: isVisible });
  };

  isActive = (circle: CircleExtended) => {
    const { selectedAreasIds } = this.props;
    return selectedAreasIds.indexOf(circle.id) !== -1;
  };

  isAreaTypeRadius = () => {
    const { areaType } = this.props;
    return areaType === 'Radius';
  };

  onMarkerLoad = (marker: any) => {
    this.mapCircle.bindTo('center', marker, 'position');
  };

  isCircleIncluded = (circle: CircleExtended) => {
    if (circle.filename) {
      const { whiteListedLocationIds } = this.props.whiteLists;
      const { blackListedLocationIds } = this.props.blackLists;
      const { id } = circle;
      const formatedId = Number(id);

      if (whiteListedLocationIds?.includes(formatedId)) {
        return true;
      }
      if (blackListedLocationIds?.includes(formatedId)) {
        return false;
      }
    }
    return circle.isInclude;
  };

  render() {
    const { isCircleIncluded, props, state } = this;
    const { getCircle, isMapReadOnly } = props;
    const { center, radius, isInfoBoxVisible } = state;
    const circle = getCircle();
    const isActive = this.isActive(circle);

    return (
      <>
        <Circle
          center={center}
          radius={radius}
          options={{
            fillColor: isCircleIncluded(circle) ? 'green' : 'red',
            strokeColor: isActive ? '#1d58d7' : undefined,
            draggable: false,
            editable: !isMapReadOnly,
            clickable: isMapReadOnly ? false : undefined,
          }}
          onClick={() => this.handleClick(circle)}
          onLoad={this.handleCircleComponentLoad}
          onRadiusChanged={() => this.handleRadiusChanged(circle)}
          onCenterChanged={() => this.handleCenterChanged(circle)}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        />
        <Marker onLoad={this.onMarkerLoad} position={center} />
        {isActive && !circle.fileId && !isMapReadOnly && (
          <CloseMarker
            position={google.maps.geometry.spherical.computeOffset(center, radius, 45)}
            onClick={() => this.handleCircleRemove(circle)}
          />
        )}
        {isInfoBoxVisible && (
          <MapInfoBox
            radius={+metersToMiles(+circle.getRadius()).toFixed(2)}
            position={google.maps.geometry.spherical.computeOffset(center, radius, 90)}
          />
        )}
      </>
    );
  }
}

const mapState = (state: AppState) => ({
  drawerMode: state.map.drawerMode,
  radiusUpdatedLocations: state.map.radiusUpdatedLocations,
  areaType: state.map.areaType,
  selectedAreasIds: state.map.selectedAreasIds,
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  whiteLists: state.advanced.whiteLists,
  blackLists: state.advanced.blackLists,
  isMapReadOnly: state.map.isMapReadOnly,
});

const actions = {
  removeCircleFromPreSaved: mapActions.removeCircleFromPreSaved,
  setDrawerMode: mapActions.setDrawerMode,
  pushSelectedAreaId: mapActions.pushSelectedAreaId,
  removeSelectedAreaId: mapActions.removeSelectedAreaId,
  emptySelectedAreasIds: mapActions.emptySelectedAreasIds,
  setToastMessage: mapActions.setToastMessage,
  updatePreSavedCircle: mapActions.updatePreSavedCircle,
  setRadiusUpdatedLocations: mapActions.setRadiusUpdatedLocations,
};

export const MapCircle = connect(mapState, actions)(MapCircleComponent);
