/* global google */
import { GeoRadiusDataTypes } from 'api/Campaign';
import { shapeType } from 'constants/location';
import uniqueId from 'lodash/uniqueId';

import { TAddressData } from 'utils/figures';

// eslint-disable-next-line import/no-cycle
import { TLatLongNumbers, LatLng, LatLngBounds, Rectangle, takeFormattedAddress } from './Google';
// eslint-disable-next-line import/no-cycle
import { KM_TO_MILE, RadiusUnity } from './PolygonExtended';

export class RectangleExtended {
  private readonly original: google.maps.Rectangle;

  id: string = uniqueId('rectangle-');

  center: LatLng | null = null;

  address: string = '';

  state: string = '';

  country: string = '';

  radius: number = 0;

  isInclude: boolean;

  type: GeoRadiusDataTypes = shapeType.rectangle;

  get lat() {
    return this.getCenter().lat();
  }

  get lng() {
    return this.getCenter().lng();
  }

  constructor({
    rectangle,
    isInclude,
    addressData,
  }: {
    rectangle: Rectangle;
    isInclude: boolean;
    addressData?: TAddressData;
  }) {
    this.original = rectangle;
    this.isInclude = isInclude;
    if (addressData) {
      this.address = addressData.address;
      this.country = addressData.country || '';
      this.state = addressData.state || '';
    }
  }

  getPolyPath(): TLatLongNumbers[] {
    const bounds = this.getBounds();
    const latLngNorthEast = bounds.getNorthEast();
    const latLngSouthWest = bounds.getSouthWest();
    return [
      {
        lat: latLngNorthEast.lat(),
        lng: latLngNorthEast.lng(),
      },
      {
        lat: latLngNorthEast.lat(),
        lng: latLngSouthWest.lng(),
      },
      {
        lat: latLngSouthWest.lat(),
        lng: latLngSouthWest.lng(),
      },
      {
        lat: latLngSouthWest.lat(),
        lng: latLngNorthEast.lng(),
      },
    ];
  }

  setIsInclude(isInclude: boolean) {
    this.isInclude = isInclude;
  }

  getBounds(): LatLngBounds {
    return this.original.getBounds();
  }

  setBounds(bounds: LatLngBounds) {
    return this.original.setBounds(bounds);
  }

  getCenter(): LatLng {
    if (this.center) {
      return this.center;
    }

    this.center = this.original.getBounds().getCenter();

    return this.center;
  }

  getRadius(unity = RadiusUnity.km) {
    const bounds = this.getBounds();
    const latLngNorthEast = bounds.getNorthEast();
    const latLngSouthWest = bounds.getSouthWest();
    const kmRadius =
      google.maps.geometry.spherical.computeDistanceBetween(latLngNorthEast, latLngSouthWest) /
      1000 /
      2;

    if (unity === RadiusUnity.km) {
      return +kmRadius.toFixed(2);
    }

    return +(kmRadius * KM_TO_MILE).toFixed(2);
  }

  async update() {
    const { address, state, country } = await takeFormattedAddress(this.getCenter());
    this.address = address;
    this.state = state;
    this.country = country;
    this.center = null;
    this.radius = this.getRadius();
  }
}
