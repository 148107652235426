import { put, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { API } from '../../api';
import { ReachParameters } from '../../api/Reach';
import { getReachParameters } from '../reach/helpers';
import { createAction } from '../../utils/actions';
import { reachConstants } from '../reach/constants';
import { AppState } from '../../models/Store';

export function* fetchReachSaga(): Generator<unknown, void, any> {
  const state: AppState = yield select();
  const reachParameters = getReachParameters(state);

  yield put(createAction<ReachParameters>(reachConstants.SET_REACH_PARAMETERS, reachParameters));
  yield put(createAction<boolean>(reachConstants.SET_IS_FETCHING_REACH, true));

  try {
    const response = yield API.Reach.getReach(reachParameters);

    if (response) {
      const estimatedReach = response?.data?.estimated_reach;
      yield put(createAction<number>(reachConstants.SET_ESTIMATED_REACH, estimatedReach));
      yield put(createAction<boolean>(reachConstants.SET_IS_SHOWING_REACH, true));
    }
  } catch (error) {
    yield put(createAction<boolean>(reachConstants.SET_HAS_FETCH_ERROR, true));
  }

  yield put(createAction<boolean>(reachConstants.SET_IS_FETCHING_REACH, false));
}

export function* reachParameterChangeSaga(): Generator<unknown, void, any> {
  const state: AppState = yield select();
  const reachParameters = getReachParameters(state);

  if (!isEqual(reachParameters, state.reach.reachParameters)) {
    API.Reach.cancelGetReach();
    yield put(createAction<void>(reachConstants.RESET_REACH_STORE));
    yield put(createAction<ReachParameters>(reachConstants.SET_REACH_PARAMETERS, reachParameters));
  }
}
