export interface DeviceType {
  name: string;
  id: number;
}

export interface DeviceTypeModel extends DeviceType {
  icon: string;
}

export const DeviceTypesMapper: { [key: string]: DeviceTypeModel } = {
  '11': {
    id: 11,
    name: 'Phone',
    icon: 'Phone',
  },
  '12': {
    id: 12,
    name: 'Tablet',
    icon: 'Tablet',
  },
  '13': {
    id: 13,
    name: 'Smart TV',
    icon: 'SmartTV',
  },
  '14': {
    id: 14,
    name: 'Other Embedded',
    icon: 'OtherEmbedded',
  },
  '15': {
    id: 15,
    name: 'Desktop',
    icon: 'Desktop',
  },
};
