/* global google */

export type LatLng = google.maps.LatLng;
export type LatLngLiteral = google.maps.LatLngLiteral;
export type MVCArray<T> = google.maps.MVCArray<T>;
export type MVCObject = google.maps.MVCObject;
export type Polygon = google.maps.Polygon;
export type Circle = google.maps.Circle;
export type Rectangle = google.maps.Rectangle;
export type Marker = google.maps.Marker;
export type MapOptions = google.maps.MapOptions;
export type GeocoderResult = google.maps.GeocoderResult;
export type GeocoderGeometry = google.maps.GeocoderGeometry;
export type LatLngBounds = google.maps.LatLngBounds;
export type LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;
export type Data = google.maps.Data;
export type OverlayType = google.maps.drawing.OverlayType;
export type Point = google.maps.Point;
export type Size = google.maps.Size;
export type TAddressState = {
  address: string;
  state: string;
  country: string;
};
export type TLatLongNumbers = { lat: number; lng: number };
export type Library = 'drawing' | 'geometry' | 'localContext' | 'places' | 'visualization';

const ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1';
const POSTAL_CODE = 'postal_code';
const COUNTRY = 'country';
export const MIN_POLYGON_PATH = 3;

export const findGeometryByName = (state: string): Promise<GeocoderGeometry> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: `${state}, USA`,
      },
      (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const address = results[0];
          resolve(address.geometry);
        } else {
          reject(status);
        }
      },
    );
  });
};

export const findGeometryByLocationName = (country: string): Promise<GeocoderGeometry> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: country,
      },
      (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const address = results[0];
          resolve(address.geometry);
        } else {
          reject(status);
        }
      },
    );
  });
};

export const takeFormattedAddress = (location: google.maps.LatLng): Promise<TAddressState> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location }, (response: GeocoderResult[], status: string) => {
      let address = '';
      if (status === google.maps.GeocoderStatus.OK) {
        const postalCodeObject = response.find((item: any) => item.types.includes(POSTAL_CODE));
        const state: string =
          response
            .find((item: any) => {
              return item.types.includes(ADMINISTRATIVE_AREA_LEVEL_1);
            })
            ?.address_components.find((innerAddress: any) =>
              innerAddress.types.includes(ADMINISTRATIVE_AREA_LEVEL_1),
            )?.short_name || '';
        const country =
          response.find((item: any) => item.types.includes(COUNTRY))?.address_components[0]
            ?.short_name || '';
        if (postalCodeObject) {
          address = postalCodeObject.formatted_address;
        } else {
          address = response[0].formatted_address;
        }
        resolve({ address, state, country });
      } else {
        reject(status);
      }
    });
  });
};
