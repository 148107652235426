import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { Container, Box, Paper } from '@applift/factor';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { ScreenLoader } from 'components/ScreenLoader';
import { BreadCrumbActions } from 'components/BreadCrumbActions';
import {
  applicationActions,
  SetBudgetTypeId,
  ResetStoreForCampaignSwitch,
  ResetAppStoreFull,
  SetModellingDimensionToShow,
} from 'store/app/actions';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaignList';
import { API } from 'api';
import { ExistingCampaignData } from '../../../models/ExistingCampaign';
import { AppState } from '../../../models/Store';
import { EditCampaign } from './EditCampaignPage';

import styles from './styles.module.scss';

type EditCampaignWrapperComponentProps = SetBudgetTypeId & SetModellingDimensionToShow & {};

const EditCampaignWrapperComponent = (props: EditCampaignWrapperComponentProps) => {
  const { setModellingDimensionToShow } = props;

  const params = useParams<{ id: string }>();
  const history: History<{ modellingDimensionToShow?: string }> = useHistory();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(null);
  const [editableCampaign, setEditableCampaign] = React.useState<ExistingCampaignData | null>(null);
  const { id: campaignID } = params;
  let campaignId: number = -1;
  if (campaignID) {
    campaignId = Number(campaignID);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (campaignId >= 0) {
        try {
          setIsLoading(true);
          const editableCampaign = await API.Campaign.FetchCampaignData(campaignId);
          if (editableCampaign?.campaignTypeId !== CAMPAIGN_TYPE_BY_NAME.advanced) {
            history.replace(`/io-detail/${editableCampaign.ioId}`);
          }
          if (['deleted', 'expired'].includes(editableCampaign?.status as string)) {
            history.replace(`/io-detail/${editableCampaign.ioId}`);
          }
          if (editableCampaign) {
            setEditableCampaign(editableCampaign);
          }
          if (history.location.state?.modellingDimensionToShow) {
            setModellingDimensionToShow(history.location.state.modellingDimensionToShow);
          }
          setIsLoading(false);
        } catch (e) {
          setError(e);
          setIsLoading(false);
        }
      } else {
        setError('Invalid Campaign ID');
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    campaignId,
    setIsLoading,
    setEditableCampaign,
    setModellingDimensionToShow,
    history.location.state,
    history,
  ]);

  /* eslint-disable */
  return !isLoading && !error ? (
    <EditCampaign editableCampaignProp={editableCampaign} />
  ) : error ? (
    <Container className={styles.LoaderContainer}>{error}</Container>
  ) : (
    <Container className={styles.LoaderContainer}>
      <ScreenLoader text="Loading..." />
    </Container>
  );
};

const mapCampaignTypeAction = {
  setBudgetTypeId: applicationActions.setBudgetTypeId,
  setModellingDimensionToShow: applicationActions.setModellingDimensionToShow,
};

const EditCampaignWrapper = connect(null, mapCampaignTypeAction)(EditCampaignWrapperComponent);

// Here we only clear the redux store, so we can initialize campaign with a fresh store
export interface ClearCampaignStoreWrapperComponentProps
  extends ResetStoreForCampaignSwitch,
    ResetAppStoreFull {
  campaignDataResetting?: AppState['app']['campaignDataResetting'];
}

const ClearCampaignStoreWrapperComponent = (props: ClearCampaignStoreWrapperComponentProps) => {
  const { resetStoreForCampaignSwitch, campaignDataResetting, resetAppStoreFull } = props;
  const { id: campaignId } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();

  const invokedRef = React.useRef(false);
  const prevCampaignIdRef = React.useRef(campaignId);

  React.useEffect(() => {
    // @ts-ignore
    if ((location?.state?.hasError ?? false) === true) {
      invokedRef.current = true;
      setIsLoading(false);
      return;
    }
    if (campaignId !== prevCampaignIdRef.current) {
      invokedRef.current = false;
      prevCampaignIdRef.current = campaignId;
    }
    if (campaignDataResetting === false && invokedRef.current === false) {
      setIsLoading(true);
      invokedRef.current = true;
      resetAppStoreFull();
      resetStoreForCampaignSwitch();
    } else if (campaignDataResetting === false) {
      setIsLoading(false);
    }
  }, [campaignDataResetting, resetStoreForCampaignSwitch, setIsLoading, campaignId]);

  return (
    <Box className={styles.container}>
      <Paper
        elevation={2}
        className={styles.breadcrumbBar}
        sx={{ px: 16, display: 'flex', alignItems: 'center', justifyContent: 'between' }}
      >
        <BreadCrumbActions panelType="campaign" />
      </Paper>
      {isLoading ? <ScreenLoader text="Loading..." /> : <EditCampaignWrapper />}
    </Box>
  );
};

const mapWrapperState = (state: AppState) => ({
  campaignDataResetting: state.app.campaignDataResetting,
});

const mapWrapperActions = {
  resetStoreForCampaignSwitch: applicationActions.resetStoreForCampaignSwitch,
  resetAppStoreFull: applicationActions.resetAppStoreFull,
};

export const EditCampaignPage = connect(
  mapWrapperState,
  mapWrapperActions,
)(ClearCampaignStoreWrapperComponent);
