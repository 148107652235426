import { ConversionType } from 'models/Conversion';
import { ConversionFilter } from '@applift/conversion/lib/models/List';

export const getConversionFilter = (ct: ConversionType | null): ConversionFilter | null => {
  if (ct === ConversionType.PIXEL) {
    return 'pixel';
  }
  if (ct === ConversionType.POSTBACK) {
    return 'postback';
  }
  return null;
};
