import { createAction } from '../../utils/actions';
import { schedulingConstants } from './constants';
import { Scheduling } from './reducer';

type Data = {
  key: string;
  value: Scheduling;
};

export type SelectSchedulingField = {
  selectSchedulingField: (data: Data) => void;
};

export type ResetState = {
  resetState: () => void;
};

export const schedulingActions = {
  selectSchedulingField(data: Data) {
    return createAction<Data>(schedulingConstants.SELECT_SCHEDULING_FIELD, data);
  },

  resetState() {
    return createAction(schedulingConstants.RESET_SCHEDULING_STORE);
  },
};
