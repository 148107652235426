import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';

import { API } from 'api';
import { Option } from 'models/Option';
import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { technologyActions, SetTechnologyField } from 'store/technology/actions';
import { ICountryOption } from 'models/Location';
import { Logger } from 'utils/logger';

type Props = SetTechnologyField & {
  value: Option<number>[];
  country: ICountryOption | null;
  className?: string;
  carriers: Option<number>[];
  onChange: (value: Option<number>[]) => void;
};

const SelectCarriersWrapperComponent = (props: Props) => {
  const { value, country, carriers, className = '', onChange, setTechnologyField } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.List.Carriers();
        const data = response || [];

        setTechnologyField({
          key: 'carriers',
          value: data.map((i) => ({
            value: i.id,
            label: i.name,
          })),
        });
      } catch (e) {
        Logger.log('Carriers Error', e);
      }
    };

    if (country) {
      fetchData();
    }
  }, [country, setTechnologyField]);

  return (
    <Select
      label="Carrier"
      placeholder="Select Carrier"
      options={carriers}
      value={value}
      onChange={onChange}
      isSearchable
      isMulti
      allSelectable
      className={className}
      tooltipParams={{ label: 'Telecommunications Service Provider' }}
    />
  );
};

const mapState = (state: AppState) => ({
  country: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  carriers: state.technology.carriers,
});

const mapAction = {
  setTechnologyField: technologyActions.setTechnologyField,
};

export const SelectCarriersWrapper = connect(mapState, mapAction)(SelectCarriersWrapperComponent);
