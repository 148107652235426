import { Box, Typography } from '@applift/factor';
import {
  AUDIO_CREATIVE_ID,
  Creative,
  NATIVE_CREATIVE_ID,
  VIDEO_CREATIVE_ID,
} from 'models/Creative';
import React from 'react';

export interface CreativeSizeCellPropTypes {
  creative: Creative;
}

export const CreativeSizeCell = (props: CreativeSizeCellPropTypes) => {
  const renderSize = () => {
    const { creative } = props;
    const { creativeTypeId, duration, width, height } = creative;
    switch (creativeTypeId) {
      case VIDEO_CREATIVE_ID:
      case AUDIO_CREATIVE_ID:
        return `${duration}s`;
      case NATIVE_CREATIVE_ID:
        if (Boolean(width) && Boolean(height)) {
          return `${width}x${height}`;
        }
        if (typeof duration === 'number') {
          return `${duration}s`;
        }
        return '—';
      default:
        return `${width}x${height}`;
    }
  };

  return (
    <Box>
      <Typography>{renderSize() || '—'}</Typography>
    </Box>
  );
};
