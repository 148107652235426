import React, { useState, useCallback, useEffect, useRef, RefObject, useMemo } from 'react';
import { connect, useStore } from 'react-redux';
import { uniqueId } from 'lodash';
import { useVirtualizer, defaultRangeExtractor } from '@tanstack/react-virtual';
import { Icon, Checkbox } from 'factor';

import { AppState } from 'models/Store';
import { CampaignInfoField } from 'models/CampaignInfoFields';
import { locationTypeIds, US_COUNTRY_ID } from 'constants/location';
import { Option } from 'models/Option';
import {
  DistrictOption,
  ICountryOption,
  ILocationTypeWithOptions,
  IStateOption,
} from 'models/Location';
import { advanceActions } from 'store/advance/actions';
import { AdvancePageState } from 'store/advance/reducer';

import styles from './index.module.scss';

interface Props {
  locationData: ILocationTypeWithOptions;
  searchText: string;
  appliedFilters: number[];
  saveLocations: (field: keyof typeof CampaignInfoField) => (value: string | Option[]) => void;
  selectedDistricts: DistrictOption[];
  selectedSenates: DistrictOption[];
  selectedHouses: DistrictOption[];
  selectedDma: DistrictOption[];
  selectedStates: IStateOption[];
  selectedCities: DistrictOption[];
  selectedCounties: DistrictOption[];
  selectedCountry: ICountryOption | null;
  sidebarCampaignInfo: AdvancePageState['sidebarCampaignInfo'];
  parent?: string;
}

type SelectedLocationKeys =
  | 'selectedStates'
  | 'selectedCities'
  | 'selectedCounties'
  | 'selectedCongressionalDistricts'
  | 'selectedHouses'
  | 'selectedSenates'
  | 'selectedDma';

interface LocationMetaData {
  id?: number;
  name: string;
  icon?: string;
  type: string;
  abbreviation?: string;
  height: number;
  parentId?: number;
  uniqueId: string;
  message?: string;
  selectedFieldsKey?: SelectedLocationKeys;
  isExpanded?: boolean;
  geojsonUrl?: string;
}

export const locationTypeMap: {
  [key: string]: string;
} = {
  '302': 'states',
  '303': 'counties',
  '304': 'cities',
  '306': 'districts',
  '307': 'senates',
  '308': 'houses',
  '309': 'dma',
};

const heights = {
  locationItemSmall: 25,
  infoDiv: 35,
  emptyMsg: 30,
  showOrHide: 30,
  header: 32,
  locationItemBig: 35,
};

const LocationDropdownComponent = (props: Props) => {
  const {
    locationData,
    searchText,
    appliedFilters,
    saveLocations,
    selectedStates,
    selectedDistricts,
    selectedSenates,
    selectedHouses,
    selectedDma,
    selectedCities,
    selectedCounties,
    selectedCountry,
    sidebarCampaignInfo,
    parent,
  } = props;

  const store = useStore();
  const [displayData, setDisplayData] = useState<LocationMetaData[]>([]);
  const isFilterApplied = appliedFilters.length > 0;
  const parentRef = useRef<HTMLElement>(null);
  const activeStickyIndexRef = useRef<number>(0);
  const stickyIndexes: number[] = useMemo(
    () => displayData.flatMap((element, index) => (element.type === 'header' ? index : [])),
    [displayData],
  );
  const isSelectedCountryUSA = useCallback(() => {
    return !!(selectedCountry && selectedCountry.value === US_COUNTRY_ID);
  }, [selectedCountry]);

  const [expandedSections, setExpandedSections] = useState<number[]>([]);

  const getSlicedList = useCallback(
    (locationTypeId: number, selectedFieldsKey: SelectedLocationKeys, isExpanded: boolean) => {
      const isLocationsPresent = locationData[locationTypeId];
      if (!isLocationsPresent) {
        return [
          {
            type: 'emptyMsg',
            message: 'No search results found',
            height: heights.emptyMsg,
            uniqueId: uniqueId(),
          },
        ];
      }

      const items: LocationMetaData[] = [];
      if (locationTypeId === locationTypeIds.states) {
        items.push({
          type: 'infoDiv',
          name: 'stateInfo',
          height: heights.infoDiv,
          message: 'State will be used for targeting & not filtering',
          uniqueId: uniqueId(),
        });
      }

      const isCategoryExpanded = isExpanded || appliedFilters.includes(locationTypeId);
      const locationsUnderCategory = locationData[locationTypeId] || [];

      if (locationsUnderCategory.length) {
        items.push(
          ...([
            locationTypeIds.cities,
            locationTypeIds.states,
            locationTypeIds.counties,
            locationTypeIds.dma,
          ].includes(locationTypeId)
            ? locationsUnderCategory.sort((a, b) => (a.name > b.name ? 1 : -1))
            : locationsUnderCategory
          )
            .slice(0, isCategoryExpanded ? locationsUnderCategory.length : 5)
            .map((item) => ({
              type: 'locationItem',
              height: item.name.length > 40 ? heights.locationItemBig : heights.locationItemSmall,
              uniqueId: uniqueId(),
              name: item.name,
              id: item.id,
              abbreviation: item.abbreviation,
              parentId: item.parentId,
              selectedFieldsKey,
              geojsonUrl: item.geojsonUrl,
            })),
        );
      }

      if (locationsUnderCategory.length > 5 && !appliedFilters.includes(locationTypeId)) {
        items.push({
          type: `show/hide-${locationTypeId}`,
          name: isExpanded ? 'View Less' : 'Show All',
          height: heights.showOrHide,
          uniqueId: uniqueId(),
          selectedFieldsKey,
        });
      }
      return items;
    },
    [locationData, appliedFilters],
  );

  useEffect(() => {
    if (Object.keys(locationData)) {
      setDisplayData([
        ...(!isFilterApplied || appliedFilters.includes(locationTypeIds.states)
          ? [
              {
                id: locationTypeIds.states,
                name: 'State',
                icon: 'FilterState',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.states,
                'selectedStates',
                expandedSections.includes(locationTypeIds.states),
              ),
            ]
          : []),
        ...((!isFilterApplied || appliedFilters.includes(locationTypeIds.dma)) &&
        isSelectedCountryUSA()
          ? [
              {
                id: locationTypeIds.dma,
                name: 'DMA',
                icon: 'FilterDma',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.dma,
                'selectedDma',
                expandedSections.includes(locationTypeIds.dma),
              ),
            ]
          : []),
        ...((!isFilterApplied || appliedFilters.includes(locationTypeIds.counties)) &&
        isSelectedCountryUSA()
          ? [
              {
                id: locationTypeIds.counties,
                name: 'County',
                icon: 'FilterCounty',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.counties,
                'selectedCounties',
                expandedSections.includes(locationTypeIds.counties),
              ),
            ]
          : []),
        ...(!isFilterApplied || appliedFilters.includes(locationTypeIds.cities)
          ? [
              {
                id: locationTypeIds.cities,
                name: 'City',
                icon: 'FilterCity',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.cities,
                'selectedCities',
                expandedSections.includes(locationTypeIds.cities),
              ),
            ]
          : []),
        ...((!isFilterApplied || appliedFilters.includes(locationTypeIds.districts)) &&
        isSelectedCountryUSA()
          ? [
              {
                id: locationTypeIds.districts,
                name: 'Congressional District',
                icon: 'FilterCongress',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.districts,
                'selectedCongressionalDistricts',
                expandedSections.includes(locationTypeIds.districts),
              ),
            ]
          : []),
        ...((!isFilterApplied || appliedFilters.includes(locationTypeIds.senates)) &&
        isSelectedCountryUSA()
          ? [
              {
                id: locationTypeIds.senates,
                name: 'State Senate',
                icon: 'FilterSenate',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.senates,
                'selectedSenates',
                expandedSections.includes(locationTypeIds.senates),
              ),
            ]
          : []),
        ...((!isFilterApplied || appliedFilters.includes(locationTypeIds.houses)) &&
        isSelectedCountryUSA()
          ? [
              {
                id: locationTypeIds.houses,
                name: 'State House',
                icon: 'FilterHouse',
                type: 'header',
                height: heights.header,
                uniqueId: uniqueId(),
              },
              ...getSlicedList(
                locationTypeIds.houses,
                'selectedHouses',
                expandedSections.includes(locationTypeIds.houses),
              ),
            ]
          : []),
      ] as LocationMetaData[]);
    }
  }, [
    locationData,
    appliedFilters,
    getSlicedList,
    isFilterApplied,
    selectedSenates,
    selectedStates,
    selectedHouses,
    selectedDistricts,
    selectedDma,
    selectedCounties,
    selectedCities,
    expandedSections,
    isSelectedCountryUSA,
  ]);

  const getHighlights = (str: string, searchField: string) => {
    const escaped = searchField
      .replaceAll(/[-[\]{}()+?.,\\^$|#]/g, (strn) => `\\${strn}`)
      .replaceAll('*', () => '.*');
    const parts: any[] = str.split(new RegExp(escaped, 'ig'));
    // @ts-ignore
    const matches = [...str.matchAll(new RegExp(escaped, 'ig'))];

    parts.forEach((part, index) => {
      if (index !== parts.length - 1) {
        parts[index] = (
          <>
            <span>{part}</span>
            <span className={styles.highlight}>{matches[index][0]}</span>
          </>
        );
      }
    });
    return parts;
  };

  const isLocationSelected = (fieldKey: SelectedLocationKeys, locationId: number) =>
    store.getState().advanced.sidebarCampaignInfo[fieldKey]?.length
      ? (sidebarCampaignInfo[fieldKey] as (IStateOption | DistrictOption)[])
          .flatMap(({ value }) => (!value ? [] : value))
          .includes(locationId)
      : false;

  const onSelectionChange = (item: LocationMetaData) => {
    const locationTypeId = (item.id as number).toString().substring(0, 3);
    const type = locationTypeMap[locationTypeId] as keyof typeof CampaignInfoField;
    const selections = store.getState().advanced.sidebarCampaignInfo[
      item?.selectedFieldsKey as SelectedLocationKeys
    ] as (IStateOption | DistrictOption)[];
    const isAlreadySelected = selections
      ? selections.map((location) => location.value).includes(item.id as number)
      : false;
    const updatedLocationsData = isAlreadySelected
      ? selections.filter((location) => location.value !== item.id)
      : [
          ...selections,
          {
            label: item.name,
            value: item.id as number,
            ...(type !== 'states'
              ? { stateId: item.parentId as number, parentId: item.parentId }
              : {}),
            v2Id: item.id as number,
            abbreviation: item.abbreviation,
            geojsonUrl: item.geojsonUrl,
          },
        ];
    saveLocations(type)(updatedLocationsData);
  };

  const renderRow = (index: number) => {
    const item: LocationMetaData = displayData[index];
    const locationTypeId: string = item.type.slice(-3); // for getting locationtypeid in case of showall alone

    switch (item.type) {
      case 'header':
        return (
          // key prop necessary to re render the list items properly
          <div className={styles.locationHeader} key={`${item.uniqueId}-header`}>
            <Icon name={item.icon} />
            <label className={styles.locationLabel}>
              {item.name}{' '}
              <span>
                {locationData[item.id as number]
                  ? `(${locationData[item.id as number].length.toString().padStart(2, '0')})`
                  : null}
              </span>
            </label>
          </div>
        );
      case 'locationItem':
        return (
          <div className={styles.locationItem} key={`${item.uniqueId}-locationItem`}>
            <Checkbox
              checked={isLocationSelected(
                item.selectedFieldsKey as SelectedLocationKeys,
                item.id as number,
              )}
              onChange={() => onSelectionChange(item)}
              className={`mr-1 ${styles.filterCheckbox}`}
            />
            <div className={styles.locationLabel}>
              {item.name ? getHighlights(item.name, searchText.trimEnd().trimStart()) : ''}
            </div>
          </div>
        );
      case 'infoDiv':
        return (
          <div className={styles.infoDivContainer} key={`${item.uniqueId}-infoDiv`}>
            <div className={styles.infoDiv}>
              <Icon name="Info" className="mr-1" />
              <div>{item.message}</div>
            </div>
          </div>
        );
      case `show/hide-${locationTypeId}`:
        return (
          <div
            className={styles.expand}
            key={`${item.uniqueId}-show/hide-${locationTypeId}`}
            onClick={() =>
              setExpandedSections((prev) =>
                prev.includes(+item.type.slice(-3))
                  ? [...prev.filter((typeId) => typeId !== +item.type.slice(-3))]
                  : [...prev, +item.type.slice(-3)],
              )
            }
          >
            <Icon
              name={expandedSections.includes(+item.type.slice(-3)) ? 'Collapse' : 'Expand'}
              className="ml-1"
            />{' '}
            <span>{item.name}</span>
          </div>
        );
      case 'emptyMsg':
        return (
          <div key={`${item.uniqueId}-emptyMsg`} className={styles.emptyMessage}>
            {item.message}
          </div>
        );
      default:
        return null;
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: displayData.length,
    estimateSize: (index) => displayData[index].height,
    getScrollElement: () => parentRef.current,
    rangeExtractor: useCallback(
      (range) => {
        activeStickyIndexRef.current = [...stickyIndexes]
          .reverse()
          .find((index) => range.startIndex >= index) as number;

        const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);
        // @ts-ignore
        return [...next].sort((a, b) => a - b);
      },
      [stickyIndexes],
    ),
  });
  return displayData.length ? (
    <div
      ref={parentRef as RefObject<HTMLDivElement>}
      className="List"
      style={{
        height: parent === 'planner' ? '340px' : `409px`,
        width: `100%`,
        overflow: 'auto',
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <div
            key={virtualRow.index}
            className="ListItem"
            style={{
              ...(stickyIndexes.includes(virtualRow.index)
                ? {
                    zIndex: 5,
                    background: 'white',
                    paddingTop: '2px',
                  }
                : {
                    paddingLeft: '15px',
                  }),
              ...(activeStickyIndexRef.current === virtualRow.index
                ? {
                    position: 'sticky',
                  }
                : {
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`,
                  }),
              top: 0,
              left: 0,
              width: '100%',
              height: `${virtualRow.size}px`,
            }}
          >
            {renderRow(virtualRow.index)}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

const mapState = (state: AppState) => ({
  selectedStates: state.advanced.sidebarCampaignInfo[CampaignInfoField.states],
  selectedDistricts: state.advanced.sidebarCampaignInfo[CampaignInfoField.districts],
  selectedSenates: state.advanced.sidebarCampaignInfo[CampaignInfoField.senates],
  selectedHouses: state.advanced.sidebarCampaignInfo[CampaignInfoField.houses],
  selectedDma: state.advanced.sidebarCampaignInfo[CampaignInfoField.dma],
  selectedCities: state.advanced.sidebarCampaignInfo[CampaignInfoField.cities],
  selectedCounties: state.advanced.sidebarCampaignInfo[CampaignInfoField.counties],
  sidebarCampaignInfo: state.advanced.sidebarCampaignInfo,
  selectedCountry: state.advanced.sidebarCampaignInfo[CampaignInfoField.country],
  editableCampaign: state.app.editableCampaign,
  isUsingV2API: state.advanced.isUsingV2API,
  limits: state.advanced?.limits,
});

const actions = {
  setWhiteList: advanceActions.setWhiteList,
  setBlackList: advanceActions.setBlackList,
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
};

export const LocationDropdown = connect(mapState, actions)(LocationDropdownComponent);
