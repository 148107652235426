import * as React from 'react';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { CampaignEstimatorMetaData } from 'models/ExistingCampaign';
import { ReachImpression } from './ReachImpression';
import { Reach } from './Reach';

export interface ReachImpressionWrapperType {
  maxBid: string;
  totalBudget?: string;
  targetableAudience?: number;
  startDate?: number;
  endDate?: number;
  reach?: number;
  campaignEstimatorMetaData?: CampaignEstimatorMetaData;
  budgetTypeId?: number;
  totalImpressions?: string;
  setShouldUpdateReach: (arg: boolean) => void;
  setCampaignSidebarInfo: (key: string, value: string) => void;
  setPrevProposedBudget: (arg: any) => void;
}

export const ReachImpressionWrapper = (props: ReachImpressionWrapperType) => {
  const { budgetTypeId } = props;
  return budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReachImpression {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Reach {...props} />
  );
};
