import { useQuery } from '@tanstack/react-query';
import { getCountryList } from 'api/List';
import { getCountryListQueryKey } from 'api/QueryKeys';

export interface CountryListParamsType {
  searchField?: string;
  options?: {
    enabled?: boolean;
    onSuccess?: (res: any) => void;
  };
}

export const useCountryList = ({ searchField, options }: CountryListParamsType) => {
  const data = useQuery(
    getCountryListQueryKey.keys('getCountryList', searchField ?? ''),
    getCountryList,
    {
      enabled: options?.enabled,
      onSuccess: options?.onSuccess,
    },
  );
  return data;
};
