import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Row,
  Col,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  FormLabel,
} from '@applift/factor';
import { CampaignAdvanced, CampaignPG } from '@applift/icons';
import { CampaignTypeByName } from 'models/campaign';
import { useStore } from 'zustandStore';
import { StoreFilter } from 'models/ZustandStore';
import { SetCampaignSidebarInfo, advanceActions } from 'store/advance/actions';
import { connect } from 'react-redux';

export interface CreateCampaignDialogType extends SetCampaignSidebarInfo {
  open?: boolean;
  onClose?: () => void;
  onCreate?: (campaignType: CampaignTypeByName) => void;
}

export const CreateCampaignDialogComponent = (props: CreateCampaignDialogType) => {
  const { onClose, onCreate } = props;

  const [campaignType, setCampaignType] = React.useState<CampaignTypeByName>('advanced');
  const [localCampaignName, setLocalCampaignName] = React.useState<string>('');

  const [campaignName, setCampaignName] = useStore((state: StoreFilter) => [
    state.campaignInfoBlock.campaignName,
    state.campaignInfoBlock.setCampaignName,
  ]);

  const handleCreateCampaignWrapper = () => {
    if (onCreate) {
      setCampaignName(localCampaignName.trim());
      onCreate(campaignType);
    }
  };

  React.useEffect(() => {
    if (setCampaignName) {
      setCampaignName('');
    }
  }, [setCampaignName]);

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>Create Campaign</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Row sx={{ mb: 32 }}>
            <Col xs={6}>
              <TextField
                onChange={(e: any) => {
                  setLocalCampaignName(e.target.value?.trimStart());
                }}
                value={localCampaignName ?? campaignName}
                autoFocus
                required
                sx={{ width: 100 }}
                label="Campaign Name"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormControl>
                <RadioGroup value={campaignType}>
                  <FormLabel sx={{ mb: 4, pl: 12, textColor: 'neutral-600' }} required>
                    Campaign Type
                  </FormLabel>
                  <FormControlLabel
                    sx={{ alignItems: 'baseline' }}
                    control={<Radio onClick={() => setCampaignType('advanced')} value="advanced" />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'start' }}>
                        <CampaignAdvanced fontSize={20} sx={{ mr: 4 }} color="primary" />
                        <Typography sx={{ flexShrink: 0 }}>Advanced -</Typography>
                        <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
                          Utilizes advanced targeting for optimized ad performance and specific
                          audience reach.
                        </Typography>
                      </Box>
                    }
                  />
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      control={<Radio onClick={() => setCampaignType('pg')} value="pg" />}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CampaignPG fontSize={20} sx={{ mr: 4 }} color="primary" />
                          PG -
                          <Typography sx={{ textColor: 'neutral-500', ml: 4 }}>
                            Pre-purchased ad inventory with guaranteed placement and impressions.
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!localCampaignName}
          onClick={() => {
            handleCreateCampaignWrapper();
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapActions = {
  setCampaignSidebarInfo: advanceActions.setCampaignSidebarInfo,
};

export const CreateCampaignDialog = connect(null, mapActions)(CreateCampaignDialogComponent);
