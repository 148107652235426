export const mapConstants = {
  INITIAL_MAP: 'INITIAL_MAP',
  PUSH__PRESAVED_POLYGON: 'PUSH__PRESAVED_POLYGON',
  REMOVE__POLYGON_FROM_PRESAVED: 'REMOVE__POLYGON_FROM_PRESAVED',
  CLEAR__PRESAVED_POLYGONS: 'CLEAR__PRESAVED_POLYGONS',
  CLEAR__PRESAVED_RECTANGLES: 'CLEAR__PRESAVED_RECTANGLES',
  ADD__POLYGONS_TO_SAVED: 'ADD__POLYGONS_TO_SAVED',
  UPDATE__PRESAVED_POLYGON: 'UPDATE__PRESAVED_POLYGON',
  REMOVE__POLYGON_FROM_SAVED: 'REMOVE__POLYGON_FROM_SAVED',
  REMOVE_SELECTED_POLYGONS_FROM_PRESAVED: 'REMOVE_SELECTED_POLYGONS_FROM_PRESAVED',
  PUSH__PRESAVED_CIRCLE: 'PUSH__PRESAVED_CIRCLE',
  UPDATE__PRESAVED_CIRCLE: 'UPDATE__PRESAVED_CIRCLE',
  REMOVE__CIRCLE_FROM_PRESAVED: 'REMOVE__CIRCLE_FROM_PRESAVED',
  REMOVE_SELECTED_CIRCLES_FROM_PRESAVED: 'REMOVE_SELECTED_CIRCLES_FROM_PRESAVED',
  CLEAR__PRESAVED_CIRCLES: 'CLEAR__PRESAVED_CIRCLES',
  PUSH__PRESAVED_RECTANGLE: 'PUSH__PRESAVED_RECTANGLE',
  UPDATE__PRESAVED_RECTANGLE: 'UPDATE__PRESAVED_RECTANGLE',
  REMOVE__RECTANGLE_FROM_PRESAVED: 'REMOVE__RECTANGLE_FROM_PRESAVED',
  REMOVE_SELECTED_RECTANGLES_FROM_PRESAVED: 'REMOVE_SELECTED_RECTANGLES_FROM_PRESAVED',
  SET__DRAWER__MODE: 'SET__DRAWER__MODE',
  SET__AREA__TYPE: 'SET__AREA__TYPE',
  SET__AREA__INCLUDE: 'SET__AREA__INCLUDE',
  PUSH_SELECTED_AREA_ID: 'PUSH_SELECTED_AREA_ID',
  REMOVE_SELECTED_AREA_ID: 'REMOVE_SELECTED_AREA_ID',
  EMPTY_SELECTED_AREAS_IDS: 'EMPTY_SELECTED_AREAS_IDS',
  CHANGED__LOCATION_COUNTY: 'CHANGED__LOCATION_COUNTY',
  CHANGED__LOCATION_STATE_SENATE: 'CHANGED__LOCATION_STATE_SENATE',
  CHANGED__LOCATION_STATE_HOUSE: 'CHANGED__LOCATION_STATE_HOUSE',
  SET__MAP_CENTER: 'SET__MAP_CENTER',
  SET__MAP_BOUNDS: 'SET__MAP_BOUNDS',
  SET__MAP_DEFAULT_CENTER: 'SET__MAP_DEFAULT_CENTER',
  SET__HOUSE_STATE_DIFFERENCE: 'SET__HOUSE_STATE_DIFFERENCE',
  RESET_MAP_STORE: 'RESET_MAP_STORE',
  SET_TOAST_MESSAGE: 'SET_TOAST_MESSAGE',
  SET_MAP_VISIBILITY: 'SET_MAP_VISIBILITY',
  UPDATE_MAP_STORE: 'UPDATE_MAP_STORE',
  SET_SIDEBAR_STATE: 'SET_SIDEBAR_STATE',
  REMOVE_FILE_PRESAVED_CIRCLES: 'REMOVE_FILE_PRESAVED_CIRCLES',
  REMOVE_FILE_PRESAVED_POLYGONS: 'REMOVE_FILE_PRESAVED_POLYGONS',
  SET_MAP_READONLY: 'SET_MAP_READONLY',
  SET_SHAPE_RADIUS_LIMIT_ERROR: 'SET_SHAPE_RADIUS_LIMIT_ERROR',
  SET_RADIUS_UPDATED_LOCATIONS: 'SET_RADIUS_UPDATED_LOCATIONS',
  SET_SELECTED_LOCATIONS_IDS: 'SET_SELECTED_LOCATIONS_IDS',
  ADD_GEOJSON_FEATURES_CACHE: 'ADD_GEOJSON_FEATURES_CACHE',
  SET_SELECTED_LOCATION_TAB: 'SET_SELECTED_LOCATION_TAB',
};
