import * as React from 'react';
import {
  Box,
  Col,
  ColorClassModifier,
  LinearProgress,
  LinearProgressProps,
  Row,
  Tooltip,
  TypoTooltip,
  Typography,
  sx,
} from '@applift/factor';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { CurrencyFormat, formatNumberWithDecimal } from 'utils/format';
import { colorMappingForPacing } from 'constants/tableCell';

interface PacingCellType {
  budgetCompletionPercentage: number;
  actualSpent: number;
  expectedSpent: number;
  budgetTypeId: number;
  dailyBudget: number;
  pacingOff?: boolean;
  totalDuration: number;
  remainingDuration: number;
}

export const PacingCell = ({
  budgetCompletionPercentage,
  actualSpent,
  expectedSpent,
  dailyBudget,
  budgetTypeId,
  pacingOff = false,
  totalDuration,
  remainingDuration,
}: PacingCellType) => {
  const expectedSpentPercentage = React.useMemo(() => {
    return (actualSpent / expectedSpent) * 100;
  }, [actualSpent, expectedSpent]);

  const getColor: () => LinearProgressProps['color'] = React.useCallback(() => {
    if (pacingOff) {
      return 'secondary';
    }
    if (expectedSpentPercentage < 60) return 'error';
    if (expectedSpentPercentage && expectedSpentPercentage < 90) return 'warning';
    if (expectedSpentPercentage && expectedSpentPercentage <= 120) return 'success';
    return 'secondary';
  }, [expectedSpentPercentage, pacingOff]);

  const spentColor: ColorClassModifier = React.useMemo(() => {
    if (expectedSpentPercentage > 120) {
      return 'purple-500';
    }
    return colorMappingForPacing[`${getColor()}`];
  }, [expectedSpentPercentage, getColor]);

  const getFormattedBudget = (budget: number | string) => {
    if (budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
      return <>Imps {Intl.NumberFormat('en-US').format(parseInt(budget as string, 10))}</>;
    }

    return `${budget}`.length <= 10
      ? CurrencyFormat.format(Number(budget) || 0)
      : String(CurrencyFormat.format(Number(budget)).slice(0, 10));
  };

  const compactNumber = (value: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      // @ts-ignore
      notation: 'compact',
    });

    return formatter.format(value);
  };

  const formatBudget = React.useCallback(
    (budget: number) => {
      return budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
        ? `Imps ${compactNumber(budget)}`
        : `$${compactNumber(budget)}`;
    },
    [budgetTypeId],
  );

  const timeSpentInDays = React.useMemo(() => {
    return totalDuration - remainingDuration;
  }, [totalDuration, remainingDuration]);

  const infoTooltipContent = () => {
    return (
      <Box sx={{ p: 12 }}>
        <Typography component="p" variant="label">
          <Typography component="span">{`Daily ${
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'budget spent' : 'impressions served'
          }: `}</Typography>
          <Typography component="span" weight="demi" sx={{ textColor: spentColor }}>
            {`${formatBudget(actualSpent)} / ${formatBudget(dailyBudget)}`}
          </Typography>
        </Typography>
        <Typography component="p" variant="label">
          <Typography component="span">{`Daily ${
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'budget spent' : 'impressions served'
          } %: `}</Typography>
          <Typography component="span" weight="demi" sx={{ textColor: spentColor }}>
            {`${formatNumberWithDecimal(budgetCompletionPercentage)}%`}
          </Typography>
        </Typography>
        <Typography component="p" variant="label">
          <Typography component="span">{`Expected ${
            budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'budget spent' : 'impressions served'
          }: `}</Typography>
          <Typography component="span" weight="demi">
            {formatBudget(expectedSpent)}
          </Typography>
        </Typography>
        <Typography component="p" variant="label">
          <Typography component="span">{'Time spent: '}</Typography>
          <Typography component="span" weight="demi">
            {`${timeSpentInDays} hrs / ${totalDuration ? `${totalDuration} hrs` : '-'}`}
          </Typography>
        </Typography>
      </Box>
    );
  };

  return (
    <Tooltip classes={{ tooltip: sx({ p: 0 }) }} title={infoTooltipContent} placement="right" arrow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: 100,
        }}
      >
        <Row xs={{ gutterSize: 8, gutterDirection: 'x' }} sx={{ alignItems: 'center' }}>
          <Col xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
              <Box>
                <Typography sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
                  <Typography weight="demi" sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
                    {getFormattedBudget(actualSpent)}
                  </Typography>
                  /
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    {getFormattedBudget(dailyBudget)}
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ ml: 'auto' }}>
                <TypoTooltip
                  TypgraphyProps={{
                    component: 'p',
                    variant: 'label',
                    sx: { textColor: spentColor },
                  }}
                  sx={{ display: 'block' }}
                  title={`${budgetCompletionPercentage}% spent`}
                  placement="right"
                  arrow
                >
                  <Typography weight="demi">{`${formatNumberWithDecimal(
                    budgetCompletionPercentage,
                  )}% `}</Typography>
                  <Typography>
                    {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? 'spent' : 'served'}
                  </Typography>
                </TypoTooltip>
              </Box>
            </Box>
          </Col>
        </Row>
        <Row xs={{ gutterSize: 8, gutterDirection: 'x' }} sx={{ alignItems: 'center' }}>
          <Col xs={12}>
            <LinearProgress
              classes={expectedSpentPercentage > 120 ? { bar: sx({ bgColor: 'purple-400' }) } : {}}
              variant="determinate"
              value={budgetCompletionPercentage <= 100 ? budgetCompletionPercentage : 100}
              sx={{ bgColor: 'neutral-200' }}
              thickness={6}
              color={
                expectedSpentPercentage <= 120 && expectedSpentPercentage >= 0
                  ? getColor()
                  : undefined
              }
            />
          </Col>
        </Row>
      </Box>
    </Tooltip>
  );
};
