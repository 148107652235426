import { StateCreator } from 'zustand';
import { PgCampaignFieldErrors, StoreFilter } from '../../models/ZustandStore';

const initialState = {
  campaignInfoBlock: [],
  conversionsBlock: [],
  creativesBlock: [],
  inventoryBlock: [],
  locationsBlock: [],
  politicalAdvertiserBlock: [],
};

export const createErrorStore: StateCreator<
  StoreFilter,
  [['zustand/devtools', never]],
  [],
  PgCampaignFieldErrors
> = (set) => ({
  errorFields: {
    ...initialState,
    setBlockFieldsError: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          errorFields: {
            ...prev.errorFields,
            [value.blockName]: [
              // @ts-ignore
              ...prev.errorFields[value.blockName],
              {
                field: value.field,
                errorText: value.errorText,
              },
            ],
          },
        }),
        false,
        'setBlockFieldsError',
      ),
    resetError: (value) =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          errorFields: {
            ...prev.errorFields,
            // @ts-ignore
            [value.blockName]: prev.errorFields[value.blockName].filter(
              (val: { field: string | undefined }) => val.field !== value.field,
            ),
          },
        }),
        false,
        'resetError',
      ),
    resetErrorStore: () =>
      set(
        (prev: StoreFilter) => ({
          ...prev,
          errorFields: {
            ...prev.errorFields,
            ...initialState,
          },
        }),
        false,
        'resetError',
      ),
  },
});
